import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { API_URL } from 'constant';
import { useNetwork } from 'hooks';
import { SignedDocPacketsState } from './states';

export const useSignedDocsPacketsInbox = () => {
	const { get } = useNetwork({ returnResponse: true, updateState: false });
	const setSignedDocPacketInboxState = useSetRecoilState(SignedDocPacketsState);
	const getSignedDocsPacketsInbox = useCallback(
		async (isLoad?: boolean): Promise<void> => {
			const resp = await get(
				`${API_URL.ENVELOPE}?action=received&status=configured&status=completed&status=voided&status=underReview`
			);
			if (!isLoad) {
				setSignedDocPacketInboxState((prev: any) => ({ ...prev, isLoaded: false }));
			}
			const { apiPayload: apiData, response, errorCode } = resp;
			const { data } = apiData || [];
			if (response?.status == 200) {
				setSignedDocPacketInboxState((prev: any) => {
					return { ...prev, data: data, isLoaded: !isLoad };
				});
			}
			if (errorCode == 404 || response?.status !== 200 || errorCode == 400) {
				setSignedDocPacketInboxState((prev: any) => {
					return { ...prev, isLoaded: !isLoad, error: true };
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return {
		getSignedDocsPacketsInbox,
	};
};
