import { useMemo, useCallback, FC, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { RadioGroup } from '@storybook';

import {
	IMultisignTemplateSelection,
	ITemplateSectionOptionValue,
	UploadDocumentOptionState,
	uploadDocumentOptions,
	uploadDocumentOptionsValues,
} from './stores';
import { IOptionValue } from 'views/pipelines/components';
import { SelectSigDocument } from './components/select-document';
import { useGetSignDocument } from 'views/sign-aggrement/store';

export const MultisignTemplateSelection: FC<IMultisignTemplateSelection> = ({
	selectedFiles,
	handleChange,
	setSelectionChange,
	setSelectedFiles,
}) => {
	const [activeOption, setActiveOption] =
		useRecoilState<ITemplateSectionOptionValue>(UploadDocumentOptionState);


	const handleOptionChange = useCallback(
		(value: IOptionValue | any) => {
			setActiveOption(value);
			setSelectionChange(value === 'newDocument');
		},
		[setActiveOption, setSelectionChange]
	);

	const { SignDocProvider } = useGetSignDocument();

	const props = useMemo(
		() => ({
			provider: SignDocProvider,
			...(handleChange && {
				handleChange,
			}),
			handleOptionChange,
			...(selectedFiles && { selectedFiles }),
		}),
		[SignDocProvider, handleChange, handleOptionChange, selectedFiles]
	);

	const documentOptions = useMemo(
		() => {
			if (uploadDocumentOptions[0] && uploadDocumentOptions[0].children)
				uploadDocumentOptions[0].children = (
					<SelectSigDocument {...props} isAdvance={true}  />
				);
			return uploadDocumentOptions;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[props]
	);

	useEffect(() => {
		if (setSelectedFiles) setSelectedFiles([]);
		if(setActiveOption) setActiveOption(uploadDocumentOptionsValues.existing);
	}, [setSelectedFiles, setActiveOption]);

	return (
		<div className="MultisignTemplateSelection">
			<div className="MultisignTemplateSelection_container">
				<RadioGroup
					options={documentOptions}
					value={activeOption}
					handleChange={handleOptionChange}
				/>
			</div>
		</div>
	);
};
