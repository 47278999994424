import type { IDocProvider } from './type';

export const SIGN_PROVIDER: { [key in Uppercase<IDocProvider>]: IDocProvider } =
	{
		DOCUSIGN: 'docusign',
		ESIGN: 'esign',
	};

export const QUESTION_TYPES = {
	CHECKBOX: 'checkbox',
	BOOLEAN: 'boolean',
	TEXT: 'text',
	RADIOGROUP: 'radiogroup',
}	
export const ALLOWED_CHECKBOX_TYPES = ['checkbox', 'boolean', 'radiogroup'];
export const ALLOWED_QUEESTION_TYPES : Record<string, string> = {
	'checkbox': 'checkbox',
	'boolean': 'checkbox',
	'radiogroup': 'radiogroup',
	'text': 'text',
}
