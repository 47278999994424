import { IClientSecret } from '../type';
import { FC, useCallback } from 'react';
import { Button } from '@storybook';
import { useNotification } from 'hooks';
import { getDateWithTime } from 'utils';

import '../credential.scss';

export const RevealSecretModal: FC<IClientSecret> = ({
	name,
	clientId,
	clientSecret,
	creationDate,
}) => {
	const { successNotification } = useNotification();

	const handleCopy = useCallback(
		(secretId: string) => {
			navigator.clipboard.writeText(secretId);
			successNotification('Copied !');
		},
		[successNotification]
	);

	return (
		<div className="reveal-secret-container">
			<div className="client-name">
				<span className="label">Name</span>
				<span className="value">{name}</span>
			</div>

			{clientId && (
				<div className="client-id">
					<div className="copyClient">
						<span className="label">Client ID</span>
						<Button
							handleClick={() => handleCopy(clientId ?? '')}
							label={<i className="ri-file-copy-line copy icon__primary" />}
							type="button"
							minHeight="16px"
						/>
					</div>
					<span className="client-id-value value">{clientId}</span>
				</div>
			)}

			{clientSecret && (
				<div className="client-secret">
					<div className="copyClient">
						<span className="label">Client Secret</span>
						<Button
							handleClick={() => handleCopy(clientSecret ?? '')}
							label={<i className="ri-file-copy-line copy icon__primary" />}
							type="button"
							minHeight="16px"
						/>
					</div>
					<span className="secret-value value">{clientSecret}</span>
				</div>
			)}

			<div className="creation-date">
				<span className="label">Creation Date</span>
				<span className="value">{getDateWithTime(creationDate as any)}</span>
			</div>
		</div>
	);
};
