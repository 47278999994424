import { FC, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import {
	analyticsChartHeaderState,
	analyticsChartTwoHeaderStart,
	useVariablePieChart,
} from 'views/analytics/hooks';
import './analytics-chart.scss';

interface ISeriesData {
	name: string;
	y: number;
	z: number;
	diff?: number;
}
interface IPieChart {
	isLoading: boolean;
	title: string;
	seriesColor: string[];
	seriesData: ISeriesData[];
	chartName: string;
	chartPercent: number;
}

export const VariablePieChart: FC<IPieChart> = ({
	isLoading,
	seriesColor,
	title,
	seriesData,
	chartName,
	chartPercent,
}) => {
	const { getVariablePieConfig } = useVariablePieChart();

	const compareDateRange = useRecoilValue(analyticsChartTwoHeaderStart);
	const selectedDate = useRecoilValue(analyticsChartHeaderState);

	const [seriesName, setSeriesName] = useState<string>(chartName);
	const [value, setValue] = useState<number>(chartPercent);

	useEffect(() => {
		setSeriesName(chartName);
		setValue(chartPercent);
	}, [chartName, chartPercent, compareDateRange, selectedDate]);

	useEffect(() => {
		getVariablePieConfig({
			isLoading,
			seriesColor,
			title,
			seriesData,
			seriesName,
			value,
			setSeriesName,
			setValue,
		});
	}, [
		getVariablePieConfig,
		isLoading,
		seriesColor,
		seriesData,
		seriesName,
		title,
		value,
	]);

	return (
		<div className="analytic-chart-wrapper">
			<div id={title} />
		</div>
	);
};
