import { Image } from '@storybook';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { CardsListViewState, loginState } from 'global-stores';
import { useSubscription } from 'hooks';
import {
	BillingSummary,
	OnboardingPaymentType,
	OnboardingPaymentTypeState,
	PayNowCardId,
	PayNowCurrentPage,
	PayNowSteps,
	ProcessingCloseShowState,
	useGetInviteOnboardingPayment,
	useInviteOnboardingPayment,
} from '../../store';

const { PAY_NOW, SUCCESS } = PayNowSteps;

export const PayNowProcessing = () => {
	// global states and recoils
	const { data: summary } = useRecoilValue(BillingSummary);
	const setCurrentPage = useSetRecoilState(PayNowCurrentPage);
	const cards = useRecoilValue(CardsListViewState);
	const setProcessingClose = useSetRecoilState(ProcessingCloseShowState);
	const { sandboxStatus } = useRecoilValue(loginState);
	const paymentType = useRecoilValue(OnboardingPaymentTypeState);
	const defaultId = useRecoilValue(PayNowCardId);

	// local states
	const [processing, setProcessing] = useState(0);

	// custom hooks
	const { payNow } = useInviteOnboardingPayment();
	const { invitedUsers } = useGetInviteOnboardingPayment();
	const { checkSubscription } = useSubscription();
	const { makeMonthlyBilling } = useInviteOnboardingPayment();

	useEffect(() => {
		setTimeout(() => {
			setProcessingClose(true);
		}, 5000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isSandboxMode = useMemo(
		() => sandboxStatus || !checkSubscription('onboarding'),
		[checkSubscription, sandboxStatus]
	);

	const handleSendInvite = useCallback(async () => {
		if (
			isSandboxMode ||
			paymentType === OnboardingPaymentType.MONTHLY_BILLING
		) {
			const resp = await makeMonthlyBilling();
			setCurrentPage(resp ? SUCCESS : PAY_NOW);
			return;
		}
		// // finding the default card for payment
		// const selectedCard: any = cards.find(card => card.default);

		// creating the extra keys to be added in the payload in case of pay now invite
		const payload = {
			currency: 'usd',
			amount: summary.totalPrice * invitedUsers ?? 0,
			cardId: defaultId || '',
		};
		// the status is a boolean value contains the status of the api call
		const status = await payNow(payload);
		setCurrentPage(status ? SUCCESS : PAY_NOW);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		cards,
		invitedUsers,
		payNow,
		setCurrentPage,
		summary.totalPrice,
		isSandboxMode,
	]);

	useEffect(() => {
		if (processing < 100) {
			setTimeout(() => {
				if (processing <= 70) {
					setProcessing(prev => prev + 10);
				} else if (processing <= 85) {
					setProcessing(prev => prev + 5);
				} else {
					setProcessing(prev => prev + 1);
				}
			}, 50);
		}
	}, [processing]);

	useEffect(() => {
		handleSendInvite();
		// eslint-disable-next-line
	}, []);

	return (
		<div className="payNow-processing">
			<div className="payNow-processing__wrapper">
				<div className="payNow-processing__image-container">
					<Image fileName="business.svg" className="payNow-processing__image" />
				</div>
				<span className="payNow-processing__description">
					Processing Payment
				</span>
				<progress
					value={processing}
					max={100}
					className="payNow-processing__loading-bar"
				/>
			</div>
		</div>
	);
};
