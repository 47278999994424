import { FC } from 'react';

interface LabelProps {
	label: string;
}
export const LabelHeader: FC<LabelProps> = ({ label }) => {
	return (
		<div className="company_details full__span">
			<p>{label}</p>
			<div className="sperater"></div>
		</div>
	);
};
