export const convertFileToBase64 = (file: any) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = reject;
	});
};

export const getInitialOrSignatureBase64 = async (
	url: string
): Promise<string> => {
	try {
		const response = await fetch(url);
		const image = await response.blob();
		const base64 = (await convertFileToBase64(image)) as string;
		return base64;
	} catch (error) {
		return url;
	}
};

export const formatBase64 = (base64String: string) => {
	return base64String.replace(/^data:[^;]+;base64,/, '');
};

export const base64ImageFromString = (base64String: string) => {
	return `data:image/png;base64,${base64String}`;
};

/**
 * scale the base 64 image to specified width and height
 * @param {string }base64 base 64 of image
 * @param {number}maxWidth maximum new width of the image
 * @param {number}maxHeight maximum new height of the image
 * @returns promise resolves to image string
 */
export const scaleBase64Image = async (
	base64: string,
	maxWidth: number,
	maxHeight: number
) => {
	const img = new Image();
	img.src = base64;
	await img.decode();
	const canvas = document.createElement('canvas');
	canvas.width = maxWidth;
	canvas.height = maxHeight;
	const ctx = canvas.getContext('2d');
	if (ctx) {
		ctx.drawImage(img, 0, 0, maxWidth, maxHeight);
	}
	return canvas.toDataURL();
};
export const checkBase64ValidImage = (base64String: string) => {
	return base64String.startsWith('data:image');
};

/**
 * append data:image/png;base64, if the string passed does not start with data:image
 * @param image image string that needs to be checked and appended
 * @returns base64 image string
 */
export const checkAndAppendBase64Property = (image: string) => {
	if (checkBase64ValidImage(image)) {
		return image;
	}
	return base64ImageFromString(image);
};
