import { FC } from 'react';
import {
	CardNumberElement,
	CardCvcElement,
	CardExpiryElement,
} from '@stripe/react-stripe-js';
import { Button, Loader, Tooltip } from '@storybook';
import { useAddCard } from '../hooks';

interface IPaymentFrom {
	from?: 'SIGNUP' | 'HOME';
}

export const AddCardForm: FC<IPaymentFrom> = ({ from }) => {
	const {
		onSubmitCard,
		handleChangeCard,
		cvvConfigOptions,
		handleChangeDefaultCheck,
		defaultCheck,
		handleCloseViewModal,
		isLoader,
		manageBtnDisabled,
	} = useAddCard({ from });

	return (
		<div className="billing-payment-form">
			<form className="form" onSubmit={onSubmitCard}>
				<div className="card-number-container">
					<div className="card-label">Card number</div>
					<div className="card-number">
						<i className="ri-wallet-line payment-form__icon" />
						<CardNumberElement
							onChange={e => handleChangeCard(e.complete, 'cardNumber')}
						/>
					</div>
				</div>
				<div className="card-ex-cvc">
					<div className="card-expiry-container">
						<div className="card-label"> Expiry date</div>
						<div className="card-expiry">
							<i className="ri-calendar-line payment-form__icon" />
							<CardExpiryElement
								onChange={e => handleChangeCard(e.complete, 'expiryDate')}
							/>
						</div>
					</div>
					<div className="card-cvc-container">
						<div className="card-label">
							<span>CVV/CVC</span>{' '}
							<Tooltip
								text={
									'CVC/CVV is a 3-digit security code found on the back of your card. It helps secure your online transactions.'
								}
								direction='top'
							>
								<>
									<i className="ri-question-line" />{' '}
								</>
							</Tooltip>
						</div>
						<div className="card-cvc">
							<i className="ri-lock-2-line payment-form__icon" />
							<CardCvcElement
								options={cvvConfigOptions}
								onChange={e => handleChangeCard(e.complete, 'cvv')}
							/>
						</div>
					</div>
				</div>
				<div className="billing-add--check">
					<input
						type="checkbox"
						onChange={handleChangeDefaultCheck}
						checked={defaultCheck}
					/>

					<div>Set as primary payment method</div>
				</div>
				<div className="payment-add-card__btn-container btn-container">
					<Button
						handleClick={handleCloseViewModal}
						label="Back"
						type="button__filled button__filled--secondary button__large"
					/>

					<Button
						label={
							isLoader ? (
								<Loader type="loader" className="loader-blue" dimension={20} />
							) : (
								'Add Card'
							)
						}
						type="button__filled button__filled--primary button__large"
						disabled={manageBtnDisabled}
					/>
				</div>
			</form>
		</div>
	);
};
