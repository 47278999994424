import { useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { PipelineMasterSteps, isChecqueFraudSelected } from 'global-stores';
import { CurrentStepState } from '../../../store';

export const PublishLeftView = () => {
	const masterSteps = useRecoilValue(PipelineMasterSteps);
	const [renderedObj, setRenderedObj] = useRecoilState(CurrentStepState);
	const isChequeFraudSelected = useRecoilValue(isChecqueFraudSelected);

	const handleSelectStep = useCallback(
		(act: any) => {
			setRenderedObj(act);
		},
		[setRenderedObj]
	);

	const publishActions = useMemo(
		() =>
			masterSteps
				?.find(step => step.key === 'publish')
				?.actions?.filter(el =>
					isChequeFraudSelected ? el.key !== 'publishAdditionalSetting' : true
				) ?? [],
		[masterSteps, isChequeFraudSelected]
	);

	return (
		<div className="OnboardingLeftNav--container">
			<div className="DeliveryLeftView__container">
				{publishActions.map(act => {
					return (
						<div
							key={act.label}
							className={`DeliveryLeftView__box ${
								renderedObj?.label === act.label &&
								'DeliveryLeftView__box-active'
							}`}
							onClick={() => handleSelectStep(act)}
						>
							<div
								className={
									renderedObj?.label === act.label
										? 'DeliveryLeftView__icon-container'
										: 'DeliveryLeftView__selected'
								}
							>
								<i
									className={
										act.label === 'Additional Settings'
											? 'ri-list-settings-fill'
											: 'ri-information-fill'
									}
									style={{
										fontSize: 18,
										color:
											renderedObj?.label === act.label ? 'var(--color-primary-light)' : '#747e99',
									}}
								></i>
							</div>
							<div className='DeliveryLeftView__label-container'>
								<span
									className={
										renderedObj?.label === act.label
											? 'DeliveryLeftView__label-selected'
											: 'DeliveryLeftView__label'
									}
								>
									{act.label}
								</span>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};
