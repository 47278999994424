import { Suspense, useEffect, useMemo, useState } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { Loader } from '@storybook';
import { API_URL } from 'constant';
import {
	BusinessPaymentCardState,
	CardsListViewState,
	ClientsCredentialsState,
	FundsState,
	IframePropsState,
	IsBannerStatusState,
	IsKycLoading,
	IsOpenInIframeState,
	PaymentCardMethodsState,
	PipelineMasterSteps,
	SubscriptionPriceState,
	UserRoleState,
	UserSubscriptionsState,
	companyDetailsState,
	subscriptionServiceState,
	useFunds,
} from 'global-stores';
import { useNetwork } from 'hooks';
import { ROUTES } from 'routes';
import { useRoutesModule } from 'routes/all-routes-constant';
import { useDashboards } from 'views/dashboard-analytics';
import DashboardPage from 'views/dashboard-analytics/dashboard-page';
import { NewUserPopup } from 'views/new-user-popup';
import { useGeneralSettings } from 'views/settings';
import { Sidebar } from 'views/sidebar';
import { useSignedDocsInbox } from 'views/signed-doc-inbox';
import { UploadDocuments } from 'views/upload-documents';
import { Banner } from 'views/user-verification';
import { BookAppointment } from '../book-appointment';
import { Deals } from 'views/deals';
import { usePaymentCard, useSubscriptions, useUserRoles } from './hooks';
import { useDashboradhook } from './hooks/hook';
import './dashboard.scss';

export const RoutesChildren = () => {
	const { DASHBOARD, UPLODAD_DOCUMENTS, DEALS } = ROUTES;
	// global states
	const [clientsCredentials, setClientsCredentials] = useRecoilState(
		ClientsCredentialsState
	);
	const pipelineMasterSteps = useRecoilValue(PipelineMasterSteps);
	const [userRole, setUserRole] = useRecoilState(UserRoleState);
	const [cards, setCards] = useRecoilState(CardsListViewState);
	const bannerStatus = useRecoilValue(IsBannerStatusState);
	const {
		background: backgroundColor,
		webAdminType,
		sidebar,
	} = useRecoilValue(IframePropsState);
	const setAddress = useSetRecoilState(companyDetailsState);
	const setSubscription = useSetRecoilState(subscriptionServiceState);
	const setKYCLoading = useSetRecoilState(IsKycLoading);
	const paymentCardMethodsState = useRecoilValue(PaymentCardMethodsState);
	const userSubscriptions = useRecoilValue(UserSubscriptionsState);
	const isIframe = useRecoilValue(IsOpenInIframeState);
	const subscriptionPrice = useRecoilValue(SubscriptionPriceState);
	const fundsData = useRecoilValue(FundsState);
	const [businessCard, setBusinessCard] = useRecoilState(
		BusinessPaymentCardState
	);

	const [IsCredentials, setCredentials] = useState(true);
	// hooks
	const { pathname } = useLocation();
	const { getFunds, isLoaded: fundsLoaded } = useFunds();
	const { get: getCredentials } = useNetwork();

	const { getClientDetails, settingsLoaded } = useGeneralSettings();
	const { getPipelineInit, fetchGoogleFonts, getSubscriptionPrice } =
		useDashboradhook();
	const { get: getUserRolesDetails, data: userRolesDetails } = useNetwork();
	const { get: findBussiness } = useNetwork();
	const { get: getCardsDetails, data: cardsDetails } = useNetwork();

	const { getDashboards } = useDashboards();
	const { getSignedDocsInbox } = useSignedDocsInbox();
	const { getPaymentCardsApi } = usePaymentCard();
	const { initSubscriptions } = useSubscriptions();
	const { customUserTab } = useUserRoles();


	useEffect(() => {
		if (fundsData.length === 0) {
			getFunds();
			getDashboards();
			getSignedDocsInbox();
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (settingsLoaded) setKYCLoading(false);
	}, [setKYCLoading, settingsLoaded]);

	useEffect(() => {
		if (pipelineMasterSteps.length === 0) {
			getPipelineInit();
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		//@avinash : call getClientDetails if not webcomponent called because it already called in root for webcomponent
		if (!isIframe) {
			getClientDetails();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isIframe]);

	const initGetCredentials = async () => {
		setCredentials(true);
		const resp = await getCredentials(`${API_URL.CREDENTIAL}`);
		if (resp?.message?.toLowerCase() === 'ok') {
			const { data = [] } = resp;
           const sortedData = data.sort((a: { createdAt: number; }, b: { createdAt: number; }) => a.createdAt - b.createdAt);
			setClientsCredentials(sortedData);
		}
		setCredentials(false);
	};

	useEffect(() => {
		//clients credentials get api
		if (clientsCredentials.length === 0) {
			initGetCredentials();
		}
		//clients user invite get api
		if (userRole.length === 0) {
			getUserRolesDetails(`${API_URL.BUSINESSES}`);
		}
		//google-fonts get api
		fetchGoogleFonts();
		// added cards
		if (cards.length === 0) {
			getCardsDetails(API_URL.BUSINESS_CARD);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// this will set the cards list in global state
	useEffect(() => {
		if (cardsDetails?.data) {
			setCards(cardsDetails.data);
		}
		// eslint-disable-next-line
	}, [cardsDetails]);

	useEffect(() => {
		const bussinessId = userRolesDetails?.data?.[0]?.business?._id;
		if (bussinessId) {
			findBussiness(`${API_URL.BUSINESSES}/${bussinessId}`).then(resp => {
				const {
					address,
					name,
					linkedInUrl,
					billingType,
					subscription,
					billingAddress,
				} = resp?.data ?? {};
				if (billingAddress) {
					setBusinessCard({ ...businessCard, billingAddress });
				}
				if (address) {
					setAddress(prev => ({
						...prev,
						address,
						billingAddress,
						name,
						linkedInUrl,
						billingType,
					}));
				}
				setSubscription(structuredClone(subscription));
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		findBussiness,
		setAddress,
		setBusinessCard,
		setSubscription,
		userRolesDetails?.data,
	]);

	useEffect(() => {
		if (userRolesDetails) {
			const { data } = userRolesDetails ?? {};
			if (data) {
				setUserRole(data);
			}
		}
		// eslint-disable-next-line
	}, [userRolesDetails]);

	useEffect(() => {
		if (!paymentCardMethodsState) getPaymentCardsApi();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!userSubscriptions.length) initSubscriptions();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!subscriptionPrice) getSubscriptionPrice();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Awadhesh - pass props data
	const { ALL_ROUTES_MODULE } = useRoutesModule({
		fundsLoaded,
		IsCredentials,
	});

	const getCustomRoutes = useMemo(() => {
		const allFilteredRoutes = [];
		for (let i = 0; i < customUserTab?.length; i++) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-ignore
			const isValidPath = ALL_ROUTES_MODULE[customUserTab?.[i]];
			if (isValidPath) {
				allFilteredRoutes.push(isValidPath);
			}
		}
		if (!customUserTab.includes('dashboard') && customUserTab?.length > 0) {
			// pradeep removing unwanted tabs inside the permissions
			const itemsToRemove = ['liveMode', 'userProfile', 'demo'];
			const updatePermissions = customUserTab.filter(
				item => !itemsToRemove.includes(item)
			);
			const tabs = (ALL_ROUTES_MODULE as any)[updatePermissions?.[0] as string];
			allFilteredRoutes.push({
				path: '*',
				element: <Navigate to={tabs.path} />,
			});
		}

		// Common routes  no need to Permission for that
		allFilteredRoutes.push(
			{ path: DEALS, element: <Deals /> },
			{ path: UPLODAD_DOCUMENTS, element: <UploadDocuments /> },
			{ path: '*', element: <Navigate to={DASHBOARD} /> }
		);

		return allFilteredRoutes.length
			? allFilteredRoutes
			: [{ path: DASHBOARD, element: <DashboardPage /> }];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customUserTab, fundsLoaded, IsCredentials]);

	const isIntegratePage = useMemo(() => {
		return /integrate|flow|files-documents/gi.test(pathname);
	}, [pathname]);

	const isSidebarPermission = useMemo(
		() =>
			!isIntegratePage &&
			(webAdminType === 'onboarded' || sidebar
				? true
				: isIframe
				? false
				: true),
		[isIframe, isIntegratePage, sidebar, webAdminType]
	);

	return (
		<div className="dashboard-container">
			{bannerStatus === false && <Banner />}
			<div
				className="dashboard-body"
				style={{
					backgroundColor,
					height: bannerStatus === false ? 'calc(100vh - 48px)' : '100vh',
				}}
			>
				<Suspense fallback={<Loader type="loader" dimension={20} />}>
					<Sidebar
						brandLoaded={settingsLoaded}
						isShowSidebar={isSidebarPermission}
					>
						{useRoutes(getCustomRoutes)}
					</Sidebar>
				</Suspense>
			</div>
			<BookAppointment />
			<NewUserPopup />
		</div>
	);
};
