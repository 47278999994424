import { actionKeyLabel } from 'views/pipelines';

interface IStatus {
	className?: string;
	title: string;
}
export const Status = ({ className, title }: IStatus) => {
	if (!title) {
		return <></>;
	}
	return (
		<div
			className={`status__clip statusbg__${className?.toLocaleLowerCase()} statustct__${title}`}
		>
			<svg
				className="leftImg"
				width="12"
				viewBox="-1.4 -4 8 28"
				fill="none"
				xmlns="http:www.w3.org/2000/svg"
			>
				<path d="M0 0H6V9.16667V20H0L6 9.16667L0 0Z" />
			</svg>

			<div className="inner__status">{actionKeyLabel[title]}</div>
			<svg
				width="12"
				viewBox="-0.6 -4 8 28"
				fill="none"
				xmlns="http:www.w3.org/2000/svg"
			>
				<path d="M0 0L6 9.16667L0 20V0Z" />
			</svg>
		</div>
	);
};
