import { FC, MouseEvent, useCallback, useMemo, useState } from 'react';
import Tippy from '@tippyjs/react';

import './drop-option.scss';

interface IDropOption {
	options: {
		title: string | JSX.Element;
		icon?: string;
		handleOption: () => void;
		disabled?: boolean;
		toolTip?: string;
	}[];
	menuIcon?: string;
}

export const DropOption: FC<IDropOption> = ({ options = [], menuIcon }) => {
	//local state for show drop options
	const [showMenu, setShowMenu] = useState<boolean>(false);

	const handleClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
		e?.stopPropagation();
		setShowMenu(pre => !pre);
	}, []);

	const renderMenu = useMemo(
		() =>
			showMenu && (
				<div onClick={e => e.stopPropagation()} className="DropOption__list">
					{options.map(
						({ title, icon, disabled = false, toolTip, handleOption }) => (
							<Tippy
								key={title?.toString()}
								disabled={!toolTip}
								content={toolTip}
							>
								<div
									onClick={() => {
										if (!disabled) {
											setShowMenu(false);
											handleOption();
										}
									}}
									className={`DropOption__list-item ${
										disabled ? 'button--disabled' : ''
									}`}
								>
									{icon && <i className={`${icon} DropOption__item-icon`} />}
									{title}
								</div>
							</Tippy>
						)
					)}
				</div>
			),
		[options, showMenu]
	);

	return (
		<>
			<div
				tabIndex={0}
				onBlur={() => setShowMenu(false)}
				className="DropOption"
			>
				<div onClick={handleClick} className="DropOption__menu-icon">
					<i className={menuIcon || 'ri-more-2-fill'} />
				</div>
				{renderMenu}
			</div>
		</>
	);
};
