import { SetStateAction, useCallback, useState } from 'react';
import './country-modal.scss';
import { IOption, Input } from '@storybook';

type CountryDropdownModalProps = {
	isOpen: boolean;
	onClose: (e: any) => void;
	handleChangeSelect: (event: IOption, key: string) => void;
	value?: IOption;
	type: string;
	defaultOperand:string;
	options: Array<any>;
};
export const CountryDropdownModal = ({
	isOpen,
	onClose,
	handleChangeSelect,
	type,
	value,
	options
}: CountryDropdownModalProps) => {
	const [search, setSearch] = useState('');
	const filteredCountries = options.filter(country =>
		country.label.toLowerCase().startsWith(search.toLowerCase())
	);

	const onSelectCountry = useCallback((e: any, options: IOption) => {
			handleChangeSelect(options, type);
			onClose(e);
		
	}, [handleChangeSelect, type, onClose]);

      const handleChange = useCallback((e: { target: { value: SetStateAction<string>; }; }) => {
			setSearch(e.target.value);
		}, []);

		const handleInputClick = useCallback((e: any) => {
			e.stopPropagation()
		},[])

	return (
		<>	
			
			{isOpen && (
				<div className="CountryDropdownModal--container nowheel">
					{!options.length && <div className='no-data-found'>No Data Found...</div>}
					{!!options.length && <div className="popup-input-wrapper">
						<Input
							inputType="text"
							handleInputClick={handleInputClick}
							handleChange={handleChange}
							label=""
							value={search}
							placeholder="Search..."
							suffixIcon="ri-search-line"
						/>
					</div>}

					<ul className="CountryDropdownModal--container__country-list">
						{filteredCountries.map(country => (
							<li
								key={country.value}
								onClick={(e) => onSelectCountry(e,country)}
								value={value}
							>
								{country.label}
							</li>
						))}
					</ul>
				</div>
			)}
		</>
	)};
