import { Button, Loader } from '@storybook';
import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { Message } from 'components';
import {
	ComplexSessionInfoState,
	ComplexSessionNodesState,
	ConditionalSessionFlowState,
	SelectedSessionsIdState,
	SessionItemSelected,
} from 'global-stores';
import {
	CheckFraudActivityTabs,
	POTENTIAL_RISK_NOTES,
} from 'views/cheque-fraud/constants';
import {
	CheckDetailsFormState,
	IChequeFraudDetails,
	IModal,
	SelectedChequeFraudItem,
	SelectedChequeFraudTransactionsListState,
	useCheckDefence,
} from 'views/cheque-fraud/stores';
import { ActivityLog, useComplexSessionInfo } from 'views/sessions';
import { ComplexSessionView } from 'views/sessions/components/complex-session-view';
import { BiometricDetails } from '../biometric-details';
import { CurrentTransactionHeader } from '../current-transaction-header';

import { PERMISSION_SCREEN, useUserRoles } from 'views/routes-children';

import './check-fraud-details.scss';

interface ICheckFraudDetails {
	handleModal: (e: IModal) => void;
	handleClose: () => void;
}

export const CheckFraudDetails: FC<ICheckFraudDetails> = ({
	handleModal,
	handleClose,
}) => {
	const setSessionItemSelected = useSetRecoilState(SessionItemSelected);
	const [loading, setLoading] = useState(false);

	const setCheckDetailsForm = useSetRecoilState(CheckDetailsFormState);
	const setComplexSessionNodes = useSetRecoilState(ComplexSessionNodesState);
	const setSelectedIdState = useSetRecoilState(SelectedSessionsIdState);
	const { complexSessionInfo, complexSessionDetailsLoading } =
		useComplexSessionInfo();
	const setIsComplexFlow = useSetRecoilState(ConditionalSessionFlowState);
	const resetIsComplexFlow = useResetRecoilState(ConditionalSessionFlowState);
	const selectedCheckFraudItem = useRecoilValue(SelectedChequeFraudItem);
	const setComplexSessionInfoState = useSetRecoilState(ComplexSessionInfoState);

	const {
		sessionId = '',
		userName,
		potentialRisk,
		isAlreadyPaid,
		status,
	} = useMemo(() => selectedCheckFraudItem, [selectedCheckFraudItem]);

	const { LABEL, SUB_LABEL } = POTENTIAL_RISK_NOTES;

	const [isKycDetailsPage, setIsKycDetailsPage] = useState(false);
	const { fetchCheckFraudDetails } = useCheckDefence();

	const { checkUserWritePermission } = useUserRoles();

	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.CheckDefense),
		[checkUserWritePermission]
	);

	useEffect(() => {
		setIsComplexFlow(true);
		return () => {
			resetIsComplexFlow();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);



	const getCheckFraudDetails = async () => {
		if (sessionId) {
			setLoading(true);
			await fetchCheckFraudDetails(sessionId);
			setLoading(false);
			setSessionItemSelected({
				_id: sessionId,
				type: 'complex',
			});
		}
	};
	useEffect(() => {
		getCheckFraudDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleOnClick = useCallback((type: 'proceed' | 'deny') => {
		setCheckDetailsForm((prev: any) => ({ ...prev, status: type }));
		handleModal('checkFraudform');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleView = useCallback(
		async (data: Partial<IChequeFraudDetails>) => {
			setComplexSessionInfoState((prev: any) => ({ ...prev, ...data }));

			const allNodes = data?.nodes?.map(el => {
				return {
					...el,
					visited: true,
					session: sessionId,
				};
			});
			setComplexSessionNodes(allNodes ?? []);
			setIsKycDetailsPage(true);
			complexSessionInfo(sessionId);
			setIsComplexFlow(true);
			setSessionItemSelected({
				...data,
				type: 'complex',
				name: userName ?? '',
				_id: sessionId,
			});
			setSelectedIdState([sessionId]);
		},

		[
			sessionId,
			userName,
			setComplexSessionInfoState,
			setComplexSessionNodes,
			complexSessionInfo,
			setIsComplexFlow,
			setSessionItemSelected,
			setSelectedIdState,
		]
	);

	const transactionLists = useRecoilValue(
		SelectedChequeFraudTransactionsListState
	);

	const { currentStep, allTabs } = useMemo(() => {
		const isFraudList = transactionLists?.find(el => el.isFraud);
		const tabs = [...CheckFraudActivityTabs];
		if (!transactionLists.length) {
			return {
				currentStep: 'all-transactions',
				allTabs: tabs.reverse(),
			};
		} else if (isFraudList) {
			return {
				currentStep: 'fraudulent-transactions',
				allTabs: tabs,
			};
		} else {
			return {
				currentStep: 'all-transactions',
				allTabs: tabs.reverse(),
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [transactionLists]);

	return isKycDetailsPage ? (
		<ComplexSessionView
			isLoading={complexSessionDetailsLoading}
			isTransactionPage
			isShowTreeFlow={false}
			isChequeFraud={true}
			handleBackModal={() => setIsKycDetailsPage(false)}
		/>
	) : loading ? (
		<div className="loader-center">
			<Loader />
		</div>
	) : (
		<div className="view-kyc-modal__inner">
			<div className="view-kyc-modal--content">
				<CurrentTransactionHeader
					handleMarkFraud={(e: IModal) => handleModal(e)}
					isAlreadyPaid={isAlreadyPaid}
					status={status}
				/>
				{potentialRisk && (
					<Message
						text={
							<Fragment>
								<div className="note-label">{LABEL}</div>
								<div className="note-sublabel">{SUB_LABEL}</div>
							</Fragment>
						}
						varient={'error'}
					/>
				)}

				<div
					className={`cf-details ${potentialRisk ? 'cf-details-potentialRisk' : ''
						}`}
				>
					<div className="cf-details__left">
						<div className="cf-details__left__inner">
							<BiometricDetails fetchSessionDetails={handleView} />
						</div>
					</div>
					<div className="cf-details__right">
						<ActivityLog
							isLoading={false}
							dataLoaded={true}
							chequeFraudPage
							activeTab={currentStep}
							customTabs={allTabs}
						/>
					</div>
				</div>
			</div>
			<div className="view-kyc-modal__btn-wrapper">
				{isUserPermissionWrite && !isAlreadyPaid ? (
					<>
						<Button
							label="Deny"
							type={
								'button button__filled--danger view-kyc-modal--danger-button '
							}
							handleClick={() => handleOnClick('deny')}
							disabled={isAlreadyPaid}
						/>
						<Button
							label={'Proceed'}
							handleClick={() => handleOnClick('proceed')}
							type={
								'button button__filled button__filled--primary view-kyc-modal--proceed-btn '
							}
							disabled={isAlreadyPaid}
						/>
					</>
				) : (
					<Button
						label={'Close'}
						type={'button button__filled--secondary '}
						handleClick={() => handleClose()}
					/>
				)}
			</div>
		</div>
	);
};
