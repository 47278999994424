import { ChangeEvent, FC, useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { TextArea } from '@storybook';

import { PipelineSettingsFormState } from 'global-stores';

interface ITextAreaComponent {
	label: string;
	name: string;
	defaultValue?: string;
	disable?:boolean;
	placeholder?: string;
}

export const TextAreaComponent: FC<ITextAreaComponent> = ({
	label,
	name,
	disable=false,
	placeholder
}) => {
	const [settingsForm, setSettingsForm] = useRecoilState(
		PipelineSettingsFormState
	);

	const handleInputChange = useCallback(
		(e: ChangeEvent<HTMLTextAreaElement>) => {
			const { value } = e.target;
			setSettingsForm(prev => {
				const prevState = { ...prev };
				prevState[name] = value;
				return { ...prevState };
			});
		},
		[name, setSettingsForm]
	);

	return (
		<TextArea
			label={label}
			value={settingsForm[name] as string}
			handleChange={handleInputChange}
			placeholder={placeholder as string}
			disable={disable}
		/>
	);
};
