export enum ErrorMsg {
	FormError = 'Atleast 1 question is required',
	FormInvestorDetailError = 'Can not have 2 Shareholders details question',
	EmptyInput = 'Please add template name',
	Duplicate = 'Template name already exists',
	DuplicateQuestions = 'Duplicate questions found! Please Update or Delete the duplicate questions',
}

export const MESSAGES = {
	MODIFY_SUCCESS: 'Complex flow Onboarding flow updated successfully',
	CLONED_SUCCESS: 'Complex flow Onboarding flow cloned successfully',
	SUCCESS: 'Complex Onboarding Flow Created',
	ONBOARDING_SUCCESS: 'Onboarding Flow Created.',
	ONBOARDING_NAME: 'Onboarding with this name already exist',
};

export const NODES_MESSAGE = {
	validationFailed:
		'Validation failed. Please ensure all required fields are filled correctly.',
};

export const WEB_COMPONENT_TYPE = {
	OnboardingFlow: 'onboarding/flow'
}