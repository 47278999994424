import { Button } from '@storybook';
import { FC, MouseEventHandler } from 'react';
import { InviteOnboardingPaymentCurrentPage } from '../store';

type IMonthlyScreenObj = {
	[key in InviteOnboardingPaymentCurrentPage]: InviteOnboardingPaymentCurrentPage;
};

export const monthlyScreenObj: IMonthlyScreenObj = {
	monthly_billing_message: 'company_info',
	company_info: 'address_info',
	address_info: 'address_verification',
	address_verification: 'add_card',
	add_card: 'checkout',
	checkout: 'company_info',
};

interface IProps {
	primaryBtnText?: string | JSX.Element;
	primaryBtnCallback?: MouseEventHandler<HTMLButtonElement>;
	secondaryBtnText?: string;
	secondaryBtnCallback?: MouseEventHandler<HTMLButtonElement>;
	isPrimaryDisabled?: boolean;
	showPrimaryBtn?: boolean;
	showSecondaryBtn?: boolean;
}

export const OnboardingPaymentFooter: FC<IProps> = ({
	primaryBtnCallback,
	primaryBtnText = 'Next',
	secondaryBtnCallback,
	secondaryBtnText = 'Back',
	isPrimaryDisabled = false,
	showPrimaryBtn = true,
	showSecondaryBtn = true,
}) => {
	return (
		<div className="OnboardingPaymentFooter">
			{showSecondaryBtn && (
				<Button
					label={secondaryBtnText}
					type="button__filled button__filled--secondary button__large"
					handleClick={secondaryBtnCallback}
				/>
			)}
			{showPrimaryBtn && (
				<Button
					disabled={isPrimaryDisabled}
					handleClick={primaryBtnCallback}
					label={primaryBtnText}
					type="button__filled button__filled--primary button__large"
				/>
			)}
		</div>
	);
};
