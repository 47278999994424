import { NoDataAvailable, ReactResponsiveTable } from '@storybook';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';

import {
	FundInvestors,
	FundPaginationState,
	FundsState,
	FundsToShowState,
	InviteNavigateModalState,
	IsCreatingFundState,
	IShowFunds,
	OpenInviteSelectorModal,
	PageCountState,
	TempFundListState,
	useFunds,
} from 'global-stores';
import { useTrackEvents } from 'helpers';
import { ROUTES } from 'routes';
import { useFormatNumber } from 'utils';
import { Header } from 'views/header';
import { OnboardingInviteModal } from 'views/pipelines/components/onboarding-invite-modal';
import { PERMISSION_SCREEN, useUserRoles } from 'views/routes-children';
import { fundsColumn } from './constants';
import './funds.scss';

interface IFundsD {
	loaded: boolean;
}
export const Funds: FC<IFundsD> = () => {
	const funds = useRecoilValue(FundsState);
	const fundsStateList  = useRecoilValue(TempFundListState);

	const setIsModal = useSetRecoilState(OpenInviteSelectorModal);
	const setNavigation = useSetRecoilState(InviteNavigateModalState);
	const setIsNewFund = useSetRecoilState(IsCreatingFundState);
	const [fundsRow, setFundsRow] = useRecoilState(FundsToShowState);
	const setInvestors = useSetRecoilState(FundInvestors);
	const { numberDecimal } = useFormatNumber();
	const navigate = useNavigate();
	const { trackEvents } = useTrackEvents();
	const { getFunds, isLoaded, pageCounter } = useFunds();
	const { FUNDS } = ROUTES;

	const { checkUserWritePermission } = useUserRoles();
	const pageNumber = useRecoilValue(FundPaginationState);
	const [searchPerformed, setSearchPerformed] = useState(false);
	const [fundsSearchText, setFundsSearchText] = useState('');
	const resetPageCount = useResetRecoilState(PageCountState);
	const restPageNumber = useResetRecoilState(FundPaginationState);

	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.Funds),
		[checkUserWritePermission]
	);

	const handleOpenDetails = useCallback((fundItem: any) => {
		const { investor, pending, id } = fundItem ?? {};

		if (id) {
			setInvestors({
				investors: investor,
				pendingInvestors: pending,
			});
			navigate(`${FUNDS}/${id}`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handlePagination = useCallback(() => {
		if (fundsSearchText) {
			getFunds(fundsSearchText.trim(), true);
			return;
		}
		if (!fundsSearchText) {
			getFunds();
			return;
		}
	}, [fundsSearchText, getFunds]);

	useEffect(() => {
		if (pageNumber !== 1) {
			restPageNumber();
			resetPageCount();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fundsSearchText]);

	useEffect(() => {
		const rows: IShowFunds[] = [];
		funds.forEach((fund: any) => {
			if (fund) {
				let row: any = {};
				fundsColumn.forEach(({ key, format, key2 }: any) => {
					row.id = fund.id;
					if (
						// eslint-disable-next-line no-constant-condition
						key === 'totalMoneyRaised' ||
						key === 'postMoneyValuation' ||
						'moneyRaised'
					) {
						row = {
							...row,
							[key]: `${fund[key] ?? 0}`,
						};
					} else {
						row = {
							...row,
							[key]: fund[key] ?? 0,
						};
					}
					if (format === 'jsx' && key === 'moneyRaised') {
						const value = () => (
							<div className="funds-multiline-column">
								{`$${numberDecimal(Number(fund[key]))}`}
								{key2 ? (
									<div className="subText">
										<span className="subText-line" />
										<span>{fund[key2]}</span>
									</div>
								) : (
									'--'
								)}
							</div>
						);

						row = {
							...row,
							[key]: value,
							raised: fund[key] ?? 0,
							round: fund[key2] ?? 0,
						};
					}
				});
				rows.push(row);
			}
		});
		return setFundsRow(rows);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [funds]);

	const searchFunds = (searchFunds: string) => {
		setFundsSearchText(searchFunds);
		getFunds(searchFunds.trim());
		setSearchPerformed(true);
	};

	const handleCreateFund = useCallback(() => {
		trackEvents('create-new-fund', {});
		setIsModal(true);
		setIsNewFund(true);
		setNavigation('create');
	}, [setIsModal, setIsNewFund, setNavigation, trackEvents]);

	const renderFundsTable = useMemo(() => {
		if (fundsStateList?.length === 0 && !fundsRow.length && isLoaded && !fundsSearchText) {
			return (
				<NoDataAvailable
					label="Create New"
					description={
						searchPerformed
							? ''
							: 'You have not created any funds or company yet.'
					}
					handleClickBtn={handleCreateFund}
					message={
						searchPerformed
							? 'No matching Funds/Company Found'
							: 'No Funds/Company Listed'
					}
					illustration="empty-funds.svg"
					hideBtn={!isUserPermissionWrite}
				/>
			);
		}
		return (
			<>
				<ReactResponsiveTable
					isLoading={!isLoaded}
					isLoaded={isLoaded}
					column={fundsColumn}
					rows={fundsRow}
					className=""
					showSearch={true}
					height="calc(100vh - 200px)"
					emptyHeight={'calc(100vh - 187px)'}
					columnHandle={true}
					handelRowClick={handleOpenDetails}
					handlePagination={handlePagination}
					handleInputSearch={searchFunds}
					isPagination
					totaloPageCount={pageCounter}
					EmptyIllustration="empty-funds.svg"
					EmptyMessageHeading="No matching Funds/Company Found"
					EmptyMessageDescription="You have not created any funds or company yet."
				/>
			</>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		fundsStateList,
		fundsRow,
		handleOpenDetails,
		handleCreateFund,
		isUserPermissionWrite,
		isLoaded,
	]);

	return (
		<div className="dashboard-main-body">
			<Header
				title="Funds"
				showBtn={fundsRow.length > 0}
				// showBtn={userData.business?.plan === "PAID" && !!fundsRow.length}
				btnLabel="Create New"
				handleClickBtn={handleCreateFund}
			/>
			<div className="funds-container">{renderFundsTable}</div>
			<OnboardingInviteModal from={'funds'} />
		</div>
	);
};

export default Funds;
