/* eslint-disable no-mixed-spaces-and-tabs */
import { Loader } from '@storybook';
import { envUrl } from 'helpers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'routes';
import {
	ActiveTabsIntegrateState,
	ClientsCredentialsState,
	SelectedTabState,
	WebColorProperties,
} from 'global-stores';

import { ServiceProviderCodes } from '../code-space/codes';
import './iframe-space.scss';

export const IframeSpace = () => {
	const navigate = useNavigate();
	const selectedTab = useRecoilValue(SelectedTabState);
	const clientsCredentials = useRecoilValue(ClientsCredentialsState);
	const webProperties = useRecoilValue(WebColorProperties);
	const activeElement = useRecoilValue(ActiveTabsIntegrateState);

	const data: { [key: string]: any } = useMemo(
		() => ({
			clientId: clientsCredentials?.[0]?.clientId,
			clientSecret: clientsCredentials?.[0]?.clientSecret,
			background: webProperties.background,
			theadColor: webProperties.tableHeadColor,
			isSearch: webProperties.isSearch,
			btnPrimary: webProperties.btnPrimary,
			btnSecondary: webProperties.btnSecondary,
			notification: webProperties.notification,
			sidebar: webProperties.sidebar,
		}),
		[
			clientsCredentials,
			webProperties.background,
			webProperties.btnPrimary,
			webProperties.btnSecondary,
			webProperties.isSearch,
			webProperties.notification,
			webProperties.sidebar,
			webProperties.tableHeadColor,
		]
	);

	const params = new URLSearchParams(data);

	const url = `${envUrl}${selectedTab}?${params}`;

	const replaceVarWithValue = useMemo(() => {
		return {
			'{type}': selectedTab,
			'{bgcolor}': webProperties.background,
			'{search}': webProperties.isSearch,
			'{theadcolor}': webProperties.tableHeadColor,
			'{clientid}': clientsCredentials?.[0]?.clientId ?? '',
			'{clientsecret}': clientsCredentials?.[0]?.clientSecret ?? '',
			'{btnprimary}': webProperties.btnPrimary,
			'{btnsecondary}': webProperties.btnSecondary,
			'{notification}': webProperties.notification,
			'{sidebar}': webProperties.sidebar,
			'{isInsideAdmin}': `isInsideAdmin = "true"`,
			'{script}': '',
		};
	}, [
		selectedTab,
		webProperties.background,
		webProperties.isSearch,
		webProperties.tableHeadColor,
		webProperties.btnPrimary,
		webProperties.btnSecondary,
		webProperties.notification,
		webProperties.sidebar,
		clientsCredentials,
	]);

	const matchVariable = useMemo(
		() => new RegExp(Object.keys(replaceVarWithValue).join('|'), 'gi'),
		[replaceVarWithValue]
	);
	const [isLoading, setIsLoading] = useState(true);
	const [customLoader, setCustomLoader] = useState(true);
	const handleOnLoadIframe = useCallback(() => {
		setIsLoading(false);
	}, []);

	const renderIframe = useMemo(() => {
		return (
			<>
				{isLoading && (
					<div
						style={{
							background: '#fff',
							height: '50%',
							display: 'grid',
							placeItems: 'center',
						}}
					>
						<Loader dimension={54} className="loader-blue" />
					</div>
				)}
				{clientsCredentials?.[0]?.clientId &&
					clientsCredentials?.[0]?.clientSecret && (
						<iframe
							src={url}
							onLoad={handleOnLoadIframe}
							title="websites"
							style={{ width: '100%', height: '97%', border: 0 }}
						/>
					)}
			</>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clientsCredentials, isLoading, url]);

	const __htmlContent: string = useMemo(
		() =>
			activeElement !== 'iframe'
				? ServiceProviderCodes.webComponent?.replace(
						matchVariable,
						function (data: any) {
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							//@ts-ignore
							return replaceVarWithValue[data];
						}
				  )
				: 'Does not Exist',
		[activeElement, matchVariable, replaceVarWithValue]
	);

	const web = useMemo(() => {
		return (
			<div
				className="Integrate--webcomponent"
				style={{
					height: '100%',
					width: '100%',
					visibility: `${customLoader ? 'hidden' : 'visible'}`,
					maxHeight: '100%',
					overflow: 'auto',
				}}
			>
				{clientsCredentials?.[0]?.clientId &&
					clientsCredentials?.[0]?.clientSecret && (
						<div dangerouslySetInnerHTML={{ __html: __htmlContent }} />
					)}
			</div>
		);
	}, [__htmlContent, clientsCredentials, customLoader]);

	useEffect(() => {
		window.onpopstate = () => {
			if (/integrate/gi.test(document.location.toString())) {
				navigate(ROUTES.PIPELINE);
			}
		};
	});

	useEffect(() => {
		setTimeout(() => {
			setCustomLoader(false);
		}, 3000);
	}, []);

	return (
		<div className="iframe-container iframe-playground-container">
			<div className="iframe-container__wrapper">
				{customLoader ? (
					<Loader dimension={54} className="loader-blue" />
				) : null}
				{activeElement !== 'iframe' ? renderIframe : web}
			</div>
		</div>
	);
};
