import { FC, ReactNode, useEffect, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { PipelineMasterSteps } from 'global-stores';
import { CustomHandle } from '../custom-handle';
import { ICustomNode } from '../custom-node';
import { RenderAmlBlock } from './renderl-aml-block';

import './aml-action.scss';
import { ActionList } from 'views/complex-onboarding-flow/constant';
import { ComplexSettingFormState } from 'views/pipeline';

const StepIds = ['Verify information about a business entity'];

export const AmlWrapper = ({ children }: { children: ReactNode }) => {
	return <div className="aml-action">{children}</div>;
};

export const AmlAction: FC<ICustomNode> = ({ id: nodeId, data }) => {
	const pipelinesSteps = useRecoilValue(PipelineMasterSteps);
	const setComplexSettingForm = useSetRecoilState(ComplexSettingFormState);

	const actionId = useMemo(() => data.label, [data.label]);

	const actions = useMemo(
		() =>
			pipelinesSteps.find(
				pipelineStep => pipelineStep.key === (ActionList as any)[actionId]
			)?.actions[0],
		[actionId, pipelinesSteps]
	);
	//Shahbaaz Set aml actions in complex config setting form for showing price breakdown
	useEffect(() => {
		setComplexSettingForm((prev: any) => {
			const newObj = structuredClone(prev);
			if (!newObj[nodeId]) {
				newObj[nodeId] = {};
			}
			Object.assign(newObj[nodeId], {
				amlVerification: 'AML',
			});

			return newObj;
		});
	}, [nodeId, setComplexSettingForm]);

	const renderActionLayout = useMemo(
		() =>
			StepIds.map((el: string) => (
				<RenderAmlBlock
					stepId="aml"
					label={actions?.label}
					key={el}
					price={actions?.price}
					nodeId={nodeId}
				/>
			)),
		[nodeId, actions]
	);

	return (
		<AmlWrapper>
			{renderActionLayout}
			<CustomHandle value={nodeId} />
		</AmlWrapper>
	);
};
