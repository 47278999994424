import { FC, Fragment, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { loginState } from 'global-stores';
import { SessionDetailsHeading } from '../session-details-heading';
import { DocCardItem } from './components';
import { ActionButton } from '../action';

export type IDocStatus = 'pending' | 'approved' | 'rejected';
export interface IDoc {
	docType: string;
	docUrl: string;
	status: IDocStatus;
	itemKey: string;
}

export interface IAction {
	entity: string;
	questionKey: string;
	doc: IDoc[];
}

export interface ISessionKybForm {
	data: {
		conditions: any[];
		action: IAction[];
	};
	status: string;
	nodeId?: string;
	isUserPermissionWrite?: boolean;
}

export const SessionKYBForm: FC<ISessionKybForm> = ({
	data,
	status,
	nodeId,
	isUserPermissionWrite = true,
}) => {
	const { role, isAgentPermitted } = useRecoilValue(loginState);
	const renderKYBDocuments = useMemo(() => {
		const action: any = data?.action?.[0] ?? {};
		return Object.keys(action).map((actionKey: any) => {
			const actionValue: any = action[actionKey];
			if (actionKey?.toLowerCase() === 'questionkey') {
				return <Fragment key={actionKey} />;
			}
			if (typeof actionValue === 'object') {
				return actionValue.map((doc: any) => (
					<Fragment key={doc?.docType ?? ''}>
						<DocCardItem
							docItem={doc ?? {}}
							question={action.questionKey}
							nodeId={nodeId ?? ''}
							isUserPermissionWrite={isUserPermissionWrite}
						/>
					</Fragment>
				));
			}
			return (
				<Fragment key={actionKey}>
					<div className="kycDetail-row">
						<div className="kyc-report-label">
							<div className="kyc-report-label__key">{actionKey}</div>
						</div>
						<div className="kycDetail-row__basicInfoContainer">
							<div>:</div>
							<div className="kyc-report-label-kycValue">{actionValue}</div>
						</div>
					</div>
				</Fragment>
			);
		});
	}, [data?.action, nodeId, isUserPermissionWrite]);

	return (
		<div className="SessionKYBForm">
		<div className="kycDetails-contain">
			<SessionDetailsHeading
				label="KYB Form"
				icon={
					<i className="ri-user-search-fill form-icon-profile-container__formIcon" />
				}
			>
				<div className="action-btn">
					{role === 'AGENT' && !isAgentPermitted ? (
						<div className="action-btn__agent">{status}</div>
					) : (
						<ActionButton
							status={status}
							type="kybForm"
							isTransactionPage={false}
							nodeId={nodeId}
							isDisabled={!isUserPermissionWrite}
						/>
					)}
				</div>
			</SessionDetailsHeading>
			<div className="kycDetails-body">
				<Fragment>
					<div className="kycDetails-info__documents">
						<div className="kycDetails-info__documents_inner">
							<div className="kycDetails-info__documents_inner_title">
								KYB Form
							</div>
						</div>
						<div className="kycDetails-info__lines" />
					</div>
					<div className="kycDetails-info__documentView kycDetails-info__documentView--kyb-sessions-detail">
						{renderKYBDocuments}
					</div>
				</Fragment>
			</div>
		</div>
		</div>
	);
};
