import { Image } from '@storybook';
import { API_URL } from 'constant';
import { useNetwork } from 'hooks';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

interface IUserAvatar {
	id: string;
}
export const UserAvatar: FC<IUserAvatar> = ({ id }) => {
	const [pic, setPic] = useState(null);

	const { get } = useNetwork({ updateState: false });

	const getProfile = useCallback(async () => {
		const resp = await get(`${API_URL.MEDIA}/${id}?resource=profilePhoto`);
		if (resp?.message?.toLowerCase() === 'ok') {
			const { data } = resp;
			setPic(data);
		}
	}, [get, id]);

	const showUserProfileImage = useMemo(() => {
		return pic ? { url: pic } : { fileName: 'AvatarDefault-Light.svg' };
	}, [pic]);

	useEffect(() => {
		getProfile();
	}, [getProfile, id]);

	return (
		<>
			{
				<Image
					{...showUserProfileImage}
					className="overlap-image"
					height="40px"
					width="40px"
				/>
			}
		</>
	);
};
