import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { Button, Loader, ReactResponsiveTable, TextArea } from '@storybook';

import { UploadDocs } from 'components';
import { CHEQUE_FRAUD_FILE_HEADER } from 'views/cheque-fraud/constants';
import { convertFileToBase64, formatSizeUnits } from 'utils';
import {
	IModal,
	IUploadedDocs,
	IsMarkAsFraudSelectedByDetails,
	SelectedChequeFraudItem,
	useCheckDefence,
} from 'views/cheque-fraud/stores';
import { useNotification } from 'hooks';

import './revert-report-fraud.scss';

interface IRevertReportFraud {
	handleClose: (arg0: IModal) => void;
	onComplete?: () => void;
}
export const RevertReportFraud = ({
	handleClose,
	onComplete,
}: IRevertReportFraud) => {
	const [fraudContent, setFraudContent] = useState('');
	const [uploadedDocus, setUploadedDocs] = useState<IUploadedDocs[]>([]);
	const [loading, setLoading] = useState(false);

	const selectedChequeFraudItem = useRecoilValue(SelectedChequeFraudItem);
	const { errorNotification } = useNotification();

	const isMarkAsFraudSelectedByDetails = useRecoilValue(
		IsMarkAsFraudSelectedByDetails
	);

	const resetIsMarkAsFraudSelectedByDetails = useResetRecoilState(
		IsMarkAsFraudSelectedByDetails
	);

	const { unMarkCheckFraud } = useCheckDefence();

	const handleSubmit = async () => {
		setLoading(true);
		const allDocuments = uploadedDocus?.map(el => {
			return {
				type: el.type,
				file: el.file,
				name: el.name,
			};
		});
		await unMarkCheckFraud(
			selectedChequeFraudItem.sessionId ?? '',
			fraudContent,
			allDocuments,
			() => {
				handleClose(isMarkAsFraudSelectedByDetails ? 'viewDetails' : '');
				onComplete?.();
			}
		);
		setLoading(false);
	};

	const handleChange = useCallback(async (event: File) => {
		const base64 = (await convertFileToBase64(event)) ?? '';
		if (!base64) {
			errorNotification('Something went wrong');
		}
		const payload: IUploadedDocs = {
			name: event.name,
			type: event.type,
			file: base64 as string,
			size: event.size,
		};

		setUploadedDocs(prev => {
			const newArr = structuredClone(prev);
			newArr.push(payload);
			return newArr;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleDeleteDocs = (index: number) => {
		setUploadedDocs(prev => {
			const newArr = structuredClone(prev);
			newArr.splice(index, 1);
			return newArr;
		});
	};

	const getAllDocus = useMemo(() => {
		if (uploadedDocus.length) {
			const rows: any = [];
			uploadedDocus.forEach((item, index: number) => {
				let row: any = {};
				if (item) {
					CHEQUE_FRAUD_FILE_HEADER.forEach(({ format, key }) => {
						if (format === 'string' && key === 'size') {
							return (row[key] = formatSizeUnits(item[key]));
						}
						if (format === 'jsx' && key === 'action') {
							const value = () => (
								<div
									className="delete-btn-wrapper"
									onClick={() => handleDeleteDocs(index)}
								>
									<i className="ri-delete-bin-6-line"></i>
								</div>
							);
							return (row[key] = value);
						}

						row = { ...row, [key]: item[key as 'name'] ?? 'N/A' };
						return null;
					});
				}

				rows.push(row);
			});

			return rows;
		}
		return [];
	}, [uploadedDocus]);

	useEffect(() => {
		return () => {
			resetIsMarkAsFraudSelectedByDetails();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="revert-report-modal__content">
			<div className="revert-report-modal__content__body-inner">
				<TextArea
					label={'Note'}
					handleChange={e => setFraudContent(e.target.value)}
					value={fraudContent}
					placeholder={'Reason of reverting a fraud user'}
				/>

				<UploadDocs
					label="Upload a document"
					handleChange={handleChange}
					isShowProgess
					accept=".pdf, .docx , .doc , .png, .jpg, .jpeg,"
				/>

				{getAllDocus.length > 0 && (
					<ReactResponsiveTable
						column={CHEQUE_FRAUD_FILE_HEADER}
						rows={getAllDocus}
						showSort={false}
					/>
				)}
			</div>
			<div className="revert-report-modal__btn-wrapper">
				<Button
					label={'Cancel'}
					type={'button button__filled--secondary '}
					handleClick={() =>
						handleClose(isMarkAsFraudSelectedByDetails ? 'viewDetails' : '')
					}
				/>
				<Button
					label={
						loading ? (
							<Loader type="loader" className="loader-white" dimension={20} />
						) : (
							'Revert Fraud'
						)
					}
					type={'button button__filled button__filled--primary '}
					handleClick={handleSubmit}
					disabled={!fraudContent.length}
				/>
			</div>
		</div>
	);
};
