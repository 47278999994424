import { atom } from 'recoil';
import { v4 } from 'uuid';

export const SelectedInstanceDetails = atom<any>({
	key: 'instance-details',
	default: {},
});

export const AuthComplexListsState = atom<any>({
	key: 'auth-complex-lists',
	default: [],
});

export const AuthProvidersState = atom<any>({
	default: [],
	key: v4(),
});
