import { FC, useMemo } from 'react';

import { useAllowedInvites, useFreePlan } from 'hooks';
import {
	AllowedInvitesState,
	IAllowedInvites,
	loginState,
} from 'global-stores';
import {
	REMAINING_SERVICES,
	ESIGN_REMAINING_SERVICES,
} from 'views/new-user-popup/constants';
import { useRecoilValue } from 'recoil';

export const DemoAcountType: FC = () => {
	const { totalSessions, totalSimpliciDocs } = useAllowedInvites();
	const allowedInvites = useRecoilValue(AllowedInvitesState);
	const { parentBusinessId } = useRecoilValue(loginState);
	const { haveKybKyc } = useFreePlan();

	const {
		onboarding: ONBOARDING,
		simpliciSign: SIMPLICI_SIGN,
	}: IAllowedInvites = allowedInvites ?? {};

	const remains: { [key: string]: number } = useMemo(() => {
		const onboarding =
			totalSessions < ONBOARDING ? ONBOARDING - totalSessions : 0;
		const simpliciSign =
			totalSimpliciDocs < SIMPLICI_SIGN ? SIMPLICI_SIGN - totalSimpliciDocs : 0;
		return { onboarding, simpliciSign };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [totalSessions, totalSimpliciDocs]);

	const RenderDemoBody = useMemo(() => {
		return (REMAINING_SERVICES ?? []).map(({ key, title }) => (
			<div
				key={key}
				className={`free-credits-remains-wrapper__info-content__${key}`}
			>
				<div className="free-credits-remains-wrapper__info-content__description">
					<div className="free-credits-remains-wrapper__info-content__description__title">
						{title}
					</div>
					<div className="floading__remains">
						{parentBusinessId ? 0 : remains[key]} invites remaining
					</div>
				</div>
				<progress
					value={parentBusinessId ? 0 : remains[key]}
					max={allowedInvites?.[key as 'onboarding'] ?? 0}
					className="subscriptions__loading-bar"
				/>
			</div>
		));
	}, [remains, allowedInvites, parentBusinessId]);

	return (
		<div>
			<div className="subscriptions__acount-type-box">
				<div className="subscriptions__acount-type subscriptions__demo  subscriptions__demo-acount">
					<i className="ri-star-smile-fill"></i>
				</div>
				<div>
					<div className="subscriptions__acount-type-label">Free</div>

					<div className="subscriptions__acount-type-amount">
						{haveKybKyc && (
							<>
								<strong>{!parentBusinessId ? ONBOARDING : 0}</strong> Onboarding
								invites |
							</>
						)}
						<strong> {!parentBusinessId ? SIMPLICI_SIGN : 0}</strong> esign
						invites
					</div>
				</div>
			</div>

			{RenderDemoBody}
		</div>
	);
};

export const EsignAcountType: FC = () => {
	const { totalSessions, totalSimpliciDocs } = useAllowedInvites();
	const allowedInvites = useRecoilValue(AllowedInvitesState);
	const { parentBusinessId } = useRecoilValue(loginState);
	const { haveKybKyc } = useFreePlan();

	const {
		onboarding: ONBOARDING,
		simpliciSign: SIMPLICI_SIGN,
	}: IAllowedInvites = allowedInvites ?? {};

	const remains: { [key: string]: number } = useMemo(() => {
		const onboarding =
			totalSessions < ONBOARDING ? ONBOARDING - totalSessions : 0;
		const simpliciSign =
			totalSimpliciDocs < SIMPLICI_SIGN ? SIMPLICI_SIGN - totalSimpliciDocs : 0;
		return { onboarding, simpliciSign };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [totalSessions, totalSimpliciDocs]);

	const RenderDemoBody = useMemo(() => {
		return (ESIGN_REMAINING_SERVICES ?? []).map(({ key, title }) => (
			<div
				key={key}
				className={`free-credits-remains-wrapper__info-content__${key}`}
			>
				<div className="free-credits-remains-wrapper__info-content__description">
					<div className="free-credits-remains-wrapper__info-content__description__title">
						{title}
					</div>
					<div className="floading__remains">
						{parentBusinessId ? 0 : remains[key]} invites remaining
					</div>
				</div>
				<progress
					value={parentBusinessId ? 0 : remains[key]}
					max={allowedInvites?.[key as 'onboarding'] ?? 0}
					className="subscriptions__loading-bar"
				/>
			</div>
		));
	}, [remains, allowedInvites, parentBusinessId]);

	return (
		<div>
			<div className="subscriptions__acount-type-box">
				<div className="subscriptions__acount-type subscriptions__demo  subscriptions__demo-acount">
					<i className="ri-star-smile-fill"></i>
				</div>
				<div>
					<div className="subscriptions__acount-type-label">Free</div>

					<div className="subscriptions__acount-type-amount">
						{haveKybKyc && (
							<>
								<strong>{!parentBusinessId ? ONBOARDING : 0}</strong> Onboarding
								invites |
							</>
						)}
						<strong> {!parentBusinessId ? SIMPLICI_SIGN : 0}</strong> esign
						invites
					</div>
				</div>
			</div>

			{RenderDemoBody}
		</div>
	);
};
