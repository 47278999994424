import { FC } from 'react';
interface IProps {
	label: string;
	value: string | string[];
}

export const BasicSection: FC<IProps> = ({ label, value }) => {
	if (!label || !value || value === '-') {
		return <></>;
	}
	const renderValue = (val: string | string[]) => {
		if (label === 'Source link' && typeof val === 'string') {
			return (
				<div className="source-link">
					<a href={val} target="_blank" rel="noreferrer">
						{val}
					</a>
				</div>
			);
		}
		if (val?.length === 1) {
			return <div>{val.toString() || '--'}</div>;
		}
		if (Array.isArray(val)) {
			// eslint-disable-next-line react/no-array-index-key
			return val.map((item, index) => <div key={index}>{`${item}`}</div>);
		}
		if (typeof val === 'boolean') {
			return <div>{val ? 'true' : 'false'}</div>;
		}
		return <div>{val}</div>;
	};

	return (
		<div key={label} className="kyb-wrapper__details__kybInfoDetailsRow">
			<div className="kyb-wrapper__details__kybInfoDetailsRow__label">
				{label || '--'}
			</div>

			<div className="kyb-wrapper__details__kybInfoDetailsRow__value">
				<span>:</span>
				<div>{renderValue(value) || '--'}</div>
			</div>
		</div>
	);
};
