import { IReminderDeadLine } from './types'; // Import the interface for the reminder deadline state

import { atom } from 'recoil'; // Import Recoil's atom function for state management

// Define the Recoil atom for storing the reminder deadline state
export const ReminderDeadLineState = atom<IReminderDeadLine>({
	key: 'reminder-dead-line-state-key', // Unique key for identifying the atom
	default: {
		value: '', // Default value for the date, initially an empty string
		errorMessage: '', // Default error message, initially empty
	},
});
