import { atom } from 'recoil';
import { IdentityType, IndetityFlowNavigate } from '../constants';
import {
	CompanyFormData,
	CompanyFormIsErrors,
	PaymentAddress,
	PersonalFormData,
} from './type';

export const ChooseUserIdentity = atom({
	key: 'choose-user-identity',
	default: IdentityType.Company,
});

export const SubscriptionIdentityNaviagtion = atom({
	key: 'subscription-identity-naviagtion',
	default: IndetityFlowNavigate.ChooseIdentity,
});

export const ChooseServices = atom<string[]>({
	key: 'choose-services',
	default: [],
});

export const BusinessFormState = atom<CompanyFormData>({
	key: 'business-form-state1',
	default: {
		firstName : '',
		lastName : '',
		website: '',
		ein: '',
		companyName: '',
		email: '',
		phone: '',
		address: '',
		zip: '',
		city: '',
		country: '',
		state: '',
		linkedIn: 'https://www.linkedin.com/company/',
		countryCode: '',
	},
});

export const BusinessIsFormState = atom<CompanyFormIsErrors>({
	key: 'business-form-error-state',
	default: {
		firstName:false,
		lastName:false,
		website: false,
		ein: false,
		companyName: false,
		email: false,
		phone: false,
		address: false,
		zip: false,
		city: false,
		country: false,
		state: false,
		linkedIn: false,
	},
});

export const PersonalIsFormState = atom<Partial<CompanyFormIsErrors>>({
	key: 'persoanl-form-error-state',
	default: {
		firstName: false,
		lastName: false,
		email: false,
		phone: false,
		dob: false,
		zip: false,
		city: false,
		country: false,
		state: false,
		address: false,
		idNumber: false,
	},
});

export const BusinessIsFormErrorState = atom<CompanyFormData>({
	key: 'business-form-all-error-state',
	default: {
		firstName : '',
		lastName : '',
		website: '',
		ein: '',
		companyName: '',
		email: '',
		phone: '',
		address: '',
		zip: '',
		city: '',
		country: '',
		state: '',
		linkedIn: '',
	},
});

export const PersonalFormErrorState = atom<PersonalFormData>({
	key: 'perosnal-form-all-error-state',
	default: {
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		dob: '',
		zip: '',
		city: '',
		country: '',
		state: '',
		address: '',
		idNumber: '',
	},
});

export const PersonFormState = atom<PersonalFormData>({
	key: 'person-form-state1',
	default: {
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		dob: '',
		zip: '',
		city: '',
		country: '',
		state: '',
		address: '',
		idType: 'SSN',
		idNumber: '',
		countryCode: '',
	},
});

export const BillingPaymentAddress = atom<PaymentAddress>({
	key: 'billing-payment-address',
	default: {
		address1: '',
		city: '',
		state: '',
		zip: '',
		address2: '',
		country: '',
	},
});
