import {
	addDays,
	endOfDay,
	startOfDay,
	startOfMonth,
	endOfMonth,
	addMonths,
	addYears,
	startOfYear,
	endOfYear,
} from 'date-fns';
import { DateTime } from 'luxon';

const endDate = DateTime.now();
const startDate = endDate.minus({ days: 29 });
const start90Date = endDate.minus({ days: 89 });

const getPreviousQuarter = () => {
	const today = new Date();
	const quarter = Math.floor((today.getMonth() / 3));
	
	const startDate = new Date(today.getFullYear(), quarter * 3 - 3, 1);
	const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 3, 0);

	return {
		startOfLastQuarter: startDate,
		endOfLastQuarter: endDate
	};
}

export const defineds = {
	startOfToday: startOfDay(new Date()),
	endOfToday: endOfDay(new Date()),

	startOfLastWeek: startOfDay(addDays(new Date(), -6)),
	endOfLastWeek: endOfDay(new Date()),

	startOfLast30days: startDate.toJSDate(),
	endOfLast30days: endDate.toJSDate(),

	startOfThisMonth: startOfMonth(addMonths(new Date(), 0)),
	endOfThisMonth: new Date(),

	startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
	endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),

	startOfLast90Days: start90Date.toJSDate(),
	endOfLast90Days: endDate.toJSDate(),

	...getPreviousQuarter(),

	startOfLastYear: startOfYear(addYears(new Date(), -1)),
	endOfLastYear: endOfYear(addYears(new Date(), -1)),

	startOfYear: startOfYear(new Date()),
	tillDate: endOfDay(new Date()),
};