import { FC } from 'react';
import { Image } from '@storybook';

interface IPageNotFound {
	reDirectUrl?: string;
}

const ErrorPage: FC<IPageNotFound> = ({ reDirectUrl = '' }) => {
	return (
		<div className="error-body">
			<Image fileName='errorTemplate.svg' loading="lazy" />
			<p className="error-body__label">Page Not Found</p>
			<p className="error-body__message">
				The page you are looking for doesn’t exist. You may have mistyped the
				address or the page may have moved to a new address.
			</p>
			{reDirectUrl && (
				<p className="error-body__redirection">Take me to home page</p>
			)}
		</div>
	);
};

export default ErrorPage;
