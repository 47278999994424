import { Message } from 'components';
import { BrowseFile, UploadedFiles } from './components';
import { SelectSigDocument } from './components/select-document';
import { useGetSignDocument } from './store';

import './sign-agreement.scss';
import { FC } from 'react';
import { IUploadedDocuments } from 'global-stores';

interface ISignAgreement {
	handleChange?: any;
	uploadedFiles?: any;
	isAdvance?: boolean;
	setSelectedFiles?: (value: IUploadedDocuments[])=>void;
	selectedFiles?: IUploadedDocuments[]
}
export const SignAgreement: FC<ISignAgreement> = ({
	handleChange,
	uploadedFiles,
	isAdvance,
	setSelectedFiles,
	selectedFiles,
}) => {
	const { SignDocProvider } = useGetSignDocument();

	const props = {
		provider: SignDocProvider,
		...(handleChange && {
			handleChange,
		}),
		...( selectedFiles && { selectedFiles })
	};

	const uploadedFileProps = {
		provider: SignDocProvider,
		...(uploadedFiles && {
			uploadedFiles,
		}),
		// pradeep chaurasia : seter function passes as props
		...(setSelectedFiles && { setSelectedFiles })
	};

	return (
		<div className="SignAgreement--scroll" style={{ height: '68vh' }}>
			<div className="SignAgreement__import-wrapper">
				<BrowseFile {...props} />
				<SelectSigDocument {...props} isAdvance={isAdvance} />
			</div>
			<Message
				text={
					'Note: Please select all the agreement documents that you want to send for onboarding.'
				}
				varient="warning"
			/>
			<UploadedFiles {...uploadedFileProps} />
		</div>
	);
};
