import { NoData } from '../no-data-available';
import './proof-read-document.scss';

import { SessionDetailsHeading } from '../session-details-heading';
import { Image } from '@storybook';
import { FC, useMemo } from 'react';
import { SessionDetailsProofReadingSkeletonLoader } from 'views/sessions/session-details-loader';
import Tippy from '@tippyjs/react';
import { formatSizeUnits } from 'utils';
import { proofReadingKey } from 'views/pipelines';

interface IProofDocUrl {
	docId: string;
	_id: string;
	signedUrl: string;
	docName: string;
	size: string;
}

interface IProofReadDocument {
	file: IProofDocUrl[];
	isSkeletonLoading: boolean;
}

export const ProofReadDocument: FC<IProofReadDocument> = ({
	file,
	isSkeletonLoading,
}) => {

	const renderFileContent = useMemo(() => {
		return (
			<>
				{file?.map((item: IProofDocUrl) => (
					<div className="proofReadDocument__doc-wrapper" key={item?._id}>
						<div className="proofReadDocument__doc-content">
							<div className="proofReadDocument__doc-content__doc-icon">
							<Image fileName='PDF.svg' height="24px" width="20px" />
							</div>
							<div className="proofReadDocument__doc-content__doc-info">
								{(item?.docName?.length > 24 ? (
									<Tippy placement="top-start" content={item?.docName}>
										<span className="proofReadDocument__doc-content__doc-title">
											{`${item?.docName.slice(0, 24)}...`}
										</span>
									</Tippy>
								) : (
									<span className="proofReadDocument__doc-content__doc-title">
										{item?.docName}
									</span>
								)) || '--'}
								<span className="proofReadDocument__doc-content__doc-size">
									{formatSizeUnits(item?.size) ?? '--'}
								</span>
							</div>
						</div>
						<span style={{ fontWeight: '500' }}>:</span>
						<div>
							<button
								className="proofReadDocument__view-button"
								onClick={() => window.open(item?.signedUrl, '_blank')}
							>
								<i className="ri-eye-line" />
								View
							</button>
						</div>
					</div>
				))}
			</>
		);
	}, [file]);

	const renderBodyContents = useMemo(() => {
		if (isSkeletonLoading) {
			return <SessionDetailsProofReadingSkeletonLoader />;
		} else {
			return (
				<>
					{renderFileContent}
					{!file && <NoData />}
				</>
			);
		}
	}, [file, isSkeletonLoading, renderFileContent]);

	return (
		<div className="proofReadDocument">
			<div className="kycDetails-contain proofReadDocument__container">
				<SessionDetailsHeading
					label={proofReadingKey.DocumentReview}
					icon={
						<i className="ri-newspaper-line form-icon-profile-container__formIcon"></i>
					}
				/>
				<div className="proofReadDocument__doc-container">
					{renderBodyContents}
				</div>
			</div>
		</div>
	);
};
