export const KYBFormData = {
	elements: [
		{
			type: 'checkbox',
			name: 'question2',
			title: 'Corporation :',
			checked: true,
			choices: [
				{
					value: 'Item1',
					text: 'IRS Letter awarding EIN',
					checked: true,
				},
				{
					value: 'Item2',
					text: 'Articles of incorporation',
					checked: true,
				},
				{
					value: 'Item3',
					text: 'Corporate Resolution',
					checked: true,
				},
				{
					value: 'Item4',
					text: 'Certification of Beneficial owner(s)*',
					checked: true,
				},
				{
					value: 'Item5',
					text: 'ID of Beneficial owner(s)',
					checked: true,
				},
				{
					value: 'Item6',
					text: 'ID of Authorized Signer(s)',
					checked: true,
				},
				{
					value: 'Item7',
					text: 'ID of Person Opening Account**',
					checked: true,
				},
			],
		},
		{
			type: 'checkbox',
			name: 'question3',
			title: 'Organization :',
			checked: true,
			choices: [
				{
					value: 'Item1',
					text: 'IRS Letter awarding EIN',
					checked: true,
				},
				{
					value: 'Item2',
					text: 'Business Meeting Minutes',
					checked: true,
				},
				{
					value: 'Item3',
					text: 'Organization Resolution',
					checked: true,
				},
				{
					value: 'Item4',
					text: 'ID of Authorized Signer(s)',
					checked: true,
				},
				{
					value: 'Item5',
					text: 'ID of Person Opening Account**',
					checked: true,
				},
			],
		},
		{
			type: 'checkbox',
			name: 'question4',
			title: 'Limited Liability Company :',
			checked: true,
			choices: [
				{
					value: 'Item1',
					text: 'IRS Letter awarding EIN',
					checked: true,
				},
				{
					value: 'Item2',
					text: 'Article of Organization',
					checked: true,
				},
				{
					value: 'Item3',
					text: 'LLC Resolution',
					checked: true,
				},
				{
					value: 'Item4',
					text: 'Certification of Beneficial owner(s)',
					checked: true,
				},
				{
					value: 'Item5',
					text: 'ID of Beneficial owner(s)*',
					checked: true,
				},
				{
					value: 'Item6',
					text: 'ID of Authorized Signer(s)',
					checked: true,
				},
				{
					value: 'Item7',
					text: 'ID of Person Opening Account**',
					checked: true,
				},
			],
		},
		{
			type: 'checkbox',
			name: 'question5',
			title: 'Trust :',
			checked: true,
			choices: [
				{
					value: 'Item1',
					text: 'IRS Letter awarding EIN (if applicable)',
					checked: true,
				},
				{
					value: 'Item2',
					text: 'Trust Instrument',
					checked: true,
				},
				{
					value: 'Item3',
					text: 'Trust Resolution',
					checked: true,
				},
				{
					value: 'Item4',
					text: 'ID of trustee(s)',
					checked: true,
				},
			],
		},
		{
			type: 'checkbox',
			name: 'question6',
			title: 'Estate :',
			checked: true,
			choices: [
				{
					value: 'Item1',
					text: 'IRS Letter awarding EIN',
					checked: true,
				},
				{
					value: 'Item2',
					text: 'Letters of Testamentary',
					checked: true,
				},
				{
					value: 'Item3',
					text: 'Letters of Administration',
					checked: true,
				},
				{
					value: 'Item4',
					text: 'ID of Executer(s)/Administrator(s)',
					checked: true,
				},
			],
		},
		{
			type: 'checkbox',
			name: 'question7',
			title: 'Partnership :',
			checked: true,
			choices: [
				{
					value: 'Item1',
					text: 'IRS Letter awarding EIN',
					checked: true,
				},
				{
					value: 'Item2',
					text: 'Partnership Agreement',
					checked: true,
				},
				{
					value: 'Item3',
					text: 'Partnership Resolution',
					checked: true,
				},
				{
					value: 'Item4',
					text: 'Certification of Beneficial owner(s)',
					checked: true,
				},
				{
					value: 'Item5',
					text: 'ID of Beneficial owner(s)*',
					checked: true,
				},
				{
					value: 'Item6',
					text: 'ID of Authorized Signer(s)',
					checked: true,
				},
				{
					value: 'Item7',
					text: 'ID of Person Opening Account**',
					checked: true,
				},
			],
		},
		{
			type: 'checkbox',
			name: 'question8',
			title: 'Sole Proprietorship',
			checked: true,
			choices: [
				{
					value: 'Item1',
					text: 'IRS Letter awarding EIN (if applicable)',
					checked: true,
				},
				{
					value: 'Item2',
					text: 'Business License',
					checked: true,
				},
				{
					value: 'Item3',
					text: 'Sole Proprietorship Resolution',
					checked: true,
				},
				{
					value: 'Item4',
					text: 'ID of Sole Proprietor (Owner)',
					checked: true,
				},
				{
					value: 'Item5',
					text: 'ID of Authorized Signer(s)',
					checked: true,
				},
			],
		},
	],
};
