export const SidebarSkeletonLoading = () => {
	const numberOfLoaders = [1, 2, 3, 4, 5, 6, 7, 8];

	return (
		<div className="SidebarSkeletonLoading">
			{numberOfLoaders.map(el => (
				<div className="SidebarSkeletonLoading--container" key={el}>
					<div className="sidebar__item sidebar__item">
						<span className="skeleton-loader-light SidebarSkeletonLoading--height"></span>
					</div>
				</div>
			))}
		</div>
	);
};
