import { ReactNode, useMemo } from 'react';

import { useRecoilValue } from 'recoil';
import { IframePropsState } from 'global-stores';
import { Image } from '@storybook/image';
import './mobile-ui.scss';

interface Props {
	height?: string;
	children?: ReactNode;
}

/**
 * MobileUI component
 *
 * @param {string} Props.height - The height of the mobile UI
 * @param {ReactNode} Props.children - The content to be displayed inside the mobile UI
 */

// width and height is required that can not be null
export const MobileUI = ({ children }: Props) => {
	const { background } = useRecoilValue(IframePropsState);

	const pageLoading = useMemo(() => {
		return children;
	}, [children]);


	return (
		<div
			className="mobile-ui"
		>
			<div className="mobile-ui__inner">
				<div className="mobile-ui__inner__btn-component">
					<div className="mobile-ui__inner__btn-component--silent" />
					<div className="mobile-ui__inner__btn-component--sleep" />
					<div className="mobile-ui__inner__btn-component--vol-up" />
					<div className="mobile-ui__inner__btn-component--vol-down" />
				</div>
				<div className="mobile-ui__inner__top-notch">
					<Image fileName="notch.svg" />
					<div className="mobile-ui__inner__top-notch__camera">
						<div className="mobile-ui__inner__top-notch__camera--left" />
					</div>
					<div className="mobile-ui__inner__top-notch__speaker" />
				</div>
				<div
					className="mobile-ui__inner__screen"
					style={{ backgroundColor: background }}
				>
					{pageLoading}
				</div>
			</div>
		</div>
	);
};
