export const SubscriptionType = [
	{
		type: 'onboarding',
		subs_name: 'Onboarding Services',
		charges: '14.99',
	},
	{
		type: 'simpliciSign',
		subs_name: 'Simplici Sign',
		charges: '4.99',
	},
	// {
	// 	type: 'simpliciText',
	// 	subs_name: 'Simplici Text',
	// 	charges: '19.99',
	// },
];

export const AcountUserTypes = {
	Entity: 'entity',
	Person: 'individual',
};

export const SanboxModeTooltip =
	'Sandbox mode is active no subscription required.';

export const UpgradeSubscriptionSanboxModeTooltip =
	'You are currently in Sandbox Mode switch to Live Mode if you want to upgrade your account.';

export const UpgradePaymentSanboxModeTooltip =
	'You are currently in Sandbox Mode Switch to Live Mode if you want to upgrade your payment.';
