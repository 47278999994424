import { useEffect, useMemo } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { IconBtn, ReactResponsiveTable } from '@storybook';
import Modal from '@storybook/new-modal/modal';

import { ConfirmationModal, DropOption } from 'components';
import { FundPaginationState } from 'global-stores';
import {
	formatNumberWithCommasAndCurrencySignSign,
	getDate,
	getTime,
} from 'utils';
import { useInterval } from 'hooks';
import { Header } from 'views/header';
import {
	DEALS_TABLE_HEADER,
	dealsTransactionStatus,
	dealsTransactionStatusClass,
	dealsTransactionType,
} from './constants';
import {
	TransactionDealsState,
	useTransactionDeals,
	usePerson,
	DealFilterTagsState,
} from './stores';
import { DealFilter } from './components';
import { PERMISSION_SCREEN, useUserRoles } from 'views';

import './deals.scss';

export const Deals = () => {
	// globle states
	const deals = useRecoilValue(TransactionDealsState);
	const dealFilteringTags = useRecoilValue(DealFilterTagsState)
	const restPageNumber = useResetRecoilState(FundPaginationState);

	// hooks
	const {
		fetchDeals,
		handleCopy,
		modalProps,
		handleOnClickRow,
		isLoadingDeals,
		handleSearchedText,
		searchedText,
		startDate,
		endDate,
		confirmationProps,
		menuOptions,
	} = useTransactionDeals();
	const { renderPersons } = usePerson();

	const { result: dealsResult, totalPages = 0 } = deals ?? {};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(()=>restPageNumber(),[])

	useEffect(() => {
		fetchDeals(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchedText, startDate, endDate, dealFilteringTags]);

	useInterval(fetchDeals, 5000);

	const { checkUserWritePermission } = useUserRoles();

	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.Deals),
		[checkUserWritePermission]
	);

	const dealColumns = useMemo(() => {
		if (!isUserPermissionWrite) {
			return DEALS_TABLE_HEADER.filter(
				headerItem => headerItem.key !== 'action'
			);
		}
		return DEALS_TABLE_HEADER;
	}, [isUserPermissionWrite]);

	const dealsRows = useMemo(() => {
		if (dealsResult?.length) {
			const rows: any = [];
			dealsResult.forEach(item => {
				if (item) {
					const {
						salePrice = 0,
						fees,
						_id,
						createdAt,
						dealId,
						type,
						status,
						name,
					} = item ?? {};
					let totalAmount = 0;
					if (Array.isArray(fees))
						fees.forEach(({ name, amount }) => {
							if (name && typeof amount === 'number') {
								totalAmount += amount;
							}
						});
					if (typeof salePrice === 'number') totalAmount += salePrice;
					const row: any = {};
					row.id = _id;
					row.deal_Id = dealId;
					row.createdAt = createdAt;
					row.transaction_type = type;
					row.totalAmount = totalAmount;
					row.dealName = name;
					dealColumns.forEach(({ format, key }) => {
						if (key === 'dealId') {
							const value = () => (
								<div className="Deals__dealId-wrapper">
									<div className="Deals__dealId">{item[key]}</div>
									<div className="btn__Onhover">
										<IconBtn
											btnClassName="ri-file-copy-line  icon__primary icon__large"
											handleClickIcon={e => handleCopy(e, item[key])}
										/>
									</div>
								</div>
							);
							return (row[key] = value);
						}
						if (format === 'jsx' && key === 'date') {
							const value = () => (
								<>
									{getDate(item['createdAt'])}
									<div className="Deals__sub-td">
										{getTime(item['createdAt'])}
									</div>
								</>
							);
							return (row[key] = value);
						}
						if (format === 'jsx' && key === 'status') {
							const value = () => (
								<div className="Deals__status-wrapper">
									<i
										className={`ri-circle-fill Deals__status-icon ${dealsTransactionStatusClass(
											status
										)}`}
									/>
									<div>{dealsTransactionStatus(status)}</div>
								</div>
							);
							return (row[key] = value);
						}
						if (format === 'jsx' && key === 'type') {
							const value = () => (
								<div
									className={`Deals__type Deals__type-${dealsTransactionType(
										item[key]
									)}`}
								>
									{dealsTransactionType(item[key])}
								</div>
							);
							return (row[key] = value);
						}

						if (format === 'jsx' && key === 'amount') {
							const value = () => (
								<>
									{formatNumberWithCommasAndCurrencySignSign(
										(totalAmount ?? 0).toFixed(2),
										'$'
									)}
								</>
							);
							return (row[key] = value);
						}
						if (key === 'sellers' || key === 'buyers') {
							const value = () => renderPersons(item, key);
							return (row[key] = value);
						}
						if (key === 'action' && isUserPermissionWrite) {
							const value = () => <DropOption options={menuOptions(item)} />;
							return (row[key] = value);
						}
						if (key === 'name') {
							const value = () => (
								<div className="Deals__deal-name">{name || '--'}</div>
							);
							return (row[key] = value);
						}
						return null;
					});
					rows.push(row);
				}
			});
			return rows;
		} else return [];
	}, [
		dealsResult,
		dealColumns,
		handleCopy,
		menuOptions,
		renderPersons,
		isUserPermissionWrite,
	]);

	return (
		<div className="Deals">
			<Header title="Deals" />
			<div className="Deals__inner">
				<ReactResponsiveTable
					column={dealColumns}
					isLoading={isLoadingDeals}
					rows={dealsRows}
					handelRowClick={handleOnClickRow}
					tableType="deals"
					EmptyIllustration="empty-transaction.svg"
					EmptyMessageHeading="No transaction available to show"
					EmptyMessageDescription="Sorry, No transactions available here yet."
					handlePagination={() => fetchDeals(true)}
					isPagination={!!totalPages}
					totaloPageCount={totalPages}
					getSearchText={handleSearchedText}
					showSearch
					hideSortKey={['status', 'sellers', 'buyers']}
					showDateFilter
					customFilter={<DealFilter />}
				/>
			</div>
			{modalProps.isOpen && <Modal {...modalProps} />}
			{confirmationProps.visible && (
				<ConfirmationModal {...confirmationProps} />
			)}
		</div>
	);
};
