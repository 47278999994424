import { useCallback, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
	ComplexFlowUnconfigedState,
	NodesState,
} from 'views/complex-onboarding-flow/stores/states';

export const useFormAction = ({
	nodeId,
	label,
}: {
	nodeId: string;
	label: string;
}) => {
	const [isConfigFormModal, setIsConfigFormModal] = useState<boolean>(false);
	const setComplexFlowConfiged = useSetRecoilState(ComplexFlowUnconfigedState);
	const nodes = useRecoilValue(NodesState);

	const steps = useMemo(() => {
		const metaData = nodes?.find(
			(el: any) => el.id === nodeId && el.data.label === label
		)?.formMetaData;
		const stepActions = [];
		const { pages = [] } = metaData ?? [];
		for (let i = 0; i < pages?.length; i++) {
			for (let j = 0; j < pages[i].elements?.length; j++) {
				stepActions.push(pages[i].elements[j]);
			}
		}

		return stepActions;
	}, [label, nodeId, nodes]);

	const addNewValue = useCallback(() => {
		setIsConfigFormModal(false);
		setComplexFlowConfiged((prev: any) => {
			const newArr = structuredClone(prev);
			return newArr.map((el: any) => {
				if (el.id === nodeId) {
					return {
						...el,
						configed: true,
					};
				} else {
					return el;
				}
			});
		});
	}, [nodeId, setComplexFlowConfiged]);

	return {
		addNewValue,
		isConfigFormModal,
		steps,
		setIsConfigFormModal,
	};
};
