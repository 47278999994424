export const currentVerifySteps = {
	verifyPhone: 'verifyPhone',
	verifyProgress: 'verifyProgress',
	verifySuccess: 'verifySuccess',
	verifyReject: 'verifyReject',
	verifyTimeOut: 'verifyTimeOut',
	EmailVerification: 'EmailVerification',
};

export const verifyStepper = [
	{ key: 'verifyPhone', value: 'Verify phone number' },
	{ key: 'EmailVerification', value: 'Verify email' },
	{ key: 'verifySuccess', value: 'Device Biometric' },
];

export const SocialLink = [
	{
		url: 'https://www.linkedin.com/company/simplici/',
		icon: 'ri-linkedin-fill',
	},
	{
		url: 'https://twitter.com/simplici2023',
		icon: 'ri-twitter-x-fill',
	},
];
