import { Image, Loader } from '@storybook';
import { useRecoilValue } from 'recoil';

import { SignDocInboxSelector } from 'views/signed-doc-inbox';
import { DocumentStatusChart } from './document-status-chart';

export const DocumentStats = () => {
	const { data: envelopeList, isLoaded } =
		useRecoilValue(SignDocInboxSelector);

	const completedDocs = envelopeList.filter(i => i.status === 'completed');

	return (
		<div className="user-dashboard__doc-stats-section hover">
			<div className="user-dashboard__doc-stats-section__heading">
				Documents
			</div>
			<div className="user-dashboard__doc-stats-section__svg-container">
				{!isLoaded ? (
					<>
						<Loader type="loader" dimension={100} className="loader-white" />
					</>
				) : (
					<>
						{!completedDocs.length ? (
							<div className="user-dashboard__doc-stats-section__svg-container__noData-img">
								<Image fileName='Analitics.svg' />
								<span>Documents status</span>
							</div>
						) : (
							<div className="user-dashboard__doc-stats-section__svg-container__pieChart">
								<DocumentStatusChart
									isLoading={!isLoaded}
									envelopeLength={envelopeList.length}
									completedLength={completedDocs.length}
								/>
								<span>Documents completed</span>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};
