import { FC, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { Image } from '@storybook';

import {
	ComplexSessionsNodesDetailsState,
	ComplexSessionsNodesLoadingState,
	SessionItemSelected,
} from 'global-stores';

import { SessionDetailsHeader } from './session-details-header';
import { ComplexSessionDetailsPage } from './complex-session-details-page';
import { useGetComplexNodeInfo } from '../hooks/use-complex-session-details';
import { SessionTreeGraph } from 'views/complex-onboarding-flow';
import { PERMISSION_SCREEN, useUserRoles } from 'views/routes-children';

import './kycDetailsQuestion.scss';

interface IKycDetail {
	isLoading: boolean;
	handleBackModal?: () => void;
	isTransactionPage?: boolean;
	isShowTreeFlow?: boolean;
	isChequeFraud?: boolean;
}

export const ComplexSessionView: FC<IKycDetail> = ({
	isLoading = true,
	handleBackModal,
	isTransactionPage = false,
	isShowTreeFlow = true,
	isChequeFraud = false,
}) => {
	const [isComplexFlow, setIsComplexFlow] = useState(false);
	const sessionItemSelected = useRecoilValue(SessionItemSelected);
	const { getComplexNodeInfo } = useGetComplexNodeInfo();
	const resetSessionDetails = useResetRecoilState(
		ComplexSessionsNodesDetailsState
	);
	const sessionDetails = useRecoilValue(ComplexSessionsNodesDetailsState);
	const sessionNodeLoading: any = useRecoilValue(
		ComplexSessionsNodesLoadingState
	);
	const resetComplexLoading = useResetRecoilState(
		ComplexSessionsNodesLoadingState
	);

	const { checkUserWritePermission } = useUserRoles();

	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.Sessions),
		[checkUserWritePermission]
	);

	useEffect(() => {
		/**
		 * here we are doing the get api call for the complex session for each node
		 * */
		getComplexNodeInfo();
		return () => {
			/**
			 * reseting the state for the complex session node
			 * */
			resetSessionDetails();
			resetComplexLoading();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const checkComplexNodesLoading = useMemo(() => {
		return Object.keys(sessionNodeLoading).every(
			node => sessionNodeLoading[node] === true
		);
	}, [sessionNodeLoading]);

	if (
		!isLoading &&
		Object.keys(sessionDetails ?? {}).length === 0 &&
		!checkComplexNodesLoading
	) {
		return (
			<div className="kycDetails">
				<div className="kycDetails--header kycDetails--header--sticky ">
					<div className="kycUserName user__name_space">
						<div onClick={handleBackModal} className="arrow__left_icon_main">
							<i className="ri-arrow-left-line fa-lg" />
						</div>
						<div>{sessionItemSelected?.name}</div>
					</div>
				</div>
				<div className="empty_data">
					<Image
						fileName="noData.svg"
						className="empty_data_img"
						height="100"
					/>
					<div className="empty-data-txt">No Data is available</div>
				</div>
			</div>
		);
	}

	const kycDetailsHeaderProps = {
		handleBackModal,
		isTransactionPage,
		isComplexFlow,
		setIsComplexFlow,
	};

	return (
		<>
			<div className="kycDetails">
				{/* Header */}
				<SessionDetailsHeader
					sessionSelected={sessionItemSelected}
					isShowTreeFlow={isShowTreeFlow}
					{...kycDetailsHeaderProps}
				/>
				{!isComplexFlow ? (
					<ComplexSessionDetailsPage
						isLoading={isLoading}
						isTransactionPage={isTransactionPage}
						isUserPermissionWrite={isUserPermissionWrite}
						isChequeFraud={isChequeFraud}
					/>
				) : (
					<div className="complex-session-view">
						<SessionTreeGraph details={sessionItemSelected} isSessionModal />
					</div>
				)}
			</div>
		</>
	);
};
