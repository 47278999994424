import { FC } from 'react';
import { Button, Image, Loader } from '@storybook';

interface INoCard {
	title: string;
	buttonLabel: string;
	handleButton: () => void;
	buttonLoader: boolean;
	description: string;
	illustration: string;
}

export const NoCard: FC<INoCard> = ({
	title,
	buttonLabel,
	handleButton,
	buttonLoader,
	description,
	illustration,
}) => {
	return (
		<div className="no-card">
			<div className="no-card_img">
				<Image fileName={illustration} />
			</div>
			<div className="no-card_wrapper">
				<div className="no-card_wrapper_title">{title}</div>
				<div className="no-card_wrapper_subtitle">{description}</div>
			</div>
			<Button
				label={buttonLoader ? '' : buttonLabel}
				type={'button__filled--primary'}
				handleClick={handleButton}
				loader={
					buttonLoader ? (
						<Loader type="loader" className="loader-white" dimension={20} />
					) : (
						<></>
					)
				}
			/>
		</div>
	);
};
