import { FC } from 'react';
import './checkbox-one.scss';

interface IProps {
	label: string;
	onChangeCheckbox: () => void;
	checked: boolean;
}
export const CheckBox: FC<IProps> = ({ label, onChangeCheckbox, checked }) => {
	return (
		<div className="checkbox_input" onClick={onChangeCheckbox}>
			<input type="radio" checked={checked} />
			<label htmlFor="checkbox_input__checkbox" className="reminder_checkbox">
				{label}
			</label>
		</div>
	);
};
