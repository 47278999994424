import { ChangeEvent, useCallback, useState } from 'react';
import { useRecoilState } from 'recoil';
import { ReminderData, SendNotificationState } from './state';
import { IMetadata } from 'global-stores';

export const useSendNotification = () => {
	const [val, setValue] = useState('');
	const [error, setError] = useState('');

	const [daysList, setReminderDays] = useRecoilState<ReminderData>(
		SendNotificationState
	);

	const blockInvalidChar = (e: { key: any; preventDefault?: any }) =>
		['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setReminderDays((prev: ReminderData) => ({
			...prev,
			checked: false,
		}));

		if (
			(Number(value) &&
				Number(value) >= 1 &&
				Number(value) <= 60 &&
				Number.isInteger(Number(value))) ||
			value === ''
		) {
			setError('');
			setValue(value);
		} else {
			setError(
				'Must be a number between 1 to 60 and should be positive integer'
			);
		}
	};

	const onClickDay = useCallback(() => {
		if (+val === 0) return;
		if (+val > 60) return setError('You can not add more than 60 day');

		if (!daysList.days.find(item => item == val)) {
			setReminderDays((prev: any) => ({
				...prev,
				days: [...prev.days, +val],
			}));
		}
		setValue('');
		setError('');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [val, daysList.days]);

	const onChangeCheckbox = (checked: boolean) => () => {
		setReminderDays((prev: ReminderData) => ({
			...prev,
			checked: checked,
		}));
	};

	const onKeyDown = useCallback(
		(e: { key: string }) => {
			blockInvalidChar(e);
			if (!val || +val === 0) return;

			if (e.key === 'Enter') {
				if (+val > 60) return setError('You can not add more than 60 day');

				if (!daysList.days.find(item => item == val)) {
					setReminderDays((prev: any) => ({
						...prev,
						days: [...prev.days, +val],
					}));
				}
				setValue('');
				setError('');
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[val, daysList]
	);

	const onDeleteDay = useCallback(
		(day: string) => {
			const updateDays: string[] = daysList.days.filter(item => item !== day);
			setReminderDays((prev: ReminderData) => ({
				...prev,
				days: updateDays,
				checked: false,
			}));
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[daysList.days]
	);
	return {
		onDeleteDay,
		onKeyDown,
		onChangeCheckbox,
		val,
		onChange,
		daysList,
		error,
		onClickDay,
	};
};

export const usePipelineSettingsPage = () => {
	const renderChildComponents = useCallback(
		(
			childkey: string,
			metadata: IMetadata[],
			renderSettingsComponents: any,
			getLable: any
		) => {
			const foundElementIndex = metadata.findIndex(
				(meta: IMetadata) => meta.key === childkey
			);
			if (foundElementIndex > -1) {
				const element = metadata[foundElementIndex] as IMetadata;
				const { description, key, label, type, price, placeholder } = element;
				return renderSettingsComponents(
					type,
					description,
					getLable(key, label),
					key,
					element.default,
					price,
					[],
					"small",
					placeholder
				);
			}
			return null;
		},
		[]
	);
	return { renderChildComponents };
};
