import { useCallback } from 'react';

import { BuilderLeftNav } from '../flow-builder';
import { DeliverLeftView } from './components/delivery-left';
import { PublishLeftView } from './components/publish-left';
import './left-nav.scss';

interface IProps {
	navigate: string;
	onDragStart?: any;
}
export const OnboardingLeftNav = ({ navigate, onDragStart }: IProps) => {
	const renderLeftSideView = useCallback(() => {
		switch (navigate) {
			case 'deliveryMethod':
				return <DeliverLeftView />;
			case 'publish':
				return <PublishLeftView />;
			default:
				return <BuilderLeftNav onDragStart={onDragStart} />;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigate]);

	return (
		<div className="OnboardingLeftNav--container">
			<div className="OnboardingLeftNav--contain">{renderLeftSideView()}</div>
		</div>
	);
};
