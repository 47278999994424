import React, { useEffect, useState } from 'react';
import "./Quantity.scss";

const minValue = 1;
const maxValue = 100000;

export const Quantity = ({
    handleDetails,
    defaultValue,
    isDisabled,
}: {
    handleDetails: (val: number) => void;
    defaultValue: number;
    isDisabled: boolean;
}) => {
    const [quantity, setQuantity] = useState<number>(1);

    const handleDecrease = () => {
        setQuantity(prevQuantity => Math.max(prevQuantity - 1, minValue));
    };

    const handleIncrease = () => {
        setQuantity(prevQuantity => Math.min(prevQuantity + 1, maxValue));
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        setQuantity(
            isNaN(value) ? minValue : Math.max(minValue, Math.min(value, maxValue))
        );
    };

    useEffect(() => {
        handleDetails(quantity);
    }, [quantity, handleDetails]);

    return (
        <div className={`Quantity--container ${isDisabled ? 'Quantity--container-disable' : ""}`}>
            <button
                className={`Quantity--button ${isDisabled ? 'Quantity--disable' : ""}`}
                onClick={handleDecrease}
                aria-label="Decrease"
                disabled={quantity <= minValue || isDisabled}
            >
                &minus;
            </button>
            <input
                className='Quantity--inputBox'
                min={minValue}
                max={maxValue}
                onChange={handleInputChange}
                defaultValue={defaultValue}
                disabled={isDisabled}
            />
            <button
                className={`Quantity--button ${isDisabled ? 'Quantity--disable' : ""}`}
                onClick={handleIncrease}
                aria-label="Increase"
                disabled={quantity >= maxValue || isDisabled}
            >
                +
            </button>
        </div>
    );
};
