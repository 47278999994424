import { ReactDropdown } from '@storybook';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { IUploadedDocuments, SignedDocsState, UploadedDocsState } from 'global-stores';
import { formatSizeUnits, getDateWithTime } from 'utils';
import { IDocProvider } from '../store';
import { ComplexConfigStep } from 'views/pipelines/components';
import { docProvider, docProviderType } from './constants';
// import { provider } from '../../signed-doc/constant';

interface IProvider {
	provider: IDocProvider;
	handleChange?: any;
	isAdvance?: boolean;
	selectedFiles?: IUploadedDocuments[]
}

export const SelectSigDocument: FC<IProvider> = ({
	provider,
	handleChange,
	isAdvance,
	selectedFiles,
}) => {
	const [docs, setDocs] = useState<any>(null);
	const signedDocs = useRecoilValue(SignedDocsState);
	const [addedFiles, setAddedFiles] = useRecoilState(UploadedDocsState);
	const configStep = useRecoilValue(ComplexConfigStep);

	const updateNames = useCallback(
		(selectedDocs: IUploadedDocuments[]) => {
			const updatedArray = selectedDocs.map(obj => {
				const matchingItem = signedDocs.find(
					item => item.templateId === obj.templateId
				);
				const selectedDoc = signedDocs.find(item => {
					return docs && docs.value && item.templateId === docs.value;
				});

				if (selectedDoc) {
					const nameParts = docs.label.split(':');
					nameParts[0] = selectedDoc.name;
					const updatedLabel = nameParts.join(':').trim();
					setDocs({ ...docs, label: updatedLabel });
				}

				if (matchingItem && provider === 'esign') {
					return { ...obj, name: matchingItem.name };
				}
				return obj;
			});
			return updatedArray;
		},
		[docs, provider, signedDocs]
	);

	const signedDocsOptions = useMemo(() => {
		return signedDocs
			.filter((doc: any) => {
				return provider === docProvider.docusign
					? doc?.provider?.toLowerCase() === docProvider.docusign
					: doc?.provider?.toLowerCase() === docProvider.esign &&
							doc?.type?.toLowerCase() === docProviderType.basic;
			})
			.map(doc => {
				const { documentName, createdAt, templateId, name } = doc;
				let found = -1;
				if (isAdvance) {
					const flowFiles = (selectedFiles ?? addedFiles).filter(
						file => file.node === configStep.id
					);
					found = flowFiles.findIndex(item => item.templateId === templateId);
				} else {
					found = (selectedFiles ?? addedFiles).findIndex(item => item.templateId === templateId);
				}
				if (!(found >= 0)) {
					return {
						label: `${documentName ?? name} : ${getDateWithTime(createdAt)}`,
						value: templateId,
					};
				}
				return null;
			})
			.filter(item => item);
	}, [addedFiles, configStep.id, isAdvance,provider, signedDocs , selectedFiles]);

	const handleSelectDocument = useCallback(
		(value: any) => {
			setDocs(value);
			const foundTemplate = signedDocs.find(
				doc => doc.templateId === value.value
			);

			const {
				documentName,
				createdAt,
				templateId,
				documentId,
				docRefId,
				prepareUrl,
				name,
				documents,
				provider
			} = foundTemplate ?? {};
			let eSignTempsize = 0;
			if (documents) {
				documents.forEach((doc: any) => {
					eSignTempsize += Number(doc.size);
				});
			}
			const newTemplate = {
				_id: templateId,
				templateName: documentName ?? name,
				name: documents?.[0]?.name ?? name ?? documentName,
				size:
					formatSizeUnits(parseFloat(docRefId?.size ?? eSignTempsize ?? 0)) ??
					'-',
				createdAt,
				configured: true,
				isChecked: true,
				prepareUrl,
				templateId,
				documentId,
				provider: provider?.toLowerCase(),
				type: 'uploaded',
			};

			if (handleChange) {
				handleChange(newTemplate);
			} else {
				setAddedFiles(prev => {
					const prevState = JSON.parse(JSON.stringify(prev));
					return [newTemplate, ...prevState];
				});
			}
		},
		[handleChange, setAddedFiles, signedDocs]
	);

	useEffect(() => {

		setAddedFiles(prev => {
			const clonedPrev = structuredClone(prev);
			const updatedVal = updateNames(clonedPrev);
			return updatedVal;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [signedDocs]);

	return (
		<ReactDropdown
			isSearchable
			optionsStyle={{
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
			}}
			options={signedDocsOptions as any}
			value={docs as any}
			placeholder="Choose from existing Sign Doc Templates"
			handleChangeSelect={handleSelectDocument}
		/>
	);
};
