import { Button } from '@storybook';
import { SelectedMultipleDateState } from 'global-stores';
import { FC, useCallback } from 'react';
import { Calendar, DateObject } from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import { useRecoilState, useRecoilValue } from 'recoil';
import { SendNotificationState } from 'views/pipeline/store';
import './reminder-date.scss';

interface IReminderDate {
	format?: string;
}
export const ReminderDate: FC<IReminderDate> = ({ format = 'MM/DD/YYYY' }) => {
	const [selectedMultipleDate, setSelectedMultipleDate] = useRecoilState(
		SelectedMultipleDateState
	);
	const reminderDays = useRecoilValue(SendNotificationState);

	const handleOnChange = useCallback((ranges: DateObject[]) => {
		setSelectedMultipleDate(
			ranges.map(range => {
				return range.format();
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div
			className={`show_calender ${
				!reminderDays.checked ? 'blur_calender' : ''
			}`}
		>
			<Calendar
				value={selectedMultipleDate ?? []}
				onChange={handleOnChange}
				multiple
				disabled={!reminderDays.checked}
				format={format}
				plugins={[
					<DatePanel
						key=""
						className={`${!reminderDays.checked ? 'selected_day' : ''}`}
					/>,
				]}
				minDate={new Date()}
			/>
			<div className="date_button">
				<Button
					label="Apply"
					type="button__filled button__filled--primary button__large"
				/>
			</div>
		</div>
	);
};
