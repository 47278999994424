import { selector } from 'recoil';
import { ComplexSessionsNodesKycComperhensiveDetailsState } from 'global-stores';
import { kycComperhensiveFormatter } from '../../formatter';
import { IKycComperhensiveAPIResponseDetails } from '../../formatter/kyc-comperhensive-formatter/types';

export const kycComperhensiveSelector = selector<any>({
	key: 'kyc-comperhensive-selector',
	get: ({ get }) => {
		const state = get(ComplexSessionsNodesKycComperhensiveDetailsState);
		const kycComperhensive = kycComperhensiveFormatter(
			state as IKycComperhensiveAPIResponseDetails
		);
		return kycComperhensive as {
			kycComperhensiveData: any;
		};
	},
});
