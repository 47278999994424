import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import {
	AllowedInvitesState,
	IAllowedInvites,
	loginState,
} from 'global-stores';
import { useFreePlan, useSubscription } from 'hooks';
import { DashboardsAtom } from 'views/dashboard-analytics';
import { SignDocInboxSelector } from 'views/signed-doc-inbox';
import { AcountUserTypes } from 'views/settings';

type IServiceName = 'onboarding' | 'simpliciSign';

export const useAllowedInvites = () => {
	// globel states
	const dashboardData = useRecoilValue(DashboardsAtom);
	const allowedInvites = useRecoilValue(AllowedInvitesState);
	/**
	 * @Manish variable name changed
	 * */
	const { data: SimpliciDocs } = useRecoilValue(SignDocInboxSelector);
	const { sandboxStatus } = useRecoilValue(loginState);

	const {
		hasSubscribedBusiness = false,
		hasSubscribedPerson = false,
		accountType,
	} = useFreePlan();

	// hooks
	const { checkSubscription } = useSubscription();

	const {
		simpliciSign: SIMPLICI_SIGN,
		onboarding: ONBOARDING,
	}: IAllowedInvites = allowedInvites ?? {};

	const totalSessions = useMemo(() => {
		const { data } = dashboardData ?? {};
		const { summary } = data ?? {};
		const { session } = summary ?? {};

		const noOfSession =
			session?.find((sessionItem: any) => sessionItem.id === 'total')?.value ??
			0;
		return noOfSession;
	}, [dashboardData]);

	const SimpliciDocsData = SimpliciDocs?.map((docsData: any) => {
		const { purpose } = docsData;
		return purpose;
	});

	const FilteredSimpliciDocsData = SimpliciDocsData?.filter(
		item => item === 'workflow' || item === 'selfsign'
	);

	const isAllowedToInvite = (
		serviceName: IServiceName,
		listLength: number | undefined = 0
	) => {
		const serviceSubscribed = checkSubscription(serviceName);

		if (sandboxStatus) return true;

		if (serviceName === 'onboarding' && hasSubscribedBusiness) return true;

		if (
			serviceName === 'simpliciSign' &&
			(hasSubscribedPerson || hasSubscribedBusiness)
		)
			return true;

		if (
			serviceName === 'onboarding' &&
			accountType === AcountUserTypes.Entity
		) {
			if (listLength && totalSessions + listLength <= ONBOARDING) return true;
			if (!listLength && totalSessions < ONBOARDING) return true;
		}
		if (
			serviceName === 'simpliciSign' &&
			(FilteredSimpliciDocsData ?? []).length < SIMPLICI_SIGN &&
			[AcountUserTypes.Person, AcountUserTypes.Entity].includes(accountType)
		) {
			return true;
		}

		if (!serviceSubscribed) return false;

		return false;
	};
	return {
		isAllowedToInvite,
		totalSessions,
		totalSimpliciDocs: (FilteredSimpliciDocsData ?? []).length,
	};
};
