import { selector } from 'recoil';
import { OfacFormatter } from '../../formatter';

import { IOfacState } from './types';
import { ComplexSessionsNodesKybOfacDetailsState } from 'global-stores';

export const OfacSelector = selector<IOfacState>({
	key: 'ofac-selector',
	get: ({get}) => {
		const state = get(ComplexSessionsNodesKybOfacDetailsState)
		const ofac = OfacFormatter(state as any);
		return ofac as IOfacState;
	},
});

