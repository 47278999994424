import { useNetwork } from 'hooks';
import type { ICreateTemplatePayload, IDocProvider } from './type';

import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { SelectedComplexPipelineNode } from 'global-stores';
import { API_URL } from 'constant';
import { ONBOARDING_TYPE, SelectedOnboardingFlowSelector } from 'views/pipelines';

export const useGetSignDocument = () => {
	// const selectedOnboarding = useRecoilValue(SelectedPipelineDetails);
	const selectedComplexNode = useRecoilValue(SelectedComplexPipelineNode);
	const { onboardingType, complexNodes,
		linearActions } = useRecoilValue(
		SelectedOnboardingFlowSelector
	);

	const SignDocProvider = useMemo((): IDocProvider => {
		let provider: IDocProvider =  'esign';
		if (onboardingType === ONBOARDING_TYPE.COMPLEX) {
			provider =
				selectedComplexNode.actions?.[0]?.metadata?.signAgreementProvider ??
				'esign';
		} else {
			const signDocAction = linearActions?.find(
				(action: any) => action.stepId === 'signAgreement'
			);
			provider = signDocAction?.metadata?.signAgreementProvider ?? 'esign';
		}
		return provider;
	}, [linearActions, onboardingType, selectedComplexNode.actions]);

	const isAllSignDocNodesHaveEsignProvider = useMemo(() => {
		if(onboardingType === ONBOARDING_TYPE.COMPLEX ) {
			const allSignNodes = complexNodes?.filter((node: any) => node.stepId === "signAgreement");
			return allSignNodes?.every((node: any) => node.actions?.[0]?.metadata?.signAgreementProvider === "esign");
		}else {
			const signDocAction = linearActions?.find(
				(action: any) => action.stepId === 'signAgreement'
			);
			return signDocAction?.metadata?.signAgreementProvider === "esign"
		}
	}, [complexNodes, linearActions, onboardingType]);
	return { SignDocProvider, isAllSignDocNodesHaveEsignProvider };
};

export const useSetSignDocument = () => {
	const { patch, remove, post } = useNetwork();

	const createTemplate = useCallback(
		async (payload: ICreateTemplatePayload, param: string) => {
			const resp = await post(`/templates${param}`, payload);
			return resp;
		},
		[post]
	);

	const updateTemplate = useCallback(
		async (
			payload: ICreateTemplatePayload,
			tempId: string,
			isKycInFlow: boolean
		) => {
			const param = isKycInFlow ? '?kycFlow=true' : '';
			const resp = await patch(
				`${API_URL.SIGN_AGREEMENT}/${tempId}${param}`,
				payload
			);
			return resp;
		},
		[patch]
	);

	const deleteTemplate = useCallback(
		async (provider: IDocProvider, tempId: string) => {
			const queryParam = provider === 'docusign' ? '' : '?type=esign';
			await remove(`${API_URL.SIGN_AGREEMENT_DOC}/${tempId}${queryParam}`);
		},
		[remove]
	);

	return { createTemplate, updateTemplate, deleteTemplate };
};
