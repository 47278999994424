import { FC, ChangeEvent, useCallback, useEffect } from 'react';

import { InvestorsCountInput } from './components';
import { InvestorTable } from './components/investor-table';
import { useRecoilState, useRecoilValue } from 'recoil';
import { InvestorsCount, InvestorsDetails, useInvestorDetails } from './store';

import './investor-details-form.scss';
import { useNotification } from 'hooks';
interface IInvestorForm {
	updateInvestorCallback: (data: any) => void
}

export const InvestorForm: FC<IInvestorForm> = ({updateInvestorCallback}) => {
	const [investors, setInvestors] = useRecoilState(InvestorsCount);
	const investorsDetails = useRecoilValue(InvestorsDetails)
	const {disableExtraRows} = useInvestorDetails()
	const {errorNotification} = useNotification()

	const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		const isValid = /^(?:|undefined|0|[1-9]|[1-4][0-9]|50)$/.test(value);
		if(isValid) {
			setInvestors(value);
		}else {
			errorNotification("Owners/shareholders can not be more than 50 and less than 0")
		}
	},[errorNotification, setInvestors])

	useEffect(() => {
		if(investorsDetails) {
			updateInvestorCallback({count: investors, investorsDetails})
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[investorsDetails])

	useEffect(() => {
		if(investors) {
			disableExtraRows()
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[investors])

	return (
		<div className="investor-details__form-wrapper">
			<InvestorsCountInput
				handleChange={handleChange}
				value={investors as any}
				type="number"
				placeholder="please enter how many Shareholders/Owners do you have ?"
				label="How many Shareholders/Owners do you have ?"
			/>
			<InvestorTable />
		</div>
	);
};
