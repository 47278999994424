import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Image } from '@storybook';

import { pipelineMobileScreenBodySettings } from 'views/mobile-ui-screen/constants';
import { BodyWrapper } from '../common';
interface IKYCVerification {
	bodyName: string;
}
export const KYCVerification: FC<IKYCVerification> = ({ bodyName }) => {
	const [renderedObj, setRenderedObj] = useState<any>(null);
	const [activeStepName, setActiveStepName] = useState('');
	const [state, setState] = useState<any>({
		frontImage: null,
		backImage: null,
		btnDisabled: true,
	});

	const verificationContent = useMemo(
		() =>
			pipelineMobileScreenBodySettings[bodyName] ??
			pipelineMobileScreenBodySettings['driverLicenseVerificationSteps'],
		[bodyName]
	);

	const { blankImage } = verificationContent;

	const { title, label, subtitle, settings } = useMemo(
		() => renderedObj ?? {},
		[renderedObj]
	);

	const onClickButton = (step: string) => {
		setActiveStepName(step);
	};

	const renderSettings = useMemo(() => {
		return (
			<div className="ppl-screen-body__body__button-wrapper ">
				{settings?.map(
					({ className, label, step, isDisabled }: any, index: number) => {
						const active = isDisabled
							? state?.btnDisabled
								? true
								: false
							: false;
						return (
							<div
								className="ppl-screen-body__body__button-wrapper__btn"
								// eslint-disable-next-line react/no-array-index-key
								key={index}
							>
								<Button
									label={label}
									handleClick={() => onClickButton(step)}
									type={className}
									disabled={active}
								/>
							</div>
						);
					}
				)}
			</div>
		);
	}, [settings, state]);

	const uploadButton = useCallback((name: string, key: string) => {
		const onClickUploadButton = (event: any) => {
			const image = URL.createObjectURL(event?.target?.files[0]);
			setState((content: any) => {
				const data: any = { ...content };
				data[key] = image;
				data['btnDisabled'] = false;
				return data;
			});
		};
		return (
			<label onChange={() => ({})} htmlFor="formId" className="upload-wrapper">
				<input
					type="file"
					onChange={e => onClickUploadButton(e)}
					id="formId"
					hidden
				/>
				<div className="btn-icon-wrapper">
					<div className="camera" />
					<div className="upload-text">{name}</div>
				</div>
			</label>
		);
	}, []);

	const handleOnClickDelete = (key: string) => {
		setState((content: any) => {
			const data: any = { ...content };
			delete data[key];
			data['btnDisabled'] = true;
			return data;
		});
	};
	const step2Content = useCallback(
		(
			imgSrc: string,
			btnNamefirst: string,
			btnNameSecond: string,
			key: string
		) => {
			return (
				<div className="filled-card">
					{imgSrc ? (
						<Fragment>
							<img
								src={imgSrc}
								alt="dl-illustration-1"
								width="100%"
								height="100%"
							/>
							<Button
								label="Remove"
								handleClick={() => handleOnClickDelete(key)}
								type="button button__filled--secondary remove-img-btn"
							/>
						</Fragment>
					) : (
						<Fragment>
							<div className="filled-card__center">
								<Image fileName="blank-id-card.svg" />
							</div>
							<div className="filled-card__bottom">
								{uploadButton(btnNamefirst, key)}
								<div className="border-right" />
								{uploadButton(btnNameSecond, key)}
							</div>
						</Fragment>
					)}
				</div>
			);
		},
		[uploadButton]
	);

	const cardContent = useMemo(() => {
		switch (activeStepName) {
			case 'step1':
				return (
					<img
						loading="lazy"
						src={blankImage}
						alt="dl img"
						width="100%"
						height="100%"
					/>
				);
			case 'step2':
				return step2Content(
					state?.frontImage,
					'Camera',
					'Upload',
					'frontImage'
				);
			case 'step3':
				return step2Content(state?.backImage, 'Camera', 'Upload', 'backImage');
			default:
				return null;
		}
	}, [activeStepName, step2Content, state, blankImage]);

	const bodyContent = useMemo(() => {
		return (
			<div className="ppl-screen-body__body__card-wrapper">{cardContent}</div>
		);
	}, [cardContent]);

	const labelElement = useMemo(() => {
		return (
			<div className="ppl-screen-body__label-container">
				<div className="ppl-screen-body__label-container__text">{label}</div>
			</div>
		);
	}, [label]);

	const headerElement = useMemo(() => {
		return (
			<div className="ppl-screen-body__body__title-wrapper">
				<div className="ppl-screen-body__body__title-wrapper__title">
					{title}
				</div>
				<div className="ppl-screen-body__body__title-wrapper__subtitle">
					{subtitle}
				</div>
			</div>
		);
	}, [title, subtitle]);

	useEffect(() => {
		const currentStep = Object.keys(verificationContent)[0];
		if (currentStep) {
			setActiveStepName(currentStep);
			setRenderedObj(verificationContent[currentStep]);
		}
	}, [verificationContent]);

	useEffect(() => {
		setRenderedObj(verificationContent[activeStepName]);
	}, [activeStepName, verificationContent]);

	return (
		<BodyWrapper
			headerElement={headerElement}
			label={labelElement}
			bodyElement={bodyContent}
			footerElement={renderSettings}
		/>
	);
};
