export class Memory {
	private static sessionTimestamp: number;

	static setTimeStamp(timestamp: number) {
		Memory.sessionTimestamp = timestamp;
		return Memory.sessionTimestamp;
	}
	static getTimeStamp() {
		return Memory.sessionTimestamp;
	}
}
