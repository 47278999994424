import { useRecoilState, useRecoilValue } from 'recoil';
import { DropPaymentOptions } from '../../../payment-details/components/DropPaymentOption';
import { usePaymentCard } from 'views/routes-children';
import { PaymentCardMethodsState } from 'global-stores';
import { useNetwork, useNotification } from 'hooks';
import { useCallback, useMemo, useState } from 'react';
import { API_URL, getLogos } from 'constant';
import { Image, Loader } from '@storybook';
import { Banks } from '../../../type';
import { DefaultCardType } from '../../../constant';

export const AchListView = ({
	selectedLabel = 'Primary',
}: {
	selectedLabel?: string;
}) => {
	const paymentCardMethodsState = useRecoilValue(PaymentCardMethodsState);
	const { post } = useNetwork();
	const { getPaymentCardsApi } = usePaymentCard();
	const [, setPaymentCardMethodsState] = useRecoilState(
		PaymentCardMethodsState
	);
	const [isLoading, setIsLoading] = useState(false);
	const [loadingId, setLoadingId] = useState('');
	const { successNotification, errorNotification } = useNotification();

	const { banks = [] } = useMemo(
		() => paymentCardMethodsState,
		[paymentCardMethodsState]
	);
	const handleDefault = useCallback(
		async (id: string, accountId: string, type: string) => {
			setLoadingId(accountId);
			setIsLoading(true);
			const payload = {
				method: DefaultCardType.Ach,
				data: {
					id,
					accountId,
					accountType: type?.toUpperCase(),
				},
			};
			const defaultResp = await post(API_URL.Payment_Methods, payload);
			const { success } = defaultResp ?? {};
			if (success) {
				getPaymentCardsApi();
				successNotification(
					'ACH accounts have been set as primary successfully!'
				);
			}
			setLoadingId('');
			setIsLoading(false);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const handleDelete = useCallback(
		async (BankId: string, accountIdToDelete: string) => {
			// eslint-disable-next-line no-console
			console.log('banks-------->', BankId, accountIdToDelete);
			errorNotification(
				'Delete service is not active right now. Please try again later.'
			);
			//Logic For ACH delete

			// 	const resp = await removeCardDetail(`${API_URL.BUSINESS_CARD}/${accountIdToDelete}`);
			// 	const {deleted} = resp ?? {};
			// 	if(deleted){
			// 		const newBankArray = banks?.map( (item: { _id: string; accounts: any[]; }) =>{
			//             if(item._id === BankId){
			// 				item.accounts = item.accounts.filter((account:any) => account.accountId !== accountIdToDelete);
			// 			}
			// 		})
			// 		setPaymentCardMethodsState((prevState:any) => ({
			// 			...prevState,
			// 			banks: newBankArray ,
			// 		}));
			// 	}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[setPaymentCardMethodsState]
	);

	const defaultAccountId = useMemo(
		() => paymentCardMethodsState?.default?.detail?.accounts?.[0]?.accountId,
		[paymentCardMethodsState?.default?.detail?.accounts]
	);

	const formattedData = useMemo(() => {
		const result = [];
		for (let i = 0; i < banks?.length; i++) {
			for (let j = 0; j < banks[i]?.accounts?.length; j++) {
				const payload = {
					...banks[i].accounts[j],
					_id: banks[i]._id,
					bankName: banks[i].bankName,
				};
				result.push(payload);
			}
		}
		return result;
	}, [banks]);

	const renderList = (item: Banks) => {
		return (
			<li
				key={item.accountId}
				className={`ach-list-container  ${
					item.accountId ===
					paymentCardMethodsState?.default?.detail?.accounts?.[0]?.accountId
						? 'ach-list-container-primary'
						: ''
				}`}
			>
				<Image
					fileName={getLogos[item?.bankName] ?? getLogos['default']}
					className="payment-save-card-list__card-icon"
				/>
				<div className="ach-list-bank-detail-row">
					<div className="ach-detail">
						<div className="payment-save-card-list__number">
							{item.bankName}
						</div>
						<div className="payment-save-card-list__expire">
							{item?.name}
							(••••{item?.mask})
						</div>
					</div>
				</div>
				<div className="payment-save-card-primary-row">
					{item.accountId === defaultAccountId && (
						<div className="payment-save-card-primary">{selectedLabel}</div>
					)}
				</div>
				<div className="ach-list-detail-option-row">
					{item.accountId !== defaultAccountId &&
						(isLoading && loadingId === item.accountId ? (
							<Loader type="loader" dimension={20} />
						) : (
							<DropPaymentOptions
								handleView={() =>
									handleDefault(item._id, item.accountId, item.subtype)
								}
								handleDelete={() => handleDelete(item._id, item.accountId)}
								handleUseFlow={() => null}
								cardId={item._id}
								defaultCardId={paymentCardMethodsState?.default?.detail?.id}
								isHideDelete
							/>
						))}
				</div>
			</li>
		);
	};
	const mapRenderList = useMemo(
		() => formattedData.map(renderList),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[formattedData, isLoading, loadingId]
	);

	return <ul className="Ach--container__lists">{mapRenderList}</ul>;
};
