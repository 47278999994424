import { atom } from 'recoil';

export const PhoneVerificationsStatusState = atom({
	key: 'phone-verification-status-key',
	default: '',
});

export const EmailVerificationsStatusState = atom({
	key: 'email-verification-status-key',
	default: '',
});

export const mobileVerificationLinkState = atom({
	key: 'mobile-verification-link-key',
	default: {
		loaded: true,
		data: [],
		error: false,
	},
});

type ISteps = 'config' | 'success' | 'error';

export const mobileVerificationSteps = atom<ISteps>({
	key: 'mobile-verification-steps-key',
	default: 'config',
});
