import { FC, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { UploadDocs } from 'components';
import { Progress, UploadedFiles } from './components';
import { StatusUploadedDocsState } from '../../store';
import { useStatusDocuments } from '../../hooks';

import './status-docs.scss';

interface IStatusDocuments {
	footer?: JSX.Element;
	commentBox: JSX.Element;
}

export const StatusDocuments: FC<IStatusDocuments> = ({
	commentBox,
	footer,
}) => {
	// globle states
	const addedFiles = useRecoilValue(StatusUploadedDocsState);

	// hooks
	const { handleFileChange, onFileError, inProgressFileName } =
		useStatusDocuments();

	const { isLoaded: isFileUploaded, data: files } = useMemo(
		() => addedFiles,
		[addedFiles]
	);

	return (
		<div className="StatusDocuments__container">
			<div className="StatusDocuments__comment-title">
				{'Comments'}
				<span className="input__isRequired"> *</span>
			</div>
			{commentBox}
			<UploadDocs
				label="Upload files"
				handleChange={handleFileChange}
				accept=".pdf, .docx , .doc , .xls, .xlsx, .csv, .numbers"
				supportedFileDescription="Pdf, Csv, Xls, Xlsx, Numbers, Docs, and Doc"
				onError={onFileError}
			/>
			{!isFileUploaded && (
				<Progress label={inProgressFileName} uploadPercentage={10} />
			)}
			{!!files?.length && <UploadedFiles />}
			{footer}
		</div>
	);
};
