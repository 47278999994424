import { useCallback } from 'react';

import { Button } from '@storybook';
import { useAddBank } from '../stotes';

export const AddBankAccount = () => {
	// hook
	const { addNewBank } = useAddBank();

	// add new bank
	const handleAddBankAccount = useCallback(async () => {
		addNewBank();
	}, [addNewBank]);

	return (
		<>
			<div className="add-bank-wrapper">
				<div className="add-bank">
					<div className="add-bank__icon-wrapper">
						<i className="ri-bank-line" />
					</div>
					<div className="add-bank__message">No bank account available</div>
					<div className="add-bank__btn-wrapper">
						<Button
							label="Add Bank Account"
							handleClick={handleAddBankAccount}
							type="button__filled button__filled--primary"
						/>
					</div>
				</div>
			</div>
		</>
	);
};
