import { IKybDetails } from 'global-stores/sessions/type';

import { FC, useCallback, useMemo, useState } from 'react';

import { AreaLineChart, BasicBarChart } from '../charts';
import { IChartKeys, useKYBInsight } from './hooks';
import { AnnouncementDate } from './components';
import { KybInfoHeader } from '../kyb-info-header';

import './kyb-insight-details.scss';

interface IKYBInsightDetail {
	kyb: IKybDetails;
}

export const KYBInsightDetail: FC<IKYBInsightDetail> = ({ kyb }) => {
	const [viewMore, setViewMore] = useState<{ [key: string]: boolean }>({});

	const { kybInsight, getKYBAreaSeries, getKYBLineSeries } = useKYBInsight(kyb);

	const renderValuation = useCallback(
		(KYBkey: string, chartKeys: IChartKeys[]) => {
			if (Object.keys(kybInsight?.[KYBkey] ?? {})?.length) {
				return (
					<div className="Income-statement_container">
						<div className="Income-statement_header">
							<div className="Income-statement_header_title">
								{kybInsight[KYBkey]?.name}
							</div>
							<div
								className="Income-statement_header_view"
								onClick={() =>
									setViewMore((pre: any) => ({
										...pre,
										[KYBkey]: !pre?.[KYBkey] ?? false,
									}))
								}
							>
								View {viewMore?.[KYBkey] ? 'less' : 'more'}
							</div>
						</div>

						<div className="Income-statement_financial">
							{chartKeys.map((item: any, index) => {
								const isShowDetail =
									(index < 3 || viewMore?.[KYBkey]) &&
									kybInsight?.[KYBkey]?.metrics?.[item.key]?.data;

								if (isShowDetail) {
									if (item.type === 'line') {
										return (
											<BasicBarChart
												key={item.key}
												id={`${item.key}_${index}`}
												isLoading={false}
												color={item.color || '#2269D3'}
												{...getKYBLineSeries(KYBkey, item.key)}
											/>
										);
									} else if (item.type === 'date') {
										return (
											<AnnouncementDate
												KYBKey={KYBkey}
												dateKey={item.key}
												key={item.key}
												item={kyb}
											/>
										);
									} else {
										return (
											<AreaLineChart
												key={`${item.key[0]}__${item.key[1]}`}
												id={`${item.key[0]}_${index}_${item.key[1]}`}
												isLoading={false}
												{...getKYBAreaSeries(KYBkey, item.key)}
												{...(item?.title ? { title: item.title } : {})}
											/>
										);
									}
								} else {
									return <></>;
								}
							})}
						</div>
					</div>
				);
			} else {
				return <></>;
			}
		},
		[getKYBAreaSeries, getKYBLineSeries, kyb, kybInsight, viewMore]
	);

	const renderInsight = useMemo(() => {
		const headerData = Object.keys(kybInsight ?? {}).map(item => {
			const processedMetrics = Object.keys(kybInsight[item]?.metrics ?? {}).map(
				(chartKey: any) => {
					const metric = kybInsight[item]?.metrics[chartKey];
					const valueFormat = metric?.valueFormat?.post ?? metric?.valueFormat;
					const areaChart = [' x', '%'];
					let chartType = 'line';

					if (areaChart.includes(valueFormat)) {
						chartType = 'area';
					} else {
						chartType = 'line';
					}

					if (chartKey === 'announcementDate') {
						chartType = 'date';
					}

					const typeKey = chartType === 'area' ? [chartKey] : chartKey;

					return { type: chartType, key: typeKey };
				}
			);

			return renderValuation(item, processedMetrics as any);
		});

		return headerData;
	}, [kybInsight, renderValuation]);

	if (!Object.keys(kybInsight ?? {})?.length) {
		return <></>;
	}

	return (
		<div className="Income-statement">
			<KybInfoHeader type="financialDetails" />

			{renderInsight}
		</div>
	);
};
