import { GoogleLocation } from 'global-stores';
import { Address } from 'global-stores/google-fonts/types';
import { useGetLocationDetails } from 'hooks';
import { FC, useCallback, useEffect, useMemo} from 'react';
import { useRecoilValue} from 'recoil';

import { getESTDateWithTime } from 'utils';
import { ISignedDocInbox } from 'views/signed-doc-inbox';

interface IProps {
	inbox: ISignedDocInbox;
}

export const SignHistoryEnvelopeDetails: FC<IProps> = ({ inbox }) => {
	const {
		_id: envelopeId,
		status,
		name,
		documents,
		recipients,
		createdAt,
		updatedAt,
		sender,
	} = inbox ?? {};
	const googleLocation = useRecoilValue(GoogleLocation);
	const {formatted_address} = useMemo(()=>googleLocation?.address as Address ?? {},[googleLocation?.address])
	
	const { getGeoInfo } = useGetLocationDetails();
	const modifiedStatus = useMemo(() => {
		const allRecipientsCompleted = recipients.every(
			recipient => recipient.status === 'completed'
		);
		if (!status) {
			return 'NA';
		} else if (status === 'configured' && allRecipientsCompleted) {
			return 'Preparing Document';
		} else if (status == 'configured') {
			return 'Sent';
		} else if (status == 'completed') {
			return 'Completed';
		}
		return status;
	}, [recipients, status]);

	const senderLocation = useCallback(async () => {
		const { latitude, longitude } = sender?.biometric?.location ?? {};
		if (latitude && longitude) {
			getGeoInfo({ lat: latitude, lng: longitude });
		}
	}, [getGeoInfo, sender?.biometric?.location]);

	useEffect(() => {
		if (inbox) {
			senderLocation();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="details-container">
			<div className="details-container__label">Packet Details</div>
			<div className="details-container__content">
				<div className="details-container__row">
					<div className="details-item">
						<div className="details-item__icon details-item__icon--green">
							<i className="ri-mail-send-line"></i>
						</div>
						<div className="details-item__group">
							<div className="details-item__label">Subject</div>
							<div className="details-item__value">
								{name ? `${name}` : 'NA'}
							</div>
						</div>
					</div>
					<div className="details-item">
						<div className="details-item__icon details-item__icon--orange">
							<i className="ri-folder-2-line"></i>
						</div>
						<div className="details-item__group">
							<div className="details-item__label">Enclosed Documents</div>
							<div className="details-item__value">
								{documents?.map(item => item.document?.name).join(', ')}
							</div>
						</div>
					</div>
					<div className="details-item">
						<div className="details-item__icon details-item__icon--purple">
							<i className="ri-mail-lock-line"></i>
						</div>
						<div className="details-item__group">
							<div className="details-item__label">Packet ID</div>
							<div className="details-item__value">{envelopeId ?? 'NA'}</div>
						</div>
					</div>
					<div className="details-item">
						<div className="details-item__icon details-item__icon--pink">
							<i className="ri-group-line"></i>
						</div>
						<div className="details-item__group">
							<div className="details-item__label">Packet Recipients</div>
							<div className="details-item__value details-item__value--recipients">
								{recipients?.map(recipient => recipient.fullName).join(', ')}
							</div>
						</div>
					</div>
					<div className="details-item">
						<div className="details-item__icon details-item__icon--blue">
							<i className="ri-key-2-line"></i>
						</div>
						<div className="details-item__group">
							<div className="details-item__label">IP Address</div>
							<div className="details-item__value">
								{sender?.biometric?.ip ?? 'NA'}
							</div>
						</div>
					</div>
					<div className="details-item">
						<div className="details-item__icon details-item__icon--orange">
							<i className="ri-earth-line"></i>
						</div>
						<div className="details-item__group">
							<div className="details-item__label">Timezone</div>
							<div className="details-item__value">
								{sender?.biometric?.timeZone ?? 'NA'}
							</div>
						</div>
					</div>
				</div>
				<div className="details-container__row">
					<div className="details-item">
						<div className="details-item__icon details-item__icon--blue">
							<i className="ri-timer-2-line"></i>
						</div>
						<div className="details-item__group">
							<div className="details-item__label">Status</div>
							<div className="details-item__value">{modifiedStatus}</div>
						</div>
					</div>
					<div className="details-item">
						<div className="details-item__icon details-item__icon--pink">
							<i className="ri-calendar-event-fill"></i>
						</div>
						<div className="details-item__group">
							<div className="details-item__label">Status Date</div>
							<div className="details-item__value">
								{updatedAt ? getESTDateWithTime(updatedAt) : 'NA'}
							</div>
						</div>
					</div>
					<div className="details-item">
						<div className="details-item__icon details-item__icon--green">
							<i className="ri-user-line"></i>
						</div>
						<div className="details-item__group">
							<div className="details-item__label">Sender</div>
							<div className="details-item__value">
								{sender?.fullName ?? 'NA'}
							</div>
						</div>
					</div>
					<div className="details-item">
						<div className="details-item__icon details-item__icon--blue">
							<i className="ri-calendar-event-fill"></i>
						</div>
						<div className="details-item__group">
							<div className="details-item__label">Date Created</div>
							<div className="details-item__value">
								{createdAt ? getESTDateWithTime(createdAt) : 'NA'}
							</div>
						</div>
					</div>
					<div className="details-item">
						<div className="details-item__icon details-item__icon--purple">
							<i className="ri-map-pin-line"></i>
						</div>
						<div className="details-item__group">
							<div className="details-item__label">Location</div>
							<div className="details-item__value details-item__value--address">
								{formatted_address ?? 'NA'}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
