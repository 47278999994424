import { FC, ReactElement } from 'react';
import './question-label.scss';

interface IQUestionLabel {
	children?: ReactElement;
	label?: string;
	blockId?: string;
	onRemoveBlock?: (id: string) => () => void;
}
export const QuestionLabel: FC<IQUestionLabel> = ({
	children,
	label,
	blockId,
	onRemoveBlock,
}) => {
	return (
		<div className="question-label">
			<div className="question-label__left">
				<div className="question-label__icon">
					{children ?? <i className="ri-question-line"></i>}
				</div>
				<div className="question-label__label">
					{label ?? 'Condition block'}
				</div>
			</div>
			{onRemoveBlock && blockId && (
				<label
					htmlFor="Remove"
					className="question-label__right"
					onClick={onRemoveBlock(blockId)}
				>
					Remove
				</label>
			)}
		</div>
	);
};
