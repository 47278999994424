import { ChangeEvent, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ComplexInitPipelines } from 'views/complex-onboarding-flow/stores/states';
import { ComplexSettingFormState } from 'views/pipeline';
import { KYBFormData } from '../form-data';

function hasOnlySpecialCharater(str: string) {
	const pattern = /^[^a-zA-Z0-9]+$/;
	return pattern.test(str);
}

export const useKybForm = ({ nodeId }: { nodeId: string }) => {
	const [state, setState] = useRecoilState(ComplexSettingFormState);
	const [inputValue, setInputValue] = useState<{
		[key: string]: any;
	}>({});
	const [errorMessage, setErrorMessage] = useState('');

	const { stepDefaults } = useRecoilValue(ComplexInitPipelines);

	useEffect(() => {
		setState((prev: any) => {
			const newObj = structuredClone(prev);
			if (!newObj[nodeId]) {
				newObj[nodeId] = {};
				Object.assign(newObj[nodeId], {
					elements: stepDefaults?.kybForm?.values ?? KYBFormData?.elements,
				});
			}
			return newObj;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSaveDocument = (index: number) => {
		setState((prev: any) => {
			const newObj = structuredClone(prev);
			const value =  `Item${newObj[nodeId].elements[index].choices.length + 1}`;
			newObj[nodeId].elements[index].choices.push({
				value,
				status: 'pending',
				text: '',
				checked: false,
			});
			return newObj;
		});
	};

	const handleSaveEntity = () => {
		setState((prev: any) => {
			const newObj = structuredClone(prev);
			const name =  `question${newObj[nodeId].elements.length + 2}`;
			newObj[nodeId].elements.push({
				type: 'checkbox',
				name,
				status: 'pending',
				title: '',
				checked: false,
				choices: [],
			});
			return newObj;
		});
	};

	const handleChange = (
		event: ChangeEvent<HTMLInputElement>,
		parentIndex: number,
		chilIndex: number
	) => {
		setInputValue(prev => {
			const newObj: any = structuredClone(prev);
			if (!newObj?.[parentIndex]?.[chilIndex]) {
				const payload = {
					[parentIndex]: {
						...(newObj?.[parentIndex] ?? {}),
						[chilIndex]: {},
					},
				};

                Object.assign(newObj, payload);
			}
			Object.assign(newObj[parentIndex][chilIndex], {
				value: event.target.value,
			});

			return newObj;
		});

		// setInputValue(event.target.value);
		setErrorMessage('');
		setState((prev: any) => {
			const newObj = structuredClone(prev);
			newObj[nodeId].elements[parentIndex].choices[chilIndex].text =
				event.target.value;
			return newObj;
		});
	};

	const handleSaveDocuments = (parentIndex: number, chilIndex: number) => {
		if (!inputValue?.[parentIndex]?.[chilIndex]?.value?.trim()) {
			setErrorMessage('This field can not be empty');
			return;
		}
		if (
			hasOnlySpecialCharater(
				inputValue?.[parentIndex]?.[chilIndex]?.value?.trim() ?? ''
			)
		) {
			setErrorMessage('Only special characters are not allowed');
			return;
		}
		setState((prev: any) => {
			const newObj = structuredClone(prev);
			delete newObj[nodeId].elements[parentIndex].choices[chilIndex].status;
			return newObj;
		});
	};

	const deleteDocument = (index: number) => {
		setState((prev: any) => {
			const newObj = structuredClone(prev);
			newObj[nodeId].elements.splice(index, 1);
			return newObj;
		});
	};

	const handleDeleteDocuments = (parentIndex: number, childIndex: number) => {
		setState((prev: any) => {
			const newObj = structuredClone(prev);
			newObj[nodeId].elements[parentIndex].choices.splice(childIndex, 1);
			return newObj;
		});
	};

	const handleChangeCheckbox = (
		type: string,
		index: number,
		childIndex?: number
	) => {
		if (type === 'parent') {
			setState((prev: any) => {
				const newObj = structuredClone(prev);
				newObj[nodeId].elements[index].checked =
					!newObj[nodeId].elements[index].checked;

				newObj[nodeId].elements[index].choices = newObj[nodeId].elements[
					index
				].choices.map((el: any) => {
					return { ...el, checked: newObj[nodeId].elements[index].checked };
				});

				return newObj;
			});
		}
		if (type === 'child' && childIndex !== null && childIndex !== undefined) {
			setState((prev: any) => {
				const newObj = structuredClone(prev);
				if (!newObj[nodeId].elements[index].choices[childIndex].checked) {
					newObj[nodeId].elements[index].checked = true;
				}
				newObj[nodeId].elements[index].choices[childIndex].checked =
					!newObj[nodeId].elements[index].choices[childIndex].checked;

				return newObj;
			});
		}
	};

	const handleDocument = (index: number) => {
		if (!inputValue[index]?.value?.trim()) {
			setErrorMessage('This field can not be empty');
			return;
		}

		if (hasOnlySpecialCharater(inputValue[index]?.value?.trim())) {
			setErrorMessage('Only special characters are not allowed');
			return;
		}

		setState((prev: any) => {
			const newObj = structuredClone(prev);
			newObj[nodeId].elements[index].status = 'completed';
			return newObj;
		});
	};

	const handleChangeDocument = (
		e: ChangeEvent<HTMLInputElement>,
		index: number
	) => {
		e.preventDefault();

		setInputValue((prev: any) => {
			const newObj: any = structuredClone(prev);
			if (!newObj[index]) {
				newObj[index] = {};
			}
			Object.assign(newObj[index], { value: e.target.value });
			return newObj;
		});
		setErrorMessage('');
		setState((prev: Record<string, string>) => {
			const newObj = JSON.parse(JSON.stringify(prev));
			newObj[nodeId].elements[index].title = e.target.value;
			return newObj;
		});
	};

	return {
		handleSaveDocument,
		handleSaveEntity,
		handleChange,
		handleSaveDocuments,
		handleChangeDocument,
		handleDocument,
		handleChangeCheckbox,
		handleDeleteDocuments,
		deleteDocument,
		state,
		errorMessage,
	};
};
