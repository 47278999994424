import { ChangeEvent, MouseEvent, useCallback, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import classNames from 'classnames';

import { API_URL } from 'constant';
import { kycSettingsState } from 'global-stores';
import { useNetwork, useNotification } from 'hooks';
import { convertFileToBase64 } from 'utils';
import {
	ALLOWED_FILE_SIZE,
	ALLOWED_FILE_TYPES,
	ISelectedLoader,
	IUploadComponent,
	LOADER_KEYS,
} from 'views/settings/general-settings/components';

export const useLoadingAnimation = () => {
	// globel states
	const [settingsKyc, setSettingKyc] = useRecoilState(kycSettingsState);

	const {
		loaderWhitelabel,
		loader,
		_id: clientId,
	} = useMemo(() => settingsKyc ?? {}, [settingsKyc]);

	const updateStorage = useCallback((payload: any, catchPayload?: any) => {
		const localStorageData = localStorage.getItem('session-loader');
		const localObject = localStorageData && JSON.parse(localStorageData);
		try {
			const json = JSON.stringify({
				...(localObject ?? {}),
				...(payload ?? {}),
			});
			localStorage.setItem('session-loader', json);
		} catch (e) {
			const json = JSON.stringify({
				...(localObject ?? {}),
				...(catchPayload ?? {}),
			});
			localStorage.setItem('session-loader', json);
		}
	}, []);

	const initialSelectedLoader = useMemo(
		() =>
			(loaderWhitelabel && loader
				? LOADER_KEYS.Custom
				: LOADER_KEYS.Default) as ISelectedLoader,
		[loader, loaderWhitelabel]
	);
	const initialUploadComponent = useMemo(
		() =>
			(loader ? LOADER_KEYS.Uploaded : LOADER_KEYS.Upload) as IUploadComponent,
		[loader]
	);

	//local states
	const [selectedLoader, setSelectedLoader] = useState<ISelectedLoader>(
		initialSelectedLoader
	);
	const [uploadComponent, setUploadComponent] = useState<IUploadComponent>(
		initialUploadComponent
	);
	const [showEditOptions, setShowEditOptions] = useState(false);
	const [isFirstUpload, setIsFirstUpload] = useState(false);
	const [showConfirmation, setShowConfirmation] = useState(false);

	// hook
	const { post } = useNetwork();
	const { remove: removeLoader, loading: removing } = useNetwork();
	const { errorNotification } = useNotification();

	const handleLoaderRequest = useCallback(
		async (requestJSON: any) => {
			const payload = requestJSON ?? {};
			const response = await post(API_URL.CLIENT_DETAILS, payload);
			if (response?._id)
				setSettingKyc(prev => ({
					...prev,
					loaderWhitelabel: response?.loaderWhitelabel,
				}));
			updateStorage({ loaderWhitelabel: response?.loaderWhitelabel });
			return response;
		},
		[post, setSettingKyc, updateStorage]
	);

	const isMp4 = useCallback((url: string) => {
		if (!url) return false;

		// Check if the URL is a Base64-encoded MP4
		if (url.startsWith('data:video/mp4;base64,')) return true;

		// Extract the file extension from the URL
		const fileExtension =
			url.split('?')?.[0]?.split('.')?.pop()?.toLowerCase() ?? '';

		return fileExtension === 'mp4';
	}, []);

	const handleSelectLoader = useCallback(
		async(loaderType: ISelectedLoader) => {
			// Early return if the selected loader is already active or upload is in progress
			if (
				selectedLoader === loaderType ||
				uploadComponent === LOADER_KEYS.Loading
			) {
				return;
			}

			const isDefaultLoader = loaderType === LOADER_KEYS.Default;
			const payload = {
				loaderWhitelabel: !isDefaultLoader,
			};

			if (isDefaultLoader) {
				const resp = await handleLoaderRequest(payload);
				if(resp?._id) setSelectedLoader(LOADER_KEYS.Default as ISelectedLoader);
			} else if (loader) {
				const resp = await handleLoaderRequest(payload);
				if(resp?._id) setSelectedLoader(LOADER_KEYS.Custom as ISelectedLoader);
			} else {
				setIsFirstUpload(true);
				document.getElementById('custom-loading-animation')?.click();
			}
		},
		[handleLoaderRequest, loader, selectedLoader, uploadComponent]
	);
	const handleEditLoader = useCallback(() => {
		if (uploadComponent !== LOADER_KEYS.Loading) {
			setShowEditOptions(prev => !prev);
		}
	}, [uploadComponent]);

	const handleLeaderOptions = useCallback(async (optionType: string) => {
		switch (optionType) {
			case LOADER_KEYS.Edit:
				document.getElementById('custom-loading-animation')?.click();
				break;
			case LOADER_KEYS.Remove:
				setShowConfirmation(true);
				break;
			default:
				break;
		}
	}, []);

	const optionCartClasses = useCallback(
		(key: string) =>
			classNames(
				'LoadingAnimation__option-card',
				selectedLoader === key && 'LoadingAnimation__option-selectedCart',
				uploadComponent === LOADER_KEYS.Loading && 'LoadingAnimation__disabled'
			),
		[selectedLoader, uploadComponent]
	);

	const checkBoxClasses = useCallback(
		(key: string) =>
			classNames(
				selectedLoader === key
					? 'ri-checkbox-circle-fill LoadingAnimation__checkbox-checked'
					: 'ri-checkbox-blank-circle-line LoadingAnimation__checkbox-unchecked'
			),
		[selectedLoader]
	);

	const dropOptionProps = useMemo(
		() => ({
			options: [
				{
					title: 'Upload new',
					handleOption: () => handleLeaderOptions(LOADER_KEYS.Edit),
					icon: 'ri-edit-line',
				},
				{
					title: 'Remove',
					handleOption: () => handleLeaderOptions(LOADER_KEYS.Remove),
					icon: 'ri-delete-bin-line',
				},
			],
			menuIcon: 'ri-pencil-line',
		}),
		[handleLeaderOptions]
	);

	const handleFileUpload = useCallback(
		async (e: ChangeEvent<HTMLInputElement> | any) => {
			const file = e.target.files[0];
			const { type: fileType = '', size: fileSize = 0 } = file ?? {};

			// Check if the file type is allowed
			if (!ALLOWED_FILE_TYPES[fileType]) {
				errorNotification(
					'Invalid loader format. Allowed formats: [GIF, MP4].'
				);
				setIsFirstUpload(false);
				e.target.value = null;
				return;
			}

			// Check if the file size exceeds the allowed limit
			if (fileSize > ALLOWED_FILE_SIZE) {
				errorNotification('File size exceeds the maximum limit.');
				setIsFirstUpload(false);
				e.target.value = null;
				return;
			}

			setUploadComponent(LOADER_KEYS.Loading as IUploadComponent);
			const loader: any = await convertFileToBase64(file);
			const loaderImage = loader?.toString() ?? '';
			const payload = {
				loader,
				loaderWhitelabel: isFirstUpload || loaderWhitelabel,
			};
			const response = await handleLoaderRequest(payload);
			const { loader: loaderAnimation } = response ?? {};
			if (loaderAnimation) {
				setSettingKyc(prev => ({ ...prev, loader: loaderImage }));
				updateStorage({ loader: loaderImage }, { loader: loaderAnimation });
				if (isFirstUpload) {
					setSelectedLoader(LOADER_KEYS.Custom as ISelectedLoader);
				}
			}
			if (loaderAnimation || settingsKyc?.loader) {
				setUploadComponent(LOADER_KEYS.Uploaded as IUploadComponent);
			} else {
				setSettingKyc(prev => ({ ...prev, loader: '' }));
				updateStorage({ loader: '' });
				setSelectedLoader(LOADER_KEYS.Default as ISelectedLoader);
				setUploadComponent(LOADER_KEYS.Retry as IUploadComponent);
			}
			setIsFirstUpload(false);
			e.target.value = null; // Reset the file input
		},
		[
			errorNotification,
			handleLoaderRequest,
			isFirstUpload,
			loaderWhitelabel,
			setSettingKyc,
			settingsKyc?.loader,
			updateStorage,
		]
	);

	const handleConfirmationModal = useCallback(
		async (isOpen: boolean, value: boolean) => {
			if (value) {
				const response = await removeLoader(
					`${API_URL.CLIENT_DETAILS}/${clientId}?removeLoader=true`
				);
				if (response?._id) {
					setSettingKyc(prev => ({
						...prev,
						loader: '',
						loaderWhitelabel: false,
					}));
					updateStorage({ loader: '', loaderWhitelabel: false });
					setUploadComponent(LOADER_KEYS.Upload as IUploadComponent);
					setSelectedLoader(LOADER_KEYS.Default as ISelectedLoader);
					setShowConfirmation(isOpen);
				}
			} else setShowConfirmation(isOpen);
		},
		[clientId, removeLoader, setSettingKyc, updateStorage]
	);

	const confirmationProps = useMemo(
		() => ({
			title: 'Are you sure',
			visible: showConfirmation,
			description: 'You want to remove this custom loader.',
			handleModal: handleConfirmationModal,
			boldDescription: '',
			label: 'Remove',
			type: 'primary',
			loading: removing,
		}),
		[handleConfirmationModal, removing, showConfirmation]
	);

	const handleClickOnUpload = useCallback(
		(e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
			e.stopPropagation();
			setIsFirstUpload(false);
			handleLeaderOptions(LOADER_KEYS.Edit)
		}
	,[handleLeaderOptions]);

	return {
		uploadComponent,
		selectedLoader,
		showEditOptions,
		optionCartClasses,
		handleSelectLoader,
		handleEditLoader,
		dropOptionProps,
		handleLoaderRequest,
		handleFileUpload,
		loaderAnimation: loader,
		checkBoxClasses,
		confirmationProps,
		isMp4,
		handleClickOnUpload,
		updateStorage,
	};
};
