export const SandboxView = () => {
	return (
		<div className="SandboxView">
			<div className="subscriptions__acount-type subscriptions__buisness  subscriptions__buisness-acount SandboxView--left">
				<i className="ri-inbox-unarchive-line" />
			</div>
			<div className="SandboxView--right">
				<h4>You are in Sandbox mode</h4>
				<p>Switch to Live mode to upgrade your account.</p>
			</div>
		</div>
	);
};

export default SandboxView;
