import { Button } from '@storybook';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNetwork, useNotification, useTimer } from 'hooks';
import { useRecoilValue } from 'recoil';
import {
	EmailCredsState,
	MfaTokenForVerifyState,
} from 'components/login/store';
import { API_URL } from 'constant';
import { Messages } from '../signin-form/constant';
import { currentVerifySteps } from 'views/authentication/components/contants';
import { EmailVerification as EmailVerificationSignup, useSignup } from 'views';
import './email-verification.scss';

let userApiCall: any = null;
export const EmailVerification = () => {
	const [count, setCount] = useState(1);
	const loginCreds = useRecoilValue(EmailCredsState);

	const { get: reinviteUser } = useNetwork();
	const { get: verificationCheck } = useNetwork();
	const { fetchUserDetails } = useSignup();
	const tokenForVerify = useRecoilValue(MfaTokenForVerifyState);

	const { successNotification, errorNotification } = useNotification();
	const [isUserVerified, setIsUserVerified] = useState<any>();
	const [currentStep, setCurrentStep] = useState(
		currentVerifySteps.verifyProgress
	);
	const { seconds, setSeconds } = useTimer();
	const isResendAllowed = useMemo(() => !seconds, [seconds]);

	const formattedSeconds = useMemo(
		() => (seconds < 10 ? `0${seconds}` : seconds),
		[seconds]
	);

	useEffect(() => {
		userApiCall = setInterval(() => {
			if (!isUserVerified?.isVerifiedEmail) {
				verificationCheck(
					`${API_URL.USER_VERIFICATION}?email=${encodeURIComponent(loginCreds)}`
				).then(res => {
					if (res?.message === 'ok') {
						setIsUserVerified(res?.data[0]);
					}
					if (res?.data[0]?.phoneNotificationStatus === 'rejected') {
						setIsUserVerified(res?.data[0]);
						setCurrentStep(currentVerifySteps.verifyReject);
						clearInterval(userApiCall);
					}
					if (res?.data[0]?.emailNotificationStatus === null) {
						setIsUserVerified(res?.data[0]);
						setCurrentStep(currentVerifySteps.verifyTimeOut);
						clearInterval(userApiCall);
					}
				});
			} else {
				return clearInterval(userApiCall);
			}
		}, 1000);
		return () => clearInterval(userApiCall);
	}, [isUserVerified, errorNotification, verificationCheck, loginCreds]);

	useEffect(() => {
		if (tokenForVerify && isUserVerified?.isVerifiedEmail) {
			fetchUserDetails(tokenForVerify);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isUserVerified?.isVerifiedEmail]);

	const handleResendEmail = useCallback(async () => {
		if (!isResendAllowed || count > 3) return;

		setCount(prev => prev + 1);
		if (count === 1) {
			setSeconds(40);
		} else if (count === 2) {
			setSeconds(60);
		}
		const response = await reinviteUser(
			`${API_URL.USER_ROLES}?email=${encodeURIComponent(
				loginCreds
			)}&sendEmail=true`
		);

		if (response.message?.toLowerCase() === 'ok') {
			successNotification('A verification link has been sent to your email ');
		} else {
			errorNotification(response?.message ?? Messages.UnExpectedError);
			clearInterval(userApiCall);
			setCurrentStep(currentVerifySteps.verifyReject);
		}
	}, [
		count,
		errorNotification,
		isResendAllowed,
		loginCreds,
		reinviteUser,
		setSeconds,
		successNotification,
	]);

	const initResendEmail = useCallback(async () => {
		setCount(prev => prev + 1);
		setSeconds(20);
		const response = await reinviteUser(
			`${API_URL.USER_ROLES}?email=${encodeURIComponent(
				loginCreds
			)}&sendEmail=true`
		);

		if (response.message?.toLowerCase() === 'ok') {
			successNotification('A verification link has been sent to your email ');
		} else {
			errorNotification(response?.message ?? Messages.UnExpectedError);
			clearInterval(userApiCall);
			setCurrentStep(currentVerifySteps.verifyReject);
		}
	}, [
		errorNotification,
		loginCreds,
		reinviteUser,
		setSeconds,
		successNotification,
	]);

	useEffect(() => {
		initResendEmail();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onClickIntercom = () => {
		(window as any)?.Intercom?.(
			'showNewMessage',
			'Unable to Login, Not receiving message'
		);
	};

	const handleRetry = useCallback(async () => {
		setCurrentStep(currentVerifySteps.verifyProgress);
		// reset timer after re try
		setSeconds(20);
		setCount(0);
		const response = await reinviteUser(
			`${API_URL.USER_ROLES}?email=${encodeURIComponent(
				loginCreds
			)}&sendEmail=true`
		);

		if (response.message?.toLowerCase() === 'ok') {
			successNotification('A verification link has been sent to your email ');
		} else {
			errorNotification(response?.message ?? Messages.UnExpectedError);
		}
	}, [
		errorNotification,
		loginCreds,
		reinviteUser,
		setSeconds,
		successNotification,
	]);

	const verifyRejectContent = useMemo(() => {
		return (
			<>
				<div className="verify-modal__image">
					<i className="ri-close-circle-fill" />
				</div>
				<div className="verify-modal__verify-text-container">
					<div className="verify-modal__title-wrapper">
						<span className="title">Phone number verification failed</span>
						<div className="sub-title">
							<span>Your phone number verification has failed.</span>
						</div>
					</div>
					<div style={{ width: '121px' }}>
						<Button
							label={'Retry'}
							type="button__filled button__filled--secondary button__small button__block"
							handleClick={handleRetry}
						/>
					</div>
				</div>
			</>
		);
	}, [handleRetry]);

	const renderBody = useMemo(() => {
		switch (currentStep) {
			case currentVerifySteps.verifyProgress:
				return (
					<div className="mobile-link">
						<div className="mobile-link__wrapper">
							<EmailVerificationSignup
								resendCount={count}
								isResendAllowed={isResendAllowed}
								handleResend={handleResendEmail}
								formattedSeconds={formattedSeconds}
								onClickIntercome={onClickIntercom}
								email={loginCreds}
							/>
						</div>
					</div>
				);
			case currentVerifySteps.verifyReject:
				return verifyRejectContent;
			default:
				return (
					<div className="mobile-link">
						<div className="mobile-link__wrapper">
							<EmailVerificationSignup
								resendCount={count}
								isResendAllowed={isResendAllowed}
								handleResend={handleResendEmail}
								formattedSeconds={formattedSeconds}
								onClickIntercome={onClickIntercom}
								email={loginCreds}
							/>
						</div>
					</div>
				);
		}
	}, [
		count,
		currentStep,
		formattedSeconds,
		handleResendEmail,
		isResendAllowed,
		loginCreds,
		verifyRejectContent,
	]);

	return <div className="EmailVerification">{renderBody}</div>;
};
