import { atom } from 'recoil';

interface ICredentials {
	name: string;
	client: string;
	createdAt: string;
	clientId: string;
	clientSecret: string;
	updatedAt: string;
	_id: string;
}

export interface IOptionsRow {
	action: () => void;
	createdAt: string;
	name: string;
	_id: string;
}
export const ClientsCredentialsState = atom<ICredentials[]>({
	key: 'clients-credentials-key',
	default: [],
});

export const ClientNameState = atom<string>({
	key: 'clients-credential-name-key',
	default: '',
});

export const duplicateClientNameCheckState = atom<boolean>({
	key: 'duplicate-client-name-check-state',
	default: false,
});

export const CredentialsLoadedState = atom({
	key: 'credentials-loaded',
	default: false,
});
export const CredentialTableRow = atom<IOptionsRow[]>({
	key: 'credentials-tables',
	default: [],
});
