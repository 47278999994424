import { FC } from 'react';
import { ComprehensiveCardType } from '../kyb-comprehensive';
import { BasicSection } from './basic-section';
import { CardSection } from './card-section';
import { ComprehensiveHeader } from './comprehensive-header';
import { InfoSection } from './info-section';
import { TabsSection } from './tabs-section';

interface IProps {
	type: ComprehensiveCardType;
	data: {
		label: string;
		value: string;
	};
}

export const ComprehensiveComponentTypes: FC<IProps> = ({ type, data }) => {
	switch (type) {
		case 'basic':
			return <BasicSection {...data} />;
		case 'card':
			return <CardSection {...data} />;
		case 'header':
			return <ComprehensiveHeader {...data} />;
		case 'info':
			return <InfoSection {...data} />;
		case 'tab':
			return <TabsSection {...data} />;
		default:
			return <></>;
	}
};
