import { Image } from '@storybook';
import { useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { PaymentCardMethodsState, SubscriptionPriceState } from 'global-stores';
import { usePaymentCard } from 'views/routes-children';
import {
	ChooseIdentityData,
	ChooseUserIdentity,
} from 'views/user-identity-flow';
import { DefaultCardType } from '../constant';
import { BankCard, Card, WireCard } from '../payment-details/components';
import './pay-subscription-amount.scss';

export const PaySubscriptionAmount = () => {
	const activeIdx = useRecoilValue(ChooseUserIdentity);
	const paymentCardMethodsState = useRecoilValue(PaymentCardMethodsState);
	const { getPaymentCardsApi, loading } = usePaymentCard();
	const subscriptionPrice = useRecoilValue(SubscriptionPriceState);

	const price = useMemo(() => {
		if (subscriptionPrice) {
			return (subscriptionPrice as any)[0]?.[activeIdx];
		} else return 0;
	}, [activeIdx, subscriptionPrice]);

	const defaultMethod = useMemo(() => {
		const data = paymentCardMethodsState?.default;
		return data;
	}, [paymentCardMethodsState?.default]);

	const accountTypeLabel = useMemo(
		() => ChooseIdentityData.find(el => el.type === activeIdx),
		[activeIdx]
	);
	const methodName = useMemo(() => {
		const data = defaultMethod?.method;
		return data;
	}, [defaultMethod?.method]);

	useEffect(() => {
		getPaymentCardsApi();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const renderDefaultMethod = useMemo(() => {
		switch (methodName) {
			case DefaultCardType.Ach:
				return <BankCard result={defaultMethod?.detail} loading={loading} />;
			case DefaultCardType.Card:
				return <Card result={defaultMethod?.detail} loading={loading} />;
			default:
				return <WireCard result={defaultMethod?.detail} loading={loading} />;
		}
	}, [defaultMethod?.detail, loading, methodName]);

	return (
		<div className=" PaySubscriptionAmount--container">
			<div className="review-amount">
				<div className="review-amount__heading">Subscribing for</div>
				<div className="added-card">
					<Image
						fileName={
							accountTypeLabel?.type === 'individual'
								? '2-person.svg'
								: 'Icon_Wrapper.svg'
						}
					/>

					<div className="added-card__subContainer">
						<div className="card-subheading">
							<div className="card-subheading__header">
								{accountTypeLabel?.label}
							</div>
						</div>
						<div className="add-card-price-container">
							<span className="add-card-price-container__price">${price}</span>
							<span className="add-card-price-container__per-month">
								/month
							</span>
						</div>
					</div>
				</div>
			</div>

			<div className="review-amount">
				<div className="review-amount__heading">Paying from</div>
				<div className="added-card">{renderDefaultMethod}</div>
			</div>
		</div>
	);
};
