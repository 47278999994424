import { Image } from '@storybook';
import classNames from 'classnames';
import { FC, useCallback, useEffect, useMemo, Fragment } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Message } from 'components';
import {
	ClientsCredentialsState,
	EmailMessageReminderValue,
	IMetadata,
	PipelineSettingsFormState,
	SelectedOnboardingAction,
} from 'global-stores';
import { AddEmailClient } from 'views/email-client';
import {
	OnboardingFooterNavigateState,
	useOnboardingHook,
} from 'views/onboarding-flow/store';
import {
	InputComponent,
	NavigateStepper,
	RadioSelection,
	ReminderPage,
	SelectComponent,
	TextAreaComponent,
	ToggleComponent,
	defaultCountry,
	defaultLang,
	onboardingPublishMessage,
	supportedCountries,
	supportedLang,
} from '.';
import { ComplexSettingFormState, usePipelineSettingsPage } from '../store';
import { ComplexPriceBreakDown } from './complex-price-breakdown';

const hideParentComponents: {[key: string]: boolean} = {
	appStoreUrl: true,
	playStoreUrl: true,
	customRedirectUrl: true,
	customRedirectBtnText: true
}

interface IPipelineSettings {
	title: string;
	description: string;
	metadata: IMetadata[] | any;
	actionKey: string;
	isTitleVisible?: boolean;
	id?: string;
}

export const PipelineSettings: FC<IPipelineSettings> = ({
	title,
	description,
	metadata,
	actionKey,
	isTitleVisible = true,
	id,
}) => {
	//global state
	const credentials = useRecoilValue(ClientsCredentialsState);
	const [settingsForm, setSettingsForm] = useRecoilState(
		PipelineSettingsFormState
	);
	const navigate = useRecoilValue(OnboardingFooterNavigateState);
	const emailMessageReminderValue = useRecoilValue(EmailMessageReminderValue);
	const { type } = useRecoilValue(SelectedOnboardingAction);
	const [complexSettingForm, setComplexSettingForm] = useRecoilState(
		ComplexSettingFormState
	);
	const { onboardingNameWithTime } = useOnboardingHook();
	const {renderChildComponents} = usePipelineSettingsPage()

	// Please donot remove the commented code for Now, Might be for future Use.
	const getSelectOptions = useCallback(
		(key: string) => {
			// const { accreditationType } = complexSettingForm[id ?? ''] ?? {};
			const dropdownOptions = credentials.map(({ _id, name }) => {
				return { label: name, value: _id };
			});
			switch (key) {
				case 'fundInvestmentCountry':
					return supportedCountries;
				case 'fundInvestmentLanguage':
					return supportedLang;
				case 'accreditationType':
					// if (isComplexFlowSelected && accreditationType) {
					// 	return accreditationType;
					// }
					return metadata?.[0]?.options ?? [];
				case 'accreditationDocument':
					return metadata[1].options ?? [];
				case 'signAgreementProvider':
					return metadata?.[0]?.options ?? [];
				case 'reminderSettingTrigger':
					return metadata?.[0]?.options?.[3] ?? [];
				case 'payInPayOut':
					return (
						metadata?.find((item: IMetadata) => item.key === 'payInPayOut')
							?.options ?? []
					);
				default:
					return dropdownOptions;
			}
		},
		[credentials, metadata]
	);

	const getDefaultValue = useCallback(
		(key: string) => {
			switch (key) {
				case 'fundInvestmentCountry':
					return defaultCountry;
				case 'fundInvestmentLanguage':
					return defaultLang;
				case 'payInPayOut':
					return metadata?.find((el: any) => el.key === key)?.default;
				case 'accreditationType':
					return metadata[0].options?.[0] ?? [];
				case 'accreditationDocument':
					return metadata[1].options?.[0] ?? [];
				case 'signAgreementProvider':
					return metadata?.find((el: any) => el.key === key)?.default;
				case 'reminderSettingTrigger':
					return metadata[0].options?.[3] ?? [];
				default:
					break;
			}
		},
		[metadata]
	);

	const defaultVal = useCallback(
		(name: string, defaultValue: string) => {
			switch (name) {
				case 'accreditationType':
					if (settingsForm.accreditationType === '') {
						const newState = {
							...settingsForm,
							accreditationType: defaultValue,
						};
						setSettingsForm(newState);
					}
					break;
				case 'signAgreementProvider':
					if (typeof settingsForm?.signAgreementProvider === 'string') {
						const newState = {
							...settingsForm,
							signAgreementProvider: defaultValue,
						};
						setSettingsForm(newState);
					}
					break;
				case 'accreditationDocument':
					if (
						settingsForm.accreditationDocument === '' &&
						settingsForm.accreditationType.value === '506b'
					) {
						const newState = {
							...settingsForm,
							accreditationDocument: defaultValue,
						};
						setSettingsForm(newState);
					}
					break;
				case 'fundInvestmentCountry':
					if (settingsForm.fundInvestmentCountry === '') {
						const newState = {
							...settingsForm,
							fundInvestmentCountry: defaultValue,
						};
						setSettingsForm(newState);
					}
					break;
				case 'fundInvestmentLanguage':
					if (settingsForm.fundInvestmentLanguage === '') {
						const newState = {
							...settingsForm,
							fundInvestmentLanguage: defaultValue,
						};
						setSettingsForm(newState);
					}
					break;
				case 'reminderSettingTrigger':
					if (settingsForm.reminderSettingTrigger === '') {
						const newState = {
							...settingsForm,
							reminderSettingTrigger: defaultValue,
						};
						setSettingsForm(newState);
					}
					break;
				default:
					break;
			}
		},
		[setSettingsForm, settingsForm]
	);
	const isInputDisabled = useCallback(
		(key: string) => {
			if (
				key === 'reminderSettingEmailSubject' ||
				key === 'reminderSettingEmailMessage'
			) {
				return !emailMessageReminderValue.emailInput;
			} else if (key === 'reminderSettingTextMessage') {
				return !emailMessageReminderValue.messageInput;
			} else if (
				(key === 'appStoreUrl' && !settingsForm?.isAppStoreUrl) ||
				(key === 'playStoreUrl' && !settingsForm?.isPlayStoreUrl) ||
				((key === 'customRedirectUrl' || key === 'customRedirectBtnText') &&
					!settingsForm?.isCustomBtnUrl)
			) {
				return true;
			}
			return false;
		},
		[emailMessageReminderValue, settingsForm]
	);

	const getLable = useCallback((key: string, label: string) => {
		if (key === 'kybMatches' || key === 'kybMatchThreshold') {
			return label + ' (required)';
		}
		return label;
	}, []);

	useEffect(() => {
		if (type !== 'modify')
			setComplexSettingForm((prev: any) => ({
				...prev,
				['publishName']: onboardingNameWithTime(),
			}));
		// Arun kumar: updating publish name in pipelineSettingState
		setSettingsForm(prev => ({
			...prev,
			['publishName']: prev['isModifyOrClone']? prev['publishName'] : onboardingNameWithTime(),
		}));

		// eslint-disable-next-line
	}, []);

	const renderSettingsComponents = useCallback(
		(
			type: string,
			description: string,
			label: string,
			key: string,
			defaultValue?: string,
			price?: number,
			children?: string[],
			labelSize?: "small" | "large",
			placeholder?: string
		) => {
			switch (type) {
				case 'toggle':
					return (
						<>
							<ToggleComponent
								description={description}
								label={label}
								name={key}
								id={id}
								price={price}
							/>
							{(children ?? []).map(child => (
								<Fragment key={child}>
									{renderChildComponents(
										child,
										metadata,
										renderSettingsComponents,
										getLable,
									)}
								</Fragment>
							))}
						</>
					);
				case 'textArea':
					return (
						<>
							{/* Paras: toggle only visible in remainder screen*/}
							{key === 'reminderSettingTextMessage' && (
								// Paras: add toggle component for message reminder
								<>
									<div className="pipeline-settings__header" />
									<ToggleComponent
										description={
											'Toggle to activate or deactivate text reminder.'
										}
										label={'Text Message'}
										name={''}
										id={id}
										isMessageToggle={true}
									/>
								</>
							)}
							<TextAreaComponent
								label={label}
								name={key}
								defaultValue={defaultValue}
								placeholder={placeholder ?? defaultValue}
								disable={isInputDisabled(key)}
							/>
						</>
					);
				case 'input':
					if(labelSize !== "small" && hideParentComponents[key]) {
						return <></>
					}
					return (
						// Paras: add toggle component for email reminder
						<>
							{key === 'reminderSettingEmailSubject' && (
								<ToggleComponent
									description={
										'Toggle to activate or deactivate email reminder.'
									}
									label={'Email'}
									name={key}
									id={id}
									isEmailToggle={true}
								/>
							)}
							<InputComponent
								label={label}
								labelSize={labelSize}	
								name={key}
								defaultValue={defaultValue}
								placeholder={placeholder}
								description={description}
								id={id}
								disable={isInputDisabled(key)}
							/>
						</>
					);
				case 'select':
					if (
						key === 'accreditationDocument' &&
						// Pradeep Chuarasia : this will include the linear and complex both case
						complexSettingForm?.[id ?? '']?.accreditationType?.value === '506c'
					) {
						return;
					}
					if (
						['fundInvestmentCountry', 'fundInvestmentLanguage'].includes(key)
					) {
						return;
					}
					defaultVal(key, getDefaultValue(key));
					return !/accreditationDocument|accreditationType/.test(key) ? (
						<ReminderPage
							label={label}
							name={key}
							selectOptions={getSelectOptions(key)}
							defaultValue={getDefaultValue(key)}
						/>
					) : (
						<SelectComponent
							label={label}
							name={key}
							selectOptions={getSelectOptions(key)}
							defaultValue={getDefaultValue(key)}
							id={id}
						/>
					);
				case 'radio':
					return (
						<RadioSelection
							stepKey={actionKey}
							actionKey={key}
							data={getSelectOptions(key)}
							defaultValue={getDefaultValue(key)}
							id={id}
						/>
					);
				default:
					return null;
			}
		},
		[
			id,
			isInputDisabled,
			complexSettingForm,
			defaultVal,
			getDefaultValue,
			getSelectOptions,
			actionKey,
			getLable,
			metadata,
			renderChildComponents,
		]
	);

	const renderSettings = useMemo(
		() => (
			<>
				{actionKey === 'reminderSetting' && (
					<div className="pipeline-settings__actions enable_reminder">
						{renderSettingsComponents(
							'toggle',
							'Click to enable reminder settings.',
							'Enable Reminder Settings',
							'enableReminderSetting',
							'false'
						)}
					</div>
				)}
				{metadata?.map(
					(
						{
							type,
							description,
							label,
							key,
							default: defaultValue = '',
							price,
							children,
							placeholder
						}: any,
						index: number
					) => {
						return actionKey === 'reminderSetting' &&
							!settingsForm['enableReminderSetting'] ? (
							<div key={`${index.toString()}-${label}_${key}`}></div>
						) : (
							<div
								className="pipeline-settings__actions"
								key={`${index.toString()}-${label}`}
							>
								{key !== 'publishCredential' &&
									key !== 'deliveryMethodCompanyName' && (
										<>
											{renderSettingsComponents(
												type,
												description,
												getLable(key, label),
												key,
												defaultValue,
												price,
												children,
												"large",
												placeholder
											)}
										</>
									)}
							</div>
						);
					}
				)}
			</>
		),
		[actionKey, getLable, renderSettingsComponents, metadata, settingsForm]
	);

	const renderEmptyActionsState = useMemo(
		() =>
			metadata.length === 0 &&
			actionKey !== 'signAgreementVerification' && (
				<div className="pipeline-settings__no-actions">
					<Image fileName="no-action-settings.svg" />
					<span>This action has no additional settings</span>
				</div>
			),
		[actionKey, metadata.length]
	);

	const renderWarningMessage = useMemo(
		() =>
			title === 'Text Message' && (
				<Message
					text={onboardingPublishMessage.WARNING_TEXT_MESSGAE}
					varient="warning"
				/>
			),
		[title]
	);

	const confgClasses = useMemo(() => {
		return classNames('pipeline-settings', {
			'pipeline-settings-signAgreementVerification':
				actionKey === 'signAgreementVerification',
			'pipeline-settings-accreditationVerify':
				actionKey === 'accreditationVerify' || actionKey === 'allId',
			'pipeline-settings_fundInvestmentVerification':
				actionKey === 'fundInvestmentVerification',
		});
	}, [actionKey]);

	const descriptionClass = useMemo(() => {
		return classNames('pipeline-settings__description', {
			'pipeline-settings__description__text':
				/signAgreementVerification|accreditationVerify|allId|fundInvestmentVerification/.test(
					actionKey
				),
		});
	}, [actionKey]);

	return (
		<div
			className={`${confgClasses} ${
				navigate === NavigateStepper.ComplexFlow ? 'header__space' : ''
			}`}
		>
			<div className="pipeline-settings__header">
				{isTitleVisible && (
					<div className="pipeline-settings__title">{title}</div>
				)}
				<div className={descriptionClass}>{description}</div>
			</div>
			<div
				className={`pipeline-settings__actions-details-container ${actionKey} `}
			>
				{title === 'Email' && <AddEmailClient />}
				{renderSettings}
				{renderEmptyActionsState}
				{renderWarningMessage}
				{actionKey === 'publishOnboarding' && <ComplexPriceBreakDown />}
			</div>
		</div>
	);
};
