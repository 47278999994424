import classNames from 'classnames';
import {
	Dispatch,
	FC,
	SetStateAction,
	useCallback,
	useMemo,
	useState,
} from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { Input, Loader } from '@storybook';
import { filteringTagsState, IComplexPipeline, pipelinesFiltersState } from 'global-stores';
import { AssignSelectedSessionState } from 'views/sessions';
import { SelectedRouteState } from 'views/sidebar';
import './popup.scss';
import { POPUP_TYPES } from './store';
import { SubPopup } from './subPopup';
import { debounce } from 'utils/debounce';

// interface IOnboardingFlow {
// 	Record<string, string>
// }

interface IPopup {
	isSearch?: boolean;
	list?: Record<string, string>[];
	type?: string;
	filterPage?: string;
	searchComplex?: string;
	setSearchComplex?: Dispatch<SetStateAction<string>>;
	complexFlowList?: any;
	isComplexLoading?: boolean;
	handleComplex?: ({
		complexTablesearch,
	}: {
		complexTablesearch?: string;
		isMounting?: boolean;
	}) => void;
	checkedOnboarding?: IComplexPipeline[];
	setCheckedOnboarding?: Dispatch<SetStateAction<IComplexPipeline[]>>;
}

export const Popup: FC<IPopup> = ({
	isSearch,
	list,
	type,
	filterPage,
	setSearchComplex,
	searchComplex,
	handleComplex,
	complexFlowList,
	isComplexLoading,
	checkedOnboarding,
	setCheckedOnboarding,
}) => {
	const [filteringTags, setFilteringTags] = useRecoilState(filteringTagsState);
	const [isSubPopupOpen, setIsSubPopupOpen] = useState<boolean>(false);
	const [selectedAction, setSelectedAction] = useState<string>('');
	const [searchedOnboarding, setSearchedOnboarding] = useState<string>('');
	const [checkedItems, setCheckedItems] = useState<string[]>([]);
	const [pipelinesFilter, setPipelinesFilter] = useRecoilState(
		pipelinesFiltersState
	);
	const seletedRoute = useRecoilValue(SelectedRouteState);
	const setSelectedSession = useSetRecoilState(AssignSelectedSessionState);

	const [isFilterLoading, setFilterLoading] = useState<{
		[key: string]: boolean;
	}>({});
	const [isChildLoading, setIsChildLoading] = useState<boolean>(false);

	const handleClickToOpen = useCallback((action: any) => {
		setSelectedAction(action);
		setIsSubPopupOpen(true);
	}, []);

	const handleChangeFilter = useCallback(
		(
			e: React.ChangeEvent<HTMLInputElement>,
			key: string,
			status: string,
			value: string,
			item: IComplexPipeline
		) => {
			const { checked } = e.target;

			handleClickToOpen(value);

			if (checked && setCheckedOnboarding) {
				const isChecked = [...(checkedOnboarding ?? [])];
				isChecked.push(item);
				setCheckedOnboarding(isChecked);
				setFilteringTags((prev: { [key: string]: string[] }) => {
					const newState = JSON.parse(JSON.stringify(prev));
					return { ...newState, [key]: [...(newState[key] ?? []), status] };
				});
			} else {
				const clonedState = JSON.parse(JSON.stringify(filteringTags));
				const filteredValue = clonedState[key].filter(
					(item: string) => item !== status
				);
				clonedState[key] = filteredValue;
				if (!filteredValue.length) {
					delete clonedState[key];
				}
				setFilteringTags(clonedState);
			}
		},
		[
			checkedOnboarding,
			filteringTags,
			handleClickToOpen,
			setCheckedOnboarding,
			setFilteringTags,
		]
	);

	const handleUncheckFilter = useCallback(
		(
			e: React.ChangeEvent<HTMLInputElement>,
			key: string,
			status: string,
			item: IComplexPipeline
		) => {
			e.stopPropagation();
			const { checked } = e.target;

			if (!checked && setCheckedOnboarding) {
				const notChecked = (checkedOnboarding ?? []).filter(
					noCheck => noCheck.name !== item.name
				);
				setCheckedOnboarding(notChecked);
				setFilteringTags((prev: { [key: string]: string[] }) => {
					const newState = JSON.parse(JSON.stringify(prev));
					return { ...newState, [key]: [...(newState[key] ?? []), status] };
				});
				const clonedState = JSON.parse(JSON.stringify(filteringTags));
				const filteredValue = clonedState[key].filter(
					(item: string) => item !== status
				);
				clonedState[key] = filteredValue;
				if (!filteredValue.length) {
					delete clonedState[key];
				}
				setFilteringTags(clonedState);
			}
		},
		[checkedOnboarding, filteringTags, setCheckedOnboarding, setFilteringTags]
	);

	const handleSelectAction = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>, action: any) => {
			const { checked } = e.target;

			handleClickToOpen(action);
			if (checked) {
				const isChecked = [...checkedItems];
				isChecked.push(action);
				setCheckedItems(isChecked);
				setSelectedAction(action);
				if (type === 'no_status_action') {
					setPipelinesFilter((prev: { [key: string]: string[] }) => {
						const newState = { ...prev };
						newState[action] = [
							'pending',
							'processing',
							'completed',
							'rejected',
						];
						return newState;
					});
				} else {
					setFilteringTags((prev: { [key: string]: string[] }) => {
						const newState = { ...prev };
						newState[action] = [
							'pending',
							'processing',
							'completed',
							'rejected',
						];
						return newState;
					});
				}
			} else {
				if (type === 'no_status_action') {
					const deleteKey = { ...pipelinesFilter };
					delete deleteKey[action];
					setPipelinesFilter(deleteKey);
				} else {
					const notChecked = checkedItems.filter(noCheck => noCheck !== action);
					setCheckedItems(notChecked);
					const deleteKey = { ...filteringTags };
					delete deleteKey[action];
					setFilteringTags(deleteKey);
				}
			}
		},
		[
			checkedItems,
			filteringTags,
			handleClickToOpen,
			pipelinesFilter,
			setFilteringTags,
			setPipelinesFilter,
			type,
		]
	);

	const handleChecked = useCallback(
		(item: IComplexPipeline | { [key: string]: any }) => {
			if (type === 'onboarding_flow') {
				const checkingOnboarding = [
					...(filteringTags?.onboardingFlowName ?? []),
				];
				return checkingOnboarding.includes(
					item.onboardingFlowName || item.name
				);
			}
			if (type === 'no_status_action') {
				return 'value' in item && Object.keys(pipelinesFilter ?? {}).includes(item.value);
			}
			return (
				'value' in item && Object.keys(filteringTags ?? {}).includes(item.value)
			);
		},
		[filteringTags, type, pipelinesFilter]
	);

	const searchedList = useMemo(() => {
		return (
			list &&
			list.filter(selector =>
				selector.onboardingFlowName
					?.toString()
					?.toLowerCase()
					?.includes(searchedOnboarding?.toLowerCase())
			)
		);
	}, [list, searchedOnboarding]);

	const fitlerIndicator = useCallback(
		(action: string) => {
			return filteringTags[action]?.length > 0 &&
				filteringTags[action]?.length < 4
				? `${filteringTags[action]?.length} selected`
				: 'All';
		},
		[filteringTags]
	);

	const classes = useCallback(
		(item: any) => {
			return classNames(
				'ri-arrow-down-s-line popup-menu-item__right__icon',
				selectedAction === item.value && isSubPopupOpen
					? 'popup-menu-item__right__icon__opened'
					: 'popup-menu-item__right__icon__closed'
			);
		},
		[isSubPopupOpen, selectedAction]
	);

	const handleChange = useCallback(
		(
			e: React.ChangeEvent<HTMLInputElement>,
			item: IComplexPipeline | any,
			index: string
		) => {
			setFilterLoading({ ...isFilterLoading, [index]: true });
			if (filterPage === 'sessions') {
				setSelectedSession([]);
			}
			setTimeout(() => {
				if (type === 'active_status' || type === 'no_status_action') {
					handleSelectAction(e, item.value);
				} else {
					handleChangeFilter(
						e,
						'onboardingFlowName',
						item.onboardingFlowName ?? item.name,
						item.value,
						item
					);
				}
				setFilterLoading({ ...isFilterLoading, [index]: false });
			}, 1000);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[handleChangeFilter, handleSelectAction, isFilterLoading, type]
	);

	const isSomeFilterLoading = useMemo(() => {
		const values = Object.values(isFilterLoading ?? {});
		return isChildLoading || values.some(value => value);
	}, [isFilterLoading, isChildLoading]);

	const renderListItem = useMemo(() => {
		const renderList: IComplexPipeline[] | any =
			type === 'onboarding_flow'
				? complexFlowList?.data[0]?.result.filter(
						(onboarding: IComplexPipeline) =>
							!(filteringTags?.onboardingFlowName ?? []).includes(
								onboarding.name
							)
				  )
				: searchedOnboarding?.length
				? [...(searchedList ?? [])]
				: [...(list ?? [])];

		if (renderList?.length === 0 && !isSomeFilterLoading) {
			return <div className="RenderListItem--empty">No Data</div>;
		}

		if (type === 'onboarding_flow' && isComplexLoading) {
			return (
				<div className="popup-menu-item">
					<Loader type="loader" dimension={24} />
				</div>
			);
		}

		return renderList?.map(
			(item: IComplexPipeline | any, index: number) => {
				return (
					<div
						key={`${item.value??item.name}_${index.toString()}`}
						className={`popup-menu-item ${
							type !== 'active_status' ? 'non-actions' : ''
						}`}
						onClick={() => handleClickToOpen(item.value)}
					>
						<div className="popup-menu-item__left">
							{isFilterLoading[`${index}`] ? (
								<div style={{ width: 20, height: 18 }}>
									<Loader type="loader" dimension={18} />
								</div>
							) : (
								<input
									disabled={isSomeFilterLoading}
									id={`item_${index}`}
									className="popup-menu-item__checkbox"
									type="checkbox"
									checked={handleChecked(item)}
									onChange={e => handleChange(e, item, `${index}`)}
								/>
							)}
							<label htmlFor={`item_${index}`} className="Popup--label-txt">
								{type === 'onboarding_flow'
									? item.name
									: item.onboardingFlowName}
							</label>
						</div>
						{type === 'active_status' && (
							<div className="popup-menu-item__right">
								<span>{fitlerIndicator(item.value)}</span>
								<i
									// onClick={() => handleClickToOpen(item.value)}
									className={classes(item)}
								></i>
							</div>
						)}
					</div>
				);
			}
		);
	}, [
		type,
		complexFlowList?.data,
		searchedOnboarding?.length,
		searchedList,
		list,
		isSomeFilterLoading,
		isComplexLoading,
		filteringTags.onboardingFlowName,
		isFilterLoading,
		handleChecked,
		fitlerIndicator,
		classes,
		handleClickToOpen,
		handleChange,
	]);

	const renderListCheckedItem = useMemo(() => {
		const renderList = type === 'onboarding_flow' ? checkedOnboarding : [];

		return renderList?.map((item: IComplexPipeline, index: number) => {
			return (
				<div
					key={`checked_${item.name}_${index.toString()}`}
					className={`popup-menu-item ${
						type !== 'active_status' ? 'non-actions' : ''
					}`}
					onClick={() => handleClickToOpen(item.name)}
				>
					<div className="popup-menu-item__left">
						<input
							disabled={isSomeFilterLoading}
							id={`checked_item_${index}`}
							className="popup-menu-item__checkbox"
							type="checkbox"
							checked={handleChecked(item)}
							onChange={e =>
								handleUncheckFilter(
									e,
									'onboardingFlowName',
									item.onboardingFlowName ?? item.name,
									item
								)
							}
						/>

						<label
							htmlFor={`checked_item_${index}`}
							className="Popup--label-txt"
						>
							{type === 'onboarding_flow' ? item.name : item.onboardingFlowName}
						</label>
					</div>
				</div>
			);
		});
	}, [
		type,
		checkedOnboarding,
		isSomeFilterLoading,
		handleChecked,
		handleClickToOpen,
		handleUncheckFilter,
	]);

	const searching = debounce((value: string) => {
		if (type !== 'onboarding_flow') {
			setSearchedOnboarding(value);
		}
		if (type === 'onboarding_flow' && handleComplex) {
			handleComplex({ complexTablesearch: value.trim() });
		}
		return;
	}, 500);

	const handleSearch = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (setSearchComplex) setSearchComplex(e.target.value);
			searching(e.target.value);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const containClass = classNames('popup-wrapper', {
		'popup-wrapper--onboarding ': type === 'onboarding_flow',
	});

	return list && list.length === 0 && type !== 'onboarding_flow' ? (
		<div className="RenderListItem--no-data">No Data</div>
	) : (
		<div className={containClass}>
			{isSearch && (
				<div className="popup-input-wrapper">
					<Input
						inputType="text"
						handleChange={handleSearch}
						label=""
						value={searchedOnboarding || searchComplex}
						placeholder="Search..."
						suffixIcon="ri-search-line"
						handleChangeCountry={() => ({})}
					/>
				</div>
			)}
			<div
				className={`popup-body-wrapper ${
					seletedRoute !== 'onboarding'
						? 'padding-wrapper'
						: 'popup-wrapper__overFlow-filter-option'
				}
				${type === POPUP_TYPES.ActionStatus ? 'popup-menu-list__action-list' : ''}
				`}
			>
				{(checkedOnboarding ?? []).length > 0 && (
					<div className={`popup-menu-list divider`}>
						<span className="popup-menu-item">Selected Onboarding Flow</span>
						{renderListCheckedItem}
					</div>
				)}
				<span className="popup-menu-item">Available Onboarding Flow</span>
				<div className={`popup-menu-list`}>{renderListItem}</div>
				{type === 'active_status' && isSubPopupOpen && (
					<SubPopup
						setIsChildLoading={setIsChildLoading}
						isParentLoading={isSomeFilterLoading}
						action={selectedAction}
					/>
				)}
			</div>
		</div>
	);
};
