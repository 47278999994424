import { ChangeEvent, useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { TextArea } from '@storybook';
import { commentText } from 'global-stores';

export const CommentBox = () => {
	const [textValue, setTextValue] = useRecoilState(commentText);

	const handleTextChange = useCallback(
		(e: ChangeEvent<HTMLTextAreaElement>) => {
			setTextValue(e.target.value);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return (
		<div style={{ marginTop: 16, marginBottom: 16 }}>
			<TextArea
				label=""
				value={textValue}
				handleChange={handleTextChange}
				placeholder="Enter your comment..."
			/>
		</div>
	);
};
