import { CodeSpace } from '@storybook';

import { REACT_APP_AUTH_SCRIPT } from 'envs';

import './embeded-code.scss';

export const EmbededCode = ({ instanceId = '' }: { instanceId: string }) => {
	return (
		<div className="auth-code-embeded-wrapper">
			<CodeSpace
				code={`<!-- Simplici-Auth WebComponent Integration -->
<!-- This WebComponent is used for authentication purposes, configurable with different environments and languages -->
<!-- The WebComponent -->
<simplici-auth
  instance_id="${instanceId}"  
  on_step_change="on_step_changed"   
  on_complete="on_completed"   
  on_error="auth_error"  
></simplici-auth>

<!-- Script to handle the events emitted by the WebComponent -->
<script>
  // This function handles the 'step change' event
  window.on_step_changed = function (event) {
    console.log("on_step_changed === ", event);  // Logs the event when the authentication step changes
  };

  // This function handles the 'completed' event
  window.on_completed = function (event) {
    console.log("on_completed", event);  // Logs the event when the authentication process is completed
  };

  // This function handles any 'error' event
  window.auth_error = function (error) {
    console.log("auth_error", error);  // Logs any error that occurs during the authentication process
  };
</script>

<!-- Load the Simplici-Auth WebComponent script -->
<!-- This script should be included inside the head tag -->
<script src="${REACT_APP_AUTH_SCRIPT}"></script>
`}
			/>
		</div>
	);
};
