import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';

import Modal from '@storybook/new-modal/modal';
import { KybInfoModalState } from '../../hooks';
import { ArraySection } from '../kyb-comprehensive/components/array-section';

export const KybSubInformationModalBody = () => {
	const [kybSubInfoModal, setKybSubInfoModal] =
		useRecoilState(KybInfoModalState);

	const [selectedTab, setSelectedTab] = useState('');

	const handleTabClick = (label: string) => {
		setSelectedTab(label);
	};

	useEffect(() => {
		const dataKeys = Object.keys(kybSubInfoModal?.data);
		if (dataKeys.length > 0) {
			setSelectedTab(dataKeys[0] ?? '');
		} else {
			// Handle the case when the object is empty
		}
	}, [kybSubInfoModal?.data]);

	const handleCloseModal = useCallback(() => {
		setKybSubInfoModal(prevState => ({
			...prevState,
			isOpen: false,
		}));
		// This Timeout is beacause to fix the Islutation cosmatic bug when we close the modal.
		setTimeout(() => {
			setKybSubInfoModal(prevState => ({
				...prevState,
				data: {},
			}));
		}, 500);
	}, [setKybSubInfoModal]);

	const renderSubInfoModalBody = useMemo(() => {
		switch (kybSubInfoModal?.modalType) {
			case 'simple':
				return (
					<ArraySection
						disableMore={true}
						label={kybSubInfoModal?.data?.label ?? '--'}
						data={kybSubInfoModal?.data?.value ?? []}
					/>
				);
			case 'tabs':
				return (
					<div className="Kyb-info__tabs-box-modal">
						<div className="Kyb-info__tabs-wrapper">
							{Object.keys(kybSubInfoModal?.data).map(tab => (
								<>
									{kybSubInfoModal?.data[tab]?.label && (
										<div
											key={tab}
											className={`tab ${selectedTab === tab ? 'selected' : ''}`}
											onClick={() => handleTabClick(tab)}
										>
											{kybSubInfoModal?.data[tab]?.label}
										</div>
									)}
								</>
							))}
						</div>

						<ArraySection
							disableMore={true}
							label={kybSubInfoModal?.data[selectedTab]?.label ?? '--'}
							data={kybSubInfoModal?.data[selectedTab]?.value ?? []}
						/>
					</div>
				);
			default:
				return <></>;
		}
	}, [kybSubInfoModal?.data, kybSubInfoModal?.modalType, selectedTab]);

	return (
		<Modal
			isOpen={kybSubInfoModal.isOpen}
			modalName={'Comperhensive'}
			closeModal={handleCloseModal}
			titleWithCss="kyb-comperhensive-modal-title"
			showCloseBtn
			isStopOutsideClick={true}
			title={kybSubInfoModal.label}
		>
			<div className="kyb-info__modal">{renderSubInfoModalBody}</div>
		</Modal>
	);
};
