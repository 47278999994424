export const fundsColumn = [
	{ label: 'Fund/Company', key: 'name', format: 'string', width: '25%' },
	{
		label: 'Investors',
		key: 'investor',
		format: 'string',
		width: '15%',
	},
	{
		label: 'Pend. Approvals',
		key: 'pending',
		format: 'string',
		width: '15%',
	},
	{
		label: 'Money Raised ',
		key: 'moneyRaised',
		key2: 'fundingRound',
		format: 'price',
		width: '15%',
	},
	{
		label: 'Total Raised ',
		key: 'totalMoneyRaised',
		format: 'price',
		width: '15%',
	},
	{
		label: 'P.M. Valuation',
		key: 'postMoneyValuation',
		format: 'price',
		width: '15%',
	},
];
