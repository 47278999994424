import type { IConfig, TemplateStatus } from './types';
import type { ISignDocHistoryAPIResponse } from 'views/sign-doc-history';

import { useCallback, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { API_URL, MESSAGE } from 'constant';
import {
	SignDocModalState,
	UploadedTemplateDocsState,
	finalConfiguredAggrementDocsState,
} from 'global-stores';
import { useFreePlan, useMarketing, useNetwork, useNotification } from 'hooks';
import { SignedDocInboxState } from 'views/signed-doc-inbox';

export const useSignedDoc = () => {
	const {
		formData: uploadDoc,
		get,
		remove,
	} = useNetwork({ returnResponse: true });
	const setSignDocState = useSetRecoilState(SignDocModalState);
	const setAddedFiles = useSetRecoilState(UploadedTemplateDocsState);
	const setFinalSelectedDocs = useSetRecoilState(
		finalConfiguredAggrementDocsState
	);

	const { errorNotification, successNotification } = useNotification();
	const uploadDocument = useCallback(
		async (payload: any): Promise<boolean> => {
			const resp = await uploadDoc(
				`${API_URL.UPLOAD_AGREEMENT_TEMPLATE}`,
				payload
			);
			const { response, apiData } = resp;
			if (response?.status === 200) {
				const { name, size, _id, createdAt } = apiData.data ?? {};
				if (name) {
					setSignDocState(true);
					setTimeout(() => {
						setAddedFiles(prev => [
							...prev,
							{
								_id,
								name,
								size,
								createdAt,
								configured: false,
								isChecked: true,
							},
						]);
						successNotification('File Uploaded.');
						setFinalSelectedDocs(prev => {
							const prevState = JSON.parse(JSON.stringify(prev));
							const selectedFile = {
								_id,
								name,
								size,
								createdAt,
								configured: false,
								isChecked: true,
							};
							const selectedIndex = prevState.findIndex(
								(file: any) => file?._id === selectedFile._id
							);
							let newState: any[] = [];
							if (selectedIndex < 0) {
								newState = [...prevState, selectedFile];
							}
							return newState;
						});
					}, 300);
				}
				return true;
			}
			if (response.status === 400) {
				errorNotification(
					apiData.message ?? 'Unsupported file format selected.'
				);
				return false;
			}
			errorNotification(apiData.message ?? MESSAGE.ERROR_TRY);
			return false;
		},
		[
			errorNotification,
			setAddedFiles,
			setFinalSelectedDocs,
			setSignDocState,
			successNotification,
			uploadDoc,
		]
	);

	const getTemplateStatus = useCallback(
		async (templateId: string): Promise<TemplateStatus> => {
			if (templateId) {
				const resp = await get(`${API_URL.TEMPLATE_STATUS}/${templateId}`);
				const { apiPayload: apiData, response } = resp;
				if (response?.status === 200) {
					return apiData?.data?.status ?? apiData?.status;
				}
			}
			return 'saved';
		},
		[get]
	);

	const deleteEsignTemplate = useCallback(
		(templateId: string) => {
			remove(`${API_URL.SIGN_AGREEMENT}/${templateId}?type=esign`);
		},
		[remove]
	);

	return {
		uploadDocument,
		getTemplateStatus,
		deleteEsignTemplate,
	};
};

export const useSimpliciSign = () => {
	const [isLoaded, setIsLoaded] = useState(true);
	const { post, patch } = useNetwork();
	const { errorNotification } = useNotification();

	const simpliciSignUseTemplate = useCallback(
		async (payload: any): Promise<IConfig | undefined> => {
			setIsLoaded(false);
			const response = await post(API_URL.ENVELOPE, payload);
			if (response?.prepareUrl) {
				setIsLoaded(true);
				return response;
			}
			errorNotification(MESSAGE.ERROR_TRY);
			setIsLoaded(true);
			return;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const updateTemplate = useCallback(
		async (templateId: string, isCopy?: boolean) => {
			setIsLoaded(false);
			let url = `${API_URL.SIGN_AGREEMENT}/${templateId}`;
			if(isCopy === false) {
				url = `${url}?copy=false`
			}
			const resp = await patch(url, {
				source: 'default',
			});
			if (resp?._id) {
				setIsLoaded(true);
				return true;
			}
			return false;
			setIsLoaded(true);
		},
		[patch]
	);

	const updateTemplateName = useCallback(
		async (id: string, payload: { name: string }): Promise<boolean> => {
			setIsLoaded(false);
			const resp = await patch(`${API_URL.SIGN_AGREEMENT}/${id}?copy=false`, payload);
			if (resp?.name) {
				setIsLoaded(true);
				return true;
			} else {
				errorNotification(resp?.message ?? 'Failed to change template');
				setIsLoaded(true);
				return false;
			}
		},
		[errorNotification, patch]
	);

	return {
		simpliciSignUseTemplate,
		updateTemplateName,
		updateTemplate,
		isLoaded,
	};
};

export const useFiveInvitedAt = () => {
	const { trackAttribute } = useMarketing();
	const { hasSubscribedPerson } = useFreePlan();
	const { get } = useNetwork({ updateState: false });
	const { data } = useRecoilValue(SignedDocInboxState);

	const onFirstComplete = async (envelopeId: string) => {
		const resp: ISignDocHistoryAPIResponse = await get(
			`${API_URL.ENVELOPE_HISTORY}?envelopeId=${envelopeId}`
		);
		if (resp?.message === 'ok') {
			const { data = [] } = resp;
			const fistCompleted = data.find(el => el.event === 'COMPLETED');
			trackAttribute({
				first_flow_complete_at_sign: fistCompleted?.createdAt ?? '',
			});
		}
	};

	const onFirstOpen = async (envelopeId: string) => {
		const resp: ISignDocHistoryAPIResponse = await get(
			`${API_URL.ENVELOPE_HISTORY}?envelopeId=${envelopeId}`
		);
		if (resp.message === 'ok') {
			const { data = [] } = resp;
			const fistOpen = data.find(el => el.event === 'OPENED');
			trackAttribute({
				first_flow_start_at_sign: fistOpen?.createdAt ?? '',
			});
		}
	};

	const marketingInit = () => {
		if (data?.length && data.length < 6 && !hasSubscribedPerson) {
			trackAttribute({ freeplan_use_sign: data?.length ?? '' });
			const firstFive = data?.slice(-5);
			(firstFive ?? []).forEach(el => {
				trackAttribute({
					freeplan_first_at_sign: el.createdAt ?? '',
					freeplan_second_at_sign: el.createdAt ?? '',
					freeplan_third_at_sign: el.createdAt ?? '',
					freeplan_fourth_at_sign: el.createdAt ?? '',
					freeplan_last_at_sign: el.createdAt ?? '',
				});
			});
			const firstInvite = data?.at(-1);
			if (firstInvite)
				trackAttribute({
					first_invite_sent_at_sign: firstInvite.createdAt ?? '',
				});
			(firstFive ?? []).forEach(el => {
				if (el.status === 'completed') {
					onFirstComplete(el?._id ?? '');
					return;
				}
			});
			(firstFive ?? []).forEach(el => {
				if (el.status === 'configured') {
					onFirstOpen(el?._id ?? '');
					return;
				}
			});
		}
	};
	return {
		marketingInit,
	};
};
