import { FC, useMemo, useState } from 'react';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';

import { Button, IOption, LatterAvater, Loader } from '@storybook';
import { NoUserAssined } from '../no-user-assined';
import {
	AssinedUserLoadingState,
	AssinedUserState,
	EditModal,
	FundsState,
	IBusiness,
	ILoginState,
	InviteDetailsState,
	InviteModal,
	LoginPhoneNumberState,
	UserRoles,
} from 'global-stores';
import { getInitials } from 'utils';
import { AssinedUserSkeletonLoader } from '../user-role-loader';
import { DropInfoOptions } from '../info-drop-options';
import { ArchiveConfirmationModal } from 'views/sessions';
import { useNetwork, useNotification } from 'hooks';
import { API_URL, MESSAGE } from 'constant';
import { InviteModalPage } from '../invite-modal';
import { InviteNavigation } from 'views/user-roles/constant';
import { InviteNavigationState } from 'views/user-roles/store';
import { useUserRoles } from 'views/routes-children';
import { UserAvatar } from '../role-permission-list';
import { useNewRoles } from 'views/user-roles/hooks';

interface IAssinedUser {
	isUserPermissionWrite?: boolean;
}

export const AssinedUser: FC<IAssinedUser> = ({
	isUserPermissionWrite = true,
}) => {
	const setIsOpenInviteUserModel = useSetRecoilState(InviteModal);
	const [selectedUserRole, setSelectedUserRole] =
		useRecoilState(AssinedUserState);
	const [invites, setInvites] = useRecoilState(InviteDetailsState);
	const resetInvites = useResetRecoilState(InviteDetailsState);
	const isLoaded = useRecoilValue(AssinedUserLoadingState);
	const [inviteNavigation, setInviteNavigation] = useRecoilState(
		InviteNavigationState
	);
	const setLoginPhoneNumber = useSetRecoilState(LoginPhoneNumberState);
	const resetLoginPhoneNumber = useResetRecoilState(LoginPhoneNumberState);
	const setEditModal = useSetRecoilState(EditModal);
	const fundsList = useRecoilValue(FundsState);

	const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);

	const [selectedId, setSelectedId] = useState('');
	const [selectedUserName, setSelectedUserName] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [apiLoading, setApiLoading] = useState(false);
	const [isBlockedData, setBlockedData] = useState<{
		isDeleted: boolean;
		isBlocked: boolean;
	}>({
		isDeleted: false,
		isBlocked: false,
	});

	const { successNotification, errorNotification } = useNotification();

	const { remove: removeInvitedUser, patch } = useNetwork({
		updateState: false,
	});
	const { fetchUserRoles } = useUserRoles();
	const { handleReinvite, isReInviteLoading } = useNewRoles({});

	const { patch: patchBlockedUser } = useNetwork({
		updateState: false,
	});

	const { users = [] } = selectedUserRole ?? {};

	const handleInviteAssignedUser = () => {
		setIsOpenInviteUserModel(true);
		setInvites(prev => {
			const prevState = JSON.parse(JSON.stringify(prev));
			prevState['role'] = selectedUserRole?._id ?? '';
			return { ...prevState };
		});
	};

	const handleEditUser = (user: ILoginState) => {
		setIsEditModalOpen(true);
		setSelectedId(user?._id ?? '');
		setEditModal(true);
		const {
			lastName,
			business,
			fundIds,
			firstName,
			email,
			role,
			countryCode,
			phone,
		} = user ?? {};

		const { name, _id } = (business?.[0] as IBusiness | any) ?? {};

		const fundValues = fundsList?.filter(object1 => {
			return fundIds?.some(object2 => {
				return object1.id === object2;
			});
		});
		const fundData: IOption[] = [];
		fundValues.forEach(fund => {
			fundData.push({ label: fund.name, value: fund.id });
		});
		setLoginPhoneNumber(pre => {
			return {
				...pre,
				phone,
				countryCode,
			};
		});
		setInvites(pre => {
			const prevState = JSON.parse(JSON.stringify(pre));
			return {
				...prevState,
				firstName,
				lastName,
				email,
				countryCode,
				phone,
				funds: fundData,
				role,
				subAccount: { label: name, value: _id },
			};
		});
	};

	const handleDeleteUser = (id: string, name: string) => {
		setOpenDeleteModal(true);
		setSelectedId(id);
		setBlockedData(prev => ({ ...prev, isDeleted: true }));
		setSelectedUserName(name);
	};

	const handleBlockedUser = (id: string, isBlocked: boolean, name: string) => {
		setOpenDeleteModal(true);
		setSelectedId(id);
		setBlockedData(prev => ({ ...prev, isBlocked, isDeleted: false }));
		setSelectedUserName(name);
	};

	const onBlockedUser = async () => {
		setIsLoading(true);

		const resp = await patchBlockedUser(`${API_URL.USER_ROLES}/${selectedId}`, {
			isBlocked: !isBlockedData.isBlocked,
		});
		if (resp?._id) {
			setOpenDeleteModal(false);
			successNotification(
				!isBlockedData.isBlocked
					? 'User Blocked successfully'
					: 'User Unblocked successfully'
			);

			const assignUser = selectedUserRole?.users?.map(el => {
				if (el._id === selectedId) {
					return {
						...el,
						isBlocked: !isBlockedData.isBlocked,
					};
				} else {
					return {
						...el,
					};
				}
			});
			const updateData = {
				...selectedUserRole,
				users: assignUser,
			} as UserRoles;
			setSelectedId('');
			setBlockedData(prev => ({ ...prev, isBlocked: false, isDeleted: false }));
			setSelectedUserRole(updateData);
		} else {
			errorNotification(resp?.message ?? MESSAGE.ERROR);
		}
		setIsLoading(false);
	};

	const handleDeleteUserRole = async (isOpen: boolean, isClose: boolean) => {
		if (!isClose && !isOpen) {
			setOpenDeleteModal(false);
			setBlockedData(prev => ({ ...prev, isBlocked: false, isDeleted: false }));

			return;
		}

		if (!isBlockedData.isDeleted) {
			onBlockedUser();
			return;
		}

		setIsLoading(true);
		const resp = await removeInvitedUser(`${API_URL.USER_ROLES}/${selectedId}`);
		if (resp?._id) {
			setSelectedUserRole(prev => {
				const prevObj = structuredClone(prev);
				const { users = [] } = prevObj ?? {};
				const index = users.findIndex(el => el._id === selectedId);
				if (index !== -1) {
					prevObj?.users?.splice(index, 1);
				}
				return prevObj;
			});
			setOpenDeleteModal(false);
			successNotification('User deleted successfully');
			// calling business role api
			fetchUserRoles();
		}
		setIsLoading(false);
	};

	const handleCloseEditModal = () => {
		setIsEditModalOpen(false);
		setEditModal(false);
		setTimeout(() => {
			setInviteNavigation(InviteNavigation.InviteForm);
			setSelectedId('');
			resetInvites();
			resetLoginPhoneNumber();
		}, 1000);
	};

	const inviteBackLabel = useMemo(() => {
		switch (inviteNavigation) {
			case InviteNavigation.InviteForm:
				return 'Cancel';
			case InviteNavigation.InvitePreview:
				return 'Back';
			default:
				return '';
		}
	}, [inviteNavigation]);

	const inviteButtonLabel = useMemo(() => {
		switch (inviteNavigation) {
			case InviteNavigation.InviteForm:
				return 'Review';
			case InviteNavigation.InvitePreview:
				return 'Update Invite';
			default:
				return '';
		}
	}, [inviteNavigation]);

	const updateUserRoles = async () => {
		setApiLoading(true);
		const { role, funds } = invites ?? {};
		const newPayload = {
			role,
			fundIds: funds?.map(fund => fund.value),
		};
		const resp = await patch(`${API_URL.USER_ROLES}/${selectedId}`, newPayload);
		if (resp._id) {
			setSelectedUserRole(prev => {
				const prevObj = structuredClone(prev);
				const { users = [] } = prevObj ?? {};
				if (resp?.role?.name === selectedUserRole?.name && prevObj) {
					const updatedUsers = users.map(user =>
						user._id === selectedId
							? { ...user, fundIds: resp.fundIds }
							: { ...user }
					);
					const updatedState = { ...prevObj, users: updatedUsers };
					return updatedState;
				}
				if (resp?.role?.name !== selectedUserRole?.name && prevObj) {
					const filteredUsers = users.filter(user => user._id !== resp._id);
					const updatedState = { ...prevObj, users: filteredUsers };
					return updatedState;
				}
				return prevObj;
			});
			successNotification('Updated Successfully');
			handleCloseEditModal();
			resetInvites();
			resetLoginPhoneNumber();
		} else {
			errorNotification(resp.message ?? MESSAGE.ERROR);
		}
		setApiLoading(false);
	};

	const onUpdateRole = () => {
		switch (inviteNavigation) {
			case InviteNavigation.InviteForm:
				return setInviteNavigation(InviteNavigation.InvitePreview);
			case InviteNavigation.InvitePreview:
				updateUserRoles();
				return;
		}
	};

	const handleBackEditModal = () => {
		switch (inviteNavigation) {
			case InviteNavigation.InviteForm:
				handleCloseEditModal();
				break;
			case InviteNavigation.InvitePreview:
				setInviteNavigation(InviteNavigation.InviteForm);
				break;
		}
	};

	const renderArchiveConfirmationModal = useMemo(() => {
		if (isBlockedData.isBlocked) {
			return {
				desc: `Unblocking ${selectedUserName} user will unblock all the access. `,
				boldDes: `Do you still want to unblock ${selectedUserName} user?`,
			};
		} else if (isBlockedData.isDeleted) {
			return {
				desc: `Removing ${selectedUserName} user will remove user’s permissions from the role. `,
				boldDes: `Do you still want to remove ${selectedUserName} user?`,
			};
		} else if (!isBlockedData.isBlocked) {
			return {
				desc: `Blocking ${selectedUserName} user will block all the access. `,
				boldDes: `Do you still want to block ${selectedUserName} user?`,
			};
		} else {
			return {
				desc: '',
				boldDes: '',
			};
		}
	}, [isBlockedData, selectedUserName]);

	const confirmationLabel = useMemo(() => {
		if (isBlockedData.isBlocked) {
			return 'Unblock';
		} else if (isBlockedData.isDeleted) {
			return 'Remove';
		} else if (!isBlockedData.isBlocked) {
			return 'Block';
		} else {
			return 'Delete';
		}
	}, [isBlockedData]);

	return (
		<div className="AssinedUser--container">
			<div className="AssinedUser--container__header">
				<div className="AssinedUser--container__header__title">
					Assigned Users:{' '}
					<span className="AssinedUser--container__header__title--usersCount">
						({isLoaded ? '-' : users?.length ?? '0'})
					</span>
				</div>
				{isUserPermissionWrite && users.length && !isLoaded ? (
					<div
						className="AssinedUser--container__header--inviteUser"
						onClick={handleInviteAssignedUser}
					>
						Invite user
					</div>
				) : (
					<></>
				)}
			</div>
			{isLoaded ? (
				<AssinedUserSkeletonLoader />
			) : (
				<div className="AssinedUser--container__userList">
					{users?.length > 0 ? (
						(users ?? [])?.map(
							(user: ILoginState & { isPrimary?: boolean }) => {
								return (
									<div
										className="AssinedUser--container__userList--data"
										key={user?._id}
									>
										<div className="AssinedUser--container__userList--data__profile">
											{user?.image ? (
												<UserAvatar id={user._id ?? ''} />
											) : (
												<LatterAvater
													firstName={`${getInitials(user?.firstName || '')}`}
													lastName={`${getInitials(user?.lastName || '')}`}
												/>
											)}
										</div>
										<div className="AssinedUser--container__userList--data__label">
											<div>
												<div className="AssinedUser--container__userList--data__label--name">
													{`${user?.firstName ?? 'A'} ${user?.lastName ?? 'B'}`}
												</div>
												<div className="AssinedUser--container__userList--data__label--account">
													{Array.isArray(user?.business)
														? user?.business[0]?.name ?? 'NA'
														: user?.business?.['name' as any] ?? 'NA'}
												</div>
											</div>
											<div className="AssinedUser--container__currentTag">
												{user.isBlocked ? (
													<div className="AssinedUser--container__blockTag">
														Blocked
													</div>
												) : (
													<>
														{user.isVerifiedPhone ? (
															<div className="AssinedUser--container__joinedTag">
																Joined
															</div>
														) : (
															<div className="AssinedUser--container__pendingTag">
																Pending
															</div>
														)}
													</>
												)}
											</div>
										</div>
										<DropInfoOptions
											isDisabled={user?.isPrimary}
											handleEdit={() => handleEditUser(user)}
											handleDelete={() =>
												handleDeleteUser(
													user._id ?? '',
													`${user?.firstName} ${user?.lastName}`
												)
											}
											editLabel="Edit user"
											deleteLabel="Remove user"
											blockedLabel={
												!user.isBlocked ? 'Block access' : 'Unblock access'
											}
											handleReInvite={() => handleReinvite(user._id ?? '')}
											showReinvite={!user.isBlocked && !user.isVerifiedPhone}
											isReInviteLoading={isReInviteLoading}
											handleBlockedUser={() =>
												handleBlockedUser(
													user._id ?? '',
													user.isBlocked ?? false,
													`${user?.firstName} ${user?.lastName}`
												)
											}
											isDeleteDisabled={true}
										/>
									</div>
								);
							}
						)
					) : (
						<NoUserAssined isUserPermissionWrite={isUserPermissionWrite} />
					)}
				</div>
			)}
			{isOpenDeleteModal && (
				<ArchiveConfirmationModal
					description={renderArchiveConfirmationModal.desc}
					boldDescription={renderArchiveConfirmationModal.boldDes}
					label={confirmationLabel}
					title="Are you sure?"
					visible={isOpenDeleteModal}
					showLoader={isLoading}
					handleModal={handleDeleteUserRole}
				/>
			)}

			<InviteModalPage
				isOpen={isEditModalOpen}
				handleClose={handleCloseEditModal}
				disableSubAccount
				edit
			>
				<div className="ConfigureModal--footer">
					<Button
						label={inviteBackLabel}
						handleClick={handleBackEditModal}
						type="button__filled button__filled--secondary"
					/>

					<Button
						label={inviteButtonLabel}
						handleClick={onUpdateRole}
						type="button__filled button__filled--primary"
						disabled={apiLoading}
						loader={
							apiLoading ? <Loader dimension={14} type="loader" /> : <></>
						}
					/>
				</div>
			</InviteModalPage>
		</div>
	);
};
