import { ChangeEvent, Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from 'react';
import Modal from '@storybook/new-modal/modal';
import { Button, Loader } from '@storybook';

import { useSignedDocsInbox } from 'views/signed-doc-inbox/store';

interface IVoidCommentBoxProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  envelopeId: string;
}

export const VoidCommentBox: FC<IVoidCommentBoxProps> = ({ setIsOpen, envelopeId }) => {
  const { voidPacket, isLoaded } = useSignedDocsInbox();
  const [message, setMessage] = useState('');

  /**
   * @Manish Handle changes in the input field for the void reason
   * */ 
  const handleChangeMessage = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setMessage(value);
  }, []);

  /**
   * @Manish Close the modal
   * */ 
  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  /**
   * @Manish Handle form submission
   * */ 
  const handleSubmit = useCallback(async () => {
    if (isLoaded) {
      const payload: { statusMessage: string; status: 'voided' } = {
        statusMessage: message.trim(),
        status: 'voided',
      };
      await voidPacket(envelopeId, payload);
      setMessage('');
      setIsOpen(false);
    }
  }, [envelopeId, isLoaded, message, voidPacket, setIsOpen]);

  /**
   * @Manish Determine the label for the submit button based on loading status
   * */ 
  const renderSubmitLabel = useMemo(() => {
    if (isLoaded) {
      return 'Submit';
    }
    return <Loader className='loader-white' dimension={20} type='loader' />;
  }, [isLoaded]);

  return (
    <Modal
      isOpen
      modalName=''
      className='void-comment-box'
      closeModal={handleClose}
    >
      <div className='void-comment-box__header'>Add a reason</div>
      <div className='void-comment-box__body'>
        <textarea
          onChange={handleChangeMessage}
          className='void-comment-box__input-box'
          autoFocus
          placeholder='Enter the reason to void the packet'
        ></textarea>
      </div>
      <div className='void-comment-box__footer'>
        <Button
          label='Close'
          type='button__filled button__filled--secondary button__large'
          handleClick={handleClose}
        />
        <Button
          label={renderSubmitLabel}
          type='button__filled button__filled--danger button__large void-comment-box__void-btn'
          handleClick={handleSubmit}
          disabled={!(message.trim().length > 3)}
        />
      </div>
    </Modal>
  );
};
