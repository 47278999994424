import {
	KYC_SECTION_ICON,
	KYC_USER_INFORMATION_ICON,
	MetricsHeading,
} from '../../constants';
import { IKycComperhensiveAPIResponseDetails } from './types';

export const kycComperhensiveFormatter = (
	kycComperhensiveData: IKycComperhensiveAPIResponseDetails
) => {
	// Deep clone kycComperhensiveData to avoid mutating the original object
	const cloneData: IKycComperhensiveAPIResponseDetails = JSON.parse(
		JSON.stringify(kycComperhensiveData)
	);

	// Define a mapping of key names to type values
	const typeMapping: { [key: string]: string } = {
		liensJudgments: 'NormalCard',
		bankruptcyInformation: 'NormalCard',
		uccFilings: 'NormalCard',
		properties: 'DropCloseCard',
		huntingFishingLicenses: 'DropCard',
		sexualOffence: 'NormalCard',
		waterCrafts: 'DropCloseCard',
		faaPilots: 'DropCloseCard',
		faaAircrafts: 'DropCloseCard',
		vehicleRegistration: 'DropCloseCard',
		associates: 'DropCloseCard',
	};

	// Function to format each key in kycComperhensiveData
	const formatData = (key: keyof IKycComperhensiveAPIResponseDetails) => {
		const data = cloneData?.[key];
		const metrics = data?.metrics || []; // Default metrics to empty array if not provided
		const type = typeMapping[key as string] || 'UNKNOWN'; // Use type assertion as string

		const formatedMetrics = (metrics ?? []).map((item: any) => {
			// Initialize types for otherInformation and rest
			const otherInformation: Record<string, string | number> = {};
			const rest: Record<string, any> = {};

			// Iterate through each key-value pair in the item object
			Object.entries(item ?? {}).forEach(([key, value]) => {
				if (typeof value === 'string' || typeof value === 'number') {
					otherInformation[key] = value;
				} else if (value === null) {
					delete item[key];
				} else {
					rest[key] = value;
				}
			});

			return {
				...rest,
				otherInformation,
			};
		});

		return {
			label: data?.label ?? '--',
			data: {
				metrics: formatedMetrics ?? [],
				...(key === 'bankruptcyInformation'
					? {
							totalAboutSection: {
								count: metrics?.length ?? 0,
								title: 'Total number of bankruptcy ',
							},
					  }
					: {}),
				sectionType: type,
			},
		};
	};
	//* Format DL DATA --- >
	const formatdriverLicensesData = (
		key: keyof IKycComperhensiveAPIResponseDetails
	) => {
		// Assuming cloneData is defined somewhere in your code
		const data: any = cloneData?.[key] ?? {};
		const metrics = data?.metrics || [];
		// Find common key-value pairs among all metrics
		const commonInfo = (metrics ?? []).reduce(
			(acc: { [x: string]: any }, item: { [x: string]: any }) => {
				Object.keys(item).forEach(key => {
					if (
						metrics.every((obj: { [x: string]: any }) => obj[key] === item[key])
					) {
						acc[key] = item[key];
					}
				});
				return acc;
			},
			{}
		);

		// Create the new formatted data
		const formattedData = {
			label: data?.label || '--',
			data: {
				userInformation: [commonInfo],
				userInformationHeading: commonInfo?.name ?? metrics[0]?.name ?? '--',
				userInformationIcon:
					KYC_USER_INFORMATION_ICON[key] ?? KYC_SECTION_ICON['fileError'],
				metricsIcon: KYC_SECTION_ICON[key] ?? KYC_SECTION_ICON['fileError'],
				metricsHeading: MetricsHeading[key] ?? 'Information',
				metrics: metrics,
				sectionType: 'DropCard',
			},
		};

		return formattedData;
	};

	const formatSameData = (key: keyof IKycComperhensiveAPIResponseDetails) => {
		const data = cloneData?.[key];
		const metrics: any = data?.metrics || [];
		return {
			label: data?.label ?? '--',
			data: {
				metrics: metrics,
				metricsHeading: metrics?.[0]?.name ?? '--',
				sectionType: typeMapping[key],
			},
		};
	};

	const formatSexualOffenceData = (
		key: keyof IKycComperhensiveAPIResponseDetails
	) => {
		const data = cloneData?.[key];
		const metrics = data?.metrics || []; // Default metrics to empty array if not provided
		const type = typeMapping[key as string] || 'UNKNOWN'; // Use type assertion as string

		const formatedSexualOffenceMetrics = (metrics ?? []).map((item: any) => {
			// Initialize types for otherInformation and rest
			const otherInformation: Record<string, string | number> = {};
			const rest: Record<string, any> = {};

			// Iterate through each key-value pair in the item object
			Object.entries(item ?? {}).forEach(([key, value]) => {
				if (typeof value === 'string' || typeof value === 'number') {
					otherInformation[key] = value;
				} else if (
					typeof value === 'object' &&
					value !== null &&
					!Array.isArray(value)
				) {
					// Convert object to an array with the object as the 0th element
					rest[key] = [value];
				} else if (value === null) {
					delete item[key];
				} else {
					rest[key] = value;
				}
			});

			return {
				...rest,
				otherInformation: [otherInformation],
			};
		});
		return {
			label: data?.label ?? '--',
			data: {
				metrics: formatedSexualOffenceMetrics ?? [],
				sectionType: type,
			},
		};
	};

	const formatWaterCraftsData = (
		key: keyof IKycComperhensiveAPIResponseDetails
	) => {
		const data = cloneData?.[key];
		const metrics = data?.metrics || []; // Default metrics to empty array if not provided
		const type = typeMapping[key as string] || 'UNKNOWN'; // Use type assertion as string

		const formatedMetrics = (metrics ?? []).map((item: any) => {
			// Initialize types for otherInformation and rest
			const rest: Record<string, any> = {};
			// In title key card outinformation will there
			if (!item?.title) {
				item['title'] = {};
			}

			// Iterate through each key-value pair in the item object
			Object.entries(item ?? {}).forEach(([key, value]) => {
				if (typeof value === 'string' || typeof value === 'number') {
					item['title'][key] = value;
				} else if (value === null) {
					delete item[key];
				} else {
					rest[key] = value;
				}
			});

			return {
				...rest,
			};
		});

		return {
			label: data?.label ?? '--',
			data: {
				metrics: formatedMetrics ?? [],
				sectionType: type,
				metricsIcon: KYC_SECTION_ICON[key] ?? KYC_SECTION_ICON['fileError'],
				...(key === 'properties'
					? {
							totalAboutSection: {
								count: metrics?.length ?? 0,
								title: 'Total number of property',
							},
					  }
					: {}),
			},
		};
	};

	const formatVehicleRegistrationData = (
		key: keyof IKycComperhensiveAPIResponseDetails
	) => {
		const data = cloneData?.[key];
		const metrics = data?.metrics || []; // Default metrics to empty array if not provided
		const type = typeMapping[key as string] || 'UNKNOWN'; // Use type assertion as string
		const formatedMetrics = (metrics ?? []).map((item: any) => {
			// Initialize types for otherInformation and rest
			const rest: Record<string, any> = {};
			// In title key card outinformation will there
			if (!item?.title) {
				item['title'] = {};
			}

			// Iterate through each key-value pair in the item object
			Object.entries(item ?? {}).forEach(([key, value]) => {
				if (
					key === 'vehicleInfo' &&
					typeof value === 'object' &&
					value !== null
				) {
					// Move all entries from vehicleInfo to title
					Object.entries(value).forEach(([vKey, vValue]) => {
						if (typeof vValue === 'string' || typeof vValue === 'number') {
							item['title'][vKey] = vValue;
						} else {
							rest[vKey] = vValue;
						}
					});
					// Delete the vehicleInfo key from item
					delete item[key];
				} else if (typeof value === 'string' || typeof value === 'number') {
					item['title'][key] = value;
				} else if (value === null) {
					delete item[key];
				} else {
					rest[key] = value;
				}
			});

			return {
				...rest,
			};
		});

		return {
			label: data?.label ?? '--',
			data: {
				metrics: formatedMetrics ?? [],
				sectionType: type,
				metricsIcon: KYC_SECTION_ICON[key] ?? KYC_SECTION_ICON['fileError'],
			},
		};
	};

	const formatAssociatesData = (
		key: keyof IKycComperhensiveAPIResponseDetails
	) => {
		const data = cloneData?.[key];
		const metrics = data?.metrics || []; // Default metrics to empty array if not provided
		const type = typeMapping[key as string] || 'UNKNOWN'; // Use type assertion as string
		const formatedMetrics = (metrics ?? []).map((item: any) => {
			// Initialize types for otherInformation and rest
			const rest: Record<string, any> = {};
			// In title key card outinformation will there
			if (!item?.title) {
				item['title'] = {};
			}

			// Iterate through each key-value pair in the item object
			Object.entries(item ?? {}).forEach(([key, value]) => {
				if (key === 'identity' && typeof value === 'object' && value !== null) {
					// Move all entries from vehicleInfo to title
					Object.entries(value).forEach(([vKey, vValue]) => {
						if (typeof vValue === 'string' || typeof vValue === 'number') {
							item['title'][vKey] = vValue;
						} else {
							rest[vKey] = vValue;
						}
					});
					// Delete the vehicleInfo key from item
					delete item[key];
				} else if (typeof value === 'string' || typeof value === 'number') {
					item['title'][key] = value;
				} else if (value === null) {
					delete item[key];
				} else {
					rest[key] = value;
				}
			});

			return {
				...rest,
			};
		});

		return {
			label: data?.label ?? '--',
			data: {
				metrics: formatedMetrics ?? [],
				sectionType: type,
				metricsIcon: KYC_SECTION_ICON[key] ?? KYC_SECTION_ICON['fileError'],
			},
		};
	};

	// Create formattedCloneData
	const formattedCloneData: { [key: string]: any } = {};
	Object.keys(cloneData ?? {}).forEach(key => {
		if (
			key === 'uccFilings' ||
			key === 'bankruptcyInformation' ||
			key === 'liensJudgments'
		) {
			formattedCloneData[key] = formatData(
				key as keyof IKycComperhensiveAPIResponseDetails
			);
		} else if (key === 'sexualOffence') {
			formattedCloneData[key] = formatSexualOffenceData(
				key as keyof IKycComperhensiveAPIResponseDetails
			);
		} else if (
			key === 'driverLicenses' ||
			key === 'peopleAtWorks' ||
			key === 'professionalLicenses' ||
			key === 'weaponPermits' ||
			key === 'voterRegistration' ||
			key === 'criminalRecords'
		) {
			formattedCloneData[key] = formatdriverLicensesData(
				key as keyof IKycComperhensiveAPIResponseDetails
			);
		} else if (key === 'huntingFishingLicenses') {
			formattedCloneData[key] = formatSameData(
				key as keyof IKycComperhensiveAPIResponseDetails
			);
		} else if (
			key === 'waterCrafts' ||
			key === 'faaPilots' ||
			key === 'faaAircrafts' ||
			key === 'properties'
		) {
			formattedCloneData[key] = formatWaterCraftsData(
				key as keyof IKycComperhensiveAPIResponseDetails
			);
		} else if (key === 'vehicleRegistration') {
			formattedCloneData[key] = formatVehicleRegistrationData(
				key as keyof IKycComperhensiveAPIResponseDetails
			);
		} else if (key === 'associates') {
			formattedCloneData[key] = formatAssociatesData(
				key as keyof IKycComperhensiveAPIResponseDetails
			);
		} else {
			// If the key is not one of the specified keys, return the same data
			formattedCloneData[key] =
				cloneData[key as keyof IKycComperhensiveAPIResponseDetails];
		}
	});

	return {
		kycComperhensiveData: formattedCloneData,
	};
};
