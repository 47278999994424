import { FC, useEffect, useMemo } from 'react';

import { ReactResponsiveTable } from '@storybook';
import { ISignedDocInbox } from 'views/signed-doc-inbox';
import {
	ActivityStatus,
	SIGN_HISTORY_TABLE_COLUMNS,
	useGetSignDocHistory,
	useSignDocHistory,
} from '../stores';

interface IActivityLog {
	envelopeId: string;
	inbox: ISignedDocInbox;
}

export const ActivityLogTable: FC<IActivityLog> = ({ envelopeId, inbox }) => {
	const { activityLogs } = useGetSignDocHistory(inbox);
	const { fetchHistory, isLoaded } = useSignDocHistory();

	useEffect(() => {
		if (envelopeId) {
			fetchHistory(envelopeId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const activities = useMemo(() => {
		const rows: any = [];
		activityLogs.forEach((activity: any) => {
			let row: any = {};
			SIGN_HISTORY_TABLE_COLUMNS.forEach(({ key, format }) => {
				if (key === 'status' && format === 'jsx') {
					const value = () => {
						const status = activity?.[key]?.toLowerCase();
						return (
							<div className="details-container__content-value">
								{ActivityStatus[status] ?? status}
							</div>
						);
					};
					row = {
						...row,
						[key]: value,
					};
					return;
				}
				row = {
					...row,
					[key]: activity?.[key],
				};
			});
			rows.push(row);
		});
		return rows;
	}, [activityLogs]);

	return (
		<div className="details-container">
			<div className="details-container__label">Activity log</div>
			<div className="details-container__content">
				<ReactResponsiveTable
					isLoaded={isLoaded}
					isLoading={!isLoaded}
					column={SIGN_HISTORY_TABLE_COLUMNS}
					rows={activities}
					showSort={false}
					showSearch={false}
					className="activity-table"
					showDateFilter={false}
					EmptyIllustration="empty-aggrement-docs.svg"
					EmptyMessageHeading="No Activity available"
					EmptyMessageDescription="This envelope does not have any activity yet."
					isSignActivityLog
				/>
			</div>
		</div>
	);
};
