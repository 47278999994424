import { FC, ReactNode, useEffect, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { PipelineMasterSteps } from 'global-stores';
import { ActionList } from 'views/complex-onboarding-flow/constant';
import { ComplexSettingFormState } from 'views/pipeline';
import { CustomHandle } from '../custom-handle';
import { ICustomNode } from '../custom-node';
import { RenderProofReadingBlock } from './render-proof-read-documents';

export const ProofReadDocumentsWrapper = ({
	children,
}: {
	children: ReactNode;
}) => {
	return <div className="kyb-action">{children}</div>;
};

export const ProofReadDocumentsAction: FC<ICustomNode> = ({
	id: nodeId,
	data,
}) => {
	const complexSettingForm = useRecoilValue(ComplexSettingFormState);
	const pipelinesSteps = useRecoilValue(PipelineMasterSteps);
	const setComplexSettingForm = useSetRecoilState(ComplexSettingFormState);

	const selectDefaultLabel = useMemo(
		() => complexSettingForm?.[nodeId]?.signAgreementProvider,
		[complexSettingForm, nodeId]
	);
	const actionId = useMemo(() => data.label, [data.label]);

	const actions = useMemo(
		() =>
			pipelinesSteps.find(
				pipelineStep => pipelineStep.key === ActionList[actionId]
			)?.actions[0],
		[actionId, pipelinesSteps]
	);

	// Set proof reading action in complex config-setting-form for showing price breakdown
	useEffect(() => {
		setComplexSettingForm((prev: any) => {
			const newObj = structuredClone(prev);
			if (!newObj[nodeId]) {
				newObj[nodeId] = {};
			}
			Object.assign(newObj[nodeId], {
				proofReading: 'proofReading',
			});
			return newObj;
		});
	}, [nodeId, setComplexSettingForm]);

	// Calc. price
	const price = useMemo(() => {
		return (
			actions?.metadata?.[0]?.options?.find(el => el.key === selectDefaultLabel)
				?.price ?? actions?.price
		);
	}, [actions?.metadata, actions?.price, selectDefaultLabel]);

	return (
		<ProofReadDocumentsWrapper>
			<RenderProofReadingBlock label="Reading document" price={price} />
			<CustomHandle value={nodeId} />
		</ProofReadDocumentsWrapper>
	);
};
