import { ReactElement, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Loader } from '@storybook';
import { Wrapper, Status } from '@googlemaps/react-wrapper';

import { REACT_APP_GOOGLE_MAP_SECRET_TOKEN as TOKEN } from 'envs';
import {
	ComplexSessionInfoState,
	ConditionalSessionFlowState,
	SelectedSessionState,
} from 'global-stores';
import { GOOGLE_API } from 'constant';
import { MapComponent } from './map';

import './map.scss';

const mapRender = (status: Status): ReactElement => {
	if (status === Status.FAILURE) return <></>;
	return <Loader />;
};
interface IGoogleMap {
	sessionData?: any;
}

export const GoogleMap = ({ sessionData }: IGoogleMap) => {
	const sessionSelected = useRecoilValue(SelectedSessionState);
	const isComplexFlow = useRecoilValue(ConditionalSessionFlowState);
	const complexSessionInfo = useRecoilValue(ComplexSessionInfoState);

	const { geoLocation } = useMemo(() => {
		if (isComplexFlow) {
			return (sessionData ?? complexSessionInfo)?.metadata ?? ({} as any);
		}
		return (
			sessionSelected?.device ?? sessionSelected?.deviceInfo ?? ({} as any)
		);
	}, [
		complexSessionInfo,
		isComplexFlow,
		sessionSelected?.device,
		sessionSelected?.deviceInfo,
		sessionData,
	]);

	const { latitude, longitude }: { [key: string]: any } = useMemo(
		() => geoLocation ?? {},
		[geoLocation]
	);

	const url = `${GOOGLE_API.MAP}${latitude},${longitude}`;
	return (
		<>
			{latitude && longitude && (
				<div className="map_container_body">
					<a
						href={url}
						target="_blank"
						rel="noreferrer"
						className="map_container"
					>
						<Wrapper apiKey={TOKEN} render={mapRender}>
							<MapComponent
								center={{
									lat: latitude,
									lng: longitude,
								}}
								zoom={11}
							/>
						</Wrapper>
					</a>
				</div>
			)}
		</>
	);
};
