import { FC, MouseEvent, createRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { IsOnboardingDeleteState } from 'global-stores';
import { IconBtn, Loader } from '@storybook';
import { useOutsideClick } from 'hooks';

import './drop.scss';
import { ConfirmationModal } from 'components';


export interface IDropOption {
	handleDelete: () => void;
	description?: string | JSX.Element ;
}

export const DropOption: FC<IDropOption> = ({ handleDelete, description}) => {

	const setIsOnboardingDelete = useSetRecoilState(IsOnboardingDeleteState)
	const isDeleteOnboarding = useRecoilValue(IsOnboardingDeleteState)

	// local state
	const [showOption, setShowOption] = useState<boolean>(false);
	const [isModal, setIsModal] = useState<boolean>(false);
	const [isDeleted, setIsDeleted] = useState<boolean>(false);

	// ref
	const ref = createRef<HTMLDivElement>();

	useOutsideClick(ref, () => {
		setShowOption(false);
	});

	const handleOptions = useCallback((e:MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		setShowOption(prev => !prev);
	}, []);

	const handleclickOnDelete = useCallback(() => {
		setIsModal(true);
		setShowOption(false);
	}, [setIsModal]);

	useEffect(()=>{
		if(!isDeleteOnboarding){
			setIsDeleted(false)
			setIsModal(false)
		}
	},[isDeleteOnboarding])	

	const handleDeleteBtn = useCallback(
		(isOpen: boolean, isDelete: boolean) => {
			if (isDelete) {
				setIsDeleted(true);
				setIsOnboardingDelete(true)
				handleDelete();
			}
			if(!isDeleteOnboarding && !isDelete){
				setIsModal(isOpen)
			}
		},[setIsOnboardingDelete, isDeleteOnboarding, handleDelete]);

	const renderDescription = useMemo(() => {
		return (
			<div className="actions-wrapper__description-wrapper">
				{description || null}
			</div>
		);
	}, [description]);

	return (
		<>
			<div ref={ref} className={'options-wrapper'}>
				<IconBtn
					className="more-options-btn-instance"
					btnClassName="ri-more-2-fill"
					handleClickIcon={handleOptions}
				/>
				{showOption && (
					<div className="options-box">
						<button className="menu-item" onClick={handleclickOnDelete}>
							<i className="ri-inbox-unarchive-line space" />
							Archive
						</button>
					</div>
				)}
			</div>
			<ConfirmationModal
				visible={isModal}
				title={'Are you sure?'}
				handleModal={(isOpen, isDelete) => handleDeleteBtn(isOpen, isDelete)}
				label={
					isDeleted ? (
						<Loader dimension={20} className="loader-white" type="loader" />
					) : (
						'Archive'
					)
				}
				description={renderDescription}
			/>
		</>
	);
};
