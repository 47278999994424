import { FC, useCallback, useEffect } from 'react';
import ReactFlow, {
	addEdge,
	ConnectionLineType,
	useNodesState,
	useEdgesState,
	Background,
	Controls,
} from 'reactflow';
import dagre from 'dagre';

import 'reactflow/dist/style.css';
import customNode from './components/bread-crumb/bread-crumb';

interface IOnboardingTreeGraph {
	details: any;
	showOnBoardingName: boolean;
	viewComplexModal?: any;
	closeComplexModal?: any;
	complexStepView?: any;
}

const nodeTypes = { customNode };
const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 172;
const nodeHeight = 36;

const getLayoutedElements = (
	nodesObj: any[],
	edges: any[],
	direction = 'LR'
) => {
	const isHorizontal = direction === 'LR';
	dagreGraph.setGraph({ rankdir: direction });

	const nodes = structuredClone(nodesObj);
	nodes.forEach(node => {
		dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
	});

	edges.forEach(edge => {
		dagreGraph.setEdge(edge.source, edge.target);
	});

	dagre.layout(dagreGraph);

	nodes?.forEach(node => {
		const nodeWithPosition = dagreGraph.node(node.id);
		node.targetPosition = isHorizontal ? 'left' : 'top';
		node.sourcePosition = isHorizontal ? 'right' : 'bottom';

		// We are shifting the dagre node position (anchor=center center) to the top left
		// so it matches the React Flow node anchor point (top left).
		node.position = {
			x: nodeWithPosition.x - nodeWidth / 2,
			y: nodeWithPosition.y - nodeHeight / 2,
		};

		return node;
	});

	return { nodes, edges };
};

export const OnboardingTreeGraph: FC<IOnboardingTreeGraph> = ({ details }) => {
	const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
		details?.design?.nodes?.map((el: any) => {
			return {
				position: { x: 0, y: 0 },
				id: el.id,
				data: { label: el.label, key: el.key },
				type: 'customNode',
			};
		}) ?? [],
		details?.design?.edges.map((el: any) => {
			return {
				id: el.id,
				source: el.source,
				target: el.target,
				markerEnd: el.markerEnd,
			};
		}) ?? []
	);

	const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
	const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);

	useEffect(() => {
		const noooo =
			details?.design?.nodes?.map((el: any) => {
				return {
					position: { x: 0, y: 0 },
					id: el.id,
					data: { label: el.label, key: el.key },
					type: 'customNode',
				};
			}) ?? [];

		const edgesssss =
			details?.design?.edges.map((el: any) => {
				return {
					id: el.id,
					source: el.source,
					target: el.target,
					markerEnd: el.markerEnd,
				};
			}) ?? [];
		const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
			noooo,
			edgesssss
		);
		setNodes([...layoutedNodes]);
		setEdges([...layoutedEdges]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onConnect = useCallback(
		(params: any) =>
			setEdges(eds =>
				addEdge(
					{ ...params, type: ConnectionLineType.SmoothStep, animated: true },
					eds
				)
			),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return (
		<ReactFlow
			nodes={nodes}
			edges={edges}
			nodeTypes={nodeTypes}
			onNodesChange={onNodesChange}
			onEdgesChange={onEdgesChange}
			onConnect={onConnect}
			fitView
			zoomOnPinch={false}
			zoomOnDoubleClick={false}
			zoomOnScroll={false}
			draggable={false}
			defaultViewport={{ x: 0, y: 100, zoom: 0.5 }}
		>
		<Controls position="top-left"  />
		<Background color="#aaa" gap={24} size={3} />
		</ReactFlow>
	);
};
