import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { SubscriptionPriceState } from 'global-stores';
import { ChooseServicesData } from 'views/user-roles/constant';
import { SelectedUserRoleState } from 'views/user-roles/store';

import './agent-selected-service.scss';

export const AgentSelectedService = () => {
	const selectedUserRole = useRecoilValue(SelectedUserRoleState);
	const subscriptionPrice = useRecoilValue(SubscriptionPriceState);
	const { services = [] } = selectedUserRole ?? {};

	const subsPrice: Record<string, string> | undefined = useMemo(() => {
		if (subscriptionPrice) {
			return subscriptionPrice[0];
		} else return {};
	}, [subscriptionPrice]);

	return (
		<>
			{ChooseServicesData.map((item, index: number) => {
				return JSON.stringify(services) === JSON.stringify(item.key) ? (
					<div
						className="AgentSelectedService--container"
						key={item.type + index.toString()}
					>
						<div className="AgentSelectedService--container__header">
							Service:
						</div>
						<div className="AgentSelectedService--container__card">
							<div className="AgentSelectedService--container__card--data">
								<div className="AgentSelectedService--container__card--data__left">
									<div className="AgentSelectedService--container__card--data__left--servicesName">
										{item.label}
									</div>
									<div className="AgentSelectedService--container__card--data__left--price">
										${(subsPrice ?? {})[item.type]}
										<span className="pricePerMonth">/user/month</span>
									</div>
								</div>
								<div className="AgentSelectedService--container__card--data__line"></div>
								<div className="AgentSelectedService--container__card--data__right">
									<div className="AgentSelectedService--container__card--data__right--action">
										Account type required:
									</div>

									{item.accountTypes.map((account, index: number) => {
										return (
											<div
												className="AgentSelectedService--container__card--data__right--bussiness"
												key={account.bussiness + index.toString()}
											>
												<div className="AgentSelectedService--container__card--data__right--bussiness__icon">
													<i
														className={`${account.icon} section--container__textIcon--bussinessName__icon--svg`}
													></i>
												</div>
												<div className="AgentSelectedService--container__card--data__right--bussiness__label">
													{account.bussiness}
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				) : (
					<></>
				);
			})}
		</>
	);
};
