import { FC, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { SelectedTransactionDealState } from '../../stores';
import { PlaceholderInfo, UserInfo } from '../user-info';

import './deal-user.scss';

export interface IInfo {
	[key: string]: string;
}

interface IData {
	key: string;
	label: string;
	icon: string;
	info: IInfo[];
}
interface IDealUser {
	data: IData;
}

export const DealUser: FC<IDealUser> = ({ data }) => {
	// globle states
	const selectedDeal = useRecoilValue(SelectedTransactionDealState);

	const { key = '', label = '', icon = '', info = [] } = data ?? {};

	const renderUsers = useMemo(() => {
		const users = selectedDeal?.[key as 'sellers'];
		return users?.length ? (
			users.map(user => <UserInfo key={user._id} info={info} user={user} />)
		) : (
			<PlaceholderInfo userKey={key} />
		);
	}, [info, key, selectedDeal]);

	return (
		<div className="DealUser">
			<div className="DealUser__header">
				<div className="DealUser__title">
					<div className={`DealUser__title-icon title-icon-${key}`}>
						<i className={icon as string} />
					</div>
					<div className="DealUser__title-name">{label as string}</div>
				</div>
			</div>
			{renderUsers}
		</div>
	);
};
