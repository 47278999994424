/* eslint-disable react/no-array-index-key */
import type { IActivities, IReplies, Reply } from 'global-stores/sessions/type';

import {
	ChangeEvent,
	Fragment,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useRecoilValue, useSetRecoilState, useResetRecoilState } from 'recoil';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { TextArea, Loader, Image, Button } from '@storybook';
import { useNetwork, useNotification } from 'hooks';
import { getDateWithTime } from 'utils';
import {
	userDataState,
	SessionItemSelected,
	SelectedSessionState,
	CcEmailState,
	CcEmailInputState,
	ConditionalSessionFlowState,
	ComplexSessionInfoState,
	BccEmailInputState,
	BCcEmailState,
} from 'global-stores';
import { API_URL } from 'constant';
import { EmailTag } from './emailTag';
import { CommentDocument } from './comment-document';
import { Actions, LabelDataActivity, sessionMessage } from '../constants';
import { SurveyReadAnswer } from 'components';
import { SessionActivitiyLogLoader } from '../session-details-loader';
import { useComplexSessionInfo } from '../hooks';
import { CheckFraudTransactions, ICustomTabs } from 'views/cheque-fraud';
import { PERMISSION_SCREEN, useUserRoles } from 'views/routes-children';

export const ActivityLog = ({
	isLoading: isLoaded,
	dataLoaded,
	chequeFraudPage = false,
	activeTab = '',
	customTabs = [],
}: any) => {
	// global states
	const selectedSession = useRecoilValue(SelectedSessionState);
	const loginStateData = useRecoilValue(userDataState);
	const setSessionSelected = useSetRecoilState(SelectedSessionState);
	const selectedSessionItem = useRecoilValue(SessionItemSelected);
	const ccEmailItem = useRecoilValue(CcEmailState);
	const resetCcEmails = useResetRecoilState(CcEmailState);
	const CcEmailInput = useRecoilValue(CcEmailInputState);
	const resetCcEmailInput = useResetRecoilState(CcEmailInputState);
	const BccEmailItem = useRecoilValue(BCcEmailState);
	const resetBCcEmails = useResetRecoilState(BCcEmailState);
	const BCcEmailInput = useRecoilValue(BccEmailInputState);
	const isComplexFlow = useRecoilValue(ConditionalSessionFlowState);
	const complexSessionInfoState = useRecoilValue(ComplexSessionInfoState);
	const resetCcInput = useResetRecoilState(CcEmailInputState);
	const resetBCcInput = useResetRecoilState(BccEmailInputState);

	const { complexSessionInfo } = useComplexSessionInfo();

	// destructure variable
	const { activities, form }: any = useMemo(() => {
		if (isComplexFlow || chequeFraudPage) {
			return complexSessionInfoState ?? {};
		}
		return selectedSession ?? {};
	}, [
		complexSessionInfoState,
		isComplexFlow,
		chequeFraudPage,
		selectedSession,
	]);

	// local states
	const [textValue, setTextValue] = useState('');
	const [saveBtn, setSaveBtn] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [activeLog, setActiveLog] = useState('all');
	const [activityData, setActivityData] = useState(activities);
	const [selectedData, setSelectedData] = useState<IActivities[]>(activities);
	const [isEmailSend, setIsEmailSend] = useState(true);
	const [isDocumentRequired, setIsDocumentRequired] = useState(false);
	const [showCC, setShowCC] = useState(false);
	const [showBCC, setShowBCC] = useState(false);

	const { AddCcMessage, AddBCcMessage } = sessionMessage;

	// hooks
	const { errorNotification } = useNotification();
	const {
		patch: sessionAction,
		data: sessionActionData,
		isLoaded: sessionActionLoaded,
	} = useNetwork();
	const {
		get: getSessionDetails,
		data: sessionDetails,
		error: sessionDetailsError,
	} = useNetwork();

	const { checkUserWritePermission } = useUserRoles();

	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.Sessions),
		[checkUserWritePermission]
	);

	useEffect(() => {
		if (activities?.length) {
			// eslint-disable-next-line
			let activity = activities
			setActivityData(activity);
			if (activeLog === 'all') {
				setSelectedData(activity);
			} else {
				const commentData = activity.filter(
					(data: any) => data.type === 'comment'
				);
				setSelectedData(commentData);
			}
		} else {
			setSelectedData([]);
		}
	}, [activities, activeLog]);

	useEffect(() => {
		if (sessionActionLoaded) {
			const { _id } = selectedSessionItem;
			if (isComplexFlow) {
				complexSessionInfo(_id ?? '');
			} else {
				getSessionDetails(`${API_URL.SESSION_DETAIL}/${_id}`);
			}
		
            resetBCcEmails();
			resetCcEmails();
			
			setTextValue('');
			setIsLoading(false);
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sessionActionData]);
	
     
	useEffect(() => {
		if (textValue) {
			setSaveBtn(false);
		} else {
			setSaveBtn(true);
		}
	}, [textValue]);

	useEffect(() => {
		if (sessionDetails?.data) {
			setSessionSelected(sessionDetails.data);
			setTextValue('');
			setIsLoading(false);
			setIsEmailSend(true);
		} else if (sessionDetails) {
			errorNotification('Failed to load Session flows.');
		}
		if (sessionDetailsError) {
			setIsLoading(false);
			errorNotification('Failed to load Session flows.');
		}
		// eslint-disable-next-line
	}, [sessionDetails]);

	const handleTextChange = useCallback(
		(e: ChangeEvent<HTMLTextAreaElement>) => {
			setTextValue(e.target.value);
		},
		[]
	);

	const stepName = useCallback(
		(stepId: string) => (
			<>
				{(Object.keys(LabelDataActivity) || []).map((sepIdText, index) => {
					return (
						<span key={'step' + index}>
						{stepId === sepIdText ? LabelDataActivity[sepIdText] : ''}
						</span>
					);
				})}
			</>
		),
		[]
	);

	// this will manage the style of the status of the process
	const statusJsx = useCallback(
		(statusText: string) => (
			<>
				{(Actions || []).map((action, index) => {
					return (
						<span key={'action' + index}>
							{statusText?.toLowerCase() === action?.value ? (
								<span style={{ color: action?.color }}>{action?.label}</span>
							) : (
								''
							)}
						</span>
					);
				})}
			</>
		),
		[]
	);

	// this will extract the first letter from the userName
	const firstChar = useCallback(
		(name = '') => <>{name[0]?.toUpperCase() ?? '?'}</>,
		[]
	);

	const renderAttachments = useCallback((attachments: any) => {
		return attachments.length ? (
			<div className="comment-document-wrapper">
				{attachments.map((attachment: any) => {
					const { name, type, password, url, _id } = attachment ?? {};
					return (
						<CommentDocument
							key={_id}
							name={name}
							type={type}
							password={password}
							url={url}
							_id={_id}
						/>
					);
				})}
			</div>
		) : (
			<></>
		);
	}, []);

	const repliedComment = useCallback(
		(commentsData: Reply[] | IReplies[]) => {
			return (
				<div className="replied-container">
					{commentsData.map((repliedComment: any, index: number) => {
						const { sender, reply, time, attachments } = repliedComment;
						return (
							<div className="comment-box" key={`${sender.name}__${index}`}>
								<div>
									<div className="replied-comment-image">
										{firstChar(sender.name)}
									</div>
								</div>
								<div className="comment-head-container">
									<div className="comment-head">
										<div className="commentor-user-details">
											<div className="commentator-name">
												<span>{sender.name}</span>
												<span className="comment-date">
													{` ${getDateWithTime(time)}`}
												</span>
											</div>
											{
												<div className="email-sent-badge">
													Replied Via Email
												</div>
											}
										</div>
									</div>
									<div className="comment-message">{reply ?? 'NA'}</div>
									{renderAttachments(attachments)}
								</div>
							</div>
						);
					})}
				</div>
			);
		},
		[firstChar, renderAttachments]
	);

	// this will render activities list

	const activityJsx = useCallback(
		(activities: IActivities[] = []) => (
			<>
				{activities.map(({ metadata, type, userName, replies }: any, index) => {
					const {
						data = '',
						from = '',
						stepId = '',
						time = 0,
						to = '',
						sendMail = false,
						cc = [],
						bcc = [],
					} = metadata ?? {};
					return type === 'status' ? (
						from !== to && (
							<div
								className="activity-comment"
								key={`activity-comment-${activeLog}-${index}`}
							>
								<div className="commentator-image">
									{firstChar(userName ? userName : loginStateData?.name)}
								</div>
								<div className="comment-head-container">
									<div className="commentor-user-details">
										<div className="commentator-name">
											<span className="commentator__name_format">
												{userName ? userName : loginStateData?.name}
											</span>
											<span className="comment-text">
											{" "}has changed the status of {" "}
											</span>
											<span className="comment-text"> {stepName(stepId)} from </span>
											{statusJsx(from)}
											<span className="comment-text"> to </span>
											{statusJsx(to)}
										</div>

										{sendMail && (
											<div className="email-sent-badge">Email sent</div>
										)}
									</div>
									<div className="comment-date">{getDateWithTime(time)}</div>
									<div className="comment-message">{data ?? ''}</div>
									{replies?.length > 0 && repliedComment(replies)}
								</div>
							</div>
						)
					) : type === 'logs' ? (
						<div
							className="activity-comment"
							key={`activity-comment-${activeLog}-${index}`}
						>
							<div className="commentator-image">
								{firstChar(userName ? userName : loginStateData?.name)}
							</div>
							<div className="comment-head-container">
								<div className="commentor-user-details">
									<div className="commentator-name">
										<span className="commentator__name_format">
											{userName ? userName : loginStateData?.name}
										</span>
										<span className="comment-text"> has </span>
										<span className="comment-name">
											{data + ' ' + getDateWithTime(time)}
										</span>
									</div>
								</div>
								{/* <div className="comment-date">{getDateWithTime(time)}</div> */}
							</div>
						</div>
					) : (
						<div
							className="activity-comment"
							key={`activity-comment-${activeLog}-${index}`}
						>
							<div className="commentator-image">
								{firstChar(userName ? userName : loginStateData?.name)}
							</div>
							<div className="comment-head-container">
								<div className="comment-head">
									<div className="commentor-user-details">
										<div className="commentator-name">
											<span className="commentator__name_format">
												{userName ? userName : loginStateData?.name}
											</span>
											<span className="comment-date">
												{` ${getDateWithTime(time)}`}
											</span>
										</div>
										<div className='bcc-cc-alingment'>
											<div>
										{cc.length > 0 && (
											<button
												type="button"
												className="email-cc-count"
												id={`ccEmail-${index}`}
											>
												CC
												<ReactTooltip
													anchorId={`ccEmail-${index}`}
													place="bottom"
													content={`CC :  ${cc.join('<br>')}`}
													className="email-cc-toolTip"
													html={`CC :  ${cc.join('<br>')}`}
												/>
												{cc.length > 0 && (
													<span className="cc-count">{cc.length}</span>
												)}
											</button>
										)}
                                           </div>
										   <div>
										{bcc.length > 0 && (
											<button
												type="button"
												className="email-bcc-count"
												id={`bccEmail-${index}`}
											>
												BCC
												<ReactTooltip
													anchorId={`bccEmail-${index}`}
													place="bottom"
													content={`BCC :  ${bcc.join('<br>')}`}
													className="email-cc-toolTip"
													html={`BCC :  ${bcc.join('<br>')}`}
												/>
												{bcc.length > 0 && (
													<span className="cc-count">{bcc.length}</span>
												)}
											</button>
										)}
										</div>
										</div>

										{sendMail && (
											<div className="email-sent-badge">Email sent</div>
										)}
									</div>
								</div>
								<div className="comment-message">{data ?? ''}</div>
								{replies?.length > 0 && repliedComment(replies)}
							</div>
						</div>
					);
				})}
			</>
		),
		[
			activeLog,
			firstChar,
			loginStateData?.name,
			statusJsx,
			stepName,
			repliedComment,
		]
	);
	// this will manage the submit btn click handle of any action
	const handleSubmitAction = useCallback(() => {
		if (CcEmailInput.text) {
			return errorNotification(AddCcMessage);
		}
		if (BCcEmailInput.text) {
			return errorNotification(AddBCcMessage);
		}
		setIsLoading(true);
		const { _id, sendEmail } = selectedSessionItem;
		const actionType = 'comment';

		const ccEmails = ccEmailItem.map(({ text }) => text);
		const bccEmails = BccEmailItem.map(({ text }) => text);
			
			const bccActioData = {
				data: textValue,
				type: actionType,
				userName: loginStateData?.name,
				sendMail: isEmailSend,
				requiredAttachment: isDocumentRequired,
				recipientEmail: sendEmail,
				bcc: bccEmails,
				cc: ccEmails,
				flow: isComplexFlow ? 'complex' : 'linear',
			};
			sessionAction(`${API_URL.ACTIVITIES}/${_id}`, bccActioData);
		
		setTimeout(() => {
			setIsDocumentRequired(false);
		}, 1000);
		return null;
	}, [CcEmailInput.text, BCcEmailInput.text, selectedSessionItem, ccEmailItem, BccEmailItem, textValue, loginStateData?.name, isEmailSend, isDocumentRequired, isComplexFlow, sessionAction, errorNotification, AddCcMessage, AddBCcMessage]);

	// this will manage the tab switch from all activities to comments
	const handleTab = useCallback(
		(activityType: any) => {
			if (activityType === 'fraudulent-transactions') {
				setActiveLog(activityType);
				return;
			}
			if (activityType === 'all-transactions') {
				setActiveLog(activityType);
				return;
			}

			if (activityType === 'form') {
				setActiveLog('form');
				return;
			}
			if (activityType === 'comment') {
				setActiveLog('comment');
				const commentData = (activityData ?? []).filter(
					(data: any) => data.type === 'comment'
				);

				if (BccEmailItem) {
					resetBCcInput();
				}
	
				if (ccEmailItem) {
				resetCcInput();
				}
				setSelectedData(commentData);
			} else {
				setActiveLog('all');
				setSelectedData(activityData);
			}
		},
		[BccEmailItem, activityData, ccEmailItem, resetBCcInput, resetCcInput]
	);

	// Email checkbox on change
	const changeCheckbox = useCallback((isChecked: boolean) => {
		setIsEmailSend(isChecked);
		if (!isChecked) {
			setIsDocumentRequired(false);
			 if (ccEmailItem) {
				resetCcEmails();
				resetCcEmailInput();
			 }
			if (BccEmailItem) {
				resetBCcEmails();
			}
		}	
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [BccEmailItem, ccEmailItem]);

	const changeDocumentRequired = useCallback((isChecked: boolean) => {
		setIsDocumentRequired(isChecked);
	}, []);

	const handleAddCc = useCallback(() => {
		setShowBCC(false);
		setShowCC(true);
	}, []);

	const handleAddBCc = useCallback(() => {
		setShowCC(false);
		setShowBCC(true);
	}, []);

	useEffect(() => {
		if (chequeFraudPage && activeTab) setActiveLog(activeTab);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeTab]);

	return (
		<div className="kycDetails-contain activity-sticky">
			<div className="activity-container">
				<div className="activity-btn-container">
					{chequeFraudPage && customTabs.length > 0 && (
						<Fragment>
							{customTabs?.map((el: ICustomTabs) => (
								<button
									type="button"
									className={`activity-tab  ${activeLog === el.key ? 'active' : ''
										}`}
									key={el.key}
									onClick={() => handleTab(el.key)}
								>
									{el.label}
								</button>
							))}
						</Fragment>
					)}
					<button
						type="button"
						className={`activity-tab  ${activeLog === 'all' ? 'active' : ''} `}
						onClick={() => handleTab('all')}
					>
						All Activity
					</button>
					<button
						type="button"
						className={`activity-tab  ${activeLog === 'comment' ? 'active' : ''
							}`}
						onClick={() => handleTab('comment')}
					>
						Only Comments
					</button>

					{form && Object.keys(form)?.length > 0 && (
						<button
							type="button"
							className={`activity-tab  ${activeLog === 'form' ? 'active' : ''
								}`}
							onClick={() => handleTab('form')}
						>
							Questionnaire
						</button>
					)}
				</div>

				{activeLog === 'fraudulent-transactions' && (
					<div className="all-transaction-lists">
						<CheckFraudTransactions
							className="transaction-wrapper"
							type="fraud"
						/>
					</div>
				)}
				{activeLog === 'all-transactions' && (
					<div className="all-transaction-lists">
						<CheckFraudTransactions
							className="transaction-wrapper"
							type="all"
						/>
					</div>
				)}

				{activeLog === 'form' && (
					<div className="comment__action-container">
						<SurveyReadAnswer />
					</div>
				)}
				{!isLoaded && dataLoaded ? (
					activeLog !== 'form' &&
					!/all-transactions|form|fraudulent-transactions/gi.test(
						activeLog
					) && (
						<div className="comment__action-container">
							{activeLog === 'comment' && isUserPermissionWrite && (
								<div className="comment-send">
									<TextArea
										label=""
										value={textValue}
										handleChange={handleTextChange}
										placeholder="Enter your comment..."
										row={3}
									/>

									{/* Comment box footer - email check and comment save button */}

									<div className="comment-footer">
										<div className="comment--footer">
											<div className="email--checkbox">
												<label className="email--checkbox-label">
													<input
														type={'checkbox'}
														checked={isEmailSend}
														className="checkbox email--checkbox-input"
														onChange={e => changeCheckbox(e.target.checked)}
													/>
													<div className="lable-text">Send as Mail</div>
												</label>
												{isEmailSend && (
													<>
														<label className="email--checkbox-label">
															<input
																type={'checkbox'}
																checked={isDocumentRequired}
																className="checkbox email--checkbox-input"
																onChange={e =>
																	changeDocumentRequired(e.target.checked)
																}
															/>
															<div className="lable-text">Request Document</div>
														</label>
														<button
															type="button"
															className={`add-cc ${showCC?"add-cc-active":""}`}
															onClick={handleAddCc}
														>
															Add CC
															{ccEmailItem.length > 0 && (
																<span className="cc-count">
																	{ccEmailItem.length}
																</span>
															)}
														</button>
														<button
															type="button"
															className={`add-cc ${showBCC?"add-cc-active":""}`}
															onClick={handleAddBCc}
														>
															Add BCC
															{BccEmailItem.length > 0 && (
																<span className="cc-count">
																	{BccEmailItem.length}
																</span>
															)}
														</button>
													</>
												)}
											</div>
											<div>
                                               {<EmailTag isVisible={(showCC || showBCC) && isEmailSend} type={showCC ? 'cc' : showBCC ? 'bcc' : ""} />}
											</div>
											
											<Button
												handleClick={handleSubmitAction}
												label={isLoading ? '' : 'Comment'}
												type="button__filled button__filled--primary comment-btn"
												buttonType="button"
												disabled={isLoading || saveBtn}
												loader={
													isLoading ? (
														<Loader
															type="loader"
															className="loader-blue"
															dimension={16}
														/>
													) : (
														<></>
													)
												}
											/>											
										</div>
									</div>
								</div>
							)}
							{selectedData?.length > 0 ? (
								activityJsx(selectedData)
							) : !isLoaded ? (
								<div className="kycDetails-noDataFound">
									<Image fileName="AddComment.svg" />
									<div>No data available!</div>
								</div>
							) : (
								<p>Loading...</p>
							)}
						</div>
					)
				) : (
					<SessionActivitiyLogLoader />
				)}
			</div>
		</div>
	);
};
