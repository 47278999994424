import { atom } from 'recoil';

import { IDateRange } from 'global-stores';
import { defineds } from '@storybook/date-range-picker/defineds';
import { IFunnelChartState } from './types';

const { startOfLast30days, endOfLast30days, startOfLastWeek, endOfLastWeek } =
	defineds;

export const analyticsChartHeaderState = atom<IDateRange[]>({
	key: 'analytics-chart-header-states',
	default: [
		{
			startDate: startOfLast30days,
			endDate: endOfLast30days,
			key: 'selection',
		},
	],
});

export const analyticsChartTwoHeaderStart = atom<IDateRange[]>({
	key: 'analytics-chartTwo-header-states',
	default: [
		{
			startDate: startOfLastWeek,
			endDate: endOfLastWeek,
			key: 'selection',
		},
	],
});

export const funnelChartFirstState = atom<IFunnelChartState>({
	key: 'funnel-chart-first-state',
	default: [],
});

export const funnelChartSecondState = atom<IFunnelChartState>({
	key: 'funnel-chart-second-state',
	default: [],
});

export const ChartCompareFirstState = atom<{ [key: string]: any }>({
	key: 'funnel-compare-first-state',
	default: {},
});

export const ChartCompareSecondState = atom<{ [key: string]: any }>({
	key: 'funnel-compare-second-state',
	default: {},
});

export const FlowAverageTimeState = atom<{ [key: string]: any }>({
	key: 'flow-average-time-state',
	default: {},
});

export const CompareAverageTimeState = atom<{ [key: string]: any }>({
	key: 'compare-average-time-state',
	default: {},
});

export const comparedFunnelChartsState = atom<boolean>({
	key: 'compared-Funnel-Charts-State',
	default: false,
});

export const NodeTitleFunnelChartState = atom<string>({
	key: 'node-title-funnel-chart-state',
	default: '',
});

export const NodeTitleCompareFunnelChartState = atom<string>({
	key: 'node-title-compare-funnel-chart-state',
	default: '',
});
