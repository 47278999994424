import { Button, ReactResponsiveTable } from '@storybook';
import { useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { loginState } from 'global-stores';
import { ESIGN_PURPOSE } from 'views/pipelines/components';
import { PERMISSION_SCREEN, useUserRoles } from 'views/routes-children';
import {
	InboxActions,
	InboxLastChangeDate,
	InboxStatus,
	InboxSubject,
	SignDocInboxFilter,
} from '.';
import {
	EnvelopeLinkUnlinkPayload,
	IInboxAction,
	ISignedDocInbox,
	SignDocInboxHeader,
	SignDocInboxSelector,
	SignedDocInboxState,
} from '../store';
import { InboxAuth } from './inbox-auth';
import { SessionLinkTableCol } from 'views/sessions';

const workFlow: any = {
	multiSigner: 'Multi Signer',
	onboarding: 'Onboarding',
	selfSign: 'Self Sign',
};

interface ISignedDocInboxTable {
	screen?: string
	linkedEnvelopes?: string[]
}

export const SignedDocInboxTable = ({screen, linkedEnvelopes}: ISignedDocInboxTable) => {
	const { data } = useRecoilValue(SignDocInboxSelector);
	const { isLoaded } = useRecoilValue(SignedDocInboxState);
	const userData = useRecoilValue(loginState);
	const [envelopeLinkUnlinkPayload, setEnvelopeLinkUnlink] = useRecoilState(EnvelopeLinkUnlinkPayload);

	const { checkUserWritePermission } = useUserRoles();

	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.Sent),
		[checkUserWritePermission]
	);

	const completedRecipientsCount = useCallback(
		(inbox: { recipients: any[] }) => {
			let count = 0;
			inbox.recipients.forEach((recipient: any) => {
				if (recipient.status === 'completed') {
					count += 1;
				}
			});

			const recipient = inbox.recipients.find(
				({ email }: any) => email === userData.email
			);
			return { count, recipient };
		},
		[userData?.email]
	);

	const getAction = useCallback(
		(inbox: ISignedDocInbox): IInboxAction => {
			const shouldShowDownloadBtn =
				inbox.status === 'completed' ||
				completedRecipientsCount(inbox).count === inbox.recipients.length;
			const allRecipientsCompleted = inbox.recipients.every((recipient) => recipient.status === "completed");

			if (inbox.status === 'voided') {
				return 'voided';
			}
			if (inbox.status === "configured" && allRecipientsCompleted ){
				return "processing";
			}
			if (shouldShowDownloadBtn) {
				return 'completed';
			}
			if (!inbox.signOrder) {
				if (completedRecipientsCount(inbox)?.recipient?.status === 'pending' && inbox.purpose !== ESIGN_PURPOSE.SIGNAGREEMENT && inbox.purpose !== ESIGN_PURPOSE.MULTISIGNAGREEMENT) {
					return 'needToSign';
				}
				return 'waitingForOthers';
			}
			const nextUser = inbox.recipients.find(
				({ status }: any) => status !== 'completed'
			);
			if (
				nextUser &&
				nextUser.email.toLowerCase() === userData.email?.toLowerCase() && 
				inbox.purpose !== ESIGN_PURPOSE.SIGNAGREEMENT && 
				inbox.purpose !== ESIGN_PURPOSE.MULTISIGNAGREEMENT
			) {
				return 'needToSign';
			}
			return 'waitingForOthers';
		},
		[completedRecipientsCount, userData.email]
	);

	const handleLinkUnlinkEnvelope = useCallback((inbox: ISignedDocInbox, key: 'unlinkEnvelopes' | 'linkEnvelopes' ) =>{
		setEnvelopeLinkUnlink((prev) =>  {
			if (prev['linkEnvelopes'].indexOf(inbox._id) >= 0 || prev['unlinkEnvelopes'].indexOf(inbox._id) >= 0) {
				return { 
					linkEnvelopes: prev['linkEnvelopes'].filter((x: any) => x !== inbox._id),
					unlinkEnvelopes: prev['unlinkEnvelopes'].filter((x: any) => x !== inbox._id),
				};
			} else {
				return {
					...prev, 
					[key]: [...prev[key], inbox._id]
				};
			}
		})
	},[setEnvelopeLinkUnlink]);

	const renderRows = useMemo(() => {
		const rows: any[] = [];
		data.forEach((inbox: ISignedDocInbox) => {
			if (inbox) {
				let row: any = {};
				const recipients: any = [];
				let inboxType: 'multiSigner' | 'selfSign' | 'onboarding' | '' = '';
				const actionStatus = getAction(inbox);

				switch (inbox.purpose) {
					case 'workflow':
						inboxType = 'multiSigner';
						break;
					case 'selfsign':
						inboxType = 'selfSign';
						break;
					default:
						inboxType = 'onboarding';
						break;
				}

				inbox.recipients.forEach(({ name, fullName, email }) =>
					recipients.push(`${name ?? fullName} , ${email}`)
				);
				SignDocInboxHeader.forEach(({ key, format }: any) => {
					if (format === 'jsx' && key === 'name') {
						const value = () => <InboxSubject inbox={inbox} />;
						row = {
							...row,
							[key]: value,
						};
					}
					if (format === 'jsx' && key === 'status') {
						const value = () => (
							<InboxStatus inbox={inbox} action={actionStatus} />
						);
						row = {
							...row,
							[key]: value,
						};
					}
					if (format === 'jsx' && key === 'updatedAtDate') {
						const value = () => <InboxLastChangeDate inbox={inbox} />;
						row = {
							...row,
							[key]: value,
						};
					}
					if(format === "jsx" && key === "authType") {
						const value = () => <InboxAuth type={inbox.authType}/>
						row = {
							...row,
							[key] : value,
						}
					}
					if (format === 'jsx' && key === 'origin') {
						let type = 'basic';
						if (
							inbox.purpose === ESIGN_PURPOSE.SIGNAGREEMENT ||
							inbox.purpose === ESIGN_PURPOSE.ACCREDITATION ||
							inbox.purpose === ESIGN_PURPOSE.MULTISIGNAGREEMENT
						) {
							type = 'onboarding';
						} else {
							type = inbox.origin ?? 'basic';
						}
						const value = () => {
							return (
								<div className={`sign-doc-capsule sign-doc-capsule--${type}`}>
									{type}
								</div>
							);
						};
						row = {
							...row,
							[key]: value,
						};
					}
					if (format === 'jsx' && key === 'actions') {
						let value;
						if(screen === 'link-esign'){
							value = () => {
								const islinked = linkedEnvelopes?.includes(inbox._id) ? 
									!envelopeLinkUnlinkPayload.unlinkEnvelopes.includes(inbox._id) :
									envelopeLinkUnlinkPayload.linkEnvelopes.includes(inbox._id);
								return (
									<>
										<Button
											handleClick={() =>
												handleLinkUnlinkEnvelope(
													inbox,
													islinked ? 'unlinkEnvelopes' : 'linkEnvelopes'
												)
											}
											label={islinked ? 'Unlink' : 'Link'}
											type={
												islinked
													? 'button__filled--esign-unlink'
													: 'button__filled--esign-link'
											}
										/>
									</>
								);
							};
						}else{
							value = () => (
								<InboxActions
									inbox={inbox}
									action={actionStatus}
									inboxType={inboxType}
									type="sentBox"
									isUserPermissionWrite={isUserPermissionWrite}
								/>
							);
						}
						row = {
							...row,
							[key]: value,
						};
					}
				});
				row.createdAt = inbox.createdAt;
				row.updatedAt = inbox.createdAt;
				row.documentName = `${inbox.name ?? ''}`;
				row.recipient = recipients.join(', ');
				row.status1 = inbox.status;
				row.inboxType = inboxType;
				row.workFlow = workFlow[inboxType];
				row.actionStatus = actionStatus;
				rows.push(row);
			}
		});
		return rows;
	}, [data, envelopeLinkUnlinkPayload.linkEnvelopes, envelopeLinkUnlinkPayload.unlinkEnvelopes, getAction, handleLinkUnlinkEnvelope, isUserPermissionWrite, linkedEnvelopes, screen]);

	const isLoading = useMemo(() => {
		return !isLoaded;
	}, [isLoaded]);
	return (
		<ReactResponsiveTable
			isLoading={isLoading}
			column={screen === 'link-esign' ? SessionLinkTableCol : SignDocInboxHeader }
			rows={renderRows}
			filterPage="signDocInbox"
			showSearch={true}
			customFilter={<SignDocInboxFilter />}
			showDateFilter={true}
			EmptyIllustration="empty-aggrement-docs.svg"
			EmptyMessageHeading="No Signed Document Available"
			EmptyMessageDescription="You have not created signed document yet."
		/>
	);
};
