import classNames from 'classnames';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ReactSwitch } from '@storybook';
import { AllowPermissionLabel } from 'views/user-roles/constant';
import {
	AddNewRoleFormState,
	SelectedUserRoleState,
} from 'views/user-roles/store';
import { PermissionTable } from '../permission-table';
import { ALL_ACCESS, NO_ACCESS } from './constant';

interface IAllowPermission {
	isDisable?: boolean;
}

export const AllowPermission: FC<IAllowPermission> = ({
	isDisable = false,
}) => {
	const [addRoleForm, setAddRoleForm] = useRecoilState(AddNewRoleFormState);
	const selectedUserRole = useRecoilValue(SelectedUserRoleState);
	const { fullAccess, services } = addRoleForm;

	useEffect(() => {
		if (selectedUserRole) {
			const { fullAccess } = selectedUserRole;

			if (fullAccess) {
				setAddRoleForm(prev => ({
					...prev,
					permissions: {
						...prev.permissions,
						embedWeb: {
							read: true,
							write: true,
						},
					},
					fullAccess: fullAccess,
				}));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleToggleChange = (checked: boolean) => {
		setAddRoleForm(prev => ({
			...prev,
			permissions: checked ? ALL_ACCESS : NO_ACCESS,
			fullAccess: checked,
		}));
		if (!checked) {
			setAddRoleForm(prev => ({
				...prev,
				permissions: {
					...prev.permissions,
					dashboard: {
						read: prev.permissions.dashboard.read,
						write: false,
					},
					embedWeb: {
						read: false,
						write: false,
					},
					apiDocs: {
						read: prev.permissions.apiDocs.read,
						write: false,
					},
				},
			}));
		}
	};

	const toggleActive = useCallback((isAllowedAccess: boolean) => {
		return classNames('AddNewRoleModal--container__permission--access', {
			'toggle-border': isAllowedAccess,
		});
	}, []);

	const isDisabledToggle = useMemo(() => {
		const isOnboardingSelected = addRoleForm?.services?.includes('onboarding');
		if (isOnboardingSelected) {
			return false;
		} else {
			setAddRoleForm(prev => ({ ...prev, fullAccess: false }));
			return true;
		}

		return isOnboardingSelected;
	}, [addRoleForm?.services, setAddRoleForm]);

	const renderPermissionHeader = useMemo(() => {
		return (
			<div className="AddNewRoleModal--container__permission--header">
				<div className="AddNewRoleModal--container__permission--header__title">
					{AllowPermissionLabel.Header}{' '}
					<span className="input__isRequired"> *</span>
				</div>
				{!isDisable && (
					<div className="AddNewRoleModal--container__permission--header__subTitle">
						{AllowPermissionLabel.Description}
					</div>
				)}
			</div>
		);
	}, [isDisable]);

	const renderPermissionCard = useMemo(() => {
		return (
			<div
				className={toggleActive(
					isDisable ? selectedUserRole?.fullAccess ?? false : fullAccess
				)}
			>
				<div className="All-access--container">
					<div className="All-access--container__header">
						<div className="All-access--container__header--title">
							{AllowPermissionLabel.Card.Header}
						</div>
						<div className="All-access--container__header--subTitle">
							{AllowPermissionLabel.Card.Description}
						</div>
					</div>
					<div className="AddNewRoleModal--container__permission--access__toggle">
						{isDisable ? (
							<i className="ri-check-line permission-checked_full-access" />
						) : (
							<ReactSwitch
								id="allow-access-toggle"
								checked={
									isDisable ? selectedUserRole?.fullAccess ?? false : fullAccess
								}
								handleChange={handleToggleChange}
								isDisabled={isDisable || isDisabledToggle}
							/>
						)}
					</div>
				</div>
			</div>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fullAccess, isDisable, selectedUserRole?.fullAccess, isDisabledToggle]);

	return (
		<div className="AddNewRoleModal--container__permission">
			{renderPermissionHeader}

			{services.includes('onboarding') && (
				<>
					{isDisable ? (
						selectedUserRole?.fullAccess ? (
							renderPermissionCard
						) : (
							<></>
						)
					) : (
						renderPermissionCard
					)}
				</>
			)}

			<PermissionTable
				isAllowedAccess={fullAccess || isDisable}
				isEditable={isDisable}
			/>
		</div>
	);
};
