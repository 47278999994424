import { useMemo } from 'react';
import { Skeleton } from '@storybook';
import { useRecoilValue } from 'recoil';
import { kycSettingsState, whiteLableLogoState } from 'global-stores';

import { LOGO } from 'constant';

export const UploadDocumentNavbar = () => {
	const settingsKyc = useRecoilValue(kycSettingsState);
	const loaded = useRecoilValue(whiteLableLogoState);

	const renderLoading = useMemo(
		() => (
			<div className="navbar1">
				<Skeleton width={100} height={40} borderRadius={8} />
			</div>
		),
		[]
	);
	const whiteLableLogo = useMemo(() => {
		if (!settingsKyc.whitelabel)
			return LOGO.LIGHT;
		else
			return settingsKyc?.logo ?? LOGO.LIGHT;
	}, [settingsKyc]);

	if (loaded) {
		return renderLoading;
	}

	return (
		<div className="navbar1">
			<img loading="lazy" src={whiteLableLogo} className="navbar1__img" />
		</div>
	);
};
