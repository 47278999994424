import { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';

import {
	Button,
	Image,
	Loader,
	NoDataAvailable,
	ReactResponsiveTable,
} from '@storybook';
import { EditModal, UserRolesState } from 'global-stores';

import { Header } from 'views/header';

import { DropOptions } from 'views/pipelines/components';
import { ArchiveConfirmationModal } from 'views/sessions';
import {
	AddNewRoleModal,
	InviteModalPage,
	InviteNavigation,
	InviteNavigationState,
} from 'views/user-roles';
import { CreateSubAccountModal, SubUserInviteModal } from './components';
import {
	HEADER,
	ISubAccountKey,
	ISubAccountListState,
	SUBACCOUNTSTATUS,
	useSubAccounts,
} from './hooks';
import { SUBACCOUNT_DEFINATION, SUBACCOUNT_QUESTION } from './constants';

import { SubscriptionRequiredModal } from 'components';
import './sub-account.scss';
import { Tooltip } from 'react-tooltip';
import { PERMISSION_SCREEN, useUserRoles } from 'views';

export const SubAccount = () => {
	const [isExpand, setIsExpand] = useState(false);
	const {
		subAccountRows,
		handleCreateBtn,
		handleUser,
		isOpenModal,
		setIsOpenModal,
		isEditAccount,
		editAccount,
		isOpenUserModal,
		handleCloseViewModal,
		isSubUserLoading,
		subUsersList,
		isOpenDeleteModal,
		subAccountLoading,
		handleDeleteSubAcc,
		inviteModalOpen,
		handleClose,
		onSaveRole,
		isApiLoading,
		isDisabled,
		onInviteRole,
		handleOpenDelete,
		handleOpenView,
		onInviteSubAccountUser,
		isAddRoleModalOpen,
		handleCloseModal,
		mapToNavigationPreviousLabel,
		mapToNavigationNextLabel,
		handleNext,
		handleBack,
		onRemoveInvitedUser,
		disableSubAccount,
		handleEditSubAccountUser,
		isSubscriptionExist,
		closeSubscriptionModal,
		handleNavigate,
		openDeleteInvitedUser,
		invitedUserToDelete,
		handleOpenDeleteInvitedUser,
	} = useSubAccounts();

	const inviteNavigation = useRecoilValue(InviteNavigationState);
	const userRoles = useRecoilValue(UserRolesState);
	const editModal = useRecoilValue(EditModal);

	const { checkUserWritePermission } = useUserRoles();

	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.SubAccount),
		[checkUserWritePermission]
	);

	const HeaderColumns = useMemo(() => {
		if (!isUserPermissionWrite) {
			return HEADER.SUB_ACCOUNT.filter(
				headerItem => headerItem.key !== 'action'
			);
		}
		return HEADER.SUB_ACCOUNT;
	}, [isUserPermissionWrite]);

	const usersRows = useMemo((): ISubAccountListState[] => {
		const rows: ISubAccountListState[] = [];
		if (HeaderColumns.length) {
			subAccountRows.forEach((item: ISubAccountListState) => {
				if (item) {
					let row: any = {};
					HeaderColumns.forEach(({ format, key, key1 }) => {
						if (format === 'jsx' && key === 'totalUsers') {
							const value = () => (
								<div>
									{item[key as ISubAccountKey] ?? 0}{' '}
									<span className="Sub-Account_view">View</span>
								</div>
							);
							return (row[key] = value);
						}
						if (format === 'jsx' && key === 'action') {
							const value = () => (
								<div className="Sub-Account--actions">
									{item.approveStatus === 'APPROVED' ? (
										<div
											className={`Sub-Account_action`}
											onClick={e => onInviteRole(e, item)}
										>
											Invite user
										</div>
									) : (
										//Access approval for primary user is pending from super admin
										<>
											<Tooltip
												place="bottom"
												anchorId={`account_id_${item._id}`}
												html={`Access approval for primary<br />user is ${SUBACCOUNTSTATUS[
													item.approveStatus
												].toLowerCase()}.`}
												events={['hover']}
												className="invite-user-tooltip"
												classNameArrow="invite-user-tooltip-arrow"
											/>
											<div
												id={`account_id_${item._id}`}
												className={`Sub-Account_action disabled`}
												onClick={e => e.stopPropagation()}
											>
												Invite user
											</div>
										</>
									)}
									<DropOptions
										handleView={() => handleOpenView(item)}
										handleDelete={() => handleOpenDelete(item)}
										isDeleteDisabled={true}
									/>
								</div>
							);
							return (row[key] = value);
						}
						if (format === 'jsx' && key1 === 'approveStatus') {
							const value = () => (
								<span className={`Sub-Account--status ${item.approveStatus}`}>
									{SUBACCOUNTSTATUS[item.approveStatus]}
								</span>
							);
							return (row[key] = value);
						}
						row = {
							...row,
							_id: item?._id,
							[key]: item[key as ISubAccountKey],
						};
						return null;
					});
					row.status1 = item.approveStatus;
					rows.push(row);
				}
			});
		}
		return rows;
	}, [
		handleOpenDelete,
		handleOpenView,
		onInviteRole,
		subAccountRows,
		HeaderColumns,
	]);

	const inviteButtonLabel = useMemo(() => {
		switch (inviteNavigation) {
			case InviteNavigation.InviteForm:
				return 'Review';
			case InviteNavigation.InvitePreview:
				return editModal ? 'Update Invite' : 'Send Invite';
			default:
				return '';
		}
	}, [editModal, inviteNavigation]);

	const inviteBackLabel = useMemo(() => {
		switch (inviteNavigation) {
			case InviteNavigation.InviteForm:
				return 'Cancel';
			case InviteNavigation.InvitePreview:
				return 'Back';
			default:
				return '';
		}
	}, [inviteNavigation]);

	return (
		<div className="sub-account-body ">
			<Header
				title="Sub Accounts"
				showBtn={subAccountRows.length > 0}
				btnLabel="Create New"
				handleClickBtn={() => handleCreateBtn()}
			/>
			{!subAccountRows.length && !subAccountLoading ? (
				<NoDataAvailable
					label="Create New"
					illustration="add-group.svg"
					message="No sub-account added"
					description={
						<div className="empty-subaccount-desc-wrapper">
							<div>You have not created any sub-account yet.</div>
							<div className="empty-subaccount-desc-wrapper__note_wrapper">
								{
									<div
										onClick={() => setIsExpand(!isExpand)}
										className="empty-subaccount-desc-wrapper__note_wrapper__label"
									>
										{SUBACCOUNT_QUESTION}
									</div>
								}
								{isExpand && (
									<div className="empty-subaccount-desc-wrapper__note_wrapper__value">
										{SUBACCOUNT_DEFINATION}{' '}
									</div>
								)}
							</div>
						</div>
					}
					handleClickBtn={() => handleCreateBtn()}
				/>
			) : (
				<div className="Sub-Account">
					<ReactResponsiveTable
						showSearch
						column={HeaderColumns}
						rows={usersRows}
						handelRowClick={user => handleUser(user)}
						isLoading={subAccountLoading}
						hideSortKey={['totalUsers']}
						columnHandle
					/>
				</div>
			)}
			{isOpenModal && (
				<CreateSubAccountModal
					isOpen={isOpenModal}
					handleCloseModal={() => setIsOpenModal(false)}
					isEditable={isEditAccount}
					subAccDetail={editAccount}
				/>
			)}
			{isOpenUserModal && (
				<SubUserInviteModal
					isOpen={isOpenUserModal}
					handleCloseModal={() => handleCloseViewModal()}
					isSubUserLoading={isSubUserLoading}
					subUsersList={subUsersList}
					onInviteSubAccountUser={onInviteSubAccountUser}
					onRemoveInvitedUser={onRemoveInvitedUser}
					handleEditSubAccountUser={handleEditSubAccountUser}
					openDeleteInvitedUser={openDeleteInvitedUser}
					invitedUserToDelete={invitedUserToDelete}
					handleOpenDeleteInvitedUser={handleOpenDeleteInvitedUser}
					loading={subAccountLoading}
				/>
			)}

			{isOpenDeleteModal && (
				<ArchiveConfirmationModal
					description={`Deleting ${editAccount?.name} sub-account will delete all its users.`}
					boldDescription={` Do you want to delete ${editAccount?.name} sub-account from the system?`}
					label="Delete"
					title="Are you sure?"
					visible={isOpenDeleteModal}
					showLoader={subAccountLoading}
					handleModal={handleDeleteSubAcc}
				/>
			)}
			{(userRoles ?? [])?.length > 0 ? (
				<InviteModalPage
					isOpen={inviteModalOpen}
					handleClose={handleClose}
					disableSubAccount={disableSubAccount}
					edit={editModal}
				>
					<div className="ConfigureModal--footer">
						<Button
							label={inviteBackLabel}
							handleClick={handleClose}
							type="button__filled button__filled--secondary"
						/>

						<Button
							label={inviteButtonLabel}
							handleClick={onSaveRole}
							type="button__filled button__filled--primary"
							disabled={isDisabled || isApiLoading}
							loader={
								isApiLoading ? <Loader dimension={14} type="loader" /> : <></>
							}
						/>
					</div>
				</InviteModalPage>
			) : (
				<AddNewRoleModal
					isAddRoleModalOpen={isAddRoleModalOpen}
					handleCloseModel={handleCloseModal}
				>
					<div className="AddNewRoleModal--container__footer">
						<Button
							label={mapToNavigationPreviousLabel}
							type="button__filled--secondary"
							handleClick={handleBack}
						/>
						<Button
							label={mapToNavigationNextLabel}
							type="button__filled--primary"
							handleClick={handleNext}
							disabled={isDisabled || isApiLoading}
							loader={
								isApiLoading ? <Loader dimension={14} type="loader" /> : <></>
							}
						/>
					</div>
				</AddNewRoleModal>
			)}

			{isSubscriptionExist && (
				<SubscriptionRequiredModal
					isModalOpen={isSubscriptionExist}
					closeModal={closeSubscriptionModal}
					handleNavigate={handleNavigate}
				>
					<div className="subscription-required-modal--body">
						<Image fileName="SubscribedIcon.svg" />
						<h2>Upgrade account type</h2>
						<p>
							To access this feature, please upgrade your account type to
							“Entity/Business”. Upgrading will unlock additional
							functionalities and benefits for your account.
						</p>
						<Button
							label="Upgrade now"
							handleClick={handleNavigate}
							type="button__filled button__filled--primary button__large button-ok"
						/>
					</div>
				</SubscriptionRequiredModal>
			)}
		</div>
	);
};

export default SubAccount;
