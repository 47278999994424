import { FC, useEffect, useState } from 'react';

import { useDonutChart } from '../hooks';
import { IPieDonutChart } from '../states';



export const DonutPieChart: FC<IPieDonutChart> = ({
	isLoading,
	title,
	seriesData,
	centerTitle = 'All Actions',
	innerSize = '75%',
	centerValue = 100,
	chartWidth = 240,
	percentColor = '#000000',
	isSession = false,
}) => {
	const { getDonutConfig } = useDonutChart();

	const [value, setValue] = useState<number>(centerValue);
	const [seriesName, setSeriesName] = useState<string>(centerTitle);

	useEffect(() => {
		getDonutConfig({
			isLoading,
			title,
			value,
			seriesName,
			seriesData,
			innerSize,
			setSeriesName,
			setValue,
			chartWidth,
			percentColor,
			isSession,
		});
	}, [
		seriesData,
		getDonutConfig,
		innerSize,
		isLoading,
		seriesName,
		title,
		value,
		chartWidth,
		percentColor,
		isSession,
	]);

	return <div id={title} />;
};
