import { Button, Loader } from '@storybook';
import { useNotification } from 'hooks';
import { useCallback, useMemo, useState } from 'react';
import { MFA_MESSAGES } from '../constant';

interface BackupCode {
	_id: string;
	referenceId: string;
	clientId: string;
	backupCode: string;
	status: string;
	__v: number;
	createdAt: string;
	updatedAt: string;
}
interface IBackupCodes {
	back_up_codes: BackupCode[];
}
export const BackUpCodes: React.FC<IBackupCodes> = ({ back_up_codes }) => {
	const { successNotification } = useNotification();
	const [downloadButtonLoader, setDownloadButtonLoader] = useState(false);

	const handleButtonClick = useCallback(() => {
		setDownloadButtonLoader(true);
		// Extract backupCode values
		const backupCodes = back_up_codes?.map(code => code.backupCode);

		// Join backupCodes with newline characters
		const backupCodesString = backupCodes.join('\n');

		// Add a heading with a space before the backup codes
		const data = `Simplici backup verification codes\n\n${backupCodesString}`;

		// Create a Blob from the data
		const blob = new Blob([data], { type: 'text/plain' });

		// Create a URL for the Blob
		const url = URL.createObjectURL(blob);

		// Create a temporary link to trigger the download
		const link = document.createElement('a');
		link.href = url;
		link.download = 'backup-codes.txt'; // The file name for the download
		document.body.appendChild(link);

		// Trigger the download
		link.click();

		// Clean up and remove the link
		URL.revokeObjectURL(url);
		document.body.removeChild(link);
		successNotification(MFA_MESSAGES.DOWNLOAD_MESSAGE);
		setDownloadButtonLoader(false);
	}, [back_up_codes, successNotification]);

	const handlePrintClick = useCallback(() => {
		// Create a print stylesheet that hides the toast notification
		const style = document.createElement('style');
		style.textContent = `
  @media print {
    .Toastify__toast-container {
      display: none !important;
    }
  }
`;
		document.head.appendChild(style);

		// Print the page
		window.print();

		// Remove the print stylesheet after printing
		document.head.removeChild(style);
	}, []);

	const renderLabelButton = useMemo(() => {
		if (downloadButtonLoader) {
			return <Loader />;
		} else return 'Download';
	}, [downloadButtonLoader]);

	return (
		<div>
			<div className="back-up__wrapper">
				{back_up_codes?.map(code => (
					<div key={code?._id} className="mfa__backup-genrated-code">
						{code?.backupCode}
					</div>
				))}
			</div>
			<div className="back-up__note-wrapper">
				<span className="back-up__note-bold">Note:</span> Backup verification
				codes can only be used once. After being used, they will no longer be
				valid.
			</div>
			<div className="back-up__footer">
				<Button
					prefixIcon="ri-printer-line"
					label="Print"
					type="button__filled button__filled--secondary button-cancel"
					handleClick={handlePrintClick}
				/>
				<Button
					prefixIcon="ri-file-download-fill"
					label={renderLabelButton}
					type="button__filled button__filled--primary"
					handleClick={handleButtonClick}
				/>
			</div>
		</div>
	);
};
