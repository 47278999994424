import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/lib/style.css';
import './mobile-input.scss';

interface Props {
	label: string;
	// eslint-disable-next-line @typescript-eslint/ban-types
	handleChange: Function;
	placeholder: string;
	country?: string;
	enableSearch?: boolean;
	isRequired?: boolean;
	value: string;
	errorMessage?: string;
	isError?: boolean;
	autofocus?: boolean;
	isDisabled?: boolean;
}

/**
 * MobileInput component
 *
 * @param {string} Props.label - The label for the mobile input
 * @param {(e: any) => void} Props.handleChange - Callback function triggered when the mobile input value changes
 * @param {string} Props.placeholder - The placeholder text for the mobile input
 * @param {string} Props.country - The default country for the mobile input
 * @param {boolean} Props.enableSearch - Flag to enable country search in the mobile input
 * @param {boolean} Props.isRequired - Flag to indicate if the mobile input is required
 * @param {string} Props.value - The value of the mobile input
 * @param {boolean} Props.autofocus - Flag to autofocus on the mobile input
 * @param {boolean} Props.isError - Flag to indicate if there is an error in the mobile input
 * @param {string} Props.errorMessage - The error message to display when isError is true
 * @param {boolean} Props.isDisabled - Flag to disable the mobile input
 */

export const MobileInput = ({
	label,
	handleChange,
	country = 'us',
	placeholder,
	enableSearch = false,
	isRequired = false,
	value,
	autofocus = false,
	isError = false,
	errorMessage,
	isDisabled = false,
}: Props) => {
	const [isInputFocus, setIsInputFocus] = useState(autofocus);

	useEffect(() => {
		setIsInputFocus(autofocus);
	}, [autofocus]);

	const handleOnChange = (phone: string, country: any) => {
		const reducedPhone = phone.replace(country.dialCode, '');
		const e: any = {};
		const target = {
			value: reducedPhone,
			countryCode: '+' + country.dialCode,
		};
		e['target'] = target;
		handleChange(e);
	};

	const props = {
		country,
		placeholder,
		onChange: handleOnChange,
		enableSearch,
		value,
		excludeCountries: ['tt'],
	};

	return (
		<div className="mobile-input__container">
			{label && (
				<label className="input__label">
					{label}
					{isRequired && <span className="input__isRequired"> *</span>}
				</label>
			)}
			<div className={`input-form ${isError ? 'error-message' : ''}`}>
				<PhoneInput
					{...props}
					disabled={isDisabled}
					inputProps={{
						name: 'phone',
						required: true,
						autoFocus: isInputFocus,
					}}
				/>
			</div>
			{isError && errorMessage && (
				<span className="input__error-message">{errorMessage}</span>
			)}
		</div>
	);
};
