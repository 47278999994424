import { API_URL } from 'constant';
import { useNetwork } from 'hooks/network';
import { useEffect, useMemo } from 'react';

type IOptionsType = {
	name: string;
};

export const useCountryStateCity = ({
	country,
	formState,
	mailingCountry,
	formMailingState,
}: {
	country: string;
	formState?: string;
	mailingCountry?: string;
	formMailingState?: string;
}) => {
	const { get: state, data: states } = useNetwork();
	const { get: city, data: cities } = useNetwork();
	const { get: mailingState, data: mailingStates } = useNetwork();
	const { get: mailingCity, data: mailingCities } = useNetwork();

	// request for states
	useEffect(() => {
		if (!country) return;
		state(`${API_URL.STATE_CITY}?country=${country}&type=state`);
	}, [state, country]);

	const stateId = useMemo(
		() =>
			Array.isArray(states?.data) &&
			states?.data?.find((item: IOptionsType) => item.name === formState)?._id,
		[formState, states]
	);

	const mailingStateId = useMemo(
		() =>
			Array.isArray(mailingStates?.data) &&
			mailingStates?.data?.find((item: IOptionsType) => item.name === formState)
				?._id,
		[formState, mailingStates]
	);

	// due to some backend side issue we need to do something like this

	// request for cities
	useEffect(() => {
		if (!formState || !stateId) return;
		if (stateId) {
			city(`${API_URL.STATE_CITY}?stateId=${stateId}&type=city`);
		}
	}, [city, formState, stateId]);

	// mailing address
	// request for mailingState
	useEffect(() => {
		if (!mailingCountry) return;
		mailingState(`${API_URL.STATE_CITY}?country=${mailingCountry}&type=state`);
	}, [mailingState, mailingCountry]);

	// request for mailingCity
	useEffect(() => {
		if (!formMailingState || !mailingStateId) return;
		mailingCity(`${API_URL.STATE_CITY}?stateId=${mailingStateId}&type=city`);
	}, [mailingCity, formMailingState, mailingStateId]);

	// dropdown options
	const StateOptions = useMemo(
		() =>
			Array.isArray(states?.data) &&
			states?.data?.map(({ name }: IOptionsType) => ({
				label: name,
				value: name,
			})),
		[states]
	);

	const MailingStatesOptions = useMemo(
		() =>
			Array.isArray(mailingStates?.data) &&
			mailingStates?.data?.map(({ name }: IOptionsType) => ({
				label: name,
				value: name,
			})),
		[mailingStates]
	);

	const CityOptions = useMemo(
		() =>
			Array.isArray(cities?.data) &&
			cities?.data?.map(({ name }: IOptionsType) => ({
				label: name,
				value: name,
			})),
		[cities]
	);

	const MailingCityOptions = useMemo(
		() =>
			Array.isArray(mailingCities?.data) &&
			mailingCities?.data?.map(({ name }: IOptionsType) => ({
				label: name,
				value: name,
			})),
		[mailingCities]
	);

	return {
		MailingCityOptions,
		MailingStatesOptions,
		CityOptions,
		StateOptions,
	};
};
