import { useMemo } from 'react';
import { useKYBInsight } from '../../hooks';
import './announcement-date.scss';

interface IAnnouncementDate {
	KYBKey: string;
	dateKey: string;
	item: any;
}
export const AnnouncementDate = ({
	KYBKey,
	dateKey,
	item,
}: IAnnouncementDate) => {
	const { kybInsight } = useKYBInsight(item);

	const announceDate = useMemo(() => {
		return kybInsight?.[KYBKey]?.metrics?.[dateKey];
	}, [KYBKey, dateKey, kybInsight]);

	return (
		<div className="Announcement-Date">
			<div className="Announcement-Date_header">{announceDate?.name}</div>
			<div className="Announcement-Date_container">
				{(announceDate?.data ?? []).map((item: any) => {
					return (
						<div className="Announcement-Date_wrapper" key={item?.year}>
							<div className="Announcement-Date_dash" />

							<div className="Announcement-Date_calender">
								<div className="Announcement-Date_year">{item?.year}</div>
								<div className="Announcement-Date_date">
									<span>Date : </span>
									{item.value}
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};
