import { Image } from '@storybook';

import { useProcessing } from 'views/user-identity-flow/hooks';
import './processing.scss';

export const SubscribeProcessing = () => {
	const { accountTypeLabel, processing } = useProcessing();

	return (
		<div className="payNow-processing">
			<div className="payNow-processing__wrapper">
				<div className="payNow-processing__image-container">
					<Image fileName="business.svg" className="payNow-processing__image" />
				</div>
				<span className="payNow-processing__description">
					Subscribing to <span> {accountTypeLabel?.label}</span>
				</span>
				<progress
					value={processing}
					max={100}
					className="payNow-processing__loading-bar"
				/>
			</div>
			<p className="payNow-processing__alert">
				Please do not go back or close this modal until processing is done
			</p>
		</div>
	);
};
