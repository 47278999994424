import { API_URL } from 'constant';
import { useNetwork, useNotification } from 'hooks';
import { useCallback, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	AllConditionalPipelinesState,
	AllPipelinesState,
	IAction,
	PipelineMasterSteps,
	PipelineSettingsFormState,
	isChecqueFraudSelected,
} from 'global-stores';
import { NodesState } from 'views/complex-onboarding-flow';
import { AddedActionsState, CurrentStepState } from 'views/onboarding-flow';
import { ComplexSettingFormState } from 'views/pipeline/store';

export const usePipelineData = () => {
	const setPipelines = useSetRecoilState(AllPipelinesState);
	const setConditionalPipelines = useSetRecoilState(
		AllConditionalPipelinesState
	);
	const { errorNotification } = useNotification();

	const renderedObj = useRecoilValue<IAction>(CurrentStepState);

	const [nodes, setNodes] = useRecoilState(NodesState);
	const setAddedActions = useSetRecoilState(AddedActionsState);
	const setIsChequeFraudSelected = useSetRecoilState(isChecqueFraudSelected);
	const [complexSettingForm, setComplexSettingForm] = useRecoilState(
		ComplexSettingFormState
	);
	const masterSteps = useRecoilValue(PipelineMasterSteps);
	const [settingForm, setSettingsForm] = useRecoilState(
		PipelineSettingsFormState
	);

	const {
		get,
		data: pipelineList,
		error: pipelineListError,
		status: getPipelineStatus,
		loading: pipelinesLoading,
		isLoaded: pipelinesLoaded,
	} = useNetwork();

	const {
		get: getComplexList,
		data: complexFlowList,
		loading: isComplexLoading,
	} = useNetwork();

	useEffect(() => {
		if (pipelineList && pipelineList?.data?.[0]?.result && getPipelineStatus) {
			setPipelines(pipelineList.data[0].result);
			return;
		}
		if (pipelineListError) {
			errorNotification('Failed to load onboarding flows.');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pipelineList, getPipelineStatus, pipelineListError]);

	useEffect(() => {
		if (!isComplexLoading) {
			if (complexFlowList && complexFlowList?.data?.[0]?.result) {
				setConditionalPipelines(complexFlowList.data[0].result);
			}
		}
		// eslint-disable-next-line
	}, [complexFlowList]);

	const getPinelines = useCallback(() => {
		get(API_URL.PIPELINE_USERS).then(resp => {
			if (resp?.data?.[0]?.result) {
				setPipelines(resp.data[0].result);
			} else {
				errorNotification('Failed to load onboarding flows.');
			}
		});
		getComplexList(`${API_URL.COMPLEX_FLOW}?isDeleted=false`).then(resp => {
			if (resp?.data?.[0]?.result) {
				setConditionalPipelines(resp.data[0].result);
			} else {
				errorNotification('Failed to load onboarding flows.');
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/* 
	  @avinashSatschel
      Handle the selection of Cheque Fraud based on the specified 'type' and optional 'id'.
     */

	const handleChequeFraudSelection = (
		type: 'linear' | 'complex',
		id?: string
	) => {
		if (type === 'complex' && id) {
			// If the 'type' is 'complex' and 'id' is provided, update the state based on the complex setting form.
			const newObj = {};

			for (const key in complexSettingForm[id]) {
				if (key !== 'chequeFraud') {
					Object.assign(newObj, { [key]: complexSettingForm[id][key] });
				} else {
					Object.assign(newObj, { [key]: true });
				}
			}
			if (id) {
				const authId = nodes.find((el: any) => el.key === 'authentication').id;

				setComplexSettingForm((prev: any) => {
					const clonedObj = structuredClone(prev);
					const updatedObj: any = {};
					updatedObj[id] = newObj;
					updatedObj[authId] = clonedObj[authId];
					updatedObj['publishName'] = complexSettingForm.publishName ?? '';

					return updatedObj;
				});

				setNodes((prev: any) => {
					// Update 'nodes' based on the filtered 'id'.
					const newObj = structuredClone(prev);
					const nodes = [];

					for (let i = 0; i < newObj?.length; i++) {
						if (newObj[i].id === id) {
							newObj[i]['parentId'] = [authId];
							nodes.push(newObj[i]);
						} else if (newObj[i].id === authId) {
							nodes.push(newObj[i]);
						}
					}
					return nodes;
				});
				setAddedActions(prev => {
					// Update 'addedActions' by filtering out actions with the specified 'nodeId'.
					return prev.filter(
						el =>
							el.nodeId === id ||
							el._id == renderedObj._id ||
							el.key === 'authentication'
					);
				});
			}
		} else {
			// If the 'type' is not 'complex', update the state based on the main setting form.
			const newObj = structuredClone(settingForm);
			const allKycMetaKey =
				masterSteps
					?.find(el => el.key === 'kyc')
					?.actions?.find(elm => elm.key === 'allId')
					?.metadata.map(el => el.key) ?? [];

			if (allKycMetaKey?.length) {
				for (let i = 0; i < allKycMetaKey.length; i++) {
					const key = allKycMetaKey[i];
					if (key !== 'chequeFraud' && key) {
						if (newObj[key]) {
							newObj[key] = settingForm[key];
						}
					} else {
						newObj['chequeFraud'] = true;
					}
				}
			}
			setSettingsForm(newObj);

			setAddedActions(prev => {
				// Update 'addedActions' by filtering out actions with the key 'allId'.
				return prev.filter(
					el => el.key === 'allId' || el.key === 'authentication'
				);
			});
		}

		// Set 'isChequeFraudSelected' to true after handling the Cheque Fraud selection.
		setIsChequeFraudSelected(true);
	};

	return {
		getPinelines,
		pipelineList,
		pipelineListError,
		getPipelineStatus,
		pipelinesLoading,
		pipelinesLoaded,
		handleChequeFraudSelection,
	};
};
