import { useRecoilValue } from 'recoil';

import { PipelineSettingsFormState } from 'global-stores';
import { Image } from '@storybook';

export const EmailVerification = () => {
	const emailFormData = useRecoilValue(PipelineSettingsFormState);

	return (
		<div className="ppl-screen-body">
			<div className="ppl-screen-body__body">
				<div className="ppl-screen-body__body--email-title-section">
					<div className="email-title">
						{emailFormData['deliveryMethodSubject']}
					</div>
					<Image fileName="star.svg" width="100%" height="100%" />
				</div>
				<div className="ppl-screen-body__body--email-info">
					<div className="email-leftside">
						<div className="email-user-circle" />
						<div className="email-info">
							<div className="email-info-top">
								<div className="sen-name">
									{emailFormData['deliveryMethodName']}
								</div>
								<div className="date">2 days ago</div>
							</div>
							<div className="rec-name">to me </div>
						</div>
					</div>
					<div className="email-rightside">
						<Image fileName="share-icon.svg" />
						<Image fileName="three-dot.svg" />
					</div>
				</div>
				<div className="ppl-screen-body__body--email-body">
					<div className="email-greetings">
						{emailFormData['deliveryMethodHeadline']}
					</div>
					<div className="email-message">
						{emailFormData['deliveryMethodMessage']}
					</div>
					<div className="bootom-qr-code">
						<div className="bootom-qr-code__qrScan ">
							<Image fileName="qr-code.svg" width="100%" height="100%"/>
						</div>
						<div className="no-required-text">
							No app installation is required
						</div>
					</div>
					<div className="email-message-footer">
						<div className="email-text-footer">
							If you facing problem scanning the qr code, use this link {'url'}
						</div>
						{emailFormData['deliveryMethodFooter']}
						<div>Thanks</div>
						<div className="thanks-bold-text">
							{emailFormData['deliveryMethodName']} Team
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
