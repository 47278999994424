import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Input } from '@storybook';
import { MobileInput } from '@storybook/mobile-input';
import { CountryCode, LoginCredsState } from 'components';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
	SocialSignupDetails,
	SocialSignupDetailsFromParams,
} from 'global-stores';
import { useSignup } from './hooks';
import { useTrackEvents } from 'helpers';
import { AuthLayout, VerifyModal } from '../components';
import { useNotification } from 'hooks';
import {
	message,
	signupFormMessage,
	validateEmail,
	validateName,
} from 'constant';
import './social-signup-modal.scss';

interface IShowFormDataError {
	isFirstNameError: boolean;
	isLastNameError: boolean;
	isEmailError: boolean;
	isPhoneError: boolean;
	isLinkedInUrlError: boolean;
	isCompanyError: boolean;
}

export const SocialSignupModal = () => {
	// const [isOpen, setIsOpen] = useState(true);
	const [phoneNumber, setPhoneNumber] = useRecoilState(LoginCredsState);
	const [countryCode, setCountry] = useRecoilState(CountryCode);
	const [isButtonDisable, setButtonDisable] = useState<boolean>(false);
	const { create } = useSignup();
	const { trackEvents } = useTrackEvents();

	const [socialSignupDetails, setSocialSignupDetails] =
		useRecoilState(SocialSignupDetails);

	const signUpDetailsFromParams = useRecoilValue(SocialSignupDetailsFromParams);
	const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);
	const [showFormDataError, setShowFormDataError] =
		useState<IShowFormDataError>({
			isFirstNameError: false,
			isLastNameError: false,
			isEmailError: false,
			isPhoneError: false,
			isLinkedInUrlError: false,
			isCompanyError: false,
		});
	const [errorMessages, setErrorMessages] = useState({
		company: '',
	});
	const { errorNotification } = useNotification();

	const { InvalidEmailMessage } = message;
	const { firstNameMessage, lastNameMessage, emailMessage } = useMemo(
		() => signupFormMessage,
		[]
	);

	const buttonLabel = useMemo((): JSX.Element => {
		return <div className="social-signup__btn-label">Submit</div>;
	}, []);

	const getPhoneNumber = useMemo(() => {
		const isNumericOnly = /^[0-9]+$/.test(phoneNumber);
		if (isNumericOnly) {
			return countryCode + phoneNumber;
		} else {
			return '';
		}
	}, [phoneNumber, countryCode]);

	const changeInputHandle = useCallback(
		(name: string, isShowError: string, e: any) => {
			const { value } = e.target;

			if (name === 'company') {
				setErrorMessages({
					company: '',
				});
			}

			setSocialSignupDetails((pre: any) => {
				const preState = { ...pre, [name]: value };
				return preState;
			});
			setShowFormDataError(prev => {
				return { ...prev, [isShowError]: false };
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const onHandleChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement> | any) => {
			const { value, countryCode } = e.target;
			const re = /^[0-9\b]+$/;
			if (re.test(value) || value === '') {
				setSocialSignupDetails((prev: any) => {
					const prevState = { ...prev };
					prevState.countryCode = countryCode;
					prevState.phone = value;
					return prevState;
				});
				if (re.test(value) || value === '') {
					setPhoneNumber(value);
					setCountry(countryCode);
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	useEffect(() => {
		const { firstName, lastName, email } = socialSignupDetails;
		const isButtonDisable =
			!firstName || !lastName || !email.trim() || !phoneNumber;

		if (isButtonDisable) {
			return setButtonDisable(true);
		}
		setButtonDisable(false);
	}, [phoneNumber, socialSignupDetails]);

	const handleSignup = useCallback(async () => {
		const { firstName, lastName, email, company } = socialSignupDetails;

		if (company?.trim()) {
			if (company.length > 50) {
				setShowFormDataError(prev => {
					return { ...prev, isCompanyError: true };
				});
				setErrorMessages({
					company: 'Company name should not exceed 50 characters',
				});
			}
		} else {
			setShowFormDataError(prev => {
				return { ...prev, isCompanyError: true };
			});
			setErrorMessages({
				company: 'Company name is required',
			});
		}

		if (firstName) {
			if (!validateName(firstName.trim())) {
				setShowFormDataError(prev => {
					return { ...prev, isFirstNameError: true };
				});
				return errorNotification('Please enter alphabets only');
			}
			setShowFormDataError(prev => {
				return { ...prev, isFirstNameError: false };
			});
		} else {
			setShowFormDataError(prev => {
				return { ...prev, isFirstNameError: true };
			});
			return errorNotification(firstNameMessage);
		}

		if (lastName) {
			if (!validateName(lastName.trim())) {
				setShowFormDataError(prev => {
					return { ...prev, isLastNameError: true };
				});
				return errorNotification('Please enter alphabets only');
			}
			setShowFormDataError(prev => {
				return { ...prev, isLastNameError: false };
			});
		} else {
			setShowFormDataError(prev => {
				return { ...prev, isLastNameError: true };
			});
			return errorNotification(lastNameMessage);
		}

		if (email) {
			if (!validateEmail(email)) {
				setShowFormDataError(prev => {
					return { ...prev, isEmailError: true };
				});
				return errorNotification(InvalidEmailMessage);
			}
			setShowFormDataError(prev => {
				return { ...prev, isEmailError: false };
			});
		} else {
			setShowFormDataError(prev => {
				return { ...prev, isEmailError: true };
			});
			return errorNotification(emailMessage);
		}
		const status = await create(socialSignupDetails);
		if (status) {
			setIsVerifyModalOpen(true);
			trackEvents('signup', { ...socialSignupDetails });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socialSignupDetails]);

	// Deepak : Added validation for input field should be alphabets only any other character should not be entered.
	const handleKeyPress = useCallback(
		(e: any, name?: string) => {
			if (e.key === 'Enter') {
				handleSignup();
			}
			if (name && !validateName(e.key)) {
				e.preventDefault();
			}
		},
		[handleSignup]
	);

	const renderModalBody = () => {
		return (
			<div className="social-signup-modal">
				<div className="social-signup-modal-header">
					<div className="social-signup-modal-header__heading">
						Additional Details Required
					</div>
					<div className="social-signup-modal__subHeader">
						Please provide these additional details
					</div>
				</div>
				<div className="social-signup-inputs">
					<div className="social-signup-inputs__row-1">
						{signUpDetailsFromParams.firstName === '' && (
							<Input
								label="First Name"
								inputType="text"
								placeholder="First Name"
								value={socialSignupDetails.firstName}
								autoFocus={true}
								isError={showFormDataError.isFirstNameError}
								isRequired
								handleChange={e =>
									changeInputHandle('firstName', 'isFirstNameError', e)
								}
								handleKeyPress={e => handleKeyPress(e, 'firstName')}
							/>
						)}
						{signUpDetailsFromParams.lastName === '' && (
							<Input
								label="Last Name"
								inputType="text"
								placeholder="Last Name"
								isError={showFormDataError.isLastNameError}
								value={socialSignupDetails.lastName}
								isRequired
								handleChange={e =>
									changeInputHandle('lastName', 'isLastNameError', e)
								}
								handleKeyPress={e => handleKeyPress(e, 'lastName')}
							/>
						)}
					</div>
					{signUpDetailsFromParams.email === '' && (
						<div className="social-signup-inputs__row-2">
							<Input
								label="Email"
								inputType="text"
								placeholder="Email"
								value={socialSignupDetails.email}
								isError={showFormDataError.isEmailError}
								isRequired
								handleChange={e =>
									changeInputHandle('email', 'isEmailError', e)
								}
								handleKeyPress={handleKeyPress}
							/>
						</div>
					)}
					<div className="social-signup-inputs__row-2">
						<MobileInput
							label="Mobile Number"
							country="us"
							isRequired
							placeholder="+1 (xxx) xxx-xxx"
							enableSearch={true}
							autofocus={true}
							handleChange={onHandleChange}
							value={getPhoneNumber}
						/>
					</div>
					<div className="signup-row-2">
						<Input
							label="Company Name"
							inputType="text"
							placeholder="Company"
							handleChange={e =>
								changeInputHandle('company', 'isCompanyError', e)
							}
							isError={showFormDataError.isCompanyError}
							value={socialSignupDetails.company}
							handleKeyPress={handleKeyPress}
							errorMessage={errorMessages.company}
							isRequired
						/>
					</div>
					<div className="social-signup-btn-container">
						<Button
							label={buttonLabel}
							type={'button__filled--primary button__large'}
							handleClick={handleSignup}
							width={'100%'}
							height={'56px'}
							disabled={isButtonDisable}
						/>
					</div>
				</div>
			</div>
		);
	};

	const handleVerifyModal = () => {
		setIsVerifyModalOpen(false);
	};

	const renderVerifyModal = useMemo(() => {
		return (
			isVerifyModalOpen && (
				<VerifyModal
					visible={isVerifyModalOpen}
					handleModal={handleVerifyModal}
					regEmail={socialSignupDetails.email}
				/>
			)
		);
	}, [isVerifyModalOpen, socialSignupDetails.email]);

	return (
		<>
			<AuthLayout
				minHeight={400}
				width={632}
				className="social-signup-flow-wrapper"
			>
				{renderModalBody()}
				{renderVerifyModal}
			</AuthLayout>
		</>
	);
};
