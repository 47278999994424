import { useRecoilValue } from 'recoil';
import { useCallback } from 'react';

import { IsCreateSignModalOpen, useResetCreateSign } from './store';
import { CreateSign, CreateSignFooter, CreateSignHeader } from './components';
import Modal from '@storybook/new-modal/modal';

export const CreateSignModal = () => {
	const isModalOpen = useRecoilValue(IsCreateSignModalOpen);
	const { reset } = useResetCreateSign();

	const handleCloseModal = useCallback(() => {
		reset();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Modal
			className="admin-create-sign-modal"
			isOpen={isModalOpen}
			modalName=""
			showCloseBtn
			closeModal={handleCloseModal}
		>
			<CreateSignHeader />
			<CreateSign />
			<CreateSignFooter />
		</Modal>
	);
};
