import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ChangeEvent, DragEvent, FC, useCallback, useMemo, useState } from 'react';

import { API_URL, MESSAGE } from 'constant';
import { useNetwork, useNotification } from 'hooks';
import { documentTypeRegex } from 'utils';
import { IInputChange } from 'types';
import { UploadStatus } from '../upload-status';
import { UploadProofDocumentState } from 'views/upload-proof-document/stores';
import { SelectedPipelineDetailsForQRState } from 'views/pipelines/components';
import { SelectedPipelineDetails } from 'global-stores';

interface IUploadedDocs {
	selectedNodeId?: string | null
}

export const BrowseFile: FC<IUploadedDocs> = ({ selectedNodeId=null }) => {
	const setAddedFiles = useSetRecoilState(UploadProofDocumentState);
	const [uploadStatus, setUploadStatus] = useState({
		loading: false,
		loaded: false,
	});
	const [currentFileName, setCurrentFileName] = useState('');
	const [isFileUploadingCancel, setIsFileUploadingCancel] = useState(false);
	const qrPipelineDetails = useRecoilValue(SelectedPipelineDetailsForQRState);
	const pipelineDetails = useRecoilValue(SelectedPipelineDetails);

	const qrNodeId = qrPipelineDetails?.design?.nodes;
	const nodeId = pipelineDetails?.design?.nodes;
	const nodes = qrNodeId || nodeId;

	const { errorNotification, successNotification } = useNotification();

	const { formDataWithAxios: UploadProofDoc, fileUploading, stopUploadingFile } = useNetwork();

	const handleImportImage = useCallback(
		async (e: ChangeEvent<HTMLInputElement>, type?: 'drag' | 'browse') => {
			const nodeId = selectedNodeId ? selectedNodeId : nodes?.find(
				(item: any) => item?.data?.label === 'proofVerification'
			)?.id;

			setUploadStatus({ loaded: false, loading: false });
			const file: File =
				(type === 'drag'
					? (e as any)
					: (e as IInputChange).target.files?.[0]) ?? ({} as File);

			setCurrentFileName(file?.name);

			if (file.size > 10000000 && e?.target?.value) {
				e.target.value = '';
				setCurrentFileName('')
				return errorNotification('File size exceeds the maximum limit.');
			}
			if (!documentTypeRegex.test(file.type)) {
				setCurrentFileName('')
				return errorNotification('Only pdf, doc and docx files are supported.');
			}
			const payload = new FormData();
			payload.append('doc', file);
			payload.append('nodeId', nodeId);

			if (file) {
				setUploadStatus(prev => ({ ...prev, loading: true }));
				try {
					const response = await UploadProofDoc(
						`${'/url' + API_URL.UPLOAD_PROOF}`,
						payload
					);

					const apiData = response;

					if (apiData) {
						const { docId, url } = apiData.data ?? {};
						if (docId) {
							setUploadStatus({ loaded: true, loading: false });

							const payload = {
								name: file.name ?? '',
								size: file.size ?? '',
								docId: docId ?? '',
								fileUrl: url ?? '',
								node: nodeId ?? '',
							};

							setAddedFiles(prev => [payload, ...prev]);
							successNotification('File Uploaded.');
							setUploadStatus({ loaded: true, loading: false });
						}
					} else {
						if (isFileUploadingCancel) errorNotification(MESSAGE.ERROR);
						setUploadStatus({ loaded: false, loading: false });
						setIsFileUploadingCancel(false)
					}
				} catch (error) {
					setUploadStatus({ loaded: false, loading: false });
				}
				e.target.value = '';
			}
			return null;
		},

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isFileUploadingCancel]
	);

	/**
	 * @Manish here we are avoiding the drag over effect when ever we are uploading any document using the drag and upload method
	 * */
	const handleDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
	}, []);

	/**
	 * @Manish here we are writting the drag and upload function so that user can drop the file to upload the document
	 * */
	const handleDrop = useCallback(
		(e: DragEvent<HTMLDivElement>) => {
			e.preventDefault();
			e.stopPropagation();
			const files = Array.from(e.dataTransfer.files);
			handleImportImage(files?.[0] as any, 'drag');
		},
		[handleImportImage]
	);

	const isDisabled = useMemo(
		() => !uploadStatus.loaded && uploadStatus.loading,
		[uploadStatus.loaded, uploadStatus.loading]
	);

	const handleCloseButton = useCallback((isCancelFileUploading?:boolean) => {
		setCurrentFileName('');
		if (isCancelFileUploading) {
			stopUploadingFile();
			setIsFileUploadingCancel(true)
		}
	}, [stopUploadingFile]);

	return (
		<>
			<div onDragOver={handleDragOver} onDrop={handleDrop}>
				<label
					htmlFor="browse-file"
					className="browse-file__label-box browse-file__label-box-height"
				>
					<i className="ri-file-upload-line browse-file__logo" />
					<div className="browse-file__label-text">
						<span className="browse-file__light-text">
							Drag and drop files or{' '}
							<span style={{ color: 'var(--color-primary-light)' }}>
								Browse file
							</span>
						</span>
					</div>
					<div className="browse-file__description">
						Supported file format: Pdf, Doc, Docx
					</div>
					<div className="browse-file__description">
						Maximum upload file size: 10 MB.
					</div>

					<input
						multiple={false}
						accept=".pdf,.doc,.docx"
						id="browse-file"
						type="file"
						onChange={handleImportImage}
						className={`browse-file__input ${
							isDisabled && 'browse-file__input-loading'
						}`}
					/>
				</label>
			</div>
			{currentFileName && (
				<UploadStatus
					uploaded={uploadStatus.loaded}
					fileName={currentFileName}
					handleClose={handleCloseButton}
					uploadingProgress={fileUploading}
				/>
			)}
		</>
	);
};
