import { useEffect, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { ComplexSettingFormState } from 'views/pipeline';
import { Ids } from '../constant';
import {
	ComplexFlowConditionState,
	ComplexInitPipelines,
} from '../stores/states';

export const useKycActionState = (label: string, nodeId: string) => {
	const setComplexFlowCondition = useSetRecoilState(ComplexFlowConditionState);
	const complexInitPipelines = useRecoilValue(ComplexInitPipelines);
	const complexSettingForm = useRecoilValue(ComplexSettingFormState);

	const { stepVariables } = useMemo(
		() => complexInitPipelines ?? {},
		[complexInitPipelines]
	);

	const kycStepId = useMemo(() => {
		if (label === 'allId') return 'kyc';
		else return 'kyc';
	}, [label]);

	const StepVariable = useMemo(
		() =>
			stepVariables?.filter((el: { stepId: string }) => el.stepId === 'kyc'),
		[stepVariables]
	);

	function getKeysWithTrueValue(obj: Record<string, boolean>) {
		return Object.keys(obj).filter(key => obj[key] === false);
	}

	const StepIds = useMemo(() => {
		let result = [];
		if (complexSettingForm?.[nodeId]) {
			const data = complexSettingForm?.[nodeId];
			const falseKeys = getKeysWithTrueValue(data);
			const filteredData = (StepVariable ?? []).filter(
				(el: any) => !falseKeys.includes(Ids[el.labelId])
			);
			result = filteredData.map((item: { labelId: string }) => ({
				labelId: item.labelId,
			}));
		} else {
			result = (StepVariable ?? []).map((item: { labelId: string }) => ({
				labelId: item.labelId,
			}));
		}
		const ids = result.map(({ labelId }: { labelId: string }) => labelId);
		const filtered = result.filter(
			({ labelId }: { labelId: string }, index: number) =>
				!ids.includes(labelId, index + 1)
		);

		return filtered;
	}, [StepVariable, complexSettingForm, nodeId]);

	useEffect(() => {
		const stepState = StepIds.reduce(
			(current: Record<string, object>, acc: string) => {
				current[acc] = {};
				return current;
			},
			{}
		);

		setComplexFlowCondition((prev: any) => {
			const prevObj = JSON.parse(JSON.stringify(prev));
			prevObj['kyc'] = {
				...prevObj?.['kyc'],
				[nodeId]: stepState,
			};

			return prevObj;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [StepIds, nodeId]);

	return {
		StepIds,
		kycStepId,
	};
};
