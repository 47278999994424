import { FC, useCallback, useMemo } from 'react';

import { Image } from '@storybook';
import { Message } from 'components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {InviteNavigateModalState, SelectedPipelineDetails } from 'global-stores';
import { useInviteInvestor } from 'views/pipelines/components/store';
import { CaptableGrid } from '../../components';
import { CSVReader } from './csv-to-json';

import './captable.scss';
import { OnboardingQRModalNavigateState } from 'views/pipelines/components';

export const Captable: FC<any> = ({ templateUrl }) => {
	const setNavigate = useSetRecoilState(OnboardingQRModalNavigateState);
	const setInviteNavigate = useSetRecoilState(InviteNavigateModalState);
	const pipelineDetails = useRecoilValue(SelectedPipelineDetails);

	const { validateInputs } = useInviteInvestor();

	const handelDownloadTemplate = useCallback(() => {
		window.open(templateUrl, '_blank', 'noopener,noreferrer');
	}, [templateUrl]);

	const handleAddFunds = useCallback(() => {
		const finalXlsValue: any = window;
		finalXlsValue?.luckysheet?.exitEditMode();
		validateInputs();
		setNavigate('create');
		setInviteNavigate('create')
		// eslint-disable-next-line
	}, [validateInputs]);

	const getButtonComponent = useMemo(
		() => (
			<div className="action--btns">
				<div className="UploadCSVInput--create-label">
					{/* * Name/First Name/Last name, Country code, Mobile number, and Email
          are required fields */}
				</div>
				<div className="UploadCSVInput--btn">
					{!/payIn|payOut|fundInvestment/gi.test(
						pipelineDetails?.fullStatus
					) ? (
						<div className="UploadCSVInput--addBtn" onClick={handleAddFunds}>
							Assign Fund/Company
						</div>
					) : null}
					<CSVReader />
					<div
						className="UploadCSVInput--addBtn"
						onClick={handelDownloadTemplate}
					>
						Download Template
					</div>
				</div>
			</div>
		),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[pipelineDetails?.fullStatus]
	);

	const getWarning = useMemo(
		() => (
			<div className="note-wrapper">
				<div className="note-wrapper__left">
					<div>
						<i className="ri-alarm-warning-line warning-icon"></i>
						Note: Please add all the variable in separate columns that you want
						to use in agreement document.
						<ul className="captable-header-map-fields">
							<li>First Name (Required)</li>
							<li>Last Name (Required)</li>
							<li>Email (Required)</li>
							<li>Country Code (Required)</li>
							<li>Phone (Required)</li>
							{pipelineDetails?.fullStatus?.includes('payOut') && (
								<li>Amount To Pay (Required)</li>
							)}
							{pipelineDetails?.fullStatus?.includes('payIn') && (
								<li>Fund Transfer Amount (Required: if there are no funds available, please enter 0)</li>
							)}
						</ul>
					</div>
					{getButtonComponent}
				</div>
				<div>
					<Image fileName="captable-header-map.svg" />
				</div>
			</div>
		),
		[getButtonComponent, pipelineDetails?.fullStatus]
	);

	const renderIframe = useMemo(() => {
		return (
			<div id="captable">
				<CaptableGrid />
			</div>
		);
	}, []);

	return (
		<>
			<div className="captabe-header-details">
				<Message text={getWarning} varient="warning" />
			</div>

			<div className="captable-grid">{renderIframe}</div>
		</>
	);
};
