export const credentialTableHead = {
	HEADER: [
		{
			label: 'Name',
			key: 'name',
			format: 'string',
			width: '27%',
		},
		{
			label: 'Client Id',
			key: 'clientId',
			format: 'jsx',
			className: 'btn__text',
			width: '40%',
		},

		{
			label: 'Creation Date',
			key: 'createdAt',
			format: 'dateTime',
			width: '18%',
		},

		{
			label: 'Actions',
			key: 'action',
			format: 'jsx',
			className: 'credntial__btn-action',
			width: '180px',
		},
	],
};
