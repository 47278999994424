import { ChangeEvent } from 'react';

import './text-area.scss';

interface Props {
	label: string;
	handleChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
	value: string;
	placeholder: string;
	isError?: boolean;
	row?: number;
	disable?: boolean;
	id?: string;
}

/**
 * TextArea component
 *
 * @param {string} Props.label - Label for the text area
 * @param {(e: ChangeEvent<HTMLTextAreaElement>) => void} Props.handleChange - Callback function to handle text area value change
 * @param {string} Props.value - Current value of the text area
 * @param {string} Props.placeholder - Placeholder text for the text area
 * @param {boolean} Props.isError - Flag indicating whether there is an error in the text area
 * @param {number} Props.row - Number of rows for the text area
 * @param {boolean} Props.disable - Flag indicating whether the text area is disabled
 */

export const TextArea = ({
	label,
	handleChange,
	value,
	placeholder,
	isError = false,
	row = 6,
	disable = false,
	id = '',
}: Props) => {
	return (
		<div className="input">
			<div className="input__label">{label}</div>
			<div
				className={`input__text-field input__text-field${
					isError ? '--error' : ''
				}`}
			>
				<textarea
					name="some area"
					className="input__field text-area-fieldd"
					rows={row}
					value={value}
					placeholder={placeholder}
					onChange={handleChange}
					disabled={disable}
					id={id}
				/>
			</div>
		</div>
	);
};
