import { ICHECK_DETAILS_FORM } from './stores';

export const CHEQUE_FRAUD_TABLE_HEADER = [
	{
		label: 'Name',
		key: 'name',
		format: 'jsx',
		width: '18%',
		className: 'transaction_id',
	},
	{
		label: 'Email & Contact',
		key: 'email',
		key2: 'contact',
		format: 'jsx',
		width: '18%',
		className: 'fund_name',
	},
	{
		label: 'Date & Time',
		key: 'date',
		key2: 'time',
		format: 'jsx',
		width: '10%',
	},
	{
		label: 'Status & QR code',
		key: 'fullStatus',
		key2: 'instanceName',
		format: 'jsx',
		width: '14%',
	},

	{
		label: 'Amount & Status',
		key: 'amount',
		key2: 'amountStatus',
		format: 'jsx',
		width: '15%',
	},
	{
		label: 'Transaction ID',
		key: 'transaction',
		format: 'jsx',
		width: '15%',
	},
	{
		label: 'Action',
		key: 'action',
		format: 'jsx',
		width: '10%',
		className: 'email__contact',
	},
];

export const CHEQUE_FRAUD_FILE_HEADER = [
	{
		label: 'Document Name',
		key: 'name',
		format: 'string',
		width: '50%',
	},
	{
		label: 'File size',
		key: 'size',
		format: 'string',
		width: '18%',
	},
	{
		label: 'Action',
		key: 'action',
		format: 'jsx',
		width: '10%',
	},
];

export const POTENTIAL_RISK_NOTES = {
	LABEL:
		'A potential risk associated with this transaction has been identified.',
	SUB_LABEL:
		'The Email, Phone number being used has been associated with a previous fraud incident.',
};

export const PaymentStatusColor = {
	Deined: {
		background: 'rgba(211, 34, 34, 0.12)',
		color: '#D32222',
	},
	Paid: {
		background: 'rgba(56, 159, 117, 0.12)',
		color: '#389F75',
	},
	Pending: {
		background: 'rgba(232, 138, 44, 0.12)',
		color: '#E88A2C',
	},
};
export const StatusLabel = {
	proceed: 'Paid',
	deny: 'Denied',
	denied: 'Denied',
};

export const CheckFraudActivityTabs = [
	{ key: 'fraudulent-transactions', label: 'Fraudulent transactions' },
	{ key: 'all-transactions', label: 'All transactions' },
];

export const CHECK_DETAILS_FORM: ICHECK_DETAILS_FORM[] = [
	{
		name: 'routingNumber',
		placeholder: 'Routing Number',
		label: 'Routing Number',
		type: 'number',
		width: 'calc(50% - 8px)',
		regex: /^[a-zA-Z0-9_' ,.]+$/i,
		disabled: true,
	},
	{
		name: 'accountNumber',
		placeholder: 'Account Number',
		label: 'Account Number',
		type: 'number',
		width: 'calc(50% - 8px)',
		regex: /^[a-zA-Z0-9]+$/i,
		disabled: true,
	},

	{
		name: 'checkNumber',
		placeholder: 'Check Number',
		label: 'Check Number',
		type: 'text',
		width: 'calc(50% - 8px)',
		regex: /^[a-zA-Z0-9_' ,.]+$/i,
		disabled: true,
	},
	{
		name: 'amount',
		placeholder: 'Amount',
		label: 'Amount',
		type: 'number',
		width: 'calc(50% - 8px)',
		prefixIcon: 'dollar',
	},
	{
		name: 'bankBranch',
		placeholder: 'Select Branch',
		label: 'Bank Branch',
		type: 'select',
		width: '100%',
	},
	{
		name: 'or',
		placeholder: 'Select Branch',
		label: 'OR',
		type: 'or',
		width: '100%',
	},
	{
		name: 'addbank',
		placeholder: 'Select Branch',
		label: 'Add New Bank',
		type: 'button',
		width: '100%',
		className: 'add-new-bank',
	},
	{
		name: 'addNewBank',
		placeholder: 'Select Branch',
		label: 'ADD NEW BANK',
		type: 'devider',
		width: '100%',
		refrer: 'bank',
		subLabel: 'Cancel',
	},
	{
		name: 'name',
		placeholder: 'Bank Name',
		label: 'Bank',
		type: 'text',
		width: 'calc(50% - 8px)',
		refrer: 'bank',
	},
	{
		name: 'branchNumber',
		placeholder: 'Bank Branch Number',
		label: 'Bank Branch Number',
		type: 'number',
		width: 'calc(50% - 8px)',
		refrer: 'bank',
	},
	{
		name: 'streetAddress',
		placeholder: 'Street Address',
		label: 'Street Address',
		type: 'text',
		width: '100%',
		refrer: 'bank',
	},

	{
		name: 'country',
		placeholder: 'Select Country',
		label: 'Country',
		type: 'text',
		width: 'calc(50% - 8px)',
		refrer: 'bank',
		regex: /^[a-z\d\-_\s]+$/i,
	},
	{
		name: 'state',
		placeholder: 'State',
		label: 'state',
		type: 'text',
		width: 'calc(50% - 8px)',
		refrer: 'bank',
		regex: /^[a-z\d\-_\s]+$/i,
	},
	{
		name: 'city',
		placeholder: 'City',
		label: 'City',
		type: 'text',
		width: 'calc(50% - 8px)',
		refrer: 'bank',
		regex: /^[a-z\d\-_\s]+$/i,
	},
	{
		name: 'zipCode',
		placeholder: 'Zip Code',
		label: 'Zip Code',
		type: 'text',
		width: 'calc(50% - 8px)',
		refrer: 'bank',
		regex: /^[a-z\d\-_\s]+$/i,
	},
];

export const MODAL_CHECK_FRAUD_HEADER = {
	checkFraudform: {
		LABEL: 'Check details',
		SUB_LABEL:
			'Add new bank or select branch easily for seamless transactions.',
	},
	fraudReport: {
		LABEL: 'Report fraud',
		SUB_LABEL: 'Reporting this transaction will stop fund transferring process',
	},
	revertFraud: {
		LABEL: 'Revert Fraud',
		SUB_LABEL:
			'When reverting a fraud user, ensure smooth transactions ahead. Use caution when reverting user.',
	},
};

export const AmountStatusIcon: { [key: string]: any } = {
	pending: {
		icon: 'ri-time-fill',
		name: 'Pending',
	},
	denied: {
		icon: 'ri-close-circle-fill',
		name: 'Denied',
	},
	deny: {
		icon: 'ri-close-circle-fill',
		name: 'Denied',
	},
	proceed: {
		icon: 'ri-checkbox-circle-fill',
		name: 'Paid',
	},
};

export const DUMMY_CHECK_FRAUD_DATA = [
	{
		amount: '400000',
		date: '2023-12-11T11:59:31.139Z',
		name: 'aAvinash ',
		email: 'bavinash@gmail.com',
		amountStatus: 'pending',
		contact: '3545435353',
		fullStatus: { kyc: 'completed' },
		action: 'adsdada',
		paymentStatus: 'Pending',
		_id: 'saddaddadadaadadaddad',
		transactionId: 'dddd',
		success: true,
	},
	{
		amount: '400000',
		date: '2023-12-11T11:59:31.139Z',
		email: 'cavinash@gmail.com',
		contact: '3545435353',
		amountStatus: 'Denied',
		name: 'bAvinash ',
		fullStatus: { kyc: 'pending' },
		action: 'adsdada',
		paymentStatus: 'Deined',
		_id: 'saddaddadadaadadaddad',
		transactionId: 'aaa',
		success: false,
	},
	{
		amount: '400000',
		date: '2023-12-11T11:59:31.139Z',
		email: 'aavinash@gmail.com',
		contact: '3545435353',
		amountStatus: 'Paid',
		name: 'cAvinash ',
		fullStatus: { kyc: 'rejected' },
		action: 'adsdada',
		paymentStatus: 'Paid',
		_id: 'saddaddadadaadadaddad',
		transactionId: 'bbb',
		success: true,
	},
	{
		amount: '400000',
		date: '2023-12-11T11:59:31.139Z',
		email: 'davinash@gmail.com',
		contact: '3545435353',
		amountStatus: 'pending',
		name: 'dAvinash ',
		fullStatus: { kyc: 'processing' },
		action: 'adsdada',
		paymentStatus: 'Paid',
		_id: 'saddaddadadaadadaddad',
		transactionId: 'cccccc',
		success: false,
	},
];

export const CHEQUE_FRAUD_MESSAGES = {
	REPORT: {
		SUCCESS: 'Successful Submitted',
		ERROR: 'Something Went Wrong ',
	},
	TRANSACTIONS: {
		SUCCESS: 'Successful Transaction',
		ERROR: 'Something Went Wrong ',
	},
};
