import { Button, Input, Loader } from '@storybook';
import {
	ChangeEvent,
	Dispatch,
	FC,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useRecoilState } from 'recoil';
import {
	SignDashboardState,
	VALID_URL,
	useSignDashboard,
} from 'views/simplici-sign-dashboard/store';

interface IEditWebHookUrlModalForm {
	setModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const EditWebHookUrlModalForm: FC<IEditWebHookUrlModalForm> = ({
	setModalOpen,
}) => {
	const [url, setUrl] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [error, setIsError] = useState(false);
	const { addWebhookUrl } = useSignDashboard();
	const [{ data }, setSignDashboard ]= useRecoilState(SignDashboardState);

	const webhookUrl = data.webhookConfig.url;

	useEffect(() => {
		if (webhookUrl.trim()) {
			setUrl(webhookUrl);
		}
	}, [webhookUrl]);

	const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		const validUrl = VALID_URL.test(value);
		setIsError(!validUrl);
		setUrl(value.trim());
	}, []);

	const handleSubmit = useCallback(async () => {
		setIsLoading(true);
		const payload = { webhookConfig: { url } };
		const status = await addWebhookUrl(payload);
		if (status) {
			setSignDashboard((prev) => ({...prev, data: {...data, webhookConfig: {url: url}}}))
			setModalOpen(false);

		}
		setIsLoading(false)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addWebhookUrl, data, url]);

	const submitButtonLabel = useMemo(() => {
		if(isLoading){
			return <Loader type="loader" dimension={18} />
		}
		return "Add"
	},[isLoading])

	const disableSubmitButton = useMemo(() => {
		return url.trim()?.length <= 1 || error
	},[error, url])

	return (
		<div className="title-modal">
			<div className="title-modal__wrapper">
				<div className="title-modal__container">
					<div className="title-modal__head">Webhook URL</div>
					<div className="title-modal__desc">
						Please add your webhook URL here.
					</div>
				</div>
				<div className="title-modal__input-field">
					<Input
						label=""
						inputType="text"
						placeholder="Enter webhook url"
						value={url as string}
						handleChange={handleChange}
						autoFocus={true}
						errorMessage={"Please enter a valid url"}
						isError={error}
					/>
					<div className="title-modal__desc">
						By adding your webhook URL, you will receive event updates.
					</div>
				</div>

				<div className="title-modal__footer ">
					<Button
						label={submitButtonLabel}
						type="button__filled--primary button__large"
						handleClick={handleSubmit}
						disabled={disableSubmitButton}					
					/>
				</div>
			</div>
		</div>
	);
};
