import { atom } from 'recoil';

export const SelectedTabState = atom<string>({
	key: 'selected-tab-state',
	default: '',
});

export const ActiveTabsIntegrateState = atom<'iframe' | 'webcomponent'>({
	key: 'active-tab',
	default: 'iframe',
});

export const WebTokenState = atom<string>({
	key: 'web-token-state',
	default: '',
});

export const SideDrawerState = atom<boolean>({
	key: 'integrate-side-drawer-state',
	default: false,
});

export const SelectedDeveloperKeyState = atom<{
	label: string;
	value: string;
} | null>({
	key: 'selected-developer-key-state',
	default: null,
});
