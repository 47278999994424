import { filteringTagsState } from 'global-stores';
import {
	Dispatch,
	FC,
	SetStateAction,
	useCallback,
	useMemo,
	useState,
} from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { AssignSelectedSessionState, LabelDataActivity } from 'views/sessions';
import './popup.scss';
import { Loader } from '@storybook/loader';

const list = [
	{ label: 'All', value: 'all' },
	{ label: 'Pending', value: 'pending' },
	{ label: 'Processing', value: 'processing' },
	{ label: 'Completed', value: 'completed' },
	{ label: 'Rejected', value: 'rejected' },
];

interface ISubPopup {
	action: string;
	isParentLoading: boolean;
	setIsChildLoading: Dispatch<SetStateAction<boolean>>;
}
export const SubPopup: FC<ISubPopup> = ({
	action,
	isParentLoading,
	setIsChildLoading,
}) => {
	// globle states
	const [filteringTags, setFilteringTags] = useRecoilState(filteringTagsState);
	const setSelectedSession = useSetRecoilState(AssignSelectedSessionState);
	// local states
	const [isFilterLoading, setFilterLoading] = useState<{
		[key: string]: boolean;
	}>({});

	const handleChangeFilter = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>, key: string, status: string) => {
			const { checked } = e.target;
			setSelectedSession([]);
			setFilterLoading({ [status]: true });
			setIsChildLoading(true);
			setTimeout(() => {
				if (checked) {
					if (status === 'all') {
					const allStatus = ['pending', 'processing', 'completed', 'rejected'];
						setFilteringTags((prev: any) => {
							const newState = JSON.parse(JSON.stringify(prev));
							return { ...newState, [key]: allStatus };
						});
						setFilterLoading({ [status]: false });
						setIsChildLoading(false);
					} else {
						setFilteringTags((prev: any) => {
							const newState = JSON.parse(JSON.stringify(prev));
							return { ...newState, [key]: [...(newState[key] ?? []), status] };
						});
						setFilterLoading({ [status]: false });
						setIsChildLoading(false);
					}
				} else {
					if (status === 'all') {
						const clonedState = JSON.parse(JSON.stringify(filteringTags));
						delete clonedState[key];
						setFilteringTags(clonedState);
						setFilterLoading({ [status]: false });
						setIsChildLoading(false);
					} else {
						const clonedState = JSON.parse(JSON.stringify(filteringTags));
						const filteredValue = clonedState[key].filter(
							(item: any) => item !== status
						);
						clonedState[key] = filteredValue;
						if (!filteredValue.length) {
							delete clonedState[key];
						}
						setFilteringTags(clonedState);
						setFilterLoading({ [status]: false });
						setIsChildLoading(false);
					}
				}
			}, 1000);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[filteringTags]
	);

	const isSomeFilterLoading = useMemo(() => {
		const values = Object.values(isFilterLoading ?? {});
		return isParentLoading || values.some(value => value);
	}, [isFilterLoading, isParentLoading]);

	const handleChecked = useCallback(
		(status: string) => {
			if (status === 'all') {
				if (filteringTags?.[action]?.length === 4) return true;
				return false;
			} else {
				if (!filteringTags?.[action]) return false;
				return filteringTags?.[action]?.includes(status);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[action, filteringTags]
	);

	const renderListItem = useMemo(() => {
		return list.map((item, index) => {
			return (
				<div key={`${item.value}__${index.toString()}`} className="popup-menu-item">
					<div className="popup-menu-item__left">
						{isFilterLoading[`${item.value}`] ? (
							<div style={{ width: 20, height: 18 }}>
								<Loader type="loader" dimension={18} />
							</div>
						) : (
							<input
								disabled={isSomeFilterLoading}
								id={`subitem_${index}`}
								className="popup-menu-item__checkbox"
								type="checkbox"
								checked={handleChecked(item.value)}
								onChange={e => handleChangeFilter(e, action, item.value)}
							/>
						)}
						<label htmlFor={`subitem_${index}`} className="Popup--label-txt">
							{item.label}
						</label>
					</div>
				</div>
			);
		});
	}, [
		action,
		handleChangeFilter,
		handleChecked,
		isSomeFilterLoading,
		isFilterLoading,
	]);

	return (
		<div className="subPopup-wrapper">
			<div className="popup-body-wrapper">
				<div className="botton-touch">
					<div className="popup-body-wrapper--title">
						{LabelDataActivity[action]}
					</div>
				</div>
				<div className="popup-menu-list">{renderListItem}</div>
			</div>
		</div>
	);
};
