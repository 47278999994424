import { useRecoilValue } from 'recoil';

import { PipelineSettingsFormState } from 'global-stores';

export const TextMessageVerification = () => {
	const message = useRecoilValue(PipelineSettingsFormState);
	return (
		<div className="ppl-screen-body">
			<div className="ppl-screen-body__body">
				<div className="bubble-line">
					<div className="bubble ">{message['deliveryMethodTextMessage']}</div>
				</div>
			</div>
		</div>
	);
};
