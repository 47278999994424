import { FC } from 'react';
import { IOption } from '../dropdown';
import { Select } from '../select';
import './connector.scss';

const Options = [
	{
		label: 'And',
		value: 'and',
	},
	{
		label: 'Or',
		value: 'or',
	},
];

interface IConnectorThread {
	handleChangeSelect: (e: IOption) => void;
	defaultValue: IOption;
	Isdisable?: boolean;
	value?: IOption;
}
export const ConnectorThread: FC<IConnectorThread> = ({
	handleChangeSelect,
	defaultValue,
	Isdisable,
	value,
}) => {
	return (
		<div className="divider-main">
			<div className="height"></div>
			<Select
				type="operator"
				options={Options}
				handleChangeSelect={handleChangeSelect}
				defaultValue={defaultValue}
				Isdisable={Isdisable}
				value={value}
			/>
			<div className="height"></div>
		</div>
	);
};
