import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import classNames from 'classnames';

import { useOutsideClick } from 'hooks';
import {
	InboxfilteringTagsState,
	InboxfiltersIndicatorState,
	IsEnvelopeTypeCollapseState,
	IsStatusCollapseState,
} from '../store';

const statusList = [
	{ name: 'Completed', value: 'completed' },
	{ name: 'Need To Sign', value: 'needToSign' },
	{ name: 'Waiting for others', value: 'waitingForOthers' },
];

const inboxType = [
	{ name: 'Multi Signer', value: 'multiSigner' },
	{ name: 'Self Sign', value: 'selfSign' },
	{ name: 'Onboarding', value: 'onboarding' },
];

export const SignDocInboxFilter = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [isStatusOpen, setIsStatusOpen] = useRecoilState(IsStatusCollapseState);
	const [isEnvelopeTypeOpen, setIsEnvelopeTypeOpen] = useRecoilState(
		IsEnvelopeTypeCollapseState
	);
	const [filteringTags, setFilteringTags] = useRecoilState(
		InboxfilteringTagsState
	);
	const [filtersIndicator, setFiletrIndicator] = useRecoilState(
		InboxfiltersIndicatorState
	);

	const statusRef = useRef(null);
	const inboxTypeRef = useRef(null);
	const filterRef = useRef(null);

	useEffect(() => {
		let count = 0;
		Object.values(filteringTags).forEach((tag: any) => {
			return (count += tag.length);
		});
		setFiletrIndicator(count);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filteringTags]);

	useOutsideClick(filterRef, () => {
		setIsOpen(false);
	});

	const handleResetAll = useCallback(() => {
		setFiletrIndicator(0);
		setIsStatusOpen(false);
		setIsEnvelopeTypeOpen(false);
		setFilteringTags({});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleClickStatus = useCallback(() => {
		setIsStatusOpen(prev => !prev);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleClickInboxType = useCallback(() => {
		setIsEnvelopeTypeOpen(prev => !prev);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const selectFilterCss = classNames('Table-header--container', {
		'Table-header--filter-active': isOpen,
		'Table-header--filter-icon-active Table-header--filter-bg-active':
			filtersIndicator > 0,
	});

	const handleClickOnFilter = useCallback(() => {
		setIsOpen(prev => !prev);
	}, []);

	const filterIconClass = classNames('ri-filter-3-line', {
		'Table-header--filter-icon-active': filtersIndicator > 0,
	});

	const handleClick = useCallback((event: any) => {
		if (event.key === 'Escape') {
			setIsOpen(false);
		}
	}, []);

	const handleChangeFilter = useCallback(
		(e: any, key: string, status: string) => {
			const { checked } = e.target;
			if (checked) {
				setFilteringTags((prev: any) => {
					const newState = structuredClone(prev);
					const finalState = {
						...newState,
						[key]: [...(newState[key] ?? []), status],
					};
					return finalState;
				});
			} else {
				const clonedState = structuredClone(filteringTags);
				const filteredValue = clonedState[key].filter(
					(item: any) => item !== status
				);
				clonedState[key] = filteredValue;
				if (!filteredValue.length) {
					delete clonedState[key];
				}
				setFilteringTags(clonedState);
			}
		},
		[filteringTags, setFilteringTags]
	);

	const classes = useCallback(
		(check: boolean) =>
			classNames('ri-arrow-down-s-line', {
				'filter-popup__opened': check,
				'filter-popup__closed': !check,
			}),
		[]
	);

	const handleValidateChecked = useCallback(
		(item: any): boolean => {
			const tags: any[] = [];
			Object.values(filteringTags).forEach((tag: any) => tags.push(...tag));
			return tags.includes(item.value);
		},
		[filteringTags]
	);

	const renderStatus = useCallback(
		(list: any[], listName: string) => {
			return (
				<>
					<div className="padding-wrapper">
						{list.length === 0 && (
							<div className="RenderListItem--empty">No Data</div>
						)}
						{list?.map((item: any, index: number) => (
							<label
								// eslint-disable-next-line react/no-array-index-key
								key={`${item.name}_${index}`}
								className="popup-menu-item"
								htmlFor={`item_${index}_${item.value}`}
							>
								<div className="popup-menu-item__left">
									<input
										id={`item_${index}_${item.value}`}
										className="popup-menu-item__checkbox"
										type="checkbox"
										checked={handleValidateChecked(item)}
										onChange={e => handleChangeFilter(e, listName, item.value)}
									/>
									<span className="Popup--label-txt">
										{item.name}
									</span>
								</div>
							</label>
						))}
					</div>
				</>
			);
		},
		[handleChangeFilter, handleValidateChecked]
	);

	return (
		<div ref={filterRef} onKeyDown={handleClick}>
			<div className="filter-btn-wrapper" tabIndex={0}>
				<div
					className={`${selectFilterCss} filter-btn-wrapper__inner`}
					onClick={handleClickOnFilter}
				>
					<i className={filterIconClass} />
					{filtersIndicator > 0 ? (
						<div className="Table-header--selected">
							{filtersIndicator} selected
						</div>
					) : (
						<div className="Filter-title filter-btn-wrapper__inner__label">
							Filter
						</div>
					)}
				</div>
			</div>
			{isOpen && (
				<div className="session-filter-popup-wrapper">
					<div className="headingrow">
						<span className="filter-popup__header__label">Filter</span>

						<div onClick={() => handleResetAll()} className="reset-filter-all">
							Reset
						</div>
					</div>

					<div
						ref={statusRef}
						className="filter-popup__onboardingflow-wrapper bottom-touch"
					>
						<div
							className="filter-popup__header bottom-touch"
							onClick={handleClickStatus}
						>
							<div>
								<div className="filter-popup__header__label">
									Status and Actions
								</div>
							</div>
							<i className={classes(isStatusOpen)}></i>
						</div>
						<>{isStatusOpen && renderStatus(statusList, 'actionStatus')}</>
					</div>
					<div
						ref={inboxTypeRef}
						className="filter-popup__active-status-wrapper bottom-touch"
					>
						<div
							className="filter-popup__header bottom-touch"
							onClick={handleClickInboxType}
						>
							<div className="filter-popup__item-container">
								<div className="filter-popup__header__label">
									Packet Workflow
								</div>
							</div>
							<i className={classes(isEnvelopeTypeOpen)}></i>
						</div>
						<>{isEnvelopeTypeOpen && renderStatus(inboxType, 'inboxType')}</>
					</div>
				</div>
			)}
		</div>
	);
};
