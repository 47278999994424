import { atom } from 'recoil';
import { v4 } from 'uuid';
import { ISignDashboard, ISignDashboardSignature } from './types';

export const SignDashboardState = atom<ISignDashboard>({
	key: 'sign-dashboard-state-key',
	default: {
		isLoaded: true,
		error: false,
		data: {
			name: '',
			email: '',
			image: '',
			signature: null,
			_id: '',
			title: null,
			webhookConfig: {url: ""}
		},
	},
});

export const SignDashboardSignatureInitialedState = atom<boolean>({
	key: v4(),
	default: false,
});

export const SignDashboardSignatureState = atom<ISignDashboardSignature>({
	key: 'sign-dashboard-signature-state-key',
	default: {
		isLoaded: false,
		error: false,
		data: {
			signDetail: null,
			initialDetail: null,
		},
	},
});
export const SignatureLoadingState = atom<boolean>({
	key: 'signature-loaded-key',
	default: false,
});
