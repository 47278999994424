import type {
	InviteOnboardingPaymentCurrentPage,
	IPaymentType,
	IBusinessAddress,
	IBusinessInfo,
	IPayNowPages,
	IBussinessInfoError,
} from './type';

import { atom } from 'recoil';

import {
	MonthlyBillingSteps,
	OnboardingPaymentType,
	PayNowSteps,
} from './constant';
import { IAtoms } from 'types';

// this stores the current page in monthly billing flow
export const OnboardingPaymentCurrentPage =
	atom<InviteOnboardingPaymentCurrentPage>({
		key: 'invite-onboarding-payment-current-page-key',
		default: MonthlyBillingSteps.COMPANY_INFO,
	});

// this stores the type of payment
export const OnboardingPaymentTypeState = atom<IPaymentType>({
	key: 'onboarding-payment-type-key',
	default: OnboardingPaymentType.PAY_NOW,
});

// this stores the status of the switch
export const IsPayAtGoStatusState = atom<boolean>({
	key: 'is-pay-at-go-status-key',
	default: true,
});

// this stores the company details
export const BussinessInfoState = atom<IBusinessInfo>({
	key: 'monthly-business-info-state-key',
	default: {
		logo: '',
		companyName: '',
		linkedInUrl: '',
	},
});

// this stores the company address
export const BussinessAddressState = atom<IBusinessAddress>({
	key: 'monthly-bussiness-address-state-key',
	default: {
		address1: '',
		address2: '',
		city: '',
		state: '',
		zip: '',
		_id: '',
	},
});
interface InvestMentType {
	price: number;
	type: string;
}

interface IBillingSummary {
	fund: InvestMentType | null;
	totalPrice: number;
	steps: { name: string; price: number }[];
}

export const BillingSummary = atom<IAtoms<IBillingSummary>>({
	key: 'onboarding-billing-summary',
	default: {
		error: false,
		isLoaded: false,
		data: {
			totalPrice: 0,
			steps: [],
			fund: null,
		},
	},
});

export const PayNowCurrentPage = atom<IPayNowPages>({
	key: 'onboarding-pay-now-current-page',
	default: PayNowSteps.PAY_NOW,
});

export const ProcessingCloseShowState = atom<boolean>({
	key: 'processing-close-show-state-key',
	default: false,
});

export const PayNowCardId = atom<string>({
	key: 'pay-now-card-id',
	default: '',
});

export const BussinessInfoErrorState = atom<IBussinessInfoError>({
	key: 'bussiness-info-error-state-key',
	default: {
		companyName: '',
		linkedInUrl: '',
	},
});
