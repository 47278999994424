import { FC, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { QuestionnaireStep } from 'views/complex-onboarding-flow/constant';
import { AddBlockButton } from '../../../add-button';
import { Select } from '../../../select';
import { QuestionnaireTemplate } from '../questionnaire-template';
import { AllowedQuestionnaire } from './constants';
import { useQustionnaireForm } from './hooks';

import './questionnaire-choices.scss';
import { ComplexSettingFormState } from 'views/pipeline';
import { IPage, IPageElement, IVESTOR_DETAILS_STRING, ShareholderThreshold } from '../shareholder-threshold';

export interface IQuestionnaireChoices {
	handleAdd: () => void;
	nodeId: string;
}

export const QuestionnaireChoices: FC<IQuestionnaireChoices> = ({
	handleAdd,
	nodeId,
}) => {
	const {
		handleOperatorChangeSelect,
		setActive,
		tempOptions,
		existFormData,
		onSelectActive,
		selectedForm,
		active,
		getDefaultValue,
	} = useQustionnaireForm({ handleAdd, nodeId });
	const complexSettingFormState = useRecoilValue(ComplexSettingFormState);
	
	const shareholderSelected = useMemo(() => {
		const selectedFormStep = complexSettingFormState[nodeId];
		if(selectedFormStep){
			return selectedFormStep.pages?.some((page: IPage) => page.elements?.some((element: IPageElement) => element.type === IVESTOR_DETAILS_STRING));
		}
		return false;

	},[nodeId, complexSettingFormState]);

	return (
		<div className="QuestionnaireChoices">
			<ul
				onClick={() => setActive(QuestionnaireStep.New)}
				className={`QuestionnaireChoices--list ${
					active === QuestionnaireStep.New &&
					!(selectedForm || getDefaultValue()?.value)
						? 'QuestionnaireChoices--active'
						: ''
				}`}
			>
				<li>
					<AddBlockButton
						label="Add New Questionnaire"
						onClick={onSelectActive(QuestionnaireStep.New)}
					/>
				</li>
				{existFormData && !(selectedForm || getDefaultValue()?.value) && (
					<div className="QuestionnaireChoices--answers">
						<div className="QuestionnaireChoices--left">Questions</div>
						<div className="QuestionnaireChoices--right" onClick={handleAdd}>
							view
						</div>
					</div>
				)}
			</ul>

			<div className="QuestionnaireChoices--separate">
				<span className="QuestionnaireChoices--separateLine" />
				<span className="QuestionnaireChoices--separateText">OR</span>
				<span className="QuestionnaireChoices--separateLine" />
			</div>

			<ul
				className={`QuestionnaireChoices--list ${
					active === QuestionnaireStep.Old || getDefaultValue()?.value
						? 'QuestionnaireChoices--active'
						: ''
				}`}
				onClick={onSelectActive(QuestionnaireStep.Old)}
			>
				<div className="QuestionnaireChoices--item nowheel">
					<div className="QuestionnaireChoices--addIcon">
						<i className="ri-add-circle-line" />
					</div>
					<div className="QuestionnaireChoices--selectWrapper">
						<Select
							defaultValue={getDefaultValue()}
							options={tempOptions ?? []}
							handleChangeSelect={handleOperatorChangeSelect}
							type="operand"
							optionalClass="QuestionnaireChoices--select"
						/>
					</div>
				</div>
				{(selectedForm || getDefaultValue()?.value) && (
					<div className="QuestionnaireChoices--answers">
						<div className="QuestionnaireChoices--left">Questions</div>
						<div className="QuestionnaireChoices--right" onClick={handleAdd}>
							view
						</div>
					</div>
				)}
			</ul>

			{existFormData?.pages?.[0]?.elements?.find(
				(item: { type: string }) => AllowedQuestionnaire[item?.type]
			) && (
				<div className="QuestionnaireChoices--Template">
					<QuestionnaireTemplate
						existFormData={existFormData}
						selectedForm={selectedForm || getDefaultValue()?.value}
						id={nodeId}
					/>
				</div>
			)}
			{
				shareholderSelected && <ShareholderThreshold nodeId={nodeId} />
			}
		</div>
	);
};
