import { FC, useRef, useState } from 'react';
import { IOption, ReactDropdown } from '../dropdown';
import { useOutsideClick } from 'hooks';

interface ISelect {
	options: IOption[];
	handleChangeSelect: (event: IOption, key: string) => void;
	type: string;
	defaultValue?: IOption;
	Isdisable?: boolean;
	optionalClass?: string;
	isMulti?: boolean;
	value?: IOption;
	isSearchable?: boolean
}
export const Select: FC<ISelect> = ({
	options,
	handleChangeSelect,
	type,
	defaultValue,
	Isdisable,
	optionalClass = '',
	isMulti = false,
	value,
	isSearchable=true
}) => {
	const [openManual, setOpenManual] = useState(false);
	const ref: any = useRef();

	useOutsideClick(ref, () => {
		setOpenManual(false);
	});

	return (
		<div ref={ref}>
			<div onClick={() => setOpenManual(prev => !prev)}>
				<ReactDropdown
					options={options}
					handleChangeSelect={(e: IOption) => handleChangeSelect(e, type)}
					placeholder="Select"
					menuIsOpen={Isdisable ? false : openManual}
					className={`question-container__threads__dropdown ${optionalClass}`}
					defaultValue={defaultValue}
					Isdisable={Isdisable}
					isMulti={isMulti}
					value={value}
					isSearchable={isSearchable}
				/>
			</div>
		</div>
	);
};
