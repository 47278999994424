import { SessionInfoChild } from 'views/sessions';

export const SkeletonCardLoading = ({ width = 30 }: { width?: number }) => {
	const numberOfLoaders = [1, 2, 3, 4, 5, 6];

	return (
		<div className="SkeletonCardLoading">
			{numberOfLoaders.map(keys => (
				<div key={keys} className="SkeletonCardLoading--threads">
					<div>
						<SessionInfoChild height={40} width={40} borderRadius={8} />
					</div>
					<div className="SkeletonCardLoading--threads_right">
						<SessionInfoChild height={8} width={width} isPayment />
						<SessionInfoChild height={8} width={width} isPayment />
					</div>
				</div>
			))}
		</div>
	);
};
