import { useCallback, useMemo } from 'react';
import { IOption } from '@storybook';

import { useDealFilters } from '../../stores';
import { DEAL_FILTER } from '../../constants';

export const DealFilter = () => {

	// hooks
	const {
		handleClickOnFilter,
		handleClickToOpenFilterList,
		isFilerListOpen,
		filterIconClass,
		classes,
		filterRef,
		selectFilterCss,
		isOpen,
		filtersIndicator,
        handleResetAll,
        handleChangeFilter,
        handleValidateChecked,
	} = useDealFilters();

	const renderStatus = useCallback((list : IOption[], key: string) => {
		return (
			<>
				<div className="padding-wrapper">
					{list?.map(({ name , value}, index: number) => (
						<label
							key={`${value}_${index.toString()}`}
							className="popup-menu-item"
							htmlFor={`item_${index}_${value}`}
						>
							<div className="popup-menu-item__left">
								<input
									id={`item_${index}_${value}`}
									className="popup-menu-item__checkbox"
									type="checkbox"
									checked={handleValidateChecked(key , value)}
									onChange={e => handleChangeFilter(e, key, value)}
								/>
								<span className="Popup--label-txt">{name}</span>
							</div>
						</label>
					))}
				</div>
			</>
		);
	}, [handleChangeFilter, handleValidateChecked]);

	const mapFilterList = useMemo(
		() =>
			DEAL_FILTER.map(({ key, label, filterList }) => (
				<div
					key={key}
					className="filter-popup__onboardingflow-wrapper bottom-touch"
				>
					<div
						className="filter-popup__header bottom-touch"
						onClick={() => handleClickToOpenFilterList(key)}
					>
						<div>
							<div className="filter-popup__header__label">{label}</div>
						</div>
						<i className={classes(isFilerListOpen[key] as boolean)}></i>
					</div>
					<>{isFilerListOpen[key] && renderStatus(filterList, key)}</>
				</div>
			)),
		[classes, handleClickToOpenFilterList, isFilerListOpen, renderStatus]
	);

	return (
		<div ref={filterRef} onKeyDown={() => ({})}>
			<div className="filter-btn-wrapper" tabIndex={0}>
				<div
					className={`${selectFilterCss} filter-btn-wrapper__inner`}
					onClick={handleClickOnFilter}
				>
					<i className={filterIconClass} />
					{filtersIndicator > 0 ? (
						<div className="Table-header--selected">
							{filtersIndicator} Selected
						</div>
					) : (
						<div className="Filter-title filter-btn-wrapper__inner__label">
							Filter
						</div>
					)}
				</div>
			</div>
			{isOpen && (
				<div className="session-filter-popup-wrapper">
					<div className="headingrow">
						<span className="filter-popup__header__label">Filter</span>
						<div onClick={handleResetAll} className="reset-filter-all">
							Reset
						</div>
					</div>
					{mapFilterList}
				</div>
			)}
		</div>
	);
};
