import { Button } from '@storybook';
import { FC, useCallback, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { PaymentCardMethodsState } from 'global-stores';
import { CardListView } from './card-list';
import {
	IndetityFlowNavigate,
	SubscriptionIdentityNaviagtion,
} from 'views/user-identity-flow';
import { NoCard } from '../upgrade';
import { PaymentNavigation } from '../constant';
import { PaymentNavigationState } from '../../stores';
import './paymentMethodList.scss';

interface IPaymentMethodList {
	fromIdentity?: boolean;
}
export const PaymentMethodList: FC<IPaymentMethodList> = ({ fromIdentity }) => {
	// global states
	const setAddPaymentNaviagtion = useSetRecoilState(PaymentNavigationState);
	const paymentCardMethodsState = useRecoilValue(PaymentCardMethodsState);

	const setIdentityNavigate = useSetRecoilState(SubscriptionIdentityNaviagtion);
	const { cards = [] } = useMemo(
		() => paymentCardMethodsState ?? {},
		[paymentCardMethodsState]
	);
	// hooks

	// this will open the add new card modal
	const handleViewModal = useCallback(() => {
		if (fromIdentity) {
			setIdentityNavigate(IndetityFlowNavigate.PaymentCard);
			return;
		}
		setAddPaymentNaviagtion(PaymentNavigation.CardScreen);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="payment-method-list">
			{cards?.length ? (
				<>
					<div className="payment-save-card-list">
						<div className="payment-save-card-list__all-card">All cards</div>
						<Button
							label="Add new card"
							type="button__filled--primary"
							handleClick={handleViewModal}
						/>
					</div>

					<div className="payment-save-card-list__saved-cards">
						<CardListView />
					</div>
				</>
			) : (
				<NoCard
					title="No card added"
					buttonLabel="Add a card"
					handleButton={handleViewModal}
					buttonLoader={false}
					description="Add a card for easy payment"
					illustration="card-method.svg"
				/>
			)}
		</div>
	);
};
