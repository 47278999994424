import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { API_URL, MESSAGE } from 'constant';
import { dateConverter } from 'helpers';
import { useNetwork, useNotification } from 'hooks';
import {
	connectorNextColor,
	funnelChartColor,
	funnelChartName,
} from '../components';
import {
	ChartCompareFirstState,
	ChartCompareSecondState,
	CompareAverageTimeState,
	FlowAverageTimeState,
	analyticsChartHeaderState,
	analyticsChartTwoHeaderStart,
	funnelChartFirstState,
	funnelChartSecondState,
} from './states';

export const useAnalytics = () => {
	const { get: getPreFunnel, loading: preFunnelLoader } = useNetwork();
	const { get: getNextFunnel, loading: nextFunnelLoader } = useNetwork();

	const { errorNotification } = useNotification();

	const setComparePreDetail = useSetRecoilState(ChartCompareFirstState);
	const setCompareNextDetail = useSetRecoilState(ChartCompareSecondState);
	const setPreFunnelDetail = useSetRecoilState(funnelChartFirstState);
	const setNextFunnelDetail = useSetRecoilState(funnelChartSecondState);
	const setFlowAverageTime = useSetRecoilState(FlowAverageTimeState);
	const setCompareAverageTime = useSetRecoilState(CompareAverageTimeState);

	const selectedDate = useRecoilValue(analyticsChartHeaderState);
	const { startDate, endDate } = selectedDate[0] ?? {};

	const compareDateRange = useRecoilValue(analyticsChartTwoHeaderStart);
	const { startDate: startDateTwo, endDate: endDateTwo } =
		compareDateRange[0] ?? {};

	const getPreFunnelDetails = useCallback(() => {
		getPreFunnel(
			`${API_URL.GA_FUNNEL}${dateConverter(startDate)}&to=${dateConverter(
				endDate
			)}&isDeleted=false`
		).then(resp => {
			if (resp?.data?.length > 0) {
				setComparePreDetail(resp?.data[0]);
				const outerData: any = [];
				Object.keys(resp.data[0]).forEach(element => {
					for (const toElement in resp.data[0][element]) {
						const totalCount = resp.data[0][element][toElement].total;
						const seriesDetail = [
							funnelChartName[element] ?? element,
							funnelChartName[toElement] ?? toElement,
							totalCount,
							funnelChartColor[element] || connectorNextColor,
							totalCount ?? 0,
						];
						outerData.push(seriesDetail);
					}
				});
				setPreFunnelDetail(outerData);
			} else {
				errorNotification(MESSAGE.ERROR);
			}
		});
		getPreFunnel(
			`${API_URL.CHART_AVERAGE_TIME}${dateConverter(
				startDate
			)}&to=${dateConverter(endDate)}`
		).then(res => {
			if (res?.data) {
				setFlowAverageTime(res.data[0]);
			} else {
				errorNotification(MESSAGE.ERROR);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [endDate, startDate]);

	const getNextFunnelDetails = useCallback(() => {
		getNextFunnel(
			`${API_URL.GA_FUNNEL}${dateConverter(startDateTwo)}&to=${dateConverter(
				endDateTwo
			)}&isDeleted=false`
		).then(resp => {
			if (resp?.data?.length > 0) {
				setCompareNextDetail(resp?.data[0]);
				const outerData: any = [];
				Object.keys(resp.data[0]).forEach(element => {
					for (const toElement in resp.data[0][element]) {
						const totalCount = resp.data[0][element][toElement].total;
						const seriesDetail = [
							funnelChartName[element] ?? element,
							funnelChartName[toElement] ?? toElement,
							totalCount,
							funnelChartColor[element] || connectorNextColor,
							totalCount ?? 0,
						];
						outerData.push(seriesDetail);
					}
				});
				setNextFunnelDetail(outerData);
			} else {
				errorNotification(MESSAGE.ERROR);
			}
		});
		getNextFunnel(
			`${API_URL.CHART_AVERAGE_TIME}${dateConverter(
				startDateTwo
			)}&to=${dateConverter(endDateTwo)}`
		).then(res => {
			if (res?.data) {
				setCompareAverageTime(res.data[0]);
			} else {
				errorNotification(MESSAGE.ERROR);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [endDateTwo, startDateTwo]);

	return {
		getPreFunnelDetails,
		getNextFunnelDetails,
		preFunnelLoader,
		nextFunnelLoader,
	};
};
