import { FC, useMemo, useState } from 'react';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';
import { Button, Loader } from '@storybook';

import { API_URL, MESSAGE, message } from 'constant';
import { UserRolesState } from 'global-stores';
import { useNetwork, useNotification } from 'hooks';
import { ArchiveConfirmationModal } from 'views/sessions';
import { useNewRoles } from 'views/user-roles/hooks';
import {
	RoleAndPermissionNavigation,
	UserRoleNavigation,
} from 'views/user-roles/constant';
import {
	AddNewRoleFormState,
	IAddNewRoleFormPayload,
	IPermissionType,
	IReadWrite,
	PermissionNavigation,
	SelectedUserRoleState,
	UserRolePermissionNavigation,
} from 'views/user-roles/store';
import { AgentSelectedService } from '../agent-selected-service';
import { AllowPermission } from '../allow-permission';
import { AssinedUser } from '../assined-user';
import { DropInfoOptions } from '../info-drop-options';
import { AddNewRoleModal } from '../add-role-modal';

import './complaince-agent.scss';
import { nameRegex } from 'views/user-roles/user-roles';

interface IComplainceAgent {
	isUserPermissionWrite?: boolean;
}

export const ComplainceAgent: FC<IComplainceAgent> = ({
	isUserPermissionWrite = true,
}) => {
	const setUserRoleNavigate = useSetRecoilState(UserRolePermissionNavigation);
	const setNavigate = useSetRecoilState(PermissionNavigation);
	const resetAddRoleForm = useResetRecoilState(AddNewRoleFormState);
	const navigate = useRecoilValue(PermissionNavigation);
	const addRoleForm = useRecoilValue(AddNewRoleFormState);

	const [isAddRoleModalOpen, setIsAddRoleModalOpen] = useState<boolean>(false);
	const [selectedUserRole, setSelectedUserRole] = useRecoilState(
		SelectedUserRoleState
	);
	const [userRoles, setUserRoles] = useRecoilState(UserRolesState);

	const { patch: updateAddNewRole, loading: updateRoleLoader } = useNetwork();
	const { successNotification, errorNotification } = useNotification();

	const { name, description, services, fullAccess, permissions } = useMemo(
		() => addRoleForm,
		[addRoleForm]
	);

	const handleCloseModel = () => null;
	const {
		handleDeleteUserRole,
		isOpenDeleteModal,
		handleOpenDelete,
		addNewRoleLoader,
	} = useNewRoles({ handleCloseModel });

	const handleBackToPermission = () => {
		setUserRoleNavigate(UserRoleNavigation.AddedRoleCard);
		setSelectedUserRole(null);
		resetAddRoleForm();
	};

	const handleCloseModal = () => {
		setIsAddRoleModalOpen(prev => !prev);
	};
	const onEditInviteRole = () => {
		handleCloseModal();
		setNavigate(RoleAndPermissionNavigation.EditRole);
	};

	const handleUpdateRole = async () => {
		const payload: IAddNewRoleFormPayload = {
			name: name.trim(),
			description: description.trim(),
			services,
			fullAccess,
		};
		if (!fullAccess) {
			payload['permissions'] = permissions;
		}

		const resp = await updateAddNewRole(
			`${API_URL.ROLES}/${selectedUserRole?._id}`,
			payload
		);

		if (resp?._id) {
			setSelectedUserRole(resp);
			handleCloseModal();
			resetAddRoleForm();
			successNotification(message.ROLE_UPDATED_SUCCESS);
			if (userRoles && selectedUserRole) {
				const editIndex = userRoles.indexOf(selectedUserRole);
				const role =
					(userRoles ?? []).find(el => el._id === selectedUserRole._id) ?? {};

				const newResp = {
					...role,
					...resp,
				};

				const filteredRoles = [...userRoles]
					.filter(role => role._id !== selectedUserRole._id);
				
				filteredRoles.splice(editIndex, 0, newResp);
				
				setUserRoles(filteredRoles);
			}
		} else {
			errorNotification(resp?.message ?? MESSAGE.ERROR);
		}
	};

	const existingUserRoles = useMemo(
		() => userRoles?.map(role => role.name.toUpperCase()),
		[userRoles]
	);

	const disabledAddRoleBtn = useMemo(() => {
		const { name, permissions, services, fullAccess } = addRoleForm ?? {};
		const { name: selectedName } = selectedUserRole ?? {};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { demo, liveMode, userProfile, ...newPermissions } =
			permissions ?? {};

		const isValidName =
			!name.trim() ||
			(existingUserRoles?.includes(name.toUpperCase()) &&
				selectedName?.toUpperCase() !== name.toUpperCase()) ||
			name.length < 3 ||
			!nameRegex.test(name);

		const hasAnyReadOrWritePermission = Object.keys(newPermissions).some(
			category => {
				return ['read', 'write'].some(
					permission =>
						permissions[category as IPermissionType][permission as IReadWrite]
				);
			}
		);

		if (isValidName) {
			return true;
		} else if (fullAccess) {
			return false;
		} else if (!services || services.length === 0) {
			return true;
		} else {
			return !hasAnyReadOrWritePermission;
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addRoleForm]);

	const disableNextBtn = useMemo(() => {
		switch (navigate) {
			case RoleAndPermissionNavigation.AddNewRole:
				return disabledAddRoleBtn;
			case RoleAndPermissionNavigation.EditRole:
				return disabledAddRoleBtn;
			default:
				return false;
		}
	}, [disabledAddRoleBtn, navigate]);

	return (
		<div className="ComplainceAgent--container">
			<div
				className="ComplainceAgent--container__back"
				onClick={handleBackToPermission}
			>
				<div className="ComplainceAgent--container__back--icon">
					<i className="ri-arrow-left-s-line" />
				</div>
				<div className="ComplainceAgent--container__back--label">
					Back to Roles and permission
				</div>
			</div>

			<div className="ComplainceAgent--container__userAccess">
				<div className="agent--container">
					<div className="agent--container__header">
						<div className="agent--container__header--title">
							<div className="agent--container__header--title__heading">
								<div className="agent--container__header--title__heading--text">
									<div className="agent--container__header--title__heading--text__label">
										{selectedUserRole?.name ?? 'NA'}
									</div>
									{isUserPermissionWrite && (
										<div className="agent--container__header--title__heading--text__btn">
											<div
												className="agent--container__header--title__heading--text__btn--label"
												onClick={onEditInviteRole}
											>
												Edit
											</div>
											<DropInfoOptions
												handleDelete={e =>
													handleOpenDelete(e, selectedUserRole)
												}
												showEdit={false}
												isDisabled
												isDeleteDisabled={true}
											/>
										</div>
									)}
								</div>
							</div>
							<div className="agent--container__header--title__subHeader">
								{selectedUserRole?.description ?? 'NA'}
							</div>
						</div>
					</div>
					<div className="agent--container__services">
						<AgentSelectedService />
					</div>
					<div className="agent--container__permission">
						<div className="agent--container__permission--table">
							<AllowPermission isDisable />
						</div>
					</div>
				</div>
				<div className="assinedUser--container">
					<AssinedUser isUserPermissionWrite={isUserPermissionWrite} />
				</div>
			</div>

			{isOpenDeleteModal && (
				<ArchiveConfirmationModal
					description={`Deleting ${selectedUserRole?.name}  role will remove user’s permissions from the
					system.`}
					boldDescription={`" Do you still want to delete ${selectedUserRole?.name} role?"`}
					label="Delete"
					title="Are you sure?"
					visible={isOpenDeleteModal}
					showLoader={addNewRoleLoader}
					handleModal={handleDeleteUserRole}
				/>
			)}

			{isAddRoleModalOpen && (
				<AddNewRoleModal
					isAddRoleModalOpen={isAddRoleModalOpen}
					handleCloseModel={handleCloseModal}
				>
					<div className="AddNewRoleModal--container__footer">
						<Button
							label="Cancel"
							type="button__filled--secondary"
							handleClick={handleCloseModal}
						/>
						<Button
							label={
								updateRoleLoader ? (
									<Loader
										type="loader"
										dimension={22}
										className="loader-white"
									/>
								) : (
									'Save'
								)
							}
							type="button__filled--primary"
							handleClick={handleUpdateRole}
							disabled={disableNextBtn}
						/>
					</div>
				</AddNewRoleModal>
			)}
		</div>
	);
};
