import { useCallback } from 'react';

import { Button, Image } from '@storybook';

import './no-escrow.scss';

export const NoEscrow = () => {
	const handleAddEscrowAcconut = useCallback(() => {
		// add feature to be implemented by pradeep and nadeem
	}, []);

	return (
		<div className="no-escrow-wrapper">
			<Image fileName={'no-account.svg'} width="156" height="120" />
			<div className="no-escrow-heading">No escrow account available</div>
			<div className="no-escrow-sub-heading">Some description here</div>
			<Button
				label="Add Account"
				handleClick={handleAddEscrowAcconut}
				type="button__filled button__filled--primary"
			/>
		</div>
	);
};
