import { FC, ReactElement, ReactNode } from 'react';

interface ISessionDetailsHeader {
	label: string;
	icon: ReactElement;
	children?: ReactNode;
}

export const SessionDetailsHeading: FC<ISessionDetailsHeader> = ({
	label,
	icon,
	children,
}) => {
	return (
		<div className="kycDetails-header">
			<div className="kycDetails--sub-header">
				<span className="form-icon-container">
					<span className="form-icon-profile-container">{icon}</span>
					<span>{label}</span>
				</span>
			</div>
			{children}
		</div>
	);
};
