import './screen-skelton.scss';

interface Props {
	header?: JSX.Element;
	body?: JSX.Element;
	footer?: JSX.Element;
}
/**
 * ScreenSkelton component
 *
 * @param {JSX.Element} Props.header - JSX element for the header section
 * @param {JSX.Element} Props.body - JSX element for the body section
 * @param {JSX.Element} Props.footer - JSX element for the footer section
 */

export const ScreenSkelton = ({ header, body, footer }: Props) => {
	return (
		<div className="skelton-wrapper">
			{header && <div className="skelton-wrapper__header">{header}</div>}
			<div className="skelton-wrapper__body">{body}</div>
			{footer && <div className="skelton-wrapper__footer">{footer}</div>}
		</div>
	);
};
