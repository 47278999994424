import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Image, SkeletonTable } from '@storybook';

import { loginState } from 'global-stores';
import {
	BillingTransactionsState,
	useBillingTransaction,
} from 'views/settings/stores';
import { Billing_Transactions_Header } from '../constant';
import { BillingTransactionRow } from './transaction-row';
import './transactions.scss';

interface ISorted {
	isSorted: boolean;
	sortKey: string;
}

export const BillingTransactions = () => {
	//globle states
	const billingTransactions = useRecoilValue(BillingTransactionsState);
	const userState = useRecoilValue(loginState);

	// local states
	const [sorted, setSorted] = useState<ISorted>({
		isSorted: false,
		sortKey: '',
	});
	const [isLoadedTransactions, setIsLoadedTransactions] = useState(true);

	// hoooks
	const { billingTransaction: getBillingTransaction } = useBillingTransaction();
	const { isSorted, sortKey } = useMemo(() => sorted, [sorted]);

	useEffect(() => {
		//Deepak : call this olny when business id exist
		if (userState?.business) {
			(async () => {
				setIsLoadedTransactions(false);
				await getBillingTransaction();
				setIsLoadedTransactions(true);
			})();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userState?.business]);

	const renderTableBody = useMemo(() => {
		return billingTransactions
			.map(transaction => {
				return (
					//Deepak: we are not getting transaction ID in case of failed transaction thats why using paymentId it is unique for all transaction
					<tr
						key={transaction?.paymentId}
						className="transactions-table--row-border"
					>
						<BillingTransactionRow transaction={transaction} />
					</tr>
				);
			})
			.sort((a, b) => {
				const first = a?.props?.children?.props?.transaction?.[sortKey];
				const second = b?.props?.children?.props?.transaction?.[sortKey];
				if (first < second) return isSorted ? -1 : 1;
				if (first > second) return isSorted ? 1 : -1;
				else return 0;
			});
	}, [sortKey, isSorted, billingTransactions]);

	const renderHeaderIcon = useCallback(
		(type: string, key: string) => {
			switch (type) {
				case 'sorting':
					return (
						<>
							<i
								className={`ri-arrow-up-s-fill sorting-up ${
									sortKey === key && isSorted ? 'sorted' : ''
								}`}
							/>
							<i
								className={`ri-arrow-down-s-fill sorting-down ${
									sortKey === key && !isSorted ? 'sorted' : ''
								}`}
							/>
						</>
					);
				case 'info':
					return (
						<>
							<i className="ri-information-fill" />
						</>
					);
				default:
					return <></>;
			}
		},
		[sortKey, isSorted]
	);

	const handleClickOnIcon = useCallback(
		(type: string, key: string) => {
			if (type === 'sorting') {
				setSorted({
					isSorted: sortKey === key && isSorted ? false : true,
					sortKey: key,
				});
			}
		},
		[isSorted, sortKey]
	);

	const renderTransactionTable = useMemo(
		() => (
			<div className="transactions-table-wrapper">
				<table className="transactions-table">
					<thead className="transactions--table-head">
						<tr>
							{Billing_Transactions_Header.map(
								({ key, label, icon_type: type }) => (
									<th key={key} className="transactions-table-header">
										<div
											className={`transactions-table-header__label-wrapper ${
												type !== '' ? 'clickable' : ''
											}`}
											onClick={() => handleClickOnIcon(type, key)}
										>
											{label}
											{billingTransactions.length ? (
												<div className="transactions-table-header__label-icon">
													{renderHeaderIcon(type, key)}
												</div>
											) : (
												<></>
											)}
										</div>
									</th>
								)
							)}
						</tr>
					</thead>
					<tbody>{billingTransactions.length ? renderTableBody : <></>}</tbody>
				</table>
				{billingTransactions.length === 0 ? (
					<div className="Billing--empty-state">
						<Image
							fileName="invoice-illustration.svg"
							className="Billing-detail-empty-image"
						/>
					</div>
				) : (
					<></>
				)}
			</div>
		),
		[renderTableBody, billingTransactions, renderHeaderIcon, handleClickOnIcon]
	);

	return (
		<div className="transactions-detail hover">
			<div className="transactions-table-detail-header">
				<div className="billing-detail-title transactions--title">
					Transactions
				</div>
				<div className="invoices--subtitle transactions--subtitle">
					See all your transactions
				</div>
			</div>
			{!isLoadedTransactions ? (
				<>
					<div className="billing-detail-loader">
						<SkeletonTable listsToRender={4} numberColumn={6} />
					</div>
				</>
			) : (
				renderTransactionTable
			)}
		</div>
	);
};
