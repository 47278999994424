import { REJECTED_CONSTANTS } from 'views/business-rejected/constants';
import Modal from '@storybook/new-modal/modal';
import { FC, useState } from 'react';
import { BusinessSession } from 'components';
import './verfication-result.scss';

export const VerificationResult: FC<{ isViewShow?: boolean }> = ({
	isViewShow,
}) => {
	const [isBusinessReport, setBusinessReport] = useState(false);
	const handletoggleModal = () => {
		setBusinessReport(prev => !prev);
	};

	const handleError = () => {
		handletoggleModal();
	};

	return (
		<>
			{!isViewShow && (
				<div className="VerificationResult">
					<button
						className="VerificationResult__report"
						onClick={handletoggleModal}
					>
						{REJECTED_CONSTANTS.VIEW_REPORT}
					</button>
				</div>
			)}

			{isBusinessReport && (
				<Modal
					modalName="kyc-modal"
					isOpen={isBusinessReport}
					closeModal={handletoggleModal}
					isCloseButtonSticky={true}
					className="Business-Rejected-Modal Business-Rejected-Session-Modal"
				>
					<div className="Business-Rejected-Modal__wrapper ">
						<BusinessSession handleError={handleError} />
					</div>
				</Modal>
			)}
		</>
	);
};
