import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { ReactResponsiveTable } from '@storybook';
import { API_URL } from 'constant';
import { FundPaginationState, PageCountState } from 'global-stores';
import { useInterval, useNetwork } from 'hooks';
import { ExportPdf } from './components';
import { transactionHeaders } from './constants';
import {
	ITransactionRow,
	SelectedTransactionIdsState,
	SelectedTransactionState,
	TransactionRowsState,
	TransactionsState,
} from './stores';
import { TransactionDetails } from './transactions-details';

import { Header } from 'views/header';
import './transactions.scss';

export const Transactions = () => {
	//globle states
	const [transactions, setTransactions] = useRecoilState(TransactionsState);
	const [transactionRows, setTransactionRows] =
		useRecoilState(TransactionRowsState);
	const [selectedTransactionIds, setselectedTransactionIds] = useRecoilState(
		SelectedTransactionIdsState
	);
	const [selectedTransaction, setSelectedTransaction] = useRecoilState(
		SelectedTransactionState
	);
	const pageNumber = useRecoilValue(FundPaginationState);
	const setPageCount = useSetRecoilState(PageCountState);

	//local states
	const [isModal, setIsModal] = useState(false);
	const [isLoadingRows, setIsLoadingRows] = useState(true);

	// hook
	const { get, loading, isLoaded } = useNetwork();
	// Rahul Singh: limit for Pagination
	const pageLimit = 20;
	const fetchTransactions = useCallback(
		(loader: boolean): void => {
			if (loader) {
				setIsLoadingRows(true);
				setTransactionRows([]);
			} else {
				get(`${API_URL.Transactions}?&page=${pageNumber}&limit=${pageLimit}`)
					.then(res => {
						if (res?.data?.length > 0) {
							setTransactions({ ...(res?.data ?? [])[0] }?.data);
							setPageCount({ ...(res?.data ?? [])[0] }?.totalPages);
						}
					})
					.finally(() => {
						setIsLoadingRows(false);
					});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[get, pageNumber]
	);

	useEffect(() => {
		if (transactions.length === 0) {
			fetchTransactions(true);
		}
		setselectedTransactionIds([]);
		// eslint-disable-next-line
	}, []);

	const handleOnChecked = useCallback(
		(e: MouseEvent<HTMLDivElement>, transaction: ITransactionRow) => {
			e.stopPropagation();
			setselectedTransactionIds(prev => {
				const index = prev.findIndex((el: any) => el === transaction._id);
				if (index === -1) {
					return [...prev, transaction._id];
				} else {
					const newArr = [...prev];
					newArr.splice(index, 1);
					return newArr;
				}
			});
		},
		[setselectedTransactionIds]
	);

	useEffect(() => {
		if (transactions.length) {
			const rows: ITransactionRow[] = [];
			transactions.forEach((transaction: any) => {
				if (transaction) {
					let row: any = {};
					transactionHeaders.forEach(header => {
						const { key, format } = header ?? {};
						if (format === 'jsx' && key === 'checkbox') {
							const value = () => (
								<div
									key={key}
									onClick={(e: MouseEvent<HTMLDivElement>) =>
										handleOnChecked(e, transaction)
									}
									className="sessions-row-checkbox"
								>
									<input
										type="checkbox"
										className="session-checkbox"
										checked={
											!!selectedTransactionIds?.find(
												(item: string) => item === transaction._id
											)
										}
									/>
								</div>
							);
							return (row[key] = value);
						}
						row = { ...row, [key]: transaction[key] ?? '--' };
						return null;
					});
					rows.push(row);
				}
			});
			setTransactionRows(rows);
		}
	}, [
		handleOnChecked,
		selectedTransactionIds,
		setTransactionRows,
		transactions,
	]);

	useInterval(fetchTransactions, 5000);

	const handleOpenDetails = useCallback(
		(item: any) => {
			if (Object.keys(item).length > 0) {
				setSelectedTransaction(item);
			}
			setIsModal(true);
		},
		[setSelectedTransaction]
	);

	useEffect(() => {
		if (isLoaded && !loading) {
			setIsLoadingRows(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoaded, loading]);

	const downloadZip = useMemo(
		() => (transactions.length ? <ExportPdf label="Download Zip" /> : <></>),
		[transactions]
	);

	const renderDownloadWrapper = useMemo(() => {
		return (
			<div className="session-header-action-btns">
				<>
					<div className="arc-btn">
						{`${selectedTransactionIds?.length ?? 0} Selected`}{' '}
						<span onClick={() => setselectedTransactionIds([])}>
							{selectedTransactionIds?.length > 0 && (
								<i className="ri-close-line" />
							)}
						</span>
					</div>
					{downloadZip}
				</>
			</div>
		);
	}, [downloadZip, selectedTransactionIds, setselectedTransactionIds]);

	const renderTransactions = useMemo(() => {
		return (
			<ReactResponsiveTable
				tableType="transactions"
				column={transactionHeaders}
				rows={transactionRows}
				height="calc(100vh - 200px)"
				emptyHeight={'calc(100vh - 187px)'}
				handelRowClick={handleOpenDetails}
				isLoading={isLoadingRows}
				columnHandle={true}
				showSearch={true}
				className="Transactions--table"
				EmptyIllustration="empty-pipeline.svg"
				EmptyMessageHeading="No Transactions Available"
				EmptyMessageDescription="You have not done any transactions yet."
				isPagination
				handlePagination={() => fetchTransactions(true)}
				downloadChildren={renderDownloadWrapper}
			/>
		);
		// eslint-disable-next-line
	}, [isLoadingRows, transactionRows, renderDownloadWrapper]);

	return (
		<div>
			<Header title="Transactions" />
			<div className="Transactions--table-container">
				<div className="Transaction--search-wraper">{renderTransactions}</div>
			</div>
			<TransactionDetails
				isModal={isModal}
				setIsModal={setIsModal}
				details={selectedTransaction}
			/>
		</div>
	);
};

export default Transactions;
