import type { IIconBtn as Props } from './type';

import './icon-btn.scss';

/**
 * IconBtn component
 *
 * @param {string} Props.btnClassName - CSS class name for the icon button
 * @param {() => void} Props.handleClickIcon - Callback function triggered when the icon button is clicked
 * @param {string} Props.className - Additional CSS class for styling the button
 * @param {boolean} Props.isDisabled - Flag to indicate whether the icon button is disabled
 */

export const IconBtn = ({
	btnClassName,
	handleClickIcon,
	className,
	isDisabled = false,
}: Props) => {
	return (
		<button
			className={`icon-btn ${className}`}
			onClick={handleClickIcon}
			disabled={isDisabled}
		>
			<i
				className={`${btnClassName} icon icon-btn${
					isDisabled ? '--disabled' : ''
				}`}
			/>
		</button>
	);
};
