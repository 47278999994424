import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Skeleton } from '@storybook';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { SignDashboardState } from '../../store';
import { AddWebhookButton } from './component/webhook-action';
import { PERMISSION_SCREEN, useUserRoles } from 'views/routes-children';

export const EsignWebhookUrl = () => {
	const { isLoaded, data } = useRecoilValue(SignDashboardState);
	
	const { webhookConfig } = data;

	const actionButton = useMemo(() => {
		const label = webhookConfig.url.trim() ? 'Edit' : 'Add';
		const prefixIcon = webhookConfig.url.trim()
			? 'ri-edit-2-line'
			: 'ri-add-line';

		return { label, prefixIcon };
	}, [webhookConfig.url]);

	const url = useMemo(() => {
		return webhookConfig.url || 'No webhook url added'
	}, [webhookConfig.url])

	const { checkUserWritePermission } = useUserRoles();

	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.SignDashboard),
		[checkUserWritePermission]
	);

	return (
		<>
			<ReactTooltip
				anchorId={url}
				place="bottom"
				content={url}
				style={{ zIndex: 1000, color: '#ffffff' }}
			/>
			<div className="webhook-url">
				<div className="webhook-url__card">
					<div className="webhook-url__label">Webhook</div>
					{!isLoaded ? (
						<Skeleton width={75} height={15} />
					) : (
						<div className="webhook-url__value" id={url}>
							{url}
						</div>
					)}
				</div>
				{
					isUserPermissionWrite && (
						<AddWebhookButton
							label={actionButton.label}
							prefixIcon={actionButton.prefixIcon}
							isLoaded={isLoaded}
						/>
					)
				}
			</div>
		</>
	);
};
