import { FC, useCallback, useMemo, useState } from 'react';
import { Loader } from '@storybook';
import Modal from '@storybook/new-modal/modal';
import { environmentUrl } from 'constant';
import { envHost } from 'helpers';
import { SimpliciSignIframe } from 'shared-components';

import './esign.scss';

interface ISimpliciUseTemplate {
	templateId: string;
}

export const EsingConfigDocs: FC<ISimpliciUseTemplate> = ({ templateId }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [isLoaded, setLoaded] = useState(false);

	const handleUseTemplate = useCallback(async () => {
		setLoaded(true);
		setIsOpen(true);

		setTimeout(() => {
			setLoaded(false);
		}, 1000);
	}, []);

	const handleCloseModal = useCallback(() => {
		setIsOpen(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const renderSimpliciSignWebComponent = useMemo(() => {
		if (isLoaded) {
			return (
				<div className="SimpliciUseTemplate__iframe-loading">
					<Loader />
				</div>
			);
		}
		return (
			<>
				<SimpliciSignIframe
					className="SimpliciUseTemplate__iframe  SimpliciUseTemplate__iframe-wrapper"
					signUrl={`${environmentUrl[envHost]}config-doc?templateId=${templateId}&view=true`}
					handleSubmitModal={() => ({})}
				/>
				<div
					style={{
						position: 'absolute',
						top: 8,
						right: 16,
						zIndex: 1,
						cursor: 'pointer',
					}}
					onClick={handleCloseModal}
				>
					<i className="ri-close-line" />
				</div>
			</>
		);
	}, [isLoaded, templateId, handleCloseModal]);

	return (
		<div className="SimpliciUseTemplate">
			<div onClick={handleUseTemplate} className="SimpliciUseTemplate__use-btn">
				View
			</div>
			{isOpen && (
				<Modal
					isOpen={isOpen}
					modalName=""
					closeModal={handleCloseModal}
					isStopOutsideClick={false}
					showCloseBtn={false}
					className="use-template-model"
				>
					{renderSimpliciSignWebComponent}
				</Modal>
			)}
		</div>
	);
};
