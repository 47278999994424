import { atom } from 'recoil';

// Here any is because data is for multiple subsection so that here given any
export const KybInfoModalState = atom<{
	isOpen: boolean;
	data: any;
	label: string;
	modalType: 'simple' | 'tabs';
}>({
	key: 'Kyb-Info-Modal-state-key',
	default: {
		isOpen: false,
		data: {},
		label: '',
		modalType: 'simple',
	},
});

export type OfacTypes = "GWL" | "PEP" | "EDD" | "SOE" | "PEP/EDD"

export const KybOfacSelectedTab = atom<OfacTypes>({
	key: 'kyb-ofac-selected-tab',
	default: 'PEP',
});
