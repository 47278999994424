import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import AppleLogin from 'react-apple-login';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Button, Confirmation, Loader } from '@storybook';

import { useNetwork, useNotification, useWebAuthn } from 'hooks';
import { isMacOrIOS, emailRegex } from 'utils';
import { MESSAGE, validateEmail } from 'constant';
import { isWebAuthnSupported } from 'global-stores';
import {
	ApprovalStatusState,
	CountryCode,
	getWebAuthnSupported,
	isConfirmationModalOpen,
	LoginCredsState,
	LoginStep,
	MobileLoginLinkResponseState,
	OptionsResponseState,
	useLogin,
	useWebLogin,
	VerificationIDState,
	Nosupported,
	ScreenLoader,
	LoginMaskPhoneCredsState,
	userIdbeforeLoginState,
} from 'components';
import { TEST_USER } from './constant';

import style from './choose-method.module.sass';

type ILoginPhoneNumber = {
	phone: string;
	countryCode: string;
};

interface IChooseMethod {
	onSuccess: any;
	onFailed: any;
	locationInfo: Partial<GeolocationCoordinates> | undefined;
	ipAddress: string;
	loginPhoneNumber: ILoginPhoneNumber;
	setSignInActiveStep: any;
	methods: string;
	send_otp_api: string;
}

let count = 0;

const params = new URLSearchParams(window.location.search);
const redirectState = params.get('redirectSocialState');

export const ChooseMethod: FC<IChooseMethod> = ({
	onFailed,
	onSuccess,
	locationInfo,
	ipAddress,
	setSignInActiveStep,
	methods,
	send_otp_api,
}) => {
	// globle states
	const setStep = useSetRecoilState(LoginStep);
	const country = useRecoilValue(CountryCode);
	const verificationID = useRecoilValue(VerificationIDState);
	const optionsResponse = useRecoilValue(OptionsResponseState);
	const phone = useRecoilValue(LoginCredsState);
	const setSendLinkResponse = useSetRecoilState(MobileLoginLinkResponseState);
	const [isConfirmationOpen, setConfirmationOpen] = useRecoilState(
		isConfirmationModalOpen
	);
	const maskPhoneCreds = useRecoilValue(LoginMaskPhoneCredsState);
	const setuserIdbeforeLogin = useSetRecoilState(userIdbeforeLoginState);

	const verficationStatus = useRecoilValue(ApprovalStatusState);
	const [isNotSupportedOpen, setNotSupportedOpen] =
		useRecoilState(isWebAuthnSupported);

	//lacal state
	const [isBoimetricLoading, setBoimetricLoading] = useState(false);
	const [isWebAuthnCompleted, setWebAuthnCompleted] = useState(false);

	//hooks
	const { registerNewCredential, authenticate: authentication } = useWebAuthn();
	const { successNotification, errorNotification } = useNotification();
	const { post: sendOtp } = useNetwork();
	const { loginWithEmail: sendLinkToMobile } = useLogin();
	const {
		registrationApproval,
		authenticateOptions,
		verifyRegistration,
		verifyAuthentication,
		registrationOptions,
	} = useWebLogin();

	useEffect(() => {
		if (redirectState) {
			localStorage.setItem('redirectOrigin', redirectState);
		}
	}, []);

	const startRegisterDevice = useCallback(async () => {
		if (verficationStatus === 'approved') {
			registrationOptions();
		} else {
			let contactInfo = {};

			if (validateEmail(phone)) {
				contactInfo = {
					email: phone,
				};
			} else {
				contactInfo = {
					phone,
					countryCode: country,
				};
			}
			const approvalPayload = {
				type: 'registrationApproval',
				deviceInfo: navigator.userAgent ?? {},
				...contactInfo,
			};
			const response = await registrationApproval(approvalPayload);
			if (response) {
				const { message, success, verificationId } = response;
				if (success && verificationId) {
					setStep('BIOMETRIC_APPROVAL');
					successNotification('Link sent successfully');
					return;
				}
				errorNotification(message);
				return;
			}
		}
	}, [
		country,
		errorNotification,
		phone,
		registrationApproval,
		registrationOptions,
		setStep,
		successNotification,
		verficationStatus,
	]);

	const handalConfimation = useCallback(
		(isOpen: boolean, value: boolean) => {
			setConfirmationOpen(isOpen);
			if (value) {
				startRegisterDevice();
			}
		},
		[setConfirmationOpen, startRegisterDevice]
	);

	const handleAuthenticationSuccess = useCallback(
		async (res: any) => {
			if (res) {
				setWebAuthnCompleted(true);
				const payload = {
					type: 'verifyAuthentication',
					authenticateOptions: res.authenticateOptions ?? {},
					id: res.id ?? '',
				};
				const resp = await verifyAuthentication(payload);
				const { message = '', isAuthenticated, mfaEnabled, refreshToken, userId, token  } = resp ?? {};
				setuserIdbeforeLogin(userId ?? "")
				if (isAuthenticated) {
					setWebAuthnCompleted(true);
					onSuccess({
						message,
						success: isAuthenticated,
						token,
						type: 'webAuth',
						mfaEnabled : mfaEnabled ?? false,
						refreshToken
					});
				} else {
					setWebAuthnCompleted(false);
					setConfirmationOpen(true);
				}
				return;
			}
			onFailed({
				message: MESSAGE.ERROR,
				success: false,
				type: 'webAuth',
			});
		},
		[onFailed, onSuccess, setConfirmationOpen, setuserIdbeforeLogin, verifyAuthentication]
	);

	const handleRegistrastionSuccess = useCallback(
		async (res: any) => {
			if (res) {
				setWebAuthnCompleted(true);
				const payloadSaveCredential = {
					type: 'verifyRegistration',
					registrationOptResponse: res,
					id: optionsResponse?.id,
					verificationId: verificationID,
					deviceInfo: {
						location: locationInfo,
						ip: ipAddress,
					},
				};
				const resp = await verifyRegistration(payloadSaveCredential);
				const { isRegistered, token, mfaEnabled, userId } = resp ?? {};
				if (isRegistered) {
					successNotification(
						mfaEnabled
							? 'Device registered'
							: 'Device registered and logged in successfully.'
					);
					setuserIdbeforeLogin(userId ?? '');
					if (token) {
						setWebAuthnCompleted(true);
						onSuccess({
							message: '',
							success: true,
							token,
							type: 'webAuth',
							mfaEnabled: mfaEnabled ?? false,
						});
						return;
					}
					setWebAuthnCompleted(false);
					// Shahbaaz: Masking number
					let payloadForAuthenticateCredentials = {};
					if (emailRegex.test(phone)) {
						payloadForAuthenticateCredentials = {
							type: 'authenticateOpts',
							email: phone,
							// phone: '',
							// countryCode: '',
						};
					} else {
						payloadForAuthenticateCredentials = {
							type: 'authenticateOpts',
							countryCode: country,
							phone,
						};
					}

					const resAuthenticateCredentials = await authenticateOptions(
						payloadForAuthenticateCredentials
					);
					const { messeage, id } = resAuthenticateCredentials ?? {};
					if (id) {
						authentication(
							handleAuthenticationSuccess,
							resAuthenticateCredentials ?? {}
						);
					} else {
						errorNotification(messeage);
					}
					return;
				}
				setWebAuthnCompleted(false);
				onFailed({
					message: MESSAGE.ERROR,
					success: false,
					type: 'webAuth',
				});
				return;
			}
			onFailed({
				message: MESSAGE.ERROR,
				success: false,
				type: 'webAuth',
			});
		},
		[
			onFailed,
			optionsResponse?.id,
			verificationID,
			locationInfo,
			ipAddress,
			verifyRegistration,
			successNotification,
			setuserIdbeforeLogin,
			phone,
			authenticateOptions,
			onSuccess,
			country,
			authentication,
			handleAuthenticationSuccess,
			errorNotification,
		]
	);

	const handleMobileApp = useCallback(async () => {
		// Shahbaaz: Masking number
		let payload = {};
		if (emailRegex.test(phone)) {
			payload = {
				type: 'maskPhone',
				email: phone,
			};
		} else {
			payload = {
				type: 'phone',
				countryCode: country,
				phone,
			};
		}
		const response = await sendLinkToMobile(payload);
		if (response) {
			const { message, success, verificationId, errorData } = response;
			if (success && verificationId) {
				setSendLinkResponse({ verificationId });
				// setIsWaiting(true)
				setStep('MOBILE_CODE');
				successNotification('Link sent successfully');
				return;
			}
			errorNotification(message ?? errorData.message);
			return;
		}
		// setIsWaiting(true);
	}, [
		country,
		errorNotification,
		phone,
		sendLinkToMobile,
		setSendLinkResponse,
		setStep,
		successNotification,
	]);
	const handleWebAuth = useCallback(async () => {
		if (TEST_USER.includes(phone)) {
			handleMobileApp();
			return;
		}
		if (await getWebAuthnSupported()) {
			if (isBoimetricLoading) return;
			setBoimetricLoading(true);

			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			// Shahbaaz: Masking number
			let payloadForAuthenticateCredentials = {};
			if (emailRegex.test(phone)) {
				payloadForAuthenticateCredentials = {
					type: 'authenticateOpts',
					email: phone,
					// phone: '',
					// countryCode: '',
				};
			} else {
				payloadForAuthenticateCredentials = {
					type: 'authenticateOpts',
					countryCode: country,
					phone,
				};
			}

			const resAuthenticateCredentials = await authenticateOptions(
				payloadForAuthenticateCredentials
			);
			const { id } = resAuthenticateCredentials ?? {};
			if (id) {
				authentication(
					handleAuthenticationSuccess,
					resAuthenticateCredentials ?? {}
				);
			} else {
				setConfirmationOpen(true);
			}
			setBoimetricLoading(false);
		} else {
			setNotSupportedOpen(true);
		}
	}, [
		handleMobileApp,
		isBoimetricLoading,
		phone,
		authenticateOptions,
		country,
		authentication,
		handleAuthenticationSuccess,
		setConfirmationOpen,
		setNotSupportedOpen,
	]);

	const handleOtp = useCallback(() => {
		setSignInActiveStep('otpVerify');
		sendOtp(send_otp_api, {
			phone,
			country,
		});
	}, [setSignInActiveStep, sendOtp, send_otp_api, phone, country]);

	const handleGoBack = useCallback(() => {
		setStep('CREDS');
	}, [setStep]);

	const handleEdit = useCallback(() => {
		setStep('CREDS');
	}, [setStep]);

	const handleAppleLogin = useCallback(() => ({}), []);

	const renderAppleLogin = useMemo(
		() =>
			isMacOrIOS && (
				<AppleLogin
					clientId="com.satschel.stage.secure"
					redirectURI={window.location.href}
					usePopup={true}
					callback={handleAppleLogin} // Catch the response
					scope="email name"
					responseMode="query"
					render={() => (
						//Custom Apple Sign in Button
						<div className={style.list_item} onClick={handleWebAuth}>
							<div className={style.left_icon}>
								<i className="ri-apple-line"></i>
							</div>
							<div className={style.method_title}>SignIn With Apple</div>
							<div className={style.right_icon}>
								<i className="ri-arrow-right-s-line"></i>
							</div>
						</div>
					)}
				/>
			),

		[handleAppleLogin, handleWebAuth]
	);

	const chooseMethodArr = useMemo(
		() => [
			{
				category: 'apple-login',
				componet: renderAppleLogin,
			},
			{
				category: 'approval',
				componet: (
					<div className={style.list_item} onClick={handleMobileApp}>
						<div className={style.left_icon}>
							<i className="ri-smartphone-line"></i>
						</div>
						<div className={style.method_title}>Approval via mobile</div>
						<div className={style.right_icon}>
							<i className="ri-arrow-right-s-line"></i>
						</div>
					</div>
				),
			},
			{
				category: 'biometric',
				componet: (
					<div
						className={`${style.list_item} ${
							isBoimetricLoading ? style.list_item_disabled : ''
						}`}
						onClick={handleWebAuth}
					>
						<div className={style.left_icon}>
							<i className="ri-fingerprint-line"></i>
						</div>
						<div className={style.method_title}>Device Biometric</div>
						<div className={style.right_icon}>
							{isBoimetricLoading ? (
								<Loader type={'loader'} dimension={24} />
							) : (
								<i className="ri-arrow-right-s-line"></i>
							)}
						</div>
					</div>
				),
			},
			{
				category: 'otp',
				componet: (
					<div className={style.list_item} onClick={handleOtp}>
						<div className={style.left_icon}>
							<i className="ri-message-line"></i>
						</div>
						<div className={style.method_title}>
							Mobile Text: One-time password
						</div>
						<div className={style.right_icon}>
							<i className="ri-arrow-right-s-line"></i>
						</div>
					</div>
				),
			},
		],
		[
			handleMobileApp,
			handleOtp,
			handleWebAuth,
			isBoimetricLoading,
			renderAppleLogin,
		]
	);

	const renderSelectMethod = useMemo(() => {
		const arr = chooseMethodArr.filter(item =>
			methods?.split('|').includes(item.category)
		);
		return arr.map(item => (
			<Fragment key={item.category}>{item.componet}</Fragment>
		));
	}, [methods, chooseMethodArr]);

	useEffect(() => {
		if (optionsResponse?.registrationOptions && count === 0) {
			registerNewCredential(
				handleRegistrastionSuccess,
				optionsResponse?.registrationOptions ?? {}
			);
			count++;
		}
		if (
			verficationStatus === 'approved' &&
			optionsResponse?.registrationOptions
		) {
			registerNewCredential(
				handleRegistrastionSuccess,
				optionsResponse?.registrationOptions ?? {}
			);
		}
		// eslint-disable-next-line
	}, [optionsResponse]);

	const renderItem = useMemo(
		() => (
			<div className={style.wrapper}>
				<div className={style.inner_wrapper}>
					<div className={style.inner}>
						<div>
							<div className={style.title}>Choose Method</div>
							<div className={style.sub_title}>
								Select the method through which you want sign in.
							</div>
						</div>
						<div className={style.edit}>
							We will send you a confirmation code :{' '}
							<div>
								{country} {maskPhoneCreds}{' '}
								<span className={style.edit_Btn} onClick={handleEdit}>
									Edit
								</span>
							</div>
						</div>
						<div className={style.lists}>{renderSelectMethod}</div>
						<Button
							type="button__filled--secondary button__large button__block"
							label={'Back'}
							handleClick={handleGoBack}
						/>
					</div>
				</div>
			</div>
		),
		[country, handleEdit, handleGoBack, maskPhoneCreds, renderSelectMethod]
	);

	const handalNotSupported = () => {
		setNotSupportedOpen(false);
	};
	return (
		<>
			{renderItem}
			<div className="popup_wrapper">
				<Confirmation
					title={'Looks like this browser is not registered!'}
					visible={isConfirmationOpen}
					description={'Please continue to register this browser.'}
					handleModal={handalConfimation}
					boldDescription=""
					label={'Continue'}
					type="primary"
				/>
				<Nosupported
					title={'Look like your browser doesn’t support WebAuthn'}
					visible={isNotSupportedOpen}
					description={'Your browser is does not support WebAuthn.'}
					handleModal={handalNotSupported}
					boldDescription=""
					label={'Close'}
					type="Secondary"
				/>
				{/* Pradeep chaurasia : Screen loader added between webauthn completion and redirect dashboard */}
				<ScreenLoader visible={isWebAuthnCompleted} />
			</div>
		</>
	);
};
