import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Button, Image } from '@storybook';
import {
	AllowedInvitesState,
	BusinessRegistrationState,
	IAllowedInvites,
} from 'global-stores';
import { useAllowedInvites, useFreePlan } from 'hooks';
import { ApprovalStatus } from 'views/user-identity-flow';
import { REMAINING_SERVICES } from '../../constants';
import { WelcomeNewUserModalState } from '../../stores';
import './credits-remain.scss';

interface IFreeCreditsRemain {
	title: string;
	subTitle: string;
	handleCaptableClose: () => void;
	setIsUserIdentityOpen: (open: boolean) => void;
}

export const FreeCreditsRemain: FC<IFreeCreditsRemain> = ({
	title,
	subTitle,
	handleCaptableClose,
	setIsUserIdentityOpen,
}) => {
	// globel state
	const setWelcomeNewUser = useSetRecoilState(WelcomeNewUserModalState);
	const businessRegistrationState = useRecoilValue(BusinessRegistrationState);
	const allowedInvites = useRecoilValue(AllowedInvitesState);

	// hooks
	const { totalSessions, totalSimpliciDocs } = useAllowedInvites();
	const navigate = useNavigate();
	const { haveKybKyc = false, hasSubscribedPerson } = useFreePlan();

	const {
		onboarding: ONBOARDING,
		simpliciSign: SIMPLICI_SIGN,
	}: IAllowedInvites = allowedInvites ?? {};

	const handleSubscribe = useCallback(() => {
		handleCaptableClose();
		const finalXlsValue: any = window;
		finalXlsValue?.luckysheet?.exitEditMode();

		setWelcomeNewUser(pre => ({ ...pre, open: false }));
		if (businessRegistrationState.approveStatus === ApprovalStatus.Pending) {
			setIsUserIdentityOpen(true);
		} else navigate('/billing');

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setIsUserIdentityOpen, businessRegistrationState]);

	const remains: { [key: string]: number } = useMemo(() => {
		const onboarding =
			totalSessions < ONBOARDING ? ONBOARDING - totalSessions : 0;
		const simpliciSign =
			totalSimpliciDocs < SIMPLICI_SIGN ? SIMPLICI_SIGN - totalSimpliciDocs : 0;
		return { onboarding, simpliciSign };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [totalSessions, totalSimpliciDocs]);

	// @shivangi i have been  changed the label in 5 invites onboarding modal//
	const renderRemainServices = useMemo(() => {
		if (!haveKybKyc) return <></>;
		return (REMAINING_SERVICES ?? []).map(({ key, title }) =>
			hasSubscribedPerson && key === 'simpliciSign' ? (
				<></>
			) : (
				<div
					key={key}
					className={`free-credits-remains-wrapper__info-content__${key}`}
				>
					<div className="free-credits-remains-wrapper__info-content__description">
						<div className="free-credits-remains-wrapper__info-content__description__title">
							{title}
						</div>
						<div className="free-credits-remains-wrapper__info-content__description__remains">
							{remains[key]} invites remaining
						</div>
					</div>
					<progress
						value={remains[key]}
						max={allowedInvites?.[key as 'onboarding'] ?? 0}
						className="free-credits-remains-wrapper__info-content__loading-bar"
					/>
				</div>
			)
		);
	}, [haveKybKyc, hasSubscribedPerson, remains, allowedInvites]);

	return (
		<div className="free-credits-remains-wrapper">
			<div className="free-credits-remains-wrapper__info-content__notification-icon">
				<Image
					fileName="notification-bell.svg"
					className="free-credits-remains-wrapper__info-content__notification-icon__image"
				/>
			</div>
			<div className="free-credits-remains-wrapper__info-header">
				<div className="free-credits-remains-wrapper__info-header__title">
					{title}
				</div>
				<div className="free-credits-remains-wrapper__info-header__sub-title">
					{subTitle}
				</div>
			</div>
			<div className="free-credits-remains-wrapper__info-content">
				{renderRemainServices}
			</div>
			<div className="free-credits-remains-wrapper__subscribe-wrapper">
				<Button
					label="Subscribe"
					handleClick={handleSubscribe}
					type={`button__filled button__filled--primary button__large button-ok`}
				/>
			</div>
		</div>
	);
};
