import { API_URL } from 'constant';
import {
	BusinessRegistrationState,
	IBusinessRegistrationState,
} from 'global-stores';
import { useNetwork } from 'hooks';
import { useState } from 'react';

import { useSetRecoilState } from 'recoil';
import { SignUpSessionStatusState } from 'views/new-user-popup';
import { ISessionDetails } from 'views/new-user-popup/stores/types';

export const useCompliance = () => {
	const setSessionDetails = useSetRecoilState(SignUpSessionStatusState);
	const setBusinessRegistration = useSetRecoilState(BusinessRegistrationState);
	const [isSessionLoading, setIsSessionLoading] = useState(false);

	// hook
	const { get } = useNetwork({
		updateState: false,
		returnResponse: true,
	});
	const { get: getSessionData } = useNetwork({ updateState: false });

	const updateBusinessRegistrationData = (result: ISessionDetails) => {
		const {
			sessionDetails = [],
			approveStatus,
			accountType,
			physicalVerificationStatus,
		} = result ?? {};
		const sessionResult = sessionDetails.find(el => el.type === accountType);
		const { sessionData } = sessionResult ?? {};
		const { kyb = [], kyc = [], kybForm = [], aml = [] } = sessionData ?? {};

		const kybData = structuredClone(kyb?.[0]?.data?.[0]);
		const kycData = structuredClone(kyc?.[0]?.data);
		const kybFormData = structuredClone(kybForm?.[0]);
		const amlData = structuredClone(aml?.[0]?.data);

		if (kycData) {
			kycData['success'] = true;
		}
		if (kybData) {
			kybData['success'] = true;
		}

		setBusinessRegistration((prev: IBusinessRegistrationState) => ({
			...prev,
			approveStatus,
			accountType,
			kyc: kycData,
			kyb: kybData,
			physicalVerificationStatus,
			aml: amlData,
			kybForm: kybFormData,
		}));
	};

	const getComplianceData = async (accessToken?: string) => {
		let configHeader = undefined;
		if (accessToken)
			configHeader = {
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			};
		const resp = await get(API_URL.BUSINESS_COMPLIANCE, configHeader);
		if (resp?.response?.status === 200) {
			const { data = [] } = resp?.apiPayload ?? {};
			const result = data?.[0];
			updateBusinessRegistrationData(result);
			setSessionDetails(result ?? []);
		}

		if (resp?.errorCode === 400) {
			setBusinessRegistration((prev: IBusinessRegistrationState) => ({
				...prev,
				isViewShow: true,
			}));
		}
	};

	const getComplianceSessionStatus = async (activeIdx?: string, reInitiateState?: boolean) => {
		let url = `${API_URL.BUSINESS_COMPLIANCE}?check=true`;
		if (activeIdx) {
			url = `${API_URL.BUSINESS_COMPLIANCE}?check=true&accountType=${activeIdx}&reInitiate=${reInitiateState ?? false}`;
		}
		const resp = await get(url);
		if (resp?.response?.status === 200) {
			const { data = [] } = resp?.apiPayload ?? {};
			const result = data?.[0];
			setSessionDetails(result ?? []);
			return result;
		}
	};

	const fetchSessionDeatil = async (sessionId: string) => {
		setIsSessionLoading(true);
		const resp = await getSessionData(
			`${API_URL.COMPLEX_SESSION_DETAILS}/${sessionId}?include=true`
		);

		if (resp.message === 'ok') {
			const { kyb = [], kyc = [] } = resp.data ?? {};

			const kybData = structuredClone(kyb[0]?.data?.[0]);
			const kycData = structuredClone(kyc[0]?.data);
			if (kycData) {
				kycData['success'] = true;
				setBusinessRegistration((prev: IBusinessRegistrationState) => ({
					...prev,
					kyc: kycData,
				}));
			}
			if (kybData) {
				kybData['success'] = true;
				setBusinessRegistration((prev: IBusinessRegistrationState) => ({
					...prev,
					kyb: kybData,
				}));
			}
		}
		setIsSessionLoading(false);
	};

	return {
		getComplianceData,
		getComplianceSessionStatus,
		updateBusinessRegistrationData,
		fetchSessionDeatil,
		isSessionLoading,
	};
};
