/* eslint-disable react/no-array-index-key */
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';

import { ReactDropdown, ReactSwitch, SketchColorPicker } from '@storybook';

import { WebColorProperties } from 'global-stores/integrate/color-picker';
import {
	RightSideKey,
	RightSideWebProperties,
	ComponentType,
} from '../constant';
import {
	ActiveTabsIntegrateState,
	ClientsCredentialsState,
	SelectedDeveloperKeyState,
	SelectedTabState,
} from 'global-stores';

import './integrate-modify-options.scss';

export const IntegrateModifyOptions = () => {
	const [webColorProperties, setWebColorProperties] =
		useRecoilState(WebColorProperties);
	const developerKeys = useRecoilValue(ClientsCredentialsState);
	const resetWebColorProperties = useResetRecoilState(WebColorProperties);
	const activeTabIntegrate = useRecoilValue(ActiveTabsIntegrateState);
	const selectedTab = useRecoilValue(SelectedTabState);
	const setSelectedDeveloperKey = useSetRecoilState(SelectedDeveloperKeyState);
	const [data, setData] = useState();
	const [currentIndex, setCurrentIndex] = useState(-1);

	const { INPUT, SELECT, TOGGLE } = ComponentType;

	const developerOptions = useMemo(() => {
		return developerKeys.map(({ _id: value, name: label }) => ({
			label,
			value,
		}));
	}, [developerKeys]);

	useEffect(() => {
		if (data) {
			setSelectedDeveloperKey(data);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const handleChangeDeveloperKeys = useCallback((value: any) => {
		setData(value);
	}, []);

	const showColorPicker = useCallback((index: number) => {
		setCurrentIndex(index);
	}, []);

	const handleOnChangeColor = useCallback(
		(value: string | boolean, name: string) => {
			setWebColorProperties({ ...webColorProperties, [name]: value });
		},
		[setWebColorProperties, webColorProperties]
	);

	const closeOutside = useCallback(() => {
		showColorPicker(-1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

	const handleReset = () => {
		resetWebColorProperties();
	};

	const renderSettings = useCallback(
		(label: string, name: string, type: string, index: number) => {
			switch (type) {
				case INPUT:
					return (
						<div style={{ position: 'relative' }}>
							<div className="Integrate__input-text">{label}</div>
							<div
								className="Integrate__background-input"
								onClick={() => showColorPicker(index)}
							>
								<div
									className="Integrate__display-color"
									style={{ backgroundColor: webColorProperties[name] }}
								/>
								<div style={{ textTransform: 'uppercase' }}>
									{webColorProperties[name]}
								</div>
							</div>
							{index === currentIndex && (
								<div style={{ position: 'absolute', zIndex: 2 }}>
									<SketchColorPicker
										currentColor={webColorProperties[name]}
										handleOnChange={(e: any) =>
											handleOnChangeColor(e.hex, name)
										}
										closeOutside={closeOutside}
									/>
								</div>
							)}
						</div>
					);
				case TOGGLE:
					return (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								marginBottom: 16,
							}}
						>
							<div className="Integrate__input-text">{label}</div>
							<div className={isSafari ? 'integrate__switch-wrapper--safari' : ''} style={{ zIndex: 1 }}>
								<ReactSwitch
									checked={webColorProperties[name] as boolean}
									handleChange={value => handleOnChangeColor(value, name)}
									id=""
									onColor="#33b87a"
									className="Integrate__input-switch"
								></ReactSwitch>
							</div>
						</div>
					);
				case SELECT:
					return (
						<div className="Integrate__select-wrapper">
							<div className="Integrate__select-label">{label}</div>
							{name === RightSideKey.DEVELOPER_KEY && (
								<ReactDropdown
									controlStyle={{
										minHeight: 40,
										height: 40,
										borderRadius: 4,
										backgroundColor: '#fff',
										'&:hover': {
											borderColor: 'var(--color-primary-light)',
											cursor: 'pointer',
										},
									}}
									optionsStyle={{
										'&:hover': { background: 'var(--color-hover-light-100)' },
										height: 40,
										minHeight: 40,
									}}
									handleChangeSelect={handleChangeDeveloperKeys}
									options={developerOptions}
									value={data}
									defaultValue={developerOptions[0]}
								/>
							)}
						</div>
					);
				default:
					return null;
			}
		},

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			INPUT,
			SELECT,
			TOGGLE,
			currentIndex,
			data,
			developerOptions,
			webColorProperties,
		]
	);

	const getComponent = useMemo(
		() =>
			RightSideWebProperties.map(({ label, name, type }, index) => {
				if (
					(activeTabIntegrate === 'iframe' && name === 'sidebar') ||
					(selectedTab === 'onboarding/flow' &&
						(name === 'sidebar' ||
							name === 'isSearch' ||
							name === 'tableHeadColor'))
				) {
					return <div key={index}></div>;
				}

				return (
					<Fragment key={index}>
						{renderSettings(label, name, type, index)}
					</Fragment>
				);
			}),
		[activeTabIntegrate, renderSettings, selectedTab]
	);

	return (
		<div className="Integrate__right-options">
			<div className="Integrate__reset">
				<div className="Integrate__reset-btn" onClick={handleReset}>
					Reset
				</div>
			</div>
			{getComponent}
		</div>
	);
};
