import { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { UserRoles, UserRolesState } from 'global-stores';

export const ReadWritePermission = ({
	userRoles,
}: {
	userRoles?: UserRoles;
}) => {
	const [isAllPermissionShow, setAllPermissionShow] = useState(false);
	const userRoleList = useRecoilValue(UserRolesState);

	const getAllPermissionTag = useCallback(
		(role: string) => {
			const allValues =
				(userRoleList ?? [])?.find(el => el.name === role)?.permissions ?? {};
			const allval = [];

			for (const key in allValues) {
				const readPermission = allValues[key]?.read;
				const writePermission = allValues[key]?.write;

				if (readPermission) {
					allval.push(`${key} read`);
				}
				if (writePermission) {
					allval.push(`${key} write`);
				}
			}

			return allval;
		},
		[userRoleList]
	);

	const showPermission = () => {
		setAllPermissionShow(prev => !prev);
	};

	const getAllPermissionsList = useCallback(
		(role: string) => {
			const havePermission = getAllPermissionTag(role)?.length;
			if (havePermission === 0) return <></>;
			if (havePermission < 3)
				return (
					<>
						{getAllPermissionTag(role)?.map((role, index) => (
							<div
								className="ReadWritePermission__colorPurple"
								key={index?.toString()}
							>
								{role}
							</div>
						))}
					</>
				);
			if (havePermission >= 3) {
				return (
					<>
						{isAllPermissionShow
							? getAllPermissionTag(role)?.map((role, index) => (
									<div
										className="ReadWritePermission--label"
										key={index?.toString()}
									>
										{role}
									</div>
							  ))
							: getAllPermissionTag(role)
									?.filter((_, index) => index < 2)
									?.map((role, index) => (
										<div
											className="ReadWritePermission--label"
											key={index?.toString()}
										>
											{role}
										</div>
									))}

						<>
							{!isAllPermissionShow ? (
								<span
									className="ReadWritePermission--numaric"
									onClick={showPermission}
								>
									+{havePermission - 2} <i className="ri-arrow-down-s-line" />
								</span>
							) : (
								<span
									className="ReadWritePermission--numaric"
									onClick={showPermission}
								>
									show less
								</span>
							)}
						</>
					</>
				);
			}
			return <></>;
		},
		[getAllPermissionTag, isAllPermissionShow]
	);

	return (
		<div className="ReadWritePermission">
			{userRoles?.fullAccess ? (
				<div className="ReadWritePermission__allAccess">All access</div>
			) : (
				getAllPermissionsList(userRoles?.name ?? '')
			)}
		</div>
	);
};
