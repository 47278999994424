import classNames from 'classnames';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { DatePicker } from 'components';
import { getMinutesTime } from 'helpers';
import {
	NodeTitleCompareFunnelChartState,
	NodeTitleFunnelChartState,
	analyticsChartHeaderState,
	analyticsChartTwoHeaderStart,
	comparedFunnelChartsState,
} from 'views/analytics/hooks';
import { changeHeaderDefaultRanges, chartHeaderDateRange } from './constant';
import './chart-header.scss';

interface IChartHeader {
	onSubmit?: any;
	chartType?: string;
	averageTime?: string | number;
}

export const ChartHeader: FC<IChartHeader> = ({
	onSubmit,
	chartType,
	averageTime,
}) => {
	const [active, setActive] = useState<string>(
		chartType === 'chart1'
			? chartHeaderDateRange.oneMonth
			: chartHeaderDateRange.oneWeek
	);

	const setDatesTwo = useSetRecoilState(analyticsChartTwoHeaderStart);
	const setDates = useSetRecoilState(analyticsChartHeaderState);
	const comparedChart = useRecoilValue(comparedFunnelChartsState);
	const nodeTitle = useRecoilValue(
		chartType === 'chart1'
			? NodeTitleFunnelChartState
			: NodeTitleCompareFunnelChartState
	);
	const resetNodeTitle = useResetRecoilState(
		chartType === 'chart1'
			? NodeTitleFunnelChartState
			: NodeTitleCompareFunnelChartState
	);

	useEffect(() => {
		if (onSubmit) onSubmit();
	}, [onSubmit]);

	const handleDates = useCallback(
		(value: string) => {
			const data = changeHeaderDefaultRanges[value];
			if (chartType === 'chart2' && value !== 'custom') setDatesTwo([data]);
			if (chartType === 'chart1' && value !== 'custom') setDates([data]);
			if (chartType === 'chart1' && value === 'custom') setActive('');
			setActive(value);
			resetNodeTitle();
		},
		[chartType, resetNodeTitle, setDates, setDatesTwo]
	);

	const datePickerClass = useMemo(() => {
		return classNames('chart-child-custom', {
			'chart-header-date-picker': comparedChart,
		});
	}, [comparedChart]);

	const activeClass = useCallback(
		(chartHeaderDateRange: string) => {
			return classNames('chart-child-div', {
				'chart-active': active === chartHeaderDateRange,
			});
		},
		[active]
	);

	return (
		<>
			<div className="chart-header-wrapper">
				<div className="chart-header-container">
					<div
						className={activeClass(chartHeaderDateRange.oneWeek)}
						onClick={() => handleDates(chartHeaderDateRange.oneWeek)}
					>
						{chartHeaderDateRange.oneWeek}
					</div>
					<div
						className={activeClass(chartHeaderDateRange.oneMonth)}
						onClick={() => handleDates(chartHeaderDateRange.oneMonth)}
					>
						{chartHeaderDateRange.oneMonth}
					</div>
					<div
						className={activeClass(chartHeaderDateRange.threeMonth)}
						onClick={() => handleDates(chartHeaderDateRange.threeMonth)}
					>
						{chartHeaderDateRange.threeMonth}
					</div>
					<div
						className={activeClass(chartHeaderDateRange.oneYear)}
						onClick={() => handleDates(chartHeaderDateRange.oneYear)}
					>
						{chartHeaderDateRange.oneYear}
					</div>
					<div
						className={activeClass(chartHeaderDateRange.allTime)}
						onClick={() => handleDates(chartHeaderDateRange.allTime)}
					>
						{chartHeaderDateRange.allTime}
					</div>
					<div className={datePickerClass}>
						<DatePicker
							onSubmit={() => handleDates(chartHeaderDateRange.custom)}
							stateType={
								chartType === 'chart2'
									? analyticsChartTwoHeaderStart
									: analyticsChartHeaderState
							}
						/>
					</div>
				</div>
			</div>
			<div className="chart-heading">
				<span>
					Average time to Successful Completion{' '}
					{!!nodeTitle && `for ${nodeTitle}`}
				</span>
				<b className="chart-time">{getMinutesTime(averageTime ?? '')}</b>
				<span className="chart-heading_reset" onClick={() => resetNodeTitle()}>
					{!!nodeTitle && 'Reset'}
				</span>
			</div>
		</>
	);
};
