import { useCallback } from 'react';

import { ObjectStoreMeta } from './types';
import { createTransaction, optionsGenerator } from './utils';

// eslint-disable-next-line no-shadow
enum DBMode {
	'READONLY' = 'readonly',
	'READWRITE' = 'readwrite',
}

const indexedDB =
	window.indexedDB ||
	(window as any).mozIndexedDB ||
	(window as any).webkitIndexedDB ||
	(window as any).msIndexedDB ||
	(window as any).shimIndexedDB;

// create object store
export const createObjectStore = (
	dbName: string,
	version: number,
	storeSchemas: ObjectStoreMeta[]
) => {
	if (!indexedDB) {
		return;
	}
	const request = indexedDB.open(dbName, version);
	request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
		const db: IDBDatabase = (event.target as any).result;
		storeSchemas?.forEach?.(storeSchema => {
			const { store, storeConfig, storeSchema: dbStoreSchemas } = storeSchema;
			if (!db.objectStoreNames.contains(storeSchema.store)) {
				const objectStore = db.createObjectStore(store, storeConfig);
				dbStoreSchemas?.forEach(dbStoreSchema => {
					const { name, keypath, options } = dbStoreSchema;
					objectStore.createIndex(name, keypath, options);
				});
			}
		});
	};

	request.onsuccess = (e: any) => {
		e.target.result.close();
	};
};

export const useIndexedDBInstance = (dbName: string, version: number) => {
	// open database
	const openDatabase = useCallback(() => {
		return new Promise<IDBDatabase>((resolve, reject) => {
			const request = indexedDB.open(dbName, version);

			let db;
			request.onsuccess = (event: Event) => {
				db = (event.target as any).result;
				resolve(db);
			};

			request.onerror = () => {
				reject(new Error(`IndexedDB error: ${request.error}`));
			};
		});
	}, [dbName, version]);

	const createDatabaseTransaction = useCallback(
		(
			database: IDBDatabase,
			mode: DBMode,
			storeName: string,
			resolve: () => void,
			reject: () => void,
			createTransactionInstance: typeof createTransaction = createTransaction,
			buildOptions: typeof optionsGenerator = optionsGenerator
		) => {
			const options = buildOptions(mode, storeName, reject, resolve);
			const transaction: IDBTransaction = createTransactionInstance(
				database,
				options
			);
			const store = transaction.objectStore(storeName);

			return {
				store,
				transaction,
			};
		},
		[]
	);

	// create database transaction
	const createReadonlyDatabaseTransaction = useCallback(
		(
			db: IDBDatabase,
			store: string,
			resolve: (e?: any) => void,
			reject: () => void
		) => {
			return createDatabaseTransaction(
				db,
				DBMode.READONLY,
				store,
				resolve,
				reject
			);
		},
		[createDatabaseTransaction]
	);

	// create database transaction
	const createReadWriteDatabaseTransaction = useCallback(
		(
			db: IDBDatabase,
			store: string,
			resolve: () => void,
			reject: () => void
		) => {
			return createDatabaseTransaction(
				db,
				DBMode.READWRITE,
				store,
				resolve,
				reject
			);
		},
		[createDatabaseTransaction]
	);

	return {
		openDatabase,
		createObjectStore,
		createReadonlyDatabaseTransaction,
		createReadWriteDatabaseTransaction,
	};
};
