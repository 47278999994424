import {
	Calendar,
	DayValue,
} from '@hassanmojab/react-modern-calendar-datepicker';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import { useOutsideClick } from 'hooks';
import React, {
	ChangeEvent,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import InputMask from 'react-input-mask';
import './react-date-picker.scss';

const currentDate = new Date();
const currentDay = currentDate.getDate();
const currentMonth = currentDate.getMonth() + 1; // Months are zero-based, so January is 0
const currentYear = currentDate.getFullYear();
interface IDateCalender {
	optionalClassName?: string;
	value?: DayValue | any;
	onChange?: any;
	errorMessage?: string;
	isError?: boolean;
	label: string;
	isRequired?: boolean;
	id?: string;
	formate?: string;
	isModal?: boolean;

	/**
	 * Optional flag to indicate if the current date should be the maximum selectable date.
	 * When true, users cannot select dates beyond today.
	 */
	isCurrentDateMax?: boolean;

	/**
	 * Optional object representing the minimum selectable date.
	 * Consists of year, month, and day properties.
	 * If no minimum date is required, it can be null.
	 */
	minDate?: {
		year: number; // The minimum selectable year
		month: number; // The minimum selectable month (1-12)
		day: number; // The minimum selectable day (1-31)
	} | null;

	/**
	 * Optional flag to control the visibility of a calendar icon in the input field.
	 * When true, an icon will be shown to the user.
	 */
	showIcon?: boolean;

	/**
	 * Optional flag to disable manual input in the date field.
	 * When true, users can only select dates using the calendar picker.
	 */
	inputDisabled?: boolean;

	/**
	 * Optional array of custom date options to display alongside the calendar.
	 * Each option includes a unique key, a label for display, and a value representing the offset in days.
	 */
	customOptions?: {
		key: string; // Unique identifier for the custom date option
		label: string; // User-friendly label to display for the custom date option
		value: number; // The number of days to add to the current date for this option
	}[];
}

export const DateCalender: React.FC<IDateCalender> = ({
	value,
	onChange,
	isError = false,
	errorMessage,
	label,
	isRequired = false,
	id,
	formate = 'MM/DD/YYYY',
	isModal = false,
	isCurrentDateMax = true,
	minDate,
	showIcon,
	inputDisabled,
	customOptions,
}) => {
	const [showCalendar, setShowCalendar] = useState<boolean>(false);
	const [inputValue, setInputValue] = useState<string>('');
	const wrapperRef = useRef<HTMLDivElement>(null);

	const formatDate = ({
		year,
		month,
		day,
	}: {
		day: number;
		month: number;
		year: number;
	}) => {
		return `${month.toString().padStart(2, '0')}/${day
			.toString()
			.padStart(2, '0')}/${year}`;
	};

	const onCalenderChange = (day: DayValue) => {
		if (day) {
			setInputValue(formatDate(day));
			const formattedDate = {
				target: {
					value: day,
				},
			};
			onChange(formattedDate);
		}
		setShowCalendar(false);
	};

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		// Remove any non-numeric characters
		// Update the input value
		const [month, day, year] = value.split('/');
		setInputValue(value);
		let newDate;
		if (day && month && year) {
			newDate = {
				day: +day,
				month: +month,
				year: +year,
			};
		}
		const formattedDate = {
			target: {
				value: newDate,
			},
		};
		onChange(formattedDate);
	};

	useOutsideClick(wrapperRef, () => {
		setShowCalendar(false);
	});

	useEffect(() => {
		if (value) setInputValue(formatDate(value));
		else setInputValue('');
	}, [value]);

	const getCustomDateValue = useCallback((value: number) => {
		// Create a new date by adding the specified number of days (value) to the current date
		const customDate = new Date(
			currentDate.getTime() + value * 24 * 60 * 60 * 1000
		); // Calculates the next date based on the 'value' in days

		const customDay = customDate.getDate(); // Extracts the day from the custom date
		const customMonth = customDate.getMonth() + 1; // Extracts the month (zero-based, so add 1)
		const customYear = customDate.getFullYear(); // Extracts the year

		// Return the custom date as an object containing the year, month, and day
		return {
			year: customYear,
			month: customMonth,
			day: customDay,
		};
	}, []);

	const mapCustomDays = useMemo(
		() =>
			// Check if 'customOptions' is an array and has at least one item
			Array.isArray(customOptions) &&
			customOptions.length && (
				<div className="DateCalender__custom-dates">
					{/* Iterate through each option in 'customOptions' to render a custom date item */}
					{customOptions.map(({ key, label, value }) => (
						<div
							key={key} // Use 'key' to uniquely identify each custom date item
							className="DateCalender__custom-date"
							onClick={() => onCalenderChange(getCustomDateValue(value))}
							// When a custom date is clicked, calculate its value using 'getCustomDateValue'
							// and trigger the 'onCalenderChange' function to update the selected date
						>
							{label}
							{/* Display the label (e.g., "Tomorrow", "After 7 days") */}
						</div>
					))}
				</div>
			),
		// Dependencies for useMemo to ensure it re-renders only when these values change
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[customOptions, getCustomDateValue]
	);

	return (
		<div ref={wrapperRef}>
			{label && (
				<label htmlFor={id} className="input__label">
					{label}
					{isRequired && <span className="input__isRequired"> *</span>}
				</label>
			)}
			<div
				onClick={() => setShowCalendar(!showCalendar)}
				className={`react-date-picker__container`}
			>
				<InputMask
					mask="99/99/9999"
					placeholder={formate}
					value={inputValue}
					onChange={handleInputChange}
					className={`react-date-picker ${
						isError ? 'active-error-message' : ''
					}`}
					readOnly={inputDisabled}
				/>
				{showIcon && (
					<i className={`ri-calendar-line react-date-picker__calendar-icon`} />
				)}
			</div>

			{showCalendar && (
				<div
					className={isModal ? 'CalendarDropdownModal--container nowheel' : ''}
				>
					<div className="DateCalender__dropper-wrapper">
						{/* Calendar component that shows the date picker */}
						<Calendar
							value={value} // The currently selected date
							onChange={onCalenderChange} // Function to handle date selection changes
							shouldHighlightWeekends // Highlights weekends in the calendar for better visibility
							{...(isCurrentDateMax && {
								// If 'isCurrentDateMax' is true, set the maximum selectable date to the current date
								maximumDate: {
									year: currentYear,
									month: currentMonth,
									day: currentDay,
								},
							})}
							{...(minDate && {
								// If 'minDate' is provided, set the minimum selectable date
								minimumDate: minDate,
							})}
						/>
						{/* Renders the custom date options next to the calendar */}
						{mapCustomDays}
					</div>
				</div>
			)}
			{isError && <span className="error-message">{errorMessage}</span>}
		</div>
	);
};
