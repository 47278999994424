import { atom } from 'recoil';

import type {
	ICredential,
	IOptionsRow,
	ISelectedSession,
	ISelectedItem,
	ICcEmail,
	ISessionType,
} from './type';
export const SessionState = atom<ICredential[]>({
	key: 'session-key',
	default: [],
});

export const ComplexSessionState = atom<ICredential[]>({
	key: 'complex-session-key',
	default: [],
});

export const ComplexSessionNodesState = atom<null | any>({
	key: 'complex-session-nodes-list-design-key',
	default: null,
});

export const ComplexSelectedSession = atom<null | any>({
	key: 'complex-selected-session-key',
	default: null,
});

export const ComplexSessionsNodesDetailsState = atom<any>({
	key: 'complex-session-nodes-details-state',
	default: {},
});

export const ComplexSessionsNodesKybOfacDetailsState = atom<any>({
	key: 'complex-session-nodes-kyb-ofac-details-state',
	default: {},
});

// State for Kyc comperhensive details in session details 
export const ComplexSessionsNodesKycComperhensiveDetailsState = atom<any>({
    key: 'complex-session-nodes-kyc-Comperhensive-details-state',
    default: {},
});

export const ComplexSessionsNodesLoadingState = atom({
	key: 'complex-session-nodes-loading-state-key',
	default: {},
});

export const SessionTableRow = atom<IOptionsRow[]>({
	key: 'session-tables',
	default: [],
});

export const SelectedSessionState = atom<
	ISelectedSession | Record<string, never>
>({
	key: 'selected-session',
	default: {},
});

export const ComplexSessionInfoState = atom<any>({
	key: 'complex-session-info',
	default: {},
});

export const SessionItemSelected = atom<ISelectedItem>({
	key: 'selected-item',
	default: {},
});
export const commentText = atom<string>({
	key: 'comment-text',
	default: '',
});

export const checkBoxStateInTable = atom<boolean>({
	key: 'check-Box-State-In-Table',
	default: false,
});

export const ConditionalSessionFlowState = atom<boolean>({
	key: 'sessional-check-Box-State-In-Table',
	default: false,
});

export const CcEmailState = atom<ICcEmail[]>({
	key: 'sessions-cc-email',
	default: [],
});

export const CcEmailInputState = atom<ICcEmail>({
	key: 'sessions-cc-input-email',
	default: { id: '', text: '' },
});

export const ccEmailState = atom<any>({
	key: 'cc-email',
	default: [],
});

export const BCcEmailState = atom<ICcEmail[]>({
	key: 'sessions-bcc-email',
	default: [],
});

export const BccEmailInputState = atom<ICcEmail>({
	key: 'sessions-bcc-input-email',
	default: { id: '', text: '' },
});

export const FilterTableIdState = atom<string[]>({
	key: 'filter-table-id-state',
	default: [],
});

export const SelectedSessionsIdState = atom<string[]>({
	key: 'selected-session-table-id-state',
	default: [],
});

export const SelectedSessionsIdsForZipState = atom<string[]>({
	key: 'selected-session-table-ids-for-zip-state',
	default: [],
});

export const FilterSessionTypeTableState = atom<ISessionType>({
	key: 'Filter-Session-Type-Table-State',
	default: {
		label: 'Active',
		value: 'active_session',
	},
});
