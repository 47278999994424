import { FC, useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';

import { IconBtn, Image } from '@storybook';
import DropDown from './Dropdown';
import { DocumentsUploaded } from '../store';
import { useNotification } from 'hooks';
import { FileTypes } from './constants';

interface IUploadedFiles {
	type: string;
	data: any[];
}

interface IData {
	heading: string;
	description: string;
}

interface IHeadings {
	[key: string]: IData;
}

const headings: IHeadings | any = {
	previously_uploaded: {
		heading: 'Previously Uploaded',
		description: '',
	},
	documents_uploaded: {
		heading: 'Documents Uploaded',
		description:
			'Please select the type of the documents from their respective drop down and provide the password if they have any.',
	},
};

export const UploadedFiles: FC<IUploadedFiles> = ({ type, data }) => {
	const [addedFiles, setAddedFiles] = useRecoilState(DocumentsUploaded);
	const { successNotification } = useNotification();

	const handleDeleteClick = useCallback(
		(selectedItem: any) => {
			if (type === 'previously_uploaded') {
				window.open(selectedItem.url, '_blank');
				return;
			}
			const data = addedFiles.data;
			const filteredItems = data.filter(function (item) {
				return item.name !== selectedItem.name;
			});
			setAddedFiles(prev => {
				return {
					...prev,
					data: filteredItems,
				};
			});
			successNotification('File Deleted Successfully');
		},
		[addedFiles.data, setAddedFiles, successNotification, type]
	);

	const handlePassword = useCallback(
		(name: string, index: number, value: string) => {
			setAddedFiles(prev => {
				const preState = JSON.parse(JSON.stringify(prev));
				preState.data[index][name] = value;
				return preState;
			});
		},
		[setAddedFiles]
	);

	const fileName = useCallback((name: string) => {
		const splitedFile = name.split('.');
		const fileType: string | any =
			splitedFile[splitedFile.length - 1]?.toUpperCase();
		if (FileTypes[fileType]) {
			return fileType + '.svg';
		}
		return 'Default.svg';
	}, []);

	const handleOpenFile = useCallback((url: string) => {
		window.open(url, '_blank');
	}, []);

	const iconClassName = useMemo(() => {
		return type === 'previously_uploaded'
			? 'ri-download-line download-icon'
			: 'ri-delete-bin-6-line delete-icon';
	}, [type]);

	const mapFiles = useMemo(() => {
		return (
			data &&
			data.map((item, i) => (
				<div key={item.url} className="uploadedfilerow">
					<div
						className="uploadedfilerow__flex uploadedFiles__name"
						onClick={() => handleOpenFile(item.url)}
					>
						<div className="progessbar__icon">
							<Image fileName={fileName(item.name)} height="20px" />
						</div>
						<div className="uploadedfilerow__field">{item.name}</div>
					</div>
					<div className="uploadedfilerow__flex">
						<input
							onChange={e => handlePassword('password', i, e.target.value)}
							value={item.password}
							disabled={type === 'previously_uploaded'}
							className="uploadedfilerow__fieldBox"
							placeholder={
								type === 'previously_uploaded' ? 'NA' : 'Enter password'
							}
							type="password"
							autoComplete='new-password'
						/>
						<DropDown keyID={i} item={item} type={type} />
						<IconBtn
							btnClassName={`files-row-icon ${iconClassName}`}
							handleClickIcon={() => handleDeleteClick(item)}
						/>
					</div>
				</div>
			))
		);
	}, [
		data,
		fileName,
		handleDeleteClick,
		handleOpenFile,
		handlePassword,
		iconClassName,
		type,
	]);

	return (
		<div className="uploadedfilerow__uploaded_wrapper">
			<div className="uploadedfilerow__headings">
				<div className="uploadedfilerow__heading-text">
					{headings[type].heading}
				</div>
				<div className="uploadedfilerow__heading-description">
					{headings[type].description}
				</div>
			</div>
			{mapFiles}
		</div>
	);
};
