import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { Button, Image, Loader } from '@storybook';
import { FundRecipientBankAccount } from 'global-stores';
import {
	AddBankAccountLoadingState,
	EscrowModalBodyNavigatorState,
	EscrowTransactionTypeState,
	SelectedBankIndexState,
	SelectedBankSubItemState,
	SeletedBankItemState,
	useAddBank,
} from 'views/fund-escrow/stotes';
import { getLogos } from 'views/pipelines/components/fund-investment';
import './change-bank.scss';

export const ChangeBank = () => {
	// globle states
	const transactionType = useRecoilValue(EscrowTransactionTypeState);
	const fundRecipient = useRecoilValue(FundRecipientBankAccount);
	const isLoading = useRecoilValue(AddBankAccountLoadingState);
	const setNavigate = useSetRecoilState(EscrowModalBodyNavigatorState);
	const [subSelectedItem, setSubSelectedItem] = useRecoilState(
		SelectedBankSubItemState
	);
	const [selectedItem, setSelectedItem] = useRecoilState(SeletedBankItemState);
	const [selectedBankIndex, setSelectedBankIndex] = useRecoilState(
		SelectedBankIndexState
	);

	// local states
	const [isTokenLoading, setTokenLoading] = useState(false);
	const [subSelectedNewItem, setSubSelectedNewItem] = useState(subSelectedItem);
	const [selectedNewItem, setSelectedNewItem] = useState(selectedItem);
	const [selectedBankNewIndex, setSelectedBankNewIndex] =
		useState(selectedBankIndex);

	//hook
	const { addNewBank } = useAddBank();

	useEffect(() => {
		setSubSelectedNewItem(subSelectedItem);
		setSelectedNewItem(selectedItem);
		setSelectedBankNewIndex(selectedBankIndex);
	}, [selectedBankIndex, selectedItem, subSelectedItem]);

	// handle open bank card
	const handleClick = useCallback(
		(index: number) => {
			if (selectedBankNewIndex === index) {
				setSelectedBankNewIndex(-1);
				return;
			}
			setSelectedBankNewIndex(index);
		},
		[selectedBankNewIndex, setSelectedBankNewIndex]
	);

	// handle back
	const handleBack = useCallback(() => {
		if (transactionType === 'deposit') {
			setNavigate('deposite_fund');
		}
		if (transactionType === 'withdraw') {
			setNavigate('withdraw_fund');
		}
	}, [setNavigate, transactionType]);

	// select bank
	const handleSelect = useCallback(() => {
		setSubSelectedItem(subSelectedNewItem);
		setSelectedItem(selectedNewItem);
		setSelectedBankIndex(selectedBankNewIndex);
		if (transactionType === 'deposit') {
			setNavigate('deposite_fund');
		}
		if (transactionType === 'withdraw') {
			setNavigate('withdraw_fund');
		}
	}, [
		selectedBankNewIndex,
		selectedNewItem,
		setNavigate,
		setSelectedBankIndex,
		setSelectedItem,
		setSubSelectedItem,
		subSelectedNewItem,
		transactionType,
	]);

	// handle bank sublist
	const handleSubBank = useCallback(
		(item: any, subItem: any) => {
			setSelectedNewItem(item);
			setSubSelectedNewItem({ ...subItem, _id: item._id });
		},
		[setSelectedNewItem, setSubSelectedNewItem]
	);

	// add new bank
	const handleAddNewBank = useCallback(() => {
		setTokenLoading(true);
		addNewBank();
	}, [addNewBank]);

	const renderBankList = useMemo(
		() =>
			fundRecipient.length > 0 &&
			fundRecipient.map((item: any, index: number) => (
				<div
					className={`bank-card ${
						selectedNewItem?._id === item._id ? 'active-bank' : ''
					}`}
					key={item._id}
				>
					<div className="bank-card-upper" onClick={() => handleClick(index)}>
						{selectedNewItem?._id === item._id ? (
							<i className="ri-checkbox-circle-fill bank-card-upper__active-icon " />
						) : (
							<></>
						)}
						<div className="bank-card-upper__icon-wrapper">
							<Image
								fileName={`${getLogos[item.bankName] ?? getLogos['default']}`}
							/>
						</div>
						<div className="bank-card-upper__bank-title-wrapper">
							<div className="bank-card-upper__bank-title-wrapper__bank-name">
								{item.bankName}
							</div>
							<div className="bank-card-upper__bank-title-wrapper__account-type">
								{subSelectedNewItem?._id === item?._id ? (
									<div>
										{`${subSelectedNewItem?.subtype}`}{' '}
										(&#8226;&#8226;&#8226;&#8226;
										{subSelectedNewItem?.mask})
									</div>
								) : (
									item.accounts?.length + ' accounts available'
								)}
							</div>
						</div>
						<div
							className="bank-card-upper__arrow-wrapper"
							style={{
								transform:
									selectedBankNewIndex === index ? 'rotate(180deg)' : 'unset',
							}}
						>
							<i className="ri-arrow-down-s-line" />
						</div>
					</div>

					{selectedBankNewIndex === index ? (
						<>
							<div className="bank-card-separator"></div>
							<div className="bank-card-lower">
								<div className="ac-type-selector-list">
									{item.accounts.map((subItem: any) => (
										<div
											onClick={() => handleSubBank(item, subItem)}
											className="ac-type-wrapper"
											key={subItem?.accountId}
										>
											<div className="ac-type-wrapper__left">
												<input
													id="primary"
													type="radio"
													checked={
														subSelectedNewItem?.accountId === subItem?.accountId
													}
													style={{ transform: 'scale(1.5)' }}
												/>
												<div className="account-type">{subItem.subtype}</div>
											</div>
											<div className="ac-type-wrapper__right">
												&#8226;&#8226;&#8226;&#8226; {subItem.mask}
											</div>
										</div>
									))}
								</div>
							</div>
						</>
					) : (
						<></>
					)}
				</div>
			)),
		[
			fundRecipient,
			selectedNewItem,
			subSelectedNewItem,
			selectedBankNewIndex,
			handleClick,
			handleSubBank,
		]
	);

	return (
		<div className="change-bank-wrapper">
			<div className="change-bank-header">
				<div className="change-bank-header__title">Change Bank Account</div>
				<div className="change-bank-header__desc">Some description here</div>
			</div>

			<div className="change-bank-body">
				<div className="change-bank-add-bank">
					<Button
						label={
							isTokenLoading ? (
								<Loader type={'loader'} dimension={26} />
							) : (
								`Add New Bank Account`
							)
						}
						handleClick={handleAddNewBank}
						type="button__filled button__filled--secondary"
						disabled={isTokenLoading}
					/>
				</div>
				<div className="change-bank">
					<div className="change-bank__bank-list">
						{isLoading ? (
							<div className="loader-wrapper">
								<Loader type="gif" />
							</div>
						) : (
							renderBankList
						)}
					</div>
				</div>
			</div>

			<div className="change-bank-footer">
				<div className="change-bank-footer__btns">
					<Button
						label="Back"
						handleClick={handleBack}
						type="button__filled button__filled--secondary"
					/>
					<Button
						label="Select"
						handleClick={handleSelect}
						type="button__filled button__filled--primary"
					/>
				</div>
			</div>
		</div>
	);
};
