import { FC, useState } from 'react';
import { KybInfoHeader } from '../kyb-info-header';
import './view-more-hoc.scss';

interface IProps {
	Component: any;
	componentProp: any[];
	heading: string;
  isdefaultShowAllinfo?: boolean
}

export const ViewMoreHOC: FC<IProps> = ({
	Component,
	componentProp,
	heading,
  isdefaultShowAllinfo
}) => {
	const [showMore, setShowMore] = useState( isdefaultShowAllinfo ?? false);

	const toggleShowMore = () => setShowMore(!showMore);

	// Show only the first 4 items or all items if `showMore` is true
	const displayedItems = showMore
		? componentProp ?? []
		: componentProp?.slice(0, 4) ?? [];

	if (displayedItems?.length === 0) {
		return <></>;
	}

	return (
		<div className="view-more__hoc--wrapper">
			{/* Render the passed component with the displayed items */}
			<KybInfoHeader type={heading} />
			<div className="enchanced-view-more-wrapper">
				{(componentProp ?? []).length > 4 && (
					<button className="view-more__buttons" onClick={toggleShowMore}>
						{showMore ? 'View Less' : 'View More'}
					</button>
				)}
			</div>
			<div className="view-more__components--wrapper">
				<Component items={displayedItems} />
			</div>
		</div>
	);
};
