import type { InviteOnboardingPaymentCurrentPage } from './type';

import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';

import {
	CapTableGridTableData,
	CapTableMapKeyAndValue,
} from '@storybook/custom-captable/states';
import { API_URL, INVITE_MODAL_STEPS, MESSAGE } from 'constant';
import { useMarketing, useNetwork, useNotification } from 'hooks';
import {
	CardsListViewState,
	IInviteNavigateModalSteps,
	InvestorTableGridData,
	InviteNavigateModalState,
	OpenInviteSelectorModal,
	PaymentCardMethodsState,
	PipelineIdState,
	SelectedDocsForUploadState,
	SelectedFundType,
	SelectedInviteFundState,
	SelectedPipelineDetails,
	UploadedDocsState,
	UserRoleState,
	companyDetailsState,
	currentCapTableData,
	loginState,
	useFunds,
} from 'global-stores';
import { addWithDynamicPrecision, getLuckSheetData } from 'utils';
import {
	ConditionalOnboardingFlowState,
	FundsIdState,
	SIGN_OPTIONS,
	SignOptionState,
	useInviteInvestorPayload,
	userCapTableState,
} from '../../store';
import { SignatureProvider } from './constant';
import {
	BillingSummary,
	BussinessAddressState,
	BussinessInfoState,
	IsPayAtGoStatusState,
	OnboardingPaymentCurrentPage,
	OnboardingPaymentTypeState,
	PayNowCurrentPage,
} from './state';
import { usePaymentCard } from 'views/routes-children';
import { DashboardsAtom } from 'views/dashboard-analytics';
import { usePipelineData } from 'views/pipelines/hooks';
import { CaptableOldState } from 'views/multi-sign-agreement';
import { proofReadingKey } from 'views/pipelines/constants';
import { UploadProofDocumentState } from 'views/upload-proof-document';

export const useInviteOnboardingPayment = () => {
	const setNavigate = useSetRecoilState(InviteNavigateModalState);
	const setCurrentPage = useSetRecoilState(OnboardingPaymentCurrentPage);
	const pipelineId = useRecoilValue(PipelineIdState);
	const isConditional = useRecoilValue(ConditionalOnboardingFlowState);
	const paymentCardMethodsState = useRecoilValue(PaymentCardMethodsState);
	const [isLoaded, setIsLoaded] = useState(true);
	const userRole = useRecoilValue(UserRoleState);
	const [address, setAddress] = useRecoilState(companyDetailsState);

	const { get: findBussiness } = useNetwork();
	const { getPinelines } = usePipelineData();

	const { inviteInvestorPayload } = useInviteInvestorPayload();
	const setSummary = useSetRecoilState(BillingSummary);
	const { successNotification, errorNotification } = useNotification();
	const { post, remove, patch, get } = useNetwork({
		updateState: false,
		returnResponse: true,
	});
	const { getPaymentCardsApi } = usePaymentCard();

	const login = useRecoilValue(loginState);
	const resetFundId = useResetRecoilState(FundsIdState);
	const { BUSINESSES } = API_URL;

	//Gaurav: To get the default card.
	const defaultMethod = useMemo(() => {
		const data = paymentCardMethodsState?.default;
		return data?.detail;
	}, [paymentCardMethodsState?.default]);

	const current = new Date();
	const year = current.getFullYear();
	const month = current.getMonth();

	//Gaurav: To check the default card is expired.
	const isCardExpired = useMemo(() => {
		return (
			defaultMethod?.exp_year < year ||
			defaultMethod?.exp_month > 12 ||
			(defaultMethod?.exp_year === year && defaultMethod?.exp_month <= month)
		);
	}, [defaultMethod?.exp_year, defaultMethod?.exp_month, year, month]);

	const changeBillingCurrentPage = useCallback(
		(stepName: InviteOnboardingPaymentCurrentPage): void => {
			setCurrentPage(stepName);
		},
		[setCurrentPage]
	);

	useEffect(() => {
		const bussinessId = userRole[0]?.business?._id;
		if (bussinessId && !address.name) {
			findBussiness(`${API_URL.BUSINESSES}/${bussinessId}`).then(resp => {
				const { name } = resp?.data ?? {};
				if (name) {
					setAddress(prev => ({
						...prev,
						name,
					}));
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userRole]);

	const addNewCard = useCallback(
		async (tokenDetails: any): Promise<boolean> => {
			const { id: token } = tokenDetails.token ?? {};
			const payload = { token, default: false };
			const resp = await post(API_URL.BUSINESS_CARD, payload);
			const { id, errorData } = resp ?? {};
			if (id) {
				getPaymentCardsApi();
				return true;
			}
			errorNotification(errorData?.message ?? MESSAGE.ERROR);
			return false;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const deleteBusinessCard = useCallback(
		async (cardId: string, isDefault: boolean) => {
			if (isDefault) {
				errorNotification('Default card can not be deleted.');
				return;
			}
			const resp = await remove(`${API_URL.BUSINESS_CARD}/${cardId}`);
			if (resp?.id) {
				getPaymentCardsApi();
				successNotification('Business card deleted successfully');
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const verifyCompanyName = useCallback(
		async (payload: any): Promise<boolean> => {
			const resp: any = await post(API_URL.BUSINESS_KYB, payload);
			const status = resp?.message === 'Company exist';
			if (!status) {
				const { errorData } = resp ?? {};
				errorNotification(errorData?.message ?? MESSAGE.ERROR);
			}
			return status;
		},
		[errorNotification, post]
	);

	const updateCompanyDetails = useCallback(
		async (payload: any): Promise<boolean> => {
			const resp = await patch(`${BUSINESSES}/${login.business}`, payload);
			if (resp) {
				return true;
			}
			return false;
		},
		[BUSINESSES, login.business, patch]
	);

	const payNow = useCallback(
		async (payload: any): Promise<boolean> => {
			setIsLoaded(false);

			//Gaurav: If default card is expired then user don't proceed.
			if (isCardExpired) {
				errorNotification('Your card has expired. Choose another card.');
				return false;
			}

			const finalPayload = { ...inviteInvestorPayload, ...payload };
			const resp = await post(API_URL.CHECKOUT, finalPayload);
			const { success, errorData } = resp ?? {};
			if (!success) {
				const { message } = errorData ?? {};
				if (message?.includes('not allowed to be empty')) {
					errorNotification('Please fill captable properly');
					setNavigate('csv');
				} else if (message?.includes('Invalid value for header')) {
					errorNotification(message ?? 'Invalid value for header');
					setNavigate('csv');
				} else {
					errorNotification(message ?? 'Card expired');
				}
			} else {
				resetFundId();
			}
			getPinelines();
			setIsLoaded(true);
			return !!success;
		},

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[inviteInvestorPayload]
	);

	const makeMonthlyBilling = useCallback(async (): Promise<boolean> => {
		setIsLoaded(false);

		//Gaurav: If default card is expired then user don't proceed.
		if (isCardExpired) {
			errorNotification('Your card has expired. Choose another card.');
			return false;
		}

		const resp = await post(
			isConditional ? API_URL.COMPLEX_INVITE : API_URL.INVITE_INVESTOR,
			inviteInvestorPayload
		);
		const { success, errorData } = resp ?? {};

		if (!success) {
			const { message } = errorData ?? {};
			if (message?.includes('Invalid value for header')) {
				errorNotification(message ?? 'Invalid value');
				setNavigate('csv');
			} else {
				errorNotification(message ?? 'Card expired');
			}
		}
		getPinelines();
		setIsLoaded(true);
		return !!success;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errorNotification, inviteInvestorPayload, post, isConditional]);

	const sendMonthlyBillingApprovalRequest =
		useCallback(async (): Promise<boolean> => {
			setIsLoaded(false);
			const resp = await post(API_URL.MONTHY_SUBSCRIPTION, {});
			const { success, errorData } = resp ?? {};
			const { message } = errorData ?? {};
			if (message?.length > 0) {
				errorNotification(message ?? MESSAGE.ERROR);
			}
			setIsLoaded(true);
			return !!success;
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

	const fetchBillingSummary = useCallback((): void => {
		setSummary(prev => ({ ...prev, isLoaded: false }));
		get(`${API_URL.PIPELINE_PRICING}=${pipelineId}`)
			.then(resp => {
				const { apiPayload: apiData, response } = resp;
				if (response?.status === 200) {
					const { data } = apiData ?? {};

					const { fund, ...rest } = data ?? {};
					let totalPrice = 0;
					const formatedResponse = Object.keys(rest).map(stepKey => {
						totalPrice = addWithDynamicPrecision(
							totalPrice,
							data[stepKey] ?? 0
						);
						// totalPrice = totalPrice +  data[stepKey] ?? 0
						return {
							name: SignatureProvider[stepKey] ?? stepKey,
							price: data[stepKey],
						};
					});
					if (fund) {
						totalPrice = totalPrice + fund?.price ?? 0;
						formatedResponse.push({
							name: fund?.type ?? '',
							price: fund?.price ?? 0,
						});
					}
					setSummary(prev => ({
						...prev,
						data: { steps: formatedResponse, totalPrice, fund },
					}));
					return;
				}
				setSummary(prev => ({ ...prev, error: true }));
			})
			.finally(() => {
				setSummary(prev => ({ ...prev, isLoaded: true }));
			});
	}, [get, pipelineId, setSummary]);

	return {
		updateCompanyDetails,
		changeBillingCurrentPage,
		payNow,
		makeMonthlyBilling,
		fetchBillingSummary,
		addNewCard,
		deleteBusinessCard,
		verifyCompanyName,
		isLoaded,
		sendMonthlyBillingApprovalRequest,
	};
};

export const useGetInviteOnboardingPayment = () => {
	const pipelineDetails = useRecoilValue(SelectedPipelineDetails);
	const setNavigate = useSetRecoilState(InviteNavigateModalState);
	const isComplex = useRecoilValue(ConditionalOnboardingFlowState);
	const  setOldCaptableState = useSetRecoilState(CaptableOldState);

	const cards = useRecoilValue(CardsListViewState);
	const capTable = useRecoilValue(userCapTableState);
	const signDocOption = useRecoilValue(SignOptionState);
	const { warningNotification } = useNotification()

	//constants
	const { proofReading } = proofReadingKey;

	const manageGoBackToPrevStage = useCallback(() => {
		// Check if the previous stage is signAgreement
		const isPrevSignAgreement =
			pipelineDetails?.fullStatus?.includes('signAgreement');
		const captableData = getLuckSheetData();
		//Gaurav: Added to show proofReadDocuments.
		const isPrevProofReadDocuments =
			pipelineDetails?.fullStatus?.includes(proofReading);
		// Arun kumar: on back click on billing it should redirect to configComplex then to csv
		return isPrevProofReadDocuments
			? setNavigate(isComplex ? 'configComplex' : proofReading)
			: isPrevSignAgreement
			? setNavigate(isComplex ? 'configComplex' : 'signAggrement')
			: isComplex
			? setNavigate('configComplex')
			: setNavigate('csv');

		// Default navigation target
		let navigateTarget = INVITE_MODAL_STEPS.CSV;

		// Update navigation target based on conditions
		if (isComplex) {
			// If it's a complex pipeline, navigate to configComplex
			navigateTarget = INVITE_MODAL_STEPS.CONFIGCOMPLEX;
		} else if (isPrevSignAgreement) {
			// If previous stage is signAgreement
			if (signDocOption === SIGN_OPTIONS.COMMON) {
				// If signDocOption is "common", navigate to csv
				warningNotification(
					'If any changes are made to the cap table details, the configurations for signing agreements will be lost.'
				);
				setOldCaptableState(captableData);
				navigateTarget = INVITE_MODAL_STEPS.CSV;
			} else {
				// If signDocOption is not "common", navigate to signAggrement
				navigateTarget = INVITE_MODAL_STEPS.SIGNAGGREMENT;
			}
		}

		// Set the navigation target
		setNavigate(navigateTarget as IInviteNavigateModalSteps);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isComplex, pipelineDetails?.fullStatus]);	  
	  
	const businessCards = useMemo(() => {
		return cards;
	}, [cards]);

	const invitedUsers = useMemo(() => {
		const { rows = [] } = capTable ?? {};
		return rows.length ?? 1;
	}, [capTable]);

	return { manageGoBackToPrevStage, businessCards, invitedUsers };
};

export const useResetInvitationFlow = () => {
	const resetCapTableData = useResetRecoilState(CapTableGridTableData);
	const resetCurrentCapTable = useResetRecoilState(currentCapTableData);
	const resetSelectedFund = useResetRecoilState(SelectedInviteFundState);
	const resetFundType = useResetRecoilState(SelectedFundType);
	const resetCapTable = useResetRecoilState(InvestorTableGridData);
	const resetUploadedFiles = useResetRecoilState(UploadedDocsState);
	const resetSelectedDocs = useResetRecoilState(SelectedDocsForUploadState)
	const resetSelectedValue = useResetRecoilState(CapTableMapKeyAndValue);
	const resetModalOpenStatus = useResetRecoilState(OpenInviteSelectorModal);
	const resetSubscriptionCurrentPage = useResetRecoilState(
		OnboardingPaymentCurrentPage
	);
	const resetProofDocFiles = useResetRecoilState(UploadProofDocumentState)
	const dashboardData = useRecoilValue(DashboardsAtom);
	const capTable = useRecoilValue(userCapTableState);

	const resetPaymentType = useResetRecoilState(OnboardingPaymentTypeState);
	const resetPayAtGoStatus = useResetRecoilState(IsPayAtGoStatusState);
	const resetCompanyInfo = useResetRecoilState(BussinessInfoState);
	const resetCompanyAddress = useResetRecoilState(BussinessAddressState);
	const resetBillingSummary = useResetRecoilState(BillingSummary);
	const resetPayNowCurrentPage = useResetRecoilState(PayNowCurrentPage);
	const resetpipelineDetails = useResetRecoilState(SelectedPipelineDetails);
	const { trackAttribute } = useMarketing();

	const totalSessions = useMemo(() => {
		const { data } = dashboardData ?? {};
		const { summary } = data ?? {};
		const { session } = summary ?? {};

		const noOfSession =
			session?.find((sessionItem: any) => sessionItem.id === 'total')?.value ??
			0;
		return noOfSession;
	}, [dashboardData]);

	const invitedUsers = useMemo(() => {
		const { rows = [] } = capTable ?? {};
		return rows.length ?? 1;
	}, [capTable]);

	const isConditional = useRecoilValue(ConditionalOnboardingFlowState);

	const { getFunds } = useFunds();

	// this will reset the state
	const resetInvitationFlow = useCallback(() => {
		resetModalOpenStatus();
		getFunds();

		//Shahbaz: Tracking Intercom variable of signed documents
		if (totalSessions + invitedUsers < 6) {
			trackAttribute({ freeplan_use_onboarding: totalSessions + invitedUsers });
		}

		setTimeout(() => {
			resetCapTableData();
			resetCurrentCapTable();
			resetSelectedFund();
			resetFundType();
			resetCapTable();
			resetUploadedFiles();
			resetSelectedDocs();
			resetSelectedValue();
			resetSubscriptionCurrentPage();
			resetPaymentType();
			resetPayAtGoStatus();
			resetCompanyInfo();
			resetCompanyAddress();
			resetBillingSummary();
			resetPayNowCurrentPage();
			resetpipelineDetails();
			resetProofDocFiles();
		}, 1000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isConditional]);

	return { resetInvitationFlow };
};
