import { Button, Loader } from '@storybook';
import {
	CardCvcElement,
	CardExpiryElement,
	CardNumberElement,
} from '@stripe/react-stripe-js';
import { FC, useMemo } from 'react';
import { usePayment } from 'views/user-identity-flow/hooks';
import { PaymentAddressDetails } from '../payment-address';
import './payment.scss';

interface IPaymentFrom {
	setNavigate: (val: string) => void;
}

export const AddPaymentDetails: FC<IPaymentFrom> = ({ setNavigate }) => {
	const {
		onHandleSubmit,
		billingAddress,
		isBillingError,
		onHandleBillingAddress,
		isDisabled,
		isLoading,
	} = usePayment({ setNavigate });

	const options = useMemo(
		() => ({
			style: {
				base: {
					color: '#424770',
					letterSpacing: '0.025em',
					fontFamily: 'Source Code Pro, monospace',
					'::placeholder': {
						color: '#aab7c4',
					},
				},
				invalid: {
					color: 'var(--color-danger-dark)',
				},
			},
		}),
		[]
	);

	const cvvConfigOptions = {
		...options,
		placeholder: '....',
	};

	return (
		<div className="payment-form bank_detail-container">
			<div className="bank_detail-container__header">
				<div className="payment-form">
					<div className="bank_detail_heading">CARD DETAIL</div>
					<hr className="bank_detail_line" />
				</div>
				<div className="bank_detail">
					<div className="bank_detail_heading">BILLING ADDRESS</div>
					<hr className="bank_detail_line" />
				</div>
			</div>

			<form className="bank_detail-container__form" onSubmit={onHandleSubmit}>
				<div className="form--left">
					<div className="card-number-container">
						<div className="card-label"> Card number</div>
						<div className="card-number">
							<i className="ri-wallet-line payment-form__icon" />
							<CardNumberElement />
						</div>
					</div>
					<div className="card-ex-cvc">
						<div className="card-expiry-container">
							<div className="card-label"> Expiry date</div>
							<div className="card-expiry">
								<i className="ri-calendar-line payment-form__icon" />
								<CardExpiryElement />
							</div>
						</div>
						<div className="card-cvc-container">
							<div className="card-label">
								<span>CVV/CVC</span> <i className="ri-question-line" />
							</div>
							<div className="card-cvc">
								<i className="ri-lock-2-line payment-form__icon" />
								<CardCvcElement options={cvvConfigOptions} />
							</div>
						</div>
					</div>
				</div>
				<div className="form--right">
					<PaymentAddressDetails
						address={billingAddress}
						updateAddress={onHandleBillingAddress}
						addressError={isBillingError}
						label="Street Address"
					/>

					<div className="identity-user-flow-modal--btns bank_detail__buttons">
						<Button
							label={
								isLoading ? (
									<Loader
										type="loader"
										className="loader-blue"
										dimension={20}
									/>
								) : (
									'Next'
								)
							}
							type="button__filled button__filled--primary button__large"
							disabled={isDisabled}
							buttonType="submit"
						/>
					</div>
				</div>
			</form>
		</div>
	);
};
