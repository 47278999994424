export const REMAINING_SERVICES = [
	{
		key: 'onboarding',
		title: 'Onboarding Service',
		remains: 0,
	},
	{
		key: 'simpliciSign',
		title: 'esign',
		remains: 0,
	},
];

export const ESIGN_REMAINING_SERVICES = [
	{
		key: 'simpliciSign',
		title: 'esign',
		remains: 0,
	},
];

export const ONBOARDING_REMAINING_SERVICES = [
	{
		key: 'onboarding',
		title: 'Onboarding Service',
		remains: 0,
	},
];

export const FREE_CREDITS_REMAINING = {
	WITH_REMAINING: {
		title: 'Looks like you don’t have enough free invites',
		subTitle:
			'Choose your account type for unlimited invites and more benefits.',
	},
	WITH_NOT_REMAINING: {
		title: 'You have used up all your free invites for ',
		subTitle:
			'Choose your account type for unlimited invites and more benefits.',
	},
	SIGN_SUBSCRIPTION_WITH_KYC: {
		title: 'You have used up all your free invites for ',
		subTitle:
			'Please subscribe for unlimited invites and more benefits.',
	},
	SIGN_SUBSCRIPTION: {
		subTitle:
			'To access this feature, please upgrade your account type to “Entity/Business”. Upgrading will unlock additional functionalities and benefits for your account',
		title: 'Upgrade account type',
	},
};
