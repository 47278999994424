import type { IDashboardBarChart } from 'views/dashboard-analytics/hooks';

import { FC, useEffect } from 'react';

import { useBarChart } from 'views/dashboard-analytics/hooks';
import './dashboard-chart.scss';

export const BarChart: FC<IDashboardBarChart> = ({
	isLoading,
	title,
	yAxis,
	xAxis,
	totalAmount,
	revenue,
}) => {
	const { getBarChartConfig } = useBarChart();

	useEffect(() => {
		getBarChartConfig({ isLoading, title, yAxis, xAxis, totalAmount, revenue });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading, revenue, title, totalAmount, xAxis, yAxis]);

	return (
		<div className="dashboard-chart-wrapper">
			<div id={title} />
		</div>
	);
};
