import { FC, useEffect, useMemo, useState } from 'react';

import { SignInForm } from './components/signin-form/signin-form';
import { useLocation } from 'hooks';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { LoginApisState, LoginStep } from './store/states';
import { MobileLinkSent } from './components/mobile-link-sent';
import {
	BiometricApprovalSent,
	EmailVerification,
	MfaAuthentication,
} from './components';

import './signin.scss';

type IWebLogin = {
	onFailed: any;
	onSuccess: any;
	methods: string;
	send_otp_api: string;
	input_type: string;
	login_api: string;
	web_auth_api: string;
};
export const WebLogin: FC<IWebLogin> = ({
	onFailed,
	onSuccess,
	methods,
	send_otp_api,
	input_type,
	login_api,
	web_auth_api,
}) => {
	const [ipAddress, setIpAddress] = useState('');
	const { locationInfo, fetchLocation } = useLocation();
	const setLoginUrls = useSetRecoilState(LoginApisState);
	const step = useRecoilValue(LoginStep);

	useEffect(() => {
		fetch('https://api.ipify.org?format=json')
			.then(response => response.json())
			.then(data => setIpAddress(data.ip));
	}, []);

	useEffect(() => {
		if (!Object.keys(locationInfo ?? {}).length) {
			fetchLocation();
		}
		// eslint-disable-next-line
	}, [locationInfo]);

	useEffect(() => {
		if (login_api !== 'undefined') {
			setLoginUrls((prev: any) => ({ ...prev, login_url: login_api }));
		}
		if (web_auth_api !== 'undefined') {
			setLoginUrls((prev: any) => ({ ...prev, web_auth_api: web_auth_api }));
		}
		// eslint-disable-next-line
	}, [login_api]);

	// eslint-disable-next-line
	const props = {
		onFailed,
		onSuccess,
	};

	const getComponent = useMemo(() => {
		switch (step) {
			case 'MOBILE_CODE':
				return <MobileLinkSent props={props} />;
			case 'BIOMETRIC_APPROVAL':
				return <BiometricApprovalSent />;
			case 'MFA-AUTH':
				return <MfaAuthentication />;
			case 'EMAIL_VERIFICATION':
				return <EmailVerification />;
			default:
				return (
					<SignInForm
						props={props}
						methods={methods}
						locationInfo={locationInfo}
						ipAddress={ipAddress}
						input_type={input_type}
						send_otp_api={send_otp_api}
					/>
				);
		}
	}, [input_type, ipAddress, locationInfo, methods, props, send_otp_api, step]);

	return getComponent;
};
