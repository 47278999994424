import { FC } from 'react';

type IdentityHeader = {
	head: string;
	desc: string;
};
export const PaymentIdentityHeader: FC<IdentityHeader> = ({ head, desc }) => {
	return (
		<section className="PaymentIdentityheader">
			<div className="PaymentIdentityheader--container">
				<div className="PaymentIdentityheader--container__heading">{head}</div>
				<p className="PaymentIdentityheader--container__info">{desc}</p>
			</div>
		</section>
	);
};
