import { useMemo } from 'react';

import { DealHeader } from '../deal-header';
import { DEAL_USERS } from '../../constants';
import { DealUser } from '../deal-user';

export const DealDetails = () => {

	const renderUserSections = useMemo(
		() =>
			DEAL_USERS.map(({ key, label, icon, info }) => (
				<div key={key} className="d-flex column gap-16 wt-50">
					<div className="border radius-4 d-flex gap-16 column wt-100">
						<DealUser
							data={{ key, label, icon, info }}
						/>
					</div>
				</div>
			)),
		[]
	);

	return (
		<div className="DealDetails">
			<div className="d-flex gap-16 column wt-100">
				<DealHeader />
				<div className="d-flex gap-16 wt-100 f-column">{renderUserSections}</div>
			</div>
		</div>
	);
};
