import { FC, Fragment, useCallback, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
	CheckFraudDetailsState,
	IModal,
	IsMarkAsFraudSelectedByDetails,
} from 'views/cheque-fraud/stores';
import {
	formatNumberWithCommasAndCurrencySignSign,
	getDateWithTime,
} from 'utils';

import './current-transaction-header.scss';

interface ICurrentTransactionHeader {
	handleMarkFraud: (e: IModal) => void;
	status?: string;
	isAlreadyPaid: boolean | undefined;
}
export const CurrentTransactionHeader: FC<ICurrentTransactionHeader> = ({
	handleMarkFraud,
	isAlreadyPaid,
	status = '',
}) => {
	const checkFraudDetails = useRecoilValue(CheckFraudDetailsState);
	const setIsMarkAsFraudByDetails = useSetRecoilState(
		IsMarkAsFraudSelectedByDetails
	);

	const {
		routingNumber,
		checkNumber: wtCheckNumber,
		amount: wtAmount,
		accountNumber,
	} = useMemo(() => checkFraudDetails.checkDetails ?? {}, [checkFraudDetails]);

	const {
		checkNumber = 'N/A',
		amount = 0.0,
		bankBranch = {},
		transactionId = 'N/A',
		createdAt,
		isFraud,
	} = useMemo(
		() => checkFraudDetails.currentTransactionDetails ?? {},
		[checkFraudDetails]
	);

	const handleMarkAsFraud = useCallback(() => {
		if (isFraud) {
			handleMarkFraud('revertFraud');
			setIsMarkAsFraudByDetails(true);
			return;
		} else if (status === 'pending') {
			return;
		} else {
			handleMarkFraud('fraudReport');
			setIsMarkAsFraudByDetails(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFraud]);

	const getValue = (val: number | string | undefined) => {
		return val ? val : 'N/A';
	};

	const getMarkLabel = useMemo(() => {
		if (isFraud) {
			return (
				<div
					className={`cth-wrapper__inner__mark-fraud-btn d-flex gp-8 align-center `}
					onClick={handleMarkAsFraud}
				>
					<i className="ri-information-line info-icon"></i>
					<span>Revert Fraud</span>
				</div>
			);
		} else {
			return (
				<div
					className={`cth-wrapper__inner__mark-fraud-btn ${
						/pending/.test(status) ? 'btn-disabled' : ''
					}`}
					onClick={handleMarkAsFraud}
				>
					Mark as fraud
				</div>
			);
		}
	}, [isFraud, handleMarkAsFraud, status]);

	return (
		<div className="cth-wrapper">
			<div className="cth-wrapper__inner">
				<div className="cth-wrapper__inner__label cth-wrapper__center">
					Current transaction
				</div>
				<div className="cth-wrapper__inner__value cth-wrapper__center">
					{formatNumberWithCommasAndCurrencySignSign(
						!isAlreadyPaid
							? parseFloat(wtAmount ? (wtAmount as string) : '0.0') ?? 0
							: amount ?? 0,
						''
					)}
					<span className="cth-amount__type"> USD</span>
				</div>
				<div className="cth-wrapper__inner__label status-wrapper cth-wrapper--label text-center">
					<div
						className={`dot status-wrapper__${status.toLocaleLowerCase()}`}
					></div>
					<div>{status}</div>
				</div>

				{isFraud && (
					<div className="fraud-report-wrapper">
						<i className="ri-error-warning-fill error-icon"></i>
						<div>Fraud reported</div>
					</div>
				)}

				{isAlreadyPaid ? (
					<Fragment>
						<div className="cth-wrapper__inner__content-wrapper">
							<div className="cth-wrapper__inner__label">Check number</div>
							<div>:</div>
							<div className="cth-wrapper__inner__value">
								{getValue(checkNumber)}
							</div>
						</div>

						<div className="cth-wrapper__inner__content-wrapper">
							<div className="cth-wrapper__inner__label">Transaction ID</div>
							<div>:</div>
							<div className="cth-wrapper__inner__value">
								{getValue(transactionId)}
							</div>
						</div>

						<div className="cth-wrapper__inner__content-wrapper">
							<div className="cth-wrapper__inner__label">Cashier</div>
							<div>:</div>
							<div className="cth-wrapper__inner__value">
								{getValue(bankBranch.cashierName)}
							</div>
						</div>

						<div className="cth-wrapper__inner__content-wrapper">
							<div className="cth-wrapper__inner__label">Date & time</div>
							<div>:</div>
							<div className="cth-wrapper__inner__value">
								{getDateWithTime(createdAt as string)}
							</div>
						</div>
						{getMarkLabel}
					</Fragment>
				) : (
					<Fragment>
						<div className="cth-wrapper__inner__content-wrapper">
							<div className="cth-wrapper__inner__label">Routing number</div>
							<div>:</div>
							<div className="cth-wrapper__inner__value">
								{getValue(routingNumber)}
							</div>
						</div>

						<div className="cth-wrapper__inner__content-wrapper">
							<div className="cth-wrapper__inner__label">Account number</div>
							<div>:</div>
							<div className="cth-wrapper__inner__value">
								{getValue(accountNumber)}
							</div>
						</div>

						<div className="cth-wrapper__inner__content-wrapper">
							<div className="cth-wrapper__inner__label">Check number</div>
							<div>:</div>
							<div className="cth-wrapper__inner__value">
								{getValue(wtCheckNumber)}
							</div>
						</div>
					</Fragment>
				)}
			</div>
		</div>
	);
};
