import { PaymentCardMethodsState } from 'global-stores';
import { usePlaid } from './use-plaid';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

interface IUseAch {
	isSignup?: boolean;
}
export const useAch = ({ isSignup = false }: IUseAch) => {
	const paymentCardMethodsState = useRecoilValue(PaymentCardMethodsState);
	const { initPlaid, isLoadingPlaid } = usePlaid({ isSignup });

	const { banks = [] } = useMemo(
		() => paymentCardMethodsState ?? {},
		[paymentCardMethodsState]
	);

	return {
		initPlaid,
		isLoadingPlaid,
		banks,
	};
};
