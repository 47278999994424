import { useCallback, useEffect, useMemo } from 'react';

import {
	IInvestor,
	IInvestorDetails,
	INVESTOR_OBJ,
	INVESTOR_OBJ_ITEM,
	INVESTOR_OBJ_KEY_CONST,
	NOTIFICATION_MESSAGE,
} from '../constant';
import { InvestorsCountInput } from './investors-count';
import { InvestorsCount, InvestorsDetails, IsInvestorsDetailsDisabled, useInvestorDetails } from '../store';
import { useRecoilState, useRecoilValue } from 'recoil';
import { CountryCode } from '@storybook';
import { isNumber } from 'utils';

const { COUNTRYCODE, EMAIL, FIRSTNAME, LASTNAME, PHONENUMBER, SHAREOWNED } =
	INVESTOR_OBJ_KEY_CONST;
const {
	INVALID_CODE,
	INVALID_EMAIL,
	INVALID_FIRST_NAME,
	INVALID_LAST_NAME,
	INVALID_PHONE_NUMBER,
	INVALID_RANGE,
	INVALID_OWNED
} = NOTIFICATION_MESSAGE;

export const InvestorTable = () => {
	const [totalInvestors, setTotalInvestors] =
		useRecoilState<IInvestor[]>(InvestorsDetails);
	const investors = useRecoilValue(InvestorsCount);
	const { disableExtraRows } = useInvestorDetails();
	const investorDetailsDisabled = useRecoilValue(IsInvestorsDetailsDisabled);
	const INVESTOR_TEXT_ONLY_INPUTS = useMemo(() => {
		return [FIRSTNAME, LASTNAME];
	}, []);

	useEffect(() => {
		// Create a new array containing count number of copies of the hardcoded object
		const newData = Array.from({ length: Number(investors) }, () => ({
			...INVESTOR_OBJ_ITEM,
		}));

		setTotalInvestors((prevData: IInvestor[]) => {
			const newDataLength = newData.length;
			const prevDataLength = prevData.length;

			// If the new data length is greater than previous data length,
			// we keep the existing data and append new objects
			if (newDataLength > prevDataLength) {
				return [...prevData, ...newData.slice(prevDataLength)];
			}
			// If the new data length is less than or equal to previous data length,
			// we keep only the required number of objects from the existing data
			else {
				return prevData.slice(0, newDataLength);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [investors]);

	const handleChange = useCallback(
		(event: any, keyName: string, index: number) => {
			const value = keyName === COUNTRYCODE ? event.label : INVESTOR_TEXT_ONLY_INPUTS.includes(
				keyName as keyof IInvestorDetails
			)
				? event.target.value.replace(/[^a-zA-Z]/g, '') 
				: event.target.value;
			setTotalInvestors((prevUsers: IInvestor[]) => {
				const newUsers: any = [...prevUsers]; // Shallow copy of the array
				newUsers[index] = {
					...newUsers[index],
					[keyName]: { value, error: '' },
				}; // Update the specific user
				return newUsers;
			});
			setTimeout(() => {
				disableExtraRows();
			}, 500);
		},
		[disableExtraRows, setTotalInvestors,INVESTOR_TEXT_ONLY_INPUTS]
	);

	const handleBlur = useCallback(
		(fieldName: string, index: number) => {
			setTotalInvestors((prevUsers: IInvestor[]) => {
				const newUsers: any = [...prevUsers]; // Shallow copy of the array
				let message = '';
				const cellValue = newUsers[index][fieldName].value;
				if (fieldName === FIRSTNAME || fieldName === LASTNAME) {
					const isValid = /^[a-zA-Z]{3,}(?:[-' ]?[a-zA-Z]+)*$/.test(
						`${cellValue}`
					);
					if (!isValid) {
						message =
							fieldName === FIRSTNAME ? INVALID_FIRST_NAME : INVALID_LAST_NAME;
					}
				} else if (fieldName === EMAIL) {
					const isValid =
						/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
							`${cellValue}`
						);
					if (!isValid) {
						message = INVALID_EMAIL;
					}
				} else if (fieldName === COUNTRYCODE) {
					const isValid = /^\+\d{1,3}$/.test(`${cellValue}`);
					if (!isValid) {
						message = INVALID_CODE;
					}
				} else if (fieldName === PHONENUMBER) {
					const isValid = /^\d{10}$/.test(`${cellValue}`);
					if (!isValid) {
						message = INVALID_PHONE_NUMBER;
					}
				} else if (fieldName === SHAREOWNED){
					if(cellValue > 100 || cellValue <= 0){
						message = INVALID_RANGE;
					}
					else if(!isNumber(cellValue)){
						message = INVALID_OWNED;
					}
				}  else {
					message = '';
				}
				newUsers[index] = {
					...newUsers[index],
					[fieldName]: { value: cellValue, error: message },
				}; // Update the specific user
				return newUsers;
			});
		},
		[setTotalInvestors]
	);

	const INVESTOR_INPUT_NUMBER = useMemo(() => {
		return [PHONENUMBER, SHAREOWNED];
	}, []);

	const renderTableRows = useMemo(() => {
		return totalInvestors.map((investor: IInvestor, index: number) => (
			<tr
				className="investor-table__row"
				// eslint-disable-next-line react/no-array-index-key
				key={index}
			>
				{Object.keys(INVESTOR_OBJ).map(columnKey => {
					if (columnKey === COUNTRYCODE) {
						return (
							<td className="investor-table__row-country-item" key={columnKey}>
								<CountryCode
									countryCode={(investor.countryCode.value || '+1') as any}
									handleChangeCountry={(event: any) =>
										handleChange(event, COUNTRYCODE, index)
									}
									className="investor-table__row-country-item--country-code"
									optionsClassName="investor-table__row-country-item--country-code-options"
									isDisabled={investor.disableRow || investorDetailsDisabled}
								/>
							</td>
						);
					}
					const errorMessage = investor[columnKey as keyof IInvestorDetails].error as string;
					const height = errorMessage ? SHAREOWNED == columnKey ? '110px' : '75px' : 'auto'
					return (
						<td
							className={`investor-table__row-item investor-table__row-item--${columnKey}`}
							key={columnKey}
							style={{ height }}
						>
							<InvestorsCountInput
								handleChange={event => handleChange(event, columnKey, index)}
								type={
									INVESTOR_INPUT_NUMBER.includes(
										columnKey as keyof IInvestorDetails
									)
										? 'number'
										: 'text'
								}
								placeholder=""
								value={
									investor[columnKey as keyof IInvestorDetails].value as any
								}
								maxLength={columnKey==PHONENUMBER ? 10 : undefined}
								fieldName={columnKey}
								handleBlur={() => handleBlur(columnKey, index)}
								errorMessage={ errorMessage }
								isDisabled={investor.disableRow}
							/>
						</td>
					);
				})}
			</tr>
		));
	}, [handleBlur, handleChange, totalInvestors, investorDetailsDisabled, INVESTOR_INPUT_NUMBER]);

	const renderTable = useMemo(() => {
		if (Number(investors || 0) > 0) {
			return (
				<div className="investor-table__wraper">
					<label className="investor-table__label">
						List all shareholders/owners in descending order of percentage
						share.
					</label>
					<table className="investor-table">
						<tr className="investor-table__header">
							<th className="investor-table__heading investor-table__heading--first-name">
								First Name
							</th>
							<th className="investor-table__heading investor-table__heading--last-name">Last Name</th>
							<th className="investor-table__heading investor-table__heading--email">Email</th>
							<th className="investor-table__heading investor-table__heading--country">Country Code</th>
							<th className="investor-table__heading investor-table__heading--phone">Phone Number</th>
							<th className="investor-table__heading investor-table__heading--shares">% Owned</th>
						</tr>
						<tbody className="investor-table__body">{renderTableRows}</tbody>
					</table>
				</div>
			);
		}
		return <></>;
	}, [investors, renderTableRows]);

	return renderTable;
};