/* eslint-disable react/no-array-index-key */
import { KYB_ICONS } from 'views/sessions/components/kyb-wrapper/hooks';
import { BasicSection } from '../basic-section';
import { FC, useCallback, useMemo, useState } from 'react';
import { backruptcyItem } from 'views/sessions/components/kyb-wrapper/hooks/kyb-comperhensive-bankruptcy-types';

interface IProps {
	backruptcyItem: backruptcyItem;
}

export const BankruptcyCard: FC<IProps> = ({ backruptcyItem }) => {
	const [isShowAllDetails, setShowAllDetails] = useState(false);

	const handleShowAllDetails = useCallback(() => {
		setShowAllDetails(prev => !prev);
	}, []);

	const renderBankruptcyCardData = useMemo(() => {
		// Separate the basic type items and other items
		const basicItems = Object.keys(backruptcyItem ?? []).filter(
			key =>
				backruptcyItem[key as keyof typeof backruptcyItem]?.type === 'basic'
		);

		const otherItems = Object.keys(backruptcyItem ?? []).filter(key => {
			const item = backruptcyItem[key as keyof typeof backruptcyItem] ?? {};
			return (
				item?.type === 'array' &&
				Array.isArray(item?.value) &&
				item?.value?.length > 0
			);
		});

		return (
			<div>
				{/* Render the heading and basic items if there are any */}
				{basicItems?.length > 0 && (
					<div>
						<div className="kyb-comperhensive-bankruptcy-label">
							Bankruptcy Report Records
						</div>
						{(basicItems ?? []).map(key => {
							const item =
								backruptcyItem[key as keyof typeof backruptcyItem] ?? {};
							return (
								<BasicSection
									key={key}
									label={item?.label ?? '--'}
									value={(item?.value as keyof typeof item) || 'N/A'}
								/>
							);
						})}
					</div>
				)}

				{/* Render other items */}
				{isShowAllDetails &&
					(otherItems ?? []).map(key => {
						const item = backruptcyItem[key as keyof typeof backruptcyItem];

						if (item?.type === 'array' && Array.isArray(item?.value)) {
							return (
								<div className="kyb-comperhensive-bankruptcy-subcard" key={key}>
									<div className="kyb-comperhensive-bankruptcy-label">
										{item?.label ?? '--'}
									</div>
									{(item?.value ?? []).map((arrayItem, index) => (
										<div
											className="kyb-comperhensive-bankruptcy-card-item"
											key={index}
										>
											{Object.keys(arrayItem ?? {}).map(arrayKey => (
												<BasicSection
													key={arrayKey}
													label={arrayKey ?? '--'}
													value={
														arrayItem[arrayKey as keyof typeof arrayItem] ||
														'N/A'
													}
												/>
											))}
										</div>
									))}
								</div>
							);
						}

						return null;
					})}
			</div>
		);
	}, [backruptcyItem, isShowAllDetails]);

	return (
		<div className="backruptcy">
			<div className="backruptcy__header-wrapper">
				<div className="kyb-wrapper__details__kybAssestDetails-label-wrapper">
					<div className="kyb-wrapper__details__kybAssestDetails-label-wrapper__icon">
						<i className={KYB_ICONS['fileError']}></i>
					</div>
					<div className="kyb-wrapper__details__kybAssestDetails-label-wrapper__label">
						{backruptcyItem?.originalCaseNumber?.value ?? '--'}
					</div>
				</div>
				<div className="backruptcy__drop-icon" onClick={handleShowAllDetails}>
					{isShowAllDetails ? (
						<i className="ri-arrow-drop-up-line"></i>
					) : (
						<i className="ri-arrow-drop-down-line"></i>
					)}
				</div>
			</div>
			{renderBankruptcyCardData}
		</div>
	);
};
