// Enum to define the various steps in the cancel subscription workflow
export enum CANCEL_SUBSCRIPTION_STEPS {
	CONFIRMATION = 'cancel_confirmations', // Step where the user confirms the subscription cancellation
	PROCESSING = 'cancel_processing', // Step where the cancellation process is underway
	PAYMENT_PROCESSING = 'payment_processing', // Step where any associated payments are being processed
	COMPLETED = 'cancel_completed', // Step indicating that the subscription cancellation has been completed
	DUES = 'payment_dues', // Step for handling outstanding payment dues
	SUCCESS = 'payment_success', // Step indicating successful completion of payments (if applicable)
	FETCHING = 'dues_fetch', // Step for fetching outstanding dues before proceeding
	DEFAULT = '', // Default step when no specific step is set
}

// Object storing all the validation messages for clarity and ease of maintenance
export const paymentValidationMessages = {
	noPaymentMethod:
		'No payment method found. Please add a payment option first.',
	noBankAccounts:
		'No linked bank accounts found with the default payment option. Please use a different payment method.',
	invalidACHAccount:
		'Invalid ACH account details. Please use a different ACH account or payment method.',
	noCards:
		'No cards found with the default payment method. Please use a different payment method.',
	expiredCard:
		'The default card has expired. Please use a different card or payment method.',
	noWireDetails:
		'Wire transfer details are missing or invalid. Please use a different payment method.',
	unknownMethod:
		'Unknown payment method. Please use a different payment option.',
};
