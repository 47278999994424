import type { IComplexNode } from 'global-stores/invite-investor/type';

/**
 * Get all nodes under a specific parent in the tree.
 * @param {string} nodeId - The node ID to get nodes under.
 * @param {Array} nodeList - The list of nodes containing _id and parentId.
 * @returns {Array} - List of nodes under the provided node.
 */
export const traceToRoot = (
	nodeId: string,
	nodeList: IComplexNode[]
): IComplexNode[] => {
	const pathToRoot: IComplexNode[] = [];
	/**
	 * Recursive function to traverse the tree and collect nodes from the current node to the root.
	 * @param {string} currentId - The current node's ID.
	 */
	function traverseTree(currentId: string) {
		const currentNode = nodeList.find(node => node._id === currentId);

		if (currentNode) {
			pathToRoot.unshift(currentNode); // Add the current node to the beginning of the array.

			if (currentNode.parentId.length > 0) {
				currentNode.parentId.forEach((parentId: string) => {
					traverseTree(parentId);
				});
			}
		}
	}

	// Start traversing the tree from the provided node ID.
	traverseTree(nodeId);

	// removing the current node so to get the list of all the nodes occur before the current node
	pathToRoot.pop();
	return pathToRoot;
};
