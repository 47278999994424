import {
	Dispatch,
	FC,
	SetStateAction,
	createRef,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { Button, Image, Loader } from '@storybook';
import Modal from '@storybook/new-modal/modal';

import './logout-modal.scss';
interface ISubscribeModal {
	isOpen: boolean;
	handleButton: () => void;
	setLogout: Dispatch<SetStateAction<any>>;
	loading: boolean;
}

export const LogoutModal: FC<ISubscribeModal> = ({
	isOpen,
	handleButton,
	setLogout,
	loading,
}) => {
	const ref = createRef<HTMLDivElement>();

	const [logoutLoading, setLogoutLoading] = useState(false);
	const handleinput = useCallback(() => {
		setLogout(false);
	}, [setLogout]);

	//@shivangi useffect apply for continue loading//
	useEffect(() => {
		if (loading) {
			setLogoutLoading(true);
		}
	}, [loading]);

	return (
		<div ref={ref}>
			<Modal
				isOpen={isOpen}
				modalName="Login modal"
				closeModal={() => setLogout(false)}
				className="logout-confirmation-modal"
				showCloseBtn={false}
				isStopOutsideClick={true}
			>
				<div className="logout-modal">
					<div className="logout-modal__wrapper">
						<div className="logout-modal__image">
							<Image fileName="Logout.svg" />
							<div className="logout-modal__text">Do you want to log out?</div>
						</div>
						<div className="logout-modal__text-head">
							<div className="logout-modal__subtext">
								Prompting the user to confirm their desire to end the current
								session and log out.
							</div>
						</div>

						<div className="logout-modal__footer ">
							<Button
								label="Cancel"
								type="button__filled button__filled--secondary button__large logout-modal__secondary"
								handleClick={() => handleinput()}
							/>

							<Button
								label={
									logoutLoading ? (
										<Loader type="loader" dimension={20} />
									) : (
										'Logout'
									)
								}
								type="button__filled button__filled--danger button__large logout-modal__danger  logout-modal__size"
								handleClick={handleButton}
							/>
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
};
