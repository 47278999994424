import { useCallback, useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { loginState } from 'global-stores';
import { ACTIVITY_EVENTS, INACTIVITY_TIMEOUT } from './constant';
import { ShowWarningPopup } from 'views/session-out';


export const useInactivityTracker = () => {
  const timerRef = useRef<NodeJS.Timeout>();
  const { loggedIn } = useRecoilValue(loginState);
  const [showWarning, setShowWarning] = useRecoilState(ShowWarningPopup);
  
  // Reset the inactivity timer
  const resetTimer = useCallback(() => {
    if (timerRef.current) clearTimeout(timerRef.current);
    if (loggedIn) {
      timerRef.current = setTimeout(() => setShowWarning(true), INACTIVITY_TIMEOUT);
    }
  }, [loggedIn, setShowWarning]);

  // Handle user activity
  const handleActivity = useCallback(() => {
    if (loggedIn && !showWarning) resetTimer();
  }, [loggedIn, showWarning, resetTimer]);

  // Setup and cleanup event listeners
  useEffect(() => {
    if (!loggedIn) {
      if (timerRef.current) clearTimeout(timerRef.current);
      return;
    }

    // Add event listeners
    ACTIVITY_EVENTS.forEach(event => window.addEventListener(event, handleActivity));
    resetTimer(); // Start the initial timer

    // Cleanup
    return () => {
      ACTIVITY_EVENTS.forEach(event => window.removeEventListener(event, handleActivity));
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [loggedIn, handleActivity, resetTimer]);
};