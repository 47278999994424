import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { PipelineSettingsFormState } from 'global-stores';

import { ComplexMetaDataId, selectAccreditation } from 'views/mobile-ui-screen';
import { ComplexSettingFormState } from 'views/pipeline';

export const Accrediation = () => {
	const settingsForm = useRecoilValue(PipelineSettingsFormState);
	const complexSettingForm: any = useRecoilValue(ComplexSettingFormState);
	const selectedNodeId = useRecoilValue(ComplexMetaDataId);

	const { accreditationType } = useMemo(
		() => complexSettingForm[selectedNodeId] ?? settingsForm,
		[complexSettingForm, selectedNodeId, settingsForm]
	);

	const renderView = useMemo(
		() =>
			selectAccreditation.map(item => {
				return (
					<div
						className="center-wrapper__accreditation-action"
						key={item.title}
					>
						<div className="center-wrapper__accreditation-action--container">
							<div
								className={`center-wrapper__accreditation-action--image-container ${
									item.title === 'Individual' ? 'individual' : 'entity'
								}`}
							>
								<i className={item.icon}></i>
							</div>
							<div className="center-wrapper__accreditation-action--details">
								<span className="center-wrapper__accreditation-action--desc">
									{item.desc}
								</span>
								<span className="center-wrapper__accreditation-action--title">
									{item.title}
								</span>
							</div>
						</div>
						<div>
							<i className="ri-arrow-right-s-line"></i>
						</div>
					</div>
				);
			}),
		[]
	);

	return (
		<div className="center-wrapper">
			<div className="center-wrapper__accreditation_container">
				<div className="center-wrapper__accreditation-header">
					<span style={{ fontSize: '14px', fontWeight: '600' }}>
						{accreditationType?.label || 'Self Accreditation (506c)'}
					</span>
				</div>
				<div className="center-wrapper__accreditation-brief">
					<span className="center-wrapper__accreditation-brief--title">
						Choose Identity Type
					</span>
					<span className="center-wrapper__accreditation-brief--description">
						Please select identity type you would like to submit for
						accreditation approval.
					</span>
				</div>
				{renderView}
			</div>
		</div>
	);
};
