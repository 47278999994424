import { REACT_APP_GOOGLE_MAP_SECRET_TOKEN as TOKEN } from 'envs';
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { GOOGLE_API } from 'constant';
import { Image, Loader } from '@storybook';
import { FC, ReactElement } from 'react';
import { MapComponent } from 'views/sessions';

import './biometric-details.scss';
import { useRecoilValue } from 'recoil';
import {
	CheckFraudDetailsState,
	IChequeFraudDetails,
} from 'views/cheque-fraud/stores';

interface IBiometricDetails {
	fetchSessionDetails: (args: Partial<IChequeFraudDetails>) => void;
}
export const BiometricDetails: FC<IBiometricDetails> = ({
	fetchSessionDetails,
}) => {
	const checkFraudDetails = useRecoilValue(CheckFraudDetailsState);

	const mapRender = (status: Status): ReactElement => {
		if (status === Status.FAILURE) return <></>;
		return <Loader />;
	};

	const {
		userDetails = {},
		sessionMetadata = {},
		geoLocation = {},
		deviceInfo = {},
	} = checkFraudDetails?.metadata ?? {};

	const { browser, os } = sessionMetadata.systemData ?? {};

	const { city, region, country_code, display_name } =
		sessionMetadata.userAddress ?? {};

	const { latitude, longitude } = geoLocation;
	const { name, profileImage, email, phone, countryCode } = userDetails;

	const url = `${GOOGLE_API.MAP}${latitude},${longitude}`;

	return (
		<div className="biometric-info">
			<div className="biometric-details--title">
				<div className="biometric-details--title__recipient-icon">
					<i className="ri-fingerprint-line"></i>
				</div>
				<div className="biometric-details--title__heading-recipient">
					Biometric Details
				</div>
			</div>
			<div className="biometric-details--user">
				{profileImage && profileImage !== 'NA' && (
					<div className="biometric-details--image-wrapper">
						<Image
							url={profileImage}
							className="biometric-details--image-wrapper__image"
						/>
					</div>
				)}

				<div className="biometric-details--info-wrapper">
					<div className="biometric-details--info-wrapper__info">
						<div className="biometric-details--info-wrapper__info-label">
							Name
						</div>
						<div className="biometric-details--info-wrapper__separate">:</div>
						<div className="biometric-details--info-wrapper__info-value">
							<span className="span">{name ?? 'N/A'}</span>
						</div>
					</div>
					<div className="biometric-details--info-wrapper__info">
						<div className="biometric-details--info-wrapper__info-label ">
							Email
						</div>
						<div className="biometric-details--info-wrapper__separate">:</div>
						<div className="biometric-details--info-wrapper__info-value lower-case">
							<span className="span">{email ?? 'N/A'}</span>
						</div>
					</div>
					<div className="biometric-details--info-wrapper__info">
						<div className="biometric-details--info-wrapper__info-label">
							Phone
						</div>
						<div className="biometric-details--info-wrapper__separate">:</div>
						<div className="biometric-details--info-wrapper__info-value">
							<span className="span">
								{phone && countryCode ? `${countryCode + phone}` : 'N/A'}
							</span>
						</div>
					</div>
					<div className="biometric-details--info-wrapper__info">
						<div className="biometric-details--info-wrapper__info-label">
							KYC
						</div>
						<div className="biometric-details--info-wrapper__separate">:</div>
						<div
							className="biometric-details--info-wrapper__info-value"
							onClick={() => fetchSessionDetails(checkFraudDetails)}
						>
							<span className="span">
								<div className="biometric-details--info-wrapper__info-link">
									<i className="ri-eye-line" />
									<span>View details</span>
								</div>
							</span>
						</div>
					</div>
				</div>
			</div>
			<div className="biometric-details--device">
				<div className="biometric-details--device-title">
					<span>Device Info</span>
					<div className="biometric-details--device-title__line" />
				</div>
				<div className="biometric-details--device-info">
					<div className="biometric-details--device-info__details-container">
						<div className="biometric-details--device-info__details">
							<div className="biometric-details--device-info__icon-container user">
								<i className="ri-user-search-line" />
							</div>
							<div className="biometric-details--device-info__detailed">
								<span className="biometric-details--device-info__key">
									User IP
								</span>
								<span className="biometric-details--device-info__value">
									{sessionMetadata.ipAddress ?? 'N/A'}
								</span>
							</div>
						</div>
					</div>
					<div className="biometric-details--device-info__details-container">
						<div className="biometric-details--device-info__details">
							<div className="biometric-details--device-info__icon-container device">
								<i className="ri-phone-find-line" />
							</div>
							<div className="biometric-details--device-info__detailed">
								<span className="biometric-details--device-info__key">
									Device ID
								</span>
								<span className="biometric-details--device-info__value biometric-details--device-info--fullspan">
									{deviceInfo?.deviceId ? deviceInfo?.deviceId : 'N/A'}
								</span>
							</div>
						</div>
					</div>
					<div className="biometric-details--device-info__details-container">
						<div className="biometric-details--device-info__details">
							<div className="biometric-details--device-info__icon-container device">
								<i className="ri-smartphone-line" />
							</div>
							<div className="biometric-details--device-info__detailed">
								<span className="biometric-details--device-info__key">
									Device OS
								</span>
								<span className="biometric-details--device-info__value">
									{os?.name ?? 'N/A'}
								</span>
							</div>
						</div>
					</div>
					<div className="biometric-details--device-info__details-container">
						<div className="biometric-details--device-info__details">
							<div className="biometric-details--device-info__icon-container browser">
								<i className="ri-global-line" />
							</div>
							<div className="biometric-details--device-info__detailed">
								<span className="biometric-details--device-info__key">
									Browser
								</span>
								<span className="biometric-details--device-info__value">
									{browser?.name ?? 'N/A'}
								</span>
							</div>
						</div>
					</div>
					<div className="biometric-details--device-info__details-container">
						<div className="biometric-details--device-info__details">
							<div className="biometric-details--device-info__icon-container location">
								<i className="ri-user-location-line" />
							</div>
							<div className="biometric-details--device-info__detailed">
								<span className="biometric-details--device-info__key">
									City
								</span>
								<span className="biometric-details--device-info__value">
									{city ?? 'N/A'}
								</span>
							</div>
						</div>
					</div>
					<div className="biometric-details--device-info__details-container">
						<div className="biometric-details--device-info__details">
							<div className="biometric-details--device-info__icon-container host">
								<i className="ri-map-pin-2-fill" />
							</div>
							<div className="biometric-details--device-info__detailed">
								<span className="biometric-details--device-info__key">
									Region
								</span>
								<span className="biometric-details--device-info__value">
									{region ?? 'N/A'}
								</span>
							</div>
						</div>
					</div>
					<div className="biometric-details--device-info__details-container">
						<div className="biometric-details--device-info__details">
							<div className="biometric-details--device-info__icon-container referrer">
								<i className="ri-flag-line" />
							</div>
							<div className="biometric-details--device-info__detailed">
								<span className="biometric-details--device-info__key">
									Country Code
								</span>
								<span className="biometric-details--device-info__value uppercase">
									{country_code ?? 'N/A'}
								</span>
							</div>
						</div>
					</div>
				</div>
				{latitude && longitude && (
					<>
						<div className="map-container-body">
							<a
								href={url}
								target="_blank"
								rel="noreferrer"
								className="map_container"
							>
								<Wrapper apiKey={TOKEN} render={mapRender}>
									<MapComponent
										center={{ lat: latitude, lng: longitude }}
										zoom={11}
									/>
								</Wrapper>
							</a>
						</div>

						{display_name && (
							<div className="footer-address">
								<span className="footer-address__label">Address: </span>
								<span className="footer-address__value">{display_name}</span>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};
