import { FC } from "react";

interface Props {
	count: number;
	label: string;
}

export const SectionCount: FC<Props> = ({
	count,
	label,
}) => {
	return (
		<div className="ofac__countwrapper">
			<div className="ofac__countwrapper__counts">{count}</div>
			<p className="ofac__countwrapper__heading">{label}</p>
		</div>
	);
};
