import { atom } from 'recoil';
import { v4 } from 'uuid';

export type IModal =
	| 'fraudReport'
	| 'viewDetails'
	| 'checkFraudform'
	| 'revertFraud'
	| '';

export type IStatusType = 'proceed' | 'deny';
interface ICheckDetailsFormState {
	amount: string;
	status: IStatusType;
	checkNumber: number;
	session: string;
	bankBranch: string;
	accountNumber: string | number;
	routingNumber: string | number;
}

export interface BankBranch {
	cashierEmail: any;
	name: string;
	branchNumber: number;
	streetAddress: string;
	country: string;
	city: string;
	state: string;
	zipCode: string;
	cashierId: string;
	createdAt: string;
	updatedAt: string;
	cashierName?: string;
}

export interface UserInfo {
	name: string;
	email: string;
	phone: string;
	countryCode: string;
	deviceId: string;
	documentId: string;
	_id: string;
}

export interface GeoLocation {
	accuracy: number;
	altitude: any;
	altitudeAccuracy: any;
	latitude: number;
	longitude: number;
}

export interface FraudDetails {
	potentialRisk: any[];
	fraudMessage: string;
	cashierName: string;
	latitude: number;
	longitude: number;
	deviceId: string;
	geoLocation: Partial<GeoLocation>;
}

export interface ITransactionsList {
	_id: string;
	session: string;
	transactionId: string;
	checkNumber: string;
	amount: number;
	bankBranch: BankBranch;
	isFraud: boolean;
	userInfo: UserInfo;
	createdAt: string;
	updatedAt: string;
	fraudDetails: FraudDetails;
	path: string;
}

export interface Transaction {
	action: string;
	fraudMessage: string;
	amount: number;
	status: string;
	transactionId: string;
}

export interface KycMetadata {
	idv: boolean;
	addressVerification: boolean;
	ssn: boolean;
	chequeFraud: boolean;
	transaction: Transaction;
}

export interface Vouched {
	id: string;
	status: string;
}
export interface Data {
	vouched: Vouched;
}

export interface Position {
	x: number;
	y: number;
}

export interface Data2 {
	label: string;
}

export interface PositionAbsolute {
	x: number;
	y: number;
}

export interface Node {
	id: string;
	type: string;
	position: Position;
	key: string;
	conditions: any[];
	updateStyle: boolean;
	stepId: string;
	label: string;
	parentId: any[];
	data: Data2;
	zIndex: number;
	width: number;
	height: number;
	selected: boolean;
	positionAbsolute: PositionAbsolute;
	dragging: boolean;
}

export interface Design {
	nodes: Node[];
	edges: any[];
}

export interface NodeActions {
	stepStartTime: number;
	data: Data;
	status: string;
	stepEndTime: number;
}

export interface IChequeDetailsLists {
	sessionId: string;
	pipelineId: string;
	name: string;
	email: string;
	contact: string;
	date: string;
	code: string;
	status: string;
	time: string;
	kycMetadata: KycMetadata;
	nodeActions: NodeActions;
	design: Design;
	userName?: string;
	potentialRisk: boolean;
	instanceName: string;
	isAlreadyPaid?: boolean;
	currentAction: string;
}

export const CheckDetailsFormState = atom<ICheckDetailsFormState | any>({
	key: v4(),
	default: {},
});

export const SelectedChequeFraudItem = atom<Partial<IChequeDetailsLists>>({
	key: v4(),
	default: {},
});

export const CheckFraudListState = atom<IChequeDetailsLists[]>({
	key: v4(),
	default: [],
});

export const ChequeFraudAllBranchState = atom<any>({
	key: v4(),
	default: [],
});

export const SelectedChequeFraudTransactionsListState = atom<
	ITransactionsList[]
>({
	key: 'cheque-fraud-transaction-lists',
	default: [],
});

export interface ICHECK_DETAILS_FORM {
	name:
		| 'checkNumber'
		| 'amount'
		| 'bankBranch'
		| 'or'
		| 'addbank'
		| 'addNewBank'
		| 'name'
		| 'branchNumber'
		| 'streetAddress'
		| 'country'
		| 'state'
		| 'city'
		| 'zipCode'
		| 'routeNumber'
		| 'accountNumber'
		| 'routingNumber';
	placeholder: string;
	label: string;
	type: 'or' | 'button' | 'devider' | 'text' | 'number' | 'select';
	width?: string;
	refrer?: string;
	className?: string;
	subLabel?: string;
	regex?: RegExp;
	prefixIcon?: string;
	disabled?: boolean;
}

export interface Metadata {
	time: number;
	cc: any[];
	data: string;
	to: string;
	from: string;
	stepId: string;
	sendMail: boolean;
}

export interface IActivities {
	type: string;
	userName: string;
	role: any;
	profileImage: any;
	userId: any;
	id: string;
	metadata: Metadata;
}

export interface DeviceInfo {
	name: string;
	email: string;
	type: string;
	deviceId: string;
	appleId: string;
}

export interface UserAddress {
	country_code: string;
	city: string;
	region: string;
	country: string;
	display_name: string;
}

export interface Browser {
	name: string;
	version: string;
	major: string;
}

export interface Engine {
	name: string;
	version: string;
}

export interface Device {
	vendor: string;
	model: string;
}

export interface Os {
	name: string;
	version: string;
}

export interface UserDetails {
	countryCode: string;
	phone: string;
	name: string;
	email: string;
	profileImage?: string;
}

export interface SystemData {
	browser: Browser;
	engine: Engine;
	cpu: any;
	device: Device;
	os: Os;
}

export interface SessionMetadata {
	userAddress: UserAddress;
	ipAddress: string;
	systemData: SystemData;
}

export interface IMetaData {
	deviceInfo: Partial<DeviceInfo>;
	geoLocation: Partial<GeoLocation>;
	sessionMetadata: Partial<SessionMetadata>;
	userDetails: Partial<UserDetails>;
}

interface ICheckDetails {
	accountNumber: string | number;
	amount: string | number;
	routingNumber: string | number;
	checkNumber: string | number;
	path: string;
}

interface IBankBranch {
	name: string;
	branchNumber: number;
	streetAddress: string;
	country: string;
	city: string;
	state: string;
	zipCode: string;
	cashierId: string;
	cashierEmail: string;
	cashierName: string;
	createdAt: string;
	updatedAt: string;
}

export interface ICustomTabs {
	key: string;
	label: string;
}

interface IcurrentTransactionDetails {
	checkNumber: string;
	amount: string;
	bankBranch: Partial<IBankBranch>;
	transactionId: string;
	createdAt: string;
	path?: string;
	isFraud?: boolean;
}

export interface IChequeFraudDetails {
	activities: IActivities[];
	metadata: IMetaData;
	nodes: Node[];
	transaction: ITransactionsList[];
	checkDetails: Partial<ICheckDetails>;
	currentTransactionDetails: Partial<IcurrentTransactionDetails>;
}

export const CheckFraudDetailsState = atom<Partial<IChequeFraudDetails>>({
	key: v4(),
	default: {},
});

export const IsMarkAsFraudSelectedByDetails = atom({
	key: v4(),
	default: false,
});

export interface IUploadedDocs {
	name: string;
	type: string;
	file: string;
	size: number;
}

export interface IDocs {
	name: string;
	type: string;
	file: string;
}
