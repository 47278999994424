import { Button } from '@storybook';
import { OfflineSvg } from './image/offline';

import './offline.scss';
import { useCallback } from 'react';

export const Offline = () => {
	const onClickHandler = useCallback(() => {
		window.location.reload();
	}, []);
	return (
		<div className="Offline__container">
			<div
				className="Offline__image"
				dangerouslySetInnerHTML={{
					__html: OfflineSvg,
				}}
			/>

			<p className="Offline__text">You’re offline</p>
			<p className="Offline__desc">
				Your internet connection in unavailable. Please check your data or wifi
				connection.
			</p>
			<Button
				label="Retry"
				handleClick={onClickHandler}
				type=" button__filled--primary button__large button__block Offline__retrybtn "
			/>
		</div>
	);
};
