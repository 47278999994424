import classNames from "classnames";
import { FC, useMemo, useState } from "react";
import './progress-stepper.scss'

interface IProgressStepper {
    steps: Record<string, string>[];
    activeStep: string;
	isVerifed: boolean;
}

export const ProgressStepper: FC<IProgressStepper> = ({steps, activeStep, isVerifed}) => {
    const [activeIndex, setActiveIndex] = useState<number>(0);

    const renderProgress = useMemo(() => {
		

		return steps.map((item, i) => {
			const isActive = item.key === activeStep;
			if (isActive) setActiveIndex(i);
			const stepClasses = classNames('progress-stepper-step', {
				'progress-stepper-step--active': isActive,
				'progress-stepper-step--completed': activeIndex > i,
				'progress-stepper-step--rejected' : isActive && !isVerifed
			});
			return (
				<div key={`${item.key}`} className={stepClasses}>
					<div className="progress-stepper-step-number">
						{activeIndex > i ? (
							<i className="ri-check-line"></i>
						) : !isVerifed && isActive ? (
							<i className="ri-close-line"></i>
						) : (
							i + 1
						)}
					</div>
					<div
						className={`progress-stepper-text  ${
							i === 0 && 'progress-stepper-first-txt'
						}`}
					>
						{item.value}
					</div>
					<div className="step-bar-left"></div>
					<div className="step-bar-right"></div>
				</div>
			);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeIndex, steps, activeStep, isVerifed]);
  return (
    <div className="progress-stepper">{renderProgress}</div>
  )
}
