import { setupLocale } from "survey-creator-core";

export var mmStrings = {
  // survey templates
  survey: {
    edit: "တည်းဖြတ်ပါ",
    externalHelpLink: "စစ်တမ်းများကိုဘယ်လိုဖန်တီးရမည်ကိုကြည့်ရှုပါ",
    externalHelpLinkUrl: "https://www.youtube.com/channel/UCH2ru9okIcooNZfpIbyq4qQ?view_as=subscriber",
    dropQuestion: "ကျေးဇူးပြု၍ Toolbox မှမေးခွန်းတစ်ခုကိုဤနေရာတွင်ချပါ။",
    addLogicItem: "စစ်တမ်း၏လည်ပတ်မှုကိုစိတ်ကြိုက်ပြုလုပ်ရန်စည်းမျဉ်းတစ်ခုဖန်တီးပါ။",
    copy: "ကူးယူပါ",
    duplicate: "မိတ္တူပြုလုပ်ပါ",
    addToToolbox: "Toolbox သို့ထည့်ပါ",
    deletePanel: "Panel ကိုဖျက်ပါ",
    deleteQuestion: "မေးခွန်းကိုဖျက်ပါ",
    convertTo: "သို့ပြောင်းပါ",
    drag: "အရာဝတ္ထုကိုဆွဲပါ",
    license: "ကျွန်ုပ်တို့၏စာကြည့်တိုက်ကိုအသုံးပြုရန် <a href='https://surveyjs.io/licensing'>developer license</a> တစ်ခုလိုအပ်ပါသည်။ သင့်တွင်တက်ကြွသောလိုင်စင်ရှိပါက <a href='https://surveyjs.io/remove-alert-banner'>သင့်လိုင်စင် key ကို set up</a> ပြုလုပ်ပြီး သင်နောက်ဆုံးဗားရှင်းကိုအသုံးပြုနေကြောင်းသေချာပါစေ။"
  },
  // Creator tabs
  tabs: {
    preview: "အစီအစဉ်",
    theme: "အပိုဒ်",
    translation: "ဘာသာစကား",
    designer: "ပံ့ပိုးသူ",
    editor: "တည်းဖြတ်သူ",
    logic: "စစ်တမ်း",
  },
  // Question types
  qt: {
    default: "ပုံမှန်",
    checkbox: "အမှန်ခြစ်များ",
    comment: "ရှည်လျားသောစာသား",
    imagepicker: "ပုံရွေးချယ်မှု",
    ranking: "အဆင့်သတ်မှတ်မှု",
    image: "ပုံ",
    dropdown: "ချိုင့်ကျစွာရွေးချယ်ခြင်း",
    tagbox: "အများဆုံးရွေးချယ်မှုများ",
    file: "ဖိုင်တင်ပို့မှု",
    html: "HTML",
    matrix: "တစ်ခုသာရွေးချယ်နိုင်သော Matrix",
    matrixdropdown: "အများဆုံးရွေးချယ်နိုင်သော Matrix",
    matrixdynamic: "Dynamic Matrix",
    multipletext: "စာသားသေတ္တာများ",
    panel: "Panel",
    paneldynamic: "Dynamic Panel",
    radiogroup: "Radio Button Group",
    rating: "အဆင့်သတ်မှတ်ခြင်း",
    text: "တစ်ကြောင်းစာသားသွင်း",
    boolean: "ဟုတ်/မဟုတ် (Boolean)",
    expression: "စကားရပ် (ဖတ်ရုံသာလျှင်)",
    signaturepad: "လက်မှတ်ပုံစံ",
    buttongroup: "Button Group"
  },
  toolboxCategories: {
    general: "အထွေထွေ",
    choice: "ရွေးချယ်မေးခွန်းများ",
    text: "စာသားမေးခွန်းများ",
    containers: "ကွန်တိန်နာများ",
    matrix: "Matrix မေးခွန်းများ",
    misc: "Misc"
  },
  // Strings in SurveyJS Creator
  ed: {
    defaultLocale: "ပုံမှန် ({0})",
    survey: "စစ်တမ်း",
    settings: "ဆက်တင်များ",
    settingsTooltip: "ဆက်တင်ဖွင့်ရန်",
    surveySettings: "စစ်တမ်းဆက်တင်များ",
    surveySettingsTooltip: "စစ်တမ်းဆက်တင်များ",
    themeSettings: "အကြောင်းအရာဆက်တင်များ",
    themeSettingsTooltip: "အကြောင်းအရာဆက်တင်များ",
    creatorSettingTitle: "ဖန်တီးသူဆက်တင်များ",
    showPanel: "Panel ပြရန်",
    hidePanel: "Panel ဖျောက်ရန်",
    prevSelected: "ယခင်ကိုရွေးချယ်ပါ",
    nextSelected: "နောက်တစ်ခုကိုရွေးချယ်ပါ",
    prevFocus: "ယခင်ကိုအာရုံစိုက်ပါ",
    nextFocus: "နောက်တစ်ခုကိုအာရုံစိုက်ပါ",
    surveyTypeName: "စစ်တမ်း",
    pageTypeName: "စာမျက်နှာ",
    panelTypeName: "Panel",
    questionTypeName: "မေးခွန်း",
    columnTypeName: "ကော်လံ",
    addNewPage: "စာမျက်နှာအသစ်ထည့်ရန်",
    moveRight: "ညာဘက်သို့ရွေ့ရန်",
    moveLeft: "ဘယ်ဘက်သို့ရွေ့ရန်",
    deletePage: "စာမျက်နှာဖျက်ရန်",
    editPage: "စာမျက်နှာတည်းဖြတ်ရန်",
    edit: "တည်းဖြတ်ရန်",
    newPageName: "စာမျက်နှာ",
    newQuestionName: "မေးခွန်း",
    newPanelName: "Panel",
    newTextItemName: "စာသား",
    defaultV2Theme: "ပုံမှန်",
    modernTheme: "ခေတ်မီ",
    defaultTheme: "ပုံမှန် (ရှေးဟောင်း)",
    testSurveyAgain: "စစ်တမ်းပြန်လည်ကြည့်ရှုရန်",
    testSurveyWidth: "စစ်တမ်းအကျယ်: ",
    navigateToMsg: "သင်သည်သွားရမည့်နေရာ",
    saveSurvey: "စစ်တမ်းကိုသိမ်းရန်",
    saveSurveyTooltip: "စစ်တမ်းကိုသိမ်းရန်",
    saveTheme: "အကြောင်းအရာကိုသိမ်းရန်",
    saveThemeTooltip: "အကြောင်းအရာကိုသိမ်းရန်",
    jsonHideErrors: "အမှားများကိုဖျောက်ပါ",
    jsonShowErrors: "အမှားများကိုပြပါ",
    undo: "မလုပ်မှားခဲ့သည်",
    redo: "ပြန်လုပ်ရန်",
    undoTooltip: "နောက်ဆုံးပြောင်းလဲမှုကိုဖျက်ပယ်ပါ",
    redoTooltip: "ပြောင်းလဲမှုကိုပြန်လုပ်ပါ",
    expandAllTooltip: "အားလုံးဖွင့်ပါ",
    collapseAllTooltip: "အားလုံးဖုံးရန်",
    zoomInTooltip: "ချဲ့သည်",
    zoom100Tooltip: "၁၀၀%",
    zoomOutTooltip: "ကျဉ်းစေသည်",
    lockQuestionsTooltip: "မေးခွန်းများ၏ဖွင့်/ပိတ်အခြေအနေကိုသော့ထားပါ",
    showMoreChoices: "ပိုမိုပြရန်",
    showLessChoices: "ပိုမိုပိတ်ရန်",
    copy: "ကူးရန်",
    cut: "ဖြတ်ပါ",
    paste: "ကပ်ပါ",
    copyTooltip: "Clipboard သို့ရွေးချယ်မှုကိုကူးပါ",
    cutTooltip: "Clipboard သို့ရွေးချယ်မှုကိုဖြတ်ပါ",
    pasteTooltip: "Clipboard မှကပ်ပါ",
    options: "ရွေးချယ်မှုများ",
    generateValidJSON: "မှန်ကန်သော JSON ဖန်တီးပါ",
    generateReadableJSON: "ဖတ်ရန်လွယ်ကူသော JSON ဖန်တီးပါ",
    toolbox: "Toolbox",
    "property-grid": "Properties",
    toolboxFilteredTextPlaceholder: "ရှာဖွေရန်ရိုက်ပါ...",
    toolboxNoResultsFound: "ရလဒ်မရှိပါ",
    propertyGridFilteredTextPlaceholder: "ရှာဖွေရန်ရိုက်ပါ...",
    propertyGridNoResultsFound: "ရလဒ်မရှိပါ",
    propertyGridPlaceholderTitle: "သင့်ပုံစံကိုပြင်ဆင်စတင်ပါ",
    propertyGridPlaceholderDescription: "စစ်တမ်းဆက်တင်များကိုစူးစမ်းရန်အမျိုးအစားအိုင်ကွန်ကိုနှိပ်ပါ။ ဒီဇိုင်းမျက်နှာပြင်တွင်စစ်တမ်းအချက်အလက်တစ်ခုကိုထည့်ပြီးနောက်ထပ်ဆက်တင်များရနိုင်ပါမည်။",
    correctJSON: "JSON ကိုပြင်ပါ။",
    surveyResults: "စစ်တမ်းရလဒ်များ ",
    surveyResultsTable: "ဇယားအဖြစ်",
    surveyResultsJson: "JSON အဖြစ်",
    resultsTitle: "မေးခွန်းခေါင်းစဉ်",
    resultsName: "မေးခွန်းအမည်",
    resultsValue: "အဖြေတန်ဖိုး",
    resultsDisplayValue: "ပြသသောတန်ဖိုး",
    modified: "ပြောင်းလဲခဲ့သည်",
    saving: "သိမ်းဆည်းနေသည်",
    saved: "သိမ်းဆည်းပြီး",
    propertyEditorError: "အမှား",
    saveError: "အမှား! တည်းဖြတ်မှုမရှိသိမ်းဆည်းပါ။",
    translationPropertyGridTitle: "ဘာသာစကားဆက်တင်များ",
    themePropertyGridTitle: "အကြောင်းအရာဆက်တင်များ",
    translationLanguages: "ဘာသာစကားများ",
    translationDeleteLanguage: "ဒီဘာသာစကားအတွက် string အားလုံးကိုဖျက်ရန်သေချာပါသလား?",
    translationAddLanguage: "ဘာသာစကားရွေးရန်",
    translationShowAllStrings: "အားလုံး string",
    translationShowUsedStringsOnly: "အသုံးပြုထားသော string များ",
    translationShowAllPages: "စာမျက်နှာအားလုံး",
    translationNoStrings: "ဘာသာပြန်ရန် string မရှိပါ။ စစ်တမ်းအချက်အလက်ကိုပြောင်းပါ။",
    translationExportToSCVButton: "CSV သို့တင်ပို့ပါ",
    translationImportFromSCVButton: "CSV မှတင်သွင်းပါ",
    translateUsigAI: "အားလုံးကိုအလိုအလျောက်ဘာသာပြန်ပါ",
    translateUsigAIFrom: "မှဘာသာပြန်ရန်: ",
    translationDialogTitle: "ဘာသာပြန်ခြင်းမရှိသော string",
    translationMergeLocaleWithDefault: "{0} ကိုပုံမှန် locale နှင့်ပေါင်းစည်းပါ",
    translationPlaceHolder: "ဘာသာပြန်ခြင်း...",
    translationSource: "မူရင်း: ",
    translationTarget: "ဦးတည်ချက်: ",
    translationYouTubeNotSupported: "YouTube လင့်များအားထောက်ပံ့မထားပါ။",
    themeExportButton: "တင်ပို့ပါ",
    themeImportButton: "တင်သွင်းပါ",
    surveyJsonExportButton: "တင်ပို့ပါ",
    surveyJsonImportButton: "တင်သွင်းပါ",
    surveyJsonCopyButton: "Clipboard သို့ကူးပါ",
    themeResetButton: "အကြောင်းအရာဆက်တင်များကိုပုံမှန်သို့ပြန်သတ်မှတ်ပါ",
    themeResetConfirmation: "အကြောင်းအရာကိုပြန်သတ်မှတ်လိုပါသလား? သင်၏စိတ်ကြိုက်ပြုပြင်မှုအားလုံးကိုဆုံးရှုံးပါမည်။",
    themeResetConfirmationOk: "ဟုတ်ကဲ့၊ အကြောင်းအရာကိုပြန်သတ်မှတ်ပါ",
    bold: "အထူ",
    italic: "စောင်း",
    underline: "အောက်ကြောင်းချ",
    addNewQuestion: "မေးခွန်းထည့်ရန်",
    selectPage: "စာမျက်နှာရွေးပါ...",
    carryForwardChoicesCopied: "ရွေးချယ်မှုများကိုကူးခဲ့သည်",
    choicesLoadedFromWebText: "ရွေးချယ်မှုများကိုဝဘ်ဆာဗာမှတင်ခဲ့သည်။",
    choicesLoadedFromWebLinkText: "ဆက်တင်များသို့သွားပါ",
    choicesLoadedFromWebPreviewTitle: "ရွေးချယ်မှုများ၏ကြိုတင်ကြည့်ရှုမှု",
    htmlPlaceHolder: "HTML အကြောင်းအရာဒီမှာရှိမည်။",
    panelPlaceHolder: "Toolbox မှမေးခွန်းတစ်ခုကိုဒီမှာလဲထည့်ပါ။",
    surveyPlaceHolder: "စစ်တမ်းမှာအချို့သောအရာများမရှိပါ။ Toolbox မှအရာတစ်ခုကိုဆွဲယူပါ။",
    pagePlaceHolder: "စာမျက်နှာမှာအချို့သောအရာများမရှိပါ။ Toolbox မှအရာတစ်ခုကိုဆွဲယူပါ။",
    imagePlaceHolder: "ပုံကိုဒီမှာဆွဲယူရန်...",
    surveyPlaceHolderMobile: "ပုံစံဖန်တီးရန် \"မေးခွန်းထည့်ပါ\" ခလုတ်ကိုနှိပ်ပါ။",
    surveyPlaceholderTitle: "ပုံစံအဘယ်သူမျှမ",
    surveyPlaceholderTitleMobile: "ပုံစံအဘယ်သူမျှမ",
    surveyPlaceholderDescription: "Toolbox မှအရာတစ်ခုကိုဆွဲယူပါ။",
    surveyPlaceholderDescriptionMobile: "Toolbox မှအရာတစ်ခုကိုဆွဲယူပါ။",
    previewPlaceholderTitle: "ကြိုတင်ကြည့်ရှုရန်မရှိပါ",
    previewPlaceholderTitleMobile: "ကြိုတင်ကြည့်ရှုရန်မရှိပါ",
    previewPlaceholderDescription: "စစ်တမ်းတွင်မည်သည့်အရာမှမပါဝင်ပါ။",
    previewPlaceholderDescriptionMobile: "စစ်တမ်းတွင်မည်သည့်အရာမှမပါဝင်ပါ။",
    translationsPlaceholderTitle: "ဘာသာပြန်ရန် string မရှိပါ",
    translationsPlaceholderTitleMobile: "ဘာသာပြန်ရန် string မရှိပါ",
    translationsPlaceholderDescription: "သင့်ပုံစံတွင်အရာများကိုထည့်ပါ။",
    translationsPlaceholderDescriptionMobile: "သင့်ပုံစံတွင်အရာများကိုထည့်ပါ။",
    pagePlaceHolderMobile: "\"မေးခွန်းထည့်ပါ\" ခလုတ်ကိုနှိပ်ပြီးစာမျက်နှာသို့အရာထည့်ပါ။",
    panelPlaceHolderMobile: "\"မေးခွန်းထည့်ပါ\" ခလုတ်ကိုနှိပ်ပြီး panel သို့အရာထည့်ပါ။",
    imagePlaceHolderMobile: "ပုံတစ်ခုကိုလွှင့်ပါ...",
    imageChooseImage: "ပုံရွေးပါ",
    addNewTypeQuestion: "{0} ထည့်ပါ", //{0} is localizable question type
    chooseLogoPlaceholder: "[LOGO]",
    choices_Item: "အရာ ",
    selectFile: "ဖိုင်ကိုရွေးပါ",
    removeFile: "ဖိုင်ကိုဖျက်ပါ",
    lg: {
      addNewItem: "သစ်သောစည်းမျဉ်းထည့်ပါ",
      empty_tab: "စစ်တမ်း၏လှုပ်ရှားမှုကိုစိတ်ကြိုက်ပြုလုပ်ရန်စည်းမျဉ်းတစ်ခုဖန်တီးပါ။",
      logicPlaceholderTitle: "နိယာမဆိုင်ရာစည်းမျဉ်းမရှိပါ",
      logicPlaceholderTitleMobile: "နိယာမဆိုင်ရာစည်းမျဉ်းမရှိပါ",
      logicPlaceholderDescription: "စစ်တမ်း၏လှုပ်ရှားမှုကိုစိတ်ကြိုက်ပြုလုပ်ရန်စည်းမျဉ်းတစ်ခုဖန်တီးပါ။",
      logicPlaceholderDescriptionMobile: "စစ်တမ်း၏လှုပ်ရှားမှုကိုစိတ်ကြိုက်ပြုလုပ်ရန်စည်းမျဉ်းတစ်ခုဖန်တီးပါ။",
      page_visibilityName: "စာမျက်နှာကို ပြ/ဖျောက်",
      page_enableName: "စာမျက်နှာကို ဖွင့်/ပိတ်",
      page_requireName: "စာမျက်နှာကိုလိုအပ်အဖြစ်ပြုလုပ်ပါ",
      panel_visibilityName: "Panel ကို ပြ/ဖျောက်",
      panel_enableName: "Panel ကို ဖွင့်/ပိတ်",
      panel_requireName: "Panel ကိုလိုအပ်အဖြစ်ပြုလုပ်ပါ",
      question_visibilityName: "မေးခွန်းကို ပြ/ဖျောက်",
      question_enableName: "မေးခွန်းကို ဖွင့်/ပိတ်",
      question_requireName: "မေးခွန်းကိုလိုအပ်အဖြစ်ပြုလုပ်ပါ",
      question_resetValueName: "မေးခွန်းတန်ဖိုးကိုပြန်စဉ်ဆင်ပါ",
      question_setValueName: "မေးခွန်းတန်ဖိုးကိုသတ်မှတ်ပါ",
      column_visibilityName: "ကော်လံကို ပြ/ဖျောက်",
      column_enableName: "ကော်လံကို ဖွင့်/ပိတ်",
      column_requireName: "ကော်လံကိုလိုအပ်အဖြစ်ပြုလုပ်ပါ",
      column_resetValueName: "ကော်လံတန်ဖိုးကိုပြန်စဉ်ဆင်ပါ",
      column_setValueName: "ကော်လံတန်ဖိုးကိုသတ်မှတ်ပါ",
      trigger_completeName: "စစ်တမ်းပြီးစီး",
      trigger_setvalueName: "အဖြေကိုသတ်မှတ်ပါ",
      trigger_copyvalueName: "အဖြေကိုကူးပါ",
      trigger_skipName: "မေးခွန်းသို့ကျော်သွားပါ",
      trigger_runExpressionName: "ဖော်ပြချက်ကိုလုပ်ဆောင်ပါ",
      completedHtmlOnConditionName: "\"ကျေးဇူးတင်\" စာမျက်နှာကိုသတ်မှတ်ပါ",
      page_visibilityDescription: "နိယာမဖော်ပြချက်မှ true အဖြစ်အကဲဖြတ်သောအခါ စာမျက်နှာကိုမြင်နိုင်စေပါ။ သို့မဟုတ် စာမျက်နှာကိုဖျောက်ထားပါ။",
      panel_visibilityDescription: "နိယာမဖော်ပြချက်မှ true အဖြစ်အကဲဖြတ်သောအခါ Panel ကိုမြင်နိုင်စေပါ။ သို့မဟုတ် Panel ကိုဖျောက်ထားပါ။",
      panel_enableDescription: "နိယာမဖော်ပြချက်မှ true အဖြစ်အကဲဖြတ်သောအခါ Panel နှင့်၎င်းတွင်းရှိအရာများကိုဖွင့်ထားပါ။ သို့မဟုတ် ပိတ်ထားပါ။",
      question_visibilityDescription: "နိယာမဖော်ပြချက်မှ true အဖြစ်အကဲဖြတ်သောအခါ မေးခွန်းကိုမြင်နိုင်စေပါ။ သို့မဟုတ် ဖျောက်ထားပါ။",
      question_enableDescription: "နိယာမဖော်ပြချက်မှ true အဖြစ်အကဲဖြတ်သောအခါ မေးခွန်းကိုဖွင့်ထားပါ။ သို့မဟုတ် ပိတ်ထားပါ။",
      question_requireDescription: "နိယာမဖော်ပြချက်မှ true အဖြစ်အကဲဖြတ်သောအခါ မေးခွန်းကိုလိုအပ်အဖြစ်ပြုလုပ်ပါ။",
      trigger_completeDescription: "နိယာမဖော်ပြချက်မှ true အဖြစ်အကဲဖြတ်သောအခါ စစ်တမ်းပြီးစီးပြီး \"ကျေးဇူးတင်\" စာမျက်နှာကိုပြပါ။",
      trigger_setvalueDescription: "နိယာမဖော်ပြချက်မှ true အဖြစ်အကဲဖြတ်သောအခါ သတ်မှတ်ထားသောတန်ဖိုးကိုရွေးချယ်ထားသောမေးခွန်းသို့ထည့်ပါ။",
      trigger_copyvalueDescription: "နိယာမဖော်ပြချက်မှ true အဖြစ်အကဲဖြတ်သောအခါ တန်ဖိုးကိုတစ်မေးခွန်းမှတစ်မေးခွန်းသို့ကူးပါ။",
      trigger_skipDescription: "နိယာမဖော်ပြချက်မှ true အဖြစ်အကဲဖြတ်သောအခါ စစ်တမ်းသည်ရွေးချယ်ထားသောမေးခွန်းသို့သွားသည်။",
      trigger_runExpressionDescription: "နိယာမဖော်ပြချက်မှ true အဖြစ်အကဲဖြတ်သောအခါ အခြားဖော်ပြချက်တစ်ခုကိုအကဲဖြတ်ပါ။ ၎င်း၏ရလဒ်ကိုမေးခွန်းတစ်ခုသို့ထည့်ရန်ရွေးချယ်နိုင်သည်။",
      completedHtmlOnConditionDescription: "နိယာမဖော်ပြချက်မှ true အဖြစ်အကဲဖြတ်သောအခါ \"ကျေးဇူးတင်\" စာမျက်နှာတွင် သတ်မှတ်ထားသောအကြောင်းအရာကိုပြပါ။",
      itemExpressionText: "ဖော်ပြချက်: '{0}' မှ true ပြန်လာသည့်အခါ", //{0} - the expression
      itemEmptyExpressionText: "သစ်သောစည်းမျဉ်း",
      page_visibilityText: "စာမျက်နှာ {0} ကိုမြင်နိုင်စေပါ", //{0} page name
      panel_visibilityText: "Panel {0} ကိုမြင်နိုင်စေပါ", //{0} panel name
      panel_enableText: "Panel {0} ကိုဖွင့်ထားပါ", //{0} panel name
      question_visibilityText: "မေးခွန်း {0} ကိုမြင်နိုင်စေပါ", //{0} question name
      question_enableText: "မေးခွန်း {0} ကိုဖွင့်ထားပါ", //{0} question name
      question_requireText: "မေးခွန်း {0} ကိုလိုအပ်အဖြစ်ပြုလုပ်ပါ", //{0} question name
      question_resetValueText: "မေးခွန်း: {0} အတွက်တန်ဖိုးကိုပြန်စဉ်ဆင်ပါ", //{0} question name.
      question_setValueText: "မေးခွန်း: {0} သို့တန်ဖိုး: {1} ကိုထည့်ပါ", //{0} question name and {1} setValueExpression
      column_visibilityText: "မေးခွန်း {1} ၏ကော်လံ {0} ကိုမြင်နိုင်စေပါ", //{0} column name, {1} question name
      column_enableText: "မေးခွန်း {1} ၏ကော်လံ {0} ကိုဖွင့်ထားပါ", //{0} column name, {1} question name
      column_requireText: "မေးခွန်း {1} ၏ကော်လံ {0} ကိုလိုအပ်အဖြစ်ပြုလုပ်ပါ", //{0} column name, {1} question name
      column_resetValueText: "ကော်လံ: {0} အတွက်တန်ဖိုးကိုပြန်စဉ်ဆင်ပါ", //{0} column name
      column_setValueText: "ကော်လံ: {0} သို့တန်ဖိုး: {1} ကိုထည့်ပါ", //{0} column name and {1} setValueExpression
      setValueExpressionPlaceholder: "ရလဒ်ကိုရွေးချယ်ထားသောမေးခွန်းသို့ထည့်ရန်ဖော်ပြချက်။",
      trigger_completeText: "စစ်တမ်းသည်ပြီးစီးသွားသည်",
      trigger_setvalueText: "မေးခွန်း: {0} ထဲသို့တန်ဖိုး {1} ကိုထည့်ပါ", //{0} question name, {1} setValue
      trigger_setvalueEmptyText: "မေးခွန်း: {0} ၏တန်ဖိုးကိုရှင်းလင်းပါ", //{0} question name
      trigger_copyvalueText: "မေးခွန်း {0} ထဲသို့ မေးခွန်း {1} မှတန်ဖိုးကိုကူးပါ", //{0} and {1} question names
      trigger_skipText: "မေးခွန်း {0} သို့စစ်တမ်းကျော်သွားပါ", //{0} question name
      trigger_runExpressionText1: "ဖော်ပြချက်: '{0}' ကိုလုပ်ဆောင်ပါ", //{0} the expression
      trigger_runExpressionText2: " နှင့်၎င်း၏ရလဒ်ကိုမေးခွန်း: {0} ထဲသို့ထည့်ပါ", //{0} question name
      completedHtmlOnConditionText: "\"ကျေးဇူးတင်\" စာမျက်နှာအတွက်စိတ်ကြိုက်စာသားကိုပြပါ။",
      showAllQuestions: "မေးခွန်းအားလုံး",
      showAllActionTypes: "လုပ်ဆောင်ချက်အမျိုးအစားအားလုံး",
      conditions: "အခြေအနေ(များ)",
      actions: "လုပ်ဆောင်ချက်(များ)",
      expressionEditorTitle: "အခြေအနေ(များ) သတ်မှတ်ပါ",
      actionsEditorTitle: "လုပ်ဆောင်ချက်(များ) သတ်မှတ်ပါ",
      deleteAction: "လုပ်ဆောင်ချက်ကိုဖျက်ပါ",
      addNewAction: "လုပ်ဆောင်ချက်ထည့်ပါ",
      selectedActionCaption: "လုပ်ဆောင်ချက်ရွေးပါ...",
      expressionInvalid: "နိယာမဖော်ပြချက်သည်လွတ်လပ်နေသည် သို့မဟုတ်မမှန်ကန်ပါ။ ဦးစွာပြုပြင်ပါ။",
      noActionError: "လုပ်ဆောင်ချက်အနည်းဆုံးတစ်ခုထည့်ပါ။",
      actionInvalid: "လုပ်ဆောင်ချက်(များ) တွင်ပြဿနာကိုပြင်ပါ။",
      uncompletedRule_title: "နိယာမစည်းမျဉ်းများမပြီးစီးပါ",
      uncompletedRule_text: "သင်၏နိယာမစည်းမျဉ်းများအချို့မပြီးစီးပါသေးပါ။ အခုပုံစံမှထွက်ပါက ပြောင်းလဲမှုများပျက်ကွက်မည်။ ပြောင်းလဲမှုများမပြီးစီးဘဲ ထွက်လိုပါသလား?",
      uncompletedRule_apply: "ဟုတ်ပါသည်",
      uncompletedRule_cancel: "မဟုတ်ပါ၊ စည်းမျဉ်းများကိုပြီးစီးချင်ပါသည်"
    }
  },
  // Property Editors
  pe: {
    panel: {
      name: "ပန်းနယ်အမည်",
      title: "ပန်းနယ်ခေါင်းစဉ်",
      description: "ပန်းနယ်ဖော်ပြချက်",
      visibleIf: "ပန်းနယ်ကိုမြင်သာစေပါ",
      requiredIf: "ပန်းနယ်လိုအပ်မှုအခြေအနေ",
      questionsOrder: "ပန်းနယ်အတွင်းမေးခွန်းများ၏အဆင့်",
      page: "ပန်းနယ်ကိုစာမျက်နှာသို့ရွှေ့ပါ",
      startWithNewLine: "ပန်းနယ်ကိုလိုင်းအသစ်တွင်ပြသပါ",
      state: "ပန်းနယ်အတိုးအကျိုးအခြေအနေ",
      width: "ပန်းနယ်အကျယ်",
      minWidth: "အနည်းဆုံးပန်းနယ်အကျယ်",
      maxWidth: "အများဆုံးပန်းနယ်အကျယ်",
      showNumber: "ပန်းနယ်နံပါတ်ကိုပြပါ"
    },
    paneldynamic: {
      name: "ပန်းနယ်အမည်",
      title: "ပန်းနယ်ခေါင်းစဉ်",
      description: "ပန်းနယ်ဖော်ပြချက်",
      visibleIf: "ပန်းနယ်ကိုမြင်သာစေပါ",
      requiredIf: "ပန်းနယ်လိုအပ်မှုအခြေအနေ",
      page: "ပန်းနယ်ကိုစာမျက်နှာသို့ရွှေ့ပါ",
      startWithNewLine: "ပန်းနယ်ကိုလိုင်းအသစ်တွင်ပြသပါ",
      state: "ပန်းနယ်အတိုးအကျိုးအခြေအနေ",
      width: "ပန်းနယ်အကျယ်",
      minWidth: "အနည်းဆုံးပန်းနယ်အကျယ်",
      maxWidth: "အများဆုံးပန်းနယ်အကျယ်",
      confirmDelete: "ပန်းနယ်ဖျက်မှုအတည်ပြုပါ",
      templateDescription: "ပန်းနယ်ဖော်ပြချက်နမူနာ",
      templateTitle: "ပန်းနယ်ခေါင်းစဉ်နမူနာ",
      noEntriesText: "ပန်းနယ်အကြောင်းအရာမရှိပါ",
      templateTabTitle: "Tab ခေါင်းစဉ်နမူနာ",
      tabTitlePlaceholder: "Tab ခေါင်းစဉ်အစားထိုး",
      templateVisibleIf: "ပန်းနယ်တစ်ခုချင်းစီကိုမြင်သာစေပါ",
      hideNumber: "ပန်းနယ်နံပါတ်ကိုဖျောက်ပါ",
      titleLocation: "ပန်းနယ်ခေါင်းစဉ်တည်နေရာ",
      descriptionLocation: "ပန်းနယ်ဖော်ပြချက်တည်နေရာ",
      templateTitleLocation: "မေးခွန်းခေါင်းစဉ်တည်နေရာ",
      templateErrorLocation: "အမှားစာစောင်တည်နေရာ",
      newPanelPosition: "ပန်းနယ်အသစ်တည်နေရာ",
      showRangeInProgress: "တိုးတက်မှုဘားကိုပြပါ",
      showProgressBar: "တိုးတက်မှုဘားကိုပြပါ",
      progressBarLocation: "တိုးတက်မှုဘားတည်နေရာ",
      keyName: "ထပ်တူဖြေဆိုမှုကိုတားဆီးပါ"
    },
    question: {
      name: "မေးခွန်းအမည်",
      title: "မေးခွန်းခေါင်းစဉ်",
      description: "မေးခွန်းဖော်ပြချက်",
      visibleIf: "မေးခွန်းကိုမြင်သာစေပါ",
      requiredIf: "မေးခွန်းလိုအပ်မှုအခြေအနေ",
      page: "မေးခွန်းကိုစာမျက်နှာသို့ရွှေ့ပါ",
      state: "မေးခွန်းဘောက်စ်အတိုးအကျိုးအခြေအနေ",
      hideNumber: "မေးခွန်းနံပါတ်ကိုဖျောက်ပါ",
      titleLocation: "မေးခွန်းခေါင်းစဉ်တည်နေရာ",
      descriptionLocation: "မေးခွန်းဖော်ပြချက်တည်နေရာ",
      errorLocation: "အမှားစာတည်နေရာ",
      indent: "အတွင်းအကွာအဝေးတိုးရန်",
      width: "မေးခွန်းအကျယ်",
      minWidth: "မေးခွန်းအနည်းဆုံးအကျယ်",
      maxWidth: "မေးခွန်းအများဆုံးအကျယ်",
      textUpdateMode: "အကွက်တန်ဖိုးကိုမွမ်းမံပါ"
    },
    signaturepad: {
      signatureWidth: "လက်မှတ်ဧရိယာအကျယ်",
      signatureHeight: "လက်မှတ်ဧရိယာအမြင့်",
      signatureAutoScaleEnabled: "လက်မှတ်ဧရိယာကိုအလိုအလျောက်ချဲ့ရန်",
      showPlaceholder: "လက်မှတ်ဧရိယာတွင် placeholder ကိုပြပါ",
      placeholder: "Placeholder စာသား",
      placeholderReadOnly: "ဖတ်ရုံသာ/ကြိုတင်ကြည့်ရန်အခြေအနေအတွက် Placeholder စာသား",
      allowClear: "လက်မှတ်ဧရိယာတွင်ရှင်း button ကိုပြပါ",
      penMinWidth: "အနည်းဆုံးstroke အကျယ်",
      penMaxWidth: "အများဆုံးstroke အကျယ်",
      penColor: "stroke အရောင်"
    },
    comment: {
      rows: "အကွက်အမြင့် (လိုင်းများဖြင့်)"
    },
    showQuestionNumbers: "မေးခွန်းနံပါတ်ပြခြင်း",
    questionStartIndex: "မေးခွန်းအညွှန်းအမျိုးအစား",
    expression: {
      name: "ဖော်ပြချက်အမည်",
      title: "ဖော်ပြချက်ခေါင်းစဉ်",
      description: "ဖော်ပြချက်ဖော်ပြချက်",
      expression: "ဖော်ပြချက်"
    },
    trigger: {
      expression: "ဖော်ပြချက်"
    },
    calculatedvalue: {
      expression: "ဖော်ပြချက်"
    },
    survey: {
      title: "စစ်တမ်းခေါင်းစဉ်",
      description: "စစ်တမ်းဖော်ပြချက်"
    },
    page: {
      name: "စာမျက်နှာအမည်",
      title: "စာမျက်နှာခေါင်းစဉ်",
      description: "စာမျက်နှာဖော်ပြချက်",
      visibleIf: "စာမျက်နှာကိုမြင်သာစေပါ",
      requiredIf: "စာမျက်နှာလိုအပ်မှုအခြေအနေ",
      timeLimit: "စာမျက်နှာကိုပြီးစီးရန်အချိန်ကန့်သတ်",
      questionsOrder: "စာမျက်နှာပေါ်မေးခွန်းများအဆင့်"
    },
    matrixdropdowncolumn: {
      name: "ကော်လံအမည်",
      title: "ကော်လံခေါင်းစဉ်",
      isUnique: "ထပ်တူဖြေဆိုမှုကိုတားဆီးပါ",
      width: "ကော်လံအကျယ်",
      minWidth: "ကော်လံအနည်းဆုံးအကျယ်",
      rows: "အကွက်အမြင့် (လိုင်းများဖြင့်)",
      visibleIf: "ကော်လံကိုမြင်သာစေပါ",
      requiredIf: "ကော်လံလိုအပ်မှုအခြေအနေ",
      showInMultipleColumns: "ရွေးချယ်မှုတိုင်းကိုကော်လံတစ်ခုစီတွင်ပြပါ"
    },
    multipletextitem: {
      name: "အမည်",
      title: "ခေါင်းစဉ်"
    },
    masksettings: {
      saveMaskedValue: "စစ်တမ်းရလဒ်တွင် masked တန်ဖိုးကိုသိမ်းဆည်းပါ"
    },
    patternmask: {
      pattern: "တန်ဖိုးနမူနာ"
    },
    datetimemask: {
      min: "အနည်းဆုံးတန်ဖိုး",
      max: "အများဆုံးတန်ဖိုး"
    },
    numericmask: {
      allowNegativeValues: "အနုတ်တန်ဖိုးများကိုခွင့်ပြုပါ",
      thousandsSeparator: "ထောင်ကျော်ကွာခြားစရာ",
      decimalSeparator: "ဒသမကွာခြားစရာ",
      precision: "တန်ဖိုးတိကျမှု",
      min: "အနည်းဆုံးတန်ဖိုး",
      max: "အများဆုံးတန်ဖိုး"
    },
    currencymask: {
      prefix: "ငွေကြေးစနစ်နာမည်",
      suffix: "ငွေကြေးနောက်ဆက်"
    },
    imageHeight: "ပုံအမြင့်",
    imageWidth: "ပုံအကျယ်",
    valueName: "တန်ဖိုးအမည်",
    rateDescriptionLocation: "အချက်အလက်ဖော်ပြချက်တည်နေရာ",
    size: "အဝင်အကွက်အကျယ် (အက္ခရာများဖြင့်)",
    cellErrorLocation: "အကွက်အမှားစာတန်းတည်နေရာ",
    enabled: "ဖွင့်ထားသည်",
    disabled: "ပိတ်ထားသည်",
    inherit: "အမွေဆက်ခံသည်",
    apply: "လက်ခံပါ",
    ok: "အိုကေ",
    save: "သိမ်းဆည်းပါ",
    clear: "ရှင်းလင်းပါ",
    saveTooltip: "သိမ်းဆည်းပါ",
    cancel: "မလုပ်တော့ပါ",
    set: "သတ်မှတ်ပါ",
    reset: "ပြန်လည်သတ်မှတ်ပါ",
    change: "ပြောင်းလဲပါ",
    refresh: "နောက်ဆုံးသတ်မှတ်မှု",
    close: "ပိတ်ပါ",
    delete: "ဖျက်ပါ",
    add: "ထည့်ပါ",
    addNew: "အသစ်ထည့်ပါ",
    addItem: "အရာတစ်ခုထည့်ရန်နှိပ်ပါ...",
    removeItem: "အရာကိုဖျက်ရန်နှိပ်ပါ...",
    dragItem: "အရာကိုဆွဲပါ",
    addOther: "အခြား",
    addSelectAll: "အားလုံးရွေးပါ",
    addNone: "မရှိပါ",
    removeAll: "အားလုံးဖျက်ပါ",
    edit: "တည်းဖြတ်ပါ",
    back: "သိမ်းဆည်းမှုမရှိဘဲပြန်သွားပါ",
    backTooltip: "သိမ်းဆည်းမှုမရှိဘဲပြန်သွားပါ",
    saveAndBack: "သိမ်းဆည်းပြီးပြန်သွားပါ",
    saveAndBackTooltip: "သိမ်းဆည်းပြီးပြန်သွားပါ",
    doneEditing: "ပြီးသွားသည်",
    editChoices: "ရွေးချယ်မှုများကိုတည်းဖြတ်ပါ",
    showChoices: "ရွေးချယ်မှုများကိုပြပါ",
    move: "ရွှေ့ပါ",
    empty: "<ဗလာ>",
    emptyValue: "တန်ဖိုးသည်ဗလာဖြစ်သည်",
    fastEntry: "ကိုယ်တိုင်ရိုက်ထည့်သည်",
    fastEntryNonUniqueError: "တန်ဖိုး '{0}' သည်ထူးခြားမှုမရှိပါ",
    fastEntryChoicesCountError: "{0} မှ {1} အထိအရာအရေအတွက်ကိုကန့်သတ်ပါ",
    fastEntryChoicesMinCountError: "{0} အရာအနည်းဆုံးရိုက်ထည့်ပါ",
    fastEntryPlaceholder: "ရွေးချယ်မှုများနှင့်၎င်းတို့၏ ID များကိုအောက်ပါပုံစံဖြင့်ရိုက်ထည့်ပါ:\n\nid|option\n\nရွေးချယ်မှု ID သည်ဖြေဆိုသူများကိုမမြင်နိုင်ပါ၊ စည်းမျဉ်းများတွင်အသုံးပြုနိုင်သည်။",
    formEntry: "ပုံစံရိုက်ထည့်ခြင်း",
    testService: "ဝန်ဆောင်မှုကိုစမ်းသပ်ပါ",
    itemSelectorEmpty: "အရာကိုရွေးချယ်ပါ",
    conditionActionEmpty: "လုပ်ဆောင်ချက်ကိုရွေးချယ်ပါ",
    conditionSelectQuestion: "မေးခွန်းကိုရွေးပါ...",
    conditionSelectPage: "စာမျက်နှာကိုရွေးပါ...",
    conditionSelectPanel: "ပန်းနယ်ကိုရွေးပါ...",
    conditionValueQuestionTitle: "တန်ဖိုးကိုရိုက်ထည့်/ရွေးချယ်ပါ",
    aceEditorHelp: "ဖော်ပြချက်အတွက်အကြံပြုချက်ရရန် ctrl+space နှိပ်ပါ",
    aceEditorRowTitle: "လက်ရှိအတန်း",
    aceEditorPanelTitle: "လက်ရှိပန်းနယ်",
    showMore: "အသေးစိတ်အချက်အလက်များအတွက်စာရွက်ကိုကြည့်ပါ",
    assistantTitle: "ရရှိနိုင်သောမေးခွန်းများ",
    cellsEmptyRowsColumns: "အနည်းဆုံးကော်လံတစ်ခု သို့မဟုတ်အတန်းတစ်ခုရှိရမည်",
    showPreviewBeforeComplete: "ဖြေဆိုမှုမပေးမီကြည့်ရှုပါ",
    overridingPropertyPrefix: "အခြေအနေအရဖွင့်ထားသည်",
    resetToDefaultCaption: "မူလအတိုင်းပြန်ထားပါ",
    propertyIsEmpty: "တန်ဖိုးတစ်ခုရိုက်ထည့်ပါ",
    propertyIsNoUnique: "ထူးခြားမှုရှိသောတန်ဖိုးတစ်ခုရိုက်ထည့်ပါ",
    propertyNameIsNotUnique: "ထူးခြားသောအမည်ရိုက်ထည့်ပါ",
    propertyNameIsIncorrect: "\"item\", \"choice\", \"panel\", \"row\" စသည့်အသုံးချထားသောစကားလုံးများကိုမသုံးပါနှင့်။",
    listIsEmpty: "သင်တွင်မည်သည့်အရာမျှမရှိသေးပါ",
    "listIsEmpty@choices": "သင်တွင်မည်သည့်ရွေးချယ်မှုမျှမရှိသေးပါ",
    "listIsEmpty@columns": "သင်တွင်မည်သည့်ကော်လံမျှမရှိသေးပါ",
    "listIsEmpty@rows": "သင်တွင်မည်သည့်အတန်းမျှမရှိသေးပါ",
    "listIsEmpty@validators": "သင်တွင်မည်သည့်စစ်မှန်မှုစည်းမျဉ်းမရှိသေးပါ",
    "listIsEmpty@calculatedValues": "သင်တွင်မည်သည့်စိတ်ကြိုက် variable မျှမရှိသေးပါ",
    "listIsEmpty@triggers": "သင်တွင်မည်သည့် trigger မျှမရှိသေးပါ",
    "listIsEmpty@navigateToUrlOnCondition": "သင်တွင်မည်သည့်လင့်မျှမရှိသေးပါ",
    "listIsEmpty@pages": "သင်တွင်မည်သည့်စာမျက်နှာမျှမရှိသေးပါ",
    "addNew@choices": "ရွေးချယ်မှုအသစ်ထည့်ပါ",
    "addNew@columns": "ကော်လံအသစ်ထည့်ပါ",
    "addNew@rows": "အတန်းအသစ်ထည့်ပါ",
    "addNew@validators": "စည်းမျဉ်းအသစ်ထည့်ပါ",
    "addNew@calculatedValues": "variable အသစ်ထည့်ပါ",
    "addNew@triggers": "trigger အသစ်ထည့်ပါ",
    "addNew@navigateToUrlOnCondition": "URL အသစ်ထည့်ပါ",
    "addNew@pages": "စာမျက်နှာအသစ်ထည့်ပါ",
    expressionIsEmpty: "ဖော်ပြချက်သည်ဗလာဖြစ်သည်",
    value: "တန်ဖိုး",
    text: "စာသား",
    rowid: "အတန်း ID",
    imageLink: "ပုံ သို့မဟုတ် ဗီဒီယိုဖိုင် URL",
    columnEdit: "ကော်လံကိုတည်းဖြတ်ပါ: {0}",
    itemEdit: "အရာကိုတည်းဖြတ်ပါ: {0}",
    url: "URL",
    path: "ဒေတာသို့ခရီးကြောင်း",
    choicesbyurl: {
      url: "ဝဘ်ဝန်ဆောင်မှု URL",
      valueName: "သိမ်းဆည်းရန်တန်ဖိုးကိုအောက်ပါပိုင်ဆိုင်မှုမှရယူပါ"
    },
    titleName: "ပြသရန်တန်ဖိုးကိုအောက်ပါပိုင်ဆိုင်မှုမှရယူပါ",
    imageLinkName: "ဖိုင် URL များကိုအောက်ပါပိုင်ဆိုင်မှုမှရယူပါ",
    allowEmptyResponse: "ဗလာဖြေဆိုမှုကိုလက်ခံပါ",
    titlePlaceholder: "ခေါင်းစဉ်",
    surveyTitlePlaceholder: "စစ်တမ်းခေါင်းစဉ်",
    pageTitlePlaceholder: "စာမျက်နှာ {num}",
    descriptionPlaceholder: "ဖော်ပြချက်",
    surveyDescriptionPlaceholder: "ဖော်ပြချက်",
    pageDescriptionPlaceholder: "ဖော်ပြချက်",
    textWrapEnabled: "ရွေးချယ်မှုများကိုထုပ်ပိုးပါ",
    showOtherItem: "\"အခြား\" ရွေးချယ်မှုကိုဖွင့်ပါ",
    otherText: "\"အခြား\" ရွေးချယ်မှုကိုအမည်ပြောင်းပါ",
    showNoneItem: "\"မရှိ\" ရွေးချယ်မှုကိုဖွင့်ပါ",
    showRefuseItem: "\"မဖြေဆိုလိုပါ\" ရွေးချယ်မှုကိုဖွင့်ပါ",
    showDontKnowItem: "\"မသိပါ\" ရွေးချယ်မှုကိုဖွင့်ပါ",
    noneText: "\"မရှိ\" ရွေးချယ်မှုကိုအမည်ပြောင်းပါ",
    showSelectAllItem: "\"အားလုံးရွေးချယ်ပါ\" ရွေးချယ်မှုကိုဖွင့်ပါ",
    selectAllText: "\"အားလုံးရွေးချယ်ပါ\" ရွေးချယ်မှုကိုအမည်ပြောင်းပါ",
    choicesMin: "အလိုအလျောက်ဖန်တီးထားသောအရာများအတွက်အနည်းဆုံးတန်ဖိုး",
    choicesMax: "အလိုအလျောက်ဖန်တီးထားသောအရာများအတွက်အများဆုံးတန်ဖိုး",
    choicesStep: "အလိုအလျောက်ဖန်တီးထားသောအရာများအတွက်အဆင့်တန်ဖိုး",
    name: "အမည်",
    title: "ခေါင်းစဉ်",
    cellType: "အကွက်အမျိုးအစား",
    colCount: "ကော်လံအရေအတွက်",
    choicesOrder: "ရွေးချယ်မှုအစီအစဉ်",
    visible: "မြင်သာမှု",
    isRequired: "လိုအပ်သည်",
    markRequired: "လိုအပ်သည်ဟုအမှတ်အသားပြုရန်",
    removeRequiredMark: "လိုအပ်သည်အမှတ်အသားကိုဖယ်ရှားပါ",
    isAllRowRequired: "အတန်းတိုင်းတွင်ဖြေဆိုမှုလိုအပ်သည်",
    eachRowUnique: "အတန်းများတွင်ထပ်တူဖြေဆိုမှုကိုတားဆီးပါ",
    requiredErrorText: "လိုအပ်သောမေးခွန်းများအတွက်အမှားစာသား",
    startWithNewLine: "မေးခွန်းကိုလိုင်းအသစ်တွင်ပြသပါ",
    rows: "အတန်းများ",
    cols: "ကော်လံများ",
    placeholder: "အကွက်အတွင်း placeholder စာသား",
    showPreview: "ကြိုတင်ကြည့်ရှုဧရိယာပြရန်",
    storeDataAsText: "ဖိုင်အကြောင်းအရာကို JSON ရလဒ်အနေဖြင့်စာသားအဖြစ်သိမ်းဆည်းပါ",
    maxSize: "ဖိုင်အရွယ်အစားအများဆုံး (bytes)",
    rowCount: "အတန်းအရေအတွက်",
    columnLayout: "ကော်လံအစီအစဉ်",
    addRowLocation: "\"အတန်းထည့်ပါ\" ခလုတ်တည်နေရာ",
    transposeData: "အတန်းများကိုကော်လံများသို့ပြောင်းလဲပါ",
    addRowText: "\"အတန်းထည့်ပါ\" ခလုတ်စာသား",
    removeRowText: "\"အတန်းဖျက်ပါ\" ခလုတ်စာသား",
    rateMin: "အနည်းဆုံးအဆင့်သတ်မှတ်တန်ဖိုး",
    rateMax: "အများဆုံးအဆင့်သတ်မှတ်တန်ဖိုး",
    rateStep: "အဆင့်တန်ဖိုး",
    minRateDescription: "အနည်းဆုံးတန်ဖိုးတန်းလုံး",
    maxRateDescription: "အများဆုံးတန်ဖိုးတန်းလုံး",
    inputType: "အဝင်အမျိုးအစား",
    optionsCaption: "ရွေးချယ်မှု placeholder",
    defaultValue: "ပုံမှန်ဖြေဆိုမှု",
    cellsDefaultRow: "ပုံမှန်စာသားများ",
    surveyEditorTitle: "စစ်တမ်းဆက်တင်များကိုတည်းဖြတ်ပါ",
    qEditorTitle: "တည်းဖြတ်ပါ: {0}",
    maxLength: "အက္ခရာအများဆုံးကန့်သတ်ချက်",
    buildExpression: "ဖန်တီးပါ",
    editExpression: "တည်းဖြတ်ပါ",
    and: "နှင့်",
    or: "သို့မဟုတ်",
    remove: "ဖယ်ရှားပါ",
    addCondition: "အခြေအနေထည့်ပါ",
    emptyLogicPopupMessage: "အခြေအနေများကိုဖန်တီးရန်မေးခွန်းတစ်ခုကိုရွေးပါ။",
    if: "ထိုအခါ",
    then: "ပြီးနောက်",
    setToName: "ရည်မှန်းမေးခွန်း",
    fromName: "ဖြေဆိုမှုကိုကူးယူမည့်မေးခွန်း",
    gotoName: "ကျော်သွားမည့်မေးခွန်း",
    ruleIsNotSet: "စည်းမျဉ်းမမှန်ကန်ပါ",
    includeIntoResult: "စစ်တမ်းရလဒ်တွင်ထည့်ပါ",
    showTitle: "ခေါင်းစဉ်နှင့်ဖော်ပြချက်ကိုမြင်သာစေပါ",
    expandCollapseTitle: "ခေါင်းစဉ်ကိုချဲ့/ကျယ်အောင်ပြုလုပ်ပါ",
    locale: "စစ်တမ်းဘာသာစကားရွေးပါ",
    simulator: "စက်ပစ္စည်းအမျိုးအစားရွေးချယ်ပါ",
    landscapeOrientation: "လျှာအနေအထားသို့ပြောင်းပါ",
    portraitOrientation: "ရုပ်ပုံအနေအထားသို့ပြောင်းပါ",
    mode: "စစ်တမ်းပြသမှု mode",
    clearInvisibleValues: "မမြင်သာသောမေးခွန်းတန်ဖိုးများကိုရှင်းလင်းပါ",
    cookieName: "တစ်ဖြေဆိုသူအပေါ်ကန့်သတ်",
    sendResultOnPageNext: "စာမျက်နှာပြောင်းလဲမှုတွင်စစ်တမ်းတိုးတက်မှုကိုအလိုအလျောက်သိမ်းဆည်းပါ",
    storeOthersAsComment: "\"အခြား\" ရွေးချယ်မှုတန်ဖိုးကိုအခြား property အနေဖြင့်သိမ်းဆည်းပါ",
    showPageTitles: "စာမျက်နှာခေါင်းစဉ်များကိုပြပါ",
    showPageNumbers: "စာမျက်နှာနံပါတ်များကိုပြပါ",
    pagePrevText: "\"ယခင်စာမျက်နှာ\" ခလုတ်စာသား",
    pageNextText: "\"နောက်စာမျက်နှာ\" ခလုတ်စာသား",
    completeText: "\"စစ်တမ်းပြီးစီးပါ\" ခလုတ်စာသား",
    previewText: "\"ဖြေဆိုမှုများကိုကြည့်ရှုပါ\" ခလုတ်စာသား",
    editText: "\"ဖြေဆိုမှုကိုတည်းဖြတ်ပါ\" ခလုတ်စာသား",
    startSurveyText: "\"စစ်တမ်းစတင်ပါ\" ခလုတ်စာသား",
    showNavigationButtons: "တည်ဆောင်ခလုတ်များကိုပြ/ဖျောက်ပါ",
    showPrevButton: "\"ယခင်စာမျက်နှာ\" ခလုတ်ကိုပြပါ",
    firstPageIsStarted: "ပထမစာမျက်နှာသည်စတင်စာမျက်နှာဖြစ်သည်",
    showCompletedPage: "\"ကျေးဇူးတင်\" စာမျက်နှာကိုပြပါ",
    goNextPageAutomatic: "နောက်စာမျက်နှာသို့အလိုအလျောက်ဆက်လက်သွားပါ",
    allowCompleteSurveyAutomatic: "စစ်တမ်းကိုအလိုအလျောက်ပြီးစီးစေပါ",
    showProgressBar: "တိုးတက်မှုဘားတည်နေရာ",
    questionTitleLocation: "မေးခွန်းခေါင်းစဉ်တည်နေရာ",
    questionTitleWidth: "မေးခွန်းခေါင်းစဉ်အကျယ်",
    requiredText: "လိုအပ်သည့်အမှတ်အသားများ",
    questionTitleTemplate: "မေးခွန်းခေါင်းစဉ်ပုံစံ, ပုံမှန်မှာ: '{no}. {require} {title}'",
    questionErrorLocation: "အမှားစာတန်းတည်နေရာ",
    focusFirstQuestionAutomatic: "စာမျက်နှာအသစ်တွင်ပထမဆုံးမေးခွန်းကိုအာရုံစိုက်ပါ",
    questionsOrder: "မေးခွန်းအစီအစဉ်",
    timeLimit: "စစ်တမ်းပြီးစီးရန်အချိန်ကန့်သတ်",
    timeLimitPerPage: "စာမျက်နှာတစ်ခုပြီးစီးရန်အချိန်ကန့်သတ်",
    showTimer: "အချိန်တိုင်းကိရိယာကိုအသုံးပြုပါ",
    timerLocation: "အချိန်တိုင်းကိရိယာတည်နေရာ",
    timerInfoMode: "အချိန် mode",
    renderMode: "ပန်းနယ်ပြသမှု mode",
    allowAddPanel: "ပန်းနယ်ထည့်ရန်ဖွင့်ပါ",
    allowRemovePanel: "ပန်းနယ်ဖျက်ရန်ဖွင့်ပါ",
    panelAddText: "\"ပန်းနယ်ထည့်ပါ\" ခလုတ်စာသား",
    panelRemoveText: "\"ပန်းနယ်ဖျက်ပါ\" ခလုတ်စာသား",
    isSinglePage: "အရာအားလုံးကိုတစ်စာမျက်နှာတွင်ပြပါ",
    html: "HTML ဖွဲ့စည်းမှု",
    setValue: "ဖြေဆိုမှု",
    dataFormat: "သိမ်းဆည်းမှုပုံစံ",
    allowAddRows: "အတန်းထည့်ရန်ဖွင့်ပါ",
    allowRemoveRows: "အတန်းဖျက်ရန်ဖွင့်ပါ",
    allowRowsDragAndDrop: "အတန်းများကိုပြောင်းရွှေ့နိုင်သည်",
    responsiveImageSizeHelp: "သင့်တိတိအကျယ် သို့မဟုတ်အမြင့်ကိုသတ်မှတ်မထားလျှင်လက်ခံမည်မဟုတ်ပါ။",
    minImageWidth: "အနည်းဆုံးပုံအကျယ်",
    maxImageWidth: "အများဆုံးပုံအကျယ်",
    minImageHeight: "အနည်းဆုံးပုံအမြင့်",
    maxImageHeight: "အများဆုံးပုံအမြင့်",
    minValue: "အနည်းဆုံးတန်ဖိုး",
    maxValue: "အများဆုံးတန်ဖိုး",
    caseInsensitive: "အရေးအသားမလိုအပ်ပါ",
    minLength: "အနည်းဆုံးအရှည် (အက္ခရာဖြင့်)",
    allowDigits: "ဂဏန်းများကိုခွင့်ပြုပါ",
    minCount: "အနည်းဆုံးအရေအတွက်",
    maxCount: "အများဆုံးအရေအတွက်",
    regex: "ပုံမှန်ဖော်ပြချက်",
    surveyvalidator: {
      text: "အမှားစာတန်း",
      expression: "စစ်မှန်မှုဖော်ပြချက်"
    },
    totalText: "စုစုပေါင်းအတန်းခေါင်းစဉ်",
    totalType: "စုစုပေါင်းနည်းလမ်း",
    totalExpression: "စုစုပေါင်းတန်ဖိုးဖော်ပြချက်",
    totalDisplayStyle: "စုစုပေါင်းတန်ဖိုးပြသမှုပုံစံ",
    totalCurrency: "ငွေကြေး",
    totalFormat: "ဖော်မတ်ထားသော string",
    logo: "စစ်တမ်းလိုဂို",
    questionsOnPageMode: "စစ်တမ်းအကွာအဝေး",
    maxTextLength: "ဖြေဆိုမှုအရှည်ကိုကန့်သတ်ပါ",
    maxOthersLength: "မှတ်ချက်အရှည်ကိုကန့်သတ်ပါ",
    commentAreaRows: "မှတ်ချက်ဧရိယာအမြင့် (လိုင်းများဖြင့်)",
    autoGrowComment: "မှတ်ချက်ဧရိယာကိုအလိုအလျောက်ချဲ့ပါ",
    allowResizeComment: "အသုံးပြုသူများအားမှတ်ချက်ဧရိယာအရွယ်အစားပြောင်းနိုင်ခွင့်ပြုပါ",
    textUpdateMode: "အကွက်တန်ဖိုးများကိုမွမ်းမံပါ",
    maskType: "အဝင် mask အမျိုးအစား",
    focusOnFirstError: "ပထမဆုံးမမှန်ကန်သောဖြေဆိုမှုကိုအာရုံစိုက်ပါ",
    checkErrorsMode: "စစ်မှန်မှုပြုလုပ်ပါ",
    validateVisitedEmptyFields: "လွတ်လပ်သောအကွက်များကိုအာရုံစိုက်မှုဆုံးရှုံးပြီးနောက်စစ်ဆေးပါ",
    navigateToUrl: "တင်သွင်းပြီးနောက်ပြင်ပလင့်သို့ပြောင်းရွှေ့ပါ",
    navigateToUrlOnCondition: "ဒိုင်နမစ်ပြင်ပလင့်",
    completedBeforeHtml: "စစ်တမ်းကိုအပြီးသတ်ပြီးဖြစ်ပါကပြသရန်အမှတ်အသား",
    completedHtml: "\"ကျေးဇူးတင်\" စာမျက်နှာအမှတ်အသား",
    completedHtmlOnCondition: "ဒိုင်နမစ် \"ကျေးဇူးတင်\" စာမျက်နှာအမှတ်အသား",
    loadingHtml: "စစ်တမ်းမော်ဒယ်တင်နေစဉ်ပြသရန်အမှတ်အသား",
    commentText: "မှတ်ချက်ဧရိယာစာသား",
    autocomplete: "အလိုအလျောက်ပြည့်စုံမှုအမျိုးအစား",
    labelTrue: "\"မှန်\" အတွက်တံဆိပ်",
    labelFalse: "\"မှား\" အတွက်တံဆိပ်",
    allowClear: "ရှင်းလင်းမှုခလုတ်ကိုပြပါ",
    searchMode: "ရှာဖွေမှု Mode",
    displayStyle: "ပြသမှုပုံစံ",
    format: "ဖော်မတ်ထားသော string",
    maximumFractionDigits: "အများဆုံးဒသမအခန်းကဏ္ဍ",
    minimumFractionDigits: "အနည်းဆုံးဒသမအခန်းကဏ္ဍ",
    useGrouping: "အုပ်စုခွဲရန်ကွာခြားစရာပြသပါ",
    allowMultiple: "မည်သည့်ဖိုင်များကိုတင်နိုင်ရန်ခွင့်ပြုပါ",
    allowImagesPreview: "တင်ထားသောပုံများကိုကြိုတင်ကြည့်ရှုပါ",
    acceptedTypes: "လက်ခံနိုင်သောဖိုင်အမျိုးအစားများ",
    waitForUpload: "တင်ပို့မှုပြီးစီးရန်စောင့်ပါ",
    needConfirmRemoveFile: "ဖိုင်ဖျက်မှုကိုအတည်ပြုပါ",
    detailPanelMode: "အတန်းချဲ့မှုတည်နေရာ",
    minRowCount: "အနည်းဆုံးအတန်းအရေအတွက်",
    maxRowCount: "အများဆုံးအတန်းအရေအတွက်",
    confirmDelete: "အတန်းဖျက်ခြင်းကိုအတည်ပြုပါ",
    confirmDeleteText: "အတည်ပြုစာသား",
    panelCount: "အစပိုင်းပန်းနယ်အရေအတွက်",
    minPanelCount: "အနည်းဆုံးပန်းနယ်အရေအတွက်",
    maxPanelCount: "အများဆုံးပန်းနယ်အရေအတွက်",
    panelsState: "အတွင်းပိုင်းပန်းနယ်အတိုးအကျိုးအခြေအနေ",
    panelPrevText: "\"ယခင်ပန်းနယ်\" ခလုတ်စာသား",
    panelNextText: "\"နောက်ပန်းနယ်\" ခလုတ်စာသား",
    panelRemoveButtonLocation: "\"ပန်းနယ်ဖျက်ပါ\" ခလုတ်တည်နေရာ",
    hideIfRowsEmpty: "အတန်းများမရှိပါကမေးခွန်းကိုဖျောက်ပါ",
    hideColumnsIfEmpty: "အတန်းများမရှိပါကကော်လံများကိုဖျောက်ပါ",
    rateValues: "စိတ်ကြိုက်အဆင့်သတ်မှတ်တန်ဖိုးများ",
    rateCount: "အဆင့်သတ်မှတ်တန်ဖိုးအရေအတွက်",
    autoGenerate: "အဆင့်သတ်မှတ်ဖွဲ့စည်းမှု",
    hideIfChoicesEmpty: "ရွေးချယ်မှုများမရှိပါကမေးခွန်းကိုဖျောက်ပါ",
    minWidth: "အနည်းဆုံးအကျယ်",
    maxWidth: "အများဆုံးအကျယ်",
    width: "အကျယ်",
    showHeader: "ကော်လံခေါင်းစဉ်များကိုပြပါ",
    horizontalScroll: "အလျားလိုက် scroll bar ကိုပြပါ",
    columnMinWidth: "အနည်းဆုံးကော်လံအကျယ်",
    rowTitleWidth: "အတန်းခေါင်းစဉ်အကျယ်",
    valueTrue: "\"မှန်\" ကိုရွေးချယ်သည့်အခါသိမ်းဆည်းရန်တန်ဖိုး",
    valueFalse: "\"မှား\" ကိုရွေးချယ်သည့်အခါသိမ်းဆည်းရန်တန်ဖိုး",
    minErrorText: "\"တန်ဖိုးသည်အနည်းဆုံးမီမနေပါ\" အမှားစာသား",
    maxErrorText: "\"တန်ဖိုးသည်အများဆုံးကျော်လွန်နေသည်\" အမှားစာသား",
    otherErrorText: "\"မှတ်ချက်ဗလာ\" အမှားစာသား",
    keyDuplicationError: "ထပ်တူဖြေဆိုမှုအတွက်အမှားစာသား",
    minSelectedChoices: "ရွေးချယ်မှုအနည်းဆုံးအရေအတွက်",
    maxSelectedChoices: "ရွေးချယ်မှုအများဆုံးအရေအတွက်",
    showClearButton: "ရှင်းလင်းမှုခလုတ်ကိုပြပါ",
    logoWidth: "လိုဂိုအကျယ်",
    logoHeight: "လိုဂိုအမြင့်",
    readOnly: "ဖတ်ရုံသာ",
    enableIf: "ဖတ်ရုံသာ mode ကိုပိတ်ပါ",
    emptyRowsText: "\"အတန်းမရှိ\" စာသား",
    separateSpecialChoices: "အထူးရွေးချယ်မှုများကိုခွဲခြားပါ",
    choicesFromQuestion: "အောက်ပါမေးခွန်းမှရွေးချယ်မှုများကိုကူးပါ",
    choicesFromQuestionMode: "မည်သည့်ရွေးချယ်မှုများကိုကူးမည်",
    progressBarShowPageTitles: "တိုးတက်မှုဘားတွင်စာမျက်နှာခေါင်းစဉ်များကိုပြပါ",
    progressBarShowPageNumbers: "တိုးတက်မှုဘားတွင်စာမျက်နှာနံပါတ်များကိုပြပါ",
    showCommentArea: "မှတ်ချက်ဘူးထည့်ပါ",
    commentPlaceholder: "မှတ်ချက်ဘူးအတွက် placeholder စာသား",
    displayRateDescriptionsAsExtremeItems: "တန်းလုံးများကိုအဆုံးဆုံးတန်ဖိုးအဖြစ်ပြပါ",
    rowsOrder: "အတန်းအစီအစဉ်",
    columnsLayout: "ကော်လံအစီအစဉ်",
    columnColCount: "nested ကော်လံအရေအတွက်",
    correctAnswer: "မှန်ကန်သောဖြေဆိုမှု",
    defaultPanelValue: "ပုံမှန်တန်ဖိုးများ",
    cells: "အကွက်စာသားများ",
    fileInputPlaceholder: "ဖိုင်ကိုရွေးချယ်ပါ သို့မဟုတ် ဖိုင်လင့်ကိုကူးထည့်ပါ...",
    keyName: "အောက်ပါကော်လံတွင်ထပ်တူဖြေဆိုမှုကိုတားဆီးပါ",
    itemvalue: {
      visibleIf: "ရွေးချယ်မှုကိုမြင်သာစေပါ",
      enableIf: "ရွေးချယ်မှုကိုရွေးနိုင်စေပါ"
    },
    "itemvalue@rows": {
      visibleIf: "အတန်းကိုမြင်သာစေပါ",
      enableIf: "အတန်းကိုတည်းဖြတ်နိုင်စေပါ"
    },
    imageitemvalue: {
      text: "ပုံဆိုင်ရာစာသား (Alt text)"
    },
    logoPosition: "လိုဂိုတည်နေရာ",
    addLogo: "လိုဂိုထည့်ပါ...",
    changeLogo: "လိုဂိုပြောင်းပါ...",
    logoPositions: {
      none: "လိုဂိုဖျက်ပါ",
      left: "ဘယ်",
      right: "ညာ",
      top: "အပေါ်တွင်",
      bottom: "အောက်တွင်"
    },
    tabs: {
      panel: {
        layout: "ပန်းနယ်ပုံစံ"
      },
      general: "အထွေထွေ",
      fileOptions: "ရွေးချယ်မှုများ",
      html: "HTML တည်းဖြတ်ကိရိယာ",
      columns: "ကော်လံများ",
      rows: "အတန်းများ",
      choices: "ရွေးချယ်မှုများ",
      items: "အရာများ",
      visibleIf: "မြင်သာမှု အခြေအနေ",
      enableIf: "ရွေးနိုင်မှု အခြေအနေ",
      requiredIf: "လိုအပ်မှု အခြေအနေ",
      rateValues: "အဆင့်သတ်မှတ်တန်ဖိုးများ",
      choicesByUrl: "ဝဘ်ဝန်ဆောင်မှုမှရွေးချယ်မှုများ",
      matrixChoices: "ပုံမှန်ရွေးချယ်မှုများ",
      multipleTextItems: "စာအဝင်များ",
      numbering: "နံပါတ်ပေးခြင်း",
      validators: "စစ်မှန်မှုစီမံခြင်း",
      navigation: "အတန်းဖြတ်သန်းမှု",
      question: "မေးခွန်းဆက်တင်များ",
      pages: "စာမျက်နှာများ",
      timer: "ကွစ်ပြုလုပ်မှု",
      calculatedValues: "တွက်ချက်ထားသောတန်ဖိုးများ",
      triggers: "လှုံ့ဆော်မှုများ",
      templateTitle: "ခေါင်းစဉ်ပုံစံ",
      totals: "စုစုပေါင်းများ",
      logic: "အခြေအနေများ",
      mask: "အဝင် mask ဆက်တင်များ",
      layout: {
        panel: "ပန်းနယ်ပုံစံ",
        question: "ပုံစံ",
        base: "အခြေခံပုံစံ"
      },
      data: "ဒေတာ",
      validation: "စစ်မှန်မှု",
      cells: "အကွက်စာသားများ",
      showOnCompleted: "\"ကျေးဇူးတင်\" စာမျက်နှာ",
      logo: "စစ်တမ်းခေါင်းစဉ်တွင်လိုဂို",
      slider: "ဆလိုက်ဒါ",
      expression: "ဖော်ပြချက်",
      questionSettings: "မေးခွန်းဆက်တင်များ",
      header: "ခေါင်းစီး",
      background: "နောက်ခံ",
      appearance: "အသွင်အပြင်",
      accentColors: "အထင်ကျန်အရောင်များ",
      surfaceBackground: "မျက်နှာပြင်နောက်ခံ",
      scaling: "အရွယ်အစားချိန်ညှိခြင်း",
      others: "အခြား"
    },
    editProperty: "ပိုင်ဆိုင်မှု '{0}' ကိုတည်းဖြတ်ပါ",
    items: "အရာများ",
    choicesVisibleIf: "ရွေးချယ်မှုများကိုမြင်သာစေပါ",
    choicesEnableIf: "ရွေးချယ်မှုများကိုရွေးနိုင်စေပါ",
    columnsEnableIf: "ကော်လံများကိုမြင်သာစေပါ",
    rowsEnableIf: "အတန်းများကိုမြင်သာစေပါ",
    innerIndent: "အတွင်းအကွာအဝေးတိုးရန်",
    defaultValueFromLastRow: "နောက်ဆုံးအတန်းမှဖြေဆိုမှုများကိုပုံမှန်အဖြေနှုန်းထားပါ",
    defaultValueFromLastPanel: "နောက်ဆုံးပန်းနယ်မှဖြေဆိုမှုများကိုပုံမှန်အဖြေနှုန်းထားပါ",
    enterNewValue: "တန်ဖိုးအသစ်ရိုက်ထည့်ပါ။",
    noquestions: "စစ်တမ်းတွင်မေးခွန်းမရှိပါ။",
    createtrigger: "လှုံ့ဆော်မှုတစ်ခုဖန်တီးပါ",
    titleKeyboardAdornerTip: "တည်းဖြတ်ရန် enter ခလုတ်ကိုနှိပ်ပါ",
    keyboardAdornerTip: "အရာတစ်ခုကိုတည်းဖြတ်ရန် enter ကိုနှိပ်ပါ၊ ဖျက်ရန် delete ကိုနှိပ်ပါ၊ အထက်သို့/အောက်သို့ရွှေ့ရန် alt+arrow ကိုနှိပ်ပါ",
    triggerOn: "On",
    triggerMakePagesVisible: "စာမျက်နှာများကိုမြင်သာစေပါ",
    triggerMakeQuestionsVisible: "အရာများကိုမြင်သာစေပါ",
    triggerCompleteText: "စစ်တမ်းကိုအောင်မြင်ပါကပြီးစီးပါ။",
    triggerNotSet: "လှုံ့ဆော်မှုကိုသတ်မှတ်မထားပါ",
    triggerRunIf: "အကယ်၍",
    triggerSetToName: "တန်ဖိုးပြောင်းရန်: ",
    triggerFromName: "တန်ဖိုးကိုကူးယူရန်: ",
    triggerRunExpression: "ဤဖော်ပြချက်ကို run ပြုလုပ်ပါ",
    triggerSetValue: "သို့: ",
    triggerGotoName: "မေးခွန်းသို့သွားပါ",
    triggerIsVariable: "variable ကိုစစ်တမ်းရလဒ်တွင်မထည့်ပါနှင့်။",
    triggerRunExpressionEmpty: "တရားဝင်သောဖော်ပြချက်ကိုရိုက်ထည့်ပါ",
    emptyExpressionPlaceHolder: "ဖော်ပြချက်ကိုဒီမှာရိုက်ထည့်ပါ...",
    noFile: "ရွေးချယ်ထားသောဖိုင်မရှိပါ",
    clearIfInvisible: "မြင်သာမဖြစ်သောမေးခွန်းတန်ဖိုးများကိုရှင်းလင်းပါ",
    valuePropertyName: "အောက်ပါပိုင်ဆိုင်မှုတွင်တန်ဖိုးများကိုသိမ်းဆည်းပါ",
    searchEnabled: "ရှာဖွေရန်ကိုခွင့်ပြုပါ",
    hideSelectedItems: "ရွေးချယ်ထားသောအရာများကိုဖျောက်ပါ",
    closeOnSelect: "ရွေးချယ်မှုအပြီး dropdown ကိုပိတ်ပါ",
    verticalAlign: "အကွက်များအတွင်းလိုက်လျောညီထွေ",
    alternateRows: "အတန်းအလှည့်အပြောင်းအလှန်အရောင်",
    columnsVisibleIf: "ကော်လံများကိုမြင်သာစေပါ",
    rowsVisibleIf: "အတန်းများကိုမြင်သာစေပါ",
    otherPlaceholder: "မှတ်ချက်ဘူးအတွက် placeholder စာသား",
    filePlaceholder: "ဒေသဖိုင်အတွက် placeholder စာသား",
    photoPlaceholder: "ကင်မရာအတွက် placeholder စာသား",
    fileOrPhotoPlaceholder: "ဒေသဖိုင် သို့မဟုတ် ကင်မရာအတွက် placeholder စာသား",
    rateType: "အဆင့်သတ်မှတ်အိုင်ကွန်",
    url_placeholder: "ဥပမာ: https://api.example.com/books",
    path_placeholder: "ဥပမာ: categories.fiction",
    questionStartIndex_placeholder: "ဥပမာ: a)",
    width_placeholder: "ဥပမာ: 6in",
    minWidth_placeholder: "ဥပမာ: 600px",
    maxWidth_placeholder: "ဥပမာ: 50%",
    imageHeight_placeholder: "auto",
    imageWidth_placeholder: "auto",
    theme: {
      themeName: "အဖွဲ့အစည်း",
      isPanelless: "မေးခွန်းအသွင်အပြင်",
      editorPanel: "နောက်ခံနှင့်မူးယစ်ထောင့်",
      questionPanel: "နောက်ခံနှင့်မူးယစ်ထောင့်",
      primaryColor: "အထင်ကြီးသောအရောင်",
      panelBackgroundTransparency: "ပန်းနယ်နှင့်မေးခွန်းဘောက်စ် transparency",
      questionBackgroundTransparency: "အဝင်အကွက် transparency",
      fontSize: "စစ်တမ်းအကြောင်းအရာ font အရွယ်အစား",
      scale: "စစ်တမ်းအရွယ်အစားချိန်ညှိခြင်း",
      cornerRadius: "ထောင့်ဝိုင်းဆ",
      advancedMode: "အဆင့်မြင့် mode",
      pageTitle: "ခေါင်းစဉ် font",
      pageDescription: "ဖော်ပြချက် font",
      questionTitle: "မေးခွန်းခေါင်းစဉ် font",
      questionDescription: "မေးခွန်းဖော်ပြချက် font",
      editorFont: "Font",
      backgroundOpacity: "Transparency",
      "--sjs-font-family": "စစ်တမ်း font မျိုးစုံ",
      "--sjs-general-backcolor-dim": "နောက်ခံအရောင်",
      "--sjs-primary-backcolor": "အထင်ကြီးသောနောက်ခံအရောင်များ",
      "--sjs-primary-forecolor": "အထင်ကြီးသောနောက်ဆုံးအရောင်များ",
      "--sjs-special-red": "အမှားစာတန်းအရောင်များ",
      "--sjs-shadow-small": "အရိပ်သက်ရောက်မှုများ",
      "--sjs-shadow-inner": "အရိပ်သက်ရောက်မှုများ",
      "--sjs-border-default": "အရောင်များ"
    },
    "header@header": {
      headerView: "ကြည့်ရှုမှု",
      logoPosition: "လိုဂိုတည်နေရာ",
      surveyTitle: "စစ်တမ်းခေါင်းစဉ် font",
      surveyDescription: "စစ်တမ်းဖော်ပြချက် font",
      headerTitle: "စစ်တမ်းခေါင်းစဉ် font",
      headerDescription: "စစ်တမ်းဖော်ပြချက် font",
      inheritWidthFrom: "အကြောင်းအရာဧရိယာအကျယ်",
      textAreaWidth: "စာသားအကျယ်",
      backgroundColorSwitch: "နောက်ခံအရောင်",
      backgroundImage: "နောက်ခံပုံ",
      backgroundImageOpacity: "Transparency",
      overlapEnabled: "ကျော်ဖြတ်",
      logoPositionX: "လိုဂိုတည်နေရာ",
      titlePositionX: "စစ်တမ်းခေါင်းစဉ်တည်နေရာ",
      descriptionPositionX: "စစ်တမ်းဖော်ပြချက်တည်နေရာ"
    }
  },
  pv: {
    "true": "မှန်",
    "false": "မမှန်",
    file: "ဒေသဖိုင်",
    camera: "ကင်မရာ",
    "file-camera": "ဒေသဖိုင် သို့မဟုတ် ကင်မရာ",
    inherit: "အမွေဆက်ခံပါ",
    show: "မြင်သာသော",
    hide: "ဖျောက်ထားသော",
    default: "အမွေခံပါ",
    initial: "အစပိုင်း",
    random: "ဆန့်ကျင်မှုမရှိ",
    collapsed: "ချုံ့ထားသည်",
    expanded: "ချဲ့ထားသည်",
    none: "မရှိပါ",
    asc: "အဆင့်စဉ်သည့်",
    desc: "အဆင့်လျော့သည့်",
    indeterminate: "မသတ်မှတ်ရသေးသော",
    selected: "ရွေးချယ်ထားသော",
    unselected: "မရွေးချယ်ထားသော",
    decimal: "ဒသမ",
    currency: "ငွေကြေး",
    percent: "ရာခိုင်နှုန်း",
    firstExpanded: "ပထမပန်းနယ်ကိုချဲ့ထားသည်",
    off: "မေးခွန်းနံပါတ်များကိုဖျောက်ထားပါ",
    list: "စာရင်း",
    carousel: "ကာရုဆဲလ်",
    tab: "Tabs",
    progressTop: "ပန်းနယ်နာဗီဂေတာ + တိုးတက်မှုဘားအပေါ်တွင်",
    progressBottom: "ပန်းနယ်နာဗီဂေတာ + တိုးတက်မှုဘားအောက်တွင်",
    progressTopBottom: "ပန်းနယ်နာဗီဂေတာ + တိုးတက်မှုဘားအပေါ်နှင့်အောက်တွင်",
    horizontal: "အလျားလိုက်",
    vertical: "ဒေါင်လိုက်",
    top: "အပေါ်",
    bottom: "အောက်",
    topBottom: "အပေါ်နှင့်အောက်",
    both: "နှစ်ခုလုံး",
    left: "ဘယ်",
    right: "ညာ",
    center: "အလယ်",
    leftRight: "ဘယ်နှင့်ညာ",
    middle: "အလယ်အလတ်",
    color: "အရောင်",
    date: "ရက်စွဲ",
    datetime: "ရက်စွဲနှင့်အချိန်",
    "datetime-local": "ဒေသဆိုင်ရာရက်စွဲနှင့်အချိန်",
    email: "အီးမေးလ်",
    month: "လ",
    number: "နံပါတ်",
    password: "စကားဝှက်",
    range: "အကွာအဝေး",
    tel: "ဖုန်းနံပါတ်",
    text: "စာသား",
    time: "အချိန်",
    url: "URL",
    week: "အပတ်",
    hidden: "ဖျောက်ထားသည်",
    edit: "တည်းဖြတ်နိုင်သော",
    display: "ဖတ်ရုံသာ",
    contain: "ပါဝင်ပါ",
    cover: "အုပ်ထားပါ",
    fill: "ဖြည့်ပါ",
    next: "နောက်တစ်ခု",
    last: "နောက်ဆုံး",
    onComplete: "စစ်တမ်းပြီးစီးသောအခါ",
    onHidden: "မေးခွန်းကိုဖျောက်သောအခါ",
    onHiddenContainer: "မေးခွန်း သို့မဟုတ် ၎င်း၏ပန်းနယ်/စာမျက်နှာကိုဖျောက်သောအခါ",
    clearInvisibleValues: {
      none: "ဘယ်တော့မှမလုပ်ပါ"
    },
    clearIfInvisible: {
      none: "ဘယ်တော့မှမလုပ်ပါ"
    },
    inputType: {
      color: "အရောင်",
      date: "ရက်စွဲ",
      "datetime-local": "ရက်စွဲနှင့်အချိန်",
      email: "အီးမေးလ်",
      month: "လ",
      number: "နံပါတ်",
      password: "စကားဝှက်",
      range: "အကွာအဝေး",
      tel: "ဖုန်းနံပါတ်",
      text: "စာသား",
      time: "အချိန်",
      url: "URL",
      week: "အပတ်"
    },
    autocomplete: {
      "name": "အမည်အပြည့်အစုံ",
      "honorific-prefix": "ဆက်ဆံကိန်း (Prefix)",
      "given-name": "နာမည်",
      "additional-name": "အလယ်နာမည်",
      "family-name": "မျိုးနွယ်နာမည်",
      "honorific-suffix": "ဆက်ဆံကိန်း (Suffix)",
      "nickname": "အခေါ်အမည်",
      "organization-title": "အလုပ်အကိုင်",
      "username": "အသုံးပြုသူအမည်",
      "new-password": "စကားဝှက်အသစ်",
      "current-password": "လက်ရှိစကားဝှက်",
      "organization": "အဖွဲ့အစည်းအမည်",
      "street-address": "လမ်းလိပ်စာအပြည့်အစုံ",
      "address-line1": "လိပ်စာလိုင်း ၁",
      "address-line2": "လိပ်စာလိုင်း ၂",
      "address-line3": "လိပ်စာလိုင်း ၃",
      "address-level4": "အဆင့် ၄ လိပ်စာ",
      "address-level3": "အဆင့် ၃ လိပ်စာ",
      "address-level2": "အဆင့် ၂ လိပ်စာ",
      "address-level1": "အဆင့် ၁ လိပ်စာ",
      "country": "နိုင်ငံကုဒ်",
      "country-name": "နိုင်ငံအမည်",
      "postal-code": "စာတိုက်ကုဒ်",
      "cc-name": "ကတ်ကိုင်ရှင်အမည်",
      "cc-given-name": "ကတ်ကိုင်ရှင်နာမည်",
      "cc-additional-name": "ကတ်ကိုင်ရှင်အလယ်နာမည်",
      "cc-family-name": "ကတ်ကိုင်ရှင်မျိုးနွယ်နာမည်",
      "cc-number": "ခရက်ဒစ်ကတ်နံပါတ်",
      "cc-exp": "သက်တမ်းကုန်ဆုံးရက်",
      "cc-exp-month": "သက်တမ်းကုန်ဆုံးလ",
      "cc-exp-year": "သက်တမ်းကုန်ဆုံးနှစ်",
      "cc-csc": "ကတ်လုံခြုံရေးကုဒ်",
      "cc-type": "ခရက်ဒစ်ကတ်အမျိုးအစား",
      "transaction-currency": "ငွေလဲနှုန်း",
      "transaction-amount": "ငွေပမာဏ",
      "language": "နှစ်သက်သောဘာသာစကား",
      "bday": "မွေးနေ့",
      "bday-day": "မွေးနေ့ရက်",
      "bday-month": "မွေးနေ့လ",
      "bday-year": "မွေးနေ့နှစ်",
      "sex": "ကျား/မ",
      "url": "ဝဘ်ဆိုက် URL",
      "photo": "ပရိုဖိုင်ပုံ",
      "tel": "တယ်လီဖုန်းနံပါတ်",
      "tel-country-code": "နိုင်ငံကုဒ်",
      "tel-national": "နိုင်ငံရေးတယ်လီဖုန်းနံပါတ်",
      "tel-area-code": "ဒေသကုဒ်",
      "tel-local": "ဒေသတယ်လီဖုန်းနံပါတ်",
      "tel-local-prefix": "ဒေသကုဒ် Prefix",
      "tel-local-suffix": "ဒေသကုဒ် Suffix",
      "tel-extension": "တယ်လီဖုန်း Extension",
      "email": "အီးမေးလ်လိပ်စာ",
      "impp": "ယာယီမက်ဆေ့ချ်ပို့စနစ်"
    },
    maskType: {
      none: "မရှိ",
      pattern: "ပုံစံ",
      numeric: "ဂဏန်း",
      datetime: "ရက်စွဲနှင့်အချိန်",
      currency: "ငွေကြေး"
    },
    inputTextAlignment: {
      auto: "အလိုအလျောက်",
      left: "ဘယ်",
      right: "ညာ"
    },
    all: "အားလုံး",
    page: "စာမျက်နှာ",
    survey: "စစ်တမ်း",
    onNextPage: "နောက်စာမျက်နှာသို့ပြောင်းသောအခါ",
    onValueChanged: "ဖြေဆိုမှုပြောင်းလဲပြီးနောက်",
    onValueChanging: "ဖြေဆိုမှုပြောင်းမီ",
    questionsOnPageMode: {
      standard: "မူလပုံစံ",
      singlePage: "မေးခွန်းအားလုံးကိုတစ်စာမျက်နှာမှာပြပါ",
      questionPerPage: "စာမျက်နှာတစ်ခုလျှင်မေးခွန်းတစ်ခုပြပါ"
    },
    noPreview: "ကြိုတင်ကြည့်ရှုမှုမရှိပါ",
    showAllQuestions: "မေးခွန်းအားလုံးကိုပြပါ",
    showAnsweredQuestions: "ဖြေဆိုထားသောမေးခွန်းများကိုသာပြပါ",
    pages: "ပြီးစီးသောစာမျက်နှာများ",
    questions: "ဖြေဆိုထားသောမေးခွန်းများ",
    requiredQuestions: "လိုအပ်သောမေးခွန်းများကိုဖြေဆိုထားသည်",
    correctQuestions: "မှန်ကန်သောဖြေဆိုမှုများ",
    buttons: "ပြီးစီးသောစာမျက်နှာများ (ခလုတ် UI)",
    underInput: "အဝင်အကွက်အောက်တွင်",
    underTitle: "မေးခွန်းခေါင်းစဉ်အောက်တွင်",
    onBlur: "အာရုံလျော့သောအခါ",
    onTyping: "ရိုက်နေစဉ်",
    underRow: "အတန်းအောက်တွင်",
    underRowSingle: "အတန်းအောက်တွင်၊ တစ်တန်းတည်းချဲ့ထားမှုကိုသာပြပါ",
    auto: "အလိုအလျောက်",
    showNavigationButtons: {
      none: "ဖျောက်ထားသည်"
    },
    timerInfoMode: {
      combined: "နှစ်ခုလုံး"
    },
    addRowLocation: {
      default: "Matrix layout အပေါ်မူတည်သည်"
    },
    panelsState: {
      default: "ချုပ်ထားသည်",
      collapsed: "အားလုံးချုံ့ထားသည်",
      expanded: "အားလုံးချဲ့ထားသည်",
      firstExpanded: "ပထမပန်းနယ်ကိုချဲ့ထားသည်"
    },
    widthMode: {
      static: "အတိအကျ",
      responsive: "တုံ့ပြန်မှု"
    },
    contentMode: {
      image: "ပုံ",
      video: "ဗီဒီယို",
      youtube: "YouTube"
    },
    displayMode: {
      buttons: "ခလုတ်များ",
      dropdown: "Dropdown"
    },
    rateColorMode: {
      default: "ပုံမှန်",
      scale: "အဆင့်ကိန်း"
    },
    scaleColorMode: {
      monochrome: "တစ်ရောင်တည်း",
      colored: "အရောင်စုံ"
    },
    autoGenerate: {
      "true": "အလိုအလျောက်ဖန်တီးပါ",
      "false": "ကိုယ်တိုင်ဖန်တီးပါ"
    },
    rateType: {
      labels: "တန်းလုံးများ",
      stars: "ကြယ်များ",
      smileys: "အပြုံးများ"
    },
    state: {
      default: "ချုပ်ထားသည်"
    },
    showQuestionNumbers: {
      default: "အလိုအလျောက်နံပါတ်ပေးခြင်း",
      on: "အလိုအလျောက်နံပါတ်ပေးခြင်း",
      onPage: "စာမျက်နှာတစ်ခုစီ reset လုပ်ပါ",
      onpanel: "ပန်းနယ်တစ်ခုစီ reset လုပ်ပါ",
      onPanel: "ပန်းနယ်တစ်ခုစီ reset လုပ်ပါ",
      onSurvey: "စစ်တမ်းတစ်ခုလုံးဆက်လက်လုပ်ဆောင်ပါ",
      off: "နံပါတ်မပေးပါ"
    },
    descriptionLocation: {
      underTitle: "မေးခွန်းခေါင်းစဉ်အောက်တွင်",
      underInput: "အဝင်အကွက်အောက်တွင်"
    },
    selectToRankAreasLayout: {
      horizontal: "ရွေးချယ်မှုများ၏နောက်တွင်",
      vertical: "ရွေးချယ်မှုများ၏အပေါ်တွင်"
    },
    displayStyle: {
      decimal: "ဒသမ",
      currency: "ငွေကြေး",
      percent: "ရာခိုင်နှုန်း",
      date: "ရက်စွဲ"
    },
    totalDisplayStyle: {
      decimal: "ဒသမ",
      currency: "ငွေကြေး",
      percent: "ရာခိုင်နှုန်း",
      date: "ရက်စွဲ"
    },
    rowsOrder: {
      initial: "မူလ"
    },
    questionsOrder: {
      initial: "မူလ"
    },
    showProgressBar: {
      off: "ဖျောက်ထားသည်",
      topbottom: "အပေါ်နှင့်အောက်",
      aboveheader: "ခေါင်းစဉ်အထက်",
      belowheader: "ခေါင်းစဉ်အောက်"
    },
    progressBarLocation: {
      "top": "အပေါ်",
      "bottom": "အောက်",
      "topBottom": "အပေါ်နှင့်အောက်"
    },
    sum: "ပေါင်း",
    count: "ရေတွက်",
    min: "အနည်းဆုံး",
    max: "အများဆုံး",
    avg: "ပျမ်းမျှ",
    searchMode: {
      contains: "ပါဝင်သည်",
      startsWith: "ဖြင့်စတင်သည်"
    },
    backgroundImageFit: {
      auto: "အလိုအလျောက်",
      cover: "အုပ်ထားသည်",
      contain: "ပါဝင်သည်",
      fill: "ဖြည့်သည်",
      tile: "Tile"
    },
    backgroundImageAttachment: {
      fixed: "တည်ငြိမ်",
      scroll: "လှိမ့်ပါ"
    },
    headerView: {
      basic: "အခြေခံ",
      advanced: "အဆင့်မြင့်"
    },
    inheritWidthFrom: {
      survey: "စစ်တမ်းနှင့်တူညီသည်",
      container: "ကွန်တိန်နာနှင့်တူညီသည်"
    },
    backgroundColorSwitch: {
      none: "မရှိ",
      accentColor: "အထင်ကြီးသောအရောင်",
      custom: "စိတ်ကြိုက်"
    },
    colorPalette: {
      light: "အလင်း",
      dark: "အမည်း"
    },
    isPanelless: {
      false: "ပုံမှန်",
      true: "ပန်းနယ်မပါဘဲ"
    },
    progressBarInheritWidthFrom: {
      survey: "စစ်တမ်းနှင့်တူညီသည်",
      container: "ကွန်တိန်နာနှင့်တူညီသည်"
    },
  },
  // Operators
  op: {
    empty: "ဗလာ",
    notempty: "ဗလာမဟုတ်",
    equal: "တူညီသည်",
    notequal: "တူညီမည်မဟုတ်",
    contains: "ပါဝင်သည်",
    notcontains: "မပါဝင်ပါ",
    anyof: "မည်သည့်အရာမှ",
    allof: "အားလုံး",
    greater: "ပိုကြီးသော",
    less: "ပိုသေးသော",
    greaterorequal: "ပိုကြီး သို့မဟုတ် တူညီသော",
    lessorequal: "ပိုသေး သို့မဟုတ် တူညီသော",
    and: "နှင့်",
    or: "သို့မဟုတ်"
  },
  ew: {
    angular: "Angular ဗားရှင်းကိုအသုံးပြုပါ",
    jquery: "jQuery ဗားရှင်းကိုအသုံးပြုပါ",
    knockout: "Knockout ဗားရှင်းကိုအသုံးပြုပါ",
    react: "React ဗားရှင်းကိုအသုံးပြုပါ",
    vue: "Vue ဗားရှင်းကိုအသုံးပြုပါ",
    bootstrap: "Bootstrap ဖရိမ်းဝေါ့ကိုအတွက်",
    modern: "ခေတ်မီအထူးအင်္ဂါရပ်",
    default: "ပုံမှန်အထူးအင်္ဂါရပ်",
    orange: "လိမ္မော်ရောင်အထူးအင်္ဂါရပ်",
    darkblue: "Darkblue အထူးအင်္ဂါရပ်",
    darkrose: "Darkrose အထူးအင်္ဂါရပ်",
    stone: "Stone အထူးအင်္ဂါရပ်",
    winter: "Winter အထူးအင်္ဂါရပ်",
    winterstone: "Winter-Stone အထူးအင်္ဂါရပ်",
    showOnPage: "စစ်တမ်းကိုစာမျက်နှာပေါ်တွင်ပြပါ",
    showInWindow: "စစ်တမ်းကိုဝင်းဒိုးတွင်ပြပါ",
    loadFromServer: "Server မှစစ်တမ်း JSON ကိုသွင်းပါ",
    titleScript: "Script များနှင့်ပုံစံများ",
    titleHtml: "HTML",
    titleJavaScript: "JavaScript"
  },
  ts: {
    selectPage: "စမ်းသပ်ရန်စာမျက်နှာကိုရွေးချယ်ပါ",
    showInvisibleElements: "မြင်မရသောအရာများကိုပြပါ",
    hideInvisibleElements: "မြင်မရသောအရာများကိုဖျောက်ပါ"
  },
  validators: {
    answercountvalidator: "ဖြေဆိုမှုအရေအတွက်",
    emailvalidator: "အီးမေးလ်",
    expressionvalidator: "ဖော်ပြချက်",
    numericvalidator: "နံပါတ်",
    regexvalidator: "Regex",
    textvalidator: "စာသား"
  },
  triggers: {
    completetrigger: "စစ်တမ်းကိုပြီးစီးပါ",
    setvaluetrigger: "ဖြေဆိုမှုကိုသတ်မှတ်ပါ",
    copyvaluetrigger: "ဖြေဆိုမှုကိုကူးယူပါ",
    skiptrigger: "မေးခွန်းသို့ဆွဲကြိုး",
    runexpressiontrigger: "ဖော်ပြချက်ကို run ပြုလုပ်ပါ",
    visibletrigger: "မြင်သာမှုကိုပြောင်းလဲပါ (မသုံးတော့ပါ)"
  },
  peplaceholder: {
    patternmask: {
      pattern: "ဥပမာ: +1(999)-999-99-99"
    },
    datetimemask: {
      pattern: "ဥပမာ: mm/dd/yyyy HH:MM:ss"
    },
    currencymask: {
      prefix: "ဥပမာ: $",
      suffix: "ဥပမာ: USD"
    },
    panelbase: {
      questionTitleWidth: "ဥပမာ: 200px"
    },
    panellayoutcolumn: {
      effectiveWidth: "ဥပမာ: 30%",
      questionTitleWidth: "ဥပမာ: 200px"
    }
  },
  pehelp: {
    panel: {
      name: "တုံ့ပြန်သူများအနေဖြင့်မြင်မရသော Panel ID",
      description: "Panel အခေါင်းစဉ်ခွဲစာသားကိုရိုက်ထည့်ပါ",
      visibleIf: "Panel မြင်သာမှုကိုသတ်မှတ်သည့်အခြေအနေစည်းကမ်းကို Magic Wand နိုင်ငံကိစ္စပုံစံကိုအသုံးပြုပါ",
      enableIf: "Panel ကို Read-only mode မဟုတ်စေရန်အခြေအနေစည်းကမ်းကို Magic Wand နိုင်ငံကိစ္စပုံစံကိုအသုံးပြုပါ",
      requiredIf: "Nested မေးခွန်းများတွင်ဖြေဆိုမှုမရှိပါက စစ်တမ်းတင်သွင်းမှုကိုတားဆီးရန်အခြေအနေစည်းကမ်းကို Magic Wand နိုင်ငံကိစ္စပုံစံကိုအသုံးပြုပါ",
      questionTitleLocation: "ဒီ Panel အတွင်းမေးခွန်းများအတွက်ပြဿနာတစ်ခုဖြစ်သည်",
      questionTitleWidth: "CSS တန်ဖိုး (px, %, in, pt, စသည်ဖြင့်) များကိုလက်ခံပါ",
      questionErrorLocation: "Panel အတွင်းမေးခွန်းများနှင့်ဆက်စပ်သောအမှားစာသားတည်နေရာကိုသတ်မှတ်ပါ",
      questionsOrder: "မူလမေးခွန်းအစီအစဉ်ကိုထားသို့မဟုတ် randomize လုပ်ပါ",
      page: "ရွေးချယ်ထားသောစာမျက်နှာ၏အဆုံးသို့ Panel ကိုပြောင်းသွားပါ",
      innerIndent: "Panel content နှင့် Panel box ဘောင်ဘေးကြားတွင် Margin ထည့်ပါ",
      startWithNewLine: "မေးခွန်းတစ်ခုသို့မဟုတ် Panel တစ်ခုနှင့်တစ်လိုင်းအတွင်းတွဲဖက်ပြပါ",
      state: "\"ချဲ့ထားသည်\" - Panel ကိုအပြည့်အဝပြပြီးချုံ့နိုင်သည်၊ \"ချုံ့ထားသည်\" - Panel ကိုခေါင်းစဉ်နှင့်ဖော်ပြချက်သာပြပြီးချဲ့နိုင်သည်၊ \"ချုပ်ထားသည်\" - Panel ကိုအပြည့်အဝပြပြီးချုံ့လို့မရပါ",
      width: "Panel ၏အကျယ်ကိုပုံစံ Element အခြားတစ်ခုနှင့်ချိန်ညှိပါ",
      showQuestionNumbers: "Panel ၏ Nested မေးခွန်းများကိုနံပါတ်ပေးပါ"
    },
    paneldynamic: {
      name: "တုံ့ပြန်သူများအနေဖြင့်မြင်မရသော Panel ID",
      description: "Panel အခေါင်းစဉ်ခွဲစာသားကိုရိုက်ထည့်ပါ",
      visibleIf: "Panel မြင်သာမှုကိုသတ်မှတ်သည့်အခြေအနေစည်းကမ်းကို Magic Wand နိုင်ငံကိစ္စပုံစံကိုအသုံးပြုပါ",
      enableIf: "Panel ကို Read-only mode မဟုတ်စေရန်အခြေအနေစည်းကမ်းကို Magic Wand နိုင်ငံကိစ္စပုံစံကိုအသုံးပြုပါ",
      requiredIf: "Nested မေးခွန်းများတွင်ဖြေဆိုမှုမရှိပါက စစ်တမ်းတင်သွင်းမှုကိုတားဆီးရန်အခြေအနေစည်းကမ်းကို Magic Wand နိုင်ငံကိစ္စပုံစံကိုအသုံးပြုပါ",
      templateTitleLocation: "ဒီ Panel အတွင်းရှိမေးခွန်းများအားလုံးအတွက် သတ်မှတ်ချက်ကိုအလိုအလျောက် သက်ဆိုင်သည်။",
      templateErrorLocation: "မမှန်သောအဝင်ရှိသောမေးခွန်းနှင့်ဆက်စပ်သော အမှားစာသားတည်နေရာကို သတ်မှတ်ပါ။",
      errorLocation: "Panel အတွင်းမေးခွန်းများနှင့်ဆက်စပ်သော အမှားစာသားတည်နေရာကို သတ်မှတ်ပါ။",
      page: "ရွေးချယ်ထားသောစာမျက်နှာ၏ အဆုံးသို့ Panel ကိုပြောင်းသွားပါ",
      innerIndent: "Panel content နှင့် Panel box ဘောင်ဘေးကြားတွင် Margin ထည့်ပါ",
      startWithNewLine: "Panel ကိုတစ်လိုင်းတည်းတွင် ဖော်ပြရန် unselect လုပ်ပါ",
      state: "\"Expanded\" - Panel ကိုအပြည့်အဝပြပြီး ချုံ့နိုင်သည်၊ \"Collapsed\" - Panel ကို ခေါင်းစဉ်နှင့် ဖော်ပြချက်သာပြပြီး ချဲ့နိုင်သည်၊ \"Locked\" - Panel ကိုအပြည့်အဝပြပြီး ချုံ့လို့မရပါ",
      width: "Panel ၏အကျယ်ကို CSS တန်ဖိုး (px, %, in, pt, စသည်ဖြင့်) အသုံးပြု၍ သတ်မှတ်ပါ",
      templateTitle: "Dynamic Panel ခေါင်းစဉ်များအတွက် Template တစ်ခု ရိုက်ထည့်ပါ",
      templateTabTitle: "Tab ခေါင်းစဉ်များအတွက် Template တစ်ခု ရိုက်ထည့်ပါ",
      tabTitlePlaceholder: "Tab ခေါင်းစဉ်များတွင် မတည်ငြိမ်သောအခါ fallback စာသားကိုသတ်မှတ်ပါ",
      templateVisibleIf: "Dynamic Panel အတွင်းရှိ တစ်ခုချင်း panel တွေကို visibility ကိုထိန်းချုပ်နိုင်သည်။",
      titleLocation: "ဒီ Panel အတွင်းရှိမေးခွန်းအားလုံးအတွက် title alignment ကိုအလိုအလျောက် သက်ဆိုင်သည်။",
      descriptionLocation: "Page-level သို့မဟုတ် Survey-level သတ်မှတ်ချက်ကို သက်ဆိုင်သည်",
      newPanelPosition: "အသစ်ထည့်သွင်းထားသော panel ၏တည်နေရာကို သတ်မှတ်ပါ",
      defaultValueFromLastPanel: "နောက်ဆုံး panel မှ ဖြေဆိုမှုများကို ထပ်တူယူပြီး အသစ်ထည့်ထားသော panel ကို သတ်မှတ်ပါ",
      keyName: "အသုံးပြုသူကို တစ်ခုချင်း panel တွင် unique ဖြေဆိုမှုပေးရန် ပြုလုပ်ရန် question name ကိုဖော်ပြပါ"
    },
    defaultValueExpression: "Expression အရ default ဖြေဆိုမှုတန်ဖိုးကို သတ်မှတ်နိုင်သည်။",
    resetValueIf: "Reset value ချိန်ညှိမှုကို Magic Wand icon ဖြင့်သတ်မှတ်ပါ။",
    setValueIf: "Magic Wand icon ကိုအသုံးပြု၍ စည်းမျဉ်းချမှတ်ပါ။",
    setValueExpression: "စည်းမျဉ်းတွေ့ရှိပါက ဖြေဆိုမှုတန်ဖိုးကို dynamically သတ်မှတ်ရန် Expression တစ်ခုဖော်ပြပါ။",
    question: {
      name: "တုံ့ပြန်သူများအနေဖြင့် မမြင်ရသော မေးခွန်း ID",
      description: "မေးခွန်းခေါင်းစဉ်ခွဲစာသားကို ရိုက်ထည့်ပါ",
      visibleIf: "မေးခွန်းမြင်သာမှုကို သတ်မှတ်ရန် Magic Wand icon ကိုအသုံးပြုပါ",
      enableIf: "မေးခွန်းကို Read-only mode မဟုတ်စေရန် Magic Wand icon ကို အသုံးပြုပါ",
      requiredIf: "မေးခွန်းဖြေဆိုမှုမရှိပါက စစ်တမ်းတင်သွင်းမှုကိုတားဆီးရန် စည်းမျဉ်းချမှတ်ပါ",
      startWithNewLine: "မေးခွန်းကိုတစ်လိုင်းတည်းတွင် ဖော်ပြရန် unselect လုပ်ပါ",
      page: "မေးခွန်းကို ရွေးချယ်ထားသော စာမျက်နှာ၏ အဆုံးသို့ ပြောင်းသွားပါ",
      state: "\"Expanded\" - မေးခွန်း box ကို အပြည့်အဝပြပြီး ချုံ့နိုင်သည်၊ \"Collapsed\" - ခေါင်းစဉ်နှင့်ဖော်ပြချက်သာ ပြပြီး ချဲ့နိုင်သည်၊ \"Locked\" - မေးခွန်း box ကို အပြည့်အဝပြပြီး ချုံ့လို့မရပါ",
      titleLocation: "Panel, page, သို့မဟုတ် survey-level သတ်မှတ်ချက်များကို override လုပ်ပါ",
      descriptionLocation: "Survey-level သတ်မှတ်ချက် (\"မေးခွန်းခေါင်းစဉ်အောက်တွင်\" တန်းမှစတင်)",
      errorLocation: "မေးခွန်းအမှားတည်နေရာကို သတ်မှတ်ပါ",
      indent: "မေးခွန်း content နှင့် box ဘောင်ဘေးကြား Margin ထည့်ပါ",
      width: "မေးခွန်း box ၏ အကျယ်ကို သတ်မှတ်ပါ",
      surveyvalidator: {
        expression: "မေးခွန်းအတွက် Validation စည်းမျဉ်းကို သတ်မှတ်ပါ"
      },
      textUpdateMode: "\"On lost focus\" - input field focus ပြောင်းလဲသည့်အချိန်၊ \"While typing\" - အသုံးပြုသူရိုက်နေစဉ် Value ကို real-time update ပြုလုပ်ပါ",
      url: "Multi-choice မေးခွန်းများအတွက် Web service URL ကို သတ်မှတ်ပါ",
      searchMode: "Drop-down list ကို filter ပြုလုပ်ရန် Operation တစ်ခုကိုရွေးချယ်ပါ",
      textWrapEnabled: "Long text ကို drop-down menu အတွင်းမှ line break များဖြင့် ဖော်ပြပါ"
    },
    signaturepad: {
      signatureWidth: "ထိုးထွင်းရယူထားသော ပုံရိပ်၏ အကျယ်ကို သတ်မှတ်ပါ",
      signatureHeight: "ထိုးထွင်းရယူထားသော ပုံရိပ်၏ အမြင့်ကို သတ်မှတ်ပါ",
      signatureAutoScaleEnabled: "Signature နယ်ပယ်ကို အလိုအလျောက် တိုးချဲ့နိုင်စေရန် ရွေးချယ်ပါ"
    },
    file: {
      imageHeight: "Survey results အတွင်း ပုံရိပ်၏ အမြင့်ကို ညှိပါ",
      imageWidth: "Survey results အတွင်း ပုံရိပ်၏ အကျယ်ကို ညှိပါ",
      allowImagesPreview: "တင်ထားသော ဖိုင်များအတွက် thumbnail preview ကို ပြပါ"
    },
    image: {
      contentMode: "URL မှာ ထည့်သွင်းထားသော အချက်အလက်အပေါ်မူတည်၍ \"Auto\" ကို အသုံးပြုပါ"
    },
    imagepicker: {
      imageHeight: "အနည်းဆုံးနှင့် အများဆုံး Height အတောအတွင်း Override လုပ်ပါ",
      imageWidth: "အနည်းဆုံးနှင့် အများဆုံး Width အတောအတွင်း Override လုပ်ပါ",
      choices: "\"Value\" သည် Conditional rules အတွက် Item ID ဖြစ်သည်၊ \"Text\" သည် တုံ့ပြန်သူများထံ ပြထားသော စာသားဖြစ်သည်",
      contentMode: "Media selector အတွက် Content mode ကိုရွေးချယ်ပါ။"
    },
    text: {
      size: "Input field ၏ အရွယ်အစားကိုသာ ချိန်ညှိသည်"
    },
    comment: {
      rows: "Input field အတွင်း ဖော်ပြထားသည့် Line အရေအတွက်ကို သတ်မှတ်ပါ"
    },
    // survey templates
    survey: {
      mode: "စစ်တမ်းကို ဖြည့်စွက်နိုင်မည့် 'Editable' နဲ့ Read-only mode ဖြစ်စေမည့် 'Read-only' ထဲမှရွေးချယ်ပါ။"
    },
    matrixdropdowncolumn: {
      name: "တုံ့ပြန်သူများအနေဖြင့် မမြင်ရသော ကော်လံ ID",
      isUnique: "ကော်လံအတွက် ဖွင့်ထားပါက တုံ့ပြန်သူသည် ကော်လံထဲရှိ မေးခွန်းများအတွက် တန်ဖိုးအထူးဖြစ်စေရန်လိုအပ်သည်။",
      rows: "Input field အတွင်း ဖော်ပြထားသည့် Line အရေအတွက်ကို သတ်မှတ်ပါ။",
      visibleIf: "ကော်လံ မြင်သာမှုကိုသတ်မှတ်ရန် Magic Wand icon ကို အသုံးပြုပါ။",
      enableIf: "ကော်လံကို Read-only mode မဟုတ်စေရန် Magic Wand icon ကိုအသုံးပြုပါ။",
      requiredIf: "Nested မေးခွန်းများတွင် ဖြေဆိုမှုမရှိပါက စစ်တမ်းတင်သွင်းမှုကို တားဆီးရန် Magic Wand icon ကိုအသုံးပြုပါ။",
      showInMultipleColumns: "ရွေးချယ်မှုများအတွက် တစ်ခုချင်း ကော်လံများကို ဖန်တီးပါ။",
      colCount: "ရွေးချယ်မှုများကို စတုရန်း စီစဉ်ပါ။"
    },
    caseInsensitive: "Regular expression တွင် အကြီးစာလုံးနဲ့ အငယ်စာလုံးကို တူညီသည်ဟုဆက်ဆံစေရန် ရွေးချယ်ပါ။",
    widthMode: "တည်ငြိမ်အကျယ်၊ ပြောင်းလွယ်ပြင်လွယ်အကျယ်၊ သို့မဟုတ် အလိုအလျောက်အကျယ်ထဲမှရွေးချယ်ပါ။",
    cookieName: "စစ်တမ်းတွင် ထပ်ခါတလဲလဲ ဖြေဆိုမှုကို တားဆီးရန်အတွက် ထူးခြားသော Cookie Value တစ်ခု သတ်မှတ်ပါ။",
    logo: "ပုံလင့်ခ်ကို ထည့်ပါ (အရွယ်အစားကန့်သတ်ချက်မရှိ) သို့မဟုတ် 64KB အထိ PC မှ ဖိုင်ရှာရန် Folder icon ကိုနှိပ်ပါ။",
    logoWidth: "CSS တန်ဖိုးများဖြင့် Logo ၏အကျယ်ကို သတ်မှတ်ပါ (px, %, in, pt, စသည်ဖြင့်)။",
    logoHeight: "CSS တန်ဖိုးများဖြင့် Logo ၏အမြင့်ကို သတ်မှတ်ပါ (px, %, in, pt, စသည်ဖြင့်)။",
    logoFit: "\"None\", \"Contain\", \"Cover\", \"Fill\" တို့ထဲမှ Logo ၏ မည်သည့်တပ်ဆင်မှုကို ရွေးချယ်ပါ။",
    goNextPageAutomatic: "တုံ့ပြန်သူသည် မရှိသေးသောမေးခွန်းအားလုံးကို ဖြေဆိုပြီးလျှင် စစ်တမ်းကို အလိုအလျောက် နောက်စာမျက်နှာသို့ ရောက်စေပါ။",
    allowCompleteSurveyAutomatic: "တုံ့ပြန်သူသည် မေးခွန်းအားလုံးကို ဖြေဆိုပြီးလျှင် စစ်တမ်းကို အလိုအလျောက်ပြီးစေပါ။",
    showNavigationButtons: "စာမျက်နှာတစ်ခုတွင် Navigation Buttons ၏ မြင်သာမှုနှင့် တည်နေရာကို သတ်မှတ်ပါ။",
    showProgressBar: "Progress Bar ၏ မြင်သာမှုနှင့် တည်နေရာကို သတ်မှတ်ပါ။",
    showPreviewBeforeComplete: "တုံ့ပြန်မှုအားလုံး သို့မဟုတ် ဖြေဆိုပြီးသော မေးခွန်းများကို Preview စာမျက်နှာပေါ်တွင်ပြပါ။",
    questionTitleLocation: "Survey အတွင်းမေးခွန်းများအားလုံးအတွက် Title Alignment ကို သတ်မှတ်ပါ။",
    requiredText: "ဖြေဆိုမှုမလိုအပ်သောအနေဖြင့် အထိန်းလက္ခဏာ သို့မဟုတ် စာလုံးများကို ရွေးချယ်ပါ။",
    questionStartIndex: "နံပါတ် သို့မဟုတ် စာလုံးတစ်ခုကို ရိုက်ထည့်ပါ။",
    questionErrorLocation: "မေးခွန်း၏ အမှားစာသားတည်နေရာကို သတ်မှတ်ပါ။",
    focusFirstQuestionAutomatic: "တစ်မျက်နှာလျှင် ပထမဦးစွာ ဖြည့်စွက်ရန် input field ကို Auto-Ready ပြုလုပ်ပါ။",
    questionsOrder: "မေးခွန်းများ၏ မူလအစီအစဉ်ကို ထိန်းသိမ်းပါ သို့မဟုတ် Randomize လုပ်ပါ။",
    maxTextLength: "Text Entry မေးခွန်းများအတွက်သာဖြစ်သည်။",
    maxOthersLength: "Comment များအတွက်သာဖြစ်သည်။",
    commentAreaRows: "Comment များအတွက် Text Area ၏ Row အရေအတွက်ကို သတ်မှတ်ပါ။",
    autoGrowComment: "Comment များနှင့် Long Text မေးခွန်းများအတွက် Auto-Grow Feature ကို ဖွင့်ပါ။",
    allowResizeComment: "Comment များနှင့် Long Text မေးခွန်းများအတွက် အရွယ်အစားကို ပြောင်းလဲနိုင်ရန် ဖွင့်ပါ။",
    calculatedValues: "Form Calculations အတွက် Custom Variable များကို ဖန်တီးပါ။",
    includeIntoResult: "Calculated Value ကို Survey Results တွင် ထည့်သွင်းရန် ရွေးချယ်ပါ။",
    triggers: "Expression တစ်ခု အပေါ်မူတည်ပြီး Survey မှာ Action တစ်ခုလှုံ့ဆော်ရန် Trigger များကို သတ်မှတ်ပါ။",
    clearInvisibleValues: "Conditional Logic ဖြင့် ဖျောက်ထားသော မေးခွန်းတန်ဖိုးများကို ဖျက်စီးမည်၊ မဖျက်စီးမည်ကို ရွေးချယ်ပါ။",
    textUpdateMode: "\"On lost focus\" သို့မဟုတ် \"While typing\" တို့ထဲမှ ရွေးချယ်ပါ။",
    columns: "ဘယ်ဘက်တန်ဖိုးသည် Conditional Rules တွင် အသုံးပြုသော Column ID ဖြစ်သည်၊ ညာဘက်တန်ဖိုးကို တုံ့ပြန်သူများအနေဖြင့် မြင်နိုင်သည်။",
    rows: "ဘယ်ဘက်တန်ဖိုးသည် Conditional Rules တွင် အသုံးပြုသော Row ID ဖြစ်သည်၊ ညာဘက်တန်ဖိုးကို တုံ့ပြန်သူများအနေဖြင့် မြင်နိုင်သည်။",
    columnMinWidth: "CSS တန်ဖိုးများကို လက်ခံသည် (px, %, in, pt, စသည်ဖြင့်)။",
    rowTitleWidth: "CSS တန်ဖိုးများကို လက်ခံသည် (px, %, in, pt, စသည်ဖြင့်)။",
    totalText: "\"Aggregation Method\" သို့မဟုတ် \"Total Value Expression\" ဖြင့် Total Values များကို ပြသသည်။",
    cellErrorLocation: "Cell Input မှာ အမှားစာသားတည်နေရာကို သတ်မှတ်ပါ။",
    keyDuplicationError: "\"Prevent Duplicate Responses\" သတ်မှတ်ချက် ဖွင့်ထားပါက Duplicate Entries အတွက် အမှားစာသားကို ဖော်ပြပါ။",
    totalExpression: "Expression တစ်ခုအပေါ်မူတည်ပြီး Total Values ကိုတွက်ချက်ပါ။",
    confirmDelete: "Row ဖျက်ပစ်မှုကို အတည်ပြုရန် Prompt ကို ဖော်ပြပါ။",
    defaultValueFromLastRow: "နောက်ဆုံး Row ၏ ဖြေဆိုမှုများကိုထပ်တူယူပြီး နောက်ထပ် Row တွင် သတ်မှတ်ပါ။",
    keyName: "ရပ်ဆိုင်းထားသော Column ID ကို သုံးပြီး တူညီသောဖြေဆိုမှုများကို တားဆီးပါ။",
    description: "အခေါင်းစဉ်ခွဲစာသားကို ရိုက်ထည့်ပါ။",
    locale: "Survey Language ကို ရွေးချယ်ပြီး သင့် Survey ကိုစတင်ဖန်တီးပါ။",
    detailPanelMode: "\"None\", \"Under the row\", \"Under the row, display one row expansion only\" တို့ထဲမှရွေးချယ်ပါ။",
    imageFit: "\"None\", \"Contain\", \"Cover\", \"Fill\" တို့ထဲမှ ရွေးချယ်ပါ။",
    autoGrow: "\"Inherit\" သည် Survey-level သတ်မှတ်ချက် (\"Disabled\" ပုံမှန်ဖြစ်သည်) ကို သက်ဆိုင်ပါသည်။",
    allowResize: "\"Inherit\" သည် Survey-level သတ်မှတ်ချက် (\"Enabled\" ပုံမှန်ဖြစ်သည်) ကို သက်ဆိုင်ပါသည်။",
    timeLimit: "စစ်တမ်းအားပြီးဆုံးရန် သတ်မှတ်အချိန်တစ်ခု (စက္ကန့်) ပြုလုပ်ပါ။",
    timeLimitPerPage: "စာမျက်နှာတစ်ခုစီအတွက် အချိန်ကန့်သတ်ချက်ကို စက္ကန့်အနေနဲ့သတ်မှတ်ပါ။",
    validateVisitedEmptyFields: "User များ empty input field ကို focus လုပ်ပြီး ပြန်ထွက်သွားတဲ့အခါ Validation Trigger ဖြစ်စေပါ။",
    page: {
      timeLimit: "စာမျက်နှာတစ်ခုစီအတွက် အချိန်ကန့်သတ်ချက်ကို စက္ကန့်အနေနဲ့သတ်မှတ်ပါ။",
      visibleIf: "စာမျက်နှာမြင်သာမှုကို သတ်မှတ်ရန် Magic Wand icon ကို အသုံးပြုပါ။",
      enableIf: "စာမျက်နှာကို Read-only mode မဟုတ်စေရန် Magic Wand icon ကို အသုံးပြုပါ။",
      questionTitleLocation: "စာမျက်နှာတွင်း မေးခွန်းများအတွက် Title Alignment ကို သတ်မှတ်ပါ။",
      navigationButtonsVisibility: "စာမျက်နှာပေါ်တွင် Navigation Buttons ၏ မြင်သာမှုကို သတ်မှတ်ပါ။"
    },
    timerLocation: "စာမျက်နှာတစ်ခုအတွင်း Timer ၏ တည်နေရာကို သတ်မှတ်ပါ။",
    panelsState: "\"Locked\", \"Collapse all\", \"Expand all\", \"First expanded\" တို့ထဲမှ Panel State ကိုရွေးချယ်ပါ။",
    imageLinkName: "ရွေးချယ်မှုစာရင်းတွင် ပြသရန်ပုံ သို့မဟုတ် ဗီဒီယိုဖိုင် URL များပါရှိသော အရာများ array အတွင်းရှိ ပိုင်ဆိုင်မှုတူသော property နာမည်ကိုထည့်ပါ။",
    choices: "ဘယ်ဘက်တန်ဖိုးသည် Conditional Rules တွင် အသုံးပြုသော Item ID ဖြစ်ပြီး၊ ညာဘက်တန်ဖိုးသည် တုံ့ပြန်သူများအတွက် ပြသရန်ဖြစ်သည်။",
    title: "ပြသရန်အသုံးပြုသူရင်းနှီးမှုရှိသော ခေါင်းစဉ်ကို ရိုက်ထည့်ပါ။",
    waitForUpload: "ဖိုင်များတင်ပြီးမချင်း စစ်တမ်းကို ပြီးစီးခြင်းမရှိစေရန် သေချာစေပါသည်။",
    minWidth: "CSS တန်ဖိုးများကို လက်ခံသည် (px, %, in, pt, စသည်ဖြင့်)။",
    maxWidth: "CSS တန်ဖိုးများကို လက်ခံသည် (px, %, in, pt, စသည်ဖြင့်)။",
    width: "CSS တန်ဖိုးများကို လက်ခံသည် (px, %, in, pt, စသည်ဖြင့်)။",
    valueName: "တန်ဖိုးများကို ချိတ်ဆက်ထားသော မေးခွန်းများနှင့် သက်ဆိုင်သော array သို့မဟုတ် object အတွက် custom key ဖြစ်သော Join Identifier ကို သတ်မှတ်ပါ။",
    useDisplayValuesInDynamicTexts: "Single- နှင့် Multiple-Selection မေးခွန်းအမျိုးအစားများတွင် ID နှင့် Display Value ပါရှိသည့်ရွေးချယ်မှုများအတွက် ID Value အစား Display Value ကို ပြသရန် ရွေးချယ်ပါ။",
    clearIfInvisible: "Conditional Logic ဖြင့်ဖျောက်ထားသော မေးခွန်းတန်ဖိုးများကို ဖျက်စီးရန် သို့မဟုတ်မဖျက်စီးရန်ရွေးချယ်ပါ။ \"Inherit\" သည် စစ်တမ်းအဆင့်သတ်မှတ်ချက်ကို သက်ဆိုင်ပါသည်။",
    choicesFromQuestionMode: "ရွေးချယ်မှုများအားလုံး၊ ရွေးချယ်ထားသောရွေးချယ်မှုများ၊ သို့မဟုတ် မရွေးချယ်ထားသောရွေးချယ်မှုများကို dynamic သတ်မှတ်ပါ။",
    showOtherItem: "ရွေးချယ်ပါက သီးခြား Comment Box မှာ ထပ်ထည့်နိုင်သောရွေးချယ်မှုများကို လွှင့်ပေးပါ။",
    separateSpecialChoices: "Special Choice Option (\"None\", \"Other\", \"Select All\") တစ်ခုချင်းစီကို အတန်းအသစ်တစ်ခုတွင် ပြသပါ။",
    path: "Target Array ကို Dataset အတွင်းမည်သည့်နေရာတွင်ရှိသည်ကို သတ်မှတ်ပါ။ URL သည် Array ကိုညွှန်ပြသောအခါ ရှင်းလင်းထားပါ။",
    choicesbyurl: {
      valueName: "Survey Results တွင် ဖြေဆိုမှုတန်ဖိုးအဖြစ် သိမ်းဆည်းမည့် Uniform Property Name ကို ထည့်သွင်းပါ။"
    },
    titleName: "Choices List တွင် ပြသရန် တန်ဖိုးများပါရှိသော အရာများ array အတွင်းရှိ Uniform Property Name ကို ထည့်သွင်းပါ။",
    allowEmptyResponse: "Service သည် Response သို့မဟုတ် Array အချည်းနှီးကို ပြန်ပေးနိုင်စေရန် ရွေးချယ်ပါ။",
    choicesVisibleIf: "ရွေးချယ်မှုများအားလုံး၏ မြင်သာမှုကို သတ်မှတ်ရန် Magic Wand Icon ကို အသုံးပြုပါ။",
    rateValues: "ဘယ်ဘက်တန်ဖိုးသည် Conditional Rules တွင် အသုံးပြုသော Item ID ဖြစ်ပြီး ညာဘက်တန်ဖိုးသည် တုံ့ပြန်သူများအတွက် ပြသရန်ဖြစ်သည်။",
    rating: {
      displayMode: "\"Auto\" သည် ရွေးချယ်မှုများကို Display Width အပေါ်မူတည်ပြီး \"Buttons\" နှင့် \"Dropdown\" Modes အကြားရွေးချယ်ပေးသည်။"
    },
    valuePropertyName: "Results များကို Format မတူညီသော မေးခွန်းများကိုချိတ်ဆက်ရာတွင် အသုံးပြုနိုင်သော Shared Property Name ကို ထည့်သွင်းပါ။",
    searchEnabled: "တုံ့ပြန်သူများ Input Field တွင် ရိုက်သည့် Search Query နှင့်ကိုက်ညီရန် Dropdown Menu အကြောင်းအရာများကို Update ပြုလုပ်ရန် ရွေးချယ်ပါ။",
    valueTrue: "တုံ့ပြန်သူများ Positive ဖြေဆိုမှုအတွက် Results တွင် သိမ်းဆည်းရန်တန်ဖိုးကို ထည့်သွင်းပါ။",
    valueFalse: "တုံ့ပြန်သူများ Negative ဖြေဆိုမှုအတွက် Results တွင် သိမ်းဆည်းရန်တန်ဖိုးကို ထည့်သွင်းပါ။",
    showPreview: "Preview Image ကို Override လုပ်ပြီး Files မတင်ရသေးမူကို ခွဲခြားရန်မလွယ်ကူစေပါ။",
    needConfirmRemoveFile: "ဖိုင်ဖျက်ပစ်မှုကို အတည်ပြုရန် Prompt ကို ဖော်ပြပါ။",
    selectToRankEnabled: "ရွေးချယ်ထားသောရွေးချယ်မှုများကိုသာ Rank ပြုလုပ်ရန် Enable လုပ်ပါ။",
    dataList: "Input များတွင် ပြသမည့် Choices စာရင်းကို ထည့်သွင်းပါ။",
    itemSize: "Input Fields များ၏အရွယ်အစားကိုသာ ပြောင်းလဲပြီး Question Box ၏အကျယ်ကို မထိခိုက်ပါ။",
    itemTitleWidth: "Item Labels အားလုံးအတွက် Width ကို Pixels အနေနဲ့ သတ်မှတ်ပါ။",
    inputTextAlignment: "Input Field အတွင်း Input Value ကို Alignment လုပ်ရန် ရွေးချယ်ပါ။",
    altText: "Image မမြင်ရသောအခါသို့မဟုတ် Accessibility အတွက် Substitute အဖြစ် အသုံးပြုပါ။",
    rateColorMode: "Rating Icon Type ကို \"Smileys\" သတ်မှတ်ထားသောအခါ Emoji ၏အရောင်ကို သတ်မှတ်ပါ။",
    expression: {
      name: "တုံ့ပြန်သူများအနေဖြင့် မမြင်ရသော Expression ID",
      description: "Expression အတွက် Subtitle ကို ရိုက်ထည့်ပါ။",
      expression: "Expression တွင် အခြေခံတွက်ချက်မှုများ (`{q1_id} + {q2_id}`), Conditions (`{age} > 60`), နှင့် Functions ('iif()', `today()`, `age()`, `min()`, `max()`, `avg()` စသည်တို့) ပါဝင်နိုင်သည်။"
    },
    storeOthersAsComment: "\"Other\" ရွေးချယ်မှုတန်ဖိုးကို စစ်တမ်းရလဒ်များတွင် သီးခြား property အဖြစ် သိမ်းဆည်းရန် ရွေးချယ်ပါ။",
    format: "{0} ကို placeholder အဖြစ်အသုံးပြုပါ။",
    acceptedTypes: "[accept](https://www.w3schools.com/tags/att_input_accept.asp) attribute အကြောင်း အသေးစိတ်ကို ကိုးကားပါ။",
    columnColCount: "ရွေးချယ်မှုများကို မူလတန်းကိန်းအလိုက် ကော်လံတန်းများအဖြစ် စီစဉ်ပါ။ \"Cell input type\" ကို Radio Button Group သို့မဟုတ် Checkboxes အဖြစ် သတ်မှတ်ထားသောကော်လံများတွင်သာ သက်ဆိုင်ပါသည်။",
    autocomplete: "အသုံးပြုသူ၏ browser မှ ပြန်လည်ယူဆောင်နိုင်သော ဒေတာအမျိုးအစားကို ရွေးချယ်ပါ။",
    filePlaceholder: "\"File source type\" သည် \"Local file\" ဖြစ်သောအခါ သို့မဟုတ် Camera မရရှိနိုင်သောအခါ သက်ဆိုင်ပါသည်။",
    photoPlaceholder: "\"File source type\" သည် \"Camera\" ဖြစ်သောအခါ သက်ဆိုင်ပါသည်။",
    fileOrPhotoPlaceholder: "\"File source type\" သည် \"Local file or camera\" ဖြစ်သောအခါ သက်ဆိုင်ပါသည်။",
    colCount: "ရွေးချယ်မှုများကို မူလတန်းကိန်းအလိုက် ကော်လံတန်းများအဖြစ် စီစဉ်ပါ။",
    masksettings: {
      saveMaskedValue: "မေးခွန်းတန်ဖိုးကို Mask ဖြင့် ထည့်သွင်းထားသောအတိုင်း စစ်တမ်းရလဒ်များတွင် သိမ်းဆည်းလိုပါက ရွေးချယ်ပါ။"
    },
    patternmask: {
      pattern: "Pattern တွင် String Literals နှင့် Placeholder အများအတွက် အောက်ပါများ ပါရှိနိုင်သည် - `9`: Digit, `a`: Upper- သို့မဟုတ် Lower-case Letter, `#`: Digit သို့မဟုတ် Upper- သို့မဟုတ် Lower-case Letter. Backslash `\\` ဖြင့် Character ကို Escaping ပြုလုပ်ပါ။"
    },
    datetimemask: {
      pattern: "Separator Characters နှင့် အောက်ပါ Placeholder များပါရှိနိုင်သည် -<br>`m`: လရက်အမှတ်, `mm`: လရက်အမှတ် (Leading Zero ဖြင့်), `d`: နေ့ရက်, `dd`: နေ့ရက် (Leading Zero ဖြင့်), `yy`: နှစ်၏နောက်ဆုံး နှစ်လုံး, `yyyy`: နှစ်လုံးနှစ်, `H`: 24 နာရီစနစ်ဖြင့် နာရီ, `h`: 12 နာရီစနစ်ဖြင့် နာရီ, `MM`: မိနစ်, `ss`: စက္ကန့်."
    },
    numericmask: {
      decimalSeparator: "Fractional Part ကို Integer Part ထံမှ ခွဲထုတ်ရန်သုံးသော အခြေအားဖြစ်သော သင်္ကေတ။",
      thousandsSeparator: "ကြီးမားသောနံပါတ်များကို သုံးခုစီခွဲထုတ်ရန် သုံးသော သင်္ကေတ။",
      precision: "ထင်ဟပ်အမှတ်များကို Decimal Point အောက်တွင် တိကျစွာ ထားရှိပါ။"
    },
    currencymask: {
      prefix: "တန်ဖိုးအရှေ့တွင် ပြသရန် သင်္ကေတတစ်ခု သို့မဟုတ် သင်္ကေတအများကို ထည့်သွင်းပါ။",
      suffix: "တန်ဖိုးအနောက်တွင် ပြသရန် သင်္ကေတတစ်ခု သို့မဟုတ် သင်္ကေတအများကို ထည့်သွင်းပါ။"
    },
    theme: {
      isPanelless: "ဤဆက်တင်သည် Panel မရှိသည့် မေးခွန်းများတွင်သာ သက်ဆိုင်ပါသည်။",
      primaryColor: "စစ်တမ်းအရေးပါသော အချက်အလက်များကို အလင်းပြထားသည့် အရောင်ကို သတ်မှတ်ပါ။",
      panelBackgroundTransparency: "စစ်တမ်းနောက်ခံနှင့် Panel များ၏ Transparency ကိုညှိပါ။",
      questionBackgroundTransparency: "Input Element များ၏ Transparency ကို စစ်တမ်းနောက်ခံနှင့်ညှိပါ။",
      cornerRadius: "လျားရိုးပုံစံအတွက် အနားအလျားကို သတ်မှတ်ပါ။"
    },
    header: {
      inheritWidthFrom: "\"Same as container\" သည် စစ်တမ်းထားရှိသည့် HTML Element နှင့် ကိုက်ညီသော Header Content အကျယ်ကို အလိုအလျောက်ညှိပေးသည်။",
      textAreaWidth: "စစ်တမ်းခေါင်းစဉ်နှင့် ဖော်ပြချက်ကို ပါဝင်သော Header Area ၏အကျယ်။",
      overlapEnabled: "Enabled ဖြစ်ပါက စစ်တမ်း၏ ထိပ်ပိုင်းသည် Header ၏ အောက်ပိုင်းကို အောက်ကျသည်။",
      mobileHeight: "0 အဖြစ် သတ်မှတ်ပါက Header Content ကိုလိုက်နာ၍ အမြင့်ကို အလိုအလျောက်တွက်ချက်ပေးသည်။"
    },
    panellayoutcolumn: {
      effectiveWidth: "%. အတွက် တန်ဖိုးများကို လက်ခံပါသည်။",
      questionTitleWidth: "px အတွက် တန်ဖိုးများကို လက်ခံပါသည်။"
    },
    progressBarInheritWidthFrom: "\"Same as container\" သည် Progress Bar Area ၏ အကျယ်ကို HTML Element နှင့် ကိုက်ညီစေပါသည်။"
  },
  // Properties
  p: {
    title: {
      name: "ခေါင်းစဉ်",
      title: "'နာမည်' နှင့်တူပါက အလွတ်ထားပါ"
    },
    multiSelect: "အများကြီးရွေးချယ်ခွင့်ပြုပါ",
    showLabel: "ပုံနှင့် ဗီဒီယိုအကြောင်းပြချက်များပြပါ",
    swapOrder: "ဟုတ်/မဟုတ် အစီအစဉ်ကိုပြောင်းပါ",
    value: "တန်ဖိုး",
    tabAlign: "တဘ် alignment",
    sourceType: "ဖိုင်အရင်းအမြစ်အမျိုးအစား",
    fitToContainer: "ကွန်တိန်နာကိုက်ညီမှု",
    setValueExpression: "တန်ဖိုးဖော်ပြချက်ကိုသတ်မှတ်ပါ",
    description: "ဖော်ပြချက်",
    logoFit: "လိုဂိုကိုက်ညီမှု",
    pages: "စာမျက်နှာများ",
    questions: "မေးခွန်းများ",
    triggers: "ထိန်းချုပ်မှုများ",
    calculatedValues: "စိတ်ကြိုက်အပြောင်းအလဲများ",
    surveyId: "စစ်တမ်း ID",
    surveyPostId: "စစ်တမ်း Post ID",
    surveyShowDataSaving: "စစ်တမ်းဒေတာသိမ်းဆည်းမှုပြပါ",
    questionDescriptionLocation: "မေးခွန်းဖော်ပြချက်တည်နေရာ",
    progressBarType: "တိုးတက်မှုဘားအမျိုးအစား",
    showTOC: "အကြောင်းအရာဇယား (TOC) ပြပါ",
    tocLocation: "TOC တည်နေရာ",
    questionTitlePattern: "မေးခွန်းခေါင်းစဉ်ပုံစံ",
    widthMode: "စစ်တမ်းအကျယ်အဝန်း",
    showBrandInfo: "အမှတ်တံဆိပ်အချက်အလက်ပြပါ",
    useDisplayValuesInDynamicTexts: "ဒိုင်နမစ်စာသားများတွင် ပြသထားသောတန်ဖိုးများကိုသုံးပါ",
    visibleIf: "မြင်နိုင်ပါက",
    defaultValueExpression: "ပုံမှန်တန်ဖိုးဖော်ပြချက်",
    requiredIf: "လိုအပ်ပါက",
    resetValueIf: "တန်ဖိုးပြန်သတ်မှတ်ပါက",
    setValueIf: "တန်ဖိုးသတ်မှတ်ပါက",
    validators: "အတည်ပြုချက်များ",
    bindings: "ချိတ်ဆက်မှုများ",
    renderAs: "အဖြစ်ဖော်ပြပါ",
    attachOriginalItems: "မူရင်းအချက်အလက်များကိုထည့်ပါ",
    choices: "ရွေးချယ်မှုများ",
    choicesByUrl: "URL ဖြင့်ရွေးချယ်မှုများ",
    currency: "ငွေကြေး",
    cellHint: "ဆဲလ်အကြံပြုချက်",
    totalMaximumFractionDigits: "စုစုပေါင်းအများဆုံးဒစ်ဂျစ်များ",
    totalMinimumFractionDigits: "စုစုပေါင်းအနည်းဆုံးဒစ်ဂျစ်များ",
    columns: "ကော်လံများ",
    detailElements: "အသေးစိတ်အချက်အလက်များ",
    allowAdaptiveActions: "အလိုက်သင့်ပြုလုပ်ချက်များကိုခွင့်ပြုပါ",
    defaultRowValue: "ပုံမှန်တန်းတန်ဖိုး",
    detailPanelShowOnAdding: "အသေးစိတ်ပန်းကန်ပြသမှု",
    choicesLazyLoadEnabled: "ရွေးချယ်မှုများကိုအလေးချိန်ဖြင့်တင်ပါ",
    choicesLazyLoadPageSize: "ရွေးချယ်မှုများအရွယ်အစား",
    inputFieldComponent: "အဝင်အကွက်အစိတ်အပိုင်း",
    itemComponent: "အချက်အလက်အစိတ်အပိုင်း",
    min: "အနည်းဆုံး",
    max: "အများဆုံး",
    minValueExpression: "အနည်းဆုံးတန်ဖိုးဖော်ပြချက်",
    maxValueExpression: "အများဆုံးတန်ဖိုးဖော်ပြချက်",
    step: "အဆင့်",
    dataList: "အလိုအလျောက်အကြံပြုချက်များ",
    itemSize: "အဝင်အကွက်အကျယ် (စာလုံးများ)",
    itemTitleWidth: "အချက်အလက်ခေါင်းစဉ်အကျယ် (px)",
    inputTextAlignment: "အဝင်တန်ဖိုး alignment",
    elements: "အချက်အလက်များ",
    content: "အကြောင်းအရာ",
    navigationButtonsVisibility: "လမ်းညွှန်ခလုတ်များကိုပြ/ဖျောက်ပါ",
    navigationTitle: "လမ်းညွှန်ခေါင်းစဉ်",
    navigationDescription: "လမ်းညွှန်ဖော်ပြချက်",
    longTap: "ကြာရှည်ထိပါ",
    autoGrow: "အဝင်အကွက်ကိုအလိုအလျောက်ချဲ့ပါ",
    allowResize: "ပြန်လည်ချဲ့နိုင်မှုကိုခွင့်ပြုပါ",
    acceptCarriageReturn: "Carriage return ကိုလက်ခံပါ",
    displayMode: "ပြသမှု mode",
    rateType: "အဆင့်သတ်မှတ်မှုအမျိုးအစား",
    label: "တံဆိပ်",
    contentMode: "အကြောင်းအရာ mode",
    imageFit: "ပုံနှင့် thumbnail ကိုက်ညီမှု",
    altText: "အခြားစာသား",
    height: "အမြင့်",
    mobileHeight: "မိုဘိုင်းအမြင့်",
    penColor: "ခဲတံအရောင်",
    backgroundColor: "နောက်ခံအရောင်",
    templateElements: "နမူနာအချက်အလက်များ",
    operator: "စစ်ဆင်ရေး",
    isVariable: "အပြောင်းအလဲဖြစ်ပါသလား",
    runExpression: "ဖော်ပြချက်ကိုပြေးပါ",
    showCaption: "အကြောင်းပြချက်ကိုပြပါ",
    iconName: "အိုင်ကွန်နာမည်",
    iconSize: "အိုင်ကွန်အရွယ်အစား",
    precision: "တိကျမှု",
    matrixDragHandleArea: "Matrix ဆွဲယူမှု handle အကျယ်",
    backgroundImage: "နောက်ခံပုံ",
    backgroundImageFit: "နောက်ခံပုံကိုက်ညီမှု",
    backgroundImageAttachment: "နောက်ခံပုံဆက်စပ်မှု",
    backgroundOpacity: "နောက်ခံ opacity",
    selectToRankEnabled: "ရွေးချယ်မှုအဆင့်သတ်မှတ်မှုကိုခွင့်ပြုပါ",
    selectToRankAreasLayout: "အဆင့်သတ်မှတ်မှုနေရာ alignment",
    selectToRankEmptyRankedAreaText: "ရွေးချယ်မှုအားလုံးကိုရွေးချယ်ပါကပြသရန်စာသား",
    selectToRankEmptyUnrankedAreaText: "အဆင့်သတ်မှတ်မှုနေရာအတွက် placeholder စာသား",
    allowCameraAccess: "ကင်မရာဝင်ရောက်ခွင့်ပြုပါ",
    scaleColorMode: "အဆင့်သတ်မှတ်မှုအိုင်ကွန်အရောင် mode",
    rateColorMode: "အပြုံးအရောင်စနစ်",
    copyDisplayValue: "ပြသမှုတန်ဖိုးကိုကူးယူပါ",
    effectiveColSpan: "အကျိုးရှိသောကော်လံ span",
    progressBarInheritWidthFrom: "တိုးတက်မှုဘားအကျယ်",
  },
  theme: {
    advancedMode: "အဆင့်မြင့် mode",
    pageTitle: "စာမျက်နှာ",
    questionTitle: "မေးခွန်း box",
    editorPanel: "အဝင်အချက်အလက်",
    lines: "လိုင်းများ",

    primaryDefaultColor: "ပုံမှန်",
    primaryDarkColor: "Hover",
    primaryLightColor: "ရွေးချယ်ထားသော",
    backgroundDimColor: "နောက်ခံအရောင်",

    cornerRadius: "ထောင့်အချိုး",
    backcolor: "ပုံမှန်နောက်ခံ",
    hovercolor: "Hover နောက်ခံ",
    borderDecoration: "စက်ဝိုင်းအလှဆင်မှု",

    fontColor: "ဖောင့်အရောင်",
    backgroundColor: "နောက်ခံအရောင်",

    primaryForecolor: "ပုံမှန်အရောင်",
    primaryForecolorLight: "ပိတ်ထားသောအရောင်",
    font: "ဖောင့်",
    borderDefault: "မှောင်သော",
    borderLight: "အလင်းသော",

    fontFamily: "ဖောင့်မိသားစု",
    fontWeightRegular: "ပုံမှန်",
    fontWeightHeavy: "ထူသော",
    fontWeightSemiBold: "Semi-bold",
    fontWeightBold: "ထူထဲသော",
    color: "အရောင်",
    placeholderColor: "Placeholder အရောင်",
    size: "အရွယ်အစား",

    opacity: "Opacity",
    boxShadowX: "X",
    boxShadowY: "Y",
    boxShadowAddRule: "အရိပ်အကျိုးသက်ရောက်မှုထည့်ပါ",
    boxShadowBlur: "Blur",
    boxShadowSpread: "ပြန့်",
    boxShadowDrop: "Drop",
    boxShadowInner: "Inner",

    names: {
      default: "ပုံမှန်",
      sharp: "ထက်သော",
      borderless: "Borderless",
      flat: "Flat",
      plain: "ရိုးရိုး",
      doubleborder: "Double Border",
      layered: "Layered",
      solid: "Solid",
      threedimensional: "3D",
      contrast: "Contrast"
    },
    colors: {
      teal: "Teal",
      blue: "အပြာရောင်",
      purple: "ခရမ်းရောင်",
      orchid: "Orchid",
      tulip: "Tulip",
      brown: "အညိုရောင်",
      green: "အစိမ်းရောင်",
      gray: "မီးခိုးရောင်"
    },
  },
  creatortheme: {
    "--sjs-special-background": "မျက်နှာပြင်နောက်ခံ",
    "--sjs-primary-background-500": "Primary",
    "--sjs-secondary-background-500": "Secondary",
    "surfaceScale": "မျက်နှာပြင်",
    "userInterfaceBaseUnit": "UI အချက်အလက်များ",
    "fontScale": "ဖောင့်",

    names: {
      "sc2020": "Survey Creator 2020",
      "default-light": "အလင်း",
      "default-dark": "မှောင်",
      "default-contrast": "Contrast"
    },
  }
};

setupLocale({ localeCode: "mm", strings: mmStrings });