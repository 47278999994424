import classNames from 'classnames';
import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { SubscriptionPriceState } from 'global-stores';
import { useFreePlan } from 'hooks';
import { IdentityType } from 'views/user-identity-flow';
import { ChooseServicesData } from 'views/user-roles/constant';

import {
	AddNewRoleFormState,
	SelectedUserRoleState,
} from 'views/user-roles/store';

export const SelectServices = () => {
	const [addRoleForm, setAddRoleForm] = useRecoilState(AddNewRoleFormState);
	const selectedUserRole = useRecoilValue(SelectedUserRoleState);
	const subscriptionPrice = useRecoilValue(SubscriptionPriceState);
	const { hasSubscribedPerson } = useFreePlan();

	const { services = [] } = useMemo(() => addRoleForm ?? [], [addRoleForm]);

	const isIndividualSubscribed = useMemo(
		() => hasSubscribedPerson ?? false,
		[hasSubscribedPerson]
	);

	useEffect(() => {
		if (selectedUserRole) {
			const { services } = selectedUserRole ?? [];
			if (services?.length) {
				setAddRoleForm(prev => ({
					...prev,
					services,
				}));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedUserRole]);

	useEffect(() => {
		if (isIndividualSubscribed) {
			setAddRoleForm(prev => ({
				...prev,
				services: ['esign'],
			}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isIndividualSubscribed]);

	const subsPrice: Record<string, string> | undefined = useMemo(() => {
		if (subscriptionPrice) {
			return subscriptionPrice[0];
		} else return {};
	}, [subscriptionPrice]);

	const selectChooseServices = useCallback(
		(isActive: boolean, isDisabled: boolean) => {
			if (isDisabled) {
				return classNames('chooseservices--container__section', {
					'section-disabled': isDisabled,
				});
			}
			return classNames('chooseservices--container__section hover', {
				'section-border': isActive,
			});
		},
		[]
	);

	const handleChooseService = useCallback(
		(key: string[], isDisabledOnbarding: boolean) => {
			if (isDisabledOnbarding) {
				return;
			}
			setAddRoleForm(prev => ({
				...prev,
				permissions: {
					...prev.permissions,
					onboarding: {
						read: false,
						write: false,
					},
					session: {
						read: false,
						write: false,
					},
					funds: {
						read: false,
						write: false,
					},
					dashboard: {
						read: prev.permissions.dashboard.read,
						write: false,
					},
					embedWeb: {
						read: false,
						write: false,
					},
					apiDocs: {
						read: prev.permissions.apiDocs.read,
						write: false,
					},
				},
			}));
			setAddRoleForm(pre => ({ ...pre, services: key }));
		},
		[setAddRoleForm]
	);

	const arraysEqual = useCallback((arr1: string[], arr2: string[]) => {
		// Check if the lengths are the same
		if (arr1.length !== arr2.length) {
			return false;
		}

		// Sort the arrays
		const sortedArr1 = arr1.slice().sort();
		const sortedArr2 = arr2.slice().sort();

		// Compare the elements
		for (let i = 0; i < sortedArr1.length; i++) {
			if (sortedArr1[i] !== sortedArr2[i]) {
				return false;
			}
		}
		// If all elements are equal, the arrays are considered equal
		return true;
	}, []);

	const renderCard = useMemo(() => {
		return ChooseServicesData.map(item => {
			const isDisabledOnbarding =
				isIndividualSubscribed && item.type !== IdentityType.Individual;
			const isActive = isIndividualSubscribed
				? item.type === IdentityType.Individual
				: arraysEqual(services, item.key);

			return (
				<section
					className={selectChooseServices(isActive, isDisabledOnbarding)}
					key={item.type}
					onClick={() => handleChooseService(item.key, isDisabledOnbarding)}
				>
					<div className="section--container">
						<div className="section--container__text">
							<div className="section--container__text--heading">
								{item.label}
							</div>
							<div className="section--container__text--price">
								<span className="section--container__text--price__amount">
									${(subsPrice ?? {})[item.type]}
								</span>
								<span className="section--container__text--price__permonth">
									/user/month
								</span>
							</div>
						</div>
						<div className="section--container__textIcon">
							<div className="section--container__textIcon--action">
								Account type required:
							</div>
							<div className="account-types">
								{item.accountTypes.map(account => (
									<div
										className="section--container__textIcon--bussinessName"
										key={item.type}
									>
										<div className="section--container__textIcon--bussinessName__icon">
											<i
												className={`${account.icon} section--container__textIcon--bussinessName__icon--svg`}
											></i>
										</div>

										<div className="section--container__textIcon--bussinessName__name">
											{account.bussiness}
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
					<div className="chooseservices--container__section--radio">
						<input type="radio" checked={isActive} />
					</div>
				</section>
			);
		});
	}, [
		isIndividualSubscribed,
		arraysEqual,
		handleChooseService,
		selectChooseServices,
		services,
		subsPrice,
	]);

	return (
		<div className="AddNewRoleModal--container__chooseservices">
			<div className="AddNewRoleModal--container__chooseservices__header">
				<div className="AddNewRoleModal--container__chooseservices__header__title">
					Services
				</div>
				<div className="AddNewRoleModal--container__chooseservices__header__icon">
					<ReactTooltip
						place="right"
						anchorId="select.service.info"
						html="
						    <ul>
                              <li>Users with a Personal or Entity Account can use eSign services.</li>
                              <li>Users with an Entity Subscription can use both Onboarding and eSign services.</li>
                            </ul>
						"
						style={{ zIndex: 1 }}
					/>

					<i className="ri-information-line" id="select.service.info"></i>
				</div>
			</div>
			<div className="AddNewRoleModal--container__chooseservices__card">
				<div className="chooseservices--container">{renderCard}</div>
			</div>
		</div>
	);
};
