/* eslint-disable no-useless-escape */
export const isValidMobileNumber = (mobileNumber: string) => {
	const mob_regex = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/;
	const isValid = mob_regex.test(mobileNumber);
	if (isValid) {
		return true;
	} else {
		return false;
	}
};

export const isValidPhoneNumber = (mobileNumber: string) => {
    const mob_regex = /^[0-9\+]{8,11}$/;
    const isValid = mob_regex.test(mobileNumber);
    if (isValid) {
        return true;
    } else {
        return false;
    }
};

export const isZipCodeValid = (mobileNumber: string) => {
	const mob_regex = /^[a-zA-Z0-9]{5,5}$/;
	const isValid = mob_regex.test(mobileNumber);
	if (isValid) {
		return true;
	} else {
		return false;
	}
};

export const isNumber = (number: string) => {
	const number_regex = /^[0-9]*$/;
	const isValid = number_regex.test(number);
	if (isValid) {
		return true;
	} else {
		return false;
	}
};

export const formatPhoneNumber = (mobileNumber: string) => {
	const cleaned = ('' + mobileNumber).replace(/\D/g, '');
	const match1 = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
	const match2 = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/);
	const match3 = cleaned.match(/^(\d{3})(\d{3})(\d{2})$/);
	const match4 = cleaned.match(/^(\d{3})(\d{3})(\d{1})$/);
	const match5 = cleaned.match(/^(\d{3})(\d{2})(\d{1})$/);
	const match6 = cleaned.match(/^(\d{3})(\d{3})(\d{5})$/);
	const match7 = cleaned.match(/^(\d{3})(\d{3})(\d{6})$/);
	if (match1) {
		return `${match1[1]} ${match1[2]} ${match1[3]}`;
	} else if (match2) {
		return `${match2[1]} ${match2[2]} ${match2[3]}`;
	} else if (match3) {
		return `${match3[1]} ${match3[2]} ${match3[3]}`;
	} else if (match4) {
		return `${match4[1]} ${match4[2]} ${match4[3]}`;
	} else if (match5) {
		return `${match5[1]} ${match5[2]} ${match5[3]}`;
	} else if (match6) {
		return `${match6[1]} ${match6[2]} ${match6[3]}`;
	} else if (match7) {
		return `${match7[1]} ${match7[2]} ${match7[3]}`;
	}
	return mobileNumber;
};

export const validateInput = (name: string) => {
	return !!name.match(/(.*[a-zA-Z]){3}/i);
};

export const isValidURL = (str: string) => {
	const pattern = new RegExp(
		'^(https?:\\/\\/)?' + // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
			'(\\#[-a-z\\d_]*)?$',
		'i'
	); // fragment locator
	return !!pattern.test(str);
};

export const isUserLinkedInURLValid = (str: string) => {
	const res = str?.match(
		/^http[s]?:\/\/www\.linkedin\.com\/(in|pub|public-profile\/public-profile\/pub)\/([\w-#!:.?+=&%@!/]+)$/gm
	);
	if (res) return true;
	else return false;
};

export const isCompanyLinkedInURLValid = (str: string) => {
	const regex =
		/^https?:\/\/(?:www\.)?(?:(?:[a-z]{2,3}\.)?linkedin\.com\/company|in\.linkedin\.com\/pub)\/[\w-#!:.?+=&%@!/'()]+$/i;
	const res = regex.test(str);
	if (res) return true;
	else return false;
};

export const isEmailValid = (str: string) => {
	const emailRegex =
		// eslint-disable-next-line no-useless-escape
		/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
	if (emailRegex.test(str) || str.length === 0) {
		return true;
	} else {
		return false;
	}
};

export const isValidDecimal = (number: string) => {
	const decimalRegex = /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/;
	const isDecimalValid = decimalRegex.test(number);
	if (isDecimalValid) {
		return true;
	} else {
		return false;
	}
};

export const isValidAmount = (number: string) => {
	const decimalRegex = /^\d*\.?\d+$/;
	const isAmountValid = decimalRegex.test(number);
	if (isAmountValid) {
		return true;
	} else {
		return false;
	}
};

export const documentTypeRegex =
	/^application\/(pdf|msword|vnd\.openxmlformats-officedocument\.wordprocessingml\.document)$/;

export const isValidateEmail = (email: string) => {
	const forbiddenDomains =
		/(gmail|hotmail|icloud|att|me|msn|optonline|sbcglobal|yahoo|aol|gmx|verizon)/i;
	return forbiddenDomains.test(email?.toLocaleLowerCase());
};

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const isValidName = (name: string) => {
	return !/[~`!@#$%^&*()\-+{}[\]|\\;:<>?,.]/.test(name);
};

export const isValidCountryCode = (code: string) => {
    return /^(\+?\d{1,3}|\d{1,4})$/.test(code);
};

export const isValidRecipientName = (name: string) => {
	// Allow letters (uppercase and lowercase) and spaces in the name, but not hyphens
	return /^[A-Za-z ]+$/.test(name);
};

export const findDuplicates = (array: string[]): string[] => {
	const duplicates = array.filter(
		(item, index) => array.indexOf(item) !== index
	);
	return Array.from(new Set(duplicates)); // Remove duplicates from duplicates array
};

export const isOnlySpace = (name: string) => {
	return /^\s+$/.test(name);
};
