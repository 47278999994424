import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Image } from '@storybook';

import { RolesTemplateState, UserRoles } from 'global-stores';
import { RoleAndPermissionNavigation } from 'views/user-roles/constant';
import {
	PermissionNavigation,
	SelectedUserRoleState,
} from 'views/user-roles/store';
import { PermissionTag } from '../permission-tag';

import './create-new-role-card.scss';

export const CreateNewRoleCard = () => {
	const templateRoles = useRecoilValue(RolesTemplateState);
	const [selectedRole, setSelectedRole] = useState('');
	const setNavigation = useSetRecoilState(PermissionNavigation);
	const setSelectedUserRole = useSetRecoilState(SelectedUserRoleState);

	const handleSelectRole = useCallback(
		(role: UserRoles) => {
			setSelectedRole(role?._id);
			setSelectedUserRole(role);
		},
		[setSelectedUserRole]
	);

	const selectedRoleActive = useCallback((isActive: boolean) => {
		return classNames('CreateNewRoleCard--container__card hover', {
			'section-border': isActive,
		});
	}, []);

	const defaultRoleActive = useCallback((isActive: boolean) => {
		return classNames('CreateNewRoleCard--container__addcard hover', {
			'section-border': isActive,
		});
	}, []);

	const handleOnClickAddNewRole = () => {
		setNavigation(RoleAndPermissionNavigation.AddNewRole);
		setSelectedUserRole(null);
	};

	return (
		<div className="CreateNewRoleCard--container">
			<div
				onClick={handleOnClickAddNewRole}
				className={defaultRoleActive(!selectedRole)}
			>
				<div className="CreateNewRoleCard--container__icon">
					<Image fileName="PlusIcon.svg" />
				</div>
				<div className="CreateNewRoleCard--container__label">Add New Role</div>
			</div>
			{templateRoles?.map((role: UserRoles) => {
				const isActive = selectedRole === role?._id;
				return (
					<div
						className={selectedRoleActive(isActive)}
						key={role?._id}
						onClick={() => handleSelectRole(role)}
					>
						<div className="CreateNewRoleCard--container__card--radio">
							<input type="radio" checked={isActive} />
						</div>
						<div className="CreateNewRoleCard--container__card--data">
							<div className="CreateNewRoleCard--container__card--text">
								<div className="CreateNewRoleCard--container__card--header">
									{role?.name ?? 'NA'}
								</div>
								<div className="CreateNewRoleCard--container__card--desc">
									{role?.description ?? 'NA'}
								</div>
							</div>
							<PermissionTag role={role} />
						</div>
					</div>
				);
			})}
		</div>
	);
};
