import { useCallback, useState } from 'react';

import { API_URL } from 'constant';
import { useNetwork } from 'hooks';
import { IUploadedDocuments } from 'global-stores';


export const useSignedDocuments = () => {

	const [isLoading, setIsLoading] = useState(false);

	const { get: getSignedDocs } = useNetwork();

	function sortByDate(arr: IUploadedDocuments[]): IUploadedDocuments[] {
		arr.sort(
			(a, b) =>
				new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
		);
		return arr;
	}
	const getAllSignDocuments = useCallback(() => {
		setIsLoading(true)
		return getSignedDocs(API_URL.SIGN_AGREEMENT)
			.then(resp => {
				const { data: temp } = resp ?? {};
				const sortedDataByDate = sortByDate(temp);
				const allowedDocs = ['reconfig', 'saved'];
				const data = sortedDataByDate?.filter((i: any) => {
					if (i.status) {
						return allowedDocs.includes(i.status);
					}
					return true;
				});
				setIsLoading(false);
				return data;
			})
			.catch(() => {
				setIsLoading(false);
				return null;
			});
	}, [getSignedDocs]);

	function findNodeById(nodes: any, id: string) {
		return nodes.find((node: any) => node._id === id);
	}
	
	function checkMultipleSignAgreementInFlow(nodes: any, nodeId: string) {
		const currentNode = nodes?.find((node: any) => node._id === nodeId);
	
		if (!currentNode) {
			return false; // Node with the provided ID not found
		}
	
		// Helper function to traverse upstream (parents)
		function checkUpstream(node: any, visited = new Set()) {
			if (visited.has(node._id)) return false;
			visited.add(node._id);
	
			// Check parent nodes but exclude the current node
			for (const parentId of node.parentId) {
				const parentNode = findNodeById(nodes, parentId);
				if (parentNode && parentNode._id !== nodeId && parentNode.stepId === 'signAgreement') {
					return true;
				}
				if (parentNode && checkUpstream(parentNode, visited)) {
					return true;
				}
			}
			return false;
		}
	
		// Helper function to traverse downstream (children)
		function checkDownstream(node: any, visited = new Set()) {
			if (visited.has(node._id)) return false;
			visited.add(node._id);
	
			// Find child nodes but exclude the current node
			const childNodes = nodes.filter((n: any )=> n.parentId.includes(node._id));
			for (const childNode of childNodes) {
				if (childNode._id !== nodeId && childNode.stepId === 'signAgreement') {
					return true;
				}
				if (checkDownstream(childNode, visited)) {
					return true;
				}
			}
			return false;
		}
	
		// Check upstream and downstream for any other signAgreement node
		return checkUpstream(currentNode) || checkDownstream(currentNode);
	}

	return {
		getAllSignDocuments,
		isLoading,
		checkMultipleSignAgreementInFlow
	};
};
