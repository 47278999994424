import { REACT_APP_API_HOST as API_HOST } from 'envs';

const host = () => {
	return window.location.origin + '/';
};
export type IEnvironmentHost = 'nonStage' | 'stage' | 'pp' | 'prod' | 'beta';

export const envUrl = host();

const isStage = /stage/gi.test(API_HOST);
const isPP = /pp/gi.test(API_HOST);
const isProd = /api.simplici.io/gi.test(API_HOST);
const isBeta = /beta/gi.test(API_HOST);

const environmentHost = (): IEnvironmentHost => {
	if (isStage) return 'stage';
	if (isPP) return 'pp';
	if (isProd) return 'prod';
	if (isBeta) return 'beta';
	return 'stage';
};

export const envHost: any = environmentHost();

const envLocalHost = () => {
	const regexPattern = /localhost/;
	const url = window.location.href;
	if (regexPattern.test(url)) return true;
	else return false;
};

export const localHost = envLocalHost();
