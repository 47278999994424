import { IAml, IValue } from '.';

import { FC, useCallback, useMemo } from 'react';

import { ProgressBar } from '@storybook';
import { AmlDetails } from './components';
import { formateMonthDayYear } from 'utils';

interface IAmlVerifyIntoJsx {
	aml: IAml;
	firstName: IValue;
	lastName: IValue;
	dateOfBirth: IValue;
}
export const AmlVerifyIntoJsx: FC<IAmlVerifyIntoJsx> = ({
	aml,
	firstName,
	lastName,
	dateOfBirth,
}) => {
	const showValue = useCallback(
		(value: any) => {
			if (value && value?.length > 0) {
				return value;
			}
			return '--';
		},
		[]
	);

	const getFullName = useMemo(() => {
		if (aml?.info?.name) {
			return aml?.info?.name
		}
		else if (firstName?.value && lastName?.value) {
			return `${firstName.value} ${lastName.value}`
		}
		else {
			return "NA"
		}
	},[aml?.info?.name, firstName?.value, lastName?.value])

	const handleRiskProgressColor = useCallback((data: number) => {
		if (data >= 60) return '#C43131';
		else if (40 < data && data < 60) return 'orange';
		else return '#389F75';
	}, []);

	const handleRiskProgressLabel = useCallback((data: number) => {
		if (data >= 60) return 'High';
		else if (40 < data && data < 60) return 'Moderate';
		else return 'Low';
	}, []);

	const getDOBValue = useMemo(() => {
		return formateMonthDayYear(dateOfBirth?.value ?? aml?.info?.dob);
	}, [aml?.info?.dob, dateOfBirth?.value]);

	return (
		<div className="kycDetail-column">
			{aml ? (
				(aml?.transactionId && aml?.info) || ((aml as any)?.[0]?.transactionId && (aml as any)?.[0]?.info) ?
				<>
					<div className="kycDetail-row">
						<div className="report-label">ID</div>
						<div>:</div>
						<p>{showValue(aml?.transactionId)}</p>
					</div>
					<div className="kycDetail-row">
						<div className="report-label">Name</div>
						<div>:</div>
						<p>
							{getFullName}
						</p>
					</div>
					<div className="kycDetail-row">
						<div className="report-label">D.O.B</div>
						<div>:</div>
						<p>{getDOBValue.length > 2 ? getDOBValue : 'NA'}</p>
					</div>
					{!!aml?.info?.aliases && (
						<div className="kycDetail-row">
							<div className="report-label">Aliases</div>
							<div>:</div>
							<p>{showValue(aml?.info?.aliases)}</p>
						</div>
					)}
					{!!aml?.info?.positions && (
						<div className="kycDetail-row">
							<div className="report-label">Positions</div>
							<div>:</div>
							<p>{showValue(aml?.info?.positions)}</p>
						</div>
					)}
					{!!aml?.info?.address?.city && (
						<div className="kycDetail-row">
							<div className="report-label">City</div>
							<div>:</div>
							<p>{showValue(aml?.info?.address?.city)}</p>
						</div>
					)}
					{!!aml?.info?.address?.stateProv && (
						<div className="kycDetail-row">
							<div className="report-label">State</div>
							<div>:</div>
							<p>{showValue(aml?.info?.address?.stateProv)}</p>
						</div>
					)}
					{!!aml?.info?.address?.countryCode && (
						<div className="kycDetail-row">
							<div className="report-label">Country Code</div>
							<div>:</div>
							<p>{showValue(aml?.info?.address?.countryCode)}</p>
						</div>
					)}
					<div className="kycDetail-row">
						<div className="report-label">PEP Watchlist</div>
						<div>:</div>
						<p>
							{aml?.info?.pepWatchList ? (
								<div className="kyc-label-wrapper">
									<i className="ri-alert-fill kyc-label-danger" /> Matches Found
								</div>
							) : (
								<div className="kyc-label-wrapper">
									<i className="ri-checkbox-circle-fill kyc-label-success" /> No
									Matches Found
								</div>
							)}
						</p>
					</div>
					<div className="kycDetail-row">
						<div className="report-label">Sanction Watchlist</div>
						<div>:</div>
						<p>
							{aml?.info?.sanctionWatchList ? (
								<div className="kyc-label-wrapper">
									<i className="ri-alert-fill kyc-label-danger" /> Matches Found
								</div>
							) : (
								<div className="kyc-label-wrapper">
									<i className="ri-checkbox-circle-fill kyc-label-success" /> No
									Matches Found
								</div>
							)}
						</p>
					</div>
					<div className="kycDetail-row">
						<div className="report-label">Adverse Media</div>
						<div>:</div>
						<p>
							{aml?.info?.adverseMedia ? (
								<div className="kyc-label-wrapper">
									<i className="ri-alert-fill kyc-label-danger" /> Matches Found
								</div>
							) : (
								<div className="kyc-label-wrapper">
									<i className="ri-checkbox-circle-fill kyc-label-success" /> No
									Matches Found
								</div>
							)}
						</p>
					</div>
					<div className="kycDetail-row">
						<div className="report-label">Threat Level</div>
						<div>:</div>
						<ProgressBar
							completed={aml?.info?.riskscore ?? 0}
							bgColor={handleRiskProgressColor(aml?.info?.riskscore ?? 0)}
							labelClassName="risk-label"
							customClass="avg-risk-progress"
							height="4px"
							customLabel={handleRiskProgressLabel(aml?.info?.riskscore ?? 0)}
							labelAlignment="outside"
						/>
					</div>
					{aml?.info && Object.keys(aml?.info).length > 0 && (
						<div className="aml-record">
							<div className="aml-record__scores">
								<div className="aml-record__match-score">
									<div className="aml-record__match-icon">
										<i className="ri-body-scan-fill"></i>
									</div>
									<div className="aml-record__match-text">
										<div className="aml-record__match-value">
											{aml?.info?.matchScore}
										</div>
										<div className="aml-record__match-label">Match Score</div>
									</div>
								</div>
								<div className="aml-record__risk-score">
									<div className="aml-record__match-icon">
										<i className="ri-search-eye-line"></i>
									</div>
									<div className="aml-record__match-text">
										<div className="aml-record__match-value">
											{aml?.info?.riskscore}
										</div>
										<div className="aml-record__match-label">Risk Score</div>
									</div>
								</div>
							</div>
							{!aml?.info?.riskscore && !aml?.info?.matchScore && (
								<div className="aml-record-discr-data">
									<div className="aml-record-discr-detail">
										This person has been found clean. No adverse record has been
										found for the person whatsoever . We may go ahead with the
										AML approval .
									</div>
								</div>
							)}
							{aml?.info?.registered_events?.length > 0 &&
								aml.info.registered_events.map((item: any, index: number) => {
									return (
										<div
											className="aml-record-discr-data"
											// eslint-disable-next-line react/no-array-index-key
											key={`${item?.source?.sourceName}_${index}`}
										>
											<div className="aml-record-discr">
												{item?.source?.headline}
											</div>
											<div className="aml-record-discr-detail">
												{item?.event_desc}
											</div>
											<div className="aml-record-date-source">
												URL :{' '}
												<a
													href={item?.source?.sourceURL}
													className="record-source-name"
													target="_blank"
													rel="noreferrer"
												>
													{item?.source?.sourceURL}
												</a>
											</div>
											<div className="aml-record-date-source">
												{item?.source?.entityDt && (
													<div className="record-date">
														{item?.source?.entityDt}
													</div>
												)}
												<span className="record-dot"></span>
												{item?.source?.sourceName}
											</div>
										</div>
									);
								})}
						</div>
					)}
				{/* Pradeep Chaurasia : Aml new UI implemented as per the UI design */}
				</> : <AmlDetails aml={aml}/>
			) : (
				<div className="kycDetail-row"> No data </div>
			)}
		</div>
	);
};
