import React, { useState } from 'react';

import './custom-pagination.scss';

interface CustomPagination {
	totalItems: number;
	itemsPerPage: number;
	onPageChange: (page: number) => void;
}

export const CustomPagination: React.FC<CustomPagination> = ({
	totalItems,
	itemsPerPage,
	onPageChange,
}) => {
	const totalPages = Math.ceil(totalItems / itemsPerPage);
	const [currentPage, setCurrentPage] = useState(1);

	const handlePageChange = (page: number) => {
		if (page < 1 || page > totalPages) {
			return; // Don't allow invalid page numbers
		}

		setCurrentPage(page);
		onPageChange?.(page);
	};

	const renderPageNumbers = () => {
		const pageNumbers: JSX.Element[] = [];

		for (let i = 1; i <= totalPages; i++) {
			if (
				i === 1 ||
				i === totalPages ||
				(i >= currentPage - 1 && i <= currentPage + 1)
			) {
				pageNumbers.push(
					<div
						key={i}
						className={`page-item${currentPage === i ? ' active-item' : ''}`}
						onClick={() => handlePageChange(i)}
					>
						{i}
					</div>
				);
			} else if (i === currentPage - 2 || i === currentPage + 2) {
				pageNumbers.push(
					<div key={`ellipsis-${i}`} className="page-item disabled">
						<span className="page-link">...</span>
					</div>
				);
			}
		}

		return pageNumbers;
	};

	return (
		<nav>
			<div className="pagination">
				<div
					className={`page-item ${
						currentPage === 1 ? 'page-item-disabled' : ''
					}`}
					onClick={() => handlePageChange(currentPage - 1)}
				>
					Previous
				</div>
				{renderPageNumbers()}
				<div
					className={`page-item ${
						currentPage === totalPages ? 'page-item-disabled' : ''
					}`}
					onClick={() => handlePageChange(currentPage + 1)}
				>
					Next
				</div>
			</div>
		</nav>
	);
};
