import type { IUserAgentDetails } from './states';

import classNames from 'classnames';
import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { Image } from '@storybook';
import { ActiveTabsIntegrateState } from 'global-stores';
import { getHoursMinutesSeconds } from 'helpers';
import { useOutsideClick } from 'hooks';
import { AgentStatus } from './agent-status';
import { SUPPORT_AGENT_HEADER, chartName, lineBarColor } from './constants';
import { DonutPieChart } from './donut-chart';

export const UserAgentDetails: FC<IUserAgentDetails> = ({
	agentData,
	userData,
	agent,
	index,
}) => {
	const [activeRow, setActiveRow] = useState(false);
	const activeIntegrate = useRecoilValue(ActiveTabsIntegrateState);

	const userRowClass = useMemo(() => {
		return classNames('support-agent_table-row', {
			'support-agent_table-row-active': activeRow,
		});
	}, [activeRow]);

	const showUserProfileImage = useCallback((image: string) => {
		return {
			...(image && { url: image }),
			...(!image && {
				fileName: 'AvatarDefault-Light.svg',
			}),
		};
	}, []);

	const userDetailClass = useMemo(() => {
		return classNames('support-agent_details-close', {
			'support-agent_details': activeRow,
			'support-agent_details-webComponent': activeIntegrate === 'webcomponent',
		});
	}, [activeIntegrate, activeRow]);

	const donutData = useMemo(
		() =>
			Object.keys(agentData ?? []).map(data => {
				return {
					name: chartName[data],
					y: (agentData[data].averageTime / userData.sessionAvgTime) * 100,
					count: agentData[data].total,
					time: agentData[data].averageTime,
					color: lineBarColor[data],
				};
			}),
		[agentData, userData.sessionAvgTime]
	);

	const renderAgentStatus = useMemo(
		() =>
			donutData.map(data => {
				return (
					<AgentStatus
						key={data.name}
						name={data.name}
						count={data.count}
						time={data.time}
						percent={data.y}
					/>
				);
			}),
		[donutData]
	);

	const ref: any = useRef();
	useOutsideClick(ref, () => {
		setActiveRow(false);
	});

	return (
		<div ref={ref}>
			<div
				className={userRowClass}
				onClick={() => {
					setActiveRow(pre => !pre);
				}}
				key={index}
			>
				{SUPPORT_AGENT_HEADER.map(({ key }) => {
					switch (key) {
						case 'name':
							return (
								key === 'name' && (
									<div className="support-agent_table-row_name">
										<Image
											className="support-agent_table-row_name_image"
											{...showUserProfileImage(userData?.profileImage ?? '')}
										/>
										{agent || '--'}
									</div>
								)
							);
						case 'sessionAvgTime':
							return (
								<div
									key={key + '-' + index}
									className="support-agent_table_header"
								>
									{getHoursMinutesSeconds(userData?.sessionAvgTime)}
								</div>
							);
						default:
							return (
								<div className="support-agent_table_header">
									{userData?.[key as string] || '--'}
								</div>
							);
					}
				})}
			</div>
			<div className={userDetailClass}>
				{activeRow && (
					<>
						<div
							key={index + '-' + index}
							className="support-agent_details_user"
						>
							<Image
								className="support-agent_details_user_image"
								{...showUserProfileImage(userData?.profileImage ?? '')}
							/>
							<div className="support-agent_details_user_container">
								<div className="support-agent_details_user_agent">
									Agent Name:
								</div>
								<div className="support-agent_details_user_name">{agent}</div>
							</div>
						</div>
						<DonutPieChart
							isLoading={false}
							title={`${agent}-${index}`}
							seriesData={donutData}
						/>
						<div className="support-agent_status">
							<div className="support-agent_status_container">
								<div className="support-agent_status_action">Action</div>
								<div className="support-agent_status_counts">Counts</div>
								<div className="support-agent_status_action">Average Time</div>
							</div>
							<div className="support-agent_status_body">
								{renderAgentStatus}
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};
