export const RECIPIENT_COUNT_ERROR_MESSAGE = 'Total recipient should not be more than ';
export const PipelineInstanseTable = {
	HEADER: [
		{
			label: 'Instance Name',
			key: 'instanceName',
			format: 'string',
		},
		{
			label: 'Creation Date',
			key: 'date',
			format: 'jsx',
		},
		{
			label: 'Fund/Company Name',
			key: 'payIn',
			format: 'string',
		},
		{
			label: 'Sign Agreement Documents',
			key: 'signAgreement',
			format: 'jsx',
		},
		{
			label: '506(b) Custom Doc',
			key: '506b',
			format: 'jsx',
		},
		{
			label: 'Actions',
			key: 'action',
			format: 'jsx',
		},
	],
};

export const isShowFooter: { [key: string]: boolean } = {
	create: true,
	signAgreement: true,
	connectBank: true,
	csv: true,
	configComplex: true,
	reconfigQR:true,
	proofReading: true,
};

export const isHideHeader: { [key: string]: boolean } = {
	instance_name: true,
};

export const QrMessage = {
	QR_UNARCHIVE: 'QR instance successfully unarchived.',
	QR_GENERATED: 'QR instance successfully generated.',
	QR_ARCHIVED : 'QR instance successfully archived.'
};
