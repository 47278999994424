import { useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import {
	EscrowModalBodyNavigatorState,
	FundDataForEscrowState,
	useEscrowAccount,
} from 'views/fund-escrow/stotes';
import { AddBankAccount } from '../add-bank';
import { ChangeBank } from '../change-bank';
import { DepositFund } from '../deposit-fund';
import { ShowDetails } from '../show-detail';
import { SuccessScreen } from '../success-screen';
import { VerifyingDetails } from '../verifying-details';
import { WithdrawFund } from '../withdraw-fund';
import './escrow-modal-body.scss';

export const EscrowModalBody = () => {
	// globle states
	const fund = useRecoilValue(FundDataForEscrowState);
	const navigate = useRecoilValue(EscrowModalBodyNavigatorState);

	// hooks
	const { getEscrowDetails } = useEscrowAccount();

	const { escrowAccounts } = useMemo(() => ({ ...fund }), [fund]);

	useEffect(() => {
		if (escrowAccounts.length) getEscrowDetails(true);
		// eslint-disable-next-line
	}, []);

	const renderComponent = useMemo(() => {
		switch (navigate) {
			case 'show_details':
				return <ShowDetails />;
			case 'add_bank_account':
				return <AddBankAccount />;
			case 'deposite_fund':
				return <DepositFund />;
			case 'withdraw_fund':
				return <WithdrawFund />;
			case 'change_bank':
				return <ChangeBank />;
			case 'verifying_details':
				return <VerifyingDetails />;
			case 'success_screen':
				return <SuccessScreen />;
			default:
				return <></>;
		}
	}, [navigate]);
	return (
		<>
			<div className="escrow-modal-wrapper">{renderComponent}</div>
		</>
	);
};
