import { FC } from 'react';

interface IProps {
	label: string;
	value: string;
}

export const CardSection: FC<IProps> = ({ label, value }) => {
	return (
		<div key={label} className="kyb-wrapper__details__kybInfoDetailsRow">
			<div className="kyb-wrapper__details__kybInfoDetailsRow__label">
				{label || '--'}
			</div>

			<div className="kyb-wrapper__details__kybInfoDetailsRow__value">
				<span>:</span>
				<div>{value || '--'}</div>
			</div>
		</div>
	);
};
