import {
	Fragment,
	MouseEvent,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { NoDataAvailable, ReactResponsiveTable } from '@storybook';
import Modal from '@storybook/new-modal/modal';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { HeaderOptions, AuthConfigure, EmbededCode } from './components';
import { Header } from 'views/header';
import { HEADER_OPTIONS, InstanceTableHeaders } from './constants';
import { getDate } from 'utils';
import { useNetwork, useNotification } from 'hooks';
import { API_URL } from 'constant';
import {
	AuthComplexListsState,
	AuthProvidersState,
	useInstance,
} from './hooks.ts';
import { PERMISSION_SCREEN, useUserRoles } from 'views';

import './auth.scss';

const Auth0 = () => {
	const [activeOption, setActiveOption] = useState('generalSettings');
	const [currentModel, setCurrentModel] = useState('');
	const [selectedInstance, setSelectedInstace] = useState<any>({});

	const [totalItems, setTotalItems] = useState(0);

	const [instances, setInstances] = useState<any>([]);
	const [loading, setLoading] = useState(false);
	const { get: getComplexList, loading: loadingComplex } = useNetwork();

	const params = new URLSearchParams(window.location.search);
	const limit = params.get('limit');
	const page = params.get('page');
	const id = params.get('id');

	const { getInstances, getProviders } = useInstance();

	const navigate = useNavigate();
	const [data, setData] = useState([]);

	const { successNotification } = useNotification();

	const handleChangeOptions = (option: {
		label: string;
		value: string;
		isDisabled: boolean;
	}) => {
		if (option.isDisabled) {
			return;
		}
		setActiveOption(option.value);
	};

	const [complexList, setAuthComplexLists] = useRecoilState(
		AuthComplexListsState
	);
	const setAuthProviders = useSetRecoilState(AuthProvidersState);

	const fetchDetails = async () => {
		const complexLists = getComplexList(API_URL.QR_FLOWS);
		const providers = getProviders();
		const [complex, provider] = await Promise.all([complexLists, providers]);

		if (complex?.data?.length) {
			setAuthComplexLists(complex.data);
		}
		if (id) {
			setCurrentModel('instance-details');
		}
		if (provider.data.length) {
			setAuthProviders(provider.data);
		}

		// eslint-disable-next-line no-console
		console.log({ complex, provider });
	};

	useEffect(() => {
		fetchDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleClose = useCallback(
		(value: boolean, isNewAdded?: boolean, response?: any) => {
			if (isNewAdded) {
				navigate(`/auth?page=${1}&limit=${limit ?? 10}`);
				if (response) {
					setInstances((prev: any) => {
						const newObj = structuredClone(prev);
						newObj.unshift(response);
						return newObj;
					});
				}

				setTotalItems(prev => prev + 1);
			} else {
				if (response) {
					setInstances((prev: any) => {
						const newObj = structuredClone(prev);
						const index = newObj.findIndex(
							(el: any) => el._id === response._id
						);
						if (index !== -1) {
							newObj[index] = response;
						}
						return newObj;
					});
				}
			}
			setCurrentModel(value ? 'instance-details' : '');
		},
		[navigate, limit]
	);

	const renderComponent = useMemo(() => {
		switch (activeOption) {
			case 'generalSettings':
				return (
					<AuthConfigure
						onClose={handleClose}
						complexLists={complexList}
						isLoading={loadingComplex}
					/>
				);
			case 'connections':
				return <div></div>;
			default:
				return <div>Component is not created yet</div>;
		}
	}, [activeOption, complexList, handleClose, loadingComplex]);

	const handleCopy = useCallback(
		(event: MouseEvent<HTMLDivElement>, clientId: string) => {
			event.stopPropagation();
			navigator.clipboard.writeText(clientId);
			successNotification('Successfully Copied Client Id ');
		},
		[successNotification]
	);

	const { checkUserWritePermission } = useUserRoles();

	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.Auth),
		[checkUserWritePermission]
	);

	const fetchAllInstances = useCallback(async () => {
		setLoading(true);
		const resp: any = await getInstances(
			Number(page ?? 1),
			Number(limit ?? 10)
		);
		setInstances(resp?.data?.data ?? []);
		setTotalItems(resp?.data?.totalCount ?? 0);
		setLoading(false);
	}, [getInstances, limit, page]);

	useEffect(() => {
		fetchAllInstances();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, limit]);

	const handleActions = (
		type: string,
		event?: MouseEvent<HTMLDivElement>,
		item?: any
	) => {
		event?.stopPropagation();
		setSelectedInstace(item);
		setCurrentModel(type);
	};

	useEffect(() => {
		if (instances?.length && InstanceTableHeaders.length) {
			const rows: any = [];
			instances.forEach((item: any) => {
				if (item) {
					let row: any = {};
					row._id = item._id;
					row.createdAt = item.createdAt;
					InstanceTableHeaders.forEach(({ key }) => {
						if (key === 'signup') {
							const value = () =>
								item.signUp.required ? (
									<div className="signup-required">
										<i className="ri-checkbox-circle-fill signup-required-icon"></i>
										<span>Yes</span>
									</div>
								) : (
									<div className="signup-not-required">
										<i className="ri-close-circle-fill signup-not-required-icon"></i>
										<span>No</span>
									</div>
								);
							return (row[key] = value);
						}
						if (key === 'actions') {
							const value = () => (
								<div className="actions">
									<div onClick={e => handleActions('embeded-code', e, item)}>
										<i className="ri-code-s-slash-fill actions-icon"></i>
									</div>
								</div>
							);
							return (row[key] = value);
						}

						if (key === 'authId') {
							const value = () => (
								<div className="auth-id">
									<div>{item[key]}</div>
									<div
										onClick={e => handleCopy(e, item[key])}
										className="auth-clientId-copy-btn"
									>
										<i className="ri-file-copy-fill copy-icon"></i>
									</div>
								</div>
							);
							return (row[key] = value);
						}

						if (key === 'date') {
							const value = () => <>{getDate(item['createdAt'])}</>;
							return (row['date'] = value);
						}
						row = { ...row, [key]: item[key as 'name'] ?? 'N/A' };
						return null;
					});
					rows.push(row);
				}
			});
			return setData(rows);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [instances]);

	const handleRowClick = useCallback(
		(event: any) => {
			if (!isUserPermissionWrite) return;
			if (page && limit) {
				navigate(`/auth?page=${page}&limit=${limit}&id=${event._id}`);
			} else {
				navigate(`/auth?id=${event._id}`);
			}
			setCurrentModel('instance-details');
		},
		[isUserPermissionWrite, limit, navigate, page]
	);

	const handlePageChange = useCallback(
		(e: any) => {
			if (e) {
				navigate(`/auth?page=${e}&limit=10`);
			}
		},
		[navigate]
	);

	const closeModal = () => {
		if (page && limit) {
			navigate(`/auth?page=${page}&limit=${limit}`);
		} else {
			navigate(`/auth`);
		}
		setCurrentModel('');
		setSelectedInstace({});
	};

	const { components, ...rest } = useMemo(() => {
		switch (currentModel) {
			case 'instance-details':
				return {
					modalName: 'auth-configure',
					className: 'auth-configure-modal',
					closeModal: closeModal,
					showHeader: true,
					isOpen: true,
					components: (
						<div className="auth-configure-modal__tabs">
							<HeaderOptions
								options={HEADER_OPTIONS}
								handleChange={handleChangeOptions}
								active={activeOption}
							/>
							{renderComponent}
						</div>
					),
					title: (
						<div className="auth-configure-modal__header">
							<div className="auth-configure-modal__label">Auth</div>
							<div className="auth-configure-modal__sublabel">
								Configure authentication settings
							</div>
						</div>
					),
				};
			case 'embeded-code':
				return {
					isOpen: true,
					modalName: 'embeded-code',
					className: 'auth-embeded-code-modal',
					closeModal: closeModal,
					showHeader: true,
					components: <EmbededCode instanceId={selectedInstance._id} />,
					title: (
						<div className="auth-configure-modal__header">
							<div className="auth-configure-modal__label">{`${selectedInstance.name}`}</div>
							<div className="auth-configure-modal__sublabel">
								Use the below code for Simplici Authentication
							</div>
						</div>
					),
				};

			default:
				return {
					isOpen: false,
					modalName: 'embeded-code',
					closeModal: closeModal,
					showHeader: true,
					components: <></>,
					title: '',
				};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		activeOption,
		currentModel,
		renderComponent,
		selectedInstance._id,
		selectedInstance.name,
	]);

	return (
		<Fragment>
			<div className="auth-wrapper">
				<Header
					title="Auth"
					showBtn={true}
					btnLabel={'Create New'}
					handleClickBtn={() => {
						setCurrentModel('instance-details');
					}}
				/>

				<div className="p-24">
					{!data.length && !loading ? (
						<NoDataAvailable
							illustration="empty-api-list.svg"
							message={'No auth instance created yet'}
							description="You have not created any auth instance yet."
							label="Create new instance"
							handleClickBtn={() => {
								setCurrentModel('instance-details');
							}}
							hideBtn={!isUserPermissionWrite}
						/>
					) : (
						<ReactResponsiveTable
							rows={loading ? [] : data}
							column={InstanceTableHeaders}
							isLoading={loading || loadingComplex}
							tableType="auth"
							columnHandle
							handelRowClick={handleRowClick}
							numberPagination
							height="calc(100vh - 200px)"
							totalItems={totalItems}
							itemsPerPage={Number(limit ?? 10)}
							handlePageChange={handlePageChange}
							noOfLoaadingRow={11}
							currentSelectPage={Number(page ?? 1)}
							hideSortKey={['signup', 'authId']}
						/>
					)}
				</div>

				{rest.isOpen && <Modal {...rest}>{components}</Modal>}
			</div>
		</Fragment>
	);
};

export default Auth0;
