import { AddCard } from './components';
import './addPaymentCard.scss';
import { FC } from 'react';
import 'views/billing/billing.scss';
interface IAddPaymentCard {
    from?: 'SIGNUP' | 'HOME';
}
export const AddPaymentCard: FC<IAddPaymentCard> = ({ from }) => {
    return (
        <div className="payment-add-card-container">
            <AddCard from={from} />
        </div>
    );
};