import { atom } from 'recoil';

import { IAgreements, IOnboardingQRModalNavigate, ISelectedPipelineQrState } from './types';

export const OnboardingQRModalOpenState = atom<boolean>({
	key: 'onboarding-qr-modal-open-state-key',
	default: false,
});

export const OnboardingQRModalNavigateState = atom<IOnboardingQRModalNavigate>({
	key: 'onboarding-qr-modal-navigate-state-key',
	default: 'table',
});

export const OnboardingQRInstanceNameState = atom<string>({
	key: 'onboarding-qr-instance-name-state-key',
	default: '',
});

export const SelectedQrInstanceState = atom<ISelectedPipelineQrState | null>({
	key: 'selected-qr-instance-state-key',
	default: null,
});
export const SelectedQrOnbordingFlowName = atom<any>({
	key: 'selected-qr-onbording-flow-name',
	default: null,
});

export const SelectedPipelineDetailsForQRState = atom<any>({
	key: 'selected-pipeline-details-for-qr-state-key',
	default: {},
});

export const SelectedPipelineQrListState = atom<ISelectedPipelineQrState[]>({
	key: 'selected-pipeline-qr-list-state-key',
	default: [],
});

export const SelectedQRSate = atom<string>({
	key: 'selected-pipeline-qr-id-key',
	default: '',
});

export const SelectedPipelineQrData = atom<any>({
	key: 'selected-pipeline-qr-list-data-key',
	default: [],
});

export const SelectedQRTemplateIdState = atom<string>({
	key: 'selected-template-id-key',
	default: '',
});

export const SelectedQRInstanceState = atom<IAgreements[] | null>({
	key: 'selected-qr-instance-id-key',
	default: null,
});
