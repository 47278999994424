import { CapTableMapKeyAndValue } from '@storybook/custom-captable/states';
import { useCallback, useMemo } from 'react';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';

import { API_URL } from 'constant';
import {
	GridCAPTableData,
	InviteNavigateModalState,
	PipelineIdState,
	SelectedFundRecipientBankAccount,
	SelectedPipelineDetails,
	UploadedDocsState,
} from 'global-stores';
import { useNetwork, useNotification } from 'hooks';
import {
	MultiSignPrepareUrl,
	SignAgreementComplexStepConfigured,
} from 'views/multi-sign-agreement';
import { EscrowType, KEYLEVEL, ProviderEnum } from 'views/pipelines/constants';
import { useGetSignDocument } from 'views/sign-aggrement/store';
import STATECODE from '../address-details/state.json';
import {
	BusinessInformationState,
	PersonalInformationErrorState,
	PersonalInformationId,
	PersonalInformationState,
} from '../address-details/store';
import { SIGN_OPTIONS } from './constant';
import {
	ComplexConfigurationInvite,
	ConditionalOnboardingFlowState,
	FundsIdState,
	SignOptionState,
	userCapTableState,
} from './state';
import { renderErrorMessage } from './utils';
import { UploadProofDocumentState } from 'views/upload-proof-document';

export const useInviteInvestor = () => {
	const setUserCapTableData = useSetRecoilState(userCapTableState);

	const validateInputs = useCallback(() => {
		const finalXlsValue: any = window;
		finalXlsValue?.luckysheet.exitEditMode();
		const finalData = finalXlsValue?.luckysheet?.transToCellData?.(
			finalXlsValue?.luckysheet.getSheetData()
		);
		const expectedArray = finalData?.reduce((acc: any, curr: any) => {
			if (!acc[curr.r]) {
				acc[curr.r] = [];
			}
			if (curr.v.v) acc[curr.r][curr.c] = curr.v.v?.toString();
			else acc[curr.r][curr.c] = '';
			return acc;
		}, []);
		let payload: any;
		if (expectedArray?.length > 0) {
			payload = {
				headers: expectedArray[0],
				rows: expectedArray.filter(
					(item: any, index: number) =>
						index !== 0 && item.length && item !== ''
				),
			};
			setUserCapTableData(payload);
		}
	}, [setUserCapTableData]);

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const createInviteInvestorPayload = useCallback(() => ({}), []);
	return { validateInputs, createInviteInvestorPayload };
};

export const useInviteInvestorPayload = () => {
	const userCapTableData = useRecoilValue(userCapTableState);
	const uploadedFiles = useRecoilValue(UploadedDocsState);
	const selectedValue = useRecoilValue(CapTableMapKeyAndValue);
	const pipelineId = useRecoilValue(PipelineIdState);
	const capTableData = useRecoilValue(GridCAPTableData);
	const pipelineDetails = useRecoilValue(SelectedPipelineDetails);
	const selectedfundId = useRecoilValue(FundsIdState);
	const signDocOption = useRecoilValue(SignOptionState);
	const subSelectedItem = useRecoilValue(SelectedFundRecipientBankAccount);
	const proofDocData = useRecoilValue(UploadProofDocumentState);
	const { SignDocProvider: provider } = useGetSignDocument();
	const multiSignComplexConfig = useRecoilValue(
		SignAgreementComplexStepConfigured
	);
	const isComplexFlow = useRecoilValue(ConditionalOnboardingFlowState);
	const multiSignSelectedNodeConfig = useRecoilValue(MultiSignPrepareUrl);

	const configuration = useRecoilValue(ComplexConfigurationInvite);

	const inviteInvestorPayload = useMemo(() => {
		const agreements: any[] = [];

		uploadedFiles.forEach(
			({
				documentId: docId,
				templateId,
				isChecked,
				node,
				provider: uploadedProvider,
			}) =>
				isChecked &&
				agreements.push({
					templateId,
					docId: provider === 'docusign' ? docId : undefined,
					provider: uploadedProvider ?? provider,
					...(node && {
						node,
					}),
				})
		);

		const updateHeader = [...(userCapTableData?.headers ?? [])];
		for (let i = 0; i < selectedValue.length; i++) {
			const key: any = Object.keys(selectedValue[i])[0];
			const value = selectedValue[i][key];
			const index = updateHeader.indexOf(value);
			updateHeader[index] = key;
		}

		const indexIA = updateHeader.indexOf('Investment Amount');

		if (indexIA !== -1) {
			updateHeader[indexIA] = 'Dollars Invested';
		}

		const indexFundTransfer = updateHeader.indexOf('Fund Transfer Amount');
		if (indexFundTransfer !== -1) {
			updateHeader[indexFundTransfer] = 'Dollars Invested';
		}

		const indexIAMobile = updateHeader.indexOf('Phone');
		if (indexIAMobile !== -1) {
			updateHeader[indexIAMobile] = 'Mobile';
		}
		const usersPayload = {
			headers: updateHeader,
			rows: userCapTableData?.rows,
		};

		const updaterUserPayload = JSON.parse(JSON.stringify(usersPayload));

		for (let i = 0; i < updaterUserPayload.headers.length; i++) {
			if (updaterUserPayload.headers[i] === '') {
				updaterUserPayload.headers.splice(i, 1);
				for (let j = 0; j < updaterUserPayload.rows.length; j++) {
					updaterUserPayload.rows[j].splice(i, 1);
				}
				i--;
			}
		}
		
		const proofDoc = proofDocData.map(item => ({ docId: item.docId, size: item.size, nodeId: item.node }));

		const payload: any = {
			pipelineId,
			users: updaterUserPayload ?? capTableData,
			agreements,
			proofDoc
		};
		if (
			selectedfundId?.fundId &&
			/payIn|payOut/gi.test(pipelineDetails?.fullStatus)
		) {
			payload.fundId = selectedfundId?.fundId ?? '';
		}

		if (/payIn|payOut|fundInvestment/gi.test(pipelineDetails?.fullStatus)) {
			if (!pipelineDetails.nodes) {
				payload.recipientBank = {
					tokenId: subSelectedItem?._id,
					accountId: subSelectedItem?.accountId,
					accountType: subSelectedItem?.subtype?.toUpperCase?.(),
				};
			} else {
				payload.recipientBanks = configuration['fundInvestment'].map(
					(el: any) => {
						return {
							tokenId: el?._id,
							accountId: el?.accountId,
							accountType: el?.subtype?.toUpperCase?.(),
							nodeId: el.node,
							fundId: el.fundId || undefined,
							// fundName: el.fundName || undefined,
							//Deepak: removed fundName because we have already create fund when we are configuring no need to send fundname to create fund again
						};
					}
				);
			}
		} else {
			payload.recipientBank = {};
		}

		if (signDocOption === SIGN_OPTIONS.COMMON) {
			if (isComplexFlow) {
				const envelopes = Object.keys(multiSignComplexConfig ?? {}).map(
					node => ({
						envelopeId: multiSignComplexConfig[node]?.envelopeId,
						nodeId: node,
					})
				);
				payload.envelopes = envelopes;
			} else {
				payload.envelopes = [
					{ envelopeId: multiSignSelectedNodeConfig.envelopeId },
				];
			}
		}
		return payload;
	}, [
		capTableData,
		configuration,
		isComplexFlow,
		multiSignComplexConfig,
		pipelineDetails?.fullStatus,
		pipelineDetails.nodes,
		pipelineId,
		provider,
		selectedValue,
		selectedfundId?.fundId,
		signDocOption,
		subSelectedItem?._id,
		subSelectedItem?.accountId,
		subSelectedItem?.subtype,
		uploadedFiles,
		userCapTableData?.headers,
		userCapTableData?.rows,
		multiSignSelectedNodeConfig,
		proofDocData
	]);
	return { inviteInvestorPayload };
};

const returnStateCode = (value: string) =>
	STATECODE.find(({ label }) => label === value)?.code ?? 'IN';

interface IPersonalFormType {
	handleSubmitPersonalForm: () => void;
	escrowPersoanlLoading: boolean;
}

export const useSubmitPersonalForm = (): IPersonalFormType => {
	// globle state
	const personalInformationValue = useRecoilValue(PersonalInformationState);
	const setNavigate = useSetRecoilState(InviteNavigateModalState);
	const [plId, setPlId] = useRecoilState(PersonalInformationId);
	const resetPersonalId = useResetRecoilState(PersonalInformationId);
	const setErrorMessage = useSetRecoilState(PersonalInformationErrorState);

	// hooks
	const { errorNotification } = useNotification();
	const { post, loading: escrowPersoanlLoading, patch } = useNetwork();

	const handleSubmitPersonalForm = useCallback(async () => {
		const {
			firstName,
			lastName,
			phone,
			email,
			address,
			zip,
			city,
			state,
			// country,
			dob,
			ssn,
		} = personalInformationValue;

		const payloadReq = {
			type: EscrowType.Personal,
			provider: ProviderEnum.Provider,
			payload: {
				firstName,
				lastName,
				phone,
				email,
				address: {
					street1: address,
					postalCode: zip,
					city,
					state: returnStateCode(state),
					country: 'US',
				},
				dateOfBirth: dob,
				ssn,
			},
		};

		let resp: any;
		if (plId)
			resp = await patch(`${API_URL.CLIENT_IDENTITY}/${plId}`, payloadReq);
		else resp = await post(API_URL.CLIENT_IDENTITY, payloadReq);
		if (resp?.message) {
			const messList = resp?.message?.split("'");
			// eslint-disable-next-line @typescript-eslint/no-unused-expressions
			messList?.length > 0 &&
				messList.map((item: string) => {
					const error = renderErrorMessage(item);
					if (typeof error === 'object') {
						setErrorMessage(prev => ({ ...prev, ...error }));
					} else {
						if (error === 'Personal Identity already exists') {
							setNavigate('businessForm');
							return null;
						}
					}
					return null;
				});
			errorNotification(resp.message);
			return;
		} else {
			const { kycLevel, upgradeRequirements, id } = resp ?? {};
			if (kycLevel === KEYLEVEL.L0) {
				setPlId(id);
				upgradeRequirements?.map((item: string) => {
					const error = renderErrorMessage(item);
					if (typeof error === 'object') {
						setErrorMessage(prev => ({ ...prev, ...error }));
					} else errorNotification(error);
					return null;
				});
			} else {
				resetPersonalId();
				setNavigate('businessForm');
			}
		}
		// eslint-disable-next-line
	}, [
		patch,
		personalInformationValue,
		plId,
		post,
		resetPersonalId,
		setNavigate,
		setPlId,
	]);

	return {
		handleSubmitPersonalForm,
		escrowPersoanlLoading,
	};
};

export const useSubmitBusinessFrom = () => {
	const businessInformationValue = useRecoilValue(BusinessInformationState);
	const setNavigate = useSetRecoilState(InviteNavigateModalState);
	const { post, data, loading, patch } = useNetwork();
	const [plId, setPlId] = useRecoilState(PersonalInformationId);

	const handleSubmitBusinessForm = useCallback(() => {
		const {
			address,
			city,
			// country,
			companyName,
			ein,
			email,
			establishedDate,
			naics,
			naicsType,
			phone,
			region,
			state,
			website,
			zip,
			mailingAddress,
			description,
			mailingAddress2,
			legalStructure,
			address2,
			mailingCity,
			// mailingCountry,
			mailingState,
			mailingZip,
		} = businessInformationValue;
		const payloadReq = {
			type: EscrowType.Business,
			provider: ProviderEnum.Provider,
			payload: {
				companyName,
				ein,
				website,
				phone,
				email,
				address: {
					street1: address,
					street2: address2,
					postalCode: zip,
					city,
					state: returnStateCode(state),
					country: 'US',
				},
				regionOfFormation: region,
				establishedOn: establishedDate,
				mailingAddress: {
					street1: mailingAddress,
					street2: mailingAddress2,
					postalCode: mailingZip,
					city: mailingCity,
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					state: returnStateCode(mailingState!),
					country: 'US',
				},
				naics,
				description,
				naicsDescription: naicsType,
				legalStructure,
			},
		};
		if (!plId) post(API_URL.CLIENT_IDENTITY, payloadReq);
		else patch(`${API_URL.CLIENT_IDENTITY}/${plId}`, payloadReq);
		setNavigate('accountProcessing');
		setPlId(data?.id);
	}, [
		businessInformationValue,
		data?.id,
		patch,
		plId,
		post,
		setNavigate,
		setPlId,
	]);

	return {
		handleSubmitBusinessForm,
		escrowBusinessData: data,
		escrowLoading: loading,
	};
};
