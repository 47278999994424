import { useCallback, useMemo } from 'react';

import { AreaFillColor, AreaLineColor, defaultAreaColor } from './constant';

export const useKYBInsight = (KYBDetails: any) => {
	const kybInsight = useMemo(() => {
		const { financialDetails } = KYBDetails ?? {};
		const { insights } = financialDetails ?? {};
		const { value } = insights ?? {};

		return value;
	}, [KYBDetails]);

	const getKYBAreaSeries = useCallback(
		(kybKey: string, chartKeys: string[]) => {
			const areaDetail = kybInsight?.[kybKey]?.metrics;
			const chartDetail: any = {
				title: '',
				valuePrefix: '',
				valueSuffix: '',
				series: [],
			};

			for (const key in areaDetail) {
				if (Object.hasOwnProperty.call(areaDetail, key)) {
					if (chartKeys.includes(key)) {
						const item = areaDetail[key];
						chartDetail.title = item?.name ?? '';
						chartDetail.valuePrefix = item?.valueFormat?.pre ?? '';
						chartDetail.valueSuffix = item?.valueFormat?.post ?? '';

						const convertedItem = {
							name: item?.name?.trim(),
							data: (item?.data ?? []).map((entry: any) => [
								parseInt(entry.year),
								entry.value,
							]),
							color: AreaLineColor[key] ?? defaultAreaColor.color,
							fillColor: AreaFillColor[key] ?? defaultAreaColor.fill,
						};
						chartDetail.series.push(convertedItem);
					}
				}
			}
			return chartDetail;
		},
		[kybInsight]
	);

	const getKYBLineSeries = useCallback(
		(kybKey: string, chartKeys: string) => {
			const areaDetail = kybInsight?.[kybKey]?.metrics?.[chartKeys];
			const chartSeries: any = {
				title: '',
				valuePrefix: '',
				valueSuffix: '',
				xAxis: [],
				yAxis: [],
			};

			chartSeries.title = areaDetail?.name ?? '';
			chartSeries.valuePrefix = areaDetail?.valueFormat?.pre ?? '';
			chartSeries.valueSuffix = areaDetail?.valueFormat?.post ?? '';
			(areaDetail?.data ?? [])?.forEach((item: any) => {
				chartSeries.xAxis.push(parseInt(item?.year));
				chartSeries.yAxis.push(Number(item?.value));
			});

			return chartSeries;
		},
		[kybInsight]
	);
	return { kybInsight, getKYBAreaSeries, getKYBLineSeries };
};
