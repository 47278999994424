import { FC } from 'react';
import Tippy from '@tippyjs/react';
import { Image } from '@storybook';

import { IInfo } from '../deal-user';
import { IPerson, useDealDetails, useDealHeader } from '../../stores';

interface IUserInfo {
	user: IPerson;
	info: IInfo[];
}
interface IPlaceholderInfo {
	userKey: string;
}

export const UserInfo: FC<IUserInfo> = ({ info, user }) => {
	// hooks
	const { getUserInfo } = useDealHeader();
	const { viewSessionDetails } = useDealDetails();

	return (
		<div key={user._id} className="DealUser__info-wrapper">
			{info.map(({ key, label }) => (
				<div key={key} className="DealUser__info">
					<div className="DealUser__info-key">{label}</div>
					<div className="DealUser__info-separator">:</div>
					<div className="DealUser__info-value">
						{key === 'sessionDetails' ? (
							user?.sessionId && user?.nodes?.length ? (
								<div
									onClick={() => viewSessionDetails(user)}
									className="DealUser__view-session"
								>
									<i className="ri-eye-line" />
									{'View details'}
								</div>
							) : (
								'Not yet started'
							)
						) : (
							<>
								<Tippy
									disabled={key === 'phone'}
									content={getUserInfo(key as string, user)}
								>
									<div className="DealUser__text-value">
										{getUserInfo(key as string, user)}
									</div>
								</Tippy>

								{user.isPrimary && key === 'name' && (
									<div className="DealUser__isPrimary">Primary</div>
								)}
							</>
						)}
					</div>
				</div>
			))}
		</div>
	);
};

export const PlaceholderInfo: FC<IPlaceholderInfo> = ({ userKey = 'data' }) => (
	<div className="DealUser__info-wrapper">
		<div className="DealUser__no-data">
			<Image fileName="missing.svg" />
			<div className="DealUser__no-data-msg">No {userKey} available</div>
		</div>
	</div>
);
