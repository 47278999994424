import { atom } from 'recoil';

export const IsOpenInIframeState = atom<boolean>({
	key: 'is-iframe-state',
	default: window.self !== window.top,
});

export const IframePropsState = atom<any>({
	key: 'iframe-props-state',
	default: {
		background: '',
		theadColor: '',
		isSearch: false,
		clientId: '',
		clientSecret: '',
		btnPrimary: '',
		btnSecondary: '',
		notification: false,
		webAdminType: '',
		sidebar: false,
	},
});

export const webComponentRouteState = atom<string>({
	key: 'web-component-route-state',
	default: '',
});

export const filteringTagsState = atom<any>({
	key: 'fitlering-tags-state',
	default: {},
});

export const pipelinesFiltersState = atom<{ [key: string]: any }>({
	key: 'pipelines-filter-state',
	default: {},
});
