import { CodeSpaceField } from '../code-space';
import { IframeSpace } from '../iframe-space';
import './playground-filed.scss';

export const Playground = () => {
	return (
		<div className="Playground--container">
			<IframeSpace />
			<CodeSpaceField />
		</div>
	);
};
