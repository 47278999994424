import { ChangeEvent, FC } from 'react';
import { useRecoilValue } from 'recoil';
import { Input } from '@storybook';

import { IsInvestorsDetailsDisabled } from '../store';

interface IInvestorsCountInput {
	handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
	value: number;
    placeholder: string;
    type: "text" | "number" | "password" | "date";
	fieldName?: string,
    label?: string,
	handleBlur?: () => void,
	errorMessage?: string,
	isDisabled?: boolean,
	maxLength?:number,
}

export const InvestorsCountInput: FC<IInvestorsCountInput> = ({
	handleChange,
	value,
    placeholder,
    type,
    label,
	handleBlur,
	errorMessage = "",
	isDisabled = false,
	maxLength
}) => {
	const investorDetailsDisabled = useRecoilValue(IsInvestorsDetailsDisabled);

	const inputClassName = isDisabled ? "input--isDisabled": ""

	return (
		<div>
            {label && <label htmlFor="investors-count">{label}</label>}
			<Input
				label=""
				inputType={type}
				placeholder={placeholder}
				handleBlur={handleBlur}
				handleChange={handleChange}
				disabled={investorDetailsDisabled || isDisabled}
				value={value}
				isError={!!errorMessage}
				errorMessage={errorMessage}
				className={inputClassName}
				maxLength={maxLength}
			/>
		</div>
	);
};
