/* eslint-disable react/no-array-index-key */
import { FC, useCallback, useState } from 'react';
import { Image } from '@storybook';
import { ComprehensiveHeader } from '../comprehensive-header';
import { SectionCount } from '../../../ofac/components';
import { BankruptcyCard } from './bankruptcy-card';
import { BankruptcyData } from 'views/sessions/components/kyb-wrapper/hooks';

interface IProps {
	label: string;
	bankruptcyData: BankruptcyData;
}

export const BankruptcyInformationSection: FC<IProps> = ({
	label,
	bankruptcyData,
}) => {
	const [isShowDetails, setIsShowDetails] = useState(false);

	const handleViewAll = useCallback(() => {
		setIsShowDetails(prev => !prev);
	}, []);

	return (
		<div className="backruptcy__section-wrapper">
			<ComprehensiveHeader label={label ?? '--'} />

			{bankruptcyData?.debtors?.value?.length > 4 && (
				<div
					onClick={handleViewAll}
					className="kyb-comprohensive__backruptcy-view-more"
				>
					{isShowDetails ? 'View less' : 'View more'}
				</div>
			)}

			{bankruptcyData?.totalDerogSummaryCntBankruptcy?.value == 0 ? (
				<div className="kyb-array-empty-wrapper">
					<div className="kyb-empty_data">
						<div className="kyb-empty_data__inner">
							<Image
								fileName="No_record_found.svg"
								className="empty_data_img"
								height="100"
							/>
							<div className="kyb-empty-data-txt">No records found</div>
						</div>
					</div>
				</div>
			) : (
				<>
					<SectionCount
						count={bankruptcyData?.derogSummaryCntBankruptcy?.value}
						label={bankruptcyData?.derogSummaryCntBankruptcy?.label}
					/>
					{(bankruptcyData?.debtors?.value ?? []).map((debtorItem, index) => {
						if (!isShowDetails && index >= 4) {
							return null;
						}
						return <BankruptcyCard backruptcyItem={debtorItem} key={index} />;
					})}
				</>
			)}
		</div>
	);
};
