import { envHost, localHost } from 'helpers';

export const useMarketing = () => {
	const trackAttribute = (
		attributes: Record<string, string | number | Date>
	) => {
		if ((envHost === 'stage' && !localHost) || envHost === 'prod') {
			try {
				(window as any)?.Intercom('update', {
					...attributes,
				});
				// eslint-disable-next-line no-empty
			} catch (error) {}
		}
	};

	return {
		trackAttribute,
	};
};
