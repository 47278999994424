import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Loader, ReactSwitch } from '@storybook';
import { useNetwork, useNotification } from 'hooks';
import { API_URL } from 'constant';
import { loginState } from 'global-stores';
import { WebhookEncrptionKeyState } from 'views';

import { ENCRPTION_KEY_MESSAGES } from '../constant';
import { ConfirmationModal } from 'components';

import { PERMISSION_SCREEN, useUserRoles } from 'views/routes-children';

export const EncrptionKeySection = () => {
	// Hooks
	const { patch, get, loading } = useNetwork();
	const { errorNotification, successNotification } = useNotification();

	// Global state
	const login = useRecoilValue(loginState);
	const { business } = login ?? {};
	const [encryptKeyData, setEncryptKeyData] = useRecoilState(
		WebhookEncrptionKeyState
	);
	const { checkUserWritePermission } = useUserRoles();

	// Local states
	const [encryptDataConfirmationModal, setEncryptDataIsConfirmationModal] =
		useState(false);
	const [genrateKeyLoader, setGenratekeyLoader] = useState(false);

	useEffect(() => {
		const fetchEncryptKeyandData = async () => {
			try {
				const resp = await get(`${API_URL.EncryptKey}/${business}`);
				if (resp?.data) {
					setEncryptKeyData(resp.data);
				}
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error('Error fetching data:', error);
			}
		};

		fetchEncryptKeyandData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [business]);

	const handleEncrptionKey = useCallback(
		async (toggleValue: any) => {
			try {
				const payload = {
					encryptWebhookPayload: toggleValue,
				};
				const resp = await patch(`${API_URL.EncryptKey}/${business}`, payload);
				if (resp) {
					setEncryptKeyData({
						webhookEncryptionKey: resp.webhookEncryptionKey,
						encryptWebhookPayload: resp.encryptWebhookPayload,
					});
					if (!toggleValue) {
						successNotification(ENCRPTION_KEY_MESSAGES.DISABLED_SUCCESS);
					} else successNotification(ENCRPTION_KEY_MESSAGES.ENABLED);
				}
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error('Error updating encryption key:', error);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[business]
	);

	const handleEncriptToggle = useCallback(
		async (togleValue?: any) => {
			handleEncrptionKey(togleValue);
		},
		[handleEncrptionKey]
	);
	const genrateKey = useCallback(async () => {
		if (!encryptKeyData.encryptWebhookPayload) {
			errorNotification(ENCRPTION_KEY_MESSAGES.GENRATE_LIMIT);
			return;
		}
		setGenratekeyLoader(true);
		try {
			const payload = {
				encryptWebhookPayload: encryptKeyData.encryptWebhookPayload,
				// webhookEncryptionKey: encryptKeyData.webhookEncryptionKey
			};
			const resp = await patch(
				`${API_URL.EncryptKey}/${business}?generate=${encryptKeyData.encryptWebhookPayload}`,
				payload
			);
			if (resp) {
				setGenratekeyLoader(false);
				successNotification(ENCRPTION_KEY_MESSAGES.NEW_KEY_SUCCESS);
				setEncryptKeyData({
					webhookEncryptionKey: resp.webhookEncryptionKey,
					encryptWebhookPayload: resp.encryptWebhookPayload,
				});
			}
		} catch (error) {
			setGenratekeyLoader(false);
			// eslint-disable-next-line no-console
			console.error('Error updating encryption key:', error);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		business,
		encryptKeyData.encryptWebhookPayload,
		encryptKeyData.webhookEncryptionKey,
	]);

	const copyToClipboard = useCallback((text: string) => {
		navigator.clipboard
			.writeText(text)
			.then(() => {
				successNotification('key copied to clipboard');
			})
			.catch(() => {
				errorNotification('Failed to copy code');
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleGenrateNewKey = useCallback(() => {
		if (!encryptKeyData.encryptWebhookPayload) {
			errorNotification(ENCRPTION_KEY_MESSAGES.GENRATE_LIMIT);
			return;
		}
		setEncryptDataIsConfirmationModal(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [encryptKeyData.encryptWebhookPayload]);

	const handleEncrptionKeyConfirmationModel = useCallback(
		(isOpen: boolean, value: boolean) => {
			setEncryptDataIsConfirmationModal(isOpen);
			if (value) {
				genrateKey();
			}
		},
		[genrateKey]
	);

	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.GeneralSettings),
		[checkUserWritePermission]
	);

	return (
		<>
			<div className="whitelabel-settings remove-border">
				<div>
					<div className="whitelabel-settings__label-wrapper">
						<div className="whitelabel-settings__label-icon">
							<i className="ri-shield-keyhole-fill whitelabel-settings__icon" />
						</div>
						<div className="whitelabel-settings__toggle-btn">
							<div className="whitelabel-settings__label-text">
								Encryption Key
							</div>
							<div className="whitelabel-settings__action encription-key-togle ">
								{loading && (
									<Loader
										type="loader"
										dimension={20}
										className="loader-blue"
									/>
								)}
								<ReactSwitch
						    		isDisabled={!isUserPermissionWrite || loading}
									handleChange={handleEncriptToggle}
									checked={encryptKeyData?.encryptWebhookPayload}
									id="whitelable-toggle"
									onColor="#33b87a"
								/>
							</div>
						</div>
					</div>
					<div className="whitelabel-settings__desp">
						Enable this option to encrypt all data using a secure key. This key
						will be required to decrypt the data, ensuring enhanced security for
						sensitive information.
					</div>
				</div>
			</div>
			{encryptKeyData.webhookEncryptionKey && (
				<div className="whitelabel-settings__encrption-key-wrapper">
					<div className="whitelabel-settings__encrption-key-wrapper__key hover">
						<div className="whitelabel-settings__encrption-key-wrapper__key-box">
							{encryptKeyData.webhookEncryptionKey}
						</div>

						<div
							onClick={() =>
								copyToClipboard(encryptKeyData?.webhookEncryptionKey)
							}
							className="whitelabel-settings__encrption-key-wrapper__copy-btn"
						>
							<i className="ri-file-copy-line"></i>
						</div>
					</div>
					{isUserPermissionWrite && (
						<div
							className={`whitelabel-settings__encrption-key-wrapper__genrate-key`}
							onClick={() => handleGenrateNewKey()}
						>
							{!genrateKeyLoader ? (
								<>
									<i className="ri-restart-fill whitelabel-settings__encrption-key-wrapper__genrate-icon"></i>{' '}
									Generate key
								</>
							) : (
								<div className="whitelabel-settings__encrption-key-wrapper__genrate-loader-wrapper">
									<Loader type="loader" dimension={20} />
								</div>
							)}
						</div>
					)}
				</div>
			)}

			<ConfirmationModal
				visible={encryptDataConfirmationModal}
				title={ENCRPTION_KEY_MESSAGES.MODAL_TITLE}
				description={ENCRPTION_KEY_MESSAGES.MODAL_DESP}
				boldDescription={ENCRPTION_KEY_MESSAGES.MODAL_BOLD_DESP}
				handleModal={handleEncrptionKeyConfirmationModel}
				label={ENCRPTION_KEY_MESSAGES.BUTTON_LABEL}
				subBody={
					<div className="whitelabel-settings__confirm-key-wrapper">
						<div className="whitelabel-settings__confirmation-left-key">
							{ENCRPTION_KEY_MESSAGES.LEFT_KEY}
						</div>
						<div className="whitelabel-settings__confirmation-key-box">
							{encryptKeyData.webhookEncryptionKey}
						</div>
						<div
							onClick={() =>
								copyToClipboard(encryptKeyData?.webhookEncryptionKey)
							}
						>
							<div className="whitelabel-settings__confirmation-copy-btn">
								<i className="ri-file-copy-fill"></i>
								Copy
							</div>
						</div>
					</div>
				}
			/>
		</>
	);
};
