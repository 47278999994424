import { FC } from 'react';
import { ICustomNode } from '../custom-node';
import { CustomHandle } from '../custom-handle';
import { RenderSignBlock } from '../sign-agreement-action/render-sign-block';
import { labelData } from 'views/complex-onboarding-flow/constant';

export const SuccessScreen: FC<ICustomNode> = ({ id: nodeId, data }) => {
	return (
		<div>
			<RenderSignBlock label={labelData[data?.label]} price={0} />
			<CustomHandle value={nodeId} showRight={false} />
		</div>
	);
};
