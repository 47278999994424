import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { Button, Loader } from '@storybook';

import { ConfigureInputmetaData } from 'views/auth/constants';

import { SelectedInstanceDetails } from 'views/auth/hooks.ts';
import { WaringMessage } from 'views/pipeline';
import { scrollToIndex } from 'utils/scroll';
import { useInstance } from 'views/auth/hooks.ts/hooks';
import { useNetwork, useNotification } from 'hooks';
import { ClientsCredentialsState } from 'global-stores';
import { API_URL } from 'constant';
import { AuthConfigMetaSetting } from '../settings';

import './auth-configure.scss';

interface IAuthConfigure {
	onClose: (e: boolean, isAdded?: boolean, response?: any) => void;
	complexLists: any;
	isLoading?: boolean;
}

export const AuthConfigure: FC<IAuthConfigure> = ({
	onClose,
	complexLists,
	isLoading = false,
}) => {
	const [formFields, setFormFields] = useState<any>(ConfigureInputmetaData);
	const [apiCalling, setApiCalling] = useState(false);
	const [oldFormDetails, setOldFormFields] = useState('');
	const [newlyAddedClientId, setNewlyAddedClientId] = useState('');

	const [instanceDetails, setInstanceDetails] = useRecoilState(
		SelectedInstanceDetails
	);
	const resetInstanceDetails = useResetRecoilState(SelectedInstanceDetails);

	const params = new URLSearchParams(window.location.search);
	const id = params.get('id');
	const [loading, setLoading] = useState(true);
	const {
		getInstanceDetails,
		updateInstance,
		createInstance,
		getPayload,
		initialInstanceSetup,
		providers,
	} = useInstance();
	const { errorNotification, successNotification } = useNotification();

	const fetchDetails = async (instanceId: string) => {
		setLoading(true);

		const resp = await getInstanceDetails(instanceId);
		setInstanceDetails(resp.data);

		setLoading(false);
	};

	const [client, setClient] = useRecoilState(ClientsCredentialsState);

	const { post, loading: credentialCreateLoading } = useNetwork();

	const handleAddClientId = async () => {
		const data = await post(`${API_URL.CREDENTIAL}`, {});
		if (data._id) {
			setNewlyAddedClientId(data._id);
			setClient(prev => [data, ...prev]);
		}
	};

	useEffect(() => {
		setFormFields((prev: any) => {
			const clonedFormFields = JSON.parse(JSON.stringify(prev));
			const providersIndex = clonedFormFields.findIndex(
				(el: any) => el.key === 'providers'
			);

			if (providersIndex !== -1) {
				clonedFormFields[providersIndex].metaData = clonedFormFields[
					providersIndex
				].metaData?.map((el: any) => {
					const defaultval =
						providers.find((elm: any) => elm.key === el.name)?._id ?? '';

					return {
						...el,
						defaultValue: defaultval,
					};
				});
			}
			return clonedFormFields;
		});
	}, [providers]);

	useEffect(() => {
		if (id) {
			fetchDetails(id);
		} else {
			setLoading(false);
		}
		return () => {
			resetInstanceDetails();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	useEffect(() => {
		if (Object.keys(instanceDetails).length > 0 && id) {
			const data = initialInstanceSetup(
				formFields,
				instanceDetails,
				complexLists
			);
			setOldFormFields(data);
			setFormFields(data);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [instanceDetails]);

	const handleOnChange = async (
		value: any,
		parentIndex: number,
		fieldIndex: number,
		name?: string,
		updateKeyName = 'value',
		regex?: string,
		type?: string
	) => {
		setFormFields((prev: any) => {
			const clonedFormFields = JSON.parse(JSON.stringify(prev));
			const val = type === 'number' ? Number(value) : value;
			if (clonedFormFields.length)
				clonedFormFields[parentIndex].metaData[fieldIndex][updateKeyName] = val;
			if (regex) {
				const pattern = new RegExp(regex, 'i');
				if (
					pattern.test(val) &&
					clonedFormFields[parentIndex].metaData[fieldIndex]['isError']
				) {
					delete clonedFormFields[parentIndex].metaData[fieldIndex]['isError'];
				}
			}

			if (name === 'authId') {
				setNewlyAddedClientId('');
			}

			if (name === 'logo') {
				//set default as empty in case if user has url and if user has uploaded something then need to remove default one other wise it will show url in logo input after having in upload Component

				if (updateKeyName !== 'default') {
					clonedFormFields[parentIndex].metaData[fieldIndex]['default'] = '';
				}

				delete clonedFormFields[parentIndex].metaData[fieldIndex]['isError'];
			}

			if (name === 'required') {
				clonedFormFields[parentIndex].metaData = clonedFormFields[
					parentIndex
				].metaData.map((el: any, index: number) => {
					const payload = {
						...el,
						...(index !== 0 && /email|phone/.test(el.name)
							? { isHidden: !val }
							: { isHidden: false }),
					};
					return payload;
				});
			}

			if (name === 'onboaringFlowRequired') {
				if (!val) {
					const methodIndex = clonedFormFields.findIndex(
						(el: any) => el.key === 'methods'
					);

					clonedFormFields[methodIndex].metaData = clonedFormFields[
						methodIndex
					].metaData.map((el: any) => {
						if (el.name === 'warning') {
							return {
								...el,
								isHidden: false,
							};
						} else if (el.payloadKey === 'biometric') {
							return {
								...el,
								...(el.name === 'simpliciBiometric' && { value: false }),
								isHidden: true,
							};
						} else {
							return {
								...el,
								isHidden: false,
							};
						}
					});
				}

				clonedFormFields[parentIndex].metaData = clonedFormFields[
					parentIndex
				].metaData.map((el: any) => {
					const payload = {
						...el,
						...(el.name === 'onboardingflow'
							? { disabled: !val, ...(!val && { value: null, default: null }) }
							: { disabled: false }),
					};
					return payload;
				});
			}

			if (name === 'onboardingflow') {
				const methodIndex = clonedFormFields.findIndex(
					(el: any) => el.key === 'methods'
				);

				clonedFormFields[methodIndex].metaData = clonedFormFields[
					methodIndex
				].metaData.map((el: any) => {
					if (el.name === 'warning') {
						return {
							...el,
							isHidden: val,
						};
					} else if (el.name === 'simpliciBiometric') {
						return {
							...el,
							isHidden: !val,
						};
					}
					return el;
				});
			}

			if (name === 'simpliciBiometric') {
				const methodIndex = clonedFormFields.findIndex(
					(el: any) => el.key === 'methods'
				);

				clonedFormFields[methodIndex].metaData = clonedFormFields[
					methodIndex
				].metaData.map((el: any) => {
					if (
						el.payloadKey === 'biometric' &&
						el.name !== 'simpliciBiometric'
					) {
						return {
							...el,
							isHidden: !val,
						};
					}
					return el;
				});
			}
			return clonedFormFields;
		});
	};

	const doesUrlExist = (urlList: string[] = [], incomingUrl: string) => {
		try {
			// Create a URL object from the incoming URL
			const incomingOrigin = new URL(incomingUrl).origin;

			// Iterate over the list of URLs and check if any match the incoming URL's origin
			const ixExist = urlList.some(url => {
				const listUrlOrigin = new URL(url).origin;
				return listUrlOrigin === incomingOrigin;
			});

			return {
				incomingUrl: incomingOrigin,
				ixExist,
			};
		} catch (error) {
			return {
				ixExist: false,
				incomingUrl: incomingUrl,
			};
		}
	};

	const handleOnChangeArray = (
		parentIndex: number,
		fieldIndex: number,
		regex: RegExp
	) => {
		const pattern = new RegExp(regex, 'i'); // fragment locator
		setFormFields((prev: any) => {
			const clonedFormFields = JSON.parse(JSON.stringify(prev));
			const { value, currentValue } =
				clonedFormFields[parentIndex].metaData[fieldIndex] ?? {};
			if (clonedFormFields.length) {
				if (!value) {
					clonedFormFields[parentIndex].metaData[fieldIndex].value = [];
				}
				const { ixExist, incomingUrl } = doesUrlExist(value, currentValue);
				if (ixExist) {
					clonedFormFields[parentIndex].metaData[fieldIndex]['isError'] = true;
					clonedFormFields[parentIndex].metaData[fieldIndex]['isDuplicate'] =
						true;
				} else {
					clonedFormFields[parentIndex].metaData[fieldIndex]['isDuplicate'] =
						false;
					if (pattern.test(incomingUrl)) {
						clonedFormFields[parentIndex].metaData[fieldIndex]['isError'] =
							false;
						clonedFormFields[parentIndex].metaData[fieldIndex].value.push(
							incomingUrl
						);
						clonedFormFields[parentIndex].metaData[fieldIndex].currentValue =
							'';
					} else {
						clonedFormFields[parentIndex].metaData[fieldIndex]['isError'] =
							true;
					}
				}
			}
			return clonedFormFields;
		});
	};

	const handleRemoveArrayValue = (
		parentIndex: number,
		fieldIndex: number,
		valueIndex: number
	) => {
		setFormFields((prev: any) => {
			const clonedFormFields = JSON.parse(JSON.stringify(prev));
			if (clonedFormFields.length) {
				clonedFormFields[parentIndex].metaData[fieldIndex].value.splice(
					valueIndex,
					1
				);
			}

			return clonedFormFields;
		});
	};

	const handleOnChangeGesture = useCallback(
		(
			value: boolean | string | number,
			parentIndex: number,
			fieldIndex: number,
			index: number,
			parentName: string,
			name: string,
			type?: string
		) => {
			setFormFields((prev: any) => {
				const clonedFormFields = JSON.parse(JSON.stringify(prev));
				if (clonedFormFields.length) {
					if (type === 'all') {
						clonedFormFields[parentIndex].metaData[fieldIndex][parentName] =
							clonedFormFields[parentIndex].metaData[fieldIndex][
								parentName
							].map((el: any) => {
								const payload = {
									...el,
									value: el.value ?? 0,
									checked: value,
								};
								return payload;
							});
					} else {
						clonedFormFields[parentIndex].metaData[fieldIndex][parentName][
							index
						][name] = value;
					}
				}

				return clonedFormFields;
			});
		},
		[]
	);

	const getDropDownOptions = (name: string) => {
		switch (name) {
			case 'authId':
				return {
					options: client.map((el: any) => {
						return {
							label: (
								<div
									className="clientId-dropdown"
									onMouseEnter={() => setNewlyAddedClientId('')}
								>
									<div className="clientId-dropdown__label">{el.clientId}</div>
									<div className="clientId-dropdown__sublabel">
										{el.clientSecret}
									</div>
								</div>
							),
							value: el._id,
						};
					}),
					footer: (
						<div
							className="clientId-dropdown__footer"
							style={{
								color: 'hsla(216, 100%, 40%, 1)',
								fontSize: 14,
								fontWeight: 500,
								display: 'flex',
								gap: 8,
								alignItems: 'center',
								padding: '8px 16px 8px 16px',
								cursor: 'pointer',
								width: '100%',
							}}
							onClick={handleAddClientId}
						>
							<i className="ri-add-line" style={{ fontWeight: 600 }}></i>
							<span>Add New Client ID & Secret</span>
							{credentialCreateLoading && (
								<Loader dimension={10} type="loader" />
							)}
						</div>
					),
				};
			case 'onboardingflow':
				return {
					options: (complexLists ?? []).map((el: any) => {
						return {
							value: el._id,
							label: el.name,
						};
					}),
				};

			default:
				return {
					options: [{ label: '', value: '' }],
				};
		}
	};

	const card = (el: any, parentIndex: number) => {
		const { label, description, metaData, isHidden, note } = el ?? {};
		if (isHidden) {
			return <></>;
		}
		return (
			<div className="auth-configure-wrapper__inner__card">
				<div className="auth-configure-wrapper__inner__card__left">
					<div className="label">{label}</div>
					<div className="description"> {description}</div>
					{note && (
						<WaringMessage
							text={<div dangerouslySetInnerHTML={{ __html: note }}></div>}
						/>
					)}
				</div>
				<div className="auth-configure-wrapper__inner__card__right">
					{(metaData ?? []).map?.((elm: any, fieldsIndex: number) => {
						if (elm.isHidden) {
							return <></>;
						}
						const payload = {
							el: elm,
							parentIndex,
							fieldIndex: fieldsIndex,
							handleOnChange,
							formFields,
							handleOnChangeArray,
							handleRemoveArrayValue,
							getDropDownOptions,
							handleOnChangeGesture,
							newlyAddedClientId,
						};
						return (
							<Fragment key={elm.name + fieldsIndex.toString()}>
								<AuthConfigMetaSetting {...payload} />
							</Fragment>
						);
					})}
				</div>
			</div>
		);
	};

	const handleSubmit = async () => {
		setApiCalling(true);
		const { hasError, fields, firstErrorId, payload } = await getPayload(
			formFields
		);

		if (hasError) {
			setFormFields(fields);
			scrollToIndex(firstErrorId);
			setApiCalling(false);
			return;
		}

		if (id) {
			const response = await updateInstance(id, payload);
			if (response.status !== 200) {
				errorNotification(response.message ?? 'Something went wrong');
				setApiCalling(false);
				return;
			} else {
				successNotification('Instance Updated Successfully.');
				setApiCalling(false);
				onClose(false, false, response.data);
			}
		} else {
			const response = await createInstance(payload);
			if (response.status !== 200) {
				errorNotification(response.message ?? 'Something went wrong');
				setApiCalling(false);
				return;
			} else {
				successNotification('Instance Created Successfully.');
				setApiCalling(false);
				onClose(false, true, response.data);
			}
		}
	};

	if (loading || isLoading) {
		return (
			<div className="instance-details-loader-center ">
				<Loader />
			</div>
		);
	}

	return (
		<Fragment>
			<div className="auth-configure-wrapper">
				<div className="auth-configure-wrapper__inner">
					<div className="auth-configure-wrapper__inner__form">
						{formFields.map((el: any, parentIndex: number) => (
							<Fragment key={el.key}>{card(el, parentIndex)}</Fragment>
						))}
					</div>
				</div>
			</div>

			<div className="auth-configure-wrapper__btn-wrapper">
				<Button
					label="Close"
					type={'button button__filled--danger view-kyc-modal--danger-button '}
					handleClick={() => onClose(false)}
					disabled={apiCalling}
				/>
				<Button
					label={
						apiCalling ? <Loader type="loader" dimension={20} /> : 'Submit'
					}
					handleClick={handleSubmit}
					type={
						'button button__filled button__filled--primary view-kyc-modal--proceed-btn '
					}
					disabled={
						apiCalling ||
						JSON.stringify(oldFormDetails) === JSON.stringify(formFields)
					}
				/>
			</div>
		</Fragment>
	);
};
