import { FC } from 'react';
import { Rejection } from 'views/sessions/store';
import './rejection-details.scss';

interface IRejectionDetails {
	data: Rejection[];
}

export const RejectionDetails: FC<IRejectionDetails> = ({ data }) => {
	return (
		<>
			{data?.length > 0
				? data.map((el: Rejection) => {
						if (!el) return <></>;
						else if (typeof el === 'string') {
							return (
								<div className="Kyc-rejection" key={el}>
									<li className="Kyc-rejection--dot"></li>
									<li className="Kyc-rejection--pointer">{el}</li>
								</div>
							);
						} else {
							return (
								<div className="Kyc-rejection" key={el?.message}>
									<li className="Kyc-rejection--dot"></li>
									<li className="Kyc-rejection--pointer">{el?.message}</li>
								</div>
							);
						}
				  })
				: 'NA'}
		</>
	);
};
