import { ChangeEvent, FC, useMemo } from 'react';
import './input.scss';

interface ITextInput {
	onChangeTextInput: (event: ChangeEvent<HTMLInputElement>) => void;
	type: string;
	value: string;
	placeholder?: string;
	optionalClass?: string;
}
export const InputText: FC<ITextInput> = ({
	onChangeTextInput,
	type,
	value,
	placeholder = 'Input field',
	optionalClass,
}) => {
	const inputPlaceholder = useMemo(
		() => (type === 'date' ? 'dd/mm/yyyy' : placeholder),
		[placeholder, type]
	);

	return (
		<div className="text-input-main">
			<input
				onChange={onChangeTextInput}
				type={type}
				value={value}
				placeholder={inputPlaceholder}
				className={optionalClass}
			/>
		</div>
	);
};
