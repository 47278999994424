import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Button, Loader } from '@storybook';

import { formatBase64 } from 'utils';
import { useNotification } from 'hooks';
import { MESSAGE } from 'constant';
import {
	activeTabState,
	CreateSignState,
	ICreateSignPayload,
	IsFontsLoadedState,
	SignatureErrorState,
	useResetCreateSign,
	useSetCreateSign,
} from '../store';

export const CreateSignFooter = () => {
	const isFontsLoaded = useRecoilValue(IsFontsLoadedState);
	const signData = useRecoilValue(CreateSignState);
	const activeTab = useRecoilValue(activeTabState);
	const { submitSign, saveInProgress } = useSetCreateSign();
	const { reset } = useResetCreateSign();
	const signature = signData[activeTab];
	const { errorNotification } = useNotification();
	const setSignatureError = useSetRecoilState(SignatureErrorState)

	const getSize = useCallback((base64String: string) => {
		const stringLength = base64String.length - 'data:image/png;base64,'.length;
		const sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
		const sizeInKb = sizeInBytes / 1000;
		return sizeInKb;
	}, []);

	useEffect(() => {
		if (activeTab) {
            setSignatureError({
                initial: {
                    erroMessage: '',
                    value: false
                }, signature: {
                    erroMessage: '',
                    value: false
                }
            })
        }
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[activeTab, signature])

	const handleCreateSign = useCallback(async () => {
		try {
			const payload: Partial<ICreateSignPayload> = {};
			if (signature.sign) {
				payload.signature = {
					base64File: formatBase64(signature.sign),
					name: `-sign`,
					size: getSize(signature.sign),
					type: activeTab,
				};
			}
			if (signature.initials) {
				payload.initial = {
					base64File: formatBase64(signature.initials),
					name: `-initial`,
					size: getSize(signature.initials),
					type: activeTab,
				};
			}
			if (!payload.initial || !payload.signature) {
				let errorMessage = "";
				if (!payload.initial && !payload.signature) {
					errorMessage = "Signature & Initial is required"					
				}
				else if (!payload.signature) {
					errorMessage = "Signature is required."
				}
				else {
					errorMessage = "Initial is required."
				}
				errorNotification(errorMessage)
				setSignatureError({
                    initial: {
                        erroMessage: !payload.initial ? errorMessage : '',
                        value: !payload.initial
                    }, signature: {
                        erroMessage: !payload.signature ? errorMessage : ' ',
                        value: !payload.signature
                    }
                })
				return
			}
			const status = await submitSign(payload);
			if (status) {
				reset();
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error);
			errorNotification(MESSAGE.ERROR_TRY);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [signature.sign, signature.initials, submitSign, getSize, activeTab, reset]);

	const isDisabled = useMemo((): boolean => {
		if (!isFontsLoaded && activeTab === "generate") {
			return true;
		}
		const { consent, fullName, initials } = signData;
		if (consent && fullName.trim() && initials.trim() && signature.sign && signature.initials) {
			return false;
		}
		return true;
	}, [activeTab, isFontsLoaded, signData,signature.sign, signature.initials]);

	const handleSignatureLabel = useMemo(() => {
		return saveInProgress ? (
			<Loader className="loader-blue" dimension={20} type="loader" /> 
		) : (
			'Create Signature'
		);
	}, [saveInProgress]);

	return (
		<div className="admin-create-sign-footer">
			<div className="admin-create-sign-footer__btn-wrapper">
				<Button
					type="button__filled button__filled--primary button__large"
					label={handleSignatureLabel}
					handleClick={handleCreateSign}
					disabled={isDisabled || saveInProgress}
					width='150px'
				/>
			</div>
		</div>
	);
};
