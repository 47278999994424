import { Button, Input, Loader } from '@storybook';
import Modal from '@storybook/new-modal/modal';
import { FormBuilder } from 'components';
import { FC, FormEvent, useCallback } from 'react';
import { useComplxSurvery } from './hooks';
import './config-modal.scss';

interface IProps {
	setIsFormOpen: (is: boolean) => void;
	isOpen: boolean;
	nodeId: string;
	stepId: string;
	addNewValue?: () => void;
	setNodeId?: (el: string) => void;
}

export const ComplexConfigureFormModal: FC<IProps> = ({
	setIsFormOpen,
	isOpen,
	nodeId,
	addNewValue = () => ({}),
	setNodeId,
}) => {
	const {
		handleChange,
		onSaveTemplate,
		saveTemplate,
		isTemplateModal,
		close,
		handleCloseModal,
		loading,
		creator,
		onCloseSaveTemplate,
		templateName,
	} = useComplxSurvery({ addNewValue, setIsFormOpen, setNodeId });

	const handleSubmitForm = useCallback(
		(e: FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			onSaveTemplate();
		},
		[onSaveTemplate]
	);

	return (
		<>
			<Modal
				isOpen={isOpen}
				closeModal={handleCloseModal}
				showCloseBtn={false}
				closeOnEscBtn
				modalName="Configure"
				isStopOutsideClick
				className="Onboarding-config-modal survey-padding survay_form"
			>
				<>
					<FormBuilder creator={creator} nodeId={nodeId} />
					<div className="ConfigureModal--footer">
						<Button
							label="Save as a Template"
							handleClick={saveTemplate}
							type="button__filled button__filled--secondary"
						/>
						<Button
							label="Save & Close"
							handleClick={close}
							type="button__filled save-close-button button__filled--primary"
						/>
					</div>
				</>
			</Modal>
			<Modal
				isOpen={isTemplateModal}
				closeModal={onCloseSaveTemplate}
				showCloseBtn
				isStopOutsideClick
				closeOnEscBtn
				modalName="TemplateName"
				className="TemplateName--Modal"
				title="Add Template Name"
			>
				<form onSubmit={handleSubmitForm}>
					<div className="TemplateName--container">
						<Input
							label="Template Name"
							inputType="text"
							placeholder="Template Name"
							handleChange={handleChange}
							value={templateName}
							isRequired
						/>
					</div>
				</form>
				<Button
					label="Save & Close"
					handleClick={onSaveTemplate}
					disabled={!templateName || loading}
					loader={loading ? <Loader type="loader" dimension={20} /> : null}
					type="button__filled button__filled--primary TemplateName--button "
				/>
			</Modal>
		</>
	);
};
