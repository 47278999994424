import { CSSProperties, useEffect, useRef, useState } from 'react';

import './accordion.scss';

interface Props {
	title: JSX.Element;
	content: JSX.Element;
	customStyle: CSSProperties;
	isCollapse?: boolean;
}

/**
 * Accordion component
 *
 * @param {JSX.Element} Props.title - Title of the accordion section
 * @param {JSX.Element} Props.content - Content to be displayed when the accordion is expanded
 * @param {CSSProperties} Props.customStyle - Custom styles to be applied to the accordion section
 * @param {boolean} Props.isCollapse - Flag to determine if the accordion can collapse or not
 */

const Accordion = ({ title, content, customStyle, isCollapse }: Props) => {
	const [active, setActive] = useState(false);
	const contentBody = useRef<any>(null);
	const [height, setHeight] = useState('0px');

	function toggleAccordion() {
		if (!isCollapse) return;
		setActive(!active);
		setHeight(active ? '0px' : `${contentBody.current.scrollHeight}px`);
	}

	useEffect(() => {
		setHeight(!active ? '0px' : `${contentBody.current.scrollHeight}px`);
	}, [active, content]);

	return (
		<div className="accordion__section" style={customStyle}>
			<div
				className={`accordion ${active ? 'active' : ''}`}
				onClick={toggleAccordion}
			>
				{title}
				{isCollapse && (
					<span
						className={`accordion-span ${
							active ? 'ri-arrow-up-s-line' : 'ri-arrow-down-s-line'
						}`}
					/>
				)}
			</div>
			<div
				ref={contentBody}
				style={{ maxHeight: `${height}` }}
				className="accordion__content"
			>
				{content}
			</div>
		</div>
	);
};

export default Accordion;
