import type { INoDataAvailable as Props } from './type';

import { Button, Image } from '@storybook';

import './no-data.scss';

/**
 * NoDataAvailable component
 *
 * @param {string} Props.illustration - Filename of the illustration image
 * @param {string} Props.description - Additional description text
 * @param {string} Props.message - Main message to display
 * @param {string} Props.label - Label for the action button
 * @param {() => void} Props.handleClickBtn - Callback function for the button click
 * @param {boolean} Props.disabled - Whether the button is disabled or not (default: false)
 * @param {boolean} Props.hideBtn - Whether to hide the action button or not (default: false)
 * @param {string} Props.className - Additional class names for the component
 */

export const NoDataAvailable = ({
	illustration,
	description,
	message,
	label,
	handleClickBtn,
	disabled = false,
	hideBtn = false,
	className = '',
}: Props) => {
	return (
		<div className={`no-data ${className}`}>
			<Image fileName={illustration} className="no-data__image-box" />
			<div className="no-data__content-wrapper">
				<div className="no-data__message">{message}</div>
				{description && (
					<div className="no-data__description">{description}</div>
				)}
			</div>
			{!hideBtn && (
				<Button
					type="button__filled button__filled--primary"
					handleClick={handleClickBtn}
					label={label ?? ''}
					disabled={disabled}
				/>
			)}
		</div>
	);
};
