import { FC, useMemo } from 'react';
import './aml-watcher-details.scss';
import { AML_APPEARS_CATEGORIES, AML_CONSTANTS_DETAILS } from '../../constants';
import { Image } from '@storybook';
import { IAMLWatcherDetalsProps, IPayloadClientInfo } from 'global-stores/sessions/type';
import { getFormattedMonthDayYear } from 'helpers';

export const AMLWatcherDetals: FC<IAMLWatcherDetalsProps> = ({
	aml,
	payload,
}) => {
	const {
		name = '',
		entityTypes = [],
		countries = [],
		categories = [],
		riskScore = '',
	} = useMemo(() => aml ?? [], [aml]);

	const { name: PersonName = '', type, doi, dob } =useMemo(() =>  payload ?? {} as IPayloadClientInfo , [payload]);
	return (
		<div className="AMLWatcherDetails">
			<div className="AMLWatcherDetails--userDetails">
				<div className="AMLWatcherDetails--name">
					{(name || PersonName) ?? 'NA'}
				</div>
				<div className="AMLWatcherDetails--matchData">
					<div className="AMLWatcherDetails--type">
						<span className="AMLWatcherDetails--type__label">
							{AML_CONSTANTS_DETAILS.TYPE_LABEL}
						</span>
						<span>:</span>
						<span className="AMLWatcherDetails--type__data">
							{entityTypes?.length || type ? entityTypes?.[0] || type : 'NA'}
						</span>
					</div>
					<div className="AMLWatcherDetails--circle"></div>
					<div className="AMLWatcherDetails--matchScore">
						<span className="AMLWatcherDetails--matchScore__label">
							{AML_CONSTANTS_DETAILS.MATCH_SCORE_LABEL}
						</span>
						<span>:</span>
						<span className="AMLWatcherDetails--matchScore__data">
							{riskScore ? `${riskScore}%` : '0%'}
						</span>
					</div>
				</div>
			</div>
			<div className="AMLWatcherDetails--underline"></div>
			<div className="AMLWatcherDetails--sanctions">
				<div className="AMLWatcherDetails--sanctions__country">
					<div className="AMLWatcherDetails--sanctions__label">
						{AML_CONSTANTS_DETAILS.DATE_OF_BIRTH_LABEL}
					</div>
					<div>:</div>
					<div className="AMLWatcherDetails--sanctions__data">
						{doi || dob ? getFormattedMonthDayYear(doi || dob as string) : 'NA'}
					</div>
				</div>
				<div className="AMLWatcherDetails--sanctions__country">
					<div className="AMLWatcherDetails--sanctions__label">
						{AML_CONSTANTS_DETAILS.APPEARS_ON_LABEL}
					</div>
					<div>:</div>
					<div className="AMLWatcherDetails--sanctions__data">
						{AML_APPEARS_CATEGORIES?.map((category: string, index: number) => {
							const isMatch = categories?.includes(category);
							return (
								<div
									key={`${category}-${index.toString()}`}
									className="AMLWatcherDetails--sanctions__Icon"
								>
									{category}
									<span>
										{isMatch ? (
											<Image fileName="matchAml.svg" />
										) : (
											<Image fileName="noMatchAml.svg" />
										)}
									</span>
								</div>
							);
						})}
					</div>
				</div>
				<div className="AMLWatcherDetails--sanctions__country">
					<div className="AMLWatcherDetails--sanctions__label">
						{AML_CONSTANTS_DETAILS.COUNTRIES_LABEL}
					</div>
					<div>:</div>
					<div className="AMLWatcherDetails--sanctions__data">
						{countries?.length
							? countries?.map((country: string, index: number) => (
									<div key={`${country}-${index.toString()}`}>{country}</div>
							  ))
							: 'NA'}
					</div>
				</div>
			</div>
		</div>
	);
};
