import {
	CardNumberElement,
	useElements,
	useStripe,
} from '@stripe/react-stripe-js';
import {
	FC,
	MouseEventHandler,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useRecoilValue } from 'recoil';

import { PaymentCardMethodsState, loginState } from 'global-stores';
import { useNotification, useSandboxAndFreeTrial } from 'hooks';
import {
	OnboardingPaymentType,
	OnboardingPaymentTypeState,
	useInviteOnboardingPayment,
} from '../../store';
import { AddCard } from '../add-card';
import { BillingDetails } from '../billing-details';
import { BusinessCard } from '../business-card';
import { OnboardingPaymentFooter } from '../footer';
import { OrderSummary } from '../order-summary';

import { Image, Loader } from '@storybook';
import {
	AchListView,
	CardListView,
	DefaultCardType,
	WireScreen,
} from 'views/settings';
import { useOnboardingHook } from 'views/onboarding-flow';
interface IProps {
	primaryBtnCallback: MouseEventHandler<HTMLButtonElement>;
	secondaryBtnCallback: MouseEventHandler<HTMLButtonElement>;
	primaryBtnText?: string | JSX.Element;
	secondaryBtnText?: string;
	isMonthly?: boolean;
}

export const PayNow: FC<IProps> = ({
	primaryBtnCallback,
	secondaryBtnCallback,
	primaryBtnText = 'Send invite',
	secondaryBtnText = 'Back',
}) => {
	const stripe = useStripe();
	const elements = useElements();
	const [addButton, setAddButton] = useState<boolean>(false);
	const [isLoaded, setIsLoaded] = useState(true);
	const [isCardLoaded, setIscardLoaded] = useState(false);
	const paymentType = useRecoilValue(OnboardingPaymentTypeState);
	const {
		role,
		parentBusinessId = false,
		ownBilling = false,
	} = useRecoilValue(loginState);
	const paymentCardMethodsState = useRecoilValue(PaymentCardMethodsState);

	const { errorNotification } = useNotification();
	const { addNewCard } = useInviteOnboardingPayment();
	const { isSandboxMode } = useSandboxAndFreeTrial();
	const { isPricingHide } = useOnboardingHook();

	const { cards = [], default: defaultCard = {} } = useMemo(
		() => paymentCardMethodsState ?? [],
		[paymentCardMethodsState]
	);

	const payNow = paymentType === OnboardingPaymentType.PAY_NOW;

	const handleCreateCard = async (tokenDetails: any) => {
		setIscardLoaded(true);
		const status = await addNewCard(tokenDetails);
		setIsLoaded(true);
		if (status) {
			setAddButton(false);
			setIsLoaded(false);
		}
		setIscardLoaded(false);
		setAddButton(false);
	};

	useEffect(() => {
		// Shahbaaz:If user is sendbox mode
		if (isSandboxMode) return;

		if (parentBusinessId && !ownBilling) return;
		// Shahbaaz: checking button disabled when it will enable
		if (!Object.keys(defaultCard?.detail ?? {}).length) {
			setAddButton(true);
		} else if (
			Object.keys(defaultCard?.detail ?? {}).length > 0 &&
			paymentType === OnboardingPaymentType.MONTHLY_BILLING
		) {
			setAddButton(false);
		} else if (paymentType === OnboardingPaymentType.PAY_NOW && !cards.length) {
			setAddButton(true);
		}
	}, [
		defaultCard?.detail,
		paymentType,
		payNow,
		cards,
		isSandboxMode,
		parentBusinessId,
		ownBilling,
	]);

	const onHandleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		if (!stripe || !elements) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			return;
		}
		setIsLoaded(false);
		const tokenDetails: any = await stripe.createToken(
			elements.getElement(CardNumberElement) as any
		);
		handleCreateCard(tokenDetails);
		if (tokenDetails.error) {
			return errorNotification(tokenDetails.error.message);
		}
		if (!tokenDetails) {
			return errorNotification('Invalid Card');
		}
		return null;
	};

	const renderCardList = useMemo(() => {
		return cards.map((card: any, index: any) => (
			<BusinessCard
				card={card}
				length={cards.length}
				index={index}
				key={card.id}
			/>
		));
	}, [cards]);

	const handleViewModal = useCallback(() => {
		if (isSandboxMode) return;
		setAddButton(prev => !prev);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cards?.length, isSandboxMode]);

	const manageCardsRender = useMemo(() => {
		return isCardLoaded ? <Loader type="gif" dimension={80} /> : renderCardList;
	}, [isCardLoaded, renderCardList]);

	const renderLeftView = useMemo(() => {
		if (isSandboxMode) {
			return (
				<div className="PayNow--nocard">
					<Image fileName="missing.svg" className="no-data__image-box" />
				</div>
			);
		} else {
			return addButton ? (
				<AddCard onSaveCard={onHandleSubmit} isLoaded={isLoaded} />
			) : (
				manageCardsRender
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSandboxMode, addButton, isLoaded, manageCardsRender]);

	const payNowView = useMemo(
		() => (
			<>
				{cards?.length > 0 && (
					<div className="payment-save-card-list">
						{role === 'OWNER' && payNow && (
							<div
								className={`${
									isSandboxMode
										? 'payment-save-card-list__add-btn PayNow--card__disable'
										: 'payment-save-card-list__add-btn'
								}`}
								onClick={handleViewModal}
							>
								{!addButton ? '+ Add new card' : 'View added cards'}
							</div>
						)}
					</div>
				)}

				{renderLeftView}
			</>
		),
		[
			cards?.length,
			role,
			payNow,
			isSandboxMode,
			handleViewModal,
			addButton,
			renderLeftView,
		]
	);

	const monthlyBillingView = useMemo(() => {
		switch (defaultCard?.method) {
			case DefaultCardType.Ach:
				return <AchListView selectedLabel="Selected" />;
			case DefaultCardType.Wire:
				return <WireScreen />;
			default:
				return <CardListView selectedLabel="Selected" />;
		}
	}, [defaultCard?.method]);

	return (
		<div className="PayNow">
			<div className="pay-now-monthly">
				<div className="pay-now-monthly_cards">
					{/* Shahbaaz:show empty ui on left side like sandboax and free trail user */}
					{!isSandboxMode ? (
						<>{payNow ? payNowView : monthlyBillingView}</>
					) : (
						<div className="PayNow--nocard">
							<Image fileName="missing.svg" className="no-data__image-box" />
						</div>
					)}
				</div>
				<div className="summary-billing-wrapper">
					<OrderSummary />
					{isPricingHide && !payNow && <BillingDetails />}
				</div>
			</div>
			<OnboardingPaymentFooter
				primaryBtnCallback={primaryBtnCallback}
				primaryBtnText={primaryBtnText}
				secondaryBtnCallback={secondaryBtnCallback}
				secondaryBtnText={secondaryBtnText}
				isPrimaryDisabled={false}
			/>
		</div>
	);
};
