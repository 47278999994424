import { FC } from 'react';
import { Image } from '@storybook';

import './confirmation-modal.scss';

interface IModal {
	visible: boolean;
	title: string;
	description?: string | JSX.Element;
	boldDescription?: string;
	handleModal: (isOpen: boolean, value: boolean) => void;
	label: string | JSX.Element;
	disabled?: boolean;
	subBody?: JSX.Element | any;
	secondaryLabel?: string | JSX.Element;
	primaryBtnClass?: string;
	descClass?: string;
}

export const ConfirmationModal: FC<IModal> = ({
	visible,
	title,
	description,
	handleModal,
	boldDescription = '',
	label,
	secondaryLabel,
	disabled,
	subBody,
	primaryBtnClass,
	descClass
}) => {
	if (!visible) {
		return null;
	}

	return (
		<div className="modal-main" onClick={() => handleModal(false, false)}>
			<div className="modal-content" onClick={e => e.stopPropagation()}>
				<div className="confirmation-header">
					<Image
						fileName="confirmation_page.svg"
						className="confirmation-image"
					/>
					<div className="modal-title">{title}</div>
				</div>
				<div>
					<div className={`modal-des ${descClass? descClass : ''}` }>
						<span>
							{description}
							<strong>{boldDescription}</strong>
						</span>
					</div>
					{subBody && <div className="modal-sub--body">{subBody}</div>}

					<div className="modal-footer">
						<button
							className="button-cancel"
							onClick={() => handleModal(false, false)}
						>
							{secondaryLabel || 'Cancel'}
						</button>
						<button
							className={`button-ok ${primaryBtnClass} ${disabled ? 'button--disabled' : ''}`}
							onClick={() => handleModal(false, true)}
							disabled={disabled}
						>
							{label}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
