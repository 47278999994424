import React, { useCallback, useMemo } from 'react';

import { formatCamelCaseHeader, useFormatNumber } from 'utils';
import { DetailRow } from './detail-row';
import { UNIT_PRICE_NUMBER_FORMAT, sortingOrder, unitPricingLabels } from '../constants';
import { KybInfoHeader } from '../../kyb-wrapper';

// Props interface for UnitPricingData
interface UnitPricingDataProps {
	payInPricingData: { [key: string]: number | string }; // Pricing data for unit pricing
}

/**
 * Component to render unit pricing data.
 */
export const UnitPricingData: React.FC<UnitPricingDataProps> = ({
	payInPricingData,
}) => {
	const { numberDecimal } = useFormatNumber();

    const getUnitPricingValue = useCallback((value: string | number, key: string) => {
        switch (key) {
            case 'unit':
                return value;
            case 'pricePerUnit':
                return <>${numberDecimal(value as number , UNIT_PRICE_NUMBER_FORMAT)}/Unit</>;
            default:
                return <>${numberDecimal(value as number , UNIT_PRICE_NUMBER_FORMAT)}</>;
        }
    },[numberDecimal]);

	/**
	 * Memoized function to render unit pricing data based on key and value.
	 */
	const renderUnitPricingData = useMemo(() => {
		
		return Object.keys(payInPricingData ?? {})?.length ? (
			<>
				<KybInfoHeader type={'Unit Pricing'} />
				<div className="FundInvestmentReportDetails__unit-pricing">
					{Object.keys(payInPricingData)
						?.sort((a, b) => {
							const indexA = sortingOrder.indexOf(a);
							const indexB = sortingOrder.indexOf(b);

							if (indexA !== -1 && indexB !== -1) {
								return indexA - indexB;
							}

							if (indexA !== -1) return -1;
							if (indexB !== -1) return 1;

							return a.localeCompare(b);
						})
						?.map((key: string) => {
							const label =
								unitPricingLabels[key] ?? formatCamelCaseHeader(key);
							const value = getUnitPricingValue(
								payInPricingData[key] as number,
								key
							) as string;
							return <DetailRow key={key} label={label} value={value} />;
						})}
				</div>
			</>
		) : null;
	}, [payInPricingData, getUnitPricingValue]);

	return <>{renderUnitPricingData}</>;
};
