import type { AutoAddress } from 'views/user-identity-flow';

import { IOption, Input, ReactDropdown } from '@storybook';
import {
	ChangeEvent,
	ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useRecoilState } from 'recoil';

import {
	capitalizeFirstLetter,
	isValidPhoneNumber,
	isValidURL,
	isZipCodeValid,
} from 'utils';
import { NaicsTypeOptions } from 'views/pipelines/constants';
import { AddressDetails } from '../address-details';
import {
	BusinessInformationErrorState,
	BusinessInformationState,
} from '../address-details/store/state';
import { LabelHeader } from '../labelHeader';
import { BusinessFormDataType } from '../store';

export const BusinessIdentificationForm = () => {
	// Local State
	const [checked, setChecked] = useState(true);

	// Globle State
	const [companyFormData, setCompanyFormData] = useRecoilState(
		BusinessInformationState
	);
	const [errorMessage, setErrorMessage] = useRecoilState(
		BusinessInformationErrorState
	);

	useEffect(() => {
		if (!checked)
			setCompanyFormData((prev: BusinessFormDataType) => ({
				...prev,
				mailingAddress: '',
				mailingAddress2: '',
				zip: '',
				mailingCity: '',
				mailingState: '',
				mailingCountry: '',
			}));
		else {
			const { address, zip, address2, state, city, country } =
				companyFormData ?? {};
			setCompanyFormData((prev: BusinessFormDataType) => ({
				...prev,
				mailingAddress: address,
				mailingAddress2: address2,
				mailingZip: zip,
				mailingCity: city,
				mailingState: state,
				mailingCountry: country,
			}));
		}
		// eslint-disable-next-line
	}, [checked]);

	const handleChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>, name: string) => {
			const { value } = e.target;

			// set value into state
			setCompanyFormData((prev: BusinessFormDataType) => ({
				...prev,
				[name]: value,
			}));

			if (
				(name === 'zip' || name === 'malingZip') &&
				!isZipCodeValid(value.trim())
			) {
				setErrorMessage((prev: BusinessFormDataType) => ({
					...prev,
					[name]: `Zip code length should be max 5 characters long`,
				}));
				return;
			}
			if (checked) {
				const mailingObj: any = {
					address: 'mailingAddress',
					zip: 'mailingZip',
					address2: 'mailingAddress2',
				};
				const key = mailingObj[name];
				setCompanyFormData((prev: BusinessFormDataType) => ({
					...prev,
					[key]: value,
				}));
			}

			if (name === 'email' && !encodeURIComponent(value)) {
				setErrorMessage((prev: BusinessFormDataType) => ({
					...prev,
					[name]: 'Invalid email address',
				}));
				return;
			}

			if (name === 'phone' && !isValidPhoneNumber(value.trim())) {
				setErrorMessage((prev: BusinessFormDataType) => ({
					...prev,
					[name]: 'Invalid phone number!',
				}));
				return;
			}

			if (name === 'website' && !isValidURL(value)) {
				setErrorMessage((prev: BusinessFormDataType) => ({
					...prev,
					[name]: 'Invalid url!',
				}));
				return;
			}

			if (!value) {
				setErrorMessage((prev: BusinessFormDataType) => ({
					...prev,
					[name]: `${capitalizeFirstLetter(name)} is required!`,
				}));
				return;
			}

			setErrorMessage((prev: BusinessFormDataType) => ({
				...prev,
				[name]: '',
			}));
		},
		// eslint-disable-next-line
		[checked, companyFormData]
	);

	const handleChangeSelect = useCallback(
		(e: IOption, name: string) => {
			setCompanyFormData((prev: BusinessFormDataType) => ({
				...prev,
				[name]: e.value,
			}));

			if (checked) {
				const mailingObj: any = {
					city: 'mailingCity',
					country: 'mailingCountry',
					state: 'mailingState',
				};
				const key = mailingObj[name];
				setCompanyFormData((prev: BusinessFormDataType) => ({
					...prev,
					[key]: e.value,
				}));
			}
		},
		// eslint-disable-next-line
		[checked, companyFormData]
	);

	// Shahbaaz: Need to work when start work on escrow feat.
	const handleAutoInputValue = (data: AutoAddress | any) => {
		setCompanyFormData(prev => ({
			...prev,
			address: data?.enteredAddress ?? '',
			city: data?.city ?? '',
			state: data?.state ?? '',
			zip: data?.postal_code ?? '',
		}));
	};

	const renderCompanyDetails: ReactNode = useMemo(() => {
		return (
			<>
				<div className="business__form company_details">
					<LabelHeader label="Company Detail" />
					<Input
						label="Company Name"
						inputType="text"
						placeholder="ABCXYZ Inc."
						value={companyFormData.companyName}
						handleChange={(e: ChangeEvent<HTMLInputElement>) =>
							handleChange(e, 'companyName')
						}
						isError={errorMessage.companyName ? true : false}
						errorMessage={errorMessage.companyName as string}
					/>
					<Input
						label="EIN"
						inputType="text"
						placeholder="12-3456789"
						value={companyFormData.ein}
						handleChange={(e: ChangeEvent<HTMLInputElement>) =>
							handleChange(e, 'ein')
						}
						isError={errorMessage.ein ? true : false}
						errorMessage={errorMessage.ein as string}
					/>
					<Input
						label="Website"
						inputType="text"
						placeholder="ABCXYZ Inc."
						value={companyFormData.website}
						handleChange={(e: ChangeEvent<HTMLInputElement>) =>
							handleChange(e, 'website')
						}
						isError={errorMessage.website ? true : false}
						errorMessage={errorMessage.website as string}
					/>
					<Input
						label="Phone"
						inputType="number"
						placeholder="123456789"
						autoComplete="false"
						id="phone"
						value={companyFormData.phone}
						handleChange={(e: ChangeEvent<HTMLInputElement>) =>
							handleChange(e, 'phone')
						}
						isError={errorMessage.phone ? true : false}
						errorMessage={errorMessage.phone as string}
					/>
					<div className="full__span">
						<Input
							label="Email"
							inputType="text"
							placeholder="admin@abcxyz.com"
							autoComplete="false"
							id="email"
							value={companyFormData.email}
							handleChange={(e: ChangeEvent<HTMLInputElement>) =>
								handleChange(e, 'email')
							}
							isError={errorMessage.email ? true : false}
							errorMessage={errorMessage.email as string}
						/>
					</div>
					<Input
						label="Region of Formation"
						inputType="text"
						placeholder="NY"
						value={companyFormData.region}
						handleChange={(e: ChangeEvent<HTMLInputElement>) =>
							handleChange(e, 'region')
						}
						isError={errorMessage.region ? true : false}
						errorMessage={errorMessage.region as string}
					/>
					<Input
						label="Established On"
						inputType="date"
						placeholder="MM/DD/YYYY"
						value={companyFormData.establishedDate}
						handleChange={(e: ChangeEvent<HTMLInputElement>) =>
							handleChange(e, 'establishedDate')
						}
						isError={errorMessage.establishedDate ? true : false}
						errorMessage={errorMessage.establishedDate as string}
					/>
					<Input
						label="Naics"
						inputType="text"
						placeholder="Naics"
						value={companyFormData.naics}
						handleChange={(e: ChangeEvent<HTMLInputElement>) =>
							handleChange(e, 'naics')
						}
						isError={errorMessage.naics ? true : false}
						errorMessage={errorMessage.naics as string}
					/>
					<ReactDropdown
						label="Naics Type"
						options={NaicsTypeOptions}
						value={{
							label: companyFormData.naicsType,
							value: companyFormData.naicsType,
						}}
						handleChangeSelect={(e: IOption) =>
							handleChangeSelect(e, 'naicsType')
						}
					/>

					<div className="full__span">
						<Input
							label="Legal Structure"
							inputType="text"
							placeholder="Legal structure"
							value={companyFormData.legalStructure}
							handleChange={(e: ChangeEvent<HTMLInputElement>) =>
								handleChange(e, 'legalStructure')
							}
							isError={errorMessage.legalStructure ? true : false}
							errorMessage={errorMessage.legalStructure as string}
						/>
					</div>
					<div className="full__span">
						<Input
							label="description"
							inputType="text"
							placeholder="Description"
							value={companyFormData.description}
							handleChange={(e: ChangeEvent<HTMLInputElement>) =>
								handleChange(e, 'description')
							}
							isError={errorMessage.description ? true : false}
							errorMessage={errorMessage.description as string}
						/>
					</div>
				</div>
			</>
		);
		// eslint-disable-next-line
	}, [companyFormData, errorMessage]);

	return (
		<div id="business__container">
			<form className="business__form">
				{renderCompanyDetails}
				<div className="business__form">
					<AddressDetails
						formData={companyFormData}
						errorMessage={errorMessage}
						handleChangeSelect={handleChangeSelect}
						handleChange={handleChange}
						checked={false}
						labelHeader="Business Address"
						isBusiness
						handleAutoInputValue={handleAutoInputValue}
					/>

					<div className="full__span input__checkbox">
						<input
							type="checkbox"
							checked={checked}
							onChange={e => setChecked(e.target.checked)}
						/>
						<label htmlFor="mailing_address" className="input__label">
							Mailing address same as company address
						</label>
					</div>

					{!checked && (
						<AddressDetails
							formData={companyFormData}
							errorMessage={errorMessage}
							handleChangeSelect={handleChangeSelect}
							mailing
							handleChange={handleChange}
							checked={checked}
							labelHeader="Mailing Address"
							isBusiness
							handleAutoInputValue={handleAutoInputValue}
						/>
					)}
				</div>
			</form>
		</div>
	);
};
