import { FC } from 'react';
import './add-block.scss';

interface IAddConditionBtn {
	onClick: () => void;
	label?: string;
}
export const AddBlockButton: FC<IAddConditionBtn> = ({ onClick, label }) => {
	return (
		<button className="add-block-btn-container" onClick={onClick}>
			<div className="add-block-btn-container__condition-btn">
				<div className="icon">
					<i className="ri-add-circle-line"></i>
				</div>
				<label htmlFor="">{label ?? 'Add new condition block'}</label>
			</div>
		</button>
	);
};
