import { FC, KeyboardEvent, useCallback, useState } from 'react';
import { useRecoilState } from 'recoil';

import { Button, Input, Loader } from '@storybook';
import {
	OnboardingQRInstanceNameState,
	useOnboardingQrFlow,
} from '../../stores';

import './enter-instance-name.scss';

interface IEnterInstanceName {
	handleClick: () => Promise<void>;
}

export const EnterInstanceName: FC<IEnterInstanceName> = ({ handleClick }) => {
	// globle state
	const [instanceName, setInstanceName] = useRecoilState(
		OnboardingQRInstanceNameState
	);

	//local states
	const [qrGenerating, setQrGenerating] = useState(false);

	//hooks
	const { isSubmitDisable } = useOnboardingQrFlow();

	const handleGenerateQr = useCallback(async () => {
		if(qrGenerating) return
		setQrGenerating(true);
		await handleClick();
		setQrGenerating(false);
	}, [handleClick, qrGenerating]);

	const handleKeyPress = useCallback((e:KeyboardEvent<HTMLInputElement>)=>{
			const { key } = e ?? {}
			if(key==="Enter"){
				handleGenerateQr()
			}
			return
	},[handleGenerateQr])

	return (
		<div className="enter-instance-name-wrapper">
			<div className="OnboardingQRFlow-modal__header_wrapper__title">
				One last step give this instance a name
			</div>
			<div className="OnboardingQRFlow-modal__header_wrapper__sub-title">
				Unique name helps to differentiate between multiple instance.
			</div>
			<div className="enter-instance-name-wrapper__form-wrapper">
				<Input
					inputType="text"
					label=""
					handleChange={e => setInstanceName(e.target.value)}
					placeholder="Name"
					value={instanceName}
					handleKeyPress={handleKeyPress}
				/>
				<Button
					handleClick={handleGenerateQr}
					label={
						qrGenerating ? (
							<Loader type="loader" dimension={24} className="loader-white" />
						) : (
							'Get QR Code'
						)
					}
					type="button__filled button__filled--primary button__large"
					disabled={isSubmitDisable || qrGenerating}
				/>
			</div>
		</div>
	);
};
