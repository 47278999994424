import type { IActiveEmailClient } from 'views/email-client/stores';

import { useCallback, useMemo } from 'react';
import { useSetRecoilState } from 'recoil';

import { SUPPORTED_CLIENT } from 'views/email-client/constants';
import {
	ActiveEmailClientMethodState,
	ActiveEmailClientState,
} from 'views/email-client/stores';
import { EmailCard } from '../email-card';
import './select-email-client.scss';

export const SelectEmailClient = () => {
	const setActiveEmailClient = useSetRecoilState(ActiveEmailClientState);
	const setActiveEmailClientMethod = useSetRecoilState(
		ActiveEmailClientMethodState
	);

	const handleClick = useCallback(
		(item: IActiveEmailClient, enable: boolean) => {
			if (enable) {
				setActiveEmailClient(item);
				setActiveEmailClientMethod(item.methods[0]?.key as any);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const renderEmail = useMemo(() => {
		return SUPPORTED_CLIENT.map(item => {
			return (
				<EmailCard
					image={item.icon}
					title={item.label}
					key={item.key}
					value={item.key}
					onClick={() => handleClick(item as IActiveEmailClient, item.enable)}
					active={item.enable}
				/>
			);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <div className="select-email-client-wrapper">{renderEmail}</div>;
};
