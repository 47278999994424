import type { IKYC } from '.';

import { atom } from 'recoil';

import { COUNTRIES, DEFAULT_DOCS, LANGUAGES } from 'constant';

export const kycState = atom<IKYC>({
	key: 'kyc-options',
	default: {
		liveness: false,
		language: [LANGUAGES[0] as any],
		document_types: DEFAULT_DOCS,
		document_countries: [COUNTRIES[0] as any],
		allow_general_document_capture: false,
		allow_general_document_upload: false,
		skip_agreement: false,
		skip_document: false,
		skip_face: false,
		allow_document_upload: false,
		allow_face_upload: false,
		allow_face_doc_upload: false,
		require_face_document: false,
		disable_document_capture: false,
		skip_desktop: false,
		capture_double_page_passport: false,
	},
});

export const AutoSaveState = atom<boolean>({
	key: 'auto-save-kyc',
	default: false,
});
