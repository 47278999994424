import { ChangeEvent, useCallback, DragEvent } from 'react';
import { useSetRecoilState } from 'recoil';
import { ProgressState, useUploadDocuments } from '../store';
import { useNotification } from 'hooks';

const allowedTypes: string[] = [
	'image/jpeg',
	'image/jpg',
	'image/png',
	'application/pdf',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const BrowseFile = () => {
	const setProgress = useSetRecoilState(ProgressState);
	const { fileUplaod } = useUploadDocuments();
	const { errorNotification } = useNotification();

	const handleFileUpload = useCallback(
		(e: ChangeEvent<HTMLInputElement> | File, type?: 'drag' | 'browse') => {
			const file: File =
				(type === 'drag'
					? (e as File)
					: (e as ChangeEvent<HTMLInputElement>).target.files?.[0]) ??
				({} as File);
			if (file && file.size > 10000000) {
				errorNotification('Max allowed file size exceeded.');
			} else if (file && !allowedTypes.includes(file.type)) {
				errorNotification('unsupported file format');
			} else if (file) {
				const payload = new FormData();
				payload.append('doc', file);
				setProgress({ isDisable: true, perentage: 40, data: [file] });
				fileUplaod(payload);
			}
			if (type !== 'drag') {
				(e as ChangeEvent<HTMLInputElement>).target.value = '';
			}
		},
		[errorNotification, fileUplaod, setProgress]
	);

	/**
	 * @Manish here we are avoiding the drag over effect when ever we are uploading any document using the drag and upload method
	 * */
	const handleDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
	}, []);

	/**
	 * @Manish here we are writting the drag and upload function so that user can drop the file to upload the document
	 * */
	const handleDrop = useCallback(
		(e: DragEvent<HTMLDivElement>) => {
			e.preventDefault();
			e.stopPropagation();
			const files = Array.from(e.dataTransfer.files);
			handleFileUpload(files?.[0] as File, 'drag');
		},
		[handleFileUpload]
	);

	return (
		<div
			className="browse-file"
			onDragOver={handleDragOver}
			onDrop={handleDrop}
		>
			<label
				htmlFor="files-documents-browse-file"
				className="browse-file__label-box browse-file__label-box-height"
			>
				<i className="ri-file-upload-line browse-file__logo" />
				<div className="browse-file__label-text">
					<span className="browse-file__light-text">
						Drag and drop files or{' '}
						<span style={{ color: '#3c65d6' }}>Browse file</span>
					</span>
				</div>
				<div className="browse-file__description">
					Supported file format: jpg, jpeg, png, pdf and docx
				</div>
				<div className="browse-file__description">
					Maximum upload file size: 10 MB.
				</div>
				<input
					multiple={false}
					accept=".pdf, .docx , .png, .jpg, .jpeg"
					id="files-documents-browse-file"
					type="file"
					onChange={handleFileUpload}
					className="browse-file__input"
				/>
			</label>
		</div>
	);
};
