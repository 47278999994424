import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import Tippy from '@tippyjs/react';

import { IconBtn, Image } from '@storybook';
import { formatSizeUnits } from 'utils';
import { StatusUploadedDocsState } from '../../../../store';
import { useStatusDocuments } from '../../../../hooks';

import './uploaded-files.scss';

const heading = {
	title: 'Files',
	description: '',
};

export const UploadedFiles = () => {
	// globle state
	const addedFiles = useRecoilValue(StatusUploadedDocsState);

	// hooks
	const { handleDeleteFile, fileName } = useStatusDocuments();

	const { data: files } = useMemo(() => addedFiles, [addedFiles]);

	const renderFiles = useMemo(() => {
		return (
			Array.isArray(files) &&
			files?.length &&
			files.map(({ size, name, _id }) => (
				<>
					<div key={_id} className="UploadedFiles__file-wrapper">
						<div className="UploadedFiles__title-wrapper">
							<div className="UploadedFiles__file-icon">
								<Image fileName={fileName(name)} height="20px" />
							</div>
							<Tippy content={<div className="UploadedFiles__tippy">{name}</div>}>
								<div className="UploadedFiles__file-name">{name || 'N/A'}</div>
							</Tippy>
							<i className="ri-circle-fill UploadedFiles__circle-icon" />
							<div className="UploadedFiles__file-size">
								{formatSizeUnits(size ?? 0)}
							</div>
						</div>
						<IconBtn
							btnClassName={`ri-delete-bin-6-line UploadedFiles__delete-icon`}
							handleClickIcon={() => handleDeleteFile(_id ?? '')}
						/>
					</div>
				</>
			))
		);
	}, [fileName, files, handleDeleteFile]);

	return (
		<div className="UploadedFiles">
			<div className="UploadedFiles__headings">
				<div className="UploadedFiles__heading-text">{heading.title}</div>
				{heading.description && (
					<div className="UploadedFiles__heading-description">
						{heading.description}
					</div>
				)}
			</div>
			<div className="UploadedFiles__wrapper">{renderFiles}</div>
		</div>
	);
};
