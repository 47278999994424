import { FC, Fragment, useCallback, useMemo } from 'react';

import { Button, Skeleton } from '@storybook';

import './welcome-user.scss';
import {
	ChooseIdentityData,
	ChooseUserIdentity,
} from 'views/user-identity-flow';
import { AllowedInvitesState, SubscriptionPriceState } from 'global-stores';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useLogout } from 'hooks';

type IWelcomeUser = {
	checked: boolean;
	onGetStarted: () => void;
	setChecked: (checked: boolean) => void;
};
export const WelcomeUser: FC<IWelcomeUser> = ({ onGetStarted, setChecked }) => {
	const subscriptionPrice = useRecoilValue(SubscriptionPriceState);
	const [chooseActiveIdx, setActiveIdx] = useRecoilState(ChooseUserIdentity);
	const allowedInvites = useRecoilValue(AllowedInvitesState);
	const { logout, loading } = useLogout();

	const onboardingAndEsignPrice = useCallback(
		(type: string) => {
			if (subscriptionPrice) {
				const [subsciption]: any = subscriptionPrice;
				return subsciption?.[type];
			} else return 0;
		},
		[subscriptionPrice]
	);

	const freeInviteCount: Record<string, number> = useMemo(() => {
		return {
			'e-sign': allowedInvites.onboarding,
			onboarding: allowedInvites.simpliciSign,
		};
	}, [allowedInvites]);

	const onChooseServices = (val: string) => () => {
		setActiveIdx(val);
		setChecked(true);
	};

	const logoutUser = () => {
		if (loading) return;
		logout();
	};

	const renderRemainPaidServices = useMemo(
		() =>
			ChooseIdentityData.map(
				({
					type,
					label,
					services,
					desc,
					subtitle,
					serviceProvide,
					icon,
					name,
				}) => {
					const price = onboardingAndEsignPrice(type);
					const activeTab = chooseActiveIdx === type;

					return (
						<Fragment key={type}>
							<div
								className={`welcome-user-wrapper__info-wrapper__info-content__service-wrapper__${type} welcome-user-wrapper--service-title
							welcome-user-wrapper${activeTab ? '--active' : ''}
							`}
								onClick={onChooseServices(type)}
							>
								<div className="welcome-user-wrapper--header-wrapper">
									<i
										className={`${icon} ${
											icon === 'ri-user-line' ? 'orange-color' : 'purple-color'
										}`}
									/>
									<div className="welcome-user-wrapper--header-wrapper-div">
										<div className="welcome-user-wrapper--header">
											<div className="welcome-user-wrapper--label">{label}</div>
										</div>
										<div className="welcome-user-wrapper--desc">
											<span className="welcome-user-wrapper__price-label">
												{desc}
											</span>
										</div>
									</div>
								</div>

								<div className="welcome-user-wrapper--footer-container">
									<div className="welcome-user-wrapper--footer-container-div">
										{/* services */}
										<div className="welcome-user-wrapper--action">
											{subtitle}
										</div>

										{/* price */}
										{services.map(service => {
											return (
												<div
													className="welcome-user-wrapper__price-body"
													key={service.name}
												>
													<div className="welcome-user-wrapper__price-label">
														<div className="welcome-user-wrapper__price-icon-checkbox">
															<i className="ri-checkbox-circle-fill"></i>
														</div>

														<div className="welcome-user-wrapper__price-service-name">
															{service.name}
														</div>
														<div className="welcome-user-wrapper__price-flex">
															{freeInviteCount[service.name]} free invites
														</div>
													</div>
												</div>
											);
										})}
										<div className="welcome-user-wrapper--price">
											{price ? (
												<>
													<div className="welcome-user-wrapper__amount">
														<div className="welcome-user-wrapper__info-icon">
															<i className="ri-information-fill"></i>
														</div>

														<div className="welcome-user-wrapper__label-header">
															After {freeInviteCount[name]} free invites,
															subscribe for&nbsp;
															<span className="welcome-user-wrapper__label-price">
																${price} per User/month for eSign.
															</span>
														</div>
													</div>
												</>
											) : (
												<div className="containers--loader">
													<Skeleton height={8} />
												</div>
											)}
										</div>
									</div>
									<div className="welcome-user-wrapper--serviceProvide">
										<i className="ri-information-fill info-icon-two"></i>
										<p>{serviceProvide}</p>
									</div>
								</div>
								{activeTab && (
									<div className="welcome-user-wrapper--selected">Selected</div>
								)}
							</div>
						</Fragment>
					);
				}
			),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onboardingAndEsignPrice, chooseActiveIdx]
	);

	return (
		<div className="welcome-user-wrapper">
			<div className="welcome-user-wrapper__info-wrapper">
				<div className="welcome-user-wrapper--content-wrapper">
					<div className="welcome-user-wrapper__info-wrapper__info-header">
						<div className="welcome-user-wrapper__info-wrapper__info-header__title">
							Choose account type
						</div>
					</div>

					<div className={`welcome-user-wrapper__info-wrapper__info-content`}>
						<div className="welcome-user-wrapper__info-wrapper__info-content__title-wrapper">
							<div className="welcome-user-wrapper__info-wrapper__info-content__service-wrapper">
								{renderRemainPaidServices}
							</div>
						</div>
					</div>
				</div>

				<div className="welcome-user-wrapper__info-wrapper__footer">
					<Button
						label="Logout"
						handleClick={logoutUser}
						type={`button__filled--secondary button__large`}
					/>
					<Button
						label="Next"
						handleClick={onGetStarted}
						type={`button__filled button__filled--primary button__large button-ok`}
					/>
				</div>
			</div>
		</div>
	);
};
