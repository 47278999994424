import { FC } from "react"
import { KYBEventsCard } from "../../kyb-enchanced-event-card"
import { formatUnderScoreRemove } from "utils"

interface IMetadata {
    companyNumber: string
    jurisdictionCode: string
    startDate: string
    homeCompanyName: string
    homeCompanyJurisdictionCode: string
    type: string
  }

export interface IBranches {
  name: string
  country?: string
  type: string
  metadata: IMetadata
  state?: string
}

interface IProps {
    items: IBranches[]
}

export const Branches: FC<IProps> = ({items}) => {
    if(!items.length) {
        return <></>
    }

    return (
    <div>
        {(items ?? []).map(branch => {
            const { name, country, metadata, type } = branch ?? {};
            return (
                <KYBEventsCard 
                    key={`${metadata?.companyNumber}`}
                    heading={name ?? "--"}
                    date={type ?? "N/A"}
                    subHeading={formatUnderScoreRemove(country || '')}
                    metaData={metadata}
                />
            )
    })}
    </div>)
}