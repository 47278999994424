import { useCallback, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useCookie, useCustomLoader, useIsFirefox, useNetwork, useNotification } from 'hooks';
import {
	AllowedInvitesState,
	BusinessRegistrationState,
	IBusinessRegistrationState,
	IframePropsState,
	loginState,
	subscriptionServiceState,
	userDataWithOutToken,
	userState,
	userVerificationId,
} from 'global-stores';
import { API_URL, ROLE } from 'constant';
import { useNavigate } from 'react-router-dom';
import { useUserRoles } from 'views/routes-children';
import { LoginStep, MfaTokenForVerifyState } from 'components';
import { ROUTES } from 'routes';
import { useCompliance } from 'routes/hooks';
const { OWNER } = ROLE;

export const useSignup = () => {
	const { post } = useNetwork({ returnResponse: true });
	const { post: verifyUser } = useNetwork();
	const { get } = useNetwork();
	const { errorNotification, successNotification } = useNotification();
	const setLoginState = useSetRecoilState(loginState);
	const [isLoaded, setIsLoaded] = useState(true);
	const setUserId = useSetRecoilState(userState);
	const setUserPhoneVerificationId = useSetRecoilState(userVerificationId);
	const setUserDataWithOutToken = useSetRecoilState(userDataWithOutToken);
	const setSubscription = useSetRecoilState(subscriptionServiceState);
	const setAllowedInvites = useSetRecoilState(AllowedInvitesState);
	// const setEmailCredsState = useSetRecoilState(EmailCredsState);

	const { set: setCookie } = useCookie();
	const navigate = useNavigate();
	const setStep = useSetRecoilState(LoginStep);
	const setMfaTokenForVerify = useSetRecoilState(MfaTokenForVerifyState);
	const iFrameProps = useRecoilValue(IframePropsState);
	const { setUserPermissions } = useUserRoles();
	const setBusinessRegistration = useSetRecoilState(BusinessRegistrationState);
	const { getComplianceData } = useCompliance();

	const { isFirefox } = useIsFirefox();
	const { fetchLoader  } = useCustomLoader()

	const urlParams = window.location.search;

	const userVerfication = useCallback(
		async (payload: any) => {
			await verifyUser(API_URL.USER_VERIFICATION, payload).then(res => {
				if (res?.phoneVerificationId?.length) {
					setUserPhoneVerificationId(res?.phoneVerificationId);
				}
				if (res?.message) {
					successNotification(
						'Verification link has been sent to your phone successfully'
					);
				}
			});
		},
		[verifyUser, setUserPhoneVerificationId, successNotification]
	);

	const create = useCallback(
		async (payload: any): Promise<boolean> => {
			setIsLoaded(false);
			const resp = await post(`${API_URL.CLIENT_SIGNUP}`, payload);
			if (resp) {
				const { data, message, errorData } = resp;
				if (data?._id) {
					const {
						_id,
						token,
						email,
						role,
						business,
						status,
						firstName,
						lastName,
						developer,
						countryCode,
						phone,
						image,
						isVerifiedEmail,
						isVerifiedSMS,
						sandboxStatus,
					} = data;
					const localStorageData = {
						accessToken: token ?? '',
						userId: _id,
						email,
						role: role?.name ?? OWNER,
						loggedIn: true,
						business,
						plan: '',
						status,
						name: firstName + ' ' + lastName,
						firstName,
						lastName,
						countryCode,
						phone,
						image,
						sandboxStatus,
					};
					const cookieData = {
						accessToken: token ?? '',
						role: role?.name ?? OWNER,
						loggedIn: true,
						developer: developer,
						status,
						business,
					};
					setUserDataWithOutToken({
						localStorageData: localStorageData,
						cookieData: cookieData,
					});

					if (!isVerifiedEmail || !isVerifiedSMS) {
						const payload = {
							email: email,
							...(!isVerifiedSMS && { sendSMS: true }),
							...(!isVerifiedEmail && { sendEmail: false }),
						};

						userVerfication(payload);
					}
					setUserId({
						userId: _id,
					});
					setIsLoaded(true);
					return true;
				}
				if (urlParams?.length > 0) {
					navigate({ pathname: ROUTES.LOGIN, search: urlParams });
					setIsLoaded(true);
					return false;
				}
				errorNotification(errorData?.message ?? message);
				setIsLoaded(true);
				return false;
			}
			errorNotification('Failed to create account try again later.');
			setIsLoaded(true);
			return false;
		},
		[
			post,
			errorNotification,
			urlParams,
			setUserDataWithOutToken,
			setUserId,
			userVerfication,
			navigate,
		]
	);

	const fetchUserDetails = useCallback(
		async (
			accessToken: string,
			preventRedicect?: boolean,
			accountMode?: 'live' | 'sandbox',
			Is_mfa_verified?: boolean,
			isSignUp?: boolean
		) => {
			const config = {
				headers: {
					Authorization: `Bearer ${accessToken}`,
					accountMode: accountMode ?? 'live',
				},
			};
			fetchLoader(accessToken)
			console.log('preventRedicect', preventRedicect); //eslint-disable-line
			await get(`${API_URL.CLIENT_SIGNUP}`, config).then(async resp => {
				if (resp) {
					const {
						id,
						email,
						role,
						business,
						firstName,
						lastName,
						// developer,
						countryCode,
						phone,
						isVerifiedEmail,
						isVerifiedPhone,
						invitedDate,
						isAgentPermitted,
						image,
						sandboxStatus = false,
						subscription,
						businessPrices,
						mfaEnabled,
						role: permissionRole,
					} = resp.data?.[0] ?? {};

					// if (!isVerifiedEmail) {
					// 	setEmailCredsState(email);
					// 	setStep('EMAIL_VERIFICATION');
					// 	setMfaTokenForVerify(accessToken);
					// 	return;
					// }
					const mfa_Verified = Is_mfa_verified ?? false;
					if (mfaEnabled && mfa_Verified === false) {
						setMfaTokenForVerify(accessToken);
						setStep('MFA-AUTH');
						return;
					}
					const { onboardingService, simpliciSignService } =
						businessPrices ?? {};
					const { freeCredits: onboarding } = onboardingService ?? {};
					const { freeCredits: simpliciSign } = simpliciSignService ?? {};
					setAllowedInvites({
						onboarding: onboarding ?? 0,
						simpliciSign: simpliciSign ?? 0,
					});
					if (id) {
						const {
							plan = '',
							status = '',
							_id: businessId,
							approveStatus,
							parentBusinessId = '',
							kyc,
							kyb,
							accountType,
						} = business;
						const localStorageData = {
							accessToken: accessToken,
							userId: id,
							email,
							role: role?.name ?? OWNER,
							loggedIn: true,
							business: businessId,
							status,
							name: firstName + ' ' + lastName,
							firstName,
							lastName,
							countryCode,
							phone,
							plan,
							approveStatus,
							isVerifiedEmail,
							isVerifiedPhone,
							invitedDate,
							isAgentPermitted,
							image,
							sandboxStatus,
							businessId: role?.businessId ?? '',
							parentBusinessId,
						};
						const cookieData = {
							accessToken: accessToken,
						};

						setUserPermissions(permissionRole);
						setSubscription(subscription ?? {});
						setLoginState({ ...localStorageData, plan: '' });
						setCookie('user', { ...cookieData });
						setUserId({
							userId: id,
						});
						if (!iFrameProps.isInSideAdmin) {
							successNotification(
								isSignUp ? 'Sign-up Successfully' : 'Logged in successfully'
							);
						}

						if (isFirefox && isSignUp) {
							window.location.reload();
							return;
						}

						if (accessToken) await getComplianceData(accessToken);

						// Setting business Data of user
						setBusinessRegistration((prev: IBusinessRegistrationState) => ({
							...prev,
							approveStatus,
							accountType,
							kyc: prev?.kyc ? prev?.kyc : kyc,
							kyb: prev?.kyb ? prev?.kyb : kyb,
						}));
					}
				}
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[get, setCookie, setLoginState, setUserId]
	);

	return { create, isLoaded, fetchUserDetails, userVerfication };
};
