import { atom } from 'recoil';

export const SignedDocsState = atom<any[]>({
	key: 'signed-docs-list',
	default: [],
});

export const ShowDocsState = atom<any>({
	key: 'show-docs-state',
	default: [],
});
