import { DateCalender } from '@storybook'; // DateCalender component from Storybook

import { useReminderDeadLine } from './stores'; // Custom hook for handling reminder deadlines

import './reminder-dead-line.scss'; // Import the SCSS file for custom styles

// ReminderDeadLine component definition
export const ReminderDeadLine = () => {
	// Destructure values from the custom hook
	const { minDate, handleDateChange, getDate, customDateOptions } =
		useReminderDeadLine();

	// Render the UI
	return (
		<div className="ReminderDeadLine__container">
			{/* Render the DateCalender component with various props */}
			<DateCalender
				label="Deadline date" // Label for the calendar input
				id="dead-line-date" // Unique ID for the input field
				value={getDate} // Current selected date value from the custom hook
				onChange={handleDateChange} // Event handler for when the date changes
				isCurrentDateMax={false} // Disable restricting the current date as the max
				minDate={minDate} // Set the minimum selectable date from the hook
				showIcon // Show a calendar icon in the input
				inputDisabled // Disable manual input; use only the calendar picker
				customOptions={customDateOptions} // Array of custom date options to be displayed in the calendar dropdown
			/>
		</div>
	);
};
