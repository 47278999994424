import { useCallback, useMemo, useState } from 'react';
import { useFormatNumber } from 'utils';
import { BillingLabelData, SubsCriptionType } from '../constant';

export const Category = ({ category, SubCategoryList }: any) => {
	const { numberDecimal } = useFormatNumber();
	const [key, setKeys] = useState<string[]>([]);

	const handleCatory = (category: string, subcats: any) => {
		const filteredArray = subcats.filter(
			(item: { key: string; name: string }) => item.key === category + 'Service'
		);
		if (filteredArray.length > 0 && filteredArray[0]?.price) {
			return ` ( $${filteredArray[0]?.price}/month )`;
		} else return ' ';
	};

	const onClickOpenAccordion = useCallback(
		(id: string) => () => {
			if (key.includes(id)) {
				const newIds = key.filter(item => item !== id);
				setKeys(newIds);
			} else {
				setKeys((prev: string[]) => [...prev, id]);
			}
		},
		[key]
	);

	const getTotalPrice = useCallback((qty: string | number | undefined, price: string | number | undefined) => {
		let amount = '0.00';
		if (qty && price) {
			const numericQty = typeof qty === 'string' ? parseFloat(qty) : qty;
			const numericPrice = typeof price === 'string' ? parseFloat(price) : price;

			if (!isNaN(numericQty) && !isNaN(numericPrice)) {
				amount = numberDecimal(numericQty * numericPrice);
			}
		}

		return amount;
	}, [numberDecimal]);

	const getSubTotal = useCallback(((subCat: any) => {
		return subCat ? numberDecimal(subCat.reduce(
			(prv: number, cur: {
				quantity: number;
				price: number;
			}) => (cur.quantity * cur.price) + prv, 0)) : 0
	}), [numberDecimal]);

	const subCategoryRow = useMemo(() => {
		return SubCategoryList.map(
			(subCat: {
				price: number;
				quantity: string;
				name: string;
				key: string;
				itemId: any;
				metadata: any;
			}) => (
				<>
					{subCat.key !== 'onboardingService' &&
						subCat.key !== 'simpliciSignService' &&
						subCat.key !== 'simpliciTextService' && (
							<>
								<tr
									key={`${subCat.name.toString()}`}
									onClick={onClickOpenAccordion(subCat?.key)}
								>
									<td className="billing-table-data-action">
										<div>
											{subCat.metadata.length !== 0 && (
												<i
													className={
														key.includes(subCat.key)
															? 'ri-arrow-right-s-fill'
															: 'ri-arrow-up-s-fill'
													}
												></i>
											)}
										</div>
										{BillingLabelData[subCat.key] ?? (subCat?.name || '--')}
									</td>
									<td className="billing-detail-table-data  billing-detail-table-data--text-right">
										<div className="billing-detail-table-data_value">
											{subCat?.quantity ?? '--'}
										</div>
									</td>
									<td className="billing-detail-table-data  billing-detail-table-data--text-right">
										{subCat.metadata.length === 0 && (
											<div className="billing-detail-table-data_value">
												${numberDecimal(subCat?.price ?? 0)}
											</div>
										)}
									</td>
									<td className="billing-detail-table-data  billing-detail-table-data--text-right">
										<div className="billing-detail-table-data_value">
											{subCat.metadata.length === 0 ? (
												`$${getTotalPrice(subCat?.quantity, subCat?.price)}`
											) :
												`$${getSubTotal(subCat.metadata)}`
											}
										</div>
									</td>
								</tr>
								{!key.includes(subCat.key) && (
									<>
										{subCat.metadata.map(
											(dropdownValue: {
												name: string;
												key: string;
												quantity: string;
												price: number;
											}) => (
												<tr
													className="category-dropdown--container"
													key={`${dropdownValue?.key}`}
												>
													<td className="billing-table-data-action category-dropdown--container__data">
														{dropdownValue?.name === 'KYB Enhanced'
															? 'KYB Enhanced'
															: BillingLabelData[dropdownValue.key] ??
															(dropdownValue?.name || '--')}
													</td>
													<td className="billing-detail-table-data billing-detail-table-data--text-right">
														<div className="billing-detail-table-data_value">
															{dropdownValue?.quantity ?? '--'}
														</div>
													</td>
													<td className="billing-detail-table-data billing-detail-table-data--text-right">
														<div className="billing-detail-table-data_value">
															${numberDecimal(dropdownValue?.price ?? 0)}
														</div>
													</td>
													<td className="billing-detail-table-data billing-detail-table-data--text-right">
														<div className="billing-detail-table-data_value">
															${getTotalPrice(dropdownValue?.quantity, dropdownValue?.price)}
														</div>
													</td>
												</tr>
											)
										)}
									</>
								)}
							</>
						)}
				</>
			)
		);
	}, [SubCategoryList, onClickOpenAccordion, numberDecimal, key, getTotalPrice, getSubTotal]);

	return (
		<tbody className="billing-detail-loaderaa  subsription-body">
			{SubCategoryList.length > 0 && (
				<tr>
					<td>
						<div className="category_heading">
							{SubsCriptionType[category]}
							{handleCatory(category, SubCategoryList)}
						</div>
					</td>
					<td className='billing-detail-table-data--text-right'></td>
					<td></td>
					<td className='billing-detail-table-data--text-right'></td>
				</tr>
			)}
			{subCategoryRow}
		</tbody>
	);
};
