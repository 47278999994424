import type { IComplexNode } from 'global-stores/invite-investor/type';

import { selector } from 'recoil';

import {
	SelectedComplexPipelineNode,
	SelectedPipelineDetails,
} from 'global-stores';
import { traceToRoot } from './utils';
import { ALLOWED_CHECKBOX_TYPES, ALLOWED_QUEESTION_TYPES, QUESTION_TYPES } from './constant';

export const OnboardingStepInfoSelector = selector({
	key: 'onboarding-step-info-selector-key',
	get: ({ get }) => {
		const pipeline = get(SelectedPipelineDetails);

		const selectedNodeItem = get(SelectedComplexPipelineNode) as IComplexNode;
		const {
			fullStatus = [],
			nodes,
			actions,
		}: { fullStatus: string[]; nodes: IComplexNode[]; actions: any } = pipeline;
		const currentNodeId = selectedNodeItem?._id;

		if (nodes && currentNodeId) {
			const parentNodes = traceToRoot(currentNodeId, nodes);

			const kycStep = parentNodes.find(({ stepId }) => stepId === 'kyc');
			const kybStep = parentNodes.find(({ stepId }) => stepId === 'kyb');
			const questionnaireStep = parentNodes.filter(
				({ stepId }) => stepId === 'dynamicForm'
			);
			const questions: {
				name: string;
				label: string;
				type: 'text';
				questionType: string;
				rows?: [];
				columns?: [];
                choices?: [];
			}[] = [];
			questionnaireStep.forEach(({ actions }) => {
				const pages = actions[0]?.metadata?.questions?.pages ?? [];
				pages.forEach((page: any) => {
					if (page.elements) {
						
						questions.push(
							...page.elements.map((element: any) =>{ 
								// for checkbox and boolean type, choices are directly mapped to the question object. For text type, choices are extracted from the choices array.
								
								let finalChoices = element.choices?.map((item: any) => {
									 if(typeof item === 'string') {
										return {
											value: item,
											text: item
										}
									} 
									return item;
								})
								
								if(QUESTION_TYPES.BOOLEAN === element.type){
									finalChoices = [{
										value: "Item 1",
										text: element.labelTrue || 'Yes'
									},
									{
										value: "Item 2",
										text: element.labelFalse || 'No'
									}];
								}
								
								// let rows = element.rows;
								const columns = element?.columns?.map((item: any) => {
									if (typeof item === 'string') {
										return {
											title: item,
											name: item,
										};
									}
									return ({title: item.title  || item?.name, name: item.name});
								});
								// If rows has only string array then converting them to the objects
								
								const rows = element.rows?.map((item: any) => {
									if(typeof item === 'string') {
									   return {
										   value: item,
										   text: item
									   }
								   } 
								   return item;
							   })
								return ({
									name: element.name,
									label: element.title ?? element?.name ?? '',
									questionType: element.type,
									columns: columns,
									rows: rows,
									choices: ALLOWED_CHECKBOX_TYPES.includes(element.type) ? finalChoices : [],
									type: ALLOWED_QUEESTION_TYPES[element.type] ? ALLOWED_QUEESTION_TYPES[element.type] : QUESTION_TYPES.TEXT,
								})
							})
						);
					}
				});
			});
			return {
				isKyc: !!kycStep,
				isKyb: !!kybStep,
				isQuestionnaire: !!questionnaireStep.length,
				questionnaire: questions,
			};
		} else {
			/**
			 * this will get executed when there is a linear flow @Manish
			 * */
			/**
			 * we are getting the index of sign agreement in the flow. @Manish
			 * */
			const signAgreementIndex = fullStatus.findIndex(
				step => step === 'signAgreement'
			);
			const steps = [...fullStatus];
			/**
			 * we are getting all the steps before sign agreement from the flow. @Manish
			 * */
			steps.splice(signAgreementIndex);
			/**
			 * we are checking if the kyc is before the sign agreement or not. @Manish
			 * */
			const kycStep = steps.includes('kyc');
			const isQuestionnaire = steps.includes('form');
			const kybStep = steps.includes('kyb');

			const questionnaireStep = (actions ?? []).filter(
				(step: any) => step.stepId === 'form'
			);
			const questions: any = [];
			questionnaireStep.forEach(({ actions }: any) => {
				const pages = actions[0]?.metadata?.formAction?.pages ?? [];
				pages.forEach((page: any) => {
					if (page.elements) {
						questions.push(
							...page.elements.map((element: any) => ({
								name: element.name,
								label: element.title ?? element?.name ?? '',
								questionType: element.type,
								type: 'text',
							}))
						);
					}
				});
			});
			return {
				isKyc: kycStep,
				isQuestionnaire,
				questionnaire: questions,
				isKyb: kybStep,
			};
		}
	},
});
