export const trimBase64ImageWhitespace = async (
    base64Image: string
  ): Promise<string> => {
    return  new Promise((resolve, reject) => {
      // Convert Base64 to binary data
  
      const binaryString = window.atob(base64Image);
      const len = binaryString.length;
  
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
  
      // Create an HTML canvas element and draw the image
      const canvas = document.createElement("canvas");
      const context: any = canvas.getContext("2d");
      const image = new Image();
      image.src = `data:image/png;base64,${base64Image}`;
      image.onload = function () {
        // Set the canvas dimensions to match the image size
        canvas.width = image.width;
        canvas.height = image.height;
  
        // Draw the image onto the canvas
        context.drawImage(image, 0, 0);
  
        // Get the image data
        const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
  
        const data = imageData.data;
        // Find the bounds of the non-white pixels
        let minX = canvas.width;
        let minY = canvas.height;
        let maxX = 0;
        let maxY = 0;
  
        for (let i = 0; i < data.length; i += 4) {
          const alpha = data[i + 3];
          if (alpha > 0) {
            const x = (i / 4) % canvas.width;
            const y = Math.floor(i / (4 * canvas.width));
            if (x < minX) minX = x;
            if (x > maxX) maxX = x;
            if (y < minY) minY = y;
            if (y > maxY) maxY = y;
          }
        }
  
        // Calculate the new dimensions of the trimmed image
  
        const trimmedWidth = maxX - minX + 1;
        const trimmedHeight = maxY - minY + 1;
  
        // Create a new canvas with the trimmed dimensions
        const trimmedCanvas = document.createElement("canvas");
        const trimmedContext: any = trimmedCanvas.getContext("2d");
        trimmedCanvas.width = trimmedWidth;
        trimmedCanvas.height = trimmedHeight;
  
        // Draw the trimmed image onto the new canvas
        trimmedContext.drawImage(
          canvas,
          minX,
          minY,
          trimmedWidth,
          trimmedHeight,
          0,
          0,
          trimmedWidth,
          trimmedHeight
        );
  
        // Convert the trimmed image back to Base64
        // Output the trimmed Base64 image
       const trimmedBase64 = trimmedCanvas.toDataURL("image/png");     
        resolve(trimmedBase64);
      };
      image.onerror = (error) => {
        reject(error);
      };
    });
  };
  