import { atom } from 'recoil';

export interface IInvitations {
	code: string;
	countryCode: string;
	createdAt: string;
	phone: string;
	provider: string;
	status: string;
	_id: string;
}

interface IInvitedMobileState {
	countryCode: string;
	phone: string;
	code: string;
	provider: string;
}

export const invitationsState = atom<IInvitations[]>({
	key: 'kyc-invitations',
	default: [],
});

export const InvitedMobileState = atom<IInvitedMobileState[]>({
	key: 'invited-mobile-number',
	default: [],
});
