import './header-options.scss';

export const HeaderOptions = ({ options, handleChange, active }: any) => {
	return (
		<div className="Settings--header">
			{options.map(
				(el: { label: string; value: string; isDisabled: boolean }) => (
					<div
						key={el.value}
						className={`header_text header_text${
							el.value === active ? '__active' : ''
						}`}
						style={{
							opacity: el.isDisabled ? 0.5 : 1,
							cursor: el.isDisabled ? 'not-allowed' : 'pointer',
						}}
						onClick={() => handleChange(el)}
					>
						{el.label}
					</div>
				)
			)}
		</div>
	);
};
