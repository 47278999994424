import type { IFontStylesState } from "./types";

export type SignStep = "generate" | "upload" | "draw";

interface ISignSteps {
  label: Capitalize<SignStep>;
  name: SignStep;
}

export const signTabs: ISignSteps[] = [
  {
    label: "Generate",
    name: "generate",
  },
  {
    label: "Upload",
    name: "upload",
  },
  {
    label: "Draw",
    name: "draw",
  },
];

export const GenerateSignData: any[] = [
  {
    id: "1",
    fullName: "Manish Chaurasiya",
    initial: "MC",
    fontFamily: "Delicious Handrawn",
    fontStyle: "cursive",
    fontWeight: 400,
  },
  {
    id: "2",
    fullName: "Manish Chaurasiya",
    initial: "MC",
    fontFamily: "Fasthand",
    fontStyle: "cursive",
    fontWeight: 400,
  },
  {
    id: "3",
    fullName: "Manish Chaurasiya",
    initial: "MC",
    fontFamily: "Freehand",
    fontStyle: "cursive",
    fontWeight: 400,
  },
  {
    id: "4",
    fullName: "Manish Chaurasiya",
    initial: "MC",
    fontFamily: "Just Another Hand",
    fontStyle: "cursive",
    fontWeight: 400,
  },
  {
    id: "5",
    fullName: "Manish Chaurasiya",
    initial: "MC",
    fontFamily: "Patrick Hand",
    fontStyle: "cursive",
    fontWeight: 400,
  },
  {
    id: "6",
    fullName: "Manish Chaurasiya",
    initial: "MC",
    fontFamily: "Poppins",
    fontStyle: "sans-serif",
    fontWeight: 400,
  },
];

export const fontStyles: IFontStylesState[] = [
    {
      id: "61559367-dca4-403b-b7a9-9ec26ec51687",
      fontFamily: "Zeyada",
      fontStyle: "cursive",
      fontWeight: 400,
      fontSize: "32px",
    },
    {
      id: "9afe0b05-3c13-4a2a-9e16-74902378f0fa",
      fontFamily: "Inspiration",
      fontStyle: "cursive",
      fontWeight: 400,
      fontSize: "32px",
    },
    {
      id: "c3e2e6c4-a59c-4d8d-9d55-db6ad090afd8",
      fontFamily: "Great Vibes",
      fontStyle: "cursive",
      fontWeight: 400,
      fontSize: "32px",
    },
    {
      id: "0353b48a-bdbd-43a7-8e3e-834cda990b12",
      fontFamily: "Sacramento",
      fontStyle: "cursive",
      fontWeight: 400,
      fontSize: "32px",
    },
    {
      id: "1d9b5f67-123c-4054-b721-c4434edd0f23",
      fontFamily: "Homemade Apple",
      fontStyle: "cursive",
      fontWeight: 400,
      fontSize: "32px",
    },
    {
      id: "a40d9a2a-6a0d-4f86-9b3a-21b41f1577bd",
      fontFamily: "Caveat",
      fontStyle: "cursive",
      fontWeight: 400,
      fontSize: "32px",
    },
    {
      id: "1d9b5f67-123c-4054-b721-c4434edd0f231",
      fontFamily: "Reenie Beanie",
      fontStyle: "cursive",
      fontWeight: 400,
      fontSize: "32px",
    },
    {
      id: "1d9b5f67-123c-4054-b721-c4434edd0f233",
      fontFamily: "Kristi",
      fontStyle: "cursive",
      fontWeight: 400,
      fontSize: "32px",
    },    
];

export const FileUploadedType = ['image/jpeg', 'image/png']