import './verfication-header.scss';

type IVerificationDetailsHeader = {
	Header: string;
	isViewShow?: boolean;
};

export const VerificationDetailsHeader = ({
	Header,
	isViewShow,
}: IVerificationDetailsHeader) => {
	return (
		<div className="KycVerificationHeader">
			<div className="KycVerificationHeader__heading">
				<div className="KycVerificationHeader__heading_inner">
					<div className="KycVerificationHeader__heading_inner_title">
						{Header}
					</div>
				</div>
				{!isViewShow && <div className="KycVerificationHeader__lines" />}
			</div>
		</div>
	);
};
