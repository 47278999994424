import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { REACT_APP_STRIPE_SECRET } from 'envs';
import { AddCardForm } from './add-card-form';
import { FC } from 'react';

// when you toggle to live mode, you should add the live publish key.
const stripePromise = loadStripe(REACT_APP_STRIPE_SECRET);
interface IAddCard {
	from?: 'SIGNUP' | 'HOME';
}
export const AddCard: FC<IAddCard> = ({ from }) => {
	return (
		<>
			<div className="App payment-app">
				<Elements stripe={stripePromise}>
					<AddCardForm from={from} />
				</Elements>
			</div>
		</>
	);
};
