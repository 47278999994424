import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { BusinessPaymentCardState } from 'global-stores';
import {
	AddCardModalState,
	BillingAddressFromState,
	PaymentNavigationState,
} from 'views/settings/billing/stores';
import { BillingPaymentAddress } from 'views/user-identity-flow';
import { PaymentNavigation } from '../../constant';
import './update.scss';
import { useFreePlan } from 'hooks';

export const UpdateBiilingAddress = () => {
	const setOpenView = useSetRecoilState(AddCardModalState);
	const setAddPaymentNaviagtion = useSetRecoilState(PaymentNavigationState);
	const setBillingAddressFrom = useSetRecoilState(BillingAddressFromState);
	const setBillingAddress = useSetRecoilState(BillingPaymentAddress);
	const businessCard = useRecoilValue(BusinessPaymentCardState);
	const { canUpdateBilling } = useFreePlan();

	const handleUpdate = useCallback(() => {
		setBillingAddressFrom('from_billing_info');
		setOpenView({ isOpen: true, isEdit: true });
		setAddPaymentNaviagtion(PaymentNavigation.Billing);
		setBillingAddress(businessCard?.billingAddress ?? {});
	}, [
		businessCard?.billingAddress,
		setAddPaymentNaviagtion,
		setBillingAddress,
		setBillingAddressFrom,
		setOpenView,
	]);

	return (
		<button
			disabled={!canUpdateBilling}
			className={`update-biiling-address ${!canUpdateBilling?"update-biiling-address--disabled":""}`}
			onClick={handleUpdate}
		>
			Update
		</button>
	);
};
