import type {
	IAddress,
	IKycData,
	IReport,
	ISelectedSession,
} from 'global-stores/sessions/type';

import { Image, Loader, ProgressBar } from '@storybook';
import { FC, ReactElement, useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import classNames from 'classnames';
import { REACT_APP_GOOGLE_MAP_SECRET_TOKEN as TOKEN } from 'envs';
import { SessionItemSelected } from 'global-stores';
import { capitalize, maskedNumber } from 'utils';

import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { GOOGLE_API } from 'constant';
import { MapComponent } from 'views/sessions';
import '../../sessions/session.scss';

interface IKycDetail {
	isLoading: boolean;
	type: string;
	details: ISelectedSession | any;
	name: string;
}

export const SenderDetails: FC<IKycDetail> = ({
	isLoading,
	type,
	details: selectedSession,
	name,
}) => {
	const sessionSelected = useRecoilValue(SessionItemSelected);

	const { kyc, aml, device } = useMemo(
		() => selectedSession ?? {},
		[selectedSession]
	);

	const {
		address,
		data: kycData,
		document,
		report,
		session_metadata,
		similarity,
		provider,
		acceptedBy,
	} = kyc?.[kyc?.length - 1] ?? {};

	const {
		age,
		dateOfBirth,
		dateOfExpiry,
		documentNumer,
		firstName,
		issuingState,
		lastName,
		nationality,
		sex,
	} = kycData ?? {};

	const { selfieImage = '', frontImage = '', backImage = '' } = document ?? {};

	const listView = useCallback(
		(list: any = []) => (
			<>
				{list &&
					list.map((listItem: any) => {
						return <li key={'reason' + listItem}>{listItem}</li>;
					})}
			</>
		),
		[]
	);

	const handleValue = useCallback(
		(item: any | IReport | IKycData | IAddress, key: string) => {
			if (item) {
				const result = Array.isArray(item[key]);
				if (item[key] === 0) {
					return '--';
				}
				if (!result) {
					return item[key] === '' || item[key] == null
						? '--'
						: item[key].toString();
				} else {
					if (item[key].length > 0) {
						return listView(item[key]);
					} else {
						return '--';
					}
				}
			}
		},
		[listView]
	);

	const handleAverageProgressColor = useCallback((data: number) => {
		if (data < 33) return 'red';
		else if (33 < data && data < 66) return 'orange';
		else return 'green';
	}, []);

	const reportJsx = useMemo(() => {
		const isIdMerit = provider === 'IDMERIT' && acceptedBy && documentNumer;
		return (
			<div className="kycDetail-column">
				{report &&
					Object.keys(report).map(key => {
						if (key !== 'similarity') {
							return (
								<>
									<div
										className="TransactionDetail-row"
										key={'TransactionDetail-row' + key}
									>
										<div className="report-label">{key}</div>
										<div>:</div>
										<p
											className={
												key.toLowerCase() === 'reject'
													? 'TransactionDetail-row__reject'
													: ''
											}
										>
											{handleValue(report, key)}
										</p>
									</div>

									{key === 'status' && provider && (
										<>
											<div
												className="TransactionDetail-row"
												key={'TransactionDetail-row-provider'}
											>
												<div className="report-label">Provider</div>
												<div>:</div>
												<p>{provider ?? '--'}</p>
											</div>
											{}
											{isIdMerit && (
												<>
													<div
														className="TransactionDetail-row"
														// eslint-disable-next-line no-useless-concat
														key={'TransactionDetail-row' + '-idType'}
													>
														<div className="report-label">ID Type</div>
														<div>:</div>
														<p>{acceptedBy.toUpperCase() ?? '--'}</p>
													</div>

													<div
														className="TransactionDetail-row"
														// eslint-disable-next-line no-useless-concat
														key={'TransactionDetail-row' + '-ssn'}
													>
														<div className="report-label">
															{acceptedBy.toUpperCase()}
														</div>
														<div>:</div>
														<p>
															{documentNumer.value
																? maskedNumber(documentNumer.value)
																: '--'}
														</p>
													</div>
												</>
											)}
										</>
									)}
								</>
							);
						}
						return null;
					})}
				<div className="TransactionDetail-row">
					<div className="report-label">Average Confidence Level</div>
					<div>:</div>
					<ProgressBar
						completed={similarity ?? 0}
						bgColor={handleAverageProgressColor(similarity ?? 0)}
						labelClassName="label"
						customClass="avg-confidence-progress"
						height="8px"
						customLabel={`${similarity ?? 0}%`}
						labelAlignment="outside"
						isLabelVisible={false}
					/>
				</div>
			</div>
		);
	}, [
		acceptedBy,
		documentNumer,
		handleAverageProgressColor,
		handleValue,
		provider,
		report,
		similarity,
	]);

	const handleProgressBar = useCallback((data: number) => {
		switch (data) {
			case 1:
			case 2:
				return data * 33.33;
			case 3:
				return 99;
			default:
				return data * 33.33;
		}
	}, []);

	const handleProgressColor = useCallback((data: number) => {
		switch (data) {
			case 1:
				return 'red';
			case 2:
				return 'orange';
			case 3:
				return 'green';
			default:
				return '';
		}
	}, []);

	const handleProgressLabel = useCallback((data: number) => {
		switch (data) {
			case 1:
				return 'Low';
			case 2:
				return 'Medium';
			case 3:
				return 'High';
			default:
				return 'NA';
		}
	}, []);

	const mapRender = (status: Status): ReactElement => {
		if (status === Status.FAILURE) return <></>;
		return <Loader />;
	};

	const personalInfoJsx = useMemo(() => {
		return (
			<div className="kycDetail-column">
				<>
					<div className="TransactionDetail-row">
						<div className="report-label">First Name</div>
						<div>:</div>
						<p>{firstName?.value || '--'}</p>
						<div className="document-percentage__confidence">
							<ProgressBar
								completed={handleProgressBar(firstName?.confidence ?? 0)}
								bgColor={handleProgressColor(firstName?.confidence ?? 0)}
								labelAlignment="outside"
								labelClassName="label-percentage"
								customLabel={handleProgressLabel(firstName?.confidence ?? 0)}
								height="8px"
							/>
						</div>
					</div>
					<div className="TransactionDetail-row">
						<div className="report-label">Last Name</div>
						<div>:</div>
						<p>{lastName?.value || '--'}</p>
						<div className="document-percentage__confidence">
							<ProgressBar
								completed={handleProgressBar(lastName?.confidence ?? 0)}
								bgColor={handleProgressColor(lastName?.confidence ?? 0)}
								labelAlignment="outside"
								labelClassName="label-percentage"
								customLabel={handleProgressLabel(lastName?.confidence ?? 0)}
								height="8px"
							/>
						</div>
					</div>
					<div className="TransactionDetail-row">
						<div className="report-label">Date Of Birth</div>
						<div>:</div>
						<p>{dateOfBirth?.value || '--'}</p>
						<div className="document-percentage__confidence">
							<ProgressBar
								completed={handleProgressBar(dateOfBirth?.confidence ?? 0)}
								bgColor={handleProgressColor(dateOfBirth?.confidence ?? 0)}
								labelAlignment="outside"
								labelClassName="label-percentage"
								customLabel={handleProgressLabel(dateOfBirth?.confidence ?? 0)}
								height="8px"
							/>
						</div>
					</div>
					<div className="TransactionDetail-row">
						<div className="report-label">Doc. Expiry Date</div>
						<div>:</div>
						<p>{dateOfExpiry?.value || '--'}</p>
						<div className="document-percentage__confidence">
							<ProgressBar
								completed={handleProgressBar(dateOfExpiry?.confidence ?? 0)}
								bgColor={handleProgressColor(dateOfExpiry?.confidence ?? 0)}
								labelAlignment="outside"
								labelClassName="label-percentage"
								customLabel={handleProgressLabel(dateOfExpiry?.confidence ?? 0)}
								height="8px"
							/>
						</div>
					</div>
					<div className="TransactionDetail-row">
						<div className="report-label">Age</div>
						<div>:</div>
						<p>{age?.value || '--'}</p>
						<div className="document-percentage__confidence">
							<ProgressBar
								completed={handleProgressBar(age?.confidence ?? 0)}
								bgColor={handleProgressColor(age?.confidence ?? 0)}
								labelAlignment="outside"
								labelClassName="label-percentage"
								customLabel={handleProgressLabel(age?.confidence ?? 0)}
								height="8px"
							/>
						</div>
					</div>
					<div className="TransactionDetail-row">
						<div className="report-label">Sex</div>
						<div>:</div>
						<p>{sex?.value || '--'}</p>
						<div className="document-percentage__confidence">
							<ProgressBar
								completed={handleProgressBar(sex?.confidence ?? 0)}
								bgColor={handleProgressColor(sex?.confidence ?? 0)}
								labelAlignment="outside"
								labelClassName="label-percentage"
								customLabel={handleProgressLabel(sex?.confidence ?? 0)}
								height="8px"
							/>
						</div>
					</div>
					<div className="TransactionDetail-row">
						<div className="report-label">Issuing State</div>
						<div>:</div>
						<p>{issuingState?.value || '--'}</p>
						<div className="document-percentage__confidence">
							<ProgressBar
								completed={handleProgressBar(issuingState?.confidence ?? 0)}
								bgColor={handleProgressColor(issuingState?.confidence ?? 0)}
								labelAlignment="outside"
								labelClassName="label-percentage"
								customLabel={handleProgressLabel(issuingState?.confidence ?? 0)}
								height="8px"
							/>
						</div>
					</div>
					<div className="TransactionDetail-row">
						<div className="report-label">Nationality</div>
						<div>:</div>
						<p>{nationality?.value || '--'}</p>
						<div className="document-percentage__confidence">
							<ProgressBar
								completed={handleProgressBar(nationality?.confidence ?? 0)}
								bgColor={handleProgressColor(nationality?.confidence ?? 0)}
								labelAlignment="outside"
								labelClassName="label-percentage"
								customLabel={handleProgressLabel(nationality?.confidence ?? 0)}
								height="8px"
							/>
						</div>
					</div>
					<div className="TransactionDetail-row">
						<div className="report-label">Address</div>
						<div>:</div>
						<p>{address?.address?.value || '--'}</p>
					</div>
					<div className="TransactionDetail-row">
						<div className="report-label">City</div>
						<div>:</div>
						<p>{address?.city?.value || '--'}</p>
					</div>
					<div className="TransactionDetail-row">
						<div className="report-label">State</div>
						<div>:</div>
						<p>{address?.state?.value || '--'}</p>
					</div>
					<div className="TransactionDetail-row">
						<div className="report-label">Zip</div>
						<div>:</div>
						<p>{address?.zip?.value || '--'}</p>
					</div>
				</>
			</div>
		);
	}, [
		firstName?.value,
		firstName?.confidence,
		lastName?.value,
		lastName?.confidence,
		dateOfBirth?.value,
		dateOfBirth?.confidence,
		dateOfExpiry?.value,
		dateOfExpiry?.confidence,
		age?.value,
		age?.confidence,
		sex?.value,
		sex?.confidence,
		issuingState?.value,
		issuingState?.confidence,
		nationality?.value,
		nationality?.confidence,
		handleProgressBar,
		handleProgressColor,
		handleProgressLabel,
		address?.address?.value,
		address?.city?.value,
		address?.state?.value,
		address?.zip?.value,
	]);

	const showValue = useCallback(
		(value: string) => {
			if (value && value?.length > 0) {
				return value;
			}
			return '--';
		},

		[]
	);

	const personalAmlInfoJsx = useMemo(
		() => (
			<div className="kycDetail-column">
				{aml ? (
					<>
						<div className="TransactionDetail-row">
							<div className="report-label">First Name</div>
							<div>:</div>
							<p>{showValue(aml?.firstName)}</p>
						</div>
						<div className="TransactionDetail-row">
							<div className="report-label">Last Name</div>
							<div>:</div>
							<p>{showValue(aml?.lastName)}</p>
						</div>
						<div className="TransactionDetail-row">
							<div className="report-label">Date Of Birth</div>
							<div>:</div>
							<p>{showValue(aml?.dob)}</p>
						</div>
						<div className="TransactionDetail-row">
							<div className="report-label">Address</div>
							<div>:</div>
							<p>{showValue(aml?.address)}</p>
						</div>
						<div className="TransactionDetail-row">
							<div className="report-label">City</div>
							<div>:</div>
							<p>{showValue(aml?.city)}</p>
						</div>
						<div className="TransactionDetail-row">
							<div className="report-label"> State</div>
							<div>:</div>
							<p>{showValue(aml?.state)}</p>
						</div>
						<div className="TransactionDetail-row">
							<div className="report-label">Zip</div>
							<div>:</div>
							<p>{showValue(aml?.zip)}</p>
						</div>
						<div className="TransactionDetail-row">
							<div className="report-label">Transaction Id</div>
							<div>:</div>
							<p>{showValue(aml?.transactionId)}</p>
						</div>
						<div className="TransactionDetail-row">
							<div className="report-label">Request Id</div>
							<div>:</div>
							<p>{showValue(aml?.requestId)}</p>
						</div>
						{aml?.info && (
							<div className="aml-record">
								<div className="aml-record__scores">
									{aml?.info?.matchScore && (
										<div className="aml-record__match-score">
											<div className="aml-record__match-icon">
												<i className="ri-body-scan-fill"></i>
											</div>
											<div className="aml-record__match-text">
												<div className="aml-record__match-value">
													{aml?.info?.matchScore}
												</div>
												<div className="aml-record__match-label">
													Match Score
												</div>
											</div>
										</div>
									)}
									{aml?.info?.riskscore && (
										<div className="aml-record__risk-score">
											<div className="aml-record__match-icon">
												<i className="ri-search-eye-line"></i>
											</div>
											<div className="aml-record__match-text">
												<div className="aml-record__match-value">
													{aml?.info?.riskscore}
												</div>
												<div className="aml-record__match-label">
													Risk Score
												</div>
											</div>
										</div>
									)}
								</div>
								{aml?.info?.registered_events?.event_desc && (
									<div className="aml-record-discr-data">
										<div className="aml-record-discr">
											{aml?.info?.registered_events?.event_desc}
										</div>
										<div className="aml-record-date-source">
											{aml?.info?.registered_events?.entityDate && (
												<div className="record-date">
													{aml?.info?.registered_events?.entityDate}
												</div>
											)}
											<span className="record-dot"></span>
											{aml?.info?.registered_events?.sourceURL ? (
												<a
													href={aml?.info?.registered_events?.sourceURL}
													className="record-source-name"
													target="_blank"
													rel="noreferrer"
												>
													{aml?.info?.registered_events?.sourceName}
												</a>
											) : (
												<div className="record-source-name">
													{aml?.info?.registered_events?.sourceName}
												</div>
											)}
										</div>
									</div>
								)}
							</div>
						)}
					</>
				) : (
					<div className="TransactionDetail-row"> No data </div>
				)}
			</div>
		),
		// eslint-disable-next-line
		[aml]
	);

	const { latitude, longitude } = device?.geoLocation ?? {};

	const {
		name: deviceName,
		email: deviceEmail,
		deviceId,
		appleId,
	} = device?.deviceInfo ?? {};

	const isInfoAvailable = useMemo(() => {
		return !!deviceName;
	}, [deviceName]);

	const isLocationAvailable = useMemo(() => {
		const { latitude } = device?.geoLocation ?? {};
		return !!latitude;
	}, [device?.geoLocation]);

	if (isLoading) {
		return (
			<div className="kycDetails">
				<div className="kycDetails--header kycDetails--header--sticky">
					<div className="kycUserName">{sessionSelected.name}</div>
				</div>
				<div className="empty_data">
					<Loader className="loader-blue" dimension={52} />
				</div>
			</div>
		);
	}

	const labelColor = classNames('TransactionDetails--label-receiver', {
		'TransactionDetails--label-sender': type === 'Sender',
	});

	const url = `${GOOGLE_API.MAP}${latitude},${longitude}`;

	return (
		<div className="kycDetails">
			<div className={labelColor}>
				{name} ({type})
			</div>
			<div className="session-column-container">
				<div className="session-column-wrapper">
					{(!!session_metadata || isInfoAvailable || isLocationAvailable) && (
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<div className="kycDetails-header">
								<div className="kycDetails--sub-header">
									Session Information
								</div>
							</div>
							<div className="session-info">
								{!!session_metadata && (
									<>
										<div className="session-info__header">
											<div className="session-info__header__icon-container">
												<i
													className={
														session_metadata?.device?.type === 'desktop'
															? 'ri-macbook-line'
															: 'ri-smartphone-line'
													}
												/>
												<span>
													{session_metadata?.device?.type
														? capitalize(session_metadata?.device?.type)
														: 'NA'}
												</span>
											</div>
											{/* <div className="date">time and date</div> */}
										</div>
										<div className="session-info__body">
											<div className="session-info__body__details-container">
												<div className="session-info__body__details">
													<div className="session-info__body__icon-container device">
														<i className="ri-smartphone-line" />
													</div>
													<div className="session-info__body__detailed">
														<span className="session-info__body__key">
															Device OS
														</span>
														<span className="session-info__body__value">
															{session_metadata?.os?.name &&
															session_metadata?.os?.version
																? `${session_metadata?.os?.name} ${session_metadata?.os?.version}`
																: 'NA'}
														</span>
													</div>
												</div>

												<div className="session-info__body__details">
													<div className="session-info__body__icon-container user">
														<i className="ri-user-search-line" />
													</div>
													<div className="session-info__body__detailed">
														<span className="session-info__body__key">
															User IP
														</span>
														<span className="session-info__body__value">
															{session_metadata?.ip_address ?? 'NA'}
														</span>
													</div>
												</div>
												<div className="session-info__body__details">
													<div className="session-info__body__icon-container host">
														<i className="ri-map-pin-2-line" />
													</div>
													<div className="session-info__body__detailed">
														<span className="session-info__body__key">
															Region
														</span>
														<span className="session-info__body__value">
															{(session_metadata?.region ?? 'NA').toUpperCase()}
														</span>
													</div>
												</div>
											</div>
											<div className="session-info__body__details-container">
												<div className="session-info__body__details">
													<div className="session-info__body__icon-container browser">
														<i className="ri-global-line" />
													</div>
													<div className="session-info__body__detailed">
														<span className="session-info__body__key">
															Browser
														</span>
														<span className="session-info__body__value">
															{session_metadata?.browser?.name &&
															session_metadata?.browser?.major
																? `${session_metadata?.browser?.name} ${session_metadata?.browser?.major}`
																: 'NA'}
														</span>
													</div>
												</div>

												<div className="session-info__body__details">
													<div className="session-info__body__icon-container location">
														<i className="ri-user-location-line" />
													</div>
													<div className="session-info__body__detailed">
														<span className="session-info__body__key">
															City
														</span>
														<span className="session-info__body__value">
															{session_metadata?.city
																? capitalize(session_metadata?.city)
																: 'NA'}
														</span>
													</div>
												</div>
												<div className="session-info__body__details">
													<div className="session-info__body__icon-container referrer">
														<i className="ri-flag-line" />
													</div>
													<div className="session-info__body__detailed">
														<span className="session-info__body__key">
															Country Code
														</span>
														<span className="session-info__body__value">
															{session_metadata?.country_code ?? 'NA'}
														</span>
													</div>
												</div>
											</div>
										</div>
									</>
								)}

								{deviceName && deviceEmail && (
									<>
										<div className="apple-id">
											<div className="session-info__body__details apple-id_info">
												<div className="session-info__body__icon-container device">
													<i className="ri-user-line" />
												</div>
												<div className="session-info__body__detailed">
													<span className="session-info__body__key">Name</span>
													<span className="session-info__body__value">
														{deviceName}
													</span>
												</div>
											</div>

											<div className="session-info__body__details apple-id_info">
												<div className="session-info__body__icon-container browser">
													<i className="ri-mail-line" />
												</div>
												<div className="session-info__body__detailed">
													<span className="session-info__body__key">Email</span>
													<span className="session-info__body__value Transactions--email">
														{deviceEmail}
													</span>
												</div>
											</div>
										</div>
										<div className="apple-id">
											<div className="session-info__body__details apple-id_info">
												<div className="session-info__body__icon-container user">
													<i className="ri-apple-line" />
												</div>
												<div className="session-info__body__detailed">
													<span className="session-info__body__key">
														Apple ID
													</span>
													<span className="session-info__body__value">
														{appleId || 'NA'}
													</span>
												</div>
											</div>

											<div className="session-info__body__details apple-id_info">
												<div className="session-info__body__icon-container location">
													<i className="ri-device-line" />
												</div>
												<div className="session-info__body__detailed">
													<span className="session-info__body__key">
														Device ID
													</span>
													<span className="session-info__body__value">
														{deviceId || 'NA'}
													</span>
												</div>
											</div>
										</div>
									</>
								)}
								{latitude && longitude && (
									<div className="map_container_body">
										<a
											href={url}
											target="_blank"
											rel="noreferrer"
											className="map_container"
										>
											<Wrapper apiKey={TOKEN} render={mapRender}>
												<MapComponent
													center={{
														lat: latitude,
														lng: longitude,
													}}
													zoom={11}
												/>
											</Wrapper>
										</a>
									</div>
								)}
							</div>
						</div>
					)}
					{kyc?.length > 0 ? (
						<>
							<div className="kycDetails-header">
								<div className="kycDetails--sub-header">KYC Details</div>
							</div>
							<div className="kycDetails-contain">
								{report.liveliness && !!selfieImage && (
									<div className="kycDetails-img-wrapper">
										<img
											src={selfieImage}
											alt="Profile"
											className={`${
												selfieImage ? 'person-photo' : 'person-placeholder'
											}`}
											onClick={() => window.open(selfieImage)}
										/>
									</div>
								)}
								<div>
									<div className="kycDetail-reports">
										<div style={{ width: 420 }}>
											<div className="kycDetails--subHead">Reports</div>
											{reportJsx}
										</div>
									</div>
								</div>
								<div className="kycDetails-info">
									<div className="kycDetail-left">
										<div>
											<div className="kycDetail-userDetails">
												<div className="kyc-document">
													{!!frontImage && (
														<div className="transaction--document">
															<img
																src={frontImage}
																alt="pic"
																className="transaction--document"
																onClick={() => window.open(frontImage)}
															/>
														</div>
													)}
													{!!backImage && (
														<div className="transaction--document">
															<img
																src={backImage}
																alt="pic"
																className="transaction--document"
																onClick={() => window.open(backImage)}
															/>
														</div>
													)}
												</div>
											</div>
											<div className="kycDetails--subHead">
												Personal Information
											</div>
											{personalInfoJsx}
										</div>
									</div>
								</div>
							</div>
						</>
					) : (
						<>
							<div className="kycDetails-header">
								<div className="kycDetails--sub-header">KYC Details</div>
							</div>
							<div className="kycDetails-contain">
								<div className="kycDetails-noDataFound">
									<Image fileName="missing.svg" />
									<div>No data available!</div>
								</div>
							</div>
						</>
					)}
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<div className="kycDetails-header">
							<div className="kycDetails--subHead">Background Check</div>
						</div>
						<div className="kycDetails-contain">
							{Object.values(aml ?? {}).length > 0 ? (
								<div className="aml-details">
									<div className="kycDetails-body">{personalAmlInfoJsx}</div>
								</div>
							) : (
								<div className="kycDetails-noDataFound">
									<Image fileName="missing.svg" />
									<div>No data available!</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
