import { REACT_APP_API_HOST as API_HOST } from 'envs';

const isStage = /stage/gi.test(API_HOST);
const isPP = /pp/gi.test(API_HOST);
const isProd = /api.simplici.io/gi.test(API_HOST);
const isBeta = /beta/gi.test(API_HOST);

const hostUrl = () => {
	if (isStage) return 'https://secure.stage.satschel.com';
	if (isPP) return 'https://secure.pp.satschel.com';
	if (isProd) return 'https://secure.simplici.io';
	if (isBeta) return 'https://secure.beta.simplici.io';
	return 'https://secure.stage.satschel.com';
};

const hostUrlSandBox = () => {
	if (isProd) return 'https://secure.beta.simplici.io';
	if (isBeta) return 'https://secure.pp.satschel.com';
	return 'https://secure.stage.satschel.com';
};

export const KycMobileHostSandbox = hostUrlSandBox();
export const KycMobileHost = hostUrl();
export { isStage, isPP, isProd };
