import type { ICountries, ICountryCode as Props } from '.';

import { useEffect, useState } from 'react';
import Select, { components, SingleValueProps, StylesConfig } from 'react-select';

import countries from 'json/country-codes.json';

import './country-code.scss';

/**
 * Confirmation Modal component
 *
 * @param {(e: ICountries) => void} Props.handleChangeCountry - Method to change Country code
 * @param {string} Props.countryCode - selected country code
 * @param {string} Props.optionsClassName - className for options dropdown
 * @param {string} Props.className - className for input box
 * @param {boolean} Props.isDisabled - disable country code dropdown
 *
 */

export const CountryCode = ({
	handleChangeCountry,
	countryCode = '+1',
	optionsClassName = '',
	className = '',
	isDisabled = false,
}: Props) => {
	const [country, setCountry] = useState<ICountries>();

	const customStyles: StylesConfig<ICountries, false> = {
		option: (styles) => ({
			...styles,
			cursor: 'pointer',
			boxShadow: 'none',
		}),
		control: (styles) => ({
			...styles,
			cursor: 'pointer',
			border: 'none',
			boxShadow: 'none',
			backgroundColor: 'transparent',
			'&:hover': {
				border: 'none',
				borderRadius: '5px',
				boxShadow: 'none',
			},
		}),
		menu: (styles) => ({
			...styles,
			width: '350px', // Make the dropdown menu 100% wide
			'@media only screen and (max-width: 430px)': {
				width: '300px',
			},
		}),
	};

	useEffect(() => {
		const countryObj = countries.find(item => item.label === countryCode);
		if (countryObj && countryObj.label !== country?.label) {
			setCountry(countryObj);
		}
	}, [countryCode, country]);

	const handleCountryChange = (selectedOption: ICountries | null) => {
		if (selectedOption) {
			setCountry(selectedOption);
			handleChangeCountry(selectedOption);
		}
	};

	// Custom SingleValue component to show only the flag and country code after selection
	const SingleValue = (props: SingleValueProps<ICountries>) => {
		return (
			<components.SingleValue {...props}>
				<div className={`option ${optionsClassName}`}>
					<div className="skeleton-wrapper">
					<img
						className="option__flag"
						src={props.data.image}
						alt="country-code"
					/>
						<div className="skeleton-image"></div>
					</div>
					<span className="option__label">{props.data.label}</span>
				</div>
			</components.SingleValue>
		);
	};

	return country ? (
		<Select
			defaultValue={country}
			styles={customStyles}
			formatOptionLabel={(countryData: ICountries) => {
				const isSelected = countryData.label === country.label;
				return (
				<div className={`option ${optionsClassName}`}>
					<div className="skeleton-wrapper">
					<img
						className="option__flag"
						src={countryData.image}
						alt="country-code"
					/>
						<div className="skeleton-image"></div>
					</div>
						<span className="option__label">
							{countryData.value}
							<span className={!isSelected ? 'option__label-code' : ''}>
								{countryData.label}
							</span>
						</span>
				</div>
			)}}
			className={`country-label ${className}`}
			options={countries}
			getOptionValue={(option: ICountries) => option.value}
			onChange={handleCountryChange}
			isSearchable
			isDisabled={isDisabled}
			value={country}
			components={{
				SingleValue,
				IndicatorSeparator: () => null,
			}}
			maxMenuHeight={140}
		/>
	) : (
		<></>
	);
};
