import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { ActiveActionKeyNameState, PipelineMasterSteps } from 'global-stores';
import {
	ConfigureSurvayFormState,
	CurrentStepState,
} from 'views/onboarding-flow';
import {
	ComplexConfigModalState,
	ComplexFlowUnconfigedState,
	ConfigFormModalState,
	IComplexFlowUnconfiged,
} from '../stores/states';

export const useSetComplex = () => {
	const steps = useRecoilValue(PipelineMasterSteps);
	const setActiveAction = useSetRecoilState(ActiveActionKeyNameState);
	const setCurrentStep = useSetRecoilState(CurrentStepState);
	const setIsConfigModal = useSetRecoilState(ComplexConfigModalState);
	const complexFlowConfiged = useRecoilValue(ComplexFlowUnconfigedState);
	const setIsConfigFormModal = useSetRecoilState(ConfigFormModalState);
	const setConfigureSurvayForm = useSetRecoilState(ConfigureSurvayFormState);

	const setNextStep = useCallback(
		(pendingFlows?: IComplexFlowUnconfiged[]) => {
			const pending = pendingFlows ?? complexFlowConfiged ?? [];
			const pendingFlow = pending.find(flow => !flow.configed);
			if (!pendingFlow) {
				setIsConfigModal(false);
				return;
			}
			const { id, stepId } = pendingFlow;
			if (stepId === 'dynamicForm') {
				setConfigureSurvayForm({});
				setIsConfigModal(false);
				setIsConfigFormModal(true);
			} else {
				setIsConfigFormModal(false);
				setIsConfigModal(true);
			}
			const result = steps.find(el => el.key === stepId);
			const renderObj: any = result?.actions && result.actions[0];
			setCurrentStep({ ...renderObj, id } || {});
			setActiveAction(stepId);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[complexFlowConfiged, steps]
	);

	return { setNextStep };
};
