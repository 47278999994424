/* eslint-disable no-console */
import { useNetwork, useNotification } from 'hooks';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
	CheckDetailsFormState,
	CheckFraudDetailsState,
	CheckFraudListState,
	ChequeFraudAllBranchState,
	IDocs,
	SelectedChequeFraudTransactionsListState,
} from './states';
import { API_URL } from 'constant';
import { ComplexSessionInfoState, SelectedSessionState } from 'global-stores';
import { CHEQUE_FRAUD_MESSAGES } from '../constants';

export const useCheckDefence = () => {
	const [checkFormDetails, setCheckFormDetails] = useRecoilState(
		CheckDetailsFormState
	);

	const setAllBracnhes = useSetRecoilState(ChequeFraudAllBranchState);
	const setCheckFraudList = useSetRecoilState(CheckFraudListState);
	const setSessionSelected = useSetRecoilState(SelectedSessionState);
	const [checkFraudDetails, setCheckFraudDetails] = useRecoilState(
		CheckFraudDetailsState
	);
	const setComplexSessionInfoState = useSetRecoilState(ComplexSessionInfoState);

	const { successNotification, errorNotification } = useNotification();

	const setTransactinonsLists = useSetRecoilState(
		SelectedChequeFraudTransactionsListState
	);

	const { post, loading, get, isLoaded } = useNetwork();
	const { get: getBranches } = useNetwork();
	const { patch: reportFraud, loading: reportFraudLoading } = useNetwork();
	const { patch } = useNetwork();

	const { REPORT, TRANSACTIONS } = CHEQUE_FRAUD_MESSAGES;
	const submitCheckDetails = (cb: () => void) => {
		const { path = '' } = checkFraudDetails?.checkDetails ?? {};
		const {
			session,
			status,
			bankBranch,
			accountNumber,
			amount,
			checkNumber,
			routingNumber,
		} = checkFormDetails ?? {};

		const payload = {
			session,
			status,
			bankBranch,
			checkNumber: checkNumber?.toString() ?? '',
			accountNumber,
			routingNumber,
			amount: Number(amount ?? 0),
			path: path,
		};
		post(API_URL.CHEQUE_DEFENCE_TRANSACTION, payload).then(res => {
			if (res._id) {
				successNotification(TRANSACTIONS.SUCCESS);
				cb();
			} else {
				errorNotification(res.message ?? TRANSACTIONS.ERROR);
			}
		});
	};

	const fetchCheckFraudDetails = async (id: string) => {
		await get(`/${API_URL.CHEQUE_DEFENCE_SESSION}/${id}`).then(resp => {
			if (resp.data) {
				setCheckFraudDetails(resp.data);
				setComplexSessionInfoState((prev: any) => ({ ...prev, ...resp.data }));
				setTransactinonsLists(resp.data.transaction ?? []);
			}
		});
	};
	const fetchCheckFraudDetailsSession = async (id: string) => {
		const resp = await get(`${API_URL.COMPLEX_SESSION_DETAILS}/${id}`);
		const { response, apiPayload } = resp;
		if (response?.status === 200) {
			const { data } = apiPayload;
			setSessionSelected((prev: Record<string, never> | any) => ({
				...prev,
				...data,
			}));
		}
	};

	const fetchCheckFraudKyc = async (id: string) => {
		const resp = await get(
			`${API_URL.Session_Nodes}/${id}?nodeId=6582e1d9c5d22b4cd12cb8bd`
		);
		const { response, apiPayload } = resp;
		if (response?.status === 200) {
			const { data } = apiPayload;
			setSessionSelected((prev: Record<string, never> | any) => ({
				...prev,
				kyc: data?.kyc ?? [],
			}));
		}
	};

	const getCheckFraudList = async () => {
		await get(API_URL.CHEQUE_DEFENCE_SESSION).then(resp => {
			if (resp.data) {
				setCheckFraudList(resp.data);
			}
		});
	};

	const addNewBank = (cb?: () => void) => {
		const {
			name,
			streetAddress,
			branchNumber,
			country,
			city,
			state,
			zipCode,
			// status,
		} = checkFormDetails ?? {};
		const payload = {
			name,
			streetAddress,
			branchNumber,
			country,
			city,
			state,
			zipCode,
			// status,
		};
		post(API_URL.CHEQUE_DEFENCE_BANK, payload).then(resp => {
			if (resp._id) {
				setAllBracnhes((prev: any) => [...prev, resp]);
				successNotification('Submitted successfully');
				setCheckFormDetails({
					...checkFormDetails,
					name: '',
					streetAddress: '',
					branchNumber: '',
					country: '',
					city: '',
					state: '',
					zipCode: '',
				});
				cb?.();
			} else {
				errorNotification('Somthing went wrong');
			}
		});
	};

	const getAllBranchs = () => {
		getBranches(API_URL.CHEQUE_DEFENCE_BANK).then(resp => {
			if (resp.data) {
				setAllBracnhes(resp.data);
			}
		});
	};

	const reportCheckFraud = (
		nodeId: any,
		fraudMessage: string,
		cb: () => void
	) => {
		const payload = {
			fraudMessage: fraudMessage,
		};
		reportFraud(`${API_URL.CHEQUE_DEFENCE_SESSION}/${nodeId}`, payload).then(
			(resp: any) => {
				if (resp._id) {
					successNotification(REPORT.SUCCESS);
					cb();
				} else {
					errorNotification(resp.message ?? REPORT.ERROR);
				}
			}
		);
	};

	const unMarkCheckFraud = async (
		nodeId: string,
		fraudMessage: string,
		allDocuments: IDocs[],
		cb: () => void
	) => {
		const payload = {
			isFraud: false,
			revokeFraud: {
				reason: fraudMessage,
				revokeDocument: allDocuments,
			},
		};
		await patch(`${API_URL.CHEQUE_DEFENCE_SESSION}/${nodeId}`, payload).then(
			(resp: any) => {
				if (resp._id) {
					successNotification(REPORT.SUCCESS);
					cb();
				} else {
					errorNotification(resp.message ?? REPORT.ERROR);
				}
			}
		);
	};

	return {
		submitCheckDetails,
		loading,
		isLoaded,
		getCheckFraudList,
		fetchCheckFraudDetailsSession,
		fetchCheckFraudKyc,
		fetchCheckFraudDetails,
		addNewBank,
		getAllBranchs,
		reportCheckFraud,
		reportFraudLoading,
		unMarkCheckFraud,
	};
};
