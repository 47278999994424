import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

// import { ReactDropdown } from "@storybook";
import { DocumentsUploaded } from '../store';
import { DocumentsTypes } from './constants';
import { useOutsideClick } from 'hooks';
import { createRef, useState } from 'react';
import './style.scss';

const DropDown = ({ type, keyID, item }: any) => {
	const ref = createRef<HTMLDivElement>();
	const [showDropDown, setShowDropDown] = useState<boolean>(false);
	const [selectedType, setSelectedType] = useState(
		item?.type?.label ?? DocumentsTypes[0]?.label
	);
	const setAddedFiles = useSetRecoilState(DocumentsUploaded);
	const handleChange = useCallback(
		(value: any) => {
			setSelectedType(value.label);
			setAddedFiles(prev => {
				const preState = JSON.parse(JSON.stringify(prev));
				preState.data[keyID].type = value;
				return preState;
			});
		},
		[keyID, setAddedFiles]
	);

	useOutsideClick(ref, () => {
		setShowDropDown(false);
	});
	return (
		<div
			ref={ref}
			onClick={() => setShowDropDown(!showDropDown)}
			className={
				type === 'previously_uploaded' ? 'dropBox stop-event' : 'dropBox'
			}
			// style={{ borderColor : "red" , borderWidth :2}}
		>
			{item?.type?.label || selectedType}
			{showDropDown && (
				<div className="box">
					<div className="dropOption">
						{DocumentsTypes.map(item => (
							<li onClick={() => handleChange(item)} key={item.label}>
								{item.label}
							</li>
						))}
					</div>
				</div>
			)}
		</div>
		// <ReactDropdown
		//   Isdisable={type === "previously_uploaded"}
		//   controlStyle={controlStyle}
		//   optionsStyle={optionStyle}
		//   handleChangeSelect={handleChange}
		//   options={DocumentsTypes}
		//   value={item.type}
		// />
	);
};

export default DropDown;
