import type { ActivityActionType } from 'views/dashboard-analytics/stores';

import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { AnalyticsOverviewAtom } from 'views/dashboard-analytics/stores';
import { BarChart } from '../dashboard-chart';
import './analytics-overview.scss';
import { ChartTitleActivity } from './constants';

export const AnalyticsOverview = () => {
	const analyticsOnboardingData = useRecoilValue(AnalyticsOverviewAtom);
	const { loaded, data } = analyticsOnboardingData ?? {};

	const XAxisValue = useMemo(() => {
		return Object.keys(data?.[0] ?? {}).map(
			item => ChartTitleActivity?.[item] ?? item
		);
	}, [data]);

	const yAxisValue = useMemo(() => {
		return Object.keys(data?.[0] ?? {}).map(
			item => data?.[0]?.[item as ActivityActionType]?.total ?? 0
		);
	}, [data]);

	const totalRevenue = useMemo(() => {
		return Object.values(data?.[0] ?? {})
			.map(item => item?.amount)
			.reduce((a, b) => a + b, 0);
	}, [data]);

	return (
		<div className="analytics-overview">
			<BarChart
				isLoading={!loaded && !data?.length}
				title="Total Consumption"
				yAxis={yAxisValue}
				xAxis={XAxisValue}
				totalAmount={data?.[0]}
				revenue={totalRevenue || 0}
			/>
		</div>
	);
};
