import { Image } from "@storybook"

export const EmptySection = ({ message }: { message: string }) => {
    return(<div>
        	<div className="kyb-empty_data">
			<div className="kyb-empty_data__inner">
				<Image fileName="missing.svg" className="empty_data_img" height="100" />
				<div className="kyb-empty-data-txt">
					{message ?? 'No data is available'}
				</div>
			</div>
		</div>
    </div>)
}