import type { ISelectedService } from './types';

import { atom } from 'recoil';

export const ProcessingModelState = atom<boolean>({
	key: 'processing-model-key',
	default: false,
});

export const PaymentSuccessModalState = atom<boolean>({
	key: 'payment-success-modal-key',
	default: false,
});

export const PaymentCardModalState = atom<boolean>({
	key: 'payment-card-modal-key',
	default: false,
});

export const ServiceModalState = atom<boolean>({
	key: 'service-modal-key',
	default: false,
});

export const CompanyNameInputState = atom<boolean>({
	key: 'company-name-input-key',
	default: false,
});

export const CompanyAddressInputState = atom<{
	address1: boolean;
	zip: boolean;
	city: boolean;
	state: boolean;
}>({
	key: 'company-address-input-key',
	default: {
		address1: false,
		zip: false,
		city: false,
		state: false,
	},
});
export const CompanyInputState = atom<boolean>({
	key: 'company--input-key',
	default: false,
});

export const ModalTypeState = atom<string>({
	key: 'modal-type-key',
	default: 'company',
});

export const ActiveBodyType = atom<JSX.Element | null>({
	key: 'body-key',
	default: null,
});

export const IsShowButtonState = atom<boolean>({
	key: 'button-state',
	default: true,
});

export const HeaderState = atom<string>({
	key: 'header-state',
	default: 'Company Details',
});

export const CompanyValidInputState = atom<boolean>({
	key: 'company-valid-input-key',
	default: false,
});

export const SelectedServiceState = atom<ISelectedService>({
	key: 'selected-service-plan-name',
	default: 'PAID',
});
