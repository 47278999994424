import { FC, useMemo } from 'react';
import { UserAccountDetail } from './components/user-account-detail';
import { useRecoilValue } from 'recoil';
import { loginState } from 'global-stores';
import './account-details.scss';
import { capitalize } from 'utils';

interface IAccountDetails {
	accountType: string
}
export const AccountDetails: FC<IAccountDetails> = ({ accountType }) => {
	const userDetail = useRecoilValue(loginState);
	const { firstName, lastName, email, phone, countryCode, companyName = "" } = useMemo(
		() => userDetail ?? {},
		[userDetail]
	);

	return (
		<div className="AccountDetails--container">
			<UserAccountDetail
				label="Name"
				value={`${firstName ?? ''} ${lastName ?? ''}` ?? 'NA'}
			/>
			<UserAccountDetail
				label="Phone"
				value={`${countryCode ?? ''}  ${phone}` ?? 'N/A'}
			/>
			<UserAccountDetail label="Email" value={email || 'N/A'} />

			<UserAccountDetail
				label="Company name"
				value={companyName || "N/A"}
			/>
			<UserAccountDetail
				label="Account Type"
				value={capitalize(accountType) || "N/A"}
			/>
		</div>
	);
};
