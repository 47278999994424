import { FC, useCallback, useMemo } from 'react';
import { useResetRecoilState } from 'recoil';

import { Button, Image, Loader } from '@storybook';
import Modal from '@storybook/new-modal/modal';
import { SessionOutTimer, ShowWarningPopup } from './store';
import { useLogout } from 'hooks';

import './session-out.scss';

interface ISessionOutModal {
	isOpen: boolean;
	closeModal: () => void;
}

export const SessionOutModal: FC<ISessionOutModal> = ({
	isOpen,
	closeModal,
}) => {
	const resetTimer = useResetRecoilState(SessionOutTimer);
	const resetPopup = useResetRecoilState(ShowWarningPopup);
	const { logout, isLoaded } = useLogout();

	const handlePrimaryBtnCallback = useCallback(() => {
		resetPopup();
		resetTimer();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSecondaryBtnCallback = useCallback(() => {
		logout();
		resetTimer();
	}, [logout, resetTimer]);
	

	const secondaryButtonLabel = useMemo(() => {
		if (!isLoaded) {
			return 'Continue';
		}
		return <Loader type="loader" dimension={18} className="loader-blue" />;
	}, [isLoaded]);

	return (
		<Modal
			isOpen={isOpen}
			modalName={'session-out-modal'}
			closeModal={closeModal}
			showCloseBtn
			isStopOutsideClick={false}
			className="session-out-modal"
		>
			<div className="session-out">
				<div className="session-out__image">
					<Image fileName="Confirmation.svg" />
				</div>
				<div className="session-out__text">
					<p className="session-out__text__heading">Are you still here?</p>
					<p className="session-out__text__subheading">
						It seems you have been inactive for a while. Click Continue to stay
						logged in, or Log Out if you are done for now.
					</p>
				</div>
				<div className="session-out__btn">
					<Button
						label="Logout"
						type="button__filled--secondary"
						handleClick={handleSecondaryBtnCallback}
					/>
					<Button
						label={secondaryButtonLabel}
						type="button__filled--primary"
						handleClick={handlePrimaryBtnCallback}
					/>
				</div>
			</div>
		</Modal>
	);
};
