import { FC, useCallback, useMemo } from 'react';

import { getDate, getTime, useFormatNumber } from 'utils';
import { IBillingTransactions } from 'views/settings/stores';
import { Arrange_Order, paymentMethod, TransactionCardType } from '../constant';

interface ITransactionRow {
	transaction: IBillingTransactions;
}

export const BillingTransactionRow: FC<ITransactionRow> = ({ transaction }) => {
	const { numberDecimal } = useFormatNumber();

	const transactionValue = useCallback(
		(key: string) => {
			switch (key) {
				case 'time':
					return getDate(transaction[key]) + ' ' + getTime(transaction[key]);
				case 'amount':
					return `$${numberDecimal(Number(transaction[key]))}`;
				case 'status':
					return (
						<>
							<div className="transactions-table-data__status">
								<i
									className={`ri-checkbox-blank-circle-fill status-circle-${transaction[
										key
									].toLowerCase()}`}
								/>
								<span>{transaction[key]}</span>
							</div>
						</>
					);
				case 'card':
					return `${paymentMethod[transaction[key] as 'credit']}`;
				case 'transactionId':
					return (
						//Deepak : when we not get transaction id then it will show --
						<div className="transactions-table-data__transactionId">
							{transaction[key] ?? '--'}
						</div>
					);
				case 'type':
					return  paymentMethod[transaction['card'] as 'credit'].includes('card') ? TransactionCardType.SubscriptionCard : transaction[key as 'type'];
				default:
					return transaction[key as 'time'];
			}
		}, 
		[numberDecimal, transaction]
	);

	const renderTransaction = useMemo(
		() =>
			Object.keys(transaction)
				.map(
					key =>
						key !== 'businessName' &&
						key !== 'paymentId' && (
							<td key={key} className="transactions-table-data">
								<div className="transactions-table-center">
									{transactionValue(key)}
								</div>
							</td>
						)
				)
				.sort((a, b) => {
					const first = Arrange_Order[(a as any)?.key] ?? 0;
					const second = Arrange_Order[(b as any)?.key] ?? 0;
					if (first < second) return -1;
					if (first > second) return 1;
					else return 0;
				}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[transaction, transactionValue]
	);

	return <>{renderTransaction}</>;
};
