import { FC, useCallback, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import Modal from '@storybook/new-modal/modal';
import { ChooseUserIdentity, UserIdentityFlow } from 'views/user-identity-flow';
import { FreeCreditsRemain, WelcomeUser } from './components';
import { FREE_CREDITS_REMAINING } from './constants';
import { IsSubscriptionModal, WelcomeNewUserModalState } from './stores';
import { useFreePlan } from 'hooks';
import './new-user-popup.scss';
import {
	BusinessRegistrationState,
	IBusinessRegistrationState,
} from 'global-stores';

interface INewUserPopup {
	handleCaptableClose?: () => void;
}

const {
	WITH_NOT_REMAINING,
	WITH_REMAINING,
	SIGN_SUBSCRIPTION,
	SIGN_SUBSCRIPTION_WITH_KYC,
} = FREE_CREDITS_REMAINING;

export const NewUserPopup: FC<INewUserPopup> = ({
	handleCaptableClose = () => ({}),
}) => {
	// global state
	const [welcomeNewUser, setWelcomeNewUser] = useRecoilState(
		WelcomeNewUserModalState
	);
	const [isUserIdentityOpen, setIsUserIdentityOpen] = useState(false);
	const [checked, setChecked] = useState(true);
	const [isOpenModal, setOpenModal] = useRecoilState(IsSubscriptionModal);
	const setBusinessRegistration = useSetRecoilState(BusinessRegistrationState);
	const chooseActiveIdx = useRecoilValue(ChooseUserIdentity);
	const {
		open: isModalOpen,
		type,
		serviceName,
	} = useMemo(() => welcomeNewUser ?? {}, [welcomeNewUser]);
	const { haveKybKyc = false, haveKyc } = useFreePlan();

	const onGetStarted = useCallback(() => {
		setOpenModal(true);
		setBusinessRegistration((prev: IBusinessRegistrationState) => ({
			...prev,
			accountType: chooseActiveIdx,
		}));
		setWelcomeNewUser(pre => ({ ...pre, open: false }));
	}, [
		chooseActiveIdx,
		setBusinessRegistration,
		setOpenModal,
		setWelcomeNewUser,
	]);

	const handleCloseSubsModal = () => {
		setOpenModal(false);
		setWelcomeNewUser({ open: true, type: 'Welcome_User' });
	};

	const handleCloseModal = useCallback(() => {
		setIsUserIdentityOpen(false);
	}, []);

	const renderModalBody = useMemo(() => {
		switch (type) {
			case 'Welcome_User':
				return (
					<WelcomeUser
						checked={checked}
						onGetStarted={onGetStarted}
						setChecked={setChecked}
					/>
				);
			case 'Credits_Remain': {
				const isEsignWithKyc =
					serviceName === 'esign' && haveKyc && !haveKybKyc;
				const hasKybKyc = haveKybKyc;

				const title = isEsignWithKyc
					? SIGN_SUBSCRIPTION_WITH_KYC.title + (serviceName || 'this service')
					: hasKybKyc
					? WITH_NOT_REMAINING.title + (serviceName || 'this service')
					: SIGN_SUBSCRIPTION.title;

				const subTitle = isEsignWithKyc
					? SIGN_SUBSCRIPTION_WITH_KYC.subTitle
					: hasKybKyc
					? WITH_NOT_REMAINING.subTitle
					: SIGN_SUBSCRIPTION.subTitle;

				return (
					<FreeCreditsRemain
						title={title}
						subTitle={subTitle}
						handleCaptableClose={handleCaptableClose}
						setIsUserIdentityOpen={setIsUserIdentityOpen}
					/>
				);
			}
			case 'Credits_Remain_With_Remaining':
				return (
					<FreeCreditsRemain
						title={WITH_REMAINING.title}
						subTitle={WITH_REMAINING.title}
						handleCaptableClose={handleCaptableClose}
						setIsUserIdentityOpen={setIsUserIdentityOpen}
					/>
				);
			default:
				return <></>;
		}
	}, [
		type,
		checked,
		onGetStarted,
		haveKybKyc,
		serviceName,
		handleCaptableClose,
		haveKyc,
	]);

	const showCloseBtn = useMemo(() => {
		if (['Welcome_User'].includes(type)) {
			return true;
		} else return false;
	}, [type]);

	return (
		<>
			{!isUserIdentityOpen ? (
				<Modal
					isOpen={isModalOpen}
					modalName="New user popup modal"
					closeModal={() =>
						setWelcomeNewUser({ open: false, type, serviceName })
					}
					className="new-user-popup-modal"
					showCloseBtn={!showCloseBtn}
					isStopOutsideClick={false}
					closeOnEscBtn={false}
				>
					{renderModalBody}
				</Modal>
			) : (
				<UserIdentityFlow
					isOpen={isUserIdentityOpen}
					handleCloseModal={handleCloseModal}
				/>
			)}

			{isOpenModal && (
				<UserIdentityFlow
					isOpen={isOpenModal}
					handleCloseModal={handleCloseSubsModal}
				/>
			)}
		</>
	);
};
