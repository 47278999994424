import {
	CardNumberElement,
	useElements,
	useStripe,
} from '@stripe/react-stripe-js';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { API_URL } from 'constant';
import { useNetwork, useNotification } from 'hooks';
import {
	AddCardModalState,
	PaymentNavigationState,
} from 'views/settings/billing/stores';
import {
	IndetityFlowNavigate,
	SubscriptionIdentityNaviagtion,
	useDefaultCard,
} from 'views/user-identity-flow';
import { PaymentNavigation } from '../../constant';

interface IComplete {
	cardNumber: boolean;
	cvv: boolean;
	expiryDate: boolean;
}

type UseAddCard = {
	from?: 'SIGNUP' | 'HOME';
};
export const useAddCard = ({ from }: UseAddCard) => {
	const setAddPaymentNaviagtion = useSetRecoilState(PaymentNavigationState);
	const { isEdit } = useRecoilValue(AddCardModalState);
	const setNavigate = useSetRecoilState(SubscriptionIdentityNaviagtion);
	const stripe = useStripe();
	const elements = useElements();
	const { successNotification, errorNotification } = useNotification();
	const { post: saveCard } = useNetwork({ returnResponse: true });

	const [isLoader, setIsLoader] = useState(false);
	const [defaultCheck, setDefaultCheck] = useState(false);

	const [complete, setComplete] = useState<IComplete>({
		cardNumber: false,
		cvv: false,
		expiryDate: false,
	});

	const handleChangeCard = useCallback((isComplete: boolean, name: string) => {
		setComplete(prev => ({ ...prev, [name]: isComplete }));
	}, []);

	const manageBtnDisabled = useMemo((): boolean => {
		const { cardNumber, cvv, expiryDate } = complete;
		if (cardNumber && cvv && expiryDate && stripe) {
			return false;
		}
		return true;
	}, [complete, stripe]);

	const options = useMemo(
		() => ({
			style: {
				base: {
					color: '#424770',
					letterSpacing: '0.025em',
					fontFamily: 'Source Code Pro, monospace',
					'::placeholder': {
						color: '#aab7c4',
					},
				},
				invalid: {
					color: 'var(--color-danger-dark)',
				},
			},
		}),
		[]
	);
	const { isDefaultExist } = useDefaultCard();

	// only first time mount
	useEffect(() => {
		if (!isDefaultExist) {
			setDefaultCheck(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDefaultExist]);

	const handleCloseViewModal = () => {
		if (isDefaultExist) {
			if (isEdit) setAddPaymentNaviagtion(PaymentNavigation.CardView);
			else setNavigate(IndetityFlowNavigate.CardView);
			return;
		}

		if (setNavigate && from === 'SIGNUP') {
			setNavigate(IndetityFlowNavigate.ChooseMethod);
			return;
		}

		setAddPaymentNaviagtion(PaymentNavigation.ChoosePage);
	};

	const onSubmitCard = async (event: React.FormEvent) => {
		event.preventDefault();
		if (!stripe || !elements) {
			return;
		}

		setIsLoader(true);
		const tokenDetails: any = await stripe.createToken(
			elements.getElement(CardNumberElement) as any
		);

		if (tokenDetails.error) {
			setIsLoader(false);
			return errorNotification(tokenDetails.error.message);
		}
		if (!tokenDetails) {
			return errorNotification('Invalid Card');
		}

		saveCard(API_URL.BUSINESS_CARD, {
			token: tokenDetails.token.id,
			default: defaultCheck,
		}).then(resp => {
			const { errorCode, errorData } = resp;
			if (errorCode) {
				errorNotification(errorData.message);
				setIsLoader(false);
				return;
			}
			if (resp.id) {
				successNotification('Card Saved Successfully!');
				if (setNavigate && from === 'SIGNUP')
					setNavigate(IndetityFlowNavigate.SubscriptionReview);
				else setAddPaymentNaviagtion(PaymentNavigation.Processing);
			}
			setIsLoader(false);
		});

		return null;
	};

	const handleChangeDefaultCheck = (e: ChangeEvent<HTMLInputElement>) => {
		if (!isDefaultExist) return;
		setDefaultCheck(e.target.checked);
	};

	const cvvConfigOptions = {
		...options,
		placeholder: '...',
	};

	return {
		onSubmitCard,
		handleChangeCard,
		cvvConfigOptions,
		handleChangeDefaultCheck,
		defaultCheck,
		handleCloseViewModal,
		isLoader,
		manageBtnDisabled,
	};
};
