export const survayFormOptions = {
	showLogicTab: true,
	showJSONEditorTab: false,
	showEmbeddedSurveyTab: false,
	isAutoSave: false,
	showSurveyTitle: false,
	showTitlesInExpressions: false,
};

export const surveyJSON = {
	title: '',
	description: '',
	logoPosition: 'right',
	showQuestionNumbers: 'off',
};
