export const countDecimalPlaces = (num: number) => {
	const decimalPart = String(num).split('.')[1];
	return decimalPart ? decimalPart.length : 0;
};

export const addWithDynamicPrecision = (num1: number, num2: number) => {
	const decimalPlaces = Math.max(
		countDecimalPlaces(num1),
		countDecimalPlaces(num2)
	);
	const multiplier = Math.pow(10, decimalPlaces);
	const result = (num1 + num2) * multiplier;
	return Math.round(result) / multiplier;
};