import type { IDateRangePicker as Props } from './type';

import { useCallback, MouseEvent } from 'react';
import { useLocation } from 'react-router-dom';
import { DateRange, DefinedRange, RangeKeyDict } from 'react-date-range';
import { Button } from '@storybook';

import { defaultStaticRanges } from './constants';

import './date-range-picker.scss';
import { createdAt as dateCreated } from 'constant';
import { useRecoilValue } from 'recoil';
import { loginState } from 'global-stores';

/**
 * DateRangePicker component
 *
 * @param {() => void} Props.handleSubmit - Callback function triggered when the date range is submitted
 * @param {RangeKeyDict} Props.range - The date range
 * @param {(item: RangeKeyDict) => void} Props.handleChangeRange - Callback function triggered when the date range is changed
 * @param {string[]} Props.rangeColor - The color for the date range
 * @param {(isVisible: boolean) => void} Props.setIsVisible - Callback function to set the visibility of the date range picker
 */

export const DateRangePicker = ({
	handleSubmit,
	range,
	handleChangeRange,
	rangeColor,
	setIsVisible,
}: Props) => {
	const location = useLocation();
	const login = useRecoilValue(loginState);
	const { createdAt } = login ?? {};
	// This is the variale for storing the createAt for range, when user click on all
	const date = new Date(createdAt ?? '');

	const handleClose = useCallback(
		(e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
			e.stopPropagation();
			setIsVisible(false);
		},
		[setIsVisible]
	);

	return (
		<>
			<div className="date-range-picker-overlay" onClick={handleClose} />
			<div
				className={`${
					location?.pathname === '/onboarding' ||
					location?.pathname === '/credentials'
						? 'date-range-picker-onBoarding'
						: 'date-range-picker'
				} ${
					location?.pathname === '/analytics' && 'date-range-picker-analytics'
				} `}
			>
				<DateRange
					className="calender"
					editableDateInputs={true}
					onChange={(item: RangeKeyDict) => handleChangeRange(item)}
					moveRangeOnFirstSelection={false}
					ranges={range}
					showMonthAndYearPickers={false}
					rangeColors={rangeColor}
					retainEndDateOnFirstSelection={false}
					fixedHeight={false}
					maxDate={new Date()}
					showDateDisplay={false}
					showPreview={true}
				/>
				<div className="overlay-container">
					<DefinedRange
						className="DefinedRange--container"
						onChange={(item: RangeKeyDict) => handleChangeRange(item)}
						ranges={range}
						rangeColors={rangeColor}
						inputRanges={[]}
						staticRanges={defaultStaticRanges(
							createdAt ? date.toString() : dateCreated
						)}
					/>
					<Button
						label="Apply"
						handleClick={handleSubmit}
						type="button__filled button__filled--primary button__large"
					/>
				</div>
			</div>
		</>
	);
};
