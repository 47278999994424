import { atom } from 'recoil';
import { IAddedCard } from './type';

// TODO @Manish please add the types in all the files

export const invoicesState = atom<any>({
	key: 'invoices-state',
	default: [],
});

export const AddCardViewState = atom<boolean>({
	key: 'add-card-view-state',
	default: false,
});

export const CardsListViewState = atom<IAddedCard[]>({
	key: 'cards-list-view-state',
	default: [],
});

export const SetDefaultCardState = atom<boolean>({
	key: 'set-default-card-state',
	default: false,
});

export const BusinessPaymentCardState = atom<any>({
	key: 'business-payment-card-state',
	default: null,
});

export const BillingDetailsLoaderState = atom<boolean>({
	key: 'billing-details-loading-state',
	default: false,
});

export const UserSubscriptionsState = atom<any>({
	key: 'user-subscriptions-taken',
	default: [],
});
export const UpdateSubscriptionState = atom<boolean>({
	key: 'UpdateSubscription',
	default: false,
});

export const UpdateLoginState = atom<boolean>({
	key: 'update-login-state',
	default: false,
});
