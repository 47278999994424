import { ReactDropdown } from '@storybook';
import {
	AddCapTableMapKeyAndValue,
	RequiredField,
} from '@storybook/custom-captable/states';
import {
	Dispatch,
	FC,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { ConfigFieldVisibleState } from 'global-stores';
import { getLuckSheetData } from 'utils';

interface IOptions {
	label: string;
	value: string;
}

interface IProps {
	label: string | any;
	isRequired: boolean;
	options: IOptions[];
	isOptional: boolean;
	isReset?: boolean;
	setIsReset?: Dispatch<SetStateAction<boolean>>;
}

export const ConfigRow: FC<IProps> = ({
	isRequired,
	label,
	options,
	isOptional,
	isReset,
	setIsReset,
}) => {
	const [selectedItem, setSelectedItem] = useState<any>(null);
	const setSelectedValue = useSetRecoilState(AddCapTableMapKeyAndValue);
	const [configVisble, setConfigVisible] = useRecoilState(
		ConfigFieldVisibleState
	);
	const setRequiredField = useSetRecoilState(RequiredField);

	useEffect(() => {
		if (isReset) {
			setSelectedItem(null);
			setSelectedValue([]);
			setConfigVisible({
				'First Name': true,
				Email: true,
				'Country Code': true,
				Mobile: true,
				'Dollars Invested': true,
				'No. of Shares': true,
				'Price per Share': true,
				'Issue Date': true,
			});
			if (setIsReset) {
				setIsReset(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isReset]);

	const handleChangeSelect = useCallback(
		(value: IOptions, label: string) => {
			if (isReset && setIsReset) {
				setIsReset(false);
			}
			setRequiredField(prev => prev.filter(el => el !== label));
			const index = options.findIndex(x => x.label === value.label);
			const { headers = [] } = getLuckSheetData();
			headers.splice(index, 1, label);
			setSelectedItem(value);
			setSelectedValue(headers);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isReset, label, options]
	);

	useEffect(() => {
		const { expectedArray = [] } = getLuckSheetData();
		let value: any;
		if (label === 'Dollars Invested') {
			value = expectedArray[0].includes('Dollars Invested')
				? { label: 'Dollars Invested', value: 'Dollars Invested' }
				: { label: null, value: null };
		} else if (label === 'Phone') {
			value = expectedArray[0].includes('Mobile')
				? { label: 'Mobile', value: 'Mobile' }
				: { label: null, value: null };
		} else {
			value = expectedArray[0].includes(label)
				? { label, value: label }
				: { label: null, value: null };
		}
		if (value?.value === null) {
			setConfigVisible(prev => {
				return { ...prev, [label]: true };
			});
			setSelectedItem(null);
		} else {
			setConfigVisible(prev => {
				return { ...prev, [label]: false };
			});

			setSelectedItem(value);
		}
		const { headers = [] } = getLuckSheetData();
		setSelectedValue(headers);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [label, isRequired]);

	useEffect(() => {
		const filtered = Object.keys(configVisble).filter(function (key) {
			return configVisble[key];
		});
		setRequiredField(filtered);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [configVisble, isReset]);

	const renderCongigRows = useMemo(() => {
		return (
			<>
				{configVisble[label] ? (
					<div className="captable-config__row captable-rows">
						<div className="captable-config__row-label">
							<span className="captable-config__row-label-text">
								{label}
								{isOptional && <span> (Optional)</span>}
							</span>
							{isRequired && (
								<span className="captable-config__row-label-required">*</span>
							)}
						</div>
						<div className="captable-config__row-dropdown-wrapper">
							{options && (
								<ReactDropdown
									options={options}
									optionsStyle={{
										'&:hover': { background: 'var(--color-hover-light-100)' },
									}}
									handleChangeSelect={e => handleChangeSelect(e, label)}
									value={selectedItem}
									isSearchable
								/>
							)}
						</div>
					</div>
				) : (
					<></>
				)}
			</>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [configVisble, isOptional, isRequired, label, selectedItem, options]);

	return <>{renderCongigRows}</>;
};
