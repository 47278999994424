import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { IframePropsState, PipelineMasterSteps } from 'global-stores';
import { PipelineBody } from 'views/pipeline';
import { CurrentStepState } from '../../store';

export const Publish = () => {
	const { background } = useRecoilValue(IframePropsState);
	const masterSteps = useRecoilValue(PipelineMasterSteps);
	const setCurrentStep = useSetRecoilState(CurrentStepState);

	useEffect(() => {
		const publish: any = masterSteps.find(method => method.key === 'publish');
		if (publish) {
			const publishStep = publish.actions.find(
				(step: any) => step.key === 'publishOnboarding'
			);
			setCurrentStep(publishStep);
		}
	}, [masterSteps, setCurrentStep]);

	return (
		<div
			className="onboarding-modal-height"
			style={{ backgroundColor: background }}
		>
			<PipelineBody />
		</div>
	);
};
