import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { Button, Image, Input, Loader } from '@storybook';
import {
	AddBankAccountLoadingState,
	AmountToDepositeToEscrowState,
	EscrowModalBodyNavigatorState,
	SelectedBankSubItemState,
	SeletedBankItemState,
} from 'views/fund-escrow/stotes';
import { getLogos } from 'views/pipelines/components/fund-investment/constants';
import './deposit-fund.scss';

export const DepositFund = () => {
	//globle states
	const selectedBankItem = useRecoilValue(SeletedBankItemState);
	const selectedSubItem = useRecoilValue(SelectedBankSubItemState);
	const isLoading = useRecoilValue(AddBankAccountLoadingState);
	const setNavigate = useSetRecoilState(EscrowModalBodyNavigatorState);
	const [amountToDeposite, setAmoutToDeposite] = useRecoilState(
		AmountToDepositeToEscrowState
	);

	//local states
	const [isError, setIsError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	useEffect(() => {
		if (amountToDeposite.trim() === '' || Number(amountToDeposite) <= 0) {
			setAmoutToDeposite('100.00');
		}
		// eslint-disable-next-line
	}, []);

	const handleBankChange = useCallback(() => {
		setNavigate('change_bank');
	}, [setNavigate]);

	const handleDeposit = useCallback(() => {
		setNavigate('verifying_details');
	}, [setNavigate]);

	const handleBack = useCallback(() => {
		setNavigate('show_details');
	}, [setNavigate]);

	const handleDepositAmount = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const { value } = e.target;
			setAmoutToDeposite(value);
			if (value.trim() === '' || Number(value) <= 0) {
				setIsError(true);
				setErrorMessage('Please enter a valid deposit amount');
				return;
			}
			setIsError(false);
			setErrorMessage('');
		},
		[setAmoutToDeposite]
	);

	const isDisable = useMemo(() => {
		return amountToDeposite.trim() === '' || Number(amountToDeposite) <= 0;
	}, [amountToDeposite]);

	return (
		<div className="deposit-fund-wrapper">
			<div className="deposit-fund-header">
				<div className="deposit-fund-header__title">Deposit Fund</div>
				<div className="deposit-fund-header__desc">Some description here</div>
			</div>
			<div className="deposit-fund-body">
				<div className="deposit-fund-input-amount">
					<Input
						label="Amount to Deposit"
						value={amountToDeposite}
						inputType="number"
						handleChange={handleDepositAmount}
						placeholder={'Amount to Deposit'}
						isError={isError}
						errorMessage={errorMessage}
					/>
				</div>
				{isLoading ? (
					<div className="loader-wrapper">
						<Loader type="gif" />
					</div>
				) : (
					<div className="deposit-fund-from">
						<div className="deposit-fund-from__title">Deposit From</div>
						<div className="deposit-fund-from__bank-list">
							<div className="bank-card">
								<div className="bank-card__icon-wrapper">
									<Image
										fileName={`${
											getLogos[selectedBankItem?.bankName] ??
											getLogos['default']
										}`}
									/>
								</div>
								<div className="bank-card__bank-title-wrapper">
									<div className="bank-card__bank-title-wrapper__bank-name">
										{selectedBankItem?.bankName}
									</div>
									<div className="bank-card__bank-title-wrapper__account-type">
										{`${selectedSubItem?.subtype}`}{' '}
										(&#8226;&#8226;&#8226;&#8226;
										{selectedSubItem?.mask})
									</div>
								</div>
								<div
									onClick={handleBankChange}
									className="bank-card__change-bank"
								>
									Change
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
			<div className="deposit-fund-footer">
				<div className="deposit-fund-footer__btns">
					<Button
						label="Back"
						handleClick={handleBack}
						type="button__filled button__filled--secondary"
					/>
					<Button
						label="Deposit"
						handleClick={handleDeposit}
						type="button__filled button__filled--primary"
						disabled={isLoading || isDisable}
					/>
				</div>
			</div>
		</div>
	);
};
