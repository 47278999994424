import { useMemo } from 'react';
import { Image } from '@storybook';

import { DOMAIN_EXAMPLES, OWNER_STEPS, WILDCARD_EXAMPLES } from './constants';

import './unauthorized-domain.scss';

export const UnauthorizedDomain = () => {
	const whatToDo = useMemo(
		() => (
			<div className="UnauthorizedDomain__todo">
				<div className="UnauthorizedDomain__todo--title">What to do:</div>
				<div className="UnauthorizedDomain__todo--subTitle">
					Please contact the account administrator and request them to add the
					website to the whitelist.
				</div>
			</div>
		),
		[]
	);

	const needHelp = useMemo(
		() => (
			<div className="UnauthorizedDomain__need-help">
				Need help? Check out our tutorial for a step-by-step guide.
			</div>
		),
		[]
	);

	const ownerSteps = useMemo(
		() => (
			<div className="UnauthorizedDomain__owner">
				<div className="UnauthorizedDomain__ownerTitle">
					Owners, here&lsquo;s how you can add your domain:
				</div>
				{OWNER_STEPS.map((step, index) => {
					if (step.includes(`"`)) {
						const stepArr = step.split(`"`);
						return (
							<div
								className="UnauthorizedDomain__ownerStep"
								key={`${step}__${index.toString()}`}
							>
								{stepArr[0]}
								<span className="UnauthorizedDomain__ownerStep--bold">
									&ldquo;{stepArr[1] ?? ''}&ldquo;
								</span>
								{stepArr[2] ?? ''}
							</div>
						);
					} else
						return <div className="UnauthorizedDomain__ownerStep">{step}</div>;
				})}
			</div>
		),
		[]
	);

	// eslint-disable-next-line no-console
	console.log(window.location);
	

	const examples = useMemo(
		() => (
			<div className="UnauthorizedDomain__examples">
				<div className="UnauthorizedDomain__exampleTitle">Example:</div>
				{DOMAIN_EXAMPLES.map(example => (
					<div className="UnauthorizedDomain__example" key={example}>
						{example}
					</div>
				))}
			</div>
		),
		[]
	);

	const wildcard = useMemo(
		() => (
			<div className="UnauthorizedDomain__wildcard">
				<div className="UnauthorizedDomain__wildcard--title">
					for wildcard domain
				</div>
				{WILDCARD_EXAMPLES.map(
					wildcard => (
						<div
							className="UnauthorizedDomain__wildcard--subTitle"
							key={wildcard}
						>
							{wildcard}
						</div>
					),
					[]
				)}
			</div>
		),
		[]
	);

	const renderMainComponent = useMemo(
		() => (
			<div className="UnauthorizedDomain__wrapper">
				<div className="UnauthorizedDomain__body">
					<Image
						fileName="missing.svg"
						className="UnauthorizedDomain__missing"
					/>
					<div className="UnauthorizedDomain__oops">
						Oops! It seems like the website you are trying to access is not
						whitelisted.
					</div>
					{whatToDo}
					{needHelp}
					{ownerSteps}
					{examples}
					{wildcard}
				</div>
			</div>
		),
		[examples, needHelp, ownerSteps, whatToDo, wildcard]
	);

	return renderMainComponent;
};
