import { FC, useMemo } from 'react';
import { ActionButton } from '../action';
import { useFormatNumber } from 'utils';
import { ReportDetails } from '../reports';
import { SessionDetailsHeading } from '../session-details-heading';
import { IFundInvestment } from 'global-stores/sessions/type';
import { NoData } from '../no-data-available';
import { SessionDetailsSkeletonLoader } from 'views/sessions/session-details-loader';
import { RejectionDetails } from '../kyb-wrapper/components/rejection-details/rejection-details';

interface IKycDetailsWrapper {
	role: string;
	isAgentPermitted: boolean;
	fundInvestment: IFundInvestment;
	isLoading: boolean;
	isLoaded?: boolean;
	nodeId?: string;
	isUserPermissionWrite?:boolean
}
export const FundInvestmentDetails: FC<IKycDetailsWrapper> = ({
	isAgentPermitted,
	role,
	fundInvestment,
	isLoading,
	isLoaded,
	nodeId,
	isUserPermissionWrite=true,
}) => {
	const { numberDecimal } = useFormatNumber();
	const isErrorFundInvestment = useMemo(
		() =>
			(fundInvestment?.error &&
				fundInvestment?.error.length > 0 &&
				fundInvestment?.error[0]) ??
			'',
		[fundInvestment?.error]
	);

	const isDisableActionDropDown = useMemo(() => {
	return (
		fundInvestment?.status === 'pending' &&
		fundInvestment?.error &&
		fundInvestment?.error?.length > 0
	);
}, [fundInvestment]);

	return (
		<div className="kycDetails-contain">
			<SessionDetailsHeading
				label="Fund Account"
				icon={
					<i className="ri-profile-line form-icon-profile-container__formIcon"></i>
				}
			>
				{(fundInvestment?.transactions?.length > 0 ||
					isDisableActionDropDown) && (
					<div className="action-btn">
						{role === 'AGENT' && !isAgentPermitted ? (
							<div className="action-btn__agent">{fundInvestment?.status}</div>
						) : (
							<ActionButton
								status={
									isDisableActionDropDown
										? 'onhold'
										: fundInvestment?.status ?? ''
								}
								type="fundInvestment"
								isLoading={isLoading}
								nodeId={nodeId}
								isDisabled={!isUserPermissionWrite || isDisableActionDropDown}
							/>
						)}
					</div>
				)}
			</SessionDetailsHeading>

			{!isLoading && isLoaded ? (
				<>
					{isErrorFundInvestment ? (
						<div className="kycDetails-body">
							{fundInvestment?.error && fundInvestment?.error.length > 0 && (
								<div className="fundInvestment-error">
									<RejectionDetails data={fundInvestment.error} isError={false} />
								</div>
							)}
							<div className="kycDetail-row">
								<div className="report-label">Investment Amount</div>
								<div className="">:</div>
								<p>
									${numberDecimal(fundInvestment?.totalInvestmentAmount ?? 0)}
								</p>
							</div>
							<div className="kycDetail-row">
								<div className="report-label">Status</div>
								<div className="">:</div>
								<p>On Hold</p>
							</div>
							<div className="kycDetail-row">
								<div className="report-label">Remark</div>
								<div className="">:</div>
								<p>{isErrorFundInvestment}</p>
							</div>
						</div>
					) : Object.keys(fundInvestment ?? {}).length > 0 ? (
						<ReportDetails
							fundInvestment={fundInvestment}
						/>
					) : (
						<>
							<NoData />
						</>
					)}
				</>
			) : (
				<SessionDetailsSkeletonLoader />
			)}
		</div>
	);
};
