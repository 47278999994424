import {
	ChangeEvent,
	useCallback,
	DragEvent,
	useState,
	FC,
	useMemo,
} from 'react';
import { Image, ProgressBar } from '@storybook';

import './upload-docs.scss';

interface IUploadDocs {
	label?: string;
	onError?: (e: any) => void;
	handleChange: (e: File) => void;
	isShowProgess?: boolean;
	accept?: string;
	allowedTypes?: string[];
	description?: string;
	uplaodedFile?: string;
	handleRemove?: () => void;
	supportedFileDescription?: string;
}
export const UploadDocs: FC<IUploadDocs> = ({
	label,
	onError,
	handleChange,
	isShowProgess = false,
	accept = '',
	allowedTypes = [],
	description = '',
	uplaodedFile = '',
	handleRemove,
	supportedFileDescription = '',
}) => {
	const [uploadPercentage, setUploadPercentage] = useState(0);

	const handleFileUpload = useCallback(
		async (
			e: ChangeEvent<HTMLInputElement> | File,
			type?: 'drag' | 'browse'
		) => {
			setUploadPercentage(0);

			const interval = setInterval(() => {
				setUploadPercentage(prev => {
					if (prev < 50) {
						return prev + 5;
					} else {
						clearInterval(interval); // Clear the interval when progress reaches 50
						return prev;
					}
				});
			}, 100);

			const file: File =
				(type === 'drag'
					? (e as File)
					: (e as ChangeEvent<HTMLInputElement>).target.files?.[0]) ??
				({} as File);

			try {
				if (file && file.size > 10000000) {
					throw new Error('Max allowed file size exceeded');
				} else if (
					file &&
					allowedTypes.length &&
					!allowedTypes.includes(file.type)
				) {
					throw new Error('Unsupported file format');
				} else if (file) {
					await handleChange(file);
				}
			} catch (error) {
				onError?.({
					fileSize: file.size,
					fileType: file.type,
					message: error ?? 'something went wrong',
				});
			} finally {
				clearInterval(interval); // Clear the interval after the handleChange finishes
				const interval50 = setInterval(() => {
					setUploadPercentage(prev => {
						if (prev <= 100) {
							return prev + 10;
						} else {
							clearInterval(interval50); // Clear the interval when progress reaches 50
							return prev;
						}
					});
				}, 10);
			}

			if (type !== 'drag') {
				(e as ChangeEvent<HTMLInputElement>).target.value = '';
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	/**
	 * @Avinash here we are avoiding the drag over effect when ever we are uploading any document using the drag and upload method
	 * */
	const handleDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
	}, []);

	/**
	 * @Avinash here we are writting the drag and upload function so that user can drop the file to upload the document
	 * */
	const handleDrop = useCallback(
		(e: DragEvent<HTMLDivElement>) => {
			e.preventDefault();
			e.stopPropagation();
			const files = Array.from(e.dataTransfer.files);
			handleFileUpload(files?.[0] as File, 'drag');
		},
		[handleFileUpload]
	);

	const renderComponent = useMemo(() => {
		if (uplaodedFile) {
			return (
				<div className="bf-wrapper__label-box bf-wrapper__label-box-height">
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							gap: 8,
						}}
					>
						<Image url={uplaodedFile} className="uploaded-image-E34dre" />

						<div className="uploaded-image-actions">
							<div
								className="uploaded-image-actions__remove"
								onClick={handleRemove}
							>
								Remove
							</div>

							<label htmlFor="uploaded-image-actions__input">
								<div className="uploaded-image-actions__replace">Replace</div>
								<input
									multiple={false}
									accept={accept}
									id="uploaded-image-actions__input"
									type="file"
									onChange={handleFileUpload}
									className="bf-wrapper__input"
								/>
							</label>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<label
					htmlFor="files-documents-bf-wrapper__input"
					className="bf-wrapper__label-box bf-wrapper__label-box-height"
				>
					<i className="ri-file-upload-line bf-wrapper__logo" />
					<div className="bf-wrapper__label-text">
						<span className="bf-wrapper__light-text">
							Drag and drop files or{' '}
							<span style={{ color: '#3c65d6' }}>Browse file</span>
						</span>
					</div>
					<div className="bf-wrapper__description">
						Supported file format:{' '}
						{`${supportedFileDescription || 'jpg, jpeg, png, pdf and docx'}`}
					</div>
					<div className="bf-wrapper__description">
						Maximum upload file size: 10 MB.
					</div>
					<input
						multiple={false}
						accept={accept}
						id="files-documents-bf-wrapper__input"
						type="file"
						onChange={handleFileUpload}
						className="bf-wrapper__input"
					/>
				</label>
			);
		}
	}, [
		accept,
		handleFileUpload,
		handleRemove,
		supportedFileDescription,
		uplaodedFile,
	]);

	return (
		<div className="bf-wrapper">
			<div className="bf-wrapper__inner">
				{label && <div className="bf-wrapper__label">{label}</div>}
				{description && (
					<div className="bf-wrapper__description">{description}</div>
				)}
				<div
					className="bf-wrapper"
					onDragOver={handleDragOver}
					onDrop={handleDrop}
				>
					{renderComponent}
				</div>

				{isShowProgess && uploadPercentage > 0 && uploadPercentage < 100 && (
					<ProgressBar
						completed={uploadPercentage ?? 0}
						bgColor={'blue'}
						labelClassName="label"
						customClass="avg-confidence-progress"
						height="12px"
						customLabel={`uploading`}
						labelAlignment="outside"
					/>
				)}
			</div>
		</div>
	);
};
