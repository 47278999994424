import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useResetRecoilState } from 'recoil';

import { Button } from '@storybook';
import { SuccessIcon } from 'components';
import { useDashboards } from 'views/dashboard-analytics';
import {
	SelectedComplexSignAgreementStep,
	SignAgreementComplexStepConfigured,
} from 'views/multi-sign-agreement';
import {
	ComplexConfigStep,
	ComplexConfigurationInvite,
	SignOptionState,
	userCapTableState,
} from '../../../store';
import { useResetInvitationFlow } from '../../store';

export const PaymentSuccess = () => {
	const resetCapTableData = useResetRecoilState(userCapTableState);
	const resetComplexConfiguration = useResetRecoilState(
		ComplexConfigurationInvite
	);
	const resetConfigSteps = useResetRecoilState(ComplexConfigStep);
	const resetSelectedNode = useResetRecoilState(
		SelectedComplexSignAgreementStep
	);
	const resetConfigurationStatus = useResetRecoilState(
		SignAgreementComplexStepConfigured
	);
	const resetActiveOption = useResetRecoilState(SignOptionState);

	const { resetInvitationFlow } = useResetInvitationFlow();
	const navigation = useNavigate();
	const { getDashboards } = useDashboards();

	const handlePrimaryCallback = useCallback(() => {
		resetInvitationFlow();
		resetCapTableData();
		getDashboards();
		resetComplexConfiguration();
		resetConfigSteps();
		resetSelectedNode();
		resetConfigurationStatus();
		resetActiveOption();
		navigation('/sessions');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigation, resetCapTableData, resetInvitationFlow]);

	return (
		<div className="PaymentSuccess">
			<div className="PaymentSuccess__wrapper">
				<SuccessIcon />
				<div className="PaymentSuccess__message">
					Payment Successful. Invite Sent.
				</div>
				<Button
					type="button__filled button__filled--primary button__large"
					handleClick={handlePrimaryCallback}
					label="Close"
				/>
			</div>
		</div>
	);
};
