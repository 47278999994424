import { envUrl } from 'helpers';

export const ServiceProviderCodes = {
	iframe: `
  <iframe
      src="${envUrl}{appendUrl}"
      title="websites"
      style="width: 100%; height: 100%; border: 0;"
    />`,
	webComponent: `
  <onboarded-dashboard
    type="{type}"
    bgcolor="{bgcolor}"
    theadcolor="{theadcolor}"
    clientid="{clientid}"
    clientsecret="{clientsecret}"
    btnprimary="{btnprimary}"
    btnsecondary="{btnsecondary}"
    sidebar="{sidebar}"
	{isInsideAdmin}
   ></onboarded-dashboard>
   
   {script}
   `,
};

export const CODE_TAB_LISTS: any = [
	{
		id: 1,
		tabName: 'Web Component',
		key: 'webcomponent',
		__content: '<onboarded-dashboard></onboarded-dashboard>',
	},
	// {
	//   id: 2,
	//   tabName: "Iframe",
	//   key: "iframe",
	//   __content: `
	//   <iframe
	//   src="https://admin.stage.satschel.com/onboarding/onboarding?background=%236AFE03&theadColor=%23FE8403&search=false"
	//   title="Satschel"
	// />`,
	// },
];
