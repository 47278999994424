import { useCSVReader } from 'react-papaparse';
import { useSetRecoilState } from 'recoil';

import { UploadCsvStateCaptable } from '../../states';

export const CSVReader = () => {
	const { CSVReader } = useCSVReader();
	const setUploadCsv = useSetRecoilState(UploadCsvStateCaptable);

	return (
		<CSVReader
			onUploadAccepted={(results: { data: [] }) => {
				const luckyCaptableInfo: any = window;
				luckyCaptableInfo?.luckysheet?.exitEditMode();
				luckyCaptableInfo?.luckysheet
					.getSheetData()
					?.forEach((item: any, i: any) => {
						if (item && i < 50) {
							item.forEach((itemRow: any, j: any) => {
								if (itemRow && i < 50) {
									luckyCaptableInfo.luckysheet.setCellValue(i, j, '');
								}
							});
						}
					});
				results?.data?.forEach((column, colIndex) => {
					(column as any).forEach((row: any, rowIndex: any) => {
						luckyCaptableInfo?.luckysheet?.setCellValue(
							colIndex,
							rowIndex,
							row
						);
					});
				});
				setUploadCsv(false);
				setTimeout(() => {
					setUploadCsv(true);
				}, 5000);
			}}
		>
			{({ getRootProps }: any) => (
				<>
					<div {...getRootProps()}>
						<div className="UploadCSVInput--addBtn">
							<label htmlFor="browse-file">Import csv</label>
						</div>
					</div>
				</>
			)}
		</CSVReader>
	);
};
