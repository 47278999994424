import { FC, useMemo } from "react";
import Modal from "@storybook/new-modal/modal";

import { getDate, useFormatNumber } from 'utils';

import './cost-breakdown-details.scss';
import { CostBreakdownDetailState } from "../../stores";
import { useRecoilValue } from "recoil";
import { NoDataAvailable } from '@storybook';

interface IcostBreakdownDetails {
	isOpen: boolean;
	setIsOpen: (e: boolean) => void;
}

export const CostBreakdownDetails: FC<IcostBreakdownDetails> = ({ isOpen, setIsOpen }) => {
	const { numberDecimal } = useFormatNumber();
	const costBreakdownDetails = useRecoilValue(CostBreakdownDetailState);

	const rows = useMemo(() => {
		return costBreakdownDetails.breakdown.map(({ businessId, name, subscription, usage }) => {
			return (<tr key={businessId}>
				<td className="cost-breakdown-details__table--description">{name || "NA"}</td>
				<td className='cost-breakdown-details__table--text-right'>${numberDecimal(subscription)}</td>
				<td className='cost-breakdown-details__table--text-right'>${numberDecimal(usage)}</td>
			</tr>)
		}
		)
	}, [costBreakdownDetails.breakdown, numberDecimal])

	return (
		<Modal
			isOpen={isOpen}
			closeModal={() => setIsOpen(false)}
			modalName="cost-breakdown-details"
			className="cost-breakdown-details"
			title={
				<div className="cost-breakdown-details__header">
					<div className="cost-breakdown-details__title">Total Cost Breakdown</div>
					<div className="cost-breakdown-details__sub-title">
						{(costBreakdownDetails.startDate && costBreakdownDetails.endDate) &&
							<>
								{getDate(costBreakdownDetails.startDate)} - {getDate(costBreakdownDetails.endDate)}
							</>}
					</div>
				</div>
			}
		>
			<div className="cost-breakdown-details__table-wrap">
				{!costBreakdownDetails?.breakdown?.length ? (
					<NoDataAvailable
						label=""
						illustration="add-group.svg"
						message="No cost breakdown available"
						hideBtn
						className="cost-breakdown-details--empty"
					/>
				) : (
					<table className="cost-breakdown-details__table">
						<tr>
							<th>Description</th>
							<th className='subscription'>Subscription</th>
							<th className='usage'>Usage</th>
						</tr>
						{rows}
					</table>
				)}
			</div>
			<table className='cost-breakdown-details__table'>
				<tr>
					<td className='footer-heading'>Total</td>
					<td></td>
					<td className='footer-heading cost-breakdown-details__table--text-right'>${numberDecimal(costBreakdownDetails.total)}</td>
				</tr>
			</table>
		</Modal>
	)
}