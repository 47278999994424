import { FC, useCallback, useState } from 'react';
import { ComprehensiveHeader } from '../comprehensive-header';
import { KYB_ICONS } from 'views/sessions/components/kyb-wrapper/hooks';
import './assest-information.scss';
import { Image } from '@storybook';
/* eslint-disable react/no-array-index-key */

// Here any is because data is for multiple subsection so that here given any
interface IProps {
	label: string;
	data: any;
}
interface ValueObject {
	label: string;
	value: any;
}

export const KybAssetsInformation: FC<IProps> = ({ label, data }) => {
	const [showAllDetails, setShowAllDetails] = useState(false);

	const toggleShowAllMembers = () => {
		setShowAllDetails(!showAllDetails);
	};

	const getClassName = useCallback((label: string) => {
		switch (label) {
			case 'Property Info':
				return 'kyb-comperhensive-blue';
				break;
			case 'Motor Vehicle':
				return 'kyb-comperhensive-violet';
				break;
			case 'Water Craft Vehicle':
				return 'kyb-comperhensive-green';
				break;
			case 'Air Craft Vehicle':
				return 'kyb-comperhensive-yellow';
				break;
			default:
				return 'kyb-comperhensive-blue';
		}
	}, []);

	const renderValue = (value: ValueObject | any[], level = 0) => {
		if (Array.isArray(value)) {
			if (value.length === 0) {
				return (
					<div className="kyb-array-empty-wrapper">
						<div className="kyb-empty_data">
							<div className="kyb-empty_data__inner">
								<Image
									fileName="No_record_found.svg"
									className="empty_data_img"
									height="100"
								/>
								<div className="kyb-empty-data-txt">No records found</div>
							</div>
						</div>
					</div>
				);
			}
			return value.map((item, index) => (
				<div key={index}>
					{renderValue(item, level)}
					{/* Add a separator line if the next item exists and is an object */}
					{level !== 0 &&
						index < value.length - 1 &&
						typeof value[index + 1] === 'object' && (
							<div className="separator-line"></div>
						)}
				</div>
			));
		} else if (typeof value === 'object') {
			return (
				<div className={(level === 0 && Object.entries(value)?.length > 0) ? 'asset__wrapper' : ''}>
					{Object.entries(value).map(([key, val], index) => (
						<div key={key}>
							{key !== 'extra' && (
								<div
									className={
										level === 0 && index > 0 ? 'asset__sub-section' : ''
									}
								>
									{level === 0 ? (
										<>
											<div className="asset__sub-section--heading">
												{(val as ValueObject).label}
											</div>
											{renderValue(
												(val as ValueObject).value ?? '--',
												level + 1
											)}
										</>
									) : (
										<div className="kyb-wrapper__details__kybInfoDetailsRow">
											<div className="kyb-wrapper__details__kybInfoDetailsRow__label">
												{(val as ValueObject).label}
											</div>

											<div className="kyb-wrapper__details__kybInfoDetailsRow__value">
												<span>:</span>
												<div>
													{/* Update the level when rendering arrays within the object */}
													{Array.isArray((val as ValueObject).value)
														? renderValue(
																(val as ValueObject).value ?? '--',
																level + 1
														  )
														: renderValue(
																(val as ValueObject).value ?? '--',
																2
														  )}
												</div>
											</div>
										</div>
									)}
								</div>
							)}
						</div>
					))}
				</div>
			);
		} else {
			return <span>{value === '' ? 'N/A' : value}</span>;
		}
	};

	const renderPropertiesInformation = useCallback(
		(label: string, data: any) => {
			return (
				<div className="kyb-comprohensive__property-asset-wrapper">
					{data?.properties?.value?.length !== 0 && (
						<div
							onClick={toggleShowAllMembers}
							className="kyb-filing_view-more kyb-comprohensive__asset-view-more "
						>
							{showAllDetails ? 'View less' : 'View more'}
						</div>
					)}

					<div key={label} className="kyb-wrapper__details__kybInfoDetailsRow">
						{/* Assuming item has a type property */}
						<div className="kyb-wrapper__details__kybAssestDetailsRow">
							<div className="kyb-wrapper__details__kybAssestDetails-label-wrapper">
								<div
									className={`kyb-wrapper__details__kybAssestDetails-label-wrapper__icon ${getClassName(
										label
									)}`}
								>
									<i
										className={
											KYB_ICONS[data?.logo?.value] ??
											KYB_ICONS['riquestionmark']
										}
									></i>
								</div>
								<div className="kyb-wrapper__details__kybAssestDetails-label-wrapper__label">
									{label}
								</div>
							</div>

							{Object.keys(data ?? {}).map((key: string) =>
								data[key]?.type === 'basic' ? (
									<>
										{key !== 'name' && key !== 'logo' && key !== 'logoType' && (
											<div
												key={key} // Changed key to key from label
												className="kyb-wrapper__details__kybInfoDetailsRow"
											>
												<div className="kyb-wrapper__details__kybInfoDetailsRow__label">
													{data[key]?.label ?? key}
												</div>
												<div className="kyb-wrapper__details__kybInfoDetailsRow__value">
													<span>:</span>
													<div>{data[key]?.value ?? '--'}</div>
												</div>
											</div>
										)}
									</>
								) : (
									<>{showAllDetails && renderValue(data[key]?.value)}</>
								)
							)}
						</div>
					</div>
				</div>
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[showAllDetails]
	);

	return (
		<>
			<ComprehensiveHeader label={label} />
			{renderPropertiesInformation('Property Info', data?.propertyInfo?.value)}
		</>
	);
};
