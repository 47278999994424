import type { IUploadedDoc } from '.';

import { atom } from 'recoil';

export const SignDocModalState = atom<boolean>({
	key: 'sign-doc-modal-state',
	default: false,
});
export const SignTemplateTablelState = atom<boolean>({
	key: 'sign-temp-table-state',
	default: true,
});
export const ConfigDocIdState = atom<string>({
	key: 'config-doc-id',
	default: '',
});
export const templateNameState = atom<string>({
	key: 'template-name',
	default: '',
});
export const UploadedTemplateDocsState = atom<IUploadedDoc[]>({
	key: 'uploaded-signature-templates-docs',
	default: [],
});
