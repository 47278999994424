import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import {
	ChartCompareFirstState,
	ChartCompareSecondState,
	comparedFunnelChartsState,
	funnelChartFirstState,
	funnelChartSecondState,
} from 'views/analytics/hooks';
import { ChartStatus } from '../chart-status';
import { VariablePieChart } from '../charts';
import { lineColor, status_chart_color } from './constants';

export const StatusDetail = () => {
	const comparePreDetail = useRecoilValue(ChartCompareFirstState);
	const compareNextDetail = useRecoilValue(ChartCompareSecondState);
	const comparedChart = useRecoilValue(comparedFunnelChartsState);

	const preFunnelDetail = useRecoilValue(funnelChartFirstState);
	const nextFunnelDetail = useRecoilValue(funnelChartSecondState);

	const preStatus: any = useMemo(() => {
		let preTotalValue = {
			completed: 0,
			pending: 0,
			processing: 0,
			rejected: 0,
		};

		Object.values(comparePreDetail).forEach((item: any) => {
			preTotalValue = {
				completed: preTotalValue.completed + (item.completed?.total ?? 0),
				pending: preTotalValue.pending + (item.pending?.total ?? 0),
				processing: preTotalValue.processing + (item.processing?.total ?? 0),
				rejected: preTotalValue.rejected + (item.rejected?.total ?? 0),
			};
		});
		return preTotalValue;
	}, [comparePreDetail]);

	const nextStatusValue: any = useMemo(() => {
		let preTotalValue = {
			completed: 0,
			pending: 0,
			processing: 0,
			rejected: 0,
		};

		Object.values(compareNextDetail).forEach((item: any) => {
			preTotalValue = {
				completed: preTotalValue.completed + (item.completed?.total ?? 0),
				pending: preTotalValue.pending + (item.pending?.total ?? 0),
				processing: preTotalValue.processing + (item.processing?.total ?? 0),
				rejected: preTotalValue.rejected + (item.rejected?.total ?? 0),
			};
		});
		return preTotalValue;
	}, [compareNextDetail]);

	const chartDefaultPercent = useMemo(() => {
		return comparedChart
			? preFunnelDetail.length > 0 || nextFunnelDetail.length > 0
				? 100
				: 0
			: preFunnelDetail.length > 0
			? 100
			: 0;
	}, [comparedChart, nextFunnelDetail.length, preFunnelDetail.length]);

	const comparedStatusChartValue: any = useMemo(() => {
		const diffStatus = Object.keys(preStatus).reduce(
			(acc: any, curr: any) => (
				(acc[curr] = preStatus[curr] - nextStatusValue[curr]), acc
			),
			{}
		);
		const diffTotalStatus: any = Object.values(diffStatus).reduce(
			(acc: any, curr: any) => acc + curr,
			0
		);

		const completedValue = (diffStatus.completed * 100) / diffTotalStatus || 0;
		const pendingValue = (diffStatus.pending * 100) / diffTotalStatus || 0;
		const processingValue =
			(diffStatus.processing * 100) / diffTotalStatus || 0;
		const rejectedValue = (diffStatus.rejected * 100) / diffTotalStatus || 0;

		const data = [
			{
				name: 'Approved',
				y: completedValue,
				z: diffStatus.completed ? 4 : 1,
				diff: diffStatus.completed,
			},
			{
				name: 'Pending',
				y: pendingValue,
				z: diffStatus.pending ? 3 : 1,
				diff: diffStatus.pending,
			},
			{
				name: 'Rejected',
				y: rejectedValue,
				z: diffStatus.rejected ? 2 : 1,
				diff: diffStatus.rejected,
			},
			{
				name: 'Under Review',
				y: processingValue,
				z: 1,
				diff: diffStatus.processing,
			},
		];

		return data;
	}, [nextStatusValue, preStatus]);

	const statusChartValue: any = useMemo(() => {
		const diffTotalStatus: any = Object.values(preStatus).reduce(
			(acc: any, curr: any) => acc + curr,
			0
		);
		const completedValue = (preStatus.completed * 100) / diffTotalStatus || 0;
		const pendingValue = (preStatus.pending * 100) / diffTotalStatus || 0;
		const processingValue = (preStatus.processing * 100) / diffTotalStatus || 0;
		const rejectedValue = (preStatus.rejected * 100) / diffTotalStatus || 0;
		const data = [
			{ name: 'Approved', y: completedValue, z: 4, diff: preStatus.completed },
			{
				name: 'Pending',
				y: pendingValue,
				z: 3,
				diff: preStatus.pending,
			},
			{
				name: 'Rejected',
				y: rejectedValue,
				z: 2,
				diff: preStatus.rejected,
			},
			{
				name: 'Under Review',
				y: processingValue,
				z: 1,
				diff: preStatus.processing,
			},
		];

		return data;
	}, [preStatus]);

	const statusChartRender = useMemo(() => {
		return (
			<div className="analytics-wrapper__pie">
				<VariablePieChart
					isLoading={false}
					title="Status"
					seriesColor={status_chart_color}
					seriesData={
						comparedChart ? comparedStatusChartValue : statusChartValue
					}
					chartName="Status"
					chartPercent={chartDefaultPercent}
				/>
				<div className="analytics-wrapper__line-wrapper">
					{(comparedChart ? comparedStatusChartValue : statusChartValue).map(
						(item: any) => (
							<div key={item.name}>
								<ChartStatus
									percent={item.y}
									title={item.name}
									color={lineColor[item.name]}
									diff={item.diff}
								/>
							</div>
						)
					)}
				</div>
			</div>
		);
	}, [
		chartDefaultPercent,
		comparedChart,
		comparedStatusChartValue,
		statusChartValue,
	]);
	return <>{statusChartRender}</>;
};
