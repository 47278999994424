import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { Header } from 'views/header';
import { ref, onValue } from 'firebase/database';
import { Memory } from './store';
import { useFiveInvitedAt } from 'views/signed-doc/store';
import { SignedDocInboxTable } from './components';
import { SignedDocInboxState, useSignedDocsInbox } from './store';
import { useFirebaseInit } from 'hooks';
import { loginState } from 'global-stores';

interface ISignedDocInbox {
	screen?: string,
	status?: string
	linkedEnvelopes?: string[]
}

export const SignedDocInbox = ({screen, status, linkedEnvelopes}: ISignedDocInbox) => {
	const { getSignedDocsInbox } = useSignedDocsInbox();
	const { data } = useRecoilValue(SignedDocInboxState);
	const { marketingInit } = useFiveInvitedAt();
	const { database } = useFirebaseInit();
	const { business } = useRecoilValue(loginState);

	// Trigger Sentbox list api whenever there is any change detected in the db by firebase
	useEffect(() => {
		// Check if the business variable is defined
		if (business) {
			// Create a reference to the database path using the business variable
			const dataRef = ref(database, business);
			// Attach a listener to the dataRef to handle value changes
			onValue(dataRef, snapshot => {
				// Get the data from the snapshot
				const data = snapshot.val();
				// Check if the timestamp in the data matches the current timestamp in memory
				if (data.timeStamp === Memory.getTimeStamp() && !data.envelopeId) {
					// If the timestamps match, do nothing and return
					return;
				}
				// Update the memory with the new timestamp from the data
				Memory.setTimeStamp(data.timeStamp);

				// Check if the businessId in the data matches the current business variable
				if (data?.businessId === business) {
					// If the business IDs match, log a message and trigger the API call
					getSignedDocsInbox(false, status);
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//Shahbaz: Tracking Intercom variable of signed documents
	useEffect(() => {
		if (data?.length > 0) {
			marketingInit();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return (
		<div className="SignedDocInbox__wrapper">
			{!screen && <Header title="esign Sentbox" />}
			<div className="SignedDocInbox__body">
				<SignedDocInboxTable screen={screen} linkedEnvelopes={linkedEnvelopes}/>
			</div>
		</div>
	);
};

export default SignedDocInbox;
