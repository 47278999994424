import { FC } from 'react';
import './add-condition.scss';

interface IAddConditionBtn {
	onClick: () => void;
}
export const AddConditionButton: FC<IAddConditionBtn> = ({ onClick }) => {
	return (
		<div className="add-condition-btn-container" onClick={onClick}>
			<div className="add-condition-btn-container__condition-btn">
				<div className="icon">
					<i className="ri-add-circle-line"></i>
				</div>
				<label htmlFor="add-condition">Add Condition </label>
			</div>
		</div>
	);
};
