import { FC } from 'react';
import { GraphColorLegendsStep } from 'views/sessions/constants';
import './color-legends.scss';

interface IColorLegends {
	title: string;
}

export const ColorLegends: FC<IColorLegends> = ({ title }) => {
	return (
		<div className="ColorLegends">
			<div className="ColorLegends--title">{title}</div>
			<div className="ColorLegends--container">
				{GraphColorLegendsStep.map(item => (
					<div className="ColorLegends--boxes" key={item.label}>
						<div
							className="ColorLegends--box"
							style={{
								backgroundColor: item.color,
							}}
						></div>
						<div className="ColorLegends--label">{item.label}</div>
					</div>
				))}
			</div>
		</div>
	);
};
