import { FC, useCallback, useEffect, useMemo } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { IRecipient } from 'views/signed-doc-inbox';
import { EditRecipientFooter, RecipientForm } from './components';
import { IEditRecipientProps, RecipientErrorsState, RecipientLocalState, useSubmitRecipient } from './stores';



export const EditRecipient: FC<IEditRecipientProps> = ({ inbox, closeModal }) => {

	//local states
	const setLocalRecipients = useSetRecoilState(RecipientLocalState);
	const resetErrorRecipients = useResetRecoilState(RecipientErrorsState);
	const updatedRecipient = useRecoilValue(RecipientLocalState);
	const { isLoading, showModal, saveRecipient } = useSubmitRecipient();

	const handleClose = useCallback(() => {

		closeModal();
	}, [closeModal]);

	const handleSubmit = useCallback(() => {
		saveRecipient(updatedRecipient, inbox._id);
	},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[updatedRecipient]
	);

	const renderEditForm = useMemo(() => {
		return inbox.recipients.map((item: IRecipient, index: number) => (
			<RecipientForm recipient={item} index={index} key={item._id} />
		))
	}, [inbox.recipients])


	useEffect(() => {
		resetErrorRecipients();
		setLocalRecipients(inbox.recipients)
	}, []) // eslint-disable-line

	useEffect(() => {
		if (!showModal)
			handleClose();
	}, [showModal]) // eslint-disable-line

	const completeStatus = useMemo(() => {
		return inbox.recipients.every((item: IRecipient) => /completed/.test(item.status))
	}, [inbox._id]) // eslint-disable-line

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<div className="edit-recipient-header">
				<div>Edit recipients information</div>
			</div>
			<div className="edit-recipient-container">
				{renderEditForm}
			</div>
			<EditRecipientFooter
				primaryBtnLabel={'Save'}
				secondaryBtnLabel="Close"
				handlePrimaryClick={handleSubmit}
				handleSecondaryClick={handleClose}
				loading={isLoading}
				disabled={completeStatus}
			/>
		</div >
	);
};
