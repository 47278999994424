
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Input } from '@storybook';

import type { IInputChange, ITextAreaChange } from 'types';
import { IRecipient } from 'views/signed-doc-inbox';
import { IRecipientFormProps, RecipientErrorsState, useRecipients } from '../stores';

const USER_DETAILS = {
	fullName: { value: '', isError: false },
	email: { value: '', isError: false },
}

/**
 * Create the RecipientForm functional component
 * */
export const RecipientForm: FC<IRecipientFormProps> = ({ recipient, index }) => {
	/**
	 * Get recipient errors from Recoil state
	 * */
	const errors = useRecoilValue(RecipientErrorsState);
	/**
	 * State variables for recipient details
	 * */
	const [recipientDetails, setRecipientDetails] = useState(USER_DETAILS)

	const { setRecipientsWithValue } = useRecipients();

	/**
	 * setting the initial local state for email and name
	 */
	useEffect(() => {
		setRecipientDetails((prev) => ({
			...prev,
			fullName: { ...prev.fullName, value: recipient.fullName || '' },
			email: { ...prev.email, value: recipient.email },
		  }));

	}, [recipient.fullName, recipient.email])
	

	/**
	 * Handle changes to input fields (name: email, fullName, title)
	 * */
	const handleChange = useCallback(
		(event: IInputChange | ITextAreaChange, name: keyof IRecipient) => {
			const { value } = event.target;
			
			const saveValue = (name === 'email') ? value.toLowerCase() : value;

			setRecipientDetails((prev) => ({
				...prev,
				[name]: {
					value: saveValue,
					isError: false
				}
			}))
			setRecipientsWithValue(name, saveValue, index);
		},

		[index, setRecipientsWithValue]
	);

	/**
	 * Update error state variables based on Recoil errors
	 * */
	useEffect(() => {
		if (recipient._id) {
			const error = errors[recipient._id];
			let isEmailError = false;
			let isFullNameError = false;
			if (error?.includes('email')) {
				isEmailError = true;
			} else {
				isEmailError = false;
			}
			if (error?.includes('fullName')) {
				isFullNameError = true;
			} else {
				isFullNameError = false;
			}
			setRecipientDetails((prev) => ({
				...prev,
				fullName: {
					...prev.fullName,
					isError: isFullNameError
				},
				email: {
					...prev.email,
					isError: isEmailError
				}
			}))
		}
	}, [errors, index, recipient._id]);


	const renderRecipientTitle = useMemo(() => {
		if(recipient.title) {

			/**
			 * If all conditions are met, render recipient title information.
			 * */
			return (
				<div className="edit-recipient__form-fields--prefilled">
					<div className="edit-recipient__form-fields--label">
						Title/role/description
					</div>
					<div className="edit-recipient__form-fields--value">
						{recipient.title}
					</div>
				</div>
			);
		}
		return null;
	
	}, [recipient.title]);

	const completeStatus = useMemo(() => /completed/.test(recipient.status) ,[recipient.status])

	const renderFormFields = useMemo(() => {
		const { fullName, email } = recipientDetails;
		/**
		 * render the name and email input fields.
		 * */
		return (
			<>
				<Input
					handleChange={(event) => handleChange(event, 'fullName')}
					inputType="text"
					placeholder="Enter Name"
					label="Name"
					value={fullName.value ?? ''}
					isError={fullName.isError}
					errorMessage={
						!fullName.value?.trim()
							? 'Please enter name'
							: 'Invalid name'
					}
					id={`${recipient._id}-title`}
					autoFocus={false}
					autoComplete="title"
					inputName="title"
					disabled={completeStatus}
				/>
				<Input
					handleChange={(event) => handleChange(event, 'email')}
					inputType="text"
					placeholder="Enter Email"
					label="Email"
					value={email.value}
					isError={email.isError}
					errorMessage={
						!email.value?.trim() ? 'Please enter email' : 'Invalid email id'
					}
					id={`${recipient._id}-email`}
					autoComplete="off"
					inputName="email"
					disabled={completeStatus}
				/>
			</>
		);
	}, [
		handleChange,
		recipientDetails,
		recipient._id,
		completeStatus
	]);

	return (
		<div className="edit-recipient__form-container">
		
			<div className="edit-recipient__form">
				<div
					className="edit-recipient__card-color"
					style={{ borderLeftColor: recipient.colorCode }}
				>
					<div className="edit-recipient__handle-container">
					<div />
						<div className="edit-recipient__controls-container">
						<div className="edit-recipient__form-actions-wrapper">
								<div className="edit-recipient__signer">{`Signer ${
									index + 1
								}`}</div>
								
							</div>
							{renderRecipientTitle}
							<div className="edit-recipient__form-fields">
								{renderFormFields}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
