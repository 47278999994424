import { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { usePaymentCard } from 'views/routes-children';
import { PaymentNavigationState } from 'views/settings/billing/stores';
import { PaymentNavigation } from '../../constant';

export const usePaymentProcessing = () => {
	const setAddPaymentNaviagtion = useSetRecoilState(PaymentNavigationState);
	const [processing, setProcessing] = useState(0);
	const { getPaymentCardsApi, loading } = usePaymentCard();

	useEffect(() => {
		if (processing < 100) {
			setTimeout(() => {
				if (processing <= 70) {
					setProcessing(prev => prev + 10);
				} else if (processing <= 85) {
					setProcessing(prev => prev + 5);
				} else {
					setProcessing(90);
					if (!loading) {
						setAddPaymentNaviagtion(PaymentNavigation.Success);
					}
				}
			}, 50);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [processing, loading]);

	useEffect(() => {
		getPaymentCardsApi();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		processing,
	};
};
