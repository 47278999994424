import { atom } from 'recoil';

import { ISessionDetails, IWelcomeNewUserModal } from './types';
import { v4 } from 'uuid';

export const WelcomeNewUserModalState = atom<IWelcomeNewUserModal>({
	key: 'welcome-new-user-modal-state-key',
	default: {
		open: false,
		type: '',
	},
});

export const IsSubscriptionModal = atom<boolean>({
	key: 'Is-SubscriptionModal',
	default: false,
});

export const SignUpSessionStatusState = atom<ISessionDetails | null>({
	key: v4(),
	default: null,
});
