import { useOutsideClick } from 'hooks';
import { FC, Fragment, useCallback, useRef, useState } from 'react';

/**
 * @Manish Define the props for the InboxDropdown component
 * */
interface IInboxDropdown {
	historyCallback: () => void; // Callback function for history action
	voidCallback: () => void; // Callback function for void action
	viewDocCallback: () => void; // Callback function for view doc
	correctCallback: () => void; // Callback function for correct action
	editRecipientCallback: () => void; // Callback function for edit recipient action
	showVoided: boolean; // Flag to determine whether to show the "Void" option
	showView: boolean;
	isHideInboxOptions?: boolean; // prop for hide void option and view option in dropdown
	action: string;
	inboxType?: 'multiSigner' | 'selfSign' | 'onboarding' | '';
	showCorrect?: boolean;
}

/**
 * @Manish Create the InboxDropdown functional component
 * */
export const InboxDropdown: FC<IInboxDropdown> = ({
	historyCallback,
	voidCallback,
	showVoided,
	viewDocCallback,
	correctCallback,
	editRecipientCallback,
	showView,
	isHideInboxOptions,
	action,
	inboxType,
	showCorrect,
}) => {
	/**
	 * @manish State to track whether the dropdown popup is open or closed
	 * */
	const [isPopupOpen, setPopupOpen] = useState(false);

	/**
	 * @Manish Create a ref for the dropdown element to handle clicks outside of it
	 * */
	const refPopup = useRef<HTMLDivElement>(null);
	useOutsideClick(refPopup, () => {
		setPopupOpen(false);
	});

	/**
	 * @Manish Callback function to toggle the visibility of the dropdown popup
	 * */
	const handlehello = useCallback(() => {
		setPopupOpen(pre => !pre);
	}, []);

	return (
		<div ref={refPopup} onClick={handlehello}>
			<div className="inbox-dropdown__icon">
				<i className="ri-arrow-down-s-fill" />
			</div>
			{/* @Manish Render the dropdown popup when it's open */}
			{isPopupOpen && (
				<div className="inbox-dropdown">
					<div className="inbox-dropdown__container">
						{showView && (
							<div
								className="inbox-dropdown__menu-item"
								onClick={viewDocCallback}
							>
								View
							</div>
						)}
						{/* @Manish Display the "History" option with a callback */}
						{/* Arun Kumar : isHideInboxOptions is a prop for to hide histroy btn in dropdown if we are showing histro label   */}
						{/* {isHideInboxOptions &&
							(action === 'completed' ||
								action === 'voided' ||
								action === 'needToSign' || action === "processing") && (
								<div
									className="inbox-dropdown__menu-item"
									onClick={historyCallback}
								>
									Certificates
								</div>
						)} */}
						{((inboxType === 'onboarding' &&
							(action === 'completed' || action === 'processing')) ||
							inboxType !== 'onboarding') && (
							<div
								className="inbox-dropdown__menu-item"
								onClick={historyCallback}
							>
								Certificates
							</div>
						)}
						{/* @Manish Display the "Void" option conditionally based on the showVoided flag */}
						{showVoided && !isHideInboxOptions && (
							<div className="inbox-dropdown__menu-item" onClick={voidCallback}>
								Void
							</div>
						)}
						{showCorrect && inboxType !== 'onboarding' && (
							<Fragment>
								<div
									className="inbox-dropdown__menu-item"
									onClick={correctCallback}
								>
									Correct
								</div>
								<div
									className="inbox-dropdown__menu-item"
									onClick={editRecipientCallback}
								>
									Edit Recipient
								</div>
							</Fragment>
						)}
						
					</div>
				</div>
			)}
		</div>
	);
};
