import { Image, ReactDropdown } from '@storybook';
import { FC, Fragment, useCallback, useMemo, useState } from 'react';

import {
	KYCDetailsHeader,
	kycDetailsObj,
	KYCIDTYPE,
	UserAddressObj,
} from 'views/sessions/hooks';
import { SessionDetailsSkeletonLoader } from 'views/sessions/session-details-loader';
import {
	IKycDetails,
	IKycDetailsHeader,
	IKycDetailsKeys,
} from 'views/sessions/store';
import { showLastDigit } from 'views/user-identity-flow';
import { ActionButton } from '../action';
import { NoData } from '../no-data-available';
import { SessionDetailsHeading } from '../session-details-heading';
import { DocumentView } from './document-view';
import { RejectionDetails } from './rejection-details';
import { UserImage } from './user-image';
import { convertDateFormat, toTitleCase } from 'utils';
import { useSessionDetailsActions } from 'views/sessions/hooks/use-session-actions';
import { KYBDownloadOption } from '../kyb-wrapper/constant';
import classNames from 'classnames';
import { useRecoilValue } from 'recoil';
import { kycComperhensiveSelector } from './kyc-comperhensive/store';
import { KycComprehensive as KycComprehensiveSection, kycFeatureTabs } from './kyc-comperhensive';
import { KycBasicFlowChart } from './kyc-basic-info-tree';

// Function to filter out null values from an object
const filterNull = (obj: IKycDetails) => {
	return Object.entries(obj).reduce((acc: any, [key, value]: any) => {
		if (value !== null && typeof value !== 'object' && !!value) {
			acc[key] = value;
		} else if (value !== null && typeof value === 'object') {
			const filteredValue = filterNull(value);
			if (Object.keys(filteredValue ?? {})?.length > 0) {
				acc[key] = filteredValue;
			}
		}
		return acc;
	}, {});
};

interface IKycDetail {
	kyc: {
		kyc: IKycDetails;
		status: string;
	};
	isTransactionPage: boolean;
	role: string;
	isAgentPermitted: boolean | undefined;
}

interface IKycDetailsWrapper {
	kycDetail: IKycDetail;
	isLoading: boolean;
	isLoaded: boolean;
	nodeId?: string;
	isUserPermissionWrite?: boolean;
	isChequeFraud?: boolean;
}

export const KycDetailsWrapper: FC<IKycDetailsWrapper> = ({
	kycDetail,
	isLoading,
	isLoaded,
	nodeId,
	isUserPermissionWrite = true,
	isChequeFraud = false,
}) => {
	const {
		isAgentPermitted,
		isTransactionPage,
		role,
		kyc: kycWrapper,
	} = kycDetail;

	const { kyc, status } = kycWrapper ?? {};
	const kycComperhensive = useRecoilValue(kycComperhensiveSelector);

	const { kycComperhensiveData } = kycComperhensive ?? {};

	// pradeep chaurasia : to get kyc keys without ssn and address
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { ssn, address, ...restKyc } = kyc ?? {};
	const KYCLabelDataKeys = Object?.keys(restKyc ?? {});
	const [kycTabs, setKycTabs] = useState(kycFeatureTabs[0]?.key);

	const { exportToJson } = useSessionDetailsActions();

	const handleChangeSelect = (event: any) => {
		const { value } = event;
		if (value === 'json') {
			exportToJson(kycWrapper, 'KYC.json');
		}
	};

	const getVerifiedIcon = useCallback(
		(
			property: {
				value: string | number | null | undefined;
				verified: boolean | null | undefined;
			},
			key: string
		) => {
			const { value, verified } = property ?? {};
			const ssnData = ssn?.ssn ?? ssn;
			const { status } = ssnData as Record<string, unknown>;
			if (key === 'ssn' && status) {
				switch (status) {
					case 'rejected':
						return <Image fileName="Verified_1.svg" />;
					case 'completed':
						return <Image fileName="Frame_27.svg" />;
					case 'processing':
						return <Image fileName="kyb_under_review.svg" />;
					default:
						return <></>;
				}
			}

			if (!value || verified == null) {
				return <></>;
			} else if (verified) {
				return <Image fileName="Frame_27.svg" />;
			} else if (verified === false) {
				return <Image fileName="Verified_1.svg" />;
			} else {
				return <></>;
			}
		},
		[ssn]
	);

	const rowItem = useCallback(
		(key: IKycDetailsKeys, header: IKycDetailsHeader) => {
			const localkycDetailsObj = { ...kycDetailsObj };
			const rightValue =
				localkycDetailsObj[kyc?.[header]?.[key]?.value] ??
				// Shahbaaz:checking if the value is not object
				((typeof kyc?.[header]?.[key]?.value !== 'object' &&
					kyc?.[header]?.[key]?.value) as string);
			if (/mrzLine|dateOfExpiry|dateOfIssue/.test(key) && !rightValue) {
				delete localkycDetailsObj[key];
			}
			if (localkycDetailsObj[key]) {
				const label = /USA|US/.test(kyc?.basicInfo?.country?.value)
					? 'SSN'
					: /IND|IN/.test(kyc?.basicInfo?.country?.value)
					? 'PAN'
					: localkycDetailsObj[key] ?? 'Tax ID';
				return (
					<Fragment key={key}>
						<div className="kycDetail-row">
							<div className="kyc-report-label">
								<div className="kyc-report-label__key">
									{header === 'address'
										? UserAddressObj[key] ?? key
										: header === 'ssn'
										? label
										: localkycDetailsObj[key] ?? key}
								</div>
							</div>
							<div className="kycDetail-row__basicInfoContainer">
								<div>:</div>
								<div className="kyc-report-label-kycValue">
									{key === 'conclusion' &&
										(kyc?.[header]?.[key] &&
										(kyc as any)?.[header]?.[key]?.length
											? ((kyc as any)?.[header]?.[key] ?? []).map(
													(conclusion: string) => (
														<>
															<div className="kyc-report-label-kycValue__conclusion">
																<i className="ri-circle-fill kyc-report-label-kycValue__conclusion__dot" />
																{conclusion ?? 'NA'}
															</div>
														</>
													)
											  )
											: 'NA')}

									{key !== 'conclusion' &&
										key !== 'report' &&
										key?.toLocaleLowerCase() !== 'ssn' &&
										key !== 'dateOfBirth' &&
										key !== 'idType' &&
										(rightValue || 'NA')}

									{key === 'idType' && (
										<>
											{KYCIDTYPE[kyc?.[header]?.[key]?.value] ??
												kyc?.[header]?.[key]?.value?.replaceAll('_', ' ') ??
												'NA'}{' '}
											{}
										</>
									)}

									{key === 'report' && (
										<RejectionDetails
											data={kyc?.[header]?.[key]?.reject ?? []}
										/>
									)}
									{key === 'dateOfBirth' && (
										<>
											{convertDateFormat(kyc?.[header]?.[key]?.value)} {}
										</>
									)}

									{key?.toLocaleLowerCase() === 'ssn' && (
										<>{showLastDigit(rightValue, label) || 'NA'}</>
									)}
								</div>
								{getVerifiedIcon(kyc?.[header]?.[key], key)}
							</div>
						</div>
					</Fragment>
				);
			}
			return <></>;
		},
		[getVerifiedIcon, kyc]
	);

	const renderKycDetails = useCallback(
		(header: IKycDetailsHeader) => {
			const {
				frontImage,
				backImage,
				userPhoto,
				simpliciMatchPercentage,
				...rest
			} = kyc?.[header] ?? {};
			
			
			const details = Object.keys(rest ?? {});
			
			const elements = [];
			switch (header) {
				case 'document': {
					const docImg = (
						<DocumentView
							frontImage={frontImage?.value}
							backImage={backImage?.value}
						/>
					);
					elements.push(docImg);
					break;
				}
				case 'basicInfo': {
					const docImg = (
						<UserImage
							image={userPhoto?.value}
							simpliciMatchPercentage={simpliciMatchPercentage?.value}
						/>
					);
					elements.push(docImg);
					break;
				}
				case 'breakdown': {
					const breakdownComp = (
						<KycBasicFlowChart
							breakdownList={kyc?.[header]}
							idType={kyc['document']?.idType?.value}
						/>
					);
					elements.push(breakdownComp);
					break;
				}
				default:
					break;
			}
			elements.push(
				...details.map(key => {
					return <Fragment key={key}>{rowItem(key as any, header)}</Fragment>;
				})
			);

			return elements;
		},
		[kyc, rowItem]
	);

	const renderKycHeaders = useMemo(() => {
		const { document, basicInfo, breakdown, ...restData } = kyc;
		const filteredData = filterNull({
			document,
			basicInfo,
			...restData,
			breakdown,
		});
		const headers = Object.keys(filteredData ?? {});
		const fullName = basicInfo?.firstName?.value
			? toTitleCase(
					`${basicInfo?.firstName?.value ?? ''} ${
						basicInfo?.lastName?.value ?? ''
					}`
			  )
			: null;
		return (
			<>
				{headers.map((header: any) => {
					return header === 'ssn' && !kyc?.ssn?.ssn?.value
						? null
						: !!kycDetailsObj[header] && (
								<Fragment key={header}>
									<div className="kycDetails-info__documents">
										<div className="kycDetails-info__documents_inner">
											<div className="kycDetails-info__documents_inner_title">
												{header === 'breakdown' && KYCDetailsHeader[header]
													? KYCDetailsHeader[header] + (fullName ?? 'You')
													: KYCDetailsHeader[header] ?? header}
											</div>
										</div>
										<div className="kycDetails-info__lines" />
									</div>
									<div className="kycDetails-info__documentView">
										{renderKycDetails(header)}
									</div>
								</Fragment>
						  );
				})}
			</>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [kyc, renderKycDetails, kycDetailsObj]);

	const isShowKycTab = useCallback(
		(key: string) => {
			switch (key) {
				case 'tab':
					return Object.keys(kycComperhensiveData ?? {})?.length > 0;
				case 'kyc':
					return true;
				case 'kycComprehensive':
					return Object.keys(kycComperhensiveData ?? {})?.length > 0;
				default:
					return false;
			}
		},
		[kycComperhensiveData]
	);

	const renderKycTab = useMemo(() => {
		const tabClass = (selected: boolean) => {
			return classNames('kyc-tab__select', {
				'kyc-tab__selected': selected,
			});
		};
		return (
			<div className="kyc-tab">
				{kycFeatureTabs.map(item => {
					return isShowKycTab(item?.key) ? (
						<div
							key={item?.key}
							className={tabClass(kycTabs === item?.key)}
							onClick={() => setKycTabs(item?.key)}
						>
							{item?.label}
						</div>
					) : null;
				})}
			</div>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [kycTabs]);

	const renderTabComponent = useMemo(() => {
		const isKycComprehensiveEmpty = Object.values(kycComperhensiveData).every(
			(item: any) =>
				item?.data?.metrics === null || item?.data?.metrics?.length === 0
		);

		switch (kycTabs) {
			case 'kyc':
				return renderKycHeaders;
			case 'kycComprehensive':
				return isKycComprehensiveEmpty ? (
					<NoData />
				) : (
					<KycComprehensiveSection />
				);
			default:
				return renderKycHeaders;
		}
	}, [kycComperhensiveData, kycTabs, renderKycHeaders]);

	return (
		<div className="kycDetails-contain">
			<SessionDetailsHeading
				label="KYC Details"
				icon={
					<i className="ri-user-search-fill form-icon-profile-container__formIcon" />
				}
			>
				{KYCLabelDataKeys?.length > 0 && (
					<div className="kyb-wrapper_download-wrapper">
						<ReactDropdown
							options={KYBDownloadOption}
							value={{ label: 'Download', value: 'download' }}
							defaultValue={{ label: 'Download', value: 'download' }}
							handleChangeSelect={handleChangeSelect}
							controlStyle={{ minHeight: 40, width: 200 }}
						/>
						<div className="action-btn">
							{role === 'AGENT' && !isAgentPermitted ? (
								<div className="action-btn__agent">{status}</div>
							) : (
								<ActionButton
									status={status}
									type="kyc"
									isTransactionPage={isTransactionPage}
									isChequeFraud={isChequeFraud}
									nodeId={nodeId}
									isDisabled={!isUserPermissionWrite}
								/>
							)}
						</div>
					</div>
				)}
			</SessionDetailsHeading>
			{renderKycTab}
			{!isLoading && isLoaded ? (
				KYCLabelDataKeys?.length > 0 ? (
					<div
						className={`"kycDetails-body" kycDetails-body${
							isTransactionPage ? '--kyc-transactions-page' : ''
						}`}
					>
						{renderTabComponent}
					</div>
				) : (
					<NoData />
				)
			) : (
				<>
					<SessionDetailsSkeletonLoader />
				</>
			)}
		</div>
	);
};
