import React, { useMemo } from 'react';
import { Image } from '@storybook';
import {
	BankruptcyInformationSection,
	IRS500Information,
	KybAssetsInformation,
	KYBComprehensive,
} from '../kyb-comprehensive';
import { KybSubInformationModalBody } from '../kyb-sub-information-modal-body';
import { OFAC } from '../ofac';
interface Ikyb {
	data: any;
	selectedKybType: string;
}

export const KybComprehensiveCompanyDetails: React.FC<Ikyb> = ({
	data,
	selectedKybType,
}) => {
	const comprehensiveKybCompanyDetails = useMemo(() => {
		return (
			<div className="kyb-wrapper__details kyb-wrapper__details-wrapper">
				{selectedKybType === 'kyb-comprehensive' ? (
					Object.keys(data?.companyDetails?.comprehensive?.report ?? {})
						.length === 0 ? (
						<div className="kyb-empty_data">
							<div className="kyb-empty_data__inner">
								<Image
									fileName="missing.svg"
									className="empty_data_img"
									height="100"
								/>
								<div className="kyb-empty-data-txt">No data is available</div>
							</div>
						</div>
					) : (
						Object.keys(data?.companyDetails?.comprehensive?.report ?? {}).map(
							key => {
								const reportItem =
									data?.companyDetails?.comprehensive?.report[key];
								if (!reportItem) return null;

								const { label, type, value } = reportItem;

								if (
									key !== 'assetSection' &&
									key !== 'irs5500information' &&
									key !== 'bankruptcyInformation'
								) {
									return (
										<KYBComprehensive
											key={key}
											label={label}
											type={type}
											value={value}
										/>
									);
								} else if (key === 'assetSection') {
									return (
										<KybAssetsInformation
											key={key}
											label={label}
											data={value}
										/>
									);
								} else if (key === 'irs5500information') {
									return (
										<IRS500Information
											key={key}
											label="IRS5500 Information"
											data={value}
										/>
									);
								} else if (key === 'bankruptcyInformation') {
									return (
										<BankruptcyInformationSection
											key={key}
											label={label}
											bankruptcyData={value}
										/>
									);
								} else {
									return null;
								}
							}
						)
					)
				) : (
					<OFAC />
				)}
				{/* We will use Dynamically for KYb sub-section Information after clicking on View button. */}
				<KybSubInformationModalBody />
			</div>
		);
	}, [data, selectedKybType]);

	return <>{comprehensiveKybCompanyDetails}</>;
};
