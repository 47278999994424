import { ReactDropdown } from '@storybook';
import { CapTableMapKeyAndValue } from '@storybook/custom-captable/states';
import {
	Dispatch,
	FC,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
	ConfigFieldVisibleState,
	InvestorTableGridData,
	SelectedPipelineDetails,
} from 'global-stores';
import {
	CHANGE_ROW_LABLE,
	CHANGE_ROW_LABLE_FUND_OUT,
} from 'views/invite-investor/constants/default-cap-table';
import { userCapTableState } from '../../store';

interface IOptions {
	label: string;
	value: string;
}

interface IProps {
	label: string | any;
	isRequired: boolean;
	options: IOptions[];
	isOptional: boolean;
	isReset?: boolean;
	setIsReset?: Dispatch<SetStateAction<boolean>>;
	setShowSeletedValue: Dispatch<SetStateAction<string[]>>;
}

export const ConfigRow: FC<IProps> = ({
	isRequired,
	label,
	options,
	isOptional,
	isReset,
	setIsReset,
	setShowSeletedValue,
}) => {
	const [selectedItem, setSelectedItem] = useState<any>(null);

	const [selectedValue, setSelectedValue] = useRecoilState(
		CapTableMapKeyAndValue
	);

	const pipelineDetails = useRecoilValue(SelectedPipelineDetails);
	const [configVisble, setConfigVisible] = useRecoilState(
		ConfigFieldVisibleState
	);

	const grid = useRecoilValue(InvestorTableGridData);
	const userCapTableData = useRecoilValue(userCapTableState);
	const [same, setSame] = useState(false);

	useEffect(() => {
		if (userCapTableData?.headers) {
			const gridHead = grid[0].map((gri: any) => {
				if (gri.value === 'Dollars Invested') return 'Investment Amount';
				return gri.value;
			});
			const userHead = JSON.parse(JSON.stringify(userCapTableData?.headers));
			const isSame = gridHead.every((header: any) =>
				userHead?.includes(header)
			);
			if (isSame) setSame(true);
			else setSame(false);
		}
	}, [grid, userCapTableData]);

	useEffect(() => {
		if (isReset) {
			setSelectedItem(null);
			setSelectedValue([]);
			setConfigVisible({
				'First Name': true,
				'Last Name': true,
				'Dollars Invested': true,
				Email: true,
				'Country Code': true,
				Mobile: true,
			});
			if (setIsReset) {
				setIsReset(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isReset]);

	const handleChangeSelect = useCallback(
		(value: IOptions) => {
			setShowSeletedValue((prev: any[]) => prev.filter(el => el !== label));
			setSelectedItem(value);
			setSelectedValue((prev: any) => {
				const index = prev.findIndex((item: any) => item[label]);
				if (index === -1) {
					return [...prev, { [label]: value.value }];
				} else {
					const newArray = [...prev];
					newArray[index] = { [label]: value.value };
					return newArray;
				}
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isReset, label]
	);

	const filterValue = useMemo(() => {
		return options?.filter(items => {
			// to hide first and last name from config dropdown options for now
			if (items.label === '') return null;
			return !selectedValue
				?.flatMap(item => Object.values(item))
				?.includes(items.value);
		});
	}, [options, selectedValue]);

	const handleDefault = useCallback(() => {
		if (same) {
			if (label === 'Dollars Invested') {
				const defaultOption = options?.filter(
					option => option.label === 'Investment Amount'
				);
				return defaultOption?.[0] ?? null;
			}
			if (label === 'Phone') {
				const defaultOption = options?.filter(
					option => option.label === 'Mobile'
				);
				return defaultOption?.[0] ?? null;
			} else {
				const defaultOption = options?.filter(option => option.label === label);
				return defaultOption?.[0] ?? null;
			}
		}
		return null;
	}, [options, label, same]);

	useEffect(() => {
		const finalXlsValue: any = window;
		finalXlsValue?.luckysheet.exitEditMode();
		const finalData = finalXlsValue?.luckysheet?.transToCellData?.(
			finalXlsValue?.luckysheet.getSheetData()
		);
		const expectedArray = finalData?.reduce((acc: any, curr: any) => {
			if (!acc[curr.r]) {
				acc[curr.r] = [];
			}
			if (curr.v.v) acc[curr.r][curr.c] = curr.v.v?.toString();
			return acc;
		}, []);
		if (same) {
			let value: any;
			if (label === 'Dollars Invested') {
				value = expectedArray[0].includes('Investment Amount')
					? { label: 'Dollars Invested', value: 'Investment Amount' }
					: null;
			} else if (label === 'Phone') {
				value = expectedArray[0].includes('Mobile')
					? { label: 'Mobile', value: 'Mobile' }
					: null;
			} else {
				value = expectedArray[0].includes(label)
					? { label, value: label }
					: null;
			}
			setSelectedItem(value);
			if (value) {
				setSelectedValue((prev: any) => {
					const index = prev.findIndex((item: any) => item[label]);
					if (index === -1) {
						return [...prev, { [label]: value?.value }];
					} else {
						const newArray = [...prev];
						newArray[index] = { [label]: value?.value };
						return newArray;
					}
				});
			}
		} else {
			let value: any;
			if (label === 'Dollars Invested') {
				value =
					expectedArray[0].includes('Investment Amount') ||
					expectedArray[0].includes('Amount To Pay') ||
					expectedArray[0].includes('Fund Transfer Amount')
						? { label: 'Dollars Invested', value: 'Investment Amount' }
						: { label: null, value: null };
			} else if (label === 'Phone') {
				value = expectedArray[0].includes('Mobile')
					? { label: 'Mobile', value: 'Mobile' }
					: { label: null, value: null };
			} else {
				value = expectedArray[0].includes(label)
					? { label, value: label }
					: { label: null, value: null };
			}
			if (value?.value === null) {
				setConfigVisible(prev => {
					return { ...prev, [label]: true };
				});
				setSelectedItem(null);
			} else {
				setConfigVisible(prev => {
					return { ...prev, [label]: false };
				});
				setSelectedItem(value);
				setSelectedValue(prev => {
					const index = prev.findIndex(item => item[label]);
					if (index === -1) {
						return [...prev, { [label]: value.value }];
					} else {
						const newArray = [...prev];
						newArray[index] = { [label]: value.value };
						return newArray;
					}
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [label, same, isRequired]);

	useEffect(() => {
		const filtered = Object.keys(configVisble).filter(function (key) {
			return configVisble[key];
		});
		if (
			pipelineDetails?.fullStatus?.includes('payOut') ||
			pipelineDetails?.fullStatus?.includes('payIn') ||
			!pipelineDetails?.fullStatus
		) {
			setShowSeletedValue(filtered);
		} else {
			const filterValue = filtered?.filter(el => el !== 'Dollars Invested');
			setShowSeletedValue(filterValue);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isReset, configVisble, pipelineDetails?.fullStatus]);

	const renderCongigRows = useMemo(() => {
		return (
			<>
				{configVisble[label] ? (
					<div className="captable-config__row">
						<div className="captable-config__row-label">
							<span className="captable-config__row-label-text">
								{pipelineDetails?.fullStatus?.includes('payOut')
									? CHANGE_ROW_LABLE_FUND_OUT[label] ?? label
									: CHANGE_ROW_LABLE[label] ?? label}
								{isOptional && <span> (Optional)</span>}
							</span>
							{isRequired && (
								<span className="captable-config__row-label-required">*</span>
							)}
						</div>
						<div className="captable-config__row-dropdown-wrapper">
							{filterValue && (
								<ReactDropdown
									options={filterValue}
									optionsStyle={{
										'&:hover': { background: 'var(--color-hover-light-100)' },
									}}
									defaultValue={handleDefault() as any}
									handleChangeSelect={handleChangeSelect}
									value={selectedItem}
									isSearchable
								/>
							)}
						</div>
					</div>
				) : (
					<></>
				)}
			</>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		configVisble,
		filterValue,
		isOptional,
		isRequired,
		label,
		pipelineDetails?.fullStatus,
		selectedItem,
	]);

	return <>{renderCongigRows}</>;
};
