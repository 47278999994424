import type { ActivityActionType } from 'views/dashboard-analytics/stores';

import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { OverviewFlowAnalyticsAtom } from 'views/dashboard-analytics/stores';
import { ActivityActionName, ChartTitleActivity } from '../analyticsOverview';
import { PieChart } from '../dashboard-chart';
import './onboarding-flow-analytics.scss';

export const OnboardingFlowAnalytics = () => {
	const overviewFlowAnalytics = useRecoilValue(OverviewFlowAnalyticsAtom);
	const { data, loaded } = overviewFlowAnalytics ?? {};

	const renderCharts = useMemo(() => {
		return ActivityActionName.map(item => (
			<div key={item} className="chart-container">
				<PieChart
					isLoading={!loaded && !data?.length}
					title={ChartTitleActivity?.[item] as string}
					approved={data?.[0]?.[item as ActivityActionType]?.completed || 0}
					rejected={data?.[0]?.[item as ActivityActionType]?.rejected || 0}
					underReview={data?.[0]?.[item as ActivityActionType]?.processing || 0}
					pending={data?.[0]?.[item as ActivityActionType]?.pending || 0}
				/>
			</div>
		));
	}, [data, loaded]);

	return (
		<div className="onboarding-flow-analytics">
			<div className="onboarding-flow-analytics__header">Analytics</div>
			<div className="onboarding-flow-analytics_container">{renderCharts}</div>
		</div>
	);
};
