import { FC, useEffect } from "react"

interface ISimpliciSignOverlay {
    handleCreateTemplate: () => void;
}

export const SimpliciSignOverlay:FC<ISimpliciSignOverlay> = ({handleCreateTemplate}) => {
    
    useEffect(() => {
        handleCreateTemplate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return <></>
}