import { FC, useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { KybInfoModalState } from 'views/sessions/components/kyb-wrapper/hooks';

interface IProps {
	label: string;
	value: string | any;
}

export const InfoSection: FC<IProps> = ({ label, value }) => {
	const setKybSubInfoModal = useSetRecoilState(KybInfoModalState);

	const handleViewClick = useCallback(() => {
		setKybSubInfoModal(prevState => ({
			...prevState,
			data: value,
			isOpen: true,
			label: label,
			modalType: 'simple',
		}));
	}, [label, setKybSubInfoModal, value]);

	const IsValueHavevalue = useCallback(() => {
		return value?.value?.length === 0;
	}, [value]);

	return (
		<div key={label} className="kyb-wrapper__details__kybInfoDetailsRow">
			<div className="kyb-wrapper__details__kybInfoDetailsRow__label">
				{label || '--'}
			</div>
			<div className="kyb-wrapper__details__kybInfoDetailsRow__value">
				<span>:</span>
				{IsValueHavevalue() ? (
					0
				) : (
					<div
						onClick={() => handleViewClick()}
						className="kyb-wrapper__details__kybInfoDetailsRow__more-btn"
					>
						View
					</div>
				)}
			</div>
		</div>
	);
};
