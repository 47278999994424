import {
	Dispatch,
	FC,
	SetStateAction,
	createRef,
	useCallback,
	useMemo,
	useState,
	ChangeEvent,
	useEffect,
} from 'react';
import { Button, Input, Loader } from '@storybook';
import Modal from '@storybook/new-modal/modal';
import {
	SignDashboardState,
	useSignDashboard,
	VALID_TITLE_REGEX,
} from '../store';
import { useRecoilState } from 'recoil';

//Ankur Singh: Define an interface for the component's props, specifying their types.
interface ISubscribeModal {
	isOpen: boolean;
	setModalClose: Dispatch<SetStateAction<boolean>>;
	loading: boolean;
}

export const TitleModal: FC<ISubscribeModal> = ({ isOpen, setModalClose }) => {
	//Ankur Singh: Create a reference for an HTML div element.
	const ref = createRef<HTMLDivElement>();

	//Ankur Singh: Use the `useSignDashboard` hook to obtain `handleSaveTitle` and `isLoaded`.
	const { handleSaveTitle, isLoaded } = useSignDashboard();

	const [signDashboardValue, setSignDashboard] =
		useRecoilState(SignDashboardState);

	//Ankur Singh: Initialize the `titleName` state variable with the current title value.
	const { title: titleValue } = signDashboardValue.data;
	const [titleName, setTitleName] = useState<string>(titleValue || '');

	//Ankur Singh: Use useEffect to set Recoil value in local state
	useEffect(() => {
		let timeoutId: string | number | NodeJS.Timeout | undefined;
		if (!isOpen) {
			timeoutId = setTimeout(() => {
				setTitleName('');
			}, 2000);
		} else {
			setTitleName(titleValue || '');
		}
		return () => {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
		};
	}, [isOpen, titleValue]);

	//Ankur Singh: Define a callback function to handle changes in the input field.
	const handleChangeName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		const titleValue = value.replace(VALID_TITLE_REGEX, '');
		setTitleName(titleValue);
	}, []);

	//Ankur Singh: Define a callback function for saving the title when not loading.
	const handleSave = useCallback(() => {
		if (!isLoaded) {
			const payload = {
				title: titleName?.trim(),
			};
			handleSaveTitle(payload).then(res => {
				if (res?._id) {
					setSignDashboard(prev => {
						const newObj = structuredClone(prev);
						newObj.data['title'] = res.title ?? '';
						return newObj;
					});
				}
				setModalClose(false);

			});
		}
	}, [isLoaded, setSignDashboard, titleName, handleSaveTitle, setModalClose]);

	//Ankur Singh: Determine the label for the "Save" button based on the `isLoaded` flag.
	const titleLabel = useMemo(() => {
		return isLoaded ? (
			<Loader type="loader" dimension={20} className="loader-white" />
		) : (
			'Save'
		);
	}, [isLoaded]);

	const renderTitle = useMemo(() => {
		return (
			<div className="title-modal">
				<div className="title-modal__wrapper">
					<div className="title-modal__container">
						<div className="title-modal__head">Add your title</div>
						<div className="title-modal__desc">Add your professional title</div>
					</div>
					<div className="title-modal__input-field">
						<Input
							label={'Title'}
							maxLength={25}
							inputType="text"
							placeholder="Director"
							value={titleName as string}
							handleChange={handleChangeName}
							autoFocus={true}
						/>
						<div className="title-modal__desc">
							By adding your professional title, you authorize its automatic use
							in documents.
						</div>
					</div>

					<div className="title-modal__footer ">
						<Button
							label={titleLabel}
							type="button__filled--primary  button__large"
							handleClick={handleSave}
							disabled={(titleName.trim())?.length <= 1}
						/>
					</div>
				</div>
			</div>
		);
	}, [handleChangeName, handleSave, titleLabel, titleName]);

	return (
		<div ref={ref}>
			<Modal
				isOpen={isOpen}
				modalName="Title modal"
				closeModal={() => setModalClose(false)}
				className="title-set-modal"
				showCloseBtn={true}
				isStopOutsideClick={true}
			>
				{renderTitle}
			</Modal>
		</div>
	);
};
