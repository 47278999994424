import { IClientSecret } from '../type';
import { getDateWithTime } from 'utils';
import {
	FC,
	useCallback,
	ChangeEvent,
	useState,
	useEffect,
	useMemo,
} from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Button, Input } from '@storybook';

import { useNotification } from 'hooks';
import {
	ClientNameState,
	ClientsCredentialsState,
	duplicateClientNameCheckState,
} from 'global-stores';
import { message } from 'constant';
import '../credential.scss';

export const EditSecretModal: FC<IClientSecret> = ({
	name,
	clientId,
	clientSecret,
	creationDate,
	error = false,
}) => {
	const { successNotification } = useNotification();
	const setCredentialName = useSetRecoilState(ClientNameState);
	const credentialsData = useRecoilValue(ClientsCredentialsState);

	const clientNames = credentialsData.map(({ name }) => {
		return name;
	});

	const { COPY_TOASTER_MESSAGE, VALID_NAME_MESSAGE, DUPLICATE_NAME_MESSAGE } =
		useMemo(() => message, []);
	const [showError, setShowError] = useState<boolean>(error);
	const [showDuplicateError, setShowDuplicateError] = useRecoilState<boolean>(
		duplicateClientNameCheckState
	);

	const handleCopy = useCallback(
		(secretId: string) => {
			navigator.clipboard.writeText(secretId);
			successNotification(COPY_TOASTER_MESSAGE);
		},
		[COPY_TOASTER_MESSAGE, successNotification]
	);
	const [assetsName, setAssetsName] = useState<string>(name);
	const handleEditName = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		const lowerCaseValue = value.toLowerCase();
		if (value.length >= 3) {
			setShowError(false);
			if (clientNames.some(name => name.toLowerCase() === lowerCaseValue)) {
				setShowDuplicateError(true);
			} else {
				setShowDuplicateError(false);
			}
		} else {
			setShowError(true);
		}
		setAssetsName(value);
	};

	const handleClearInputField = useCallback(() => {
		setAssetsName('');
		setShowError(true);
	}, []);

	useEffect(() => {
		setCredentialName(assetsName);
	}, [assetsName, setCredentialName]);

	return (
		<div className="edit-crendentials">
			<Input
				handleChange={handleEditName}
				inputType="text"
				placeholder="Enter Name"
				label="Name"
				value={assetsName}
				allowClear={true}
				errorMessage={
					showError
						? VALID_NAME_MESSAGE
						: showDuplicateError
						? DUPLICATE_NAME_MESSAGE
						: ''
				}
				isError={showError || showDuplicateError}
				isShowButton={false}
				isCountryCodeVisible={false}
				handleClearField={handleClearInputField}
				maxLength={32}
			/>

			{clientId && (
				<div className="client-id">
					<div className="copyClient">
						<span className="label">Client ID</span>
						<Button
							handleClick={() => handleCopy(clientId ?? '')}
							label={<i className="ri-file-copy-line copy icon__primary" />}
							type="button"
							minHeight="16px"
							height="20px"
						/>
					</div>
					<span className="client-id-value value">{clientId}</span>
				</div>
			)}

			{clientSecret && (
				<div className="client-secret">
					<div className="copyClient">
						<span className="label">Client Secret</span>
						<Button
							handleClick={() => handleCopy(clientSecret ?? '')}
							label={<i className="ri-file-copy-line copy icon__primary" />}
							type="button"
							minHeight="16px"
							height="20px"
						/>
					</div>
					<span className="secret-value value">{clientSecret}</span>
				</div>
			)}

			<div className="creation-date">
				<span className="label">Creation Date</span>
				<span className="value">{getDateWithTime(creationDate as any)}</span>
			</div>
		</div>
	);
};
