export const INTEGRATE_ACTIVE_TABS = {
    HOME: 'home',
    ONBOARDING: 'onboarding',
    ONBOARDING_FLOW: 'onboarding/flow',
    FUNDS: 'funds',
    SESSIONS: 'sessions',
    USER_ROLES: 'user-roles',
    SIMPLICI_SIGN_DASHBOARD: 'esign-dashboard',
    DEVELOPER_KEYS: 'credentials',
};

export const INTEGRATE_LEFT_NAV = [
    {
        TITLE: 'Home',
        ICON: 'ri-home-4-line',
        ROUTE: INTEGRATE_ACTIVE_TABS.HOME,
    },
    {
        TITLE: 'Onboarding',
        ICON: 'ri-list-check-2',
        ROUTE: INTEGRATE_ACTIVE_TABS.ONBOARDING,
    },
    {
        TITLE: 'Onboarding Flow',
        ICON: 'ri-team-line',
        ROUTE: INTEGRATE_ACTIVE_TABS.ONBOARDING_FLOW,
    },
    {
        TITLE: 'Funds',
        ICON: 'ri-funds-line',
        ROUTE: INTEGRATE_ACTIVE_TABS.FUNDS,
    },
    {
        TITLE: 'Developer Keys',
        ICON: 'ri-key-2-fill',
        ROUTE: INTEGRATE_ACTIVE_TABS.DEVELOPER_KEYS,
    },
    {
        TITLE: 'Sessions',
        ICON: 'ri-contacts-line',
        ROUTE: INTEGRATE_ACTIVE_TABS.SESSIONS,
    },
    {
        TITLE: 'User Roles',
        ICON: 'ri-team-line',
        ROUTE: INTEGRATE_ACTIVE_TABS.USER_ROLES,
    },
    {
        TITLE: 'esign Dashboard',
        ICON: 'ri-draft-line',
        ROUTE: INTEGRATE_ACTIVE_TABS.SIMPLICI_SIGN_DASHBOARD,
    },
    // {
    //   TITLE: "Settings",
    //   ICON: "ri-settings-5-line",
    // },
];
