import { PermissionTableType } from 'global-stores';

export const UserRolesHeaders = {
	HEADER: [
		{
			label: 'First Name',
			key: 'firstName',
			format: 'string',
			width: '30%',
		},
		{
			label: 'Last Name',
			key: 'lastName',
			format: 'string',
			width: '30%',
		},
		{
			label: 'Role',
			key: 'role.name',
			format: 'string',
			width: '15%',
		},
		{
			label: 'Status',
			key: 'status',
			format: 'jsx',
			className: 'userRole-status',
			width: '15%',
		},
		{
			label: 'Actions',
			key: 'action',
			format: 'jsx',
			className: 'userRole-action',
			width: '140px',
		},
	],
};

export const Roles = [
	{
		label: 'Admin',
		value: 'ADMIN',
	},
	{
		label: 'Developer',
		value: 'DEVELOPER',
	},
	{
		label: 'Portfolio Manager',
		value: 'PORTFOLIO MANAGER',
	},
];

export type IRolesType =
	| 'OWNER'
	| 'ADMIN'
	| 'DEVELOPER'
	| 'MANAGER'
	| 'SUPER_ADMIN'
	| 'AGENT'
	| 'FundAccess';

export const UserRolesSubTitle: Record<
	IRolesType,
	{ subTitle: string; subInfo: string }
> = {
	OWNER: {
		subTitle: 'Give developers access',
		subInfo:
			'Developer account has access to manage pipelines along with credentials and settings.',
	},
	ADMIN: {
		subTitle: 'Give developers access',
		subInfo:
			'Developer account has access to manage pipelines along with credentials and settings.',
	},
	DEVELOPER: {
		subTitle: 'Give developers access',
		subInfo:
			'Developer account has access to manage pipelines along with credentials and settings.',
	},
	MANAGER: {
		subTitle: 'Give developers access',
		subInfo:
			'Developer account has access to manage pipelines along with credentials and settings.',
	},
	SUPER_ADMIN: {
		subTitle: 'Give developers access',
		subInfo:
			'Developer account has access to manage pipelines along with credentials and settings.',
	},
	AGENT: {
		subTitle: 'Change Session Status',
		subInfo: 'Agent can change sessions detail status as well.',
	},
	FundAccess: {
		subTitle: 'Give developers access',
		subInfo:
			'Developer account has access to manage pipelines along with credentials and settings.',
	},
};

export const ChooseServicesData = [
	{
		type: 'individual',
		label: 'esign',
		accountTypes: [
			{ bussiness: 'Person/Individual', icon: 'ri-user-line' },
		],
		key: ['esign'],
	},

	{
		type: 'entity',
		label: 'Onboarding + esign',
		accountTypes: [{ bussiness: 'Entity/Business', icon: 'ri-briefcase-line' }],
		key: ['esign', 'onboarding'],
	},
];

export const AddUserRoleLabel = {
	PermissionHeader: 'Permissions',
	PermissionDescription: 'Select permissions you want to give it to this role',
	CreateNewRoleHeader: 'No role available',
	CreateNewRoleDescription: 'You haven’t added any role yet',
};

export const AllowPermissionLabel = {
	Header: 'Permissions',
	Description: 'Select permissions you want to give to this role',
	Card: {
		Header: 'All access',
		Description:
			'Enable this option to grant this role unrestricted access to all available permissions. When turned on, individual permissions below will be disabled.',
	},
};

export const ServicesIcon: Record<string, string> = {
	entity: 'ri-briefcase-line',
	esign: '2-person.svg',
	individual: 'ri-user-line',
};

export const ServiceType = {
	Individual: 'individual',
	Company: 'entity',
	Esign: 'esign',
};
export const PermissionTableHeader = [
	'onboardingFlow',
	'esign',
	'settings',
	'integrate',
	'insights',
	'finance',
];

export const PermissionTableData: PermissionTableType[] = [
	{
		key: 'dashboard',
		label: 'Dashboard',
		icon: 'dashboard-line',
		services: [],
		isRead: false,
		isWrite: true,
	},
	{
		key: 'onboardingFlow',
		label: 'Onboarding',
		icon: 'list-check-2',
		services: [
			{
				key: 'onboarding',
				label: 'Onboarding Flow',
			},
			{ key: 'session', label: 'Session Details' },
			{ key: 'funds', label: 'Funds' },
		],
	},
	{
		key: 'esign',
		label: 'esign',
		icon: 'draft-line',
		services: [
			{ key: 'signDashboard', label: 'esign Dashboard' },
			{ key: 'template', label: 'esign Templates' },
			{ key: 'inbox', label: 'Inbox' },
			{ key: 'sent', label: 'Sent' },
		],
	},
	{
		key: 'finance',
		label: 'Finance',
		icon: 'hand-coin-line',
		services: [
			{ key: 'transaction', label: 'Transactions' },
			{ key: 'checkDefense', label: 'Check Defense' },
		],
	},
	{
		key: 'settings',
		label: 'Settings',
		icon: 'settings-5-line',
		services: [
			{ key: 'generalSettings', label: 'General Settings' },
			{ key: 'developersKeys', label: 'Developers Keys' },
			// { key: 'userRoles', label: 'User Roles' },
		],
	},
	{
		key: 'apiDocs',
		label: 'Integrate',
		icon: 'guide-fill',
		isWrite: true,
		services: [
			{ key: 'embedWeb', label: 'Embed Web', isRead: true, isWrite: true },
			{ key: 'apiDocs', label: 'API Docs', isRead: false, isWrite: true },
		],
	},
	// We have comment out the insights permissions until the insights module is enabled
	// {
	// 	key: 'insights',
	// 	label: 'Insights',
	// 	icon: 'bar-chart-2-line',
	// 	services: [
	// 		{ key: 'userAnalytics', label: 'User Analytics' },
	// 		{ key: 'supportAgent', label: 'Support Agent' },
	// 	],
	// },
	{
		key: 'billing',
		label: 'Billings',
		icon: 'bank-card-line',
		services: [],
	},
];

export const InviteAssinedUser = [
	{
		id: '1',
		key: 'user1',
		name: 'Kristen Stewart',
		account: 'Sub Account 1',
		icon: <i className="ri-more-2-line"></i>,
		profile: 'user_profile.svg',
	},
	{
		id: '2',
		key: 'user2',
		name: 'John Doe',
		account: 'Main Account 2',
		icon: <i className="ri-more-2-line"></i>,
		profile: 'user_profile.svg',
	},
	{
		id: '3',
		key: 'user3',
		name: 'Alice Johnson',
		account: 'Sub Account 3',
		icon: <i className="ri-more-2-line"></i>,
		profile: 'user_profile.svg',
	},
	{
		id: '4',
		key: 'user4',
		name: 'Bob Smith',
		account: 'Main Account 4',
		icon: <i className="ri-more-2-line"></i>,
		profile: 'user_profile.svg',
	},
	{
		id: '5',
		key: 'user5',
		name: 'Eva White',
		account: 'Sub Account 5',
		icon: <i className="ri-more-2-line"></i>,
		profile: 'user_profile.svg',
	},
	{
		id: '6',
		key: 'user6',
		name: 'Michael Johnson',
		account: 'Main Account 6',
		icon: <i className="ri-more-2-line"></i>,
		profile: 'user_profile.svg',
	},
	{
		id: '7',
		key: 'user7',
		name: 'Sophia Williams',
		account: 'Sub Account 7',
		icon: <i className="ri-more-2-line"></i>,
		profile: 'user_profile.svg',
	},
	{
		id: '8',
		key: 'user8',
		name: 'Daniel Brown',
		account: 'Main Account 8',
		icon: <i className="ri-more-2-line"></i>,
		profile: 'user_profile.svg',
	},
	{
		id: '9',
		key: 'user9',
		name: 'Olivia Davis',
		account: 'Sub Account 9',
		icon: <i className="ri-more-2-line"></i>,
		profile: 'user_profile.svg',
	},
	{
		id: '10',
		key: 'user10',
		name: 'Liam Miller',
		account: 'Main Account 10',
		icon: <i className="ri-more-2-line"></i>,
		profile: 'user_profile.svg',
	},
];

export const RolesAndPermissionData = [
	{
		id: 1,
		role: 'Admin',
		Description:
			'Reduce technical debt by refactoring legacy code and improving architecture design.',
		Permission: 'All Access',
	},
	{
		id: 2,
		role: 'Manager',
		Description:
			'Reduce technical debt by refactoring legacy code and improving architecture design.',
		Permission: 'Onboarding create',
	},
	{
		id: 3,
		role: 'Compliance agent',
		Description:
			'Reduce technical debt by refactoring legacy code and improving architecture design.',
		Permission: 'Session read',
	},
];

export const UserRoleHeaderData: Record<
	string,
	{ head: string; desc: string }
> = {
	AddNewRole: {
		head: 'Add new role',
		desc: 'Define roles, set permissions and assign to users for streamlined access',
	},
	CreateNewRole: {
		head: 'Create New Role',
		desc: 'Create role and add permissions',
	},
	NoRoleAvailable: {
		head: 'No role available',
		desc: 'You haven’t added any role yet',
	},
	EditRole: {
		head: 'Edit role',
		desc: 'Define roles, edit permissions and assign to users for streamlined access',
	},
};

export const RolesAndPermissionCardData = {
	Invite_User: 'Invite user',
	Edit_Role: 'Edit role',
	Delete_Role: 'Delete role',
};

export const RoleAndPermissionNavigation = {
	CreateNewRole: 'CreateNewRole',
	AddNewRole: 'AddNewRole',
	NoRoleAvailable: 'NoRoleAvailable',
	EditRole: 'EditRole',
};

export const UserRoleNavigation = {
	ComplainceAgent: 'ComplainceAgent',
	AddedRoleCard: 'AddedRoleCard',
	userListTable: 'userlist',
};

export const InviteNavigation = {
	InviteForm: 'InviteForm',
	InvitePreview: 'InvitePreview',
};

export const DisabledEsignPermission = [
	'onboardingFlow',
	'onboarding',
	'session',
	'funds',
];

export const TooltipMessage = {
	dashboard: 'Dashboard have only view permission',
	embedWeb: 'Embed Web will be enable when "All access" toggle get On',
	apiDocs: 'API Docs have only view permission',
};

export const UserRoleIds = {
	name: 'sub-account_name',
	description: 'sub-account_description',
	permission: 'sub-account_permission',
};
