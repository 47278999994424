import { FC, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { loginState, userState } from 'global-stores';
import Modal from '@storybook/new-modal/modal';
import { KycMobileHost, KycMobileHostSandbox } from 'helpers';

interface IReinviteKyc {
	open: boolean;
	handleClose: () => void;
	reinvitedStep: any;
}

export const ReInviteKyc: FC<IReinviteKyc> = ({
	open,
	handleClose,
	reinvitedStep,
}) => {
	const { step, code } = reinvitedStep ?? {};
	const userId = useRecoilValue(userState);
	const { sandboxStatus } = useRecoilValue(loginState)

	const url = useMemo(() => {
		return `${sandboxStatus ? KycMobileHostSandbox : KycMobileHost}/${code}?stepId=${step}&userID=${userId.userId}`;
	}, [code, step, userId.userId , sandboxStatus]);

	return (
		<Modal isOpen={open} closeModal={handleClose} modalName="kyc-reinvite">
			<div>
				<iframe
					title="Reinvite"
					src={url}
					width="400px"
					height="632px"
					allow="camera; microphone"
				/>
			</div>
		</Modal>
	);
};
