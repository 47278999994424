import { useCallback, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useNetwork } from 'hooks';
import { kycSettingsState } from 'global-stores';
import { API_URL } from 'constant';
import {
	ActiveEmailClientFormDataState,
	ActiveEmailClientMethodState,
	ResetCredentialsState,
} from './states';
import { SUPPORTED_CLIENT, avoidTrimKeys } from '../constants';

export const useResetCredentials = () => {
	// global state
	const resetAllCredentials = useSetRecoilState(ResetCredentialsState);

	const resetCredential = useCallback(() => {
		const credentialKeys: string[] = [];
		SUPPORTED_CLIENT.map(({ methods }) => {
			return methods.map(method => {
				credentialKeys.push(method.key);
			});
		});
		credentialKeys.map(key => {
			resetAllCredentials(key);
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return { resetCredential };
};

export const useEmailClient = () => {
	// global states
	const settingsKyc = useRecoilValue(kycSettingsState);
	const activeEmailClientMethod = useRecoilValue(ActiveEmailClientMethodState);
	const credentialData = useRecoilValue(
		ActiveEmailClientFormDataState(activeEmailClientMethod)
	);

	// hooks
	const { post } = useNetwork();

	const { whitelabel, whitelabelKey, whitelistConfig } = settingsKyc ?? {};

	const finalCredentials = useMemo(() => {
		const newCredential = { ...credentialData };
		Object.keys(newCredential).forEach(key => {
			if(!avoidTrimKeys[key as "userName"])
			newCredential[key] = newCredential[key]?.trim() as string;
		});
		return { ...newCredential };
	}, [credentialData]);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { _id, ...rest } = whitelistConfig ?? {};

	const updateEmailClient = useCallback(async () => {
		const payload = {
			whitelabel: whitelabel ?? false,
			whitelabelKey: activeEmailClientMethod,
			whitelistConfig: whitelistConfig
				? { ...rest, [activeEmailClientMethod]: finalCredentials }
				: { [activeEmailClientMethod]: finalCredentials },
		};
		const resp = await post(API_URL.CLIENT_DETAILS, payload);
		return resp;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [whitelabel, activeEmailClientMethod, whitelistConfig, rest, finalCredentials]);

	const removeEmailClient = useCallback(
		async (name: string) => {
			const newRest = Object.keys(whitelistConfig)
				.filter(key => {
					return key !== name && key !== '_id';
				})
				.reduce((obj: any, index) => {
					obj[index] = whitelistConfig[index];
					return obj;
				}, {});

			const newWhiteKey = Object.keys(newRest).at(-1);

			const payload = {
				whitelabel: whitelabel ?? false,
				whitelabelKey:
					name === whitelabelKey
						? Object.keys(newRest).length === 0
							? ''
							: newWhiteKey
						: whitelabelKey,
				whitelistConfig: { ...newRest },
			};
			const resp = await post(API_URL.CLIENT_DETAILS, payload);
			return resp;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[whitelistConfig, whitelabel, whitelabelKey]
	);

	const changeEmailClient = useCallback(async (key: string) => {
		const payload = {
			whitelabel: whitelabel ?? false,
			whitelabelKey: key,
		};
		const resp = await post(API_URL.CLIENT_DETAILS, payload);
		return resp;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [whitelabel]);

	return { updateEmailClient, removeEmailClient, changeEmailClient };
};
