/* eslint-disable no-console */
export class WebWorker {
    static worker: Worker;
    static apiWorker: any = {
      // doing for web component
      // will replace once we find the solution to use web worker in web component
      postMessage: (data: any) => {
        const { endpoint, config, payload, nodeId } = data;
        fetch(endpoint, config)
          .then(async res => {
            const apiData = await res.json();
            return {
              status: res.status,
              ...(apiData ?? {}),
            };
          })
          .then(apiData => {
            WebWorker.worker?.onmessage?.({
              data: {
                nodeId,
                ...JSON.parse(
                  JSON.stringify({ ...apiData, ...payload })
                ),
              },
            } as any);
          })
          .catch(err => {
            WebWorker.worker?.onmessage?.({
              data: { nodeId, ...JSON.parse(JSON.stringify({ err })) },
            } as any);
          });
      },
    }
  
    static init() {
      WebWorker.worker = WebWorker.apiWorker as any;

      const url = './worker.js'
      fetch(url)
        .then(response => {
          if (response.ok) {
            // The file exists, try to create the worker
            try {
              const worker = new Worker(url);
              worker.onerror = function(error) {
                WebWorker.worker = WebWorker.apiWorker;
                console.error('Worker error:', error.message);
              };
              WebWorker.worker = worker;
              // Worker created successfully, you can send a message or do other actions here
            } catch (error) {
              WebWorker.worker = WebWorker.apiWorker;
              console.error('Error creating worker:', error);
            }
          } else {
            WebWorker.worker = WebWorker.apiWorker;
            console.error('Worker script not found:', url);
          }
        })
        .catch(error => {
          console.error('Error fetching worker script:', error);
          WebWorker.worker = WebWorker.apiWorker;
        });
    }
  }
  
  WebWorker.init();