import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Loader } from '@storybook';
import { useNetwork, useNotification, useTimer } from 'hooks';
import { message as errorMessage, validateEmail } from 'constant';
import {
	ApprovalStatusState,
	CountryCode,
	LoginCredsState,
	LoginMaskPhoneCredsState,
	LoginStep,
	VerificationIDState,
	useWebLogin,
} from '../../store';
import { WEBAUTH_URL } from 'components/login/store';

import './biometric-approval-sent.scss';

export const BiometricApprovalSent = () => {
	// globel state
	const country = useRecoilValue(CountryCode);
	const maskPhoneCreds = useRecoilValue(LoginMaskPhoneCredsState);
	const setStep = useSetRecoilState(LoginStep);
	const verificationID = useRecoilValue(VerificationIDState);
	const setVerificationStatus = useSetRecoilState(ApprovalStatusState);
	const phone = useRecoilValue(LoginCredsState);

	// local state
	const [checkStatus, setCheckStatus] = useState(true);
	const [count, setCount] = useState(1);

	// hooks
	const { get } = useNetwork({ updateState: false, returnResponse: true });
	const { errorNotification, successNotification } = useNotification();
	const { seconds, setSeconds } = useTimer();
	const { registrationApproval } = useWebLogin();

	const isResendAllowed = useMemo(() => !seconds, [seconds]);

	const handleResendOTP = useCallback(async () => {
		if (!isResendAllowed || count > 3) return;

		setCount(prev => prev + 1);
		if (count === 1) {
			setSeconds(40);
		} else if (count === 2) {
			setSeconds(60);
		}

		const contactInfo = validateEmail(phone)
			? { email: phone }
			: { phone, countryCode: country };

		const approvalPayload = {
			type: 'registrationApproval',
			deviceInfo: navigator.userAgent ?? {},
			...contactInfo,
		};

		const response = await registrationApproval(approvalPayload);
		if (!response) return;

		const { message, success, verificationId, errorData } = response;
		if (success && verificationId) {
			setStep('BIOMETRIC_APPROVAL');
			successNotification(message);
		} else {
			const errorMessageToShow =
				message ||
				(errorData?.message ?? errorMessage.SomethingWentWrongMessage);
			errorNotification(errorMessageToShow);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isResendAllowed, count, phone, registrationApproval, country]);

	const onClickIntercom = () => {
		(window as any)?.Intercom?.(
			'showNewMessage',
			'Unable to Login, Not receiving message'
		);
	};

	// handle back
	const handleClickBtn = useCallback(() => {
		setStep('VERIFY');
	}, [setStep]);

	const verifyPhone = useCallback(async () => {
		const verficationUrl = `${WEBAUTH_URL.CLIENT_VERIFY_PHONE}/${verificationID}`;
		const res = await get(verficationUrl);

		const { status } = res?.apiPayload?.data ?? {};
		if (status === 'rejected' || status === 'approved') {
			setStep('VERIFY');
		}
		if (res?.errorCode === 400 && res?.errorData?.message) {
			errorNotification(res.errorData.message);
			setStep('VERIFY');
		}
		setVerificationStatus(status);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [get, setStep, setVerificationStatus, verificationID]);

	const formattedSeconds = useMemo(
		() => (seconds < 10 ? `0${seconds}` : seconds),
		[seconds]
	);

	useEffect(() => {
		if (verificationID !== '') {
			verifyPhone();
		}
		setTimeout(() => {
			setCheckStatus(pre => !pre);
		}, 4000);
		// eslint-disable-next-line
	}, [checkStatus, verificationID]);

	return (
		<div className="biometric-approval">
			<div className="biometric-approval__wrapper">
				<div className="biometric-approval__row">
					<div className="biometric-approval__title">Waiting for approval</div>
					<div className="biometric-approval__description">
						We have sent you a text message to{' '}
						<strong className="biometric-approval__mobile-number">
							{' '}
							{` ${country} ${maskPhoneCreds}`}
						</strong>{' '}
						and an email with an approval link. Kindly open either the link
						provided in the text message or the one in the email to approve from
						there.
					</div>
				</div>
				<div className="biometric-approval__row biometric-approval__row-1 biometric-approval__vertical-space">
					<Loader className="loader-blue" dimension={60} />
					{/* <div className="biometric-approval__number">{securityCode}</div>
              <div className="biometric-approval__specification">
                Tap <strong>{securityCode}</strong> on your phone to verify your
                phone number{" "}
              </div> */}
				</div>
				<div className="biometric-approval__row">
					<Button
						label="Back"
						type="button__filled--secondary button__large button__block"
						handleClick={handleClickBtn}
					/>
				</div>
				{count !== 4 ? (
					<>
						{isResendAllowed ? (
							<div className="verify-modal__footer--resend">
								<span className="footer-text">
									Didn&apos;t receive the text message?
								</span>
								<span
									className="footer-resend-btn"
									onClick={() => handleResendOTP()}
								>
									Resend
								</span>
							</div>
						) : (
							<div className="verify-modal__footer">
								<span className="footer-text">
									Time remaining for next OTP 00:{formattedSeconds}
								</span>
							</div>
						)}
					</>
				) : (
					<div
						className="verify-modal__footer verify-modal--connect"
						onClick={onClickIntercom}
					>
						<div className="verify-modal--icon">
							<i className="ri-user-voice-line"></i>
						</div>
						<span className="footer-resend-btn">
							Facing trouble? Click here for assistance.
						</span>
					</div>
				)}
			</div>
		</div>
	);
};
