import React from 'react';
import { Button, Image, Input, Loader } from '@storybook';

import { Message } from 'components';
import { useCancelSubscriptionConfirmation } from '../../stores';

interface ICancelSubscriptionConfirmation {
	handleClose: () => void; // Function to handle the close button click
}

export const CancelSubscriptionConfirmation: React.FC<
	ICancelSubscriptionConfirmation
> = ({ handleClose }) => {
	// Destructure the handleChange function and confirmationTxt state from the custom hook
	const {
		handleChange,
		confirmationTxt,
		handleCancelSubscription,
		fetchingDues,
		handleKeyPress,
	} = useCancelSubscriptionConfirmation();

	return (
		<div className="Cancel_Subscription__confirmation">
			{/* Header Section */}
			<div className="Cancel_Subscription__header">
				{/* Display an image for the confirmation page */}
				<Image fileName="confirmation_page.svg" height="96px" width="96px" />
				{/* Heading text prompting the user to confirm cancellation */}
				<p className="Cancel_Subscription__heading">
					Are you sure you want to cancel your subscription?
				</p>
			</div>

			{/* Body Section */}
			<div className="Cancel_Subscription__body">
				{/* Informational text about the impact of cancellation */}
				<p>
					Upon cancellation, all benefits and features will stop immediately.
					You will have read-only access to your data but will no longer be able
					to make changes or use any active features.
				</p>

				{/* Warning message about the reactivation fee */}
				<Message
					text={
						<>
							<strong>Note:</strong> Reactivation fee <strong>($250)</strong>{' '}
							will apply if you decide to restore your account after
							cancellation.
						</>
					}
					varient="warning"
					icon="ri-information-line"
				/>

				{/* Input field for user confirmation by typing 'Cancel' */}
				<Input
					label={
						<>
							Please type <strong>Cancel</strong> to confirm.
						</>
					}
					inputType="text"
					placeholder=""
					value={confirmationTxt} // Controlled input value from the hook
					handleChange={handleChange} // Function to update the input value
					handleKeyPress={handleKeyPress}
					disabled={fetchingDues}
				/>
			</div>

			{/* Footer Section */}
			<div className="Cancel_Subscription__footer">
				{/* Button to confirm subscription cancellation */}
				<Button
					label={
						fetchingDues ? (
							<Loader type="loader" dimension={26} />
						) : (
							'Yes, Cancel Subscription'
						)
					}
					type="button button__filled--primary danger-btn"
					handleClick={handleCancelSubscription} // Trigger the next step on click
					disabled={confirmationTxt.toLowerCase() !== 'cancel' || fetchingDues} // Enable only when the user types 'Cancel'
				/>

				{/* Button to close the modal and keep the subscription */}
				<Button
					label="Keep Subscription"
					type="button button__filled--primary keep-btn"
					handleClick={handleClose} // Trigger the close action on click
					disabled={fetchingDues}
				/>
			</div>
		</div>
	);
};
