import type { IFunnelChartState } from 'views/analytics/hooks';

import { FC, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
	CompareAverageTimeState,
	FlowAverageTimeState,
	NodeTitleCompareFunnelChartState,
	NodeTitleFunnelChartState,
	comparedFunnelChartsState,
	useSankeyChart,
} from 'views/analytics/hooks';
import './analytics-chart.scss';

interface ISankeyDiagram {
	isLoading: boolean;
	title: string;
	seriesData: IFunnelChartState;
}

export const SankeyDiagram: FC<ISankeyDiagram> = ({
	title,
	seriesData,
	isLoading,
}) => {
	const { getSankeyConfig } = useSankeyChart();

	const comparedChart = useRecoilValue(comparedFunnelChartsState);
	const flowAverageTime = useRecoilValue(
		title === 'onboarding' ? FlowAverageTimeState : CompareAverageTimeState
	);
	const setNodeTitle = useSetRecoilState(
		title === 'onboarding'
			? NodeTitleFunnelChartState
			: NodeTitleCompareFunnelChartState
	);

	useEffect(() => {
		getSankeyConfig({
			isLoading,
			seriesData,
			title,
			setNodeTitle,
			flowAverageTime,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		isLoading,
		seriesData,
		setNodeTitle,
		comparedChart,
		flowAverageTime,
		title,
	]);

	return (
		<div className="analytic-chart-wrapper">
			<div id={title} />
		</div>
	);
};
