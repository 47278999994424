export const DEALS_TABLE_HEADER = [
	{
		label: 'Deal ID',
		key: 'dealId',
		format: 'jsx',
		width: '8%',
	},
	{
		label: 'Deal Name',
		key: 'name',
		format: 'jsx',
		width: '12%',
	},
	{
		label: 'Date',
		key: 'date',
		format: 'jsx',
		width: '12%',
	},
	{
		label: 'Sellers',
		key: 'sellers',
		format: 'jsx',
		width: '12%',
	},
	{
		label: 'Buyers',
		key: 'buyers',
		format: 'jsx',
		width: '12%',
	},
	{
		label: 'Type',
		key: 'type',
		format: 'jsx',
		width: '12%',
	},
	{
		label: 'Amount',
		key: 'amount',
		format: 'jsx',
		width: '12%',
	},
	{
		label: 'Status',
		key: 'status',
		format: 'jsx',
		width: '12%',
	},
	{
		label: 'Action',
		key: 'action',
		format: 'jsx',
		width: '5%',
	},
];

export const dealsTransactionType = (type: string) => {
	return type !== 'express' ? 'protect' : type;
};

export const dealsTransactionStatusClass = (type: string) => {
	switch (type) {
		case 'completed':
		case 'processing':
		case 'rejected':
		case 'canceled':
			return `Deals__status-${type}`;
		default:
			return 'Deals__status-pending';
	}
};
export const dealsTransactionStatus = (type: string) => {
	switch (type) {
		case 'completed':
			return 'Completed';
		case 'processing':
			return 'In Progress';
		case 'canceled':
			return 'Canceled';
		default:
			return 'Pending';
	}
};

export const DEAL_USERS = [
	{
		key: 'sellers',
		label: 'Sellers',
		icon: 'ri-download-2-line',
		info: [
			{
				key: 'name',
				label: 'Name',
			},
			{
				key: 'email',
				label: 'Email',
			},
			{
				key: 'phone',
				label: 'Phone',
			},
			{
				key: 'sessionDetails',
				label: 'Session Details',
			},
		],
	},
	{
		key: 'buyers',
		label: 'Buyers',
		icon: 'ri-upload-2-line',
		info: [
			{
				key: 'name',
				label: 'Name',
			},
			{
				key: 'email',
				label: 'Email',
			},
			{
				key: 'phone',
				label: 'Phone',
			},
			{
				key: 'sessionDetails',
				label: 'Session Details',
			},
		],
	},
];

const colorList = [
	'#389F75',
	'#7935DE',
	'#F545AE',
	'#EF4343',
	'#2269D3',
	'#ECC527',
];

export const getColorForName = (index?: number) => {
	if (index === undefined) return '#515767';
	const colorIndex = index % colorList.length;
	return colorList[colorIndex];
};

export const initialConfirmation = {
	visible: false,
	dealId: '',
	name: '',
};


export const initialFilterOpenValue: { [key: string]: boolean | undefined } = {
	status: false,
};

export const DEAL_FILTER = [
	{
		key: 'status',
		label: 'Status Filter',
		filterList: [
			{ name: 'Pending', value: 'pending' },
			{ name: 'Completed', value: 'completed' },
			{ name: 'In Progress', value: 'processing' },
			{ name: 'Canceled', value: 'canceled' },
		],
	},
];