import { Skeleton } from '@storybook';
import { FC } from 'react';
import { useRecoilValue } from 'recoil';

import { SignDashboardSignatureInitialedState, SignDashboardSignatureState } from '../store';

interface IProps {
	url: string | undefined;
	defaultImage: string;
	className: string;
	skeletonWidth: number;
	alt?: string;
}

export const UserSignature: FC<IProps> = ({
	defaultImage,
	url,
	className,
	skeletonWidth,
	alt = 'image',
}) => {
	const { isLoaded } = useRecoilValue(SignDashboardSignatureState);
	const isSignGenerated = useRecoilValue(SignDashboardSignatureInitialedState);

	return (
		<div className={'user-dashboard__image-Text'}>
			<div className={'user-dashboard__sign-img-container'}>
				{!isLoaded || !isSignGenerated ? (
					<Skeleton width={skeletonWidth} height={15} />
				) : (
					<img
						src={url ?? defaultImage}
						alt={alt}
						className={className}
						loading="lazy"
						style={{ height: '70px' }}
					/>
				)}
			</div>
		</div>
	);
};
