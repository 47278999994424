import { AdminSkelton } from '@storybook';
import {
	IntegrateHeader,
	Playground,
	IntegrateModifyOptions,
} from './components';
import './integrate.scss';

export const Integrate = () => {
	return (
		<AdminSkelton
			bodyElement={<Playground />}
			rightElement={<IntegrateModifyOptions />}
			headerElement={<IntegrateHeader />}
		/>
	);
};

export default Integrate;
