export const capitalizeFirstLetter = (text: string) => {
	return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export const getInitials = (text: string) => {
	let initial = ""
	text.split(" ").forEach((nameText) => initial += nameText?.[0] ?? "")
	return initial.toUpperCase()
}

export const convertCamelCaseToWords = (text: string) => {
	// Use regular expression to replace camel case with spaces
	const convertedText = text.replace(/([a-z])([A-Z])/g, '$1 $2');
	return convertedText;
};

export const formatCamelCaseHeader = (header: string) => {
	return convertCamelCaseToWords(header)
		.split(' ')
		.map(capitalizeFirstLetter)
		.join(' ');
};

export const formatUnderScoreRemove = (status: string) => {
	return status
		.toLowerCase() // Convert the entire string to lowercase
		.split('_') // Split by underscores
		.map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
		.join(' '); // Join the words with a space
};


export const camelCaseToCapitalizedWords = (input: string): string => {
	try {
	  // Replace underscores or any non-alphanumeric characters with spaces
	  const formattedInput = input.replace(/[_\W]+/g, ' ');
  
	  // Convert camelCase to capitalized words
	  return formattedInput
		.replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before uppercase letters
		.replace(/(^\w|\s\w)/g, match => match.toUpperCase()); // Capitalize first letter of each word
	} catch (error) {
	  // If an error occurs, return the original input value
	  return input;
	}
  };