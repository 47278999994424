import type { INode } from '../stores';

import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import className from 'classnames';
import Modal from '@storybook/new-modal/modal';

import { IRecipient } from 'views/signed-doc-inbox';
import { ComplexSessionView } from 'views/sessions/components/complex-session-view';
import { useComplexSessionInfo } from 'views/sessions/hooks';
import { PacketRecipientsKYCDetails } from '../stores';

import {
	ConditionalSessionFlowState,
	ComplexSessionNodesState,
	SessionItemSelected,
	SelectedSessionsIdState,
} from 'global-stores';
import { RecipientImage } from './recipient-image';
import { useGetLocationDetails } from 'hooks';

// Define the properties that the RecipientCard component expects
interface IRecipientCard {
	recipient: IRecipient;
	envelopeId: string;
	notary: boolean;
}

// React functional component for displaying recipient details
export const RecipientCard: FC<IRecipientCard> = ({ recipient, notary }) => {
	// State to store the recipient's address
	const [complexOpenView, setComplexOpenView] = useState<boolean>(false);
	const recipientKyc = useRecoilValue(PacketRecipientsKYCDetails);
	const setIsComplexTab = useSetRecoilState(ConditionalSessionFlowState);
	const setComplexSessionNodes = useSetRecoilState(ComplexSessionNodesState);
	const setSessionItemSelected = useSetRecoilState(SessionItemSelected);
	const setSelectedIdState = useSetRecoilState(SelectedSessionsIdState);

	// Declare a state variable 'formatted_address' with an initial empty string, and 'setFormattedAddress' to update it
	const [formatted_address, setFormattedAddress] = useState('');

	const { complexSessionInfo, complexSessionDetailsLoading } =
		useComplexSessionInfo();

	const { getGeoInfo } = useGetLocationDetails();

	// Destructure properties from the recipient object
	const { biometric, _id, fullName, status, email } = recipient;

	const kyc = useMemo(() => recipientKyc[_id ?? ''], [_id, recipientKyc]);

	// Function to retrieve and set the recipient's address from coordinates
	const senderLocation = useCallback(async () => {
		// Destructure 'location' from 'biometric' object, or use an empty object if 'biometric' is undefined
		const { location } = biometric ?? {};
		// Destructure 'latitude' and 'longitude' from 'location', or use an empty object if 'location' is undefined
		const { latitude, longitude } = location ?? {};

		// If both 'latitude' and 'longitude' exist, proceed with fetching the address
		if (latitude && longitude) {
			// Call 'getGeoInfo' to retrieve address info based on the provided coordinates, but do not update the state
			const resp = await getGeoInfo({
				lat: latitude,
				lng: longitude,
				updateState: false,
			});
			// Update the 'formatted_address' state with the retrieved formatted address, or set it as an empty string if not available
			setFormattedAddress(resp?.address?.formatted_address ?? '');
		}
	}, [biometric, getGeoInfo]); // Dependencies: 'biometric' and 'getGeoInfo'

	// Use the useEffect hook to call senderLocation when the component mounts
	useEffect(() => {
		if (recipient) {
			senderLocation();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const openKycDetails = useCallback(() => {
		if (kyc?.session) {
			const { nodes = [], _id = '' } = kyc?.session ?? {};
			const modifiedNodes = nodes.map((node: INode) => ({
				...node,
				visited: true,
			}));
			setComplexOpenView(true);
			setIsComplexTab(true);
			setComplexSessionNodes(modifiedNodes);
			setComplexOpenView(true);
			complexSessionInfo(_id);
			setSessionItemSelected(kyc?.session);
			setSelectedIdState([_id]);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [complexSessionInfo, kyc?.session]);

	const handleComplexCloseViewModal = useCallback(() => {
		setComplexOpenView(false);
	}, []);

	const kycStatusClassName = className(
		'recipients-deatils__kyc-status-badge',
		{ 'recipients-deatils__kyc-status-badge--completed': kyc?.kycStatus === 'completed' },
		{ 'recipients-deatils__kyc-status-badge--rejected': kyc?.kycStatus === 'rejected' },
		{ 'recipients-deatils__kyc-status-badge--pending': kyc?.kycStatus === 'pending' }
	);

	const renderKycStatus = useMemo(() => {
		if (!notary) {
			return <></>;
		}
		return (
			<div className="recipients-deatils__personal-details">
				<div className="recipients-deatils__desc">KYC Status</div>
				<div className="recipients-deatils__desc-data">
					<div>:&nbsp;</div>
					<div className="recipients-deatils__desc-data-action-btn-wrapper">
						<div className="recipients-deatils__kyc-status">
							<span className={kycStatusClassName}></span>
							<span>{kyc?.kycStatus ?? 'Pending'}</span>
						</div>
						{kyc?.kycStatus === 'completed' && (
							<div
								className="recipients-deatils__desc-data-action-btn"
								onClick={openKycDetails}
							>
								View
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}, [kyc?.kycStatus, kycStatusClassName, notary, openKycDetails]);

	return (
		<Fragment>
			<div className="recipients-deatils__wrapper">
				<div className="recipients-deatils__img-wrapper">
					<RecipientImage image={biometric?.image} status={status} />
				</div>
				<div className="recipients-deatils__description">
					<div className="recipients-deatils__personal-details">
						<div className="recipients-deatils__desc">Name</div>
						<div className="recipients-deatils__desc-data">
							<div>:&nbsp;</div> {fullName}
						</div>
					</div>
					<div className="recipients-deatils__personal-details">
						<div className="recipients-deatils__desc">Sign Status</div>
						<div className="recipients-deatils__desc-data">
							<div>:&nbsp;</div>{' '}
							{status.charAt(0).toUpperCase() + status.slice(1)}
						</div>
					</div>
					<div className="recipients-deatils__personal-details">
						<div className="recipients-deatils__desc">Email</div>
						<div
							className="recipients-deatils__desc-data"
							id={`${email}-${_id}`}
						>
							<div>:&nbsp;</div>
							{email}
							<ReactTooltip
								anchorId={`${email}-${_id}`}
								place="top"
								content={email}
								className="recipients-deatils__tooltip"
							/>
						</div>
					</div>
					<div className="recipients-deatils__personal-details">
						<div className="recipients-deatils__desc">Location</div>
						<div
							className="recipients-deatils__desc-data"
							id={`${formatted_address}-${_id}`}
						>
							<div>:&nbsp;</div>
							{formatted_address || 'NA'}
							<ReactTooltip
								anchorId={`${formatted_address}-${_id}`}
								place="top"
								content={formatted_address}
								className="recipients-deatils__tooltip"
							/>
						</div>
					</div>
					<div className="recipients-deatils__personal-details">
						<div className="recipients-deatils__desc">IP Address</div>
						<div className="recipients-deatils__desc-data">
							<div>:&nbsp;</div> {biometric?.ip ?? 'NA'}
						</div>
					</div>
					{renderKycStatus}
				</div>
			</div>
			{complexOpenView && (
				<Modal
					isOpen={complexOpenView}
					closeModal={handleComplexCloseViewModal}
					className="react-modal_kycDetail"
					modalName="Session modal"
				>
					<ComplexSessionView
						isShowTreeFlow={false}
						isLoading={complexSessionDetailsLoading}
					/>
				</Modal>
			)}
		</Fragment>
	);
};
