import { FC, useMemo, useState } from 'react';
import { Image } from '@storybook';
import { IKybDetails } from 'global-stores/sessions/type';
import './kyb-logo.scss';

interface ICompanyLogo {
	item: IKybDetails;
}
export const KybLogo: FC<ICompanyLogo> = ({ item }) => {
	const { basicInfo } = item ?? {};
	const [expanded, setExpanded] = useState(true);

	const renderLogo = useMemo(() => {
		if (!basicInfo) return null;
		const { name, logo, description, industries, status } = basicInfo ?? {};

		const CompanyName = name?.value ?? '';
		const Logo = logo?.value ? logo?.value : '';
		const CompanyDescription = description?.value ?? '';
		const CompanyIndustries = industries?.value ?? '';

		const toggleDescription = () => {
			setExpanded(prev => !prev);
		};

		let styleClass, value;

		if (status?.value === 'N/A') {
			// Do nothing or perform the intended action for 'N/A'
		} else if (typeof status?.value === 'boolean' && status.value) {
			styleClass = `kyb-logo-wrapper__active`;
			value = 'active';
		} else if (status?.value === 'active') {
			styleClass = `kyb-logo-wrapper__active`;
			value = 'active';
		} else if (status?.value === 'merged') {
			styleClass = `kyb-logo-wrapper__NA`;
			value = 'merged';
		} else {
			styleClass = `kyb-logo-wrapper__inactive`;
			value = 'inactive';
		}

		return (
			<>
				<div className="kyb-logo-wrapper">
					{Logo ? (
						<Image className="kyb-logo-wrapper__image" url={Logo || ''} />
					) : (
						<></>
					)}

					<div>
						<div className="kyb-logo-wrapper__name">
							{CompanyName}
							{!!status?.value && <span className={styleClass}>{value}</span>}
						</div>
						<div className="kyb-logo-wrapper__sub-name">
							{CompanyIndustries}
						</div>
					</div>
				</div>

				{!!CompanyDescription.length && (
					<div className="kyb-logo-wrapper_description">
						{CompanyDescription.length > 100 && !expanded ? (
							<>
								{CompanyDescription.slice(0, 105)}...{' '}
								<span
									className="kyb-logo-wrapper__view-more"
									onClick={toggleDescription}
								>
									View more
								</span>
							</>
						) : (
							<>
								{CompanyDescription}{' '}
								{CompanyDescription.length > 100 && (
									<span
										className="kyb-logo-wrapper__view-more"
										onClick={toggleDescription}
									>
										View less
									</span>
								)}
							</>
						)}
					</div>
				)}
			</>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [basicInfo, expanded]);

	return <>{renderLogo}</>;
};
