import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import {
	SelectedSessionsIdsForZipState,
	checkBoxStateInTable,
} from 'global-stores';
import { useNetwork, useNotification } from 'hooks';

import { AssignSelectedSessionState } from '../components';
import { message } from 'constant/message';
import { API_URL } from 'constant';
import { toast } from 'react-toastify';

export const useSessionDetailsActions = () => {
	const setSelectedSession = useSetRecoilState(AssignSelectedSessionState);
	const setCheckBoxState = useSetRecoilState(checkBoxStateInTable);
	const setSelectedIdsForZip = useSetRecoilState(
		SelectedSessionsIdsForZipState
	);

	// hooks
	const { post: updateSessionIds, get } = useNetwork();
	const { errorNotification, successNotification } = useNotification();
	const { API_RESPONSE_NOTIFICATION, DOWNLOAD_ZIP_MESSAGE } = message;

	const exportPdf = useCallback(
		async (sessionIDs: any[], type: string) => {
			if (sessionIDs?.length === 0 && type === 'zip') {
				errorNotification('Please select atleast 1 session to download zip');
			} else {
				// pradeep chaurasia : added for zip case only
				if (type === 'zip') {
					const response = await updateSessionIds(API_URL.SESSION_REPORTS, {
						sessionIds: sessionIDs,
						fileType: type,
					});
					const { success, message } = response ?? {};
					if (success) {
						successNotification(message ?? DOWNLOAD_ZIP_MESSAGE);
					} else {
						errorNotification(message ?? 'Something went wrong.');
					}
					setSelectedSession([]);
					setSelectedIdsForZip([]);
					setCheckBoxState(false);
				} else {
					// Rahul Singh: added response notification on download button in session as it is taking time 30sec
					await toast
						.promise(
							updateSessionIds(API_URL.SESSION_REPORTS, {
								sessionIds: sessionIDs,
								fileType: type,
							}),
							{
								pending: API_RESPONSE_NOTIFICATION,
							}
						)
						.then(res => {
							if (type === 'pdf' && res?.url.length > 0) {
								res.url.map((url: any) => window.open(url, '_self'));
								setSelectedSession([]);
								setCheckBoxState(false);
							}
						});
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[updateSessionIds]
	);

	const exportCsv = async (id: string, name: string) => {
		try {
			await toast
				.promise(get(`/session-details-csv/${id}`), {
					pending: API_RESPONSE_NOTIFICATION,
				})
				.then(res => {
					if (res) {
						const csvData = res.data; // Assuming CSV data is directly available under 'data'

						// Create a Blob from the CSV data
						const blob = new Blob([csvData], { type: 'text/csv' });

						// Create a URL for the Blob
						const url = window.URL.createObjectURL(blob);

						// Create a temporary link element
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', name + '.csv');

						// Append the link to the document body and click it
						document.body.appendChild(link);
						link.click();

						// Clean up by removing the link
						document.body.removeChild(link);
					}
				});
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log('Error downloading CSV:', error);
		}
	};

	const downloadFile = ({ data, fileName, fileType }: any) => {
		// Create a blob with the data we want to download as a file
		const blob = new Blob([data], { type: fileType });
		// Create an anchor element and dispatch a click event on it
		// to trigger a download
		const a = document.createElement('a');
		a.download = fileName;
		a.href = window.URL.createObjectURL(blob);
		const clickEvt = new MouseEvent('click', {
			view: window,
			bubbles: true,
			cancelable: true,
		});
		a.dispatchEvent(clickEvt);
		a.remove();
	};

	const exportToJson = (usersData: any, fileName: string) => {
		downloadFile({
			data: JSON.stringify(usersData),
			fileName: fileName,
			fileType: 'text/json',
		});
	};
	return { exportPdf, exportToJson, exportCsv };
};
