import { atom } from 'recoil';
import {
	IEscrowDetailsAtom,
	IEscrowModalBodyNavigatorState,
	IEscrowTransactionTypeState,
	IFundDataForEscrowState,
	ISelectedBankSubItemState,
} from './types';

export const EscrowModalBodyNavigatorState =
	atom<IEscrowModalBodyNavigatorState>({
		key: 'escrow-modal-body-navigator-state',
		default: 'show_details',
	});

export const EscrowModalOpenState = atom<boolean>({
	key: 'escrow-modal-open-state',
	default: false,
});

export const AddBankAccountLoadingState = atom<boolean>({
	key: 'add-bank-account-loading',
	default: false,
});

export const EscrowTransactionTypeState = atom<IEscrowTransactionTypeState>({
	key: 'escrow-transaction-type-state',
	default: undefined,
});

export const SelectedBankIndexState = atom<number>({
	key: 'selected-bank-index-state',
	default: 0,
});

export const SeletedBankItemState = atom<any>({
	key: 'selected-bank-item-state',
	default: undefined,
});

export const SelectedBankSubItemState = atom<
	ISelectedBankSubItemState | undefined
>({
	key: 'selected-bank-sub-item-state',
	default: undefined,
});

export const AmountToDepositeToEscrowState = atom<string>({
	key: 'amount-to-deposite-to-escrow-state',
	default: '100.00',
});

export const AmountToWithdrawFromEscrowState = atom<string>({
	key: 'amount-to-withdraw-from-escrow-state',
	default: '100.00',
});

export const EscrowDetailsAtom = atom<IEscrowDetailsAtom>({
	key: 'escrow-details-atom-key',
	default: {
		loaded: true,
		error: false,
		data: {
			id: 'ed0f0907-bf08-4cd5-b207-cac109056cbb',
			accountStatus: 'open',
			accountType: 'business',
			accountNumber: 'FT72B1266015',
			assetType: 'fiatCurrency',
			assetFiatType: 'usd',
			disbursable: 0,
			locked: 0,
			pending: 0,
			total: 0,
		},
	},
});

export const FundDataForEscrowState = atom<IFundDataForEscrowState>({
	key: 'fund-data-for-escrow-state',
	default: {
		id: '',
		name: '',
		investor: 0,
		pending: 0,
		moneyRaised: 0,
		fundingRound: '',
		totalMoneyRaised: 0,
		postMoneyValuation: 0,
		escrowAccounts: [],
	},
});
