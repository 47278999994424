import { Button } from '@storybook';
import Tippy from '@tippyjs/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';

import {
	BillingDetailsLoaderState,
	BusinessRegistrationState,
	IsUserPermissionLoading,
	loginState,
} from 'global-stores';
import { useFreePlan } from 'hooks';
import { useCompliance } from 'routes/hooks';
import { SignUpSessionStatusState } from 'views/new-user-popup';
import { PERMISSION_SCREEN, useUserRoles } from 'views/routes-children';
import { ApprovalStatus, UserIdentityFlow } from 'views/user-identity-flow';
import { SwitchToLiveFromSandboxState } from '../../stores';
import { BILLING_PERMISSION_TEXT } from '../constant';
import { SandboxView } from '../sandbox-view';
import {
	BuisnessAcountType,
	BuisnessFreeAcountType,
	DemoAcountType,
	EsignAcountType,
	IndividualAcountType,
	SkeltonAcountTypeCard,
	CancelSubscription,
	useCancelSubscription,
} from './components';
import { BussinessStatusDetails } from './components/bussiness-status-details';
import { AcountUserTypes } from './constants';

import './subscriptions.scss';

export const SubscriptionsBox = () => {
	const [isOpenModal, setIsOpenModal] = useState(false);
	const businessRegistrationState = useRecoilValue(BusinessRegistrationState);
	const billingLoader = useRecoilValue(BillingDetailsLoaderState);
	const signUpSessionStatusState = useRecoilValue(SignUpSessionStatusState);
	const complianceLoader = useRecoilValue(IsUserPermissionLoading);
	// Pradeep Chaurasia: this state use to manage loader switcing sandbox to live
	const switchToLiveFromSandbox = useRecoilValue(SwitchToLiveFromSandboxState);
	const user = useRecoilValue(loginState);
	const {
		sandboxStatus = '',
		differentCompany = false,
		ownBilling = false,
	} = useMemo(() => user ?? {}, [user]);

	const {
		accountType,
		approveStatus = '',
		kyc,
		kyb,
	} = useMemo(
		() => businessRegistrationState ?? {},
		[businessRegistrationState]
	);
	const {
		hasSubscribedBusiness,
		hasSubscribedPerson,
		invitationSubscriptionCheck = false,
		userSubscriptions = [],
		haveKybKyc = false,
		haveKyb = false,
		haveKyc = false,
	} = useFreePlan();

	const { checkUserWritePermission } = useUserRoles();
	const { getComplianceData, fetchSessionDeatil, isSessionLoading } =
		useCompliance();

	const { showCancelSubscription } = useCancelSubscription();

	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.Billing),
		[checkUserWritePermission]
	);

	const onClickUpgrade = useCallback(async () => {
		setIsOpenModal(true);
	}, []);

	const handleCloseModal = useCallback(async () => {
		setIsOpenModal(false);
		await getComplianceData();
	}, [getComplianceData]);

	const btnLabel = useMemo(() => {
		if (kyc?.success && kyb?.success) return 'Subscribe';
		if (hasSubscribedPerson) return 'Upgrade to Entity/Company';
		else return 'Subscribe';
	}, [hasSubscribedPerson, kyb?.success, kyc?.success]);

	const renderButton = useMemo(() => {
		return (
			<Tippy
				disabled={isUserPermissionWrite}
				content={
					<div className="text-align-center">
						{BILLING_PERMISSION_TEXT.Subscribe}
					</div>
				}
			>
				<div className="subscriptions__upgrade-btn">
					<Button
						label={btnLabel}
						type="button button__filled--primary subscriptions__upgrade-btn"
						handleClick={onClickUpgrade}
						disabled={
							billingLoader || !isUserPermissionWrite || isSessionLoading
						}
					/>
				</div>
			</Tippy>
		);
	}, [
		billingLoader,
		btnLabel,
		isUserPermissionWrite,
		isSessionLoading,
		onClickUpgrade,
	]);

	const renderUpgradeSubscription = useMemo(() => {
		if (
			(differentCompany && approveStatus === ApprovalStatus.Pending) ||
			!hasSubscribedBusiness
		) {
			return renderButton;
		}

		return <></>;
	}, [differentCompany, hasSubscribedBusiness, renderButton, approveStatus]);

	const renderAcountTypeBody = useMemo(() => {
		if (
			billingLoader ||
			switchToLiveFromSandbox ||
			complianceLoader ||
			isSessionLoading
		) {
			// Show a skeleton loading component if billing data is loading or switching to live from sandbox
			return <SkeltonAcountTypeCard />;
		}

		if (
			!hasSubscribedBusiness &&
			accountType &&
			differentCompany &&
			ownBilling
		) {
			return <DemoAcountType />;
		}
		if (
			!accountType ||
			approveStatus === ApprovalStatus.Pending ||
			approveStatus === ApprovalStatus.Rejected
		) {
			// Show demo account type if account type is not set or if approval status is pending or rejected
			return <DemoAcountType />;
		}

		// Determine the account type based on the approval status and user type
		if (
			[ApprovalStatus.Approved, ApprovalStatus.UnderReview].includes(
				approveStatus?.toUpperCase()
			)
		) {
			switch (accountType) {
				case AcountUserTypes.Entity:
					// Show business or individual account type based on subscription status
					return (
						<>
							{haveKybKyc && !hasSubscribedBusiness && (
								<>
									{hasSubscribedPerson && <BuisnessFreeAcountType />}
									{!hasSubscribedPerson && <DemoAcountType />}
								</>
							)}
							{hasSubscribedBusiness && <BuisnessAcountType />}
						</>
					);
				case AcountUserTypes.Person:
					// Show individual account type
					return hasSubscribedPerson ? (
						haveKyb ? (
							<BuisnessFreeAcountType />
						) : (
							<IndividualAcountType />
						)
					) : (
						<EsignAcountType />
					);
				default:
					return <IndividualAcountType />; // Default case, should not be reached
			}
		}
		return <></>; // Default case if approval status is not approved
	}, [
		billingLoader,
		switchToLiveFromSandbox,
		complianceLoader,
		hasSubscribedBusiness,
		accountType,
		differentCompany,
		ownBilling,
		approveStatus,
		haveKybKyc,
		hasSubscribedPerson,
		haveKyb,
		isSessionLoading,
	]);

	const renerAccountButton = useMemo(
		() => renderUpgradeSubscription,
		[renderUpgradeSubscription]
	);

	const renderBusinessDetails = useMemo(() => {
		if (
			approveStatus === ApprovalStatus.Approved &&
			!complianceLoader &&
			!billingLoader
		) {
			return <BussinessStatusDetails />;
		}
		return <></>;
	}, [approveStatus, complianceLoader, billingLoader]);

	useEffect(() => {
		if (
			userSubscriptions?.length &&
			!billingLoader &&
			invitationSubscriptionCheck &&
			!complianceLoader
		) {
			setIsOpenModal(true);
		}
	}, [
		billingLoader,
		userSubscriptions,
		complianceLoader,
		invitationSubscriptionCheck,
	]);

	useEffect(() => {
		if (!signUpSessionStatusState?.sessionIds.length) return;

		const { sessionIds } = signUpSessionStatusState;
		if (accountType === AcountUserTypes.Person && !haveKyc) {
			sessionIds.forEach(session => {
				fetchSessionDeatil(session.sessionId);
			});
			return;
		}

		if (accountType === AcountUserTypes.Entity && !haveKybKyc) {
			sessionIds.forEach(session => {
				fetchSessionDeatil(session.sessionId);
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [signUpSessionStatusState, haveKyc, accountType, haveKybKyc]);

	return (
		<div className="subscriptions hover">
			<div className="subscriptions__wrapper">
				<div>
					<div className="subscriptions__title">Account type</div>
					<div className="subscriptions__subtitle">
						{!sandboxStatus
							? 'Manage your account from here'
							: 'Account up-gradation is not available in sandbox mode'}
					</div>
				</div>
				{showCancelSubscription && <CancelSubscription />}
			</div>
			{!sandboxStatus ? (
				<div className="subscriptions__types-box">
					{renderAcountTypeBody}
					{renerAccountButton}
					{renderBusinessDetails}
					{isOpenModal && (
						<UserIdentityFlow
							isOpen={isOpenModal}
							handleCloseModal={handleCloseModal}
						/>
					)}
				</div>
			) : (
				<SandboxView />
			)}
		</div>
	);
};
