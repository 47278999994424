/* eslint-disable react/no-array-index-key */
import { FC } from 'react';
import { BasicSection } from '../../../kyb-comprehensive/components/basic-section';
import { Metadaum } from 'views/sessions/components/kyb-wrapper/hooks/formatter/ofac/types';
import { KYB_OFAC_CARDS_KEYS } from 'views/sessions/components/kyb-wrapper/hooks';

interface IProps {
	cases: Metadaum | any;
}

export const CaseDetails: FC<IProps> = ({ cases }) => {

	const isArrayofStrings = (val: any): val is string[] => {
		return Array.isArray(val) && val.every(item => typeof item === 'string');
	};

	const formatAddress = (address: {
		address1: string;
		city: string;
		province: string;
		postalCode: string;
		countryName: string;
	}) => {
		const addressParts = [
			address?.address1,
			address?.city,
			address?.province,
			address?.postalCode,
			address?.countryName
		  ];
		
		  // Filter out undefined, null, and empty string values
		  const formattedAddress = addressParts?.filter(part => part).join(', ');
		  return formattedAddress;
	};

	return (
		<div className='ofac__case-details-wrapper'>
			{Object.entries(cases).map(([key, value]) =>
				Array.isArray(value) ? (
					<div key={key}>
						{isArrayofStrings(value) ? (
							<BasicSection
								label={
									KYB_OFAC_CARDS_KEYS[key] ? KYB_OFAC_CARDS_KEYS[key] : key
								}
								value={value}
							/>
						) : (
							<BasicSection
								label={
									KYB_OFAC_CARDS_KEYS[key] ? KYB_OFAC_CARDS_KEYS[key] : key
								}
								value={value?.map(formatAddress)}
							/>
						)}
					</div>
				) : (
					<div key={key}>
						<BasicSection
							label={KYB_OFAC_CARDS_KEYS[key] ? KYB_OFAC_CARDS_KEYS[key] : key}
							value={value as string}
						/>
					</div>
				)
			)}
		</div>
	);
};
