import { FC, ReactNode, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { PipelineMasterSteps } from 'global-stores';
import { ActionList } from 'views/complex-onboarding-flow/constant';
import { ComplexSettingFormState } from 'views/pipeline';
import { CustomHandle } from '../custom-handle';
import { ICustomNode } from '../custom-node';
import { RenderBiometricBlock } from './render-biometric-block';

export const BiometricWrapper = ({ children }: { children: ReactNode }) => {
	return <div className="kyb-action">{children}</div>;
};

export const Biometric: FC<ICustomNode> = ({ id: nodeId, data }) => {
	const complexSettingForm = useRecoilValue(ComplexSettingFormState);

	const pipelinesSteps = useRecoilValue(PipelineMasterSteps);

	const selectDefaultLabel = useMemo(
		() => complexSettingForm?.[nodeId]?.signAgreementProvider,
		[complexSettingForm, nodeId]
	);
	const actionId = useMemo(() => data.label, [data.label]);

	const actions = useMemo(
		() =>
			pipelinesSteps.find(
				pipelineStep => pipelineStep.key === ActionList[actionId]
			)?.actions[0],
		[actionId, pipelinesSteps]
	);

	// Calc. price
	const price = useMemo(() => {
		return (
			actions?.metadata?.[0]?.options?.find(el => el.key === selectDefaultLabel)
				?.price ?? actions?.price
		);
	}, [actions?.metadata, actions?.price, selectDefaultLabel]);

	return (
		<BiometricWrapper>
			<RenderBiometricBlock label={'Authentication'} price={price} />
			<CustomHandle value={nodeId} showLeft={false} />
		</BiometricWrapper>
	);
};
