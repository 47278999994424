import type { Banks } from '../../../type';

import { Image } from '@storybook';
import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { getLogos } from 'constant';
import { PaymentCardMethodsState } from 'global-stores';
import { usePaymentCard } from 'views/routes-children';
import { PrimaryAchAccountChecked } from 'views/settings/billing/stores';
import { useDefaultCard } from 'views/user-identity-flow';
import { SkeletonCardLoading } from '../skeleton-loading';

export const ChooseAchDefault = () => {
	const paymentCardMethodsState = useRecoilValue(PaymentCardMethodsState);
	const [defaultCheck, setAchDefaultCheck] = useRecoilState(
		PrimaryAchAccountChecked
	);
	const { getPaymentCardsApi, loading } = usePaymentCard();
	const { isDefaultExist } = useDefaultCard();

	const { banks = [] } = useMemo(
		() => paymentCardMethodsState,
		[paymentCardMethodsState]
	);

	const handleDeafultRadioCheck = useCallback(
		(item: Banks) => () => {
			setAchDefaultCheck({
				id: item._id,
				accountId: item.accountId,
				accountType: item.subtype,
			});
		},
		[setAchDefaultCheck]
	);

	const renderList = useCallback(
		(item: Banks) => {
			return (
				<li
					key={item.accountId}
					className={`${
						defaultCheck?.accountId === item.accountId ? 'active-card' : ''
					}`}
					onClick={handleDeafultRadioCheck(item)}
				>
					<input
						type="radio"
						className="input-radio"
						checked={defaultCheck?.accountId === item.accountId}
					/>
					<Image
						fileName={getLogos[item?.bankName] ?? getLogos['default']}
						className="payment-save-card-list__card-icon"
					/>
					<div className="payment-card-detail">
						<div className="payment-save-card-list__number">
							{item.bankName}
						</div>
						<div className="payment-save-card-list__expire">
							{item?.name}
							(••••{item?.mask})
						</div>
					</div>
				</li>
			);
		},
		[defaultCheck?.accountId, handleDeafultRadioCheck]
	);

	const formattedData = useMemo(() => {
		const result = [];
		for (let i = 0; i < banks?.length; i++) {
			for (let j = 0; j < banks[i]?.accounts?.length; j++) {
				const payload = {
					...banks[i].accounts[j],
					_id: banks[i]._id,
					bankName: banks[i].bankName,
				};
				result.push(payload);
			}
		}
		return result;
	}, [banks]);

	useEffect(() => {
		getPaymentCardsApi();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!isDefaultExist && formattedData.length > 0) {
			setAchDefaultCheck({
				id: formattedData[0]._id,
				accountId: formattedData[0].accountId,
				accountType: formattedData[0].subtype,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDefaultExist, formattedData]);

	const mapRenderList = useMemo(
		() => formattedData?.map(renderList),
		[formattedData, renderList]
	);

	if (loading) return <SkeletonCardLoading />;

	return (
		<div className="ChooseAchDefaault">
			<ul className="ChooseAchDefaault__lists">{mapRenderList}</ul>
		</div>
	);
};
