import { FC } from 'react';
import './rejection-details.scss';

interface IRejectionDetails {
	data: string[];
	isError:boolean;
}

export const RejectionDetails: FC<IRejectionDetails> = ({ data,isError=true }) => {
	return (
		<div className='kyb-rejection'>
			{data?.length > 0
				? data.map((el) => {
						if (!el) return <></>;
						else if (typeof el === 'string') {
							return (
								<div className="Kyc-rejection" key={el}>
									<li className={isError? 'Kyc-rejection--dot' : 'Kyc-rejection--dotOrange'} ></li>
									<li className={isError? 'Kyc-rejection--pointer' : 'Kyc-rejection--pointerOrange'}>{el}</li>
								</div>
							);
						} else {
							return (
								<div className="Kyc-rejection" key={el}>
									<li className={isError? 'Kyc-rejection--dot' : 'Kyc-rejection--dotOrange'} ></li>
									<li className={isError? 'Kyc-rejection--pointer' : 'Kyc-rejection--pointerOrange'}>{el}</li>
								</div>
							);
						}
				  })
				: 'NA'}
		</div>
	);
};
