import { useCallback } from 'react';
import { Button, Image } from '@storybook';

import { SuccessIcon } from 'components';

import './success-screen.scss';

export const SuccessScreen = () => {
	const handleGotoLogin = useCallback(() => {
		window.location.href = '/login';
	}, []);

	return (
		<div className="mobile-verification">
			<div className="mobile-verification__success">
				<SuccessIcon />
				<div className="mobile-verification__success-message">
					Approved successfully
				</div>
				<div className="mobile-verification__btns">
					<Button
						label={'Click to login'}
						handleClick={handleGotoLogin}
						type="button__filled button__filled--primary button__large button-ok"
					/>
				</div>
			</div>
			<div className="mobile-verification__powered-by">
				<span className="mobile-verification__powered-by-text">Powered By</span>
				<Image
					url="https://storage.googleapis.com/satschel-public-assets/images/logo/simplici-wordmark.png"
					className="mobile-verification__powered-by-img"
				/>
			</div>
		</div>
	);
};
