import { FC, useCallback, useMemo } from 'react';
import { Button, Loader } from '@storybook';
import { IEditRecipientFooter } from '../';

/**
 * Create the EditRecipientFooter functional component
 * */
export const EditRecipientFooter: FC<IEditRecipientFooter> = ({
	primaryBtnLabel,
	secondaryBtnLabel,
	handlePrimaryClick,
	handleSecondaryClick,
	loading,
	disabled
}) => {

	/**
	 * Handle the primary button click with loading state
	 * */
	const handlePrimaryButtonClicked = useCallback( () => {
		 handlePrimaryClick();
	}, [handlePrimaryClick]);

	/**
	 * Determine the label for the primary button (loading or primaryBtnLabel)
	 * */
	const primaryButtonLabel = useMemo(
		() =>
			!loading ? (
				primaryBtnLabel
			) : (
				<Loader dimension={20} className={'loader-white'} type={'loader'} />
			),
		[loading, primaryBtnLabel]
	);

	return (
		<div className="edit-recipient__footer">
			<div className="edit-recipient__btn-container">
				{secondaryBtnLabel && (
					/**
					 * Render a secondary button if secondaryBtnLabel is provided
					 **/
					<Button
					type='button__filled--secondary'
						handleClick={handleSecondaryClick}
						label={secondaryBtnLabel}
					/>
				)}
				<Button
					type='button__filled--primary'
					handleClick={handlePrimaryButtonClicked}
					disabled={disabled || loading}
					label={primaryButtonLabel}
				/>
			</div>
		</div>
	);
};
