import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { Button, IconBtn, Image, Loader, Skeleton } from '@storybook';
import { SelectedPipelineQrData, useOnboardingQrFlow } from '../../stores';
import { useNotification } from 'hooks';
import { KycMobileHost, KycMobileHostSandbox } from 'helpers';
import { MESSAGE } from 'constant';

import './qr-image.scss';
import { loginState } from 'global-stores';

export const QrImage = () => {
	// global states
	const [qrInstance, setQRInstance] = useState<any>({});
	const qRflowData = useRecoilValue(SelectedPipelineQrData);
	const { successNotification, errorNotification } = useNotification();
	const { sandboxStatus } = useRecoilValue(loginState);

	// local states
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	// hooks
	const { handleDownload, reset, getPipelineQrList, handleCopy } =
		useOnboardingQrFlow();

	const { qrImage, name } = qrInstance ?? {};
	const { _id, pipelineId } = qRflowData ?? {};

	useEffect(() => {
		// TODO: at complex type
		const isComplex = qRflowData?.banks;
		getPipelineQrList(pipelineId ?? '', isComplex).then(resp => {
			setIsLoading(false);
			if (resp?.length > 0) {
				const foundqr = resp.find((item: any) => item._id === _id);
				if (foundqr) {
					setQRInstance(foundqr);
				}
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pipelineId, qRflowData, _id]);

	const handleClickOnDownload = useCallback(() => {
		if (qrImage && name) {
			setLoading(true);
			handleDownload(qrImage, name);
			setTimeout(() => setLoading(false), 1000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [qrImage, name]);

	const handleCopyImage = useCallback(async () => {
		const qrUrl = sandboxStatus
			? `${KycMobileHostSandbox}/${_id}?type=qr&allow=true`
			: `${KycMobileHost}/${_id}?type=qr`;
		try {
			await navigator.clipboard.writeText(qrUrl);
			successNotification('QR URL Copied Successfully');
		} catch (error) {
			errorNotification(MESSAGE.ERROR);
		}
		// eslint-disable-next-line
	}, [_id, sandboxStatus]);

	const handlePrint = () => {
		let innerHTML = `<!DOCTYPE html>
        <html lang="pt-BR">
        <head id="print-qr">   
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
		<style>
			body {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100vh;
				margin: 0; 
			}
			img {
				width: 60vw;
				height: 60vw;
			}
    	</style>
        </head>
        <body>`;
		innerHTML +=
			document.getElementById('printImage')?.innerHTML + '</body></html>';
		const printIframe = window.document.createElement('iframe');
		document.body.appendChild(printIframe);
		const printContent = printIframe.contentWindow;
		printContent?.document.open();
		printContent?.document.write(innerHTML);
		printContent?.document.close();
		setTimeout(() => {
			printContent?.focus();
			printContent?.print();
			setTimeout(() => {
				printIframe.parentNode?.removeChild(printIframe);
			}, 500);
		}, 500);
	};

	if (isLoading) {
		return (
			<div className="qr-image-loader">
				<Skeleton height={192} width={192} />
			</div>
		);
	}

	if (error) {
		return (
			<div className="qr-image-wrapper">
				<Image
					fileName="no_qr.svg"
					className="qr-image-wrapper__empty-image-state"
				/>
				<div className="qr-image-wrapper__empty-image-text">
					Something went wrong
				</div>
				<div className="qr-image-btn-wrapper">
					<Button
						handleClick={reset}
						label={'Retry'}
						type="button__filled button__filled--primary button__large"
					/>
				</div>
			</div>
		);
	}

	return (
		<div className="qr-image-wrapper">
			{qrImage && (
				<>
					<div id="printImage" className="printImage">
						<Image
							url={qrImage}
							onError={() => setError(true)}
							className="qr-image-wrapper__qr-image"
						/>
					</div>
					{_id && (
						<div className='qr-image-wrapper__copy-qr'>
							<div>{_id}</div>
							<div className="btn__Onhover">
								<IconBtn
									btnClassName="ri-file-copy-line  icon__primary icon__large"
									handleClickIcon={e => handleCopy(e, _id)}
								/>
							</div>
						</div>
					)}
					<div className="qr-image-btn-wrapper">
						<Button
							handleClick={handleCopyImage}
							label={
								<>
									<i className="ri-file-copy-line button-label-txt__icon" />
									Copy URL
								</>
							}
							type="button__filled button__filled--primary button__large"
						/>
						<Button
							handleClick={handlePrint}
							label={
								<>
									<i className="ri-printer-line button-label-txt__icon" />
									Print
								</>
							}
							type="button__filled button__filled--primary button__large"
						/>

						<Button
							handleClick={handleClickOnDownload}
							label={
								<>
									Download
									{loading ? (
										<Loader
											type="loader"
											dimension={20}
											className="loader-white"
										/>
									) : (
										<i className="ri-download-line button-label-txt__icon" />
									)}
								</>
							}
							type="button__filled button__filled--primary button__large"
							disabled={loading}
						/>
					</div>
				</>
			)}
		</div>
	);
};
