/* eslint-disable no-mixed-spaces-and-tabs */
import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { CodeSpace } from '@storybook';
import { envHost } from 'helpers';
import {
	ActiveTabsIntegrateState,
	ClientsCredentialsState,
	SelectedDeveloperKeyState,
	SelectedTabState,
	WebColorProperties,
} from 'global-stores';
import { CODE_TAB_LISTS, ServiceProviderCodes } from './codes';

import './code-space.scss';

export const CodeSpaceField = () => {
	const selectedTab = useRecoilValue(SelectedTabState);
	const clientsCredentials = useRecoilValue(ClientsCredentialsState);
	const selectedClient = useRecoilValue(SelectedDeveloperKeyState);
	const webProperties = useRecoilValue(WebColorProperties);
	const [activeElement, setActiveElement] = useRecoilState(
		ActiveTabsIntegrateState
	);

	const selectedDeveloperKey = useMemo(() => {
		const client = clientsCredentials.find(
			({ _id }) => _id === selectedClient?.value
		);
		const { clientId, clientSecret } = client ?? {};
		return { clientId, clientSecret };
	}, [clientsCredentials, selectedClient]);

	const clientId =
		selectedDeveloperKey.clientId ?? clientsCredentials?.[0]?.clientId ?? '';
	const clientSecret =
		selectedDeveloperKey.clientSecret ??
		clientsCredentials?.[0]?.clientSecret ??
		'';

	const handleOnClickTab = useCallback((item: any) => {
		const { key } = item ?? {};
		setActiveElement(key);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getTableList = useMemo(
		() =>
			CODE_TAB_LISTS.map((item: any) => {
				return (
					<div
						className={
							activeElement === item.key
								? 'code-wrapper__header__lists-name active'
								: 'code-wrapper__header__lists-name'
						}
						key={item.key}
						onClick={() => handleOnClickTab(item)}
					>
						{item.tabName}
					</div>
				);
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[activeElement]
	);

	useEffect(() => {
		setActiveElement(CODE_TAB_LISTS[0].key as any);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const params = new URLSearchParams({
		background: webProperties.background,
		theadColor: webProperties.tableHeadColor,
		isSearch: webProperties.isSearch,
		clientId: clientId,
		clientSecret: clientSecret,
		btnPrimary: webProperties.btnPrimary,
		btnSecondary: webProperties.btnPrimary,
		notification: webProperties.notification,
		sidebar: webProperties.sidebar,
	});

	const url = `${selectedTab}?${params}`;

	const webComponentPath = useMemo(() => {
		if (envHost === 'stage') return 'web-component.stage.js';
		if (envHost === 'pp') return 'web-component.pp.js';
		if (envHost === 'beta') return 'web-component.beta.js';
		if (envHost === 'prod') return 'web-component.js';
		return 'web-component.stage.js';
	}, []);

	const replaceVarWithValue = useMemo(() => {
		return {
			'{type}': selectedTab,
			'{bgcolor}': webProperties.background,
			'{search}': webProperties.isSearch,
			'{theadcolor}': webProperties.tableHeadColor,
			'{clientid}': clientId,
			'{clientsecret}': clientSecret,
			'{btnprimary}': webProperties.btnPrimary,
			'{btnsecondary}': webProperties.btnSecondary,
			'{notification}': webProperties.notification,
			'{sidebar}': webProperties.sidebar,
			'{appendUrl}': url,
			'{isInsideAdmin}': '',
			'{script}': `<script src="${window.location.origin}/${webComponentPath}"></script>`,
		};
	}, [
		clientId,
		clientSecret,
		selectedTab,
		url,
		webComponentPath,
		webProperties.background,
		webProperties.btnPrimary,
		webProperties.btnSecondary,
		webProperties.isSearch,
		webProperties.notification,
		webProperties.sidebar,
		webProperties.tableHeadColor,
	]);

	const matchVariable = useMemo(
		() => new RegExp(Object.keys(replaceVarWithValue).join('|'), 'gi'),
		[replaceVarWithValue]
	);

	const getCode = useMemo(
		() =>
			activeElement === 'iframe'
				? ServiceProviderCodes.iframe?.replace(
						matchVariable,
						function (data: any) {
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							//@ts-ignore
							return replaceVarWithValue[data];
						}
				  ) ?? 'Does not Exist'
				: selectedTab === 'home'
				? `         <onboarded-dashboard 
            admin="onboarded"  
            clientId="${clientId}"
            clientSecret="${clientSecret}" 
          ></onboarded-dashboard>
          
          <script src="${window.location.origin}/${webComponentPath}"></script>`
				: ServiceProviderCodes.webComponent?.replace(
						matchVariable,
						function (data: any) {
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							//@ts-ignore
							return replaceVarWithValue[data];
						}
						// eslint-disable-next-line no-mixed-spaces-and-tabs
				  ) ?? 'Does not Exist',
		[
			activeElement,
			clientId,
			clientSecret,
			matchVariable,
			replaceVarWithValue,
			selectedTab,
			webComponentPath,
		]
	);

	return (
		<div className="code-wrapper">
			<div className="code-wrapper__header">
				<div className="code-wrapper__header__lists">{getTableList}</div>
			</div>
			<div style={{ height: 150, overflow: 'auto' }}>
				<CodeSpace code={getCode} />
			</div>
		</div>
	);
};
