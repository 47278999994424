import { useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Image, Skeleton } from '@storybook';

import {
	IsOpenInIframeState,
	kycSettingsState,
	loginState,
} from 'global-stores';
import { useNetwork } from 'hooks';
import { API_URL } from 'constant';

export const useCustomLoader = () => {
	// const global state
	const [customLoader, setCustomLoader] = useRecoilState(kycSettingsState);
	const user = useRecoilValue(loginState);

	// hooks
	const { get } = useNetwork();

	const fetchLoader = useCallback(
		async (token?: string) => {
			const config = {
				headers: {
					Authorization: `Bearer ${token || user?.accessToken}`,
				},
			};
			const resp = await get(API_URL.CLIENT_DETAILS, config);
			const {
				loader,
				whitelabel,
				loaderWhitelabel,
				name,
				logo,
				_id,
				whitelistedDomains,
				whitelabelKey,
				whitelistConfig
			} = resp?.data?.[0] ?? {};
			const clientData = {
				loader: loader || null,
				whitelabel,
				loaderWhitelabel,
				name,
				logo,
			};
			setCustomLoader(pre => ({
				...pre,
				_id,
				...clientData,
				whitelistedDomains,
				whitelabelKey,
				whitelistConfig
			}));
			const json = JSON.stringify({ ...(clientData ?? {}) });
			localStorage.setItem('session-loader', json);
		},
		[get, setCustomLoader, user?.accessToken]
	);

	const { loader, whitelabel, loaderWhitelabel } = useMemo(() => {
		const localStorageData = localStorage.getItem('session-loader');
		const localObject = localStorageData && JSON.parse(localStorageData);
		return customLoader ?? localObject ?? {};
	}, [customLoader]);

	const isWebComponentOpen = useRecoilValue(IsOpenInIframeState);

	const isMp4 = useCallback((url: string) => {
		if (!url) return false;

		// Check if the URL is a Base64-encoded MP4
		if (url.startsWith('data:video/mp4;base64,')) return true;

		// Extract the file extension from the URL
		const fileExtension =
			url.split('?')?.[0]?.split('.')?.pop()?.toLowerCase() ?? '';

		return fileExtension === 'mp4';
	}, []);

	const customLoaderElement = useCallback(
		(_dimension: number) => {
			const shouldShowDefaultImage =
				(!user?.loggedIn && !isWebComponentOpen) ||
				whitelabel === false ||
				(loader === null && loaderWhitelabel === undefined);

			if (shouldShowDefaultImage) {
				return (
					<Image
						fileName="simpliciGif.gif"
						width={`${_dimension}`}
						height={`${_dimension}`}
					/>
				);
			}

			if (loaderWhitelabel === false) {
				return (
					<div
						style={{ width: 62, height: 62 }}
						className="loader loader-blue"
					/>
				);
			}

			if (loaderWhitelabel === true && loader) {
				return isMp4(loader) ? (
					<video
						src={loader}
						className="gif-loader-custom"
						controls={false}
						autoPlay
						muted
						loop
					/>
				) : (
					<Image className="gif-loader-custom" url={loader} />
				);
			}

			return <Skeleton height={100} width={100} borderRadius={12} />;
		},
		[
			isMp4,
			isWebComponentOpen,
			loader,
			loaderWhitelabel,
			user?.loggedIn,
			whitelabel,
		]
	);

	return {
		customLoaderElement,
		fetchLoader,
	};
};
