/* eslint-disable no-console */
/* eslint-disable no-case-declarations */
import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
	PlaidLinkOnEvent,
	PlaidLinkOnExit,
	PlaidLinkOnSuccess,
	PlaidLinkOptions,
	usePlaidLink,
} from 'react-plaid-link';
import { useNavigate } from 'react-router-dom';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';

import {
	CapTableGridTableData,
	CapTableMapKeyAndValue,
} from '@storybook/custom-captable/states';
import { API_URL, MESSAGE, message, PLAID_COUNTRY_CODE } from 'constant';
import {
	AllConditionalPipelinesState,
	AllPipelinesState,
	FundRecipientBankAccount,
	FundsState,
	InvestorTableGridData,
	IsCreatingFundState,
	IsOnboardingDeleteState,
	RecipientBankAccountLoadingState,
	SelectedDocsForUploadState,
	SelectedFundRecipientBankAccount,
	SelectedFundType,
	SelectedInviteFundState,
	UploadedDocsState,
	currentCapTableData,
} from 'global-stores';
import { number2words, useTrackEvents } from 'helpers';
import { useAllowedInvites, useNetwork, useNotification } from 'hooks';
import {
	getLuckSheetData,
	isEmailValid,
	isNumber,
	isValidAmount,
	isValidDecimal,
	isValidName,
} from 'utils';
import { useDashboards } from 'views/dashboard-analytics';
import {
	DEFAULT_FUND_OUT_TABLE,
	DEFAULT_FUND_TABLE,
	DEFAULT_FUND_TRANSFER_TABLE,
	DEFAULT_TABLE,
} from 'views/invite-investor/constants/default-cap-table';
import { WelcomeNewUserModalState } from 'views/new-user-popup';
import { usePipelineData } from 'views/pipelines/hooks';
import {
	ComplexConfigurationInvite,
	ConditionalOnboardingFlowState,
	FundsIdState,
	IComplexConfig,
	IsQrInviteState,
	SignOptionState,
	useInviteInvestor,
	userCapTableState,
} from '../../store';
import {
	OnboardingQRInstanceNameState,
	OnboardingQRModalNavigateState,
	OnboardingQRModalOpenState,
	SelectedPipelineDetailsForQRState,
	SelectedPipelineQrData,
	SelectedPipelineQrListState,
	SelectedQRSate,
	SelectedQRTemplateIdState,
	SelectedQrInstanceState,
} from './states';
import { UploadProofDocumentState } from 'views/upload-proof-document';
import { proofReadingKey } from 'views/pipelines/constants';

import {
	allConfiguredNodes,
	IsSessionChartLoadingState,
	OverlaySignAgreementEnvelopeId,
	SelectedComplexSignAgreementStep,
	SignAgreementComplexStepConfigured,
} from 'views/multi-sign-agreement';
import { UploadDocumentOptionState } from 'views/multisign-template-selection';
import { QrMessage, RECIPIENT_COUNT_ERROR_MESSAGE } from '../constants';

interface IArgument {
	templateId?: string;
	docId?: string;
	provider: string;
	node: string;
	templateType?: string;
}

const useAddBank = () => {
	// globle state
	const setIsModalOpen = useSetRecoilState(OnboardingQRModalOpenState);
	const setNavigate = useSetRecoilState(OnboardingQRModalNavigateState);
	const setFundRecipient = useSetRecoilState(FundRecipientBankAccount);
	const setLoading = useSetRecoilState(RecipientBankAccountLoadingState);
	const [subSelectedItem, setSubSelectedItem] = useRecoilState(
		SelectedFundRecipientBankAccount
	);

	// local states
	const [token, setToken] = useState('');
	const [selectedItem, setSelectedItem] = useState<any>();
	const [selectedIndex, setSelectedIndex] = useState(0);

	// hooks
	const { post: generateToken, data: tokenResponse } = useNetwork();
	const { get, post } = useNetwork();
	const { errorNotification, successNotification } = useNotification();

	const props = {
		setSelectedIndex,
		selectedIndex,
		setSelectedItem,
		selectedItem,
		subSelectedItem,
		setSubSelectedItem,
	};

	const onSuccess = useCallback<PlaidLinkOnSuccess>(
		async (publicToken, metadata) => {
			const payload = {
				bankName: metadata.institution?.name,
				linkToken: token,
				token: publicToken,
			};

			setLoading(true);
			setIsModalOpen(true);
			const response = await post(API_URL.TokenExchange, payload);
			if (response?.id) {
				successNotification('Account Linked Successfully');
				setNavigate('connectBank');
			}
			setToken('');
			get(API_URL.BusinessBankAccounts)
				.then(res => {
					if (res.data) {
						setFundRecipient(res.data);
						if (
							res.data.length > 0 &&
							res.data[0]?.accounts.length > 0 &&
							Object.keys(subSelectedItem ?? {}).length === 0 &&
							Object.keys(selectedItem ?? {}).length === 0
						) {
							setSelectedItem(res.data[0]);
							setSubSelectedItem({
								...res.data[0]?.accounts[0],
								_id: res.data[0]?._id,
							});
						}
					}
					setLoading(false);
				})
				.catch(err => {
					errorNotification(err.message ?? MESSAGE.ERROR);
					setLoading(false);
				});
		},

		[
			token,
			setLoading,
			setIsModalOpen,
			post,
			get,
			successNotification,
			setNavigate,
			setFundRecipient,
			subSelectedItem,
			selectedItem,
			setSubSelectedItem,
			errorNotification,
		]
	);

	//not required for now keep it for future use
	const onEvent = useCallback<PlaidLinkOnEvent>(() => ({}), []);

	//not required for now keep it for future use
	const onExit = useCallback<PlaidLinkOnExit>(() => {
		setToken('');
		setIsModalOpen(true);
	}, [setIsModalOpen]);

	const config: PlaidLinkOptions = {
		token,
		onSuccess,
		onEvent,
		onExit,
	};

	const { open } = usePlaidLink(config);

	useEffect(() => {
		if (tokenResponse?.token) {
			setToken(tokenResponse?.token);
			setIsModalOpen(false);
			open();
		}
	}, [open, setIsModalOpen, tokenResponse?.token]);

	const handleGenerateToken = useCallback(() => {
		const payload = {
			language: 'en',
			countryCodes: PLAID_COUNTRY_CODE,
		};
		generateToken(API_URL.GenerateLinkToken, payload);
	}, [generateToken]);

	return { handleGenerateToken, props };
};

export const useOnboardingQrFlow = () => {
	//globle states
	const setPipelines = useSetRecoilState(AllPipelinesState);
	const setConditionalPipelines = useSetRecoilState(
		AllConditionalPipelinesState
	);
	const pipelineDetails = useRecoilValue(SelectedPipelineDetailsForQRState);
	const selectedFund = useRecoilValue(SelectedInviteFundState);
	const uploadedFiles = useRecoilValue(UploadedDocsState);
	const fundType = useRecoilValue(SelectedFundType);
	const fundRecipient = useRecoilValue(FundRecipientBankAccount);
	const [selectedfundId, setFundsIdState] = useRecoilState(FundsIdState);
	const [funds, setFunds] = useRecoilState(FundsState);
	const [navigate, setNavigate] = useRecoilState(
		OnboardingQRModalNavigateState
	);
	const [instanceName, setInstanceName] = useRecoilState(
		OnboardingQRInstanceNameState
	);
	const subSelectedItem = useRecoilValue(SelectedFundRecipientBankAccount);
	const [selectedQrList, setSelectedQrList] = useRecoilState(
		SelectedPipelineQrListState
	);
	const resetIsModalOpen = useResetRecoilState(OnboardingQRModalOpenState);
	const resetNavigate = useResetRecoilState(OnboardingQRModalNavigateState);
	const resetFundId = useResetRecoilState(FundsIdState);
	const resetSelectedFund = useResetRecoilState(SelectedInviteFundState);
	const resetFundType = useResetRecoilState(SelectedFundType);
	const resetUploadedDocs = useResetRecoilState(UploadedDocsState);
	const reseAllConfiguredNodes = useResetRecoilState(allConfiguredNodes);
	const resetSelectedDocs = useResetRecoilState(SelectedDocsForUploadState);
	const resetOverlaytemplateIdState = useResetRecoilState(
		SelectedQRTemplateIdState
	);
	const resetQrInstance = useResetRecoilState(SelectedQrInstanceState);
	const resetIsComplex = useResetRecoilState(ConditionalOnboardingFlowState);
	const resetIsChartLoading = useResetRecoilState(IsSessionChartLoadingState);
	const resetUploadProofDocument = useResetRecoilState(
		UploadProofDocumentState
	);
	const setIsOnboaringQrModal = useSetRecoilState(OnboardingQRModalOpenState);
	const conditionalPipelines = useRecoilState(AllConditionalPipelinesState);
	const isNewFund = useRecoilValue(IsCreatingFundState);
	const setWelcomeNewUser = useSetRecoilState(WelcomeNewUserModalState);
	const { fundInvestment } = useRecoilValue(ComplexConfigurationInvite);
	const resetIsQrFlow = useResetRecoilState(IsQrInviteState);
	const resetSelectedPipelineQrList = useResetRecoilState(
		SelectedPipelineQrListState
	);
	const selectedQRSate = useRecoilValue(SelectedQRSate);
	const uploadProofDocument = useRecoilValue(UploadProofDocumentState);
	const resetSignOptionState = useResetRecoilState(SignOptionState)

	// hooks
	const { handleGenerateToken, props } = useAddBank();
	const { errorNotification, successNotification } = useNotification();
	const { trackEvents } = useTrackEvents();
	const { post: createFund, loading: creatingFund } = useNetwork();
	const { get, post, remove, patch } = useNetwork({ updateState: false });
	const { validateInputs } = useInviteInvestor();
	const { isAllowedToInvite } = useAllowedInvites();
	const { getDashboards } = useDashboards();
	//Captable Integration in QR code

	const [, setCapTableData] = useRecoilState(userCapTableState);
	const [selectedValue] = useRecoilState(CapTableMapKeyAndValue);
	const grid = useRecoilValue(InvestorTableGridData);
	const resetCapTableData = useResetRecoilState(CapTableGridTableData);
	const resetCurrentCapTable = useResetRecoilState(currentCapTableData);
	const resetCapTable = useResetRecoilState(InvestorTableGridData);
	const resetUserCapTable = useResetRecoilState(userCapTableState);
	const setIsOnboardingDelete = useSetRecoilState(IsOnboardingDeleteState);
	const resetComplexConfiguration = useResetRecoilState(
		ComplexConfigurationInvite
	);
	const resetOverlayInviteConfig = useResetRecoilState(
		OverlaySignAgreementEnvelopeId
	);
	const resetSignOption = useResetRecoilState(SignOptionState);
	const resetUploadDocumentOption = useResetRecoilState(
		UploadDocumentOptionState
	);
	const resetSignAgreementComplexStepConfigured = useResetRecoilState(
		SignAgreementComplexStepConfigured
	);
	const resetSelectedNode = useResetRecoilState(
		SelectedComplexSignAgreementStep
	);
	const resetOverlaySignAgreementEnvelopeId = useResetRecoilState(
		OverlaySignAgreementEnvelopeId
	);

	const configuration = useRecoilValue(ComplexConfigurationInvite);

	const navigation = useNavigate();
	const [isInviteQR, setisInviteQR] = useState(false);
	const qrStateSelectedData = useRecoilValue(SelectedPipelineQrData);
	const configurationStatus = useRecoilValue(
		SignAgreementComplexStepConfigured
	);
	const [recipientsForOverlay, setRecipientsForOverlay] = useState(0);
	const overlayMultiSignAgreement = useRecoilValue(
		OverlaySignAgreementEnvelopeId
	);

	const { getPinelines } = usePipelineData();

	//constants
	const { proofReading } = proofReadingKey;

	const validateInstance = useMemo(
		() =>
			selectedQrList &&
			selectedQrList?.find(item => item.name === instanceName.trim()),
		[instanceName, selectedQrList]
	);

	const payloadNewQr = useMemo(() => {
		if (instanceName.trim()) {
			const payload: any = {};
			payload.name = instanceName.trim() ?? '';
			payload.pipelineId = pipelineDetails?._id;
			const agreements: any[] = [];
			uploadedFiles.forEach(
				({ documentId: docId, templateId, isChecked, provider }) =>
					isChecked &&
					agreements.push({
						templateId,
						docId: provider === 'docusign' ? docId : undefined,
						provider,
					})
			);
			payload.agreements = agreements ?? [];
			// proofDoc payload for linearQr
			payload.proofDoc = uploadProofDocument?.map(item => ({ docId: item?.docId }));
			
			if (selectedfundId?.fundId) {
				payload.fundId = selectedfundId?.fundId ?? '';
			}
			if (/payIn|payOut|fundInvestment/gi.test(pipelineDetails?.fullStatus)) {
				payload.recipientBank = {
					tokenId: subSelectedItem?._id,
					accountId: subSelectedItem?.accountId,
					accountType: subSelectedItem?.subtype?.toUpperCase?.(),
				};
			}
			return payload;
		}
	}, [
		instanceName,
		pipelineDetails?._id,
		pipelineDetails?.fullStatus,
		uploadedFiles,
		selectedfundId?.fundId,
		subSelectedItem?._id,
		subSelectedItem?.accountId,
		subSelectedItem?.subtype,
		uploadProofDocument
	]);

	const payloadNewComplexQr = useMemo(() => {
		if (!instanceName?.trim()) {
			return {};
		}

		// TODO: @avinash please check for aggrements and banks
		// send the same data you are sending to checkout api
		const agreements: IArgument[] = [];
		const banks: Record<string, any>[] = [];

		configuration['fundInvestment'].forEach(
			({ _id, accountId, subtype, node, fundId, fundName }: any) => {
				if (!node) {
					return;
				}
				banks.push({
					tokenId: _id,
					accountId: accountId,
					accountType: subtype?.toUpperCase?.(),
					nodeId: node,
					fundId: fundId || undefined,
					fundName: fundName || undefined,
				});
			}
		);

		// // proofDoc payload for new complexQr
		// const proofDoc = uploadProofDocument?.map(item => ({
		// 	docId: item?.docId,
		// 	size: item?.size,
		// 	nodeId: item?.node,
		// }));

		// special case only for qr multisign invite, when user upload new document
		if (configurationStatus && Object.keys(configurationStatus).length > 0) {
			/**
			 * case for multi sign agreement
			 * */
			Object.keys(configurationStatus).map(key =>{
				agreements.push({
					templateId:
						configurationStatus[key as string]?.templateId ?? '',
					provider: 'esign',
					node: key ?? '',
					templateType: 'overlay',
				});
			})
		}

		/**
		 * Case for single sign agreement
		 * */
		if(uploadedFiles && uploadedFiles.length > 0) {
			uploadedFiles.forEach(
				({ documentId: docId, templateId, isChecked, node, provider }) => {
					if (!node) {
						return;
					}

					if (!isChecked) {
						return;
					}

					agreements.push({
						templateId,
						docId: provider === 'docusign' ? docId : undefined,
						provider: provider ?? '',
						node: node ?? '',
					});
				}
			);
		}
		// proofDoc payload for new complexQr 
		const proofDoc = uploadProofDocument?.map(item => ({ docId: item?.docId, size: item?.size, nodeId: item?.node }));

		const payload: Record<string, any> = {
			name: instanceName.trim() ?? '',
			pipelineId: pipelineDetails?._id,
			agreements: agreements ?? [],
			banks: banks ?? [],
			proofDoc: proofDoc ?? []
		};
		return payload;
	}, [
		instanceName,
		configuration,
		uploadedFiles,
		uploadProofDocument,
		configurationStatus,
		pipelineDetails?._id,		
	]);

	const payloadQrCsv = useMemo(() => {
		//payload.name = instanceName.trim() ?? '';
		//const filterQR = qrStateSelectedData
		const agreements: any[] = [];
		const envelopes: any[] = [];
		qrStateSelectedData?.agreements?.map((item: any) => {
			if (item.templateType === 'overlay' && configurationStatus[item.node as string]?.status === 'configured') {
				envelopes.push({
					nodeId: item?.node ?? undefined,
					envelopeId: configurationStatus[item.node as string]?.envelopeId,
				});
			}  
			if(item.templateType === 'basic') {	
				const templateAgreement = {
					templateId: item?.templateId,
					provider: item?.provider,
					docId: item?.docId ?? undefined,
					node: item?.node ?? undefined,
				};
				agreements.push(templateAgreement);
			}
		});
		// const proofDoc = qrStateSelectedData?.proofDoc?.map((item: any) => ({
		// 	docId: item?.docId,
		// 	size: item?.size,
		// 	nodeId: item?.node,
		// }));

		const proofDoc = qrStateSelectedData?.proofDoc?.map((item: any) => ({
			docId: item?.docId,
			size: item?.size,
			nodeId: item?.node || item?.nodeId,
		}));

		const payload: any = {
			pipelineId: qrStateSelectedData?.pipelineId,
			agreements: agreements ?? [],
			proofDoc: proofDoc ?? []
		};

		if (qrStateSelectedData.banks) {
			payload.recipientBanks = qrStateSelectedData?.banks;
		}

		if (overlayMultiSignAgreement.status === 'configured') {
			payload.envelopes = envelopes;
		}

		if (qrStateSelectedData?.fundId) {
			payload.fundId = qrStateSelectedData?.fundId ?? '';
		}
		if (Object.keys(qrStateSelectedData?.recipientBank ?? {}).length > 0) {
			payload.recipientBank = {
				tokenId: qrStateSelectedData?.recipientBank?.tokenId,
				accountId: qrStateSelectedData?.recipientBank?.accountId,
				accountType:
					qrStateSelectedData?.recipientBank?.accountType?.toUpperCase(),
			};
		} else {
			// TODO: check if the qr if for complex
			if (!qrStateSelectedData.banks) {
				payload.recipientBank = {};
			}
		}
		return payload;
	}, [overlayMultiSignAgreement, qrStateSelectedData, configurationStatus]);

	const reset = useCallback(() => {
		resetUploadedDocs();
		resetSelectedDocs();
		setInstanceName('');
		resetFundId();
		resetSelectedFund();
		resetFundType();
		resetNavigate();
		resetCapTableData();
		resetCurrentCapTable();
		resetUserCapTable();
		resetCapTable();
		resetComplexConfiguration();
		resetSelectedPipelineQrList();		
		resetSignOption();
		resetUploadDocumentOption();
		resetSignAgreementComplexStepConfigured();
		resetSelectedNode();
		resetOverlaySignAgreementEnvelopeId();
		resetSelectedPipelineQrList();
		resetUploadProofDocument();
		/**
		 * this will reset the isqrCode flag when invite modal gets closed by @Manish
		 * */
		resetIsQrFlow();
		resetSignOptionState();
		reseAllConfiguredNodes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigate]);

	const handleClose = useCallback(() => {
		const finalXlsValue: any = window;
		finalXlsValue?.luckysheet?.exitEditMode();
		reset();
		resetQrInstance();
		resetIsChartLoading();
		resetIsComplex();
		resetOverlaytemplateIdState();
		resetIsModalOpen();
		resetNavigate();
		reseAllConfiguredNodes();
		finalXlsValue?.luckysheet.destroy();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleBack = useCallback(() => {
		switch (navigate) {
			case 'create':
				setNavigate('csv');
				break;
			case 'connectBank':
				if (
					pipelineDetails?.fullStatus?.includes('fundInvestment') ||
					pipelineDetails?.fullStatus?.includes('payIn')
				) {
					setNavigate('create');
					break;
				}
				setNavigate('table');
				break;
			case 'signAgreement':
				if (
					pipelineDetails?.fullStatus?.includes('fundInvestment') ||
					pipelineDetails?.fullStatus?.includes('payIn') ||
					pipelineDetails?.fullStatus?.includes('payOut')
				) {
					setNavigate('connectBank');
					break;
				}
				resetUploadedDocs();
				resetUploadProofDocument();
				setNavigate('table');
				break;
			case proofReading:
				if (pipelineDetails?.fullStatus?.includes('signAgreement')) {
					setNavigate('signAgreement');
					break;
				}
				resetUploadProofDocument();
				setNavigate('table');
				break;
			case 'csv':
				handleClose();
				break;
			case 'configComplex':
				handleClose();
				break;
			case 'reconfigQR':
				handleClose();
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleClose, navigate, pipelineDetails?.fullStatus, setNavigate]);

	const getPipelineQrList = useCallback(
		async (_id: string, isComplex = false) => {
			if (_id) {
				let url = API_URL.PIPELINE_QR;

				if (isComplex) {
					url = API_URL.COMPLEX_QR;
				}
				const resp = await get(`${url}?pipelineId=${_id}`);
				const { data } = resp ?? {};
				if (data) {
					setSelectedQrList(data);
				} else setSelectedQrList([]);
				return data ?? [];
			}
		},
		[get, setSelectedQrList]
	);

	const reconfigPaylaod = useCallback(() => {
		const agreements: IArgument[] = [];
		const banks: Record<string, any>[] = [];
		configuration['fundInvestment'].forEach(
			({ _id, accountId, node, fundId, accountType, subtype }) => {
				if (!node) {
					return;
				}
				banks.push({
					tokenId: _id,
					accountId,
					accountType: accountType || subtype?.toUpperCase?.(),
					nodeId: node,
					fundId,
				});
			}
		);

		uploadedFiles.forEach(
			({ documentId: docId, templateId, isChecked, node, provider, templateType }) => {
				if (!node) {
					return;
				}
				if (!isChecked) {
					return;
				}
				agreements.push({
					templateId,
					docId: provider === 'docusign' ? docId : undefined,
					provider: provider ?? '',
					node: node ?? '',
					templateType
				});
			}
		);

		const qrname = pipelineDetails?.instances.find(
			(el: { _id: string }) => el._id === selectedQRSate
		)?.name;
		const agreementsPayload = agreements.map(({templateId,docId, provider, node, templateType }) => ({templateId, docId, provider, node, templateType}))


		const payload: Record<string, any> = {
			name: qrname ?? '',
			pipelineId: pipelineDetails?._id,
			agreements: agreementsPayload ?? [],
			banks: banks ?? [],
		};

		return {payload, agreements};
	}, [configuration, uploadedFiles, pipelineDetails, selectedQRSate]);

	const handleReconfigQR = useCallback(async () => {
		const {payload: parseConfigPaylaod, agreements } = reconfigPaylaod();
		const { signAgreement = [], fundInvestment } = configuration ?? {};
		parseConfigPaylaod['agreements'].concat(signAgreement);
		parseConfigPaylaod['banks'].concat(fundInvestment);
		const proofDoc = uploadProofDocument?.map(item => ({
			docId: item?.docId,
			size: item?.size,
			nodeId: item?.node,
		}));
		const reconfigComplexQRPayload = {
			...parseConfigPaylaod,
			proofDoc: proofDoc ?? [],
		};
		const url = `${API_URL.COMPLEX_QR}/${selectedQRSate}`;
		const resp = await patch(url, reconfigComplexQRPayload);

		if (resp?.success) {
			setConditionalPipelines(prePiplines => {
				const prePiplinesState = structuredClone(prePiplines);
				let newPipeline = prePiplinesState.find(
					({ _id }: { _id: string }) => _id === pipelineDetails?._id
				);
				const updatedInstances = newPipeline?.instances.map(
					(el: IComplexConfig) => {
						if (el._id === selectedQRSate) {
							return {
								...el,
								agreements: agreements ?? [],
								banks: parseConfigPaylaod?.banks ?? [],
								isDeleted: false,
							};
						} else
							return {
								...el,
							};
					}
				);
				const index = prePiplinesState.indexOf(newPipeline);
				newPipeline = {
					...newPipeline,
					instances: updatedInstances,
				};
				prePiplinesState[index] = { ...newPipeline };
				return [...prePiplinesState];
			});
			setisInviteQR(false);
		}
		return resp;
	}, [
		reconfigPaylaod,
		configuration,
		selectedQRSate,
		patch,
		setConditionalPipelines,
		pipelineDetails?._id,
		uploadProofDocument
	]);

	const updateonboardingList = useCallback(
		(resp: Record<any, any>, isComplex: boolean) => {
			// pradeep chaurasia : to update the qr list
			if (!isComplex) {
				setPipelines(prePiplines => {
					const prePiplinesState = JSON.parse(JSON.stringify(prePiplines));
					let newPipeline = prePiplinesState.find(
						({ _id }: { _id: string }) => _id === resp?.pipelineId
					);
					const index = prePiplinesState.indexOf(newPipeline);
					newPipeline = {
						...newPipeline,
						instances: [...(newPipeline?.instances ?? []), resp],
					};
					prePiplinesState[index] = { ...newPipeline };
					return [...prePiplinesState];
				});
			} else {
				setConditionalPipelines(prePiplines => {
					const prePiplinesState = JSON.parse(JSON.stringify(prePiplines));
					let newPipeline = prePiplinesState.find(
						({ _id }: { _id: string }) => _id === resp?.pipelineId
					);
					const index = prePiplinesState.indexOf(newPipeline);
					newPipeline = {
						...newPipeline,
						instances: [...(newPipeline?.instances ?? []), resp],
					};
					prePiplinesState[index] = { ...newPipeline };
					return [...prePiplinesState];
				});
			}
		},
		[setConditionalPipelines, setPipelines]
	);

	/**
	 * Generates a new QR instance based on certain conditions.
	 * If the instance name is already validated, it returns an error notification.
	 * Determines if the pipeline is complex based on pipeline details.
	 * Makes API calls to either create a new QR instance or reconfigure an existing one, based on pipeline complexity and reconfiguration status.
	 * If the API call is successful, updates the onboarding list, displays a success notification, and resets relevant states.
	 * If the API call fails, displays an error notification with a default message.
	 * @param isReconfigured - An optional string indicating if the QR instance is being reconfigured.
	 */
	const generateNewQR = useCallback(
		async (isReconfigured?: string) => {
			if (validateInstance)
				return errorNotification('Instance name already exists.');

			const isComplex = pipelineDetails?.nodes && pipelineDetails?.design;
			let resp: Record<string, any> | null = null;
			if (!isComplex) {
				resp = await post(`${API_URL.PIPELINE_QR}`, payloadNewQr);
			} else {
				if (isReconfigured) {
					setisInviteQR(true);
					resp = await handleReconfigQR();
				} else {
					setisInviteQR(false);
					resp = await post(`${API_URL.COMPLEX_QR}`, payloadNewComplexQr);
				}
			}

			if (resp?.success || resp?._id) {
				updateonboardingList(resp, isComplex);
				const { QR_GENERATED, QR_UNARCHIVE } = QrMessage ?? {};
				successNotification(isReconfigured ? QR_UNARCHIVE : QR_GENERATED);
				resetQrInstance();
				resetIsModalOpen();
				resetNavigate();
				resetIsComplex();
				reseAllConfiguredNodes();
				reset();
			} else {
				errorNotification(resp?.message ?? MESSAGE.ERROR_TRY);
			}
		},
		[
			validateInstance,
			errorNotification,
			pipelineDetails?.nodes,
			pipelineDetails?.design,
			post,
			payloadNewQr,
			handleReconfigQR,
			reseAllConfiguredNodes,
			payloadNewComplexQr,
			updateonboardingList,
			successNotification,
			resetQrInstance,
			resetIsModalOpen,
			resetIsComplex,
			reset,
			resetNavigate
		]
	);

	const deleteQrInstance = useCallback(
		async (qrId: string, isComplex = false) => {
			setSelectedQrList(pre => {
				const prevState = pre.filter((item: { _id: string }) => {
					return qrId !== item._id;
				});
				return [...prevState];
			});
			let url = API_URL.PIPELINE_QR;

			if (isComplex) {
				url = API_URL.COMPLEX_QR;
			}
			const resp = await remove(`${url}/${qrId}`).finally(() => {
				setIsOnboardingDelete(false);
				getPinelines();
			});
			const { success } = resp ?? {}
			if(success){
				successNotification(QrMessage.QR_ARCHIVED)
			}else{
				errorNotification(message.SomethingWentWrongMessage)
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[setIsOnboardingDelete]
	);

	const handleMoveToConnectBank = useCallback(async () => {
		trackEvents('create-fund-flow-name', {});
		const isAlreadyAvailable = !!funds.find(
			({ name }) =>
				name.trim().toLowerCase() === selectedFund.value.trim().toLowerCase()
		);
		if (fundType === 'create') {
			if (isAlreadyAvailable) {
				errorNotification('Fund with this name already available.');
				return;
			}
		}
		// create fund API if new fund arrived
		if (fundType === 'create') {
			const createFdResp = await createFund(API_URL.FUNDS, {
				name: selectedFund?.value,
			});
			if (createFdResp?._id) {
				const { _id } = createFdResp ?? {};
				setFundsIdState({ fundId: _id });
				setFunds(prev => [...prev, createFdResp]);
				successNotification('Fund created successfully');
			} else {
				errorNotification(createFdResp?.message);
				return;
			}
		}
		setNavigate('connectBank');
	}, [
		trackEvents,
		funds,
		fundType,
		setNavigate,
		selectedFund.value,
		errorNotification,
		createFund,
		setFundsIdState,
		setFunds,
		successNotification,
	]);

	const handleUncheckedSignAgreement = useCallback(() => {
		const unConfiguredFileIndex = uploadedFiles.findIndex(
			({ isChecked, configured }) => {
				if (isChecked) {
					return !configured;
				}
				return false;
			}
		);
		if (unConfiguredFileIndex > -1) {
			return true;
		}
		return false;
	}, [uploadedFiles]);

	const handleDisableSignAggrement = useCallback(() => {
		const docLength = uploadedFiles.length;
		const isAnyCheckedFile = uploadedFiles.findIndex(
			({ isChecked }) => isChecked
		);
		const isAllCheckedConfigured = uploadedFiles.findIndex(
			({ configured, isChecked }) => isChecked && !configured
		);

		if (
			docLength === 0 ||
			isAnyCheckedFile === -1 ||
			isAllCheckedConfigured > -1
		) {
			return true;
		}
		return false;
	}, [uploadedFiles]);

	const checkProperties = (obj: any) => {
		for (const key in obj) {
			if (obj[key] > 1) return true;
		}
		return false;
	};

	const getOverlayRecipients = useCallback(
		async (_id: string) => {
			const url = API_URL.SIGN_AGREEMENT;
			const resp = await get(`${url}/${_id}`);
			const { data } = resp ?? {};
			if (data) {
				setRecipientsForOverlay(data.recipients?.length);
			} else setRecipientsForOverlay(0);
		},
		[get, setRecipientsForOverlay]
	);

	const getOverlayAllRecipients = useCallback(
		async (templateIds: string[]) => {
			const url = API_URL.SIGN_AGREEMENT_TEMPLATE_LIST+`?source=onboarding&fetchCopyTemplates=true`;
			const resp = await post(`${url}`, { templateIds });
			const data = resp;
			if (data) {
				const overlayAgreements = data.filter((item: any) => item.type === 'overlay');
				let minRecipients = overlayAgreements[0]?.recipients?.length;
				for (let i = 1; i < overlayAgreements.length; i++) {
					if (overlayAgreements[i].recipients.length < minRecipients) {
						minRecipients = overlayAgreements[i].recipients.length;
					}
				}
				setRecipientsForOverlay(minRecipients);
			} else setRecipientsForOverlay(0);
		},
		[post, setRecipientsForOverlay]
	);

	const validateCaptableData = useCallback(() => {
		const { rows = [], headers = [] } = getLuckSheetData(
			pipelineDetails?.fullStatus?.includes('payIn')
		);
		setCapTableData({ rows, headers });
		let validateRow = true;
		const duplicateEmailData: { [key: string]: number } = {};
		selectedValue.forEach(item => {
			const key: any = Object.keys(item)[0];
			const value = item[key];
			const index = headers.indexOf(value);
			rows.forEach((rowItem: any, j: any) => {
				if (!rowItem[index] && rowItem.length && validateRow) {
					errorNotification(
						`Please fill the ${value} in  ${number2words(j + 2)} row `
					);
					validateRow = false;
					return;
				}

				if (key === 'First Name' || key === 'Last Name') {
					const nameIndex = headers.indexOf(key);
					const validName = isValidName(rowItem[nameIndex]);
					if (!validName && validateRow) {
						errorNotification(
							`Please fill the valid ${key.toLowerCase()} in   ${number2words(
								j + 2
							)} row`
						);
						validateRow = false;
						return;
					}
				}

				if (key === 'Country Code') {
					const countryCodeIndex = headers.indexOf('Country Code');
					const countryCodeValid =
						isNumber(rowItem[countryCodeIndex]) &&
						rowItem[countryCodeIndex].length >= 1 &&
						rowItem[countryCodeIndex].length <= 6;

					if (!countryCodeValid && validateRow) {
						errorNotification(
							`Please fill the valid country code in   ${number2words(
								j + 2
							)} row`
						);
						validateRow = false;
						return;
					}
				}

				if (key === 'Mobile') {
					const mobileIndex = headers.indexOf('Mobile');
					const mobileValid =
						isNumber(rowItem[mobileIndex]) &&
						rowItem[mobileIndex].length >= 8 &&
						rowItem[mobileIndex].length <= 14;
					if (!mobileValid && validateRow) {
						errorNotification(
							`Please fill the valid mobile in   ${number2words(j + 2)} row`
						);
						validateRow = false;
						return;
					}
				}

				if (key === 'Email') {
					const emailIndex = headers.indexOf('Email');
					const emailValid = isEmailValid(rowItem[emailIndex]);
					if (duplicateEmailData[rowItem[emailIndex]]) {
						errorNotification(
							`Email id's are same for ${
								duplicateEmailData[rowItem[emailIndex]]
							} and ${number2words(j + 2)} row`
						);
						validateRow = false;
						return;
					} else {
						duplicateEmailData[rowItem[emailIndex]] = number2words(j + 2);
					}

					if (!emailValid && validateRow) {
						errorNotification(
							`Please fill the valid email in   ${number2words(j + 2)} row`
						);
						validateRow = false;
						return;
					}
				}
				// pradeep chaurasia : minimum amount validate
				if (key === 'Dollars Invested' && value === 'Fund Transfer Amount') {
					const dollarsInvestedIndex = headers.indexOf('Fund Transfer Amount');
					const dollarsInvestedValid = isValidAmount(
						rowItem[dollarsInvestedIndex]
					);
					if (!dollarsInvestedValid && validateRow) {
						errorNotification(
							`Please fill only numeric values for amount in ${number2words(
								j + 2
							)} row.`
						);
						validateRow = false;
						return;
					}
					if (
						dollarsInvestedValid &&
						rowItem[dollarsInvestedIndex] > 0 &&
						rowItem[dollarsInvestedIndex] < 0.5
					) {
						errorNotification(
							`Minimum Amount should be $0.5 in ${number2words(j + 2)} row.`
						);
						validateRow = false;
						return;
					}
				}
				if (item[key] === 'Amount To Pay') {
					const dollarsInvestedIndex = headers.indexOf('Amount To Pay');
					const dollarsInvestedValid = isValidDecimal(
						rowItem[dollarsInvestedIndex]
					);

					if (!dollarsInvestedValid && validateRow) {
						errorNotification(
							`Please fill the valid amount in   ${number2words(j + 2)} row`
						);
						validateRow = false;
						return;
					}
				}
			});
		});
		return validateRow;
	}, [
		pipelineDetails?.fullStatus,
		setCapTableData,
		selectedValue,
		errorNotification,
	]);

	const validateCapTableRowForQROverlay = useCallback(() => {
		const { rows = [] } = getLuckSheetData(
			pipelineDetails?.fullStatus?.includes('payIn')
		);

		if (!validateCaptableData()) {
			return false;
		}
		if (rows.length <= recipientsForOverlay) {
			return true;
		}

		errorNotification(RECIPIENT_COUNT_ERROR_MESSAGE + recipientsForOverlay);
		return false;
	}, [
		recipientsForOverlay,
		errorNotification,
		pipelineDetails,
		validateCaptableData,
	]);

	const handleCsvfile = useCallback(
		async (finalPaylaod: any) => {
			setisInviteQR(true);
			let url = API_URL.INVITE_INVESTOR;
			// TODO: for now checking complex using payload
			// need to do with type
			if (finalPaylaod.recipientBanks) {
				url = API_URL.COMPLEX_INVITE;
			}
			const resp = await post(url, finalPaylaod);
			if (resp?.success) {
				resetOverlayInviteConfig();
				handleClose();
				getDashboards();
				navigation('/sessions');
			}
			setisInviteQR(false);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const handleQRcsv = useCallback(
		(pipelineDetailsData: any) => {
			let DEFAULT_TABLE_HEADER = DEFAULT_TABLE[0];
			validateInputs();
			if (conditionalPipelines) {
				DEFAULT_TABLE_HEADER = DEFAULT_TABLE[0];
			}
			if (
				pipelineDetailsData?.fullStatus?.includes('fundInvestment') ||
				pipelineDetailsData?.fullStatus?.includes('payIn')
			) {
				DEFAULT_TABLE_HEADER = DEFAULT_FUND_TABLE[0];
			} else if (pipelineDetailsData?.fullStatus?.includes('payOut')) {
				DEFAULT_TABLE_HEADER = DEFAULT_FUND_OUT_TABLE[0];
			} else if (
				!conditionalPipelines &&
				(!pipelineDetailsData?.fullStatus || isNewFund)
			) {
				DEFAULT_TABLE_HEADER = DEFAULT_FUND_TRANSFER_TABLE[0];
			} else {
				DEFAULT_TABLE_HEADER = DEFAULT_TABLE[0];
			}

			const finalXlsValue: any = window;
			finalXlsValue?.luckysheet.exitEditMode();
			const finalData = finalXlsValue?.luckysheet?.transToCellData?.(
				finalXlsValue?.luckysheet.getSheetData()
			);
			const expectedArray = finalData?.reduce((acc: any, curr: any) => {
				if (!acc[curr.r]) {
					acc[curr.r] = [];
				}
				if (pipelineDetails?.fullStatus?.includes('payIn')) {
					if (curr.v.v || curr.v.v === 0)
						acc[curr.r][curr.c] = curr.v.v?.toString();
					else {
						if (acc[0][curr.c] === 'Fund Transfer Amount')
							acc[curr.r][curr.c] = '0';
					}
					return acc;
				} else {
					if (curr.v.v) acc[curr.r][curr.c] = curr.v.v?.toString();
					return acc;
				}
			}, []);

			const rows = expectedArray.filter(
				(item: any, index: number) => index !== 0 && item.length
			);

			if (!isAllowedToInvite('onboarding', (rows ?? []).length)) {
				handleClose();
				setWelcomeNewUser({
					open: true,
					type: 'Credits_Remain_With_Remaining',
					serviceName: 'Onboarding Service',
				});
				return;
			}

			let isValid = false;
			rows?.forEach((element: any) => {
				const copyDataArray = [...element];
				const isExistVal = copyDataArray.some(el => !el);
				if (isExistVal) isValid = true;
				else isValid = false;
			});

			// remove first element of the array and check the length of the each row and column
			const headers = expectedArray.shift();
			const matchedHeader = DEFAULT_TABLE_HEADER?.filter((el: any) =>
				headers.includes(el)
			);
			const isSameHeader = DEFAULT_TABLE_HEADER?.every((el: any) =>
				matchedHeader?.includes(el)
			);

			const isheaderEmpty = headers.includes(undefined);
			let isRowLengthGreater = false;

			for (let i = 0; i < expectedArray.length; i++) {
				if (expectedArray[i]?.length) {
					if (expectedArray[i].length !== headers.length) {
						isValid = true;
					}
					if (expectedArray[i].length > headers.length) {
						isRowLengthGreater = true;
					}
				}
			}

			if (new Set(headers).size !== headers.length) {
				const repeatHeader: any = {};
				headers?.forEach((value: any) => {
					const trimmedValue = value?.toLowerCase().trim();
					if (repeatHeader[trimmedValue]) {
						repeatHeader[trimmedValue] = repeatHeader[trimmedValue] + 1;
					} else {
						repeatHeader[trimmedValue] = 1;
					}
				});
				const isDuplicate = checkProperties(repeatHeader);
				if (isDuplicate) {
					errorNotification('Duplicate header value encountered');
					return;
				}
			}

			if (isheaderEmpty || isRowLengthGreater) {
				errorNotification('Header should not be empty');
				return;
			}

			if (isValid) {
				errorNotification('Please fill all fields');
				return;
			}
			const emptyHeader = grid?.[0]?.find((item: any) => item.value === '');
			if (isSameHeader) {
				if (validateCaptableData()) {
					const indexFundTransfer = headers.indexOf('Fund Transfer Amount');
					if (indexFundTransfer !== -1) {
						headers[indexFundTransfer] = 'Dollars Invested';
					}
					const indexAmountInvested = headers.indexOf('Amount To Pay');
					if (indexAmountInvested !== -1) {
						headers[indexAmountInvested] = 'Dollars Invested';
					}

					const users = { headers: headers, rows: expectedArray };

					const finalPaylaod = { ...payloadQrCsv, users };
					handleCsvfile(finalPaylaod);
				}
			} else {
				// setNavigate('configCaptable');
				errorNotification('Header can not be changed.');
			}

			trackEvents('create-fund-flow-csv', { emptyHeader });
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			conditionalPipelines,
			errorNotification,
			grid,
			handleCsvfile,
			isAllowedToInvite,
			isNewFund,
			payloadQrCsv,
			pipelineDetails?.fullStatus,
			trackEvents,
			validateCaptableData,
			validateInputs,
		]
	);
	const handleNext = useCallback(
		async (item?: any, page?: string) => {
			const pipelineDetailsData =
				!item || !item?.fullStatus ? pipelineDetails : item;
			switch (page || navigate) {
				case 'table': {
					setIsOnboaringQrModal(true);
					// TODO: @gaurav add type for complex and change it
					const isComplex = item?.nodes && item?.design;
					if (isComplex) {
						setNavigate('configComplex');
						break;
					}
					if (
						pipelineDetailsData?.fullStatus?.includes('fundInvestment') ||
						pipelineDetailsData?.fullStatus?.includes('payIn')
					) {
						setNavigate('create');
						break;
					}
					if (pipelineDetailsData?.fullStatus?.includes('payOut')) {
						setNavigate('connectBank');
						break;
					}
					if (pipelineDetailsData?.fullStatus?.includes('signAgreement')) {
						setNavigate('signAgreement');
						break;
					}

					//Gaurav: Added to show proofReadDocuments.
					if (pipelineDetailsData?.fullStatus?.includes(proofReading)) {
						setNavigate(proofReading);
						break;
					}
					setNavigate('instance_name');
					break;
				}
				case 'create':
					handleMoveToConnectBank();
					break;
				case 'connectBank':
					if (pipelineDetailsData?.fullStatus?.includes('signAgreement')) {
						setNavigate('signAgreement');
						break;
					}
					//Gaurav: Added to show proofReadDocuments.
					if (pipelineDetailsData?.fullStatus?.includes(proofReading)) {
						setNavigate(proofReading);
						break;
					}
					setNavigate('instance_name');
					break;
				case 'signAgreement':
					if (handleUncheckedSignAgreement()) {
						errorNotification(
							'Please configure the document before moving to the next step'
						);
					}
					//Gaurav: Added to show proofReadDocuments.
					else if (pipelineDetailsData?.fullStatus?.includes(proofReading)) {
						setNavigate(proofReading);
					} else {
						setNavigate('instance_name');
					}
					break;
				case proofReading:
					setNavigate('instance_name');
					break;
				case 'instance_name':
					await generateNewQR();
					break;
				case 'csv':
					handleQRcsv(pipelineDetailsData);
					break;
				case 'configComplex':
					setNavigate('instance_name');
					break;
				case 'reconfigQR':
					await generateNewQR('reconfig');
					resetIsModalOpen();
					break;
			}
		},
		[
			pipelineDetails,
			navigate,
			handleMoveToConnectBank,
			setNavigate,
			handleUncheckedSignAgreement,
			generateNewQR,
			handleQRcsv,
			setIsOnboaringQrModal,
			errorNotification,
			resetIsModalOpen,
			proofReading,
		]
	);

	const isSubmitDisable = useMemo(() => {
		switch (navigate) {
			case 'create':
				if (
					!selectedFund.value ||
					creatingFund ||
					(fundType === 'edit' && selectedFund.label === '')
				) {
					return true;
				}
				return false;
			case 'connectBank':
				if (fundRecipient?.length > 0) {
					return false;
				}
				return true;
			// Fixing disabled button for config comolex
			case 'configComplex':
			case 'reconfigQR':
				const { nodes } = pipelineDetails?.design ?? {};
				const allIds = nodes
					?.filter((el: any) =>
						/signAgreementVerification|fundInvestmentVerification|proofVerification/.test(el.key)
					)
					?.map((el: any) => el.id);

				const allConfiguredSignAgreement = uploadedFiles.map(el => el.node);
				const allConfiguedFundInvestment = fundInvestment.map(
					(el: any) => el.node
				);
				const allConfiguredProofReading = uploadProofDocument.map(
					el => el.node
				);

				const allConfiguredSteps = [
					...allConfiguredSignAgreement,
					...allConfiguedFundInvestment,
					...allConfiguredProofReading,
				];

				// if this is mulitsign then pushing the selectedSignNode to all configured step id
				// if (
				// 	configurationStatus[selectedSignNode as string]?.status ===
				// 	'configured' 
				// ) {
				// 	allConfiguredSteps.push(selectedSignNode);
				// }
				Object.keys(configurationStatus).map(key =>{
					if(configurationStatus[key as string]?.status === 'configured'){
						allConfiguredSteps.push(key);
					}
				});

				const data = allIds?.every((el: string) =>
					allConfiguredSteps.includes(el)
				);

				return !data;
			case 'signAgreement':
				return handleDisableSignAggrement();
			case 'instance_name':
				if (!instanceName.trim()) {
					return true;
				}
				return false;
			case 'csv':
				return isInviteQR;
			case proofReading:
				return !uploadProofDocument.length;
			default:
				return false;
		}
	}, [
		navigate,
		selectedFund,
		creatingFund,
		fundType,
		fundRecipient?.length,
		pipelineDetails,
		uploadedFiles,
		fundInvestment,
		handleDisableSignAggrement,
		instanceName,
		isInviteQR,
		configurationStatus,
		uploadProofDocument,
		proofReading
	]);

	const isSubmitLoading = useMemo(() => {
		switch (navigate) {
			case 'create':
				return creatingFund;
			case 'csv':
			case 'reconfigQR':
				return isInviteQR;
			default:
				return false;
		}
	}, [navigate, creatingFund, isInviteQR]);

	const handleDownload = useCallback((url: string, QrName: string) => {
		const downloadLink = document.createElement('a');
		const fileName = `;filename=${QrName}.png`;
		downloadLink.href = url + fileName;
		downloadLink.download = QrName;
		downloadLink.click();
	}, []);

	const handleCopy = useCallback(
		(e: MouseEvent<HTMLElement>, _id: string) => {
			e.stopPropagation();
			if (_id) {
				navigator.clipboard.writeText(_id);
				successNotification('Copied.');
			}
		},
		[successNotification]
	);

	return {
		reset: handleClose,
		handleNext,
		handleGenerateToken,
		props,
		isSubmitDisable,
		isSubmitLoading,
		handleDownload,
		handleBack,
		getPipelineQrList,
		deleteQrInstance,
		getOverlayRecipients,
		getOverlayAllRecipients,
		validateCapTableRowForQROverlay,
		handleCopy,
	};
};
