import { FC, useCallback, useState } from 'react';
import { KeyValuePair } from '../key-value-pair';

interface IProps {
	id?: string;
	name: string;
	riskScore: string | number;
	date: string;
	children: JSX.Element;
	entityId: string;
}

export const CaseHOC: FC<IProps> = ({
	name,
	riskScore,
	date,
	children,
	entityId,
}) => {
	const [isOpen, setIsOpen] = useState(true);

	const handleDropClick = useCallback(() => {
		setIsOpen(!isOpen);
	}, [isOpen]);

	return (
		<div className="ofac__accordion">
			<div onClick={handleDropClick}  className="ofac__accordion-header">
				<div>
					<div className="ofac__accordion-heading">
						{entityId} • {name}
					</div>
					<div className="ofac__accordion-subheading">
						<KeyValuePair value={riskScore} isDanger={true} label={'Risk Score'} />
						<div className="ofac__header-list-item"></div>
						<KeyValuePair value={date} label={'Date'} />
					</div>
				</div>
				<div className="ofac__accordion-icon">
					{isOpen ? (
						<i className="ri-arrow-drop-up-line"></i>
					) : (
						<i className="ri-arrow-drop-down-line"></i>
					)}
				</div>
			</div>
			{isOpen && children}
		</div>
	);
};
