export type IQrHeader = {
    label: string,
    name: string,
    type: "text"
}

export const QR_HEADER: IQrHeader[] = [
	{
		label: 'First Name',
		name: 'First Name',
		type: 'text',
	},
	{
		label: 'Last Name',
		name: 'Last Name',
		type: 'text',
	},
	{
		label: 'Country Code',
		name: 'Country Code',
		type: 'text',
	},
	{
		label: 'Mobile',
		name: 'Mobile',
		type: 'text',
	},
	{
		label: 'Email',
		name: 'Email',
		type: 'text',
	},
];
