import { SessionQuestionLabel } from './questionlabel';
import { CheckBox } from './checkbox-one';
import './form-actions.scss';

export const FormOne = ({ choices }:{choices:string[]}) => {
	const onChangeCheckbox = () => {
		return;
	};
	return (
		<>
			<div className="form-action__questions-wrapper" style={{ margin: '0px' }}>
				<SessionQuestionLabel label="Select your identity type?" />

				{choices?.map(el => (
					<CheckBox
						key={el}
						checked={true}
						label={el}
						onChangeCheckbox={onChangeCheckbox}
					/>
				))}
			</div>
		</>
	);
};
