import { FC, createRef } from 'react';
import { Button, Image, Loader } from '@storybook';
import Modal from '@storybook/new-modal/modal';

import { message, MODAL_TYPE } from 'constant';

interface IOnboardingFlowBackModel {
	type?: string;
	description?: string;
	isOpen: boolean;
	isLoaded?: boolean;
	handleProceedBack: () => void;
	handleInput: () => void;
}

/**
 * OnboardingFlowBackModel component displays a modal with different
 * messages and actions based on the type of modal.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {string} [props.type=MODAL_TYPE.LEAVE] - Type of the modal (e.g., leave, delete).
 * @param {string} [props.description] - Additional description text for the modal.
 * @param {boolean} props.isOpen - Indicates if the modal is currently open.
 * @param {boolean} [props.isLoaded] - Indicates if the modal content is loaded.
 * @param {Function} props.handleProceedBack - Function to handle the "Proceed Back" action.
 * @param {Function} props.handleInput - Function to handle input actions within the modal.
 * @returns {JSX.Element} The OnboardingFlowBackModel component.
 */
export const OnboardingFlowBackModel: FC<IOnboardingFlowBackModel> = ({
	isOpen,
	isLoaded,
	handleProceedBack,
	handleInput,
	type = MODAL_TYPE.LEAVE,
	description
}) => {
	const ref = createRef<HTMLDivElement>();

	/**
	 * Renders the body of the modal based on the type.
	 *
	 * @function
	 * @returns {JSX.Element} The modal body content.
	 */
	const renderBody = (): JSX.Element => {
		switch (type) {
			case MODAL_TYPE.DELETE:
				return (
					<div className="onboarding-flow-back-modal">
						<div className="onboarding-flow-back-modal__wrapper">
							<div className="onboarding-flow-back-modal__image">
								{type === MODAL_TYPE.LEAVE ? (
									<Image fileName="Logout.svg" />
								) : (
									<Image
										fileName="confirmation_page.svg"
										className="confirmation-image"
									/>
								)}
								<div className="onboarding-flow-back-modal__text">
									{message.DELETE_CONFIRMATION_MESSAGE}
								</div>
							</div>
							<div className="onboarding-flow-back-modal__text-head">
								<div className="onboarding-flow-back-modal__subtext">
									{description}
								</div>
							</div>
							<div className="onboarding-flow-back-modal__footer">
								<Button
									label={
										isLoaded ? (
											<Loader type="loader" dimension={20} />
										) : (
											"Cancel"
										)
									}
									type="button__filled button__filled--secondary button__large onboarding-flow-back-modal__secondary"
									handleClick={handleProceedBack}
								/>
								<Button
									label={"Delete"}
									type="button__filled button__filled--primary button__large onboarding-flow-back-modal__size onboarding-flow-back-modal__danger"
									handleClick={() => handleInput()}
								/>
							</div>
						</div>
					</div>
				);
			default:
				return (
					<div className="onboarding-flow-back-modal">
						<div className="onboarding-flow-back-modal__wrapper">
							<div className="onboarding-flow-back-modal__image">
								<Image fileName="Logout.svg" />
								<div className="onboarding-flow-back-modal__text">
									{message.CONFIRMATION_MESSAGE}
								</div>
							</div>
							<div className="onboarding-flow-back-modal__text-head">
								<div className="onboarding-flow-back-modal__subtext">
									{message.ALERT_NOTIFICATION}
								</div>
							</div>
							<div className="onboarding-flow-back-modal__footer">
								<Button
									label={
										isLoaded ? (
											<Loader type="loader" dimension={20} />
										) : (
											"Leave"
										)
									}
									type="button__filled button__filled--secondary button__large onboarding-flow-back-modal__secondary"
									handleClick={handleProceedBack}
								/>
								<Button
									label={"Stay"}
									type="button__filled button__filled--primary button__large onboarding-flow-back-modal__size"
									handleClick={() => handleInput()}
								/>
							</div>
						</div>
					</div>
				);
		}
	};

	return (
		<div ref={ref}>
			<Modal
				isOpen={isOpen}
				modalName="Login modal"
				className="onboarding-flow-back-confirmation-modal"
				showCloseBtn={false}
				isStopOutsideClick={true}
				overlayStyles={{ overlay: { zIndex: 100000 } }}
			>
				{renderBody()}
			</Modal>
		</div>
	);
};
