import { Image } from '@storybook';
import { FC } from 'react';
import { useRecoilValue } from 'recoil';

import { getMinutesTime } from 'helpers';
import { comparedFunnelChartsState } from 'views/analytics/hooks';
import { onboardingSteps } from '../detailed-charts';
import './chart-status.scss';

interface IChartStatus {
	percent: number;
	title: string;
	color: string;
	diff: number;
	avgTime?: number;
}

export const ChartStatus: FC<IChartStatus> = ({
	percent,
	title,
	color,
	diff,
	avgTime,
}) => {
	const inPercentage = `${percent}%`;
	const comparedChart = useRecoilValue(comparedFunnelChartsState);

	return (
		<div className="chart_status">
			<div className="chart_status_wrapper">
				<div className="chart_status_title">{title}</div>
				<div className="chart_status_wrapper">
					<div className="chart_status_title chart-icon">
						{comparedChart &&
							(diff < 0 ? (
								<Image fileName="trending-up.svg" />
							) : diff === 0 ? (
								''
							) : (
								<Image fileName="trending-down.svg" />
							))}
						{percent.toFixed(1)}%
					</div>
				</div>
			</div>
			{!comparedChart && onboardingSteps.includes(title) && (
				<div className="chart_status_average">
					Average time
					<span className="chart_status_average_time">
						{getMinutesTime(diff ? avgTime ?? '' : 0)}
					</span>
				</div>
			)}
			<div className="chart_status_line">
				<div
					className="chart_status_percentage"
					style={{
						width: inPercentage,
						background: color,
					}}
				/>
			</div>
		</div>
	);
};
