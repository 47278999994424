import { FC, ReactElement } from 'react';
import './questionlabel.scss';

interface IQUestionLabel {
	children?: ReactElement;
	label?: string;
}
export const SessionQuestionLabel: FC<IQUestionLabel> = ({
	children,
	label,
}) => {
	return (
		<div className="question-label">
			<div className="question-label__left">
				<div className="question-label__icon">
					{children ?? <i className="ri-question-line"></i>}
				</div>
				<div className="question-label__label">
					{label ?? 'Condition block'}
				</div>
			</div>
		</div>
	);
};
