import {
	BaseEdge,
	EdgeLabelRenderer,
	EdgeProps,
	getBezierPath,
} from 'reactflow';

import './custom-edge.scss';
import { useComplexLayout } from 'views/complex-onboarding-flow/hooks';
import { MouseEvent, memo } from 'react';

//Avinash: Added this Component for Custom Edges
const CustomEdge = ({
	id,
	sourceX,
	sourceY,
	targetX,
	targetY,
	sourcePosition,
	targetPosition,
	style = {},
	markerEnd,
}: EdgeProps) => {
	const { handleDeleteEdges } = useComplexLayout();
	const [edgePath, labelX, labelY] = getBezierPath({
		sourceX,
		sourceY,
		sourcePosition,
		targetX,
		targetY,
		targetPosition,
	});

	const onEdgeClick = (
		evt: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
		id: string
	) => {
		evt.stopPropagation();
		handleDeleteEdges(id);
	};

	return (
		<>
			<BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
			<EdgeLabelRenderer>
				<div
					style={{
						position: 'absolute',
						transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
						fontSize: 12,
						pointerEvents: 'all',
					}}
					className="nodrag nopan"
				>
					<button
						className="edgebutton"
						onClick={event => onEdgeClick(event, id)}
					>
						×
					</button>
				</div>
			</EdgeLabelRenderer>
		</>
	);
};

export default memo(CustomEdge);
