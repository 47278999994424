import { useCallback } from 'react';

import { CopyBlock, dracula } from 'react-code-blocks';
import { useNotification } from 'hooks';

interface Props {
	code: string;
}

/**
 * CodeSpace Component
 * @param {string} Props.code - The code to be displayed in the CodeSpace component.
 */

export const CodeSpace = ({ code }: Props) => {
	const { successNotification } = useNotification();

	const handleCopy = useCallback(() => {
		successNotification('Copied Code!');
		// eslint-disable-next-line
	}, []);

	return (
		<CopyBlock
			text={code}
			language={'jsx'}
			showLineNumbers={false}
			theme={dracula}
			codeBlock
			onCopy={handleCopy}
		/>
	);
};
