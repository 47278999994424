import { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { API_URL, MESSAGE } from 'constant';
import { UserSubscriptionsState } from 'global-stores';
import { useNetwork, useNotification } from 'hooks';
import { IdentityType } from '../constants';
import { useBillingDetails } from './use-processing';
import { useUserInit } from './use-user';

export const useError = () => {
	const userSubscriptions = useRecoilValue(UserSubscriptionsState);

	const [isLoading, setIsLoading] = useState(false);
	const { post } = useNetwork({
		updateState: false,
		returnResponse: true,
	});

	const { initBillingApi } = useBillingDetails();
	const { initUser } = useUserInit();
	const { errorNotification } = useNotification();

	const hasSubscribedPerson = useMemo(
		() =>
			userSubscriptions[0]?.[IdentityType.Individual] &&
			userSubscriptions[0]?.[IdentityType.Individual].status,
		[userSubscriptions]
	);

	const onRetry = async () => {
		setIsLoading(true);
		let resp;
		if (hasSubscribedPerson) resp = await post(API_URL.SUBSCRIPTIONS, {});
		else resp = await post(API_URL.SUBSCRIPTIONS, { upgrade: true });
		if (resp.success) {
			await initBillingApi();
			await initUser();
		} else {
			errorNotification(resp?.errorData?.message || MESSAGE.ERROR);
		}
		setIsLoading(false);
	};

	return {
		isLoading,
		onRetry,
	};
};
