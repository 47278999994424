import { ReactDropdown } from '@storybook';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { PipelineSettingsFormState } from 'global-stores';
import {
	EdgesState,
	NodesState,
} from 'views/complex-onboarding-flow';
import {
	AddedActionsState,
	AddedFileDataState,
	CurrentStepState,
} from 'views/onboarding-flow';
import { ComplexSettingFormState, useSendNotification } from '../store';
import { ReminderDate } from './reminder-date';
import { CheckboxInput } from './reminder-input/checkbox-input';

interface IOption {
	label: string;
	value: string | string[];
	price?: number;
}
interface ISelectComponent {
	label: string;
	name: string;
	selectOptions: IOption[];
	defaultValue: IOption;
	hasInviteModal?: boolean;
	id?: string;
}

let isHideCustom = '';

export const SelectComponent: FC<ISelectComponent> = ({
	label,
	name,
	selectOptions,
	defaultValue,
	hasInviteModal,
	id,
}) => {
	const [settingsForm, setSettingsForm] = useRecoilState(
		PipelineSettingsFormState
	);
	const [currentStep, setCurrentStep] = useRecoilState<any>(CurrentStepState);
	const setAddedAccredationFile = useSetRecoilState(AddedFileDataState);
	const setAddedActions = useSetRecoilState(AddedActionsState);

	const [complexSettingForm, setComplexSettingForm] = useRecoilState(
		ComplexSettingFormState
	);

    const setNodes = useSetRecoilState(NodesState);
	const setEdges = useSetRecoilState(EdgesState);
	// hooks
	const { onChangeCheckbox, daysList } = useSendNotification();

	const updateActionPrice = useCallback((name: string, actionKey: string) => {
		setAddedActions(prev => {
			const prevState = JSON.parse(JSON.stringify(prev));
			const foundActionIndex = prevState.findIndex(
				({ key }: any) => key === actionKey
			);
			const foundOptionIndex = (
				prevState[foundActionIndex]?.metadata?.[0]?.options ?? []
			).findIndex((option: any) => option.value === name);
			const { price } =
				prevState[foundActionIndex]?.metadata?.[0]?.options?.[
					foundOptionIndex
				] ?? {};
			if (price) {
				prevState[foundActionIndex].price = price;
			}
			return prevState;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onRemoveAccreditationBlock = useCallback(() => {
		setNodes((prev: any) => {
			const prevObj = JSON.parse(JSON.stringify(prev));
			const findIndex = prevObj.findIndex((el: any) => el.id === id);
			if (findIndex !== -1) {
				prevObj[findIndex].conditions = [];
			}
			return prevObj;
		});
		setEdges((prev: any) => {
			let prevObj = structuredClone(prev);
			prevObj = prevObj?.filter(
				(el: any) => !(el.source === id && el.source !== el.sourceHandle)
			);
			return prevObj;
		});
	}, [id, setEdges, setNodes]);

	const handleChange = useCallback(
		(event: any) => {
			if (name === 'signAgreementProvider') {
				updateActionPrice(event.value, 'signAgreementVerification');
			}
			if (event.value === '506c' && isHideCustom !== '506c') {
				setCurrentStep((preState: any) => ({
					...preState,
					copyMetadata: preState.metadata,
					metadata: [...preState.metadata].slice(0, 1),
				}));
				updateActionPrice('506c', 'accreditationVerify');
			} else if (event.value === '506b' && isHideCustom !== '506b') {
				onRemoveAccreditationBlock();
				setCurrentStep((preState: any) => ({
					...preState,
					metadata: preState.copyMetadata ?? preState.metadata,
				}));
				updateActionPrice('506b', 'accreditationVerify');
			}
			// eslint-disable-next-line
			isHideCustom = event.value;
			if (name === 'accreditationDocument') {
				setAddedAccredationFile(prev => ({ ...prev, type: event.value }));
			}

			if (id) {
				//we have created another state as there could be multiple kyc aml
				const payload = {
					[name]: event,
				};
				setSettingsForm({
					...settingsForm,
					accreditationDocument: { label: event.label, value: event.value },
				});

				setComplexSettingForm((prev: any) => {
					const newObj = structuredClone(prev);
					if (!newObj[id]) {
						newObj[id] = {};
					}

					if (newObj[id].accreditationDocument && event.value === '506c') {
						newObj[id].accreditationDocument = {
							label: 'Generic',
							value: 'generic',
						};
					}

					Object.assign(newObj[id], payload);
					return newObj;
				});
			}
		},
		[
			name,
			id,
			updateActionPrice,
			setCurrentStep,
			setAddedAccredationFile,
			setSettingsForm,
			settingsForm,
			setComplexSettingForm,
			onRemoveAccreditationBlock,
		]
	);

	// Setting default value for complex config
	useEffect(() => {
		if (id && !complexSettingForm?.[id]) {
			const payload = {
				[name]: defaultValue,
			};
			setComplexSettingForm((prev: any) => {
				const newObj = structuredClone(prev);
				if (!newObj[id]) {
					newObj[id] = {};
				}
				Object.assign(newObj[id], payload);
				return newObj;
			});
		}
	}, [complexSettingForm, defaultValue, id, name, setComplexSettingForm]);

	const initialValue = useMemo(() => {
		return complexSettingForm[id ?? '']?.[name];
	}, [complexSettingForm, id, name]);

	return (
		<div className="select-component" key={name}>
			{currentStep?.key !== 'reminderSetting' && (
				<div className="select-label">{label}</div>
			)}
			{currentStep?.key === 'reminderSetting' || hasInviteModal ? (
				<div className="reminder__container">
					<CheckboxInput
						label="Select specific date"
						onChangeCheckbox={onChangeCheckbox}
						checked={daysList.checked}
					/>

					<ReminderDate />
				</div>
			) : (
				<ReactDropdown
					optionsStyle={{
						'&:hover': { background: 'var(--color-hover-light-100)' },
					}}
					options={selectOptions}
					defaultValue={defaultValue}
					value={initialValue}
					handleChangeSelect={handleChange}
				/>
			)}
		</div>
	);
};
