import { Button, Input, Loader } from '@storybook';
import { API_URL, message } from 'constant';
import { useNetwork, useNotification } from 'hooks';
import {
	ChangeEvent,
	FC,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
	AllConditionalPipelinesState,
	AllPipelinesState,
	SelectedPipelineState,
} from 'global-stores';
import { validURL } from 'utils';

export interface OnboardingList {
	_id: string;
	name: string;
	v: number;
	business: string;
	isDeleted: boolean;
	createdBy: string;
	versions: any[];
	status: string;
	createdAt: string;
	updatedAt: string;
	instances: any[];
	sessionCount: number;
	fullStatus: string[];
}
interface IViewPipeline {
	handleClose: () => void;
	editable: boolean;
}

export const ViewPipeline: FC<IViewPipeline> = ({ handleClose }) => {
	const [pipelines, setPipelines] = useRecoilState(AllPipelinesState);
	const selectedPipeline = useRecoilValue(SelectedPipelineState);
	const [conditionalPipelines, setConditionalPipelines] = useRecoilState(
		AllConditionalPipelinesState
	);

	const { successNotification, errorNotification } = useNotification();

	const {
		COPY_TOASTER_MESSAGE_onboardingFlow,
		COPY_TOASTER_MESSAGE_creditional_Id,
		INVALID_URL_ADDRESS,
		UPDATE_SUCCESS_MESSAGE,
		ONBOARDING_FLOW_NAME_REQUIRED,
	} = useMemo(() => message, []);

	const { patch: editOnboardingFlow, loading: editLoader } = useNetwork();

	const {
		_id = '62dfbbf94214f4247d59d1c3',
		name = 'Pipeline 2',
		redirectUrl = 'https://example.com',
		webhookUrl = 'https://example.com',
		// totalPrice = 2,
		credentialId = '62aece8d3c93fcf23ea9fd90',
		sessionCount = '0 Completed',
	}: any = useMemo(() => selectedPipeline, [selectedPipeline]);

	const [editValue, setEditValue] = useState<{
		name: string;
		redirectUrl: string;
		webhookUrl: string;
	}>({
		name: name,
		redirectUrl: redirectUrl,
		webhookUrl: webhookUrl,
	});

	const handleCopy = useCallback(
		(secretId: string, toasterMeassage: string) => {
			navigator.clipboard.writeText(secretId);
			successNotification(toasterMeassage);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const handleChangeEdit = useCallback(
		(name: string, e: ChangeEvent<HTMLInputElement>) => {
			const { value } = e.target;
			setEditValue((prev: any) => {
				const prevState = JSON.parse(JSON.stringify(prev));
				prevState[name] = value;
				return { ...prevState };
			});
		},
		[]
	);

	const pipelineIndex = useMemo(() => {
		return pipelines?.map(item => item._id)?.indexOf(selectedPipeline?._id);
	}, [pipelines, selectedPipeline?._id]);

	const handleSaveEdit = useCallback(() => {
		if (!validURL(editValue.redirectUrl) || !validURL(editValue.webhookUrl)) {
			errorNotification('Invalid Url');
			return;
		}

		const url =
			selectedPipeline.type === 'complex'
				? `${API_URL.COMPLEX_FLOW}/${_id}`
				: `${API_URL.PIPELINE_USERS}/${_id}`;
		editOnboardingFlow(url, {
			name: editValue.name.trim(),
			redirectUrl: editValue.redirectUrl,
			webhookUrl: editValue.webhookUrl,
		}).then(res => {
			if (res?._id) {
				if (selectedPipeline.type === 'complex') {
					setConditionalPipelines(prev => {
						const newObj = structuredClone(prev);
						const findIndex = newObj.findIndex(
							el => el._id === selectedPipeline._id
						);
						if (findIndex !== -1) {
							const actionIndexAdditionalSetting = newObj[
								findIndex
							].configurations.publish.actions.findIndex(
								(el: { actionId: string }) =>
									el.actionId === 'publishAdditionalSetting'
							);
							const actionIndexPublishOnboarding = newObj[
								findIndex
							].configurations.publish.actions.findIndex(
								(el: { actionId: string }) =>
									el.actionId === 'publishOnboarding'
							);
							// @shivangi change onboarding name//
							newObj[findIndex].name = editValue.name?.trim();
							if (actionIndexAdditionalSetting !== -1) {
								newObj[findIndex].configurations.publish.actions[
									actionIndexAdditionalSetting
								].metadata = {
									name: editValue.name.trim(),
									publishRedirect: editValue.redirectUrl,
									publishWebhook: editValue.webhookUrl,
								};
							}
							if (actionIndexPublishOnboarding !== -1) {
								newObj[findIndex].configurations.publish.actions[
									actionIndexPublishOnboarding
								].metadata = {
									...newObj[findIndex].configurations.publish.actions[
										actionIndexPublishOnboarding
									].metadata,
									publishName: editValue.name.trim(),
								};
							}
						}
						return newObj;
					});
				} else {
					setPipelines(pre => {
						const newArray = [...pre];
						newArray[pipelineIndex] = {
							...newArray[pipelineIndex],
							...{
								name: editValue.name.trim(),
								redirectUrl: editValue.redirectUrl,
								webhookUrl: editValue.webhookUrl,
							},
						};
						return newArray;
					});
				}

				handleClose();
				successNotification(UPDATE_SUCCESS_MESSAGE);
			}
			if (res?.message) {
				errorNotification(res.message);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		editValue.name,
		editValue.redirectUrl,
		editValue.webhookUrl,
		_id,
		pipelineIndex,
	]);

	const isDisabledSaveBtn = useMemo(() => {
		return Object.values(editValue ?? {}).every(el => el);
	}, [editValue]);

	useEffect(() => {
		if (selectedPipeline.type === 'complex') {
			const { publishRedirect, publishWebhook } =
				selectedPipeline.configurations.publish.actions.find(
					(el: any) => el.actionId === 'publishAdditionalSetting'
				)?.metadata ?? {};
			if (publishRedirect && publishWebhook) {
				setEditValue({
					name: selectedPipeline?.name ?? '',
					redirectUrl: publishRedirect,
					webhookUrl: publishWebhook,
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedPipeline, conditionalPipelines]);

	return (
		<div className="view-pipeline">
			<div className="view-pipeline__row">
				<div className="view-pipeline__label">Onboarding Flow Name</div>
				<div className="view-pipeline__value">
					<Input
						id="name"
						value={editValue.name}
						label=""
						inputType="text"
						placeholder=""
						isError={!editValue.name.trim()}
						errorMessage={ONBOARDING_FLOW_NAME_REQUIRED}
						handleChange={e => handleChangeEdit('name', e)}
					/>
				</div>
			</div>
			<div className="view-pipeline__row">
				<div className="view-pipeline__label">
					<span>Onboarding Flow Id</span>
					<Button
						handleClick={() =>
							handleCopy(_id ?? '', COPY_TOASTER_MESSAGE_onboardingFlow)
						}
						label={<i className="ri-file-copy-line copy icon__primary" />}
						type="button"
						minHeight="16px"
						height="20px"
					/>
				</div>
				<div className="view-pipeline__value">{_id}</div>
			</div>
			<div className="view-pipeline__row">
				<div className="view-pipeline__label">
					<span>Credential Id</span>
					<Button
						handleClick={() =>
							handleCopy(
								credentialId ?? '',
								COPY_TOASTER_MESSAGE_creditional_Id
							)
						}
						label={<i className="ri-file-copy-line copy icon__primary" />}
						type="button"
						minHeight="16px"
						height="20px"
					/>
				</div>
				<div className="view-pipeline__value">{credentialId}</div>
			</div>
			{/* Deepak : Price have some bugs need to fix from backend side then we will enable this code */}
			{/* <div className="view-pipeline__row">
				<div className="view-pipeline__label">Price Per Flow</div>
				<div className="view-pipeline__value">{`$${totalPrice}`}</div>
			</div> */}
			<div className="view-pipeline__row">
				<div className="view-pipeline__label">Redirect URL</div>
				<div className="view-pipeline__value">
					<Input
						id="redirectUrl"
						value={editValue.redirectUrl}
						label=""
						inputType="text"
						placeholder=""
						isError={!editValue.redirectUrl.trim()}
						errorMessage={INVALID_URL_ADDRESS}
						handleChange={e => handleChangeEdit('redirectUrl', e)}
					/>
				</div>
			</div>
			<div className="view-pipeline__row">
				<div className="view-pipeline__label">Webhook URL</div>
				<div className="view-pipeline__value">
					<Input
						id="webhookUrl"
						value={editValue.webhookUrl}
						label=""
						inputType="text"
						placeholder=""
						isError={!editValue.webhookUrl.trim()}
						errorMessage={INVALID_URL_ADDRESS}
						handleChange={e => handleChangeEdit('webhookUrl', e)}
					/>
				</div>
			</div>
			<div className="view-pipeline__row">
				<div className="view-pipeline__label">Sessions</div>
				<div className="view-pipeline__value">{sessionCount}</div>
			</div>

			<div className="view-pipeline__footer">
				<Button
					label="Close"
					type="button__filled--secondary"
					handleClick={handleClose}
				/>
				<Button
					label={editLoader ? <Loader type="loader" dimension={20} /> : 'Save'}
					type="button__filled--primary"
					disabled={!isDisabledSaveBtn}
					handleClick={handleSaveEdit}
				/>
			</div>
		</div>
	);
};
