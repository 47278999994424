import { Button } from '@storybook'; // Importing the Button component from Storybook
import Modal from '@storybook/new-modal/modal'; // Importing the Modal component from Storybook

import { useCancelSubscription } from './stores'; // Custom hook to handle subscription cancellation logic

import './cancel-subscription.scss'; // Importing styles for the CancelSubscription component

// Component to handle subscription cancellation with a modal confirmation
export const CancelSubscription = () => {
	// Destructure modal-related properties and the function to open the modal from the custom hook
	const { modalProps, handleOpenModal, showCancelSubscription } =
		useCancelSubscription();

	return (
		<div>
			{/* Button to trigger the subscription cancellation modal */}
			<Button
				label={'Cancel Subscription'} // Button label text
				type="subscriptions__btn__outline btn--danger" // CSS classes for button styling
				handleClick={handleOpenModal} // Function to open the modal on button click
				disabled={!showCancelSubscription}
			/>
			{/* Render the Modal component if `isOpen` is true */}
			{modalProps?.isOpen && <Modal {...modalProps} />}
		</div>
	);
};
