import classNames from 'classnames';
import { FC, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { Image } from '@storybook';
import { ActiveEmailClientState } from 'views/email-client/stores';
import './email-card.scss';

interface IEmailCard {
	image: string;
	title: string;
	value: string;
	active: boolean;
	onClick: () => void;
}

export const EmailCard: FC<IEmailCard> = ({
	title,
	image,
	onClick,
	value,
	active,
}) => {
	const activeEmailClient = useRecoilValue(ActiveEmailClientState);

	const activeEmail = useMemo(() => {
		return classNames('email-card', {
			'email-card-active': activeEmailClient?.key === value,
			'email-card-active-method': !active,
		});
	}, [active, activeEmailClient?.key, value]);

	return (
		<div className={activeEmail} onClick={onClick}>
			<div className="email-card_image">
				<Image fileName={image} />
			</div>
			<div className="email-card_name">{title}</div>
		</div>
	);
};
