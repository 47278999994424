import { Fragment, useCallback, useMemo } from 'react';
import { GestureSettings } from '../gesture-setttings';
import { Image, Input, ReactDropdown, ReactSwitch, TextArea } from '@storybook';
import { convertFileToBase64 } from 'utils';
import { UploadDocs } from 'components';
import { WaringMessage } from 'views/pipeline';

export const AuthConfigMetaSetting = ({
	el,
	parentIndex,
	fieldIndex,
	handleOnChange,
	formFields,
	handleOnChangeArray,
	handleRemoveArrayValue,
	getDropDownOptions,
	handleOnChangeGesture,
	newlyAddedClientId,
}: any) => {
	const rederInputWIthValueArray = useCallback(
		(data: any, parentIndex: number, fieldIndex: number) => {
			const {
				value = [],
				validErrorMessage,
				emptyErrorMessage,
				currentValue,
				isDuplicate,
				duplicateErrorMessage,
			} = data ?? {};

			const errorMessage = isDuplicate
				? duplicateErrorMessage
				: value.length || currentValue
				? validErrorMessage
				: emptyErrorMessage;
			return (
				<Fragment>
					<Input
						handleChange={event =>
							handleOnChange(
								event.target.value,
								parentIndex,
								fieldIndex,
								'',
								'currentValue'
							)
						}
						isError={!!data.isError}
						errorMessage={errorMessage}
						label={data.label}
						placeholder={data.placeholder}
						description={data.description}
						inputType={data.inputType}
						value={formFields[parentIndex].metaData[fieldIndex].currentValue}
						isShowButton={true}
						handleClickBtn={() =>
							handleOnChangeArray(parentIndex, fieldIndex, data.regex)
						}
						id={data.id}
					/>

					<div style={{ marginTop: '16px' }}>
						<div className="bf-wrapper__label">{data.footerLabel}</div>
						<div className="bf-wrapper__description">
							{data.footerDescription}
						</div>

						<div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
							{formFields[parentIndex].metaData[fieldIndex]?.value?.map(
								(el: string, index: number) => {
									return (
										<div
											key={(index, el)}
											className="auth-instance-space-between"
										>
											<div className="fs-16 auth-whitelisted-domain">{el}</div>
											<div
												onClick={() =>
													handleRemoveArrayValue(parentIndex, fieldIndex, index)
												}
												className="remove-btn-wrapper"
											>
												Remove
											</div>
										</div>
									);
								}
							)}
						</div>
					</div>
				</Fragment>
			);
		},
		[formFields, handleOnChange, handleOnChangeArray, handleRemoveArrayValue]
	);

	const components = useMemo(() => {
		if (!el) {
			return <></>;
		}
		const { type, className = '', options, ...rest } = el;

		switch (type) {
			case 'textArray':
				return rederInputWIthValueArray(rest, parentIndex, fieldIndex);
			case 'input':
				return (
					<Input
						{...rest}
						handleChange={event =>
							handleOnChange(
								event.target.value,
								parentIndex,
								fieldIndex,
								'',
								undefined,
								el.regex,
								rest.inputType
							)
						}
					/>
				);
			case 'radio':
				return (
					<div className={`auth-instance-radio-wrapper ${className}`}>
						<div style={{ width: 'calc(100% - 200' }}>
							<div className="auth-instance-toggle-wrapper__left">
								<div>
									<div className="label">{rest.label}</div>
									<div className="description"> {rest.description}</div>
								</div>
							</div>
						</div>

						<div className="auth-instance-radio-wrapper__inputs">
							<div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
								<input
									type="radio"
									onChange={() =>
										handleOnChange(
											true,
											parentIndex,
											fieldIndex,
											'',
											undefined,
											el.regex
										)
									}
									name={rest.name}
									checked={rest.value}
								/>
								<div className="auth-instance-radio-wrapper__inputs__label">
									Required
								</div>
							</div>
							<div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
								<input
									type="radio"
									onChange={() =>
										handleOnChange(
											false,
											parentIndex,
											fieldIndex,
											'',
											undefined,
											el.regex
										)
									}
									name={rest.name}
									checked={!rest.value}
								/>
								<div className="auth-instance-radio-wrapper__inputs__label">
									Optional
								</div>
							</div>
						</div>
					</div>
				);
			case 'fileUpload':
				return (
					<div>
						<UploadDocs
							handleChange={async event =>
								handleOnChange(
									await convertFileToBase64(event),
									parentIndex,
									fieldIndex,
									rest.name
								)
							}
							handleRemove={async () =>
								handleOnChange('', parentIndex, fieldIndex)
							}
							uplaodedFile={
								/http:|https:/.test(rest.default) ? rest.default : rest.value
							}
							isShowProgess
							{...rest}
						/>
						{rest.name === 'logo' && (
							<Fragment>
								<div className="separator">
									<div className="separator__line"></div>
									<span>OR</span>
								</div>

								<Input
									{...rest}
									inputType="text"
									label=""
									description=""
									value={
										/http:|https:/.test(rest.value) ? rest.value : rest.default
									}
									handleChange={async event =>
										handleOnChange(
											event.target.value,
											parentIndex,
											fieldIndex,
											'logo',
											'default'
										)
									}
								/>
							</Fragment>
						)}
					</div>
				);
			case 'textArea':
				return (
					<TextArea
						{...rest}
						handleChange={event =>
							handleOnChange(event.target.value, parentIndex, fieldIndex)
						}
					/>
				);
			case 'toggle':
				return (
					<div className={`auth-instance-toggle-wrapper ${className}`}>
						<div style={{ width: 'calc(100% - 200' }}>
							<div className="auth-instance-toggle-wrapper__left">
								{rest.imageUrl && (
									<div className="auth-instance-image-wrapper">
										<Image className="image-icon" url={rest.imageUrl} />
									</div>
								)}
								<div>
									<div className="label">{rest.label}</div>
									<div className="description"> {rest.description}</div>
								</div>
							</div>
						</div>

						<ReactSwitch
							id={''}
							checked={rest.value}
							handleChange={event =>
								handleOnChange(event, parentIndex, fieldIndex, rest.name)
							}
						/>
					</div>
				);

			case 'dropdown':
				return (
					<ReactDropdown
						options={getDropDownOptions(el.name).options} // options ? options : []
						label={el.label}
						description={rest.description}
						handleChangeSelect={(event: any) =>
							handleOnChange(event.value, parentIndex, fieldIndex, rest.name)
						}
						defaultValue={rest.default}
						footerLabel={getDropDownOptions(el.name).footer}
						optionsStyle={{
							'&:hover': {
								color: '#fff',
								backgroundColor: '#3c65d6',
								'.clientId-dropdown__label': {
									color: '#fff !important',
								},
								'.clientId-dropdown__sublabel': {
									color: '#fff !important',
								},
							},
						}}
						focusValue={newlyAddedClientId}
						isCustomComponent={rest.isCustomComponent}
						Isdisable={el.disabled}
						value={
							getDropDownOptions(el.name).options.find(
								(el: any) => el.value === rest.value
							) ?? null
						}
					/>
				);

			case 'gestureSetting':
				return (
					<GestureSettings
						handleChange={handleOnChangeGesture}
						parentIndex={parentIndex}
						fieldIndex={fieldIndex}
						options={options}
						label={rest.label}
						description={rest.description}
						className={className}
					/>
				);

			case 'border':
				return (
					<div
						style={{
							width: '100%',
							height: '1px',
							border: '0.25px solid #D9D9D9',
						}}
					></div>
				);
			case 'warning':
				return (
					<WaringMessage
						text={<div dangerouslySetInnerHTML={{ __html: rest.note }} />}
					/>
				);

			default:
				return <></>;
		}
	}, [
		el,
		fieldIndex,
		getDropDownOptions,
		handleOnChange,
		handleOnChangeGesture,
		newlyAddedClientId,
		parentIndex,
		rederInputWIthValueArray,
	]);

	return components;
};
