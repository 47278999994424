import { atom } from 'recoil';
import { IAddNewRoleForm } from './types';
import {
	InviteNavigation,
	RoleAndPermissionNavigation,
	UserRoleNavigation,
} from '../constant';
import { UserRoles } from 'global-stores';

export const OpenDeleteModal = atom<boolean>({
	key: 'open-delete-modal-key',
	default: false,
});

export const PermissionNavigation = atom({
	key: 'permission-navigation-key',
	default: RoleAndPermissionNavigation.CreateNewRole,
});

export const AddNewRoleFormState = atom<IAddNewRoleForm>({
	key: 'add-new-role-form-key',
	default: {
		name: '',
		description: '',
		services: ['esign', 'onboarding'],
		fullAccess: false,
		permissions: {
			dashboard: {
				read: false,
				write: false,
			},
			onboarding: {
				read: false,
				write: false,
			},
			session: {
				read: false,
				write: false,
			},
			funds: {
				read: false,
				write: false,
			},
			signDashboard: {
				read: false,
				write: false,
			},
			template: {
				read: false,
				write: false,
			},
			inbox: {
				read: false,
				write: false,
			},
			sent: {
				read: false,
				write: false,
			},
			transaction: {
				read: false,
				write: false,
			},
			checkDefense: {
				read: false,
				write: false,
			},
			generalSettings: {
				read: false,
				write: false,
			},
			developersKeys: {
				read: false,
				write: false,
			},
			userRoles: {
				read: false,
				write: false,
			},
			embedWeb: {
				read: false,
				write: false,
			},
			apiDocs: {
				read: false,
				write: false,
			},
			userAnalytics: {
				read: false,
				write: false,
			},
			supportAgent: {
				read: false,
				write: false,
			},
			billing: {
				read: false,
				write: false,
			},
			liveMode: {
				read: false,
				write: false,
			},
			demo: {
				read: false,
				write: false,
			},
			userProfile: {
				read: false,
				write: false,
			},
		},
	},
});

export const UserRolePermissionNavigation = atom({
	key: 'user-role-permission-navigation-key',
	default: UserRoleNavigation.AddedRoleCard,
});

export const InviteNavigationState = atom({
	key: 'invite-navigation-key',
	default: InviteNavigation.InviteForm,
});

export const SelectedUserRoleState = atom<UserRoles | null>({
	key: 'selected-user-role-key',
	default: null,
});
