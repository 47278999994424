import { FC } from "react";

import { getDate, getTime } from "utils";
import { ISignedDocInbox } from "../store";

interface IProps {
  inbox: ISignedDocInbox;
}

export const InboxLastChangeDate: FC<IProps> = ({ inbox }) => {
  return (
    <div className="InboxLastChangeDate__last-changes">
      <div className="InboxLastChangeDate__last-changes-date">
        {getDate(inbox.createdAt)}
      </div>
      <div className="InboxLastChangeDate__last-changes-time">
        {getTime(inbox.createdAt, "hh:mm a")}
      </div>
    </div>
  );
};
