import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';

import { envHost } from 'helpers';

export const axiosMockInstance = new MockAdapter(axios, {
	delayResponse: 2000,
	onNoMatch: 'passthrough',
});

// @AWADHESH: DO NOT CHANGE THIS CODE -
// this will restrict mock API to be call on local and stage
if (envHost !== 'stage') {
	axiosMockInstance.restore();
}
