import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import {
	IframePropsState,
	IsBannerStatusState,
	IsOpenInIframeState,
} from 'global-stores';
import { useGeneralSettings } from 'views/settings';
import { OnboardingFooterNavigateState } from 'views/onboarding-flow';

export const useComplexOnboarding = () => {
	const { background } = useRecoilValue(IframePropsState);
	const isIframeOpen = useRecoilValue(IsOpenInIframeState);
	const bannerStatus = useRecoilValue(IsBannerStatusState);
	const navigate = useRecoilValue(OnboardingFooterNavigateState);

	const { getClientDetails } = useGeneralSettings();

	const onDragStart = (event: any, nodeType: any) => {
		event.dataTransfer.setData('application/complex-flow', nodeType);
		event.dataTransfer.effectAllowed = 'move';
	};

	//use effect
	useEffect(() => {
		getClientDetails();
		// eslint-disable-next-line
	}, []);

	return {
		bannerStatus,
		isIframeOpen,
		background,
		navigate,
		onDragStart,
	};
};
