import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import classNames from 'classnames';

import { useOutsideClick } from 'hooks';
import { DealFilterIndicatorState, DealFilterTagsState } from '../states';
import { initialFilterOpenValue } from '../../constants';

export const useDealFilters = () => {
	// global states
	const [filtersIndicator, setFiltersIndicator] = useRecoilState(
		DealFilterIndicatorState
	);
	const [filteringTags, setFilteringTags] = useRecoilState(DealFilterTagsState);

	// local states
	const [isOpen, setIsOpen] = useState(false);
	const [isFilerListOpen, setIsFilterListOpen] = useState(
		initialFilterOpenValue
	);

	// refs
	const filterRef = useRef(null);

	useOutsideClick(filterRef, () => {
		setIsOpen(false);
	});

	useEffect(() => {
		let count = 0;
		Object.values(filteringTags).forEach((tag: any) => {
			return (count += tag.length);
		});
		setFiltersIndicator(count);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filteringTags]);

	const handleClickOnFilter = useCallback(() => setIsOpen(prev => !prev), []);

	const handleClickToOpenFilterList = useCallback(
		(key: string) =>
			setIsFilterListOpen(prev => ({
				[key]: !prev[key],
			})),
		[]
	);

	const filterIconClass = classNames('ri-filter-3-line', {
		'Table-header--filter-icon-active': filtersIndicator > 0,
	});

	const selectFilterCss = classNames('Table-header--container', {
		'Table-header--filter-active': isOpen,
		'Table-header--filter-icon-active Table-header--filter-bg-active':
			filtersIndicator > 0,
	});

	const classes = useCallback(
		(check: boolean) =>
			classNames('ri-arrow-down-s-line', {
				'filter-popup__opened': check,
				'filter-popup__closed': !check,
			}),
		[]
	);

	const handleChangeFilter = useCallback(
		(e: ChangeEvent<HTMLInputElement>, key: string, status: string) => {
			const { checked } = e.target;

			setFilteringTags(prev => {
				// Clone the previous state
				const newState = { ...prev };
				const existingTags = newState[key] || [];

				if (checked) {
					// Add status to the filter if checked
					newState[key] = [...existingTags, status];
				} else {
					// Remove status from the filter if unchecked
					const updatedTags = existingTags.filter(
						(item: string) => item !== status
					);
					if (updatedTags.length > 0) {
						newState[key] = updatedTags;
					} else {
						delete newState[key];
					}
				}

				return newState;
			});
		},
		[setFilteringTags]
	);

	const handleResetAll = useCallback(() => {
		setFiltersIndicator(0);
		setIsFilterListOpen(initialFilterOpenValue);
		setFilteringTags({});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleValidateChecked = useCallback(
		(key: string, value: string) => {
			if (key === 'status') {
				return filteringTags[key]?.includes(value);
			}
			return false;
		},
		[filteringTags]
	);

	return {
		classes,
		isOpen,
		isFilerListOpen,
		filterIconClass,
		selectFilterCss,
		handleClickOnFilter,
		handleClickToOpenFilterList,
		filterRef,
		filtersIndicator,
		handleResetAll,
		handleChangeFilter,
		handleValidateChecked,
	};
};
