import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { SubscriptionPriceState } from 'global-stores';
import { REMAINING_SERVICES } from 'views/new-user-popup/constants';
import { ChooseIdentityData } from 'views/user-identity-flow';
import { Image } from '@storybook';

export const IndividualAcountType = () => {
	const subscriptionPrice = useRecoilValue(SubscriptionPriceState);

	const individualPrice = useMemo(() => {
		if (subscriptionPrice) {
			return (subscriptionPrice as any)[0]?.['individual'];
		} else return 0;
	}, [subscriptionPrice]);

	const BusinessPrice = useMemo(() => {
		if (subscriptionPrice) {
			return subscriptionPrice[0]?.['entity'];
		} else return 0;
	}, [subscriptionPrice]);

	return (
		<div>
			<div className="subscriptions__acount-type-box upgradeAccountIndividual">
				<div className="subscriptions__upgradeAccountType">
					<div className="subscriptions__acount-type subscriptions__individaual-acount subscriptions__individaual">
						<i className="ri-user-line"></i>
					</div>
					<div>
						<div className="subscriptions__acount-type-label">
							{ChooseIdentityData[0]?.label}
						</div>
						<div className="subscriptions__acount-type-amount">
							{`$${individualPrice ?? 0}`}{' '}
							<span className="subscriptions__perUserPrice">
								per user/month
							</span>
						</div>
					</div>
				</div>
				<div className="subscriptions__arrowIcon">
					<Image fileName="ArrowIcon.svg" />
				</div>
				<div className="subscriptions__upgradeAccountType">
					<div className="subscriptions__acount-type subscriptions__buisness  subscriptions__buisness-acount">
						<i className="ri-briefcase-fill"></i>
					</div>
					<div>
						<div className="subscriptions__acount-type-label">
							{ChooseIdentityData[1]?.label}
						</div>
						<div className="subscriptions__acount-type-amount">
							{`$${BusinessPrice ?? 0}`}{' '}
							<span className="subscriptions__perUserPrice">
								per user/month
							</span>
						</div>
					</div>
				</div>
			</div>
			<div className="subscriptions__upgrateText">
				Your account upgrade from Individual to Entity is pending. Once the
				physical verification and KYB process are completed, your account will
				be upgraded.
			</div>
			<div>
				<div className="subscriptions__sevice-include">Services included:</div>
				{ChooseIdentityData[0]?.services.map(ser => (
					<>
						<div className="subscriptions__sevice-include-type">
							<i className="ri-checkbox-circle-fill subscriptions__sevice-include-type-icon "></i>
							{ser.name}
						</div>
						{(REMAINING_SERVICES ?? []).map(() => null)}
					</>
				))}
			</div>
		</div>
	);
};
