import { FC, useCallback } from 'react';

import Modal from '@storybook/new-modal/modal';
import { EditModalBody } from './edit-modal-body';

import './company-details-edit.scss';

type ICompanyDetailsEdit = {
	setIsOpenModal: (value: boolean) => void;
	isOpenModal: boolean;
};

export const CompanyDetailsEdit: FC<ICompanyDetailsEdit> = ({
	setIsOpenModal,
	isOpenModal,
}) => {
	const handleCloseModal = useCallback(() => {
		setIsOpenModal(false);
	}, [setIsOpenModal]);

	return (
		<Modal
			isOpen={isOpenModal}
			modalName="Company Details Edit"
			closeModal={handleCloseModal}
			className="company-details-edit"
			showCloseBtn={true}
			isStopOutsideClick={false}
			title={
				<div className="company-details-edit__header">
					<div className="company-details-edit__title">Whitelabel Details</div>
				</div>
			}
		>
			<EditModalBody setIsOpenModal={setIsOpenModal} />
		</Modal>
	);
};
