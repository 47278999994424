import { atom } from "recoil";

import { IStatusUploadedDocs } from "./types";

export const StatusUploadedDocsState = atom<IStatusUploadedDocs>({
	key: 'status-uploaded-docs-state-key',
	default: {
		isLoaded: true,
		error: false,
		data: [],
	},
});