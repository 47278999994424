import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import {
	ActiveActionKeyNameState,
	ActiveStepState,
	AllOpenedStepsNameState,
	isChecqueFraudSelected,
	PipelineStepsState,
	useSignedDocs,
} from 'global-stores';
import { useTrackEvents } from 'helpers';
import { ROUTES } from 'routes';
import {
	ComplexFlowUnconfigedState,
	EdgesState,
	NodesState,
} from 'views/complex-onboarding-flow/stores/states';
import { Header } from 'views/header';
import { ConfigureSurvayFormState } from 'views/onboarding-flow';
import { Pipelines } from 'views/pipelines';
import './pipeline.scss';
import { ComplexSettingFormState } from './store';

const Pipeline = () => {
	const activeStepKeyName = useRecoilValue(ActiveStepState);
	const steps = useRecoilValue(PipelineStepsState);
	const setOpenedStepNames = useSetRecoilState(AllOpenedStepsNameState);
	const activeAction = useRecoilValue(ActiveActionKeyNameState);
	const resetComplexSettingForm = useResetRecoilState(ComplexSettingFormState);
	const resetComplexFlowConfiged = useResetRecoilState(ComplexFlowUnconfigedState);
	const resetConfigureSurvayFormState = useResetRecoilState(
		ConfigureSurvayFormState
	);

	const { trackEvents } = useTrackEvents();
	const { getDocuments } = useSignedDocs();
	const setNodes = useSetRecoilState(NodesState);
	const setEdges = useSetRecoilState(EdgesState);
	const resetChequeFraudSelected = useResetRecoilState(isChecqueFraudSelected);

	const navigate = useNavigate();
	const { PIPELINE, FLOW } = ROUTES;

	const handleOpen = useCallback(
		(type: string) => {
			resetComplexSettingForm();
			resetComplexFlowConfiged();
			trackEvents('create-new-onboarding', {});
			navigate(type);
			setEdges([]);
			setNodes([]);
			resetConfigureSurvayFormState();
			resetChequeFraudSelected();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[FLOW, PIPELINE, navigate, trackEvents]
	);

	useEffect(() => {
		getDocuments();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		setOpenedStepNames(prev =>
			prev.includes(activeStepKeyName.key)
				? [...prev]
				: [...prev, activeStepKeyName.key]
		);
		// eslint-disable-next-line
	}, [activeStepKeyName, setOpenedStepNames, steps, activeAction]);

	const headerButtonOptions = [
		{
			label: 'Create New',
			onClick: () => handleOpen(`${PIPELINE}${FLOW}`),
		},
	];

	const props = {
		title: 'Onboarding',
		showBtn: true,
		handleClickBtn: () => handleOpen(`${PIPELINE}${FLOW}`),
		btnLabel: 'Create New',
		elements: headerButtonOptions,
		btnClass: 'Onboarding--header__button'
	};

	return (
		<div className="dashboard-main-body">
			<Header {...props} />
			<div className="pipeline-body Instance--table__container">
				<Pipelines handleOpen={() => handleOpen(`${PIPELINE}${FLOW}`)} />
			</div>
		</div>
	);
};

export default Pipeline;
