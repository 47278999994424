import { ILinkRepresentative } from 'global-stores/sessions/type';
import { FC, useCallback, useEffect, useState } from 'react';

import './kyc-aml-members-verification.scss';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
	ComplexSessionInfoState,
	ComplexSessionNodesState,
	ConditionalSessionFlowState,
	PreviousSessionDataState,
	SelectedSessionsIdState,
	SessionItemSelected,
} from 'global-stores';
import { useGetComplexNodeInfo } from 'views/sessions/hooks/use-complex-session-details';
import { useNetwork } from 'hooks';
import { API_URL } from 'constant';
import { Loader } from '@storybook';

interface IKycAmlMembersVerifications {
	item?: ILinkRepresentative[];
}

export const KycAmlMembersVerification: FC<IKycAmlMembersVerifications> = ({
	item: linkRepresentatives,
}) => {
	const setSessionItemSelected = useSetRecoilState(SessionItemSelected);
	const setComplexSessionNodes = useSetRecoilState(ComplexSessionNodesState);
	const setSelectedIdState = useSetRecoilState(SelectedSessionsIdState);
	const setIsComplexFlow = useSetRecoilState(ConditionalSessionFlowState);
	const [isLoadingScreen, setIsLoadingScreen] = useState(false);
	const sessionItemSelected = useRecoilValue(SessionItemSelected);
	const setPreviousSessionData = useSetRecoilState(PreviousSessionDataState);
	const setComplexSessionInfo = useSetRecoilState(ComplexSessionInfoState);
	const { getComplexNodeInfo } = useGetComplexNodeInfo();
	const { get } = useNetwork();

	useEffect(() => {
		if (sessionItemSelected) {
			setPreviousSessionData(sessionItemSelected);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleView = useCallback(
		async (data: ILinkRepresentative) => {
			setIsLoadingScreen(true);
			const sessionId = data?.sessionId;
			const resp = await get(`${API_URL.COMPLEX_SESSION_DETAILS}/${sessionId}`);
			const { data: getdata } = resp ?? {};
			if (getdata) {
				setComplexSessionInfo(getdata);
				const allNodes = getdata?.nodes?.map((el: any) => {
					return {
						...el,
						session: sessionId,
					};
				});
				setComplexSessionNodes(allNodes || []);
				if (allNodes) {
					setTimeout(() => {
						getComplexNodeInfo(allNodes);
					}, 100);
				}
			}
			setIsComplexFlow(true);
			setSessionItemSelected({
				...data,
				type: 'complex',
				name: data?.fullName ?? 'NaN',
				_id: sessionId || '',
			});
			setSelectedIdState([sessionId]);
		},
		[
			get,
			setIsComplexFlow,
			setSessionItemSelected,
			setSelectedIdState,
			setComplexSessionInfo,
			setComplexSessionNodes,
			getComplexNodeInfo,
		]
	);

	const getVerificationProcessStatusContent = useCallback(
		(member: ILinkRepresentative) => {
			const renderBadge = (status: string) => {
				const iconClass: Record<string, string> = {
					completed: 'ri-checkbox-circle-fill',
					rejected: 'ri-close-circle-fill',
					pending: 'ri-time-fill',
					processing: 'ri-error-warning-fill',
				};

				const getIconClass = iconClass[status];
				return (
					<div className={`kyc-aml-members__verification__${status}-badge`}>
						<i
							className={`kyc-aml-members__${status}-status ${getIconClass}`}
						/>
						<span className="kyc-aml-members__verification__status">
							{status}
						</span>
					</div>
				);
			};

			return (
				<div
					className="kyc-aml-members__verification__container"
					key={member?.representativeId}
				>
					<div className="kyc-aml-members__verification__wrapper">
						<div className="kyc-aml-members__verification__process-status">
							<span className="kyc-aml-members__verification__process-name">
								KYC
							</span>
							<span>:</span>
							{renderBadge(member?.kycStatus)}
						</div>
						<div className="kyc-aml-members__verification__process-status">
							<span className="kyc-aml-members__verification__process-name">
								AML
							</span>
							<span>:</span>
							{renderBadge(member?.amlStatus)}
						</div>
						<div className="kyc-aml-members__verification__process-status">
							<span className="kyc-aml-members__verification__process-name">
								Session
							</span>
							<span>:</span>
							<div style={{ width: '96px' }}>
								{isLoadingScreen? <Loader type='loader' dimension={20}/> : <button
									className="kyc-aml-members__verification__view-button"
									onClick={() => handleView(member)}
								>
									<i className="ri-eye-line" />
									<span className="kyc-aml-members__verification__view-button__text">
										View
									</span>
								</button>}
							</div>
						</div>
					</div>
				</div>
			);
		},
		[handleView, isLoadingScreen]
	);

	if (!linkRepresentatives?.length) {
		return <></>;
	}

	return (
		<>
			{linkRepresentatives?.map((member: ILinkRepresentative) =>
				getVerificationProcessStatusContent(member)
			)}
		</>
	);
};
