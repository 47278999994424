import { FC, useMemo } from 'react';
import classNames from 'classnames';
import { Image } from '@storybook';

import './upload-status.scss';

interface IUploadStatus {
	uploaded: boolean;
	fileName: string;
	handleClose: (isCancelFileUploading:boolean) => void;
	uploadingProgress: number;
}

export const UploadStatus: FC<IUploadStatus> = ({
	uploaded,
	fileName,
	handleClose,
	uploadingProgress,
}) => {
	const showImageType = useMemo(() => {
		const image = fileName.slice(fileName.indexOf('.') + 1).toUpperCase();
		return `${image ?? 'PDF'}.svg`;
	}, [fileName]);

	const fileUploadClass = useMemo(() => {
		return classNames('UploadStatus_file_completed ', {
			UploadStatus_file_start: !uploadingProgress,
		});
	}, [uploadingProgress]);

	if (uploadingProgress === 100) {
		setTimeout(() => {
			handleClose(false);
		}, 800);
	}

	return (
		<div className="UploadStatus">
			<div className="UploadStatus_image">
				<Image fileName={showImageType} className="UploadStatus_image_pdf" />
			</div>
			<div className="UploadStatus_file">
				<div className="UploadStatus_file_name">
					<div className="UploadStatus_file_name-value">{fileName}</div>
					<div className="UploadStatus_file_upload">
						{uploaded ? 'Uploaded' : 'Uploading'}
					</div>
				</div>
				<div className="UploadStatus_file_progress">
					<div
						className={fileUploadClass}
						style={{ width: `${uploadingProgress}%` }}
					/>
				</div>
			</div>
			<i
				className="ri-close-circle-line UploadStatus_icon"
				onClick={()=>handleClose(true)}
			/>
		</div>
	);
};
