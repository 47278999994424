import classNames from 'classnames';
import { FC, MouseEvent, useCallback, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
	InviteDetailsState,
	InviteModal,
	IsBannerStatusState,
	UserRoles,
	UserRolesPermissions,
	UserRolesState,
	loginState,
} from 'global-stores';
import { useUserRoles } from 'views/routes-children';
import { ArchiveConfirmationModal } from 'views/sessions';
import {
	RolesAndPermissionCardData,
	UserRoleNavigation,
} from 'views/user-roles/constant';
import { useNewRoles } from 'views/user-roles/hooks';
import {
	SelectedUserRoleState,
	UserRolePermissionNavigation,
} from 'views/user-roles/store';
import { DropInfoOptions } from '../info-drop-options';
import { PermissionTag } from '../permission-tag';
import { UserAvatar } from './user-avatar';
import { Image } from '@storybook';

interface IRolesAndPermission {
	isUserPermissionWrite?: boolean;
}

export const RolesAndPermission: FC<IRolesAndPermission> = ({
	isUserPermissionWrite = true,
}) => {
	// global state
	const setUserRoleNavigate = useSetRecoilState(UserRolePermissionNavigation);
	const setIsOpenInviteUserModel = useSetRecoilState(InviteModal);
	const setSelectedUserRole = useSetRecoilState(SelectedUserRoleState);
	const userRoles = useRecoilValue(UserRolesState);
	const bannerStatus = useRecoilValue(IsBannerStatusState);
	const setInvites = useSetRecoilState(InviteDetailsState);
	const userPermissions = useRecoilValue(UserRolesPermissions);
	const userDetail = useRecoilValue(loginState);

	const { image, name: userName = '' } = useMemo(() => {
		return userDetail;
	}, [userDetail]);

	const { name: currentRole = '' } = useMemo(() => {
		return userPermissions as UserRoles;
	}, [userPermissions]);

	const { fetchAssinedUser } = useUserRoles();

	const handleCloseModel = () => null;
	const {
		handleDeleteUserRole,
		isOpenDeleteModal,
		handleOpenDelete,
		addNewRoleLoader,
		selectedUserRole,
	} = useNewRoles({ handleCloseModel });

	const handleEditRole = (item: any) => {
		fetchAssinedUser(item?._id);
		setUserRoleNavigate(UserRoleNavigation.ComplainceAgent);
		setSelectedUserRole(item);
	};

	const handleInviteRole = (
		e: React.MouseEvent<HTMLInputElement>,
		role: UserRoles
	) => {
		e.stopPropagation();
		setIsOpenInviteUserModel(true);
		setInvites(prev => {
			const prevState = JSON.parse(JSON.stringify(prev));
			prevState['role'] = role?._id ?? '';
			return { ...prevState };
		});
	};

	const RolePermissionClass = useCallback((bannerStatus: boolean) => {
		return classNames('RolesAndPermission', {
			'bannerStatus-height': bannerStatus,
		});
	}, []);

	const showUserProfileImage = useMemo(() => {
		return {
			...(image && { url: image }),
			...(!image && {
				fileName: 'AvatarDefault-Light.svg',
			}),
			className: 'user_avatar',
		};
	}, [image]);

	return (
		<div className={RolePermissionClass(!bannerStatus)}>
			<div className="RolesAndPermission--container owner-permission">
				<div className="RolesAndPermission--container__header hover">
					<div className="RolesAndPermission--container__header--label">
						<div className="RolesAndPermission--container__text">
							<div className="RolesAndPermission--container__labelContainer">
								<div className="RolesAndPermission--container__header--label__title">
									{`${currentRole} (You)` ?? 'NA'}
								</div>
								<div className="RolesAndPermission--container__header--label__subTitle">
									{userName ?? 'NA'}
								</div>
							</div>
							<PermissionTag role={userPermissions as UserRoles} />
						</div>
					</div>
					<div className="RolesAndPermission--container__userProfile">
						{image ? (
							<Image {...showUserProfileImage} className="user-profile-image" />
						) : (
							<Image
								fileName="AvatarDefault-Light.svg"
								className="overlap-image"
							/>
						)}
					</div>
				</div>
			</div>
			{(userRoles ?? [])?.map(role => (
				<div
					className="RolesAndPermission--container"
					key={role._id}
					onClick={() => handleEditRole(role)}
				>
					<div className="RolesAndPermission--container__header hover">
						<div className="RolesAndPermission--container__header--label">
							<div className="RolesAndPermission--container__text">
								<div className="RolesAndPermission--container__labelContainer">
									<div className="RolesAndPermission--container__header--label__title">
										{role?.name ?? 'NA'}
									</div>
									<div className="RolesAndPermission--container__header--label__subTitle">
										{role?.description ?? 'NA'}
									</div>
								</div>
								<PermissionTag role={role} />
							</div>
							{isUserPermissionWrite && (
								<div className="RolesAndPermission--container__dropOptions">
									<DropInfoOptions
										handleDelete={(e: MouseEvent<HTMLDivElement>) =>
											handleOpenDelete(e, role)
										}
										showEdit={false}
										isDisabled={true}
										isDeleteDisabled={true}
									/>
								</div>
							)}
						</div>
						<div className="RolesAndPermission--container__inviteUser">
							<div className="RolesAndPermission--container__inviteUser--icon">
								{role?.userList?.slice(0, 3).map(user => (
									<UserAvatar id={user} key={user} />
								))}

								{role?.userList?.length > 3 ? (
									<div className="overlap-count">
										+{role?.userList?.length - 3}
									</div>
								) : (
									<div className="RolesAndPermission--overlap-opacity"></div>
								)}
							</div>
							{isUserPermissionWrite && (
								<div
									className="RolesAndPermission--container__inviteUser--label"
									onClick={(e: React.MouseEvent<HTMLInputElement>) =>
										handleInviteRole(e, role)
									}
								>
									{RolesAndPermissionCardData.Invite_User}
								</div>
							)}
						</div>
					</div>
				</div>
			))}

			{isOpenDeleteModal && (
				<ArchiveConfirmationModal
					description={`Deleting ${selectedUserRole?.name} role will remove user’s permissions from the
					system.`}
					boldDescription={` Do you still want to delete ${selectedUserRole?.name} role?`}
					label="Delete"
					title="Are you sure?"
					visible={isOpenDeleteModal}
					showLoader={addNewRoleLoader}
					handleModal={handleDeleteUserRole}
				/>
			)}
		</div>
	);
};
