import { atom } from 'recoil';
export const SidebarToogelState = atom<boolean>({
	key: 'SidebarToogelState',
	default: true,
});

export const SelectedRouteState = atom<string>({
	key: 'seleted-route-state',
	default: '',
});
