export const SUBACCOUNT_DEFINATION = ` A sub-account is a
								supplementaryaccount under the umbrella of a primary account,
								designed to manage and oversee specific operations,
								transactions, and financial activities related to a subsidiary,
								branch, or collaborative partnership. It facilitates detailed
								tracking and management, providing transparency and control over
								various financial segments of an organization. The financial
								responsibility of a sub-account can be borne either by the
								parent company or by the entity to which the sub-account is
								assigned.`;

export const SUBACCOUNT_QUESTION = 'What is a sub-account?';

export const SubAccountNameIds: any = {
	firstName: 'sub-account-firstName',
	lastName: 'sub-account-lastName',
	phone: 'sub-account-phone',
	name: 'sub-account-name',
	email: 'sub-account-email',
};
