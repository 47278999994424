import { API_URL } from 'constant';
import { useNetwork } from 'hooks';
import { useSetRecoilState } from 'recoil';
import { UserSubscriptionsState } from 'global-stores';

export const useSubscriptions = () => {
	const setUserSubscriptions = useSetRecoilState(UserSubscriptionsState);
	const { get } = useNetwork();

	const initSubscriptions = async () => {
		const subResp = await get(API_URL.SUBSCRIPTIONS);
		if (subResp?.message === 'ok' && subResp?.data) {
			setUserSubscriptions(subResp.data);
		}
	};

	return {
		initSubscriptions,
	};
};
