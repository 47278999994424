import type { ISubAccountListState, ISubAccountKey } from 'views/sub-account';

import { Button, Loader, ReactResponsiveTable } from '@storybook';
import { useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { API_URL } from 'constant';
import { useNetwork } from 'hooks';
import {
	SubAccountListState,
	SubUserInviteModal,
	useSubAccounts,
} from 'views/sub-account';
import { BILLING_SUB_ACCOUNT_HEADER } from '../constant';
import './billing-sub-account.scss';
import { billingDetailState } from '../../stores';
import { InviteModalPage, InviteNavigation, InviteNavigationState } from 'views/user-roles';
import { EditModal } from 'global-stores';

export const BillingSubAccount = () => {
	const [subAccountRows, setSubAccountRows] =
		useRecoilState(SubAccountListState);
	const billingDetailData =
		useRecoilValue(billingDetailState);
		const editModal = useRecoilValue(EditModal);
		const inviteNavigation = useRecoilValue(InviteNavigationState);

	const { get: getSubAccount, data: subAccountList, loading } = useNetwork();
	const {
		handleUser,
		isOpenUserModal,
		handleCloseViewModal,
		isSubUserLoading,
		subUsersList,
		onInviteSubAccountUser,
		onRemoveInvitedUser,
		handleEditSubAccountUser,
		openDeleteInvitedUser,
		invitedUserToDelete,
		handleOpenDeleteInvitedUser,
		subAccountLoading,
		inviteModalOpen,
		isApiLoading,
		isDisabled,
		handleClose,
		onSaveRole
	} = useSubAccounts();
	useEffect(() => {
		if (!subAccountRows.length) {
			getSubAccount(API_URL.SUB_ACCOUNT);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (subAccountList?.data) {
			setSubAccountRows(subAccountList.data);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subAccountList?.data]);

	const inviteBackLabel = useMemo(() => {
		switch (inviteNavigation) {
			case InviteNavigation.InviteForm:
				return 'Cancel';
			case InviteNavigation.InvitePreview:
				return 'Back';
			default:
				return '';
		}
	}, [inviteNavigation]);

	const inviteButtonLabel = useMemo(() => {
		switch (inviteNavigation) {
			case InviteNavigation.InviteForm:
				return 'Review';
			case InviteNavigation.InvitePreview:
				return editModal ? 'Update Invite' : 'Send Invite';
			default:
				return '';
		}
	}, [editModal, inviteNavigation]);

	const usersRows = useMemo((): ISubAccountListState[] => {		
		const rows: ISubAccountListState[] = [];
		if (BILLING_SUB_ACCOUNT_HEADER.length) {
			subAccountRows.forEach((item: ISubAccountListState) => {
				if (item) {
					let row: any = {};
					BILLING_SUB_ACCOUNT_HEADER.forEach(({ format, key }) => {
						const subAccount = billingDetailData.find(it => it.businessId === item._id) || {totalAmount: 0, totalOnboardingServiceAmount: 0, totalSimpliciSignServiceAmount: 0, lastCycleBilling: {totalAmount: 0}, lifeTimeBilling: {totalAmount: 0}}
						if (format === 'jsx' && key === 'totalUsers') {
							const value = () => (
								<div>
									{item[key as ISubAccountKey] ?? 0}{' '}
									<span className="Sub-Account_view">View</span>
								</div>
							);
							return (row[key] = value);
						}
						if (format === 'jsx' && key === 'billingType') {
							const value = () => (
								<div>
									{item?.ownBilling && 'Own billing'}{' '}
									{item?.ownBilling && !item?.differentCompany && '&'}{' '}
									{!item?.differentCompany && 'Under company'}
								</div>
							);
							return (row[key] = value);
						}

						if(format === 'jsx' && key === 'monthlySubsCost'){
                            const value = () => {
                                const amount = (subAccount.totalOnboardingServiceAmount + subAccount.totalSimpliciSignServiceAmount)                            
                                return (
                                <div>
                                    ${amount}
                                </div>
                            )};
                            return (row[key] = value);
                        }
                        if(format === 'jsx' && key === 'totalLastBill'){
                            const value = () => {
                                return (
                                <div>
                                    ${subAccount.lastCycleBilling?.totalAmount || 0}
								</div>
                            )};
                            return (row[key] = value);
                        }
                        if(format === 'jsx' && key === 'totalToDate'){
                            const value = () => {
                                return (
                                <div>
                                    ${subAccount.lifeTimeBilling?.totalAmount || 0}
								</div>
                            )};
                            return (row[key] = value);
                        }

						row = {
							...row,
							_id: item?._id,
							[key]: item[key as ISubAccountKey],
						};
						return null;
					});
					rows.push(row);
				}
			});
		}
		return rows;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subAccountRows, billingDetailData]);
	
	return (
		<div className="Billing-SubAccount hover">
			<div className="Billing-SubAccount_text">Sub accounts</div>
			<div className="Billing-SubAccount_description">
				{subAccountRows?.length ?? 0} sub accounts
			</div>
			<div className="Billing-SubAccount_SearchContainer">
				<ReactResponsiveTable
					column={BILLING_SUB_ACCOUNT_HEADER}
					rows={usersRows}
					handelRowClick={user => handleUser(user)}
					isLoading={loading}
					hideSortKey={[
						'totalUsers',
						'billingType',
						'monthlySubsCost',
						'totalLastBill',
						'totalToDate',
					]}
					EmptyIllustration="add-group.svg"
					EmptyMessageHeading="No sub-account added"
					EmptyMessageDescription="You have not created any sub-account yet."
					showSearch
					className="Billing-SubAccount_table"
					columnHandle
				/>

				{isOpenUserModal && (
					<SubUserInviteModal
						isOpen={isOpenUserModal}
						handleCloseModal={() => handleCloseViewModal()}
						isSubUserLoading={isSubUserLoading}
						subUsersList={subUsersList}
						onInviteSubAccountUser={onInviteSubAccountUser}
						onRemoveInvitedUser={onRemoveInvitedUser}
						handleEditSubAccountUser={handleEditSubAccountUser}
						hideBtn
						openDeleteInvitedUser={openDeleteInvitedUser}
						invitedUserToDelete={invitedUserToDelete}
						handleOpenDeleteInvitedUser={handleOpenDeleteInvitedUser}
						loading={subAccountLoading}
					/>
				)}
				{inviteModalOpen && (
					<InviteModalPage
						isOpen={inviteModalOpen}
						handleClose={handleClose}
						edit={editModal}
					>
						<div className="ConfigureModal--footer">
							<Button
								label={inviteBackLabel}
								handleClick={handleClose}
								type="button__filled button__filled--secondary"
							/>

							<Button
								label={inviteButtonLabel}
								handleClick={onSaveRole}
								type="button__filled button__filled--primary"
								disabled={isDisabled || isApiLoading}
								loader={
									isApiLoading ? <Loader dimension={14} type="loader" /> : <></>
								}
							/>
						</div>
					</InviteModalPage>
				)}
			</div>
		</div>
	);
};
