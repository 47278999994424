import { Button } from '@storybook';
import Modal from '@storybook/new-modal/modal';
import { useMemo, useState } from 'react';

interface ISessionSendSynergy {
	isOpen: boolean;
	handleCloseModal: () => void;
}

interface FileItem {
	id: string;
	name: string;
	sessionId?: string;
	isParentSession?: boolean;
	recipients?: string;
	isChecked: boolean;
}

export const SessionSendSynergy = ({
	isOpen,
	handleCloseModal,
}: ISessionSendSynergy) => {
	const [files, setFiles] = useState<FileItem[]>([
		{
			id: '1',
			name: 'Goverment issued ID of {Applicant}',
			sessionId: '{Session ID}',
			isParentSession: true,
			isChecked: true,
		},
		{
			id: '2',
			name: 'Session PDF of  {Applicant}',
			sessionId: '{Session ID}',
			isChecked: true,
		},
		{
			id: '3',
			name: 'Goverment issued ID of {Representative 1}',
			sessionId: '{Session ID}',
			isChecked: false,
		},
		{ id: '4', name: 'Session PDF of  {Representative 1}', sessionId: '{Session ID}', isChecked: false },
		{
			id: '5',
			name: 'Goverment issued ID of {Representative 2}',
			isChecked: false,
		},
		{
			id: '6',
			name: 'Session PDF of  {Representative 2}',
			isChecked: false,
		},
		{
			id: '7',
			name: 'Esign Document of {{Applicant}}',
			recipients: 'To: Jordon, Linda, +2 more',
			isChecked: false,
		},
	]);

	const [selectAll, setSelectAll] = useState(false);

	const handleSelectAll = () => {
		const newSelectAll = !selectAll;
		setSelectAll(newSelectAll);

		const updatedFiles = files.map(file => ({
			...file,
			isChecked: newSelectAll,
		}));

		setFiles(updatedFiles);
	};

	const handleSelectFile = (id: string) => {
		const updatedFiles = files.map(file =>
			file.id === id ? { ...file, isChecked: !file.isChecked } : file
		);

		setFiles(updatedFiles);

		// Update selectAll state based on whether all files are checked
		setSelectAll(updatedFiles.every(file => file.isChecked));
	};

	const modalTitle = useMemo(
		() => (
			<>
				<p className="modal-title__heading">Send to Synergy</p>
				{/* <small className="modal-title__desc">
					Select the eSign documents you want to associate with Person Name
					onboarding session.
				</small> */}
			</>
		),
		[]
	);

	return (
		<Modal
			isOpen={isOpen}
			closeModal={handleCloseModal}
			className="SessionEsignLink__modal"
			modalName="Session Esign Link"
			title={modalTitle}
			titleWithCss="SessionEsignLink__modal-title"
		>
			<div className="SessionEsignLink__modal-body">
				<div className="file-selection-container">
					<div className="select-all-header">
						<label className="checkbox-container">
							<input
								type="checkbox"
								checked={selectAll}
								onChange={handleSelectAll}
							/>
							<span className="checkmark"></span>
							<span className="select-all-text">
								Select All ({files.length} files)
							</span>
						</label>
					</div>

					<div className="file-list">
						{files.map(file => (
							<div key={file.id} className="file-item">
								<div className="file-item-content">
									<label className="checkbox-container">
										<input
											type="checkbox"
											checked={file.isChecked}
											onChange={() => handleSelectFile(file.id)}
										/>
										<span className="checkmark"></span>
									</label>

									<div className="file-details">
										<div className="file-name-container">
											<span className="file-name">{file.name}</span>
											{file.isParentSession && (
												<span className="parent-tag">Parent Session</span>
											)}
										</div>
										<div className="session-id">{file.sessionId}</div>
										{file.recipients && (
											<div className="recipients">{file.recipients}</div>
										)}
									</div>

									<button className="view-button">
										<i className="ri-eye-line" />
										<span>View</span>
									</button>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className="SessionEsignLink__modal-footer">
				<Button
					type="button__filled--primary"
					handleClick={handleCloseModal}
					label={'Send'}
				/>
				<Button
					type="button__filled--secondary"
					handleClick={handleCloseModal}
					label="Cancel"
				/>
			</div>
		</Modal>
	);
};
