import { useCallback, useMemo } from "react";
import { activeTabState, hasKey, SignStep, signTabs } from "../store";
import { DrawSign, GenerateSign, UploadSign } from ".";
import { useRecoilState } from "recoil";

export const SignStepper = () => {
  const [activeTab, setActiveTab] = useRecoilState(activeTabState);

  const handleSelectTab = useCallback(
    (name: SignStep) => {
      setActiveTab(name);
    },
    [setActiveTab]
  );

  const stepperBodyObj: { [key in SignStep]: JSX.Element } = useMemo(
    () => ({
      draw: <DrawSign />,
      generate: <GenerateSign />,
      upload: <UploadSign />,
    }),
    []
  );

  const activeTabBody = useMemo(() => {
    if (hasKey(stepperBodyObj, activeTab)) {
      return stepperBodyObj[activeTab];
    }
    return stepperBodyObj.generate;
  }, [activeTab, stepperBodyObj]);

  const renderHeader = useMemo(() => {
    return signTabs.map(({ label, name }) => (
      <div
        className={`admin-sign-stepper__header-item admin-sign-stepper__header-item${
          activeTab === name ? "--active" : ""
        }`}
        onClick={() => handleSelectTab(name)}
        key={name}
      >
        {label}
      </div>
    ));
  }, [activeTab, handleSelectTab]);

  return (
    <div className="admin-sign-stepper">
      <div className="admin-sign-stepper__header">{renderHeader}</div>
      <div className="admin-sign-stepper__body">{activeTabBody}</div>
    </div>
  );
};
