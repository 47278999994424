export const status_chart_color: any = [
	{
		linearGradient: { x1: 1, x2: 0, y1: 0, y2: 1 },
		stops: [
			[0, '#391AE9'],
			[1, '#D011A6'],
		],
	},
	{
		linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
		stops: [
			[0, '#F2500B'],
			[1, '#F5AF45'],
		],
	},
	{
		linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
		stops: [
			[0, '#0A2C84'],
			[1, '#1085DB'],
		],
	},
	{
		linearGradient: { x1: 0, x2: 0, y1: 0.2, y2: 0.2 },
		stops: [
			[0, '#FD0404'],
			[1, '#DC45F5'],
		],
	},
];

export const device_type_chart_color: any = [
	{
		linearGradient: { x1: 1, x2: 0, y1: 0, y2: 1 },
		stops: [
			[0, '#391AE9'],
			[1, '#D011A6'],
		],
	},
	{
		linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
		stops: [
			[0, '#0A2C84'],
			[1, '#1085DB'],
		],
	},
	{
		linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
		stops: [
			[0, '#F2500B'],
			[1, '#F5AF45'],
		],
	},
];

export const lineColor: any = {
	Tablet: 'linear-gradient(90.48deg, #0C4BA1 19.42%, #1086DB 55.45%)',
	Desktop: 'linear-gradient(91.37deg, #F1510D 6.1%, #F6AF46 102.93%)',
	Mobile: 'linear-gradient(90.46deg, #5719DD 34.13%, #A015BC 58.63%)',
	Approved: 'linear-gradient(90.46deg, #5719DD 34.13%, #A015BC 58.63%)',
	Pending: 'linear-gradient(91.37deg, #F1510D 6.1%, #F6AF46 102.93%)',
	Rejected: 'linear-gradient(90.48deg, #0C4BA1 19.42%, #1086DB 55.45%)',
	'Under Review': 'linear-gradient(90.53deg, #F7688B 10.62%, #E554A1 74.82%)',
	KYC: '#2269D3',
	AML: '#E89C2C',
	'Sign Doc': '#C3276F',
	'Funds Pay In': '#04C9FE',
	'Funds Pay Out': '#33B87A',
	'Acc. 506b': '#8B45F5',
	'Acc. 506c': '#F55353',
};

export const funnelChartName: any = {
	desktop: 'Desktop',
	mobile: 'Mobile',
	tablet: 'Tablet',
	accreditation: 'Accreditation',
	'506b': 'Acc. 506b',
	'506c': 'Acc. 506c',
	signAgreement: 'Sign Agreement',
	proofReading: 'Document Review',
	kyc: 'KYC',
	aml: 'AML',
	fundInvestment: 'Fund Inv.',
	authentication: 'Authentication',
	payIn: 'Funds Pay In',
	payOut: 'Funds Pay Out',
	pending: 'Pending',
	completed: 'Approved',
	processing: 'Under Review',
	rejected: 'Rejected',
	failed: 'Failed',
	kyb: 'KYB',
	form: 'Form',
	kybForm: 'KYB Form',
	dynamicForm: 'Dynamic Form',
};

const connectorPreColor = {
	linearGradient: { x1: 1, x2: 0, y1: 0, y2: 0 },
	stops: [
		[0, '#F77593'],
		[1, '#55D6FF'],
	],
};

export const connectorNextColor = {
	linearGradient: { x1: 1, x2: 0, y1: 0, y2: 0 },
	stops: [
		[0, '#895BED'],
		[1, '#E94578'],
	],
};
export const funnelChartColor: any = {
	desktop: connectorPreColor,
	mobile: connectorPreColor,
	tablet: connectorPreColor,
	accreditation: connectorNextColor,
	signAgreement: connectorNextColor,
	kyc: connectorNextColor,
	aml: connectorNextColor,
	fundInvestment: connectorNextColor,
	authentication: connectorNextColor,
	'506b': connectorNextColor,
	'506c': connectorNextColor,
	payIn: connectorNextColor,
	payOut: connectorNextColor,
	kyb: connectorNextColor,
	form: connectorNextColor,
	kybForm: connectorNextColor,
	dynamicForm: connectorNextColor,
	proofReading: connectorNextColor,
};

export const onboardingSteps: any = [
	'Accreditation',
	'Acc. 506b',
	'Acc. 506c',
	'Sign Agreement',
	'KYC',
	'AML',
	'Funds Pay In',
	'Funds Pay Out',
	'KYB',
	'Form',
	'KYB Form',
	'Dynamic Form',
];

export const onboardingStepsAvgTime: any = {
	'Acc. 506b': 'accreditationAvg',
	'Acc. 506c': 'accreditationAvg',
	'Sign Agreement': 'signAgreementAvg',
	KYC: 'kycAvg',
	KYB: 'kybAvg',
	AML: 'amlAvg',
	'Funds Pay In': 'fundInvestmentAvg',
	'Funds Pay Out': 'fundInvestmentAvg',
	'': 'sessionAvg',
};
