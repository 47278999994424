import { GetQueryParams } from 'utils';
import { Footer, AuthHeader } from '../components';
import { SignUpComponent } from './components';
import { SocialSignupModal } from './social-signup-modal';
import { useEffect, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
	IframePropsState,
	SocialSignupDetails,
	SocialSignupDetailsFromParams,
} from 'global-stores';

export const SignUp = () => {
	const socialSignupType = GetQueryParams('type');
	const socialSignupFirstName = GetQueryParams('firstname');
	const socialSignupLastName = GetQueryParams('lastname');
	const socialSignupEmail = GetQueryParams('email');
	const {
		clientlogo = '',
		companyname,
		companyemail,
	} = useRecoilValue(IframePropsState);
	const setSocialSignupDetails = useSetRecoilState(SocialSignupDetails);
	const setSignUpDetailsFromParams = useSetRecoilState(
		SocialSignupDetailsFromParams
	);

	useEffect(() => {
		const details = {
			firstName: socialSignupFirstName ?? '',
			lastName: socialSignupLastName ?? '',
			email: socialSignupEmail ?? '',
			countryCode: '',
			phone: '',
		};
		setSocialSignupDetails(details);
		setSignUpDetailsFromParams(details);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socialSignupFirstName, socialSignupLastName, socialSignupEmail]);

	const isClientWeb = useMemo(() => clientlogo.length > 0, [clientlogo.length]);
	const companyEmail = useMemo(
		() => (isClientWeb ? companyemail : 'info@simplici.com'),
		[companyemail, isClientWeb]
	);

	return (
		<div className="signup-container">
			<AuthHeader btnActive={'signup'} />
			{socialSignupType === 'social' ? (
				<SocialSignupModal />
			) : (
				<>
					<SignUpComponent />
					<div className="signup-container--info">
						<div className="signup-container--details">
							By clicking on the register button, you also give consent to{' '}
							{isClientWeb ? companyname : 'Simplici'} to send SMS notifications
							related to OTP, approvals and account verification. The message
							frequency varies depending on our need to verify an account
							holder. For HELP, send an email to{' '}
							<a href={`mailto:${companyEmail}`}>{companyEmail}</a> or call
							us at
							<a href="tel:+1 (478) 249-3826"> +1 (478) 249-3826</a>. Text STOP
							to opt-out. Message and data rates may apply.
						</div>
					</div>
				</>
			)}
			<Footer />
		</div>
	);
};
