import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { WARNINGS } from 'constant';
import { useNotification } from 'hooks';
import {
	EscrowModalBodyNavigatorState,
	EscrowTransactionTypeState,
	useEscrowAccount,
} from 'views/fund-escrow/stotes';
import './verifying-details.scss';

export const VerifyingDetails = () => {
	//globle states
	const setNavigate = useSetRecoilState(EscrowModalBodyNavigatorState);
	const transactionType = useRecoilValue(EscrowTransactionTypeState);

	// local states
	const [processing, setProcessing] = useState(0);

	//hooks
	const { accountTransaction } = useEscrowAccount();
	const { errorNotification } = useNotification();

	// handle back
	const handleBack = useCallback(() => {
		if (transactionType === 'deposit') {
			setNavigate('deposite_fund');
		}
		if (transactionType === 'withdraw') {
			setNavigate('withdraw_fund');
		}
	}, [setNavigate, transactionType]);

	// handle transaction
	const handleTransaction = useCallback(async () => {
		if (transactionType) {
			const success = await accountTransaction(transactionType);
			if (success) setNavigate('success_screen');
			else handleBack();
		} else {
			setTimeout(() => {
				errorNotification(WARNINGS.ERROR);
				handleBack();
			}, 1000);
		}
	}, [
		accountTransaction,
		errorNotification,
		handleBack,
		setNavigate,
		transactionType,
	]);

	useEffect(() => {
		handleTransaction();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (processing < 100) {
			setTimeout(() => {
				if (processing <= 70) {
					setProcessing(prev => prev + 10);
				} else if (processing <= 85) {
					setProcessing(prev => prev + 5);
				} else {
					setProcessing(prev => prev + 1);
				}
			}, 50);
		}
	}, [processing]);

	return (
		<div className="verifying-details-wrapper">
			<div className="verifying-details">
				<span className="verifying-details__description">
					Verifying detail and{' '}
					{transactionType === 'deposit' ? 'depositing' : 'withdrawing'} fund
				</span>
				<progress
					value={processing}
					max={100}
					className="verifying-details__loading-bar"
				/>
			</div>
			<div className="verifying-details-footer">
				Please do not go back or close this modal until processing is done
			</div>
		</div>
	);
};
