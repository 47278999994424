import { FC, useCallback, useEffect, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
	CreateSignState,
	IBiometricType,
	IDataCoordinate,
	IDataCoordinates,
	PointObject,
	SignPadColorState,
} from '../store';

interface IProps {
	type: IBiometricType;
	isError: boolean;
}

export const DrawSignBox: FC<IProps> = ({ type , isError}) => {
	const [signature, setSignature] = useRecoilState(CreateSignState);
	const color = useRecoilValue(SignPadColorState);
	const signPad: any = useRef();

	const dataCoordinates: IDataCoordinate =
		type === 'sign' ? 'signDataCoordinates' : 'initialsDataCoordinates';

	const handleDelete = useCallback(() => {
		setSignature(prev => {
			const prevState = JSON.parse(JSON.stringify(prev));
			prevState.draw[type] = '';
			prevState.draw[dataCoordinates] = [];
			return prevState;
		});
		signPad.current.clear();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataCoordinates, type]);

	const removeDuplicatePoints = useCallback(
		(arr: PointObject[][]): PointObject[][] =>
			Array.from(new Set(arr.map(JSON.stringify as any))).map(
				JSON.parse as any
			),
		[]
	);

	const handleChange = useCallback(
		(type: IBiometricType | undefined, updatedData?: PointObject[][]) => {
			const prevCloneD = removeDuplicatePoints(
				updatedData || [
					...(signature.draw[dataCoordinates] as IDataCoordinates[]),
					...signPad.current.toData(),
				]
			);
			const data = signPad.current.getTrimmedCanvas().toDataURL('image/png');
			setSignature(prev => {
				const prevState = JSON.parse(JSON.stringify(prev));
				if (type) {
					prevState.draw[type] = data;
				}
				prevState.draw[dataCoordinates] = prevCloneD;
				return prevState;
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[signature, signPad, dataCoordinates]
	);

	useEffect(() => {
		if (dataCoordinates?.length > 0) {
			signPad.current._sigPad?.fromData(signature.draw[dataCoordinates]);
			signPad.current._sigPad._data = JSON.parse(
				JSON.stringify(signPad.current._sigPad._data)
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataCoordinates]);

	return (
		<div style={{ position: 'relative', width: '100%' }}>
			<div
				style={{ position: 'absolute', top: 12, right: 12 }}
				className="admin-draw-sign__clear-btn"
				onClick={handleDelete}
			>
				Clear
			</div>
			<SignatureCanvas
				penColor={color}
				onEnd={() => handleChange(type)}
				ref={signPad}
				clearOnResize={false}
				canvasProps={{ className: `admin-draw-sign__sign-pad admin-draw-sign__sign-pad${isError ? "--error": ""}` }}
			/>
		</div>
	);
};
