import {
	InviteOnboardingPaymentCurrentPage,
	IPaymentType,
	IPayNowPages,
} from './type';

export const MonthlyBillingSteps: {
	[key in Uppercase<InviteOnboardingPaymentCurrentPage>]: InviteOnboardingPaymentCurrentPage;
} = {
	ADD_CARD: 'add_card',
	ADDRESS_INFO: 'address_info',
	ADDRESS_VERIFICATION: 'address_verification',
	CHECKOUT: 'checkout',
	MONTHLY_BILLING_MESSAGE: 'monthly_billing_message',
	COMPANY_INFO: 'company_info',
};
export const OnboardingPaymentType: {
	[key in Uppercase<IPaymentType>]: IPaymentType;
} = {
	MONTHLY_BILLING: 'monthly_billing',
	PAY_NOW: 'pay_now',
};

export const PayNowSteps: { [key in Uppercase<IPayNowPages>]: IPayNowPages } = {
	PAY_NOW: 'pay_now',
	PROCESSING_PAYMENT: 'processing_payment',
	SUCCESS: 'success',
};

export const SignatureProvider: { [key: string]: string } = {
	signAgreementSimpliciSign: 'Sign Agreement(Simplici Sign)',
	signAgreementDocusign: 'Sign Agreement(Docusign)',
	authentication: 'Authentication',
	fundInvestment: 'Fund Processing',
	form: 'Form',
	aml: 'AML(PEPs & Sanctions)',
	'kyc(with liveness)': 'KYC(with liveness)',
	kyc: 'KYC',
	kyb: 'KYB',
};
