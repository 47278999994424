export const IndetityFlowNavigate = {
	ChooseIdentity: 'ChooseIdentity',
	PersonalIdentity: 'PersonalIdentity',
	BusinessIdentity: 'BusinessIdentity',
	Payment: 'Payment',
	Success: 'Success',
	Processing: 'Processing',
	Error: 'Error',
	ChooseMethod: 'ChooseMethod',
	Wire: 'Wire',
	PaymentCard: 'PaymentCard',
	AchView: 'AchView',
	CardView: 'CardView',
	WirePage: 'WireView',
	DefaultAchPage: 'AchDefaultAccount',
	BillingForm: 'BillingForm',
	SubscriptionReview: 'SubscriptionReview',
	ExpireCard: 'ExpireCard',
};

export const IdentityIcon: Record<string, string> = {
	entity: '1-business.svg',
	individual: '2-person.svg',
	warning: '3-warning.svg',
};

export const ChooseIdentityData = [
	{
		type: 'individual',
		label: 'Person/Individual',
		icon: 'ri-user-line',
		desc: 'Best suited for individuals',
		subtitle: 'Service available',
		services: [
			{
				name: 'e-sign',
			},
		],
		serviceProvide: 'You will need to complete KYC verification.',
		name: 'e-sign',
	},
	{
		type: 'entity',
		label: 'Entity/Company',
		icon: 'ri-briefcase-line',
		desc: 'Best suited for entities',
		subtitle: 'Services available',
		services: [
			{
				name: 'e-sign',
			},
			{
				name: 'onboarding',
			},
		],
		serviceProvide: 'You will need to complete both KYC and KYB verifications.',
		name: 'onboarding',
	},
];

export const IdentityType = {
	Individual: 'individual',
	Company: 'entity',
};

export const IdentityHeaderData: Record<
	string,
	{ head: string; desc: string }
> = {
	ChooseIdentity: {
		head: 'Select account type',
		desc: 'Please select identity type you would like to use your Simplici account as',
	},
	SubscribeToService: {
		head: 'Subscribe to services',
		desc: 'Choose services you want to subscribe for',
	},
	PersonalIdentity: {
		head: 'Personal identification details',
		desc: 'Payment method is required so that it can be billed according to your usage',
	},
	BusinessIdentity: {
		head: 'Business identification details',
		desc: 'Payment method is required so that it can be billed according to your usage',
	},
	Payment: {
		head: 'Payment Method Details',
		desc: 'Payment method is required so that it can be billed according to your usage',
	},
	ChooseMethod: {
		head: 'Add a payment method',
		desc: 'Please select your preferred payment method type.',
	},
	PaymentCard: {
		head: 'Card details',
		desc: 'Please provide card details below to ensure seamless future transactions.',
	},
	WireView: {
		head: 'Card details',
		desc: 'Please provide card details below to ensure seamless future transactions.',
	},
	CardView: {
		head: 'Manage payment methods',
		desc: 'Easily manage your payment methods for seamless transactions.',
	},
	AchView: {
		head: 'Manage payment methods',
		desc: 'Easily manage your payment methods for seamless transactions.',
	},
	Wire: {
		head: 'Wire transfer detail',
		desc: 'Please wire transfer money to the given account details below.',
	},
	AchDefaultAccount: {
		head: 'Choose your default or primary payment method',
		desc: "Select the account you'd like to set as your default or primary payment method",
	},
	BillingForm: {
		head: 'Billing Information',
		desc: 'Please provide your billing information.',
	},
	SubscriptionReview: {
		head: 'Review subscription and pay',
		desc: 'Please review the subscription details and proceed with the payment to complete the process.',
	},
};

export const ApprovalStatus = {
	Approved: 'APPROVED',
	Rejected: 'REJECTED',
	Pending: 'PENDING',
	UnderReview: 'UNDER_REVIEW',
};

export const RequestType = {
	UpgradeAccount: 'upgradeAccount',
	IdentityRegistration: 'accountTypeRegistration',
};

export const ShowBussinessDetails = {
	KycReport: 'KycReport',
	KybReport: 'KybReport',
	KycKybReport: 'KycKybReport',
};
