import { FC, useMemo } from 'react'; // Importing React utilities for functional components and side effects
import { useRecoilValue } from 'recoil'; // To access Recoil state
import { Button, Image } from '@storybook'; // Importing Image and Button components from Storybook

import { CancelSubscriptionResponseState } from '../../stores'; // Recoil state to get cancellation details
import { getDate, getTime } from 'utils'; // Utility functions for date and time formatting

import './cancel-subscription-completed.scss'; // Importing styles specific to the completed subscription cancellation page

// Interface defining props for the CancelSubscriptionCompleted component
interface ICancelSubscriptionCompleted {
	handleClose: () => void; // Callback function to handle modal or component close
}

// Component to display a confirmation message after subscription cancellation
export const CancelSubscriptionCompleted: FC<ICancelSubscriptionCompleted> = ({
	handleClose, // Destructuring the handleClose function from props
}) => {
	// Retrieve the cancellation response from Recoil state
	const cancelResponse = useRecoilValue(CancelSubscriptionResponseState);

	// Memoize the cancellation date to avoid unnecessary recalculations
	const canceledDate = useMemo(() => {
		const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
		const cancelledAt = cancelResponse?.cancelledAt;

		// Ensure cancelledAt is a valid ISO string, otherwise use the current date and time
		return typeof cancelledAt === 'string' && isoRegex.test(cancelledAt)
			? cancelledAt
			: new Date().toISOString();
	}, [cancelResponse?.cancelledAt]);

	return (
		<div className="Cancel_Subscription_Completed">
			{/* Display a confirmation image */}
			<Image fileName="cancel-confirm.svg" width="188px" height="144px" />

			{/* Title to confirm cancellation */}
			<div className="Cancel_Subscription_Completed--title">
				Cancellation confirmed. We hope to see you back again soon!
			</div>

			{/* Description with cancellation details */}
			<div className="Cancel_Subscription_Completed--description">
				Your subscription plan was cancelled on{' '}
				<strong>{getDate(canceledDate)}</strong> at{' '}
				<strong>{getTime(canceledDate)}</strong>. If you change your mind, you
				can reactivate your subscription anytime.
			</div>

			{/* Close button */}
			<Button
				label={'Close'}
				type="button button__filled--secondary Cancel_Subscription_Completed--close_btn"
				handleClick={handleClose} // Close handler passed from parent component
			/>
		</div>
	);
};
