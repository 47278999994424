import { ICredential } from 'global-stores/sessions/type';

import { useCallback, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { API_URL } from 'constant';
import {
	ComplexSessionState,
	FilterSessionTypeTableState,
	IDateRange,
	SelectedDateRangeState,
	SessionState,
} from 'global-stores';
import { useNetwork, useNotification } from 'hooks';
import { getDate } from 'utils';
import { AssignSelectedSessionState, SessionTypesQuery } from 'views/sessions';
import {
	FetchingSessionsFilterState,
	FoundFilterSessionsCountState,
	SessionTextFilterState,
	TextForFilterSessionsState,
} from './states';

export const useSessionsTextFilter = () => {
	// globle states
	const [textForFilterSession, setTextForFilterSession] = useRecoilState(
		TextForFilterSessionsState
	);
	const [sessionFilterText, setSessionFilterText] = useRecoilState(
		SessionTextFilterState
	);
	const [fetchingSessions, setFetchingSessions] = useRecoilState(
		FetchingSessionsFilterState
	);
	const selectedDateRange = useRecoilValue(SelectedDateRangeState);
	const sessionTypeState = useRecoilValue(FilterSessionTypeTableState);
	const setSessionList = useSetRecoilState(SessionState);
	const setComplexSessionList = useSetRecoilState(ComplexSessionState);
	const setFoundFilteredSessions = useSetRecoilState(
		FoundFilterSessionsCountState
	);
	const setSelectedSession = useSetRecoilState(AssignSelectedSessionState);

	//local state
	const [resetSessionsFilter, setResetSessionsFilter] = useState(false);

	// hooks
	const { errorNotification } = useNotification();
	const { get, abortController: Controller } = useNetwork();

	const { endDate, startDate } = (selectedDateRange?.[0] as IDateRange) ?? {};

	const textFilterCount = useMemo(() => {
		if (!textForFilterSession?.length) return 0;
		return textForFilterSession?.split(',')?.length ?? 0;
	}, [textForFilterSession]);

	const foundSessionsCount = useCallback(
		(sessions: ICredential[]) => {
			if (sessions?.length) {
				const checkDateRange = (date: string) => {
					const createdAt = new Date(date);
					return createdAt >= startDate && createdAt <= endDate;
				};
				return (
					sessions.filter(sessionRow => {
						return checkDateRange(sessionRow.date ?? '');
					})?.length ?? 0
				);
			}
			return 0;
		},
		[endDate, startDate]
	);

	const resetSessions = (notResetText?: boolean) => {
		if (sessionFilterText && !notResetText) {
			setSessionFilterText('');
		}
		if (textForFilterSession) {
			setSelectedSession([]);
			setResetSessionsFilter(true);
			setFetchingSessions(true);
			setTextForFilterSession('');
			setFoundFilteredSessions(0);
			setSessionList([]);
			setComplexSessionList([]);
			setResetSessionsFilter(false);
			setFetchingSessions(false);
			Controller.abort();
		}
	};

	const isArchived = useMemo(
		() => sessionTypeState?.value === SessionTypesQuery?.Archived_Sessions,
		[sessionTypeState?.value]
	);

	const fetchSessions = async (newSessionTexts: string) => {
		if (newSessionTexts) {
			setFetchingSessions(true);
			setSelectedSession([]);
			const encodedSessionTexts = encodeURIComponent(newSessionTexts);
			const fromDate = getDate(startDate);
			const toDate = getDate(endDate);
			const sessionUrl = `${
				API_URL.SESSIONS
			}?from=${fromDate}&to=${toDate}&isDeleted=${
				sessionTypeState.value === SessionTypesQuery.Archived_Sessions
			}&textSearch=${encodedSessionTexts}`;
			const complexUrl = `${API_URL.COMPLEX_SESSION}?isDeleted=${isArchived}&textSearch=${encodedSessionTexts}`;
			const sessionResponse = await Promise.all([
				get(complexUrl),
				get(sessionUrl),
			]);
			const { data: complexData } = sessionResponse?.[0] ?? {};
			const { data: sessionsData } = sessionResponse?.[1] ?? {};
			setTextForFilterSession(encodedSessionTexts);
			Controller.abort();
			if (!sessionsData?.length && !complexData?.length) {
				errorNotification('No session found');
				setFoundFilteredSessions(0);
				setTextForFilterSession('');
				setSessionList([]);
				setComplexSessionList([]);
				setFetchingSessions(false);
			} else {
				setSessionList(sessionsData ?? []);
				setComplexSessionList(complexData ?? []);
				setFetchingSessions(false);
			}
		}
	};

	const filterSessions = (rowSessionsIds: string) => {
		const trimmedSessionIDs = rowSessionsIds?.trim() ?? '';

		if (trimmedSessionIDs) {
			const sessionsList = trimmedSessionIDs
				.split(/,\s*/)
				.filter(sessionID => sessionID !== '');
			const newSessionsList: string[] = [];
			let checkError = false;
			sessionsList.some(sessionID => {
				const trimedValue = sessionID?.trim() ?? '';
				if (trimedValue.includes(' ')) {
					errorNotification(
						`'${trimedValue}' is as invalid value , it should not contain spaces`
					);
					resetSessions(true);
					checkError = true;
					return true;
				}
				if (
					!/^\+?\d{8,15}$/.test(trimedValue) &&
					!/^[0-9a-fA-F]{24}$/.test(trimedValue)
				) {
					errorNotification(
						`'${trimedValue}' is not a valid session id or phone`
					);
					resetSessions(true);
					checkError = true;
					return true;
				}
				newSessionsList.push(trimedValue);
				return false;
			});
			if (!checkError) fetchSessions(newSessionsList.join(',') ?? '');
		} else {
			resetSessions(true);
			errorNotification('Please paste your Session Id or Phone Number');
		}
	};
	return {
		filterSessions,
		resetSessions,
		textFilterCount,
		resetingSessionsFilter: resetSessionsFilter,
		fetchingSessions,
		foundSessionsCount,
	};
};
