import Progress from '@storybook/progress';
import { FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { API_URL } from 'constant';
import { InviteNavigateModalState } from 'global-stores';
import { useNetwork, useNotification } from 'hooks';
import { EscrowType, KEYLEVEL, ProviderEnum } from 'views/pipelines/constants';
import { BusinessInformationErrorState } from '../address-details/store';
import { FundsIdState, renderErrorMessage } from '../store';

interface VerifyingDetailsProps {
	response: any;
	loading: boolean;
}

export const VerifyingDetails: FC<VerifyingDetailsProps> = ({
	loading,
	response,
}) => {
	// globle State
	const setErrorMessage = useSetRecoilState(BusinessInformationErrorState);
	const setNavigate = useSetRecoilState(InviteNavigateModalState);
	const fundsIdState = useRecoilValue(FundsIdState);

	const { post, loading: isBankCreated } = useNetwork();
	const { errorNotification } = useNotification();

	// Generate example data.
	const [progress, setProgress] = useState(0);

	const defaultData = Array.from(Array(1000).keys());
	const batchSize = 100;

	function sleep(ms: number) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	const saveEmployees = useCallback((items: number[]) => {
		return sleep(10 * items.length);
	}, []);

	const uploadBatched = useCallback(
		(
			items: number[],
			setProgress: {
				(value: SetStateAction<number>): void;
				(arg0: number): void;
			}
		) => {
			let previous;
			for (let i = 0; i < items.length; i += batchSize) {
				// Workaround code, please read the async version of uploadBatched instead.
				// I also cheat and add batchSize, to avoid needing a complex fix for the last batch.
				const doBatch = () => {
					setProgress((100 * (i + batchSize)) / items.length);
					const chunk = items.slice(i, i + batchSize);
					return saveEmployees(chunk);
				};
				if (previous) {
					previous = previous.then(doBatch);
				} else {
					previous = doBatch();
				}
			}
		},
		[saveEmployees]
	);

	const createBankAccount = useCallback(async () => {
		const resp = await post(API_URL.ESCROW_ACCOUNT, {
			provider: ProviderEnum.Provider,
			type: EscrowType.Business,
			fundId: fundsIdState?.fundId,
		});

		if (resp && resp?.id) {
			setNavigate('escrowSuccess');
			return;
		} else {
			if (resp?.message === 'Escrow account already created') {
				setNavigate('escrowSuccess');
			} else {
				errorNotification(resp?.message);
				return;
			}
		}

		// eslint-disable-next-line
	}, [post, fundsIdState, setNavigate]);

	useEffect(() => {
		uploadBatched(defaultData, setProgress);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (!fundsIdState && loading) return;
		createBankAccount();
		// eslint-disable-next-line
	}, [response, loading]);

	// checking progress value
	useEffect(() => {
		if (!response || loading || isBankCreated) return;
		if (response?.kycLevel !== KEYLEVEL.L1) {
			const messList = response?.message?.split("'");
			if (messList?.length > 0) {
				messList.forEach((item: string) => {
					const error = renderErrorMessage(item);
					if (typeof error === 'object') {
						setErrorMessage(prev => ({ ...prev, ...error }));
					}
					return null;
				});
			}
			errorNotification(response?.message);
			return setNavigate('businessForm');
		}
		// eslint-disable-next-line
	}, [response, loading]);

	return (
		<div id="verifing__container">
			<p className="verifing__label">
				Verifying detail and creating an escrow accounts.
			</p>
			<Progress bgcolor="#000" progress={progress} />
		</div>
	);
};
