import { SurveyQuestionElementBase } from 'survey-react-ui';

import { CSSProperties } from 'react';
import { AutoFillLocation } from '@storybook';

export class CustomAddressPicker extends SurveyQuestionElementBase {
	constructor(props: any) {
		super(props);
		this.state = { value: this.question?.value?.enteredAddress ?? null };
	}
	get question() {
		return this.questionBase;
	}
	get value() {
		return this.question?.value;
	}
	get disableAlpha() {
		return this.question.disableAlpha;
	}
	get type() {
		return this.question.colorPickerType;
	}

	handleChange = (e: any) => {
		this.setState((prev: any) => ({...prev, value: e.target.value}))
	};

	handleUpdateDetails = (data: any) => {
		this.question.value = data;
		this.setState({value: data.enteredAddress})
	};
	// Support the read-only and design modes
	get style() {
		return this.question.getPropertyValue('readOnly') ||
			this.question.isDesignMode
			? { pointerEvents: 'none' }
			: undefined;
	}

	renderElement() {
		return (
			<div style={this.style as CSSProperties}>
                <AutoFillLocation handleOnChange={this.handleChange} value={this.state.value ?? this.question?.value?.enteredAddress} onSuccess={this.handleUpdateDetails} isDisabled={this.question.isReadOnly}/>
			</div>
		);
	}
}
