import { useCallback } from 'react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { Button, Image } from '@storybook';
import { InviteNavigateModalState } from 'global-stores';
import {
	BusinessInformationState,
	PersonalInformationId,
	PersonalInformationState,
} from '../address-details/store';

export const SuccessDetails = () => {
	// Globle state
	const setNavigate = useSetRecoilState(InviteNavigateModalState);
	const resetBusinessForm = useResetRecoilState(BusinessInformationState);
	const resetPersonalForm = useResetRecoilState(PersonalInformationState);
	const resetPersonalId = useResetRecoilState(PersonalInformationId);

	const handleNextClick = useCallback(() => {
		resetBusinessForm();
		resetPersonalForm();
		resetPersonalId();
		setNavigate('csv');
	}, [resetBusinessForm, resetPersonalForm, resetPersonalId, setNavigate]);

	return (
		<div id="success__container">
			<Image fileName="success.gif" loading="lazy" />
			<h2>Escrow account added successfully</h2>
			<p className="success__label">
				You&lsquo;ve successfully added escrow account for this fund.
			</p>
			<Button
				handleClick={handleNextClick}
				label="Next"
				type="button__filled button__filled--primary button__large"
			/>
		</div>
	);
};
