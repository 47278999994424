import { useCallback, useMemo, useState } from 'react';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';

import { API_URL, TOAST_MESSAGES, message } from 'constant';
import { UserRoles, UserRolesState } from 'global-stores';
import { useNetwork, useNotification } from 'hooks';
import { RoleAndPermissionNavigation, UserRoleNavigation } from '../constant';
import {
	AddNewRoleFormState,
	IAddNewRoleFormPayload,
	PermissionNavigation,
	SelectedUserRoleState,
	UserRolePermissionNavigation,
} from '../store';
import { useUserRoles } from 'views/routes-children';

type UseNewRole = {
	handleCloseModel?: () => void;
};

export const useNewRoles = ({ handleCloseModel }: UseNewRole) => {
	//global state
	const [navigate, setNavigation] = useRecoilState(PermissionNavigation);
	const [userRoles, setUserRoles] = useRecoilState(UserRolesState);
	const resetAddRoleForm = useResetRecoilState(AddNewRoleFormState);
	const resetSelectedUserRole = useResetRecoilState(SelectedUserRoleState);
	const addRoleForm = useRecoilValue(AddNewRoleFormState);
	const setUserRoleNavigate = useSetRecoilState(UserRolePermissionNavigation);

	const { name, description, services, fullAccess, permissions } = addRoleForm;

	const {
		post: postAddNewRole,
		remove: deleteAddNewRole,
		loading: addNewRoleLoader,
	} = useNetwork();

	const { post: reinvite, loading: isReInviteLoading } = useNetwork();

	const [isOpenDeleteModal, setIsUserDeleteModal] = useState(false);
	const [selectedUserRole, setSelectedUserRole] = useState<any>(null);
	const { successNotification, errorNotification } = useNotification();
	const { fetchUserRoles } = useUserRoles();

	const handleBackModel = () => {
		setNavigation(RoleAndPermissionNavigation.CreateNewRole);
	};

	const handleAddRole = () => {
		const payload: IAddNewRoleFormPayload = {
			name: name.trim(),
			description: description.trim(),
			services,
			fullAccess,
		};

		if (!fullAccess) {
			payload['permissions'] = permissions;
		}

		postAddNewRole(API_URL.ROLES, payload).then((resp: any) => {
			if (resp?._id) {
				setUserRoles(pre => {
					const prevObject = structuredClone(pre);
					prevObject?.push(resp);

					return prevObject;
				});
				successNotification(message.ROLE_ADDED_SUCCESS);
				handleCloseModel?.();
				resetAddRoleForm();
				resetSelectedUserRole();
				fetchUserRoles();
			}
		});
	};

	const handleContinue = () => {
		setNavigation(RoleAndPermissionNavigation.AddNewRole);
	};

	// handle next on onclick Add role and continue button
	const handleNext = () => {
		switch (navigate) {
			case RoleAndPermissionNavigation.AddNewRole:
				handleAddRole();
				break;
			case RoleAndPermissionNavigation.CreateNewRole:
				handleContinue();
				break;
			case RoleAndPermissionNavigation.NoRoleAvailable:
				handleContinue();
				break;
			default:
				break;
		}
	};

	// handle back onclick back and cancel button
	const handleBack = useCallback(() => {
		switch (navigate) {
			case RoleAndPermissionNavigation.AddNewRole:
				resetAddRoleForm();
				handleBackModel();
				resetSelectedUserRole();
				break;
			case RoleAndPermissionNavigation.CreateNewRole:
				resetAddRoleForm();
				handleCloseModel?.();
				resetSelectedUserRole();
				break;
			case RoleAndPermissionNavigation.NoRoleAvailable:
				handleCloseModel?.();
				break;
			default:
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigate]);

	// render next button label
	const mapToNavigationNextLabel = useMemo(() => {
		switch (navigate) {
			case RoleAndPermissionNavigation.AddNewRole:
				return 'Add role';
			case RoleAndPermissionNavigation.CreateNewRole:
				return 'Next';
			case RoleAndPermissionNavigation.NoRoleAvailable:
				return 'Continue';
			default:
				return 'Next';
		}
	}, [navigate]);

	// render previous button label
	const mapToNavigationPreviousLabel = useMemo(() => {
		switch (navigate) {
			case RoleAndPermissionNavigation.AddNewRole:
				return 'Back';
			case RoleAndPermissionNavigation.CreateNewRole:
				return 'Cancel';
			case RoleAndPermissionNavigation.NoRoleAvailable:
				return 'Cancel';
			default:
				return 'Cancel';
		}
	}, [navigate]);

	const handleDeleteUserRole = useCallback(
		(isOpen: boolean, value: boolean) => {
			if (value && userRoles) {
				const remainedSubAccount = userRoles.filter(
					item => item._id !== selectedUserRole._id
				);
				deleteAddNewRole(`${API_URL.ROLES}/${selectedUserRole?._id}`).then(
					resp => {
						if (resp?.success) {
							setUserRoles(remainedSubAccount);
							setIsUserDeleteModal(isOpen);
							setSelectedUserRole(null);
							successNotification(message.DELETE_MESSAGE);
							setUserRoleNavigate(UserRoleNavigation.AddedRoleCard);
						} else {
							errorNotification(resp?.message ?? TOAST_MESSAGES.ERROR);
						}
					}
				);
			} else {
				setIsUserDeleteModal(value);
				setSelectedUserRole(null);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectedUserRole, userRoles]
	);

	const handleOpenDelete = useCallback(
		(e: React.MouseEvent<HTMLDivElement>, item: UserRoles | null) => {
			e.stopPropagation();
			setIsUserDeleteModal(true);
			setSelectedUserRole(item);
		},
		[]
	);

	const handleReinvite = useCallback(
		async (id: string) => {
			if (!id) {
				return;
			}
			const response = await reinvite(API_URL.USER_VERIFICATION, {
				sendEmail: true,
				sendSMS: true,
				smsType: 'invitePhoneVerification',
				id,
			});
			if (response.emailVerificationId) {
				successNotification('You have successfully re-invited the user.');
			} else {
				errorNotification('Failed to re-invite the user. Please try again.');
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);
	return {
		mapToNavigationPreviousLabel,
		mapToNavigationNextLabel,
		handleBack,
		handleNext,
		addNewRoleLoader,
		isOpenDeleteModal,
		handleDeleteUserRole,
		handleOpenDelete,
		selectedUserRole,
		handleReinvite,
		isReInviteLoading,
	};
};
