import { useMemo } from 'react';

import { useRecoilValue } from 'recoil';
import {
	ComplexSessionInfoState,
	ConditionalSessionFlowState,
	SelectedSessionState,
} from 'global-stores';

interface IAppleID {
	sessionData?: any;
}
export const AppleID = ({ sessionData }: IAppleID) => {
	const sessionSelected = useRecoilValue(SelectedSessionState);
	const isComplexFlow = useRecoilValue(ConditionalSessionFlowState);
	const complexSessionInfo = useRecoilValue(ComplexSessionInfoState);

	const { deviceInfo } = useMemo(() => {
		if (isComplexFlow) {
			return (sessionData ?? complexSessionInfo)?.metadata ?? ({} as any);
		}
		return (
			sessionSelected?.device ?? sessionSelected?.deviceInfo ?? ({} as any)
		);
	}, [
		complexSessionInfo,
		isComplexFlow,
		sessionData,
		sessionSelected?.device,
		sessionSelected?.deviceInfo,
	]);

	const device = useMemo(() => {
		const {
			name = 'NA',
			email = 'NA',
			deviceId = 'NA',
			appleId = 'NA',
		} = deviceInfo ?? {};
		return { name, email, deviceId, appleId };
	}, [deviceInfo]);

	return (
		<>
			{device.name && device.email && (
				<>
					<div className="apple-id">
						<div className="session-info__body__details apple-id_info">
							<div className="session-info__body__icon-container user">
								<i className="ri-apple-line" />
							</div>
							<div className="session-info__body__detailed">
								<span className="session-info__body__key">Apple ID</span>
								<span className="session-info__body__value">
									{device.appleId?.toLowerCase() || 'NA'}
								</span>
							</div>
						</div>

						<div className="session-info__body__details apple-id_info">
							<div className="session-info__body__icon-container location">
								<i className="ri-device-line" />
							</div>
							<div className="session-info__body__detailed">
								<span className="session-info__body__key">Device ID</span>
								<span className="session-info__body__value">
									{device.deviceId || 'NA'}
								</span>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
};
