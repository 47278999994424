import { Button } from '@storybook';
import { useMemo, useState } from 'react';

import { REJECTED_CONSTANTS } from 'views/business-rejected/constants';
import Modal from '@storybook/new-modal/modal';
import { BusinessSession } from 'components';
import { useLogout } from 'hooks';
import { AccountDetails } from 'views/account-details';
import { StepStatus, TimeLine } from './components';
import { BusinessRegistrationState } from 'global-stores';
import { useRecoilValue } from 'recoil';
import { AcountUserTypes } from 'views/settings';
import './business-processing.scss';
import '../business-rejected/business-rejected.scss';

export const BusinessProcessing = () => {
	const [openModal, setOpenModal] = useState(false);
	const { logout, loading } = useLogout();
	const businessRegistration = useRecoilValue(BusinessRegistrationState);

	const {
		kyb,
		kyc,
		accountType = '',
		aml,
		kybForm,
	} = useMemo(() => businessRegistration ?? {}, [businessRegistration]);

	const { status: kycStatus = '' } = useMemo(
		() => kyc?.data ?? kyc ?? {},
		[kyc]
	);

	const { status: kybStatus = '' } = useMemo(
		() => kyb?.lexisNexisReport ?? kyb ?? {},
		[kyb]
	);

	const handletoggleModal = () => {
		setOpenModal(preState => !preState);
	};

	const logoutUser = () => {
		if (loading) return;
		logout();
	};

	return (
		<section className="Business-Processing">
			<div className="Business-Processing--body">
				<div className="Business-Processing--header">
					<h1>Submitted for review</h1>
					<p>
						We&apos;ll review your information. Once your account get verified
						you can start using the platform.
					</p>
				</div>
				<div className="Business-Processing--body-container">
					<div className="Business-Processing--left">
						<StepStatus statusLabel="KYC" status={kycStatus} />
						<StepStatus statusLabel="AML" status={aml?.status} />
						{accountType === AcountUserTypes.Entity && (
							<>
								<StepStatus statusLabel="KYB" status={kybStatus} />
								<StepStatus statusLabel="KYB Form" status={kybForm?.status} />
							</>
						)}
					</div>

					<div className="Business-Processing--right">
						<TimeLine />
					</div>
				</div>
				<AccountDetails accountType={accountType} />
				<div className="Business-Processing__viewBtn">
					<Button
						label="Logout"
						type="button__filled--secondry Business-Rejected-logout"
						handleClick={logoutUser}
						disabled={loading}
						prefixIcon="ri-logout-box-r-line"
					/>
					<Button
						label={REJECTED_CONSTANTS.VIEW_REPORT}
						type="button__filled--secondary"
						handleClick={handletoggleModal}
					/>
				</div>
			</div>

			<Modal
				modalName="kyc-modal"
				isOpen={openModal}
				closeModal={handletoggleModal}
				className="Business-Rejected-Modal Business-Rejected-Session-Modal"
				isCloseButtonSticky={true}
				optionalClassName="Business-Rejected-Modal--body"
			>
				<div className="Business-Rejected-Modal__wrapper">
					<BusinessSession />
				</div>
			</Modal>
		</section>
	);
};
