import { Image } from '@storybook';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { ComplexMetaDataId } from 'views/mobile-ui-screen/constants';
import { ComplexSettingFormState } from 'views/pipeline';

export const SignMobileUI = () => {
	const complexMetaDataId = useRecoilValue(ComplexMetaDataId);
	const complexSettingForm = useRecoilValue(ComplexSettingFormState);

	const signtype = useMemo(() => {
		return complexSettingForm?.[complexMetaDataId]?.signAgreementProvider;
	}, [complexMetaDataId, complexSettingForm]);

	const getImage: { [key: string]: string } = {
		esign: 'eSignMobile.svg',
		docusign: 'docuSignMobile.svg',
	};

	return (
		<div className="signature-wrapper">
			<div className="signature-wrapper__inner">
				<Image fileName={getImage[signtype]} />
			</div>
		</div>
	);
};
