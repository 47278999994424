import { FC } from 'react';

import { useOnboardingHook } from 'views/onboarding-flow';

import './label.scss';

interface ILabel {
	label?: string;
	priceLabel?: string;
	price?: number;
}
export const Label: FC<ILabel> = ({ label, price, priceLabel }) => {
	const { isPricingHide } = useOnboardingHook();

	return (
		<div
			className={`condition-label ${
				label === 'esign' ? 'condition-label__esign' : ''
			}`}
		>
			<div className="left">{label}</div>
			{isPricingHide && price && (
				<div className="right">
					<span>${price?.toFixed(2)}</span>/{priceLabel}
				</div>
			)}
		</div>
	);
};
