import Modal from '@storybook/new-modal/modal';
import { Fragment, useCallback, useEffect, useMemo } from 'react';
import {
	allConfiguredNodes,
	IsOverlayMultiSignModalOpen,
	OverlayMultiSignPrepareUrl,
	SelectedComplexSignAgreementStep,
	SignAgreementComplexStepConfigured,
	useOverlaySignAgreement,
} from '../store';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';
import { Loader } from '@storybook';
import { UploadDocumentOptionState } from 'views/multisign-template-selection';
import {
	ShowMultiSignTemplateSelectionScreen,
	ShowSignOptionInQr,
	SignOptionState,
} from 'views/pipelines/components';
import { NavigateToBackConfirmation } from 'components/esign-back-modal';
import { SimpliciSignIframe } from 'shared-components';
import { UploadedDocsState } from 'global-stores';

export const OverlayMultiSignAgreement = () => {
	const [isOpen, setIsOpen] = useRecoilState(IsOverlayMultiSignModalOpen);
	const config = useRecoilValue(OverlayMultiSignPrepareUrl);
	const resetConfig = useResetRecoilState(OverlayMultiSignPrepareUrl);
	const activeOption = useRecoilValue(UploadDocumentOptionState);
	const setConfigurationStatus = useSetRecoilState(
		SignAgreementComplexStepConfigured
	);
	const [allConfiguredNodesOverlay, setAllConfiguredNodesOverlay] = useRecoilState(allConfiguredNodes);
	const selectedSignNode = useRecoilValue(SelectedComplexSignAgreementStep);
	const resetSignOption = useResetRecoilState(SignOptionState);
	const resetShowMultiSignTemplateSelectionScreen = useResetRecoilState(
		ShowMultiSignTemplateSelectionScreen
	);
	const resetShowSignOptionVisible = useResetRecoilState(ShowSignOptionInQr);
	const setUploadedDocsState = useSetRecoilState(UploadedDocsState);
	const { createTemplate, updateTemplate } = useOverlaySignAgreement();

	/**
	 * useEffect to handle initial fetch and configurations.
	 */
	useEffect(() => {
		if (activeOption === 'newDocument') {
			createTemplate();
		} else {
			updateTemplate();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// When multisign configure modal closes this function triggered
	const handleSaveTemplate = useCallback(() => {
		setIsOpen(false);
		if (selectedSignNode) {
			// Update the configuration status after successful configuration
			setConfigurationStatus(prev => {
				// Create a copy of the previous state
				const newState = structuredClone(prev);
				// Update the state based on selectedSignNode
				newState[selectedSignNode] = {
					status: 'configured',
					/**
					 * When the user is reconfiguring any sign node, then we need not to change the envelopeId,
					 * and the old envelopeId will only be used for reconfigure for as many times also it will be used as payload in the
					 */
					templateId:
						newState[selectedSignNode]?.templateId || config.templateId,
					envelopeId: '',
				};
				// Return the updated state
				return newState;
			});
			setAllConfiguredNodesOverlay((prev) => [...prev, selectedSignNode])
			setUploadedDocsState(prev => {
				// Create a copy of the previous state
				const newState = structuredClone(prev);
				return newState.filter(doc => doc.node !== selectedSignNode)
			})
			resetShowSignOptionVisible();
			resetSignOption();
			resetShowMultiSignTemplateSelectionScreen();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [config.templateId, selectedSignNode, setAllConfiguredNodesOverlay]);

	const handleCloseModal = useCallback(() => {
		if (selectedSignNode) {
			setConfigurationStatus(prev => {
				return {
					...prev,
					[selectedSignNode]: {
						templateId: prev[selectedSignNode]?.templateId || config.templateId,
						envelopeId: '',
						status:
							prev[selectedSignNode]?.status === 'reconfigure' || allConfiguredNodesOverlay.includes(selectedSignNode)
								? 'configured'
								: prev[selectedSignNode]?.status || 'pending',
					},
				};
			});
		}
		// Close the MultiSign modal
		setIsOpen(false);
		// Clear the interval for checking envelope status		
		// Reseting all selected option while going back
		resetSignOption();
		resetShowSignOptionVisible();
		// Reset the config values
		resetConfig();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedSignNode, allConfiguredNodesOverlay]);

	const renderSimpliciSignIframe = useMemo(() => {
		if (config.prepareUrl?.trim()) {
			return (
				<>
					<SimpliciSignIframe
						className="SimpliciUseTemplate__iframe  SimpliciUseTemplate__iframe-wrapper"
						title="onboarding-multisigner-modal"
						signUrl={config.prepareUrl}
						handleSubmitModal={handleSaveTemplate}
					/>
					<button onClick={handleCloseModal} className="close-btn">
						<i className="ri-close-line" />
					</button>
				</>
			);
		}
		return <Loader dimension={50} />;
	}, [config, handleCloseModal, handleSaveTemplate]);

	return (
		<Fragment>
			<Modal
				isOpen={isOpen}
				modalName=""
				isStopOutsideClick={false}
				showCloseBtn={false}
				className="start-new-modal"
			>
				{renderSimpliciSignIframe}
			</Modal>
			{isOpen && (
				<NavigateToBackConfirmation
					isModalOpen={isOpen}
					modalCloseHandler={handleCloseModal}
				/>
			)}
		</Fragment>
	);
};
