import { FC, memo } from 'react'
import { DonutPieChart } from 'views/support-agent';

export const DocumentStatusChart: FC<{
	completedLength: number;
	envelopeLength: number;
	isLoading: boolean;
}> = memo(({ completedLength, envelopeLength, isLoading }) => {
	return (
		<DonutPieChart
			isLoading={isLoading}
			centerTitle="Documents"
			centerValue={completedLength}
			innerSize="90%"
			title="signDashboard"
			seriesData={[
				{
					name: 'Completed',
					y: completedLength,
					color: 'var(--color-green)',
				},
				{
					name: 'Pending',
					y: envelopeLength - completedLength,
					color: 'var(--color-input-border-light)',
				},
			]}
		/>
	);
});
DocumentStatusChart.displayName = 'DocumentStatusChart';
