import { FC, useMemo } from 'react';

import { SessionDetailsSkeletonLoader } from 'views/sessions/session-details-loader';
import { ActionButton } from '../action';
import { DocSlider } from '../docSlider';
import { NoData } from '../no-data-available';
import { SessionDetailsHeading } from '../session-details-heading';

interface ISignAgreeMent {
	signAgreement: any;
	role: string;
	isAgentPermitted?: boolean;
	isTransactionPage: boolean;
	isLoading: boolean;
	isLoaded?: boolean;
	nodeId?: string;
	isUserPermissionWrite?:boolean;
}

const AGREEMENT_STEP = {
	STATUS: {
		COMPLETED: 'completed',
		CONFIGURED: 'configured',
	},
	MESSAGE:
		'The agreement will be made available once all parties involved have successfully completed the signing process',
};

export const SignAgreeMent: FC<ISignAgreeMent> = ({
	signAgreement,
	role,
	isAgentPermitted,
	isTransactionPage,
	isLoading,
	isLoaded,
	nodeId,
	isUserPermissionWrite= true,
}) => {
	const modifiedSignAgreementStep = useMemo(() => {
		return signAgreement?.map((agreementNode: { [key: string]: string }) => {
			// Create a copy of the object without modifying the original object
			const modifiedNode = { ...agreementNode };
				if (modifiedNode.envelopeStatus === AGREEMENT_STEP.STATUS.CONFIGURED) {
					// Delete the 'envelopeStatus' property
					delete modifiedNode.envelopeStatus;
					modifiedNode.message = AGREEMENT_STEP.MESSAGE;
				}
				
				/* The 'envelopeStatus' key is needed to display the packet status, so it has been no need    to delete hence commented for deletion.*/

				// delete modifiedNode.envelopeStatus;
				return modifiedNode;
		});
	}, [signAgreement]);

	return (
		<div className="agreement-accreditation">
			<div className="signAgreement">
				<div className="kycDetails-contain">
					<SessionDetailsHeading
						label="Sign Agreement"
						icon={
							<i className="ri-profile-line form-icon-profile-container__formIcon"></i>
						}
					>
						{signAgreement?.length > 0 && (
							<>
								<div className="action-btn">
									{role === 'AGENT' && !isAgentPermitted ? (
										<div className="action-btn__agent">
											{signAgreement[0].status}
										</div>
									) : (
										<ActionButton
											status={signAgreement[0].status}
											type="signAgreement"
											isTransactionPage={isTransactionPage}
											isLoading={isLoading}
											nodeId={nodeId}
											isDisabled={!isUserPermissionWrite}
										/>
									)}
								</div>
							</>
						)}
					</SessionDetailsHeading>
					{!isLoading && isLoaded ? (
						<>
							{modifiedSignAgreementStep?.length > 0 ? (
								<div className="kycDetails-body">
									<DocSlider slides={modifiedSignAgreementStep} />
								</div>
							) : (
								<NoData />
							)}
						</>
					) : (
						<>
							<SessionDetailsSkeletonLoader />
						</>
					)}
				</div>
			</div>
		</div>
	);
};
