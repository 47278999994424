import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { capitalize } from 'utils';
import {
	EdgesState,
	NodesState,
	isComplexViewOnlyState,
} from 'views/complex-onboarding-flow/stores/states';
import { AddConditionButton } from '../add-condition-button';
import { ConnectorThread } from '../connector';
import { IOption } from '../dropdown';
import { QuestionLabel } from '../question-label';
import { AccreditationQuestionWrapper } from './accreditation-question-field';

export interface IRenderQuestion {
	stepId: string;
	nodeId: string;
	blockId: string;
	showAddCondition?: boolean;
}

export const RenderAccreditationQuestion: FC<IRenderQuestion> = ({
	stepId,
	nodeId,
	blockId,
	showAddCondition,
}) => {
	// Global variables
	const [connect, setConnect] = useState('and');
	const [nodes, setNodes] = useRecoilState(NodesState);
	const setEdges = useSetRecoilState(EdgesState);
	const isComplexViewOnly = useRecoilValue(isComplexViewOnlyState);

	const { conditions: accreditation } = useMemo(
		() => nodes.find((el: any) => el.id === nodeId) ?? [],
		[nodes, nodeId]
	);

	const handleChangeSelect = useCallback(
		(val: IOption) => {
			setNodes((prev: any) => {
				const prevObj = JSON.parse(JSON.stringify(prev));
				const findIndex = prevObj.findIndex((el: any) => el.id === nodeId);
				if (findIndex !== -1) {
					const index = prevObj[findIndex].conditions.findIndex(
						(el: any) => el.currentId === nodeId && el.blockId === blockId
					);

					if (index !== -1) {
						const prevValue = prevObj[findIndex].conditions[index][connect];
						const payload = {
							[val.value]: prevValue,
						};
						Object.assign(prevObj[findIndex].conditions[index], payload);
						if (val.value !== connect) {
							delete prevObj[findIndex].conditions[index][connect];
						}
					}
				}
				return prevObj;
			});
			setConnect(val.value);
		},
		[setNodes, nodeId, blockId, connect]
	);

	const addNewQuestion = useCallback(() => {
		setNodes((prev: any) => {
			const prevObj = JSON.parse(JSON.stringify(prev));
			const findIndex = prevObj.findIndex((el: any) => el.id === nodeId);
			if (findIndex !== -1) {
				const index = prevObj[findIndex]?.conditions?.findIndex(
					(el: any) => el.currentId === nodeId && el.blockId === blockId
				);
				if (index !== -1) {
					prevObj[findIndex]?.conditions[index]?.[connect]?.push({});
				}
			}

			return prevObj;
		});
	}, [setNodes, nodeId, blockId, connect]);

	const kycQuestionList = useMemo(
		() =>
			accreditation?.find(
				(el: any) => el.currentId == nodeId && el.blockId === blockId
			)?.[connect] ?? [{}],
		[accreditation, connect, nodeId, blockId]
	);

	const onRemoveBlock = useCallback(
		(blockId: string) => () => {
			setNodes((prev: any) => {
				const prevObj = JSON.parse(JSON.stringify(prev));
				const findIndex = prevObj.findIndex((el: any) => el.id === nodeId);
				if (findIndex !== -1) {
					prevObj[findIndex].conditions = prevObj[
						findIndex
					]?.conditions?.filter((el: any) => el.blockId !== blockId);
				}
				return prevObj;
			});
			setEdges((prev: any) => {
				let prevObj = structuredClone(prev);
				prevObj = prevObj?.filter(
					(el: any) => !(el.source === nodeId && el.source !== el.sourceHandle)
				);
				return prevObj;
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[nodeId]
	);

	useEffect(() => {
		if (!isComplexViewOnly && kycQuestionList?.length === 0) addNewQuestion();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isComplexViewOnly, kycQuestionList?.length]);

	const questionProps = {
		stepId,
		nodeId,
		blockId,
		questionLen: kycQuestionList?.length,
		connect,
	};

	useEffect(() => {
		const data = accreditation?.find(
			(el: any) => el.currentId == nodeId && el.blockId === blockId
		);
		if (Object.keys(data ?? {}).includes('or')) {
			setConnect('or');
		}
	}, [accreditation, blockId, nodeId]);

	const defaultThenValue =
		accreditation?.find(
			(el: any) => el.currentId == nodeId && el.blockId === blockId
		)?.then ?? '';

	return (
		<div className="question-list">
			<QuestionLabel blockId={blockId} onRemoveBlock={onRemoveBlock} />
			{kycQuestionList?.map((_: any, index: number) => {
				return (
					<Fragment key={`condition_${index.toString()}`}>
						<AccreditationQuestionWrapper
							{...questionProps}
							index={index}
							conditionId={`condition_${index}`}
							key={`${_.param}_${_.operand}_${blockId}_${nodeId}`}
							questionData={_}
							defaultThenValue={defaultThenValue}
						/>
						{kycQuestionList?.length !== index + 1 && (
							<ConnectorThread
								handleChangeSelect={handleChangeSelect}
								value={{ label: capitalize(connect), value: connect }}
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								defaultValue={{ label: capitalize(connect), value: connect }}
							/>
						)}
					</Fragment>
				);
			})}
			{showAddCondition && <AddConditionButton onClick={addNewQuestion} />}
		</div>
	);
};
