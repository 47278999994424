import type { IComplexConfig, IOptionValue } from './type';
import { atom } from 'recoil';
import { v4 } from 'uuid';

export const userCapTableState = atom<any>({
	key: 'user-captable-state-key',
	default: null,
});

export const FundsIdState = atom<{
	fundId: string | null;
	hasEscrowLink?: boolean;
}>({
	key: 'funds-state-key',
	default: {
		hasEscrowLink: false,
		fundId: null,
	},
});

export const ConditionalOnboardingFlowState = atom<boolean>({
	key: 'conditional-onboarding-flow-state-key',
	default: false,
});

interface IActions {
	actionId?: string;
	_id?: string;
	metadata?: {
		signAgreementProvider?: string;
	};
}

interface IComplexConfigStep {
	id: string;
	key: string;
	actions?: IActions[];
}

export const ComplexConfigStep = atom<IComplexConfigStep>({
	key: 'complex-config-step',
	default: {
		id: '',
		key: '',
	},
});

export const ComplexSaveState = atom({
	key: v4(),
	default: false,
});

export const ComplexSelectedNode = atom({
	key: 'selected-complex-node',
	default: '',
});

export const ComplexConfigurationInvite = atom<IComplexConfig>({
	key: 'complex-configure-invite',
	default: {
		signAgreement: [],
		fundInvestment: [],
	},
});

/**
 * creating this to store status that weather we are moving with qr flow or linear flow by @Manish
 * */
export const IsQrInviteState = atom<boolean>({
	key: 'is-qr-invite-state-key',
	default: false,
});

export const SignOptionState = atom<IOptionValue>({
	key: 'sign-option-state-key',
	default: 'indivisual',
});


export const ShowSignOptionInQr = atom<boolean>({
	key: "show-sign-option-in-qr",
	default: false
})

export const ShowMultiSignTemplateSelectionScreen = atom<boolean>({
	key: "show-multi-sign-template-selection-option-screen-in-qr",
	default: false
})