import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { API_URL } from 'constant';
import {
	AllConditionalPipelinesState,
	AllPipelinesState,
	IframePropsState,
	IsOpenInIframeState,
	PipelineSettingsFormState,
	SelectedMultipleDateState,
	SelectedOnboardingAction,
	UploadedDocsState,
	isChecqueFraudSelected,
} from 'global-stores';
import { useTrackEvents } from 'helpers';
import { useNetwork, useNotification } from 'hooks';
import { ROUTES } from 'routes';
import {
	ComplexDynamicFormPathsState,
	ComplexDynamicFormStatusState,
	EdgesState,
	NodesState,
} from 'views/complex-onboarding-flow';
import { MESSAGES, WEB_COMPONENT_TYPE } from 'views/onboarding-flow/constants';
import {
	AddedActionsState,
	AddedFileDataState,
	ConfigureSurvayFormState,
	ConfiguredStepState,
	OnboardingFooterNavigateState,
	SelectedRadioState,
	SelectedStepsState,
} from 'views/onboarding-flow/store';
import {
	ComplexSettingFormState,
	ReminderDeadLineState,
	SendNotificationState,
	UnitPriceInputErrorState,
	UnitPriceInputValueState,
	UnitPriceToggleState,
} from 'views/pipeline';
import { ConditionalOnboardingFlowState } from 'views/pipelines/components';
import { useComplexPublishAPI } from './complex-publish-api';
import { usePublishPayload } from './publish-payload';
import { useDashboards } from 'views/dashboard-analytics';

export const usePublish = () => {
	// recoils and global states
	const setPipelines = useSetRecoilState(AllPipelinesState);
	const setUploadedDocs = useSetRecoilState(UploadedDocsState);
	const setNavigate = useSetRecoilState(OnboardingFooterNavigateState);
	const setSelectedSteps = useSetRecoilState(SelectedStepsState);
	const setAddedAction = useSetRecoilState(AddedActionsState);
	const resetAddedFiles = useResetRecoilState(AddedFileDataState);
	const isIframeOpen = useRecoilValue(IsOpenInIframeState);
	const setConfiguredStep = useSetRecoilState(ConfiguredStepState);
	const selectedMultipleDate = useRecoilValue(SelectedMultipleDateState);
	const reminderDayList = useRecoilValue(SendNotificationState);
	const settingForms = useRecoilValue(PipelineSettingsFormState);
	const resetConfigureSurvayFormState = useResetRecoilState(
		ConfigureSurvayFormState
	);
	const selectedOnboardingFlow = useRecoilValue(SelectedOnboardingAction);
	const resetSelectedOnboardingFlow = useResetRecoilState(
		SelectedOnboardingAction
	);
	const resetComplexSettingForm = useResetRecoilState(ComplexSettingFormState);
	const resetSelectedRadioLabel = useResetRecoilState(SelectedRadioState);
	const setNodes = useSetRecoilState(NodesState);
	const setEdges = useSetRecoilState(EdgesState);

	const resetReminderDayList = useResetRecoilState(SendNotificationState);
	const resetSelectedMultipleDate = useResetRecoilState(
		SelectedMultipleDateState
	);
	const setIsConditional = useSetRecoilState(ConditionalOnboardingFlowState);
	const setConditionalPipelines = useSetRecoilState(
		AllConditionalPipelinesState
	);
	const setIsChequeFraudSelected = useSetRecoilState(isChecqueFraudSelected);
	const resetComplexDynamicFormPaths = useResetRecoilState(
		ComplexDynamicFormPathsState
	);
	const resetComplexDynamicFormStatus = useResetRecoilState(
		ComplexDynamicFormStatusState
	);

	// hooks
	const {
		post: createPipeline,
		patch: createModifyPipeline,
		loading: creatingPipeline,
	} = useNetwork();
	const { get: getPipelines } = useNetwork();
	const { successNotification, errorNotification } = useNotification();
	const { getPayload } = usePublishPayload();
	const { trackEvents } = useTrackEvents();
	const { get: getComplexList } = useNetwork();
	const navigate = useNavigate();
	const { getComplexPayload } = useComplexPublishAPI();
	const resetUnitPriceInputError = useResetRecoilState(
		UnitPriceInputErrorState
	);
	const resetUnitPriceToggle = useResetRecoilState(UnitPriceToggleState);
	const resetUnitPriceInputValue = useResetRecoilState(
		UnitPriceInputValueState
	);
	const { getDashboards } = useDashboards();

	// Retrieves webComponent properties from the Recoil state atom or selector `IframePropsState`.
	const webComponentProps = useRecoilValue(IframePropsState);
	// Get the current deadline value
	const { value: deadline = '' } = useRecoilValue(ReminderDeadLineState);

	// Get the function to reset the deadline to its default state
	const resetDeadline = useResetRecoilState(ReminderDeadLineState);

	// local vaiables
	const { PIPELINE } = ROUTES;

	const fetchComplexList = useCallback(async () => {
		const resp = await getComplexList(
			`${API_URL.COMPLEX_FLOW}?isDeleted=false`
		);
		if (resp?.data?.[0]?.result) setConditionalPipelines(resp.data[0].result);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// get the all created onboarding list
	const fetchPipelineLists = useCallback(() => {
		setTimeout(() => {
			getPipelines(API_URL.PIPELINE_USERS).then(resp => {
				try {
					if (resp && resp?.data?.[0]?.result) {
						setPipelines(resp?.data?.[0]?.result);
					}
					// eslint-disable-next-line no-empty
				} catch (error) {
					/* empty */
				}
			});
		}, 1000);
	}, [getPipelines, setPipelines]);

	/*
	 @avinashSatschel
	 # Common success handling method for HTTP requests
     # This method is designed to handle success responses for different types of HTTP requests,
     # including POST for normal operations, PATCH for modifications, and others like cloning.
     # By centralizing success response handling in one method, we ensure consistent and
     # maintainable code, reducing duplication and improving code reusability.
	*/

	const handleSuccess = (response: any) => {
		setConditionalPipelines(pre => [...pre, response]);
		setSelectedSteps([]);
		setAddedAction([]);
		fetchPipelineLists();
		fetchComplexList();
		setConfiguredStep([]);
		resetAddedFiles();
		resetConfigureSurvayFormState();
		resetReminderDayList();
		resetSelectedRadioLabel();
		resetSelectedMultipleDate();
		setNodes([]);
		setEdges([]);
		resetComplexSettingForm();
		resetComplexDynamicFormPaths();
		resetComplexDynamicFormStatus();
		getDashboards();
		resetUnitPriceInputError();
		resetUnitPriceInputValue();
		resetUnitPriceToggle();
		// Reset the ReminderDeadLineState to its default values (clears the selected date and any error messages)
		resetDeadline();

		//@avinashSatschel : need to set false once api is called successfully
		setIsChequeFraudSelected(false);
		if (isIframeOpen) {
			// If the component type is not OnboardingFlow, navigate to the PIPELINE route.
			if (webComponentProps.type !== WEB_COMPONENT_TYPE.OnboardingFlow) {
				navigate(PIPELINE); // Redirects the user to the PIPELINE route if not in the onboarding flow
			}
			setNavigate('complex-flow');
			return;
		} else {
			navigate(PIPELINE);
			setTimeout(() => {
				setNavigate('complex-flow');
			}, 1000);
			return;
		}
	};

	//  create new onboarding flow api call
	const onPublish = useCallback(() => {
		const payload = getPayload();

		const updatedPayload = JSON.parse(JSON.stringify(payload));
		const step = updatedPayload?.steps?.find(
			(stepid: any) => stepid.stepId === 'deliveryMethod'
		);

		if (!step) {
			// eslint-disable-next-line no-console
			console.warn('step is not available');
		}
		const action = step?.actions?.find(
			(reminder: any) =>
				reminder?.actionId === 'reminderSetting' &&
				reminder?.metadata.reminderSettingTrigger.label === 'Custom'
		);
		if (action) {
			// Check if the reminder settings are enabled in the form settings
			if (settingForms['enableReminderSetting']) {
				// Set the reminder trigger value based on whether the reminderDayList is checked
				// If checked, use the selectedMultipleDate or an empty array; otherwise, use reminderDayList.days
				action.metadata.reminderSettingTrigger.value = reminderDayList.checked
					? selectedMultipleDate || []
					: reminderDayList.days || [];
				
				// Assign the selected deadline to the action metadata
				action.metadata.reminderDeadline = deadline;
			} else {
				// If reminder settings are not enabled, clear the reminder trigger and deadline
				action.metadata.reminderSettingTrigger = '';
				action.metadata.reminderDeadline = '';
			}
		}		

		const { type, id, authId } = selectedOnboardingFlow;

		const complexPayload = getComplexPayload(authId);

		const url =
			type === 'modify'
				? `${API_URL.COMPLEX_FLOW}/${id}`
				: API_URL.COMPLEX_FLOW;

		if (type === 'modify') {
			createModifyPipeline(url, { ...complexPayload, type }).then(response => {
				if (response?._id || response.success) {
					resetSelectedOnboardingFlow();
					successNotification(MESSAGES.MODIFY_SUCCESS);
					setIsConditional(true);
					handleSuccess(response);
				} else {
					errorNotification(response?.message ?? MESSAGES.ONBOARDING_NAME);
				}
			});
		} else {
			createPipeline(url, complexPayload).then(response => {
				if (response?._id) {
					successNotification(
						type === 'clone' ? MESSAGES.CLONED_SUCCESS : MESSAGES.SUCCESS
					);
					setIsConditional(true);
					handleSuccess(response);
				}
			});
		}
		setUploadedDocs([]);
		return payload;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		PIPELINE,
		settingForms,
		createPipeline,
		creatingPipeline,
		errorNotification,
		fetchPipelineLists,
		getPayload,
		isIframeOpen,
		navigate,
		resetAddedFiles,
		selectedMultipleDate,
		setAddedAction,
		setConfiguredStep,
		setNavigate,
		setSelectedSteps,
		setUploadedDocs,
		successNotification,
		trackEvents,
		createModifyPipeline,
		handleSuccess,
		selectedOnboardingFlow,
		deadline,
	]);

	return { onPublish, fetchPipelineLists, creatingPipeline };
};
