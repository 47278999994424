import { Image, Skeleton } from '@storybook';
import { getLogos } from 'constant';

export const BankCard = ({ result, loading }: any) => {
	return (
		<div className="BankView">
			{loading ? (
				<Skeleton width={40} height={30} />
			) : (
				<Image
					fileName={getLogos[result?.bankName] ?? getLogos['default']}
					className="payment-save-card-list__card-icon"
				/>
			)}
			<div className="payment-card-detail">
				<div className="payment-save-card-list__number">
					{loading ? <Skeleton width={150} height={14} /> : result?.bankName}
				</div>
				<div className="payment-save-card-list__expire">
					{loading ? (
						<Skeleton width={150} height={14} />
					) : (
						result?.accounts?.[0]?.name ?? '--'
					)}
					(••••{result?.accounts?.[0]?.mask ?? '--'})
				</div>
			</div>
		</div>
	);
};
