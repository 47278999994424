export const kycFeatureTabs = [
	{ label: 'KYC', key: 'kyc' },
	{ label: 'KYC Comprehensive', key: 'kycComprehensive' },
];

export const KYC_SECTION_ICON: { [key: string]: string } = {
	debtors: 'ri-user-fill',
	filings: 'ri-building-fill',
	statusHistory: 'ri-newspaper-fill',
	comments: 'ri-question-answer-fill',
	collaterals: 'ri-user-fill',
	creditors: 'ri-user-fill',
	filingOffices: 'ri-information-fill',
	trustees: 'ri-group-fill',
	Addresses: 'ri-home-3-fill',
	Operator: 'ri-car-fill',
	fileError: 'ri-file-damage-line',
	driverLicenses: 'ri-file-copy-fill',
	peopleAtWorks: 'ri-briefcase-fill',
	weaponPermits: 'ri-file-copy-fill',
	voterRegistration: 'ri-file-copy-fill',
	convictions: 'ri-file-copy-fill',
	registration: 'ri-file-copy-fill',
	otherInformation: 'ri-file-copy-fill',
	waterCrafts: 'ri-ship-2-fill',
	faaPilots: 'ri-plane-line',
	faaAircrafts: 'ri-plane-line',
	vehicleRegistration: 'ri-car-fill',
	criminalRecords: 'ri-file-copy-fill',
};

export const KYC_USER_INFORMATION_ICON: { [key: string]: string } = {
	driverLicenses: 'ri-user-fill',
	professionalLicenses: 'ri-user-fill',
	peopleAtWorks: 'ri-user-fill',
	weaponPermits: 'ri-user-fill',
	voterRegistration: 'ri-user-fill',
};

export const MetricsHeading: { [key: string]: string } = {
	driverLicenses: 'License Info',
	peopleAtWorks: 'Information',
	professionalLicenses: 'License Type',
	weaponPermits: 'Permit',
	voterRegistration: 'Voter Registration Info',
};

export const KycKeys: { [key: string]: string } = {
	addresses: 'Addresses',
	originName: 'Origin name',
	type: 'Type',
	filings: 'Filings',
	date: 'Date',
	collaterals: 'Collaterals',
	description: 'Description',
	filingOffices: 'Filing Offices',
	filingAgency: 'Filing Agency',
	originFilingDate: 'Origin filing date',
	originFilingNumber: 'origin filing number',
	filingJurisdiction: 'Filing jurisdiction',
	filingJurisdictionName: 'Filing jurisdiction name',
	debtors: 'Debtors',
	agencyState: 'Agency state',
	number: 'Number',
	agency: 'Agency',
	agencyCounty: 'Agencycounty',
	multipleDefendant: 'Multiple defendant',
	defendantId: 'Defendant Id',
	recordId: 'Record Id',
	originalCounty: 'Original County',
	uniqueId: 'Unique Id',
	defendantType: 'Defendant Type',
	phones: 'Phone',
	selfRepresented: 'Selfrepresented',
	corpFlag: 'Corp Flag',
	filerType: 'Filer type',
	caseType: 'Case type',
	disposition: 'Disposition',
	filingDate: 'Filing date',
	transferIn: 'Transfer In',
	assetsForUnsecured: 'Assets for unsecured',
	judgeIdentification: 'Judge identification',
	chapter: 'Chapter',
	courtName: 'Court name',
	originalChapter: 'Original chapter',
	courtLocation: 'Court location',
	originalFilingDate: 'Original filing date',
	courtCode: 'Court code',
	dischargeDate: 'Discharge date',
	driverLicense: 'Driver license',
	originStateName: 'origin state name',
	nonDmvSource: 'Non Dmv source',
	age: 'Age',
	birthDate: 'Birth date',
	convictions: 'Convictions',
	otherInformation: 'Other Information',
	sex: 'Sex',
	originState: 'Origin state',
	activeOrInactive: 'Active or inactive',
	dateFirstSeen: 'Date first seen',
	history: 'History',
	vehicleRecordId: 'Vehicle Record Id',
	yearMake: 'Year Make',
	vin: 'Vin',
	comments: 'Comments',
	series: 'Series',
	stateOfOrigin: 'State of origin',
	style: 'Style',
	numberOfAxles: 'Number of axles',
	netWeight: 'Net weight',
	make: 'Make',
	model: 'Model',
	statusHistory: 'History Status',
	trustees: 'Trustees',
	names: 'Names',
	sourceState: 'Source state',
	licenseNumber: 'License number',
	professionOrBoard: 'Profession or board',
	dateLastSeen: 'Date last seen',
	licenseType: 'License type',
	issuedDate: 'Issued date',
	lastRenewalDate: 'Last renewal date',
	expirationDate: 'Expiration date',
	originalAddress: 'Original address',
	activeOrInactiveFlag: 'Active or inactive flag',
	registrateStateName: 'Registrate state name',
	ageCategory: 'Age category',
	race: 'Race',
	politicalParty: 'Political party',
	gender: 'Gender',
	residentAddress: 'Resident address',
	ageRange: 'Age range',
	registrateState: 'Registrate state',
	voterRecordId: 'Voter record Id',
	registrationDate: 'Registration date',
	fdnDidInd: 'DdnDidInd',
	confidence: 'Confidence',
	dateLastCohabit: 'Date last cohabit',
	offenseCodeOrStatute: 'Offense code or statute',
	convictionJurisdiction: 'Conviction jurisdiction',
	offenseDescription: 'Offense description',
	sentenceDescription: 'Sentence description',
	address1: 'Address 1',
	address2: 'Address 2',
	date1: 'date 1',
	date2: 'date 2',
	date3: 'date 3',
	date2Type: 'Date 2 type',
	date3Type: 'Date 3 type',
	offences: 'Offences',
	dispositionDate: 'DispositionDate',
	permit: 'Permit',
	permitType: 'Permit type',
	homeState: 'Home state',
	licenseState: 'License state',
	status: 'Status',
	aircraftNumber: 'Aircraft number',
	lastActionDate: 'Last action date',
	certificationDate: 'Certification date',
	class: 'Class',
	transactionType: 'Transaction type',
	issueDate: 'Issue date',
	waterCraftKey: 'Water craft key',
	owners: 'Owners',
	address: 'Address',
	nameType: 'Name type',
	lienHolders: 'Lien holders',
	registration: 'Registration',
	decalNumber: 'Decal number',
	originalName: 'Original name',
	stateOriginName: 'State origin name',
	stateOrigin: 'State origin',
	mailingAddress : 'Mailing address',
	registrateDate: 'Registrate date',
	use: 'Use',
	hullNumber: 'Hull number',
	propulsion: 'Propulsion',
	hullType: 'hull type',
	fuel: 'Fuel',
	modelYear: 'Model year',
	vesselType: 'Vessel type',
	recordTypeDesc: 'Record type desc',
	recordType: 'Record type',
	sourcePropertyRecordId: 'Source property record Id',
	entities: 'Entities',
	recorderPageNumber: 'Recorder page number',
	recorderBookNumber: 'Recorder book number',
	entityType: 'Entity type',
	geoLocation: 'Geo location',
	entityTypeCode: 'Entity type code',
	originalNames: 'Original names',
	deed: 'Deed',
	creditors: 'Creditors',
	documentTypeDesc: 'Document type description',
	parcelId: 'Parcel Id',
	salesPrice: 'Sales price',
	propertyUseDesc: 'Property use description',
	lenderInfo: 'Lender info',
	deedSourcePropertyRecord: 'Deed source property record',
	mortgageTermCodeDesc: 'Mortgage term code description',
	mortgageTerm: 'Mortgage term',
	mortgageDeedType: 'Mortgage deed type',
	mortgageTermCode: 'Mortgage term code',
	transactionTypeDesc: 'Transaction type description',
	mortgageDeedTypeDesc: 'Mortgage deed type description',
	contractDate: 'Contract date',
	firstTdLoanAmount: 'First Td Loan amount',
	recordingDate: 'Recording date',
	county: 'County',
	propertyUseCode: 'Property use code',
	documentTypeCode: 'Document type code',
	firstTdLoanType: 'First Td loan type',
	firstTdDueDate: 'First Td due date',
	documentNumber: 'Document number',
	titleCompany: 'Title company',
	assessment: 'Assessment',
	assessedTotalValue: 'Assessed total value',
	mortgageLender: 'Mortgage lender',
	garageType: 'Garage type',
	legalInfo: 'Legal Information',
	briefDescription: 'Brief description',
	lotNumber: 'Lot number',
	subdivision: 'Sub division',
	noOfStories: 'No of stories',
	saleDate: 'Sale date',
	landUseCode: 'Land use code',
	landUse: 'Land use',
	yearBuilt: 'Year built',
	exteriorWalls: 'Exterior walls',
	assessedImprovementValue: 'Assessed improvement value',
	landSquareFootage: 'Land square footage',
	mortgageLoanType: 'Mortgage loan type',
	noOfBaths: 'No of baths',
	marketLandValue: 'Market land value',
	fipsCode: 'Fips code',
	priorRecordingDate: 'Prior recording date',
	assesseeRelationship: 'Assessee relationship',
	stateCode: 'State code',
	noOfBedrooms: 'No of bedrooms',
	sourcePropertyRecord: 'Source property record',
	livingSquareFeet: 'Living square feet',
	airConditioning: 'Air conditioning',
	mortgageLoanAmount: 'Mortgage loan amount',
	marketTotalValue: 'Market total value',
	heating: 'Heating',
	taxAmount: 'Tax amount',
	taxYear: 'Tax year',
	marketImprovementValue: 'Market improvement value',
	companyTimeZone: 'Company time zone',
	confidenceLevel: 'Confidence level',
	companyName: 'Company name',
	latestEffectiveDate: 'Latest effective date',
	licensePlateTypeDesc: 'License plate type description',
	latestExpirationDate: 'Latest expiration date',
	previousLicensePlate: 'Previous license plate',
	previousLicenseState: 'Previous license state',
	earliestEffectiveDate: 'Earliest effective date',
	trueLicensePlate: 'True license plate',
	licensePlateTypeCode: 'License plate type code',
	firstDate: 'First date',
	powerSteering: 'Power steering',
	fourWheelDrive: 'Four wheel drive',
	optionalRadio2: 'Optional radio 2',
	optionalRadio1: 'Optional radio 1',
	seriesName: 'Series name',
	tiltWheel: 'Tilt wheel',
	securitySystem: 'Security system',
	radio: 'Radio',
	numberOfCylinders: 'Number of cylinders',
	year: 'Year',
	transmission: 'Transmission',
	frontWheelDrive: 'Front wheel drive',
	powerBrakes: 'Power brakes',
	antiLockBrakes: 'Anti-lock brakes',
	powerWindows: 'Power windows',
	roof: 'Roof',
	engineSize: 'Engine size',
	daytimeRunningLights: 'Daytime running lights',
	price: 'Price',
	lesses: 'Lesses',
	vinaData: 'Vina data',
	appendSsn: 'SSN',
	name: 'Name',
	lessesInfo: 'Lesses Information',
	historyDescription: 'History description',
	registrants: 'Registrants',
	registrationInfo: 'Registration Information',
	registrantInfo: 'Registrant Information',
	driverLicenseNumber: 'Driver License Number',
	earliestIssueDate: 'Earliest issue date',
	latestIssueDate: 'Latest issue date',
	Number: 'Number',
	previousIssueDate: 'Previous issue date',
	ownerInfo: 'Owner Information',
	titleInfo: 'Title Information',
	aircraftInfo: 'Aircraft Information',
	weight: 'Weight',
	engines: 'Engines',
	seats: 'Seats',
	manufacturerName: 'Manufacturer name',
	category: 'Category',
	aircraftType: 'Aircraft type',
	modelName: 'Model name',
	engineInfo: 'Engine Information',
	fuelConsumption: 'Fuel consumption',
	horsepower: 'Horsepower',
	'Model name': 'Model name',
	'Manufacturer name': 'Manufacturer name',
	registrant: 'Registrant',
	residents: 'Residents',
	stateName: 'State name',
	certificates: 'Certificates',
	typeDesc: 'Type description',
	levelDesc: 'Level description',
	DispositionDate: 'Disposition date',
	Disposition: 'Disposition',
	offense: 'Offense',
	caseNumber: 'Case number',
	offenseDate: 'Offense date',
	lastVoteDate: 'Last vote date',
};
