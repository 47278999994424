import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { DashboardsAtom } from 'views/dashboard-analytics/stores';
import { AnalyticsOverview } from '../analyticsOverview';
import { CardKey, DashboardCard } from '../dashboard-card';
import { StackedColumnChart } from '../dashboard-chart';
import { OnboardingFlowAnalytics } from '../onboardingFlowAnalytics';
import './dashboard-body.scss';

export const DashboardBody = () => {
	const dashboardData = useRecoilValue(DashboardsAtom);

	const { data, loaded } = useMemo(() => dashboardData, [dashboardData]);
	const { ONBOARDING, SESSION } = CardKey;

	const renderCards = useMemo(() => {
		const { summary } = data;
		return (
			summary &&
			Object.keys(summary)?.map(key => {
				return (
					key !== ONBOARDING && (
						<div
							key={key}
							className={`dashboard-outer ${
								key === SESSION ? 'dashboard-outer-session' : ''
							}`}
						>
							<DashboardCard
								cardKey={key}
								cardData={summary[key]}
								loading={loaded}
							/>
						</div>
					)
				);
			})
		);
	}, [ONBOARDING, SESSION, data, loaded]);

	const renderOnboarding = useMemo(() => {
		const { summary } = data;
		return (
			summary &&
			Object.keys(summary)?.map(key => {
				return (
					key === ONBOARDING && (
						<div key={key}>
							<DashboardCard
								cardKey={key}
								cardData={summary[key]}
								loading={loaded}
							/>
						</div>
					)
				);
			})
		);
	}, [ONBOARDING, data, loaded]);

	const renderChart = useMemo(() => {
		const { chartData } = data;
		return (
			<StackedColumnChart
				isLoading={loaded}
				title="Session Distribution"
				XAxis={chartData?.XAxis ?? []}
				seriesData={chartData?.data ?? []}
			/>
		);
	}, [data, loaded]);

	return (
		<div className="dashboard-body-wrapper">
			<div className="dashboard-body__onboarding-wrapper">
				{renderOnboarding}
			</div>

			<div className="dashboard-body__card-wrapper">{renderCards}</div>

			<div className="dashboard-body__chart-section">
				<div className="dashboard-body__chart-wrapper hover">{renderChart}</div>

				<div className="dashboard-body__chart-wrapper hover">
					<AnalyticsOverview />
				</div>
			</div>
			<div className="dashboard-body__chart-wrapper dashboard-analytics-wrapper hover ">
				<OnboardingFlowAnalytics />
			</div>
		</div>
	);
};
