import { FC, useState } from 'react';

import { UserFaceMatch } from './user-face-match';

interface IUserImage {
	image: string;
	simpliciMatchPercentage: string;
}

export const UserImage: FC<IUserImage> = ({
	image,
	simpliciMatchPercentage,
}) => {
	const [isError, setIsError] = useState(false);

	const simpliciMatchValue = parseInt(simpliciMatchPercentage ?? 0, 10);

	return (
		<div className="kyc-user-profile">
			{!image || isError ? (
				<></>
			) : (
				<div className='kyc-user-profile__container'>
					<img
						src={image}
						alt=""
						className="kyc-user-profile__profile-image"
						onError={() => {
							setIsError(true);
						}}
					/>
					<UserFaceMatch
						title="Simplici"
						matchPercent={
							simpliciMatchValue
						}
						matchChartColor="#0051CC"
						unMatchChartColor="#0051CC29"
						id={image}
					/>
				</div>
			)}
		</div>
	);
};
