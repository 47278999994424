import { FC, ReactNode, useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { CurrentStepState } from 'views/onboarding-flow';
import { ComplexSettingFormState } from 'views/pipeline';
import { ComplexConfigureFormModal } from '../complex-survey-form';
import { CustomHandle } from '../custom-handle';
import { ICustomNode } from '../custom-node';
import { QuestionnaireChoices } from './componenets';
import { useFormAction } from './hooks';
import './form-action.scss';

const FormProvider = ({
	children,
	handleAdd,
	nodeId,
}: {
	children: ReactNode;
	handleAdd: () => void;
	nodeId: string;
}) => {
	return (
		<>
			{children}
			<QuestionnaireChoices nodeId={nodeId} handleAdd={handleAdd} />
		</>
	);
};

export const FormAction: FC<ICustomNode> = ({ id: nodeId, data }) => {
	const setCurrentStep = useSetRecoilState(CurrentStepState);
	const complexSettingForm = useRecoilValue(ComplexSettingFormState);

	const { addNewValue, isConfigFormModal, setIsConfigFormModal } =
		useFormAction({ nodeId, label: data.label });

	const handleAdd = useCallback(() => {
		setCurrentStep(pre => ({ ...pre, id: nodeId }));
		setIsConfigFormModal(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [complexSettingForm, nodeId]);

	return (
		<FormProvider handleAdd={handleAdd} nodeId={nodeId}>
			{/* <div className="form-action">{renderActionLayout}</div> */}
			<CustomHandle value={nodeId} />
			{isConfigFormModal && (
				<ComplexConfigureFormModal
					setIsFormOpen={setIsConfigFormModal}
					addNewValue={addNewValue}
					isOpen={isConfigFormModal}
					nodeId={nodeId}
					stepId={data.label}
				/>
			)}
		</FormProvider>
	);
};
