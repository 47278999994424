import type { IInputChange } from 'types';

import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Input } from '@storybook';
import {
	CreateSignState,
	IsFontsLoadedState,
	useCreateSign,
	useSetCreateSign,
} from '../store';

export const SignerName = () => {
	const [isError, setIsError] = useState({
		fullName: false,
		initials: false,
	});
	const { fullName, initials } = useRecoilValue(CreateSignState);
	const { fetchSignFonts } = useSetCreateSign();
	useEffect(() => {
		fetchSignFonts(fullName, initials);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const { handleChangeCreateSign } = useCreateSign();
	const setFontsLoaded = useSetRecoilState(IsFontsLoadedState);
	const handleBlur = useCallback((name: string, value: string) => {
		setIsError(prev => ({
			...prev,
			[name]: !value.trim(),
		}));
	}, []);

	const handleOnChange = useCallback(
		(name: string, value: string) => {
			const isInitial = name === 'initials';
			if (isInitial && value.length > 3) {
				setIsError({ ...isError, initials: true });
				return;
			}
			setFontsLoaded(false);
			if (isError.initials) setIsError({ ...isError, initials: false });

			handleChangeCreateSign(name, isInitial ? value.toUpperCase() : value);
			setIsError(prev => ({ ...prev, [name]: false }));
		},
		[handleChangeCreateSign, isError, setFontsLoaded]
	);

	return (
		<div className="admin-create-sign-name">
			<div className="admin-create-sign-name__full-name">
				<Input
					label="Full Name *"
					handleChange={(e: IInputChange) => {
						handleOnChange('fullName', e.target.value);
					}}
					placeholder="Enter full Name"
					isError={isError.fullName}
					inputType="text"
					errorMessage=""
					value={fullName}
					disabled={true}
					maxLength={20}
					handleBlur={() => handleBlur('fullName', fullName)}
				/>
			</div>
			<div className="admin-create-sign-name__initials">
				<Input
					label="Initial *"
					handleChange={(e: IInputChange) => {
						handleOnChange('initials', e.target.value);
					}}
					value={initials.toUpperCase()}
					isError={isError.initials}
					errorMessage="Max length can be 3"
					placeholder="Enter initials"
					inputType="text"
					handleBlur={() => handleBlur('initials', initials)}
					disabled={true}
				/>
			</div>
		</div>
	);
};
