import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Image } from '@storybook';
import { ComplexSessionInfoState } from 'global-stores';
import { ComprehensiveHeader } from '../kyb-wrapper/components/kyb-comprehensive/components/comprehensive-header';

type Label = {
	name: string;
	confidence: number;
	count?: number;
};

export const FacialInformationSection: FC = () => {
	const complexSessionInfo = useRecoilValue(ComplexSessionInfoState);
	const [isSelfieError, setSelfieError] = useState(false);
	const [isVideoError, setVideoError] = useState(false);
	const [showAllobjects, setShowAllobjects] = useState(false);

	const {
		selfieImage,
		plamVideoURL,
		blobVideoUrl = '',
		faceVideoURL = '',
		facebiometric_information,
	} = useMemo(
		() =>
			complexSessionInfo?.nodes?.find(
				(el: Record<string, any>) => el?.stepId === 'authentication'
			)?.action?.data ?? {},
		[complexSessionInfo.nodes]
	);

	useEffect(() => {
		return () => {
			setSelfieError(false);
			setVideoError(false);
		};
	}, []);

	const filteredLabels = useMemo(() => {
		// If labels is not an array, return an empty array as a safeguard
		if (!Array.isArray(facebiometric_information?.labels)) {
			return [];
		}
	
		// If the length of labels is less than or equal to 8, return all labels
		if (facebiometric_information?.labels?.length <= 8) {
			return facebiometric_information?.labels;
		}
	
		// Return all filtered labels if `showAllobjects` is true, otherwise limit to 8
		return showAllobjects ? facebiometric_information?.labels : facebiometric_information?.labels?.slice(0, 8);
	}, [facebiometric_information?.labels, showAllobjects]);

	const handleSelfieError = useCallback(() => {
		setSelfieError(true);
	}, []);

	const handleVideoError = useCallback(() => {
		setVideoError(true);
	}, []);

	const handleObjectShowwAll = useCallback(() => {
		setShowAllobjects(prev => !prev);
	}, []);

	return (
		<>
			{(selfieImage || faceVideoURL || blobVideoUrl) && (
				<div className="facial__wrapper">
					<div className="facial__heading--wrapper">
						<div className="facial__heading--wrapper__icon-box">
							<i className="ri-fingerprint-line"></i>
						</div>
						<div className="facial__heading--wrapper__label">
							Biometric Information
						</div>
					</div>
					{/* Image and video section */}
					<div className="facial__user-face--wrapper">
						{(faceVideoURL || blobVideoUrl) && (
							<>
								{!isVideoError && (
									<div className="auth-selfie-image">
										{faceVideoURL?.trim() !== '' && faceVideoURL !== null ? (
											<div className="auth-selfie-image__user-video-container">
												<video
													className="auth-selfie-image__user-video"
													id="videoElement"
													muted
													loop
													controls
												>
													<source src={faceVideoURL} type="video/mp4" />
													<source src={faceVideoURL} type="video/webm" />
												</video>
											</div>
										) : blobVideoUrl?.trim() !== '' && blobVideoUrl !== null ? (
											<div className="auth-selfie-image__user-video-container">
												<video
													className="auth-selfie-image__user-video"
													id="videoElement"
													muted
													loop
													controls
													onError={handleVideoError}
												>
													<source src={blobVideoUrl} type="video/mp4" />
													<source src={blobVideoUrl} type="video/webm" />
												</video>
											</div>
										) : (
											<></>
										)}
									</div>
								)}
							</>
						)}
						{!isSelfieError && (
							<div className="auth-selfie-image">
								<div className="auth-selfie-image__user-video-container ">
									<Image onError={handleSelfieError} url={selfieImage} />
								</div>
							</div>
						)}
					</div>

					{/* Palm section */}
					{plamVideoURL && blobVideoUrl?.trim() !== '' && (
						<div className="palm-section">
							<div className="auth-selfie-image__header">
								<div className="auth-selfie-image__header__title">
									Palm Print
								</div>
								<div className="auth-selfie-image__header__sub-title"></div>
							</div>
							<div className="auth-selfie-image__palm-video-container">
								<video
									className="auth-selfie-image__user-video"
									id="videoElement"
									muted
									loop
								>
									<source src={plamVideoURL} type="video/mp4" />
									<source src={plamVideoURL} type="video/webm" />
								</video>
							</div>
						</div>
					)}

					{/* Livevess Section */}
					{typeof facebiometric_information?.liveness_detected ===
						'boolean' && (
						<>
							<ComprehensiveHeader label={'Liveness'} />
							<div className="facial__body--wrapper">
								<div className="facial__sppof--row">
									<div
										className={`facial__sppof--row__icon ${
											!facebiometric_information?.liveness_detected
												? 'facial__failed'
												: 'facial__success'
										}`}
									>
										<i className={'ri-qr-scan-2-line'}></i>
									</div>
									<div className="facial__sppof--row__label--wrapper">
										<div className="facial__sppof--row__label">
											{!facebiometric_information?.liveness_detected
												? 'Liveness check failed.'
												: 'Liveness Verified.'}
										</div>
									</div>
								</div>
							</div>
						</>
					)}

					{/* Object Detection section */}
					{facebiometric_information?.labels?.length > 0 && (
						<>
							<ComprehensiveHeader label={'Object Detection'} />
							<div className="facial__body--wrapper">
								<div className="facial__sppof--row">
									<div className={`facial__sppof--row__icon facial__labels`}>
										<i className={'ri-error-warning-line'}></i>
									</div>
									<div className="facial__sppof--row__label--wrapper">
										<div className="facial__objects--heading-wrapper">
											<div className="facial__sppof--row__label">
												Object detected in scene
											</div>
											{facebiometric_information?.labels?.length > 8 && (
												<div
													onClick={handleObjectShowwAll}
													className="facial__object--show-all"
												>
													{!showAllobjects ? (
														<i className="ri-arrow-drop-down-line"></i>
													) : (
														<i className="ri-arrow-drop-up-line"></i>
													)}
												</div>
											)}
										</div>
										<div className="facial__object-detection-list--wrapper">
											{(filteredLabels ?? []).map((obj: Label) => (
												<div
													key={obj?.name} // Use a unique id if available, avoid using the index
													className={`facial__object-detection-list--item ${
														obj?.confidence > 75
															? 'green-dot'
															: obj?.confidence > 60
															? 'yellow-dot'
															: 'gray-dot'
													}`}
												>
													{obj?.name} {Math.floor(obj?.confidence ?? 0)}%
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			)}
		</>
	);
};
