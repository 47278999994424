import { API_URL } from 'constant';
import { useNetwork } from 'hooks';
import { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { SelectedSessionState } from 'global-stores';

export const useSessionDetails = () => {
	const [isLoaded, setIsLoaded] = useState(true);
	const [isLoading , setIsLoading] = useState(false);

	const { get } = useNetwork({ updateState: false, returnResponse: true });
	const setSessionSelected = useSetRecoilState(SelectedSessionState);

	const fetchActivity = async (id: string) => {
		setIsLoading(true)
		setIsLoaded(false);
		const resp = await get(
			`${API_URL.COMPLEX_SESSION_INFO}/${id}?include=activities`
		);

		const { response, apiPayload } = resp;
		if (response?.status === 200) {
			const { data } = apiPayload;
			setSessionSelected((prev: Record<string, never> | any) => ({
				...prev,
				...data,
			}));
		}
		setIsLoading(false);
		setIsLoaded(true);
	};

	const fetchDeviceInfo = async (id: string) => {
		setIsLoading(true);
		setIsLoaded(false);
		const resp = await get(
			`${API_URL.COMPLEX_SESSION_INFO}/${id}?include=deviceInfo`
		);
		const { response, apiPayload } = resp;
		if (response?.status === 200) {
			const { data } = apiPayload;
			setSessionSelected((prev) => ({...prev, ...data}))
		}
		setIsLoading(false);
		setIsLoaded(true);
	};

	const fetchKyc = async (id: string) => {
		setIsLoading(true);
		setIsLoaded(false);
		const resp = await get(`${API_URL.COMPLEX_SESSION_INFO}/${id}?include=kyc`);
		const { response, apiPayload } = resp;
		if (response?.status === 200) {
			const { data } = apiPayload;
			setSessionSelected((prev: Record<string, never> | any) => ({
				...prev,
				kyc: data?.kyc ?? [],
			}));
		}
		setIsLoading(false);
		setIsLoaded(true);
	};

	const fetchKyb = async (id: string) => {
		setIsLoading(true);
		setIsLoaded(false);
		const resp = await get(`${API_URL.COMPLEX_SESSION_INFO}/${id}?include=kyb`);
		const { response, apiPayload } = resp;
		if (response?.status === 200) {
			const { data } = apiPayload;
			setSessionSelected((prev: Record<string, never> | any) => ({
				...prev,
				...data,
			}));
		}
		setIsLoading(false);
		setIsLoaded(true);
	};

	const fetchAml = async (id: string) => {
		setIsLoading(true);
		setIsLoaded(false);
		const resp = await get(`${API_URL.COMPLEX_SESSION_INFO}/${id}?include=aml`);
		const { response, apiPayload } = resp;
		if (response?.status === 200) {
			const { data } = apiPayload;
			setSessionSelected((prev: Record<string, never> | any) => ({
				...prev,
				...data,
			}));
		}
		setIsLoading(false);
		setIsLoaded(true);
	};

	const fetchSignAgreement = async (id: string) => {
		setIsLoading(true);
		setIsLoaded(false);
		const resp = await get(
			`${API_URL.COMPLEX_SESSION_INFO}/${id}?include=signAgreement`
		);
		const { response, apiPayload } = resp;
		if (response?.status === 200) {
			const { data } = apiPayload;
			setSessionSelected((prev: Record<string, never> | any) => ({
				...prev,
				...data,
			}));
		}
		setIsLoading(false);
		setIsLoaded(true);
	};

	const fetchAccreditation = async (id: string) => {
		setIsLoading(true);
		setIsLoaded(false);
		const resp = await get(
			`${API_URL.COMPLEX_SESSION_INFO}/${id}?include=accreditation`
		);
		const { response, apiPayload } = resp;
		if (response?.status === 200) {
			const { data } = apiPayload;
			setSessionSelected((prev: Record<string, never> | any) => ({
				...prev,
				...data,
			}));
		}
		setIsLoading(false);
		setIsLoaded(true);
	};

	const fetchFundInvestment = async (id: string) => {
		setIsLoading(true);
		setIsLoaded(false);
		const resp = await get(
			`${API_URL.COMPLEX_SESSION_INFO}/${id}?include=fundInvestment`
		);
		const { response, apiPayload } = resp;
		if (response?.status === 200) {
			const { data } = apiPayload;
			setSessionSelected((prev: Record<string, never> | any) => ({
				...prev,
				...data,
			}));
		}
		setIsLoading(false);
		setIsLoaded(true);
	};

	const fetchForm = async (id: string) => {
		setIsLoading(true);
		setIsLoaded(false);
		const resp = await get(
			`${API_URL.COMPLEX_SESSION_INFO}/${id}?include=form`
		);
		const { response, apiPayload } = resp;
		if (response?.status === 200) {
			const { data } = apiPayload;
			setSessionSelected((prev: Record<string, never> | any) => ({
				...prev,
				...data,
			}));
		}
		setIsLoading(false);
		setIsLoaded(true);
	};

	const fetchTransactionSession = async (id: string) => {
		setIsLoading(true);
		setIsLoaded(false);
		const resp = await get(
			`${API_URL.SESSION_DETAIL}/${id}`
		);
		const { response, apiPayload } = resp;
		if (response?.status === 200) {
			const { data } = apiPayload;
			setSessionSelected((prev: Record<string, never> | any) => ({
				...prev,
				...data,
			}));
		}
		setIsLoading(false);
		setIsLoaded(true);
	}

	const fetchProofReading = async (id: string) => {
		setIsLoading(true);
		setIsLoaded(false);
		const resp = await get(
			`${API_URL.COMPLEX_SESSION_INFO}/${id}?include=proofReading`
		);
		const { response, apiPayload } = resp;
		if (response?.status === 200) {
			const { data } = apiPayload;
			setSessionSelected((prev: Record<string, never> | any) => ({
				...prev,
				...data,
			}));
		}
		setIsLoading(false);
		setIsLoaded(true);
	};

	return {
		fetchActivity,
		fetchDeviceInfo,
		fetchKyc,
		fetchAml,
		fetchKyb,
		fetchSignAgreement,
		fetchFundInvestment,
		fetchAccreditation,
		fetchForm,
		fetchProofReading,
		fetchTransactionSession,
		isLoaded,
		isLoading
	};
};
