import { useCallback, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { useNetwork } from 'hooks';
import {
	IsKycLoading,
	kycSettingsState,
	whiteLableLogoState,
} from 'global-stores';
import { API_URL } from 'constant';

export const useGeneralSettings = () => {
	//globle states
	const [SettingsKyc, setKycSettings] = useRecoilState(kycSettingsState);
	const setIsLogoLoaded = useSetRecoilState(IsKycLoading);
	const setIsLogoLoading = useSetRecoilState(whiteLableLogoState);

	//local state
	const [settingsLoaded, setSettingsLoaded] = useState(true);

	// hooks
	const { get: getSettings } = useNetwork();

	const getClientDetails = useCallback(() => {
		if (SettingsKyc?._id?.length === 0) {
			setSettingsLoaded(false);
			setIsLogoLoading(true);
			getSettings(`${API_URL.CLIENT_DETAILS}`)
				.then(res => {
					if (res?.message === 'ok') {
						const {
							name,
							logo,
							whitelistedDomains,
							_id,
							settings,
							whitelabel,
							whitelistConfig,
							whitelabelKey,
							showPricing,
							loaderWhitelabel,
							loader,
						} = res?.data?.[0] ?? {};

						if (name !== '') setKycSettings(prev => ({ ...prev, name }));
						if (logo !== '') setKycSettings(prev => ({ ...prev, logo }));
						if (whitelistedDomains)
							setKycSettings(prev => ({ ...prev, whitelistedDomains }));
						if (_id !== '') setKycSettings(prev => ({ ...prev, _id }));
						if (settings) setKycSettings(prev => ({ ...prev, settings }));
						if (whitelabel) setKycSettings(prev => ({ ...prev, whitelabel }));
						if (whitelabelKey)
							setKycSettings(prev => ({ ...prev, whitelabelKey }));
						if (whitelistConfig)
							setKycSettings(prev => ({ ...prev, whitelistConfig }));
						setKycSettings(prev => ({
							...prev,
							showPricing,
							loaderWhitelabel: !!loaderWhitelabel,
							loader,
						}));
						setIsLogoLoaded(false);
						const { brandColor, bodyFont } = settings ?? {};
						localStorage.setItem('theme-color', brandColor ?? '#0051cc');
						localStorage.setItem('theme-font', bodyFont?.family ?? 'Poppins');
						setIsLogoLoading(false);
					}
				})
				.finally(() => {
					setSettingsLoaded(true);
					setIsLogoLoaded(false);
				});
		}
		// eslint-disable-next-line
	}, []);
	return { getClientDetails,settingsLoaded};
};
