export const REJECTED_CONSTANTS = {
	KYC_FAILED: 'Verification failed',
	KYB_FAILED: 'Verification failed',
	KYC_AND_KYB_FAILED: 'Verification failed',
	FAIL_MESSAGE: 'We regret to inform you that your verification has failed',
	REINITIATE_KYC: 'Reinitiate Verification',
	REINITIATE_KYB: 'Reinitiate Verification',
	REINITIATE: 'Reinitiate Verification',
	VIEW_REPORT: 'View report',
	KYC: 'kyc',
	KYB: 'kyb',
	INDIVIDUAL: 'Identity verification failed',
	ENTITY: 'Business verification failed',
};
