import { atom } from 'recoil';
import { CANCEL_SUBSCRIPTION_STEPS } from '../constants';

// Interface for the subscription cancellation response
interface ICancelSubscriptionResponseStage {
	cancelledAt?: string; // Timestamp of when the subscription was canceled
}

// Tracks the active step in the cancellation workflow
export const CancelSubscriptionActiveStepState = atom<CANCEL_SUBSCRIPTION_STEPS>({
	key: 'cancel-subscription-active-step-state-key',
	default: CANCEL_SUBSCRIPTION_STEPS.DEFAULT, // Default step
});

// Holds the outstanding due amount before cancellation
export const CancelSubscriptionDueAmountState = atom<number>({
	key: 'cancel-subscription-due-amount-state-key',
	default: 0, // Default due amount is 0
});

// Stores the response details of the cancellation process
export const CancelSubscriptionResponseState = atom<ICancelSubscriptionResponseStage>({
	key: 'cancel-subscription-response-stage-key',
	default: {}, // Initially empty
});

// Stores user input or comments related to the cancellation
export const CancelSubscriptionTextState = atom<string>({
	key: 'cancel-subscription-text-state-key',
	default: '', // Default to an empty string
});
