import { Image, Loader } from '@storybook';
import { FC, Fragment, useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { ISignedDocInbox } from 'views/signed-doc-inbox';
import { PacketNotaryDetails, useSignDocHistory } from '../stores';

interface IProps {
	inbox: ISignedDocInbox;
}

export const NotaryDetails: FC<IProps> = ({ inbox }) => {
	const { fetchNotaryDetails, isLoaded } = useSignDocHistory();
	const notaryDetails = useRecoilValue(PacketNotaryDetails);
	const { _id: envelopeId, notary } = inbox;

	useEffect(() => {
		if (notary) {
			fetchNotaryDetails(envelopeId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const renderNotaryDetails = useMemo(() => {
		const isNotaryAvailable = !!Object.keys(notaryDetails).length;
		if (!isLoaded) {
			return (
				<div className="details-container__empty">
					<Loader type="gif" dimension={60} />
				</div>
			);
		} else if (!isNotaryAvailable) {
			return (
				<div className="details-container__empty">
					<Image
						fileName="empty-sessions.svg"
						className="details-container__empty-illustration"
					/>
					<span>Notarization is not completed yet</span>
				</div>
			);
		} else {
			return (
				<Fragment>
					<div className="details-container__row">
						<div className="details-item">
							<div className="details-item__icon details-item__icon--gray">
								<i className="ri-notification-badge-line"></i>
							</div>
							<div className="details-item__group">
								<div className="details-item__label">Notarization Status</div>
								<div className="details-item__value">
									Not notarized (1 Signer KYC is pending)
								</div>
							</div>
						</div>
						<div className="details-item">
							<div className="details-item__icon details-item__icon--orange">
								<i className="ri-map-pin-line"></i>
							</div>
							<div className="details-item__group">
								<div className="details-item__label">Notarization Type</div>
								<div className="details-item__value">Remote</div>
							</div>
						</div>
						<div className="details-item">
							<div className="details-item__icon details-item__icon--purple">
								<i className="ri-user-location-line"></i>
							</div>
							<div className="details-item__group">
								<div className="details-item__label">Notarial Act</div>
								<div className="details-item__value">Acknowledgement</div>
							</div>
						</div>
					</div>
					<div className="details-container__row">
						<div className="details-item">
							<div className="details-item__icon details-item__icon--pink">
								<i className="ri-calendar-event-line"></i>
							</div>
							<div className="details-item__group">
								<div className="details-item__label">Date & Time</div>
								<div className="details-item__value details-item__value--recipients">
									N/A
								</div>
							</div>
						</div>
						<div className="details-item">
							<div className="details-item__icon details-item__icon--purple">
								<i className="ri-key-2-line"></i>
							</div>
							<div className="details-item__group">
								<div className="details-item__label">Jurisdiction</div>
								<div className="details-item__value">Washington</div>
							</div>
						</div>
						<div className="details-item">
							<div className="details-item__icon details-item__icon--green">
								<i className="ri-coins-line"></i>
							</div>
							<div className="details-item__group">
								<div className="details-item__label">Notarization fee</div>
								<div className="details-item__value">
									$25 (varies between states)
								</div>
							</div>
						</div>
					</div>
				</Fragment>
			);
		}
	}, [isLoaded, notaryDetails]);

	return (
		<div className="details-container">
			<div className="details-container__label">Notary Details</div>
			<div className="details-container__content">{renderNotaryDetails}</div>
		</div>
	);
};
