import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { API_URL } from 'constant';
import { loginState } from 'global-stores';
import { useNetwork, useNotification } from 'hooks';
import { ROUTES } from 'routes';
import { useSignup } from 'views/authentication';
import { mobileVerificationLinkState, mobileVerificationSteps } from './state';

export const useUserVerification = () => {
	const [loaded, setLoaded] = useState(true);
	const { post, get } = useNetwork();
	const { errorNotification, successNotification } = useNotification();
	const setSteps = useSetRecoilState(mobileVerificationSteps);
	const navigate = useNavigate();
	const setUser = useSetRecoilState(loginState);
	const user = useRecoilValue(loginState);
	const { fetchUserDetails } = useSignup();

	const setMobileLinkOnDevice = useSetRecoilState(mobileVerificationLinkState);
	const verifyEmail = useCallback(async (): Promise<boolean> => {
		setLoaded(false);
		if (user?.email) {
			const resp = await get(
				`${API_URL.USER_ROLES}?email=${encodeURIComponent(
					user.email
				)}&sendEmail=true`
			);

			if (resp) {
				setLoaded(true);
				successNotification('Link sent to email.');
				return true;
			}
		}
		setLoaded(true);
		errorNotification('Failed to send link try again later.');

		return false;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [get, user.email]);

	const verifyEmailWithCode = useCallback(
		(code: string) => {
			get(`/verify-email/${code}`).then(resp => {
				if (resp) {
					const { token, message, success } = resp.data ?? {};
					if (token && success === true) {
						navigate(ROUTES.DASHBOARD);
						setUser(prev => ({ ...prev, isVerifiedEmail: true }));
						successNotification(message);
						window.location.reload();
						fetchUserDetails(token);
						return;
					}
					errorNotification('Failed to verify email id. resend new link.');
					return;
				}
				errorNotification('Failed to verify email id. resend new link.');
			});
		},
		[errorNotification, fetchUserDetails, get, navigate, setUser, successNotification]
	);

	const verifyPhone = useCallback(
		async (payload: any) => {
			setLoaded(false);
			const resp = await post(API_URL.VERIFY_CODES, payload);
			if (resp) {
				setLoaded(true);
				return true;
			}
			setLoaded(true);
			return false;
		},
		[post]
	);

	const trackPhoneStatus = useCallback(() => {
		get('/track-phone-status');
		// eslint-disable-next-line
	}, []);

	const fetchCodesForVerification = useCallback(
		(code: string) => {
			setMobileLinkOnDevice(prev => ({ ...prev, loaded: false }));
			get(`${API_URL.USER_LOGIN}/${code}`).then(resp => {
				if (resp) {
					const { securityCodes } = resp.data ?? {};
					if (securityCodes) {
						setMobileLinkOnDevice(prev => ({
							...prev,
							loaded: true,
							data: securityCodes,
						}));
						return;
					}
					setMobileLinkOnDevice(prev => ({
						...prev,
						error: true,
						loaded: true,
					}));
					// setSteps("error");
					return;
				}
				setMobileLinkOnDevice(prev => ({ ...prev, loaded: true }));
			});
		},
		[get, setMobileLinkOnDevice]
	);

	const selectOptionFromMobile = useCallback(
		async (payload: any): Promise<boolean> => {
			const resp = await post(API_URL.USER_LOGIN, payload);
			if (resp) {
				const { status } = resp;
				if (status === 'approved') {
					return true;
				}
				setSteps('error');
				return false;
			}
			return false;
		},
		[post, setSteps]
	);

	return {
		verifyEmail,
		verifyPhone,
		trackPhoneStatus,
		loaded,
		fetchCodesForVerification,
		selectOptionFromMobile,
		verifyEmailWithCode,
	};
};
