export const SessionStatus = {
	Approved: 'completed',
	Rejected: 'rejected',
	Failed: 'failed',
};

export const KYBDownloadOption = [
	{
		label: 'Download as JSON',
		value: 'json',
	},
	//when requirement is coming for pdf it will use//
	// {
	//   label: "Download as report PDF",
	//   value: "csv",
	// },
];

// color constant for subsidiary business icon
export const getIconColor = [
	{ color: 'rgb(186 18 253)' },
	{ color: 'rgb(255 49 48)' },
	{ color: 'rgb(255 61 133)' },
	{ color: 'rgb(23 97 193)' },
	{ color: 'rgb(208 80 55)' },
	{ color: 'rgb(255 94 0)' },
	{ color: 'rgb(30 81 252)' },
	{ color: 'rgb(2 175 38)' },
	{ color: 'rgb(233 36 243)' },
	{ color: 'rgb(233 36 243)' },
];

// this tabs only for subsidiary report.
export const KYB_TABS = ['kyb-enhanced', 'business-sanctions-ofac'];
