import { FC, Fragment, useMemo, useState } from 'react';
import { Tooltip } from 'react-tooltip';

import { ISignedDocInbox } from '../store';
import { SignedDocMenuTab } from './menu-tab';

interface IProps {
	inbox: ISignedDocInbox;
}
export const InboxSubject: FC<IProps> = ({ inbox }) => {
	const [isOpen, setIsOpen] = useState(false);

	const renderPendingRecipients = useMemo(() => {
		return (
			<div className="InboxSubject__recipients-list">
				{inbox?.recipients?.map(
					(recipient, index) =>
						index > 1 && (
							<div key={recipient.email}>
								<div
									className="InboxSubject__invited-user"
									id={`${recipient.email}-${recipient.name}-${recipient.userId}`}
								>
									<i className="ri-mail-line"></i>
									<div className="InboxSubject__invited-user-wrapper">
										<div className="InboxSubject__invited-user-name">
											{recipient.fullName}
										</div>
										<div className="InboxSubject__invited-user-email">
											{recipient.email?.toLowerCase()}
										</div>
									</div>
								</div>
								<Tooltip
									anchorId={`${recipient.email}-${recipient.name}-${recipient._id}`}
									place="left"
									content={recipient.role}
								/>
							</div>
						)
				)}
			</div>
		);
	}, [inbox]);

	const renderRecipients = useMemo(() => {
		return (
			<div
				className="InboxSubject__recipients-wrapper"
				onMouseLeave={() => setIsOpen(false)}
			>
				{inbox.recipients.map((recipient, index) => {
					if (index > 1) {
						return (
							<Fragment
								key={`${recipient.email}-${recipient.name}-${recipient._id}`}
							/>
						);
					}
					return (
						<div
							className="InboxSubject__recipient"
							key={recipient.email}
							id={`${recipient.email}-${inbox._id}`}
						>
							<div className="InboxSubject__recipient-name">
								{recipient.fullName}
								<Tooltip
									anchorId={`${recipient.email}-${inbox._id}`}
									place="right"
									content={recipient.email?.toLowerCase()}
									style={{ zIndex: 9, textTransform: 'lowercase' }}
								/>
							</div>
						</div>
					);
				})}
				{inbox.recipients.length > 2 && (
					<div className="InboxSubject__show-more"
						id={`signed-doc-inbox-subject-${inbox._id}`}
					>
						<div onMouseEnter={() => setIsOpen(true)}>
							{`+${inbox.recipients.length - 2} more`}
						</div>
						<>
							{isOpen && (
								<SignedDocMenuTab setIsOpen={setIsOpen}
									elementId={`signed-doc-inbox-status-${inbox._id}`}
								>
									{renderPendingRecipients}
								</SignedDocMenuTab>
							)}
						</>
					</div>
				)}
			</div>
		);
	}, [inbox, isOpen, renderPendingRecipients]);

// Paras: document name condition removed, we maintain it from backend side. as discuss with manish and abhishek s
	return (
		<div className="InboxSubject__wrapper">
			<div className="InboxSubject__document-name">
				{`${inbox.name}`}
			</div>
			<div className="InboxSubject__recipients">
				<span className="InboxSubject__to">To :</span>
				{renderRecipients}
			</div>
		</div>
	);
};
