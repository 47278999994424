import { IPerson } from '../type';

import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { SessionItemSelected } from 'global-stores';
import { useNetwork } from 'hooks';
import { API_URL } from 'constant';
import {
	DealSelectedUserDetailsState,
	DealSessionNodesState,
	DealUserSessionDetailsState,
	TransactionDealDetailsLoadingState,
	TransactionDealDetailsModalState,
} from '../states';

export const useDealDetails = () => {
	// globle states
	const setDealLoading = useSetRecoilState(TransactionDealDetailsLoadingState);
	const setActiveModal = useSetRecoilState(TransactionDealDetailsModalState);
	const setNodes = useSetRecoilState(DealSessionNodesState);
	const setDealSessionData = useSetRecoilState(DealUserSessionDetailsState);
	const setSelectedDealUser = useSetRecoilState(DealSelectedUserDetailsState);
	const setSelectedSessionItem = useSetRecoilState(SessionItemSelected);

	// hooks
	const { get } = useNetwork();

	const fetchSessionData = useCallback(
		async (sessionId: string) => {
			const resp = await get(
				`${API_URL.COMPLEX_SESSION_DETAILS}/${sessionId}?include=true`
			);
			const { data } = resp ?? [];
			setDealSessionData(data);
			setDealLoading(false);
		},
		[get, setDealLoading, setDealSessionData]
	);

	const viewSessionDetails = useCallback(
		(user: IPerson) => {
			const { sessionId, nodes } = user ?? {};
			fetchSessionData(sessionId);
			setNodes(nodes);
			setSelectedDealUser(user);
			setActiveModal('session-details');
			setSelectedSessionItem(prev => ({
				...prev,
				_id: sessionId,
			}));
		},
		[
			fetchSessionData,
			setSelectedSessionItem,
			setActiveModal,
			setNodes,
			setSelectedDealUser,
		]
	);

	return { viewSessionDetails };
};
