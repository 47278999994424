const linearGradient: {
	x1: number;
	y1: number;
	x2: number;
	y2: number;
} = {
	x1: 0,
	y1: 0,
	x2: 0,
	y2: 1,
};

export interface IChartKeys {
	type: 'line' | 'area' | 'date';
	key: string | string[];
	color?: string;
	title?: string;
}

export const defaultAreaColor = {
	color: '#2269D3',
	fill: {
		linearGradient,
		stops: [
			[0, '#3C65D633'],
			[1, '#3C65D600'],
		],
	},
};

export const AreaLineColor: { [key: string]: string } = {
	net_sales: '#357AF6',
	ebitda: '#7935DE',
	ebit: '#F5AF45',
	net_income: '#389F75',
	ev_revenue: '#357AF6',
	ev_ebitda: '#F5AF45',
	ev_fcf: '#389F75',
	fcf_conversion: '#389F75',
};

export const AreaFillColor: {
	[key: string]: {
		linearGradient: {
			x1: number;
			y1: number;
			x2: number;
			y2: number;
		};
		stops: [number, string][];
	};
} = {
	net_sales: {
		linearGradient,
		stops: [
			[0, '#3C65D633'],
			[1, '#3C65D600'],
		],
	},
	ebitda: {
		linearGradient,
		stops: [
			[0, '#7935DE33'],
			[1, '#7935DE00'],
		],
	},
	ebit: {
		linearGradient,
		stops: [
			[0, '#F5AF4533'],
			[1, '#F5AF4500'],
		],
	},
	net_income: {
		linearGradient,
		stops: [
			[0, '#389F7533'],
			[1, '#389F7500'],
		],
	},
	ev_revenue: {
		linearGradient,
		stops: [
			[0, '#3C65D633'],
			[1, '#3C65D600'],
		],
	},
	ev_ebitda: {
		linearGradient,
		stops: [
			[0, '#F5AF4533'],
			[1, '#F5AF4500'],
		],
	},
	ev_fcf: {
		linearGradient,
		stops: [
			[0, '#389F7533'],
			[1, '#389F7500'],
		],
	},
	fcf_conversion: {
		linearGradient,
		stops: [
			[0, '#389F7533'],
			[1, '#389F7500'],
		],
	},
};
