import { atom } from 'recoil';

export const SelectedBakcgroundColorState = atom<string>({
	key: 'selected-background-color-state',
	default: '',
});

export const SelectedHeaderColorState = atom<string>({
	key: 'selected-header-color-state',
	default: '',
});

export const WebColorProperties = atom<{ [key: string]: string | any }>({
	key: 'web-color-properties',
	default: {
		type: 'onboarding',
		background: '#f7f7f7',
		tableHeadColor: '#d9e1f2',
		isSearch: true,
		btnPrimary: '#3c65d6',
		btnSecondary: '#ebeef5',
		notification: true,
		sidebar: true,
	},
});
