import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { IAnalyticsPieChart } from './types';
import { kycSettingsState } from 'global-stores';

export const useVariablePieChart = () => {
	const kycSettings = useRecoilValue(kycSettingsState);
	const { settings } = useMemo(() => kycSettings, [kycSettings]);
	const { bodyFont } = settings ?? {};

	const getVariablePieConfig = ({
		isLoading,
		seriesColor,
		title,
		seriesData,
		seriesName,
		value,
		setSeriesName,
		setValue,
	}: IAnalyticsPieChart) => {
		const chart =
			(window as any).Highcharts?.chart?.(title, {
				chart: {
					type: 'variablepie',
					backgroundColor: 'transparent',
					style: {
						fontFamily: bodyFont?.family !== '' ? bodyFont?.family : 'Poppins',
					},
					marginLeft: 0,
					marginRight: 0,
					events: {
						render: function () {
							// eslint-disable-next-line @typescript-eslint/no-this-alias
							const chart: any = this,
								sum = value;

							chart.textGroup = chart.renderer.g('textGroup').add().toFront();

							const customText = (chart.myCustomText = chart.renderer
								.text(
									`<div style="color:#7C8398">${seriesName}</br><div style="color:#000000; fontSize:16px">${sum}%</div></div>`,
									seriesName.length > 10
										? chart.plotWidth / 1.48
										: chart.plotWidth / 1.66,
									chart.plotHeight / 1.6
								)
								.css({
									fontSize: '14px',
									fontWeight: 500,
									lineHeight: 20,
									textAlign: 'center',
									verticalAlign: 'middle',
									textAnchor: 'middle',
								})
								.add(chart.textGroup));
							chart.textGroup.translate(
								-chart.myCustomText.getBBox().width / 2
							);

							return customText;
						},
					},
				},

				title: {
					text: title,
					align: 'left',
					style: {
						color: '#000000',
						fontWeight: '600',
						fontSize: '16px',
					},
				},

				credits: {
					enabled: false,
				},

				colors: seriesColor,

				accessibility: {
					enabled: false,
				},

				legend: {
					width: 0,
					align: 'center',
					verticalAlign: 'bottom',
					symbolRadius: 10,
					title: {
						text: null,
					},
					itemStyle: {
						color: '#000000',
						fontWeight: '400',
						fontSize: '12px',
						lineHeight: '16px',
						textAlign: 'center',
					},
					itemHoverStyle: {
						color: '#000000',
					},
				},

				plotOptions: {
					variablepie: {
						states: {
							inactive: {
								enabled: false,
							},
						},
						borderColor: '',
						shadow: false,
						showInLegend: true,
						cursor: 'pointer',
						dataLabels: {
							enabled: false,
						},
						series: {
							shadow: false,
						},
						point: {
							events: {
								legendItemClick: function (this: any) {
									setSeriesName(this.name);
									setValue(this.y.toFixed(1));
									this.series.data.map((point: any) => {
										if (point.name === this.name) {
											point.update({ opacity: 1 }, false);
										}
										if (point.name !== this.name) {
											point.update({ opacity: 0.2 }, false);
										}
									});
									return false;
								},
							},
						},
					},
				},

				tooltip: {
					borderWidth: 0,
					formatter: function (this: any) {
						return (
							`<span style="color:${this.color}">` +
							this.key +
							' : </span><b>' +
							this.y.toFixed(1) +
							'%</b>'
						);
					},
				},
				exporting: { enabled: false },

				series: [
					{
						type: 'variablepie',
						innerSize: '50%',
						zMin: 0,
						name: title,
						data: seriesData,
						point: {
							events: {
								click: function (this: any) {
									setSeriesName(this.name);
									setValue(this.y.toFixed(1));
									this.series.data.map((point: any) => {
										if (point.name === this.name) {
											point.update({ opacity: 1 }, false);
										}
										if (point.name !== this.name) {
											point.update({ opacity: 0.2 }, false);
										}
									});
									return;
								},
							},
						},
						states: {
							hover: {
								enabled: false,
							},
						},
					},
				],
			}) ?? {};

		if (isLoading) {
			chart.showLoading();
		} else {
			chart.hideLoading();
		}
	};

	return { getVariablePieConfig };
};
