import { NoDataAvailable, ReactResponsiveTable } from '@storybook';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';

import Modal from '@storybook/new-modal/modal';
import { API_URL } from 'constant';
import {
	ComplexSessionNodesState,
	ConditionalSessionFlowState,
	FundDetailState,
	SelectedSessionState,
	SelectedSessionsIdState,
	SessionItemSelected,
	isFundCallingState,
} from 'global-stores';
import { useNetwork, useNotification } from 'hooks';
import { ROUTES } from 'routes';
import { getDate, useFormatNumber } from 'utils';
import { SessionTreeGraph } from 'views/complex-onboarding-flow';
import { actionKeyLabel } from 'views/pipelines';
import { ComplexStepsView } from 'views/pipelines/components/complex-step-view';
import { PERMISSION_SCREEN, useUserRoles } from 'views/routes-children';
import {
	ViewKyc,
	useComplexSessionInfo,
	useSessionDetails,
} from 'views/sessions';
import { ComplexSessionView } from 'views/sessions/components/complex-session-view';
import { FundIconHeader } from './components/fund-header';
import { FUND_DETAIL_TABLE } from './constants';
import './funds-Details.scss';

interface IOptionsRow {
	email: () => void;
	firstName: () => void;
	invested: () => void;
	issueDate: () => void;
	share: string;
	name: string;
	price: string;
}

export const FundDetails = () => {
	const [fundDetailList, setfundDetailList] = useRecoilState(FundDetailState);
	const [isFundCalling, setIsFundCalling] = useRecoilState(isFundCallingState);
	const setSelectedIdState = useSetRecoilState(SelectedSessionsIdState);
	const setSessionItemSelected = useSetRecoilState(SessionItemSelected);
	const resetSelectedSessionState = useResetRecoilState(SelectedSessionState);
	const { errorNotification, infoNotification } = useNotification();
	const navigate = useNavigate();
	const { FUNDS } = ROUTES;
	const {
		get: getFundDetail,
		data: FundDetailData,
		error: FundDetailError,
		status: FundDetailStatus,
		isLoaded: FundDetailLoaded,
		loading: FundDetailLoading,
	} = useNetwork();
	const { get: getNodesDetail } = useNetwork({ updateState: false });

	const { fetchActivity, isLoaded: activityLoaded } = useSessionDetails();
	const { fetchDeviceInfo, isLoaded: sessionDeviceLoaded } =
		useSessionDetails();
	const { fetchKyc, isLoaded: sessionKycLoaded } = useSessionDetails();
	const { fetchAml, isLoaded: sessionAmlLoaded } = useSessionDetails();
	const { fetchKyb, isLoaded: sessionKybLoaded } = useSessionDetails();
	const { fetchSignAgreement, isLoaded: sessionSignLoaded } =
		useSessionDetails();
	const { fetchFundInvestment, isLoaded: sessionFundLoaded } =
		useSessionDetails();
	const { fetchAccreditation, isLoaded: sessionAccreditationLoaded } =
		useSessionDetails();
	const { fetchForm } = useSessionDetails();

	const { numberDecimal } = useFormatNumber();

	// Local state
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const seComplexSessionNodes = useSetRecoilState(ComplexSessionNodesState);
	const setIsComplexTab = useSetRecoilState(ConditionalSessionFlowState);
	const [isComplexOpen, setIsComplexOpen] = useState(false);
	const [openConditionalView, setConditionalOpenView] =
		useState<boolean>(false);
	const [selectedItem, setSelectedItem] = useState<any>();
	const [isNodesSession, setIsNodesSession] = useState<boolean>(false);

	const { complexSessionInfo, complexSessionDetailsLoading } =
		useComplexSessionInfo();

	const { checkUserWritePermission } = useUserRoles();

	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.Funds),
		[checkUserWritePermission]
	);

	const fundId = useMemo(() => {
		const { href: url } = window.location;
		return url.substring(url.lastIndexOf('/') + 1);
	}, []);

	useEffect(() => {
		setfundDetailList([]);
		if (!fundId) return;
		getFundDetail(`${API_URL.FUND_DETAIL}/${fundId}`);
		// eslint-disable-next-line
	}, [fundId]);

	useEffect(() => {
		if (!fundId) return;
		if (isFundCalling) {
			getFundDetail(`${API_URL.FUND_DETAIL}/${fundId}`);
			setIsFundCalling(false);
		}
		// eslint-disable-next-line
	}, [isFundCalling, fundId]);

	useEffect(() => {
		if (FundDetailData?.data && FundDetailStatus) {
			return setfundDetailList(FundDetailData.data);
		}

		if (FundDetailError) {
			errorNotification('Failed to load fund details.');
		}
		// eslint-disable-next-line
	}, [FundDetailData, FundDetailError, FundDetailLoaded]);

	const handleCloseViewModal = useCallback(() => {
		setIsModalOpen((prev: boolean) => !prev);
		resetSelectedSessionState();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getSessionDetails = (id: string) => {
		fetchActivity(id);
		fetchDeviceInfo(id);
		fetchKyc(id);
		fetchAml(id);
		fetchKyb(id);
		fetchSignAgreement(id);
		fetchFundInvestment(id);
		fetchAccreditation(id);
		fetchForm(id);
	};

	const fetchComplexSessionDetails = useCallback(
		(sessionId: string) => {
			complexSessionInfo(sessionId);
		},
		[complexSessionInfo]
	);

	//Shahbaaz TODO  Remove in future
	const validateComplexSession = async ({
		sessionId,
	}: {
		sessionId: string;
	}) => {
		setIsNodesSession(true);
		infoNotification('Please wait while, we fetch the details...');
		const nodesResp = await getNodesDetail(
			`${API_URL.COMPLEX_SESSION_DETAILS}/${sessionId}`
		);
		setIsNodesSession(false);
		if (nodesResp?.message === 'ok') {
			return nodesResp?.data?.nodes ?? [];
		}

		return null;
	};

	const handleClickViewIcon = useCallback(
		(fund: {
				sessionId: string;
				firstName: string;
				lastName: string;
				session?: any;
			}) =>
			async () => {
				const { sessionId, lastName, firstName } = fund ?? {};
				const sesssionNodes = await validateComplexSession({ sessionId });
				if (sesssionNodes) {
					setIsComplexTab(true);
					seComplexSessionNodes(sesssionNodes);
					setIsComplexOpen(true);
					fetchComplexSessionDetails(sessionId);
					setSessionItemSelected({
						name: `${firstName} ${lastName}`,
						_id: sessionId,
						type: 'complex',
					});
				} else {
					setSessionItemSelected({
						name: `${firstName} ${lastName}`,
						_id: sessionId,
					});
					setIsComplexTab(false);
					getSessionDetails(sessionId);
					handleCloseViewModal();
				}
				setSelectedIdState([sessionId]);
			},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[getSessionDetails, handleCloseViewModal]
	);

	const handleViewStepDesign = useCallback((e: any, item: any) => {
		e.stopPropagation();
		setSelectedItem(item);
		setConditionalOpenView(true);
	}, []);

	const handleCloseModal = () => {
		setConditionalOpenView(false);
		setSelectedItem({});
	};

	const StatusJsx = useCallback(
		(fullStatus: { [key: string]: string }) => {
			return (
				<>
					{fullStatus &&
						Object.keys(fullStatus).map((key, index) => {
							return (
								<>
									<div
										className={`status__clip statusbg__${fullStatus[
											key
										]?.toLocaleLowerCase()} statustct__${key}`}
										key={`session-list-${index.toString()}`}
									>
										<svg
											className="leftImg"
											width="6"
											viewBox="0 0 6 20"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path d="M0 0H6V9.16667V20H0L6 9.16667L0 0Z" />
										</svg>

										<div className="inner__status">{actionKeyLabel[key]}</div>
										<svg
											width="6"
											viewBox="0 0 6 20"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path d="M0 0L6 9.16667L0 20V0Z" />
										</svg>
									</div>
								</>
							);
						})}
				</>
			);
		},
		// eslint-disable-next-line
		[]
	);

	const fundDetailListRows = useMemo(() => {
		if (fundDetailList && FUND_DETAIL_TABLE.HEADER.length) {
			const rows: IOptionsRow[] = [];
			fundDetailList.forEach((fundItem: any) => {
				if (fundItem) {
					let row: any = {};
					FUND_DETAIL_TABLE.HEADER.forEach(({ format, key, key2, key3 }) => {
						row.name = fundItem?.firstName;
						row.investedAmount = fundItem?.invested || '0.00';
						row.price = fundItem?.price || '0.00';
						row.sendEmail = fundItem?.email;
						row.totalShares = Number(fundItem?.share || 0);
						row.lastName = fundItem?.lastName || '';
						row.phone = fundItem?.phone || '0';
						if (format === 'jsx' && key === 'action') {
							const value = () => (
								<div className="btn-group">
									<button
										disabled={!fundItem?.sessionId}
										onClick={handleClickViewIcon(fundItem)}
										className=" view-session-btn"
									>
										View Session
									</button>
								</div>
							);
							return (row[key] = value);
						}
						if (key === 'issueDate' && format === 'jsx') {
							const value = () => (
								<>
									{' '}
									{fundItem[key]
										? getDate(fundItem[key]) !== 'Invalid DateTime'
											? getDate(fundItem[key])
											: '--'
										: '--'}
								</>
							);
							return (row[key] = value);
						}
						if (format === 'jsx') {
							let statusView = <></>;
							if (key3 && key3 === 'fullStatus') {
								if (fundItem?.session) {
									statusView = (
										<SessionTreeGraph
											details={fundItem.session}
											isSessionList
											viewComplexModal={(e: any) =>
												handleViewStepDesign(e, fundItem.session)
											}
										/>
									);
								} else {
									statusView = (
										<div className="session-status fund-status" key={key3}>
											{StatusJsx(fundItem[key3])}
										</div>
									);
								}
							}
							const value = () => (
								<>
									{key === 'invested'
										? `$${numberDecimal(Number(fundItem[key]))}`
										: fundItem[key] || '--'}
									{key2 ? (
										<div className="subTd">
											{key2 === 'class'
												? `${fundItem[key2]} Class`
												: fundItem[key2] || '--'}
										</div>
									) : (
										''
									)}
									{statusView}
								</>
							);
							return (row[key] = value);
						}

						if (key === 'price' && typeof fundItem[key] !== 'string') {
							row = { ...row, [key]: `$${fundItem[key] ?? 0}` };
						} else {
							row = {
								...row,
								[key]: fundItem[key],
							};
						}

						return null;
					});

					rows.push(row);
				}
			});
			return rows ?? [];
		}
		return [];
		// eslint-disable-next-line
	}, [fundDetailList]);

	const handleClickBtn = useCallback(() => {
		navigate(`${FUNDS}`);
		setSelectedIdState([]);
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<div className="funds-details-container">
				<div className="funds-details-back--container">
					<div className="funds-details-back" onClick={handleClickBtn}>
						<i className="ri-arrow-left-s-line" />
						<span>Back to Funds</span>
					</div>
				</div>
				<FundIconHeader
					fundId={fundId}
					isUserPermissionWrite={isUserPermissionWrite}
				/>

				<div className="funds-detail-table-container">
					{fundDetailList.length === 0 && !FundDetailLoading ? (
						<NoDataAvailable
							description="No investor details available"
							message="No fund details available"
							illustration="empty-funds.svg"
							hideBtn
							className="fund-detail-empty-screen"
						/>
					) : (
						<ReactResponsiveTable
							isLoaded={FundDetailLoaded}
							isLoading={FundDetailLoading}
							column={FUND_DETAIL_TABLE.HEADER}
							rows={fundDetailListRows}
							showSearch
							showFilter={false}
							columnHandle={false}
							height="calc(100vh - 300px)"
							emptyHeight={'calc(100vh - 255px)'}
							tableType={'fund-details'}
						/>
					)}
				</div>
			</div>
			<Modal
				isOpen={isModalOpen}
				closeModal={handleCloseViewModal}
				className="react-modal_kycDetail"
				modalName="Session modal"
				optionalClassName="override__padding"
			>
				<ViewKyc
					sessionKybLoaded={sessionKybLoaded}
					sessionKycLoaded={sessionKycLoaded}
					sessionAmlLoaded={sessionAmlLoaded}
					sessionSignLoaded={sessionSignLoaded}
					sessionFundLoaded={sessionFundLoaded}
					sessionAccreditationLoaded={sessionAccreditationLoaded}
					activityLoaded={activityLoaded}
					sessionDeviceLoaded={sessionDeviceLoaded}
					isFundView
				/>
			</Modal>
			{isComplexOpen && (
				<Modal
					isOpen={isComplexOpen}
					closeModal={() => setIsComplexOpen(false)}
					className="react-modal_kycDetail"
					modalName="Session modal"
				>
					<ComplexSessionView
						isLoading={complexSessionDetailsLoading || isNodesSession}
					/>
				</Modal>
			)}
			{openConditionalView && (
				<ComplexStepsView
					isOpen={openConditionalView}
					closeModal={handleCloseModal}
					details={selectedItem}
				/>
			)}
		</>
	);
};

export default FundDetails;
