import type {
	IPacketRecipientsKYCDetails,
	ISession,
	ISignDocHistoryAPIResponse,
	ISignDocHistoryValues,
} from './types';

import { useCallback, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { API_URL } from 'constant';
import { useNetwork, useNotification } from 'hooks';
import { ISignedDocInbox } from 'views/signed-doc-inbox';
import { EventToAction, EventToActivity } from './constants';
import {
	PacketNotaryDetails,
	PacketRecipientsKYCDetails,
	SignDocHistoryEntriesState,
} from './states';

export const useSignDocHistory = () => {
	const setActivityLog = useSetRecoilState(SignDocHistoryEntriesState);
	const setNotaryDetails = useSetRecoilState(PacketNotaryDetails);
	const setRecipientKyc = useSetRecoilState(PacketRecipientsKYCDetails);

	const [isLoaded, setIsLoaded] = useState(true);

	const { get, post } = useNetwork();
	const { errorNotification, successNotification } = useNotification();

	const fetchHistory = useCallback(
		async (envelopeId: string) => {
			setIsLoaded(false);
			const resp: ISignDocHistoryAPIResponse = await get(
				`${API_URL.ENVELOPE_HISTORY}?envelopeId=${envelopeId}`
			);
			if (resp?.data) {
				setActivityLog(resp.data);
				setIsLoaded(true);
				return;
			}
			setIsLoaded(true);
			errorNotification(resp?.message ?? 'Failed to load activities.');
		},
		[errorNotification, get, setActivityLog]
	);

	const fetchRecipientsKycStatus = useCallback(async (envelopeId: string) => {
		setIsLoaded(false);
		const resp = await get(`${API_URL.RECIPIENT_KYC}?envelopeId=${envelopeId}`);
		const { data = [] } = resp ?? {};
		const formattedKycStatus: IPacketRecipientsKYCDetails = {};
		data.forEach(
			({
				kycStatus,
				nodeId,
				recipientId,
				session
			}: {
				recipientId: string;
				kycStatus: string;
				nodeId: string;
				session: ISession;
			}) => {
				formattedKycStatus[recipientId] = {
					kycStatus,
					nodeId,
					session,
				};
			}
		);
		setRecipientKyc(formattedKycStatus);
		setIsLoaded(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchNotaryDetails = useCallback(async (envelopeId: string) => {
		setIsLoaded(false);
		const resp = await get(
			`${API_URL.PACKET_DETAILS}/${envelopeId}?type=notary`
		);
		const { notary = {} } = resp?.data ?? {};
		setNotaryDetails(notary);
		setTimeout(() => {
			setIsLoaded(true);
		}, 500);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const resetHistory = useCallback(() => {
		setActivityLog([]);
	}, [setActivityLog]);

	const resendFinalSignedCopy = useCallback(async(payload: {envelopeId: string, recipientEmails: string[]}) => {
		setIsLoaded(false);
		const resp = await post(API_URL.RESEND_DOCUMENT, payload);
		if(resp?.success) {
			successNotification("Document resent successfully.")
		}else {
			errorNotification(resp?.message ?? "Failed to resend the document")
		}
		setIsLoaded(true)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		fetchHistory,
		isLoaded,
		resetHistory,
		fetchNotaryDetails,
		fetchRecipientsKycStatus,
		resendFinalSignedCopy,
	};
};

export const useGetSignDocHistory = (inbox: ISignedDocInbox) => {
	const logs = useRecoilValue(SignDocHistoryEntriesState);

	const allUsers = useMemo(
		() => [
			...inbox.recipients,
			{
				_id: inbox.sender?._id,
				name: inbox.sender?.name,
				email: inbox.sender?.email,
				fullName: inbox.sender?.fullName,
			},
		],
		[inbox.recipients, inbox.sender]
	);

	const getActivity = useCallback(
		(event: string, userId?: string) => {
			const { fullName: senderFullName, name: senderName = '' } =
				inbox.sender ?? {};
			const documentsName = inbox.documents
				?.map(document => document?.document?.name)
				.join(', ');
			const recipient = allUsers.find(recipient => recipient._id == userId);
			const activityString = EventToActivity[event];

			const recipientDetail =
				(recipient?.fullName || recipient?.name) ?? 'Deleted User';

			if (activityString) {
				return activityString
					.replace('%SENDER%', senderFullName || senderName)
					.replace('%RECIPIENT%', recipientDetail)
					.replace('%EMAIL%', recipient?.email || '')
					.replace('%DOCUMENTS%', documentsName);
			}
			return '';
		},
		[inbox.documents, inbox.sender, allUsers]
	);

	const activityLogs = useMemo<ISignDocHistoryValues[]>(
		() =>
			logs
				.filter(log => log.event !== 'COMPLETED' && log.event !== 'DOWNLOAD')
				.map(log => {
					const data: ISignDocHistoryValues = { ...log };
					const userDetails = allUsers.find(user => user?._id === log.userId);
					if (userDetails) {
						data.userName = userDetails?.fullName;
						data.email = userDetails?.email;
					} else if (!userDetails) {
						data.userName = 'Deleted User';
					}
					if (log?.event) {
						data.action = EventToAction[log.event];
					}
					/**
					 * @Manish condition added for voided documents
					 * */
					if (
						log?.event === 'CREATED' ||
						log.event == 'SENT' ||
						log.event === 'VOIDED'
					) {
						data.userName = inbox.sender?.fullName || inbox.sender?.name;
					}

					data.activity = getActivity(log.event, log.userId);
					data.status =
						data?.envelopeStatus && data?.envelopeStatus?.length > 1
							? data?.envelopeStatus?.charAt(0).toUpperCase() +
							  data?.envelopeStatus?.slice(1)
							: data.action;
					if (log?.envelopeStatus === 'configured') {
						data.status = 'Sent';
					}
					return data;
				}),
		[allUsers, getActivity, inbox, logs]
	);

	return { activityLogs };
};
