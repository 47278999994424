import { camelCaseToCapitalizedWords } from 'utils';
import { KYB_ICONS } from '../../hooks';
import './beneficial-owners-card.scss';

interface BeneficialOwnersCardProps {
	heading: string;
	ownership?: string;
	metadata?: {
		totalPercentageOfShares?: string;
		totalValuation?: string;
		[key: string]: any;
	};
	subTitle?: string;
}

export const BeneficialOwnersCard: React.FC<BeneficialOwnersCardProps> = ({
	heading,
	ownership,
	metadata,
	subTitle = 'Ownership',
}) => {
	return (
		<div className="benifical__wrapper">
			<div className="benifical__heading--row">
				<div className="benifical__heading--row__label__wrapper">
					<div className="benifical__heading--row__icon">
						<i className={KYB_ICONS['individual']}></i>
					</div>
					<div className="benifical__heading--row__heading">{heading}</div>
				</div>
				<div className="benifical__heading--row__sub--heading">
					{ownership !== 'N/A' ? (
						<>
							{subTitle}: {ownership || '-'}
						</>
					) : null}
				</div>
			</div>

			{Object.keys(metadata ?? {}).filter(
				key => typeof metadata?.[key] !== 'object' && metadata?.[key]
			)?.length > 0 && (
				<div className="benifical__body--wrapper">
					{Object.entries(metadata ?? {}).map(([key, value]) => {
						if (
							typeof value === 'object' ||
							!value ||
							/url|uri|link/gi.test(key)
						) {
							return <></>;
						}
						return (
							<>
								<div className="benifical__data--key">
									<span className="benifical__data--label">
										{camelCaseToCapitalizedWords(key)}:
									</span>
									<span className="benifical__data--value">
										{value ?? 'N/A'}
									</span>
								</div>
							</>
						);
					})}
				</div>
			)}
		</div>
	);
};
