import { ChangeEvent, FC, ReactNode, useCallback } from 'react';
import { IOption, Input, ReactDropdown } from '@storybook';
import CountryJson from 'views/pipelines/components/address-details/countries.json';

import { AddressDetailsType } from '../store';
import { LabelHeader } from '../labelHeader';
import { useCountryStateCity } from 'hooks';
import { AutoFillLocation } from '@storybook/auto-fil-input-location';
import { isNumber } from 'utils';

interface IAddressDetails {
	checked: boolean;
	handleChangeSelect: (e: IOption, name: string) => void;
	mailing?: boolean;
	handleChange: (e: ChangeEvent<HTMLInputElement>, name: string) => void;
	formData: AddressDetailsType;
	errorMessage: AddressDetailsType;
	labelHeader: string;
	isBusiness?: boolean;
	children?: ReactNode;
	handleAutoInputValue: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const AddressDetails: FC<IAddressDetails> = ({
	checked,
	handleChangeSelect,
	mailing = false,
	handleChange,
	errorMessage,
	formData,
	labelHeader,
	isBusiness = false,
	children = <></>,
	handleAutoInputValue,
}) => {
	const {
		country,
		state: formState,
		mailingCountry,
		mailingState: formMailingState,
		mailingAddress,
		city: formCity,
		mailingCity: formMailingCity,
		address,
		zip,
		mailingZip,
		mailingAddress2,
		address2,
	} = formData ?? {};

	const {
		MailingCityOptions,
		CityOptions,
		MailingStatesOptions,
		StateOptions,
	} = useCountryStateCity({
		country,
		formState,
		formMailingState,
		mailingCountry,
	});

	const handleZip = useCallback(
		(e: ChangeEvent<HTMLInputElement>, name: string) => {
			const { value } = e.target ?? {};
			if (!isNumber(value)) return;
			handleChange(e, name);
		},
		[handleChange]
	);

	return (
		<>
			<LabelHeader label={labelHeader} />
			{children}
			<div className="full__span">
				<AutoFillLocation
					isManualSearch
					label={`${mailing ? 'Mailing' : 'Street'} Address`}
					placeHolder={'ABCXYZ Inc.'}
					isRequired={true}
					handleOnChange={(e: ChangeEvent<HTMLInputElement>) =>
						handleChange(e, `${mailing ? 'mailingAddress' : 'address'}`)
					}
					value={!mailing ? address : mailingAddress}
					isError={
						!mailing ? !!errorMessage.address : !!errorMessage.mailingAddress
					}
					errorMessage={
						!mailing ? errorMessage.address : errorMessage.mailingAddress
					}
					onSuccess={handleAutoInputValue}
				/>
			</div>
			{isBusiness && (
				<div className="full__span">
					<Input
						label={`${mailing ? 'Mailing 2' : 'Street 2'} Address`}
						inputType="text"
						placeholder="ABCXYZ Inc."
						maxLength={40}
						handleChange={(e: ChangeEvent<HTMLInputElement>) =>
							handleChange(e, `${mailing ? 'mailingAddress2' : 'address2'}`)
						}
						value={!mailing ? address2 : mailingAddress2}
						isError={
							!mailing
								? !!errorMessage.address2
								: !!errorMessage.mailingAddress2
						}
						errorMessage={
							!mailing ? errorMessage.address2 : errorMessage.mailingAddress2
						}
						isRequired={true}
					/>
				</div>
			)}
			<ReactDropdown
				label="Country"
				options={CountryJson}
				isSearchable
				value={
					!mailing
						? country === ''
							? ''
							: { label: country, value: country }
						: {
								label: mailingCountry ?? '',
								value: mailingCountry ?? '',
						  }
				}
				handleChangeSelect={(e: IOption) =>
					handleChangeSelect(e, `${mailing ? 'mailingCountry' : 'country'}`)
				}
				placeholder="Select"
				isRequired
				errorMessage={errorMessage?.country}
			/>
			<Input
				label="Zip Code"
				inputType="text"
				placeholder="12345"
				maxLength={6}
				handleChange={(e: ChangeEvent<HTMLInputElement>) =>
					handleZip(e, `${mailing ? 'mailingZip' : 'zip'}`)
				}
				value={!mailing ? zip : mailingZip}
				isError={
					!mailing ? !!errorMessage.zip : !checked && !!errorMessage.mailingZip
				}
				errorMessage={mailing ? errorMessage.mailingZip : errorMessage.zip}
				isRequired
			/>
			<ReactDropdown
				label="State"
				options={!mailing ? StateOptions || [] : MailingStatesOptions || []}
				isSearchable
				value={
					!mailing
						? formState === ''
							? ''
							: { label: formState, value: formState }
						: {
								label: formMailingState ?? '',
								value: formMailingState ?? '',
						  }
				}
				handleChangeSelect={(e: IOption) =>
					handleChangeSelect(e, `${mailing ? 'mailingState' : 'state'}`)
				}
				isRequired
				placeholder="Select"
				errorMessage={errorMessage?.state}
			/>

			<ReactDropdown
				label="City"
				options={!mailing ? CityOptions || [] : MailingCityOptions || []}
				isSearchable
				value={
					!mailing
						? formCity === ''
							? ''
							: { label: formCity, value: formCity }
						: {
								label: formMailingCity ?? '',
								value: formMailingCity ?? '',
						  }
				}
				handleChangeSelect={(e: IOption) =>
					handleChangeSelect(e, `${mailing ? 'mailingCity' : 'city'}`)
				}
				isRequired
				errorMessage={errorMessage?.city}
			/>
		</>
	);
};
