import { useMemo } from 'react';

export const useIsFirefox = () => {
	const isFirefox = useMemo(() => {
		const userAgent = navigator.userAgent.toLowerCase();
		if (userAgent.indexOf('firefox') > -1) {
			return true;
		} else return false;
	}, []);
	return {
		isFirefox,
	};
};
