import { FC, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { NodesState } from 'views/complex-onboarding-flow/stores/states';
import { Label } from '../condition-label';
import { CustomHandle } from '../custom-handle';
import { QuestionLabel } from '../question-label';
import { FormQuestion } from './questions';

export interface IRenderBlock {
	label?: string | undefined;
	price: number;
	nodeId: string;
}
// Shahbaaz: changes for saloni
const QuestionariesLabel: any = {
	Individual: 'Person/Individual',
	Business: 'Entity/Business',
};

const RenderQuestion = ({ el, nodeId }: { el: string; nodeId: string }) => {
	const nodes = useRecoilValue(NodesState);

	const { conditions: form } = useMemo(
		() => nodes.find((el: any) => el.id === nodeId) ?? [],
		[nodes, nodeId]
	);

	const isHidden = useMemo(() => {
		if (form) {
			const hasValue = form[0]?.multipleChoice?.some(
				(item: { key: string }) => item.key === el
			);
			return hasValue;
		} else return false;
	}, [form, el]);

	return (
		<div style={{ position: 'relative' }}>
			<FormQuestion
				// Shahbaaz: change label
				label={QuestionariesLabel[el]}
				questionId={el}
				nodeId={nodeId}
			/>
			<CustomHandle
				hidden={!isHidden}
				value={`${nodeId}_multipleChoice_${el}`}
				showLeft={false}
				showRight
			/>
		</div>
	);
};

export const RenderFormBlock: FC<IRenderBlock> = ({ label, price, nodeId }) => {
	const questionData = ['Individual', 'Business'];

	return (
		<>
			<Label label={label || ''} price={price} priceLabel="Verification" />
			<div className="questions-wrapper">
				<QuestionLabel label="Select your identity type?" />
				{questionData.map(el => (
					<RenderQuestion el={el} nodeId={nodeId} key={el} />
				))}
			</div>
		</>
	);
};
