import ReactDOM from 'react-dom';
import ReactDomRenderer from 'react-dom/client';

import { OnboardedDashboard } from 'web-components/onboarded-dashboard/onboarded-dashboard';

const root = document.getElementById('simplici-admin');

if (root) {
	const rootContainer = ReactDomRenderer.createRoot(root);
	rootContainer.render(<OnboardedDashboard />);
} else {
	customElements.define(
		'onboarded-dashboard',
		class extends HTMLElement {
			connectedCallback() {
				const props: Record<string, any> = {};
				const attributes = this.getAttributeNames();

				attributes.forEach(attr => {
					const propData = this.getAttribute(attr);
					if (
						window[propData as any] &&
						typeof window[propData as any] === 'function'
					) {
						props[attr] = window[propData as any];
					} else {
						props[attr] = propData;
					}
				});

				// eslint-disable-next-line react/no-deprecated
				ReactDOM.render(<OnboardedDashboard {...props} />, this);
			}
			disconnectedCallback() {
				// eslint-disable-next-line react/no-deprecated
				ReactDOM.unmountComponentAtNode(this);
			}

			static get observedAttributes() {
				return [
					'bgcolor',
					'search',
					'theadcolor',
					'clientid',
					'clientsecret',
					'btnprimary',
					'btnsecondary',
					'notification',
					'type',
					'admin',
					'sidebar',
					'isinsideadmin',
					'clientlogosmall',
					'clientlogo',
					'companyname',
					'companyemail',
				];
			}

			attributeChangedCallback(_: string, oldValue: any, newValue: any) {
				if (newValue !== oldValue) {
					this.connectedCallback();
				}
			}
		}
	);
}
