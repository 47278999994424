import { useEffect, useState } from 'react';
import { Button, Loader, TextArea } from '@storybook';

import './fraud-report.scss';
import {
	IModal,
	IsMarkAsFraudSelectedByDetails,
	SelectedChequeFraudItem,
	useCheckDefence,
} from 'views/cheque-fraud/stores';
import { useRecoilValue, useResetRecoilState } from 'recoil';

interface IFraudReport {
	handleClose: (arg0: IModal) => void;
	onComplete?: () => void;
}
export const FraudReport = ({ handleClose, onComplete }: IFraudReport) => {
	const [fraudContent, setFraudContent] = useState('');

	const selectedChequeFraudItem = useRecoilValue(SelectedChequeFraudItem);

	const isMarkAsFraudSelectedByDetails = useRecoilValue(
		IsMarkAsFraudSelectedByDetails
	);

	const resetIsMarkAsFraudSelectedByDetails = useResetRecoilState(
		IsMarkAsFraudSelectedByDetails
	);

	const { reportCheckFraud, reportFraudLoading } = useCheckDefence();

	const handleSubmit = () => {
		reportCheckFraud(
			selectedChequeFraudItem.sessionId ?? '',
			fraudContent,
			() => {
				handleClose(isMarkAsFraudSelectedByDetails ? 'viewDetails' : '');
				onComplete?.();
			}
		);
	};

	useEffect(() => {
		return () => {
			resetIsMarkAsFraudSelectedByDetails();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="report-modal__content">
			<div className="report-modal__content__body-inner">
				<TextArea
					label={''}
					handleChange={e => setFraudContent(e.target.value)}
					value={fraudContent}
					placeholder={'Enter your message here...'}
				/>
			</div>
			<div className="report-modal__btn-wrapper">
				<Button
					label={'Cancel'}
					type={'button button__filled--secondary '}
					handleClick={() =>
						handleClose(isMarkAsFraudSelectedByDetails ? 'viewDetails' : '')
					}
				/>
				<Button
					label={
						reportFraudLoading ? (
							<Loader type="loader" className="loader-white" dimension={20} />
						) : (
							'Report fraud'
						)
					}
					type={'button button__filled button__filled--primary '}
					handleClick={handleSubmit}
					disabled={!fraudContent.length}
				/>
			</div>
		</div>
	);
};
