import { atom } from 'recoil';
import { IGoogleFontsState, LocationInfo } from './types';

export const GoogleFontsState = atom<IGoogleFontsState>({
	key: 'google-fonts-state',
	default: {
		data: [],
	},
});

export const GoogleLocation = atom<null | LocationInfo>({
	key: 'google-location-state',
	default: null,
});
