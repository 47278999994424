import { useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';

import { Image, SkeletonTable } from '@storybook';
import { API_URL } from 'constant';
import { useNetwork } from 'hooks';

import {
	SUPPORT_AGENT_HEADER,
	UserAgentDetailState,
	UserAgentDetails,
} from './components';

import { Header } from 'views/header';
import './support-agent.scss';

export const SupportAgent = () => {
	const [supportAgent, setSupportAgent] = useRecoilState(UserAgentDetailState);

	const {
		get: getSupportAgent,
		data: supportAgentData,
		loading: supportAgentLoader,
	} = useNetwork();

	useEffect(() => {
		if (Object.keys(supportAgent).length === 0) {
			getSupportAgent(API_URL.SUPPORT_AGENT);
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (supportAgentData?.data) {
			return setSupportAgent(supportAgentData?.data);
		}
	}, [setSupportAgent, supportAgentData?.data]);

	const userAgentDetails = useMemo(() => {
		return (
			<div className="support-agent_table-body">
				{Object.keys(supportAgent).map((agent, index) => {
					const { userData, agentData } = supportAgent[agent] ?? {};
					return (
						<div key={userData.userId}>
							<UserAgentDetails
								userData={userData}
								agentData={agentData}
								agent={agent}
								index={index}
							/>
						</div>
					);
				})}
			</div>
		);
	}, [supportAgent]);

	const renderTableColumns = useMemo(
		() => (
			<div className="support-agent_table">
				{SUPPORT_AGENT_HEADER.map(({ key, label }) => (
					<div
						key={key}
						className={`${
							key === 'name'
								? 'support-agent_table_header-name'
								: 'support-agent_table_header'
						}`}
					>
						{label}
					</div>
				))}
			</div>
		),
		[]
	);

	return (
		<div className="analytics-body">
			<Header title="Support Agent" />
			<div className="support-agent">
				{renderTableColumns}
				{supportAgentLoader ? (
					<SkeletonTable
						listsToRender={10}
						numberColumn={SUPPORT_AGENT_HEADER.length}
						column={SUPPORT_AGENT_HEADER}
					/>
				) : Object.keys(supportAgent).length ? (
					userAgentDetails
				) : (
					<div className="support-agent_illustration">
						<Image fileName="empty-sessions.svg" />
						<div>
							<div>No Agent Detail Available</div>
							<div className="support-agent_illustration_text">
								You do not have any agent yet.
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default SupportAgent;
