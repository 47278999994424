import { Button, Image, ReactResponsiveTable } from '@storybook';
import { useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { API_URL } from 'constant';
import { useNetwork, useNotification } from 'hooks';
import {
	SignDocModalState,
	UploadedTemplateDocsState,
	finalConfiguredAggrementDocsState,
} from 'global-stores';

import { SelectedSignerState } from '../store';
import { uploadedFilesColumn } from './../constant';

export const UploadedFiles = () => {
	const [addedFiles, setAddedFiles] = useRecoilState(UploadedTemplateDocsState);
	const provider = useRecoilValue(SelectedSignerState);

	const { successNotification } = useNotification();

	const { remove: removeDoc } = useNetwork();

	const setFinalSelectedDocs = useSetRecoilState(
		finalConfiguredAggrementDocsState
	);

	const setSignDocState = useSetRecoilState(SignDocModalState);

	const handleDeleteFile = useCallback(
		async (item: any, index: number) => {
			setAddedFiles(prev => {
				const prevState = [...prev];
				prevState.splice(index, 1);
				return prevState;
			});
			setFinalSelectedDocs(prev => {
				const prevState = JSON.parse(JSON.stringify(prev));
				const foundIndex = prevState.findIndex(
					(doc: any) => doc._id === item._id
				);
				if (foundIndex > -1) {
					prevState.splice(foundIndex, 1);
				}
				return [...prevState];
			});

			successNotification('File deleted successfully.');
			const param = provider?.toLowerCase() === 'docusign' ? '' : '?type=esign';
			removeDoc(`${API_URL.SIGN_AGREEMENT_DOC}/${item._id}${param}`);
			setSignDocState(false);
		},
		[
			provider,
			removeDoc,
			setAddedFiles,
			setFinalSelectedDocs,
			setSignDocState,
			successNotification,
		]
	);

	const files = useMemo(() => {
		const rows: any[] = [];
		addedFiles.forEach((item: any, index) => {
			if (item) {
				let row = {} as any;
				uploadedFilesColumn.forEach(
					({ format, key }: { format: string; key: string }) => {
						row.filename = item.name;
						if (format === 'jsx' && key === 'actions') {
							const value = () => {
								return (
									<Button
										handleClick={() => handleDeleteFile(item, index)}
										label="Remove"
										type="button__ghost--danger button__small UploadedFiles--btn-height"
									/>
								);
							};
							return (row[key] = value);
						}

						if (format === 'jsx' && key === 'name') {
							const value = () => {
								return (
									<div className="uploaded-file-name">
										<Image fileName="PDF.svg" /> 
										<div className='uploaded-file-name__title'>
											{item.name}
											</div>
									</div>
								);
							};
							return (row[key] = value);
						}
						return (row = { ...row, [key]: item[key] });
					}
				);
				rows.push(row);
			}
		});
		return rows;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addedFiles]);

	return (
		<div className="uploaded-files">
			{files?.length > 0 && (
				<ReactResponsiveTable
					tableType="agreementdocumentmodal"
					isLoading={false}
					isLoaded={true}
					rows={files}
					column={uploadedFilesColumn}
					height={'calc(100vh - 536px)'}
					emptyHeight={'calc(100vh - 400px'}
					handelRowClick={() => () => ({})}
					showSort={false}
				/>
			)}
		</div>
	);
};
