import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { loginState } from 'global-stores';
import { useUserVerification } from '../stores';

export const Banner = () => {
	const user = useRecoilValue(loginState);
	const { verifyEmail, loaded } = useUserVerification();

	const handleResendLink = useCallback(() => {
		verifyEmail();
	}, [verifyEmail]);

	return (
		<div className="banner">
			<div className="banner__test-wrapper">
				<span className="banner__text">
					Email is not verified. Please check <strong>{user.email}</strong> for
					a link to verify your email.
				</span>
			</div>
			<button onClick={handleResendLink} className="banner__btn">
				{!loaded ? 'Sending' : 'Resend'}
			</button>
		</div>
	);
};
