import { FC, Fragment } from 'react';

import { ISignedDocInbox } from 'views/signed-doc-inbox';
import {
	ActivityLogTable,
	NotaryDetails,
	SignDocHistoryHeader,
	SignHistoryEnvelopeDetails,
	SignedRecipientDetails,
} from './components';

interface IProps {
	inbox: ISignedDocInbox;
	isUserPermissionWrite?: boolean;
}

export const SignDocHistory: FC<IProps> = ({
	inbox,
	isUserPermissionWrite = true,
}) => {
	return (
		<Fragment>
			<SignDocHistoryHeader
				inbox={inbox}
				isUserPermissionWrite={isUserPermissionWrite}
			/>
			<div className="sign-history-container">
				<SignHistoryEnvelopeDetails inbox={inbox} />
				<SignedRecipientDetails inbox={inbox} />
				{inbox.notary && <NotaryDetails inbox={inbox} />}			
				<ActivityLogTable envelopeId={inbox._id} inbox={inbox} />
			</div>
		</Fragment>
	);
};
