import { atom } from 'recoil';
import { ICreateSignErrorState, ICreateSignState, IGenerateSignatures, ISignType } from './types';

export const IsCreateSignModalOpen = atom<boolean>({
	key: 'is-create-sign-modal-open',
	default: false,
});

// this is a temporary state and should be removed

export const CreateSignTypeState = atom<'create' | 'edit'>({
	key: 'is-create-sign-type-state-key',
	default: 'create',
});

export const CreateSignState = atom<ICreateSignState>({
	key: 'create-sign-state-key',
	default: {
		fullName: '',
		initials: '',
		generate: {
			id: '',
			sign: '',
			initials: '',
		},
		draw: {
			sign: '',
			initials: '',
			signDataCoordinates: [],
			initialsDataCoordinates: [],
		},
		upload: {
			sign: '',
			initials: '',
		},
		consent: false,
	},
});

export const activeTabState = atom<ISignType>({
	key: 'create-sign-modal-active-tab-key',
	default: 'generate',
});

// these are for the state of the for the signature pad
export const SignPadColorState = atom<string>({
	key: 'draw-sign-color-code',
	default: '#000000',
});

export const SignPadDesignState = atom<any>({
	key: 'sign-pad-design-state-key',
	default: null,
});

export const UploadSignState = atom<{ initials: ''; sign: '' }>({
	key: 'upload-sign-state-key',
	default: {
		initials: '',
		sign: '',
	},
});

export const GenerateSignImagesState = atom<IGenerateSignatures[]>({
	key: 'generate-sign-image-state-key',
	default: [],
});
export const IsFontsLoadedState = atom<boolean>({
	key: 'is-sign-fonts-loaded',
	default: false,
});


export const SignatureErrorState = atom<ICreateSignErrorState>({
    key: "signature-error-state-key",
    default: {
        signature: {
            erroMessage:  '',
            value :false
        },
        initial: {
            erroMessage:  '',
            value :false
        }
    }
})