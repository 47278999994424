import { camelCaseToCapitalizedWords } from 'utils';
import { KybInfoHeader } from '../../kyb-info-header';
import { AdditionalsInformation } from '../additional-info/additional';
import { IAditionalDetails } from './type';

interface IProps {
	data: IAditionalDetails[];
}
export const AdditionalInfoWrapper: React.FC<IProps> = ({ data }) => {
	if (!data?.length) {
		return <></>;
	}
	return (
		<div>
			<KybInfoHeader type={'Additional Information'} />
			{(data ?? []).map(item => {
				const { type, data } = item ?? {};
				return (
					<AdditionalsInformation
						key={type?.value}
						heading={camelCaseToCapitalizedWords(type?.value)}
						data={data.value}
					/>
				);
			})}
		</div>
	);
};
