import { Input, Pagination, ReactDropdown } from '@storybook';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { DatePicker } from 'components/date-picker';
import {
	ComplexSessionState,
	FilterSessionTypeTableState,
	IframePropsState,
	SelectedSessionsIdState,
	SelectedSessionsIdsForZipState,
	SessionState,
	checkBoxStateInTable,
	filteringTagsState,
	pipelinesFiltersState,
} from 'global-stores';
import { AssignSelectedSessionState } from 'views/sessions';
import { FilterPopup, TextForFilterSessionsState } from './components';
import { SessionTypesOptions } from './store';
import './table-header.scss';
import { debounce } from 'utils/debounce';

export const TableHeader = ({
	downloadZip,
	showSearch,
	showFilter,
	setFilterOpen,
	isFilterOpen,
	showArchiveFilter,
	filterRef,
	filterPage,
	setSearchedText,
	showDateFilter,
	searchedText,
	handleAppyDateFilter,
	isFilterDisabled,
	isArchiveDisabled,
	customFilter,
	handleInputSearch,
	handlePagination,
	totaloPageCount,
	isPageLoading,
	isPagination,
	children = <></>,
}: any) => {
	const { isSearch } = useRecoilValue(IframePropsState);
	const [filteringTags, setFilteringTags] = useRecoilState(filteringTagsState);
	const pipelinesFilter = useRecoilValue(pipelinesFiltersState);
	const [sessionTypeState, setSessionTypeState] = useRecoilState(
		FilterSessionTypeTableState
	);
	const setSelectedSession = useSetRecoilState(AssignSelectedSessionState);
	const setCheckBoxState = useSetRecoilState(checkBoxStateInTable);
	const setSelectedIdState = useSetRecoilState(SelectedSessionsIdState);
	const setSelectedIdsForZip = useSetRecoilState(
		SelectedSessionsIdsForZipState
	);
	const [searchText, setSearchText] = useState(searchedText ?? '');
	const setSessionList = useSetRecoilState(SessionState);
	const setComplexSessionList = useSetRecoilState(ComplexSessionState);
	const textForFilterSession = useRecoilValue(TextForFilterSessionsState);

	const handleClearField = useCallback(() => {
		setSearchedText('');
	}, [setSearchedText]);

	useEffect(() => {
		handleClearField();
	}, [handleClearField, sessionTypeState]);

	const filtersIndicator: number = useMemo(() => {
		let sum = 0;
		if (filterPage === 'pipelines') {
			Object.keys(pipelinesFilter).forEach(tag => {
				if (tag === 'onboardingFlowName') {
					sum += pipelinesFilter[tag].length;
				} else {
					sum += 1;
				}
			});
			return sum;
		}
		Object.keys(filteringTags).forEach(tag => {
			if (tag === 'onboardingFlowName' || tag === 'fund_name') {
				sum += filteringTags[tag].length;
			} else {
				sum += 1;
			}
		});
		if (textForFilterSession.length > 0 && filterPage !== 'pipelines') {
			sum += 1;
		}
		return sum;
	}, [filterPage, filteringTags, pipelinesFilter, textForFilterSession.length]);

	const handleDebounceSearch = debounce((value: string) => {
		setSearchedText(value);
		if (handleInputSearch) handleInputSearch(value);
	}, 500);

	const handleSearch = useCallback(
		(value: string) => {
			setSearchText(value);
			handleDebounceSearch(value);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[handleInputSearch]
	);

	const selectFilterCss = classNames('Table-header--container', {
		'Table-header--filter-active': isFilterOpen,
		'filter-btn-wrapper--filter-disabled': isFilterDisabled,
		'Table-header--filter-bg-active': filtersIndicator > 0,
	});

	const filterIconClass = classNames('ri-filter-3-line', {
		'Table-header--filter-icon-active': filtersIndicator > 0,
	});

	const controlStyle = {
		minHeight: 40,
		height: 45,
		borderRadius: 8,
		backgroundColor: 'var(--color-text-light-100)',
		border: 0,
		fontWeight: 500,
		fontSize: 14,
	};

	const optionStyle = {
		height: 45,
		minHeight: 40,
		'&:hover': { background: 'var(--color-hover-light-100)' },
	};

	const handleChange = useCallback(
		(item: any) => {
			if (isArchiveDisabled) return;
			if (item.value !== sessionTypeState.value) {
				setSessionList([]);
				setComplexSessionList([]);
			}
			setSessionTypeState(item);
			setSelectedSession([]);
			setCheckBoxState(false);
			setSelectedIdState([]);
			setSelectedIdsForZip([]);
			setFilteringTags([]);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isArchiveDisabled, sessionTypeState]
	);

	const handleClickOnFilter = useCallback(() => {
		if (isFilterDisabled) return;
		setFilterOpen((prev: any) => !prev);
	}, [isFilterDisabled, setFilterOpen]);

	return (
		<div>
			{(showSearch || showFilter || showArchiveFilter || downloadZip) && (
				<div className="table-responsive-actions__btns">
					{children}

					<div className="table-responsive-actions--right">
						{showArchiveFilter && (
							<div className="filter-btn-wrapper zindex">
								<ReactDropdown
									controlStyle={controlStyle}
									optionsStyle={optionStyle}
									handleChangeSelect={handleChange}
									options={SessionTypesOptions}
									value={sessionTypeState ?? SessionTypesOptions[0]}
									defaultValue={sessionTypeState}
									Isdisable={isArchiveDisabled}
								/>
							</div>
						)}
						{showFilter && (
							<div className="filter-btn-wrapper" ref={filterRef}>
								<div
									className={`${selectFilterCss}  filter-btn-wrapper__inner `}
									onClick={handleClickOnFilter}
								>
									<i className={filterIconClass} />
									{filtersIndicator > 0 ? (
										<div className="Table-header--selected">
											{filtersIndicator} selected
										</div>
									) : (
										<div className="filter-btn-wrapper__inner__label">
											Filter
										</div>
									)}
								</div>

								{isFilterOpen && (
									<div>
										<FilterPopup filterPage={filterPage} />
									</div>
								)}
							</div>
						)}
						{customFilter}
						{showDateFilter && <DatePicker onSubmit={handleAppyDateFilter} />}
						{showSearch && isSearch !== 'false' && (
							<div className="Table-header--search-input_field">
								<Input
									inputType="text"
									placeholder="Search"
									allowClear={false}
									handleClearField={() => handleClearField}
									prefixIcon="ri-search-line"
									value={searchText}
									handleChange={e => handleSearch(e.target.value)}
									label=""
								/>
							</div>
						)}

						{isPagination && totaloPageCount > 1 && (
							<Pagination
								handlePagination={handlePagination}
								isLoaded={isPageLoading}
								totaloPageCount={totaloPageCount ?? 1}
							/>
						)}
					</div>
				</div>
			)}
		</div>
	);
};
