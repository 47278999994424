import { FC, useEffect } from 'react';

import { IAreaLineChart, useAreaLineChart } from '../../hooks';
import './kyb-chart.scss';

export const AreaLineChart: FC<IAreaLineChart> = ({
	id,
	isLoading,
	title,
	valuePrefix,
	valueSuffix,
	series,
}) => {
	const { getAreaLineChartConfig } = useAreaLineChart();

	useEffect(() => {
		getAreaLineChartConfig({
			id,
			isLoading,
			title,
			valuePrefix,
			valueSuffix,
			series,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, isLoading, series, title, valuePrefix, valueSuffix]);

	return (
		<div className="kyb-chart-wrapper">
			<div id={id} />
		</div>
	);
};
