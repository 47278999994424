import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { kycSettingsState } from 'global-stores';
import { NumberWithCommas } from 'utils';
import { IDashboardPieChart } from './types';

export const usePieChart = () => {
	const kycSettings = useRecoilValue(kycSettingsState);
	const { settings } = useMemo(() => kycSettings, [kycSettings]);
	const { bodyFont } = settings ?? {};

	const getPieChartConfig = ({
		isLoading,
		title,
		approved,
		rejected,
		underReview,
		pending,
	}: IDashboardPieChart) => {
		const totalNo = approved + rejected + underReview + pending;

		const charts =
			(window as any).Highcharts?.chart(title, {
				chart: {
					type: 'pie',
					plotBackgroundColor: null,
					plotBorderWidth: null,
					plotShadow: false,
					animation: true,
					spacingTop: 0,
					spacingRight: 0,
					spacingBottom: 0,
					spacingLeft: 0,
					height: 250,
					style: {
						fontFamily: bodyFont?.family !== '' ? bodyFont?.family : 'Poppins',
					},
				},
				title: {
					text: title,
					align: 'left',
					style: {
						color: '#1D1E21',
						fontWeight: '600',
						fontSize: '20px',
					},
				},

				subtitle: {
					text: `Total Number : <span style="font-weight:600;color:#363A45">${NumberWithCommas(
						totalNo,
						isLoading
					)}</span>`,
					align: 'right',
					verticalAlign: 'bottom',
					y: 0,
					x: -40,
					style: {
						color: '#7C8398',
						fontWeight: '500',
						fontSize: '14px',
						lineHeight: '20px',
					},
				},

				colors: ['#2E57DB', '#4D80EF', '#75B1FB', '#A5CFFD'],

				exporting: {
					enabled: false,
				},

				credits: {
					enabled: false,
				},

				tooltip: {
					headerFormat: '<b>{series.name}</b></br>',
					pointFormat: '{point.name}: <b>{point.y}</b>',
				},

				accessibility: {
					enabled: false,
				},

				legend: {
					labelFormatter: function (this: any) {
						return (
							'<div style="text-align: left; width:130px;float:left;">' +
							this.name +
							'</div><div style="width:40px; float:left;text-align:left;">' +
							'  &nbsp' +
							NumberWithCommas(this.y, isLoading) +
							'</div>'
						);
					},
					title: {
						text: 'Status',
						style: {
							color: '#1D1E21',
							fontWeight: '600',
							fontSize: '16px',
							lineHeight: '24px',
						},
					},
					itemMarginTop: 4,
					align: 'left',
					alignColumns: true,
					useHTML: true,
					verticalAlign: 'middle',
					layout: 'vertical',
					x: 0,
					y: 0,
					symbolRadius: 0,
					itemStyle: {
						color: '#3D3D3D',
						fontWeight: '500',
						fontSize: '12px',
						lineHeight: '16px',
						cursor: 'default',
					},
				},

				plotOptions: {
					pie: {
						states: {
							inactive: {
								enabled: false,
							},
						},
						showInLegend: true,
						borderColor: null,
						borderRadius: 0,
						dataLabels: {
							enabled: false,
						},
						point: {
							events: {
								legendItemClick: function (e: any) {
									e.preventDefault();
								},
							},
						},
					},
				},

				series: [
					{
						states: {
							hover: {
								enabled: false,
							},
						},
						name: title,
						data: [
							{
								name: 'Approved',
								y: approved,
							},
							{
								name: 'Rejected',
								y: rejected,
							},
							{
								name: 'Under Review',
								y: underReview,
							},
							{
								name: 'Pending',
								y: pending,
							},
						],
					},
				],

				responsive: {
					rules: [
						{
							condition: {
								maxWidth: 250,
							},
							chartOptions: {
								chart: {
									height: 300,
								},
								legend: {
									itemMarginTop: 4,
									align: 'center',
									alignColumns: true,
									useHTML: false,
									verticalAlign: 'bottom',
									layout: 'vertical',
									x: null,
									y: null,
								},
							},
						},
					],
				},
			}) ?? {};

		if (isLoading) {
			charts?.showLoading();
		} else {
			charts?.hideLoading();
		}
	};

	return { getPieChartConfig };
};
