import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import {
	ChartCompareFirstState,
	ChartCompareSecondState,
	FlowAverageTimeState,
	comparedFunnelChartsState,
	funnelChartFirstState,
	funnelChartSecondState,
} from 'views/analytics/hooks';
import { ChartStatus } from '../chart-status';
import { SemiPieChart } from '../charts';
import { calculateTotalSum } from '../utils';
import { onboardingStepsAvgTime } from './constants';

export const StepsDetail = () => {
	const comparePreDetail = useRecoilValue(ChartCompareFirstState);
	const compareNextDetail = useRecoilValue(ChartCompareSecondState);
	const comparedChart = useRecoilValue(comparedFunnelChartsState);
	const preFunnelDetail = useRecoilValue(funnelChartFirstState);
	const nextFunnelDetail = useRecoilValue(funnelChartSecondState);
	const flowAverageTime = useRecoilValue(FlowAverageTimeState);

	const chartDefaultPercent = useMemo(() => {
		return comparedChart
			? preFunnelDetail.length > 0 || nextFunnelDetail.length > 0
				? 100
				: 0
			: preFunnelDetail.length > 0
			? 100
			: 0;
	}, [comparedChart, nextFunnelDetail.length, preFunnelDetail.length]);

	const comparedOnboardingStep: any = useMemo(() => {
		const acc506bDiff =
			calculateTotalSum(comparePreDetail, '506b') -
			calculateTotalSum(compareNextDetail, '506b');
		const acc506cDiff =
			calculateTotalSum(comparePreDetail, '506c') -
			calculateTotalSum(compareNextDetail, '506c');
		const kycDiff =
			calculateTotalSum(comparePreDetail, 'kyc') -
			calculateTotalSum(compareNextDetail, 'kyc');
		const amlDiff =
			calculateTotalSum(comparePreDetail, 'aml') -
			calculateTotalSum(compareNextDetail, 'aml');
		const fundPayInDiff =
			calculateTotalSum(comparePreDetail, 'payIn') -
			calculateTotalSum(compareNextDetail, 'payIn');
		const fundPayOutDiff =
			calculateTotalSum(comparePreDetail, 'payOut') -
			calculateTotalSum(compareNextDetail, 'payOut');
		const signAgreementDiff =
			calculateTotalSum(comparePreDetail, 'signAgreement') -
			calculateTotalSum(compareNextDetail, 'signAgreement');
		const kybDiff =
			calculateTotalSum(comparePreDetail, 'kyb') -
			calculateTotalSum(compareNextDetail, 'kyb');
		const formDiff =
			calculateTotalSum(comparePreDetail, 'form') -
			calculateTotalSum(compareNextDetail, 'form');
		const kybFormDiff =
			calculateTotalSum(comparePreDetail, 'kybForm') -
			calculateTotalSum(compareNextDetail, 'kybForm');
		const dynamicFormDiff =
			calculateTotalSum(comparePreDetail, 'dynamicForm') -
			calculateTotalSum(compareNextDetail, 'dynamicForm');

		const allDevice =
			kycDiff +
			amlDiff +
			signAgreementDiff +
			acc506bDiff +
			acc506cDiff +
			fundPayInDiff +
			fundPayOutDiff +
			kybDiff +
			formDiff +
			kybFormDiff +
			dynamicFormDiff;
		const acc506bValue = (acc506bDiff * 100) / allDevice || 0;
		const acc506cValue = (acc506cDiff * 100) / allDevice || 0;
		const kycValue = (kycDiff * 100) / allDevice || 0;
		const amlValue = (amlDiff * 100) / allDevice || 0;
		const fundPayInValue = (fundPayInDiff * 100) / allDevice || 0;
		const fundPayOutValue = (fundPayOutDiff * 100) / allDevice || 0;
		const signAgreementValue = (signAgreementDiff * 100) / allDevice || 0;
		const kybValue = (kybDiff * 100) / allDevice || 0;
		const formValue = (formDiff * 100) / allDevice || 0;
		const kybFormValue = (kybFormDiff * 100) / allDevice || 0;
		const dynamicFormValue = (dynamicFormDiff * 100) / allDevice || 0;

		const data = [
			{
				name: 'KYC',
				y: kycValue,
				sliced: true,
				color: '#356DDE',
				diff: kycDiff,
			},
			{
				name: 'KYB',
				y: kybValue,
				sliced: true,
				color: '#D335DE',
				diff: kybDiff,
			},
			{
				name: 'AML',
				y: amlValue,
				sliced: true,
				color: '#DECD35',
				diff: amlDiff,
			},
			{
				name: 'Acc. 506b',
				y: acc506bValue,
				sliced: true,
				color: '#1A9E69',
				diff: acc506bDiff,
			},
			{
				name: 'Acc. 506c',
				y: acc506cValue,
				sliced: true,
				color: '#6922D3',
				diff: acc506cDiff,
			},
			{
				name: 'Sign Agreement',
				y: signAgreementValue,
				sliced: true,
				color: '#35BCDE',
				diff: signAgreementDiff,
			},
			{
				name: 'Funds Pay In',
				y: fundPayInValue,
				sliced: true,
				color: '#DE8A35',
				diff: fundPayInDiff,
			},
			{
				name: 'Funds Pay Out',
				y: fundPayOutValue,
				sliced: true,
				color: '#DE3535',
				diff: fundPayOutDiff,
			},
			{
				name: 'Form',
				y: formValue,
				sliced: true,
				color: '#A6C431',
				diff: formDiff,
			},
			{
				name: 'KYB Form',
				y: kybFormValue,
				sliced: true,
				color: '#C3276F',
				diff: kybFormDiff,
			},
			{
				name: 'Dynamic Form',
				y: dynamicFormValue,
				sliced: true,
				color: '#ffd200',
				diff: dynamicFormDiff,
			},
		];
		return data;
	}, [comparePreDetail, compareNextDetail]);

	const onboardingStep: any = useMemo(() => {
		const acc506b = calculateTotalSum(comparePreDetail, '506b');
		const acc506c = calculateTotalSum(comparePreDetail, '506c');
		const kyc = calculateTotalSum(comparePreDetail, 'kyc');
		const kyb = calculateTotalSum(comparePreDetail, 'kyb');
		const aml = calculateTotalSum(comparePreDetail, 'aml');
		const fundPayIn = calculateTotalSum(comparePreDetail, 'payIn');
		const fundPayOut = calculateTotalSum(comparePreDetail, 'payOut');
		const signAgreement = calculateTotalSum(comparePreDetail, 'signAgreement');
		const form = calculateTotalSum(comparePreDetail, 'form');
		const kybForm = calculateTotalSum(comparePreDetail, 'kybForm');
		const dynamicForm = calculateTotalSum(comparePreDetail, 'dynamicForm');
		const allDevice =
			kyc +
			aml +
			signAgreement +
			acc506b +
			acc506c +
			fundPayIn +
			fundPayOut +
			kyb +
			form +
			kybForm +
			dynamicForm;
		const acc506bValue = (acc506b * 100) / allDevice || 0;
		const acc506cValue = (acc506c * 100) / allDevice || 0;
		const kycValue = (kyc * 100) / allDevice || 0;
		const kybValue = (kyb * 100) / allDevice || 0;
		const amlValue = (aml * 100) / allDevice || 0;
		const fundPayInValue = (fundPayIn * 100) / allDevice || 0;
		const fundPayOutValue = (fundPayOut * 100) / allDevice || 0;
		const signAgreementValue = (signAgreement * 100) / allDevice || 0;
		const formValue = (form * 100) / allDevice || 0;
		const kybFormValue = (kybForm * 100) / allDevice || 0;
		const dynamicFormValue = (dynamicForm * 100) / allDevice || 0;

		const data = [
			{
				name: 'KYC',
				y: kycValue,
				sliced: true,
				color: '#356DDE',
				diff: kyc,
			},
			{
				name: 'KYB',
				y: kybValue,
				sliced: true,
				color: '#D335DE',
				diff: kyb,
			},
			{
				name: 'AML',
				y: amlValue,
				sliced: true,
				color: '#DECD35',
				diff: aml,
			},
			{
				name: 'Acc. 506b',
				y: acc506bValue,
				sliced: true,
				color: '#1A9E69',
				diff: acc506b,
			},
			{
				name: 'Acc. 506c',
				y: acc506cValue,
				sliced: true,
				color: '#6922D3',
				diff: acc506c,
			},
			{
				name: 'Sign Agreement',
				y: signAgreementValue,
				sliced: true,
				color: '#35BCDE',
				diff: signAgreement,
			},
			{
				name: 'Funds Pay In',
				y: fundPayInValue,
				sliced: true,
				color: '#DE8A35',
				diff: fundPayIn,
			},
			{
				name: 'Funds Pay Out',
				y: fundPayOutValue,
				sliced: true,
				color: '#DE3535',
				diff: fundPayOut,
			},
			{
				name: 'Form',
				y: formValue,
				sliced: true,
				color: '#A6C431',
				diff: form,
			},
			{
				name: 'KYB Form',
				y: kybFormValue,
				sliced: true,
				color: '#C3276F',
				diff: kybForm,
			},
			{
				name: 'Dynamic Form',
				y: dynamicFormValue,
				sliced: true,
				color: '#ffd200',
				diff: dynamicForm,
			},
		];
		return data;
	}, [comparePreDetail]);

	const stepsChartRender = useMemo(() => {
		return (
			<div className="analytics-wrapper__pie">
				<SemiPieChart
					isLoading={false}
					title="Onboarding Steps"
					seriesData={comparedChart ? comparedOnboardingStep : onboardingStep}
					chartPercent={chartDefaultPercent}
				/>
				<div className="analytics-wrapper__line-wrapper">
					{(comparedChart ? comparedOnboardingStep : onboardingStep).map(
						(item: any) => (
							<div key={item.name}>
								<ChartStatus
									percent={item.y}
									title={item.name}
									color={item.color}
									diff={item.diff}
									avgTime={flowAverageTime[onboardingStepsAvgTime[item.name]]}
								/>
							</div>
						)
					)}
				</div>
			</div>
		);
	}, [
		chartDefaultPercent,
		comparedChart,
		comparedOnboardingStep,
		flowAverageTime,
		onboardingStep,
	]);

	return <>{stepsChartRender}</>;
};
