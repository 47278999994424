import { useMemo } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { BusinessPaymentCardState } from 'global-stores';
import {
	ActivePaymentType,
	AddCardModalState,
	PaymentNavigationState,
} from 'views/settings/billing/stores';
import { PaymentNavigation } from '../../constant';

export const usePaymentSuccess = () => {
	const businessPaymentCardState = useRecoilValue(BusinessPaymentCardState);
	const setOpenView = useSetRecoilState(AddCardModalState);
	const setAddPaymentNaviagtion = useSetRecoilState(PaymentNavigationState);
	const resetaddCardModalState = useResetRecoilState(AddCardModalState);
	const restPaymentNavigationState = useResetRecoilState(
		PaymentNavigationState
	);
	const restActivePaymentType = useResetRecoilState(ActivePaymentType);

	const hasBillingAddress = useMemo(() => {
		if (!businessPaymentCardState) return false;
		else if (
			Object.values(businessPaymentCardState?.billingAddress ?? {})?.every(
				el => el
			)
		)
			return true;
		else return false;
	}, [businessPaymentCardState]);

	const handleClose = () => {
		setOpenView({
			isOpen: false,
			isEdit: false,
		});
		setTimeout(() => {
			resetaddCardModalState();
			restPaymentNavigationState();
			restActivePaymentType();
		}, 500);
	};

	const addBillingInfo = () => {
		setAddPaymentNaviagtion(PaymentNavigation.Billing);
	};
	return {
		addBillingInfo,
		handleClose,
		hasBillingAddress,
	};
};
