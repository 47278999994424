import { useLayoutEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ConditionalOnboardingFlowState } from '../../store';
import {
	IsPayAtGoStatusState,
	OnboardingPaymentCurrentPage,
	OnboardingPaymentType,
	OnboardingPaymentTypeState,
	PayNowCurrentPage,
} from '../store';
import { InviteOnboardingPaymentBody } from './pay-now-module';
import { SwitchScreenToggle } from './toggle-screen';

export const SubscriptionWrapper = () => {
	const [isToggleActive, setIsToggleActive] =
		useRecoilState(IsPayAtGoStatusState);
	const currentMonthlyScreen = useRecoilValue(OnboardingPaymentCurrentPage);
	const setPaymentType = useSetRecoilState(OnboardingPaymentTypeState);
	const currentPayNowScreen = useRecoilValue(PayNowCurrentPage);
	const isComplex = useRecoilValue(ConditionalOnboardingFlowState);
	// // this will manage the visibility of the (payNow | monthly-billing) toggle in the payment screen

	useLayoutEffect(() => {
		if (isComplex) {
			setIsToggleActive(false);
			setPaymentType(OnboardingPaymentType.MONTHLY_BILLING);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isComplex]);

	const screenToggleVisibility =
		!/checkout|address_verification/gi.test(currentMonthlyScreen) &&
		!/processing_payment|success/gi.test(currentPayNowScreen);

	return (
		<>
			{screenToggleVisibility && (
				<SwitchScreenToggle
					isToggle={isToggleActive}
					setIsToggle={setIsToggleActive}
				/>
			)}
			<InviteOnboardingPaymentBody />
		</>
	);
};
