import type { RangeKeyDict } from 'react-date-range';

import { DateRangePicker, defaultStaticRanges } from '@storybook';
import classNames from 'classnames';
import { endOfDay, isSameDay } from 'date-fns';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { kycSettingsState, SelectedDateRangeState } from 'global-stores';
import { useLocalStorage, useOutsideClick } from 'hooks';
import { getDate } from 'utils';
import { AssignSelectedSessionState } from 'views/sessions';
import './date-picker.scss';
import { createdAt } from 'constant';

interface IDatePicker {
	onSubmit?: any;
	stateType?: any;
}

interface IDateRange {
	startDate: Date;
	endDate: Date;
	key: string;
}

export const DatePicker: FC<IDatePicker> = ({ onSubmit, stateType }) => {
	const [isVisible, setIsVisible] = useState<boolean>(false);
	const [count, setCount] = useState<number>(0);

	const kycSettings = useRecoilValue(kycSettingsState);

	const [selectedDateRange, setSelectedDateRange] = useRecoilState<any>(
		stateType ?? SelectedDateRangeState
	);
	const setSelectedSession = useResetRecoilState(AssignSelectedSessionState);

	const [range, setRange] = useState<IDateRange[] | any>();
	const { set: setLocalStorage } = useLocalStorage();

	const { settings } = useMemo(() => kycSettings, [kycSettings]);
	const { brandColor } = settings;

	useEffect(() => {
		setRange(selectedDateRange);
	}, [selectedDateRange]);

	useEffect(() => {
		if (range) {
			const isSame = isSameDay(range[0].startDate, range[0].endDate);
			if (!isSame || count > 1) {
				setLocalStorage('date-range', range[0]);
				setCount(0);
			}
		}
	}, [count, range, setLocalStorage]);

	const handleOpenDatePicker = useCallback(() => {
		setIsVisible(true);
	}, []);

	const handleChangeDateRange = useCallback(({ selection }: RangeKeyDict) => {
		if (selection) {
			setRange([selection as any]);
			setCount(prev => prev + 1);
		}
	}, []);

	const handleApplyDateRange = useCallback(() => {
		setIsVisible(false);
		setSelectedDateRange(range);
		setLocalStorage('date-range', range[0]);
		if (onSubmit) onSubmit();
		setSelectedSession();
	}, [
		setSelectedDateRange,
		range,
		setLocalStorage,
		onSubmit,
		setSelectedSession,
	]);

	const tilldate = endOfDay(new Date());

	const renderedDate = useMemo(() => {
		const { endDate, startDate }: any = selectedDateRange[0];

		const selectedRange = defaultStaticRanges(createdAt).find(
			date =>
				date.range().startDate === startDate && date.range().endDate === endDate
		);

		if (
			JSON.stringify(startDate) === JSON.stringify(createdAt) &&
			JSON.stringify(endDate) === JSON.stringify(tilldate)
		) {
			return 'ALL';
		}
		if (selectedRange?.label) {
			return selectedRange.label;
		}
		const isSame = isSameDay(startDate, endDate);
		const fromDate = getDate(startDate, 'MMM dd');
		const toDate = getDate(endDate, 'MMM dd');
		if (isSame) {
			return fromDate;
		}

		return (
			<>
				<div className="selected-range--date">{fromDate}</div>
				<div className="selected-range--to">to</div>
				<div className="selected-range--date">{toDate}</div>
			</>
		);
	}, [selectedDateRange, tilldate]);

	const selectedClass = classNames('calender-btn', {
		'calender-btn--active': isVisible,
	});

	const arrowIconClass = classNames('ri-arrow-down-s-line', {
		'arrow-icon--active': isVisible,
	});

	const ref: any = useRef();
	useOutsideClick(ref, () => {
		setIsVisible(false);
	});

	return (
		<div className="onboarding">
			<div onClick={handleOpenDatePicker} className={selectedClass}>
				<i className="ri-calendar-event-fill Date-picker--calendar-icon" />
				<span className="selected-range">{renderedDate}</span>
				<i className={arrowIconClass} />
			</div>
			<div className="picker-container" ref={ref}>
				{isVisible && (
					<DateRangePicker
						range={range}
						rangeColor={[brandColor]}
						handleChangeRange={handleChangeDateRange}
						handleSubmit={handleApplyDateRange}
						setIsVisible={setIsVisible}
					/>
				)}
			</div>
		</div>
	);
};
