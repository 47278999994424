import { FC, ReactNode } from 'react';

interface IAuthLayout {
	width: number;
	minHeight: number;
	children: ReactNode[];
	className?: string;
}

export const AuthLayout: FC<IAuthLayout> = ({
	width,
	minHeight,
	children,
	className = '',
}) => {
	return (
		<div className="AuthLayout">
			<div
				className={`AuthLayout-Card ${className}`}
				style={{ width, minHeight }}
			>
				{children}
			</div>
		</div>
	);
};
