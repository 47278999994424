export const BillingAction: any = {
	kyc: 'KYC',
	'kyc(with liveness)': 'KYC with liveness',
	aml: 'AML',
	'accreditation(506b)': 'Accreditation 506B',
	'accreditation(506c)': 'Accreditation 506C',
	signAgreement: 'Sign Agreement',
	signAgreement_esign: 'Simplici Sign',
	signAgreement_docusign: 'Docusign',
	accreditation: 'Accreditation',
	fundInvestment: 'Fund Investment',
	'Fund Pay In': 'Fund Pay In',
	'Fund Pay Out': 'Fund Pay Out',
	kyb: 'KYB',
	'signAgreement(esign)': 'Simplici Sign',
	'signAgreement(docusign)': 'Docusign',
};

export const BillingItemName: any = {
	kyc: 'kyc',
	'kyc(with liveness)': 'kyc(with liveness)',
	aml: 'aml',
	'accreditation(506b)': '506B',
	'accreditation(506c)': '506C',
	signAgreement: 'signAgreement',
	signAgreement_esign: 'signAgreement_esign',
	signAgreement_docusign: 'signAgreement_docusign',
	accreditation: 'accreditation',
	fundInvestment: 'fundInvestment',
	'Fund Pay In': 'payIn',
	'Fund Pay Out': 'payOut',
	kyb: 'kyb',
	'signAgreement(esign)': 'signAgreement_esign',
	'signAgreement(docusign)': 'signAgreement_docusign',
};

export const BillingHeaderItemName: any = {
	kyc: 'KYC',
	'kyc(with liveness)': 'KYC(with liveness)',
	aml: 'AML',
	'506B': '506B',
	'506C': '506C',
	signAgreement: 'SignAgreement',
	signAgreement_esign: 'SignAgreement_Simplici Sign',
	signAgreement_docusign: 'SignAgreement_Docusign',
	accreditation: 'Accreditation',
	fundInvestment: 'FundInvestment',
	'Fund Pay In': 'PayIn',
	'Fund Pay Out': 'PayOut',
	kyb: 'KYB',
};

export const NON_LIVE_MODE_ERROR =
	'Please switch to live mode for this service';

export const Billing_Transactions_Header = [
	{
		key: 'paymentId',
		label: 'Transaction ID',
		icon_type: 'sorting',
	},
	{
		key: 'time',
		label: 'Date & Time',
		icon_type: 'sorting',
	},
	{
		key: 'amount',
		label: 'Amount (USD)',
		icon_type: '',
	},
	{
		key: 'type',
		label: 'Type',
		icon_type: '',
	},
	{
		key: 'paymentMethod',
		label: 'Payment Method',
		icon_type: '',
	},
	{
		key: 'status',
		label: 'Status',
		icon_type: '',
	},
];

export const Arrange_Order: { [key: string]: number } = {
	transactionId: 1,
	time: 2,
	amount: 3,
	type: 4,
	card: 5,
	status: 6,
};

export const paymentMethod = {
	credit: 'credit card',
	debit: 'debit card',
	'credit card': 'credit card',
	'debit card': 'debit card',
	ach: 'ACH',
	wire: 'wire',
};

export const paymentCardTypes = [
	{ key: 'CardView', icon: 'ri-bank-card-fill', id: 'CardView', label: 'Card' },
	{ key: 'AchView', icon: 'ri-bank-fill', id: 'AchView', label: 'ACH' },
	{ key: 'WireView', icon: 'ri-links-fill', id: 'Wire', label: 'Wire' },
];

export const PaymentNavigation = {
	ChoosePage: 'Choose',
	Processing: 'Processing',
	Success: 'Success',
	Billing: 'Billing',
	WireScreen: 'Wire',
	CardScreen: 'Card',
	CardView: 'CardView',
	AchView: 'AchView',
	WireView: 'WireView',
	UpdateAddressSuccess: 'UpdateAddressSuccess',
	DefaultAchPage: 'AchDefaultAccount',
};

export const PaymentCardType: Record<string, string> = {
	Card: 'CardView',
	Ach: 'AchView',
	Wire: 'WireView',
};

export const TransactionCardType: Record<string, string> = {
	SubscriptionCard: 'Subscription-Card'
};

export const BillingAddressFrom: { [key: string]: 'from_billing_info' } = {
	FromBillingInfo: 'from_billing_info',
};

export const DefaultCardType = {
	Ach: 'ach',
	Card: 'card',
	Wire: 'wire',
};

export const ExpiredCardModalDetails = {
	Header: 'Card Expiry Alert',
	Description:
		'To ensure uninterrupted service, please update your payment information with a card that has a longer expiration date. This will help us keep your payments smooth and hassle-free.',
	BtnLabel: 'Add a new card',
};

export const BILLING_PERMISSION_TEXT = {
	Reintiate: 'You are not permitted to Reintiate',
	Subscribe: 'You are not permitted to Subscribe or Upgrade to Account type',
	PaymentMethod: 'You are not permitted to change payment method',
};

export const BILLING_SUB_ACCOUNT_HEADER = [
	{
		label: 'Name',
		key: 'name',
		format: 'string',
		width: '20%',
		className: 'sub-account-name-ellipsis',
	},
	{
		label: 'Creation Date',
		key: 'createdAt',
		format: 'date',
		width: '20%',
	},
	{
		label: 'Users',
		key: 'totalUsers',
		format: 'jsx',
		width: '10%',
	},
	{
		label: 'Billing Under',
		key: 'billingType',
		format: 'jsx',
		width: '20%',
	},
	{
        label: 'Monthly Subs. Cost',
        key: 'monthlySubsCost',
        format: 'jsx',
        width: '20%',
    },
    {
        label: 'Total (Last Bill)',
        key: 'totalLastBill',
        format: 'jsx',
        width: '10%',
    },
    {
        label: 'Total to Date',
        key: 'totalToDate',
        format: 'jsx',
        width: '10%',
    },
];
