import { useMemo } from 'react';

import { OnboardingHeader } from 'views/onboarding-flow';
import { Banner } from 'views/user-verification';
import './complex-onboarding-flow.scss';
import { RightFlowContainer } from './components';
import { useComplexOnboarding } from './hooks';

export const ComplexOnboardingFlow = ({ handleOnDrop }: any) => {
	const { bannerStatus, isIframeOpen, background } = useComplexOnboarding();

	const getHeader = useMemo(() => <OnboardingHeader />, []);

	return (
		<div
			id="complex-onboarding-main"
			className="OnboardingFlow--container"
			style={{ backgroundColor: background }}
		>
			{/* Header */}
			{bannerStatus === false && <Banner />}
			{getHeader}

			{/* UI Wrapper */}
			<div
				className="OnboardingFlow--contain"
				style={{
					...(isIframeOpen && {
						marginTop: 0,
					}),
				}}
			>
				<RightFlowContainer handleOnDrop={handleOnDrop} />
			</div>
		</div>
	);
};
