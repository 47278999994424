import type {
	IPacketRecipientsKYCDetails,
	ISignDocHistoryEntries,
} from './types';

import { atom } from 'recoil';

export const SignDocHistoryEntriesState = atom<ISignDocHistoryEntries[]>({
	key: 'sign-doc-history-entries',
	default: [],
});

export const PacketNotaryDetails = atom({
	key: 'packet-notary-details-state-key',
	default: {},
});

export const PacketRecipientsKYCDetails = atom<IPacketRecipientsKYCDetails>({
	key: 'packet-recipient-kyc-status-state-key',
	default: {},
});
