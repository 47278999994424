/* eslint-disable react/no-array-index-key */
import { useMemo } from 'react';

import { BodyWrapper } from '../common';
import { IndentityVerificationObject } from 'views/mobile-ui-screen/constants';

export const IndentityVerification = () => {
	const labelElement = useMemo(() => {
		return (
			<div className="ppl-screen-body__label-container">
				<div className="ppl-screen-body__label-container__text">
					Identity Verification
				</div>
			</div>
		);
	}, []);

	const headerElement = useMemo(() => {
		return (
			<div className="ppl-screen-body__body__title-wrapper">
				<div className="ppl-screen-body__body__title-wrapper__title">
					Choose ID you would like to use
				</div>
				<div className="ppl-screen-body__body__title-wrapper__subtitle">
					It must be an official Photo ID
				</div>
			</div>
		);
	}, []);
	const bodyElement = useMemo(() => {
		return (
			<div className="ppl-screen-body__body__list-wrapper">
				{IndentityVerificationObject?.map(
					({ leftIcon, name, rightIcon }, index) => {
						return (
							<div className="list-item" key={index}>
								<img loading="lazy" src={leftIcon} alt="car-icon" height='100%' />
								<span className="list-text">{name}</span>
								<img loading="lazy" src={rightIcon} alt="next-icon" />
							</div>
						);
					}
				)}
			</div>
		);
	}, []);
	return (
		<BodyWrapper
			label={labelElement}
			headerElement={headerElement}
			bodyElement={bodyElement}
		/>
	);
};
