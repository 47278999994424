import { useCallback } from 'react';

// Awadhesh : for survey JS
export const useAddDollarCurrencySurvey = () => {
	const modifyElements = (elements: any[]) => {
		const newElement = [];
		for (let i = 0; i < elements?.length; i++) {
			if (elements[i].inputMask?.includes('currency')) {
				const payload = {
					...elements[i],
					prefix: '$',
				};
				newElement.push(payload);
			} else {
				newElement.push(elements[i]);
			}
		}
		return newElement;
	};

	const modifyPagesResponse = useCallback((pages: any) => {
		const newFinalArry = [];

		for (let i = 0; i < pages?.length; i++) {
			const newPayload = {
				...pages[i],
				elements: modifyElements(pages[i].elements),
			};
			newFinalArry.push(newPayload);
		}
		return newFinalArry;
	}, []);

	return { modifyPagesResponse };
};
