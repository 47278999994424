import { FC, useEffect, useMemo, useState } from 'react';

import { Image } from '@storybook';
import { FileTypes } from './constants';

interface IProgress {
	fileName: string;
	uploadPercentage: number;
	label: string;
}

export const Progress: FC<IProgress> = ({ uploadPercentage, label }) => {
	const [count, setCount] = useState<number>(uploadPercentage);

	useEffect(() => {
		setCount(uploadPercentage);
	}, [uploadPercentage]);

	useEffect(() => {
		if (count < 100) {
			setTimeout(() => {
				setCount(prev => prev + 1);
			}, 100);
		}
	}, [count]);

	const fileName = useMemo(() => {
		const splitedFile = label.split('.');
		const fileType: string | any =
			splitedFile[splitedFile.length - 1]?.toUpperCase();
		if (FileTypes[fileType]) {
			return fileType + '.svg';
		}
		return 'Default.svg';
	}, [label]);

	return (
		<div className="progessbar__box">
			<div className="progessbar__icon">
				<Image fileName={fileName} width="24px" />
			</div>
			<div className="progessbar__right_box">
				<div className="progessbar__labelbox">
					<p className="progessbar__label-text">{label}</p>
					<div className="progessbar__progess-count">
						{count !== 100 ? 'uploading...' : count + '%'}
					</div>
				</div>
				<div className="progessbar__bar">
					<div
						className="progessbar__slider"
						style={{
							width: `${count}%`,
						}}
					/>
				</div>
			</div>
			{/* Delete Icon May, We will use in Future */}
			{/* <div className="progessbar__icon">
      <i className="ri-close-circle-line"></i>
      </div> */}
		</div>
	);
};
