import { NoDataAvailable, ReactResponsiveTable } from '@storybook';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ConfirmationModal } from 'components';
import { message } from 'constant';
import { ShowDocsState, SignedDocsState, useSignedDocs } from 'global-stores';
import { PERMISSION_SCREEN, useUserRoles } from 'views/routes-children';
import { provider, signedDocsHeader } from '../constant';
import { SignedDocTemplateAction } from './agreement-table-action';
import { EditTemplateName } from './edit-template-name';

interface IAgreementTable {
	handleOpen: () => void;
}

export const AgreementTable: FC<IAgreementTable> = ({ handleOpen }) => {
	const [tableRows, setTableRows] = useRecoilState(ShowDocsState);
	const signedDocs = useRecoilValue(SignedDocsState);
	const [isConfirmationModal, setIsConfirmationModal] =
		useState<boolean>(false);
	const [deleteID, setDeleteId] = useState<string>('');
	const [deleteIndex, setDeleteIndex] = useState<number | any>();
	const [isDeleted, setDeleted] = useState<boolean>(false);
	const [isTableLoading, setIsTableLoading] = useState(false);

	const {
		getDocuments,
		isLoading: documentsLoading,
		isLoaded: documentsLoaded,
	} = useSignedDocs();

	const { BOLD_DESCRIPTION, DESCRIPTION_MESSAGE, TITLE_MESSAGE } = useMemo(
		() => message,
		[]
	);

	const { deleteDocument } = useSignedDocs();

	const { checkUserWritePermission } = useUserRoles();

	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.Templates),
		[checkUserWritePermission]
	);

	useEffect(() => {
		if (!signedDocs.length) {
			getDocuments();
		}
		// eslint-disable-next-line
	}, []);

	const handleOpenFile = useCallback((fileUrl: string) => {
		window.open(fileUrl, '_blank');
	}, []);

	const handleDeleteModel = useCallback((isOpen: boolean, value: any) => {
		setIsConfirmationModal(isOpen);
		setDeleted(value);
	}, []);

	const handleDeleteTemplate = useCallback(
		(templateId: string, index: number) => {
			setIsConfirmationModal(true);
			setDeleteId(templateId);
			setDeleteIndex(index);
		},
		[]
	);

	useEffect(() => {
		if (isDeleted) {
			deleteDocument(deleteID, deleteIndex);
			setDeleted(false);
		}
		// eslint-disable-next-line
	}, [isDeleted, deleteDocument]);

	const labelSize = useCallback((size: string) => {
		if (/mb/gi.test(size)) {
			const numSize = size.replace(/mb/gi, '').trim();
			return `${parseFloat(numSize).toFixed(2) ?? 0} MB`;
		}
		if (parseFloat(size) > 1048576) {
			return `${(parseInt(size ?? 0) / 1048576).toFixed(2)} MB`;
		}
		return `${(parseInt(size ?? 0) / 1024).toFixed(2)} KB`;
	}, []);

	useEffect(() => {
		setIsTableLoading(true);
		const rows: any = [];
		signedDocs.forEach((item: any, index: number) => {
			if (item) {
				let row: any = {};
				row.providerName = item?.provider;
				row.name = item.name ?? item.documentName;
				row.documentName = item?.documentName1;
				row.type = item?.type1;
				row.id = item.templateId?.trim();
				signedDocsHeader.HEADER.forEach(({ format, key }) => {
					if (key === 'documentName') {
						const value = () => (
							<EditTemplateName item={item} keyName={key} index={index} />
						);
						return (row[key] = value);
					}
					if (key === 'documentId') {
						const templateId = item[key] ?? item.templateId;
						return (row[key] = templateId);
					}
					if (format === 'jsx' && key === 'action') {
						const value = () => (
							<SignedDocTemplateAction
								item={item}
								handleDelete={handleDeleteTemplate}
								index={index}
								isUserPermissionWrite={isUserPermissionWrite}
							/>
						);
						return (row[key] = value);
					}
					if (key === 'provider') {
						const providerKey = item[key];
						const docProvider = provider[providerKey?.toLowerCase()] ?? 'esign';
						const value = () => (
							<div>{docProvider === 'esign' ? docProvider : 'Docusign'}</div>
						);
						return (row[key] = value);
					}
					if (key === 'documentSize') {
						let docSize = 0;
						const { docRefId, documents } = item ?? {};
						if (documents) {
							documents.forEach(({ size }: any) => (docSize += Number(size)));
						}
						return (row[key] = labelSize(docRefId?.size ?? docSize));
					}
					if (key === 'type') {
						const type = item[key] ?? 'docusign';
						const value = () => {
							return (
								<div className={`sign-doc-capsule sign-doc-capsule--${type}`}>
									{type}
								</div>
							);
						};
						return (row[key] = value);
					}
					if (key === 'signers') {
						const count = item.recipients?.length ?? 'NA';
						return (row[key] = `${count}`);
					}
					return (row = { ...row, [key]: item[key] });
				});
				rows.push(row);
			}
		});
		setIsTableLoading(false);
		return setTableRows(rows);
	}, [
		handleDeleteTemplate,
		handleOpenFile,
		labelSize,
		setTableRows,
		signedDocs,
		isUserPermissionWrite,
	]);

	const handleIsLoading = useMemo(() => {
		if (!documentsLoading && !isTableLoading && documentsLoaded) return false;
		else return true;
	}, [documentsLoading, isTableLoading, documentsLoaded]);

	const getComponent = useMemo(() => {
		if (!tableRows.length && !handleIsLoading) {
			return (
				<NoDataAvailable
					label="Create New"
					description="You have not created agreement document yet."
					handleClickBtn={handleOpen}
					message="No Agreement Document Available"
					illustration="empty-aggrement-docs.svg"
					hideBtn={!isUserPermissionWrite}
				/>
			);
		}
		return (
			<ReactResponsiveTable
				isLoaded={documentsLoaded}
				isLoading={documentsLoading}
				column={signedDocsHeader.HEADER}
				rows={tableRows}
				showSearch={true}
				showDateFilter={true}
			/>
		);
	}, [
		documentsLoading,
		documentsLoaded,
		tableRows,
		handleOpen,
		handleIsLoading,
		isUserPermissionWrite,
	]);

	return (
		<div>
			{getComponent}
			<ConfirmationModal
				visible={isConfirmationModal}
				title={TITLE_MESSAGE}
				description={DESCRIPTION_MESSAGE}
				boldDescription={BOLD_DESCRIPTION}
				handleModal={handleDeleteModel}
				label="Delete"
			/>
		</div>
	);
};
