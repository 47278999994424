import { SelectedPipelineDetails } from "global-stores";
import { selector } from "recoil";

export const SelectedOnboardingFlowSelector = selector({
	key: 'selected-onboarding-flow-selector-key',
	get: ({ get }) => {
        const selectedOnboardingFlow = get(SelectedPipelineDetails) || {};
        const onboardingType = selectedOnboardingFlow.type;
        const complexNodes = selectedOnboardingFlow.nodes;
        const linearActions = selectedOnboardingFlow.actions;
        return {
            selectedOnboardingFlow, 
            onboardingType,
            complexNodes,
            linearActions
        }
    },
});
