import { useRecoilValue } from 'recoil';

import {
	ClientsCredentialsState,
	WebTokenState,
	loginState,
} from 'global-stores';
import { useCookie } from 'hooks';
import { isValidImageUrl } from 'utils';
import { REACT_APP_AUTH_API_URL } from 'envs';
import { AuthProvidersState } from './states';

export const useInstance = () => {
	const { accessToken: token } = useRecoilValue(loginState);
	const { get: getCookie } = useCookie();
	const localUserData = getCookie('user');
	const webToken = useRecoilValue(WebTokenState);

	const providers = useRecoilValue(AuthProvidersState);

	const accessToken =
		webToken?.length > 0 ? webToken : token || localUserData?.accessToken;
	const AUTH_URL = `${REACT_APP_AUTH_API_URL}/instances`;
	const AUTH_PROVIDER_URL = `${REACT_APP_AUTH_API_URL}/providers`;
	const getInstances = async (page = 1, limit = 10) => {
		const data = await fetch(
			`${AUTH_URL}?page=${page ?? 1}&limit=${limit ?? 10}`,
			{
				headers: {
					'Content-type': 'application/json; charset=UTF-8',
					Authorization: 'Bearer ' + accessToken,
				},
			}
		);

		const response = await data.json();

		return response;
	};

	const getProviders = async () => {
		try {
			const data = await fetch(AUTH_PROVIDER_URL, {
				headers: {
					'Content-type': 'application/json; charset=UTF-8',
					Authorization: 'Bearer ' + accessToken,
				},
			});
			const response = await data.json();
			return response;
		} catch (error) {
			return error;
		}
	};

	const getInstanceDetails = async (id: string) => {
		if (!id) {
			return;
		}
		const data = await fetch(`${AUTH_URL}/${id}`, {
			headers: {
				'Content-type': 'application/json; charset=UTF-8',
				Authorization: 'Bearer ' + accessToken,
			},
		});

		const response = await data.json();

		return response;
	};

	const updateInstance = async (id: string, payload: any) => {
		const data = await fetch(`${AUTH_URL}/${id}`, {
			// Adding method type
			method: 'PATCH',
			// Adding body or contents to send
			body: JSON.stringify(payload),

			// Adding headers to the request
			headers: {
				'Content-type': 'application/json; charset=UTF-8',
				Authorization: 'Bearer ' + accessToken,
			},
		});
		const response = await data.json();

		const resp = { ...response, status: data.status };
		return resp;
	};

	const createInstance = async (payload: any) => {
		const data = await fetch(`${AUTH_URL}/`, {
			// Adding method type
			method: 'POST',
			// Adding body or contents to send
			body: JSON.stringify(payload),
			// Adding headers to the request
			headers: {
				'Content-type': 'application/json; charset=UTF-8',
				Authorization: 'Bearer ' + accessToken,
			},
		});
		const response = await data.json();

		const resp = { ...response, status: data.status };

		return resp;
	};

	const getPayload = async (formFields: any) => {
		const payload: any = {};
		let hasError = false;
		let firstErrorId = undefined;
		const obj = JSON.parse(JSON.stringify(formFields));

		for (let i = 0; i < obj.length; i++) {
			const { payloadType, key } = obj[i] ?? {};

			const fields: Record<string, any> = {};
			const methods = {
				biometric: {},
				methods: {},
			};
			for (let j = 0; j < obj[i].metaData?.length; j++) {
				const {
					name,
					value,
					regex,
					type,
					id,
					defaultValue,
					options,
					ignore,
					payloadKey: childPayloadKey,
				} = obj[i].metaData[j];

				if (!ignore) {
					if (regex && type !== 'textArray') {
						const pattern = new RegExp(regex, 'i');
						if (!pattern.test(value)) {
							obj[i].metaData[j].isError = true;
							hasError = true;

							if (!firstErrorId) {
								firstErrorId = id;
							}
						}
					}
					if (name === 'whitelistingUrls') {
						if (!value?.length) {
							obj[i].metaData[j].isError = true;
							hasError = true;
							if (!firstErrorId) {
								firstErrorId = id;
							}
						} else {
							fields[name] = value;
						}
					} else if (name === 'logo') {
						if (obj[i].metaData[j].default) {
							const isValid = await isValidImageUrl(obj[i].metaData[j].default);

							if (!isValid) {
								obj[i].metaData[j].isError = true;
								hasError = true;

								if (!firstErrorId) {
									firstErrorId = id;
								}
							} else {
								fields[name] = obj[i].metaData[j].default;
							}
						} else {
							fields[name] = value;
						}
					} else if (name === 'gestureSetting') {
						const objValue = {};

						for (let k = 0; k < options.length; k++) {
							const { name, value, checked } = options[k] ?? {};
							if (checked) {
								Object.assign(objValue, { [name]: value });
							}
						}

						Object.assign(methods.biometric, { [name]: objValue });
					} else if (key === 'providers') {
						if (value) {
							fields[name] = defaultValue;
						}
					} else if (key === 'signUp') {
						if (value) {
							fields[name] = value;
						}
					} else if (key === 'methods') {
						if (
							childPayloadKey === 'biometric' &&
							name !== 'simpliciBiometric'
						) {
							Object.assign(methods.biometric, { [name]: value });
						} else {
							Object.assign(methods.methods, { [name]: value });
						}
					} else {
						fields[name] = value;
					}
				}
			}

			if (key === 'methods') {
				Object.assign(payload, {
					['settings']: {
						['biometric']: methods.biometric,
					},
				});

				Object.assign(payload, {
					['methods']: methods.methods,
				});
			} else if (key === 'signUp') {
				const { required, ...rest } = fields;

				Object.assign(payload, {
					[obj[i].key]: {
						...payload[obj[i].key],
						...{
							required: required ?? false,
							scope: Object.keys(rest).filter(el => el !== 'onboardingflow'),
						},
					},
				});

				Object.assign(payload, {
					['onboardingflow']: rest.onboardingflow ?? null,
				});
			} else if (payloadType === 'keyValuePair') {
				Object.assign(payload, { ...fields });
			} else if (payloadType === 'keyArrayPair') {
				const allFields = Object.values(fields);
				const value = allFields.length ? allFields : undefined;
				Object.assign(payload, { [obj[i].key]: value });
			} else {
				Object.assign(payload, {
					[obj[i].key]: { ...payload[obj[i].key], ...fields },
				});
			}
		}

		const defaultRefesh = {
			refresh: {
				reuseInterval: 5000000000,
				expiration: 50000000,
			},
		};

		Object.assign(payload, defaultRefesh);

		return {
			hasError,
			firstErrorId,
			payload,
			fields: obj,
		};
	};

	const getValues = (
		payloadType: string,
		parentKey: string,
		key: string,
		instanceDetails: any
	) => {
		if (payloadType === 'keyValuePair') {
			return instanceDetails[key];
		}
		return instanceDetails[parentKey]?.[key] ?? '';
	};

	const client = useRecoilValue(ClientsCredentialsState);

	const initialInstanceSetup = (
		data: any,
		instanceDetails: any,
		complexLists?: any
	) => {
		const array = JSON.parse(JSON.stringify(data));
		for (let i = 0; i < array.length; i++) {
			let isAllTrue = false;
			for (let j = 0; j < array[i].metaData.length; j++) {
				const { key, payloadType } = array[i] ?? {};

				const {
					name: metaName,
					options,
					payloadKey: childPayloadKey,
				} = array[i].metaData[j] ?? {};

				if (metaName === 'authId') {
					array[i].metaData[j].value = getValues(
						payloadType,
						key,
						array[i].metaData[j].name,
						instanceDetails
					);
					const clientIdObj = client.find(
						el => el._id === array[i].metaData[j].value
					);
					if (clientIdObj) {
						array[i].metaData[j].default = {
							label: (
								<div className="clientId-dropdown">
									<div className="clientId-dropdown__label">
										{clientIdObj.clientId}
									</div>
									<div className="clientId-dropdown__sublabel">
										{clientIdObj.clientSecret}
									</div>
								</div>
							),
							value: clientIdObj?._id,
						};
					}
				} else if (key === 'methods') {
					if (metaName === 'gestureSetting') {
						for (let k = 0; k < options.length; k++) {
							if (
								array[i].metaData[j].options[k] &&
								instanceDetails['settings'][childPayloadKey][metaName]?.[
									options[k]?.name
								]
							) {
								array[i].metaData[j].options[k].value =
									instanceDetails['settings'][childPayloadKey][metaName][
										options[k].name
									];

								if (
									instanceDetails['settings'][childPayloadKey][metaName][
										options[k].name
									]
								) {
									array[i].metaData[j].options[k].checked = true;
								}
							}
						}
					}

					if (metaName === 'warning') {
						array[i].metaData[j].isHidden = !!instanceDetails['onboardingflow'];
					}
					if (childPayloadKey === 'biometric') {
						if (metaName === 'simpliciBiometric') {
							array[i].metaData[j].value = instanceDetails[key][metaName];
							array[i].metaData[j].isHidden =
								!instanceDetails['onboardingflow'];
						} else {
							array[i].metaData[j].value =
								instanceDetails['settings'][childPayloadKey][metaName];
							array[i].metaData[j].isHidden =
								!instanceDetails[key]['simpliciBiometric'];
						}
					} else {
						array[i].metaData[j].value = getValues(
							payloadType,
							key,
							array[i].metaData[j].name,
							instanceDetails
						);
					}
				} else if (key === 'providers') {
					array[i].metaData[j].value = instanceDetails[key].includes(
						array[i].metaData[j].defaultValue
					);
				} else if (key === 'signUp') {
					if (/email|phone/.test(metaName)) {
						array[i].metaData[j].value = instanceDetails[key].scope.includes(
							array[i].metaData[j].name
						);
					}
					if (metaName === 'onboaringFlowRequired') {
						array[i].metaData[j].value = !!instanceDetails['onboardingflow'];
					}
					if (metaName === 'onboardingflow') {
						const onboaradingFlow =
							complexLists?.find(
								(el: any) => el._id == instanceDetails[metaName]
							) ?? {};
						array[i].metaData[j].value = instanceDetails[metaName];
						array[i].metaData[j].default = {
							label: onboaradingFlow.onBoardingName,
							value: onboaradingFlow._id,
						};
						//for required or not toggle
						array[i].metaData[0].value = true;
						array[i].metaData[j].isHidden = false;
						array[i].metaData[j].disabled = !instanceDetails[metaName];
					}
				} else {
					array[i].metaData[j].value = getValues(
						payloadType,
						key,
						array[i].metaData[j].name,
						instanceDetails
					);
				}

				if (isAllTrue) {
					array[i].metaData[j].isHidden = false;
				}

				if (
					(metaName === 'required' || metaName === 'onboaringFlowRequired') &&
					array[i].metaData[j].value
				) {
					isAllTrue = true;
				}
			}
		}

		return array;
	};

	return {
		getInstanceDetails,
		getInstances,
		updateInstance,
		createInstance,
		getPayload,
		initialInstanceSetup,
		getProviders,
		providers,
	};
};
