import { Button } from '@storybook';
import { FC, useCallback, useMemo } from 'react';

interface IUploadBox {
	images: any;
	handleRemove: any;
	handleUpload: any;
	type: 'sign' | 'initials';
	label: string;
	handleDrop: any;
	isError: boolean;
}

export const UploadBox: FC<IUploadBox> = ({
	images,
	handleRemove,
	handleUpload,
	type,
	label,
	handleDrop,
	isError

}) => {
	const renderImages = useMemo(() => {
		return (
			<img
				className=""
				id="image-upload"
				src={images[type]}
				alt="pdfImage"
				style={{
					width: '100%',
					height: '100%',
				}}
			/>
		);
	}, [images, type]);
 
	
  const handleDragOver = useCallback((e: any
    ) => {
		e.preventDefault();
		e.stopPropagation();
	},[])

	return (
		<div className="admin-upload-sign__wrapper" onDragOver={handleDragOver} onDrop={handleDrop}>
			<div className="admin-upload-sign__label" >{label}</div>
			{!images[type].length ? (
				<>
					<label
						className="admin-upload-sign"
						htmlFor={`upload-sign-image-${type}`}
						style={{ display: 'flex', flexDirection: 'column'}}
					>
						<div className={`admin-upload-sign__sign-box admin-upload-sign__sign-box${isError ? "--error": ""}`}>
							<i className="ri-file-upload-line admin-upload-sign__icon" />
							<div className="admin-upload-sign__upload-text">
								Drag and drop here or click to upload
							</div>
							<div className="admin-upload-sign__upload-description">
								Supported file format: JPG, PNG Max file size 1MB.
							</div>
						</div>
					</label>
					<input
						type="file"
						accept=".png,image/png,image/jpeg"
						onChange={e => handleUpload(e, type)}
						name=""
						className="admin-upload-sign__input"
						id={`upload-sign-image-${type}`}
					/>
				</>
			) : (
				<div className="admin-upload-sign__sign-img">
					<div className="admin-upload-sign__render-img">{renderImages}</div>
					<Button
						label={'Remove'}
						type="button__filled button__filled--danger button__small"
						handleClick={() => handleRemove(type)}
					/>
				</div>
			)}
		</div>
	);
};
