import { Button, Image } from '@storybook';
import { useSetRecoilState } from 'recoil';
import {
	IndetityFlowNavigate,
	SubscriptionIdentityNaviagtion,
} from 'views/user-identity-flow';
import { ExpiredCardModalDetails } from '../constant';
import './expire-card-modal.scss';

export const ExpireCardAlert = () => {
	const setNavigate = useSetRecoilState(SubscriptionIdentityNaviagtion);

	const handleBackToPaymentCard = () => {
		setNavigate(IndetityFlowNavigate.PaymentCard);
	};

	return (
		<div className="ExpireCardAlert">
			<div className="ExpireCardAlert__img">
				<Image
					fileName="Frame_427320119.svg"
					className="payNow-processing__image"
				/>
			</div>

			<div className="ExpireCardAlert--body">
				<div className="ExpireCardAlert--body__heading">
					{ExpiredCardModalDetails.Header}
				</div>
				<div className="ExpireCardAlert__desc">
					<div className="ExpireCardAlert--discription">
						{ExpiredCardModalDetails.Description}
					</div>
				</div>
			</div>
			<div>
				<Button
					label={ExpiredCardModalDetails.BtnLabel}
					type="button button__filled--primary"
					handleClick={handleBackToPaymentCard}
				/>
			</div>
		</div>
	);
};
