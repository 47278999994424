import { ReactResponsiveTable, Tooltip } from '@storybook';
import Modal from '@storybook/new-modal/modal';
import {
	Fragment,
	MouseEvent,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';

import { useInterval } from 'hooks';
import {
	formatNumberWithCommasAndCurrencySignSign,
	getDate,
	getTime,
} from 'utils';
import { Header } from 'views/header';
import {
	CheckDetailsForm,
	CheckFraudDetails,
	FraudReport,
	RevertReportFraud,
	Status,
} from './components';
import {
	AmountStatusIcon,
	CHEQUE_FRAUD_TABLE_HEADER,
	MODAL_CHECK_FRAUD_HEADER,
	StatusLabel,
} from './constants';
import {
	CheckDetailsFormState,
	CheckFraudDetailsState,
	CheckFraudListState,
	IChequeDetailsLists,
	IModal,
	IStatusType,
	SelectedChequeFraudItem,
	useCheckDefence,
} from './stores';

import { PERMISSION_SCREEN, useUserRoles } from 'views/routes-children';
import './cheque-fraud.scss';

export const ChequeFraud = () => {
	const [data, setData] = useState([]);
	const [activeModal, setActiveModal] = useState<IModal>('');
	const [checkDetailsForm, setCheckDetailsForm] = useRecoilState(
		CheckDetailsFormState
	);
	const setSelectedChequeFraudItem = useSetRecoilState(SelectedChequeFraudItem);
	const [loading, setLoading] = useState(false);
	const checkFraudList = useRecoilValue(CheckFraudListState);
	const { getCheckFraudList, getAllBranchs } = useCheckDefence();
	const resetCheckFraudDetails = useResetRecoilState(CheckFraudDetailsState);

	const { checkUserWritePermission } = useUserRoles();

	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.CheckDefense),
		[checkUserWritePermission]
	);

	const StatusJsx = useCallback(
		(fullStatus: { [key: string]: string }) => {
			return (
				<>
					{fullStatus &&
						Object.keys(fullStatus).map(key => {
							return (
								<Fragment key={`session-list-${key}`}>
									<Status className={fullStatus[key]} title={key} />
								</Fragment>
							);
						})}
				</>
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const getAllChequeFraudLists = async () => {
		setLoading(true);
		await getCheckFraudList();
		setLoading(false);
	};

	const handleModal = useCallback((type: IModal) => {
		setActiveModal(type);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleMarkFraud = (
		event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
		item: IChequeDetailsLists
	) => {
		const { transaction } = item.kycMetadata ?? {};
		const { action } = transaction ?? {};
		event.stopPropagation();
		if (action === 'Fraud') {
			handleModal('revertFraud');
			setSelectedChequeFraudItem(item);
		} else if (action === 'pending' || !transaction || !isUserPermissionWrite) {
			return;
		} else {
			handleModal('fraudReport');
			setSelectedChequeFraudItem(item);
		}
	};

	useInterval(() => {
		if (!activeModal) getCheckFraudList();
	}, 5000);

	useEffect(() => {
		if (!checkFraudList.length) getAllChequeFraudLists();
		getAllBranchs();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getAction = (val: string, status: string) => {
		if (val === 'Fraud') {
			return (
				<div className="action-label__markfraud">
					<div>Revert Fraud</div>
				</div>
			);
		} else {
			return (
				<div
					className={`action-label__markfraud ${
						status === 'pending' || !status || !isUserPermissionWrite
							? 'action-label--disabled'
							: ''
					}`}
				>
					Mark as Fraud
				</div>
			);
		}
	};

	useEffect(() => {
		if (checkFraudList?.length && CHEQUE_FRAUD_TABLE_HEADER.length) {
			const rows: any = [];
			checkFraudList.forEach(item => {
				if (item) {
					const { amount, status, action, transactionId } =
						item.kycMetadata?.transaction ?? {};
					let row: any = {};
					row.sessionId = item.sessionId;
					row.userName = item.name;
					row.sendEmail = item.email;
					row.userMobileNumber = item.contact;
					row.potentialRisk = item.potentialRisk;
					row.createdAt = item.time;
					row.isAlreadyPaid = /proceed|deny|denied/.test(status ?? '')
						? true
						: false;
					row.status = StatusLabel[status as IStatusType] ?? 'pending';
					row.amount = amount ?? 0.0;
					row.transactionId = transactionId ?? 'N/A';
					row.currentAction = action;

					CHEQUE_FRAUD_TABLE_HEADER.forEach(({ format, key, key2 }) => {
						if (format === 'jsx' && key === 'transaction') {
							const value = () => (
								<div>
									<div>{transactionId ?? 'N/A'} </div>
									{action === 'Fraud' && (
										<div className="fraud-report-wrapper ">
											<i className="ri-error-warning-fill error-icon"></i>
											<div>Fraud reported</div>
										</div>
									)}
								</div>
							);

							return (row[key] = value);
						}
						if (format === 'jsx' && key === 'name') {
							const value = () => (
								<div className="check-fraud-name">
									<Tooltip text={item[key]} direction="bottom">
										<div className="text-ellipsis w-150">{item[key]}</div>
									</Tooltip>

									{item.potentialRisk && (
										<div className="check-fraud-name__icon-wrapper">
											<i className="ri-alarm-warning-fill"></i>
										</div>
									)}
								</div>
							);
							return (row[key] = value);
						}

						if (
							format === 'jsx' &&
							key === 'fullStatus' &&
							key2 === 'instanceName'
						) {
							const value = () => (
								<div className="status-wrapper">
									{StatusJsx({
										kyc: item?.nodeActions?.status ?? item.status,
									})}
									{item[key2] && <div className="subtd"> {item[key2]}</div>}
								</div>
							);
							return (row[key] = value);
						}

						if (format === 'jsx' && key === 'amount') {
							const value = () => (
								<div className="amount-status">
									<div>
										{amount || amount === 0
											? `${formatNumberWithCommasAndCurrencySignSign(
													amount ?? 0,
													'$'
											  )}`
											: 'N/A'}
									</div>
									{key2 && (
										<div
											className={`subtd align-item-center amount-status__${status?.toLocaleLowerCase()} `}
										>
											<i
												className={
													AmountStatusIcon[
														(status?.toLocaleLowerCase() as string) ?? 'pending'
													]?.icon
												}
											/>

											<div>
												{AmountStatusIcon[status ?? 'pending']?.name ??
													'Pending'}
											</div>
										</div>
									)}
								</div>
							);
							return (row[key] = value);
						}
						if (key === 'date' && key2 === 'time') {
							const value = () => (
								<>
									{getDate(item[key2])}
									{key2 ? (
										<div className="subTd">{getTime(item[key2])}</div>
									) : (
										''
									)}
								</>
							);
							return (row[key] = value);
						}
						if (format === 'jsx' && key === 'email') {
							const value = () => (
								<div>
									<Tooltip text={item[key]} direction="bottom">
										<div className="text-ellipsis">{item[key]}</div>
									</Tooltip>

									{key2 && (
										<div className="subtd">{item[key2 as 'contact']}</div>
									)}
								</div>
							);
							return (row['email'] = value);
						}
						if (format === 'jsx' && key === 'action') {
							const value = () => (
								<div
									className="btn-group action-label"
									onClick={event => handleMarkFraud(event, item)}
								>
									{getAction(action, status)}
								</div>
							);
							return (row[key] = value);
						}
						row = { ...row, [key]: item[key as 'name'] ?? 'N/A' };
						return null;
					});
					rows.push(row);
				}
			});
			return setData(rows);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checkFraudList]);

	const handleClickOnRow = (item: IChequeDetailsLists) => {
		handleModal('viewDetails');
		setCheckDetailsForm({ ...checkDetailsForm, session: item.sessionId });
		setSelectedChequeFraudItem(item);
	};

	const getHeader = (
		type: 'fraudReport' | 'checkFraudform' | 'revertFraud'
	) => {
		return (
			<div className="modal-title-wrapper">
				<div className="report-modal__title">
					{MODAL_CHECK_FRAUD_HEADER[type].LABEL}
				</div>
				<div className="report-modal__subtitle">
					{MODAL_CHECK_FRAUD_HEADER[type].SUB_LABEL}
				</div>
			</div>
		);
	};

	const handleOnCloseCheckDetails = useCallback(() => {
		resetCheckFraudDetails();
		handleModal('');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleOnSubmitForm = useCallback(() => {
		//@avinash : if there is no active modal then call check fraud list immediately
		getAllChequeFraudLists();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const {
		modal,
		component,
		onClose,
		className,
		isStopOutsideClick,
		rootStyle,
		header,
	} = useMemo(() => {
		switch (activeModal) {
			case 'fraudReport':
				return {
					modal: true,
					component: (
						<FraudReport
							handleClose={(e: IModal) => handleModal(e)}
							onComplete={handleOnSubmitForm}
						/>
					),
					className: 'report-modal',
					onClose: () => handleModal(''),
					header: getHeader(activeModal),
				};
			case 'revertFraud':
				return {
					modal: true,
					component: (
						<RevertReportFraud
							handleClose={(e: IModal) => handleModal(e)}
							onComplete={handleOnSubmitForm}
						/>
					),
					className: 'revert-report-modal',
					onClose: () => handleModal(''),
					header: getHeader(activeModal),
				};
			case 'checkFraudform':
				return {
					modal: true,
					component: (
						<CheckDetailsForm
							handleBack={(type: IModal) => handleModal(type)}
						/>
					),
					className: 'check-details-modal',
					rootStyle: {
						overlay: {
							zIndex: 999,
						},
					},
					onClose: () => handleModal(''),
					header: getHeader(activeModal),
				};
			case 'viewDetails':
				return {
					modal: true,
					component: (
						<CheckFraudDetails
							handleModal={(e: IModal) => handleModal(e)}
							handleClose={handleOnCloseCheckDetails}
						/>
					),
					isStopOutsideClick: true,
					className: 'view-kyc-modal',
					onClose: () => {
						handleModal('');
						handleOnCloseCheckDetails();
					},
				};
			default:
				return {
					modal: false,
					component: <></>,
					className: '',
					header: <></>,
				};
		}
	}, [activeModal, handleModal, handleOnCloseCheckDetails, handleOnSubmitForm]);

	const modalProps = {
		title: header,
		modalName: className,
		className: className,
		closeModal: onClose,
		children: component,
		...(isStopOutsideClick && {
			isStopOutsideClick,
		}),
		...(rootStyle && {
			overlayStyles: rootStyle,
		}),
		isOpen: modal,
	};

	return (
		<div className="cf-wrapper">
			<Header title="Check Defense" />
			<div className="cf-wrapper__inner">
				<ReactResponsiveTable
					isLoading={loading}
					tableType="chequeFraud"
					column={CHEQUE_FRAUD_TABLE_HEADER}
					rows={loading ? [] : data}
					showSearch={true}
					showDateFilter
					height={'calc(100vh - 200px)'}
					emptyHeight={'calc(100vh - 240px'}
					columnHandle
					EmptyIllustration={'empty-transaction.svg'}
					EmptyMessageHeading="No check cashing transaction found"
					EmptyMessageDescription="There are no check cashing transactions to display at the moment."
					handelRowClick={handleClickOnRow}
					hideSortKey={['email', 'amount', 'transaction']}
				/>
			</div>
			{modal && <Modal {...modalProps} />}
		</div>
	);
};

export default ChequeFraud;
