import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { ref, onValue } from 'firebase/database';

import { Header } from 'views/header';
import { SignedDocPacketsInboxTable } from './components';
import { SignedDocPacketsState, useSignedDocsPacketsInbox } from './store';
import { useFirebaseInit } from 'hooks';
import { loginState } from 'global-stores';
import { Memory } from 'views/signed-doc-inbox';

import './signed-doc-packets-inbox.scss';

// Define the main component for the SignedDocPacketsInbox
export const SignedDocPacketsInbox = () => {
	// Get the 'getSignedDocsPacketsInbox' function from a custom hook
	const { getSignedDocsPacketsInbox } = useSignedDocsPacketsInbox();
	const { database } = useFirebaseInit();
	const {business, email} = useRecoilValue(loginState);
	const { data } = useRecoilValue(SignedDocPacketsState);

	// Use the 'useEffect' hook to perform an action when the component mounts
	useEffect(() => {
		if (data?.length < 1) {
			// If empty, call 'getSignedDocsPacketsInbox' to fetch data
			getSignedDocsPacketsInbox();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	// Trigger Inbox list api whenever there is any change detected in the db by firebase
	useEffect(() => {
		// Check if the business variable is defined
		if (business) {
			// Create a reference to the database path using the business variable
			const dataRef = ref(database, business);
			// Attach a listener to the dataRef to handle value changes
			onValue(dataRef, snapshot => {
				// Get the data from the snapshot
				const data = snapshot.val();				
				// Check if the timestamp in the data matches the current timestamp in memory
				if (data.timeStamp === Memory.getTimeStamp() && !data.envelopeId) {
					// If the timestamps match, do nothing and return
					return;
				}
				// Update the memory with the new timestamp from the data
				Memory.setTimeStamp(data.timeStamp);

				const recipients = JSON.parse(data?.recipientEmails ?? "[]")
				// Check if the businessId in the data matches the current business variable
				if (data?.businessId === business && recipients.includes(email)) {
					// If the business IDs match, log a message and trigger the API call
					getSignedDocsPacketsInbox();
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Render the component's JSX
	return (
		<div className="SignedDocPacketsinbox__wrapper">
			<Header title="esign Inbox" />
			<div className="SignedDocPacketsinbox__body">
				<SignedDocPacketsInboxTable />
			</div>
		</div>
	);
};

export default SignedDocPacketsInbox;
