import { FC } from 'react';
import { FormOne } from '../form-one/form-actions';
import { SessionDetailsHeading } from '../session-details-heading';

export interface MultipleChoice {
	label: string;
	key: string;
}

export interface IFormOne {
	multipleChoice: MultipleChoice[];
}

export interface IFormContainer {
	conditions: IFormOne[];
	choices: string[];
}

export const FormContainer: FC<IFormContainer> = ({ choices }) => {
	return (
		<div className="kycDetails-contain">
			<SessionDetailsHeading
				label="Form"
				icon={
					<i className="ri-file-list-3-fill form-icon-profile-container__formIcon" />
				}
			/>
			{choices?.length > 0 && (
				<div className="form-one">
					<FormOne choices={choices} />
				</div>
			)}
		</div>
	);
};
