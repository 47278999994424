import { useEffect, useRef } from 'react';

import './map.scss';

export const MapComponent = ({
	center,
	zoom,
	showMarker = true,
	showCircle = false,
	radius = 150,
}: {
	center: google.maps.LatLngLiteral;
	zoom: number;
	showMarker?: boolean;
	showCircle?: boolean;
	radius?: number;
}) => {
	const mapRef: any = useRef();

	useEffect(() => {
		const map = new window.google.maps.Map(mapRef.current, {
			center,
			zoom,
			zoomControl: false,
			fullscreenControl: false,
			streetViewControl: false,
			mapTypeControl: false,
			keyboardShortcuts: false,
			gestureHandling: 'none',
		});

		if (showMarker) {
			new google.maps.Marker({
				position: center,
				map,
			});
		}
		if (showCircle) {
			new google.maps.Circle({
				strokeColor: 'rgba(0, 81, 204, 0.24)',
				strokeOpacity: 1,
				strokeWeight: 2,
				fillColor: 'rgba(34, 105, 211, 0.24)',
				fillOpacity: 1,
				map,
				center: center,
				radius: radius,
			});
		}
		// eslint-disable-next-line
	}, []);

	return <div ref={mapRef} id="map" className="map_container" />;
};
