import { FC, useState } from 'react';
import { IKybDetails } from 'global-stores/sessions/type';
import { KybInfoHeader } from '../kyb-info-header';

import './kyb-members.scss';

interface IKYBMembers {
	item: IKybDetails;
}

export const KybMembers: FC<IKYBMembers> = ({ item }) => {
	const { memberDetails } = item ?? {};
	const [showAllMembers, setShowAllMembers] = useState(false);

	const toggleShowAllMembers = () => {
		setShowAllMembers(!showAllMembers);
	};

	const getInitials = (name: string) => {
		const words = name?.split?.(' ') ?? [];
		const initials = words.map(word => word.charAt(0)).join('');
		return initials.substring(0, 2);
	};

	if (!memberDetails?.value.length) {
		return <></>;
	}

	return (
		<>
			<div className="kyb-members_header">
				<KybInfoHeader type="Members" />
				{memberDetails?.value?.length > 4 && (
					<div onClick={toggleShowAllMembers} className="kyb-members_view-more">
						{showAllMembers ? 'View less' : 'View more'}
					</div>
				)}
			</div>

			<div className="kyb-members_container">
				{memberDetails?.value?.map((member, index) => {
					if ((index < 4 || showAllMembers) && member.name) {
						return (
							<div
								key={member.name + member.designation}
								className="kyb-members_container__card"
							>
								<div className="kyb-members_container__card__para">
									{getInitials(member.name)}
								</div>
								<div className="kyb-members_container__card__initial">
									<div className="kyb-members_container__card__initial__text">
										{member.name}
									</div>
									<div className="kyb-members_container__card__initial__designation">
										{member.designation}
									</div>
								</div>
							</div>
						);
					} else {
						return <></>;
					}
				})}
			</div>
		</>
	);
};
