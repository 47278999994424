import { FC, useCallback, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import classNames from 'classnames';
import { TreeGraphChart } from './tree-graph-chart';
import {
	fundInvestmentType,
	MAX_SESSION_NAME_LENGTH,
	sessionActionLine,
	sessionActionStatus,
} from './constant';
import {
	ComplexConfigStep,
	IOptionValue,
	IsQrInviteState,
	SIGN_OPTIONS,
	SelectedQRInstanceState,
	SelectedQRTemplateIdState,
	ShowSignOptionInQr,
	SignOptionState,
} from 'views/pipelines/components';
import { SelectedComplexPipelineNode } from 'global-stores';
import {
	IsMultiSignModalOpen,
	SignAgreementComplexStepConfigured,
	SelectedComplexSignAgreementStep,
	IsOverlayMultiSignModalOpen,
	IsSessionChartLoadingState,
} from 'views/multi-sign-agreement';

import './tree-graph-chart.scss';
import { labelData } from 'views/complex-onboarding-flow/constant';
import { UploadProofDocumentState } from 'views/upload-proof-document';
import { actionKeyLabel, proofReadingKey } from 'views/pipelines';
import { ComplexFlowUnconfigedState } from 'views/complex-onboarding-flow/stores/states';
import Tippy from '@tippyjs/react';
import {
	UploadDocumentOptionState,
	uploadDocumentOptionsValues,
} from 'views/multisign-template-selection';
import { SESSION_CONFIGURE_KEYS_VALUE } from 'views/sessions/components/configure-session/constant';
import { useSignedDocuments } from './hooks';
interface ISessionTreeGraph {
	details: any;
	showOnBoardingName?: boolean;
	viewComplexModal?: any;
	isInvite?: boolean;
	funds?: any[];
	docs?: any[];
	isSessionModal?: boolean;
	isSessionList?: boolean;
	isStepper?: boolean;
	activeStepId?: string;
	selectedStepsState?: any[];
	maxNodes?: number;
	isRecipientInvite?: boolean;
	overlayTemplateIds?: string[];
}

const countNodeLength = 1;

export const SessionTreeGraph: FC<ISessionTreeGraph> = ({
	details,
	showOnBoardingName = false,
	isInvite = false,
	funds = [],
	docs = [],
	isSessionModal = false,
	isSessionList = false,
	viewComplexModal,
	isStepper = false,
	activeStepId,
	maxNodes,
	isRecipientInvite = false,
	overlayTemplateIds = []
}) => {
	const setComplexConfigStep = useSetRecoilState(ComplexConfigStep);
	const setComplexPipelineNode = useSetRecoilState(SelectedComplexPipelineNode);
	const QRInstancesForMultisign = useRecoilValue(SelectedQRInstanceState);
	const setQRTemplateId = useSetRecoilState(SelectedQRTemplateIdState);
	const activeOption = useRecoilValue<IOptionValue>(SignOptionState);
	const setIsMultiSignerModalOpen = useSetRecoilState(IsMultiSignModalOpen);
	const isQrFlow = useRecoilValue(IsQrInviteState);
	const complexFlowUnconfigedState = useRecoilValue(ComplexFlowUnconfigedState);
	const setShowSignOptionVisible = useSetRecoilState(ShowSignOptionInQr);
	const [signDocConfigStatus, setSignDocConfigStatus] = useRecoilState(
		SignAgreementComplexStepConfigured
	);
	const [amlRejcted, setAmlRejected] = useState(false);
	const isChartloading = useRecoilValue(
		IsSessionChartLoadingState
	);
	const setDocActiveOption = useSetRecoilState(UploadDocumentOptionState);
	const selectedSignAgreementId = useSetRecoilState(
		SelectedComplexSignAgreementStep
	);
	const uploadProofDocument = useRecoilValue(UploadProofDocumentState);
	const setIsOverlayConfigOpen = useSetRecoilState(IsOverlayMultiSignModalOpen);

	const { checkMultipleSignAgreementInFlow } = useSignedDocuments();

	//constants
	const { proofVerification } = proofReadingKey;
	const isSessionNameLengthy =
		details?.onBoardingName?.length > MAX_SESSION_NAME_LENGTH ? true : false;

	const labelName = useCallback(
		(id: string) => {
			const nodeFiltered = (details?.design?.nodes || []).filter(
				(item: any) => item?.id === id
			);
			// if node id not matched the check pipeline id to get details
			const foundNode = details?.nodes?.find(
				(item: any) => item?._id === id || item?.pipelineNodeId === id
			);

			const filtered = (details?.nodes || []).filter(
				// Gaurav: In case fund detail _id is not specific chart node id, it's pipelineNodeId
				(item: any) => item?._id === id || item?.pipelineNodeId === id
			);

			if (
				filtered[0]?.label === 'Fund Investment' &&
				fundInvestmentType?.[filtered?.[0]?.type]
			) {
				return `Fund (${fundInvestmentType[filtered[0]?.type]})`;
			}
			if (
				filtered[0]?.label === 'Accreditation Verification' &&
				filtered?.[0]?.type
			) {
				return `Accreditation (${filtered[0]?.type})`;
			}
			//Deepak : Change Sign Agreement label to Sign Doc. in case of complex
			if (filtered[0]?.label === 'Sign Agreement') {
				return 'Sign Doc.';
			}

			if (filtered[0]?.label === labelData.proofVerification) {
				return actionKeyLabel.proofReading;
			}

			return (
				filtered[0]?.label ||
				nodeFiltered[0]?.label ||
				(SESSION_CONFIGURE_KEYS_VALUE as Record<string, string>)[
					foundNode?.stepId
				] ||
				'Loading...'
			);
		},
		[details]
	);

	// const getPricingData = useCallback((id: string)=>{
	// 	const nodeKey = (details?.design?.nodes || []).find(
	// 		(item: any) => item?.id === id
	// 	).stepId;
	// 	const nodePrice = selectedStepsState?.find(el => el.key === nodeKey)?.actions[0].price;
	// 	const type = nodeKey == 'signAgreement'? 'per packet' : 'per verification';
	// 	return `<div class="tree-node-pricing"><b>$${nodePrice}</b><br/>${type}</div>`

	// },[selectedStepsState, details])

	const actionLinkStatus = useCallback(
		(to: string, from: string) => {
			const toFiltered = (details?.nodes || []).filter(
				(item: any) => item.pipelineNodeId === to
			);
			const fromFiltered = (details?.nodes || []).filter(
				(item: any) => item.pipelineNodeId === from
			);

			return (fromFiltered[0]?.action?.status || 'pending') !== 'pending' &&
				sessionActionLine[toFiltered[0]?.action?.status] ===
					sessionActionLine[fromFiltered[0]?.action?.status]
				? '#0000FF'
				: '#B3B3B3';
		},
		[details?.nodes]
	);

	// const firstNodeId = useMemo(() => {
	// 	const firstNodeId = (details?.design?.edges || []).map(
	// 		(item: any) => item.target
	// 	);
	// 	const allNodes = (details?.design?.nodes || []).map((item: any) => item.id);

	// 	const startID = allNodes.filter(
	// 		(item: any) => firstNodeId.indexOf(item) === -1
	// 	);
	// 	return startID[0];
	// }, [details?.design?.edges, details?.design?.nodes]);

	const getTrimedData = (nodes: any, edges: any, maxNodes: number) => {
		const trimedNode = nodes.slice(0, maxNodes);

		const validTrimedNode = trimedNode.map((el:any) => el['_id']);

		const filteredEdges = edges.filter(
		(edge: any) =>
			validTrimedNode.includes(edge.source) && validTrimedNode.includes(edge.target)
	);

	return { nodes: trimedNode, edges: filteredEdges };
}

	const chartData = useMemo(() => {
		//@avinash: it was throwing typo error with $ sign so i have destructured it
		const { design = {} } = details ?? {};
		const amlNode = (details?.nodes ?? []).find(
			(node: { stepId: string; action: { status: string; }; }) =>
			  node?.stepId === "aml" &&
			  node?.action?.status === "rejected"
		  );
		  // If the node is found, set the state to true
		  if (amlNode) {
			setAmlRejected(true);
		  }
		const { nodes = [], edges = [] } = design;

		let n = structuredClone(nodes);
		let e = structuredClone(edges);

		if (typeof maxNodes === 'number') {
			const {nodes, edges} = getTrimedData(n, e, maxNodes);
			n = nodes;
			e = edges;
		}

		let data: any = [];
		if (e.length > 0) {
			data = (e.length ? e : [])?.map((item: any) => [
				item.source,
				item.target,
				actionLinkStatus(item.source, item.target),
			]);
		} else {
			const relationships: any[] = [];
			n?.forEach((node: { parentId: string[]; id: string }) => {
				if (node.parentId.length > 0) {
					node.parentId.forEach(pId => {
						relationships.push([pId, node.id, actionLinkStatus(pId, node.id)]);
					});
				} else {
					relationships.push([
						node.id,
						node.id,
						actionLinkStatus(node.id, node.id),
					]);
				}
			});

			data = relationships;
		}

		if (n.length === 0) {
			return [[details?.design?.nodes?.[0]?.id]];
		}
		return data;
	}, [actionLinkStatus, details, maxNodes]);

	const nodeColor = useMemo(() => {
		const data = (details?.nodes || []).map((item: any) => {
			const isConfiged = complexFlowUnconfigedState.find(
				el => el.id == item?.id
			)?.configed;

			const nodeStartColor =
				!item?.action?.status && item?.action?.stepStartTime;

			let color = nodeStartColor
				? '#7935de'
				: sessionActionStatus[item?.action?.status]
				? sessionActionStatus[item?.action?.status]
				: isStepper && activeStepId == item?.id
				? '#FFFFFF'
				: (isConfiged || isConfiged == undefined) && isStepper
				? '#389F75'
				: '#8c97b8';
			const pipelineNodeId = item?._id || item?.id;
			if (isInvite) {
				const isFundConfigured = !!funds.find(
					(fund: any) => fund.node === pipelineNodeId
				);

				const isDocConfigured = !!docs.find(
					(doc: any) => doc.node === pipelineNodeId
				);
				const { status } = signDocConfigStatus[pipelineNodeId] ?? {};
				const isMultiSignNodeConfigured = status === 'configured';

				const isProofDocConfigured = !!uploadProofDocument.find(
					(doc: any) => doc.node === pipelineNodeId
				);

				const isConfigured =
					isFundConfigured ||
					isDocConfigured ||
					isProofDocConfigured ||
					isMultiSignNodeConfigured;
				if (isConfigured) {
					color = sessionActionStatus.completed ?? color;
				}
			}
			return {
				id: item?.pipelineNodeId ?? pipelineNodeId,
				color,
				borderColor:
					isStepper && activeStepId == item?.id
						? '#0051CC'
						: isConfiged
						? '#389F75'
						: '#8c97b8',
			};
		});

		return data;
	}, [
		details?.nodes,
		docs,
		funds,
		isInvite,
		signDocConfigStatus,
		uploadProofDocument,
		activeStepId,
		complexFlowUnconfigedState,
		isStepper,
	]);

	const onClick = useCallback(
		async (id: string) => {
			if (!isInvite) {
				return;
			}
			const selectNode = details?.nodes?.find((node: any) => node._id === id);
			const multipleSigningNodes = checkMultipleSignAgreementInFlow(details?.nodes, id)
			if (!selectNode) {
				return;
			}
			setComplexPipelineNode(selectNode);
			if (
				selectNode?.stepId === 'signAgreement' &&
				!multipleSigningNodes &&
				isQrFlow
			) {
				const selectedNodeBeingConfigured =
					signDocConfigStatus[selectNode?._id];
				if (selectedNodeBeingConfigured?.status === 'configured') {
					setDocActiveOption(uploadDocumentOptionsValues.existing);
					setIsOverlayConfigOpen(true);
				} else {
					setShowSignOptionVisible(true);
					setComplexConfigStep({
						id,
						key: selectNode.key,
						actions: selectNode.actions,
					});
				}
				setSignDocConfigStatus(prev => {
					const prevState = structuredClone(prev);
					if (prevState[selectNode._id]?.status === 'configured') {
						return {
							...prevState,
							[selectNode._id]: {
								...prevState[selectNode._id],
								status: 'reconfigure',
							},
						};
					} else {
						return {
							...prevState,
							[selectNode._id]: {
								envelopeId: '',
								status: 'pending',
								templateId: '',
							},
						};
					}
				});
				selectedSignAgreementId(selectNode._id);
				return;
			}
			if (
				selectNode?.stepId === 'signAgreement' &&
				QRInstancesForMultisign?.length
			) {
				const templateData = QRInstancesForMultisign.find(item => item.node === id);
				if(templateData)
					setQRTemplateId(templateData?.templateId)
				selectedSignAgreementId(selectNode._id);
				// setIsComplexFlow(true);
				setIsMultiSignerModalOpen(true);
			}
			
			if (
				selectNode?.stepId === 'signAgreement' &&
				activeOption === SIGN_OPTIONS.COMMON
			) {
				/**
				 * mounting multisigner
				 * */
				setIsMultiSignerModalOpen(true);
				setSignDocConfigStatus(prev => {
					const prevState = structuredClone(prev);
					if (prevState[selectNode._id]?.status === 'configured') {
						return {
							...prevState,
							[selectNode._id]: {
								...prevState[selectNode._id],
								status: 'reconfigure',
							},
						};
					} else {
						return {
							...prevState,
							[selectNode._id]: {
								envelopeId: '',
								status: 'pending',
								templateId: '',
							},
						};
					}
				});
				selectedSignAgreementId(selectNode._id);
				return;
			}
			if (selectNode?.stepId === proofVerification) {
				setComplexPipelineNode(selectNode);
			}
			setComplexConfigStep({
				id,
				key: selectNode.key,
				actions: selectNode.actions,
			});
		},

		[
			activeOption,
			details?.nodes,
			isInvite,
			isQrFlow,
			proofVerification,
			selectedSignAgreementId,
			setComplexConfigStep,
			setComplexPipelineNode,
			setIsMultiSignerModalOpen,
			setIsOverlayConfigOpen,
			setShowSignOptionVisible,
			setSignDocConfigStatus,
			signDocConfigStatus,
			setDocActiveOption,
			checkMultipleSignAgreementInFlow,
			QRInstancesForMultisign,
			setQRTemplateId
		]
	);

	const renderChart = useMemo(() => {
		const id = isInvite
			? `invite_${details._id}`
			: isSessionModal
			? `session${details._id}`
			: details._id;
		
		return (
			<TreeGraphChart
				isLoading={isChartloading}
				title={id}
				isInverted={false}
				seriesData={chartData}
				seriesLabelName={labelName}
				nodesColor={nodeColor}
				isInvite={isInvite}
				onClick={onClick}
				isSessionModal={isSessionModal}
				isRecipientInvite={isRecipientInvite}
				isStepper={isStepper}
				activeStepId={activeStepId}
				getPricingData={() => ''}
				overlayTemplateIds={overlayTemplateIds}
			/>
		);
	}, [
		chartData,
		details._id,
		isInvite,
		isSessionModal,
		labelName,
		nodeColor,
		onClick,
		activeStepId,
		isStepper,
		isChartloading,
		isRecipientInvite,
		overlayTemplateIds
	]);

	const chartClasses = useMemo(() => {
		return classNames('onboarding-session-tree-graph', {
			'session-tree-graph': isSessionList,
			'onboarding-invite-tree-graph': isInvite || isSessionModal || isStepper,
			'onboarding-stepper-tree-graph': isStepper,
		});
	}, [isSessionList, isInvite, isSessionModal, isStepper]);

	return (
		<div className={chartClasses}>
			{renderChart}
			{!isStepper &&
				!isInvite &&
				!isSessionModal &&
				chartData.length > countNodeLength && (
					<div
						className="tree-graph-show-more"
						onClick={e => viewComplexModal(e, details)}
					>
						View <div>complete</div> flow
					</div>
				)}
			{showOnBoardingName && (
				<>
					{isSessionNameLengthy && (
						<Tippy content={details?.onBoardingName}>
							<div className="tree-graph-onboarding-name">
								{amlRejcted && (
									<i className="ri-error-warning-line onboarding-aml-session-icon"></i>
								)}
								{details?.onBoardingName}
							</div>
						</Tippy>
					)}
					{!isSessionNameLengthy && (
						<div className="tree-graph-onboarding-name ">
							{amlRejcted && (
								<i className="ri-error-warning-line onboarding-aml-session-icon"></i>
							)}
							{details?.onBoardingName}
						</div>
					)}
				</>
			)}
		</div>
	);
};
