import { FC, Fragment, useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import {
	ComplexSessionNodesState,
	ComplexSessionsNodesDetailsState,
	ComplexSessionsNodesLoadingState,
	loginState,
} from 'global-stores';
import {
	ActivityLog,
	Condition,
	SessionInfo,
	KycDetailsWrapper,
	FlowLine,
	SignAgreeMent,
	SessionDetailsHeading,
	Accreditation,
	BackgroundCheck,
	FundInvestmentDetails,
	KybWrapper,
	DynamicFormContainer,
} from '.';
import { SessionDetailsSkeletonLoader } from '../session-details-loader';
import { FormContainer } from './form-container';
import { SessionKYBForm } from './session-kyb-form';
import { ProofReadDocument } from './proof-reading/proof-read-document';
import { proofReadingKey } from 'views/pipelines';

interface IComplexSessionDetailsPage {
	isLoading: boolean;
	isTransactionPage: boolean;
	isUserPermissionWrite?: boolean;
	isChequeFraud?: boolean;
}

const isLoaded = true;
const { proofReading } = proofReadingKey;

export const ComplexSessionDetailsPage: FC<IComplexSessionDetailsPage> = ({
	isLoading,
	isTransactionPage,
	isUserPermissionWrite = true,
	isChequeFraud = false,
}) => {
	const totalNodes = useRecoilValue(ComplexSessionNodesState);
	const { role, isAgentPermitted } = useRecoilValue(loginState);
	const sessions = useRecoilValue(ComplexSessionsNodesDetailsState);
	const sessionNodeLoading: any = useRecoilValue(
		ComplexSessionsNodesLoadingState
	);

	const nodes = useMemo(() => {
		return totalNodes.filter((node: any) => node?.visited);
	}, [totalNodes]);

	const getStepData = useCallback(
		(nodeId: string) => {
			const step = sessions[nodeId];
			return step;
		},
		[sessions]
	);

	const renderFlowLine = useCallback(
		(index: number) => {
			return index < nodes.length - 1 && <FlowLine />;
		},
		[nodes.length]
	);

	const renderCard = useCallback(
		(node: any, index: number) => {
			switch (node?.stepId) {
				case 'form': {
					const { conditions, data } = getStepData(node?._id) ?? {};
					return sessionNodeLoading?.[node?._id] === false ? (
						<Fragment>
							<FormContainer
								{...{ conditions, choices: data?.choices ?? [] }}
							/>
							{renderFlowLine(index)}
						</Fragment>
					) : (
						<div className="kycDetails-contain">
							<SessionDetailsHeading
								label="Form"
								icon={
									<i className="ri-file-list-3-fill form-icon-profile-container__formIcon" />
								}
							/>
							<SessionDetailsSkeletonLoader />
							{renderFlowLine(index)}
						</div>
					);
				}
				case 'kyc': {
					const kycData = getStepData(node?._id);
					const { kyc = {}, status, ssn = {} } = kycData?.data ?? {};
					// TODO: maybe we can change it from backend in future
					const kycInfo = {
						...kyc,
						ssn: {
							ssn,
						},
						address: kyc?.basicInfo?.newAddress ?? {},
					};
					const kycDetail = {
						isAgentPermitted,
						isTransactionPage,
						role,
						kyc: {
							kyc: kycInfo,
							status,
						},
					};

					return sessionNodeLoading?.[node?._id] === false ? (
						<Fragment>
							<Condition
								label="KYC"
								isKyc
								conditions={kycData?.conditions ?? []}
							/>
							<KycDetailsWrapper
								isLoaded={isLoaded}
								isLoading={isLoading}
								kycDetail={kycDetail as any}
								nodeId={node?._id}
								isUserPermissionWrite={isUserPermissionWrite}
								isChequeFraud={isChequeFraud}
							/>
							{renderFlowLine(index)}
						</Fragment>
					) : (
						<div className="kycDetails-contain">
							<SessionDetailsHeading
								label="KYC Details"
								icon={
									<i className="ri-user-search-fill form-icon-profile-container__formIcon" />
								}
							/>
							<SessionDetailsSkeletonLoader />
							{renderFlowLine(index)}
						</div>
					);
				}
				case 'aml': {
					const { data, conditions } = getStepData(node?._id) ?? {};
					return sessionNodeLoading?.[node?._id] === false ? (
						<Fragment>
							<Condition label="AML" isKyc conditions={[conditions] ?? []} />
							<BackgroundCheck
								isLoading={isLoading}
								aml={data}
								role={role}
								isAgentPermitted={isAgentPermitted}
								isTransactionPage={isTransactionPage}
								firstName={data?.firstName}
								lastName={data?.lastName}
								dateOfBirth={data?.dateOfBirt}
								isLoaded={isLoaded}
								nodeId={node?._id}
								isUserPermissionWrite={isUserPermissionWrite}
							/>
							{renderFlowLine(index)}
						</Fragment>
					) : (
						<div className="kycDetails-contain">
							<SessionDetailsHeading
								label="AML PEPs and Sanctions"
								icon={
									<i className="ri-coins-fill form-icon-profile-container__formIcon" />
								}
							></SessionDetailsHeading>
							{renderFlowLine(index)}
							<SessionDetailsSkeletonLoader />
						</div>
					);
				}
				case 'kybForm': {
					const { data: kybForm = {}, status = 'pending' } =
						getStepData(node?._id) ?? {};
					return sessionNodeLoading?.[node?._id] === false ? (
						<Fragment>
							<SessionKYBForm
								data={kybForm as any}
								status={status}
								nodeId={node?._id}
								isUserPermissionWrite={isUserPermissionWrite}
							/>
							{renderFlowLine(index)}
						</Fragment>
					) : (
						<div className="kycDetails-contain">
							<SessionDetailsHeading
								label="KYB Form"
								icon={
									<i className="ri-file-list-3-fill form-icon-profile-container__formIcon" />
								}
							/>
							<SessionDetailsSkeletonLoader />
							{renderFlowLine(index)}
						</div>
					);
				}
				case 'kyb': {
					const { data, conditions } = getStepData(node?._id) ?? {};
					return sessionNodeLoading?.[node?._id] === false ? (
						<Fragment>
							<>
								<KybWrapper
									isLoaded={isLoaded}
									isLoading={isLoading}
									kyb={data}
									nodeId={node?._id}
									isUserPermissionWrite={isUserPermissionWrite}
									isAgentPermitted={isAgentPermitted}
									role={role}
								/>
								<Condition label="KYB" conditions={conditions ?? []} />
							</>
							{renderFlowLine(index)}
						</Fragment>
					) : (
						<div className="kyb">
							<div className="kyb-wrapper">
								<div className="kyb-wrapper__header">
									<i className="ri-hotel-fill" />
									<div>Business Identity and Industry</div>
								</div>
								<SessionDetailsSkeletonLoader />
								{renderFlowLine(index)}
							</div>
						</div>
					);
				}
				case 'fundInvestment': {
					const fund = getStepData(node?._id) ?? {};
					return sessionNodeLoading?.[node?._id] === false ? (
						<Fragment>
							<FundInvestmentDetails
								isLoading={isLoading}
								isAgentPermitted={isAgentPermitted as boolean}
								role={role}
								fundInvestment={(fund?.data ?? {}) as any}
								isLoaded={isLoaded}
								nodeId={node?._id}
								isUserPermissionWrite={isUserPermissionWrite}
							/>
							{renderFlowLine(index)}
						</Fragment>
					) : (
						<div className="kycDetails-contain">
							<SessionDetailsHeading
								label="Fund Account"
								icon={
									<i className="ri-profile-line form-icon-profile-container__formIcon"></i>
								}
							></SessionDetailsHeading>
							<SessionDetailsSkeletonLoader />
							{renderFlowLine(index)}
						</div>
					);
				}
				case 'accreditation': {
					const { data: accreditations } = getStepData(node?._id) ?? {};
					return sessionNodeLoading?.[node?._id] === false ? (
						<Fragment>
							<Accreditation
								isLoading={false}
								accreditation={accreditations ?? []}
								role={role}
								isAgentPermitted={isAgentPermitted}
								isLoaded={isLoaded}
								nodeId={node?._id}
								isUserPermissionWrite={isUserPermissionWrite}
							/>
							{renderFlowLine(index)}
						</Fragment>
					) : (
						<div className="accreditation">
							<div className="kycDetails-contain">
								<SessionDetailsHeading
									label={`Accreditation${'(506c)'}`}
									icon={
										<i className="ri-user-star-fill form-icon-profile-container__formIcon" />
									}
								></SessionDetailsHeading>
								<SessionDetailsSkeletonLoader />
								{renderFlowLine(index)}
							</div>
						</div>
					);
				}
				case 'signAgreement': {
					const { data: signAgreements } = getStepData(node?._id) ?? {};
					return sessionNodeLoading?.[node?._id] === false ? (
						<Fragment>
							<SignAgreeMent
								isLoading={isLoading}
								signAgreement={signAgreements}
								role={role}
								isAgentPermitted={isAgentPermitted}
								isTransactionPage={isTransactionPage}
								isLoaded={isLoaded}
								nodeId={node?._id}
								isUserPermissionWrite={isUserPermissionWrite}
							/>
							{renderFlowLine(index)}
						</Fragment>
					) : (
						<div className="agreement-accreditation">
							<div className="signAgreement">
								<div className="kycDetails-contain">
									<SessionDetailsHeading
										label="Sign Agreement"
										icon={
											<i className="ri-profile-line form-icon-profile-container__formIcon"></i>
										}
									></SessionDetailsHeading>
									<SessionDetailsSkeletonLoader />
									{renderFlowLine(index)}
								</div>
							</div>
						</div>
					);
				}
				case 'dynamicForm': {
					const { data: dynamicForm } = getStepData(node?._id) ?? {};
					return sessionNodeLoading?.[node?._id] === false ? (
						<Fragment>
							<DynamicFormContainer
								dynamicForm={dynamicForm ?? {}}
								isLoading
								isAgentPermitted
								isUserPermissionWrite
								role={role}
								nodeId={node?._id}
							/>
							{renderFlowLine(index)}
						</Fragment>
					) : (
						<>
							<FlowLine />
							<div className="kycDetails-contain">
								<SessionDetailsHeading
									label="Dynamic Form"
									icon={
										<i className="ri-file-list-3-fill form-icon-profile-container__formIcon" />
									}
								/>
								<SessionDetailsSkeletonLoader />
							</div>
						</>
					);
				}
				// render proofReading card for complex
				case proofReading: {
					const { data: proofReadingDocuments } = getStepData(node?._id) ?? {};
					return (
						<Fragment>
							<ProofReadDocument
								file={proofReadingDocuments?.proofDocUrls}
								isSkeletonLoading={sessionNodeLoading?.[node?._id] ?? true}
							/>
							{renderFlowLine(index)}
						</Fragment>
					);
				}
				default:
					return <></>;
			}
		},
		[
			getStepData,
			isAgentPermitted,
			isLoading,
			isTransactionPage,
			isChequeFraud,
			renderFlowLine,
			role,
			sessionNodeLoading,
			isUserPermissionWrite,
		]
	);

	const renderSessionCards = useMemo(() => {
		if (!nodes) {
			return <div>loading...</div>;
		}
		return nodes.map((node: any, index: number) => {
			if (node?.visited) {
				return <Fragment key={node.id}>{renderCard(node, index)}</Fragment>;
			}
			return <Fragment key={node.id} />;
		});
	}, [nodes, renderCard]);

	const renderBody = useMemo(
		() => (
			<div className="session-column-container">
				<div className="session-col-detail session-col-detail__main">
					<div className="session-info-container">
						<SessionInfo isLoading={isLoading} loaded={true} />
						{nodes.length > 1 && <FlowLine />}
					</div>
					{renderSessionCards}
				</div>
				<div className="session-col-detail session-col-detail__activity">
					<ActivityLog isLoading={isLoading} dataLoaded={true} />
				</div>
			</div>
		),
		[isLoading, nodes.length, renderSessionCards]
	);

	return renderBody;
};
