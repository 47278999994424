import { useCallback, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { API_URL } from 'constant';
import { useNetwork } from 'hooks';
import { BusinessPaymentCardState } from 'global-stores';

export const useBusinessCards = () => {
	const { get } = useNetwork({ updateState: false });

	const [isLoaded, setIsLoaded] = useState(true);
	const [error, setError] = useState(false);
	const setBusinessCard = useSetRecoilState(BusinessPaymentCardState);

	const fetchBusinessCard = useCallback(
		async (isLoading?: boolean) => {
			const isLoad = isLoading ?? false;
			setIsLoaded(isLoad);
			const resp = await get(API_URL.BUSINESS_PAYMENT_DETAILS);
			if (resp?.data) {
				setBusinessCard(resp.data);
				setIsLoaded(true);
				return;
			}
			setError(true);
			setIsLoaded(true);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return { fetchBusinessCard, error, isLoaded };
};
