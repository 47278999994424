import type { IComplexThenBlock } from 'views/complex-onboarding-flow/stores/states';
import {
	ChangeEvent,
	FC,
	createRef,
	useCallback,
	useMemo,
	useState,
} from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useOutsideClick } from 'hooks';
import { capitalize } from 'utils';
import {
	AccreditationSelectAnswer,
	OperatorKey,
} from 'views/complex-onboarding-flow/constant';
import {
	ComplexInitPipelines,
	EdgesState,
	NodesState,
} from 'views/complex-onboarding-flow/stores/states';
import { CustomHandle } from '../custom-handle';
import { IOption } from '../dropdown';
import { InputText } from '../input';
import { CountryDropdownModal } from '../question-container/countyr-modal';
import '../question-container/question-wrapper.scss';

interface IQuestionWrapper {
	stepId: string;
	conditionId: string | number;
	questionData: any;
	nodeId: string;
	blockId: string;
	questionLen: number;
	index: number;
	connect: string;
	defaultThenValue: string | undefined;
}
let prevShowModal = '';

interface IEdge {
	source: string;
	sourceHandle?: string;
}

export const AccreditationQuestionWrapper: FC<IQuestionWrapper> = ({
	stepId,
	nodeId,
	blockId,
	questionLen,
	index,
	connect,
	questionData,
	defaultThenValue,
}) => {
	const [showModal, setShowModal] = useState('');
	const [dropdownConfig, setDropdownConfig] = useState<any>({});
	const ref = createRef<HTMLDivElement>();

	// Global variables
	const complexInitPipelines = useRecoilValue(ComplexInitPipelines);
	const setNodes = useSetRecoilState(NodesState);
	const setEdges = useSetRecoilState(EdgesState);

	const { stepVariables, thenConditions = [] } = complexInitPipelines ?? {};

	useOutsideClick(ref, () => {
		setShowModal('');
		prevShowModal = '';
	});

	const openCountryDropdown = useCallback((key: string) => {
		if (prevShowModal === key) {
			prevShowModal = '';
		} else {
			prevShowModal = key;
		}
		setShowModal(prevShowModal);
	}, []);

	const closeCountryDropdown = useCallback((e: any) => {
		e.stopPropagation();
		setShowModal('');
		prevShowModal = '';
	}, []);

	const OptionsResult = useMemo(
		() =>
			stepVariables.filter((el: { stepId: string }) => el.stepId === stepId),
		[stepVariables, stepId]
	);

	const onRemoveQuestion = useCallback(() => {
		setNodes((prev: any) => {
			const prevObj = JSON.parse(JSON.stringify(prev));
			const findIndex = prevObj.findIndex((el: any) => el.id === nodeId);
			if (findIndex !== -1 && index !== -1) {
				const nodeIndex = prevObj[findIndex].conditions?.findIndex(
					(el: any) => el.currentId === nodeId && el.blockId === blockId
				);
				if (nodeIndex !== -1 && index !== -1) {
					if (prevObj[findIndex].conditions[nodeIndex][connect].length > 1) {
						prevObj[findIndex].conditions[nodeIndex][connect].splice(index, 1);
					} else {
						prevObj[findIndex].conditions.splice(nodeIndex, 1);
					}
				}
			}
			return prevObj;
		});
		setEdges((prevEdges: IEdge[]) => {
			// Clone the previous edges array to avoid mutating state directly
			const clonedEdges = structuredClone(prevEdges);

			// Filter out the edges where the source matches nodeId and sourceHandle is undefined
			const filteredEdges = clonedEdges.filter(
				edge => !(edge.source === nodeId && edge.source !== edge.sourceHandle)
			);

			return filteredEdges;
		});
	}, [setNodes, setEdges, index, nodeId, blockId, connect]);

	const handleChangeSelect = useCallback(
		({ value }: IOption, key: string) => {
			setNodes((prev: any) => {
				const prevObj = JSON.parse(JSON.stringify(prev));
				const findIndex = prevObj.findIndex((el: any) => el.id === nodeId);
				if (findIndex !== -1 && index !== -1) {
					const nodeIndex = prevObj[findIndex].conditions?.findIndex(
						(el: any) => el.currentId === nodeId && el.blockId === blockId
					);
					if (nodeIndex !== -1 && index !== -1) {
						Object.assign(
							prevObj[findIndex].conditions[nodeIndex][connect][index],
							{
								[key]: value,
							}
						);
					}
				}
				return prevObj;
			});
		},
		[setNodes, index, nodeId, blockId, connect]
	);

	const onChangeTextInput = useCallback(
		(event: ChangeEvent<HTMLInputElement>, key: string) => {
			const {
				target: { value },
			} = event;
			setNodes((prev: Record<any, any>) => {
				const prevObj = JSON.parse(JSON.stringify(prev));
				const findIndex = prevObj.findIndex((el: any) => el.id === nodeId);
				if (findIndex !== -1 && index !== -1) {
					const nodeIndex = prevObj[findIndex].conditions?.findIndex(
						(el: any) => el.currentId === nodeId && el.blockId === blockId
					);
					if (nodeIndex !== -1 && index !== -1) {
						Object.assign(
							prevObj[findIndex].conditions[nodeIndex][connect][index],
							{
								[key]: value,
							}
						);
					}
				}
				return prevObj;
			});
		},
		[setNodes, index, nodeId, blockId, connect]
	);

	const handleThenChangeSelect = useCallback(
		({ value }: IOption) => {
			setNodes((prev: any) => {
				const prevObj = JSON.parse(JSON.stringify(prev));
				const findIndex = prevObj.findIndex((el: any) => el.id === nodeId);
				if (findIndex !== -1 && index !== -1) {
					const nodeIndex = prevObj[findIndex].conditions?.findIndex(
						(el: any) => el.currentId === nodeId && el.blockId === blockId
					);
					if (nodeIndex !== -1 && index !== -1) {
						prevObj[findIndex].conditions[nodeIndex]['then'] = value;
						prevObj[findIndex].conditions[nodeIndex][
							'key'
						] = `${stepId}_${blockId}_${nodeId}`;
					}
				}
				return prevObj;
			});
		},
		[setNodes, index, nodeId, blockId, stepId]
	);

	const operandOptions = useMemo(() => {
		const options = OptionsResult?.find(
			(el: Record<string, string>) => el.key === questionData.param
		);

		const data = options?.allowedOp?.map((el: string) => ({
			label: (OperatorKey as Record<string, string>)[el] ?? capitalize(el),
			value: el,
		}));
		return data;
	}, [OptionsResult, questionData.param]);

	const Options = useMemo(
		() =>
			OptionsResult?.map((el: { label: string; key: string }) => ({
				label: el.label,
				value: el.key,
			})),
		[OptionsResult]
	);

	const findDefaultParamValue = useMemo(
		() => Options?.find((el: any) => el.value === questionData.param),
		[Options, questionData.param]
	);

	const findDefaultOperand = useMemo(
		() => operandOptions?.find((el: any) => el.value === questionData.operand),
		[operandOptions, questionData.operand]
	);

	const findDefaultOperator = useMemo(
		() =>
			AccreditationSelectAnswer?.find(
				(el: any) => el.value === questionData.operator
			),
		[questionData.operator]
	);

	const thenOptions = thenConditions.map((el: IComplexThenBlock) => {
		const { key, label } = el;
		return { value: key, label };
	});

	const defaultThenObj = thenOptions.find(
		(el: any) => el.value === defaultThenValue
	);

	const inputType = OptionsResult.find(
		(el: { key: string }) => el.key === questionData?.param
	);

	const hanldeOpenDropdown = useCallback(
		(type: string) => {
			switch (type) {
				case 'operand':
					setDropdownConfig({
						type,
						options: operandOptions ?? [],
						defaultValue: findDefaultOperand || { label: 'Select', value: '' },
						handleChangeSelect: handleChangeSelect,
					});
					break;
				case 'operator':
					setDropdownConfig({
						type,
						options: AccreditationSelectAnswer,
						defaultValue: findDefaultOperator ?? { label: 'Select', value: '' },
						handleChangeSelect: handleChangeSelect,
					});
					break;

				case 'param':
					setDropdownConfig({
						type: 'param',
						options: Options,
						defaultValue: findDefaultParamValue,
						handleChangeSelect: handleChangeSelect,
					});
					break;
				case 'then':
					setDropdownConfig({
						type: 'then',
						options: thenOptions,
						defaultValue: defaultThenObj,
						handleChangeSelect: handleThenChangeSelect,
					});
					break;
				default:
					break;
			}
			openCountryDropdown(type);
		},
		[
			Options,
			defaultThenObj,
			findDefaultOperand,
			findDefaultParamValue,
			thenOptions,
			handleChangeSelect,
			handleThenChangeSelect,
			openCountryDropdown,
			operandOptions,
			findDefaultOperator,
		]
	);

	const countrySelectDropdown = useMemo(() => {
		return (
			<CountryDropdownModal
				defaultOperand={dropdownConfig.defaultValue}
				isOpen={!!showModal}
				onClose={closeCountryDropdown}
				handleChangeSelect={dropdownConfig.handleChangeSelect}
				type={dropdownConfig.type}
				options={dropdownConfig.options}
			/>
		);
	}, [dropdownConfig, showModal, closeCountryDropdown]);

	const isShowConnector = useMemo(
		() =>
			findDefaultParamValue?.label &&
			findDefaultOperand?.label &&
			findDefaultOperator?.label &&
			defaultThenObj?.label,
		[
			defaultThenObj?.label,
			findDefaultOperand?.label,
			findDefaultOperator?.label,
			findDefaultParamValue?.label,
		]
	);

	return (
		<div className="question-container">
			<div className="question-container__threads">
				<div className="question-container__threads__left">
					<span>If</span>
					<div
						onClick={() => hanldeOpenDropdown('param')}
						className="question-container__select-country"
						ref={showModal === 'param' ? ref : null}
					>
						<span className="question-ellipsis">
							{findDefaultParamValue?.label
								? findDefaultParamValue.label
								: 'Select'}
						</span>
						<div className="select-arrow"></div>
						{showModal === 'param' && countrySelectDropdown}
					</div>
				</div>

				{inputType?.type !== 'number' ? (
					<div
						onClick={() => hanldeOpenDropdown('operand')}
						className="question-container__select-country"
						ref={showModal === 'operand' ? ref : null}
					>
						<span className="question-ellipsis">
							{findDefaultOperand?.label ? findDefaultOperand.label : 'Select'}
						</span>
						<div className="select-arrow"></div>
						{showModal === 'operand' && countrySelectDropdown}
					</div>
				) : (
					<InputText
						type={inputType.type}
						value={questionData['operand'] as any}
						onChangeTextInput={e => onChangeTextInput(e, 'operand')}
					/>
				)}

				<div
					onClick={() => hanldeOpenDropdown('operator')}
					className="question-container__select-country"
					ref={showModal === 'operator' ? ref : null}
				>
					<span className="question-ellipsis">
						{findDefaultOperator?.label ? findDefaultOperator.label : 'Select'}
					</span>
					<div className="select-arrow"></div>
					{showModal === 'operator' && countrySelectDropdown}
				</div>
				{questionLen === index + 1 && (
					<>
						<span>then</span>
						<div
							onClick={() => hanldeOpenDropdown('then')}
							className="question-container__select-country"
							ref={showModal === 'then' ? ref : null}
						>
							<span className="question-ellipsis">
								{defaultThenObj?.label ? defaultThenObj.label : 'Select'}
							</span>
							<div className="select-arrow"></div>
							{showModal === 'then' && countrySelectDropdown}
						</div>
						<div
							className="custom_handle-main"
							style={{ visibility: isShowConnector ? 'visible' : 'hidden' }}
						>
							<CustomHandle
								value={`${stepId}_${blockId}_${nodeId}`}
								showLeft={false}
								showRight
							/>
						</div>
					</>
				)}
				<div className="question-container__threads__right-container">
					<div className="right" onClick={onRemoveQuestion}>
						<i className="ri-indeterminate-circle-line"></i>
					</div>
				</div>
			</div>
		</div>
	);
};
