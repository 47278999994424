import { Button } from '@storybook';

import { SuccessIcon } from 'components';

import './cancel-subscription-payment-success.scss';

export const CancelSubscriptionPaymentSuccess = () => {
	return (
		<div className="Cancel_Subscription_Payment_Success">
			<SuccessIcon />
			<div className="Cancel_Subscription_Payment_Success--title">
				Payment Successful.
			</div>
			{false && (
				<Button
					label={'Close'}
					type="button button__filled--secondary"
					handleClick={() => ({})}
				/>
			)}
		</div>
	);
};
