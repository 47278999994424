export const chartHeaderDateRange = {
	oneWeek: '1W',
	oneMonth: '1M',
	threeMonth: '3M',
	oneYear: '1Y',
	allTime: 'All',
	custom: 'custom',
};
const today = new Date();

export const changeHeaderDefaultRanges: { [key: string]: any } = {
	'1W': {
		startDate: new Date(
			today.getFullYear(),
			today.getMonth(),
			today.getDate() - 6
		),
		endDate: new Date(),
		key: 'selection',
	},
	'1M': {
		startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
		endDate: new Date(),
		key: 'selection',
	},
	'3M': {
		startDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
		endDate: new Date(),
		key: 'selection',
	},
	'1Y': {
		startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
		endDate: new Date(),
		key: 'selection',
	},

	All: {
		startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 5)),
		endDate: new Date(),
		key: 'selection',
	},
	custom: {},
};
