import { Loader } from '@storybook';
import { ChangeEvent, DragEvent, useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { useNotification } from 'hooks';
import { documentTypeRegex } from 'utils';
import { SelectedSignerState, useSignedDoc } from '../store';
import { ILoadingStatus, LOADING_STATUS } from 'constant';

export const BrowseFile = () => {
	const selectedSigner = useRecoilValue(SelectedSignerState);
	const [uploadStatus, setUploadStatus] = useState<ILoadingStatus>(LOADING_STATUS.IDLE);
	const { errorNotification } = useNotification();
	const { uploadDocument } = useSignedDoc();

	const handleImportImage = useCallback(
		async (file: File) => {
			setUploadStatus(LOADING_STATUS.PENDING);
			if (!file) {
				return;
			}
			if (file.size > 26214400) {
				errorNotification('File size exceeds the maximum limit.');
				setUploadStatus(LOADING_STATUS.IDLE);
				return;
			}
			if (!documentTypeRegex.test(file.type)) {
				errorNotification('Supported file format: Pdf, Doc, Docx.');
				setUploadStatus(LOADING_STATUS.IDLE);
				return;
			}
			const payload = new FormData();
			payload.append('doc', file);
			if (selectedSigner === 'esign') {
				payload.append('type', 'esign');
				payload.append('extension', file.type);
			}
			const status = await uploadDocument(payload);
			if (status) {
				setUploadStatus(LOADING_STATUS.SUCCESS);
			} else {
				setUploadStatus(LOADING_STATUS.IDLE);
			}
		},
		[errorNotification, selectedSigner, uploadDocument]
	);
	const handleChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const file = e.target.files?.[0];
			if (file) {
				handleImportImage(file);
			}
			e.target.value = ''; // Reset the input after handling the file
		},
		[handleImportImage]
	);
	/**
	 * @Manish here we are avoiding the drag over effect when ever we are uploading any document using the drag and upload method
	 * */
	const handleDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
	}, []);

	/**
	 * @Manish here we are writting the drag and upload function so that user can drop the file to upload the document
	 * */
	const handleDrop = useCallback(
		(e: DragEvent<HTMLDivElement>) => {
			e.preventDefault();
			e.stopPropagation();
			const file = e.dataTransfer.files[0];
			if (file) {
				handleImportImage(file);
			}
		},
		[handleImportImage]
	);

	return (
		<div
			className="browse-file-sign-doc"
			onDragOver={handleDragOver}
			onDrop={handleDrop}
		>
			<div>
				<label
					htmlFor="browse-file"
					className="browse-file__label-box browse-file__label-box-height"
				>
					<>
						{uploadStatus === LOADING_STATUS.PENDING ? (
							<div
								className='browse-file__signdoc-loader'
							>
								<Loader className="loader-blue" dimension={60} />
								<div className='browse-file__uploading-text'>Uploading file...</div>
							</div>
						) : (
							<>
								<i className="ri-file-upload-line browse-file__logo" />
								<div className="browse-file__label-text">
									<span className="browse-file__light-text">
										Drag and drop files or{' '}
										<span style={{ color: 'var(--color-primary-light)' }}>
											Browse file
										</span>
									</span>
								</div>
								<div className="browse-file__description">
									Supported file format: Pdf, Doc, Docx
								</div>
								<div className="browse-file__description">
									Maximum upload file size: 25 MB.
								</div>
							</>
						)}
					</>

					<input
						multiple={false}
						accept=".pdf, .doc, .docx"
						id="browse-file"
						type="file"
						onChange={handleChange}
						className={`browse-file__input ${
							uploadStatus === LOADING_STATUS.PENDING && 'browse-file__input-loading'
						}`}
						disabled={uploadStatus === LOADING_STATUS.PENDING}
					/>
				</label>
			</div>
		</div>
	);
};
