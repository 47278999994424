export const AML_DETAILS_TABLE: any = {
	GWL: [
		{
			key: 'riskScore',
			label: 'Risk Score',
		},
		{
			key: 'name',
			label: 'Name',
		},
		{
			key: 'alias',
			label: 'Aliases',
		},
		{
			key: 'dob',
			label: 'Date of birth',
		},
		{
			key: 'Gender',
			label: 'Gender',
		},
		{
			key: "address",
			label: "Addresses",
		  },
		{
			key: 'pob',
			label: 'Place of birth',
		},
		{
			key: 'LanguagesSpoken',
			label: 'Languages spoken',
		},
		{
			key: 'OriginalSource',
			label: 'Original source',
		},
	],
	EDD: [
		{
			key: 'riskScore',
			label: 'Risk Score',
		},
		{
			key: 'name',
			label: 'Name',
		},
		{
			key: 'alias',
			label: 'Aliases',
		},
		{
			key: 'title',
			label: 'Titles',
		},
		{
			key: 'dob',
			label: 'Date of birth',
		},
		{
			key: 'Gender',
			label: 'Gender',
		},
		{
			key: 'address',
			label: 'Addresses',
		},
		{
			key: 'pob',
			label: 'Place of birth',
		},
		{
			key: 'NameSource',
			label: 'Name source',
		},
		{
			key: 'Relationship',
			label: 'Relationship',
		},
		{
			key: 'SubCategory',
			label: 'Sub-category',
		},
		{
			key: 'EntityLevel',
			label: 'Entity level',
		},
		{
			key: 'LanguagesSpoken',
			label: 'Languages spoken',
		},
		{
			key: 'OriginalSource',
			label: 'Original source',
		},
	],
	PEP: [
		{
			key: 'riskScore',
			label: 'Risk Score',
		},
		{
			key: 'name',
			label: 'Name',
		},
		{
			key: 'alias',
			label: 'Aliases',
		},
		{
			key: 'title',
			label: 'Titles',
		},
		{
			key: 'dob',
			label: 'Date of birth',
		},
		{
			key: 'Gender',
			label: 'Gender',
		},
		{
			key: 'address',
			label: 'Addresses',
		},
		{
			key: 'pob',
			label: 'Place of birth',
		},
		{
			key: 'NameSource',
			label: 'Name source',
		},
		{
			key: 'Relationship',
			label: 'Relationship',
		},
		{
			key: 'SubCategory',
			label: 'Sub-category',
		},
		{
			key: 'EntityLevel',
			label: 'Entity level',
		},
		{
			key: 'LanguagesSpoken',
			label: 'Languages spoken',
		},
		{
			key: 'OriginalSource',
			label: 'Original source',
		},
	],
	SOE: [
		{
			key: 'riskScore',
			label: 'Risk Score',
		},
		{
			key: 'name',
			label: 'Name',
		},
		{
			key: 'alias',
			label: 'Aliases',
		},
		{
			key: 'title',
			label: 'Titles',
		},
		{
			key: 'dob',
			label: 'Date of birth',
		},
		{
			key: 'Gender',
			label: 'Gender',
		},
		{
			key: 'address',
			label: 'Addresses',
		},
		{
			key: 'pob',
			label: 'Place of birth',
		},
		{
			key: 'NameSource',
			label: 'Name source',
		},
		{
			key: 'Relationship',
			label: 'Relationship',
		},
		{
			key: 'SubCategory',
			label: 'Sub-category',
		},
		{
			key: 'EntityLevel',
			label: 'Entity level',
		},
		{
			key: 'LanguagesSpoken',
			label: 'Languages spoken',
		},
		{
			key: 'OriginalSource',
			label: 'Original source',
		},
	],
};
export const AML_INFO_HEADER: { [key: string]: string } = {
	GWL: 'GWL (Global watchlist)',
	EDD: 'EDD (Enhanced Due Diligence)',
	PEP: 'PEP (Politically exposed person)',
	SOE: 'SOE (State owned enterprise)',
};

export const AML_APPEARS_CATEGORIES  = [
	'PEP',
	'PEP Level 1',
	'PEP Level 2',
	'PEP Level 3',
	'PEP Level 4',
	'Fitness and Probity',
	'Insolvency',
	'Sanctions',
	'SIE',
	'SIP',
	'Warnings and Regulatory Enforcement',
	'Adverse Media'
];

export const AML_CONSTANTS_DETAILS = {
    MATCH_SCORE_LABEL: 'Match Score',
    TYPE_LABEL: 'Type',
    DATE_OF_BIRTH_LABEL: 'Date of birth/Incorporation',
    APPEARS_ON_LABEL: 'Appears On',
    COUNTRIES_LABEL: 'Countries',
};