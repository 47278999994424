import { useCallback, useEffect, useMemo, useState } from 'react';

import { Loader } from '@storybook';
import Modal from '@storybook/new-modal/modal';
import { useNetwork } from 'hooks';
import { API_URL } from 'constant';
import { ExportPdf, SenderDetails, TransactionHead } from './components';
import { TransactionType } from './constants';
import { IDetails } from './stores';

const initialSelectedDetails = {
	sender: {},
	recipient: {},
	paymentInfo: {},
	senderSessionId: '',
	recipientSessionId: '',
	senderAppleId: '',
	senderDeviceId: '',
	recipientAppleId: '',
	recipientDeviceId: '',
};

export const TransactionDetails = ({ isModal, setIsModal, details }: any) => {
	//local states
	const [isLoadingRows, setIsLoadingRows] = useState(false);
	const [selectedDetails, setSelectedDetails] = useState(
		initialSelectedDetails
	);
	const [transactionType, setTransactionType] =
		useState<null | TransactionType>(null);

	//hooks
	const { get, loading } = useNetwork();

	const fetchselectedTransactionIds = useCallback(() => {
		setSelectedDetails(initialSelectedDetails);
		setIsLoadingRows(true);
		if (details?._id) {
			get(`${API_URL.Transactions}/${details._id}?complianceData=false`)
				.then(res => {
					if (res?.data) {
						setSelectedDetails(res.data);
					}
				})
				.finally(() => {
					setIsLoadingRows(false);
				});
		}
	}, [details._id, get]);

	useEffect(() => {
		if (details?._id) {
			fetchselectedTransactionIds();
		}
		// eslint-disable-next-line
	}, [details]);

	const { sender, recipient } = details ?? {};

	const handleCloseModal = useCallback(() => {
		setIsModal(false);
		setTransactionType(null);
	}, [setIsModal]);

	const handleBackModal = useCallback(() => {
		setTransactionType(null);
	}, []);

	const renderHeader = useMemo(() => {
		return !transactionType ? (
			<div className="transaction-header">
				<div className="transaction-heading">Transaction Details</div>
				<div className="kycDetails--sub-header">
					{Object.keys(selectedDetails?.paymentInfo ?? {}).length > 0 && (
						<ExportPdf label="Download" />
					)}
				</div>
				<div
					className="Modal__closeButton override__crossIcon_space"
					onClick={handleCloseModal}
					onKeyPress={handleCloseModal}
					role="button"
					tabIndex={0}
				>
					<i className="ri-close-line Modal__closeIcon" />
				</div>
			</div>
		) : (
			<div className="back__btn">
				<div
					className="Modal__closeButton overring_margin"
					onClick={handleBackModal}
					role="button"
					tabIndex={0}
				>
					<i className="ri-close-line Modal__closeIcon" />
				</div>
			</div>
		);
		// eslint-disable-next-line
	}, [selectedDetails?.paymentInfo, transactionType]);

	const TransactionDetailsProps = {
		senderSessionId: selectedDetails?.senderSessionId,
		recipientSessionId: selectedDetails?.recipientSessionId,
	};

	const getDeviceAppleId = {
		senderAppleId: selectedDetails?.senderAppleId ,
		senderDeviceId: selectedDetails?.senderDeviceId,
		recipientAppleId: selectedDetails?.recipientAppleId,
		recipientDeviceId: selectedDetails?.recipientDeviceId,
	};
	return (
		<Modal
			isOpen={isModal}
			closeModal={handleCloseModal}
			showCloseBtn={false}
			modalName="Transactions"
			className="Transaction--modal"
			isStopOutsideClick={false}
			optionalClassName="modal_padding"
		>
			{isLoadingRows || loading ? (
				<div className="Transactions--loader">
					<Loader />
				</div>
			) : (
				<>
					{renderHeader}

					{Object.keys(selectedDetails?.paymentInfo ?? {}).length > 0 && (
						<TransactionHead
							details={selectedDetails.paymentInfo as IDetails}
							transactionDetails={{ ...TransactionDetailsProps }}
							transactionType={transactionType as TransactionType}
							setTransactionType={
								setTransactionType as (value: TransactionType) => void
							}
							handleBackModal={handleBackModal}
							appleDeviceID={{...getDeviceAppleId}}
						/>
					)}
					{!transactionType &&
						(Object.keys(selectedDetails?.sender ?? {}).length > 0 ||
							Object.keys(selectedDetails?.recipient ?? {}).length > 0) && (
							<div className="TransactionDetails--row">
								<div className="TransactionDetails--left">
									<SenderDetails
										isLoading={isLoadingRows || loading}
										type="Sender"
										details={selectedDetails.sender}
										name={sender}
									/>
								</div>
								<div className="TransactionDetails--right">
									<SenderDetails
										isLoading={isLoadingRows || loading}
										type="Recipient"
										details={selectedDetails.recipient}
										name={recipient}
									/>
								</div>
							</div>
						)}
				</>
			)}
		</Modal>
	);
};
