import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { Loader } from '@storybook';
import { LOGO, BRAND_LOGO } from 'constant';
import {
	IframePropsState,
	IsBannerStatusState,
	IsKycLoading,
	IsOpenInIframeState,
	SelectedMultipleDateState,
	kycSettingsState,
} from 'global-stores';

import {
	ConfigureSurvayFormState,
	ConfiguredStepState,
} from 'views/onboarding-flow/store';
import { SendNotificationState } from 'views/pipeline/store';
import { OnboardingFlowBackModel } from 'components';
import { OnboardingProgress } from '../onboarding-progress';
import './header.scss';

export const OnboardingHeader = () => {
	const navigateToOnboarding = useNavigate();
	const isWebComponentOpen = useRecoilValue(IsOpenInIframeState);
	const { webAdminType, clientlogo = '' } = useRecoilValue(IframePropsState);
	const { whitelabel, logo } = useRecoilValue(kycSettingsState);
	const isLogoLoaded = useRecoilValue(IsKycLoading);
	const bannerStatus = useRecoilValue(IsBannerStatusState);
	const resetFormStepState = useResetRecoilState(ConfigureSurvayFormState);
	const resetReminderDayList = useResetRecoilState(SendNotificationState);
	const setSelectedMultipleDate = useSetRecoilState(SelectedMultipleDateState);
	const resetConfiguredStep = useResetRecoilState(ConfiguredStepState);
	const [isBackModel, setIsBackModal] = useState(false);
	const [isBackLoading, setIsBackLoading] = useState(false);

	const handleLogoClick = useCallback(() => {
		setIsBackModal(true);
	}, []);

	const handleLeaveClick = useCallback(() => {
		setIsBackLoading(true);
		setTimeout(() => {
			setIsBackModal(false);
			navigateToOnboarding('/onboarding');
			resetReminderDayList();
			setSelectedMultipleDate([]);
			resetFormStepState();
			resetConfiguredStep();
		}, 1000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleInput = useCallback(() => {
		setIsBackModal(false);
	}, []);

	const webComponentImage = useMemo(() => {
		return (
			<img
				src={clientlogo || logo || BRAND_LOGO}
				alt="logo"
				className="sidebar-container__companyLogo"
			/>
		);
	}, [clientlogo, logo]);

	const logoUrl = useMemo(() => {
		if (clientlogo?.length) {
			return clientlogo;
		} else return LOGO.LIGHT;
	}, [clientlogo]);

	const renderBrandLogo = useMemo(() => {
		return webAdminType === 'onboarded' || isWebComponentOpen || whitelabel ? (
			webComponentImage
		) : (
			<img
				src={logoUrl}
				className="sidebar-container__brandLogo__img"
				alt="logo"
			/>
		);
	}, [
		isWebComponentOpen,
		logoUrl,
		webAdminType,
		webComponentImage,
		whitelabel,
	]);

	return (
		<div
			className="OnboardingHeader--container"
			style={{ top: bannerStatus === false ? '44px' : '0px' }}
		>
			<div className="OnboardingHeader--left">
				<div className="OnboardingHeader--logo" onClick={handleLogoClick}>
					{isLogoLoaded ? <Loader dimension={24} /> : renderBrandLogo}
				</div>
				<div className="OnboardingHeader--steps">
					<OnboardingProgress />
				</div>
			</div>
			<OnboardingFlowBackModel
				isOpen={isBackModel}
				isLoaded={isBackLoading}
				handleProceedBack={handleLeaveClick}
				handleInput={handleInput}
			/>
		</div>
	);
};
