import { Image } from '@storybook';
import { FC } from 'react';
import './email-verification.scss';

interface IEmailVerification {
	resendCount: number;
	isResendAllowed: boolean;
	handleResend: () => void;
	formattedSeconds: string | number;
	onClickIntercome: () => void;
	email: string;
}
export const EmailVerification: FC<IEmailVerification> = ({
	resendCount,
	isResendAllowed,
	handleResend,
	formattedSeconds,
	onClickIntercome,
	email,
}) => {
	return (
		<div className="EmailVerification">
			<div className="EmailVerification--img">
				<Image fileName="EmailVerificationIcon.svg" />
			</div>
			<section className="EmailVerification--details">
				<h1>Verify your email</h1>
				<p>
					A verification email has been sent to <b> {email ?? 'N/A'}</b>
				</p>
				<p>
					Please check your inbox and spam folder. Kindly click on the link
					provided in the email and complete the verification process from
					there.
				</p>
				<div className="verify-modal__footer verify-modal__footer_verify verify-modal--resend-body">
					{resendCount !== 4 ? (
						<>
							{isResendAllowed ? (
								<div className="verify-modal__footer--resend">
									<span className="footer-text">
										Didn&apos;t receive the text message?
									</span>
									<span
										className="footer-resend-btn"
										onClick={() => handleResend()}
									>
										Resend
									</span>
								</div>
							) : (
								<div className="verify-modal__footer">
									<span className="footer-text">
										Time remaining for next OTP 00:{formattedSeconds}
									</span>
								</div>
							)}
						</>
					) : (
						<div
							className="verify-modal__footer verify-modal--connect"
							onClick={onClickIntercome}
						>
							<div className="verify-modal--icon">
								<i className="ri-user-voice-line"></i>
							</div>
							<span className="footer-resend-btn">
								Facing trouble? Click here for assistance.
							</span>
						</div>
					)}
				</div>
			</section>
		</div>
	);
};
