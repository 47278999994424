import { FC } from 'react';
import { BeneficialOwnersCard } from '../../benificial-owners';

interface IBeneficialOwners {
	name: string;
	designation: string;
	maxShares?: {
		ownershipMaxShares?: string;
	};
	totalPercentageOfShares?: string;
	totalValuation?: string;
	metadata?: {
		totalPercentageOfShares?: string;
		totalValuation?: string;
	};
}

interface IProps {
	items: IBeneficialOwners[];
}

export const BeneficialOwners: FC<IProps> = ({ items }) => {
	if (!items.length) {
		return <></>;
	}

	return (
		<div>
			{(items ?? []).map(beneficialOwner => {
				const { name, maxShares, metadata } = beneficialOwner ?? {};
				return (
					<BeneficialOwnersCard
						heading={name ?? '--'}
						metadata={metadata ?? {}}
						ownership={maxShares?.ownershipMaxShares ?? 'N/A'}
						key={name}
					/>
				);
			})}
		</div>
	);
};
