import { FC, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import {
	InviteDetailsState,
	LoginPhoneNumberState,
	UserRolesState,
} from 'global-stores';
import { ReadWritePermission } from '../read-write-permission';
import { IdentityType } from 'views/user-identity-flow';

interface IPreviewInfo {
	accountType?: string;
}
export const PreviewInfo:FC<IPreviewInfo> = ({accountType}) => {
	const invites = useRecoilValue(InviteDetailsState);
	const loginPhoneNumber = useRecoilValue(LoginPhoneNumberState);
	const userRoles = useRecoilValue(UserRolesState);

	const { email, firstName, lastName, funds, role, subAccount } = invites ?? {};
	const { countryCode, phone } = loginPhoneNumber ?? {};

	const userRole = useMemo(
		() => (userRoles ?? []).find(el => el._id === role),
		[role, userRoles]
	);

	return (
		<div className="PreviewInfo">
			<div className="PreviewInfo--container">
				<div className="PreviewInfo--key">Full Name</div>
				<div className="PreviewInfo--seperator">:</div>
				<div className="PreviewInfo--value">
					{firstName} {lastName}
				</div>
			</div>

			<div className="PreviewInfo--container">
				<div className="PreviewInfo--key">Email</div>
				<div className="PreviewInfo--seperator">:</div>
				<div className="PreviewInfo--value">{email ?? 'NA'}</div>
			</div>

			<div className="PreviewInfo--container">
				<div className="PreviewInfo--key">Phone Number</div>
				<div className="PreviewInfo--seperator">:</div>
				<div className="PreviewInfo--value">
					{countryCode} {phone}
				</div>
			</div>
			{accountType === IdentityType.Company &&
				<div className="PreviewInfo--container">
					<div className="PreviewInfo--key">Sub Account</div>
					<div className="PreviewInfo--seperator">:</div>
					<div className="PreviewInfo--value">{subAccount?.label ?? 'NA'}</div>
				</div>
			}
			<div className="PreviewInfo--container">
				<div className="PreviewInfo--key">Role</div>
				<div className="PreviewInfo--seperator">:</div>
				<div className="PreviewInfo--value">{userRole?.name ?? 'NA'}</div>
			</div>
			<div className="PreviewInfo--container">
				<div className="PreviewInfo--key">Funds</div>
				<div className="PreviewInfo--seperator">:</div>
				<div className="PreviewInfo--funds">
					{funds ? (
						(funds ?? []).map(el => (
							<div
								key={el?.value}
								className="PreviewInfo--value PreviewInfo--fund"
							>
								{el?.label}
							</div>
						))
					) : (
						<div className="PreviewInfo--value">NA</div>
					)}
				</div>
			</div>

			<div className="PreviewInfo--services">
				<div className="PreviewInfo--service">
					{(userRole?.services ?? [])?.map(
						(el: string, idx: number, arr: string[]) => (
							<h2 key={el}>
								{el}
								{idx !== arr?.length - 1 && <span> +</span>}
							</h2>
						)
					)}
				</div>
				<div className="PreviewInfo--price">
					<h2>${userRole?.price ?? 'NA'}</h2>
					<span>/user/month</span>
				</div>
				<ReadWritePermission userRoles={userRole ?? undefined} />
			</div>

			<div className="PreviewInfo--footer">
				<p>
					As soon as a new user accepts the invite, their subscription amount
					will be deducted immediately. The next month is prepaid subscription
					amount will be added to the current billing cycle and deducted on the
					next cycle date.
				</p>
			</div>
		</div>
	);
};
