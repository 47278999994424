import { Loader } from '@storybook';
import { FC } from 'react';

import { useWire } from '../../hooks';

interface IWireScreen {
	isViewPage?: boolean;
}
export const WireScreen: FC<IWireScreen> = ({ isViewPage = false }) => {
	const {
		wire = {},
		handleChangeDefaultCheck,
		defaultCheck,
		loading,
	} = useWire();

	return (
		<section className={`WirePage ${isViewPage ? 'WireView' : ''}`}>
			<div className="WirePage--header">
				<h2>Wire transfer detail</h2>
				<p>Please wire transfer money to the given account details below</p>
			</div>
			<div className="WirePage--details">
				<div className="WirePage--details__key">Account number</div>
				<div>:</div>
				<div className="WirePage--details__value">
					{wire.accountNumber ?? '--'}
				</div>
			</div>
			<div className="WirePage--details">
				<div className="WirePage--details__key">Banking Name</div>
				<div>:</div>
				<div className="WirePage--details__value">{wire.bankName ?? '--'}</div>
			</div>
			<div className="WirePage--details">
				<div className="WirePage--details__key">Routing number</div>
				<div>:</div>
				<div className="WirePage--details__value">
					{wire.routingNumber ?? '--'}
				</div>
			</div>
			{!defaultCheck ? (
				<div className="billing-add--check">
					{!loading ? (
						<input
							type="checkbox"
							onChange={handleChangeDefaultCheck}
							checked={defaultCheck}
						/>
					) : (
						<Loader type="loader" className="loader-white" dimension={10} />
					)}

					<div className="WirePage--details__text">
						Set as primary payment method
					</div>
				</div>
			) : (
				<div className="payment-save-card-primary">Primary</div>
			)}
			<div className="WirePage--footer">
				<i className="ri-alarm-warning-line" />
				<span>Note:</span>$15 will be charged for every transaction done
				using domestic wire transfer.
			</div>
		</section>
	);
};
