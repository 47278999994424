import { FC, useEffect, useState } from 'react';

import { Image } from '@storybook';
import { useStatusDocuments } from '../../../../hooks';

import './progress.scss';

interface IProgress {
	uploadPercentage: number;
	label: string;
}

export const Progress: FC<IProgress> = ({ uploadPercentage, label }) => {
	// local states
	const [count, setCount] = useState<number>(uploadPercentage);

	// hooks
	const { fileName } = useStatusDocuments()

	useEffect(() => {
		setCount(uploadPercentage);
	}, [uploadPercentage]);

	useEffect(() => {
		if (count < 100) {
			setTimeout(() => {
				setCount(prev => prev + 10);
			}, 100);
		}
	}, [count]);

	return (
		<div className="Progress__box">
			<div className="Progress__icon">
				<Image fileName={fileName(label ?? '')} width="24px" />
			</div>
			<div className="Progress__right_box">
				<div className="Progress__labelbox">
					<p className="Progress__label-text">{label}</p>
					<div className="Progress__progess-count">{'uploading...'}</div>
				</div>
				<div className="Progress__bar">
					<div
						className="Progress__slider"
						style={{
							width: `${count}%`,
						}}
					/>
				</div>
			</div>
		</div>
	);
};
