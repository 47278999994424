import { FC, PropsWithChildren } from 'react';
import { useRecoilValue } from 'recoil';

import { PipelineSettingsFormState } from 'global-stores';
import { Image } from '@storybook';

interface IActionButtonProps {
	showButton: boolean;
	name: string;
	className: string;
	ariaLabel: string;
}

const ActionButtons: FC<PropsWithChildren<IActionButtonProps>> = ({
	showButton,
	name,
	className,
	ariaLabel,
	children,
}) => {
	if (showButton) {
		return (
			<button name={name} className={className} aria-label={ariaLabel}>
				{children}
			</button>
		);
	}
	return null;
};

export const Success = () => {
	const successMessage = useRecoilValue(PipelineSettingsFormState);
	const {
		isAppStoreUrl,
		isPlayStoreUrl,
		isCustomBtnUrl,
		customRedirectBtnText,
		customMessage,
	} = successMessage;

	return (
		<div className="success-wrapper">
			<div className="success-wrapper__greetings">
				<div className="success-wrapper__success-icon">
					<i
						className="ri-check-line"
						aria-hidden="true"
						aria-label="success icon"
					/>
				</div>
				<h3 className="success-wrapper__greetings-message">
					Verification Completed
				</h3>
			</div>
			<p className="success-wrapper__custom-message">{customMessage || ''}</p>
			<div className="success-wrapper__actions">
				<ActionButtons
					showButton={isAppStoreUrl}
					name="app store button"
					className="success-wrapper__app-store-action"
					ariaLabel="Open App Store"
				>
					<Image fileName="appstore.svg" width="108px" height="32px" />
				</ActionButtons>
				<ActionButtons
					showButton={isPlayStoreUrl}
					name="app store button"
					className="success-wrapper__app-store-action"
					ariaLabel="Open Play Store"
				>
					<Image fileName="googleplay.svg" width="108px" height="32px" />
				</ActionButtons>{' '}
				<ActionButtons
					showButton={isCustomBtnUrl}
					className="success-wrapper__custom-explore"
					ariaLabel="Redirect to custom url"
					name="custom button"
				>
					{customRedirectBtnText || "Explore"}
				</ActionButtons>
			</div>
		</div>
	);
};
