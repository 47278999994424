import { atom } from 'recoil';
import { SignerType } from './types';

export const SelectedSignerState = atom<SignerType>({
	key: 'selected-signer-state-key',
	default: '',
});
/**
 * @Manish new state to manage status of create template modal on or off
 * */ 
export const IsCreateTemplateStepperOpen = atom<boolean>({
	key: "is-create-template-stepper-open-state-key",
	default: false,
})