import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';

import { Button } from '@storybook';
import {
	ActiveTabsIntegrateState,
	SelectedTabState,
	SideDrawerState,
} from 'global-stores';
import { ROUTES } from 'routes';
import { INTEGRATE_LEFT_NAV } from 'views/integrate/constants';
import './integrate-header.scss';

export const IntegrateHeader = () => {
	const [selectedTab, setSelectedTab] = useRecoilState(SelectedTabState);
	const setIsDrawerOpen = useSetRecoilState(SideDrawerState);

	const activeTab = useRecoilValue(ActiveTabsIntegrateState);
	const resetActiveTab = useResetRecoilState(ActiveTabsIntegrateState);

	const navigate = useNavigate();

	useEffect(() => {
		setSelectedTab('home');
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const tabClickHandle = useCallback((route: string) => {
		setSelectedTab(route);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleBackDashboard = useCallback(() => {
		navigate(ROUTES.DASHBOARD);
		resetActiveTab();
	}, [navigate, resetActiveTab]);

	return (
		<div className="header-wrapper">
			<Button
				type=""
				label="Back to dashboard"
				handleClick={handleBackDashboard}
				prefixIcon="ri-arrow-left-s-line"
			/>
			<div className="IntegrateHeader--row">
				<>
					{INTEGRATE_LEFT_NAV.map(nav => {
						const { ICON, TITLE, ROUTE } = nav;
						if (activeTab === 'iframe' && ROUTE === 'home') {
							return <div key={TITLE}></div>;
						}
						return (
							<div
								key={TITLE}
								className={`left-nav__tab  ${
									selectedTab === `${ROUTE}` && 'left-nav__tab--active'
								}`}
								onClick={() => tabClickHandle(ROUTE)}
							>
								<i className={`left-nav__icon ${ICON}`} />
								<div className="left-nav__text">{TITLE}</div>
							</div>
						);
					})}
				</>
				<div
					className="IntegrateHeader--menu"
					onClick={() => setIsDrawerOpen(true)}
				>
					<i className="ri-menu-line left-nav__icon" />
					<div className="left-nav__text">Customize</div>
				</div>
			</div>
		</div>
	);
};
