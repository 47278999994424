import { Button, Image } from '@storybook';
import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
	BusinessPaymentCardState,
	SubscriptionPriceState,
	loginState,
} from 'global-stores';
import { useFullStoryTrackEvent } from 'hooks';
import { useBillingTransaction } from 'views/settings';
import {
	ChooseIdentityData,
	IndetityFlowNavigate,
} from 'views/user-identity-flow/constants';
import {
	ChooseUserIdentity,
	SubscriptionIdentityNaviagtion,
} from 'views/user-identity-flow/store';

export const SuccessPage = ({ handleClose }: { handleClose: () => void }) => {
	const activeIdx = useRecoilValue(ChooseUserIdentity);
	const businessPaymentCardState = useRecoilValue(BusinessPaymentCardState);
	const setNavigate = useSetRecoilState(SubscriptionIdentityNaviagtion);
	const user = useRecoilValue(loginState);
	const subscriptionPrice = useRecoilValue(SubscriptionPriceState);

	const price = useMemo(() => {
		if (subscriptionPrice) {
			return (subscriptionPrice as any)[0]?.[activeIdx];
		} else return 0;
	}, [activeIdx, subscriptionPrice]);

	const { billingTransaction: getBillingTransaction } = useBillingTransaction();
	const { trackEvent } = useFullStoryTrackEvent();

	const accountTypeLabel = useMemo(
		() => ChooseIdentityData.find(el => el.type === activeIdx),
		[activeIdx]
	);

	const trackFSSession = useCallback(() => {
		const {
			userId,
			firstName,
			approveStatus = '',
			lastName,
			companyName = '',
			countryCode,
			phone,
			email,
			accountType = '',
		} = user ?? {};
		const properties = {
			user: {
				userId,
				firstName,
				lastName,
				phone: `${countryCode} ${phone}`,
				email,
			},
			createdAt: new Date(),
			subscriptionName: accountType,
			amount: price,
			businessName: companyName,
			businessApproved: approveStatus,
		};

		trackEvent('subscribed', properties);
	}, [price, trackEvent, user]);

	const hasBillingAddress = useMemo(() => {
		if (!businessPaymentCardState) return false;
		else if (
			Object.values(businessPaymentCardState?.billingAddress ?? {})?.every(
				el => el
			)
		)
			return true;
		else return false;
	}, [businessPaymentCardState]);

	const addBillingInfo = () => {
		setNavigate(IndetityFlowNavigate.BillingForm);
	};

	useEffect(() => {
		trackFSSession();
		getBillingTransaction();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="SuccessPage">
			<div className="SuccessPage--icon">
				<Image fileName="success.gif" loading="lazy" />
			</div>
			<div className="SuccessPage--info">
				<h2 className="SuccessPage--info__heading">Subscribed successfully</h2>
				<p className="SuccessPage--info__desc">
					You&apos;ve successfully subscribed for{' '}
					<span>{accountTypeLabel?.label}.</span>
				</p>
			</div>
			{!hasBillingAddress ? (
				<Button
					handleClick={addBillingInfo}
					label="Add billing info"
					type="button__filled button__filled--primary button__large"
				/>
			) : (
				<Button
					handleClick={handleClose}
					label="Close"
					type="button__filled button__filled--secondary button__large"
				/>
			)}
		</div>
	);
};
