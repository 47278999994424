/* eslint-disable no-use-before-define */
import { FC } from 'react';
import { BeneficialOwnersCard } from '../../benificial-owners';
import { KybInfoHeader } from '../../kyb-info-header';

export interface IIndustryDetails {
	value: Value[];
}

export interface Value {
	code: Code;
	name: Name;
	description: Description;
	metaDetail: Record<string, any>;
	source: Source;
}

export interface Code {
	value: string;
}

export interface Name {
	value: string;
}

export interface Description {
	value: any;
}

export interface Source {
	value: string;
}

interface IProps {
	items: IIndustryDetails;
}

export const IndustryDetails: FC<IProps> = ({ items }) => {
	if (!items?.value?.length) {
		return <></>;
	}

	return (
		<>
			<KybInfoHeader type="Industry Details" />
			{(items?.value ?? []).map(industryDetail => {
				const { name, code, metaDetail, description } = industryDetail ?? {};
				return (
					<BeneficialOwnersCard
						heading={name?.value ?? '--'}
						metadata={{
							...(metaDetail ?? {}),
							description: description?.value || '',
						}}
						ownership={code?.value}
						subTitle="Code"
						key={name?.value}
					/>
				);
			})}
		</>
	);
};
