export const ChartTitleActivity: { [key: string]: string } = {
	kyc: 'KYC',
	kyb: 'KYB',
	aml: 'AML',
	'506b': 'Accreditation 506b',
	'506c': 'Accreditation 506C',
	payIn: 'Funds Pay In',
	payOut: 'Funds Pay Out',
	signAgreement: 'Sign Agreement',
	form: 'Form',
	proofReading: 'Document Review',
	individualCreditReport: 'Individual Credit Report',
	checkDefense: 'Check Defense',
	kycComprehensive: 'KYC Comprehensive',
	businessCreditReport: 'Business Credit Reports',
	kybComprehensive: 'KYB Comprehensive',
	kybNegativeFilings: 'KYB Negative Filings',
	representativeKycAndAmlVerification: 'Representative KYC and AML Verification',
	kybSubsidiary:"KYB Subsidiary",
	businessSanction:"Business Sanctions and OFAC"
};

export const ChartXAxisActivity: { [key: string]: string } = {
	KYC: 'kyc',
	KYB: 'kyb',
	AML: 'aml',
	'Accreditation 506b': '506b',
	'Accreditation 506C': '506c',
	'Funds Pay In': 'payIn',
	'Funds Pay Out': 'payOut',
	'Sign Agreement': 'signAgreement',
};

export const ActivityActionName = [
	'kyc',
	'kyb',
	'aml',
	'506b',
	'506c',
	'payIn',
	'payOut',
	'signAgreement',
];
