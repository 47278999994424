import { useCallback, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { API_URL } from 'constant';
import { useNetwork, useNotification } from 'hooks';
import { SignedDocsState } from './state';

export const useSignedDocs = () => {
	const setSignedDocs = useSetRecoilState(SignedDocsState);

	const [isLoading, setIsLoading] = useState(false);
	const [isLoaded, setIsLoaded] = useState(true);

	const { get: getSignedDocs } = useNetwork();
	const { remove: deleteSignedDoc } = useNetwork();
	const { successNotification } = useNotification();

	function sortByDate(arr: { createdAt: string }[]): { createdAt: string }[] {
		arr.sort(
			(a, b) =>
				new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
		);
		return arr;
	}
	const getDocuments = useCallback(() => {
		setIsLoading(true);
		setIsLoaded(false);
		getSignedDocs(API_URL.SIGN_AGREEMENT)
			.then(resp => {
				const { data: temp } = resp ?? {};
				const sortedDataByDate = sortByDate(temp);
				const allowedDocs = ['reconfig', 'saved'];
				const data = sortedDataByDate?.filter((i: any) => {
					if (i.status) {
						return allowedDocs.includes(i.status);
					}
					return true;
				});
				if (data) {
					setSignedDocs(data);
				}
				setIsLoading(false);
				setIsLoaded(true);
			})
			.catch(() => {
				setIsLoading(false);
				setIsLoaded(true);
			});
	}, [getSignedDocs, setSignedDocs]);
	
	const getDocument = useCallback(async (templateId: string) => {
		setIsLoading(true);
		setIsLoaded(false);
		try {
			const resp = await getSignedDocs(`${API_URL.SIGN_AGREEMENT}/${templateId}?copy=false`);
			return resp.data;
		} catch (error) {
			return {}
		} finally {
			setIsLoading(false);
			setIsLoaded(true);
		}
		
	}, [getSignedDocs]);

	const deleteDocument = useCallback(
		(id: string, index: number) => {
			setSignedDocs(prev => {
				const prevState = [...prev];
				prevState.splice(index, 1);
				return prevState;
			});
			successNotification('Template deleted successfully.');
			deleteSignedDoc(`${API_URL.SIGN_AGREEMENT}/${id}`);
		},
		[deleteSignedDoc, setSignedDocs, successNotification]
	);

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const createDocument = useCallback(() => ({}), []);

	return {
		getDocuments,
		getDocument,
		createDocument,
		deleteDocument,
		isLoading,
		isLoaded,
	};
};
