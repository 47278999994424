import { Loader } from '@storybook';
import { useCallback, useEffect, useState } from 'react';
import { API_URL } from 'constant';
import { REACT_APP_KYC_FRONTEND_DOMAIN, REACT_APP_KYC_KYB_ID } from 'envs';
import { useNetwork, useNotification } from 'hooks';
import './kyc-web-components.scss';

declare global {
	// eslint-disable-next-line @typescript-eslint/no-namespace
	namespace JSX {
		interface IntrinsicElements {
			'simplici-onboarding': any;
		}
	}
}

interface IFrameData {
	type: 'handleChange' | 'onComplete';
	data: Record<string, any>;
}

interface IKycWebComponents {
	type: 'kyc' | 'kyb' | string;
	handleSuccess: () => void;
	businessType?: string;
}
const { BUSINESS_COMPLIANCE } = API_URL;
export const KycWebComponents = ({
	type,
	handleSuccess,
	businessType,
}: IKycWebComponents) => {
	const [isLoading, setIsLoading] = useState(true);
	const [sessionCode, setSessionCode] = useState('');
	const [iFrameLoading, setIFrameLoading] = useState(true);

	// Hooks
	const { post } = useNetwork();
	const { errorNotification } = useNotification();

	const handleSessionCode = useCallback(async () => {
		await post(BUSINESS_COMPLIANCE, {
			qrId: REACT_APP_KYC_KYB_ID,
			type: businessType,
		}).then(res => {
			if (res?.sessionCode?.length > 0) {
				setSessionCode(res.sessionCode);
			} else {
				errorNotification('Session Not Found!');
			}
			setIsLoading(false);
		});
		return null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const appendScripts = async () => {
		// await InjectScript([
		// 	{
		// 		type: 'script',
		// 		// link: ${REACT_APP_KYC_FRONTEND_BUILD},
		// 		link: REACT_APP_KYC_FRONTEND_DOMAIN,
		// 	},
		// ]);
		await handleSessionCode();
		setIsLoading(false);
	};

	const onStepChange = useCallback(
		async function (data: any) {
			if (type === 'kyc' && data.key === 'kybVerification') {
				handleSuccess();
				return;
			}
			if (data.key === 'successScreenCompletion') {
				handleSuccess();
			}
		},
		[handleSuccess, type]
	);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
	(window as any).onSessionComplete = function (_data: any) { };

	useEffect(() => {
		appendScripts();
		// Add an event listener to listen for messages from the iframe
		window.addEventListener('message', event => {
			// Check if the message is from the iframe (if needed)
			// For security, you should validate event.origin if your parent window accepts messages from multiple sources
			// if (event.origin !== 'https://your-iframe-origin.com') return;

			// Handle the received data from the iframe
			const receivedData: IFrameData = event.data;
			if (receivedData?.type === 'handleChange') {
				onStepChange(receivedData.data);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="Kyc--WebComponents__container">
			{(isLoading || iFrameLoading) && (
				<div className="BusinessSession--loader BusinessSession--iframe-loader">
					<Loader />
					<div className="Business-Rejected--title">
						Please wait while we setup signup flow for you.
					</div>
				</div>
			)}
			{!isLoading && (
				<iframe
					src={`${REACT_APP_KYC_FRONTEND_DOMAIN}/${sessionCode}?type=complex&isfromsignup=true`}
					// src="http://localhost:3001/da27138f-a177-4b42-8c99-abc8300800cc?type=complex"
					allow="camera; microphone; geolocation;"
					style={{ border: 'none', height: 'calc(100vh - 60px)', width: '100%' }}
					onLoad={() => setIFrameLoading(false)}
				/>
			)}
		</div>
	);
};
