import { FC } from 'react';

import { DonutPieChart } from 'views/support-agent';

interface IUserFaceMatch {
	title: string;
	matchPercent: number;
	matchChartColor: string;
	unMatchChartColor: string;
	id:string;
}
export const UserFaceMatch: FC<IUserFaceMatch> = ({
	title,
	matchPercent,
	matchChartColor,
	unMatchChartColor,
	id=""
}) => {
	return (
		<div className="User-faceMatch">
			<div className="User-faceMatch--width">
				<DonutPieChart
					isSession={true}
					isLoading={false}
					centerTitle=""
					centerValue={matchPercent}
					title={`${title}-${id}`}
					chartWidth={90}
					percentColor={matchChartColor}
					seriesData={[
						{
							name: 'Matched',
							y: matchPercent,
							color: matchChartColor,
						},
						{
							name: 'Not-Matched',
							y: 100 - matchPercent,
							color: unMatchChartColor,
						},
					]}
				/>
			</div>

			<div className="User-faceMatch_description">
				<div className="User-faceMatch_title">{title}</div>
				<div className="User-faceMatch_sub-title">Face match</div>
			</div>
		</div>
	);
};
