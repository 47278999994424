import { useCallback, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { Button } from '@storybook';
import Modal from '@storybook/new-modal/modal';
import {
	ConnectedClient,
	EmailSuccess,
	EmailVerify,
	EnterCredentials,
	SelectEmailClient,
} from './components';
import { EmailClientModalTitle } from './constants';
import {
	ActiveEmailClientFormDataState,
	ActiveEmailClientMethodState,
	ActiveEmailClientState,
	ClientEmailNavigateState,
	useResetCredentials,
} from './stores';

import './email-client.scss';

export const AddEmailClient = () => {
	//globel states
	const [clientEmailNavigate, setClientEmailNavigate] = useRecoilState(
		ClientEmailNavigateState
	);
	const activeEmailClient = useRecoilValue(ActiveEmailClientState);
	const resetActiveEmailClient = useResetRecoilState(ActiveEmailClientState);
	const resetActiveEmailClientMethod = useResetRecoilState(
		ActiveEmailClientMethodState
	);
	const activeEmailClientMethod = useRecoilValue(ActiveEmailClientMethodState);
	const credentialData = useRecoilValue(
		ActiveEmailClientFormDataState(activeEmailClientMethod)
	);

	// local state
	const [openModal, setOpenModal] = useState(false);

	// hooks
	const { resetCredential } = useResetCredentials();

	const { methods } = activeEmailClient ?? {};

	const { credentials } =
		methods?.find(method => method.key === activeEmailClientMethod) ?? {};

	// handle close
	const handleClose = useCallback(() => {
		setOpenModal(false);
		setTimeout(() => {
			resetCredential();
			setClientEmailNavigate('select-client');
			resetActiveEmailClient();
			resetActiveEmailClientMethod();
		}, 500);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// handle back
	const handleBack = useCallback(() => {
		switch (clientEmailNavigate) {
			case 'select-client':
				handleClose();
				return;
			case 'enter-client-details':
				setClientEmailNavigate('select-client');
				return;
			case 'client-verify':
				setClientEmailNavigate('enter-client-details');
				return;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clientEmailNavigate, handleClose]);

	// handle next
	const handleNext = useCallback(() => {
		switch (clientEmailNavigate) {
			case 'select-client':
				setClientEmailNavigate('enter-client-details');
				return;
			case 'enter-client-details':
				setClientEmailNavigate('client-verify');
				return;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clientEmailNavigate]);

	// handle disabled
	const handleDisabled = useMemo(() => {
		switch (clientEmailNavigate) {
			case 'select-client':
				return !activeEmailClient;
			case 'enter-client-details':
				return !(
					credentials?.filter(cred => cred.isRequired)?.length ===
						Object.keys(credentialData).length &&
					Object.values(credentialData).indexOf('') === -1 &&
					encodeURIComponent(credentialData['email'] as string)
				);
			default:
				return false;
		}
	}, [clientEmailNavigate, activeEmailClient, credentials, credentialData]);

	const renderFooter = useMemo(() => {
		return (
			<div className="add-email-clients-modal__footer_wrapper">
				<Button
					handleClick={handleBack}
					label="Back"
					type="button__filled button__filled--secondary"
				/>
				<Button
					handleClick={handleNext}
					label="Next"
					disabled={handleDisabled}
					type="button__filled button__filled--primary"
				/>
			</div>
		);
	}, [handleNext, handleBack, handleDisabled]);

	const renderBody = useMemo(() => {
		switch (clientEmailNavigate) {
			case 'select-client':
				return <SelectEmailClient />;
			case 'enter-client-details':
				return <EnterCredentials />;
			case 'client-verify':
				return <EmailVerify />;
			case 'verification-status':
				return <EmailSuccess handleClose={handleClose} />;
			default:
				return <SelectEmailClient />;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clientEmailNavigate]);

	const hideHeaderFooter = useMemo(
		() =>
			clientEmailNavigate === 'client-verify' ||
			clientEmailNavigate === 'verification-status',
		[clientEmailNavigate]
	);

	return (
		<>
			<ConnectedClient handleClick={() => setOpenModal(true)} />
			<Modal
				isOpen={openModal}
				modalName="Add email client modal"
				closeModal={handleClose}
				showCloseBtn={true}
				isStopOutsideClick={false}
				title={
					!hideHeaderFooter ? (
						<div className="add-email-clients-modal__header_wrapper">
							<div className="add-email-clients-modal__header_wrapper__title">
								{EmailClientModalTitle[clientEmailNavigate as 'select-client']}
							</div>
							<div className="add-email-clients-modal__header_wrapper__sub-title"></div>
						</div>
					) : (
						<></>
					)
				}
				className="add-email-clients-modal"
			>
				<div className="add-email-clients-modal__body_wrapper">
					{renderBody}
				</div>
				{!hideHeaderFooter && renderFooter}
			</Modal>
		</>
	);
};
