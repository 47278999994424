import { Button, Loader } from '@storybook';
import Modal from '@storybook/new-modal/modal';
import { API_URL } from 'constant';
import { useNetwork } from 'hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import {
	EnvelopeLinkUnlinkPayload,
	useSignedDocsInbox,
} from 'views/signed-doc-inbox';
import SignedDocInbox from 'views/signed-doc-inbox/signed-doc-inbox';

interface ISessionEsignLink {
	isOpen: boolean;
	handleCloseModal: () => void;
	selectedSession: { _id: string; linkedEnvelopes: string[] };
	initComplexSessionData: (data: any) => void;
}

export const SessionEsignLink = ({
	isOpen,
	handleCloseModal,
	selectedSession,
	initComplexSessionData,
}: ISessionEsignLink) => {
	const statusParam =
		'configured&status=completed&purpose=workflow&purpose=selfsign';
	const [isLoading, setIsLoading] = useState(false);
	const { linkEnvelopes, unlinkEnvelopes } = useRecoilValue(
		EnvelopeLinkUnlinkPayload
	);
	const resetEnvelopesLinkUnlink = useResetRecoilState(
		EnvelopeLinkUnlinkPayload
	);
	const { getSignedDocsInbox } = useSignedDocsInbox();
	const { patch } = useNetwork();

	const modalTitle = useMemo(
		() => (
			<>
				<p className="modal-title__heading">Link eSign Documents</p>
				<small className="modal-title__desc">
					Select the eSign documents you want to associate with Person Name
					onboarding session.
				</small>
			</>
		),
		[]
	);

	const handlSaveLinkUnlink = useCallback(async () => {
		if (!linkEnvelopes.length && !unlinkEnvelopes.length) {
			handleCloseModal();
			return;
		}
		setIsLoading(true);
		await patch(API_URL.LINK_ENVELOPES + `/${selectedSession._id}`, {
			linkEnvelopes,
			unlinkEnvelopes,
		});
		handleCloseModal();
		initComplexSessionData({});
		resetEnvelopesLinkUnlink();
		setIsLoading(false);
	}, [
		handleCloseModal,
		initComplexSessionData,
		linkEnvelopes,
		patch,
		resetEnvelopesLinkUnlink,
		selectedSession._id,
		unlinkEnvelopes,
	]);

	useEffect(() => {
		getSignedDocsInbox(false, statusParam);
	}, [getSignedDocsInbox]);

	return (
		<Modal
			isOpen={isOpen}
			closeModal={handleCloseModal}
			className="SessionEsignLink__modal"
			modalName="Session Esign Link"
			title={modalTitle}
			titleWithCss="SessionEsignLink__modal-title"
		>
			<div className="SessionEsignLink__modal-body">
				<SignedDocInbox
					screen="link-esign"
					status={statusParam}
					linkedEnvelopes={selectedSession?.linkedEnvelopes}
				/>
			</div>
			<div className="SessionEsignLink__modal-footer">
				<Button
					type="button__filled--primary"
					disabled={isLoading}
					handleClick={handlSaveLinkUnlink}
					label={isLoading ? <Loader type="loader" dimension={20} /> : 'Save'}
				/>
				<Button type="button__filled--secondary" handleClick={handleCloseModal} label="Cancel" />
			</div>
		</Modal>
	);
};
