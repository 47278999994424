import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Image } from '@storybook';

import {
	CreateSignState,
	GenerateSignImagesState,
	IsFontsLoadedState,
} from '../store';

import { base64ImageFromString } from 'utils';

export const GenerateSign = () => {
	const [signature, setSignature] = useRecoilState(CreateSignState);
	const signatureFamilies = useRecoilValue(GenerateSignImagesState);
	const selectedID = signature.generate.id;
	const isFontsLoaded = useRecoilValue(IsFontsLoadedState);
	useEffect(() => {
		const item = signatureFamilies[0];
		setSignature(prev => {
			const clonedState = structuredClone(prev);
			clonedState.generate = {
				...clonedState.generate,
				sign: item?.name,
				initials: item?.initial,
				id: item?._id,
			};
			return clonedState;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [signatureFamilies]);

	const handleClick = useCallback(
		(item: { name: string; initial: string; signId: string }): void => {
			setSignature(prev => {
				const ClonedPrev = JSON.parse(JSON.stringify(prev));
				ClonedPrev.generate = {
					...ClonedPrev.generate,
					sign: item.name,
					initials: item.initial,
					id: item.signId,
				};
				return ClonedPrev;
			});
		},
		[setSignature]
	);

	const renderGenerateData = useMemo(() => {
		return signatureFamilies.map(item => {
			const { initial, name, _id: signId } = item ?? {};
			return (
				<div
					key={signId}
					className={`admin-generate-sign__sign-samples admin-generate-sign__sign-samples${signId === selectedID ? '--active' : ''
						}`}
					onClick={() => handleClick({ initial, name, signId })}
				>
					{signId === selectedID && (
						<div className="admin-generate-sign__checkbox">
							<i
								style={{ color: '#33B87A' }}
								className="ri-checkbox-circle-fill"
							></i>
						</div>
					)}
					<div className="admin-generate-sign__sign-samples__name-box">
						<div className="admin-generate-sign__full_name">
							<span>
								<Image fileName="SimpliciSign.svg" />
							</span>
							<img
								className="admin-generate-sign__full_name_image"
								src={base64ImageFromString(name)}
								alt="fullName"
							/>
						</div>
						<div className="admin-generate-sign__initials">
							<span>
								<Image fileName="SimpliciSign.svg" />
							</span>
							<img
								className="admin-generate-sign__initials_image"
								src={base64ImageFromString(initial)}
								alt="Initial"
							/>
						</div>
					</div>
				</div>
			);
		});
	}, [signatureFamilies, selectedID, handleClick]);

	const skeletonLoader = useMemo(
		() =>
			[1, 2, 3, 4, 5, 6].map(value => (
				<div className="admin-create-sign__loader-light" key={value}></div>
			)),
		[]
	);
	return (
		<div className="admin-generate-sign">
			<div className="admin-generate-sign__heading">
				Select Signature & Initial style
			</div>
			<div className="admin-generate-sign__wrapper">
				<div className="admin-generate-sign__container">
					{isFontsLoaded ? renderGenerateData : <>{skeletonLoader}</>}
				</div>
			</div>
		</div>
	);
};
