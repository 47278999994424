import { selector } from 'recoil';
import { SignedDocInboxState } from './states';

export const SignDocInboxSelector = selector({
	key: 'sign-document-inbox-selector-key',
	get: ({ get }) => {
		const { data, isLoaded } = get(SignedDocInboxState);
		return {
			data,
			isLoaded
		};
	},
});
