import type { ISideDrawer as Props } from './type';

import './drawer.scss';

/**
 * SideDrawer component
 * @param {boolean} Props.visible - Flag indicating whether the side drawer is visible.
 * @param {function} Props.setVisible - Callback function to control the visibility of the side drawer.
 * @param {ReactNode} Props.body - Content to be displayed inside the side drawer.
 */

export const SideDrawer = ({ visible, setVisible, body }: Props) => {
	return (
		<div>
			<div
				className={`overlay ${visible ? 'overlay--show' : ''}`}
				onClick={() => setVisible(false)}
			/>
			<div className={`drawer ${visible ? 'drawer--open' : ''}`}>
				<div className="drawer__close-btn" onClick={() => setVisible(false)}>
					<i className="ri-close-line" />
				</div>
				<div className="drawer__body">{body}</div>
			</div>
		</div>
	);
};
