import { atom } from 'recoil';
import { ICostBreakdownDetails, ISummaryBilling } from '../component';

export const PaymentNavigationState = atom<string>({
	key: 'payment-navigation-state',
	default: 'Choose',
});

export const ActivePaymentType = atom<string>({
	key: 'active-payment-type',
	default: 'CardView',
});

export const PrimaryAccountChecked = atom({
	key: 'primary-account-checked',
	default: false,
});

export const AddCardModalState = atom({
	key: 'add-card-modal-state',
	default: {
		isOpen: false,
		isEdit: false,
	},
});

export const BillingAddressFromState = atom<'' | 'from_billing_info'>({
	key: 'billing-address-from-state-key',
	default: '',
});
export const PrimaryAchAccountChecked = atom<{
	id: string;
	accountId: string;
	accountType: string;
}>({
	key: 'primary-ach-cccount-checked',
	default: {
		id: '',
		accountId: '',
		accountType: '',
	},
});

// Pradeep Chaurasia: this state use to manage loader switcing sandbox to live
export const SwitchToLiveFromSandboxState = atom<boolean>({
	key: 'switch-to-live-from-sandbox-state-key',
	default: false,
});

// Gaurav Chaurasia: this state use to manage proceed for subscription
export const ProceedBillingSubscriptionState = atom<boolean>({
	key: 'proceed-billing-subscription-state-key',
	default: false,
});

export const ShowBussinessDetailsNaviagtion = atom({
	key: 'show-bussiness-details-naviagtion',
	default: '',
});

export const billingDetailState = atom<ISummaryBilling[]>({
	key: 'billing-detail-state-key',
	default: [],
});

export const CostBreakdownDetailState = atom<ICostBreakdownDetails>({
	key: 'cost-breakdown-detail-state-key',
	default: {
		total: 0, 
		breakdown: [],
		endDate: null,
		startDate: null
	},
});
