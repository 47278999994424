import { atom } from 'recoil';


export const ShowWarningPopup = atom({
	key: 'show-session-out-popup-state-key',
	default: false,
});

export const SessionOutTimer = atom({
    key: "session-out-timer-state-key",
    default: 0
})
