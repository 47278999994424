import { Loader, MobileUI } from '@storybook';
import { FC, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { IAction, PipelineSettingsFormState } from 'global-stores';
import { MobileUIScreen } from 'views/mobile-ui-screen';
import { CurrentStepState } from 'views/onboarding-flow';
import { OnboardingType } from 'views/pipelines';
import { ComplexSettingFormState } from '../store';
import { mobileUINotRenderObject } from './constants';
import { PipelineCustomUpload } from './pipeline-custom-upload';
import { PipelineSettings as PipelineSettingsPage } from './pipeline-settings-page';

interface IPipelineBody {
	isTitleVisible?: boolean;
	renderStepper?: any;
	header?: any;
	mobileUiRef?: any;
}

export const PipelineBody: FC<IPipelineBody> = ({
	isTitleVisible,
	renderStepper,
	header,
	mobileUiRef,
}) => {
	const renderedObj = useRecoilValue<IAction>(CurrentStepState);
	const settingsForm = useRecoilValue(PipelineSettingsFormState);
	const [isLoaderIframe, setIsLoaderIframe] = useState(true);
	const complexSettingForm: any = useRecoilValue(ComplexSettingFormState);
	const { label, description, metadata = [], key, id } = renderedObj ?? {};

	const inActive = useMemo(
		() =>
			(mobileUINotRenderObject.includes(key) ||
				(key === 'accreditationVerify' &&
					complexSettingForm?.[id ?? '']?.accreditationType?.value ===
						'506b')) ??
			false,
		[complexSettingForm, id, key]
	);

	const isHideMobile: boolean = useMemo(
		() => key === OnboardingType.ReminderSetting,
		[key]
	);

	const renderRightSection = useMemo(() => {
		return (
			<>
				{key === 'accreditationVerify' &&
					complexSettingForm[id as any]?.accreditationType?.value === '506b' &&
					settingsForm?.accreditationType?.value === '506b' && (
						<div className="PipelineBody--right vh-center" ref={mobileUiRef}>
							{settingsForm?.accreditationType?.value === '506b' && (
								<>
									{complexSettingForm[id as any]?.accreditationDocument
										?.value === 'generic' ? (
										<div className="accreditation-generic">
											{isLoaderIframe && (
												<div className="PipelineBody--iframe-loader">
													<Loader className="loader-blue" dimension={60} />
												</div>
											)}
											<iframe
												src={`${metadata[1]?.url}#toolbar=0`}
												title="pdf"
												width="100%"
												height="385"
												allowFullScreen={true}
												style={{ backgroundColor: '#ffffff' }}
												onLoad={() => setIsLoaderIframe(false)}
											/>
										</div>
									) : (
										<div className="PipelineBody--upload-custom">
											<PipelineCustomUpload
												type={settingsForm?.accreditationType?.value}
												complexFlowId={id}
											/>
										</div>
									)}
								</>
							)}
						</div>
					)}
			</>
		);
	}, [
		complexSettingForm,
		id,
		isLoaderIframe,
		key,
		metadata,
		settingsForm,
		mobileUiRef,
	]);

	return (
		<div className="pipeline-modal">
			<div className="pipeline-details-page">
				<div
					className={`${
						isTitleVisible === false
							? 'pipeline-details-set-modal'
							: 'pipeline-details'
					}`}
				>
					{renderStepper}
					{header}
					<PipelineSettingsPage
						actionKey={key}
						description={description}
						metadata={metadata}
						title={label}
						isTitleVisible={isTitleVisible}
						id={id}
					/>
				</div>
				{renderRightSection}

				{!inActive && !isHideMobile && (
					<div
						className={`${
							isTitleVisible === false
								? 'pipeline-modile-ui-set-modal'
								: 'pipeline-modile-ui'
						}`}
						ref={mobileUiRef}
					>
						<MobileUI>
							<MobileUIScreen componentName={key} id={id} />
						</MobileUI>
					</div>
				)}
			</div>
		</div>
	);
};
