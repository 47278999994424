import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Button, Input, Loader, ReactDropdown } from '@storybook';

import { CHECK_DETAILS_FORM } from 'views/cheque-fraud/constants';
import {
	CheckDetailsFormState,
	CheckFraudDetailsState,
	ChequeFraudAllBranchState,
	useCheckDefence,
} from 'views/cheque-fraud/stores';

import './check-details-form.scss';

export const CheckDetailsForm = ({ handleBack }: any) => {
	const [checkDetailsForm, setCheckDetailsForm] = useRecoilState(
		CheckDetailsFormState
	);

	const allBranches = useRecoilValue(ChequeFraudAllBranchState);
	const checkFraudDetails = useRecoilValue(CheckFraudDetailsState);
	const [isShowOnlyBankAdd, setIsShowOnlyBankAdd] = useState(false);

	const [isAddBank, setIsAddBank] = useState(false);

	const { submitCheckDetails, loading, addNewBank } = useCheckDefence();
	const handleClickSetting = (name: string) => {
		if (name === 'addbank') {
			setIsAddBank(true);
		}
	};

	const handleDeviderSublabelClick = (name: string) => {
		if (name === 'addNewBank') {
			setIsAddBank(false);
			setIsShowOnlyBankAdd(false);
		}
	};

	const handleOnChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>, regex?: RegExp) => {
			const { name, value } = event.target ?? {};
			if (regex) {
				if (!regex.test(value) && value !== '') {
					return;
				}
			}

			if (name === 'zipCode' && value.length > 6) {
				return;
			}
			if (/routingNumber|accountNumber/.test(name) && value.length > 16) {
				return;
			}
			setCheckDetailsForm({ ...checkDetailsForm, [name]: value });
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[checkDetailsForm, setCheckDetailsForm]
	);

	useEffect(() => {
		if (!allBranches.length) {
			setIsAddBank(true);
			setIsShowOnlyBankAdd(true);
		}
	}, [allBranches]);

	useEffect(() => {
		if (Object.keys(checkFraudDetails.checkDetails ?? {}).length > 0) {
			setCheckDetailsForm({
				...checkDetailsForm,
				...checkFraudDetails.checkDetails,
			});
		}

		return () => {
			setCheckDetailsForm({
				session: checkDetailsForm.session,
			});
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getOptions = useCallback(
		(name: string) => {
			if (name === 'bankBranch') {
				return allBranches?.map((el: any) => ({
					label: `${el.name}(${el.branchNumber ?? 'N/A'}), ${
						el.city ?? 'N/A'
					}, ${el.state ?? 'N/A'} `,
					value: el._id,
				}));
			}
			return [];
		},
		[allBranches]
	);

	const handleSelect = useCallback(
		(event: any, name: string) => {
			setCheckDetailsForm({ ...checkDetailsForm, [name]: event.value });
		},
		[setCheckDetailsForm, checkDetailsForm]
	);

	const getSettingForm = (el: any) => {
		const {
			type,
			name,
			placeholder,
			label,
			className,
			subLabel,
			regex,
			prefixIcon,
			disabled
		} = el ?? {};

		const props = {
			...(type === 'number' && {
				onKeyDown: (evt: any) =>
					['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault(),
			}),
		};
		switch (type) {
			case 'text':
			case 'number':
				return (
					<Input
						label={label}
						inputType={type}
						inputName={name}
						placeholder={placeholder}
						value={checkDetailsForm[name] ? checkDetailsForm[name] : ''}
						handleChange={event => handleOnChange(event, regex)}
						prefixIcon={prefixIcon}
						disabled={disabled}
						{...props}
					/>
				);
			case 'or':
				return (
					<div className="center-option-wrapper">
						<div className="center-option-wrapper__inner">{label}</div>
					</div>
				);
			case 'select':
				return (
					<ReactDropdown
						options={getOptions(name)}
						handleChangeSelect={event => handleSelect(event, name)}
						optionsDropHeight={130}
						maxMenuHeight={130}
						isSearchable
						label={label}
					/>
				);
			case 'devider':
				return (
					<div className="label-divider-wrapper">
						<div className="label-divider">{label}</div>
						{subLabel && (
							<div
								className="divider_cancel"
								onClick={() => handleDeviderSublabelClick(name)}
							>
								{subLabel}
							</div>
						)}
					</div>
				);
			case 'button':
				return (
					<Button
						label={label}
						type={`button button__filled ${className} `}
						handleClick={() => handleClickSetting(name)}
					/>
				);
			default:
				return <></>;
		}
	};

	const isDisabled = useMemo(() => {
		const {
			session,
			bankBranch,
			accountNumber,
			amount,
			checkNumber,
			routingNumber,
			name,
			streetAddress,
			branchNumber,
			country,
			city,
			state,
			zipCode,
		} = checkDetailsForm ?? {};
		if (!isAddBank) {
			if (
				session &&
				accountNumber &&
				bankBranch &&
				amount &&
				routingNumber &&
				checkNumber
			) {
				return false;
			}
			return true;
		} else {
			if (
				name &&
				streetAddress &&
				branchNumber &&
				country &&
				city &&
				state &&
				zipCode
			) {
				return false;
			} else {
				return true;
			}
		}
	}, [checkDetailsForm, isAddBank]);

	const handleSubmit = useCallback(() => {
		if (isAddBank) {
			addNewBank(() => {
				setIsAddBank(false);
				setIsShowOnlyBankAdd(false);
			});
		} else {
			submitCheckDetails(handleBack);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAddBank, addNewBank, submitCheckDetails]);

	return (
		<div className="report-modal__content">
			<div className="report-modal__content__form-body">
				{CHECK_DETAILS_FORM.map((el, i: number) => {
					if (!el) {
						return <></>;
					}
					if (
						isShowOnlyBankAdd &&
						(el.refrer !== 'bank' || el.name === 'addNewBank')
					) {
						return <></>;
					}
					if (!isAddBank && el.refrer === 'bank') {
						return;
					}
					if (isAddBank && el.name === 'addbank') {
						return;
					}
					return (
						<div key={`${el.name}_${i.toString()}`} style={{ width: el.width }}>
							{getSettingForm(el)}
						</div>
					);
				})}
			</div>

			<div className="report-modal__content__btn-wrapper">
				<Button
					label={'Back'}
					type={'button button__filled--secondary '}
					handleClick={() => handleBack('viewDetails')}
				/>
				<Button
					label={
						loading ? (
							<Loader type="loader" className="loader-white" dimension={20} />
						) : checkDetailsForm.status === 'proceed' ? (
							isAddBank ? (
								'Submit'
							) : (
								'Add transaction'
							)
						) : (
							'Deny'
						)
					}
					type={`button button__filled button__filled--${
						checkDetailsForm.status === 'proceed'
							? 'primary'
							: 'danger deny-button'
					} `}
					disabled={isDisabled}
					handleClick={handleSubmit}
				/>
			</div>
		</div>
	);
};
