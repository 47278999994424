import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';

import { API_URL, TOAST_MESSAGES } from 'constant';
import {
	BusinessPaymentCardState,
	BusinessRegistrationState,
	loginState,
} from 'global-stores';
import { useFreePlan, useNetwork, useNotification } from 'hooks';
import {
	ActivePaymentType,
	DefaultCardType,
	PaymentNavigation,
	PrimaryAccountChecked,
	PrimaryAchAccountChecked,
	usePlaid,
} from 'views/settings';
import {
	ApprovalStatus,
	IdentityType,
	IndetityFlowNavigate,
	RequestType,
} from '../constants';
import {
	BillingPaymentAddress,
	ChooseUserIdentity,
	SubscriptionIdentityNaviagtion,
} from '../store';
import { useDefaultCard } from './use-default-card';
import {
	IsSubscriptionModal,
	WelcomeNewUserModalState,
} from 'views/new-user-popup';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes';
import { useCompliance } from 'routes/hooks';

type UseUserIdentity = {
	handleCloseModal: () => void;
};

const { BUSINESSES } = API_URL;

export const useUserIdentity = ({ handleCloseModal }: UseUserIdentity) => {
	// Global
	const [activeIdx, setActiveIdx] = useRecoilState(ChooseUserIdentity);
	const user = useRecoilValue(loginState);
	const [businessRegistrationState, setBusinessRegistration] = useRecoilState(
		BusinessRegistrationState
	);
	const chooseMethod = useRecoilValue(ActivePaymentType);
	const defaultCheck = useRecoilValue(PrimaryAccountChecked);
	const defaultAchCheck = useRecoilValue(PrimaryAchAccountChecked);
	const [navigate, setNavigate] = useRecoilState(
		SubscriptionIdentityNaviagtion
	);
	const resetActiveIdx = useResetRecoilState(ChooseUserIdentity);
	const billingAddress = useRecoilValue(BillingPaymentAddress);
	const setBusinessPaymentCardState = useSetRecoilState(
		BusinessPaymentCardState
	);
	const isOpenModal = useRecoilValue(IsSubscriptionModal);
	const setWelcomeNewUser = useSetRecoilState(WelcomeNewUserModalState);
	const setLogin = useSetRecoilState(loginState);
	const resetIsSubscriptionModal = useResetRecoilState(IsSubscriptionModal);
	const naviagtion = useNavigate();

	// Local
	const [isLoading, setIsLoading] = useState(false);

	// Hooks
	const { post, patch } = useNetwork({
		updateState: false,
		returnResponse: true,
	});
	const { errorNotification } = useNotification();
	const { initPlaid, isLoadingPlaid } = usePlaid({
		isSignup: true,
	});
	const { isDefaultExist } = useDefaultCard();
	const {
		ownBilling = false,
		differentCompany = false,
		business = '',
		parentBusinessId = '',
	} = useMemo(() => user ?? {}, [user]);

	const { getComplianceSessionStatus } = useCompliance();

	const { hasSubscribedPerson } = useFreePlan();

	const { accountType, kyb, kyc } = useMemo(
		() => businessRegistrationState ?? {},
		[businessRegistrationState]
	);

	const handleChooseNavigate = (val: string) => () => {
		if (val === 'WireView') return;
		setNavigate(val);
	};

	const navigateToWaiting = async () => {
		const resp = await getComplianceSessionStatus(activeIdx);
		const { sessionDetails = [], approveStatus, accountType } = resp ?? {};
		const sessionResult = sessionDetails.find(
			(el: { type: string }) => el.type === accountType
		);

		const { sessionData, physicalVerificationStatus } = sessionResult ?? {};
		const { kyb = [], kyc = [], kybForm = [], aml = [] } = sessionData ?? {};
		const kybData = structuredClone(kyb[0]?.data?.[0]);
		const kycData = structuredClone(kyc[0]?.data);
		const kybFormData = structuredClone(kybForm?.[0]);
		const amlData = structuredClone(aml?.[0]?.data);
		if (kycData) {
			kycData['success'] = true;
		}
		if (kybData) {
			kybData['success'] = true;
		}

		switch (approveStatus) {
			case ApprovalStatus.Rejected:
				setLogin(prev => ({
					...prev,
					approveStatus: ApprovalStatus.Rejected,
				}));
				setBusinessRegistration({
					approveStatus,
					kyc: kycData,
					kyb: kybData,
					physicalVerificationStatus,
					aml: amlData,
					kybForm: kybFormData,
					accountType
				});
				naviagtion(ROUTES.BUSINESS_REJECTED);
				break;
			case ApprovalStatus.UnderReview:
				setBusinessRegistration({
					approveStatus,
					accountType,
					kyc: kycData,
					kyb: kybData,
					physicalVerificationStatus,
					aml: amlData,
					kybForm: kybFormData,
				});
				setLogin(prev => ({
					...prev,
					approveStatus: ApprovalStatus.UnderReview,
				}));
				naviagtion(ROUTES.BUSINESS_PROCESSING);
				break;
			default:
				setLogin(prev => ({
					...prev,
					approveStatus: ApprovalStatus.Pending,
				}));
				naviagtion(ROUTES.COMPLETED);
		}
		setIsLoading(false);
		handleCloseModal();
	};

	const handleChooseService = async () => {
		const resp = await post(API_URL.Business_Registration, {
			requestType: RequestType.IdentityRegistration,
			accountType: activeIdx,
		});
		if (resp?.errorCode) {
			errorNotification(resp?.errorData?.message || TOAST_MESSAGES.ERROR);
			return;
		}
		return resp;
	};

	const handleSumbitKycDetails = async () => {
		setIsLoading(true);

		if (parentBusinessId) {
			navigateToWaiting();
			return;
		}
		const resp = await handleChooseService();
		if (resp?.success) {
			navigateToWaiting();
		}
	};

	const onSubmitBusinessIdentity = async () => {
		setIsLoading(true);
		if (parentBusinessId) {
			navigateToWaiting();
			return;
		}
		if (hasSubscribedPerson) {
			const upgradeResp = await post(API_URL.Business_Registration, {
				requestType: RequestType.UpgradeAccount,
			});
			if (upgradeResp?.errorData) {
				errorNotification(
					upgradeResp?.errorData?.message || TOAST_MESSAGES.ERROR
				);
			}
			navigateToWaiting();
			return;
		}
		const resp = await handleChooseService();
		if (resp?.success) {
			navigateToWaiting();
		}
	};

	const chooseMethode = async () => {
		setIsLoading(true);
		if (chooseMethod === PaymentNavigation.AchView) {
			initPlaid();
		} else if (chooseMethod === PaymentNavigation.CardView) {
			setNavigate(IndetityFlowNavigate.PaymentCard);
		} else {
			setNavigate(IndetityFlowNavigate.Wire);
		}
		setIsLoading(false);
	};

	const handleDefaultWireAccount = async () => {
		setIsLoading(true);
		const payload = {
			method: DefaultCardType.Wire,
		};
		const defaultResp = await post(API_URL.Payment_Methods, payload);
		if (defaultResp.success) {
			setNavigate(IndetityFlowNavigate.SubscriptionReview);
		}
		setIsLoading(false);
	};

	const handleWireMethode = () => {
		if (defaultCheck) handleDefaultWireAccount();
		else setNavigate(IndetityFlowNavigate.SubscriptionReview);
	};

	const setDefaultAchAccount = async () => {
		if (Object.values(defaultAchCheck ?? {}).every(el => el)) {
			setIsLoading(true);
			const payload = {
				method: DefaultCardType.Ach,
				data: {
					...defaultAchCheck,
					accountType: defaultAchCheck.accountType.toUpperCase(),
				},
			};
			const defaultResp = await post(API_URL.Payment_Methods, payload);
			if (defaultResp.success) {
				setNavigate(IndetityFlowNavigate.SubscriptionReview);
			}
		} else {
			setNavigate(IndetityFlowNavigate.SubscriptionReview);
		}
		setIsLoading(false);
	};

	const handleBilling = async () => {
		setIsLoading(true);
		const resp = await patch(`${BUSINESSES}/${business ?? ''}`, {
			billingAddress,
		});
		if (resp?._id) {
			setNavigate(IndetityFlowNavigate.Success);
			setBusinessPaymentCardState((prev: any) => ({ ...prev, billingAddress }));
		} else {
			errorNotification(resp?.message);
		}
		setIsLoading(false);
	};

	const handleClose = () => {
		handleCloseModal();
		setTimeout(() => {
			resetActiveIdx();
			resetIsSubscriptionModal();
		}, 500);
	};

	const handleNavigateServices = () => {
		setNavigate(IndetityFlowNavigate.PersonalIdentity);
	};

	const handleNextUserIdentity = () => {
		switch (navigate) {
			case IndetityFlowNavigate.ChooseIdentity:
				handleNavigateServices();
				break;
			case IndetityFlowNavigate.ChooseMethod:
				chooseMethode();
				break;
			case IndetityFlowNavigate.Wire:
				handleWireMethode();
				break;
			case IndetityFlowNavigate.DefaultAchPage:
				setDefaultAchAccount();
				break;
			case IndetityFlowNavigate.Payment:
				setNavigate(IndetityFlowNavigate.Success);
				break;
			case IndetityFlowNavigate.BillingForm:
				handleBilling();
				break;
			case IndetityFlowNavigate.WirePage:
				setNavigate(IndetityFlowNavigate.SubscriptionReview);
				break;
			case IndetityFlowNavigate.AchView:
				setNavigate(IndetityFlowNavigate.SubscriptionReview);
				break;
			case IndetityFlowNavigate.CardView:
				setNavigate(IndetityFlowNavigate.SubscriptionReview);
				break;
			case IndetityFlowNavigate.SubscriptionReview:
				setNavigate(IndetityFlowNavigate.Processing);
				break;
			default:
				break;
		}
	};

	const handleBackUserIdentity = useCallback(() => {
		switch (navigate) {
			case IndetityFlowNavigate.Wire:
				setNavigate(IndetityFlowNavigate.ChooseMethod);
				break;
			case IndetityFlowNavigate.PaymentCard:
				setNavigate(IndetityFlowNavigate.CardView);
				break;
			case IndetityFlowNavigate.BillingForm:
				setNavigate(IndetityFlowNavigate.Success);
				break;
			case IndetityFlowNavigate.BusinessIdentity:
				setNavigate(
					hasSubscribedPerson
						? IndetityFlowNavigate.ChooseIdentity
						: IndetityFlowNavigate.PersonalIdentity
				);
				break;
			case IndetityFlowNavigate.ChooseIdentity:
				handleClose();
				break;
			case IndetityFlowNavigate.ChooseMethod:
				if (isOpenModal) {
					handleCloseModal();
					setWelcomeNewUser({ open: true, type: 'Welcome_User' });
				} else {
					handleCloseModal();
				}
				break;
			case IndetityFlowNavigate.SubscriptionReview:
				setNavigate(IndetityFlowNavigate.CardView);
				break;
			case IndetityFlowNavigate.PersonalIdentity:
				if (isOpenModal) {
					handleCloseModal();
					setWelcomeNewUser({ open: true, type: 'Welcome_User' });
				} else {
					setNavigate(IndetityFlowNavigate.ChooseIdentity);
				}
				break;
			default:
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigate, setNavigate, isOpenModal]);

	const mapToNavigationNextLabel = useMemo(() => {
		switch (navigate) {
			case IndetityFlowNavigate.Wire:
				return 'Save';
			case IndetityFlowNavigate.SubscriptionReview:
				return 'Pay';
			case IndetityFlowNavigate.DefaultAchPage:
				return 'Add Account';
			default:
				return 'Next';
		}
	}, [navigate]);

	// After component render this will check accountType, Kyb, kyc and payment and navigate accordingly
	useEffect(() => {
		// This file contains the logic for navigating through the identity flow in a React component
		if (!accountType) {
			// If the account type is not set, navigate to the identity selection step
			return setNavigate(IndetityFlowNavigate.ChooseIdentity);
		}

		if (ownBilling) {
			// If the user has their own billing
			if (differentCompany) {
				// If the user is from a different company, navigate to personal identity (KYC)
				return setNavigate(IndetityFlowNavigate.PersonalIdentity);
			}
			// Otherwise, navigate to the payment method selection step
			return setNavigate(IndetityFlowNavigate.ChooseMethod);
		}

		// If the user has diffrent company
		if (differentCompany) {
			// navigate to personal identity (KYC)
			return setNavigate(IndetityFlowNavigate.PersonalIdentity);
		}

		if (accountType === IdentityType.Company) {
			// If the account type is a company
			if (kyc?.success && kyb?.success) {
				// If KYC and KYB checks are successful,
				// navigate to the card view if a default identity exists, else navigate to method selection
				return setNavigate(
					isDefaultExist
						? IndetityFlowNavigate.CardView
						: IndetityFlowNavigate.ChooseMethod
				);
			} else {
				return setNavigate(IndetityFlowNavigate.PersonalIdentity);
			}
		}

		if (accountType === IdentityType.Individual) {
			if (!hasSubscribedPerson) {
				setActiveIdx(IdentityType.Individual);
			}

			if (hasSubscribedPerson) {
				// If a default identity exists and the user has subscribed as a person, navigate to identity selection
				return setNavigate(IndetityFlowNavigate.ChooseIdentity);
			}

			// If the account type is an individual
			if (!isDefaultExist) {
				if (!kyc?.success) {
					return setNavigate(IndetityFlowNavigate.PersonalIdentity);
				} else {
					return setNavigate(IndetityFlowNavigate.ChooseMethod);
				}
			} else {
				return setNavigate(IndetityFlowNavigate.CardView);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		handleNextUserIdentity,
		handleBackUserIdentity,
		navigate,
		setNavigate,
		mapToNavigationNextLabel,
		isLoading,
		accountType,
		isLoadingPlaid,
		handleChooseNavigate,
		handleSumbitKycDetails,
		onSubmitBusinessIdentity,
		activeIdx,
	};
};
