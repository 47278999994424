import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { PipelineMasterSteps, SelectedOnboardingAction } from 'global-stores';
import { ActionList } from 'views/complex-onboarding-flow/constant';
import { NodesState } from 'views/complex-onboarding-flow/stores/states';
import { AddBlockButton } from '../add-button';
import { Label } from '../condition-label';
import { RenderQuestion } from './render-questions';

export interface IRenderBlock {
	label?: string | undefined;
	labelId: string;
	stepId: string;
	nodeId: string;
	actionId: string;
}

export const RenderBlock: FC<IRenderBlock> = ({
	label,
	labelId,
	stepId,
	nodeId,
	actionId,
}) => {
	const [nodes, setNodes] = useRecoilState(NodesState);
	const pipelinesSteps = useRecoilValue(PipelineMasterSteps);
	const setSelectedOnboarding = useRecoilValue(SelectedOnboardingAction);

	// Local variables
	const [blockCount, setBlockCount] = useState(0);

	const { conditions: kycActionNodes = [] } = useMemo(
		() => nodes?.find((el: any) => el.id === nodeId) ?? {},
		[nodeId, nodes]
	);

	/*
	@avinashSatschel
    # Set the block count value for "modify" and "clone" operations
    # Since the node already has a block 0 and other blocks, we need to find the
    # maximum block number currently in use and set it as the block count to avoid
    # block number conflicts when modifying or cloning the node.
    # This ensures that the new node has a unique block number.
    */
	useEffect(() => {
		if (/modify|clone/.test(setSelectedOnboarding.type)) {
			const currentNodesConditions = nodes.find(
				(el: any) => el.id === nodeId
			)?.conditions;

			if (currentNodesConditions?.length > 0) {
				const allNumber = [];

				for (let i = 0; i < currentNodesConditions.length; i++) {
					allNumber.push(
						currentNodesConditions[i]?.blockId?.split('_')?.[1] ?? 0
					);
				}

				setBlockCount(Math.max(...allNumber) + 1);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const addNewBlock = useCallback(() => {
		setBlockCount(prev => prev + 1);
		const payload = {
			blockId: `block_${blockCount + 1}`,
			currentId: nodeId,
			and: [],
			labelId,
		};

		setNodes((prev: any) => {
			const prevObj = JSON.parse(JSON.stringify(prev));
			const findIndex = prevObj.findIndex((el: any) => el.id === nodeId);

			if (findIndex !== -1) {
				prevObj[findIndex].conditions.push(payload);
			}
			return prevObj;
		});
	}, [blockCount, nodeId, labelId, setNodes]);

	const allList = useMemo(
		() =>
			kycActionNodes.filter(
				(el: any) => el.currentId === nodeId && el.labelId === labelId
			),
		[kycActionNodes, labelId, nodeId]
	);

	const metaData = useMemo(
		() =>
			pipelinesSteps.find(
				pipelineStep => pipelineStep.key === ActionList[actionId]
			)?.actions[0]?.metadata ?? [],
		[actionId, pipelinesSteps]
	);

	const price = useMemo(
		() => metaData?.find(el => el.key === ActionList[labelId])?.price ?? 0,
		[labelId, metaData]
	);

	return (
		<>
			<Label label={label} price={price} priceLabel="Verification" />
			<div
				className="kyc-action__questions-wrapper"
				style={{ position: 'relative' }}
			>
				{allList?.map((item: any) => (
					<RenderQuestion
						labelId={labelId}
						stepId={stepId}
						nodeId={nodeId}
						key={`${item.blockId}_${item.labelId}`}
						blockId={item.blockId}
					/>
				))}

				<AddBlockButton onClick={addNewBlock} />
			</div>
		</>
	);
};
