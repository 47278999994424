import { FC, Fragment, ReactElement, useMemo } from 'react';

import { Button } from '@storybook';
import { ROUTES } from 'routes';
import { useUserRoles } from 'views/routes-children';
import './header.scss';

interface IElements {
	label: string;
	onClick: VoidFunction;
}

interface IHeader {
	title: string;
	showBtn?: boolean;
	btnLabel?: string | JSX.Element;
	btnClass?: string;
	icon?: string;
	handleClickBtn?: () => void;
	disabled?: boolean;
	elements?: IElements[];
	children?: ReactElement;
}

export const Header: FC<IHeader> = ({
	title,
	showBtn,
	btnLabel,
	icon,
	btnClass = '',
	handleClickBtn = () => ({}),
	disabled,
	elements,
	children,
}) => {
	const iframe = window.location.pathname === ROUTES.API;

	const { checkUserWritePermission } = useUserRoles();

	const renderButtons = useMemo(() => {
		if (elements) {
			return elements.map((el: IElements) => {
				return (
					<Fragment key={el.label}>
						<Button
							type="button__filled--primary"
							label={el.label ?? ''}
							handleClick={el.onClick}
							prefixIcon={icon}
							disabled={disabled}
						/>
					</Fragment>
				);
			});
		}
		return (
			<Button
				type="button__filled--primary"
				label={btnLabel ?? ''}
				handleClick={handleClickBtn}
				prefixIcon={icon}
				disabled={disabled}
			/>
		);
	}, [btnLabel, disabled, elements, handleClickBtn, icon]);

	return (
		<div className={`header header${iframe ? '--isActive' : ''}`}>
			<div className="header__name">{title}</div>
			<div className={`header__right ${btnClass}`}>
				{showBtn && checkUserWritePermission(title) && renderButtons}
			</div>
			{children ? children : null}
		</div>
	);
};
