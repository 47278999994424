import { ReactNode, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Button, Loader } from '@storybook';
import {
	AddNewRoleFormState,
	IPermissionType,
	IReadWrite,
	PermissionNavigation,
} from 'views/user-roles/store';
import { AddRoleModalHeader } from '../add-role-modal-header';
import { AllowPermission } from '../allow-permission';
import { SelectServices } from '../choose-services';
import { CreateNewRole } from '../create-new-role';
import { UserInputRole } from '../user-input-role';
import Modal from '@storybook/new-modal/modal';
import { useNewRoles } from 'views/user-roles/hooks';
import {
	RoleAndPermissionNavigation,
	UserRoleIds,
} from 'views/user-roles/constant';
import { useNotification } from 'hooks';

import { NoRoleAvailable } from '../no-role-available';

import { UserRolesState } from 'global-stores';
import { nameRegex } from 'views/user-roles/user-roles';
import { scrollToIndex } from 'utils/scroll';

import './add-new-role-modal.scss';

type IAddNewRoleModal = {
	handleCloseModel: () => void;
	isAddRoleModalOpen: boolean;
	children?: ReactNode;
};
export const AddNewRoleModal = ({
	handleCloseModel,
	isAddRoleModalOpen,
	children,
}: IAddNewRoleModal) => {
	//global state
	const navigate = useRecoilValue(PermissionNavigation);
	const addRoleForm = useRecoilValue(AddNewRoleFormState);
	const userRoles = useRecoilValue(UserRolesState);

	const { errorNotification } = useNotification();

	// hooks
	const {
		mapToNavigationPreviousLabel,
		mapToNavigationNextLabel,
		handleBack,
		handleNext,
		addNewRoleLoader,
	} = useNewRoles({ handleCloseModel });

	// render modal body
	const mapToNavigationBody: Record<string, ReactNode> = useMemo(
		() => ({
			[RoleAndPermissionNavigation.AddNewRole]: (
				<>
					<UserInputRole />
					<SelectServices />
					<AllowPermission />
				</>
			),
			[RoleAndPermissionNavigation.CreateNewRole]: <CreateNewRole />,
			[RoleAndPermissionNavigation.NoRoleAvailable]: <NoRoleAvailable />,
			[RoleAndPermissionNavigation.EditRole]: (
				<>
					<UserInputRole />
					<SelectServices />
					<AllowPermission />
				</>
			),
		}),
		[]
	);

	// render modal header
	const mapToNavigationHeader: Record<string, JSX.Element> = useMemo(
		() => ({
			[RoleAndPermissionNavigation.AddNewRole]: (
				<AddRoleModalHeader navigate={navigate} />
			),
			[RoleAndPermissionNavigation.CreateNewRole]: (
				<AddRoleModalHeader navigate={navigate} />
			),
			[RoleAndPermissionNavigation.NoRoleAvailable]: (
				<AddRoleModalHeader navigate={navigate} />
			),
			[RoleAndPermissionNavigation.EditRole]: (
				<AddRoleModalHeader navigate={navigate} />
			),
		}),
		[navigate]
	);

	const renderBody = useMemo(
		() => mapToNavigationBody[navigate],
		[mapToNavigationBody, navigate]
	);

	const renderTitle = useMemo(
		() => mapToNavigationHeader[navigate],
		[mapToNavigationHeader, navigate]
	);

	const existingUserRoles = useMemo(
		() => userRoles?.map(role => role.name.toUpperCase()),
		[userRoles]
	);

	const handleOnSubmit = () => {
		if (navigate === RoleAndPermissionNavigation.AddNewRole) {
			const { name, permissions } = addRoleForm ?? {};
			const isValidName =
				!name.trim() ||
				existingUserRoles?.includes(name.toUpperCase()) ||
				name.length < 3 ||
				!nameRegex.test(name);
			const hasAnyReadOrWritePermission = Object.keys(permissions).some(
				category => {
					return ['read', 'write'].some(
						permission =>
							permissions[category as IPermissionType][permission as IReadWrite]
					);
				}
			);

			if (isValidName) {
				return scrollToIndex(UserRoleIds.name);
			} else if (!hasAnyReadOrWritePermission) {
				errorNotification('Minimum one permission is required');
				scrollToIndex(UserRoleIds.permission);
			} else {
				handleNext();
			}
		} else {
			handleNext();
		}
	};

	return (
		<div className="Add-role-modal--container">
			<Modal
				isOpen={isAddRoleModalOpen}
				closeModal={handleCloseModel}
				modalName="Add user role"
				className="AddNewRoleModal"
				title={renderTitle}
			>
				<div className="AddNewRoleModal--container">{renderBody}</div>
				{children ? (
					children
				) : (
					<div className="AddNewRoleModal--container__footer">
						<Button
							label={mapToNavigationPreviousLabel}
							type="button__filled--secondary"
							handleClick={handleBack}
						/>
						<Button
							label={
								addNewRoleLoader ? (
									<Loader
										type="loader"
										dimension={22}
										className="loader-white"
									/>
								) : (
									mapToNavigationNextLabel
								)
							}
							type="button__filled--primary"
							handleClick={handleOnSubmit}
							disabled={addNewRoleLoader}
						/>
					</div>
				)}
			</Modal>
		</div>
	);
};
