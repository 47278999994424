type Event = 'new_user' | 'subscribed';

export const useFullStoryTrackEvent = () => {
	const trackEvent = (
		eventName: Event,
		properties: Record<string, any>
	): void => {
		if ((window as any)?.FS)
			(window as any).FS('trackEvent', {
				name: eventName,
				properties,
			});
	};

	return {
		trackEvent,
	};
};
