import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { loginState } from 'global-stores';
import { Header } from 'views/header';
import { DashboardBody } from './components';
import { useDashboards } from './stores';
import { NewUserPopup } from 'views/new-user-popup';

const DashboardPage = () => {
	// global stage
	const userLogin = useRecoilValue(loginState);
	const { getDashboards, getAnalyticsData, getOnboardingFlowAnalyticsData } =
		useDashboards();

	useEffect(() => {
		getDashboards();
		getAnalyticsData();
		getOnboardingFlowAnalyticsData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userLogin?.business]);

	return (
		<div className="dashboard-main-body">
			<Header title="Dashboard" showBtn={false} />
			<DashboardBody />
			<NewUserPopup />
		</div>
	);
};

export default DashboardPage;
