export const SessionTypesOptions = [
	{
		label: 'Active',
		value: 'active_session',
	},
	{
		label: 'Archived',
		value: 'archived_session',
	},
];

export const filterList: { [key: string]: string } = {
	kyb: 'kybVerification',
	kyc: 'allId',
	signAgreement: 'signAgreementVerification',
	aml: 'amlVerification',
	payIn: 'payIn',
	payOut: 'payOut',
	'506b': '506b',
	'506c': '506c',
	form:'form',
	dynamicForm:'dynamicForm',
	kybForm: 'kybForm'
};

export const filterListWithType: { [key: string]: boolean } = {
	payIn: true,
	payOut: true,
	'506b': true,
	'506c': true,
};
