import { ChangeEvent, KeyboardEvent, useCallback } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { useNetwork, useNotification } from 'hooks';
import {
	CancelSubscriptionActiveStepState,
	CancelSubscriptionDueAmountState,
	CancelSubscriptionResponseState,
	CancelSubscriptionTextState,
} from '../states';
import { CANCEL_SUBSCRIPTION_STEPS } from '../../constants';
import { subscriptionServiceState } from 'global-stores';
import { API_URL } from 'constant';

export const useCancelSubscriptionConfirmation = () => {
	// Recoil state management for confirmation text, steps, dues, and response
	const subscription = useRecoilValue(subscriptionServiceState);
	const [confirmationTxt, setConfirmationTxt] = useRecoilState(CancelSubscriptionTextState);
	const setActiveStep = useSetRecoilState(CancelSubscriptionActiveStepState);
	const setDueAmount = useSetRecoilState(CancelSubscriptionDueAmountState);
	const setCancelResponse = useSetRecoilState(CancelSubscriptionResponseState);

	// Network requests and notification handling
	const { remove } = useNetwork({ returnResponse: true });
	const { get, loading: fetchingDues } = useNetwork({ returnResponse: true });
	const { errorNotification } = useNotification();

	// Handle changes in the confirmation input field (only allow letters up to 50 characters)
	const handleChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const { value } = e.target ?? {};
			if (!value || /^[a-zA-Z]{1,50}$/.test(value)) {
				setConfirmationTxt(value?.trim());
			}
		},
		[setConfirmationTxt]
	);

	// Process the cancellation of the subscription
	const processedCancelSubscription = useCallback(
		async (back?: CANCEL_SUBSCRIPTION_STEPS) => {
			if (!subscription?.id) return errorNotification('Subscription Not Active');
			const resp = await remove(`${API_URL.SUBSCRIPTIONS}/${subscription?.id}`);

			const { cancelledAt } = resp?.apiPayload ?? {};
			setTimeout(() => {
				if (cancelledAt) {
					setCancelResponse(prev => ({ ...prev, cancelledAt }));
					setActiveStep(CANCEL_SUBSCRIPTION_STEPS.COMPLETED);
				} else {
					errorNotification(
						resp?.response?.message ??
							resp?.errorData?.message ??
							'Something went wrong.'
					);
					setActiveStep(back ?? CANCEL_SUBSCRIPTION_STEPS.CONFIRMATION);
				}
			}, 3000);
		},
		[
			errorNotification,
			remove,
			setActiveStep,
			setCancelResponse,
			subscription?.id,
		]
	);

	// Check the due amount and handle subscription cancellation or dues display
	const handleCancelSubscription = useCallback(async () => {
		if (!subscription?.id) return errorNotification('Subscription Not Active');
		const resp = await get(`${API_URL.SUBSCRIPTIONS}/${subscription?.id}`);

		const { dueAmount } = resp?.apiPayload?.data ?? {};

		if (dueAmount > 0) {
			setDueAmount(dueAmount);
			setActiveStep(CANCEL_SUBSCRIPTION_STEPS.DUES);
		} else if (dueAmount <= 0) {
			setDueAmount(0);
			setActiveStep(CANCEL_SUBSCRIPTION_STEPS.PROCESSING);
			await processedCancelSubscription();
		} else {
			setDueAmount(0);
			errorNotification(resp?.errorData?.message ?? 'Something went wrong.');
		}
	}, [
		errorNotification,
		get,
		processedCancelSubscription,
		setActiveStep,
		setDueAmount,
		subscription?.id,
	]);

	// Handle key press event, trigger cancellation if "Enter" is pressed and conditions are met
	const handleKeyPress = useCallback(
		({ key }: KeyboardEvent<HTMLInputElement>) => {
			if (key !== 'Enter' || fetchingDues || confirmationTxt?.toLowerCase() !== 'cancel') return;
			handleCancelSubscription();
		},
		[confirmationTxt, fetchingDues, handleCancelSubscription]
	);

	// Return the necessary values and functions to be used in the component
	return {
		confirmationTxt,
		handleChange,
		handleCancelSubscription,
		fetchingDues,
		processedCancelSubscription,
		handleKeyPress,
	};
};
