import { ICaptableHeader } from "./uploaded-files";

export const uploadedFilesColumn = [
	{
		label: '',
		key: 'checkbox',
		format: 'jsx',
		width: '5%',
	},
	{
		label: 'Document',
		key: 'name',
		format: 'jsx',
		width: '20%',
	},
	{
		label: 'Template Name',
		key: 'templateName',
		format: 'string',
		width: '20%',
	},
	{
		label: 'Size',
		key: 'size',
		format: 'size',
	},
	{
		label: 'Date Uploaded',
		key: 'createdAt',
		format: 'date',
	},
	{
		label: 'Actions',
		key: 'actions',
		key2: 'actions2',
		format: 'jsx',
		className: 'upload-sign-action',
	},
];

export const incognitoMessage = {
	Title: 'Incognito/private mode detected !!',
	Description:
		'This feature is not allowed in Incognito/Private mode. switch to normal mode to use it',
};

/**
 * pre-decided captable headers for qr code flow by @Manish
 * */ 
export const qrHeaders: ICaptableHeader[] = [
	{
		label: 'First Name',
		name: 'First Name',
		type: 'text',
	},
	{
		label: 'Last Name',
		name: 'Last Name',
		type: 'text',
	},
	{
		label: 'Country Code',
		name: 'Country Code',
		type: 'text',
	},
	{
		label: 'Mobile',
		name: 'Mobile',
		type: 'text',
	},
	{
		label: 'Email',
		name: 'Email',
		type: 'text',
	},
];

export const docProvider ={
	esign:"esign",
	docusign:"docusign"
}

export const docProviderType ={
	basic:"basic",
	overlay:"overlay"
}
