export const CONFIGURE_SESSION_KEYS_VALUE = {
	fundInvestment: 'Fund Pay In',
	payOut: 'Fund Pay Out',
	payIn: 'Fund Pay In',
	kyc: 'KYC (Know Your Customer)',
	aml: 'AML (Anti Money Laundering)',
	signAgreement: 'Sign Agreement',
	authentication: 'Biometric Authentication',
	accreditation: 'Accreditation',
	'506c': 'Accreditation (506c)',
	'506b': 'Accreditation (506b)',
	form: 'Form',
	kyb: 'KYB',
	signAgreement_esign: 'Sign Agreement',
	'kyc(with liveness)': 'KYC (Know Your Customer)',
	docusignSignAgreement: 'Sign Agreement',
	simpliciSignAgreement: 'Sign Agreement',
};

export const SESSION_CONFIGURE_KEYS_VALUE = {
	authentication: 'Biometric',
};

export const PAYLOAD_CONFIGURE_SESSION_KEYS_VALUE = {
	KycWithLiveness: 'kyc(with liveness)',
};
