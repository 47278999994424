export class Memory {
	private static signedDocInbox: number;

	static setTimeStamp(timestamp: number) {
		Memory.signedDocInbox = timestamp;
		return Memory.signedDocInbox;
	}
	static getTimeStamp() {
		return Memory.signedDocInbox;
	}
}
