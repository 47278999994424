import './flow.scss';

export const FlowLine = () => {
	return (
		<div className="connector-container">
			<div className="session-pointer" />
			<div className="session-line" />
			<div className="session-pointer" />
		</div>
	);
};
