import { FC, ReactNode, useMemo } from 'react';

import Modal from '@storybook/new-modal/modal';
import './invite-modal.scss';
import { InviteUserFrom } from '../invite-user-form';
import { PreviewInfo } from '../preview-info';
import { useRecoilValue } from 'recoil';
import { InviteNavigationState } from 'views/user-roles/store';
import { InviteNavigation } from 'views/user-roles/constant';
import { BusinessRegistrationState } from 'global-stores';

interface IUserRole {
	edit?: boolean;
	isOpen: boolean;
	handleClose: () => void;
	children?: ReactNode;
	isDisableRole?: boolean;
	disableSubAccount?: boolean;
}

export const InviteModalPage: FC<IUserRole> = ({
	handleClose,
	isOpen,
	children = <></>,
	edit = false,
	isDisableRole = false,
	disableSubAccount = false,
}) => {
	const inviteNavigation = useRecoilValue(InviteNavigationState);
	const businessRegistrationState = useRecoilValue(
		BusinessRegistrationState
	);
	const { accountType } = businessRegistrationState ?? {};

	const renderInviteFormLayout = useMemo(() => {
		switch (inviteNavigation) {
			case InviteNavigation.InviteForm:
				return (
					<InviteUserFrom
						isDisableRole={isDisableRole}
						disableSubAccount={disableSubAccount}
					/>
				);
			case InviteNavigation.InvitePreview:
				return <PreviewInfo accountType={accountType}/>;

			default:
				return null;
		}
	}, [inviteNavigation, isDisableRole, disableSubAccount, accountType]);

	const renderLabel: { title: string; subTitle: string } = useMemo(() => {
		switch (inviteNavigation) {
			case InviteNavigation.InviteForm:
				return {
					title: edit ? 'Edit Invite User' : 'Invite User',
					subTitle: 'Assign roles to users for streamlined access',
				};
			case InviteNavigation.InvitePreview:
				return { title: 'Invitation preview', subTitle: '' };
			default:
				return { title: '', subTitle: '' };
		}
	}, [inviteNavigation, edit]);

	return (
		<Modal
			isOpen={isOpen}
			closeModal={handleClose}
			className="react-modal_userRole"
			modalName="Invite modal"
			showCloseBtn={false}
			isStopOutsideClick
		>
			<div className="Invite-header">
				<div className="user-roles--header">{renderLabel?.title ?? ''}</div>
				<p>{renderLabel?.subTitle ?? ''}</p>
			</div>
			{renderInviteFormLayout}
			{children}
		</Modal>
	);
};
