import { useMemo } from 'react';
import { Image, Skeleton } from '@storybook';

import { AddZeroInSingleNumbers } from 'utils';
import { cardImage } from '../../utils';

export const Card = ({ loading, result }: any) => {
	//Gaurav: To show the expire card.
	const current = new Date();
	const year = current.getFullYear();
	const month = current.getMonth();

	const isCardExpired = useMemo(() => {
		return (
			result?.exp_year < year ||
			result?.exp_month > 12 ||
			(result?.exp_year === year && result?.exp_month <= month)
		);
	}, [result?.exp_year, result?.exp_month, month, year]);

	return (
		<div className="payment-bussines-card-detail">
			<Image
				fileName={cardImage(result?.brand ?? '')}
				className="payment-bussines-card-detail__brand"
			/>
			<div className="payment-card-detail">
				<div className="payment-card-number">
					{loading ? (
						<Skeleton width={150} height={14} />
					) : (
						`••• •••• •••• ${result?.last4 ?? ''}`
					)}
				</div>
				<div className="payment-card-expire">
					{loading ? (
						<Skeleton width={150} height={14} />
					) : (
						`Expire: ${
							AddZeroInSingleNumbers(`${result?.exp_month}`)
								? AddZeroInSingleNumbers(`${result?.exp_month}`)
								: '--'
						} / ${result?.exp_year ? result?.exp_year : '--'}`
					)}
				</div>
			</div>
			{isCardExpired ? (
				<div className="payment-bussines-card-detail__expired">Expired</div>
			) : (
				<></>
			)}
		</div>
	);
};
