import { useMemo } from 'react';

import { SideDrawer } from '@storybook';
import { useRecoilState } from 'recoil';
import { SideDrawerState } from 'global-stores';

import './admin-skelton.scss';

interface Props {
	headerElement?: JSX.Element;
	rightElement: JSX.Element;
	bodyElement: JSX.Element;
	footerElement?: JSX.Element;
}
/**
 * AdminSkelton component
 *
 * @param {JSX.Element} Props.headerElement - JSX element for the header section
 * @param {JSX.Element} Props.rightElement - JSX element for the right section
 * @param {JSX.Element} Props.bodyElement - JSX element for the body section
 * @param {JSX.Element} Props.footerElement - JSX element for the footer section
 */

export const AdminSkelton = ({
	headerElement,
	rightElement,
	bodyElement,
	footerElement,
}: Props) => {
	const [isDrawerOpen, setIsDrawerOpen] = useRecoilState(SideDrawerState);

	const getHeaderElement = useMemo(
		() =>
			headerElement && (
				<div className="admin-skelton-wrapper__header">{headerElement}</div>
			),
		[headerElement]
	);

	const getBodyElement = useMemo(
		() =>
			bodyElement && (
				<div className="admin-skelton-wrapper__body__center">{bodyElement}</div>
			),
		[bodyElement]
	);
	const getRightElement = useMemo(
		() =>
			rightElement && (
				<div className="admin-skelton-wrapper__body__right">{rightElement}</div>
			),
		[rightElement]
	);

	const getFooterElement = useMemo(
		() =>
			footerElement && (
				<div className="admin-skelton-wrapper__footer" style={{ height: 72 }}>
					{footerElement}
				</div>
			),
		[footerElement]
	);

	return (
		<div className="admin-skelton-wrapper ">
			{getHeaderElement}

			<div className="admin-skelton-wrapper__body">
				<div style={{ flex: 1 }}>{getBodyElement}</div>
				{/* this will render in the big screen */}
				<div className="admin-skelton-wrapper__drawer">{getRightElement}</div>
				{/* this will render in the small screens */}
				<div className="admin-skelton-wrapper__drawer--tab">
					<SideDrawer
						body={getRightElement}
						setVisible={setIsDrawerOpen}
						visible={isDrawerOpen}
					/>
				</div>
			</div>
			{getFooterElement}
		</div>
	);
};
