import { useSetRecoilState } from 'recoil';

import { FundDetailState } from 'global-stores';
import { API_URL, MESSAGE } from 'constant';
import { useNetwork, useNotification } from 'hooks';
import { getLuckSheetData } from 'utils';

export const useEditCaptable = (
	investerIds: string[],
	fundId: string,
	closeAddInvestorModal: () => void
) => {
	// hooks
	const { get: getFundDetail } = useNetwork();
	const { patch: updateFunds, loading } = useNetwork();
	const { errorNotification, successNotification } = useNotification();

	//globle states
	const setFundDetailList = useSetRecoilState(FundDetailState);

	const fetchFundList = async () => {
		const getFund = await getFundDetail(`${API_URL.FUND_DETAIL}/${fundId}`);
		if (getFund?.data) {
			setFundDetailList(getFund?.data ?? []);
		}
	};

	function compareValueOfArrays(arr1: string[], arr2: any) {
		for (let i = 0; i < arr2.length; i++) {
			if (!arr1.includes(arr2[i])) {
				return false;
			}
		}

		return true;
	}

	const handleEditInvestor = async () => {
		const { rows = [], headers = [] } = getLuckSheetData();
		let isValidData = false;

		const requiredHeader = [
			'First Name',
			'Last Name',
			'Country Code',
			'Mobile',
			'Email',
		];

		if (!compareValueOfArrays(headers, requiredHeader)) {
			errorNotification(
				'You cannot edit header name of First Name, Last Name, Country Code, Mobile or Email'
			);
			return;
		}

		const hasFName = headers.indexOf('First Name');
		const hasLName = headers.indexOf('Last Name');
		const isCode = headers.indexOf('Country Code');
		const isContact = headers.indexOf('Mobile');
		const isEmail = headers?.indexOf('Email');

		const newInvestorsUpdate = rows.splice(investerIds.length);
		const newInvestors = newInvestorsUpdate?.map((items: any) => {
			isValidData = true;
			const isValidEmail: any = items[isEmail];
			if (!items[isEmail]) {
				errorNotification('Email is required');
			} else if (!items[isContact] || !items[isCode]) {
				errorNotification('Contact is required');
			} else if (!items[hasLName] || !items[hasFName]) {
				errorNotification('Full Name is required');
			} else if (!encodeURIComponent(isValidEmail)) {
				errorNotification('Please enter a valid email address');
			} else {
				isValidData = false;
			}
			const copyDataArray = [...items];
			return copyDataArray?.map((val: string) => val ?? '');
		});

		if (isValidData) return;

		const investors = investerIds.map((investorId, index) => {
			const values = headers?.map((item: string, idx: number) => ({
				label: item,
				value: Array.isArray(rows[index]) ? rows[index][idx] : '',
			}));

			return {
				investorId,
				values,
			};
		});

		investors?.forEach(item => {
			const { value = '' } = item.values.find(
				(val: { label: string }) => val.label === 'Email'
			);
			if (!encodeURIComponent(value)) {
				errorNotification('Please enter a valid email address');
				isValidData = true;
				return;
			}
		});

		if (isValidData) return;

		const payload = {
			headers,
			investors,
			newInvestors,
		};

		const resp = await updateFunds(`${API_URL.INVESTOR}/${fundId}`, payload);
		if (resp?.success) {
			closeAddInvestorModal();
			if (fetchFundList) fetchFundList();
			successNotification('Successfully Updated');
		} else errorNotification(resp?.message || MESSAGE.ERROR);
	};

	return {
		handleEditInvestor,
		loading,
	};
};
