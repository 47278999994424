import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { Button, Image, Input, Loader } from '@storybook';
import { useNotification } from 'hooks';
import { useFormatNumber } from 'utils';
import {
	AddBankAccountLoadingState,
	AmountToWithdrawFromEscrowState,
	EscrowDetailsAtom,
	EscrowModalBodyNavigatorState,
	SelectedBankSubItemState,
	SeletedBankItemState,
} from 'views/fund-escrow/stotes';
import { getLogos } from 'views/pipelines/components/fund-investment/constants';
import './withdraw-fund.scss';

export const WithdrawFund = () => {
	//globle states
	const setNavigate = useSetRecoilState(EscrowModalBodyNavigatorState);
	const selectedBankItem = useRecoilValue(SeletedBankItemState);
	const selectedSubItem = useRecoilValue(SelectedBankSubItemState);
	const isLoading = useRecoilValue(AddBankAccountLoadingState);
	const escrowDetails = useRecoilValue(EscrowDetailsAtom);
	const [amountToWithdraw, setAmountToWithdraw] = useRecoilState(
		AmountToWithdrawFromEscrowState
	);

	//local states
	const [isError, setIsError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	// hooks
	const { errorNotification } = useNotification();
	const { numberDecimal } = useFormatNumber();

	const { data } = useMemo(() => escrowDetails, [escrowDetails]);
	const { disbursable } = useMemo(() => data, [data]);

	useEffect(() => {
		if (amountToWithdraw.trim() === '' || Number(amountToWithdraw) <= 0) {
			setAmountToWithdraw('100.00');
		}
		if (disbursable <= 100) {
			setAmountToWithdraw(numberDecimal(disbursable));
		}
		if (disbursable <= 0) {
			setIsError(true);
			setErrorMessage('Insufficient fund available');
		} else {
			setIsError(false);
			setErrorMessage('');
		}
		// eslint-disable-next-line
	}, []);

	const handleBankChange = useCallback(() => {
		setNavigate('change_bank');
	}, [setNavigate]);

	const handleWithdraw = useCallback(() => {
		if (Number(amountToWithdraw) > disbursable) {
			errorNotification('Insufficient fund available');
			return;
		}
		setNavigate('verifying_details');
	}, [disbursable, amountToWithdraw, errorNotification, setNavigate]);

	const handleBack = useCallback(() => {
		setNavigate('show_details');
	}, [setNavigate]);

	const handleWithdrawAmount = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const { value } = e.target;
			setAmountToWithdraw(value);
			if (value.trim() === '' || Number(value) <= 0) {
				setIsError(true);
				setErrorMessage('Please enter a valid withdrawal amount');
				return;
			}
			setIsError(false);
			setErrorMessage('');
		},
		[setAmountToWithdraw]
	);

	const handleMaxAmount = useCallback(() => {
		if (disbursable) {
			setIsError(false);
			setErrorMessage('');
		}
		setAmountToWithdraw((disbursable ?? 0).toFixed(2));
	}, [disbursable, setAmountToWithdraw]);

	const isDisable = useMemo(() => {
		return (
			disbursable <= 0 ||
			amountToWithdraw.trim() === '' ||
			Number(amountToWithdraw) <= 0
		);
	}, [disbursable, amountToWithdraw]);

	return (
		<div className="withdraw-fund-wrapper">
			<div className="withdraw-fund-header">
				<div className="withdraw-fund-header__title">Withdraw Fund</div>
				<div className="withdraw-fund-header__desc">Some description here</div>
			</div>
			<div className="withdraw-fund-body">
				<div className="withdraw-fund-available-balance">
					<div className="withdraw-fund-available-balance__label">
						Available Banlace
					</div>
					:
					<div className="withdraw-fund-available-balance__value">
						${numberDecimal(disbursable ?? 0)}
					</div>
				</div>
				<div className="withdraw-fund-input-amount">
					<Input
						label="Amount to Withdraw"
						value={amountToWithdraw}
						inputType="number"
						handleChange={handleWithdrawAmount}
						placeholder={'Amount to Withdraw'}
						disabled={disbursable === 0}
						isError={isError}
						errorMessage={errorMessage}
					/>
					<div className="withdraw-fund-max-amount" onClick={handleMaxAmount}>
						Max
					</div>
				</div>
				{isLoading ? (
					<div className="loader-wrapper">
						<Loader type="gif" />
					</div>
				) : (
					<div className="withdraw-fund-in">
						<div className="withdraw-fund-in__title">Withdraw In</div>
						<div className="withdraw-fund-in__bank-list">
							<div className="bank-card">
								<div className="bank-card__icon-wrapper">
									<Image
										fileName={`${
											getLogos[selectedBankItem?.bankName] ??
											getLogos['default']
										}`}
									/>
								</div>
								<div className="bank-card__bank-title-wrapper">
									<div className="bank-card__bank-title-wrapper__bank-name">
										{selectedBankItem?.bankName}
									</div>
									<div className="bank-card__bank-title-wrapper__account-type">
										{`${selectedSubItem?.subtype}`}{' '}
										(&#8226;&#8226;&#8226;&#8226;
										{selectedSubItem?.mask})
									</div>
								</div>
								<div
									onClick={handleBankChange}
									className="bank-card__change-bank"
								>
									Change
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
			<div className="withdraw-fund-footer">
				<div className="withdraw-fund-footer__btns">
					<Button
						label="Back"
						handleClick={handleBack}
						type="button__filled button__filled--secondary"
					/>
					<Button
						label="Withdraw"
						handleClick={handleWithdraw}
						type="button__filled button__filled--primary"
						disabled={isLoading || isDisable}
					/>
				</div>
			</div>
		</div>
	);
};
