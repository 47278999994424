import { Fragment, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import {
	AllowedInvitesState,
	IAllowedInvites,
	SubscriptionPriceState,
	loginState,
} from 'global-stores';
import { ChooseIdentityData } from 'views/user-identity-flow';
import { ONBOARDING_REMAINING_SERVICES } from 'views/new-user-popup/constants';
import { useAllowedInvites } from 'hooks';

export const BuisnessAcountType = () => {
	const subscriptionPrice = useRecoilValue(SubscriptionPriceState);

	const price = useMemo(() => {
		if (subscriptionPrice) {
			return subscriptionPrice[0]?.['entity'];
		} else return 0;
	}, [subscriptionPrice]);

	return (
		<>
			<div className="subscriptions__acount-type-box">
				<div className="subscriptions__acount-type subscriptions__buisness  subscriptions__buisness-acount">
					<i className="ri-briefcase-fill"></i>
				</div>
				<div>
					<div className="subscriptions__acount-type-label">
						{ChooseIdentityData[1]?.label}
					</div>
					<div className="subscriptions__acount-type-amount">
						{`$${price ?? 0}/month`}
					</div>
				</div>
			</div>
			<div>
				<div className="subscriptions__sevice-include">Services included:</div>
				{ChooseIdentityData[1]?.services.map(ser => (
					<Fragment key={ser.name}>
						<div className="subscriptions__sevice-include-type">
							<i className="ri-checkbox-circle-fill subscriptions__sevice-include-type-icon "></i>
							{ser.name}
						</div>
					</Fragment>
				))}
			</div>
		</>
	);
};

export const BuisnessFreeAcountType = () => {
	const subscriptionPrice = useRecoilValue(SubscriptionPriceState);
	const { parentBusinessId = '' } = useRecoilValue(loginState);
	const allowedInvites = useRecoilValue(AllowedInvitesState);
	const { totalSessions, totalSimpliciDocs } = useAllowedInvites();

	const price = useMemo(() => {
		if (subscriptionPrice) {
			return subscriptionPrice[0]?.['entity'];
		} else return 0;
	}, [subscriptionPrice]);

	const {
		onboarding: ONBOARDING,
		simpliciSign: SIMPLICI_SIGN,
	}: IAllowedInvites = allowedInvites ?? {};

	const remains: { [key: string]: number } = useMemo(() => {
		const onboarding =
			totalSessions < ONBOARDING ? ONBOARDING - totalSessions : 0;
		const simpliciSign =
			totalSimpliciDocs < SIMPLICI_SIGN ? SIMPLICI_SIGN - totalSimpliciDocs : 0;
		return { onboarding, simpliciSign };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [totalSessions, totalSimpliciDocs]);

	const renderDemoBody = useMemo(() => {
		return (ONBOARDING_REMAINING_SERVICES ?? []).map(({ key, title }) => (
			<div
				key={key}
				className={`free-credits-remains-wrapper__info-content__${key}`}
			>
				<div className="free-credits-remains-wrapper__info-content__description">
					<div className="free-credits-remains-wrapper__info-content__description__title">
						{title}
					</div>
					<div className="floading__remains">
						{parentBusinessId ? 0 : remains[key]} invites remaining
					</div>
				</div>
				<progress
					value={parentBusinessId ? 0 : remains[key]}
					max={allowedInvites?.[key as 'onboarding'] ?? 0}
					className="subscriptions__loading-bar"
				/>
			</div>
		));
	}, [remains, allowedInvites, parentBusinessId]);

	return (
		<>
			<div className="subscriptions__acount-type-box">
				<div className="subscriptions__acount-type subscriptions__buisness  subscriptions__buisness-acount">
					<i className="ri-briefcase-fill"></i>
				</div>
				<div>
					<div className="subscriptions__acount-type-label">
						{ChooseIdentityData[1]?.label}
					</div>
					<div className="subscriptions__acount-type-amount">
						{`$${price ?? 0}/month`}
					</div>
				</div>
			</div>
			<div>
				<div className="subscriptions__sevice-include">Services included:</div>
				{ChooseIdentityData[0]?.services.map(ser => (
					<>
						<div className="subscriptions__sevice-include-type">
							<i className="ri-checkbox-circle-fill subscriptions__sevice-include-type-icon "></i>
							{ser.name}
						</div>
					</>
				))}
				{renderDemoBody}
			</div>
		</>
	);
};
