import { IOption, IconBtn, Loader, ReactResponsiveTable } from '@storybook';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { ConfirmationModal } from 'components';
import { API_URL, ROLE, message } from 'constant';
import { useNetwork, useNotification } from 'hooks';
import {
	EditModal,
	FundsState,
	InviteDetailsState,
	InviteEmailInputState,
	InviteModal,
	UserRoleState,
	loginState,
} from 'global-stores';
import { IRoleType } from 'types';
import { UserRolesHeaders } from '../constant';
import { IBusinessUser } from '../store';

interface IUserList {
	users: IBusinessUser[];
}

const { DELETE_MESSAGE, DESCRIPTION_MESSAGE, TITLE_MESSAGE, BOLD_DESCRIPTION } =
	message;

export const UserLists: FC<IUserList> = ({ users }) => {
	const [isConfirmationModal, setIsConfirmationModal] =
		useState<boolean>(false);
	const setShowLastEmailError = useSetRecoilState(InviteEmailInputState);
	const setUsers = useSetRecoilState(UserRoleState);
	const { role: loginUserRole } = useRecoilValue(loginState);
	const setIsOpenInviteUserModel = useSetRecoilState(InviteModal);
	const setInvites = useSetRecoilState(InviteDetailsState);
	const setEdit = useSetRecoilState(EditModal);
	const fundsList = useRecoilValue(FundsState);
	// const addedCards = useRecoilValue(CardsListViewState);

	const { DEVELOPER, OWNER, ADMIN, MANAGER } = useMemo(() => ROLE, []);

	const {
		remove: deleteUser,
		data: deletedUser,
		loading: deletedLoading,
	} = useNetwork();
	const [selectedUserId, setSelectedUserId] = useState<string>('');

	const { successNotification } = useNotification();

	const onClickDeleteIcon = useCallback((id: string) => {
		setIsConfirmationModal(true);
		setSelectedUserId(id);
	}, []);

	const handleOpen = useCallback(
		(item: any) => {
			setIsOpenInviteUserModel(true);
			const fundValues = fundsList?.filter(object1 => {
				return item?.fundIds?.some((object2: IOption) => {
					return object1.id === object2;
				});
			});
			const fundData: IOption[] = [];
			fundValues.forEach(fund => {
				fundData.push({ label: fund.name, value: fund.id });
			});

			setInvites({ ...item, funds: fundData });
			setEdit(true);
			setShowLastEmailError(false);
		},

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[fundsList]
	);

	const manageUserActive = useCallback(
		(
			role: IRoleType,
			isVerifiedPhone: boolean,
			isVerifiedEmail?: boolean
		): {
			userClass: string;
			userStatus: string;
		} => {
			const isVerified = isVerifiedPhone || isVerifiedEmail;
			let userClass = 'InActive';
			let userStatus = 'inactiveStatus';
			if (role === 'OWNER') {
				// TODO: we we are checking cards length prev
				// const cardsLength = addedCards.length;
				// const isActive = cardsLength > 0 && isVerifiedPhone;

				const isActive = isVerified;
				userStatus = isActive ? 'Active' : 'Inactive';
				userClass = isActive ? 'activeStatus' : 'inactiveStatus';
			} else {
				userClass = isVerified ? 'activeStatus' : 'inactiveStatus';
				userStatus = isVerified ? 'Active' : 'Inactive';
			}
			return {
				userClass: userClass ?? 'Inactive',
				userStatus: userStatus ?? 'inactiveStatus',
			};
		},
		[]
	);

	const handleDisableRoles = useCallback(
		(role: string) => {
			if (
				role === OWNER ||
				loginUserRole === DEVELOPER ||
				(loginUserRole === ADMIN && role === ADMIN) ||
				(loginUserRole === MANAGER && role === ADMIN) ||
				(loginUserRole === MANAGER && role === MANAGER)
			)
				return true;
			return false;
		},
		// eslint-disable-next-line
		[loginUserRole]
	);

	const usersRows = useMemo((): IBusinessUser[] => {
		const rows: any = [];
		if (users && UserRolesHeaders.HEADER) {
			users.forEach((item: any) => {
				if (item) {
					let row: any = {};
					UserRolesHeaders.HEADER.forEach(({ format, key }) => {
						if (key === 'role.name') {
							return (row[key] = item.role?.name);
						}
						if (format === 'jsx' && key === 'action') {
							const value = () => (
								<div className="btn-group">
									<IconBtn
										btnClassName="ri-edit-line  icon__primary icon__large"
										handleClickIcon={() => handleOpen(item)}
										isDisabled={handleDisableRoles(item?.role?.name ?? '')}
									/>
									<IconBtn
										btnClassName="ri-delete-bin-line  icon__danger icon__large"
										handleClickIcon={() => onClickDeleteIcon(item._id)}
										isDisabled={handleDisableRoles(item?.role?.name ?? '')}
									/>
								</div>
							);
							return (row[key] = value);
						}
						if (format === 'jsx' && key === 'status') {
							const value = () => (
								<div className="userStatus">
									<div
										className={
											manageUserActive(
												item.role.name,
												item.isVerifiedPhone,
												item.isVerifiedEmail
											).userClass
										}
									></div>
									<div className="client-id">
										{
											manageUserActive(
												item.role.name,
												item.isVerifiedPhone,
												item.isVerifiedEmail
											).userStatus
										}
									</div>
								</div>
							);
							return (row[key] = value);
						}
						row = {
							...row,
							[key]: item[key],
							status1: manageUserActive(
								item.role.name,
								item.isVerifiedPhone,
								item.isVerifiedEmail
							).userStatus,
						};
						return null;
					});
					rows.push(row);
				}
			});
		}
		return rows;
	}, [
		handleDisableRoles,
		handleOpen,
		manageUserActive,
		onClickDeleteIcon,
		users,
	]);

	const handleUserDeleteModel = useCallback(
		(isOpen: boolean, value: boolean) => {
			if (value && !deletedLoading) {
				deleteUser(`${API_URL.USER_ROLES}/${selectedUserId}`);
			} else {
				setIsConfirmationModal(isOpen);
			}
		},
		[deleteUser, selectedUserId, deletedLoading]
	);

	useEffect(() => {
		if (deletedUser) {
			successNotification(DELETE_MESSAGE);
			setUsers(prevState => {
				return prevState.filter(item => item._id !== deletedUser._id);
			});
			users.filter(item => item._id !== deletedUser._id);
			setIsConfirmationModal(false);
		}
		// eslint-disable-next-line
	}, [deleteUser, deletedUser]);

	const deleteButtonLabel = useMemo(() => {
		return !deletedLoading ? (
			'Delete'
		) : (
			<Loader className="loader-blue" dimension={26} />
		);
	}, [deletedLoading]);

	return (
		<>
			<ReactResponsiveTable
				isLoading={!users.length}
				className="user-table"
				column={UserRolesHeaders.HEADER}
				rows={usersRows}
				height={'calc(100vh - 150px)'}
				emptyHeight={'calc(100vh - 200px'}
				showSearch={true}
				showFilter={false}
				columnHandle={false}
			/>
			<ConfirmationModal
				visible={isConfirmationModal}
				title={TITLE_MESSAGE}
				description={DESCRIPTION_MESSAGE}
				boldDescription={BOLD_DESCRIPTION}
				handleModal={handleUserDeleteModel}
				label={deleteButtonLabel}
				disabled={deletedLoading}
			/>
		</>
	);
};
