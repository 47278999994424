/* eslint-disable react/no-array-index-key */
import { FC, useCallback, useEffect, useState } from 'react';
import { OFACSectionHOC } from '../ofac-hoc';
import { CaseHOC } from '../case-hoc';
import { CaseDetails } from '../case-details';
import { useRecoilValue } from 'recoil';
import { OfacSelector } from 'views/sessions/components/kyb-wrapper/hooks/store/selector/ofac';
import { IOfacState } from 'views/sessions/components/kyb-wrapper/hooks/store/selector/types';
import { EmptySection } from '../empty-section';
import {
	KybOfacSelectedTab,
	OfacTypes,
} from 'views/sessions/components/kyb-wrapper/hooks';

interface IProps {
	cases: string[];
}

const OFAC_TYPE: Record<OfacTypes, string> = {
	GWL: 'Global Watchlist',
	PEP: 'Politically Exposed Person',
	EDD: 'Enhanced Due Diligence',
	SOE: 'State Owned Enterprise ',
	['PEP/EDD']: 'Politically Exposed Person / Enhanced Due Diligence',
};

export const OFACCaseSection: FC<IProps> = ({ cases }) => {
	const OfacData: IOfacState = useRecoilValue(OfacSelector);
	const selectedTab = useRecoilValue(KybOfacSelectedTab);
	const [viewAll, setViewAll] = useState(false);

	const handleView = useCallback((val: boolean) => {
		setViewAll(val);
	}, []);

	useEffect(() => {
		setViewAll(false);
	}, [selectedTab]);

	return (
		<>
			{cases?.map((caseId: string) => {
				const currentCase =
					OfacData.ofacCases[selectedTab as keyof typeof OfacData.ofacCases];
				const { report } = currentCase ?? {};
				const { metadata } = report ?? {};
				if (caseId !== selectedTab) {
					return;
				}	
				return (
					<OFACSectionHOC
						key={caseId}
						label={OFAC_TYPE[selectedTab]}
						matches={report?.totalMatches}
						showViewMoreBtn={metadata?.length > 4}
						handleViewMore={handleView}
					>
						<>
							{(!metadata || metadata?.length === 0) && (
								<EmptySection message={'No data is available'} />
							)}
							{metadata?.map((currentCase, index) => {
								if (!viewAll && index >= 4) {
									return null;
								}
								return (
									<CaseHOC
										key={index}
										date={(currentCase as any).date}
										name={currentCase.name}
										riskScore={currentCase?.riskScore}
										entityId={currentCase.entityId}
									>
										<CaseDetails key={index} cases={currentCase} />
									</CaseHOC>
								);
							})}
						</>
					</OFACSectionHOC>
				);
			})}
		</>
	);
};
