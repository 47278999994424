import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { kycSettingsState } from 'global-stores';
import { IStackedColumnChart } from './types';

export const useStackedChart = () => {
	const kycSettings = useRecoilValue(kycSettingsState);
	const { settings } = useMemo(() => kycSettings, [kycSettings]);
	const { bodyFont } = settings ?? {};

	const getStackedConfig = ({
		seriesData,
		title,
		XAxis,
		isLoading,
	}: IStackedColumnChart) => {
		const checkDataValue = seriesData
			.map((item: any) => item.data.reduce((a: number, b: number) => a + b, 0))
			.reduce((a: number, b: number) => a + b, 0);
		let yMax;
		if (checkDataValue === 0) {
			yMax = 10;
		} else {
			yMax = null;
		}

		const chart =
			(window as any).Highcharts?.chart(title, {
				chart: {
					type: 'column',
					style: {
						fontFamily: bodyFont?.family !== '' ? bodyFont?.family : 'Poppins',
					},
				},

				credits: {
					enabled: false,
				},

				colors: [
					'#356DDE',
					'#D335DE',
					'#DECD35',
					'#1A9E69',
					'#6922D3',
					'#DE8A35',
					'#A6C431',
				],

				title: {
					text: title,
					align: 'left',
					style: {
						color: '#000000',
						fontWeight: '600',
						fontSize: '16px',
					},
				},

				xAxis: {
					labels: {
						overflow: 'justify',
					},
					categories: XAxis,
				},

				yAxis: {
					min: 0,
					max: yMax,
					title: {
						text: null,
					},
					stackLabels: {
						enabled: false,
					},
				},

				legend: {
					width: '100%',
					symbolRadius: 0,
					itemMarginTop: 2,
					title: {
						text: 'Action',
						style: {
							color: '#878C99',
							fontWeight: '500',
							fontSize: '12px',
						},
					},
					itemStyle: {
						color: '#747E99',
						fontWeight: '500',
						fontSize: '12px',
						lineHeight: '16px',
					},
					itemHoverStyle: {
						color: '#000000',
					},
				},

				tooltip: {
					pointFormat:
						'<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> <br/>',
				},

				exporting: { enabled: false },

				plotOptions: {
					series: {
						maxPointWidth: 30,
					},
					column: {
						stacking: 'normal',
						borderColor: null,
						states: {
							inactive: {
								enabled: false,
							},
						},
					},
				},

				series: seriesData,
			}) ?? {};

		if (isLoading) {
			chart.showLoading();
		} else {
			chart.hideLoading();
		}
	};

	return { getStackedConfig };
};
