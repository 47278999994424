import type { IDashboardPieChart } from 'views/dashboard-analytics/hooks';

import { FC, useEffect } from 'react';

import { usePieChart } from 'views/dashboard-analytics/hooks';
import './dashboard-chart.scss';

export const PieChart: FC<IDashboardPieChart> = ({
	isLoading,
	title,
	approved,
	rejected,
	underReview,
	pending,
}) => {
	const { getPieChartConfig } = usePieChart();

	useEffect(() => {
		getPieChartConfig({
			isLoading,
			title,
			approved,
			rejected,
			underReview,
			pending,
		});
	}, [
		approved,
		getPieChartConfig,
		isLoading,
		pending,
		rejected,
		title,
		underReview,
	]);

	return (
		<div className="dashboard-chart-wrapper">
			<div id={title} />
		</div>
	);
};
