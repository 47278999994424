import { OnboardingFlowBackModel } from 'components/footer-navigate-modal';
import { FC, useCallback, useEffect, useState } from 'react';

interface INavigateToBackAlert {
	isModalOpen: boolean;
	modalCloseHandler: () => void;
}

/**
 * NavigateToBackAlert component that handles the browser back button event
 * and shows a modal when the user tries to navigate back.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {boolean} props.isModalOpen - Indicates if the modal is currently open.
 * @param {Function} props.modalCloseHandler - Function to close the modal.
 * @returns {JSX.Element} The NavigateToBackAlert component.
 */
export const NavigateToBackConfirmation: FC<INavigateToBackAlert> = ({
	isModalOpen,
	modalCloseHandler,
}) => {
	const [isBackModel, setIsBackModal] = useState(false);
	const [isBackLoading, setIsBackLoading] = useState(false);

	useEffect(() => {
		// Push the current state to history
		window.history.pushState(null, '', window.location.href);

		// Define the popstate handler
		const handlePopState = () => {
			window.history.pushState(null, '', window.location.href);
			if (isModalOpen) {
				setIsBackModal(true);
			}
		};

		// Attach the event listener
		window.addEventListener('popstate', handlePopState);

		// Cleanup the event listener on unmount
		return () => {
			window.removeEventListener('popstate', handlePopState);
		};
	}, [isModalOpen]);

	/**
	 * Handles the closure of the back modal with a loading delay.
	 *
	 * @function
	 */
	const handleCloseBackModal = useCallback(() => {
		setIsBackLoading(true);
		setTimeout(() => {
			setIsBackModal(false);
			modalCloseHandler();
			setIsBackLoading(false);
		}, 1000);
	}, [modalCloseHandler]);

	/**
	 * Handles input actions within the modal.
	 *
	 * @function
	 */
	const handleInput = useCallback(() => {
		setIsBackModal(false);
	}, []);

	return (
		<OnboardingFlowBackModel
			isOpen={isBackModel}
			isLoaded={isBackLoading}
			handleProceedBack={handleCloseBackModal}
			handleInput={handleInput}
		/>
	);
};
