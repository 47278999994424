/* eslint-disable react/no-array-index-key */
import React, { useCallback, useState } from 'react';
import { camelCaseToCapitalizedWords } from 'utils';
import { BasicSection } from '../../kyb-comprehensive';
import { ISubAdditionalInfo } from '../additional-info-wrapper/type';
import './additional-info.scss';

interface IProps {
	heading: string;
	data: ISubAdditionalInfo[];
}
export const AdditionalsInformation: React.FC<IProps> = ({ heading, data }) => {
	const [viewMore, setViewMore] = useState(false);
	const displayedData = viewMore ? data : data?.slice(0, 2);

	const toggleViewMore = useCallback(() => {
		setViewMore(prev => !prev);
	}, []);

	if (!data?.length) {
		return <></>;
	}
	return (
		<div>
			<div className="additional__heading--wrapper">
				<div className="additional__heading">{heading ?? 'Informations'}</div>
				{data?.length > 2 && (
					<button
						className="additional__heading--view--more"
						onClick={toggleViewMore}
					>
						{viewMore ? 'View Less' : 'View More'}
					</button>
				)}
			</div>

			<div className="kyb-enchanced--aditional-information">
				{(displayedData ?? []).map((info, index) => (
					<React.Fragment key={index}>
						{Object.keys(info ?? {}).map((item, idx) => (
							<BasicSection
								key={idx} // Use idx here for individual items
								label={camelCaseToCapitalizedWords(item)}
								value={info?.[item as keyof typeof info]?.value ?? '--'}
							/>
						))}
						{displayedData.length !== index + 1 && (
							<div className="separator-line"></div>
						)}
					</React.Fragment>
				))}
			</div>
		</div>
	);
};
