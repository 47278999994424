import { useRecoilValue } from 'recoil';
import { Skeleton } from '@storybook';

import { BillingDetailsLoaderState, loginState } from 'global-stores';
import { getCurrentDate, getDate, useFormatNumber } from 'utils';

import './billing-status.scss';
import { useSummaryBilling } from '../hook';

import { useState } from 'react';
import { CostBreakdownDetails } from '../costBreakdownDetails/cost-breakdown-details';
import { CostBreakdownDetailState } from '../../stores';

export const BillingStatus = () => {
	const { numberDecimal } = useFormatNumber();
	
	const billingLoader = useRecoilValue(BillingDetailsLoaderState);
	const costBreakdownDetails = useRecoilValue(CostBreakdownDetailState);
	const { sandboxStatus } = useRecoilValue(loginState);
	
	const { showSelectedBillingDetail } = useSummaryBilling();
	const [isOpen, setIsOpen] = useState(false)

	return (
		<div className="Billing--Status hover">
			<div className="Billing--Status__wraper">
				<div className="Billing--Status__title">Current month</div>
				<div className="Billing--Status__subtitle">{getCurrentDate()}</div>
			</div>
			{!sandboxStatus ? (
				<div className="Billing--Status__amount-wraper">
					<div>
						<div className="Billing--Status__amount-wraper-amount">
							{billingLoader ? (
								<div className="padding-skelton">
									<Skeleton height={24} width={100} />
								</div>
							) : (
								`$${numberDecimal(costBreakdownDetails.total ?? 0)}`
							)}
						</div>
						<div className="Billing--Status__subtitle">Month to date cost <span className='Billing--Status__subtitle_skeleton'>{billingLoader ? <Skeleton height={10} width={100} /> : <a className='see-breakdown' onClick={() => setIsOpen(true)}>(See breakdown)</a>}</span></div>
					</div>
					<div>
						<div className="Billing--Status__amount-wraper-amount">
							{billingLoader ? (
								<div className="padding-skelton">
									<Skeleton height={24} width={100} />
								</div>
							) : (
								`${
									showSelectedBillingDetail?.endDate
										? getDate(showSelectedBillingDetail?.endDate)
										: ' -- '
								}`
							)}
						</div>
						<div className="Billing--Status__subtitle">Next billing date</div>
					</div>
				</div>
			) : (
				<div className="Billing--Status__subtitle Billing--Status__sandboxText">
					You are in sandbox mode switch to live mode for current month usage
					summary
				</div>
			)}
			<CostBreakdownDetails isOpen={isOpen} setIsOpen={setIsOpen} />
		</div>		
	);
};
