import { IconBtn } from '@storybook';
import { FC, createRef, useCallback, useMemo, useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { useOutsideClick } from 'hooks';
import { ACTION_KEY_TYPE, actions } from '../constant';
import { IAction, actionKeys } from '../store';
import { SimpliciUseTemplate } from './esign-use-template';
import { EsingConfigDocs } from './esign-config-docs';
import { PACKET_ORIGIN } from 'views/signed-doc-packets-inbox';
import { SimpliciEditTemplate } from './edit-template';

interface IProps {
	item: any;
	handleDelete: (id: string, index: number) => void;
	index: number;
	isUserPermissionWrite?: boolean;
}

export const SignedDocTemplateAction: FC<IProps> = ({
	item,
	handleDelete,
	index,
	isUserPermissionWrite = true,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const ref = createRef<HTMLDivElement>();

	useOutsideClick(ref, () => {
		setIsOpen(false);
	});

	const handleKeyPress = useCallback((event: any) => {
		if (event.key.toLowerCase() === 'escape') {
			setIsOpen(false);
		}
	}, []);

	const handleDeleteTemplate = useCallback(() => {
		const id =
			item.provider === 'ESign' ? `${item.templateId}?type=esign` : item._id;
		handleDelete(id, index);
	}, [handleDelete, index, item._id, item.provider, item.templateId]);

	const handleOpenDocs = useCallback(() => {
		const { fileUrl, documents } = item;
		if (fileUrl) {
			window.open(item.fileUrl, '_blank');
			return;
		}
		documents.forEach((doc: any) => {
			window.open(doc.path, '_blank');
		});
	}, [item]);

	const handleSelect = useCallback(
		(key: actionKeys) => {
			switch (key) {
				case 'delete':
					handleDeleteTemplate();
					setIsOpen(false);
					break;
				case 'file':
					handleOpenDocs();
					setIsOpen(false);
					break;
				case 'useTemplate':
					break;
				default:
					break;
			}
		},
		[handleDeleteTemplate, handleOpenDocs]
	);

	const renderActions = useMemo(() => {
		// if the item source === 'onboarding' removing delete option from actionlist
		const filteredActions = actions.filter(icon => {
			return !(
				icon.key === ACTION_KEY_TYPE.DELETE &&
				item?.type === PACKET_ORIGIN.OVERLAY
			);
		});

		return (filteredActions as IAction[]).map(action => {
			if (!isUserPermissionWrite && action.key === 'delete') return;
			return (
				<div
					id={`${action.label}-${action.icon}`}
					key={action.key}
					onClick={() => handleSelect(action.key)}
					className={`SignedDocTemplateAction__action SignedDocTemplateAction__action${
						action.isDisabled ? '--disabled' : ''
					}`}
				>
					<i className={action.icon} style={{ color: action.iconColor }} />
					<div className="SignedDocTemplateAction__action-label">
						{action.label}
					</div>
					{action.isDisabled && (
						<ReactTooltip
							place="left"
							anchorId={`${action.label}-${action.icon}`}
							content="This feature is coming soon"
							style={{
								zIndex: 1,
								opacity: 1,
								background: '#000',
								color: '#fff',
							}}
						/>
					)}
				</div>
			);
		});
	}, [handleSelect, isUserPermissionWrite, item]);
	return (
		<div className="SignedDocTemplateAction__container">
			<div className="SignedDocTemplateAction__ues-template">
				{(item.provider ?? '').toLowerCase() === 'esign' &&
					isUserPermissionWrite && <SimpliciUseTemplate template={item} />}
			</div>
			<div className="SignedDocTemplateAction__ues-template">
				{(item.provider ?? '').toLowerCase() === 'esign' &&
					isUserPermissionWrite && (
						<SimpliciEditTemplate template={item}/>
					)}
			</div>
			<div className="SignedDocTemplateAction__ues-template">
				{(item.provider ?? '').toLowerCase() === 'esign' &&
					isUserPermissionWrite && (
						<EsingConfigDocs templateId={item?.templateId ?? ''} />
					)}
			</div>

			<div
				className="SignedDocTemplateAction"
				ref={ref}
				onKeyDown={handleKeyPress}
			>
				<IconBtn
					btnClassName="ri-more-2-fill"
					handleClickIcon={() => setIsOpen(prev => !prev)}
					id={`${item.templateId}-${item.documentId}`}
				/>
				<ReactTooltip
					place="left"
					anchorId={`${item.templateId}-${item.documentId}`}
					content="Actions Menu"
					style={{ zIndex: 1 }}
				/>
				{isOpen && (
					<div className="SignedDocTemplateAction__wrapper options-box">
						{renderActions}
					</div>
				)}
			</div>
		</div>
	);
};
