import { IPreviousSessionData } from 'global-stores/sessions/type';
import type {
	IFunds,
	IFundDetails,
	IFundRow,
	IShowFunds,
	IFundModal,
	IFundFormErrorHandler,
	IFundInvestors,
} from './type';

import { atom } from 'recoil';
import { v4 } from 'uuid';

export const FundsState = atom<IFunds[]>({
	key: 'total-funds-recieved',
	default: [],
});

export const TempFundListState = atom<IFunds[]>({
	key : "temp-funds-state-list",
	default: []
})

export const FundsLoadedState = atom<boolean>({
	key: v4(),
	default: false,
});

export const FundInvestors = atom<IFundInvestors>({
	key: 'fund-investors',
	default: {
		investors: '',
		pendingInvestors: '',
	},
});

export const FundDetailState = atom<IFundDetails[]>({
	key: 'fund-detail',
	default: [],
});

export const FundDetailRowState = atom<IFundRow[]>({
	key: 'fund-detail-tables',
	default: [],
});

export const FundsToShowState = atom<IShowFunds[]>({
	key: 'fund-to-show-state',
	default: [],
});

export const FundInvestorState = atom<IFundDetails>({
	key: 'fund-investor-state',
	default: {
		firstName: '',
		lastName: '',
		entity: '',
		address: '',
		countryCode: '+1',
		phone: '',
		email: '',
		share: '',
		price: '',
		invested: '',
		class: '',
		issueDate: '',
		completionDate: '',
		fundingRound: '',
	},
});

export const FundInvestorModalState = atom<IFundModal>({
	key: 'fund-investor-modal-status',
	default: {
		id: '',
		isEdit: false,
		isModalOpen: false,
	},
});

export const FundInvestorFormErrorHandlerState = atom<IFundFormErrorHandler>({
	key: 'fund-investor-form-error-handler-state',
	default: {
		firstName: false,
		lastName: false,
		email: false,
		phone: false,
	},
});

export const isFundCallingState = atom<boolean>({
	key: 'is-fund-calling-state',
	default: false,
});

export const FundPaginationState = atom<number>({
	key: 'fund-pagination',
	default: 1,
});

export const PageCountState = atom<number>({
	key: 'page-count-state',
	default: 0,
});

// storing session data for back button use in kyc-aml verification members screen
export const PreviousSessionDataState = atom<IPreviousSessionData>({
	key: 'previous-session-data-state',
	default: {},
});