/* eslint-disable no-console */
import type { IComplexThenBlock } from 'views/complex-onboarding-flow/stores/states';

import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { OperatorOptions } from 'views/complex-onboarding-flow/constant';
import {
	ComplexInitPipelines,
	NodesState,
} from 'views/complex-onboarding-flow/stores/states';
import { IOption } from '../dropdown';
import { QuestionsThread } from '../question-container';
import { convertCamelCaseToWords } from 'utils';
import { SelectedOnboardingAction } from 'global-stores';

interface IQuestionWrapper {
	stepId: string;
	conditionId: any;
	questionData?: any;
	labelId: string;
	nodeId: string;
	blockId: string;
	questionLen: number;
	index: number;
	connect: string;
	defaultThenValue: string;
	defaultOperandValue: string;
}

export interface Value {
	label: string;
	value: string;
}

export interface InputType {
	key: string;
	label: string;
	type: string;
	value: Value[];
}

export const KycQuestionWrapper: FC<IQuestionWrapper> = ({
	stepId,
	conditionId,
	questionData,
	labelId,
	nodeId,
	blockId,
	questionLen,
	index,
	connect,
	defaultThenValue,
	defaultOperandValue,
}) => {
	// Global variables
	const complexInitPipelines = useRecoilValue(ComplexInitPipelines);
	const setNodes = useSetRecoilState(NodesState);

	const [operators, setOperators] = useState({});
	const selectedOnboarding = useRecoilValue(SelectedOnboardingAction);

	// Local Variables
	const [inputType, setInputType] = useState<InputType>({
		key: '',
		label: '',
		type: 'text',
		value: [],
	});
	const [checked, setChecked] = useState(false);

	const {
		stepVariables,
		operators: allOperators = [],
		thenConditions = [],
	} = complexInitPipelines ?? {};

	const OptionsResult = useMemo(
		() =>
			stepVariables.filter(
				(el: any) => el.stepId === stepId && el.labelId === labelId
			),
		[stepVariables, stepId, labelId]
	);
	//Rahul Singh: replace the positon of code
	const Options = useMemo(
		() =>
			OptionsResult?.map((el: { label: string; key: string }) => ({
				label: el.label,
				value: el.key,
			})),
		[OptionsResult]
	);
	const findDefaultParamValue = Options.find(
		(el: any) => el.value === questionData.param
	);
	// Rahul Singh: for finding value and type for paramvalue.
	const OptionsResultValue = useMemo(
		() =>
			OptionsResult.find((el: any) => el.key === findDefaultParamValue?.value),
		[OptionsResult, findDefaultParamValue?.value]
	);
	//Rahul Singh: useEffect to render type,value and key at the time of edit of complex flow.
	useEffect(() => {
		setInputType(prv => ({
			...prv,
			['type']: OptionsResultValue?.type,
			['key']: OptionsResultValue?.key,
			['value']: OptionsResultValue?.value,
		}));
	}, [
		OptionsResultValue?.key,
		OptionsResultValue?.type,
		OptionsResultValue?.value,
	]);

	const onRemoveQuestion = useCallback(() => {
		setNodes((prev: any) => {
			const prevObj = JSON.parse(JSON.stringify(prev));
			const findIndex = prevObj.findIndex((el: any) => el.id === nodeId);
			if (findIndex !== -1) {
				const findBlockIndex = prevObj[findIndex].conditions.findIndex(
					(el: any) => el.labelId === labelId && el.blockId === blockId
				);

				if (findBlockIndex !== -1) {
					if (
						prevObj[findIndex].conditions[findBlockIndex][connect]?.length > 1
					) {
						prevObj[findIndex].conditions[findBlockIndex][connect].splice(
							index,
							1
						);
					} else {
						prevObj[findIndex].conditions.splice(findBlockIndex, 1);
					}
				}
				return prevObj;
			}
			return prevObj;
		});
	}, [setNodes, nodeId, blockId, labelId, connect, index]);

	const handleParamsChangeSelect = useCallback(
		({ value }: IOption, key: string) => {
			const optionObj = OptionsResult.find(
				(el: { key: string }) => el.key === value
			);

			const filteredOperators = allOperators
				.filter((el: any) => optionObj?.allowedOp?.includes(el.value))
				?.map((elm: any) => {
					return {
						label: convertCamelCaseToWords(elm.key),
						value: elm.value,
					};
				});
			setOperators(filteredOperators);
			setInputType(optionObj);
			setNodes((prev: any) => {
				const prevObj = JSON.parse(JSON.stringify(prev));
				const findIndex = prevObj.findIndex((el: any) => el.id === nodeId);
				if (findIndex !== -1) {
					const findBlockIndex = prevObj[findIndex].conditions.findIndex(
						(el: any) => el.labelId === labelId && el.blockId === blockId
					);
					if (findBlockIndex !== -1) {
						prevObj[findIndex].conditions[findBlockIndex][connect][index][key] =
							value;
					}
					return prevObj;
				}

				return prevObj;
			});
		},
		[
			OptionsResult,
			allOperators,
			setNodes,
			nodeId,
			labelId,
			blockId,
			connect,
			index,
		]
	);

	const handleOperatorChangeSelect = useCallback(
		({ value }: IOption, key: string) => {
			setNodes((prev: any) => {
				const prevObj = JSON.parse(JSON.stringify(prev));
				const findIndex = prevObj.findIndex((el: any) => el.id === nodeId);
				if (findIndex !== -1) {
					const findBlockIndex = prevObj[findIndex].conditions.findIndex(
						(el: any) => el.labelId === labelId && el.blockId === blockId
					);
					if (findBlockIndex !== -1) {
						prevObj[findIndex].conditions[findBlockIndex][connect][index][key] =
							value;
					}
					return prevObj;
				}
				return prevObj;
			});
		},
		[setNodes, nodeId, labelId, blockId, connect, index]
	);

	const onChangeTextInput = useCallback(
		({ target: { value } }: any, type: string) => {
			const { day, month, year } = value;
			let newValue = '';
			if (type == 'date') {
				newValue = `${month}-${day}-${year}`;
			} else {
				newValue = value;
			}
			setNodes((prev: any) => {
				const prevObj = JSON.parse(JSON.stringify(prev));
				const findIndex = prevObj.findIndex((el: any) => el.id === nodeId);
				if (findIndex !== -1) {
					const findBlockIndex = prevObj[findIndex].conditions.findIndex(
						(el: any) => el.labelId === labelId && el.blockId === blockId
					);
					if (findBlockIndex !== -1) {
						prevObj[findIndex].conditions[findBlockIndex][connect][index][
							'operand'
						] = newValue;
					}
					return prevObj;
				}
				return prevObj;
			});
		},
		[setNodes, nodeId, labelId, blockId, connect, index]
	);

	const handleThenChangeSelect = useCallback(
		({ value }: IOption) => {
			setNodes((prev: any) => {
				const prevObj = JSON.parse(JSON.stringify(prev));
				const findIndex = prevObj.findIndex((el: any) => el.id === nodeId);
				if (findIndex !== -1) {
					const findBlockIndex = prevObj[findIndex].conditions.findIndex(
						(el: any) => el.labelId === labelId && el.blockId === blockId
					);
					if (findBlockIndex !== -1) {
						prevObj[findIndex].conditions[findBlockIndex]['then'] = value;
						prevObj[findIndex].conditions[findBlockIndex][
							'key'
						] = `${labelId}_${stepId}_${blockId}_${nodeId}`;
					}
					return prevObj;
				}
				return prevObj;
			});
		},
		[setNodes, nodeId, stepId, blockId, labelId]
	);

	const handleChangeSwith = (checked: boolean) => {
		setChecked(prev => !prev);
		setNodes((prev: any) => {
			const prevObj = JSON.parse(JSON.stringify(prev));
			const findIndex = prevObj.findIndex((el: any) => el.id === nodeId);
			const conditionKey = Object.keys(conditionId)[0] ?? '';
			if (findIndex !== -1) {
				const findBlockIndex = prevObj[findIndex].conditions.findIndex(
					(el: any) => el.labelId === stepId && el.blockId === blockId
				);
				if (findBlockIndex !== -1) {
					prevObj[findIndex].conditions[findBlockIndex][connect][index][
						conditionKey
					]['operand'] = checked;
				}
				return prevObj;
			}
			return prevObj;
		});
	};

	const defaultOperatorValue = OperatorOptions.find(
		(el: any) => el.value === questionData.operator
	);

	const thenOptions = thenConditions.map((el: IComplexThenBlock) => {
		const { key, label } = el;
		return { value: key, label };
	});

	const defaultThenObj = thenOptions.find(
		(el: any) => el.value === defaultThenValue
	);

	useEffect(() => {
		if (['modify', 'clone'].includes(selectedOnboarding?.type)) {
			const getOperatorOptions = allOperators?.map((el: any) => ({
				label: el.key,
				value: el.value,
			}));
			setOperators(getOperatorOptions);
		}
	}, [allOperators, selectedOnboarding?.type]);

	const defaultProps = {
		findDefaultParamValue,
		Options,
		handleParamsChangeSelect,
		inputType,
		defaultOperatorValue,
		handleOperatorChangeSelect,
		conditionId,
		onChangeTextInput,
		checked,
		handleChangeSwith,
		questionLen,
		index,
		defaultThenObj,
		handleThenChangeSelect,
		labelId,
		stepId,
		blockId,
		nodeId,
		onRemoveQuestion,
		operators,
		defaultOperandValue,
		thenConditions: thenOptions,
	};

	return <QuestionsThread {...defaultProps} />;
};
