import { useCallback, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Button } from '@storybook';
import { ErrorIcons, SuccessIcon } from 'components';
import {
	EscrowModalBodyNavigatorState,
	EscrowModalOpenState,
	EscrowTransactionTypeState,
} from 'views/fund-escrow/stotes';
import './success-screen.scss';

export const SuccessScreen = () => {
	// globle state
	const setModalOpen = useSetRecoilState(EscrowModalOpenState);
	const setNavigate = useSetRecoilState(EscrowModalBodyNavigatorState);
	const transactionType = useRecoilValue(EscrowTransactionTypeState);

	// handle close
	const handleClose = useCallback(() => {
		setModalOpen(false);
		setNavigate('show_details');
	}, [setModalOpen, setNavigate]);

	useEffect(() => {
		setTimeout(() => {
			handleClose();
		}, 3000);
	}, [handleClose]);

	const isShow = true;

	return (
		<>
			<div className="success-screen-wrapper">
				<div className="success-screen">
					{isShow ? <SuccessIcon /> : <ErrorIcons />}

					<div className="success-screen__head-text">
						Fund {transactionType === 'deposit' ? 'deposited' : 'withdrawn'}{' '}
						successfully
					</div>
					<div className="success-screen__sub-text">
						{transactionType === 'deposit'
							? "You've successfully deposited fund into escrow account."
							: "You've successfully withdrawn fund from escrow account."}
					</div>
					<div className="success-screen__btn-wrapper">
						<Button
							label="Close"
							handleClick={handleClose}
							type="button__filled button__filled--secondary"
						/>
					</div>
				</div>
			</div>
		</>
	);
};
