import type { ISignedDocInbox } from 'views/signed-doc-inbox';

import { FC, useEffect, useMemo } from 'react';

import { useSignDocHistory } from '../stores';
import { RecipientCard } from './recipient-card';

interface IProps {
	inbox: ISignedDocInbox;
}

export const SignedRecipientDetails: FC<IProps> = ({ inbox }) => {
	const { fetchRecipientsKycStatus } = useSignDocHistory();	
	//Ankur Singh: Destructure the 'recipients' property from the 'inbox' object with a default empty array
	const { recipients = [], notary, _id: envelopeId } = inbox ?? {};

	useEffect(() => {
		if (notary) {
			fetchRecipientsKycStatus(envelopeId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	//Ankur Singh: Generate UI for each recipient with tooltips for email and location
	const renderRecipient = useMemo(() => {
		return recipients.map(data => {
			return (
				<RecipientCard
					recipient={data}
					key={data.email}
					envelopeId={envelopeId}
					notary={notary}
				/>
			);
		});
	}, [envelopeId, notary, recipients]);
	return (
		<div className="details-container">
			<div className="details-container__label">Recipients</div>
			<div className="recipients-deatils">{renderRecipient}</div>
		</div>
	);
};
