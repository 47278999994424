import { useCallback, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { message } from 'constant';
import { convertFileToBase64, formatBase64 } from 'utils';
import { useNotification } from 'hooks';
import { StatusUploadedDocsState } from '../store';

const FileTypes: any = {
	DOC: true,
	DOCX: true,
	PDF: true,
	CSV: true,
	XLS: true,
};

const SupportedFileTypes: any = {
	DOC: true,
	DOCX: true,
	PDF: true,
	CSV: true,
	XLS: true,
	NUMBERS: true,
	XLSX: true,
};

export const useStatusDocuments = () => {
	// globle states
	const setAddedFiles = useSetRecoilState(StatusUploadedDocsState);

	// local states
	const [inProgressFileName, setInProgressFileName] = useState('');

	// hooks
	const { errorNotification } = useNotification();

	const fileName = (name: string) => {
		const splitedFile = name.split('.');
		const fileType: string | any =
			splitedFile[splitedFile.length - 1]?.toUpperCase();
		if (FileTypes[fileType]) {
			return fileType + '.svg';
		}
		return 'Default.svg';
	};

	const isFileSupported = (name: string) => {
		const splitedFile = name.split('.');
		const fileType: string | any =
			splitedFile[splitedFile.length - 1]?.toUpperCase();
		return SupportedFileTypes[fileType];
	};

	const generateUUID = () => {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
			/[xy]/g,
			function (c) {
				const r = (Math.random() * 16) | 0,
					v = c == 'x' ? r : (r & 0x3) | 0x8;
				return v.toString(16);
			}
		);
	};

	const handleFileChange = useCallback(
		async (e: File) => {
			const { name, type, size, lastModified } = e ?? {};
			if (!isFileSupported(name)) {
				errorNotification('Unsupported file format.');
				return;
			}
			setInProgressFileName(name);
			setAddedFiles(prev => ({ ...prev, isLoaded: false }));
			setTimeout(async () => {
				const fileUrl = await convertFileToBase64(e);
				const formatFileUrl = formatBase64(fileUrl as string)
				setAddedFiles(prev => {
					const prevState = { ...prev };
					prevState.data = [
						{
							name,
							type,
							size,
							createdAt: new Date(lastModified),
							url: formatFileUrl as string,
							_id: generateUUID(),
						},
						...prevState.data,
					];
					return { ...prevState, isLoaded: true };
				});
			}, 1000);
		},
		[errorNotification, setAddedFiles]
	);

	const onFileError = useCallback(
		(e: any) => {
			const { message: error } = e ?? {};
			errorNotification(error?.message ?? message.SomethingWentWrongMessage);
		},
		[errorNotification]
	);

	const handleDeleteFile = useCallback(
		(_id: string) => {
			setAddedFiles(prev => {
				const prevState = { ...prev };
				prevState.data = prevState.data.filter(file => file._id !== _id);
				return { ...prevState };
			});
		},
		[setAddedFiles]
	);

	return {
		handleFileChange,
		onFileError,
		fileName,
		handleDeleteFile,
		inProgressFileName,
	};
};
