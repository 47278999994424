import { DateCalender, ReactSwitch } from '@storybook';
import {
	ChangeEvent,
	FC,
	useState,
	createRef,
	useCallback,
	useMemo,
} from 'react';
import { CustomHandle } from '../custom-handle';
import { IOption } from '../dropdown';
import { InputText } from '../input';
import './question-wrapper.scss';
import { useOutsideClick } from 'hooks';
import { CountryDropdownModal } from './countyr-modal';
import CountryJson from 'views/pipelines/components/address-details/countries.json';

export interface InputType {
	key: string;
	label: string;
	type: string;
	value: IOption[];
}

interface IQuestionWrapper {
	findDefaultParamValue?: IOption;
	Options: IOption[];
	handleParamsChangeSelect: (value: IOption, key: string) => void;
	inputType: InputType;
	defaultOperatorValue?: IOption;
	handleOperatorChangeSelect: (value: IOption, key: string) => void;
	conditionId: any;
	onChangeTextInput: (e: ChangeEvent<HTMLInputElement>, type: string) => void;
	checked?: boolean;
	handleChangeSwith: (check: boolean) => void;
	questionLen: number;
	index: number;
	defaultThenObj?: IOption;
	handleThenChangeSelect: (value: IOption, key: string) => void;
	labelId: string;
	stepId: string;
	blockId: string;
	nodeId: string;
	onRemoveQuestion: () => void;
	findDefaultOperand?: IOption;
	operators: any;
	defaultOperandValue: string;
	thenConditions: IOption[];
}
let prevShowModal = '';
export const QuestionsThread: FC<IQuestionWrapper> = ({
	findDefaultParamValue,
	Options,
	handleParamsChangeSelect,
	inputType,
	defaultOperatorValue,
	handleOperatorChangeSelect,
	conditionId,
	onChangeTextInput,
	checked,
	handleChangeSwith,
	questionLen,
	index,
	defaultThenObj,
	handleThenChangeSelect,
	labelId,
	stepId,
	blockId,
	nodeId,
	onRemoveQuestion,
	operators,
	thenConditions,
	defaultOperandValue,
}) => {
	const [showModal, setShowModal] = useState<string>();
	const [dropdownConfig, setDropdownConfig] = useState<any>({});
	const ref = createRef<HTMLDivElement>();

	useOutsideClick(ref, () => {
		setShowModal('');
		prevShowModal = '';
	});

	const openCountryDropdown = useCallback((key: string) => {
		if (prevShowModal === key) {
			prevShowModal = '';
		} else {
			prevShowModal = key;
		}
		setShowModal(prevShowModal);
	}, []);

	const closeCountryDropdown = useCallback((e: any) => {
		e.stopPropagation();
		setShowModal('');
		prevShowModal = '';
	}, []);

	const hanldeOpenDropdown = useCallback(
		(type: string, key: string) => {
			switch (type) {
				case 'operand':
					{
						const options =
							inputType.key == 'nationality'
								? CountryJson
								: inputType?.value ?? [];
						setDropdownConfig({
							type,
							options,
							defaultValue: defaultOperandValue || {
								label: 'Select',
								value: '',
							},
							handleChangeSelect: handleOperatorChangeSelect,
						});
					}
					break;
				case 'operator':
					setDropdownConfig({
						type,
						options: operators?.length > 0 ? operators : [],
						defaultValue: defaultOperatorValue || {
							label: 'Select',
							value: '',
						},
						handleChangeSelect: handleOperatorChangeSelect,
					});

					break;
				case 'param':
					setDropdownConfig({
						type: 'param',
						options: Options,
						defaultValue: findDefaultParamValue,
						handleChangeSelect: handleParamsChangeSelect,
					});
					break;
				case 'then':
					setDropdownConfig({
						type: 'then',
						options: thenConditions,
						defaultValue: defaultThenObj,
						handleChangeSelect: handleThenChangeSelect,
					});
					break;
				default:
					break;
			}
			openCountryDropdown(key + type);
		},
		[
			inputType,
			operators,
			defaultOperatorValue,
			defaultOperandValue,
			findDefaultParamValue,
			Options,
			thenConditions,
			defaultThenObj,
			handleThenChangeSelect,
			handleParamsChangeSelect,
			openCountryDropdown,
			handleOperatorChangeSelect,
		]
	);

	const countrySelectDropdown = useMemo(() => {
		return (
			<CountryDropdownModal
				defaultOperand={dropdownConfig.defaultValue}
				isOpen={!!showModal}
				onClose={closeCountryDropdown}
				handleChangeSelect={dropdownConfig.handleChangeSelect}
				type={dropdownConfig.type}
				options={dropdownConfig.options}
			/>
		);
	}, [dropdownConfig, showModal, closeCountryDropdown]);

	const operandValue = useMemo(() => conditionId['operand'], [conditionId]);
	const getDate = useMemo(() => {
		if (operandValue) {
			const [month, day, year] = operandValue.split(/[-/]/);
			if (isNaN(+year) || isNaN(+month) || isNaN(+day)) {
				return;
			}
			const newDate = {
				day: +day,
				month: +month,
				year: +year,
			};
			return newDate;
		}
		return null;
	}, [operandValue]);

	return (
		<div className="question-container">
			<div className="question-container__threads">
				<div className="question-container__threads__left">
					<span>If</span>
					<div
						onClick={() =>
							hanldeOpenDropdown('param', inputType.key + inputType?.type)
						}
						className="question-container__select-country"
						ref={
							showModal === inputType.key + inputType?.type + 'param'
								? ref
								: null
						}
					>
						<span className="question-ellipsis">
							{findDefaultParamValue?.label
								? findDefaultParamValue.label
								: 'Select'}
						</span>
						<div className="select-arrow"></div>
						{showModal === inputType.key + inputType?.type + 'param' &&
							countrySelectDropdown}
					</div>
				</div>
				{inputType.type !== 'boolean' ? (
					<>
						<div
							onClick={() =>
								hanldeOpenDropdown('operator', inputType.key + inputType?.type)
							}
							className="question-container__select-country"
							ref={
								showModal === inputType.key + inputType?.type + 'operator'
									? ref
									: null
							}
						>
							<span className="question-ellipsis">
								{defaultOperatorValue?.label
									? defaultOperatorValue.label
									: 'Select'}
							</span>
							<div className="select-arrow"></div>
							{showModal === inputType.key + inputType?.type + 'operator' &&
								countrySelectDropdown}
						</div>
						{inputType?.type === 'selects' ? (
							<div
								onClick={() =>
									hanldeOpenDropdown('operand', inputType.key + inputType?.type)
								}
								className="question-container__select-country"
								ref={
									showModal === inputType.key + inputType?.type + 'operand'
										? ref
										: null
								}
							>
								<span className="question-ellipsis">
									{defaultOperandValue ? defaultOperandValue : 'Select'}
								</span>
								<div className="select-arrow"></div>
								{showModal === inputType.key + inputType?.type + 'operand' &&
									countrySelectDropdown}
							</div>
						) : inputType?.type === 'date' ? (
							<div className="question-container__date-picker">
								<DateCalender
									onChange={(e: ChangeEvent<HTMLInputElement>) =>
										onChangeTextInput(e, inputType?.type)
									}
									formate={'mm/dd/yyyy'}
									value={getDate}
									label=""
									isModal
								/>
							</div>
						) : (
							<InputText
								type={inputType.type}
								value={conditionId['operand'] as any}
								onChangeTextInput={e => onChangeTextInput(e, inputType.type)}
							/>
						)}
					</>
				) : (
					<ReactSwitch
						checked={checked ?? false}
						id={conditionId}
						handleChange={handleChangeSwith}
					/>
				)}
				{questionLen === index + 1 && (
					<>
						<span>then</span>
						<div
							onClick={() =>
								hanldeOpenDropdown('then', inputType.key + inputType?.type)
							}
							className="question-container__select-country"
							ref={
								showModal === inputType.key + inputType?.type + 'then'
									? ref
									: null
							}
						>
							<span className="question-ellipsis">
								{defaultThenObj?.label ? defaultThenObj.label : 'Select'}
							</span>
							<div className="select-arrow"></div>
							{showModal === inputType.key + inputType?.type + 'then' &&
								countrySelectDropdown}
						</div>
						<div className="custom_handle-main">
							<CustomHandle
								value={`${labelId}_${stepId}_${blockId}_${nodeId}`}
								showLeft={false}
								showRight
							/>
						</div>
					</>
				)}
				<div className="question-container__threads__right-container">
					<div className="right" onClick={onRemoveQuestion}>
						<i className="ri-indeterminate-circle-line" />
					</div>
				</div>
			</div>
		</div>
	);
};
