import { Dispatch, FC, PropsWithChildren, SetStateAction, useRef } from 'react';

import Modal from '@storybook/new-modal/modal';

interface IEditWebHookUrlModal {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	name: string;
}

export const EditWebHookUrlModal: FC<
	PropsWithChildren<IEditWebHookUrlModal>
> = ({ isOpen, setIsOpen, children, name }) => {
	const ref = useRef(null);
	return (
		<div ref={ref}>
			<Modal
				isOpen={isOpen}
				modalName={name}
				closeModal={() => setIsOpen(false)}
				className="title-set-modal"
				showCloseBtn={true}
				isStopOutsideClick={true}
			>
				{children}
			</Modal>
		</div>
	);
};
