import { IShareholderThresholdItem } from "./types"

import { ChangeEvent, FC, useCallback, useEffect, useState } from "react"
import { useNotification } from "hooks";

const ShareholderThresholdItem: FC<IShareholderThresholdItem> = ({ title, subTitle, cb, defaultValue }) => {

  const [percentage, setPercentage] = useState(defaultValue);
  const { errorNotification } = useNotification();

  const inputValueChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.valueAsNumber
    if(value > 100) {
      errorNotification('Percentage cannot be greater than 100.');
    } else {
      setPercentage(value);
    }
  }, [errorNotification])

  const onInputUnFocus = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.valueAsNumber
    if(isNaN(value)) {
      errorNotification(`This field cannot be empty. \nSetting default value of ${defaultValue}.`);
      setPercentage(defaultValue);
    }
  }, [errorNotification, defaultValue])



  useEffect(() => {
    cb(percentage);
  }, [percentage, cb])

  return (
    <div className='ShareholderThresholdItem'>
      <div className="ShareholderThresholdItem--titleContainer">
        <h4 className="ShareholderThresholdItem--title">{title}</h4>
        <p className="ShareholderThresholdItem--subTitle">{subTitle}</p>
      </div>
      <div className="ShareholderThresholdItem--actionContainer">
        <button 
          className="ShareholderThresholdItem--button"
          disabled={percentage === 0}
          onClick={() => setPercentage(prev => prev-1)}
        >
          <i className="ri-subtract-line"></i>
        </button>
        <input value={percentage} onBlur={onInputUnFocus} type="number" className="ShareholderThresholdItem--input" onChange={inputValueChange} />
        <button 
          className="ShareholderThresholdItem--button"
          disabled={percentage === 100}
          onClick={() => setPercentage(prev => prev+1)}
        >
           <i className="ri-add-line"></i>
        </button>
      </div>
    </div>
  )
}

export default ShareholderThresholdItem

