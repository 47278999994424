import type { SignerType, ISigner } from '../store';

import { useCallback, useMemo } from 'react';
import { useSetRecoilState } from 'recoil';

import { Image } from '@storybook';
import { SignerTypes, signerList } from '../constant';
import { IsCreateTemplateStepperOpen, SelectedSignerState } from '../store';

/**
 * @Manish Define the SignSelectorScreen component responsible for selecting a signer type
 * */ 
export const SignSelectorScreen = () => {
  /**
   * @Manish Get the function to set the selected signer from Recoil state
   * */ 
  const setSelectedSigner = useSetRecoilState<SignerType>(SelectedSignerState);
	const setIsModal = useSetRecoilState(IsCreateTemplateStepperOpen);
  
  /**
   * @Manish Define a callback function to handle selecting a signer type
   * */ 
  const handleSignerClick = useCallback(
    (label: SignerType, isDisabled: boolean) => {
      /**
	   * @Manish Check if the signer type is not disabled, then set it as the selected signer
	   * */ 
      if (!isDisabled) {
        if (label===SignerTypes.esignOverlay){
          setIsModal(false);
        }
        setSelectedSigner(label);
      }
    },
    [setSelectedSigner, setIsModal]
  );

  /**
   * @Manish Create a list of signers to be rendered, based on the signerList data
   * */ 
  const renderList = useMemo(
    () =>
      signerList.map((item: ISigner) => {
        return (
          <div
            key={item.key}
            className={`signer-select__type__content-wrapper signer-select__type__content-wrapper${
              item.isDisabled ? '--disabled' : ''
            }`}
            onClick={() => handleSignerClick(item.name, item.isDisabled)}
          >
            <div className="signer-select__penIcon">
              {/* @Manish Display the pen icon for the signer */}
              <Image fileName={`${item.penIcon}`} loading="lazy" />
            </div>
            <div className="signer-select__type__label">
              {/* @Manish Display the label and price of the signer */}
              {item?.label}
              <p className="signer-select__price">({item.price})</p>
            </div>
            <i className={`${item.arrIcon} signer-select__arrIcon`} />
          </div>
        );
      }),
    [handleSignerClick]
  );

  /**
   * @Manish Render the SignSelectorScreen component with a list of signers
   * */ 
  return (
    <div className="signer-select__main-wrapper">
      <div className="signer-select__heading-wrapper">
        <div className="CreateAgreementModal-head-title">Choose a Method</div>
        <div className="OnboardingInviteModal-head-desc">
          {/* @Manish Display a description for selecting the signer method */}
          Select the method that you want to use for signing.
        </div>
      </div>
      <div className="signer-select__type-wrapper">{renderList}</div>
    </div>
  );
};