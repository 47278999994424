import { useCallback, useState } from 'react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

import { API_URL, MESSAGE } from 'constant';
import { useNetwork, useNotification } from 'hooks';
import { ConfigureSelectedToggleKey } from './states';
import { AssignSelectedSessionState } from '../../select-fund-modal';

export const useSelectedConfugureSessionRequests = () => {
	const [isLoaded, setLoaded] = useState(true);
	const resetConfigState = useResetRecoilState(ConfigureSelectedToggleKey);

	const setSelectedSession = useSetRecoilState(AssignSelectedSessionState);
	const { patch } = useNetwork();
	const { errorNotification, successNotification } = useNotification();

	const submitConfugureSession = useCallback(
		async (payload: any, sessionId: string, cb?: VoidFunction) => {
			setLoaded(false);
			patch(`${API_URL.SESSION_CONFIGURE}/${sessionId}`, payload)
				.then(res => {
					if (res?.success) {
						successNotification('Successfully Submitted');
						setSelectedSession([]);
						cb?.();
						resetConfigState();
					} else errorNotification(res?.message ?? MESSAGE.ERROR);
				})
				.finally(() => setLoaded(true));
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return { submitConfugureSession, isLoaded };
};
