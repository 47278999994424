import './progress-bar.scss';

/**
 * ProgressBar component
 *
 * @param {string} bgColor - Background color of the progress bar
 * @param {number} completed - Completion percentage
 * @param {string} height - Height of the progress bar
 * @param {string} labelClassName - Class name for the label
 * @param {string} customLabel - Custom label for the progress bar
 * @param {string} customClass - Custom class for the progress bar container
 */

export const ProgressBar = (props: any) => {
	const {
		bgColor,
		completed,
		height,
		labelClassName,
		customLabel,
		customClass,
	} = props;

	const containerStyles = {
		height: height,
		width: '100%',
		backgroundColor: '#e0e0de',
		borderRadius: 50,
	};

	const fillerStyles = {
		height: '100%',
		width: `${completed}%`,
		backgroundColor: bgColor,
		borderRadius: 'inherit',
		textAlign: 'right',
	};

	return (
		<div className={customClass ? customClass : 'progress-bar'}>
			<div style={containerStyles}>
				<div style={fillerStyles as any}></div>
			</div>
			<span className={labelClassName}>{customLabel}</span>
		</div>
	);
};
