import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { IframePropsState, IsOpenInIframeState } from 'global-stores';
import { toast } from 'react-toastify';

export const useNotification = () => {
	const { notification } = useRecoilValue(IframePropsState);
	const isIframeOpen = useRecoilValue(IsOpenInIframeState);

	const successNotification = useCallback(
		(msg: any) => {
			if (isIframeOpen && !notification) {
				return;
			}
			toast.success(msg)
		},
		[isIframeOpen, notification]
	);

	const infoNotification = useCallback(
		(msg: any) => {
			if (isIframeOpen && !notification) {
				return;
			}
			toast.info(msg)
		},
		[isIframeOpen, notification]
	);

	const errorNotification = useCallback(
		(msg: any) => {
			if (isIframeOpen && !notification) {
				return;
			}
			toast.error(msg)

		},
		[isIframeOpen, notification]
	);

	const warningNotification = useCallback(
		(msg: any) => {
			if (isIframeOpen && !notification) {
				return;
			}
			toast.warning(msg)
		},
		[isIframeOpen, notification]
	);

	return {
		successNotification,
		infoNotification,
		errorNotification,
		warningNotification,
	};
};
