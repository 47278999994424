export const BussinessStatus: Record<string, string> = {
	APPROVED: 'Verified',
};

export const BussinessDetailsStatus: Record<string, string> = {
	completed: 'Approved',
	rejected: 'Rejected',
	pending: 'Pending',
	processing: 'Under Review',
	REJECTED: 'Rejected',
	APPROVED: 'Approved',
	UNDER_REVIEW: 'Under Review',
};
