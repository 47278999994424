import { FC } from "react"

interface IProps {
    label: string
}

export const ComprehensiveHeader: FC<IProps> = ({ label }) => (
    <div className="kyb-wrapper__details__kybInfo-header">
        <div className="kyb-wrapper__details__kybInfo-header__title">
            {label}
        </div>
        <div className="kyb-wrapper__details__kybInfo-header__sub-title" />
    </div>
)