import classNames from 'classnames';
import { FC, useMemo } from 'react';

import { Image, Skeleton } from '@storybook';
import { CardKey, cardIcons, ids } from './constants';
import { NumberWithCommas } from 'utils';

import './dashboard-card.scss';

interface IDashboardCard {
	cardKey: string;
	loading: boolean;
	cardData: {
		id: string;
		label: string;
		value: number;
	}[];
}

export const DashboardCard: FC<IDashboardCard> = ({
	cardKey = '',
	cardData,
	loading,
}) => {
	const { ONBOARDING, SESSION } = CardKey ?? {};

	const iconImage = useMemo(() => cardIcons[cardKey], [cardKey]);

	const { TOTAL, OPENED, COMPLETED, BOUNCED, FAILED, PROCESSING, PENDING } =
		ids;

	const totalData = useMemo(() => {
		return cardData.find(item => item.id === TOTAL);
	}, [TOTAL, cardData]);

	const completedData = useMemo(() => {
		return cardData.find(item => item.id === OPENED || item.id === COMPLETED);
	}, [COMPLETED, OPENED, cardData]);

	const failedData = useMemo(() => {
		return cardData.find(item => item.id === BOUNCED || item.id === FAILED);
	}, [BOUNCED, FAILED, cardData]);

	const processingData = useMemo(() => {
		return cardData.find(item => item.id === PROCESSING);
	}, [PROCESSING, cardData]);

	const pendingData = useMemo(() => {
		return cardData.find(item => item.id === PENDING);
	}, [PENDING, cardData]);

	const bgCard = classNames('hover', {
		'card-wrapper-session': cardKey === SESSION,
		'card-wrapper': cardKey !== SESSION,
	});

	const renderCard = useMemo(() => {
		if (cardKey && cardData) {
			if (cardKey !== ONBOARDING) {
				return (
					<div className={bgCard}>
						<div className="card-main">
							<div className="card-main__card-icon">
								<Image fileName={iconImage ?? 'session-icon.svg'} />
							</div>
							<div className="card-main__data">
								<div className="card-main__data__value">
									{loading ? (
										<Skeleton />
									) : (
										NumberWithCommas(totalData?.value ?? 0, loading)
									)}
								</div>
								<div className="card-main__data__label">
									{totalData?.label ?? `Total ${cardKey.toLowerCase()}`}
								</div>
							</div>
						</div>
						<div className="card-secondary card-secondary__completed">
							<div className="card-secondary__value">
								{loading ? (
									<Skeleton />
								) : (
									NumberWithCommas(completedData?.value ?? 0, loading)
								)}
							</div>
							<div className="card-secondary__label">
								{completedData?.label ?? `Completed ${cardKey.toLowerCase()}`}
							</div>
						</div>
						<div className="card-secondary card-secondary__failed">
							<div className="card-secondary__value">
								{loading ? (
									<Skeleton />
								) : (
									NumberWithCommas(failedData?.value ?? 0, loading)
								)}
							</div>
							<div className="card-secondary__label">
								{failedData?.label ?? `Failed ${cardKey.toLowerCase()}`}
							</div>
						</div>
						{cardKey === SESSION && (
							<>
								<div className="card-secondary card-secondary__processing">
									<div className="card-secondary__value">
										{loading ? (
											<Skeleton />
										) : (
											NumberWithCommas(processingData?.value ?? 0, loading)
										)}
									</div>
									<div className="card-secondary__label">
										{processingData?.label ??
											`Processing ${cardKey.toLowerCase()}`}
									</div>
								</div>
								<div className="card-secondary card-secondary__pending">
									<div className="card-secondary__value">
										{loading ? (
											<Skeleton />
										) : (
											NumberWithCommas(pendingData?.value ?? 0, loading)
										)}
									</div>
									<div className="card-secondary__label">
										{pendingData?.label ?? `Pending ${cardKey.toLowerCase()}`}
									</div>
								</div>
							</>
						)}
					</div>
				);
			} else if (cardKey === ONBOARDING) {
				return (
					<div className="card-wrapper-onboarding hover">
						<i className="ri-flow-chart card-icon-onboarding" />
						<div className="card-data-onboarding">
							<div className="card-data-onboarding__value">
								{loading ? <Skeleton /> : totalData?.value ?? '0'}
							</div>
							<div className="card-data-onboarding__label">
								{totalData?.label ?? 'Onboarding Flows'}
							</div>
						</div>
					</div>
				);
			}
		}
		return <></>;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		bgCard,
		cardData,
		cardKey,
		completedData?.label,
		completedData?.value,
		failedData?.label,
		failedData?.value,
		iconImage,
		pendingData?.label,
		pendingData?.value,
		processingData?.label,
		processingData?.value,
		totalData?.label,
		totalData?.value,
		loading,
	]);

	return renderCard;
};
