import type {
	ICSVHeader,
	IFundRecipient,
	ISelectedFundRecipientBankAccount,
	IConfigFieldVisible,
} from './type';

import { atom } from 'recoil';

import { csvHeaders } from '../../views/invite-investor/constants';

export const ActiveComponentType = atom<JSX.Element | null>({
	key: 'invite-investor-component-key',
	default: null,
});

export const OpenInviteInvestorModal = atom({
	key: 'invite-investor-open-modal-key',
	default: false,
});

export const OpenInviteSelectorModal = atom<boolean>({
	key: 'open-invite-selector-modal',
	default: false,
});

export const CSVDataState = atom<any>({
	key: 'csv-data-key',
	default: [],
});

export const UploadedCSVState = atom<any[]>({
	key: 'uploaded-csv-key',
	default: [],
});

export const CsvTableAddColumn = atom<ICSVHeader[]>({
	key: 'csv-column-key',
	default: csvHeaders.HEADER,
});

export const PipelineIdState = atom<string>({
	key: 'pipeline-id-investor-key',
	default: '',
});

export const InvestorTableGridData = atom<any>({
	key: 'cap-grid-table',
	default: [
		[
			{ value: 'Investor First Name', readOnly: true },
			{ value: 'Investor Last Name', readOnly: true },
			{ value: 'Investor Entity', readOnly: true },
			{ value: 'Investor Address', readOnly: true },
			{ value: 'Investor Country Code', readOnly: true },
			{ value: 'Investor Mobile', readOnly: true },
			{ value: 'Investors Email', readOnly: true },
			{ value: 'Number of Shares', readOnly: true },
			{ value: 'Price per Share', readOnly: true },
			{ value: 'Dollars Invested', readOnly: true },
			{ value: 'Share Class', readOnly: true },
			{ value: 'Issue Date', readOnly: true },
		],
		[
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
		],
		[
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
		],
		[
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
		],
		[
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
		],
		[
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
		],
	],
});

export const InvestorTableGridDataHeader = atom({
	key: 'cap-grid-table-dublicate',
	default: [
		[
			{ value: 'Investor First Name', readOnly: true },
			{ value: 'Investor Last Name', readOnly: true },
			{ value: 'Investor Entity', readOnly: true },
			{ value: 'Investor Address', readOnly: true },
			{ value: 'Investor Country Code', readOnly: true },
			{ value: 'Investor Mobile', readOnly: true },
			{ value: 'Investors Email', readOnly: true },
			{ value: 'Number of Shares', readOnly: true },
			{ value: 'Price per Share', readOnly: true },
			{ value: 'Dollars Invested', readOnly: true },
			{ value: 'Share Class', readOnly: true },
			{ value: 'Issue Date', readOnly: true },
		],
		[
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
		],
		[
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
		],
		[
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
		],
		[
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
		],
		[
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
		],
	],
});
export const GridCAPTableData = atom<any>({
	key: 'invite-table-grid',
	default: {},
});

export const SelectedInviteFundState = atom<{ label: string; value: string }>({
	key: 'selected-invite-fund-state',
	default: {
		label: '',
		value: '',
	},
});

export const SelectedFundType = atom<'create' | 'edit'>({
	key: 'selected-fund-type',
	default: 'create',
});

export const currentCapTableData = atom<any>({
	key: 'current-cap-table',
	default: [
		[
			{ value: 'Investor First Name', readOnly: true },
			{ value: 'Investor Last Name', readOnly: true },
			{ value: 'Investor Entity', readOnly: true },
			{ value: 'Investor Address', readOnly: true },
			{ value: 'Investor Country Code', readOnly: true },
			{ value: 'Investor Mobile', readOnly: true },
			{ value: 'Investors Email', readOnly: true },
			{ value: 'Number of Shares', readOnly: true },
			{ value: 'Price per Share', readOnly: true },
			{ value: 'Dollars Invested', readOnly: true },
			{ value: 'Share Class', readOnly: true },
			{ value: 'Issue Date', readOnly: true },
		],
		[
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
		],
		[
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
		],
		[
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
		],
		[
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
		],
		[
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
			{ value: '' },
		],
	],
});

export type IInviteNavigateModalSteps =
	| 'create'
	| 'csv'
	| 'signOptions'
	| 'signAggrement'
	| 'connectBank'
	| 'configCaptable'
	| 'billing'
	| 'selectBankFLow'
	| 'personalForm'
	| 'businessForm'
	| 'accountProcessing'
	| 'escrowSuccess'
	| 'reminderNotification'
	| 'configComplex'
	| 'proofReading';

export const InviteNavigateModalState = atom<IInviteNavigateModalSteps>({
	key: 'invite-navigate-modal-state',
	default: 'create',
});

export const IsCreatingFundState = atom<boolean>({
	key: 'is-creating-fund-state',
	default: false,
});

export const IsCapTableLoading = atom<boolean>({
	key: 'is-capTable-loading',
	default: false,
});

export const SelectedPipelineDetails = atom<any>({
	key: 'selected-pipeline-details',
	default: {},
});

export const SelectedComplexPipelineNode = atom<any>({
	key: 'selected-complex-pipeline-node-state-key',
	default: {},
});

export const FundRecipientBankAccount = atom<IFundRecipient[] | any>({
	key: 'fund-recipient-bank-account',
	default: [],
});

export const ShowSelectedField = atom<string[]>({
	key: 'show-selected-field',
	default: [],
});

export const SelectedFundRecipientBankAccount = atom<
	ISelectedFundRecipientBankAccount | undefined
>({
	key: 'selected-fund-recipient-bank-account',
	default: undefined,
});

export const ComplexSelectedFundRecipientBankAccount = atom<
	ISelectedFundRecipientBankAccount[] | undefined
>({
	key: 'complex-selected-fund-recipient-bank-account',
	default: [],
});

export const RecipientBankAccountLoadingState = atom<boolean>({
	key: 'recipient-bank-account-loading',
	default: false,
});

export const ConfigFieldVisibleState = atom<IConfigFieldVisible>({
	key: 'config-field-visible-state',
	default: {
		'Dollars Invested': false,
		Email: false,
		'Country Code': false,
		Phone: false,
	},
});

export const SelectedBankFlowState = atom<string>({
	key: 'select-bank-flow-state',
	default: '',
});

export const IsOnboardingDeleteState = atom<boolean>({
	key: 'onboarding-delete-state',
	default: false,
});

export const SelectedComplexDesign = atom<any>({
	key: 'selected-complex-design',
	default: {},
});
export const whiteLableLogoState = atom<boolean>({
	key: 'white-lable-logo-state-loading',
	default: false,
});
