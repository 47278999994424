import { FC } from "react"
import { KYBEventsCard } from "../../kyb-enchanced-event-card"

interface IAnnouncement {
    title: string
    date: string
    description?: string
    metadata : {
        metaDetail : {
            type : string
            categories: string
        }
    }
}

interface IProps {
    items: IAnnouncement[]
}

export const Announcements: FC<IProps> = ({ items }) => {
    if(!items) {
        return <></>
    }

    return (
        <>
            {(items ?? []).map(announcement => {
                const { date, title, description , metadata } = announcement ?? {};
                return (
                    <KYBEventsCard 
                        key={`${title}-${date}-${description}`}
                        date={date}
                        description={description}
                        heading={title}
                        metaData={metadata?.metaDetail}
                    />
                )
            })}
        </>
    )
}