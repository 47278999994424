import { FC } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { PaymentFrom } from '.';
import { activeTabs } from 'constant';
import { ModelHeader } from './model-header';
import { REACT_APP_STRIPE_SECRET } from 'envs';

// when you toggle to live mode, you should add the live publishale key.
const stripePromise = loadStripe(REACT_APP_STRIPE_SECRET);

interface IPayment {
	from?: 'HOME' | 'SIGNUP';
}

export const Payment: FC<IPayment> = ({ from = 'SIGNUP' }) => {
	const { PAYMENT } = activeTabs;

	return (
		<>
			{from === 'SIGNUP' && <ModelHeader active={PAYMENT} />}
			<div className="App payment-app">
				<Elements stripe={stripePromise}>
					<PaymentFrom from={from} />
				</Elements>
			</div>
		</>
	);
};
