import { useCallback, useEffect, useMemo } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
	IframePropsState,
	IsOpenInIframeState,
	PipelineSettingsFormState,
	isChecqueFraudSelected,
} from 'global-stores';
import { ROUTES } from 'routes';
import {
	ComplexOnboardingFlow,
	useComplexOnboarding,
} from 'views/complex-onboarding-flow';
import { PERMISSION_SCREEN, useUserRoles } from 'views/routes-children';
import { useGeneralSettings } from 'views/settings';
import { DeliveryMethod } from './components/delivery-method';
import { FooterNavigate } from './components/footer-navigate';
import { OnboardingHeader } from './components/header';
import { OnboardingLeftNav } from './components/left-nav';
import { Publish } from './components/publish';
import './onboarding-flow.scss';
import {
	AddedActionsState,
	OnboardingFooterNavigateState,
	SelectedStepsState,
	SettingFormInitState,
} from './store';
import classNames from 'classnames';

const OnboardingFlow = () => {
	// global states
	const navigate = useRecoilValue(OnboardingFooterNavigateState);
	const selectedSteps = useRecoilValue(SelectedStepsState);
	const [addedActions, setAddedActions] = useRecoilState(AddedActionsState);
	const { background } = useRecoilValue(IframePropsState);
	const isIframeOpen = useRecoilValue(IsOpenInIframeState);
	const [settingsForm, setSettingsForm] = useRecoilState(
		PipelineSettingsFormState
	);
	const settingFormInit = useRecoilValue(SettingFormInitState);
	const isChequeFraud = useRecoilValue(isChecqueFraudSelected);
	// hooks
	const { getClientDetails } = useGeneralSettings();

	const { checkUserWritePermission } = useUserRoles();
	const navigator = useNavigate();

	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.Onboarding),
		[checkUserWritePermission]
	);

	useEffect(() => {
		if (!isUserPermissionWrite) navigator(ROUTES.PIPELINE);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isUserPermissionWrite]);

	//use effect
	useEffect(() => {
		getClientDetails();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		/*@avinash: as this is not required for modify or clone
		 */
		if (!settingsForm.isModifyOrClone) {
			setSettingsForm(settingFormInit);
		}

		//@avinash: removing isModifyOrClone key from settting form object when component will be unmont
		return () => {
			setSettingsForm(prev => {
				const newObj = structuredClone(prev);
				delete newObj.isModifyOrClone;
				return newObj;
			});
		};
		// eslint-disable-next-line
	}, []);

	// local variables
	const globalActions = useMemo(() => {
		const stepActions: any[] = [];
		selectedSteps.forEach(({ actions }) => {
			stepActions.push(...actions);
		});
		return stepActions;
	}, [selectedSteps]);

	const leftNavActions = useMemo(() => {
		if (navigate === 'complex-flow') {
			return globalActions;
		}
		return globalActions.filter(
			action =>
				!addedActions.find(addedAction => addedAction._id === action._id)
		);
	}, [addedActions, globalActions, navigate]);

	// lagacy code do not change
	const onDragEnd = useCallback(
		(result: DropResult) => {
			/* 
			  @avinashSatschel
              If the 'isChequeFraud' flag is set to true, prevent further execution of the code block.
              This condition ensures that additional actions are not added when Cheque Fraud is enabled,
              as Cheque Fraud handling may require a specific flow without additional steps.
             */
			if (isChequeFraud) {
				// Exit the function to prevent further processing when Cheque Fraud is enabled.
				return;
			}
			// destination and source extracted here
			const { draggableId: actionKey } = result;
			const found = leftNavActions.find(item => item.key === actionKey);
			if (found) {
				setAddedActions(prev => {
					const prevObj = JSON.parse(JSON.stringify(prev));
					prevObj.push({ ...found, nodeId: (result as any).nodeId });
					return prevObj;
				});
			}
			return;
		},
		[isChequeFraud, leftNavActions, setAddedActions]
	);

	// this will render different views / screens in creating new onboarding
	const renderView = useCallback(() => {
		switch (navigate) {
			case 'complex-flow':
				return <ComplexOnboardingFlow handleOnDrop={onDragEnd} />;
			case 'deliveryMethod':
				return <DeliveryMethod />;
			case 'publish':
				return <Publish />;
			default:
				return <ComplexOnboardingFlow handleOnDrop={onDragEnd} />;
		}
	}, [navigate, onDragEnd]);

	const getHeader = useMemo(() => <OnboardingHeader />, []);

	const { onDragStart } = useComplexOnboarding();

	const onboardingleftFlowClass = classNames('OnboardingFlow--left', {
		'OnboardingFlow--left--config':
			navigate !== 'selectSteps' && navigate !== 'complex-flow',
	});

	const renderComponent = useMemo(() => {
		return (
			<div
				className="OnboardingFlow--container"
				style={{ backgroundColor: background }}
			>
				{getHeader}

				<div
					className="OnboardingFlow--contain"
					style={{
						...(isIframeOpen && {
							marginTop: 0,
							height: '100%',
						}),
					}}
				>
					<div className={onboardingleftFlowClass}>
						<OnboardingLeftNav navigate={navigate} onDragStart={onDragStart} />
					</div>

					<div
						className="OnboardingFlow--right"
						style={{ width: 'calc(100% - 300px)' }}
					>
						{renderView()}
						<FooterNavigate />
					</div>
				</div>
			</div>
		);
	}, [navigate, background, getHeader, isIframeOpen, onboardingleftFlowClass, onDragStart, renderView]);
	return (
		<DragDropContext onDragEnd={onDragEnd}>{renderComponent}</DragDropContext>
	);
};

export default OnboardingFlow;
