export const formateDate = (date: string) => {
	return new Date(date).toLocaleDateString();
};

export const dateConverter = (element: any) => {
	const date = new Date(element),
		month = ('0' + (date.getMonth() + 1)).slice(-2),
		day = ('0' + date.getDate()).slice(-2);
	return [date.getFullYear(), month, day].join('-');
};

export const getMinutesTime = (time: number | string) => {
	const date = new Date(1000 * Math.round(Number(time) / 1000));
	const pad = (i: number) => {
		return ('0' + (i || '0')).slice(-2);
	};
	const str = pad(date.getUTCMinutes()) + ':' + pad(date.getUTCSeconds());
	return str;
};

export const getHoursMinutesSeconds = (milliseconds: number) => {
	const hours = Math.floor(milliseconds / 3600000);
	const minutes = Math.floor((milliseconds % 3600000) / 60000);
	const seconds = Math.floor(((milliseconds % 360000) % 60000) / 1000);
	if (hours) {
		return `${hours}${
			hours === 1 ? 'hour' : 'hours'
		} ${minutes}min ${seconds}sec`;
	} else if (minutes) {
		return `${minutes}min ${seconds}sec`;
	} else {
		return !isNaN(seconds) ? `${seconds}sec` : '--';
	}
};

export const getMonthDayYear = (date: string) => {
	const formateDate = new Date(date).toLocaleDateString('en-US', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	});
	return formateDate;
};

export const getDay = (date: string) => {
	const day = new Date(date);
	return day.getDate();
};

// function to get full month like : January
export const getMonthName = (dateString:string) => {
    const date = new Date(dateString);
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    return monthNames[date.getMonth()];
};


// function to get date in the formate of ( October 24, 2023 ) 
export const formatMonthDateYear = (dateString:string) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: 'numeric' }).format(date);
};

export const getFormattedMonthDayYear = (date: string) => {
    // Check if the date is in 'DD-MM-YYYY' format
    let formattedDate = date;
    if (date.includes('-') && date.split('-').length === 3) {
        const [day, month, year] = date.split('-');
        
        // If it's in DD-MM-YYYY, convert it to MM/DD/YYYY format
        if (day?.length === 2 && month?.length === 2 && year?.length === 4) {
            formattedDate = `${month}/${day}/${year}`;
        }
    }

    // Parse the date using the newly formatted string
    const parsedDate = new Date(formattedDate);
    if (isNaN(parsedDate.getTime())) {
        return 'Invalid Date';  // Return a fallback for invalid dates
    }

    // Return the date in MM/DD/YYYY format
    const formateDate = parsedDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });
    return formateDate;
};
