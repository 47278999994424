import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { PaymentCardMethodsState } from 'global-stores';

export const useDefaultCard = () => {
	const paymentCardMethodsState = useRecoilValue(PaymentCardMethodsState);

	const isDefaultExist = useMemo(
		() => Object.values(paymentCardMethodsState?.default ?? {}).every(el => el),
		[paymentCardMethodsState?.default]
	);

	const defaultMethod = useMemo(() => {
		const data = paymentCardMethodsState?.default;
		return data;
	}, [paymentCardMethodsState?.default]);
	return {
		isDefaultExist,
		defaultMethod,
	};
};
