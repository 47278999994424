import { FC, useCallback, useMemo, useState } from 'react';

import { useSetRecoilState } from 'recoil';
import { ShowSelectedField } from 'global-stores';
import { ConfigRow } from './components';
import './config-captable.scss';

interface IOptions {
	label: string;
	value: string;
}
interface ICapTableHeader {
	options: IOptions[];
	label: string;
	isOptional: boolean;
	isRequired: boolean;
	inputType: string;
}
interface IProps {
	capTableHeader: ICapTableHeader[];
}

export const ConfigCaptable: FC<IProps> = ({ capTableHeader }) => {
	const [isReset, setIsReset] = useState(false);
	const setShowSeletedValue = useSetRecoilState(ShowSelectedField);

	const handleOnClick = useCallback(() => {
		setIsReset(true);
	}, []);

	const renderCapTableConfiguration = useMemo(() => {
		return (
			<>
				<div className="reset">
					<div className="reset-btn" onClick={handleOnClick}>
						Reset
					</div>
				</div>
				{capTableHeader?.map(({ isRequired, label, options, isOptional }) => (
					<ConfigRow
						key={label}
						isRequired={isRequired}
						label={label}
						options={options}
						isOptional={isOptional}
						isReset={isReset}
						setIsReset={setIsReset}
						setShowSeletedValue={setShowSeletedValue}
					/>
				))}
			</>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [capTableHeader, handleOnClick, isReset]);

	return <div className="captable-config">{renderCapTableConfiguration}</div>;
};
