import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { Button, Loader } from '@storybook';
import { AddCardModalState } from '../../stores';
import { AddPaymentCard } from '../addPaymentCard';
import { PaymentNavigation, paymentCardTypes } from '../constant';
import {
	ACH,
	BillingInfoForm,
	CardView,
	ChooseAchDefault,
	PaymentCardProcessing,
	PaymentChoose,
	PaymentSuccessPage,
	UpdateAddressSuccess,
	WireScreen,
} from './components';
import { useChoose, usePayments } from './hooks';
import classNames from 'classnames';

export const AddPaymentMethod = () => {
	const addCardModalState = useRecoilValue(AddCardModalState);
	const {
		handleNextBtn,
		addPaymentNaviagtion,
		handleBackBtn,
		disabledNextBtn,
		hideFooterBtn,
		shouldShowSideBar,
		isLoading,
		hideBackBtn,
	} = usePayments();
	const { handleChooseNavigate } = useChoose();

	const ActivePaymentMethodCard = useCallback(
		(type: string) => {
			return classNames('upgrade_select_cards', {
				upgrade_select_cards__wire: type === 'WireView',
				upgrade_select_cards_active: type === addPaymentNaviagtion,
			});
		},
		[addPaymentNaviagtion]
	);

	const renderComponent = useMemo(() => {
		switch (addPaymentNaviagtion) {
			case PaymentNavigation.CardView:
				return <CardView />;
			case PaymentNavigation.AchView:
				return <ACH />;
			case PaymentNavigation.ChoosePage:
				return <PaymentChoose />;
			case PaymentNavigation.Success:
				return <PaymentSuccessPage />;
			case PaymentNavigation.WireScreen:
				return <WireScreen />;
			case PaymentNavigation.WireView:
				return <WireScreen isViewPage />;
			case PaymentNavigation.CardScreen:
				return <AddPaymentCard />;
			case PaymentNavigation.Billing:
				return <BillingInfoForm />;
			case PaymentNavigation.Processing:
				return <PaymentCardProcessing />;
			case PaymentNavigation.UpdateAddressSuccess:
				return <UpdateAddressSuccess />;
			case PaymentNavigation.DefaultAchPage:
				return <ChooseAchDefault />;
			default:
				return;
		}
	}, [addPaymentNaviagtion]);

	const renderSideNavigation = useMemo(
		() => (
			<div className="upgrade_select">
				{paymentCardTypes.map(type => {
					return (
						<div
							key={type.key}
							onClick={handleChooseNavigate(type.key)}
							className={ActivePaymentMethodCard(type.key)}
						>
							{type.label}
						</div>
					);
				})}
			</div>
		),
		[ActivePaymentMethodCard, handleChooseNavigate]
	);

	const isEditOrBillng = useMemo(
		() =>
			!addCardModalState.isEdit ||
			addPaymentNaviagtion === PaymentNavigation.Billing ||
			addPaymentNaviagtion === PaymentNavigation.DefaultAchPage,
		[addCardModalState.isEdit, addPaymentNaviagtion]
	);

	const renderLabel = useMemo(() => {
		switch (addPaymentNaviagtion) {
			case PaymentNavigation.DefaultAchPage:
				return 'Add Account';
			default:
				return 'Next';
		}
	}, [addPaymentNaviagtion]);

	return (
		<div className={`upgrade ${isEditOrBillng ? 'upgrade--edit' : ''}`}>
			{shouldShowSideBar && renderSideNavigation}
			{renderComponent}
			{!hideFooterBtn && (
				<div className="payment-method_footer">
					{!hideBackBtn && (
						<Button
							label="Back"
							type="button__filled--secondary"
							handleClick={handleBackBtn}
						/>
					)}
					<Button
						label={renderLabel}
						type="button__filled--primary"
						handleClick={handleNextBtn}
						disabled={disabledNextBtn}
						loader={isLoading ? <Loader type="loader" dimension={20} /> : <></>}
					/>
				</div>
			)}
		</div>
	);
};
