import { FC, useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { toast } from 'react-toastify';

import { Button, Loader } from '@storybook';
import { API_URL } from 'constant';
import { useNetwork, useNotification } from 'hooks';
import { SelectedSessionsIdsForZipState, checkBoxStateInTable } from 'global-stores';
import { AssignSelectedSessionState } from '../select-fund-modal';
import { message } from 'constant/message';

import './export-pdf.scss';

interface IExportPdf {
	sessionIDs: any[];
	type: string;
	label: string;
}

export const ExportPdf: FC<IExportPdf> = ({ sessionIDs, type, label }) => {
	// globle states
	const setSelectedSession = useSetRecoilState(AssignSelectedSessionState);
	const setCheckBoxState = useSetRecoilState(checkBoxStateInTable);
	const setSelectedIdsForZip = useSetRecoilState(
		SelectedSessionsIdsForZipState
	);

	// hokks
	const { post: updateSessionIds, loading } = useNetwork();
	const { errorNotification, successNotification } = useNotification();
	const { API_RESPONSE_NOTIFICATION, DOWNLOAD_ZIP_MESSAGE } = message;

	const handleOnClick = useCallback(async () => {
		if (sessionIDs?.length === 0 && type === 'zip') {
			errorNotification('Please select atleast 1 session to download zip');
		} else {
			// pradeep chaurasia : added for zip case only
			if (type === 'zip') {
				const response = await updateSessionIds(API_URL.SESSION_REPORTS, {
					sessionIds: sessionIDs,
					fileType: type,
				});
				const { success, message } = response ?? {};
				if (success) {
					successNotification(message ?? DOWNLOAD_ZIP_MESSAGE);
				} else {
					errorNotification(message ?? 'Something went wrong.');
				}
				setSelectedSession([]);
				setSelectedIdsForZip([]);
				setCheckBoxState(false);
			} else {
				// Rahul Singh: added response notification on download button in session as it is taking time 30sec
				await toast
					.promise(
						updateSessionIds(API_URL.SESSION_REPORTS, {
							sessionIds: sessionIDs,
							fileType: type,
						}),
						{
							pending: API_RESPONSE_NOTIFICATION,
						}
					)
					.then(res => {
						if (type === 'pdf' && res?.url.length > 0) {
							res.url.map((url: any) => window.open(url, '_self'));
							setSelectedSession([]);
							setCheckBoxState(false);
						}
					});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sessionIDs, type, updateSessionIds]);

	return (
		<Button
			handleClick={handleOnClick}
			label={
				loading ? (
					<div className="download-zip__label-wrapper">
						<Loader type="loader" className="loader-white" dimension={20} />
					</div>
				) : (
					<div className="download-zip__label-wrapper">{label}</div>
				)
			}
			type="button button__filled--primary download-zip"
			disabled={loading}
		/>
	);
};
