import type { IEnvStatus } from 'views/signed-doc-inbox';

import { Button, Loader } from '@storybook';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { API_URL } from 'constant';
import { useNetwork } from 'hooks';
import { documentType } from './constants';
import './multidoc-download-modal.scss';

interface IDownloadModal {
	visible: boolean;
	envelopeId: string;
	handleClose: () => void;
	fileCount: number;
	inboxStatus: IEnvStatus;
}

interface Data {
	url: string[] | string;
}
interface IDownloadRes {
	message: string;
	data: Data;
}

interface IDocumentProperty {
	id: string;
	label: string;
	fileCount: number;
}

interface ITarget {
	id: string;
	checked: boolean;
}

interface IEvent {
	target: ITarget;
}

export const MultidocDownloadModal: FC<IDownloadModal> = ({
	visible,
	envelopeId,
	handleClose,
	fileCount,
	inboxStatus,
}) => {
	// local states
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectAllFile, setSelectAllFile] = useState<boolean>(false);
	const [selectDoc, setSelectDoc] = useState<boolean>(false);
	const [selectCertificate, setSelectCertificate] = useState<boolean>(false);
	const [selectCombinePdf, setCombinePdf] = useState<boolean>(false);

	// hooks
	const { get } = useNetwork();

	// constants
	const checkboxData: Array<IDocumentProperty> = [
		{
			id: documentType.allFile.id,
			label: documentType.allFile.label,
			fileCount: fileCount + 1,
		},
		{
			id: documentType.document.id,
			label: documentType.document.label,
			fileCount: fileCount,
		},
		{
			id: documentType.certificate.id,
			label: documentType.certificate.label,
			fileCount: 1,
		},
	];

	const voidedCheckBoxData: Array<IDocumentProperty> = [
		{
			id: documentType.voidedDocuments.id,
			label: documentType.voidedDocuments.label,
			fileCount: fileCount,
		},
	];

	const resetState = useCallback(() => {
		setSelectAllFile(false);
		setSelectDoc(false);
		setSelectCertificate(false);
		setCombinePdf(false);
	}, []);

	useEffect(() => {
		if (!visible) {
			resetState();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [visible]);

	const renderLabel = useMemo(() => {
		if (isLoading) {
			return <Loader type="loader" dimension={20} className="loader-white" />;
		}
		return 'Download';
	}, [isLoading]);

	useEffect(() => {
		if (selectDoc && selectCertificate) {
			setSelectAllFile(true);
		}
	}, [selectDoc, selectCertificate, selectCombinePdf]);

	const handleCheckBoxChange = useCallback(
		(event: IEvent) => {
			const { id, checked } = event.target;
			if (id === documentType.allFile.id) {
				setSelectAllFile(checked);
				setSelectDoc(checked);
				setSelectCertificate(checked);
			} else {
				setSelectAllFile(false);

				if (id === documentType.document.id) {
					setSelectDoc(checked);
				} else if (id === documentType.certificate.id) {
					setSelectCertificate(checked);
				} else if (id === documentType.combinePdf.id) {
					setCombinePdf(checked);
					setSelectDoc(false);
					setSelectCertificate(false);
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const isDisable = useMemo(() => {
		if (selectAllFile || selectDoc || selectCertificate || selectCombinePdf) {
			return false;
		} else {
			return true;
		}
	}, [selectAllFile, selectDoc, selectCertificate, selectCombinePdf]);

	const handleDownload = useCallback(async () => {
		const queryString = {
			combinePdf: 'type=certificate&type=document&combine=true',
			combineVoidedPdf: 'type=document&combine=true',
			certificate: 'type=certificate&combine=false',
			document: 'type=document&combine=false',
			allFiles: 'type=certificate&type=document&combine=false',
		};

		const getQueryString =
			(selectCombinePdf &&
				inboxStatus === 'voided' &&
				queryString.combineVoidedPdf) ||
			(selectCombinePdf &&
				inboxStatus !== 'voided' &&
				queryString.combinePdf) ||
			(selectAllFile && queryString.allFiles) ||
			(selectCertificate && queryString.certificate) ||
			(selectDoc && queryString.document) ||
			'';
		if (!isLoading) {
			setIsLoading(true);
			const resp = await get(
				`${API_URL.ENVELOPE}/${envelopeId}?${getQueryString}`
			);
			if (resp?.message === 'ok') {
				const { data }: IDownloadRes = resp;
				setIsLoading(false);
				handleClose();
				if (Array.isArray(data?.url)) {
					data?.url?.map((file: string) => {
						window.open(file, '_blank');
					});
					return;
				} else {
					window.open(data?.url, '_blank');
					return;
				}
			}
			setIsLoading(false);
		}
	}, [
		selectCombinePdf,
		inboxStatus,
		selectAllFile,
		selectCertificate,
		selectDoc,
		isLoading,
		get,
		envelopeId,
		handleClose,
	]);

	if (!visible) {
		return null;
	}

	return (
		<div className="download-modal__wrapper">
			<div className="download-modal__container">
				<div className="download-modal__header">
					<div>Choose option that best suits your needs:</div>
					<div>
						<i
							className="download-modal__closeicon ri-close-line"
							onClick={handleClose}
						/>
					</div>
				</div>
				<div className="download-modal__content">
					<div className="download-modal__content_box">
						{(inboxStatus === 'voided' ? voidedCheckBoxData : checkboxData).map(
							checkboxItem => (
								<div
									key={checkboxItem.id}
									className={`download-modal__${
										!selectCombinePdf ? 'checkbox_wrapper' : 'checkbox_disable'
									}`}
								>
									<input
										type="checkbox"
										id={checkboxItem.id}
										checked={
											checkboxItem.id === documentType.allFile.id
												? selectAllFile
												: checkboxItem.id === documentType.document.id
												? selectDoc
												: selectCertificate
										}
										onChange={handleCheckBoxChange}
										disabled={selectCombinePdf}
										className="download-modal__checkbox_wrapper__checkbox"
									/>
									<div
										className="download-modal__checkbox_container"
										onClick={() => {
											if (!selectCombinePdf) {
												const event: IEvent = {
													target: {
														id: checkboxItem.id,
														checked:
															checkboxItem.id === documentType.allFile.id
																? !selectAllFile
																: checkboxItem.id === documentType.document.id
																? !selectDoc
																: !selectCertificate,
													},
												};
												handleCheckBoxChange(event);
											}
										}}
									>
										<label className="download-modal__checkbox_label">
											{checkboxItem.label}
										</label>
										<span className="download-modal__checkbox_file">
											{checkboxItem.fileCount}{' '}
											{checkboxItem.fileCount > 1 ? 'files' : 'file'}
										</span>
									</div>
								</div>
							)
						)}
					</div>

					<div className="download-modal__bottom_checkbox">
						<input
							type="checkbox"
							id={documentType.combinePdf.id}
							checked={selectCombinePdf}
							onChange={handleCheckBoxChange}
							className="download-modal__bottom_checkbox__input"
						/>
						<label
							className="download-modal__checkbox_label"
							onClick={() => {
								const event: IEvent = {
									target: {
										id: documentType.combinePdf.id,
										checked: !selectCombinePdf,
									},
								};
								handleCheckBoxChange(event);
							}}
						>
							{documentType.combinePdf.label}
						</label>
					</div>
				</div>
				<div className="download-modal__footer">
					<Button
						type="button__outline--secondary"
						label={'Cancel'}
						handleClick={handleClose}
					/>
					<Button
						type="button__filled--primary"
						label={renderLabel}
						handleClick={handleDownload}
						disabled={isDisable}
					/>
				</div>
			</div>
		</div>
	);
};
