import { useCallback } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { CreateSignState, FileUploadedType, SignatureErrorState, UploadSignState } from '../store';
import { UploadBox } from './upload-box';
import { convertFileToBase64 } from 'utils';
import { useNotification } from "hooks";

export const UploadSign = () => {
	const [images, setImage] = useRecoilState(UploadSignState);
	const setSignature = useSetRecoilState(CreateSignState);
	const signatureError = useRecoilValue(SignatureErrorState);
	const { errorNotification } = useNotification();

	const handleChange = useCallback(
		async (event: any, type: "sign" | "initials", dragDrop?: boolean) => {
			const file: string | any = !dragDrop ? event.target.files[0] : event;
			
			if(!(FileUploadedType.includes(file?.type))){
				errorNotification('Please upload a JPEG or PNG image file for signature and initials.');
				return;
			}

			if (file?.size <= 1000000) {
				const images: any = await convertFileToBase64(file);
				setSignature(prev => {
					const prevState = JSON.parse(JSON.stringify(prev));
          prevState.upload[type] = images
					return prevState;
				});
				setImage(prev => ({...prev, [type]: images}));
				return;
			}
			if(!dragDrop)
			event.target.value = null;
			errorNotification('Signature or initial can not be more than 1 MB.');
		},
		[errorNotification, setImage, setSignature]
	);

	const handleRemove = useCallback((type: "sign" | "initials") => {
		setSignature(prev => {
			const prevState = JSON.parse(JSON.stringify(prev));
      prevState.upload[type] = "";
			return prevState;
		});
		setImage(prev => ({...prev, [type]: ""}));
	}, [setImage, setSignature]);

	const handleDrop = useCallback(
		(e: any, type: 'sign' | 'initials') => {
			e.preventDefault();
			e.stopPropagation();
			const files = Array.from(e.dataTransfer.files)[0];
			handleChange(files, type, true);
		},
		[handleChange]
	);

	return (
		<div
			className='admin-upload-sign__container'			
		>
			<UploadBox
				handleRemove={handleRemove}
				handleUpload={handleChange}
				images={images}
				type="sign"
				label="Upload your Signature"
				handleDrop={(e:any)=>handleDrop(e,'sign')}
				isError={signatureError.signature.value}
			/>
			<UploadBox
				handleRemove={handleRemove}
				handleUpload={handleChange}
				images={images}
				type="initials"
				label="Upload your Initial"
				handleDrop={(e:any)=>handleDrop(e,'initials')}
				isError={signatureError.initial.value}
			/>
		</div>
	);
};
