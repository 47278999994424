import { Loader } from '@storybook';
import './kyb-status-processing-ilustration.scss';

export const KYBLoadingSection = () => {
	return (
		<div className="KYB__status--wraper">
             <div className='KYB__processing__loader--wrapper'><Loader type="loader" dimension={20} /></div>
			<div className='KYB__status--heading'>We are still fetching KYB data from multiple sources. Please wait for 2-3 minutes.</div>
		</div>
	);
};
