import { Button, Image } from '@storybook';

import { usePaymentSuccess } from '../../hooks';
import './update-address-success.scss';


export const UpdateAddressSuccess = () => {
	const { handleClose } = usePaymentSuccess();

	return (
		<div className="UpdateSuccessPage">
			<div className="UpdateSuccessPage--icon">
				<Image fileName="success.gif" loading="lazy" />
			</div>
			<div className="UpdateSuccessPage--info">
				<h2 className="UpdateSuccessPage--info__heading">
					Billing address updated successfully
				</h2>
				<p className="UpdateSuccessPage--info__desc">
					You&apos;ve successfully updated a billing address for payment.
				</p>
			</div>
			<Button
				handleClick={handleClose}
				label="Close"
				type="button__filled button__filled--secondary button__large"
			/>
		</div>
	);
};
