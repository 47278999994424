import { atom } from 'recoil';

export interface IUploadProofDocumentState {
	name: string;
	size: number;
	docId: string;
	fileUrl: string;
	node?: string;
}

export const UploadProofDocumentState = atom<IUploadProofDocumentState[]>({
	key: 'upload-proof-document-key',
	default: [],
});
