import { useMemo } from 'react';
import { NumberWithCommas, useFormatNumber } from 'utils';
import { IService } from '../../type';

export const CategoryFooter = ({ data }: any) => {
	const { numberDecimal } = useFormatNumber()
	const quantity = useMemo(() => {
		const onboarding = data.services.onboarding.reduce((p: any,c: { key: string; quantity: any; }) => c.key !== "onboardingService" ? c.quantity + p : p , 0)
		const simpliciSign = data.services.simpliciSign.reduce((p: any,c: { key: string; quantity: any; }) => c.key !== "simpliciSignService" ? c.quantity + p : p , 0)
		return onboarding + simpliciSign
	}, [data]);

	const amount = useMemo(() => {
		const onboardingTotal = data.services.onboarding.reduce((preTotal: number, curSer: IService) => {
			if (curSer.key !== "onboardingService") {
				if(curSer.metadata.length > 0){
					const serviceTotal = curSer.metadata.reduce((subTotal, meta) => {
						return (meta.price * meta.quantity) + subTotal;
					}, 0);
					return preTotal + serviceTotal;
				} else {
					return (curSer.price * curSer.quantity) + preTotal;
				}
			} else {
				return preTotal;
			}
		}, 0);

		const simpliciSignTotal = data.services.simpliciSign.reduce((preTotal: number, curSer: IService) => {
			if (curSer.key !== "simpliciSignService") {
				if(curSer.metadata.length > 0){
					const serviceTotal = curSer.metadata.reduce((subTotal, meta) => {
						return (meta.price * meta.quantity) + subTotal;
					}, 0);
					return preTotal + serviceTotal;
				} else {
					return (curSer.price * curSer.quantity) + preTotal;
				}
			} else {
				return preTotal;
			}
		}, 0);
		return onboardingTotal + simpliciSignTotal
	}, [data]);
	
	return (
		<tfoot className='billing-table-footer'>
			<tr>
				<td>Total</td>
				<td className='billing-table-footer--text-right'>{NumberWithCommas(quantity)}</td>
				<td></td>
				<td className='billing-table-footer--text-right'>${numberDecimal(amount)}</td>
			</tr>
		</tfoot>
	);
};
