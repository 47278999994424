/* eslint-disable react/no-array-index-key */
import React, { FC, useCallback, useState } from 'react';
import { KYC_SECTION_ICON, KycKeys } from '../constants';
import { BasicSection } from 'views/sessions/components/kyb-wrapper/components/kyb-comprehensive/components/basic-section';

interface IProps {
	cardInfo: any;
	cardData: any;
	cardLabel: string | number;
	key: any;
	cardIcon?: string;
}
type HandleShowAllDetailsType = (
	event: React.MouseEvent<HTMLDivElement>
) => void;

export const KycDropCloseCard: FC<IProps> = ({
	cardInfo,
	cardData,
	cardLabel,
	cardIcon,
	key,
}) => {
	const [showAllDetails, setShowAllDetails] = useState(false);

	const renderObjects = useCallback((label: string, data: any) => {
		const entries = Object.entries(data ?? {});
		return (
			<div className="kyc-comp__drop--card-sub-sections">
				<strong>{KycKeys[label] ?? label}</strong>
				{(entries ?? []).map(([key, value], index) =>
					typeof value === 'string' ? (
						<BasicSection
							key={index}
							label={KycKeys[key] ?? key}
							value={value}
						/>
					) : value !== null && Array.isArray(value) ? (
						<div>
							{value.map((item: any, index: any) => (
								<React.Fragment key={index}>
									{Object.entries(item ?? {}).map(([key, value]) =>
										typeof value === 'string' ? (
											<div key={key}>
												<BasicSection
													label={KycKeys[key] ?? key}
													value={value ?? '--'}
												/>
											</div>
										) : typeof value === 'object' ? (
											renderObjects(key, value)
										) : (
											<></>
										)
									)}
									<div className="kyc-comp__vertical-line"></div>
								</React.Fragment>
							))}
						</div>
					) : (
						<></>
					)
				)}
			</div>
		);
	}, []);

	const isArrayOfStrings = (arr: unknown) => {
		if (!Array.isArray(arr)) {
			return false;
		}
		return arr.every(item => typeof item === 'string');
	};

	const renderArrays = useCallback(
		(label: string, data: any) => {
			return (
				<div className="kyc-comp__drop--card-sub-sections">
					<strong>{KycKeys[label] ?? label}</strong>
					<div>
						{data.map((item: any, index: any) => (
							<React.Fragment key={index}>
								{Object.entries(item ?? {}).map(([key, value]) =>
									typeof value === 'string' ? (
										<div key={key}>
											<BasicSection
												label={KycKeys[key] ?? key}
												value={value ?? '--'}
											/>
										</div>
									) : !Array.isArray(value) &&
									  typeof value === 'object' &&
									  value !== null ? (
										renderObjects(key, value)
									) : isArrayOfStrings(value) ? (
										<BasicSection
											label={KycKeys[key] ?? key}
											value={value as string[]}
										/>
									) : Array.isArray(value) ? (
										renderArrays(key, value)
									) : (
										<></>
									)
								)}
								<div className="kyc-comp__vertical-line"></div>
							</React.Fragment>
						))}
					</div>
				</div>
			);
		},
		[renderObjects]
	);

	const renderCardItem = useCallback(
		(cardItem: any) => {
			return Object.entries(cardItem ?? {}).map(([key, value], index) => {
				if (typeof value === 'string') {
					return (
						<BasicSection
							key={index}
							label={KycKeys[key] ?? key}
							value={value}
						/>
					);
				} else if (Array.isArray(value)) {
					return renderArrays(key, value);
				} else if (
					typeof value === 'object' &&
					value !== null &&
					key !== 'title'
				) {
					return renderObjects(key, value);
				} else return <></>;
			});
		},
		[renderArrays, renderObjects]
	);

	const handleShowAlldetails: HandleShowAllDetailsType = useCallback(() => {
		setShowAllDetails(prev => !prev);
	}, []);

	return (
		<div key={key} className="kyc-comp__card">
			<div className="kyc-comp__card--header">
				<div className="kyc-comp__card--header__icon">
					<i className={cardIcon ?? KYC_SECTION_ICON['fileError']}></i>
				</div>
				<div className="kyc-comp__card--label-section-wrapper">
					<div className="kyc-comp__card--header__label">
						{cardLabel ?? '--'}
					</div>
					<div
						className="kyc-comp__card--label-section-wrapper__icon"
						onClick={e => handleShowAlldetails(e)}
					>
						<i
							className={
								showAllDetails
									? 'ri-arrow-drop-up-fill'
									: 'ri-arrow-drop-down-fill'
							}
						></i>
					</div>
				</div>
			</div>
			<div className="kyc-comp__drop--card-body">
				{renderObjects('', cardInfo)}
				{showAllDetails && (
					<div>
						{(cardData ?? []).map((item: any, index: any) => (
							<>
								<div className="kyc-comp__drop--card-child" key={index}>
									{renderCardItem(item)}
								</div>
							</>
						))}
					</div>
				)}
			</div>
		</div>
	);
};
