import { SurveyQuestionElementBase } from 'survey-react-ui';

import { CSSProperties } from 'react';
import { Quantity } from './quantity-question';

export class QuantitySelectorQuestion extends SurveyQuestionElementBase {
    constructor(props: any) {
        super(props);
        this.state = { value: this.question?.value };
    }
    get question() {
        return this.questionBase;
    }
    get value() {
        return this.question?.value;
    }
    get disableAlpha() {
        return this.question.disableAlpha;
    }
    get type() {
        return this.question.colorPickerType;
    }
    handleDetails = (data: number) => {
        this.question.value = data;
    };
    // Support the read-only and design modes
    get style() {
        return this.question.getPropertyValue('readOnly') ||
            this.question.isDesignMode
            ? { pointerEvents: 'none' }
            : undefined;
    }

    renderElement() {
        return (
            <div style={this.style as CSSProperties}>
                <Quantity handleDetails={this.handleDetails} defaultValue={this.question?.value} isDisabled={this.question.isReadOnly} />
            </div>
        );
    }
}
