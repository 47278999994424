import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';

import { API_URL, MESSAGE } from 'constant';
import { useNetwork, useNotification } from 'hooks';
import { getInitialOrSignatureBase64 } from 'utils';
import {
	SignDashboardSignatureState,
	SignDashboardState,
} from 'views/simplici-sign-dashboard';
import {
	CreateSignState,
	GenerateSignImagesState,
	ICreateSignPayload,
	IGenerateSignatures,
	IsCreateSignModalOpen,
	IsFontsLoadedState,
	SignPadColorState,
	SignPadDesignState,
	TimeoutHandle,
	UploadSignState,
	activeTabState,
} from '../';

export const useTimeout = (
	callback: () => void
): ((delay: number | null) => void) => {
	const savedCallback = useRef(callback);
	const timeoutRef = useRef<TimeoutHandle | null>(null);

	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	const set = (delay: number | null): void => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}

		if (delay === null) {
			return;
		}

		timeoutRef.current = setTimeout(() => {
			savedCallback.current();
		}, delay);
	};

	return set;
};
export const useResetCreateSign = () => {
	const resetPenColor = useResetRecoilState(SignPadColorState);
	const resetModalOpen = useResetRecoilState(IsCreateSignModalOpen);
	const resetCreatedSignDesign = useResetRecoilState(SignPadDesignState);
	const setSignForm = useSetRecoilState(CreateSignState);
	const resetUploadImage = useResetRecoilState(UploadSignState);
	const resetActiveTab = useResetRecoilState(activeTabState);

	const reset = useCallback(() => {
		resetModalOpen();
		setTimeout(() => {
			resetActiveTab();
			resetCreatedSignDesign();
			setSignForm(prev => ({
				...prev,
				draw: {
					...prev.draw,
					sign: '',
					initials: '',
					initialsDataCoordinates: [],
					signDataCoordinates: [],
				},
				upload: {
					...prev.upload,
					sign: '',
					initials: '',
				},
				consent: false,
			}));
			resetUploadImage();
			resetPenColor();
		}, 500);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		resetPenColor,
		resetModalOpen,
		resetCreatedSignDesign,
		resetUploadImage,
		resetActiveTab,
	]);

	return { reset };
};
export const useSetCreateSign = () => {
	const { post, patch, get } = useNetwork();
	const { errorNotification, successNotification } = useNotification();
	const [saveInProgress, setSaveInProgress] = useState(false);
	const setGenerateFontFamilies = useSetRecoilState(GenerateSignImagesState);
	const [
		{
			data: { signature: signId },
		},
		setSignDashboard,
	] = useRecoilState(SignDashboardState);

	const setSignDashboardSignature = useSetRecoilState(
		SignDashboardSignatureState
	);
	const { reset } = useResetCreateSign();
	const [isLoaded, setIsLoaded] = useRecoilState(IsFontsLoadedState);
	const fetchSignFonts = useCallback(
		async (name: string, initial: string) => {
			setIsLoaded(false);
			const response = await get(
				`${API_URL.SIGNATURE_FONT}?name=${name}&initial=${initial}`
			);
			if (response) {
				const fonts: IGenerateSignatures[] = (response?.data ?? []).map(
					(font: IGenerateSignatures) => ({
						...font,
						id: font._id,
					})
				);

				setGenerateFontFamilies(fonts);
				setIsLoaded(true);
				return;
			}
			errorNotification(MESSAGE.ERROR_TRY);
			setIsLoaded(true);
		},
		[errorNotification, get, setGenerateFontFamilies, setIsLoaded]
	);
	const submitSign = useCallback(
		async (payload: Partial<ICreateSignPayload>): Promise<boolean> => {
			setSaveInProgress(true);
			const service = signId
				? patch(`${API_URL.USER_SIGNATURE}/${signId}`, payload)
				: post(API_URL.USER_SIGNATURE, payload);
			const resp = await service;
			if (resp) {
				const { initialDetail, signDetail, _id } = resp;
				const initialImage = await getInitialOrSignatureBase64(
					initialDetail?.document?.path ?? ''
				);
				const signImage = await getInitialOrSignatureBase64(
					signDetail?.document?.path ?? ''
				);
				setSignDashboard(prev => ({
					...prev,
					data: { ...prev.data, signature: _id ?? null },
				}));
				setSignDashboardSignature(prev => ({
					...prev,
					data: {
						initialDetail: initialDetail
							? {
									_id: initialDetail._id,
									type: initialDetail.type,
									url: initialImage,
							  }
							: null,
						signDetail: signDetail
							? {
									_id: signDetail._id,
									type: signDetail.type,
									url: signImage,
							  }
							: null,
					},
				}));
				successNotification(
					`Signature and Initial ${signId ? 'updated' : 'created'} successfully`
				);
				setSaveInProgress(false);
				reset()
				return true;
			}
			errorNotification(MESSAGE.ERROR_TRY);
			setSaveInProgress(false);
			return false;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			errorNotification,
			patch,
			successNotification,
			signId,
			API_URL,
			setSignDashboardSignature,
			setIsLoaded,
		]
	);

	return { submitSign, isLoaded, fetchSignFonts, saveInProgress };
};
export const useCreateSign = () => {
	const [{ fullName, initials }, setCreateSign] =
		useRecoilState(CreateSignState);
	const { fetchSignFonts } = useSetCreateSign();
	const previousValue = useRef({
		fullName: fullName,
		initials: initials,
	});

	const getSigns = useCallback(() => {
		setCreateSign(prev => {
			const ClonedPrev = JSON.parse(JSON.stringify(prev));
			ClonedPrev.generate = {
				id: '',
				sign: '',
				initials: '',
			};
			return ClonedPrev;
		});
		fetchSignFonts(fullName, initials);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fullName, initials]);
	const startGetSign = useTimeout(getSigns);
	useEffect(() => {
		if (
			fullName &&
			initials &&
			(previousValue.current.fullName !== fullName ||
				previousValue.current.initials != initials)
		) {
			startGetSign(2000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fullName, initials]);

	const handleChangeCreateSign = useCallback(
		(name: string, value: string | boolean) => {
			const previousValue: { [key: string]: string } = {
				fullName: '',
				initials: '',
			};
			setCreateSign(prev => {
				previousValue.fullName = prev.fullName;
				previousValue.initials = prev.initials;
				const prevState = JSON.parse(JSON.stringify(prev));
				prevState[name] = value;
				return prevState;
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return { handleChangeCreateSign };
};

export function hasKey<O>(obj: object, key: PropertyKey): key is keyof O {
	return key in obj;
}
