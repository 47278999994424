import { FC, useCallback, useMemo } from 'react';

import { OperatorKey } from 'views/complex-onboarding-flow';
import { FlowLine } from '../form-one/flow';
import './condition.scss';

interface ICondition {
	conditions: any;
	isKyc?: boolean;
	label: string;
}

const KeywordData = {
	Params: 'param',
	Operator: 'operator',
	Operand: 'operand',
};

type IConditionResult =
	| 'approve'
	| 'reject'
	| 'manualReview'
	| 'rejected'
	| 'completed'
	| 'processing';

interface IConditions {
	operand: string;
	operator: string;
	param: string;
	result?: IConditionResult;
}

export const Condition: FC<ICondition> = ({
	conditions,
	isKyc = false,
	label,
}) => {
	const conditionsData = useMemo((): IConditions[] => {
		const data: IConditions[] = [];
		if (isKyc) {
			conditions?.forEach((item: any) => {
				Object.values(item ?? {})?.forEach((val: any) =>
					(Object.values(val ?? {}) as any)[0]?.forEach((entry: any) => {
						data.push({ ...entry, result: val.then });
					})
				);
			});
			return data;
		} else {
			conditions?.forEach((item: any) => {
				(Object.values(item ?? {}) as any)[0]?.forEach((entry: any) => {
					data.push(entry);
				});
			});
			return data;
		}
		return data;
	}, [conditions, isKyc]);

	const getConditionResult = useCallback((result: IConditionResult) => {
		switch (result) {
			//@avinash: keep old and new key as new key is given by backend
			case 'approve':
			case 'completed':
				return (
					<div className="session-kyc-condition-result__item session-kyc-condition-result__item--approve">
						<span>True</span>
					</div>
				);
			default:
				return (
					<div className="session-kyc-condition-result__item session-kyc-condition-result__item--reject">
						<span>False</span>
					</div>
				);
		}
	}, []);

	return conditionsData?.length > 0 ? (
		<>
			<div className="kycDetails-conditions">
				<div className="condition-aml-label">{label}</div>
				{conditionsData.map((item: any) => {
					return Object.values(item ?? {}).length > 0 ? (
						<div className="age-condition">
							<div className="session-kyc-age">{item[KeywordData.Params]}</div>
							{(OperatorKey as any)[item[KeywordData.Operator]] && (
								<div className="session-kyc-age">
									{(OperatorKey as any)[item[KeywordData.Operator]]}
								</div>
							)}
							<div className="session-kyc-age">{item[KeywordData.Operand]}</div>
							{item.result && (
								<div className="session-kyc-condition-result">
									{getConditionResult(item.result)}
								</div>
							)}
						</div>
					) : (
						<></>
					);
				})}
			</div>
			<FlowLine />
		</>
	) : null;
};
