import { useCallback, useMemo, useEffect, Fragment } from 'react';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';
import { Loader } from '@storybook';

import Modal from '@storybook/new-modal/modal';
import {
	IsMultiSignModalOpen,
	useMultiSignAgreement,
	MultiSignPrepareUrl,
	SignAgreementComplexStepConfigured,
	SelectedComplexSignAgreementStep,
	LinearMultiSignAgreementStepConfigured,
	CaptableOldState,
	OverlaySignAgreementEnvelopeId,
} from './store';
import {
	ConditionalOnboardingFlowState,
	SelectedQRInstanceState,
	SelectedQRTemplateIdState,
} from 'views/pipelines/components';
import { getLuckSheetData } from 'utils';
import { NavigateToBackConfirmation } from 'components/esign-back-modal';
import { SimpliciSignIframe } from 'shared-components';

/**
 * MultiSignAgreement component.
 * This component handles the opening and configuration of the MultiSign modal.
 */
export const MultiSignAgreement = () => {

	const [isOpen, setIsOpen] = useRecoilState(IsMultiSignModalOpen);
	const {
		fetchMultiSignPrepareUrl,
		fetchOverlayMultisignerPrepareUrl,
		reconfigureMultiSignAgreement,
	} = useMultiSignAgreement();
	const config = useRecoilValue(MultiSignPrepareUrl);
	const [QRTemplateId, setQRTemplateId] = useRecoilState(
		SelectedQRTemplateIdState
	);
	const resetConfig = useResetRecoilState(MultiSignPrepareUrl);
	const [configurationStatus, setConfigurationStatus] = useRecoilState(
		SignAgreementComplexStepConfigured
	);
	const setLinearConfigStatus = useSetRecoilState(
		LinearMultiSignAgreementStepConfigured
	);
	const selectedSignNode = useRecoilValue(SelectedComplexSignAgreementStep);
	const selectedQRinstanceData = useRecoilValue(SelectedQRInstanceState);
	const resetSelectedNode = useResetRecoilState(
		SelectedComplexSignAgreementStep
	);
	const isComplexFlow = useRecoilValue(ConditionalOnboardingFlowState);
	const setOldCaptableState = useSetRecoilState(CaptableOldState);
	const setOverlayInviteConfig = useSetRecoilState(
		OverlaySignAgreementEnvelopeId
	);

	/**
	 * useEffect to handle initial fetch and configurations.
	 */
	useEffect(() => {
		const { status, envelopeId } =
			configurationStatus[selectedSignNode as string] ?? {};
		const reconfigure = status === 'reconfigure';
		if (reconfigure && envelopeId && isComplexFlow) {
			// Reconfigure the MultiSign Agreement if needed
			reconfigureMultiSignAgreement(envelopeId);
			return;
		}
		if (QRTemplateId) {
			// Fetch the Overlay MultiSign prepare URL
			fetchOverlayMultisignerPrepareUrl();
		} else {
			// Fetch the MultiSign prepare URL
			fetchMultiSignPrepareUrl();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Handles closing the modal.
	 */
	const handleCloseModal = useCallback(() => {
		if (isComplexFlow && selectedSignNode) {
			// Update the configuration status based on user interactions
			setConfigurationStatus((prev) => {
				return {
					...prev,
					[selectedSignNode]: {
						envelopeId: prev[selectedSignNode]?.envelopeId ?? '',
						status:
							prev[selectedSignNode]?.status === 'reconfigure'
								? 'configured'
								: prev[selectedSignNode]?.status || 'pending',
					},
				};
			});
		}

		// Close the MultiSign modal
		setIsOpen(false);
		// Clear the interval for checking envelope status
		// Reset the selected sign node and linear configuration status
		resetSelectedNode();
		// Reset the config values
		resetConfig();

		setLinearConfigStatus(false);		
		setOverlayInviteConfig({envelopeId: "", status: "pending"});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isComplexFlow, selectedSignNode]);
	/**
	 * Handles the callback after saving the envelope.
	 */
	const handleSavedEnvelopeCallback = useCallback(() => {
		const captableData = getLuckSheetData();
		setTimeout(() => {
			// Close the MultiSign modal
			setIsOpen(false);
			setOldCaptableState(captableData);
			setOverlayInviteConfig(prev => ({...prev, status: "configured"}));
			if(selectedQRinstanceData?.length && selectedSignNode) {
				setConfigurationStatus((prev) => {
					// Create a copy of the previous state
					const newState = structuredClone(prev);
					// Update the state based on selectedSignNode
					newState[selectedSignNode] = {
						status: 'configured',
						/**
						 * When the user is reconfiguring any sign node, then we need not to change the envelopeId,
						 * and the old envelopeId will only be used for reconfigure for as many times also it will be used as payload in the 
						 */
						envelopeId: newState[selectedSignNode]?.envelopeId || config.envelopeId,
						templateId: QRTemplateId
					};
					// Return the updated state
					return newState;
				});
				
				setQRTemplateId('');
				// Reset the selected sign node and MultiSign configuration
				resetSelectedNode();
				resetConfig();	
			}
			if (isComplexFlow) {
				if (selectedSignNode) {
					// Update the configuration status after successful configuration
					setConfigurationStatus((prev) => {
						// Create a copy of the previous state
						const newState = structuredClone(prev);
						// Update the state based on selectedSignNode
						newState[selectedSignNode] = {
							status: 'configured',
							/**
							 * When the user is reconfiguring any sign node, then we need not to change the envelopeId,
							 * and the old envelopeId will only be used for reconfigure for as many times also it will be used as payload in the 
							 */
							envelopeId: newState[selectedSignNode]?.envelopeId || config.envelopeId,
							templateId: QRTemplateId
						};
						// Return the updated state
						return newState;
					});
				}
				setQRTemplateId('');
				// Reset the selected sign node and MultiSign configuration
				resetSelectedNode();
				resetConfig();				
			} else {
				// Set the linear configuration status for non-complex flow
				setLinearConfigStatus(true);
			}
		}, 1000);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [QRTemplateId, config.envelopeId, isComplexFlow, resetConfig, resetSelectedNode, selectedSignNode]);

	/**
	 * useMemo for rendering the MultiSign iframe.
	 */
	const renderSimpliciSignIframe = useMemo(() => {
		if (config.prepareUrl?.trim()) {
			return (
				<>
					<SimpliciSignIframe
						className="multi-sign__wrapper"
						title="onboarding-multisigner-modal"
						signUrl={config.prepareUrl}
						handleSubmitModal={handleSavedEnvelopeCallback}
					/>
					<button onClick={handleCloseModal} className="close-btn">
						<i className="ri-close-line" />
					</button>
				</>
			);
		}

		return <Loader dimension={50} />;
	}, [config.prepareUrl, handleCloseModal, handleSavedEnvelopeCallback]);

	return (
		<Fragment>
			<Modal
				isOpen={isOpen}
				modalName=""
				isStopOutsideClick={false}
				showCloseBtn={false}
				className="multi-signer-modal"
			>
				{renderSimpliciSignIframe}
			</Modal>
			{isOpen && (
				<NavigateToBackConfirmation
					isModalOpen={isOpen}
					modalCloseHandler={handleCloseModal}
				/>
			)}
		</Fragment>
	);
};
