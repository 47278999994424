import { atom } from 'recoil';
import { IBillingTransactions } from './types';

export const BillingTransactionsState = atom<IBillingTransactions[]>({
	key: 'billing-transactions-state-key',
	default: [],
});

export interface WebhookEncrptionKeyState {
	webhookEncryptionKey: string;
	encryptWebhookPayload: boolean;
}

export const WebhookEncrptionKeyState = atom<WebhookEncrptionKeyState>({
	key: 'Web-hook-EncrptionKey-State',
	default: {
		webhookEncryptionKey: "",
		encryptWebhookPayload: false,
	},
});
