import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';

import { IOption } from '@storybook';
import { API_URL, MESSAGE, message, validateEmail } from 'constant';
import {
	EditModal,
	FundsState,
	ILoginState,
	InviteDetailsState,
	InviteEmailInputState,
	InviteFirstNameInputState,
	InviteLastNameInputState,
	InvitePhoneInputState,
	InviteRoleState,
	LoginPhoneNumberState,
	UserInviteType,
	UserRolesState,
	loginState,
} from 'global-stores';
import { useTrackEvents } from 'helpers';
import { useFreePlan, useNetwork, useNotification } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes';
import { useUserRoles } from 'views/routes-children';
import {
	InviteNavigation,
	PermissionNavigation,
	RoleAndPermissionNavigation,
} from 'views/user-roles';
import {
	AddNewRoleFormState,
	IAddNewRoleFormPayload,
	IPermissionType,
	IReadWrite,
	InviteNavigationState,
	SelectedUserRoleState,
} from 'views/user-roles/store';
import { nameRegex } from 'views/user-roles/user-roles';
import { ISubAccountListState, SubAccountListState } from './states';

export const useSubAccounts = () => {
	const { trackEvents } = useTrackEvents();

	const [subAccountRows, setSubAccountRows] =
		useRecoilState(SubAccountListState);
	const [invites, setInvites] = useRecoilState(InviteDetailsState);

	const [loginPhoneNumber, setLoginPhoneNumber] = useRecoilState(
		LoginPhoneNumberState
	);
	const setShowLastNameError = useSetRecoilState(InviteLastNameInputState);
	const setShowFirstNameError = useSetRecoilState(InviteFirstNameInputState);
	const resetInvites = useResetRecoilState(InviteDetailsState);
	const resetShowPhoneError = useResetRecoilState(InvitePhoneInputState);
	const resetLoginPhoneNumber = useResetRecoilState(LoginPhoneNumberState);
	const resetShowEmailError = useResetRecoilState(InviteEmailInputState);
	const [resetShowRoleError, setResetShowRoleError] = useRecoilState(InviteRoleState);
	const [navigate, setNavigation] = useRecoilState(PermissionNavigation);
	const fundsList = useRecoilValue(FundsState);
	const [editModal, setEditModal] = useRecoilState(EditModal);
	const addRoleForm = useRecoilValue(AddNewRoleFormState);
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [inviteModalOpen, setInviteModalOpen] = useState(false);
	const [isApiLoading, setApiLoading] = useState(false);
	const [isOpenUserModal, setIsUserOpenModal] = useState(false);
	const [selectedId, setSelectedId] = useState('');
	const user = useRecoilValue(loginState);

	const { sandboxStatus = false } = useMemo(() => user, [user]);

	const [isOpenDeleteModal, setIsUserDeleteModal] = useState(false);
	const [isEditAccount, setIsEditAccount] = useState(false);
	const [editAccount, setEditAccount] = useState<ISubAccountListState | null>(
		null
	);
	const [openDeleteInvitedUser, setOpenDeleteInvitedUser] = useState(false);
	const [invitedUserToDelete, setInvitedUserToDelete] =
		useState<ILoginState | null>(null);
	const [isAddRoleModalOpen, setIsAddRoleModalOpen] = useState<boolean>(false);
	const setInviteNavigation = useSetRecoilState(InviteNavigationState);
	const inviteNavigation = useRecoilValue(InviteNavigationState);
	const resetInviteNavigation = useResetRecoilState(InviteNavigationState);
	const resetAddRoleForm = useResetRecoilState(AddNewRoleFormState);
	const setUserRoles = useSetRecoilState(UserRolesState);
	const resetSelectedUserRole = useResetRecoilState(SelectedUserRoleState);
	const selectedUserRole = useRecoilValue(SelectedUserRoleState);

	const [subUsersList, setSubUsersList] = useState<ILoginState[]>([]);
	const [isSubUserLoading, setSubUserLoading] = useState(false);
	const [disableSubAccount, setDisableSubAccount] = useState(false);
	const [isSubscriptionExist, setSubscriptionExist] = useState(false);

	const routeNavigate = useNavigate();
	const { hasSubscribedBusiness } = useFreePlan();
	const { successNotification, errorNotification } = useNotification();
	const {
		get: getSubAccount,
		remove: deleteSubAccount,
		data: subAccountList,
		loading: subAccountLoading,
		isLoaded: subAccountLoaded,
	} = useNetwork();
	const {
		post: inviteUser,
		get: getSingleInvitedUser,
		remove: removeInvitedUser,
		patch,
	} = useNetwork({
		updateState: false,
	});
	const { post: postAddNewRole } = useNetwork({
		updateState: false,
	});

	const { fetchUserRoles, userRoles, fetchRolesTemplate, templateRoles } =
		useUserRoles();

	useEffect(() => {
		getSubAccount(`${API_URL.SUB_ACCOUNT}?isDeleted=false`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (subAccountList?.data) {
			setSubAccountRows(subAccountList.data);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subAccountList?.data]);

	const fetchSingleInvitedUser = async (id: string) => {
		setSubUserLoading(true);
		const resp = await getSingleInvitedUser(
			`${API_URL.SUB_ACCOUNT}/${id}?archivedUser=false`
		);
		if (resp?.message?.toLowerCase() && resp?.data) {
			const {
				data: { users = [] },
			} = resp;
			setSubUsersList(users ?? []);
		}
		setSubUserLoading(false);
	};

	const onRemoveInvitedUser = async (isOpen: boolean, value: boolean) => {
		if (value) {
			const id = invitedUserToDelete?._id;
			const resp = await removeInvitedUser(`${API_URL.USER_ROLES}/${id}`);
			if (resp?._id) {
				setSubUsersList(prev => {
					const prevObj = structuredClone(prev);
					const index = prevObj.findIndex(el => el._id === id);
					if (index !== -1) {
						prevObj.splice(index, 1);
					}
					return prevObj;
				});
				await getSubAccount(API_URL.SUB_ACCOUNT);
				successNotification('User deleted successfully');
				setOpenDeleteInvitedUser(isOpen);
				setInvitedUserToDelete(null);
			}
		} else {
			setOpenDeleteInvitedUser(value);
			setInvitedUserToDelete(null);
		}
	};

	const handleUser = useCallback((user: ISubAccountListState) => {
		setIsUserOpenModal(true);
		fetchSingleInvitedUser(user?._id);
		setEditAccount(user);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleOpenView = useCallback((item: ISubAccountListState) => {
		setIsEditAccount(true);
		setIsOpenModal(true);
		setEditAccount(item);
	}, []);

	const handleOpenDelete = useCallback((item: ISubAccountListState) => {
		setIsUserDeleteModal(true);
		setEditAccount(item);
	}, []);

	const handleOpenDeleteInvitedUser = useCallback((item: ILoginState) => {
		setOpenDeleteInvitedUser(true);
		setInvitedUserToDelete(item);
	}, []);

	const handleCloseModal = () => {
		setIsAddRoleModalOpen(false);
		resetSelectedUserRole();
	};

	const onInviteRole = (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>,
		item: ISubAccountListState
	) => {
		e.stopPropagation();

		setInviteModalOpen(prev => !prev);
		// If no user role available
		if (!userRoles?.length) {
			setNavigation(RoleAndPermissionNavigation.NoRoleAvailable);
		} else {
			setNavigation(RoleAndPermissionNavigation.CreateNewRole);
		}

		setIsAddRoleModalOpen(true);
		setDisableSubAccount(true);
		if (item?._id) {
			setInvites((pre: UserInviteType | any) => ({
				...pre,
				subAccount: { label: item?.name, value: item._id },
			}));
		}
	};

	const resetErrors = () => {
		resetShowPhoneError();
		resetLoginPhoneNumber();
		resetShowEmailError();
		resetInvites();
		resetInviteNavigation();
	};

	const handleClose = () => {
		switch (inviteNavigation) {
			case InviteNavigation.InvitePreview:
				setInviteNavigation(InviteNavigation.InviteForm);
				break;
			case InviteNavigation.InviteForm:
				setInviteModalOpen(prev => !prev);
				setShowFirstNameError(false);
				setShowLastNameError(false);
				setResetShowRoleError(false);
				resetErrors();
				if (editModal) {
					setIsUserOpenModal(true);
					setEditModal(false);
				}
				break;
			default:
				break;
		}
	};

	const handleCreateBtn = () => {
		if (!hasSubscribedBusiness) {
			setSubscriptionExist(true);
			return;
		}
		setIsEditAccount(false);
		setIsOpenModal(true);
		setEditAccount(null);
	};

	const inviteSubUser = useCallback(async () => {
		setApiLoading(true);
		const {
			firstName = '',
			lastName = '',
			funds = [],
			subAccount,
			...restPayload
		} = invites ?? {};
		if ('email' in invites) {
			if (firstName.trim().length < 3 || lastName.trim().length < 3) {
				if (firstName.trim().length < 3) {
					setShowFirstNameError(true);
				}

				if (lastName.trim().length < 3) {
					setShowLastNameError(true);
				}
				return;
			}

			const fundIds = funds.map(({ value }) => value);

			const newPayload = {
				...restPayload,
				fundIds,
				firstName,
				lastName,
				subAccount: subAccount?.value,
				developer: false,
				isAgentPermitted: false,
			};
			trackEvents('sent-user-invite', { newPayload });
			const resp = await inviteUser(API_URL.USER_ROLES, newPayload);
			if (resp?.success) {
				successNotification(resp?.message ?? 'Successfully invited');
				handleClose();
				setSubAccountRows(prev => {
					const prevObj = structuredClone(prev);
					const subResult = prevObj?.find(
						item => item._id === subAccount.value
					);
					const idx = prevObj?.findIndex(item => item._id === subAccount.value);
					if (subResult && idx !== -1) {
						const payload = {
							...subResult,
							['totalUsers']: (subResult.totalUsers || 0) + 1,
						};

						prevObj.splice(idx, 1, payload);
					}
					return prevObj;
				});
				setTimeout(() => {
					resetInvites();
					resetInviteNavigation();
				}, 1000);
			}
		}
		setApiLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [invites]);

	const updateInviteSubUser = async () => {
		setApiLoading(true);
		const { role, funds } = invites ?? {};
		const newPayload = {
			role,
			fundIds: funds?.map(fund => fund.value),
		};
		const resp = await patch(`${API_URL.USER_ROLES}/${selectedId}`, newPayload);
		if (resp._id) {
			successNotification('Updated Successfully');
			setInviteModalOpen(false);
			setTimeout(() => {
				resetInviteNavigation();
			}, 1000);
		} else {
			errorNotification(resp.message ?? MESSAGE.ERROR);
		}
		setApiLoading(false);
	};

	const onSaveRole = useCallback(() => {
		switch (inviteNavigation) {
			case InviteNavigation.InviteForm:
				setInviteNavigation(InviteNavigation.InvitePreview);
				break;
			case InviteNavigation.InvitePreview:
				if (editModal) {
					updateInviteSubUser();
				} else {
					inviteSubUser();
				}
				break;
			default:
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inviteNavigation, setInviteNavigation, editModal, inviteSubUser]);

	const disabledAddRoleBtn = useMemo(() => {
		const { name, permissions, services, fullAccess } = addRoleForm ?? {};

		const hasAnyReadOrWritePermission = Object.keys(permissions).some(
			category => {
				return ['read', 'write'].some(
					permission =>
						permissions[category as IPermissionType][permission as IReadWrite]
				);
			}
		);

		if (!name.trim()) {
			return true;
		} else if (fullAccess) {
			return false;
		} else if (!services || services.length === 0) {
			return true;
		} else {
			return !hasAnyReadOrWritePermission;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addRoleForm]);

	const isInviteFormDisable = useMemo(() => {
		const { email, role, lastName, firstName, subAccount } = invites ?? {};
		if (
			loginPhoneNumber?.phone?.length >= 8 &&
			firstName?.length >= 3 &&
			lastName?.length >= 3 &&
			Object.keys(subAccount ?? {})?.length &&
			nameRegex.test(firstName) &&
			nameRegex.test(lastName) &&
			validateEmail(email) &&
			role &&
			!resetShowRoleError
		) {
			return false;
		}
		return true;
	}, [invites, loginPhoneNumber?.phone?.length, resetShowRoleError]);

	const disabledNextBtn = useMemo(() => {
		if (selectedUserRole) {
			return false;
		}
		return true;
	}, [selectedUserRole]);

	const isDisabled = useMemo(() => {
		switch (navigate) {
			case RoleAndPermissionNavigation.NoRoleAvailable:
				return disabledNextBtn;
			case RoleAndPermissionNavigation.AddNewRole:
				return disabledAddRoleBtn;
			default:
				return isInviteFormDisable;
		}
	}, [navigate, isInviteFormDisable, disabledAddRoleBtn, disabledNextBtn]);

	useEffect(() => {
		if (!userRoles) fetchUserRoles();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!templateRoles) fetchRolesTemplate();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [templateRoles]);

	const handleDeleteSubAcc = useCallback(
		(isOpen: boolean, value: boolean) => {
			if (value) {
				const remainedSubAccount = subAccountRows.filter(
					item => item !== editAccount
				);
				deleteSubAccount(`${API_URL.SUB_ACCOUNT}/${editAccount?._id}`).then(
					resp => {
						if (resp?.success) {
							setSubAccountRows(remainedSubAccount);
							setIsUserDeleteModal(isOpen);
							setEditAccount(null);
							successNotification(message.DELETE_MESSAGE);
						}
					}
				);
			} else {
				setIsUserDeleteModal(value);
				setEditAccount(null);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[editAccount, subAccountRows]
	);

	const onInviteSubAccountUser = useCallback(() => {
		if (!userRoles?.length) {
			setIsUserOpenModal(false);
			setIsAddRoleModalOpen(true);
			setNavigation(RoleAndPermissionNavigation.NoRoleAvailable);
			return;
		}

		setIsUserOpenModal(false);
		const { _id, name } = editAccount ?? {};
		setInviteModalOpen(prev => !prev);
		setInvites((pre: UserInviteType | any) => ({
			...pre,
			subAccount: { label: name, value: _id },
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editAccount, sandboxStatus, userRoles?.length]);

	// render next button label
	const mapToNavigationNextLabel = useMemo(() => {
		switch (navigate) {
			case RoleAndPermissionNavigation.AddNewRole:
				return 'Add role';
			case RoleAndPermissionNavigation.NoRoleAvailable:
				return 'Continue';
			default:
				return 'Continue';
		}
	}, [navigate]);

	// render previous button label
	const mapToNavigationPreviousLabel = useMemo(() => {
		switch (navigate) {
			case RoleAndPermissionNavigation.AddNewRole:
				return 'Back';
			case RoleAndPermissionNavigation.NoRoleAvailable:
				return 'Cancel';
			default:
				return 'Cancel';
		}
	}, [navigate]);

	const handleBackModel = () => {
		setNavigation(RoleAndPermissionNavigation.NoRoleAvailable);
	};

	const handleAddRole = async () => {
		setApiLoading(true);
		const { name, description, services, fullAccess, permissions } =
			addRoleForm ?? {};

		const payload: IAddNewRoleFormPayload = {
			name: name.trim(),
			description: description.trim(),
			services,
			fullAccess,
		};

		if (!fullAccess) {
			payload['permissions'] = permissions;
		}
		const resp = await postAddNewRole(API_URL.ROLES, payload);
		if (resp?._id) {
			setUserRoles(pre => {
				const prevObject = structuredClone(pre);
				prevObject?.push(resp);

				return prevObject;
			});
			successNotification(message.ROLE_ADDED_SUCCESS);
			handleCloseModal();
			resetAddRoleForm();
			resetSelectedUserRole();
			fetchUserRoles();
		}
		setApiLoading(false);
	};

	const handleContinue = () => {
		setNavigation(RoleAndPermissionNavigation.AddNewRole);
	};

	const handleBack = useCallback(() => {
		switch (navigate) {
			case RoleAndPermissionNavigation.AddNewRole:
				handleBackModel();
				break;
			case RoleAndPermissionNavigation.NoRoleAvailable:
				handleCloseModal();
				break;
			default:
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigate]);

	const handleNext = () => {
		switch (navigate) {
			case RoleAndPermissionNavigation.AddNewRole:
				handleAddRole();
				break;
			case RoleAndPermissionNavigation.NoRoleAvailable:
				handleContinue();
				break;
			default:
				break;
		}
	};

	const handleCloseViewModal = () => {
		setIsUserOpenModal(false);
		setSubUsersList([]);
	};

	const handleEditSubAccountUser = (item: ILoginState) => {
		setIsUserOpenModal(prev => !prev);
		setInviteModalOpen(prev => !prev);
		const fundValues = fundsList?.filter(object1 => {
			return item?.fundIds?.some(object2 => {
				return object1.id === object2;
			});
		});
		const fundData: IOption[] = [];
		fundValues.forEach(fund => {
			fundData.push({ label: fund.name, value: fund.id });
		});

		const { _id, name } = editAccount ?? {};

		if (item?._id) {
			setDisableSubAccount(true);
			setLoginPhoneNumber(pre => {
				return {
					...pre,
					phone: item.phone,
					countryCode: item.countryCode,
				};
			});
			setInvites(pre => {
				const prevState = JSON.parse(JSON.stringify(pre));
				prevState['role'] = item?.role?._id ?? '';

				return {
					...prevState,
					firstName: item?.firstName,
					lastName: item?.lastName,
					email: item?.email,
					funds: fundData,
					subAccount: { label: name, value: _id },
				};
			});
			setSelectedId(item?._id ?? '');
			setEditModal(true);
		}
	};

	const closeSubscriptionModal = useCallback(() => {
		setSubscriptionExist(prev => !prev);
	}, []);

	const handleNavigate = useCallback(() => {
		routeNavigate(ROUTES.BILLING);
		setSubscriptionExist(prev => !prev);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		subAccountRows,
		handleCreateBtn,
		subAccountLoaded,
		handleUser,
		isOpenModal,
		setIsOpenModal,
		isEditAccount,
		editAccount,
		isOpenUserModal,
		setIsUserOpenModal,
		isSubUserLoading,
		subUsersList,
		isOpenDeleteModal,
		subAccountLoading,
		handleDeleteSubAcc,
		inviteModalOpen,
		handleClose,
		onSaveRole,
		isApiLoading,
		isDisabled,
		onInviteRole,
		handleOpenDelete,
		handleOpenView,
		onInviteSubAccountUser,
		isAddRoleModalOpen,
		handleCloseModal,
		mapToNavigationNextLabel,
		mapToNavigationPreviousLabel,
		handleBack,
		handleNext,
		templateRoles,
		onRemoveInvitedUser,
		handleCloseViewModal,
		disableSubAccount,
		handleEditSubAccountUser,
		isSubscriptionExist,
		handleNavigate,
		closeSubscriptionModal,
		openDeleteInvitedUser,
		invitedUserToDelete,
		handleOpenDeleteInvitedUser,
	};
};
