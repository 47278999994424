import { FC } from 'react';
import { accountRenderColor, BussinessDetailsStatus } from 'views/settings';

interface IStepStatus {
	statusLabel: string;
	status: string;
}
export const StepStatus: FC<IStepStatus> = ({ statusLabel, status }) => {
	return (
		<div className="Business-Processing--left-desc">
			<i
				className="ri-circle-fill"
				style={{
					color: accountRenderColor(status),
				}}
			></i>
			<div className="Business-Processing--info">
				<h4>{statusLabel}</h4>
				<p> {BussinessDetailsStatus[status] ?? 'NA'}</p>
			</div>
		</div>
	);
};
