import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { API_URL, MESSAGE } from 'constant';
import {
	PaymentCardMethodsState,
	SelectedDateRangeState,
	UserSubscriptionsState,
} from 'global-stores';
import { useNetwork, useNotification } from 'hooks';
import { formatDate } from 'utils';
import { usePaymentCard } from 'views/routes-children';
import { ChooseIdentityData, IndetityFlowNavigate } from '../constants';
import { ChooseUserIdentity, SubscriptionIdentityNaviagtion } from '../store';
import { useUserInit } from './use-user';
import { useSummaryBilling } from 'views/settings';

export function useBillingDetails() {
	const dateRange = useRecoilValue(SelectedDateRangeState);
	const { get: getBillingData } = useNetwork();
	const { endDate, startDate }: any = dateRange?.[0] ?? {};
	const fromDates = formatDate(startDate);
	const endDates = formatDate(endDate);

	const { setShowBillingSelectedDetail } = useSummaryBilling();

	const initBillingApi = async () => {
		const respnse = await getBillingData(
			`${API_URL.BILLING}?from=${fromDates}&to=${endDates}`
		);
		if (respnse?.data) {
			const newState = { ...respnse };
			newState.services = respnse.data.services;
			newState.endDate = respnse.data.endDate;
			newState.totalAmount = respnse.data.totalAmount;
			setShowBillingSelectedDetail(newState);
		}
	};

	return {
		initBillingApi,
	};
}

export const useProcessing = () => {
	// global states and recoils
	const activeIdx = useRecoilValue(ChooseUserIdentity);
	const { initBillingApi } = useBillingDetails();
	const { errorNotification } = useNotification();
	const userSubscriptions = useRecoilValue(UserSubscriptionsState);
	const setNavigate = useSetRecoilState(SubscriptionIdentityNaviagtion);
	const paymentCardMethodsState = useRecoilValue(PaymentCardMethodsState);
	const { default: defaultCard } = useMemo(
		() => paymentCardMethodsState,
		[paymentCardMethodsState]
	);

	// Local states
	const [processing, setProcessing] = useState(0);
	// Hooks
	const { post } = useNetwork({
		updateState: false,
		returnResponse: true,
	});
	const { initUser } = useUserInit();
	const { getPaymentCardsApi } = usePaymentCard();

	const current = new Date();
	const year = current.getFullYear();
	const month = current.getMonth();

	const isCardExpired = useCallback(
		() =>
			defaultCard?.detail?.exp_year < year ||
			defaultCard?.detail?.exp_month > 12 ||
			((defaultCard?.detail?.exp_year === year &&
				defaultCard?.detail?.exp_month < month) ??
				false),
		[defaultCard?.detail?.exp_month, defaultCard?.detail?.exp_year, month, year]
	);

	const accountTypeLabel = useMemo(
		() => ChooseIdentityData.find(el => el.type === activeIdx),
		[activeIdx]
	);

	const handleSubscription = async () => {
		let resp;
		if (
			userSubscriptions?.length > 0 &&
			userSubscriptions[0]?.individual?.status
		)
			resp = await post(API_URL.SUBSCRIPTIONS, { upgrade: true });
		else resp = await post(API_URL.SUBSCRIPTIONS, {});
		if (resp.success) {
			setProcessing(100);
			await initBillingApi();
			await initUser();
			setNavigate(IndetityFlowNavigate.Success);
		} else {
			if (isCardExpired()) {
				setNavigate(IndetityFlowNavigate.ExpireCard);
			} else {
				errorNotification(resp?.errorData?.message || MESSAGE.ERROR);
				setNavigate(IndetityFlowNavigate.Error);
			}
		}
	};

	useEffect(() => {
		handleSubscription();
		getPaymentCardsApi();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (processing < 100) {
			setTimeout(() => {
				if (processing <= 70) {
					setProcessing(prev => prev + 10);
				} else if (processing <= 85) {
					setProcessing(prev => prev + 5);
				} else {
					setProcessing(90);
				}
			}, 50);
		}
	}, [processing, setNavigate, errorNotification]);

	return {
		accountTypeLabel,
		processing,
	};
};
