import { Image } from '@storybook';
import React from 'react';
interface EmptyPageProps {
  message?: string;
  ilustrationType?: string;

}

export const EmptyKybSection: React.FC<EmptyPageProps> = ({ message, ilustrationType }) => {
  return (
    <div className="kyb-empty_data">
      <div className="kyb-empty_data__inner">
        <Image fileName={ilustrationType ?? "missing.svg" }  className="empty_data_img" height="100" />
        <div className="kyb-empty-data-txt">
          {message ?? 'No data is available'}
        </div>
      </div>
    </div>
  );
};
