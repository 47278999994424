export const ShowEscrowDetails = [
	[
		{
			label: 'Disbursable',
			key: 'disbursable',
		},
		{
			label: 'Pending',
			key: 'pending',
		},
		{
			label: 'Locked',
			key: 'locked',
		},
	],
	[
		{
			label: 'Account Number',
			key: 'accountNumber',
		},
		{
			label: 'Account Type',
			key: 'accountType',
		},
		{
			label: 'Account Status',
			key: 'accountStatus',
		},
	],
	[
		{
			label: 'Currency Type',
			key: 'assetType',
		},
		{
			label: 'Currency',
			key: 'assetFiatType',
		},
	],
];

export const dummyEscrowDetails = {
	id: 'ed0f0907-bf08-4cd5-b207-cac109056cbb',
	accountStatus: 'open',
	accountType: 'business',
	accountNumber: 'FT72B1266015',
	assetType: 'fiatCurrency',
	assetFiatType: 'usd',
	disbursable: 0,
	locked: 0,
	pending: 0,
	total: 0,
};
