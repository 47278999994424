const handleBeforeUnload = (event: { returnValue: string }) => {
    event.returnValue =
    'You have unsaved changes. Are you sure you want to refresh?';
};

export const addBeforeUnload = () => {
    window.addEventListener('beforeunload', handleBeforeUnload);

}

export const removeBeforeUnload = () => {
    window.removeEventListener('beforeunload', handleBeforeUnload);

}

