export const SHAREHOLDER_HEADINGS = {
    MIN_TOTAL_SHARE_OWNED_TITLE: 'Sum total percentage required to proceed',
    MIN_TOTAL_SHARE_OWNED_SUB_TITLE: 'Set the minimum total percentage of shareholder’s holdings required to proceed. End users will need to add shareholders and their respective holding percentages until the total meets or exceeds this threshold to proceed further.',
    COMPLIANCE_SESSION_TITLE: 'Shareholding percentage threshold required for KYC & AML',
    COMPLIANCE_SESSION_SUB_TITLE: 'Specify the shareholding percentage at which a user should go through KYC (Know Your Customer) and AML (Anti Money Laundering) verification. Shareholders owning equal to or more than this percentage will be required to complete the compliance process.',
}

export const DEFAULT_MIN_TOTAL_SHARE_OWNED = 50;
export const DEFAULT_COMPLIANCE_SESSION_THRESHOLD = 25;

export const IVESTOR_DETAILS_STRING = 'investors-detail'