import { atom } from 'recoil';

type IStep =
	| 'CREDS'
	| 'VERIFY'
	| 'MOBILE_CODE'
	| 'BIOMETRIC_APPROVAL'
	| 'EMAIL_VERIFICATION'
	| 'MFA-AUTH';

export const LoginCredsState = atom<string>({
	key: 'login-creds-key',
	default: '',
});

export const EmailCredsState = atom<string>({
	key: 'email-creds-key',
	default: '',
});

export const LoginMaskPhoneCredsState = atom<string>({
	key: 'login-mask-phone-creds-key',
	default: '',
});

export const AcountLiveStatus = atom<boolean>({
	key: 'acount-live-status',
	default: false,
});

export const CountryCode = atom<string>({
	key: 'selected-country-code',
	default: '+1',
});

export const LoginStep = atom<IStep>({
	key: 'login-screen-active-step',
	default: 'CREDS',
});

export type API_END_POINT = 'login_url';

export const LoginApisState = atom<{ [key in string]: string } | any>({
	key: 'login-apis-url-key',
	default: {},
});

export const MobileLoginLinkResponseState = atom<{
	verificationId: string;
}>({
	key: 'mobile-login-link-response-key',
	default: {
		verificationId: '',
	},
});

export const ApprovalStatusState = atom<string>({
	key: 'approval-status-state-key',
	default: '',
});

export const VerificationIDState = atom<string>({
	key: 'verification-id-state-key',
	default: '',
});

export const OptionsResponseState = atom<any>({
	key: 'options-response-state-key',
	default: null,
});

export const isConfirmationModalOpen = atom({
	key: 'confirmation-modal-open',
	default: false,
});

export const ConfigHeaderForWebAuthnState = atom<any>({
	key: 'config-header-for-webauthn-state-key',
	default: null,
});

export const MfaTokenForVerifyState = atom<string>({
	key: 'mfa-verify-state',
	default: '',
});

export const userIdbeforeLoginState = atom<string>({
	key: 'user-id-before-login-state',
	default: '',
});
