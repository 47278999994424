import type { IStackedColumnChart } from 'views/dashboard-analytics/hooks';

import { FC, useEffect } from 'react';

import { useStackedChart } from 'views/dashboard-analytics/hooks';
import './dashboard-chart.scss';

export const StackedColumnChart: FC<IStackedColumnChart> = ({
	isLoading,
	title,
	XAxis,
	seriesData,
}) => {
	const { getStackedConfig } = useStackedChart();

	useEffect(() => {
		getStackedConfig({ isLoading, title, XAxis, seriesData });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [XAxis, isLoading, title, seriesData]);

	return (
		<div className="dashboard-chart-wrapper">
			<div id={title} />
		</div>
	);
};
