import { Button, Image } from '@storybook';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import {
	ActivePaymentType,
	PaymentNavigationState,
} from 'views/settings/billing/stores';
import { PaymentNavigation } from '../../../constant';
import { usePaymentSuccess } from '../../hooks';

export const PaymentSuccessPage = () => {
	const choose = useRecoilValue(ActivePaymentType);
	const addPaymentNaviagtion = useRecoilValue(PaymentNavigationState);
	const { hasBillingAddress, handleClose, addBillingInfo } =
		usePaymentSuccess();

	const isCardSelect = useMemo(() => {
		if (
			addPaymentNaviagtion === PaymentNavigation.CardView ||
			choose === PaymentNavigation.CardView
		)
			return 'Card';
		else if (
			addPaymentNaviagtion === PaymentNavigation.AchView ||
			choose === PaymentNavigation.AchView
		)
			return 'Bank account';
		else return 'Wire account';
	}, [choose, addPaymentNaviagtion]);

	return (
		<div className="SuccessPage">
			<div className="SuccessPage--icon">
				<Image fileName="success.gif" loading="lazy" />
			</div>
			<div className="SuccessPage--info">
				<h2 className="SuccessPage--info__heading">
					{isCardSelect} added successfully
				</h2>
				<p className="SuccessPage--info__desc">
					You&apos;ve successfully added a {isCardSelect} for payment.
				</p>
			</div>
			{!hasBillingAddress ? (
				<Button
					handleClick={addBillingInfo}
					label="Add billing info"
					type="button__filled button__filled--primary button__large"
				/>
			) : (
				<Button
					handleClick={handleClose}
					label="Close"
					type="button__filled button__filled--secondary button__large"
				/>
			)}
		</div>
	);
};
