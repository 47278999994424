import { FC } from 'react';
import { Label } from '../condition-label';

export interface IRenderBlock {
	label?: string | undefined;
	price: number;
}

export const RenderProofReadingBlock: FC<IRenderBlock> = ({ label, price }) => {
	// Global variables

	// Local variables

	return (
		<>
			{/* Arun Kumar : For Sign Aggrement verification changed into Packet  */}
			<Label label={label || ''} price={price} priceLabel="Packet" />
			<div className="questions-wrapper">
				<span className="fw-600">Note: </span>Approved documents shown to the
				user for read.
			</div>
		</>
	);
};
