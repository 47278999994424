import {
	Dispatch,
	FC,
	SetStateAction,
	createRef,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { useOutsideClick } from 'hooks';

interface IProps {
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	children: JSX.Element;
	elementId: string;
}
export const SignedDocMenuTab: FC<IProps> = ({
	setIsOpen,
	children,
	elementId,
}) => {
	const ref = createRef<HTMLDivElement>();
	const [containerDimension, setContainerDimension] = useState({
		containerTop: 0,
		containerBottom: 0,
	});

	useOutsideClick(ref, () => {
		setIsOpen(false);
	});

	const handleKeyPress = useCallback(
		(event: any) => {
			if (event.key.toLowerCase() === 'escape') {
				setIsOpen(false);
			}
		},
		[setIsOpen]
	);

	const actionElement = document.getElementById(elementId);

	useEffect(() => {
		const element = document.getElementById('react-table-responsive-table');
		if (element) {
			const { top: containerTop, bottom: containerBottom } =
				element.getBoundingClientRect();
			setContainerDimension({
				containerBottom,
				containerTop,
			});
		}
	}, []);

	const menuTabDirection: () => 'top' | 'bottom' = () => {
		let direction: 'top' | 'bottom' = 'bottom';
		if (actionElement) {
			const { containerBottom } = containerDimension;
			const { bottom } = actionElement.getBoundingClientRect();
			if (containerBottom - bottom > 60) {
				direction = 'top';
			}
		}
		return direction;
	};

	return (
		<div
			className={`SignedDocMenuTab status-box SignedDocMenuTab--${menuTabDirection()}`}
			ref={ref}
			onKeyDown={handleKeyPress}
		>
			{children}
		</div>
	);
};
