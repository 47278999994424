import Tippy from '@tippyjs/react';
import './kyb-price-tooltip.scss'
import { FC } from 'react';

export interface IKybPricingDetails {
    addressVerification: string,
    business_sanctions_ofac: string,
    chequeFraud: string,
    idv: string,
    kybSubsidiary: string,
    kyb_comprehensive: string,
    kyb_enhanced: string,
    kycComprehensive: string,
    representative_kyc_aml_verification: string,
    ssn: string,
}

interface IPricingProps {
    kybPricing: Partial<IKybPricingDetails>
}

export const KybPriceTooltip: FC<IPricingProps> = ({ kybPricing }) => {
    return (
        <Tippy
            interactive={true}
            content={
                <div className="kyb-tooltip">
                    <div className="kyb-tooltip__section kyb-tooltip__section--fixed">
                        <div className="kyb-tooltip__section-title">Fixed Prices</div>
                        <div className="kyb-tooltip__item">
                            <div className="kyb-tooltip__item-name">KYB Enhanced</div>
                            <div className="kyb-tooltip__item-price">$ {kybPricing.kyb_enhanced}</div>
                        </div>
                        <div className="kyb-tooltip__item">
                            <div className="kyb-tooltip__item-name">KYB Comprehensive</div>
                            <div className="kyb-tooltip__item-price">$ {kybPricing.kyb_comprehensive}</div>
                        </div>
                    </div>
                    <div className="kyb-tooltip__section kyb-tooltip__section--variable">
                        <div className="kyb-tooltip__section-title">Variable Prices</div>
                        <div className="kyb-tooltip__item">
                            <div className="kyb-tooltip__item-name">KYB for Subsidiary</div>
                            <div className="kyb-tooltip__item-price">X * $ {kybPricing.kybSubsidiary}</div>
                        </div>
                        <div className="kyb-tooltip__item">
                            <div className="kyb-tooltip__item-name">Representative Verification</div>
                            <div className="kyb-tooltip__item-price">Y * $ {kybPricing.representative_kyc_aml_verification}</div>
                        </div>
                    </div>
                    <div className="kyb-tooltip__note">
                        <span className="kyb-tooltip__note-label">Where,</span> X is the number of subsidiaries
                    </div>
                    <div className="kyb-tooltip__note--representatives">
                        Y is the number of representatives
                    </div>
                </div>
            }
        >
            <i className="ri-question-fill" id="ri-question-fill" />
        </Tippy>
    );
};