import { FC, MouseEvent, useCallback, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { Button, Loader } from '@storybook';
import { useNotification, useOutsideClick } from 'hooks';

import { DownloadPdfOptions } from 'views/transactions/constants';
import { SelectedTransactionIdsState, useDownloadPdf } from 'views/transactions/stores';
import './export-pdf.scss';

interface IExportPdf {
	label: 'Download' | 'Download Zip';
}

export const ExportPdf: FC<IExportPdf> = ({ label }) => {
	// globle states
	const selectedTransactionIds = useRecoilValue(SelectedTransactionIdsState);

	//local state
	const [isPopupOpen, setPopupOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	//hook
	const refPopup: any = useRef();
	const { downloadPdf } = useDownloadPdf();
	const { errorNotification } = useNotification();

	useOutsideClick(refPopup, () => {
		setPopupOpen(false);
	});

	const handleDownload = useCallback(
		async (e: MouseEvent<HTMLDivElement>, complianceData: boolean) => {
			e.stopPropagation();
			setPopupOpen(false);
			setIsLoading(true);
			const status = await downloadPdf(label, complianceData);
			setIsLoading(!!status);
		},
		[downloadPdf, label]
	);

	const downloadPopup = useMemo(
		() => (
			<div
				onClick={e => e.stopPropagation()}
				className={`export-pdf__popup-wrapper ${
					label === 'Download' ? 'export-pdf__popup-wrapper__for-modal' : ''
				}`}
			>
				{DownloadPdfOptions &&
					DownloadPdfOptions.map(({ complianceData, label }) => (
						<div
							onClick={(e: MouseEvent<HTMLDivElement>) =>
								handleDownload(e, complianceData)
							}
							key={label}
							className="export-pdf__popup-btn"
						>
							{label}
						</div>
					))}
			</div>
		),
		[handleDownload, label]
	);

	const handleOnClick = useCallback(() => {
		if (label === 'Download Zip' && !selectedTransactionIds.length)
			errorNotification('Please select atleast 1 transaction to download zip');
		else setPopupOpen(pre => !pre);
	}, [errorNotification, label, selectedTransactionIds.length]);

	return (
		<>
			<div ref={refPopup}>
				<Button
					handleClick={handleOnClick}
					label={
						<div className="export-pdf__label-wrapper">
							{label} |{' '}
							{!isLoading ? (
								<i className="ri-arrow-down-s-fill" />
							) : (
								<Loader type="loader" className="loader-white" dimension={16} />
							)}
							{isPopupOpen && downloadPopup}
						</div>
					}
					type="button button__filled--primary download-zip"
					disabled={isLoading}
				/>
			</div>
		</>
	);
};
