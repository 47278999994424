export const scrollToIndex = (id: string) => {
	if (!id) {
		return;
	}
	const inputElement = document.getElementById(id);
	if (inputElement) {
		inputElement.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
		});
	}
};
