export const sessionLabelData = {
	kyc: 'KYC',
	'kyc(with liveness)': 'kyc(with liveness)',
	fundInvestment: 'Fund Investment',
	'506b': 'Accreditation',
	'506c': 'Accreditation',
	aml: 'AML',
	signAgreement: 'Sign Agreement',
	signAgreement_esign: 'signAgreement_esign',
	signAgreement_docusign: 'signAgreement_docusign',
	kyb: 'KYB',
};

export const SubsCriptionType: any = {
	onboarding: 'Onboarding Services',
	simpliciSign: 'Sign Services',
	simpliciText: 'Text Messaging Services',
};

export const HEADER = [
	{
		label: 'Name',
		key: 'name',
		format: 'string',
		width: '25%',
	},
	{
		label: 'Email & Contact',
		key: 'email',
		format: 'jsx',
		width: '25%',
	},
	{
		label: 'Date & Time',
		key: 'date',
		format: 'jsx',
		width: '25%',
	},
	{
		label: 'Status',
		format: 'jsx',
		key: 'status',
		width: '25%',
	},
];

export const STATUS_VALUE = ['rejected', 'completed', 'failed', 'processing'];

export const STATUS_COLOR: any = {
	failed: 'red',
	completed: 'green',
	rejected: 'red',
	processing: 'orange',
};

export const BillingLabelData: { [key: string]: string } = {
	kyc: 'KYC (Know your customer)',
	kyb: 'KYB (Know your business)',
	accrditation: 'Accreditation',
	signAgreement: 'Sign Agreement',
	accreditation_506b: 'Accreditation 506(b)',
	accreditation_506c: 'Accreditation 506(c)',
	aml: 'AML (PEPs & sanctions, adverse media)',
	others: 'Others',
	fundInvestment: 'Fund Investment',
	docusignSignAgreement: 'DocuSign Agreement',
	payIn: 'Pay In',
	payOut: 'Payout',
	liveness: 'Liveness Test & Biometric Similarity',
	geoLocation: 'Geolocation Check',
	webComponent: 'IFrame, API, Web Component',
	simpliciSignAgreement: 'esign Agreement',
	simpliciSign : 'esign'
};
