import { ScreenSkelton } from '@storybook';
import { FC, Fragment, useEffect, useMemo } from 'react';

import { ComplexMetaDataId, pipelineMobileScreen } from './constants';

import './mobile-ui-screen.scss';
import { useSetRecoilState } from 'recoil';

interface IMobileUIScreen {
	componentName: string;
	id?: string;
}
export const MobileUIScreen: FC<IMobileUIScreen> = ({ componentName, id }) => {
	const setComplexMetaDataId = useSetRecoilState(ComplexMetaDataId);

	useEffect(() => {
		if (id) {
			setComplexMetaDataId(id);
		}
	}, [setComplexMetaDataId, id]);

	const skeltonContent = useMemo(() => {
		return (
			pipelineMobileScreen[componentName] ?? pipelineMobileScreen['emptyScreen']
		);
	}, [componentName]);

	const { header, body, footer } = skeltonContent ?? {};

	const screenFooter = useMemo(() => {
		return <Fragment>{footer}</Fragment>;
	}, [footer]);

	return <ScreenSkelton header={header} body={body} footer={screenFooter} />;
};
