import { Loader } from '@storybook';
import { FC, useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { loginState } from 'global-stores';
import { envHost } from 'helpers';
import { useNetwork } from 'hooks';
import { InjectScript } from 'utils/script';
import { REACT_APP_SESSION_WEB_COMPONENT as WEB_COMPONENT } from 'envs';
import './business-session.scss';
import { SignUpSessionStatusState } from 'views/new-user-popup';
import { useCompliance } from 'routes/hooks';

declare global {
	// eslint-disable-next-line @typescript-eslint/no-namespace
	namespace JSX {
		interface IntrinsicElements {
			'session-details': any;
		}
	}
}

interface IBusinessSession {
	handleError?: (e: any) => void;
}

export const BusinessSession: FC<IBusinessSession> = ({ handleError }) => {
	const userDetails = useRecoilValue(loginState);
	const [isLoading, setIsLoading] = useState(true);
	const [sessionId, setSessionId] = useState('');
	const signUpSessionStatusState = useRecoilValue(SignUpSessionStatusState);

	const { get } = useNetwork();
	const { getComplianceData } = useCompliance();

	const handleSessionId = useCallback(async () => {
		try {
			if (userDetails?.business) {
				const res = await get(
					`/business-compliance?businessId=${userDetails.business}`
				);
				if (res?.data?.[0]?.sessionDetails?.[0]?.sessionId) {
					setSessionId(res.data[0].sessionDetails[0].sessionId);
				} else {
					handleError?.(res);
				}
			}
		} finally {
			setIsLoading(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const appendScripts = async () => {
		await getComplianceData();
		await InjectScript([
			{
				type: 'script',
				link: WEB_COMPONENT,
			},
		]);
		await handleSessionId();
		setIsLoading(false);
	};

	useEffect(() => {
		appendScripts();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="BusinessSession--container">
			{isLoading ? (
				<div className="BusinessSession--loader">
					<Loader />
				</div>
			) : (
				<div
					className="BusinessSession--container--web-component"
					dangerouslySetInnerHTML={{
						__html: `<session-details
							session_id=${sessionId}
							token=${userDetails.accessToken}
							comments="true"
							env=${envHost}
							type="sessions"
							compliance_data="false"
							url="/super-admin-session-details"
							action_disable="true"
							session_type="complex"
							is_pdf="false"
							session_id_list=${JSON.stringify(signUpSessionStatusState?.sessionIds)}
							is_admin="true"
							businessId=${signUpSessionStatusState?.businessId}
							custom_loader="true"
						/>`,
					}}
				/>
			)}
		</div>
	);
};
