import { useMemo } from 'react';
import './kyc-basic-info-tree.scss';
import { fromCamelCaseToTitleCase } from 'utils';
import classNames from 'classnames';
import { KYCIDTYPE } from 'views/sessions/hooks';

export const KycBasicFlowChart = ({ breakdownList, idType }: any) => {
	const getFlowChart = useMemo(() => {
		const keys = Object.keys(breakdownList ?? {});
		return (
			<div className="kyc-basic-flow-chart__wrapper">
				{keys?.map((key: string) => {
					const breakdownKeys = Object.keys(
						breakdownList[key]?.breakdown ?? {}
					);
					const { breakdown, result: overallResult } = breakdownList[key];
					const iconContainer = classNames(
						'kyc-basic-flow-chart__container-heading-icon',
						{
							'subtract-icon': overallResult === 'rejected',
							'check-icon': overallResult === 'completed',
							'processing-icon': overallResult === 'processing',
							'empty-icon':
								overallResult === null
									? JSON.stringify(overallResult) === 'null'
									: overallResult === 'null',
						}
					);
					const resultIcon = classNames('', {
						'ri-check-line': overallResult === 'completed',
						'ri-subtract-line':
							overallResult === 'rejected' || overallResult === 'processing',
					});
					return (
						<div className="kyc-basic-flow-chart__container" key={key}>
							<div className="kyc-basic-flow-chart__container-heading">
								<span className={iconContainer}>
									<i className={resultIcon}></i>
								</span>
								<p className="kyc-basic-flow-chart__container-heading-title">
									{fromCamelCaseToTitleCase(key)}
								</p>
							</div>
							<div className="kyc-basic-flow-chart__container--sub-heading">
								{breakdownKeys.map((breakdownKey: string) => {
									const { result, properties } = breakdown[breakdownKey];
									const propertiesKeys = Object.keys(properties ?? {});
									const iconContainer = classNames(
										'kyc-basic-flow-chart__container--sub-heading-item-status-icon',
										{
											'subtract-icon': result === 'rejected',
											'check-icon': result === 'completed',
											'processing-icon': result === 'processing',
											'empty-icon':
												result === null
													? JSON.stringify(result) === 'null'
													: result === 'null',
										}
									);
									const resultIcon = classNames('', {
										'ri-check-line': result === 'completed',
										'ri-subtract-line':
											result === 'rejected' || result === 'processing',
									});
									return (
										<div key={breakdownKey}>
											<div className="kyc-basic-flow-chart__container--sub-heading-item">
												<span className={iconContainer}>
													<i className={resultIcon}></i>
												</span>

												<p className="kyc-basic-flow-chart__container--sub-heading-item--title">
													{fromCamelCaseToTitleCase(breakdownKey)}
												</p>
											</div>
											{propertiesKeys?.map(propertiesKey => (
												<li
													key={propertiesKey}
													className="kyc-basic-flow-chart__container--sub-heading-item-properties"
												>
													{properties[propertiesKey]}
												</li>
											))}
										</div>
									);
								})}
							</div>
						</div>
					);
				})}
			</div>
		);
	}, [breakdownList]);

	const documentName = useMemo(() => {
		return KYCIDTYPE[idType] ?? idType?.replaceAll('_', ' ') ?? 'document';
	}, [idType]);

	if (typeof breakdownList !== 'object' || !breakdownList) return <></>;

	return (
		<div className="kyc-basic-flow-chart">
			<p className="kyc-basic-flow-chart__sub-heading">
				KYC data is taken from the {documentName}.
			</p>
			{getFlowChart}
		</div>
	);
};
