import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { SelectedPipelineDetails } from 'global-stores';

// Define the Action type
interface IAction {
	actionId: string;
	metadata: {
		payInPayOut: string;
		payInUnitPricing?: number; // Assuming this can be a number or undefined
	};
}

export const usePayInUnitPricing = () => {
	// Fetch the current value of the 'SelectedPipelineDetails'
	const pipelineDetails = useRecoilValue(SelectedPipelineDetails);

	const isPayInUnitPricing = useMemo(() => {
		// Find the 'fundInvestment' step in the pipeline nodes
		const fundInvestment = pipelineDetails?.nodes?.find(
			({ stepId }: { stepId: string }) => stepId === 'fundInvestment'
		);

		// Extract the payInUnitPricing value only if the conditions are met
		const payInUnitPricing = fundInvestment?.actions.find(
			(action: IAction) =>
				action.actionId === 'fundInvestmentVerification' &&
				action.metadata.payInPayOut === 'payIn'
		)?.metadata?.payInUnitPricing;

		// Return true if payInUnitPricing exists, otherwise false
		return !!payInUnitPricing;
	}, [pipelineDetails?.nodes]); // Dependency array: recalculates when pipelineDetails.nodes changes

	return {
		isPayInUnitPricing,
	};
};
