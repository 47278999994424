import { atom } from 'recoil';
import { v4 } from 'uuid';

export const ComplexInitPipelines = atom<any>({
	key: 'complex-init-pipelines ',
	default: {},
});

export const NodesState = atom<any>({
	key: 'nodes-state',
	default: [],
});

export const EdgesState = atom<any>({
	key: 'edges-state',
	default: [],
});

export const FormFlowActionStepNodes = atom<any>({
	key: 'form-action-step-state-nodes',
	default: {
		multipleChoice: [],
	},
});

export const KycOperatorKey = atom<Record<string, object>>({
	key: 'kyc-operator-state',
	default: {},
});

export const ComplexFlowConditionState = atom<any>({
	key: 'complex-flow-condition:state',
	default: {},
});

export const KYBActionDataState = atom<any>({
	key: 'kyb-action-state-date',
	default: [],
});

export const AmlActionDataState = atom<any>({
	key: 'aml-action-state-date',
	default: [],
});

export const ComplexFlowCondtionsState = atom<any>({
	key: 'complex-flow-conditions',
	default: {},
});

export const isComplexViewOnlyState = atom({
	key: 'is-complex-view-only-state',
	default: false,
});

export interface IComplexFlowUnconfiged {
	id: string;
	configed: boolean;
	stepId: string;
}

export const ComplexFlowUnconfigedState = atom<IComplexFlowUnconfiged[]>({
	key: v4(),
	default: [],
});

export const ComplexConfigModalState = atom({
	key: v4(),
	default: false,
});

export const ComplexConfigFromState = atom<'NEXT' | 'INDIVIDUAL' | ''>({
	key: v4(),
	default: '',
});

export const ConfigFormModalState = atom<boolean>({
	key: 'config-form-modal-state-key',
	default: false,
});

export const ConfigComplexNodeId = atom<string>({
	key: 'config-complex-node-id',
	default: '',
});

export interface IComplexThenBlock {
	key: string;
	label: string;
}

export interface IOption {
	label: string;
	value: string;
}

export const ComplexDynamicFormPathsState = atom<{ [key: string]: IOption }>({
	key: 'complex-dynamic-form-paths-state-key',
	default: {},
});

export const ComplexDynamicFormStatusState = atom<{ [key: string]: IOption }>({
	key: 'complex-dynamic-form-status-state-key',
	default: {},
});

export const ComplexErrorSteps = atom<string[]>({
	key: 'Complex-Error-Steps',
	default: [],
});
