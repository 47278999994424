/* eslint-disable react/no-array-index-key */
import { FC, useCallback, useMemo, useState } from 'react';
import { KycComperhensiveCard } from './kyc-comperhensive-card';
import { BasicSection, KybInfoHeader, SectionCount } from 'views/sessions/components/kyb-wrapper';
import KycDropCard from './kyc-drop-card';
import { KycDropCloseCard } from './kyc-comperhensive-close-card';
import { KycKeys } from '../constants';

interface IProps {
	sectionData?: any;
	showViewMoreBtn?: boolean;
}

export const KycComperhensiveSectionHOC: FC<IProps> = ({
	sectionData,
	showViewMoreBtn = true,
}) => {
	const { label, data } = useMemo(()=> sectionData ?? {},[sectionData]) ;
	const  {
		totalAboutSection,
		metrics,
		metricsIcon,
		metricsHeading,
		userInformationHeading,
		userInformationIcon,
		userInformation,
		sectionType,
	} = useMemo(()=> data ?? {},[data]);
	
	const [showMoreDetails, setShowMoreDetails] = useState(false);

	const toggleMoreButton = useCallback(() => {
		setShowMoreDetails(!showMoreDetails);
	}, [showMoreDetails]);

	const getCardLabel = useCallback(
		(card: {
			title: {
				number: string | number;
				name: string;
				vehicleRecordId: string | number;
				aircraftNumber: string | number;
				sourcePropertyRecordId: string | number;
			};
		}) => {
			return (
				card?.title?.number ??
				card?.title?.name ??
				card?.title?.vehicleRecordId ??
				card?.title?.aircraftNumber ??
				card?.title?.sourcePropertyRecordId ??
				'--'
			);
		},
		[]
	);

	return (
		<>
			<div className="kyc-comp__header--wrapper">
				<div className="kyc-comp__header">
					<KybInfoHeader type={label ?? '--'} />
				</div>
				<div className="kyc-comp__vertical-line"></div>
				{showViewMoreBtn && (
					<div onClick={toggleMoreButton} className="kyc-comp__view-more">
						{showMoreDetails ? 'View less' : 'View more'}
					</div>
				)}
			</div>
			{totalAboutSection && (
				<SectionCount
					count={totalAboutSection?.count ?? 0}
					label={totalAboutSection?.title ?? 'Total'}
				/>
			)}
			<div className="kyc-comp__drop--card-section">
				{userInformation && metrics?.length > 1 && (
					<KycComperhensiveCard
						key={userInformation}
						cardLabel={userInformationHeading}
						cardData={userInformation}
						userInformationIcon={userInformationIcon}
					/>
				)}

				{sectionType === 'DropCard' && (
					<KycDropCard
						key={123123}
						cardData={metrics}
						cardLabel={metricsHeading}
						cardIcon={metricsIcon}
					/>
				)}

				{sectionType === 'DropCloseCard' && (
					<>
						{(metrics ?? []).map((card: any, index: number) => {
							if (!showMoreDetails && index >= 2) return null;
							return (
								<KycDropCloseCard
									key={index}
									cardInfo={card?.title ?? {}}
									cardData={[card]}
									cardIcon={metricsIcon}
									cardLabel={getCardLabel(card ?? {})}
								/>
							);
						})}
					</>
				)}
			</div>
			{sectionType === 'NormalCard' &&
				(metrics ?? []).map((metric: any, metricIndex: number) => {
					if (!showMoreDetails && metricIndex >= 2) return null;
					return (
						<div className="kyc-comp__metric--wrapper" key={metricIndex}>
							{Object.entries(metric ?? {}).map(([key, value], index) =>
								Array.isArray(value) ? (
									<KycComperhensiveCard
										key={index}
										cardLabel={key}
										cardData={value}
									/>
								) : typeof value === 'string' ? (
									<BasicSection
										key={index}
										label={KycKeys[key] ?? key}
										value={value}
									/>
								) : (
									<div className="kyc-comp__other-information" key={index}>
										{key !== 'otherInformation' && <div>{key ?? '--'}</div>}
										{typeof value === 'object' &&
										value !== null &&
										!Array.isArray(value)
											? Object.entries(value ?? {}).map(
													([key, value], index) => (
														<BasicSection
															key={index}
															label={KycKeys[key] ?? key}
															value={value}
														/>
													)
											  )
											: null}
									</div>
								)
							)}
						</div>
					);
				})}
		</>
	);
};
