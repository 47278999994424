import { ChangeEvent, createRef, useCallback, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import classNames from 'classnames';

import { Button, Loader } from '@storybook';
import { useOutsideClick } from 'hooks';
import {
	useSessionsTextFilter,
	SessionTextFilterState,
	FoundFilterSessionsCountState,
	TextForFilterSessionsState,
} from './stores';

import './text-filter.scss';

export const TextFilter = () => {
	// globle states
	const [sessionFilterText, setSessionFilterText] = useRecoilState(
		SessionTextFilterState
	);
	const foundSessionsCount = useRecoilValue(FoundFilterSessionsCountState);
	const textForFilterSession = useRecoilValue(TextForFilterSessionsState);

	// local states
	const [textFilterOpen, setTextFilterOpen] = useState(false);

	// refs
	const refOnboarding = createRef<HTMLDivElement>();
	useOutsideClick(refOnboarding, () => {
		setTextFilterOpen(false);
	});

	// hooks
	const { filterSessions, resetingSessionsFilter, fetchingSessions } =
		useSessionsTextFilter();

	const classes = useCallback(
		(check: boolean) =>
			classNames('ri-arrow-down-s-line', {
				'filter-popup__opened': check,
				'filter-popup__closed': !check,
			}),
		[]
	);

	const handleTextChange = useCallback(
		(e: ChangeEvent<HTMLTextAreaElement>) => {
			let { value } = e.target ?? {};
			if (value?.trim()?.startsWith(',')) {
				value = value.replace(',', '');
			}
			setSessionFilterText(value);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const handleFilter = useCallback(
		() => filterSessions(sessionFilterText),
		[filterSessions, sessionFilterText]
	);

	const renderSessionIdOpen = useMemo(
		() => (
			<div className="SessionTextFilter--text-wrapper">
				<textarea
					name="some area"
					className="input__field text-area-fieldd SessionTextFilter--text-area"
					rows={5}
					value={sessionFilterText}
					placeholder={''}
					onChange={handleTextChange}
				/>
				<Button
					label={
						fetchingSessions && !resetingSessionsFilter ? (
							<Loader type="loader" dimension={18} />
						) : (
							<i className="ri-filter-fill" />
						)
					}
					type="SessionTextFilter--filterBtn"
					handleClick={handleFilter}
					disabled={fetchingSessions && !resetingSessionsFilter}
				/>
			</div>
		),
		[
			sessionFilterText,
			handleTextChange,
			fetchingSessions,
			handleFilter,
			resetingSessionsFilter,
		]
	);

	const handleClickSessionId = useCallback(
		() => setTextFilterOpen(!textFilterOpen),
		[textFilterOpen]
	);

	return (
		<div
			ref={refOnboarding}
			className="SessionTextFilter--wrapper  bottom-touch"
		>
			<div className="SessionTextFilter--header" onClick={handleClickSessionId}>
				<div>
					<div className="SessionTextFilter--label">
						Session ID or Phone Number
					</div>
					<span className="SessionTextFilter--description">
						{textForFilterSession
							? (foundSessionsCount ?? 0) + ' Found'
							: 'Please paste Session Ids or Phone Numbers in comma separated values only'}
					</span>
				</div>
				<i className={classes(textFilterOpen)} />
			</div>
			{textFilterOpen && renderSessionIdOpen}
		</div>
	);
};
