import { CompanyFormData, PersonalFormData } from '../store';

export const isValidORDateGreaterThanToday = (date: any) => {
	const currentDate = new Date();
	const { day, month, year } = date ?? {};

	const inputDate = new Date(year, month, day);

	const isValidDate =
		day >= 1 &&
		day <= 31 &&
		month > 0 &&
		month <= 12 &&
		year >= 1925 &&
		inputDate < currentDate;
	if (!isValidDate) {
		return true;
	} else return false;
};

export const handleDateFormat = (date: any) => {
	if (date) {
		const currentDate = new Date(date);
		const currentDay = currentDate.getDate();
		const currentMonth = currentDate.getMonth() + 1; // Months are zero-based, so January is 0
		const currentYear = currentDate.getFullYear();
		const result = {
			year: currentYear,
			month: currentMonth,
			day: currentDay,
		};
		return result;
	}
	return {};
};

export const parseBusinessFormData = (
	payload: CompanyFormData,
	businessId: string | undefined
) => {
	const {
		firstName,
		lastName,
		companyName,
		website,
		ein,
		linkedIn,
		phone,
		address,
		zip,
		city,
		state,
		// country,
	} = payload;
	return {
		requestType: 'kyb',
		kyb: {
			businessId,
			name: companyName.trim(),
			website,
			fein: ein.trim(),
			linkedInUrl: linkedIn,
			address: {
				street_address1: address,
				postal_code: zip,
				city,
				state,
				// country,
				phone,
			},
			person: {
				firstName,
				lastName,
			},
		},
	};
};

export const parsekycFormData = (payload: PersonalFormData) => {
	const {
		firstName,
		lastName,
		address,
		city,
		state,
		zip,
		phone,
		country,
		dob,
		idType,
		idNumber,
	} = payload;
	return {
		kyc: {
			firstName,
			lastName,
			streetAddress: address,
			city,
			state,
			zip5: zip,
			homePhone: phone,
			country,
			dob,
			idType: idType,
			idNumber: idNumber,
		},
		requestType: 'kyc',
	};
};

export const showLastDigit = (input: string, label = 'SSN') => {
	// Check if the provided SSN is a valid 9-digit number

	if (!input) return 'N/A';
	// Remove non-alphanumeric characters
	const alphanumericInput = input.replace(/[^a-zA-Z0-9]/g, '');

	// Check if the cleaned input is valid (at least 4 characters)
	if (alphanumericInput.length < 4) return 'N/A';

	// Extract the part to keep (last 4 characters)
	const partToKeep = alphanumericInput.slice(-4);
	const maskedPart = /SSN/.test(label) ? 'XXX-XX-' : 'XXXXX';

	// Combine the masked part with the part to keep
	const maskedNumber = maskedPart + partToKeep;
	return maskedNumber;
};
