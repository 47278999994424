/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { loginState } from 'global-stores';
import { useFreePlan } from 'hooks/free-plan';
import { AcountUserTypes } from 'views/settings';

type IServiceType = 'onboarding' | 'simpliciSign' | 'simpliciText';

export const useSubscription = () => {
	const { isServiceSubscribed, accountType } = useFreePlan();

	const checkSubscription = (_serviceName: IServiceType) => {
		if (!accountType) return false;

		if (isServiceSubscribed && accountType === AcountUserTypes.Entity)
			return true;

		if (isServiceSubscribed && accountType === AcountUserTypes.Person)
			return true;
		else return false;
	};

	return { checkSubscription };
};

export const useSandboxAndFreeTrial = () => {
	const { checkSubscription } = useSubscription();
	const { sandboxStatus } = useRecoilValue(loginState);

	const isSandboxMode = useMemo(
		() => sandboxStatus || !checkSubscription('onboarding'),
		[checkSubscription, sandboxStatus]
	);

	return { isSandboxMode };
};
