import { KYB_TYPE_TABS } from '../../hooks';

interface KybTypeSelectorProps {
	configurations: string[];
	selectedKybType: string;
	setSelectedKybType: (type: string) => void;
}

export const KybTypeSelectorSection: React.FC<KybTypeSelectorProps> = ({
	configurations,
	selectedKybType,
	setSelectedKybType,
}) => {
	return (
		<>
			{(configurations ?? []).length > 1 && (
				<div className="kyb-wrapper__kyb-type-wrapper">
					{(configurations ?? []).map(item => (
						<span
							key={item}
							className={`kyb__type ${
								item === selectedKybType ? 'kyb-active' : ''
							}`}
							onClick={() => setSelectedKybType(item)}
						>
							{KYB_TYPE_TABS[item] ? KYB_TYPE_TABS[item] : item}
						</span>
					))}
				</div>
			)}
		</>
	);
};
