import { Image } from '@storybook/image';

import './error-page.scss';

type StatusType = 400 | 404 | 500;

interface Props {
	status?: StatusType;
	heading?: string;
	description?: string | JSX.Element;
	actionContent?: JSX.Element;
	imageName?: string;
}

/**
 * Interface for the ErrorPage component props.
 * @param {StatusType} Props.status - HTTP status code (400, 404, or 500) default is 500.
 * @param {string} Props.heading - Heading or title for the error page.
 * @param {string | JSX.Element} Props.description - Description or content for the error page.
 * @param {JSX.Element} Props.actionContent - Additional action content for the error page.
 */

export const ErrorPage = ({
	status = 500,
	heading = '',
	description = '',
	actionContent = <></>,
	imageName = '',
}: Props) => {
	const getImage = {
		400: '400.svg',
		404: '404.svg',
		500: '500.svg',
	};

	const props = {
		...(!imageName
			? { fileName: getImage[status] ?? getImage['500'] }
			: { fileName: imageName }),
		className: 'error-wrapper__image',
		alt: status,
	};

	return (
		<div className="error-wrapper">
			<Image {...props} />
			<div className="error-wrapper__text-container">
				{heading && <div className="error-wrapper__heading">{heading}</div>}

				{description && (
					<div className="error-wrapper__desc">{description}</div>
				)}

				{actionContent}
			</div>
		</div>
	);
};
