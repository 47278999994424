import { atom } from 'recoil';
import { data } from './constants';
import { IAtoms } from 'types';
import { ISignedDocInbox } from './types';

export const SignedDocInboxState = atom<IAtoms<ISignedDocInbox[]>>({
	key: 'signed-doc-inbox-state-key',
	default: {
		isLoaded: false,
		error: false,
		data: data,
	},
});

export const EsignDratDocState = atom<IAtoms<ISignedDocInbox[]>>({
	key: 'esign-drat-doc-state-key',
	default: {
		isLoaded: false,
		error: false,
		data: data,
	},
});

export const IsStatusCollapseState = atom<boolean>({
	key: 'is-status-collapse-state-key',
	default: false,
});

export const IsEnvelopeTypeCollapseState = atom<boolean>({
	key: 'is-envelope-type-collapse-state-key',
	default: false,
});

export const InboxfilteringTagsState = atom<any>({
	key: 'inbox-fitlering-tags-state-key',
	default: {},
});

export const InboxfiltersIndicatorState = atom<number>({
	key: 'inbox-filter-indicators-state-key',
	default: 0,
});

export const EnvelopeLinkUnlinkPayload = atom<{linkEnvelopes: string[], unlinkEnvelopes: string[]}>({
	key: 'envelope-link-unlink-payload-state-key',
	default: {linkEnvelopes: [], unlinkEnvelopes: []},
});