import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import axios from 'axios';

import { REACT_APP_API_HOST as API_HOST } from 'envs';
import { loginState } from 'global-stores';
import { API_URL } from 'constant';
import { useCookie, useRefreshCookie } from 'hooks/cookie';
import { moveToLoginPage } from 'hooks/components';

export const useUpdateUserToken = () => {
	const { accessToken } = useRecoilValue(loginState);
	const { set: setCookie, deleteCookie } = useCookie();
	const { get: getRefreshCookie, deleteCookie: deleteRefreshCookie } =
		useRefreshCookie();
	const setLoginState = useSetRecoilState(loginState);

	const updateToken = useCallback(async () => {
		try {
			const response = await axios.post(
				API_HOST + API_URL.VERIFY_REFRESH_TOKEN,
				{
					accessToken: accessToken,
					refreshToken: getRefreshCookie('auth_refresh_token'),
				}
			);
			if (response?.data?.data?.accessToken) {
				setLoginState(prevState => ({
					...prevState,
					accessToken: response?.data?.data?.accessToken,
				}));
				const cookieData = {
					accessToken: response?.data?.data?.accessToken,
				};
				setCookie('user', { ...cookieData });
				return true;
			}
			return true;
		} catch (error:any) {
			if(error?.response?.data?.message !== 'Access token is not expired yet'){
				await moveToLoginPage();
				deleteCookie('user');
				deleteRefreshCookie('auth_refresh_token');
				localStorage.clear();
				window.location.href = '/login';
			}
			
			return false;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accessToken]);

	return { updateToken };
};
