import { Input, TextArea } from '@storybook';
import { message } from 'constant';
import { UserRolesState } from 'global-stores';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
	RoleAndPermissionNavigation,
	UserRoleIds,
} from 'views/user-roles/constant';

import {
	AddNewRoleFormState,
	PermissionNavigation,
	SelectedUserRoleState,
} from 'views/user-roles/store';
const specialCharRegex = /^[a-zA-Z0-9 ]*$/;

export const UserInputRole = () => {
	const [addRoleForm, setAddRoleForm] = useRecoilState(AddNewRoleFormState);
	const { name, description } = addRoleForm;
	const [selectedUserRole] = useRecoilState(SelectedUserRoleState);
	const userRoles = useRecoilValue(UserRolesState);
	const navigate = useRecoilValue(PermissionNavigation);
	const [nameError, setNameError] = useState({
		changeName: false,
		nameLength: false,
		onBlurError: false,
		uniqueName: false,
	});
	const [autoFocus, setAutoFocus] = useState<boolean>(false);
	const [nameToEdit, setNameToEdit] = useState<string>('');

	const {
		VALID_NAME_MESSAGE,
		NAME_REQUIRED,
		NAME_UNIQUE,
	} = useMemo(() => message, []);

	const isError = useMemo(
		() =>
			nameError.nameLength ||
			nameError.onBlurError ||
			nameError.uniqueName,
		[
			nameError.nameLength,
			nameError.onBlurError,
			nameError.uniqueName,
		]
	);

	const errorMessage = useMemo(
		() =>
			
				nameError.nameLength
				? VALID_NAME_MESSAGE
				: nameError.onBlurError
				? NAME_REQUIRED
				: nameError.uniqueName
				? NAME_UNIQUE
				: '',
		[
			nameError.nameLength,
			nameError.onBlurError,
			nameError.uniqueName,
			NAME_REQUIRED,
			VALID_NAME_MESSAGE,
			NAME_UNIQUE,
		]
	);

	const existingUserRoles = useMemo(
		() => userRoles?.map(role => role.name.toUpperCase()),
		[userRoles]
	);

	useEffect(() => {
		if (selectedUserRole) {
			const { name, description } = selectedUserRole;
			setNameToEdit(name);
			if (
				existingUserRoles?.includes(name.toUpperCase()) &&
				navigate !== RoleAndPermissionNavigation.EditRole
			) {
				setAutoFocus(true);
				setNameError(prev => ({
					...prev,
					uniqueName: true,
				}));
			}
			if (name) {
				setAddRoleForm(prev => ({
					...prev,
					name,
				}));
			}
			if (description) {
				setAddRoleForm(prev => ({
					...prev,
					description,
				}));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChangeInput = useCallback(
		(
			e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
			name: string
		) => {
			const { value } = e.target;

			if (name === 'name') {
				if (!specialCharRegex.test(value)) return;
				if (value.length < 3) {
					setNameError(prev => ({
						...prev,
						uniqueName: false,
						changeName: false,
						nameLength: true,
					}));
					if (!value.length) {
						setNameError(prev => ({
							...prev,
							uniqueName: false,
							changeName: false,
							onBlurError: true,
							nameLength: false,
						}));
					}
				}
				if (
					existingUserRoles?.includes(value.toUpperCase()) &&
					value.length > 2
				) {
					setNameError(prev => ({
						...prev,
						nameLength: false,
						changeName: false,
						uniqueName: true,
					}));
				}
				if (
					existingUserRoles?.includes(value.toUpperCase()) &&
					navigate === RoleAndPermissionNavigation.EditRole &&
					value.toUpperCase() === nameToEdit.toUpperCase()
				) {
					setNameError(prev => ({
						...prev,
						nameLength: false,
						changeName: false,
						uniqueName: false,
					}));
				}
				if (
					value.length > 2 &&
					!existingUserRoles?.includes(value.toUpperCase())
				) {
					setNameError({
						uniqueName: false,
						changeName: false,
						nameLength: false,
						onBlurError: false,
					});
					setAutoFocus(false);
				}
			}
			setAddRoleForm(pre => ({ ...pre, [name]: value }));
		},
		[existingUserRoles, nameToEdit, navigate, setAddRoleForm]
	);

	const handleOnBlur = () => {
		if (!name) {
			setNameError({
				uniqueName: false,
				changeName: false,
				nameLength: false,
				onBlurError: true,
			});
		}
	};

	return (
		<div className="AddNewRoleModal--container__inputRole">
			<div>
				<Input
					autoFocus={autoFocus}
					placeholder="Enter role name"
					label="Role name"
					inputType="text"
					handleChange={e => handleChangeInput(e, 'name')}
					handleBlur={handleOnBlur}
					value={name}
					isRequired
					isError={isError}
					errorMessage={errorMessage}
					id={UserRoleIds.name}
				/>
			</div>
			<div>
				<TextArea
					label="Description"
					handleChange={e => handleChangeInput(e, 'description')}
					placeholder="Enter description here..."
					value={description}
					row={3}
					id={UserRoleIds.description}
				/>
			</div>
		</div>
	);
};
