import { SurveyQuestionElementBase } from 'survey-react-ui';

import { CSSProperties } from 'react';
import { CounteryStateDropdown } from './component/countery-dropdown';

export class CustomCountryQuestion extends SurveyQuestionElementBase {
	constructor(props: any) {
		super(props);
		this.state = { value: this.question?.value };
	}
	get question() {
		return this.questionBase;
	}
	get value() {
		return this.question?.value;
	}
	get disableAlpha() {
		return this.question.disableAlpha;
	}
	get type() {
		return this.question.colorPickerType;
	}
	handleUpdateCountryDetails = (data: { country: string, state: string[] }) => {
		this.question.value = data;
	};
	// Support the read-only and design modes
	get style() {
		return this.question.getPropertyValue('readOnly') ||
			this.question.isDesignMode
			? { pointerEvents: 'none' }
			: undefined;
	}

	renderElement() {
		return (
			<div style={this.style as CSSProperties}>
				<CounteryStateDropdown handleUpdateCountryDetails={this.handleUpdateCountryDetails} defaultValue={this.question?.value} isDisabled={this.question.isReadOnly} />
			</div>
		);
	}
}
