export const sessionActionStatus: { [key: string]: string } = {
	completed: '#33b87a',
	pending: '#7c8398',
	processing: '#e5ae40',
	rejected: '#d32222',
	failed: '#f55353',
};

export const sessionActionLine: { [key: string]: string } = {
	completed: '#2269D3',
	pending: '#B3B3B3',
	processing: '#2269D3',
	rejected: '#2269D3',
	failed: '#2269D3',
};

export const fundInvestmentType: { [key: string]: string } = {
	payIn: 'Pay In',
	payOut: 'Pay Out',
};

export const MAX_SESSION_NAME_LENGTH = 44;
