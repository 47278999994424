import { Skeleton } from '@storybook';
import {
	AllowedInvitesState,
	BusinessRegistrationState,
	SubscriptionPriceState,
} from 'global-stores';
import { FC, useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import {
	ChooseIdentityData,
	IdentityType,
} from 'views/user-identity-flow/constants';
import { ChooseUserIdentity } from 'views/user-identity-flow/store';
import 'views/new-user-popup/components/welcome-user/welcome-user.scss';
import './choose-identity.scss';

interface IChooseIdentity {
	handleClickChoose: (val: string) => () => void;
}
export const ChooseIdentity: FC<IChooseIdentity> = ({ handleClickChoose }) => {
	const activeIdx = useRecoilValue(ChooseUserIdentity);
	const businessRegistrationState = useRecoilValue(BusinessRegistrationState);
	const subscriptionPrice = useRecoilValue(SubscriptionPriceState);
	const allowedInvites = useRecoilValue(AllowedInvitesState);

	const { accountType } = useMemo(
		() => businessRegistrationState ?? {},
		[businessRegistrationState]
	);

	const freeInviteCount: Record<string, number> = useMemo(() => {
		return {
			'e-sign': allowedInvites.onboarding,
			onboarding: allowedInvites.simpliciSign,
		};
	}, [allowedInvites]);

	const onboardingAndEsignPrice = useCallback(
		(type: string) => {
			if (subscriptionPrice) {
				const [subsciption]: any = subscriptionPrice;
				return subsciption?.[type];
			} else return 0;
		},
		[subscriptionPrice]
	);

	const renderRemainPaidServices = useMemo(
		() =>
			ChooseIdentityData.map(({ type, label, services, desc, subtitle,serviceProvide, icon }) => {
				const price = onboardingAndEsignPrice(type);
				const activeTab = activeIdx === type;

				return (
					<>
						<div
							key={type}
							className={`welcome-user-wrapper__info-wrapper__info-content__service-wrapper__${type} welcome-user-wrapper--service-title
							welcome-user-wrapper${activeTab ? '--active' : ''}
							`}
							style={{
								border: activeTab ? '2px solid var(--color-primary-light)' : '',
								...(accountType &&
									type === IdentityType.Individual && {
										cursor: 'not-allowed',
										opacity: 0.3,
										background: 'rgba(0,0,0,.1)',
										pointerEvents: 'none',
									}),
							}}
							onClick={handleClickChoose(type)}
						>
							<div className="welcome-user-wrapper--header-wrapper">
							<i className={`${icon} ${icon === 'ri-user-line' ? 'orange-color' : 'purple-color'}`} />
							<div className="welcome-user-wrapper--header-wrapper-div">
								<div className="welcome-user-wrapper--header">
									<div className="welcome-user-wrapper--label">{label}</div>
								</div>
								<div className="welcome-user-wrapper--desc">
									<span className="welcome-user-wrapper__price-label">
										{desc}
									</span>
								</div>
							</div>
							</div>
							<div className="welcome-user-wrapper--footer-container">
							<div className="welcome-user-wrapper--footer-container-div">
								{/* services */}
								<div className="welcome-user-wrapper--action">{subtitle}</div>

								{/* price */}
								{services.map(service => {
									return (
										<div
											className="welcome-user-wrapper__price-body"
											key={service.name}
										>
											<div className="welcome-user-wrapper__price-label">
												
												<div className="welcome-user-wrapper__price-icon-checkbox">
													<i className="ri-checkbox-circle-fill"></i>
												</div>
												 
												 <div className="welcome-user-wrapper__price-service-name">
													 {service.name} 
												 </div>
												 <div className="welcome-user-wrapper__price-flex">
													 {freeInviteCount[service.name]} free invites
												 </div>
												 
											 </div>
										</div>
									);
								})}
							
							<div className="welcome-user-wrapper--price">
									{price ? (
										<>
											<div className="welcome-user-wrapper__amount">
													<div className="welcome-user-wrapper__info-icon">
														<i className="ri-information-fill"></i>
													</div>
													
													<div className="welcome-user-wrapper__label-header">
														After 5 free invites, subscribe for&nbsp; 
														
														<span className="welcome-user-wrapper__label-price">
															${price} per User/month for eSign.
														</span> 
														</div>
												</div>
										</>
									) : (
										<div className="containers--loader">
											<Skeleton height={8} />
										</div>
									)}
								</div>
								</div>
								<div className="welcome-user-wrapper--serviceProvide">
									  <i className="ri-information-fill info-icon-two"></i>
										<p>{serviceProvide}</p>
								</div>
								</div>
							{activeTab && (
								<div className="welcome-user-wrapper--selected">Selected</div>
							)}
						</div>
					</>
				);
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onboardingAndEsignPrice, activeIdx]
	);

	return (
		<div className="ChooseIdentity">
			<div className="custom-user-identity containers">
				{renderRemainPaidServices}
			</div>
			<div className="ChooseIdentity--footer">
				<i className="ri-alarm-warning-line" />
				<span>Note:</span> Charges for all actions are separate from the monthly
				subscription fee associated with account type.
			</div>
		</div>
	);
};
