import { ChangeEvent, ReactNode, useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { IOption, Input } from '@storybook';
import { LabelHeader } from '../labelHeader';
import { PersonalFormDataType } from '../store';
import {
	capitalizeFirstLetter,
	isValidPhoneNumber,
	isZipCodeValid,
} from 'utils';
import { AddressDetails } from '../address-details';
import {
	PersonalInformationErrorState,
	PersonalInformationState,
} from '../address-details/store/state';
import { AutoAddress } from 'views/user-identity-flow';

export const PersonalIdentificationForm = () => {
	// globle state
	const [personalFormData, setPersonalFormData] = useRecoilState(
		PersonalInformationState
	);
	const [errorMessage, setErrorMessage] = useRecoilState(
		PersonalInformationErrorState
	);

	const handleChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>, name: string) => {
			const { value } = e.target;
			// set value into state
			setPersonalFormData((prev: PersonalFormDataType) => ({
				...prev,
				[name]: value,
			}));

			if (name === 'email' && !encodeURIComponent(value)) {
				setErrorMessage((prev: PersonalFormDataType) => ({
					...prev,
					[name]: 'Invalid email address',
				}));
				return;
			}

			if (name === 'phone' && !isValidPhoneNumber(value.trim())) {
				setErrorMessage((prev: PersonalFormDataType) => ({
					...prev,
					[name]: 'Invalid phone number!',
				}));
				return;
			}
			// validate zip code with maximum 10 letter
			if (name === 'ssn' && value.trim().length !== 9) {
				setErrorMessage((prev: PersonalFormDataType) => ({
					...prev,
					[name]: 'SSN number should be 9 digits',
				}));
				return;
			}

			if (name === 'zip' && !isZipCodeValid(value.trim())) {
				setErrorMessage((prev: PersonalFormDataType) => ({
					...prev,
					[name]: `Zip code length should be max 5 characters long`,
				}));
				return;
			}

			// first check if value is required
			if (!value) {
				setErrorMessage((prev: PersonalFormDataType) => ({
					...prev,
					[name]: `${capitalizeFirstLetter(name)} is required!`,
				}));
				return;
			}

			// reset error message
			setErrorMessage((prev: PersonalFormDataType) => ({
				...prev,
				[name]: '',
			}));
		},
		[setErrorMessage, setPersonalFormData]
	);

	const handleChangeSelect = useCallback(
		(e: IOption, name: string) => {
			setPersonalFormData((prev: PersonalFormDataType) => ({
				...prev,
				[name]: e.value,
			}));
		},
		[setPersonalFormData]
	);

	// Shahbaaz: Need to work when start work on escrow feat.
	const handleAutoInputValue = (data: AutoAddress | any) => {
		setPersonalFormData((prev: PersonalFormDataType) => ({
			...prev,
			address: data?.enteredAddress ?? '',
			city: data?.city ?? '',
			state: data?.state ?? '',
			zip: data?.postal_code ?? '',
		}));
	};

	const renderPersonalDetails: ReactNode = useMemo(() => {
		return (
			<>
				<LabelHeader label="Personal Detail" />
				<Input
					label="First Name"
					inputType="text"
					id="firstName"
					placeholder="John"
					handleChange={(e: ChangeEvent<HTMLInputElement>) =>
						handleChange(e, 'firstName')
					}
					value={personalFormData.firstName}
					isError={errorMessage.firstName ? true : false}
					errorMessage={errorMessage.firstName}
				/>
				<Input
					label="Last Name"
					inputType="text"
					placeholder="Deo"
					id="lastName"
					value={personalFormData.lastName}
					handleChange={(e: ChangeEvent<HTMLInputElement>) =>
						handleChange(e, 'lastName')
					}
					isError={errorMessage.lastName ? true : false}
					errorMessage={errorMessage.lastName}
				/>
				<div className="full__span">
					<Input
						label="Email"
						id="email"
						inputType="text"
						placeholder="admin@abcxyz.com"
						value={personalFormData.email}
						handleChange={(e: ChangeEvent<HTMLInputElement>) =>
							handleChange(e, 'email')
						}
						autoComplete="off"
						isError={errorMessage.email ? true : false}
						errorMessage={errorMessage.email}
					/>
				</div>
				<div className="full__span">
					<Input
						id="phone"
						label="Phone"
						inputType="text"
						placeholder="123456789"
						autoComplete="off"
						value={personalFormData.phone}
						handleChange={(e: ChangeEvent<HTMLInputElement>) =>
							handleChange(e, 'phone')
						}
						isError={errorMessage.phone ? true : false}
						errorMessage={errorMessage.phone}
					/>
				</div>

				<Input
					label="Date of Birth"
					inputType="date"
					id="date"
					placeholder="MM/DD/YYYY"
					value={personalFormData.dob}
					handleChange={(e: ChangeEvent<HTMLInputElement>) =>
						handleChange(e, 'dob')
					}
					isError={errorMessage.dob ? true : false}
					errorMessage={errorMessage.dob}
				/>

				<Input
					label="SSN"
					id="ssn"
					inputType="password"
					placeholder="****"
					autoComplete="off"
					value={personalFormData.ssn}
					handleChange={(e: ChangeEvent<HTMLInputElement>) =>
						handleChange(e, 'ssn')
					}
					isError={errorMessage.ssn ? true : false}
					errorMessage={errorMessage.ssn}
				/>
			</>
		);
		// eslint-disable-next-line
	}, [personalFormData, errorMessage]);

	return (
		<div id="business__container">
			<form autoComplete="off" className="business__form">
				<div className="business__form">{renderPersonalDetails}</div>
				<div className="business__form">
					<AddressDetails
						formData={personalFormData}
						errorMessage={errorMessage}
						handleChangeSelect={handleChangeSelect}
						handleChange={handleChange}
						checked={false}
						labelHeader="Address"
						handleAutoInputValue={handleAutoInputValue}
					/>
				</div>
			</form>
		</div>
	);
};
