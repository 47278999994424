export const lineBarColor: any = {
	KYC: '#2251D2',
	AML: '#E79B31',
	'Sign Agreement': '#C12870',
	'Fund Pay In': '#00CAFE',
	'Fund Pay Out': '#7935DE',
	'Accreditation 506b': '#389F75',
	'Accreditation 506c': '#D9DD0A',
	Accreditation: '#389F75',
	'Fund Investment': '#00CAFE',
	kyc: '#2251D2',
	aml: '#E79B31',
	signAgreement: '#C12870',
	fundInvestment: '#00CAFE',
	accreditation: '#389F75',
};

export const chartName: any = {
	kyc: 'KYC',
	aml: 'AML',
	signAgreement: 'Sign Agreement',
	fundInvestment: 'Fund Investment',
	accreditation: 'Accreditation',
};

export const SUPPORT_AGENT_HEADER = [
	{
		label: 'Name',
		key: 'name',
		format: 'string',
		width: '28%',
	},
	{
		label: 'Agent Number',
		key: 'userId',
		format: 'string',
		width: '24%',
	},
	{
		label: 'Number of Sessions',
		key: 'sessionCount',
		format: 'number',
		width: '24%',
	},
	{
		label: 'Avg. Completion Time',
		key: 'sessionAvgTime',
		format: 'number',
		width: '24%',
	},
];
