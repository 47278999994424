import { createRef } from 'react';
import { SketchPicker } from 'react-color';
import { useOutsideClick } from 'hooks';

interface Props {
	handleOnChange: (e: any) => void;
	currentColor: string;
	closeOutside: () => void;
}

/**
 * Component for a Sketch color picker.
 * @param {(e: any) => void} Props.handleOnChange -  method for change selected color.
 * @param {string} Props.currentColor - current selected color
 * @param {() => void} Props.closeOutside - close opened  color modal
 */

export const SketchColorPicker = ({
	handleOnChange,
	currentColor,
	closeOutside,
}: Props) => {
	const ref = createRef<HTMLDivElement>();

	useOutsideClick(ref, () => {
		closeOutside();
	});

	return (
		<div ref={ref}>
			<SketchPicker color={currentColor} onChangeComplete={handleOnChange} />
		</div>
	);
};
