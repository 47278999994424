import { BusinessFormDataType, PersonalFormDataType } from './type';

export const parsePersonalReqData = (data: any): PersonalFormDataType => {
	const {
		firstName,
		lastName,
		email,
		phone,
		dateOfBirth,
		ssn,
		address: { postalCode, city, country, state, street1 },
	} = data;

	return {
		firstName,
		lastName,
		email,
		phone,
		dob: dateOfBirth,
		ssn,
		zip: postalCode,
		city,
		country,
		state,
		address: street1,
	};
};

export const parseBusinessReqData = (data: any): BusinessFormDataType => {
	const {
		companyName,
		email,
		phone,
		ein,
		website,
		regionOfFormation,
		naicsDescription,
		naics,
		establishedOn,
		description,
		legalStructure,
		mailingAddress: {
			postalCode: mailingPostalCode,
			city: mailingCity,
			country: mailingCountry,
			state: mailingState,
			street1: mailingAddress,
			street2: mailingAddress2,
		},
		address: { postalCode, city, country, state, street1, street2 },
	} = data;

	return {
		website,
		ein,
		companyName,
		email,
		establishedDate: establishedOn,
		phone,
		naics,
		naicsType: naicsDescription,
		region: regionOfFormation,
		address2: street2,
		address: street1,
		zip: postalCode,
		city,
		country,
		state,
		description,
		legalStructure,
		mailingAddress: mailingAddress,
		mailingAddress2: mailingAddress2,
		mailingZip: mailingPostalCode,
		mailingState: mailingState,
		mailingCity: mailingCity,
		mailingCountry: mailingCountry,
	};
};

export const renderErrorMessage = (error: string) => {
	switch (error) {
		case 'Phone':
			return { phone: 'Phone is not valid' };
		case 'Email':
			return { phone: 'Email is not valid' };
		case 'Address':
			return { phone: 'Address is not valid' };
		case 'Date Of Birth':
			return { dob: 'The specified condition was not met for Date Of Birth' };
		case 'Established On':
			return {
				establishedDate:
					'The specified condition was not met for Established On',
			};
		case 'Legal structure':
			return { legalStructure: 'Legal structure is invalid' };
		case 'Ein':
			return { ein: 'EIN is invalid' };

		default:
			return error;
	}
};
