import { Skeleton } from '@storybook';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import {
	BillingDetailsLoaderState,
	BusinessPaymentCardState,
	BusinessRegistrationState,
	loginState,
} from 'global-stores';
import { capitalizeFirstLetter } from 'utils';
import { PERMISSION_SCREEN, useUserRoles } from 'views/routes-children';
import { UpdateBiilingAddress } from './components';
import './biling-info.scss';

export const BillingInfo = () => {
	const businessCard = useRecoilValue(BusinessPaymentCardState);
	const billingLoader = useRecoilValue(BillingDetailsLoaderState);
	const businessRegistration = useRecoilValue(BusinessRegistrationState);
	const userDetail = useRecoilValue(loginState);

	const { kyb } = useMemo(() => {
		return businessRegistration ?? {};
	}, [businessRegistration]);

	const report = useMemo(() => {
		return kyb?.data?.companyDetails?.enhanced?.report ?? {};
	}, [kyb]);

	const name = useMemo(() => {
		return report?.basicInfo?.name ?? {};
	}, [report]);

	const billingAddress = useMemo(() => {
		return businessCard?.billingAddress ?? {};
	}, [businessCard]);

	const {
		address1 = '',
		address2 = '',
		zip = '',
		city = '',
		state = '',
		country = '',
	} = billingAddress ?? {};

	const { firstName, lastName, email, companyName } = userDetail ?? {};

	const { checkUserWritePermission } = useUserRoles();

	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.Billing),
		[checkUserWritePermission]
	);

	const streetAddress = useMemo(() => {
		if (address1) {
			return address1.split(',')?.[0];
		} else return '';
	}, [address1]);

	const displayAddressDetails = useMemo(() => {
		if (address1 || address2 || city || state || zip) {
			return `${address2 ?? ''} ${streetAddress ?? ''} ${city ?? ''} ${
				state ?? ''
			} ${country ?? ''} ${zip ?? ''} `;
		}
		return 'N/A';
	}, [address1, address2, city, state, zip, streetAddress, country]);

	return (
		<div className="Billing--Info hover">
			<div className="Billing--Info__wrapper">
				<div>
					<div className="Billing--Info__title">Billing info</div>
					<div className="Billing--Info__subtitle">
						It will be added to all future invoices
					</div>
				</div>
				{email && !billingLoader && isUserPermissionWrite && (
					<UpdateBiilingAddress />
				)}
			</div>
			<div className="Billing--Info__information">
				<div className="Billing--Info__deatils-wrapper">
					<div className="Billing--Info__light-heading">Business name</div>
					<div className="Billing--Info__address">
						{billingLoader ? (
							<Skeleton height={12} width={100} />
						) : (
							companyName ?? name?.value ?? '--'
						)}
					</div>
				</div>
				<div className="Billing--Info__deatils-wrapper">
					<div className="Billing--Info__light-heading">Name</div>
					<div className="Billing--Info__address">
						{billingLoader ? (
							<Skeleton height={12} width={100} />
						) : !firstName ? (
							'--'
						) : (
							`${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(
								lastName
							)}`
						)}
					</div>
				</div>
			</div>
			<div className="Billing--Info__information">
				<div className="Billing--Info__deatils-wrapper">
					<div className="Billing--Info__light-heading">Billing address</div>
					<div className="Billing--Info__address">
						{billingLoader ? (
							<Skeleton height={12} width={100} />
						) : (
							displayAddressDetails
						)}
					</div>
				</div>
				<div className="Billing--Info__deatils-wrapper">
					<div className="Billing--Info__light-heading">Email</div>
					<div className="Billing--Info__address">
						<div>
							{billingLoader ? (
								<Skeleton height={12} width={100} />
							) : !email ? (
								'--'
							) : (
								email
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
