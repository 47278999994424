import { FC, useMemo, useCallback } from 'react';
import classname from "classnames";

interface IRadioOption {
	label: string;
	value: string;
	description?: string;
	icon?: string,
	color?: string,
	children?: JSX.Element
}

interface IRadioGroup {
	options: IRadioOption[];
	value: string;
	handleChange: (value: string) => void;
}

export const RadioGroup: FC<IRadioGroup> = ({
	options,
	value: activeRadio,
	handleChange,
}) => {

	const radioButtonLabelClassName = useCallback(
		(value: string) => {
			return classname('button-group__button', {
				'button-group__button--active': value === activeRadio,
			});
		},
		[activeRadio]
	);

	const renderRadioOptions = useMemo(() => {
		return options.map(({ icon='', color='', label, value, description, children }) => {
			const radioButton = <input
					className="button-group__button-radio"
					type="radio"
					id={value}
					checked={value === activeRadio}
					onChange={() => handleChange(value)}
				/>;
			return (
				
				<div className={radioButtonLabelClassName(value)} key={value} >
					<label  htmlFor={value}  className='button-group__container'>
							<div className='button-group__label'>
								{/* IF icon is provided then render the icon first otherwise readio button */}
								{
									icon !== '' ? <span style={{ backgroundColor: color }} className='button-group__icon-container'><i className={icon} /></span> 
									:  radioButton
								}
								<div className="button-group__button-text-content">
									<div className="button-group__button-label">{label}</div>
									{!!description && (
										<div className="button-group__button-description">
											{description}
										</div>
									)}
								</div>
							</div>
							{/* render the radio button if icon is provided in options  */}
							{ icon !== '' && radioButton }
					</label>
					{/* rending the children if given in options */}
					{children && <div className='button-group__children-container'>{children}</div>}
				</div>
		)});
	}, [activeRadio, handleChange, options, radioButtonLabelClassName]);

	return <div className="button-group">{renderRadioOptions} </div>;
};
