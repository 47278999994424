export const getLogos: any = {
	default: 'Bank_Default.svg',
	'Navy Federal Credit Union': 'Navy_Federal_Credit_Union.svg',
	Fidelity: 'Fidelity.svg',
	Wealthfront: 'Wealthfront.svg',
	'TD Bank': 'TD.svg',
	'Citibank Online': 'Citi.svg',
	'Citizens Bank': 'Citizens.svg',
	Chase: 'Chase.svg',
	'Bank of America': 'Bank_of_America.svg',
	USAA: 'USAA.svg',
	'Regions Bank': 'Regions_Financial_Corporation.svg',
	'Charles Schwab': 'Charles_Schwab_Corporation.svg',
	'American Express - Personal Savings': 'American_Express.svg',
	'American Express': 'American_Express.svg',
	'Digital Federal Credit Union': 'DigitalFederal_Credit_Union.svg',
	'Wells Fargo': 'Wells_Fargo.svg',
	'Marcus by Goldman Sachs': 'Marcus_By_Goldman_Sachs.svg',
};
