import { Button, Image } from '@storybook';
import Modal from '@storybook/new-modal/modal';
import { FC, ReactNode } from 'react';

interface ISubscriptionRequiredModal {
	isModalOpen: boolean;
	closeModal: () => void;
	handleNavigate: () => void;
	children?:ReactNode;
}
export const SubscriptionRequiredModal: FC<ISubscriptionRequiredModal> = ({
	closeModal,
	isModalOpen,
	handleNavigate,
	children
}) => {
	return (
		<Modal
			isOpen={isModalOpen}
			modalName="Subscription Required Modal"
			closeModal={closeModal}
			optionalClassName="subscription-required-modal"
			showCloseBtn
			isStopOutsideClick={false}
		>
			{children ?<>{children}</> :<div className="subscription-required-modal--body">
				<Image fileName="SubscribedIcon.svg" />
				<h2>Subscription required</h2>
				<p>
					To access this feature, please subscribe to a service. Subscribing
					will unlock additional functionalities and benefits for your account.
				</p>
				<Button
					label="Subscribe now"
					handleClick={handleNavigate}
					type="button__filled button__filled--primary button__large button-ok"
				/>
			</div>}
			
		</Modal>
	);
};
