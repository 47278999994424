/* eslint-disable no-console */
import { useCallback, useMemo } from 'react';
import { Skeleton } from '@storybook';
import { useNotification } from 'hooks';

import './genrate-mfa.scss';

type MfaKey = 'step1' | 'step2' | 'step3';

interface IMfaItem {
	label: string;
	description: string;
	subdescription?: string;
	image?: string;
	JSX?: JSX.Element;
}

type IGenerateMfa = {
	[key in MfaKey]: IMfaItem;
};

interface GenrateMultifactorAuthProps {
	handleChange: (value: string) => void;
	mfa_data: {
		qrlink: string,
		secret: string,

	},
}

export const GenrateMultifactorAuth: React.FC<GenrateMultifactorAuthProps> = ({
	handleChange,
	mfa_data,
}) => {
	const { successNotification, errorNotification } = useNotification();
	const copyToClipboard = useCallback((text: string) => {
		navigator.clipboard
			.writeText(text)
			.then(() => {
				successNotification('Code copied to clipboard');
			})
			.catch(() => {
				errorNotification('Failed to copy code');
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleInputChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const value = event.target.value;
			handleChange(value);
		},
		[handleChange]
	);

	const verificationCode = useMemo(() => {
		return (
			<div className="mfa__genrated-code-box">
				<input
					className="mfa__genrated-code"
					disabled
					type="text"
					placeholder="XXXX XXXX XXXX XXXX"
					value={mfa_data?.secret}
				/>
				<div
					className="mfa__copy-code"
					onClick={() => copyToClipboard(mfa_data?.secret)}
				>
					<i className="ri-file-copy-fill"></i> Copy key
				</div>
			</div>
		);
	}, [copyToClipboard, mfa_data?.secret]);

	const enterVerificationCode = useMemo(() => {
		return (
			<div className="mfa__genrated-code-box">
				<input
					className="mfa__enter-code"
					type="text"
					placeholder="Verification code"
					onChange={handleInputChange}
				/>
			</div>
		);
	}, [handleInputChange]);

	const GENERATE_MFA_MESSAGE: IGenerateMfa = {
		step1: {
			label: 'Step 1: Install an authenticator app',
			description:
				'Download and install an authenticator app like Google Authenticator, Microsoft Authenticator, or Authy on your mobile device.',
		},
		step2: {
			label: 'Step 2: Scan QR Code',
			description:
				'Open the authenticator app and scan the QR code displayed on the screen.',
			subdescription:
				'Having trouble scanning the QR code? You can manually enter the code below into your authenticator app to set up two-factor authentication',
			image: mfa_data?.qrlink ?? "",
			JSX: verificationCode,
		},
		step3: {
			label: 'Step 3: Install an authenticator app',
			description:
				'Enter the verification code generated by the authenticator app into the provided field.',
			JSX: enterVerificationCode,
		},
	};

	return (
		<div className="mfa">
			{Object.entries(GENERATE_MFA_MESSAGE).map(([key, value]) => (
				<div key={key}>
					<div className="mfa__step-heading">{value.label}</div>
					<div className="mfa__step-desp">{value.description}</div>
					{value.image ? (
						<div className='mfa__step-image'>
						<img src={value.image} alt="QR Code" />
						</div>
					) : (
						<>
							{key === 'step2' && (
								<div className="mfa__qr-image-loader">
									<Skeleton height={152} width={152} borderRadius={4} />
								</div>
							)}
						</>
					)}
					{value.subdescription && (
						<p className="mfa__step-desp">{value.subdescription}</p>
					)}
					{value.JSX && value.JSX}
				</div>
			))}
		</div>
	);
};
