export default {
  'gmail.com': true,
  'yahoo.com': true,
  'hotmail.com': true,
  'outlook.com': true,
  'aol.com': true,
  'mail.com': true,
  'zoho.com': true,
  'icloud.com': true,
  'yandex.com': true,
  'protonmail.com': true,
  'gmx.com': true,
  'gmx.us': true,
  'gmx.co.uk': true,
  'gmx.de': true,
  'fastmail.com': true,
  'hushmail.com': true,
  'inbox.com': true,
  'lavabit.com': true,
  'guerrillamail.com': true,
  'mailinator.com': true,
  'myopera.com': true,
  'nexxmail.com': true,
  'shortmail.com': true,
  'sibmail.com': true,
  'tormail.org': true,
  'trashmail.com': true,
  '33mail.com': true,
  'deadspam.com': true,
  'airmail.net': true,
  'animail.net': true,
  'antireg.ru': true,
  'anpa.ro': true,
  'beep.pl': true,
  'bofthew.com': true,
  'binkmail.com': true,
  'bobmail.info': true,
  'brefmail.com': true,
  'buggymail.com': true,
  'chammy.info': true,
  'chef.net': true,
  'chopstick.ch': true,
  'cock.li': true,
  'cool.fr.nf': true,
  'courriel.fr.nf': true,
  'crapmail.org': true,
  'cuvox.de': true,
  'dacoolest.com': true,
  'deadaddress.com': true,
  'despam.it': true,
  'devnullmail.com': true,
  'dfgh.net': true,
  'digitalsanctuary.com': true,
  'directbox.com': true,
  'discardmail.com': true,
  'discardmail.de': true,
  'disposinbox.com': true,
  'disposemail.com': true,
  'dispostable.com': true,
  'dudmail.com': true,
  'dumpandjunk.com': true,
  'dumpmail.de': true,
  'dumpyemail.com': true,
  'e4ward.com': true,
  'email60.com': true,
  'emaildienst.de': true,
  'emailias.com': true,
  'emailigo.de': true,
  'emailinfive.com': true,
  'emailmiser.com': true,
  'emailtemporanea.com': true,
  'emailtemporanea.net': true,
  'emailtemporar.ro': true,
  'emailtemporario.com.br': true,
  'emailthe.net': true,
  'emailtmp.com': true,
  'emailwarden.com': true,
  'emailx.at.hm': true,
  'emailxfer.com': true,
  'ephemeral.email': true,
  'explodemail.com': true,
  'fakeinbox.com': true,
  'fakeinformation.com': true,
  'fakemail.fr': true,
  'fakemailgenerator.com': true,
  'falsinbox.com': true,
  'fastacura.com': true,
  'fastchevy.com': true,
  'fastchrysler.com': true,
  'fastkawasaki.com': true,
  'fastmazda.com': true,
  'fastmitsubishi.com': true,
  'fastnissan.com': true,
  'fastsubaru.com': true,
  'fastsuzuki.com': true,
  'fasttoyota.com': true,
  'fastyamaha.com': true,
  'filzmail.com': true,
  'fleetingemail.com': true,
  'forgetmail.com': true,
  'fr33mail.info': true,
  'frapmail.com': true,
  'freemail.ms': true,
  'freemail.tweakly.net': true,
  'friendlymail.co.uk': true,
  'fux0ringduh.com': true,
  'garliclife.com': true,
  'get2mail.fr': true,
  'getairmail.com': true,
  'ghosttexter.de': true,
  'girlsundertheinfluence.com': true,
  'gowikibooks.com': true,
  'gowikicampus.com': true,
  'gowikicars.com': true,
  'gowikifilms.com': true,
  'gowikigames.com': true,
  'gowikimusic.com': true,
  'gowikinetwork.com': true,
  'gowikitravel.com': true,
  'gowikitv.com': true,
  'great-host.in': true,
  'greensloth.com': true,
  'grr.la': true,
  'guerrillamail.biz': true,
  'guerrillamail.de': true,
  'guerrillamail.info': true,
  'guerrillamail.net': true,
  'guerrillamail.org': true,
  'guerrillamailblock.com': true,
  'h.mintemail.com': true,
  'haltospam.com': true,
  'harakirimail.com': true,
  'hartbot.de': true,
  'hellodream.mobi': true,
  'hemmamail.com': true,
  'herp.in': true,
  'hidemail.de': true,
  'hidzz.com': true,
  'hotpop.com': true,
  'humaility.com': true,
  'hush.ai': true,
  'ieatspam.eu': true,
  'ieatspam.info': true,
  'ieh-mail.de': true,
  'ihateyoualot.info': true,
  'iheartspam.org': true,
  'improvmx.com': true,
  'inbax.tk': true,
  'inbox.si': true,
  'inboxalias.com': true,
  'inboxclean.com': true,
  'inboxclean.org': true,
  'infocom.zp.ua': true,
  'instant-mail.de': true,
  'ip6.li': true,
  'irish2me.com': true,
  'iwi.net': true,
  'jetable.com': true,
  'jetable.fr.nf': true,
  'jetable.net': true,
  'jetable.org': true,
  'jnxjn.com': true,
  'journeyrecycling.com': true,
  'kasmail.com': true,
  'kaspop.com': true,
  'killmail.com': true,
  'killermail.com': true,
  'klzlk.com': true,
  'koszmail.pl': true,
  'kukamail.com': true,
  'kurzepost.de': true,
  'lawlita.com': true,
  'letthemeatspam.com': true,
  'lhsdv.com': true,
  'lifebyfood.com': true,
  'link2mail.net': true,
  'litedrop.com': true,
  'lol.ovpn.to': true,
  'lolfreak.net': true,
  'lookugly.com': true,
  'lortemail.dk': true,
  'lr78.com': true,
  'm21.cc': true,
  'maboard.com': true,
  'mail.by': true,
  'mail.mezimages.net': true,
  'mail.zp.ua': true,
  'mail1a.de': true,
  'mail21.cc': true,
  'mail2rss.org': true,
  'mail333.com': true,
  'mail4trash.com': true,
  'mailbidon.com': true,
  'mailblocks.com': true,
  'mailbucket.org': true,
  'mailcat.biz': true,
  'mailcatch.com': true,
  'mailde.de': true,
  'mailde.info': true,
  'mailed.ro': true,
  'mailforspam.com': true,
  'mailfreeonline.com': true,
  'mailfs.com': true,
  'mailguard.me': true,
  'mailimate.com': true,
  'mailin8r.com': true,
  'mailinater.com': true,
  'mailinator.net': true,
  'mailinator2.com': true,
  'mailincubator.com': true,
  'mailismagic.com': true,
  'mailme.lv': true,
  'mailme24.com': true,
  'mailmetrash.com': true,
  'mailmoat.com': true,
  'mailms.com': true,
  'mailnesia.com': true,
  'mailnull.com': true,
  'mailorg.org': true,
  'mailpick.biz': true,
  'mailrock.biz': true,
  'mailscrap.com': true,
  'mailshell.com': true,
  'mailsiphon.com': true,
  'mailslapping.com': true,
  'mailslite.com': true,
  'mailtemp.info': true,
  'mailtome.de': true,
  'mailtothis.com': true,
  'mailtrash.net': true,
  'mailtv.net': true,
  'mailtv.tv': true,
  'mailzilla.com': true,
  'makemetheking.com': true,
  'manybrain.com': true,
  'mbx.cc': true,
  'mega.zik.dj': true,
  'meinspamschutz.de': true,
  'meltmail.com': true,
  'messagebeamer.de': true,
  'mierdamail.com': true,
  'migmig.net': true,
  'mintemail.com': true,
  'mjukglass.nu': true,
  'moakt.com': true,
  'moakt.ws': true,
  'moburl.com': true,
  'moncourrier.fr.nf': true,
  'monemail.fr.nf': true,
  'monmail.fr.nf': true,
  '0-mail.com': true,
  '027168.com': true,
  '0815.ru': true,
  '0815.ry': true,
  '0815.su': true,
  '0845.ru': true,
  '0box.eu': true,
  '0clickemail.com': true,
  '0n0ff.net': true,
  '0nelce.com': true,
  '0v.ro': true,
  '0w.ro': true,
  '0wnd.net': true,
  '0wnd.org': true,
  '0x207.info': true,
  '1-8.biz': true,
  '1-tm.com': true,
  '10-minute-mail.com': true,
  '1000rebates.stream': true,
  '100likers.com': true,
  '105kg.ru': true,
  '10dk.email': true,
  '10mail.com': true,
  '10mail.org': true,
  '10mail.tk': true,
  '10mail.xyz': true,
  '10minmail.de': true,
  '10minut.com.pl': true,
  '10minut.xyz': true,
  '10minutemail.be': true,
  '10minutemail.cf': true,
  '10minutemail.co.uk': true,
  '10minutemail.co.za': true,
  '10minutemail.com': true,
  '10minutemail.de': true,
  '10minutemail.ga': true,
  '10minutemail.gq': true,
  '10minutemail.ml': true,
  '10minutemail.net': true,
  '10minutemail.nl': true,
  '10minutemail.pro': true,
  '10minutemail.us': true,
  '10minutemailbox.com': true,
  '10minutemails.in': true,
  '10minutenemail.de': true,
  '10minutenmail.xyz': true,
  '10minutesmail.com': true,
  '10minutesmail.fr': true,
  '10minutmail.pl': true,
  '10x9.com': true,
  '11163.com': true,
  '123-m.com': true,
  '12hosting.net': true,
  '12houremail.com': true,
  '12minutemail.com': true,
  '12minutemail.net': true,
  '12storage.com': true,
  '140unichars.com': true,
  '147.cl': true,
  '14n.co.uk': true,
  '15qm.com': true,
  '1blackmoon.com': true,
  '1ce.us': true,
  '1chuan.com': true,
  '1clck2.com': true,
  '1fsdfdsfsdf.tk': true,
  '1mail.ml': true,
  '1pad.de': true,
  '1s.fr': true,
  '1secmail.com': true,
  '1secmail.net': true,
  '1secmail.org': true,
  '1st-forms.com': true,
  '1to1mail.org': true,
  '1usemail.com': true,
  '1webmail.info': true,
  '1zhuan.com': true,
  '2012-2016.ru': true,
  '20email.eu': true,
  '20email.it': true,
  '20mail.eu': true,
  '20mail.in': true,
  '20mail.it': true,
  '20minutemail.com': true,
  '20minutemail.it': true,
  '20mm.eu': true,
  '2120001.net': true,
  '21cn.com': true,
  '247web.net': true,
  '24hinbox.com': true,
  '24hourmail.com': true,
  '24hourmail.net': true,
  '2anom.com': true,
  '2chmail.net': true,
  '2ether.net': true,
  '2fdgdfgdfgdf.tk': true,
  '2odem.com': true,
  '2prong.com': true,
  '2wc.info': true,
  '300book.info': true,
  '30mail.ir': true,
  '30minutemail.com': true,
  '30wave.com': true,
  '3202.com': true,
  '36ru.com': true,
  '3d-painting.com': true,
  '3l6.com': true,
  '3mail.ga': true,
  '3trtretgfrfe.tk': true,
  '4-n.us': true,
  '4057.com': true,
  '418.dk': true,
  '42o.org': true,
  '4gfdsgfdgfd.tk': true,
  '4k5.net': true,
  '4mail.cf': true,
  '4mail.ga': true,
  '4nextmail.com': true,
  '4nmv.ru': true,
  '4tb.host': true,
  '4warding.com': true,
  '4warding.net': true,
  '4warding.org': true,
  '50set.ru': true,
  '55hosting.net': true,
  '5ghgfhfghfgh.tk': true,
  '5gramos.com': true,
  '5july.org': true,
  '5mail.cf': true,
  '5mail.ga': true,
  '5minutemail.net': true,
  '5oz.ru': true,
  '5tb.in': true,
  '5x25.com': true,
  '5ymail.com': true,
  '60minutemail.com': true,
  '672643.net': true,
  '675hosting.com': true,
  '675hosting.net': true,
  '675hosting.org': true,
  '6hjgjhgkilkj.tk': true,
  '6ip.us': true,
  '6mail.cf': true,
  '6mail.ga': true,
  '6mail.ml': true,
  '6paq.com': true,
  '6somok.ru': true,
  '6url.com': true,
  '75hosting.com': true,
  '75hosting.net': true,
  '75hosting.org': true,
  '7days-printing.com': true,
  '7mail.ga': true,
  '7mail.ml': true,
  '7tags.com': true,
  '80665.com': true,
  '8127ep.com': true,
  '8mail.cf': true,
  '8mail.ga': true,
  '8mail.ml': true,
  '99.com': true,
  '99cows.com': true,
  '99experts.com': true,
  '9mail.cf': true,
  '9me.site': true,
  '9mot.ru': true,
  '9ox.net': true,
  '9q.ro': true,
  'a-bc.net': true,
  'a45.in': true,
  'a7996.com': true,
  'aa5zy64.com': true,
  'abacuswe.us': true,
  'abakiss.com': true,
  'abcmail.email': true,
  'abilitywe.us': true,
  'abovewe.us': true,
  'absolutewe.us': true,
  'abundantwe.us': true,
  'abusemail.de': true,
  'abuser.eu': true,
  'abyssmail.com': true,
  'ac20mail.in': true,
  'academiccommunity.com': true,
  'academywe.us': true,
  'acceleratewe.us': true,
  'accentwe.us': true,
  'acceptwe.us': true,
  'acclaimwe.us': true,
  'accordwe.us': true,
  'accreditedwe.us': true,
  'acentri.com': true,
  'achievementwe.us': true,
  'achievewe.us': true,
  'acornwe.us': true,
  'acrossgracealley.com': true,
  'acrylicwe.us': true,
  'activatewe.us': true,
  'activitywe.us': true,
  'acucre.com': true,
  'acuitywe.us': true,
  'acumenwe.us': true,
  'adaptivewe.us': true,
  'adaptwe.us': true,
  'add3000.pp.ua': true,
  'addictingtrailers.com': true,
  'adeptwe.us': true,
  'adfskj.com': true,
  'adios.email': true,
  'adiq.eu': true,
  'aditus.info': true,
  'admiralwe.us': true,
  'ado888.biz': true,
  'adobeccepdm.com': true,
  'adoniswe.us': true,
  'adpugh.org': true,
  'adroh.com': true,
  'adsd.org': true,
  'adubiz.info': true,
  'advantagewe.us': true,
  'advantimo.com': true,
  'adventurewe.us': true,
  'adventwe.us': true,
  'advisorwe.us': true,
  'advocatewe.us': true,
  'adwaterandstir.com': true,
  'aegde.com': true,
  'aegia.net': true,
  'aegiscorp.net': true,
  'aegiswe.us': true,
  'aelo.es': true,
  'aeonpsi.com': true,
  'afarek.com': true,
  'affiliate-nebenjob.info': true,
  'affiliatedwe.us': true,
  'affilikingz.de': true,
  'affinitywe.us': true,
  'affluentwe.us': true,
  'affordablewe.us': true,
  'afia.pro': true,
  'afrobacon.com': true,
  'afterhourswe.us': true,
  'agedmail.com': true,
  'agendawe.us': true,
  'agger.ro': true,
  'agilewe.us': true,
  'agorawe.us': true,
  'agtx.net': true,
  'aheadwe.us': true,
  'ahem.email': true,
  'ahk.jp': true,
  'ahmedkhlef.com': true,
  'air2token.com': true,
  'airmailbox.website': true,
  'airsi.de': true,
  'ajaxapp.net': true,
  'akapost.com': true,
  'akerd.com': true,
  'akgq701.com': true,
  'akmail.in': true,
  'akugu.com': true,
  'al-qaeda.us': true,
  'albionwe.us': true,
  'alchemywe.us': true,
  'alfaceti.com': true,
  'aliaswe.us': true,
  'alienware13.com': true,
  'aligamel.com': true,
  'alina-schiesser.ch': true,
  'alisongamel.com': true,
  'alivance.com': true,
  'alivewe.us': true,
  'all-cats.ru': true,
  'allaccesswe.us': true,
  'allamericanwe.us': true,
  'allaroundwe.us': true,
  'alldirectbuy.com': true,
  'allegiancewe.us': true,
  'allegrowe.us': true,
  'allemojikeyboard.com': true,
  'allgoodwe.us': true,
  'alliancewe.us': true,
  'allinonewe.us': true,
  'allofthem.net': true,
  'alloutwe.us': true,
  'allowed.org': true,
  'alloywe.us': true,
  'allprowe.us': true,
  'allseasonswe.us': true,
  'allstarwe.us': true,
  'allthegoodnamesaretaken.org': true,
  'allurewe.us': true,
  'almondwe.us': true,
  'alph.wtf': true,
  'alpha-web.net': true,
  'alphaomegawe.us': true,
  'alpinewe.us': true,
  'altairwe.us': true,
  'altitudewe.us': true,
  'altuswe.us': true,
  'ama-trade.de': true,
  'ama-trans.de': true,
  'amadeuswe.us': true,
  'amail.club': true,
  'amail.com': true,
  'amail1.com': true,
  'amail4.me': true,
  'amazon-aws.org': true,
  'amberwe.us': true,
  'ambiancewe.us': true,
  'ambitiouswe.us': true,
  'amelabs.com': true,
  'americanawe.us': true,
  'americasbestwe.us': true,
  'americaswe.us': true,
  'amicuswe.us': true,
  'amilegit.com': true,
  'amiri.net': true,
  'amiriindustries.com': true,
  'amplewe.us': true,
  'amplifiedwe.us': true,
  'amplifywe.us': true,
  'ampsylike.com': true,
  'analogwe.us': true,
  'analysiswe.us': true,
  'analyticalwe.us': true,
  'analyticswe.us': true,
  'analyticwe.us': true,
  'anappfor.com': true,
  'anappthat.com': true,
  'andreihusanu.ro': true,
  'andthen.us': true,
  'animesos.com': true,
  'anit.ro': true,
  'ano-mail.net': true,
  'anon-mail.de': true,
  'anonbox.net': true,
  'anonmail.top': true,
  'anonmails.de': true,
  'anonymail.dk': true,
  'anonymbox.com': true,
  'anonymized.org': true,
  'anonymousness.com': true,
  'anotherdomaincyka.tk': true,
  'ansibleemail.com': true,
  'anthony-junkmail.com': true,
  'antireg.com': true,
  'antispam.de': true,
  'antispam24.de': true,
  'antispammail.de': true,
  'any.pink': true,
  'anyalias.com': true,
  'aoeuhtns.com': true,
  'apfelkorps.de': true,
  'aphlog.com': true,
  'apkmd.com': true,
  'appc.se': true,
  'appinventor.nl': true,
  'appixie.com': true,
  'apps.dj': true,
  'appzily.com': true,
  'arduino.hk': true,
  'ariaz.jetzt': true,
  'armyspy.com': true,
  'aron.us': true,
  'arroisijewellery.com': true,
  'art-en-ligne.pro': true,
  'artman-conception.com': true,
  'arur01.tk': true,
  'arurgitu.gq': true,
  'arvato-community.de': true,
  'aschenbrandt.net': true,
  'asdasd.nl': true,
  'asdasd.ru': true,
  'ashleyandrew.com': true,
  'ask-mail.com': true,
  'asorent.com': true,
  'ass.pp.ua': true,
  'astonut.tk': true,
  'astroempires.info': true,
  'asu.mx': true,
  'asu.su': true,
  'at.hm': true,
  'at0mik.org': true,
  'atnextmail.com': true,
  'attnetwork.com': true,
  'augmentationtechnology.com': true,
  'ausgefallen.info': true,
  'auti.st': true,
  'autorobotica.com': true,
  'autosouvenir39.ru': true,
  'autotwollow.com': true,
  'autowb.com': true,
  'aver.com': true,
  'averdov.com': true,
  'avia-tonic.fr': true,
  'avls.pt': true,
  'awatum.de': true,
  'awdrt.org': true,
  'awiki.org': true,
  'awsoo.com': true,
  'axiz.org': true,
  'axon7zte.com': true,
  'axsup.net': true,
  'ayakamail.cf': true,
  'azazazatashkent.tk': true,
  'azcomputerworks.com': true,
  'azmeil.tk': true,
  'b1of96u.com': true,
  'b2bx.net': true,
  'b2cmail.de': true,
  'badgerland.eu': true,
  'badoop.com': true,
  'badpotato.tk': true,
  'balaket.com': true,
  'bangban.uk': true,
  'banit.club': true,
  'banit.me': true,
  'bank-opros1.ru': true,
  'bareed.ws': true,
  'barooko.com': true,
  'barryogorman.com': true,
  'bartdevos.be': true,
  'basscode.org': true,
  'bauwerke-online.com': true,
  'bazaaboom.com': true,
  'bbbbyyzz.info': true,
  'bbhost.us': true,
  'bbitf.com': true,
  'bbitj.com': true,
  'bbitq.com': true,
  'bcaoo.com': true,
  'bcast.ws': true,
  'bcb.ro': true,
  'bccto.me': true,
  'bdmuzic.pw': true,
  'beaconmessenger.com': true,
  'bearsarefuzzy.com': true,
  'beddly.com': true,
  'beefmilk.com': true,
  'belamail.org': true,
  'belljonestax.com': true,
  'beluckygame.com': true,
  'benipaula.org': true,
  'bepureme.com': true,
  'beribase.ru': true,
  'beribaza.ru': true,
  'berirabotay.ru': true,
  'best-john-boats.com': true,
  'bestchoiceusedcar.com': true,
  'bestlistbase.com': true,
  'bestoption25.club': true,
  'bestparadize.com': true,
  'bestsoundeffects.com': true,
  'besttempmail.com': true,
  'betr.co': true,
  'bgtmail.com': true,
  'bgx.ro': true,
  'bheps.com': true,
  'bidourlnks.com': true,
  'big1.us': true,
  'bigprofessor.so': true,
  'bigstring.com': true,
  'bigwhoop.co.za': true,
  'bij.pl': true,
  'binka.me': true,
  'binnary.com': true,
  'bio-muesli.info': true,
  'bio-muesli.net': true,
  'bione.co': true,
  'bitwhites.top': true,
  'bitymails.us': true,
  'blackgoldagency.ru': true,
  'blackmarket.to': true,
  'bladesmail.net': true,
  'blip.ch': true,
  'blnkt.net': true,
  'block521.com': true,
  'blogmyway.org': true,
  'blogos.net': true,
  'blogspam.ro': true,
  'blondemorkin.com': true,
  'bluedumpling.info': true,
  'bluewerks.com': true,
  'bnote.com': true,
  'boatmail.us': true,
  'bobmurchison.com': true,
  'bonobo.email': true,
  'boofx.com': true,
  'bookthemmore.com': true,
  'bootybay.de': true,
  'borged.com': true,
  'borged.net': true,
  'borged.org': true,
  'bot.nu': true,
  'boun.cr': true,
  'bouncr.com': true,
  'boxformail.in': true,
  'boximail.com': true,
  'boxmail.lol': true,
  'boxomail.live': true,
  'boxtemp.com.br': true,
  'bptfp.net': true,
  'brand-app.biz': true,
  'brandallday.net': true,
  'brasx.org': true,
  'breakthru.com': true,
  'brennendesreich.de': true,
  'briggsmarcus.com': true,
  'broadbandninja.com': true,
  'bsnow.net': true,
  'bspamfree.org': true,
  'bspooky.com': true,
  'bst-72.com': true,
  'btb-notes.com': true,
  'btc.email': true,
  'btcmail.pw': true,
  'btcmod.com': true,
  'btizet.pl': true,
  'buccalmassage.ru': true,
  'budaya-tionghoa.com': true,
  'budayationghoa.com': true,
  'buffemail.com': true,
  'bugfoo.com': true,
  'bugmenever.com': true,
  'bugmenot.com': true,
  'bukhariansiddur.com': true,
  'bulrushpress.com': true,
  'bum.net': true,
  'bumpymail.com': true,
  'bunchofidiots.com': true,
  'bund.us': true,
  'bundes-li.ga': true,
  'bunsenhoneydew.com': true,
  'burnthespam.info': true,
  'burstmail.info': true,
  'businessbackend.com': true,
  'businesssuccessislifesuccess.com': true,
  'buspad.org': true,
  'bussitussi.com': true,
  'buymoreplays.com': true,
  'buyordie.info': true,
  'buyusdomain.com': true,
  'buyusedlibrarybooks.org': true,
  'buzzcluby.com': true,
  'byebyemail.com': true,
  'byespm.com': true,
  'byom.de': true,
  'c01.kr': true,
  'c51vsgq.com': true,
  'cachedot.net': true,
  'californiafitnessdeals.com': true,
  'cam4you.cc': true,
  'camping-grill.info': true,
  'candymail.de': true,
  'cane.pw': true,
  'capitalistdilemma.com': true,
  'car101.pro': true,
  'carbtc.net': true,
  'cars2.club': true,
  'carsencyclopedia.com': true,
  'cartelera.org': true,
  'caseedu.tk': true,
  'cashflow35.com': true,
  'casualdx.com': true,
  'catgroup.uk': true,
  'cavi.mx': true,
  'cbair.com': true,
  'cbes.net': true,
  'cc.liamria': true,
  'ccmail.uk': true,
  'cdfaq.com': true,
  'cdpa.cc': true,
  'ceed.se': true,
  'cek.pm': true,
  'cellurl.com': true,
  'centermail.com': true,
  'centermail.net': true,
  'cetpass.com': true,
  'cfo2go.ro': true,
  'chacuo.net': true,
  'chaichuang.com': true,
  'chalupaurybnicku.cz': true,
  'chasefreedomactivate.com': true,
  'chatich.com': true,
  'cheaphub.net': true,
  'cheatmail.de': true,
  'chenbot.email': true,
  'chewydonut.com': true,
  'chibakenma.ml': true,
  'chickenkiller.com': true,
  'chielo.com': true,
  'childsavetrust.org': true,
  'chilkat.com': true,
  'chinamkm.com': true,
  'chithinh.com': true,
  'chitthi.in': true,
  'choco.la': true,
  'chogmail.com': true,
  'choicemail1.com': true,
  'chong-mail.com': true,
  'chong-mail.net': true,
  'chong-mail.org': true,
  'chumpstakingdumps.com': true,
  'cigar-auctions.com': true,
  'civikli.com': true,
  'civx.org': true,
  'ckaazaza.tk': true,
  'ckiso.com': true,
  'cl-cl.org': true,
  'cl0ne.net': true,
  'claimab.com': true,
  'clandest.in': true,
  'classesmail.com': true,
  'clearwatermail.info': true,
  'click-email.com': true,
  'clickdeal.co': true,
  'clipmail.eu': true,
  'clixser.com': true,
  'clonemoi.tk': true,
  'cloud-mail.top': true,
  'cloudns.cx': true,
  'clout.wiki': true,
  'clrmail.com': true,
  'cmail.club': true,
  'cmail.com': true,
  'cmail.net': true,
  'cmail.org': true,
  'cnamed.com': true,
  'cndps.com': true,
  'cnew.ir': true,
  'cnmsg.net': true,
  'cnsds.de': true,
  'co.cc': true,
  'cobarekyo1.ml': true,
  'cocoro.uk': true,
  'cocovpn.com': true,
  'codeandscotch.com': true,
  'codivide.com': true,
  'coffeetimer24.com': true,
  'coieo.com': true,
  'coin-host.net': true,
  'coinlink.club': true,
  'coldemail.info': true,
  'compareshippingrates.org': true,
  'completegolfswing.com': true,
  'comwest.de': true,
  'conf.work': true,
  'consumerriot.com': true,
  'contbay.com': true,
  'cooh-2.site': true,
  'coolandwacky.us': true,
  'coolimpool.org': true,
  'copyhome.win': true,
  'coreclip.com': true,
  'cosmorph.com': true,
  'courrieltemporaire.com': true,
  'coza.ro': true,
  'crankhole.com': true,
  'crastination.de': true,
  'crazespaces.pw': true,
  'crazymailing.com': true,
  'cream.pink': true,
  'crepeau12.com': true,
  'cringemonster.com': true,
  'cross-law.ga': true,
  'cross-law.gq': true,
  'crossmailjet.com': true,
  'crossroadsmail.com': true,
  'crunchcompass.com': true,
  'crusthost.com': true,
  'cs.email': true,
  'csh.ro': true,
  'cszbl.com': true,
  'ctmailing.us': true,
  'ctos.ch': true,
  'cu.cc': true,
  'cubene.com': true,
  'cubiclink.com': true,
  'cuendita.com': true,
  'cuirushi.org': true,
  'cuoly.com': true,
  'cupbest.com': true,
  'curlhph.tk': true,
  'curryworld.de': true,
  'cust.in': true,
  'cutout.club': true,
  'cutradition.com': true,
  'cyber-innovation.club': true,
  'cyber-phone.eu': true,
  'cylab.org': true,
  'd1yun.com': true,
  'd3p.dk': true,
  'daabox.com': true,
  'dab.ro': true,
  'daemsteam.com': true,
  'daibond.info': true,
  'daily-email.com': true,
  'daintly.com': true,
  'damai.webcam': true,
  'dammexe.net': true,
  'damnthespam.com': true,
  'dandikmail.com': true,
  'darkharvestfilms.com': true,
  'daryxfox.net': true,
  'dasdasdascyka.tk': true,
  'dash-pads.com': true,
  'dataarca.com': true,
  'datarca.com': true,
  'datazo.ca': true,
  'datenschutz.ru': true,
  'datum2.com': true,
  'davidkoh.net': true,
  'davidlcreative.com': true,
  'dawin.com': true,
  'daymail.life': true,
  'daymailonline.com': true,
  'dayrep.com': true,
  'dbunker.com': true,
  'dcctb.com': true,
  'dcemail.com': true,
  'ddcrew.com': true,
  'de-a.org': true,
  'dea-21olympic.com': true,
  'deadchildren.org': true,
  'deadfake.cf': true,
  'deadfake.ga': true,
  'deadfake.ml': true,
  'deadfake.tk': true,
  'deagot.com': true,
  'dealja.com': true,
  'dealrek.com': true,
  'deekayen.us': true,
  'defomail.com': true,
  'degradedfun.net': true,
  'deinbox.com': true,
  'delayload.com': true,
  'delayload.net': true,
  'delikkt.de': true,
  'delivrmail.com': true,
  'demen.ml': true,
  'dengekibunko.ga': true,
  'dengekibunko.gq': true,
  'dengekibunko.ml': true,
  'der-kombi.de': true,
  'derkombi.de': true,
  'derluxuswagen.de': true,
  'desoz.com': true,
  'despammed.com': true,
  'dev-null.cf': true,
  'dev-null.ga': true,
  'dev-null.gq': true,
  'dev-null.ml': true,
  'developermail.com': true,
  'deyom.com': true,
  'dharmatel.net': true,
  'dhm.ro': true,
  'dhy.cc': true,
  'dialogus.com': true,
  'diapaulpainting.com': true,
  'dicopto.com': true,
  'digdig.org': true,
  'digital-message.com': true,
  'digitalesbusiness.info': true,
  'digitalmail.info': true,
  'digitalmariachis.com': true,
  'dildosfromspace.com': true,
  'dim-coin.com': true,
  'dingbone.com': true,
  'diolang.com': true,
  'directmail24.net': true,
  'disaq.com': true,
  'disbox.net': true,
  'disbox.org': true,
  'discard.cf': true,
  'discard.email': true,
  'discard.ga': true,
  'discard.gq': true,
  'discard.ml': true,
  'discard.tk': true,
  'discos4.com': true,
  'dishcatfish.com': true,
  'disign-concept.eu': true,
  'disign-revelation.com': true,
  'dispo.in': true,
  'dispomail.eu': true,
  'disposable-e.ml': true,
  'disposable-email.ml': true,
  'disposable.cf': true,
  'disposable.ga': true,
  'disposable.ml': true,
  'disposable.site': true,
  'disposableaddress.com': true,
  'disposableemailaddresses.com': true,
  'disposableinbox.com': true,
  'disposablemails.com': true,
  'dispose.it': true,
  'disposeamail.com': true,
  'disposemymail.com': true,
  'divad.ga': true,
  'divermail.com': true,
  'divismail.ru': true,
  'diwaq.com': true,
  'dlemail.ru': true,
  'dmarc.ro': true,
  'dndent.com': true,
  'dnses.ro': true,
  'doanart.com': true,
  'dob.jp': true,
  'dodgeit.com': true,
  'dodgemail.de': true,
  'dodgit.com': true,
  'dodgit.org': true,
  'dodsi.com': true,
  'doiea.com': true,
  'dolphinnet.net': true,
  'domforfb1.tk': true,
  'domforfb18.tk': true,
  'domforfb19.tk': true,
  'domforfb2.tk': true,
  'domforfb23.tk': true,
  'domforfb27.tk': true,
  'domforfb29.tk': true,
  'domforfb3.tk': true,
  'domforfb4.tk': true,
  'domforfb5.tk': true,
  'domforfb6.tk': true,
  'domforfb7.tk': true,
  'domforfb8.tk': true,
  'domforfb9.tk': true,
  'domozmail.com': true,
  'donebyngle.com': true,
  'donemail.ru': true,
  'dongqing365.com': true,
  'dontreg.com': true,
  'dontsendmespam.de': true,
  'doojazz.com': true,
  'doquier.tk': true,
  'dotman.de': true,
  'dotmsg.com': true,
  'dotslashrage.com': true,
  'doublemail.de': true,
  'douchelounge.com': true,
  'dozvon-spb.ru': true,
  'dp76.com': true,
  'dr69.site': true,
  'drdrb.com': true,
  'drdrb.net': true,
  'dred.ru': true,
  'drevo.si': true,
  'drivetagdev.com': true,
  'drmail.in': true,
  'droolingfanboy.de': true,
  'dropcake.de': true,
  'dropjar.com': true,
  'droplar.com': true,
  'dropmail.me': true,
  'dropsin.net': true,
  'drowblock.com': true,
  'dsgvo.party': true,
  'dsgvo.ru': true,
  'dshfjdafd.cloud': true,
  'dsiay.com': true,
  'dspwebservices.com': true,
  'duam.net': true,
  'duck2.club': true,
  'duk33.com': true,
  'dukedish.com': true,
  'dump-email.info': true,
  'durandinterstellar.com': true,
  'duskmail.com': true,
  'dwse.edu.pl': true,
  'dyceroprojects.com': true,
  'dz17.net': true,
  'e-mail.com': true,
  'e-mail.org': true,
  'e-marketstore.ru': true,
  'e-tomarigi.com': true,
  'e3z.de': true,
  'eanok.com': true,
  'easy-trash-mail.com': true,
  'easynetwork.info': true,
  'easytrashmail.com': true,
  'eatmea2z.club': true,
  'eay.jp': true,
  'ebbob.com': true,
  'ebeschlussbuch.de': true,
  'ecallheandi.com': true,
  'ecolo-online.fr': true,
  'edgex.ru': true,
  'edinburgh-airporthotels.com': true,
  'edv.to': true,
  'ee1.pl': true,
  'ee2.pl': true,
  'eeedv.de': true,
  'eelmail.com': true,
  'efxs.ca': true,
  'egzones.com': true,
  'einmalmail.de': true,
  'einrot.com': true,
  'einrot.de': true,
  'eintagsmail.de': true,
  'elearningjournal.org': true,
  'electro.mn': true,
  'elitevipatlantamodels.com': true,
  'elki-mkzn.ru': true,
  'email-fake.cf': true,
  'email-fake.com': true,
  'email-fake.ga': true,
  'email-fake.gq': true,
  'email-fake.ml': true,
  'email-fake.tk': true,
  'email-jetable.fr': true,
  'email-lab.com': true,
  'email-temp.com': true,
  'email.edu.pl': true,
  'email.net': true,
  'email1.pro': true,
  'emailage.cf': true,
  'emailage.ga': true,
  'emailage.gq': true,
  'emailage.ml': true,
  'emailage.tk': true,
  'emailate.com': true,
  'emailbin.net': true,
  'emailcu.icu': true,
  'emaildrop.io': true,
  'emailfake.com': true,
  'emailfake.ml': true,
  'emailfreedom.ml': true,
  'emailgenerator.de': true,
  'emailgo.de': true,
  'emailisvalid.com': true,
  'emaillime.com': true,
  'emailna.co': true,
  'emailnax.com': true,
  'emailo.pro': true,
  'emailondeck.com': true,
  'emailportal.info': true,
  'emailproxsy.com': true,
  'emailresort.com': true,
  'emails.ga': true,
  'emailsecurer.com': true,
  'emailsensei.com': true,
  'emailsingularity.net': true,
  'emailspam.cf': true,
  'emailspam.ga': true,
  'emailspam.gq': true,
  'emailspam.ml': true,
  'emailspam.tk': true,
  'emailsy.info': true,
  'emailtech.info': true,
  'emailto.de': true,
  'emailure.net': true,
  'emailz.cf': true,
  'emailz.ga': true,
  'emailz.gq': true,
  'emailz.ml': true,
  'emeil.in': true,
  'emeil.ir': true,
  'emeraldwebmail.com': true,
  'emil.com': true,
  'emkei.cf': true,
  'emkei.ga': true,
  'emkei.gq': true,
  'emkei.ml': true,
  'emkei.tk': true,
  'eml.pp.ua': true,
  'emlhub.com': true,
  'emlpro.com': true,
  'emltmp.com': true,
  'empireanime.ga': true,
  'emstjzh.com': true,
  'emz.net': true,
  'enayu.com': true,
  'enterto.com': true,
  'envy17.com': true,
  'eoffice.top': true,
  'eoopy.com': true,
  'epb.ro': true,
  'ephemail.net': true,
  'eposta.buzz': true,
  'eposta.work': true,
  'eqiluxspam.ga': true,
  'ereplyzy.com': true,
  'ericjohnson.ml': true,
  'eripo.net': true,
  'ero-tube.org': true,
  'esadverse.com': true,
  'esbano-ru.ru': true,
  'esc.la': true,
  'escapehatchapp.com': true,
  'esemay.com': true,
  'esgeneri.com': true,
  'esiix.com': true,
  'esprity.com': true,
  'estate-invest.fr': true,
  'eth2btc.info': true,
  'ether123.net': true,
  'ethereum1.top': true,
  'ethersports.org': true,
  'ethersportz.info': true,
  'etotvibor.ru': true,
  'etranquil.com': true,
  'etranquil.net': true,
  'etranquil.org': true,
  'euaqa.com': true,
  'evanfox.info': true,
  'eveav.com': true,
  'evilcomputer.com': true,
  'evopo.com': true,
  'evvgo.com': true,
  'evyush.com': true,
  'exdonuts.com': true,
  'exelica.com': true,
  'existiert.net': true,
  'exitstageleft.net': true,
  'express.net.ua': true,
  'extracurricularsociety.com': true,
  'extremail.ru': true,
  'eyepaste.com': true,
  'ez.lv': true,
  'ezehe.com': true,
  'ezfill.com': true,
  'ezstest.com': true,
  'ezztt.com': true,
  'f4k.es': true,
  'facebook-email.cf': true,
  'facebook-email.ga': true,
  'facebook-email.ml': true,
  'facebookmail.gq': true,
  'facebookmail.ml': true,
  'fackme.gq': true,
  'fadingemail.com': true,
  'faecesmail.me': true,
  'fag.wf': true,
  'failbone.com': true,
  'faithkills.com': true,
  'fake-box.com': true,
  'fake-email.pp.ua': true,
  'fake-mail.cf': true,
  'fake-mail.ga': true,
  'fake-mail.ml': true,
  'fakedemail.com': true,
  'fakeinbox.cf': true,
  'fakeinbox.ga': true,
  'fakeinbox.info': true,
  'fakeinbox.ml': true,
  'fakeinbox.tk': true,
  'fakemail.io': true,
  'fakemailz.com': true,
  'fallinhay.com': true,
  'fammix.com': true,
  'fanclub.pm': true,
  'fangoh.com': true,
  'fansworldwide.de': true,
  'fantasymail.de': true,
  'farrse.co.uk': true,
  'fast-email.info': true,
  'fast-mail.fr': true,
  'fasternet.biz': true,
  'fatflap.com': true,
  'fbma.tk': true,
  'fddns.ml': true,
  'fdfdsfds.com': true,
  'femailtor.com': true,
  'fer-gabon.org': true,
  'fermaxxi.ru': true,
  'fettometern.com': true,
  'fexbox.org': true,
  'fexbox.ru': true,
  'fexpost.com': true,
  'fextemp.com': true,
  'ficken.de': true,
  'fictionsite.com': true,
  'fightallspam.com': true,
  'figjs.com': true,
  'figshot.com': true,
  'figurescoin.com': true,
  'fiifke.de': true,
  'filbert4u.com': true,
  'filberts4u.com': true,
  'film-blog.biz': true,
  'findemail.info': true,
  'findu.pl': true,
  'finews.biz': true,
  'fir.hk': true,
  'firemailbox.club': true,
  'fitnesrezink.ru': true,
  'fivemail.de': true,
  'fixmail.tk': true,
  'fizmail.com': true,
  'fleckens.hu': true,
  'flemail.ru': true,
  'flexvio.com': true,
  'fliegender.fish': true,
  'flowu.com': true,
  'flu.cc': true,
  'fluidsoft.us': true,
  'flurred.com': true,
  'fly-ts.de': true,
  'flyinggeek.net': true,
  'flymail.tk': true,
  'flyspam.com': true,
  'foobarbot.net': true,
  'footard.com': true,
  'foreastate.com': true,
  'forecastertests.com': true,
  'foreskin.cf': true,
  'foreskin.ga': true,
  'foreskin.gq': true,
  'foreskin.ml': true,
  'foreskin.tk': true,
  'fornow.eu': true,
  'forspam.net': true,
  'forward.cat': true,
  'fosil.pro': true,
  'foxja.com': true,
  'foxtrotter.info': true,
  'fr.cr': true,
  'fr.nf': true,
  'fragolina2.tk': true,
  'frappina.tk': true,
  'free-email.cf': true,
  'free-email.ga': true,
  'free-temp.net': true,
  'freebabysittercam.com': true,
  'freeblackbootytube.com': true,
  'freecat.net': true,
  'freedom4you.info': true,
  'freedompop.us': true,
  'freefattymovies.com': true,
  'freehotmail.net': true,
  'freeinbox.email': true,
  'freelance-france.eu': true,
  'freeletter.me': true,
  'freemails.cf': true,
  'freemails.ga': true,
  'freemails.ml': true,
  'freemeil.ga': true,
  'freemeil.gq': true,
  'freemeil.ml': true,
  'freeml.net': true,
  'freeplumpervideos.com': true,
  'freerubli.ru': true,
  'freeschoolgirlvids.com': true,
  'freesistercam.com': true,
  'freeteenbums.com': true,
  'freundin.ru': true,
  'front14.org': true,
  'frwdmail.com': true,
  'ftp.sh': true,
  'ftpinc.ca': true,
  'fuckedupload.com': true,
  'fuckingduh.com': true,
  'fuckme69.club': true,
  'fucknloveme.top': true,
  'fuckxxme.top': true,
  'fudgerub.com': true,
  'fuirio.com': true,
  'fukaru.com': true,
  'fukurou.ch': true,
  'fullangle.org': true,
  'fulvie.com': true,
  'fun64.com': true,
  'funnycodesnippets.com': true,
  'funnymail.de': true,
  'furzauflunge.de': true,
  'futuramind.com': true,
  'fuwa.be': true,
  'fuwa.li': true,
  'fuwamofu.com': true,
  'fuwari.be': true,
  'fxnxs.com': true,
  'fyii.de': true,
  'g14l71lb.com': true,
  'g1xmail.top': true,
  'g2xmail.top': true,
  'g3xmail.top': true,
  'g4hdrop.us': true,
  'gafy.net': true,
  'gage.ga': true,
  'galaxy.tv': true,
  'gally.jp': true,
  'gamail.top': true,
  'gamegregious.com': true,
  'gamgling.com': true,
  'garasikita.pw': true,
  'garbagecollector.org': true,
  'garbagemail.org': true,
  'gardenscape.ca': true,
  'garizo.com': true,
  'garrymccooey.com': true,
  'gav0.com': true,
  'gawab.com': true,
  'gbcmail.win': true,
  'gbmail.top': true,
  'gcmail.top': true,
  'gdmail.top': true,
  'gedmail.win': true,
  'geekforex.com': true,
  'geew.ru': true,
  'gehensiemirnichtaufdensack.de': true,
  'geldwaschmaschine.de': true,
  'gelitik.in': true,
  'genderfuck.net': true,
  'geronra.com': true,
  'geschent.biz': true,
  'get-mail.cf': true,
  'get-mail.ga': true,
  'get-mail.ml': true,
  'get-mail.tk': true,
  'get.pp.ua': true,
  'get1mail.com': true,
  'getairmail.cf': true,
  'getairmail.ga': true,
  'getairmail.gq': true,
  'getairmail.ml': true,
  'getairmail.tk': true,
  'geteit.com': true,
  'getfun.men': true,
  'getmails.eu': true,
  'getnada.com': true,
  'getnowtoday.cf': true,
  'getonemail.com': true,
  'getonemail.net': true,
  'getover.de': true,
  'getsimpleemail.com': true,
  'gett.icu': true,
  'gexik.com': true,
  'ggmal.ml': true,
  'giacmosuaviet.info': true,
  'giaiphapmuasam.com': true,
  'giantmail.de': true,
  'gifto12.com': true,
  'ginzi.be': true,
  'ginzi.co.uk': true,
  'ginzi.es': true,
  'ginzi.net': true,
  'ginzy.co.uk': true,
  'ginzy.eu': true,
  'giratex.com': true,
  'girlmail.win': true,
  'girlsindetention.com': true,
  'gishpuppy.com': true,
  'giveh2o.info': true,
  'givememail.club': true,
  'givmail.com': true,
  'gixenmixen.com': true,
  'glitch.sx': true,
  'globaltouron.com': true,
  'glubex.com': true,
  'glucosegrin.com': true,
  'gmal.com': true,
  'gmatch.org': true,
  'gmial.com': true,
  'gmx1mail.top': true,
  'gmxmail.top': true,
  'gmxmail.win': true,
  'gnctr-calgary.com': true,
  'go2usa.info': true,
  'go2vpn.net': true,
  'goatmail.uk': true,
  'goemailgo.com': true,
  'golemico.com': true,
  'gomail.in': true,
  'goonby.com': true,
  'goplaygame.ru': true,
  'gorillaswithdirtyarmpits.com': true,
  'goround.info': true,
  'gosarlar.com': true,
  'gosuslugi-spravka.ru': true,
  'gothere.biz': true,
  'gotmail.com': true,
  'gotmail.net': true,
  'gotmail.org': true,
  'grandmamail.com': true,
  'grandmasmail.com': true,
  'grassdev.com': true,
  'greencafe24.com': true,
  'greendike.com': true,
  'greenhousemail.com': true,
  'greggamel.com': true,
  'greggamel.net': true,
  'gregorsky.zone': true,
  'gregorygamel.com': true,
  'gregorygamel.net': true,
  'grish.de': true,
  'griuc.schule': true,
  'grn.cc': true,
  'groupbuff.com': true,
  'grugrug.ru': true,
  'gruz-m.ru': true,
  'gs-arc.org': true,
  'gsredcross.org': true,
  'gsrv.co.uk': true,
  'gsxstring.ga': true,
  'gudanglowongan.com': true,
  'guerillamail.biz': true,
  'guerillamail.com': true,
  'guerillamail.de': true,
  'guerillamail.info': true,
  'guerillamail.net': true,
  'guerillamail.org': true,
  'guerillamailblock.com': true,
  'gufum.com': true,
  'gustr.com': true,
  'gxemail.men': true,
  'gynzi.co.uk': true,
  'gynzi.es': true,
  'gynzy.at': true,
  'gynzy.es': true,
  'gynzy.eu': true,
  'gynzy.gr': true,
  'gynzy.info': true,
  'gynzy.lt': true,
  'gynzy.mobi': true,
  'gynzy.pl': true,
  'gynzy.ro': true,
  'gynzy.sk': true,
  'gzb.ro': true,
  'h8s.org': true,
  'habitue.net': true,
  'hacccc.com': true,
  'hackersquad.tk': true,
  'hackthatbit.ch': true,
  'hahawrong.com': true,
  'haida-edu.cn': true,
  'hairs24.ru': true,
  'hamham.uk': true,
  'hangxomcuatoilatotoro.ml': true,
  'happy2023year.com': true,
  'happydomik.ru': true,
  'haribu.com': true,
  'hasanmail.ml': true,
  'hat-geld.de': true,
  'hatespam.org': true,
  'hawrong.com': true,
  'haydoo.com': true,
  'hazelnut4u.com': true,
  'hazelnuts4u.com': true,
  'hazmatshipping.org': true,
  'hccmail.win': true,
  'headstrong.de': true,
  'heathenhammer.com': true,
  'heathenhero.com': true,
  'hecat.es': true,
  'heisei.be': true,
  'helloricky.com': true,
  'helpinghandtaxcenter.org': true,
  'helpjobs.ru': true,
  'heros3.com': true,
  'herpderp.nl': true,
  'hezll.com': true,
  'hi5.si': true,
  'hiddentragedy.com': true,
  'hidebox.org': true,
  'hidebusiness.xyz': true,
  'hidemail.pro': true,
  'hidemail.us': true,
  'highbros.org': true,
  'hiltonvr.com': true,
  'himail.online': true,
  'hmail.us': true,
  'hmamail.com': true,
  'hmh.ro': true,
  'hoanggiaanh.com': true,
  'hoanglong.tech': true,
  'hochsitze.com': true,
  'hola.org': true,
  'holl.ga': true,
  'honeys.be': true,
  'honor-8.com': true,
  'hopemail.biz': true,
  'hornyalwary.top': true,
  'host1s.com': true,
  'hostcalls.com': true,
  'hostguru.top': true,
  'hostingmail.me': true,
  'hostlaba.com': true,
  'hot-mail.cf': true,
  'hot-mail.ga': true,
  'hot-mail.gq': true,
  'hot-mail.ml': true,
  'hot-mail.tk': true,
  'hotmai.com': true,
  'hotmailproduct.com': true,
  'hotmial.com': true,
  'hotprice.co': true,
  'hotsoup.be': true,
  'housat.com': true,
  'hpc.tw': true,
  'hs.vc': true,
  'ht.cx': true,
  'huangniu8.com': true,
  'huizk.com': true,
  'hukkmu.tk': true,
  'hulapla.de': true,
  'hungpackage.com': true,
  'hushmail.cf': true,
  'huskion.net': true,
  'hvastudiesucces.nl': true,
  'hwsye.net': true,
  'hypenated-domain.com': true,
  'i2pmail.org': true,
  'i6.cloudns.cc': true,
  'iaoss.com': true,
  'ibnuh.bz': true,
  'icantbelieveineedtoexplainthisshit.com': true,
  'icemail.club': true,
  'ichigo.me': true,
  'icx.in': true,
  'icx.ro': true,
  'icznn.com': true,
  'idx4.com': true,
  'idxue.com': true,
  'iencm.com': true,
  'iffymedia.com': true,
  'ige.es': true,
  'igg.biz': true,
  'ignoremail.com': true,
  'ihazspam.ca': true,
  'ikbenspamvrij.nl': true,
  'ikuromi.com': true,
  'illistnoise.com': true,
  'ilovespam.com': true,
  'imail1.net': true,
  'imails.info': true,
  'imailt.com': true,
  'imgof.com': true,
  'imgv.de': true,
  'immo-gerance.info': true,
  'imperialcnk.com': true,
  'imstations.com': true,
  'imul.info': true,
  'in-ulm.de': true,
  'in2reach.com': true,
  'inactivemachine.com': true,
  'inbound.plus': true,
  'inbox2.info': true,
  'inboxbear.com': true,
  'inboxdesign.me': true,
  'inboxed.im': true,
  'inboxed.pw': true,
  'inboxkitten.com': true,
  'inboxproxy.com': true,
  'inboxstore.me': true,
  'inclusiveprogress.com': true,
  'incognitomail.com': true,
  'incognitomail.net': true,
  'incognitomail.org': true,
  'incq.com': true,
  'ind.st': true,
  'indieclad.com': true,
  'indirect.ws': true,
  'indomaed.pw': true,
  'indomina.cf': true,
  'indoserver.stream': true,
  'indosukses.press': true,
  'ineec.net': true,
  'inggo.org': true,
  'inkiny.com': true,
  'inkomail.com': true,
  'inmynetwork.tk': true,
  'inoutmail.de': true,
  'inoutmail.eu': true,
  'inoutmail.info': true,
  'inoutmail.net': true,
  'inpwa.com': true,
  'insanumingeniumhomebrew.com': true,
  'insorg-mail.info': true,
  'instaddr.ch': true,
  'instaddr.uk': true,
  'instaddr.win': true,
  'instance-email.com': true,
  'instantblingmail.info': true,
  'instantemailaddress.com': true,
  'instantmail.fr': true,
  'instmail.uk': true,
  'internet-v-stavropole.ru': true,
  'internetkeno.com': true,
  'internetoftags.com': true,
  'interstats.org': true,
  'intersteller.com': true,
  'intopwa.com': true,
  'intopwa.net': true,
  'intopwa.org': true,
  'investore.co': true,
  'iozak.com': true,
  'ip4.pp.ua': true,
  'ip6.pp.ua': true,
  'ipoo.org': true,
  'ippandansei.tk': true,
  'ipsur.org': true,
  'irabops.com': true,
  'iralborz.bid': true,
  'irc.so': true,
  'irishspringrealty.com': true,
  'iroid.com': true,
  'ironiebehindert.de': true,
  'irssi.tv': true,
  'is.af': true,
  'isdaq.com': true,
  'ishop2k.com': true,
  'isosq.com': true,
  'istii.ro': true,
  'isukrainestillacountry.com': true,
  'it7.ovh': true,
  'italy-mail.com': true,
  'itcompu.com': true,
  'itfast.net': true,
  'itsjiff.com': true,
  'itunesgiftcodegenerator.com': true,
  'iubridge.com': true,
  'iuemail.men': true,
  'ixaks.com': true,
  'ixx.io': true,
  'j-p.us': true,
  'jafps.com': true,
  'jaga.email': true,
  'jajxz.com': true,
  'jakemsr.com': true,
  'janproz.com': true,
  'jaqis.com': true,
  'jdmadventures.com': true,
  'jdz.ro': true,
  'je-recycle.info': true,
  'jellow.ml': true,
  'jellyrolls.com': true,
  'jeoce.com': true,
  'jet-renovation.fr': true,
  'jetable.pp.ua': true,
  'ji5.de': true,
  'ji6.de': true,
  'ji7.de': true,
  'jiooq.com': true,
  'jmail.ovh': true,
  'jmail.ro': true,
  'jobbikszimpatizans.hu': true,
  'jobbrett.com': true,
  'jobposts.net': true,
  'jobs-to-be-done.net': true,
  'joelpet.com': true,
  'joetestalot.com': true,
  'jopho.com': true,
  'joseihorumon.info': true,
  'josse.ltd': true,
  'jourrapide.com': true,
  'jpco.org': true,
  'jsrsolutions.com': true,
  'jumonji.tk': true,
  'jungkamushukum.com': true,
  'junk.to': true,
  'junk1e.com': true,
  'junkmail.ga': true,
  'junkmail.gq': true,
  'just-email.com': true,
  'justemail.ml': true,
  'juyouxi.com': true,
  'jwork.ru': true,
  'kademen.com': true,
  'kadokawa.cf': true,
  'kadokawa.ga': true,
  'kadokawa.gq': true,
  'kadokawa.ml': true,
  'kadokawa.tk': true,
  'kaengu.ru': true,
  'kagi.be': true,
  'kakadua.net': true,
  'kalapi.org': true,
  'kamen-market.ru': true,
  'kamsg.com': true,
  'kaovo.com': true,
  'kappala.info': true,
  'kara-turk.net': true,
  'karatraman.ml': true,
  'kariplan.com': true,
  'karta-kykyruza.ru': true,
  'kartvelo.com': true,
  'katztube.com': true,
  'kazelink.ml': true,
  'kbox.li': true,
  'kcrw.de': true,
  'keepmymail.com': true,
  'keinhirn.de': true,
  'keipino.de': true,
  'kekita.com': true,
  'kellychibale-researchgroup-uct.com': true,
  'kemptvillebaseball.com': true,
  'kennedy808.com': true,
  'kiani.com': true,
  'killmail.net': true,
  'kimsdisk.com': true,
  'kinda.email': true,
  'kindamail.com': true,
  'kingsq.ga': true,
  'kino-100.ru': true,
  'kiois.com': true,
  'kismail.ru': true,
  'kisstwink.com': true,
  'kitnastar.com': true,
  'kjkszpjcompany.com': true,
  'kkmail.be': true,
  'kkoup.com': true,
  'kksm.be': true,
  'klassmaster.com': true,
  'klassmaster.net': true,
  'klick-tipp.us': true,
  'klipschx12.com': true,
  'kloap.com': true,
  'klovenode.com': true,
  'kludgemush.com': true,
  'kmail.li': true,
  'kmail.live': true,
  'kmhow.com': true,
  'knickerbockerban.de': true,
  'knol-power.nl': true,
  'kobrandly.com': true,
  'kommunity.biz': true,
  'kon42.com': true,
  'konican.com': true,
  'konultant-jurist.ru': true,
  'kook.ml': true,
  'kopagas.com': true,
  'kopaka.net': true,
  'korona-nedvizhimosti.ru': true,
  'koshu.ru': true,
  'kosmetik-obatkuat.com': true,
  'kostenlosemailadresse.de': true,
  'kpay.be': true,
  'kpooa.com': true,
  'kpost.be': true,
  'krd.ag': true,
  'krsw.tk': true,
  'kruay.com': true,
  'krypton.tk': true,
  'ksmtrck.tk': true,
  'kuhrap.com': true,
  'kulmeo.com': true,
  'kulturbetrieb.info': true,
  'kumli.racing': true,
  'kutakbisajauhjauh.gq': true,
  'kvhrr.com': true,
  'kvhrs.com': true,
  'kvhrw.com': true,
  'kwift.net': true,
  'kwilco.net': true,
  'kyal.pl': true,
  'kyois.com': true,
  'kzccv.com': true,
  'l-c-a.us': true,
  'l33r.eu': true,
  'l6factors.com': true,
  'laafd.com': true,
  'labetteraverouge.at': true,
  'labworld.org': true,
  'lacedmail.com': true,
  'lackmail.net': true,
  'lackmail.ru': true,
  'lacto.info': true,
  'lags.us': true,
  'lain.ch': true,
  'lak.pp.ua': true,
  'lakelivingstonrealestate.com': true,
  'lakqs.com': true,
  'lamasticots.com': true,
  'lambsauce.de': true,
  'landmail.co': true,
  'laoeq.com': true,
  'larisia.com': true,
  'larland.com': true,
  'last-chance.pro': true,
  'laste.ml': true,
  'lastmail.co': true,
  'lastmail.com': true,
  'laymro.com': true,
  'lazyinbox.com': true,
  'lazyinbox.us': true,
  'ldaho.biz': true,
  'ldop.com': true,
  'ldtp.com': true,
  'le-tim.ru': true,
  'lee.mx': true,
  'leeching.net': true,
  'leetmail.co': true,
  'legalrc.loan': true,
  'lellno.gq': true,
  'lenovog4.com': true,
  'lerbhe.com': true,
  'letmeinonthis.com': true,
  'lez.se': true,
  'lgxscreen.com': true,
  'liamcyrus.com': true,
  'lifetimefriends.info': true,
  'lifetotech.com': true,
  'ligsb.com': true,
  'lillemap.net': true,
  'lilo.me': true,
  'lilspam.com': true,
  'lindenbaumjapan.com': true,
  'linkedintuts2016.pw': true,
  'linshiyou.com': true,
  'linshiyouxiang.net': true,
  'linuxmail.so': true,
  'liveradio.tk': true,
  'lkgn.se': true,
  'llogin.ru': true,
  'loadby.us': true,
  'loan101.pro': true,
  'loaoa.com': true,
  'loapq.com': true,
  'locanto1.club': true,
  'locantofuck.top': true,
  'locantowsite.club': true,
  'locomodev.net': true,
  'login-email.cf': true,
  'login-email.ga': true,
  'login-email.ml': true,
  'login-email.tk': true,
  'logular.com': true,
  'loh.pp.ua': true,
  'loin.in': true,
  'lolmail.biz': true,
  'lordsofts.com': true,
  'losemymail.com': true,
  'lovemeet.faith': true,
  'lovemeleaveme.com': true,
  'lpfmgmtltd.com': true,
  'lr7.us': true,
  'lroid.com': true,
  'lru.me': true,
  'ls-server.ru': true,
  'lsyx24.com': true,
  'luckymail.org': true,
  'lukecarriere.com': true,
  'lukemail.info': true,
  'lukop.dk': true,
  'luv2.us': true,
  'lyfestylecreditsolutions.com': true,
  'lyft.live': true,
  'lyricspad.net': true,
  'lzoaq.com': true,
  'm4ilweb.info': true,
  'mac-24.com': true,
  'macr2.com': true,
  'macromaid.com': true,
  'macromice.info': true,
  'magamail.com': true,
  'maggotymeat.ga': true,
  'magicbox.ro': true,
  'magim.be': true,
  'magspam.net': true,
  'maidlow.info': true,
  'mail-card.net': true,
  'mail-easy.fr': true,
  'mail-filter.com': true,
  'mail-help.net': true,
  'mail-hosting.co': true,
  'mail-hub.info': true,
  'mail-now.top': true,
  'mail-owl.com': true,
  'mail-share.com': true,
  'mail-temporaire.com': true,
  'mail-temporaire.fr': true,
  'mail-tester.com': true,
  'mail.wtf': true,
  'mail0.ga': true,
  'mail1.top': true,
  'mail114.net': true,
  'mail1web.org': true,
  'mail22.club': true,
  'mail666.ru': true,
  'mail7.io': true,
  'mail707.com': true,
  'mail72.com': true,
  'mailapp.top': true,
  'mailback.com': true,
  'mailbiscuit.com': true,
  'mailbiz.biz': true,
  'mailbox.in.ua': true,
  'mailbox52.ga': true,
  'mailbox80.biz': true,
  'mailbox82.biz': true,
  'mailbox87.de': true,
  'mailbox92.biz': true,
  'mailboxy.fun': true,
  'mailchop.com': true,
  'mailcker.com': true,
  'maildax.me': true,
  'maildrop.cc': true,
  'maildrop.cf': true,
  'maildrop.ga': true,
  'maildrop.gq': true,
  'maildrop.ml': true,
  'maildu.de': true,
  'maildx.com': true,
  'maileater.com': true,
  'mailed.in': true,
  'maileimer.de': true,
  'maileme101.com': true,
  'mailers.edu.pl': true,
  'mailexpire.com': true,
  'mailf5.com': true,
  'mailfa.tk': true,
  'mailfall.com': true,
  'mailfirst.icu': true,
  'mailfree.ga': true,
  'mailfree.gq': true,
  'mailfree.ml': true,
  'mailgutter.com': true,
  'mailhazard.com': true,
  'mailhazard.us': true,
  'mailhex.com': true,
  'mailhub.pro': true,
  'mailhz.me': true,
  'mailinatar.com': true,
  'mailinator.co.uk': true,
  'mailinator.gq': true,
  'mailinator.info': true,
  'mailinator.org': true,
  'mailinator.us': true,
  'mailinator0.com': true,
  'mailinator1.com': true,
  'mailinator2.net': true,
  'mailinator3.com': true,
  'mailinator4.com': true,
  'mailinator5.com': true,
  'mailinator6.com': true,
  'mailinator7.com': true,
  'mailinator8.com': true,
  'mailinator9.com': true,
  'mailita.tk': true,
  'mailjunk.cf': true,
  'mailjunk.ga': true,
  'mailjunk.gq': true,
  'mailjunk.ml': true,
  'mailjunk.tk': true,
  'mailmate.com': true,
  'mailme.gq': true,
  'mailme.ir': true,
  'mailmoth.com': true,
  'mailna.biz': true,
  'mailna.co': true,
  'mailna.in': true,
  'mailna.me': true,
  'mailnator.com': true,
  'mailonaut.com': true,
  'mailorc.com': true,
  'mailosaur.net': true,
  'mailox.fun': true,
  'mailpluss.com': true,
  'mailpooch.com': true,
  'mailpoof.com': true,
  'mailpress.gq': true,
  'mailproxsy.com': true,
  'mailquack.com': true,
  'mailsac.com': true,
  'mailseal.de': true,
  'mailshiv.com': true,
  'mailsucker.net': true,
  'mailt.net': true,
  'mailt.top': true,
  'mailtechx.com': true,
  'mailtemporaire.com': true,
  'mailtemporaire.fr': true,
  'mailto.plus': true,
  'mailtraps.com': true,
  'mailtrix.net': true,
  'mailuniverse.co.uk': true,
  'mailzi.ru': true,
  'mailzilla.org': true,
  'mainerfolg.info': true,
  'makemenaughty.club': true,
  'malahov.de': true,
  'malayalamdtp.com': true,
  'mama3.org': true,
  'mamulenok.ru': true,
  'mandraghen.cf': true,
  'manifestgenerator.com': true,
  'mannawo.com': true,
  'mansiondev.com': true,
  'mark-compressoren.ru': true,
  'marketlink.info': true,
  'markmurfin.com': true,
  'mask03.ru': true,
  'maskmy.id': true,
  'masonline.info': true,
  'maswae.world': true,
  'matamuasu.ga': true,
  'matchpol.net': true,
  'matra.site': true,
  'max-mail.org': true,
  'mbox.re': true,
  'mcache.net': true,
  'mciek.com': true,
  'mdhc.tk': true,
  'mdz.email': true,
  'meantinc.com': true,
  'mebelnu.info': true,
  'mechanicalresumes.com': true,
  'medkabinet-uzi.ru': true,
  'meepsheep.eu': true,
  'meidecn.com': true,
  'meltedbrownies.com': true,
  'memsg.site': true,
  'mentonit.net': true,
  'mepost.pw': true,
  'merepost.com': true,
  'merry.pink': true,
  'meruado.uk': true,
  'messwiththebestdielikethe.rest': true,
  'metadownload.org': true,
  'metaintern.net': true,
  'metalunits.com': true,
  'mezimages.net': true,
  'mfsa.info': true,
  'mfsa.ru': true,
  'mhzayt.online': true,
  'miaferrari.com': true,
  'miauj.com': true,
  'midcoastcustoms.com': true,
  'midcoastcustoms.net': true,
  'midcoastsolutions.com': true,
  'midcoastsolutions.net': true,
  'midiharmonica.com': true,
  'midlertidig.com': true,
  'midlertidig.net': true,
  'midlertidig.org': true,
  'migmail.net': true,
  'migmail.pl': true,
  'migumail.com': true,
  'mihep.com': true,
  'mijnhva.nl': true,
  'minimail.gq': true,
  'ministry-of-silly-walks.de': true,
  'minsmail.com': true,
  'mirai.re': true,
  'misterpinball.de': true,
  'miucce.com': true,
  'mji.ro': true,
  'mjj.edu.ge': true,
  'mkpfilm.com': true,
  'ml8.ca': true,
  'mliok.com': true,
  'mm.my': true,
  'mm5.se': true,
  'mnode.me': true,
  'moakt.cc': true,
  'moakt.co': true,
  'mobileninja.co.uk': true,
  'mobilevpn.top': true,
  'mockmyid.com': true,
  'moeri.org': true,
  'mofu.be': true,
  'mohmal.com': true,
  'mohmal.im': true,
  'mohmal.in': true,
  'mohmal.tech': true,
  'moimoi.re': true,
  'molms.com': true,
  'momentics.ru': true,
  'monachat.tk': true,
  'monadi.ml': true,
  'moneypipe.net': true,
  'monumentmail.com': true,
  'moonwake.com': true,
  'moot.es': true,
  'moreawesomethanyou.com': true,
  'moreorcs.com': true,
  'morriesworld.ml': true,
  'morsin.com': true,
  'moruzza.com': true,
  'motique.de': true,
  'mountainregionallibrary.net': true,
  'mox.pp.ua': true,
  'moy-elektrik.ru': true,
  'moza.pl': true,
  'mozej.com': true,
  'mp-j.ga': true,
  'mr24.co': true,
  'mrvpm.net': true,
  'mrvpt.com': true,
  'msgos.com': true,
  'mspeciosa.com': true,
  'msrc.ml': true,
  'mswork.ru': true,
  'msxd.com': true,
  'mt2009.com': true,
  'mt2014.com': true,
  'mt2015.com': true,
  'mtmdev.com': true,
  'muathegame.com': true,
  'muchomail.com': true,
  'mucincanon.com': true,
  'muehlacker.tk': true,
  'muell.icu': true,
  'muell.io': true,
  'muell.monster': true,
  'muell.xyz': true,
  'muellemail.com': true,
  'muellmail.com': true,
  'munoubengoshi.gq': true,
  'musiccode.me': true,
  'mutant.me': true,
  'mvrht.com': true,
  'mvrht.net': true,
  'mwarner.org': true,
  'mxclip.com': true,
  'mxfuel.com': true,
  'my-pomsies.ru': true,
  'my-teddyy.ru': true,
  'my10minutemail.com': true,
  'mybitti.de': true,
  'mycleaninbox.net': true,
  'mycorneroftheinter.net': true,
  'myde.ml': true,
  'mydefipet.live': true,
  'mydemo.equipment': true,
  'myecho.es': true,
  'myemailboxy.com': true,
  'mygeoweb.info': true,
  'myindohome.services': true,
  'myinfoinc.com': true,
  'myinterserver.ml': true,
  'mykickassideas.com': true,
  'mymail-in.net': true,
  'mymail90.com': true,
  'mymailoasis.com': true,
  'mymaily.lol': true,
  'mynetstore.de': true,
  'myopang.com': true,
  'mypacks.net': true,
  'mypartyclip.de': true,
  'myphantomemail.com': true,
  'mysamp.de': true,
  'myspaceinc.com': true,
  'myspaceinc.net': true,
  'myspaceinc.org': true,
  'myspacepimpedup.com': true,
  'myspamless.com': true,
  'mystvpn.com': true,
  'mysugartime.ru': true,
  'mytemp.email': true,
  'mytempemail.com': true,
  'mytempmail.com': true,
  'mytrashmail.com': true,
  'mywarnernet.net': true,
  'mywrld.site': true,
  'mywrld.top': true,
  'myzx.com': true,
  'mzico.com': true,
  'n1nja.org': true,
  'na-cat.com': true,
  'nabuma.com': true,
  'nada.email': true,
  'nada.ltd': true,
  'nagi.be': true,
  'nakedtruth.biz': true,
  'namewok.com': true,
  'nanonym.ch': true,
  'naslazhdai.ru': true,
  'nationalgardeningclub.com': true,
  'nawmin.info': true,
  'naymedia.com': true,
  'nbzmr.com': true,
  'negated.com': true,
  'neko2.net': true,
  'nekochan.fr': true,
  'nekosan.uk': true,
  'neomailbox.com': true,
  'neotlozhniy-zaim.ru': true,
  'nepwk.com': true,
  'nervmich.net': true,
  'nervtmich.net': true,
  'net1mail.com': true,
  'netcom.ws': true,
  'netmails.com': true,
  'netmails.net': true,
  'netricity.nl': true,
  'netris.net': true,
  'netviewer-france.com': true,
  'netzidiot.de': true,
  'nevermail.de': true,
  'newbpotato.tk': true,
  'newfilm24.ru': true,
  'newideasfornewpeople.info': true,
  'newmail.top': true,
  'next.ovh': true,
  'nextmail.info': true,
  'nextstopvalhalla.com': true,
  'nezdiro.org': true,
  'nezid.com': true,
  'nezumi.be': true,
  'nezzart.com': true,
  'nfast.net': true,
  'nguyenusedcars.com': true,
  'nh3.ro': true,
  'nice-4u.com': true,
  'nicknassar.com': true,
  'nincsmail.com': true,
  'nincsmail.hu': true,
  'niseko.be': true,
  'niwl.net': true,
  'nm7.cc': true,
  'nmail.cf': true,
  'nnh.com': true,
  'nnot.net': true,
  'nnoway.ru': true,
  'no-spam.ws': true,
  'no-ux.com': true,
  'noblepioneer.com': true,
  'nobugmail.com': true,
  'nobulk.com': true,
  'nobuma.com': true,
  'noclickemail.com': true,
  'nodezine.com': true,
  'nogmailspam.info': true,
  'noicd.com': true,
  'nokiamail.com': true,
  'nolemail.ga': true,
  'nomail.cf': true,
  'nomail.ga': true,
  'nomail.pw': true,
  'nomail2me.com': true,
  'nomorespamemails.com': true,
  'nonspam.eu': true,
  'nonspammer.de': true,
  'nonze.ro': true,
  'noref.in': true,
  'norseforce.com': true,
  'norwegischlernen.info': true,
  'nospam4.us': true,
  'nospamfor.us': true,
  'nospamthanks.info': true,
  'nothingtoseehere.ca': true,
  'notif.me': true,
  'notmailinator.com': true,
  'notrnailinator.com': true,
  'notsharingmy.info': true,
  'now.im': true,
  'nowhere.org': true,
  'nowmymail.com': true,
  'nowmymail.net': true,
  'nproxi.com': true,
  'nthrl.com': true,
  'ntlhelp.net': true,
  'nubescontrol.com': true,
  'nullbox.info': true,
  'nurfuerspam.de': true,
  'nut.cc': true,
  'nutpa.net': true,
  'nuts2trade.com': true,
  'nvhrw.com': true,
  'nwldx.com': true,
  'nwytg.com': true,
  'nwytg.net': true,
  'ny7.me': true,
  'nyasan.com': true,
  'nypato.com': true,
  'nyrmusic.com': true,
  'o2stk.org': true,
  'o7i.net': true,
  'oalsp.com': true,
  'obfusko.com': true,
  'objectmail.com': true,
  'obobbo.com': true,
  'oborudovanieizturcii.ru': true,
  'obxpestcontrol.com': true,
  'octovie.com': true,
  'odaymail.com': true,
  'odem.com': true,
  'odnorazovoe.ru': true,
  'oepia.com': true,
  'oerpub.org': true,
  'offshore-proxies.net': true,
  'ofisher.net': true,
  'ohaaa.de': true,
  'ohi.tw': true,
  'oida.icu': true,
  'oing.cf': true,
  'okclprojects.com': true,
  'okinawa.li': true,
  'okrent.us': true,
  'okzk.com': true,
  'olimp-case.ru': true,
  'olypmall.ru': true,
  'omail.pro': true,
  'omnievents.org': true,
  'omtecha.com': true,
  'one-mail.top': true,
  'one-time.email': true,
  'one2mail.info': true,
  'onekisspresave.com': true,
  'onemail.host': true,
  'oneoffemail.com': true,
  'oneoffmail.com': true,
  'onetm.jp': true,
  'onewaymail.com': true,
  'onlatedotcom.info': true,
  'online.ms': true,
  'onlineidea.info': true,
  'onlyapp.net': true,
  'onqin.com': true,
  'ontyne.biz': true,
  'oohioo.com': true,
  'oolus.com': true,
  'oonies-shoprus.ru': true,
  'oopi.org': true,
  'oosln.com': true,
  'opayq.com': true,
  'openavz.com': true,
  'opendns.ro': true,
  'opentrash.com': true,
  'opmmedia.ga': true,
  'opp24.com': true,
  'optimaweb.me': true,
  'opwebw.com': true,
  'oranek.com': true,
  'ordinaryamerican.net': true,
  'oreidresume.com': true,
  'orgmbx.cc': true,
  'oroki.de': true,
  'oshietechan.link': true,
  'otherinbox.com': true,
  'ourklips.com': true,
  'ourpreviewdomain.com': true,
  'outlawspam.com': true,
  'outlook.edu.pl': true,
  'outmail.win': true,
  'ovomail.co': true,
  'ovpn.to': true,
  'owleyes.ch': true,
  'owlpic.com': true,
  'ownsyou.de': true,
  'oxopoha.com': true,
  'ozatvn.com': true,
  'ozyl.de': true,
  'p-banlis.ru': true,
  'p33.org': true,
  'p71ce1m.com': true,
  'pa9e.com': true,
  'pachilly.com': true,
  'packiu.com': true,
  'pagamenti.tk': true,
  'paharpurmim.ga': true,
  'pakadebu.ga': true,
  'pamaweb.com': true,
  'pancakemail.com': true,
  'papierkorb.me': true,
  'paplease.com': true,
  'para2019.ru': true,
  'parlimentpetitioner.tk': true,
  'pastebitch.com': true,
  'patonce.com': true,
  'pavilionx2.com': true,
  'payperex2.com': true,
  'payspun.com': true,
  'pe.hu': true,
  'pecinan.com': true,
  'pecinan.net': true,
  'pecinan.org': true,
  'penisgoes.in': true,
  'penoto.tk': true,
  'pepbot.com': true,
  'peterdethier.com': true,
  'petloca.com': true,
  'petrzilka.net': true,
  'pewpewpewpew.pw': true,
  'pfui.ru': true,
  'phone-elkey.ru': true,
  'photo-impact.eu': true,
  'photomark.net': true,
  'pi.vu': true,
  'piaa.me': true,
  'pig.pp.ua': true,
  'pii.at': true,
  'piki.si': true,
  'pimpedupmyspace.com': true,
  'pinehill-seattle.org': true,
  'pingir.com': true,
  'pipemail.space': true,
  'pisls.com': true,
  'pitaniezdorovie.ru': true,
  'pivo-bar.ru': true,
  'pixiil.com': true,
  'pizzajunk.com': true,
  'pjjkp.com': true,
  'placebomail10.com': true,
  'pleasenoham.org': true,
  'plexfirm.com': true,
  'plexolan.de': true,
  'plhk.ru': true,
  'ploae.com': true,
  'plw.me': true,
  'poehali-otdihat.ru': true,
  'pojok.ml': true,
  'pokemail.net': true,
  'pokiemobile.com': true,
  'polarkingxx.ml': true,
  'politikerclub.de': true,
  'polyfaust.net': true,
  'pooae.com': true,
  'poofy.org': true,
  'pookmail.com': true,
  'poopiebutt.club': true,
  'popcornfarm7.com': true,
  'popcornfly.com': true,
  'popesodomy.com': true,
  'popgx.com': true,
  'porjoton.com': true,
  'porsh.net': true,
  'posdz.com': true,
  'posta.store': true,
  'postacin.com': true,
  'postonline.me': true,
  'poutineyourface.com': true,
  'powered.name': true,
  'powerencry.com': true,
  'powlearn.com': true,
  'pp7rvv.com': true,
  'ppetw.com': true,
  'pptrvv.com': true,
  'pqoia.com': true,
  'pratikmail.com': true,
  'pratikmail.net': true,
  'pratikmail.org': true,
  'prazdnik-37.ru': true,
  'predatorrat.cf': true,
  'predatorrat.ga': true,
  'predatorrat.gq': true,
  'predatorrat.ml': true,
  'predatorrat.tk': true,
  'premium-mail.fr': true,
  'primabananen.net': true,
  'prin.be': true,
  'privacy.net': true,
  'privatdemail.net': true,
  'privmail.edu.pl': true,
  'privy-mail.com': true,
  'privy-mail.de': true,
  'privymail.de': true,
  'pro-tag.org': true,
  'pro5g.com': true,
  'procrackers.com': true,
  'profast.top': true,
  'projectcl.com': true,
  'promailt.com': true,
  'proprietativalcea.ro': true,
  'propscore.com': true,
  'protempmail.com': true,
  'proxymail.eu': true,
  'proxyparking.com': true,
  'prtnx.com': true,
  'prtshr.com': true,
  'prtz.eu': true,
  'psh.me': true,
  'psles.com': true,
  'psnator.com': true,
  'psoxs.com': true,
  'puglieisi.com': true,
  'puji.pro': true,
  'punkass.com': true,
  'puppetmail.de': true,
  'purcell.email': true,
  'purelogistics.org': true,
  'pursip.com': true,
  'put2.net': true,
  'puttanamaiala.tk': true,
  'putthisinyourspamdatabase.com': true,
  'pwpwa.com': true,
  'pwrby.com': true,
  'qabq.com': true,
  'qasti.com': true,
  'qbfree.us': true,
  'qc.to': true,
  'qibl.at': true,
  'qiott.com': true,
  'qipmail.net': true,
  'qiq.us': true,
  'qisdo.com': true,
  'qisoa.com': true,
  'qmrbe.com': true,
  'qoika.com': true,
  'qopow.com': true,
  'qq.my': true,
  'qsl.ro': true,
  'qtum-ico.com': true,
  'quadrafit.com': true,
  'quick-mail.cc': true,
  'quickemail.info': true,
  'quickinbox.com': true,
  'quickmail.nl': true,
  'quicksend.ch': true,
  'quipas.com': true,
  'ququb.com': true,
  'qvy.me': true,
  'qwickmail.com': true,
  'r4nd0m.de': true,
  'ra3.us': true,
  'rabin.ca': true,
  'rabiot.reisen': true,
  'rackabzar.com': true,
  'raetp9.com': true,
  'rainbowly.ml': true,
  'raketenmann.de': true,
  'ramenmail.de': true,
  'rancidhome.net': true,
  'randomail.io': true,
  'randomail.net': true,
  'rapt.be': true,
  'raqid.com': true,
  'rax.la': true,
  'raxtest.com': true,
  'razemail.com': true,
  'razuz.com': true,
  'rbb.org': true,
  'rcasd.com': true,
  'rcpt.at': true,
  'rdklcrv.xyz': true,
  're-gister.com': true,
  'reality-concept.club': true,
  'reallymymail.com': true,
  'realquickemail.com': true,
  'realtyalerts.ca': true,
  'rebates.stream': true,
  'receiveee.com': true,
  'recipeforfailure.com': true,
  'recode.me': true,
  'reconmail.com': true,
  'recyclemail.dk': true,
  'redfeathercrow.com': true,
  'reftoken.net': true,
  'regapts.com': true,
  'regbypass.com': true,
  'regspaces.tk': true,
  'reimondo.com': true,
  'rejectmail.com': true,
  'rejo.technology': true,
  'reliable-mail.com': true,
  'remail.cf': true,
  'remail.ga': true,
  'remarkable.rocks': true,
  'remote.li': true,
  'rentaen.com': true,
  'reptilegenetics.com': true,
  'resgedvgfed.tk': true,
  'revolvingdoorhoax.org': true,
  'rfc822.org': true,
  'rhyta.com': true,
  'richfinances.pw': true,
  'riddermark.de': true,
  'rifkian.ga': true,
  'rippb.com': true,
  'risingsuntouch.com': true,
  'riski.cf': true,
  'risu.be': true,
  'rklips.com': true,
  'rkomo.com': true,
  'rm2rf.com': true,
  'rma.ec': true,
  'rmqkr.net': true,
  'rnailinator.com': true,
  'ro.lt': true,
  'robertspcrepair.com': true,
  'roborena.com': true,
  'robot-mail.com': true,
  'rollindo.agency': true,
  'ronnierage.net': true,
  'rootfest.net': true,
  'rosebearmylove.ru': true,
  'rotaniliam.com': true,
  'rover.info': true,
  'rowe-solutions.com': true,
  'royal.net': true,
  'royaldoodles.org': true,
  'royalmarket.life': true,
  'royandk.com': true,
  'rppkn.com': true,
  'rsvhr.com': true,
  'rtrtr.com': true,
  'rtskiya.xyz': true,
  'rudymail.ml': true,
  'rumgel.com': true,
  'runi.ca': true,
  'rupayamail.com': true,
  'ruru.be': true,
  'rustydoor.com': true,
  'ruu.kr': true,
  'rvb.ro': true,
  'ryteto.me': true,
  's0ny.net': true,
  's33db0x.com': true,
  'sabrestlouis.com': true,
  'sackboii.com': true,
  'saeoil.com': true,
  'safaat.cf': true,
  'safermail.info': true,
  'safersignup.de': true,
  'safetymail.info': true,
  'safetypost.de': true,
  'saharanightstempe.com': true,
  'salmeow.tk': true,
  'samsclass.info': true,
  'sandcars.net': true,
  'sandelf.de': true,
  'sandwhichvideo.com': true,
  'sanfinder.com': true,
  'sanim.net': true,
  'sanstr.com': true,
  'sast.ro': true,
  'satisfyme.club': true,
  'satukosong.com': true,
  'sausen.com': true,
  'saynotospams.com': true,
  'scatmail.com': true,
  'scay.net': true,
  'schachrol.com': true,
  'schafmail.de': true,
  'schmeissweg.tk': true,
  'schrott-email.de': true,
  'scrsot.com': true,
  'sd3.in': true,
  'sdvft.com': true,
  'sdvgeft.com': true,
  'sdvrecft.com': true,
  'secmail.pw': true,
  'secretemail.de': true,
  'secure-mail.biz': true,
  'secure-mail.cc': true,
  'secured-link.net': true,
  'securehost.com.es': true,
  'seekapps.com': true,
  'seekjobs4u.com': true,
  'sejaa.lv': true,
  'selfdestructingmail.com': true,
  'selfdestructingmail.org': true,
  'send22u.info': true,
  'sendfree.org': true,
  'sendingspecialflyers.com': true,
  'sendnow.win': true,
  'sendspamhere.com': true,
  'senseless-entertainment.com': true,
  'seosnaps.com': true,
  'server.ms': true,
  'services391.com': true,
  'sexforswingers.com': true,
  'sexical.com': true,
  'sexyalwasmi.top': true,
  'sfolkar.com': true,
  'shadap.org': true,
  'shalar.net': true,
  'sharedmailbox.org': true,
  'sharkfaces.com': true,
  'sharklasers.com': true,
  'shchiba.uk': true,
  'sheryli.com': true,
  'shhmail.com': true,
  'shhuut.org': true,
  'shieldedmail.com': true,
  'shieldemail.com': true,
  'shiftmail.com': true,
  'shipfromto.com': true,
  'shiphazmat.org': true,
  'shipping-regulations.com': true,
  'shippingterms.org': true,
  'shitaway.tk': true,
  'shitmail.de': true,
  'shitmail.me': true,
  'shitmail.org': true,
  'shmeriously.com': true,
  'shopxda.com': true,
  'shortmail.net': true,
  'shotmail.ru': true,
  'showslow.de': true,
  'shrib.com': true,
  'shut.name': true,
  'shut.ws': true,
  'siberpay.com': true,
  'sidelka-mytischi.ru': true,
  'siftportal.ru': true,
  'sify.com': true,
  'sika3.com': true,
  'sikux.com': true,
  'silenceofthespam.com': true,
  'siliwangi.ga': true,
  'silvercoin.life': true,
  'sim-simka.ru': true,
  'simaenaga.com': true,
  'simpleitsecurity.info': true,
  'sin.cl': true,
  'sinaite.net': true,
  'sinema.ml': true,
  'sinfiltro.cl': true,
  'singlespride.com': true,
  'sinnlos-mail.de': true,
  'sino.tw': true,
  'siteposter.net': true,
  'sizzlemctwizzle.com': true,
  'sjuaq.com': true,
  'skeefmail.com': true,
  'skrx.tk': true,
  'sky-inbox.com': true,
  'sky-ts.de': true,
  'skygazerhub.com': true,
  'skyrt.de': true,
  'slapsfromlastnight.com': true,
  'slaskpost.se': true,
  'slave-auctions.net': true,
  'slippery.email': true,
  'slipry.net': true,
  'slopsbox.com': true,
  'slothmail.net': true,
  'slushmail.com': true,
  'sluteen.com': true,
  'sly.io': true,
  'smallker.tk': true,
  'smapfree24.com': true,
  'smapfree24.de': true,
  'smapfree24.eu': true,
  'smapfree24.info': true,
  'smapfree24.org': true,
  'smartnator.com': true,
  'smarttalent.pw': true,
  'smashmail.de': true,
  'smellfear.com': true,
  'smellrear.com': true,
  'smellypotato.tk': true,
  'smtp99.com': true,
  'smwg.info': true,
  'snakebutt.com': true,
  'snakemail.com': true,
  'snapmail.cc': true,
  'snapwet.com': true,
  'sneakmail.de': true,
  'snece.com': true,
  'social-mailer.tk': true,
  'socialfurry.org': true,
  'sociallymediocre.com': true,
  'sofia.re': true,
  'sofimail.com': true,
  'sofort-mail.de': true,
  'sofortmail.de': true,
  'sofrge.com': true,
  'softkey-office.ru': true,
  'softpls.asia': true,
  'sogetthis.com': true,
  'sohai.ml': true,
  'sohus.cn': true,
  'soioa.com': true,
  'soisz.com': true,
  'solar-impact.pro': true,
  'solvemail.info': true,
  'solventtrap.wiki': true,
  'songsign.com': true,
  'sonshi.cf': true,
  'soodmail.com': true,
  'soodomail.com': true,
  'soodonims.com': true,
  'soombo.com': true,
  'soon.it': true,
  'spacebazzar.ru': true,
  'spam-be-gone.com': true,
  'spam.care': true,
  'spam.la': true,
  'spam.org.es': true,
  'spam.su': true,
  'spam4.me': true,
  'spamail.de': true,
  'spamarrest.com': true,
  'spamavert.com': true,
  'spambob.com': true,
  'spambob.net': true,
  'spambob.org': true,
  'spambog.com': true,
  'spambog.de': true,
  'spambog.net': true,
  'spambog.ru': true,
  'spambooger.com': true,
  'spambox.info': true,
  'spambox.me': true,
  'spambox.org': true,
  'spambox.us': true,
  'spamcero.com': true,
  'spamcon.org': true,
  'spamcorptastic.com': true,
  'spamcowboy.com': true,
  'spamcowboy.net': true,
  'spamcowboy.org': true,
  'spamday.com': true,
  'spamdecoy.net': true,
  'spamex.com': true,
  'spamfellas.com': true,
  'spamfighter.cf': true,
  'spamfighter.ga': true,
  'spamfighter.gq': true,
  'spamfighter.ml': true,
  'spamfighter.tk': true,
  'spamfree.eu': true,
  'spamfree24.com': true,
  'spamfree24.de': true,
  'spamfree24.eu': true,
  'spamfree24.info': true,
  'spamfree24.net': true,
  'spamfree24.org': true,
  'spamgoes.in': true,
  'spamherelots.com': true,
  'spamhereplease.com': true,
  'spamhole.com': true,
  'spamify.com': true,
  'spaminator.de': true,
  'spamkill.info': true,
  'spaml.com': true,
  'spaml.de': true,
  'spamlot.net': true,
  'spammer.fail': true,
  'spammotel.com': true,
  'spammy.host': true,
  'spamobox.com': true,
  'spamoff.de': true,
  'spamsalad.in': true,
  'spamsandwich.com': true,
  'spamslicer.com': true,
  'spamsphere.com': true,
  'spamspot.com': true,
  'spamstack.net': true,
  'spamthis.co.uk': true,
  'spamthis.network': true,
  'spamthisplease.com': true,
  'spamtrail.com': true,
  'spamtrap.ro': true,
  'spamtroll.net': true,
  'spamwc.cf': true,
  'spamwc.ga': true,
  'spamwc.gq': true,
  'spamwc.ml': true,
  'speedgaus.net': true,
  'sperma.cf': true,
  'spikio.com': true,
  'spindl-e.com': true,
  'spoofmail.de': true,
  'sportrid.com': true,
  'spr.io': true,
  'spritzzone.de': true,
  'spruzme.com': true,
  'spybox.de': true,
  'spymail.com': true,
  'spymail.one': true,
  'squizzy.de': true,
  'squizzy.net': true,
  'sroff.com': true,
  'sry.li': true,
  'ssoia.com': true,
  'stanfordujjain.com': true,
  'starlight-breaker.net': true,
  'starpower.space': true,
  'startfu.com': true,
  'startkeys.com': true,
  'statdvr.com': true,
  'stathost.net': true,
  'statiix.com': true,
  'stayhome.li': true,
  'steam-area.ru': true,
  'steambot.net': true,
  'stexsy.com': true,
  'stinkefinger.net': true,
  'stop-my-spam.cf': true,
  'stop-my-spam.com': true,
  'stop-my-spam.ga': true,
  'stop-my-spam.ml': true,
  'stop-my-spam.pp.ua': true,
  'stop-my-spam.tk': true,
  'stopspam.app': true,
  'storiqax.top': true,
  'storj99.com': true,
  'storj99.top': true,
  'streetwisemail.com': true,
  'stromox.com': true,
  'stuckmail.com': true,
  'stuffmail.de': true,
  'stumpfwerk.com': true,
  'stylist-volos.ru': true,
  'submic.com': true,
  'suburbanthug.com': true,
  'suckmyd.com': true,
  'sudern.de': true,
  'sueshaw.com': true,
  'suexamplesb.com': true,
  'suioe.com': true,
  'super-auswahl.de': true,
  'superblohey.com': true,
  'supergreatmail.com': true,
  'supermailer.jp': true,
  'superplatyna.com': true,
  'superrito.com': true,
  'supersave.net': true,
  'superstachel.de': true,
  'superyp.com': true,
  'suremail.info': true,
  'sute.jp': true,
  'svip520.cn': true,
  'svk.jp': true,
  'svxr.org': true,
  'sweetpotato.ml': true,
  'sweetxxx.de': true,
  'swift-mail.net': true,
  'swift10minutemail.com': true,
  'syinxun.com': true,
  'sylvannet.com': true,
  'symphonyresume.com': true,
  'syosetu.gq': true,
  'syujob.accountants': true,
  'szerz.com': true,
  'tafmail.com': true,
  'tafoi.gr': true,
  'taglead.com': true,
  'tagmymedia.com': true,
  'tagyourself.com': true,
  'talkinator.com': true,
  'talmetry.com': true,
  'tanlanav.com': true,
  'tanukis.org': true,
  'taobudao.com': true,
  'tapchicuoihoi.com': true,
  'taphear.com': true,
  'tapi.re': true,
  'tarzanmail.cf': true,
  'tastrg.com': true,
  'tatsu.uk': true,
  'taukah.com': true,
  'tb-on-line.net': true,
  'tcwlm.com': true,
  'tcwlx.com': true,
  'tdtda.com': true,
  'tech69.com': true,
  'techblast.ch': true,
  'techemail.com': true,
  'techgroup.me': true,
  'technoproxy.ru': true,
  'teerest.com': true,
  'teewars.org': true,
  'tefl.ro': true,
  'telecomix.pl': true,
  'teleg.eu': true,
  'teleworm.com': true,
  'teleworm.us': true,
  'tellos.xyz': true,
  'telvetto.com': true,
  'teml.net': true,
  'temp-link.net': true,
  'temp-mail.com': true,
  'temp-mail.de': true,
  'temp-mail.org': true,
  'temp-mail.pp.ua': true,
  'temp-mail.ru': true,
  'temp-mails.com': true,
  'tempail.com': true,
  'tempalias.com': true,
  'tempe-mail.com': true,
  'tempemail.biz': true,
  'tempemail.co.za': true,
  'tempemail.com': true,
  'tempemail.net': true,
  'tempinbox.co.uk': true,
  'tempinbox.com': true,
  'tempmail.cn': true,
  'tempmail.co': true,
  'tempmail.de': true,
  'tempmail.eu': true,
  'tempmail.it': true,
  'tempmail.pp.ua': true,
  'tempmail.us': true,
  'tempmail.ws': true,
  'tempmail2.com': true,
  'tempmaildemo.com': true,
  'tempmailer.com': true,
  'tempmailer.de': true,
  'tempmailer.net': true,
  'tempmailo.com': true,
  'tempomail.fr': true,
  'tempomail.org': true,
  'temporarily.de': true,
  'temporarioemail.com.br': true,
  'temporary-mail.net': true,
  'temporaryemail.net': true,
  'temporaryemail.us': true,
  'temporaryforwarding.com': true,
  'temporaryinbox.com': true,
  'temporarymailaddress.com': true,
  'tempr.email': true,
  'tempsky.com': true,
  'tempthe.net': true,
  'tempymail.com': true,
  'tensi.org': true,
  'ternaklele.ga': true,
  'testore.co': true,
  'testudine.com': true,
  'thanksnospam.info': true,
  'thankyou2010.com': true,
  'thatim.info': true,
  'thc.st': true,
  'theaviors.com': true,
  'thebearshark.com': true,
  'thecarinformation.com': true,
  'thechildrensfocus.com': true,
  'thecity.biz': true,
  'thecloudindex.com': true,
  'thediamants.org': true,
  'thedirhq.info': true,
  'theeyeoftruth.com': true,
  'thejoker5.com': true,
  'thelightningmail.net': true,
  'thelimestones.com': true,
  'thembones.com.au': true,
  'themegreview.com': true,
  'themostemail.com': true,
  'thereddoors.online': true,
  'theroyalweb.club': true,
  'thescrappermovie.com': true,
  'thespamfather.com': true,
  'theteastory.info': true,
  'thex.ro': true,
  'thichanthit.com': true,
  'thietbivanphong.asia': true,
  'thisisnotmyrealemail.com': true,
  'thismail.net': true,
  'thisurl.website': true,
  'thnikka.com': true,
  'thoas.ru': true,
  'thraml.com': true,
  'thrma.com': true,
  'throam.com': true,
  'thrott.com': true,
  'throwam.com': true,
  'throwawayemailaddress.com': true,
  'throwawaymail.com': true,
  'throwawaymail.pp.ua': true,
  'throya.com': true,
  'thrubay.com': true,
  'thunderbolt.science': true,
  'thunkinator.org': true,
  'thxmate.com': true,
  'tiapz.com': true,
  'tic.ec': true,
  'tilien.com': true,
  'timgiarevn.com': true,
  'timkassouf.com': true,
  'tinoza.org': true,
  'tinyurl24.com': true,
  'tipsb.com': true,
  'tittbit.in': true,
  'tiv.cc': true,
  'tizi.com': true,
  'tkitc.de': true,
  'tlpn.org': true,
  'tmail.com': true,
  'tmail.io': true,
  'tmail.ws': true,
  'tmail3.com': true,
  'tmail9.com': true,
  'tmailinator.com': true,
  'tmails.net': true,
  'tmmbt.net': true,
  'tmpbox.net': true,
  'tmpemails.com': true,
  'tmpeml.com': true,
  'tmpeml.info': true,
  'tmpjr.me': true,
  'tmpmail.net': true,
  'tmpmail.org': true,
  'tmpx.sa.com': true,
  'toddsbighug.com': true,
  'tofeat.com': true,
  'toiea.com': true,
  'tokem.co': true,
  'tokenmail.de': true,
  'tonaeto.com': true,
  'tonne.to': true,
  'tonymanso.com': true,
  'toomail.biz': true,
  'toon.ml': true,
  'top-shop-tovar.ru': true,
  'top101.de': true,
  'top1mail.ru': true,
  'top1post.ru': true,
  'topinrock.cf': true,
  'topmail2.com': true,
  'topmail2.net': true,
  'topofertasdehoy.com': true,
  'topranklist.de': true,
  'toprumours.com': true,
  'tospage.com': true,
  'toss.pw': true,
  'tosunkaya.com': true,
  'totallynotfake.net': true,
  'totalvista.com': true,
  'totesmail.com': true,
  'totoan.info': true,
  'tourcc.com': true,
  'tp-qa-mail.com': true,
  'tpwlb.com': true,
  'tqoai.com': true,
  'tqosi.com': true,
  'trackden.com': true,
  'tradermail.info': true,
  'tranceversal.com': true,
  'trash-amil.com': true,
  'trash-mail.at': true,
  'trash-mail.cf': true,
  'trash-mail.com': true,
  'trash-mail.de': true,
  'trash-mail.ga': true,
  'trash-mail.gq': true,
  'trash-mail.ml': true,
  'trash-mail.tk': true,
  'trash-me.com': true,
  'trash2009.com': true,
  'trash2010.com': true,
  'trash2011.com': true,
  'trashcanmail.com': true,
  'trashdevil.com': true,
  'trashdevil.de': true,
  'trashemail.de': true,
  'trashemails.de': true,
  'trashinbox.com': true,
  'trashmail.at': true,
  'trashmail.de': true,
  'trashmail.gq': true,
  'trashmail.io': true,
  'trashmail.me': true,
  'trashmail.net': true,
  'trashmail.org': true,
  'trashmail.ws': true,
  'trashmailer.com': true,
  'trashmailgenerator.de': true,
  'trashmails.com': true,
  'trashymail.com': true,
  'trashymail.net': true,
  'trasz.com': true,
  'trayna.com': true,
  'trbvm.com': true,
  'trbvn.com': true,
  'trbvo.com': true,
  'trend-maker.ru': true,
  'trgfu.com': true,
  'trgovinanaveliko.info': true,
  'trialmail.de': true,
  'trickmail.net': true,
  'trillianpro.com': true,
  'triots.com': true,
  'trixtrux1.ru': true,
  'trollproject.com': true,
  'tropicalbass.info': true,
  'trungtamtoeic.com': true,
  'truthfinderlogin.com': true,
  'tryalert.com': true,
  'tryninja.io': true,
  'tryzoe.com': true,
  'tsderp.com': true,
  'ttirv.org': true,
  'ttszuo.xyz': true,
  'tualias.com': true,
  'tuofs.com': true,
  'turoid.com': true,
  'turual.com': true,
  'turuma.com': true,
  'tutuapp.bid': true,
  'tvchd.com': true,
  'tverya.com': true,
  'twinmail.de': true,
  'twkly.ml': true,
  'twocowmail.net': true,
  'twoweirdtricks.com': true,
  'twzhhq.online': true,
  'txcct.com': true,
  'txen.de': true,
  'txtadvertise.com': true,
  'tyhe.ro': true,
  'tyldd.com': true,
  'tympe.net': true,
  'uacro.com': true,
  'uber-mail.com': true,
  'ubinert.com': true,
  'ubismail.net': true,
  'ubm.md': true,
  'ucche.us': true,
  'ucupdong.ml': true,
  'uemail99.com': true,
  'ufacturing.com': true,
  'uggsrock.com': true,
  'uguuchantele.com': true,
  'uhe2.com': true,
  'uhhu.ru': true,
  'uiu.us': true,
  'ujijima1129.gq': true,
  'uk.to': true,
  'ultra.fyi': true,
  'ultrada.ru': true,
  'uma3.be': true,
  'umail.net': true,
  'undo.it': true,
  'unicodeworld.com': true,
  'unids.com': true,
  'unimark.org': true,
  'unit7lahaina.com': true,
  'unmail.ru': true,
  'uooos.com': true,
  'uorak.com': true,
  'upliftnow.com': true,
  'uplipht.com': true,
  'uploadnolimit.com': true,
  'upozowac.info': true,
  'urfunktion.se': true,
  'urhen.com': true,
  'uroid.com': true,
  'us.af': true,
  'us.to': true,
  'usa.cc': true,
  'usako.net': true,
  'usbc.be': true,
  'used-product.fr': true,
  'ushijima1129.cf': true,
  'ushijima1129.ga': true,
  'ushijima1129.gq': true,
  'ushijima1129.ml': true,
  'ushijima1129.tk': true,
  'utiket.us': true,
  'uu.gl': true,
  'uu2.ovh': true,
  'uuf.me': true,
  'uwork4.us': true,
  'uyhip.com': true,
  'vaasfc4.tk': true,
  'vaati.org': true,
  'valemail.net': true,
  'valhalladev.com': true,
  'vankin.de': true,
  'vasteron.com': true,
  'vctel.com': true,
  'vda.ro': true,
  'vddaz.com': true,
  'vdig.com': true,
  'veanlo.com': true,
  'vemomail.win': true,
  'venompen.com': true,
  'veo.kr': true,
  'ver0.cf': true,
  'ver0.ga': true,
  'ver0.gq': true,
  'ver0.ml': true,
  'ver0.tk': true,
  'vercelli.cf': true,
  'vercelli.ga': true,
  'vercelli.gq': true,
  'vercelli.ml': true,
  'verdejo.com': true,
  'vermutlich.net': true,
  'veryday.ch': true,
  'veryday.eu': true,
  'veryday.info': true,
  'veryrealemail.com': true,
  'vesa.pw': true,
  'vevs.de': true,
  'vfemail.net': true,
  'via.tokyo.jp': true,
  'vickaentb.tk': true,
  'victime.ninja': true,
  'victoriantwins.com': true,
  'vidchart.com': true,
  'viditag.com': true,
  'viewcastmedia.com': true,
  'viewcastmedia.net': true,
  'viewcastmedia.org': true,
  'vikingsonly.com': true,
  'vinernet.com': true,
  'vintomaper.com': true,
  'vipepe.com': true,
  'vipmail.name': true,
  'vipmail.pw': true,
  'vipxm.net': true,
  'viralplays.com': true,
  'virtualemail.info': true,
  'visal007.tk': true,
  'visal168.cf': true,
  'visal168.ga': true,
  'visal168.gq': true,
  'visal168.ml': true,
  'visal168.tk': true,
  'visignal.com': true,
  'vixletdev.com': true,
  'vixtricks.com': true,
  'vjuum.com': true,
  'vkcode.ru': true,
  'vkr1.com': true,
  'vmailing.info': true,
  'vmani.com': true,
  'vmpanda.com': true,
  'vnedu.me': true,
  'voidbay.com': true,
  'volaj.com': true,
  'voltaer.com': true,
  'vomoto.com': true,
  'vorga.org': true,
  'votiputox.org': true,
  'voxelcore.com': true,
  'vpn.st': true,
  'vps30.com': true,
  'vps911.net': true,
  'vradportal.com': true,
  'vremonte24-store.ru': true,
  'vrmtr.com': true,
  'vsimcard.com': true,
  'vssms.com': true,
  'vtxmail.us': true,
  'vubby.com': true,
  'vuiy.pw': true,
  'vusra.com': true,
  'vztc.com': true,
  'w-asertun.ru': true,
  'w3internet.co.uk': true,
  'wakingupesther.com': true,
  'walala.org': true,
  'walkmail.net': true,
  'walkmail.ru': true,
  'wallm.com': true,
  'wanko.be': true,
  'watch-harry-potter.com': true,
  'watchever.biz': true,
  'watchfull.net': true,
  'watchironman3onlinefreefullmovie.com': true,
  'waterisgone.com': true,
  'watrf.com': true,
  'wazabi.club': true,
  'wbdev.tech': true,
  'wbml.net': true,
  'web-contact.info': true,
  'web-ideal.fr': true,
  'web-inc.net': true,
  'web-mail.pp.ua': true,
  'web2mailco.com': true,
  'webcontact-france.eu': true,
  'webemail.me': true,
  'webhook.site': true,
  'webm4il.info': true,
  'webmail24.top': true,
  'webtrip.ch': true,
  'webuser.in': true,
  'wee.my': true,
  'wef.gr': true,
  'weg-werf-email.de': true,
  'wegwerf-email-addressen.de': true,
  'wegwerf-email-adressen.de': true,
  'wegwerf-email.at': true,
  'wegwerf-email.de': true,
  'wegwerf-email.net': true,
  'wegwerf-emails.de': true,
  'wegwerfadresse.de': true,
  'wegwerfemail.com': true,
  'wegwerfemail.de': true,
  'wegwerfemail.info': true,
  'wegwerfemail.net': true,
  'wegwerfemail.org': true,
  'wegwerfemailadresse.com': true,
  'wegwerfmail.de': true,
  'wegwerfmail.info': true,
  'wegwerfmail.net': true,
  'wegwerfmail.org': true,
  'wegwerpmailadres.nl': true,
  'wegwrfmail.de': true,
  'wegwrfmail.net': true,
  'wegwrfmail.org': true,
  'weizixu.com': true,
  'wekawa.com': true,
  'welikecookies.com': true,
  'wellsfargocomcardholders.com': true,
  'wemel.top': true,
  'wenkuu.com': true,
  'wentcity.com': true,
  'wetrainbayarea.com': true,
  'wetrainbayarea.org': true,
  'wfgdfhj.tk': true,
  'wg0.com': true,
  'wh4f.org': true,
  'whaaaaaaaaaat.com': true,
  'whatiaas.com': true,
  'whatifanalytics.com': true,
  'whatpaas.com': true,
  'whatsaas.com': true,
  'whiffles.org': true,
  'whopy.com': true,
  'whyspam.me': true,
  'wibblesmith.com': true,
  'wickmail.net': true,
  'widaryanto.info': true,
  'widget.gg': true,
  'wiemei.com': true,
  'wierie.tk': true,
  'wifimaple.com': true,
  'wifioak.com': true,
  'wikfee.com': true,
  'wikidocuslava.ru': true,
  'wilemail.com': true,
  'willhackforfood.biz': true,
  'willselfdestruct.com': true,
  'wimsg.com': true,
  'winemaven.info': true,
  'wins.com.br': true,
  'wlist.ro': true,
  'wmail.cf': true,
  'wmail.club': true,
  'wokcy.com': true,
  'wolfmail.ml': true,
  'wolfsmail.tk': true,
  'wollan.info': true,
  'worldspace.link': true,
  'wpdork.com': true,
  'wpg.im': true,
  'wralawfirm.com': true,
  'writeme.us': true,
  'wronghead.com': true,
  'ws.gy': true,
  'wsym.de': true,
  'wudet.men': true,
  'wuespdj.xyz': true,
  'wupics.com': true,
  'wuuvo.com': true,
  'wuzak.com': true,
  'wuzup.net': true,
  'wuzupmail.net': true,
  'wwjmp.com': true,
  'wwwnew.eu': true,
  'wxnw.net': true,
  'x24.com': true,
  'xagloo.co': true,
  'xagloo.com': true,
  'xbaby69.top': true,
  'xcode.ro': true,
  'xcodes.net': true,
  'xcompress.com': true,
  'xcoxc.com': true,
  'xcpy.com': true,
  'xemaps.com': true,
  'xemne.com': true,
  'xents.com': true,
  'xjoi.com': true,
  'xkx.me': true,
  'xl.cx': true,
  'xmail.com': true,
  'xmailer.be': true,
  'xmaily.com': true,
  'xn--9kq967o.com': true,
  'xn--d-bga.net': true,
  'xojxe.com': true,
  'xost.us': true,
  'xoxox.cc': true,
  'xperiae5.com': true,
  'xrap.de': true,
  'xrho.com': true,
  'xvx.us': true,
  'xww.ro': true,
  'xxhamsterxx.ga': true,
  'xxi2.com': true,
  'xxlocanto.us': true,
  'xxolocanto.us': true,
  'xxqx3802.com': true,
  'xy9ce.tk': true,
  'xyzfree.net': true,
  'xzsok.com': true,
  'yabai-oppai.tk': true,
  'yahmail.top': true,
  'yahooproduct.net': true,
  'yamail.win': true,
  'yanet.me': true,
  'yannmail.win': true,
  'yapped.net': true,
  'yaqp.com': true,
  'yarnpedia.ga': true,
  'ycare.de': true,
  'ycn.ro': true,
  'ye.vc': true,
  'yedi.org': true,
  'yeezus.ru': true,
  'yep.it': true,
  'yermail.net': true,
  'yhg.biz': true,
  'ynmrealty.com': true,
  'yodx.ro': true,
  'yogamaven.com': true,
  'yoggm.com': true,
  'yomail.info': true,
  'yoo.ro': true,
  'yopmail.com': true,
  'yopmail.fr': true,
  'yopmail.gq': true,
  'yopmail.net': true,
  'yopmail.pp.ua': true,
  'yordanmail.cf': true,
  'you-spam.com': true,
  'yougotgoated.com': true,
  'youmail.ga': true,
  'youmailr.com': true,
  'youneedmore.info': true,
  'youpymail.com': true,
  'yourdomain.com': true,
  'youremail.cf': true,
  'yourewronghereswhy.com': true,
  'yourlms.biz': true,
  'yourspamgoesto.space': true,
  'yourtube.ml': true,
  'youxiang.dev': true,
  'yroid.com': true,
  'yspend.com': true,
  'ytpayy.com': true,
  'yugasandrika.com': true,
  'yui.it': true,
  'yuoia.com': true,
  'yuurok.com': true,
  'yxzx.net': true,
  'yyolf.net': true,
  'z-o-e-v-a.ru': true,
  'z0d.eu': true,
  'z1p.biz': true,
  'z86.ru': true,
  'zain.site': true,
  'zainmax.net': true,
  'zaktouni.fr': true,
  'zarabotokdoma11.ru': true,
  'zasod.com': true,
  'zaym-zaym.ru': true,
  'zcrcd.com': true,
  'zdenka.net': true,
  'ze.tc': true,
  'zebins.com': true,
  'zebins.eu': true,
  'zehnminuten.de': true,
  'zehnminutenmail.de': true,
  'zemzar.net': true,
  'zepp.dk': true,
  'zetmail.com': true,
  'zfymail.com': true,
  'zhaoqian.ninja': true,
  'zhaoyuanedu.cn': true,
  'zhcne.com': true,
  'zhewei88.com': true,
  'zhorachu.com': true,
  'zik.dj': true,
  'zipcad.com': true,
  'zipcatfish.com': true,
  'zipo1.gq': true,
  'zippymail.info': true,
  'zipsendtest.com': true,
  'ziragold.com': true,
  'zoaxe.com': true,
  'zoemail.com': true,
  'zoemail.net': true,
  'zoemail.org': true,
  'zoetropes.org': true,
  'zombie-hive.com': true,
  'zomg.info': true,
  'zsero.com': true,
  'zumpul.com': true,
  'zv68.com': true,
  'zxcv.com': true,
  'zxcvbnm.com': true,
  'zymuying.com': true,
  'zzi.us': true,
  'zzrgg.com': true,
  'zzz.com': true,
} as Record<string, boolean>;
