import { Button, Image } from '@storybook'; // Import components for UI
import { useCancelSubscriptionDues } from '../../stores'; // Import custom hook for canceling dues

import './cancel-subscription-dues.scss'; // Import the component's styles

export const CancelSubscriptionDues = () => {
	// Destructure the necessary methods and data from the custom hook
	const { onClearPayment, formattedAmount } = useCancelSubscriptionDues();

	return (
		<div className="Cancel_Subscription_Dues">
			{/* Display an image representing pending dues */}
			<Image fileName="dues-payment.svg" width="142px" height="118px" />
			
			{/* Title of the section */}
			<div className="Cancel_Subscription_Dues--title">
				Clear Pending Dues to Cancel Subscription
			</div>
			
			{/* Description explaining the outstanding dues and the action required */}
			<div className="Cancel_Subscription_Dues--description">
				Please clear the outstanding dues of <strong>${formattedAmount}</strong>
				. Once the payment is completed, {`you'll`} be able to proceed with the
				cancellation.
			</div>
			
			{/* Button to initiate the payment clearing action */}
			<Button
				label={'Clear Due Payment'} // Button text
				type="button button__filled--primary" // Styling class for the button
				handleClick={onClearPayment} // Handle button click by invoking the onClearPayment function from the hook
			/>
		</div>
	);
};
