import { FC } from 'react';
import { IdentityHeaderData } from 'views/user-identity-flow/constants';

type IdentityHeader = {
	navigate: string;
};
export const IdentityHeader: FC<IdentityHeader> = ({ navigate }) => {
	return (
		<section className="identityheader">
			<div className="identityheader--container">
				<h1 className="identityheader--container__heading">
					{IdentityHeaderData[navigate]?.head}
				</h1>
				<p className="identityheader--container__info">
					{IdentityHeaderData[navigate]?.desc}
				</p>
			</div>
		</section>
	);
};
