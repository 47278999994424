import { ChangeEvent, useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';

import { Input } from '@storybook';
import { LOGO } from 'constant';
import {
	convertFileToBase64,
	isCompanyLinkedInURLValid,
	validateInput,
} from 'utils';
import {
	BussinessInfoErrorState,
	BussinessInfoState,
	MonthlyBillingSteps,
	useInviteOnboardingPayment,
} from '../../../store';
import { OnboardingPaymentFooter } from '../../footer';
import { SideBar } from '../../sidebar';

import './business-info.scss';

const { ADDRESS_INFO, MONTHLY_BILLING_MESSAGE } = MonthlyBillingSteps;

export const BusinessInfo = () => {
	/* all the global states are here*/
	const [bussinessInfo, setBussinessInfo] = useRecoilState(BussinessInfoState);
	const [error, setError] = useRecoilState(BussinessInfoErrorState);

	/* all the custom hooks are here*/
	const { changeBillingCurrentPage } = useInviteOnboardingPayment();

	/* all the local variables are here*/
	const { logo, linkedInUrl, companyName } = bussinessInfo;

	/* this will use to import the logo */
	const handleLogoChange = useCallback(
		async (e: ChangeEvent<HTMLInputElement> | any) => {
			const file = e.target.files[0];
			/* here we are resolving the promise to extract the base64 url of any file */
			const fileName: any = await convertFileToBase64(file);
			const logo = fileName.toString();
			/* setting the logo in the state */
			setBussinessInfo(prev => ({ ...prev, logo }));
		},
		[setBussinessInfo]
	);

	/* extracting the values from thr input and setting in the states */
	const handleChangeInput = useCallback(
		(e: ChangeEvent<HTMLInputElement>, name: string) => {
			const { value } = e.target;
			const isValidUrl = isCompanyLinkedInURLValid(value);
			const isValidName = validateInput(name);
			if (name === 'linkedInUrl' && !isValidUrl) {
				setError(prev => ({ ...prev, [name]: 'Invalid linkedIn url.' }));
			} else if (name === 'companyName' && !isValidName) {
				setError(prev => ({ ...prev, [name]: 'Invalid company name.' }));
			} else if (name === 'linkedInUrl' && isValidUrl) {
				setError(prev => ({ ...prev, [name]: '' }));
			} else {
				setError(pre => ({ ...pre, companyName: '' }));
			}
			setBussinessInfo(prev => ({ ...prev, [name]: value }));
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[setBussinessInfo]
	);

	const renderUpload = useMemo(() => {
		return (
			<div className="monthly-billing-bussiness__info__upload">
				<div className="bussiness-info__label">Company Logo</div>
				<div className="bussiness-info__upload-wrapper">
					<div className="bussiness-info__upload-wrapper__logo">
						<img
							className="bussiness-info__upload-wrapper__logo__img"
							src={logo !== '' ? logo : LOGO.LIGHT}
							alt=""
						/>
					</div>
					<div className="bussiness-info__upload-wrapper__content">
						<div className="bussiness-info__upload-wrapper__text">
							Upload a company logo. Max file size 2Mb.
						</div>
						<div className="bussiness-info__upload-wrapper__browse">
							<label
								htmlFor="upload-logo"
								className="company-logo-container__upload-container__browser-txt"
							>
								Browse
							</label>
							<input
								type="file"
								id="upload-logo"
								accept="image/png, image/jpeg"
								onChange={handleLogoChange}
								hidden
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}, [handleLogoChange, logo]);

	const infoInputs = useMemo(
		() => [
			{
				label: 'Company Name',
				name: 'companyName',
				placeholder: 'Company Name',
				error: error.companyName,
				value: companyName,
			},
			{
				label: 'Linkedln Url',
				name: 'linkedInUrl',
				placeholder: 'Linkedln Url',
				error: error.linkedInUrl,
				value: linkedInUrl,
			},
		],
		[companyName, error, linkedInUrl]
	);

	const renderInputs = useMemo(() => {
		return infoInputs.map(({ label, name, placeholder, value, error }) => (
			<Input
				inputType="text"
				handleChange={(e: ChangeEvent<HTMLInputElement>) =>
					handleChangeInput(e, name)
				}
				placeholder={placeholder}
				value={value}
				isError={!!error}
				errorMessage={error}
				label={label}
				key={name}
			/>
		));
	}, [handleChangeInput, infoInputs]);

	const handlePrimaryCallback = useCallback(() => {
		changeBillingCurrentPage(ADDRESS_INFO);
		// eslint-disable-next-line
	}, [changeBillingCurrentPage]);

	const handleSecondaryCallback = useCallback(() => {
		changeBillingCurrentPage(MONTHLY_BILLING_MESSAGE);
		// eslint-disable-next-line
	}, [changeBillingCurrentPage]);

	const managePrimaryDisabled = useMemo(() => {
		if (
			linkedInUrl.trim() &&
			companyName.trim() &&
			!error.companyName &&
			!error.linkedInUrl
		) {
			return false;
		}
		return true;
	}, [companyName, error.companyName, error.linkedInUrl, linkedInUrl]);

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				height: '100%',
			}}
		>
			<div style={{ width: '100%', display: 'flex', minHeight: 460 }}>
				<SideBar />
				<div className="monthly-billing-bussiness__info">
					<div className="monthly-billing-bussiness__info__heading">
						Business info.
					</div>
					{renderUpload}
					{renderInputs}
				</div>
			</div>
			<OnboardingPaymentFooter
				primaryBtnCallback={handlePrimaryCallback}
				primaryBtnText="Next"
				secondaryBtnCallback={handleSecondaryCallback}
				secondaryBtnText="Back"
				isPrimaryDisabled={managePrimaryDisabled}
			/>
		</div>
	);
};
