import {
	ChangeEvent,
	FC,
	KeyboardEvent,
	useCallback,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useSetRecoilState } from 'recoil';

import { useOutsideClick } from 'hooks';
import { SignedDocsState } from 'global-stores';
import {useSimpliciSign } from '../store';
import { Loader } from '@storybook';
import { PERMISSION_SCREEN, useUserRoles } from 'views';

interface IProps {
	item: any;
	keyName: string;
	index: number;
}

export const EditTemplateName: FC<IProps> = ({ item, keyName, index }) => {
	const [isEdit, setIsEdit] = useState(false);
	const [name, setName] = useState({
		value: item.name,
		error: false,
	});
	const setTemplate = useSetRecoilState(SignedDocsState);

	const { updateTemplateName, isLoaded } = useSimpliciSign();
	const editNameFormRef = useRef<any>();


	const handleSaveName = useCallback(async() => {
		if (name.value.trim()) {
			
			const status = await updateTemplateName(item.templateId, { name: name.value });
			if (status) {
				setTemplate(prev => {
					const prevState = JSON.parse(JSON.stringify(prev));
					const foundState = prevState[index];
					foundState.name = name.value.trim();
					prevState.splice(index, 1, foundState);
					return prevState;
				});	
				setIsEdit(false);
				return;
			}
			setName(prev => ({ ...prev, error: true }));
		}
		setName(prev => ({ ...prev, error: true }));
	}, [index, item.templateId, name.value, setTemplate, updateTemplateName]);

	const handleClose = useCallback(() => {
		setIsEdit(false);
		setName({ value: item.name, error: false });
	}, [item.name]);

	const handleChangeName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		let { value } = e.target;
		if (value.length > 50) {
			value = value.slice(0, 50); // Truncate the value to 50 characters
		}
		setName({ value, error: false });
	}, []);

	const handleKeyPress = useCallback(
		(event: KeyboardEvent<HTMLInputElement>) => {
			const { key } = event;
			if (key === 'Enter') {
				handleSaveName();
			}
			if (key === 'Escape') {
				handleClose();
			}
		},
		[handleSaveName, handleClose]
	);

	const { checkUserWritePermission } = useUserRoles();

	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.Templates),
		[checkUserWritePermission]
	);
	const handleClickName = useCallback(() => {
		if (!isUserPermissionWrite) return;
		setIsEdit(true);
	}, [isUserPermissionWrite]);

	useOutsideClick(editNameFormRef, () => {
		handleClose();
	});

	const renderNameEditor = useMemo(() => {
		return !isEdit ? (
			<div className="doc-template__wrapper" onClick={handleClickName}>
				<div className="doc-template__name">{item[keyName] ?? item.name}</div>
				{isUserPermissionWrite && (
					<i className="ri-edit-2-fill doc-template__edit-icon" />
				)}
			</div>
		) : (
			<div className="doc-template__form" ref={editNameFormRef}>
				<input
					onKeyDown={handleKeyPress}
					className={`doc-template__input doc-template__input${
						name.error ? '--error' : ''
					}`}
					type="text"
					autoFocus
					value={name.value}
					onChange={handleChangeName}
				/>
				<div className="doc-template__name-action-form">
					<div
						className="doc-template__action-btn doc-template__action-btn--check"
						onClick={handleSaveName}
					>
						{isLoaded ? (
							<i className="ri-check-line" />
						) : (
							<Loader dimension={12} className="loader-blue" type="loader" />
						)}
					</div>
				</div>
			</div>
		);
	}, [
		isEdit,
		handleClickName,
		item,
		keyName,
		handleKeyPress,
		name.error,
		name.value,
		handleChangeName,
		handleSaveName,
		isLoaded,
		isUserPermissionWrite,
	]);

	return (
		<div className="doc-template">
			{item.provider !== 'ESign' ? (
				<div className="doc-template__name">{item[keyName] ?? item.name}</div>
			) : (
				renderNameEditor
			)}
		</div>
	);
};
