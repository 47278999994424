import { Image, Loader } from '@storybook';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { API_URL, MESSAGE } from 'constant';
import { loginState } from 'global-stores';
import { useNetwork, useNotification } from 'hooks';
import { capitalizeFirstLetter, convertFileToBase64, getDate } from 'utils';
import { SidebarToogelState } from 'views/sidebar/stores';
import { useSummaryBilling } from 'views/settings';
import { PERMISSION_SCREEN, useUserRoles } from 'views';

import './user-profile.scss';

export const UserProfile = () => {
	// globle states
	const [userDetail, setUserDetail] = useRecoilState(loginState);
	const isOpen = useRecoilValue(SidebarToogelState);

	// local states
	const [openUploadPopup, setOpenUploadPopup] = useState(false);

	// hooks
	const {
		patch: patchUserProfile,
		remove: removeImage,
		loading: isUserImageLoaded,
	} = useNetwork();
	const { errorNotification } = useNotification();
	const { showSelectedBillingDetail } = useSummaryBilling();

	const { firstName, lastName, email, countryCode, phone, image } = userDetail;

	const handleOpenUploadImage = () => {
		setOpenUploadPopup(prev => !prev);
	};

	const handleChangeImage = useCallback(
		async (e: ChangeEvent<HTMLInputElement> | any) => {
			const file = e.target.files[0];
			const selectedImage = (await convertFileToBase64(file)) as string;
			setOpenUploadPopup(false);
			if (selectedImage) {
				patchUserProfile(`${API_URL.CLIENT_SIGNUP}/${userDetail.userId}`, {
					image: selectedImage,
				}).then(res => {
					const { image, success, message } = res ?? {};
					if (success && image) {
						setUserDetail(prev => ({
							...prev,
							image: URL.createObjectURL(e.target.files[0]),
						}));
					} else {
						errorNotification(message ?? MESSAGE.ERROR);
					}
				});
			}
		},
		// eslint-disable-next-line
		[patchUserProfile, userDetail.userId]
	);

	const { checkUserWritePermission } = useUserRoles();

	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.UserProfile),
		[checkUserWritePermission]
	);

	const handleRemoveImage = useCallback(() => {
		removeImage(`${API_URL.CLIENT_SIGNUP}/${userDetail.userId}`).then(() => {
			setUserDetail(prev => ({
				...prev,
				image: '',
			}));
		});
		setOpenUploadPopup(prev => !prev);
		// eslint-disable-next-line
	}, [userDetail.userId]);

	const showUserProfileImage = useMemo(() => {
		return {
			...(image && { url: image }),
			...(!image && {
				fileName: 'AvatarDefault-Light.svg',
			}),
			className: 'user_avatar',
		};
	}, [image]);

	return (
		<div
			className={`user-profile_menu ${isOpen ? 'user-profile_menu__open' : ''}`}
		>
			<div className="user-profile__wrapper">
				{isUserImageLoaded ? (
					<div className="loading_user_image">
						<Loader type="loader" dimension={20} />
					</div>
				) : (
					<Image {...showUserProfileImage} />
				)}
				{isUserPermissionWrite && (
					<div
						className={`gallary_button ${
							openUploadPopup ? 'gallary_button_opened_popup' : ''
						}`}
						onClick={handleOpenUploadImage}
					>
						<i className="ri-image-edit-line remove-icon" />
					</div>
				)}
				{openUploadPopup && (
					<div className="main_upload_and_remove">
						<label htmlFor="upload-logo" className="image_upload_container">
							<i className="ri-image-add-fill remove-icon" />
							Upload Image
						</label>
						<input
							onChange={handleChangeImage}
							type="file"
							id="upload-logo"
							accept="image/png, image/jpeg"
							hidden
						/>
						{image && (
							<label
								htmlFor="upload"
								className="image_upload_container"
								onClick={handleRemoveImage}
							>
								<i className="ri-delete-bin-6-line remove-icon" />
								Remove Image
							</label>
						)}
					</div>
				)}
				<div className="user-profile_name-wrapper">
					<div className="user-profile_user">User Profile</div>
					<div className="user-profile_name">
						{capitalizeFirstLetter(firstName || 'NA')}{' '}
						{capitalizeFirstLetter(lastName)}
					</div>
				</div>
			</div>
			{showSelectedBillingDetail?.endDate && (
				<div className="user_profile_details">
					<i className="ri-bank-card-line user_profile_details_icon"></i>
					<div>
						Next billing date on
						<span className="user_profile_details_date">
							{` ${
								showSelectedBillingDetail?.endDate
									? getDate(showSelectedBillingDetail?.endDate)
									: ' -- '
							}`}
						</span>
					</div>
				</div>
			)}
			{!!email && (
				<div className="user_profile_details">
					<i className="ri-mail-line user_profile_details_icon"></i>
					{email}
				</div>
			)}

			{!!phone && (
				<div className="user_profile_details">
					<i className="ri-phone-line user_profile_details_icon"></i>
					{countryCode} {phone}
				</div>
			)}
		</div>
	);
};
