import { Fragment, useCallback } from 'react';
import { Handle, Position } from 'reactflow';

interface ICustomHandle {
	value?: any;
	showLeft?: boolean;
	showRight?: boolean;
	hidden?: boolean;
}
export const CustomHandle = ({
	value,
	showLeft = true,
	showRight = true,
}: ICustomHandle) => {
	const onChange = useCallback(() => {
		return;
	}, []);

	return (
		<Fragment>
			{showLeft && (
				<>
					<input
						type="radio"
						className="react-flow__handle-left"
						checked
						onChange={onChange}
					/>
					<Handle type="target" position={Position.Left} id={value} />
				</>
			)}
			{showRight && (
				<>
					<input
						type="radio"
						className="react-flow__handle-right"
						onChange={onChange}
						checked
					/>
					<Handle type="source" position={Position.Right} id={value} />
				</>
			)}
		</Fragment>
	);
};
