import { FC, useCallback, useMemo, useState } from 'react';
import {
	CardNumberElement,
	CardCvcElement,
	CardExpiryElement,
	useStripe,
} from '@stripe/react-stripe-js';

import './add-card.scss';
import { Button, Loader } from '@storybook';

interface IAddCard {
	onSaveCard: (e: any) => void;
	isLoaded?: boolean;
}
interface IComplete {
	cardNumber: boolean;
	cvv: boolean;
	expiryDate: boolean;
}

export const AddCard: FC<IAddCard> = ({ onSaveCard, isLoaded = true }) => {
	const [complete, setComplete] = useState<IComplete>({
		cardNumber: false,
		cvv: false,
		expiryDate: false,
	});
	const stripe = useStripe();

	const options = useMemo(
		() => ({
			style: {
				base: {
					color: '#424770',
					letterSpacing: '0.025em',
					fontFamily: 'Source Code Pro, monospace',
					'::placeholder': {
						color: '#aab7c4',
					},
				},
				invalid: {
					color: 'var(--color-danger-dark)',
				},
			},
		}),
		[]
	);
	const cvvConfigOptions = {
		...options,
		placeholder: '....',
	};

	const handleChangeCard = useCallback((isComplete: boolean, name: string) => {
		setComplete(prev => ({ ...prev, [name]: isComplete }));
	}, []);

	const manageBtnDisabled = useMemo((): boolean => {
		const { cardNumber, cvv, expiryDate } = complete;
		if (cardNumber && cvv && expiryDate && isLoaded && stripe) {
			return false;
		}
		return true;
	}, [complete, isLoaded, stripe]);

	return (
		<div className="payment-form">
			<form className="add-card" onSubmit={onSaveCard}>
				<div className="add-card_number">
					<div className="add-card_label">Card number</div>
					<div className="add-card_sub-label add-card_space">
						Enter the 16-digit card number on the card
					</div>
					<div className="card-number add-card_space_card">
						<i className="ri-wallet-line payment-form__icon" />
						<CardNumberElement
							onChange={e => handleChangeCard(e.complete, 'cardNumber')}
						/>
					</div>
				</div>
				<div className="add-card_cvv">
					<div className="add-card_cvv_container">
						<div className="add-card_label">CVV Number</div>
						<div className="add-card_sub-label">
							Enter the 3 or 4 digit number on the card
						</div>
					</div>
					<div className="card-cvc add-card_space_cvv">
						<i className="ri-lock-2-line payment-form__icon" />
						<CardCvcElement
							options={cvvConfigOptions}
							onChange={e => handleChangeCard(e.complete, 'cvv')}
						/>
					</div>
				</div>
				<div className="add-card_cvv">
					<div className="add-card_cvv_container">
						<div className="add-card_label">Expiry date</div>
						<div className="add-card_sub-label">
							Enter the expiration date of the card
						</div>
					</div>
					<div className="card-expiry add-card_space_cvv">
						<i className="ri-calendar-line payment-form__icon" />
						<CardExpiryElement
							onChange={e => handleChangeCard(e.complete, 'expiryDate')}
						/>
					</div>
				</div>
				<Button
					label={
						!isLoaded ? (
							<Loader type="loader" className="loader-white" dimension={20} />
						) : (
							'Add Card'
						)
					}
					type="button__filled button__filled--primary button__large add-card_button"
					disabled={manageBtnDisabled}
					handleClick={onSaveCard}
				/>
			</form>
		</div>
	);
};
