import { ISignedDocInbox } from './types';

export const data: ISignedDocInbox[] = [];

export const SignDocInboxHeader = [
	{
		label: 'Subject',
		key: 'name',
		key2: 'recipients',
		format: 'jsx',
	},
	{
		label: 'Status',
		key: 'status',
		key2: 'statusMessage',
		format: 'jsx',
	},
	{
		label: 'WorkFlow',
		key: 'workFlow',
		format: 'string',
	},
	{
		label: 'Type',
		key: 'origin',
		format: 'jsx',
	},
	{
		label: 'Face Auth.',
		key: 'authType',
		format: 'jsx',
	},
	{
		label: 'Created At',
		key: 'updatedAtDate',
		key2: 'updatedAtTime',
		format: 'jsx',
	},
	{
		label: 'Actions',
		key: 'actions',
		format: 'jsx',
	},
];

export const STATUS_HEADER: { key: string; label: Capitalize<string> }[] = [
	{
		key: 'pending',
		label: 'Pending',
	},
	{
		key: 'underReview',
		label: 'Under Review',
	},
	{
		key: 'completed',
		label: 'Completed',
	},
	{
		key: 'approved',
		label: 'Reviewed',
	},
];
