import { useCallback, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { API_URL } from 'constant';
import { useNetwork, useNotification } from 'hooks';
import { AssignSelectedSessionState } from './states';
import { useFunds } from 'global-stores';

export const useSelectedFundRequests = () => {
	const { post } = useNetwork();
	const { getFunds } = useFunds();

	const { errorNotification, successNotification } = useNotification();
	const [isLoaded, setIsLoaded] = useState<boolean>(false);
	const setSelectedAssignSession = useSetRecoilState(
		AssignSelectedSessionState
	);

	const submitAssignFundToSession = useCallback(
		async (payload: any, cb?: VoidFunction) => {
			setIsLoaded(true);
			post(API_URL.SESSION_FUND_ASSIGN, payload).then(res => {
				if (res?.success) {
					setIsLoaded(false);
					successNotification('Successfully Updated');
					setSelectedAssignSession([]);
					getFunds();
					cb?.();
				} else {
					setIsLoaded(false);
					if (res?.message) errorNotification(res?.message);
				}
			});
		},
		[
			errorNotification,
			getFunds,
			post,
			setSelectedAssignSession,
			successNotification,
		]
	);

	return { submitAssignFundToSession, isLoaded };
};
