import { FC } from 'react';
import './reminder-input.scss';

interface IProps {
	label: string;
	onChangeCheckbox: (check: boolean) => () => void;
	checked: boolean;
	checkVal?: boolean;
}
export const CheckboxInput: FC<IProps> = ({
	label,
	onChangeCheckbox,
	checked,
	checkVal = true,
}) => {
	return (
		<div
			className="reminder_checkbox_input"
			onClick={onChangeCheckbox(checkVal)}
		>
			<input type="radio" checked={checked} />
			<label htmlFor="reminder_checkbox" className="reminder_checkbox">
				{label}
			</label>
		</div>
	);
};
