import { FC, useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { Button } from '@storybook';
import { FundPaginationState } from 'global-stores';
import { useNotification } from 'hooks';
import './pagination.scss';

interface IPagination {
	isLoaded: boolean;
	handlePagination?: () => void;
	totaloPageCount?: number;
}
export const Pagination: FC<IPagination> = ({
	handlePagination = () => null,
	isLoaded,
	totaloPageCount = 1,
}) => {
	const [pageNumber, setPageNumber] = useRecoilState(FundPaginationState);
	const { infoNotification } = useNotification();

	useEffect(() => {
		handlePagination();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageNumber]);

	useEffect(() => {
		if (isLoaded && pageNumber > 1) {
			infoNotification('Please wait while, we fetch the details.');
		}
	}, [infoNotification, isLoaded, pageNumber]);

	const handlePrevClick = useCallback(() => {
		if (pageNumber > 1) {
			setPageNumber(prev => prev - 1);
		}
		if (pageNumber === 2)
			infoNotification('Please wait while, we fatch the details.');
	}, [infoNotification, pageNumber, setPageNumber]);

	const handleNextClick = useCallback(() => {
		if (pageNumber < totaloPageCount) {
			setPageNumber(prev => prev + 1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [totaloPageCount, pageNumber]);

	return (
		<div className="pagination__container">
			<Button
				type=""
				handleClick={handlePrevClick}
				disabled={pageNumber === 1 || isLoaded}
				icon="ri-arrow-left-s-line"
				label=""
			/>

			<div className="pagination__container__loader-text">
				{pageNumber} of {totaloPageCount ? totaloPageCount : 1}
			</div>
			<Button
				type=""
				handleClick={handleNextClick}
				disabled={pageNumber === totaloPageCount || isLoaded}
				icon="ri-arrow-right-s-line"
				label=""
			/>
		</div>
	);
};
