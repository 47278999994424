import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { IDoc } from '../session-kyb-form';
import { useNetwork } from 'hooks';
import { API_URL } from 'constant';
import { ComplexSessionsNodesDetailsState } from 'global-stores';
import { useSetRecoilState } from 'recoil';
import { Loader } from '@storybook';

interface IDocCardItem {
	docItem: IDoc;
	question: string;
	nodeId: string;
	isUserPermissionWrite?: boolean;
}

export const DocCardItem: FC<IDocCardItem> = ({
	docItem,
	question,
	nodeId,
	isUserPermissionWrite=true,
}) => {
	const [status, setStatus] = useState(docItem?.status);
	const [isLoaded, setIsLoaded] = useState({
		approved: true,
		rejected: true,
	});
	const setSessionDetails = useSetRecoilState(ComplexSessionsNodesDetailsState);

	useEffect(() => {
		setStatus(docItem?.status ?? 'pending');
	}, [docItem?.status]);

	const { patch } = useNetwork();

	const handleOpen = useCallback(() => {
		window.open(docItem?.docUrl);
	}, [docItem?.docUrl]);

	const handleAction = useCallback(
		async (action: 'approved' | 'rejected') => {
			if (isLoaded.approved && isLoaded.rejected) {
				setIsLoaded(prev => ({ ...prev, [action]: false }));
				const payload = {
					status: action,
					question: question,
					item: docItem?.itemKey,
				};
				if (nodeId) {
					const resp = await patch(
						`${API_URL.COMPLEX_SESSION_NODES}/${nodeId}`,
						payload
					);
					if (resp?.status) {
						setSessionDetails((prev: any) => ({ ...prev, [nodeId]: resp }));
					}
				}
				setIsLoaded(prev => ({ ...prev, [action]: true }));
			}
		},
		[
			docItem?.itemKey,
			isLoaded.approved,
			isLoaded.rejected,
			nodeId,
			patch,
			question,
			setSessionDetails,
		]
	);

	const renderDocActions = useMemo(() => {
		switch (status) {
			case 'approved':
				return (
					<div className="kyc-report-label-kycValue__accepted">
						<span className="kyc-report-label-kycValue__accepted-badge" />
						Accepted
					</div>
				);
			case 'rejected':
				return (
					<div className="kyc-report-label-kycValue__rejected">
						<span className="kyc-report-label-kycValue__rejected-badge" />
						Rejected
					</div>
				);
			default:
				return (
					isUserPermissionWrite &&
					<Fragment>
						<button
							className="kyc-report-label-kycValue__check-button"
							onClick={() => handleAction('approved')}
						>
							{isLoaded.approved ? (
								'Accept'
							) : (
								<Loader type="loader" dimension={12} className="loader-white" />
							)}
						</button>
						<button
							className="kyc-report-label-kycValue__uncheck-button "
							onClick={() => handleAction('rejected')}
						>
							{isLoaded.rejected ? (
								'Reject'
							) : (
								<Loader type="loader" dimension={12} className="loader-white" />
							)}
						</button>
					</Fragment>
				);
		}
	}, [handleAction, isLoaded.approved, isLoaded.rejected, status, isUserPermissionWrite]);

	return (
		<div className="kycDetail-row  kycDetail-row__session-kyb-action-card-item">
			<div className="kyc-report-label">
				<div className="kyc-report-label__key">{docItem?.docType ?? ''}</div>
			</div>
			<div className="kycDetail-row__basicInfoContainer">
				<div>:</div>
				<div className="kyc-report-label-kycValue kyc-report-label-kycValue__button-container">
					{docItem?.docUrl ? (
						<>
							<div className="kyc-report-label-kycValue__button-wrapper">
								<button
									className="kyc-report-label-kycValue__view-button"
									onClick={handleOpen}
								>
									<i className="ri-eye-line" />
									View
								</button>
							</div>
							<div className="kyc-report-label-kycValue__button-wrapper">
								{renderDocActions}
							</div>
						</>
					) : (
						<div className="kyc-report-label-kycValue__doc-not-available">
							<span className="kyc-report-label-kycValue__doc-pending-badge" />
							Document not uploaded
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
