import { FC, useState } from 'react';
import { Button } from '@storybook';

import { EditWebHookUrlModal } from './webhook-modal';
import { EditWebHookUrlModalForm } from './webhook-form';

interface IAddWebhookButton {
	label: string;
	prefixIcon: string;
	isLoaded: boolean;
}

export const AddWebhookButton: FC<IAddWebhookButton> = ({
	label,
	prefixIcon,
	isLoaded,
}) => {
	const [isOpenModal, setIsOpenModal] = useState(false);

	return (
		<>
			<Button
				label={label}
				prefixIcon={prefixIcon}
				type={
					!isLoaded
						? 'button-hide'
						: 'button__filled button__filled--primary user-dashboard__edit-btn'
				}
				handleClick={() => setIsOpenModal(true)}
			/>
			{isOpenModal && (
				<EditWebHookUrlModal
					name=""
					isOpen={isOpenModal}
					setIsOpen={setIsOpenModal}
				>
					<EditWebHookUrlModalForm setModalOpen={setIsOpenModal} />
				</EditWebHookUrlModal>
			)}
		</>
	);
};
