import { useSetRecoilState } from 'recoil';
import { ChooseUserIdentity } from '../store/state';
import { useFreePlan } from 'hooks';

export const useChooseIdentity = () => {
	const setActiveIdx = useSetRecoilState(ChooseUserIdentity);
	const { hasSubscribedPerson } = useFreePlan();

	const handleClickChoose = (val: string) => () => {
		if (hasSubscribedPerson) return;
		setActiveIdx(val);
	};

	return {
		handleClickChoose,
	};
};
