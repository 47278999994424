export const cardImage = (brand: string) => {
	switch (brand) {
		case 'Visa':
			return 'visa-card.svg';
		case 'MasterCard':
			return 'master-card.svg';
		case 'Discover':
			return 'discover-card.svg';
		case 'MaestroCard':
			return 'maestro-card.svg';
		case 'American Express':
			return 'american-express-card.svg';
		default:
			return 'default-card.svg';
	}
};
