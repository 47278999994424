import { FC } from 'react';

import { Button } from '@storybook';
import { SuccessIcon } from 'components';

import './email-success.scss';

interface IEmailSuccess {
	handleClose: () => void;
}

export const EmailSuccess: FC<IEmailSuccess> = ({ handleClose }) => {
	return (
		<div className="email-success">
			<SuccessIcon />
			<div className="email-success_wrapper">
				<div className="email-success_title">
					Email client connected successfully
				</div>
				<div className="email-success_description">
					{`You've successfully connected to your email client.`}
				</div>
			</div>
			<Button
				label="Close"
				type="button__filled button__filled--secondary"
				handleClick={handleClose}
			/>
		</div>
	);
};
