import { FC } from 'react';
import { ComprehensiveHeader } from '../comprehensive-header';
import { Image } from '@storybook';
/* eslint-disable react/no-array-index-key */



// Here any is because data is for multiple subsection so that here given any
interface IProps {
	label: string;
	data: any;
}

export const IRS500Information: FC<IProps> = ({ label, data }) => {
	return (
		<div className='kyb-information"'>
			<ComprehensiveHeader label={label} />
			{data?.value.length > 0 ? (
			<div>
				{data.value.map((item: any, index: any) => (
					<div className="asset__wrapper" key={index}>
						{Object.keys(item).map((sectionKey, sectionIndex) => (
							<div key={sectionIndex}>
								{sectionKey !== 'extraInfo' ? (
									<>
										<div className="asset__bottom-border">
											{Object.keys(item[sectionKey].value).map(
												(key, subIndex) =>
													item[sectionKey].value[key].type === 'basic' && (
														<div
															key={subIndex}
															className="kyb-wrapper__details__kybInfoDetailsRow"
														>
															<div className="kyb-wrapper__details__kybInfoDetailsRow__label">
																{item[sectionKey]?.value[key]?.label ?? '--'}
															</div>

															<div className="kyb-wrapper__details__kybInfoDetailsRow__value">
																<span>:</span>
																<div>
																	{(
																		item[sectionKey]?.value[key]?.value ?? ''
																	).toString()}
																</div>
															</div>
														</div>
													)
											)}
										</div>
									</>
								) : (
									<></>
								)}
							</div>
						))}
					</div>
				))}
			</div>
			): (
				<div className="kyb-array-empty-wrapper">
						<div className="kyb-empty_data">
							<div className="kyb-empty_data__inner">
								<Image
									fileName="No_record_found.svg"
									className="empty_data_img"
									height="100"
								/>
								<div className="kyb-empty-data-txt">No records found</div>
							</div>
						</div>
					</div>
			) }
		</div>
	);
};
