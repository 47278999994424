import { Skeleton } from '@storybook';

export const SkeltonAcountTypeCard = () => {
	return (
		<div>
			<div className="subscriptions__skelton-card">
				<div>
					<Skeleton width={40} height={40} />
				</div>
				<div>
					<div className="subscriptions__acount-type-label">
						<Skeleton width={200} height={15} />
					</div>
					<div className="subscriptions__acount-type-amount">
						<Skeleton width={100} height={10} />
					</div>
				</div>
			</div>
			<div className="subscriptions__skelton-card-details">
				<Skeleton width={150} height={15} />
				<Skeleton width={300} height={10} />
				<Skeleton width={300} height={10} />
			</div>
		</div>
	);
};
