import { FC } from 'react';
import './message.scss';

interface IMessage {
	text: string | JSX.Element;
	varient: 'warning' | 'info' | 'error' | 'none';
}
export const Message: FC<IMessage> = ({ text, varient }) => {
	return (
		<div className={`message message--${varient}`}>
			<i className="ri-alarm-warning-line" />
			<div className="message__text">{text}</div>
		</div>
	);
};
