import { useCallback } from 'react';

import { useRecoilState } from 'recoil';
import { trimBase64ImageWhitespace } from 'utils';

import { CreateSignState } from '../state';
import { fontStyles } from '../constant';

export const useGenerateSignImage = () => {
	const [{ fullName, initials, generate }, setSignature] =
		useRecoilState(CreateSignState);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const textSignature = useCallback(async (options: any) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		let imageData: any = null;

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		function formatInput(options: any) {
			// Handle error
			if (!options || options === undefined) {
				throw new Error('text-signature: parameter cannot be null or empty');
			}
			if (!options.font) {
				throw new Error('text-signature: parameter font cannot be empty');
			}
			if (typeof options.font === 'string') {
				return options;
			}
			options.font = options.font.join(' ');
			options.fillStyle = options.color || 'black';
			options.textString = options.textString || 'Signature';
			options.paddingX = options.paddingX || 0;
			options.paddingY = options.paddingY || 0;
			return options;
		}

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const generateImage = (options: any) => {
			options = formatInput(options);

			const uniqueTime = new Date().getUTCMilliseconds();
			const ratio = Math.ceil(window.devicePixelRatio);
			const canvas = document.createElement('canvas');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const context: any = canvas.getContext('2d');
			canvas.height = options.canvasHeight * ratio;
			canvas.width = options.canvasWidth * ratio;
			canvas.style.width = `${options.canvasWidth}px`;
			canvas.style.height = `${options.canvasHeight}px`;
			canvas.id = `text-signature-${uniqueTime}`;
			if (context) {
				context.setTransform(ratio, 0, 0, ratio, 0, 0);
				context.font = options.font;
				context.textRendering = 'optimizeLegibility';
				context.fillText(options.textString || 'Sign', 10, 30);
			}
			imageData = canvas.toDataURL('image/png');
		};
		generateImage(options);
		const base64String = imageData.replace('data:image/png;base64,', '');
		const image = await trimBase64ImageWhitespace(base64String);
		return image;
	}, []);

	const createSignature = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		async ({ fontFamily, fontStyle, name, width, height }: any) => {
			const optionsParameter = {
				font: ['24px', `${fontFamily}, ${fontStyle}`],
				color: 'black', // Text color
				textString: name || 'Signature', // Text to be displayed
				height: 48,
				canvasHeight: height,
				canvasWidth: width,
				canvasTargetDom: '.js-canvasTargetDom',
				customFont: {
					name: "'Delicious Handrawn', cursive'",
					url: `https://fonts.googleapis.com/css2?family=Delicious+Handrawn&family=Fasthand&family=Freehand&family=Just+Another+Hand&family=Satisfy&family=Patrick+Hand&family=Poppins&family=Shadows+Into+Light&family=Dancing+Script:wght@400;500;600;700&display=swap%27`,
				},
			};
			const src = await textSignature(optionsParameter);
			return src;
		},
		[textSignature]
	);

	const handleGetGenerateSignSamples = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		async (item: any) => {
			const { fontFamily, fontStyle } = item ?? {};
			const imgName = await createSignature({
				fontFamily: fontFamily,
				fontStyle: fontStyle,
				name: fullName,
				isInitial: false,
				width: 300,
				height: 40,
			});
			const imgInit = await createSignature({
				fontFamily: fontFamily,
				fontStyle: fontStyle,
				name: initials.toUpperCase(),
				isInitial: true,
				width: 80,
				height: 40,
			});
			return { imgName, imgInit };
		},
		[createSignature, fullName, initials]
	);

	const handleCreateSignImages = useCallback(async () => {
		const fonts = await Promise.all(
			fontStyles.map(async (item) => {
				const { imgName, imgInit } = await handleGetGenerateSignSamples(item);
				return { imgName, imgInit, ...item };
			})
		);
		if (generate?.id) {
			const foundFont = fonts.find((font) => font.id === generate.id);
			if (foundFont) {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				setSignature((prev: any) => {
					const prevState = JSON.parse(JSON.stringify(prev));
					prevState.generate.initials = foundFont.imgInit;
					prevState.generate.sign = foundFont.imgName;
					return prevState;
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [generate.id, handleGetGenerateSignSamples]);

	return { handleCreateSignImages };
};
