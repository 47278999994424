import { useCallback, useMemo } from 'react';

import { API_URL } from 'constant';
import { useNetwork } from 'hooks';
import { IPermissionScreen, PERMISSION_SCREEN } from '../constants';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
	AssinedUserLoadingState,
	AssinedUserState,
	RolesTemplateState,
	UserRolesPermissions,
	UserRolesState,
} from 'global-stores';

export const useUserRoles = () => {
	const [userRoles, setUserRoles] = useRecoilState(UserRolesState);
	const [templateRoles, setTemplateRoles] = useRecoilState(RolesTemplateState);
	const setAssinedUser = useSetRecoilState(AssinedUserState);
	const setIsLoaded = useSetRecoilState(AssinedUserLoadingState);
	const [userPermissions, setUserPermissions] =
		useRecoilState(UserRolesPermissions);

	const { get, loading } = useNetwork({ updateState: false });

	const fetchUserRoles = async () => {
		const resp = await get(
			`${API_URL.ROLES}?isDeleted=false&archivedUser=false`
		);
		if (resp?.message?.toLowerCase() === 'ok') {
			const { data } = resp;
			setUserRoles(data ?? []);
		}
	};

	const fetchRolesTemplate = async () => {
		const resp = await get(`${API_URL.ROLES}?template=true&archivedUser=false`);
		if (resp?.message?.toLowerCase() === 'ok') {
			const { data } = resp;
			setTemplateRoles(data ?? []);
		}
	};

	const fetchAssinedUser = useCallback(
		async (id: string) => {
			setIsLoaded(true);
			const resp = await get(`${API_URL.ROLES}/${id}?archivedUser=false`);
			if (resp?.data?._id) {
				const { data } = resp;
				setAssinedUser(data ?? {});
			}
			setIsLoaded(false);
		},
		[get, setAssinedUser, setIsLoaded]
	);

	//Calculating read or write permission for user
	const customUserTab: string[] = useMemo(() => {
		const { permissions } = userPermissions ?? {};
		const trueKeys: string[] = [];
		for (const key in permissions) {
			if (
				permissions[key]?.read ||
				permissions[key]?.write
				// Awadhesh - Avinash
				// 		 ||
				// (fullAccess && key !== '_id')
			) {
				trueKeys.push(key);
			}
		}
		if (trueKeys.length) {
			trueKeys.push('auth');
		}

		return trueKeys ?? [];
	}, [userPermissions]);

	const checkUserWritePermission = (screenName: string | undefined = '') => {
		const { permissions, fullAccess } = userPermissions ?? {};
		if (fullAccess) return true;
		if (
			permissions?.[
				PERMISSION_SCREEN[screenName as IPermissionScreen] ?? screenName
			]?.write
		)
			return true;
		return false;
	};

	const checkUserReadPermission = (screenName: string | undefined = '') => {
		const { permissions, fullAccess } = userPermissions ?? {};
		if (fullAccess) return true;
		if (
			permissions?.[
				PERMISSION_SCREEN[screenName as IPermissionScreen] ?? screenName
			]?.read
		)
			return true;
		return false;
	};

	return {
		fetchUserRoles,
		userRoles,
		customUserTab,
		loading,
		checkUserWritePermission,
		checkUserReadPermission,
		fetchRolesTemplate,
		templateRoles,
		fetchAssinedUser,
		setUserPermissions,
	};
};
