import type { IDashboardBarChart } from './types';

import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { kycSettingsState } from 'global-stores';
import { NumberWithCommas } from 'utils';
import { ChartXAxisActivity } from '../components';

export const useBarChart = () => {
	const kycSettings = useRecoilValue(kycSettingsState);
	const { settings } = useMemo(() => kycSettings, [kycSettings]);
	const { bodyFont } = settings ?? {};

	const getBarChartConfig = ({
		isLoading,
		title,
		yAxis,
		xAxis,
		totalAmount,
		revenue,
	}: IDashboardBarChart) => {
		const value = yAxis?.reduce((a: number, b: number) => a + b, 0);
		let yMax;
		if (value === 0) {
			yMax = 10;
		} else {
			yMax = Math.max(...yAxis) + Math.max(...yAxis) / 8;
		}
		const charts =
			(window as any).Highcharts?.chart(title, {
				chart: {
					type: 'column',
					style: {
						fontFamily: bodyFont?.family !== '' ? bodyFont?.family : 'Poppins',
					},
				},

				title: {
					text: title,
					align: 'left',
					style: {
						color: '#000000',
						fontWeight: '600',
						fontSize: '16px',
					},
				},

				subtitle: {
					text: `Consumption : $${NumberWithCommas(revenue)}`,
					align: 'right',
					style: {
						color: '#000000',
						fontWeight: '600',
						fontSize: '16px',
					},
				},

				colors: ['#2269D3'],

				exporting: {
					enabled: false,
				},

				credits: {
					enabled: false,
				},

				xAxis: {
					categories: xAxis,
					crosshair: false,
					accessibility: {
						description: 'Steps',
					},
					labels: {
						style: {
							color: '#7C8398',
							fontWeight: 500,
						},
					},
				},
				yAxis: {
					min: 0,
					max: yMax,
					title: {
						text: null,
					},
					labels: {
						style: {
							color: '#7C8398',
							fontWeight: 500,
						},
					},
				},

				tooltip: {
					formatter: function (this: any) {
						return (
							`<span style="color:${this?.color}">` +
							this.key +
							' : </span><b>' +
							NumberWithCommas(this?.y) +
							'</b>'
						);
					},
				},

				plotOptions: {
					series: {
						maxPointWidth: 30,
					},
					column: {
						showInLegend: false,
						pointPadding: 0.2,
						borderWidth: 0,
						dataLabels: {
							enabled: true,
							formatter: function (this: any) {
								return (
									'$' +
									NumberWithCommas(
										totalAmount?.[ChartXAxisActivity?.[this?.x] as any]
											?.amount || 0
									)
								);
							},
							style: {
								color: '#7C8398',
								fontWeight: 600,
								fontSize: 12,
								textOutline: 'none',
							},
						},
					},
				},

				series: [
					{
						name: 'Total',
						data: yAxis,
					},
				],
			}) ?? {};

		if (isLoading) {
			charts?.showLoading();
		} else {
			charts?.hideLoading();
		}
	};

	return { getBarChartConfig };
};
