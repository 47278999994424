import { FC, Fragment, useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Image } from '@storybook';

import { loginState, SelectedSessionState, SessionItemSelected } from 'global-stores';
import { ActivityLog } from './activityLog';
import { KYBDetails } from './kyb-details/kyb-details';
import { SessionInfo } from './session-info';
import { BackgroundCheck } from './background-check';
import { SignAgreeMent } from './sign-agreement';
import { Accreditation } from './accreditation';
import { FundInvestmentDetails } from './fund-investment-details';
import { SessionDetailsHeader } from './session-details-header';
import { KycDetailsWrapper } from './kyc-details';
import { Seperator } from './divider';
import { KybWrapper } from './kyb-wrapper';
import { actionKeyLabel } from 'views/pipelines';
import { PERMISSION_SCREEN, useUserRoles } from 'views/routes-children';
import { ProofReadDocument } from './proof-reading/proof-read-document';

interface IKycDetail {
	handleBackModal?: () => void;
	isTransactionPage?: boolean;
	activityLoaded?: boolean;
	sessionAmlLoaded?: boolean;
	sessionDeviceLoaded?: boolean;
	sessionAccreditationLoaded?: boolean;
	sessionSignLoaded?: boolean;
	sessionKycLoaded?: boolean;
	sessionKybLoaded?: boolean;
	sessionFundLoaded?: boolean;
	sessionProofReadingLoaded?: boolean;
	isLoading?: boolean;
	isFundView?: boolean;
	isShowHeader?: boolean;
	isCheckFraud?: boolean;
}

export const ViewKyc: FC<IKycDetail> = ({
	sessionDeviceLoaded = true,
	handleBackModal,
	isTransactionPage = false,
	sessionKybLoaded = true,
	sessionKycLoaded = true,
	sessionAmlLoaded = true,
	sessionSignLoaded = true,
	sessionFundLoaded = true,
	sessionProofReadingLoaded = true,
	sessionAccreditationLoaded = true,
	activityLoaded = true,
	isFundView = false,
	isLoading = false,
	isShowHeader = true,
}) => {
	const selectedSession = useRecoilValue(SelectedSessionState);
	const sessionSelected = useRecoilValue(SessionItemSelected);
	const [isComplexFlow, setIsComplexFlow] = useState(false);
	const { role, isAgentPermitted } = useRecoilValue(loginState);

	const { checkUserWritePermission } = useUserRoles();

	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.Sessions),
		[checkUserWritePermission]
	);

	const {
		kyc,
		aml,
		signAgreement,
		accreditation = [],
		fundInvestment,
		kyb = [],
		form,
		device,
		deviceInfo,
		proofReading
	}: any = useMemo(() => selectedSession ?? {}, [selectedSession]);

	const sessionInfo = useMemo(() => {
		return device ?? deviceInfo ?? ({} as any);
	}, [device, deviceInfo]);

	const { basicInfo } = kyc?.[0]?.kyc ?? {};

	const { dateOfBirth, lastName, firstName } = basicInfo ?? {};

	const kycDetailProps = useMemo(() => {
		const { ssn = {}, kyc: kycInfo = {}, status } = kyc?.[0] ?? {};
		const { newAddress: address = {} } = basicInfo ?? {};
		const kycDetails: any = {
			kyc: {
				...kycInfo,
				ssn: {
					ssn,
				},
				address,
			},
			status,
		};

		return {
			kyc: kycDetails,
			isAgentPermitted,
			isTransactionPage,
			role,
		};
	}, [basicInfo, isAgentPermitted, isTransactionPage, kyc, role]);

	const getLabel = useCallback(
		(key: string) => {
			if (key === 'fundInvestment') {
				return actionKeyLabel[
					selectedSession['fundInvestment']?.payInPayOut ?? 'fundInvestment'
				];
			}
			return actionKeyLabel[key];
		},
		[selectedSession]
	);

	const sessionLoaded = useMemo(() => {
		return (
			sessionAccreditationLoaded &&
			sessionAmlLoaded &&
			sessionDeviceLoaded &&
			sessionFundLoaded &&
			sessionKycLoaded &&
			activityLoaded &&
			sessionKybLoaded &&
			sessionSignLoaded
		);
	}, [
		activityLoaded,
		sessionAccreditationLoaded,
		sessionAmlLoaded,
		sessionDeviceLoaded,
		sessionFundLoaded,
		sessionKybLoaded,
		sessionKycLoaded,
		sessionSignLoaded,
	]);

	if (
		Object.values(fundInvestment ?? {})?.length === 0 &&
		Object.values(aml ?? {})?.length === 0 &&
		Object.keys(sessionInfo ?? {}).length === 0 &&
		kyc?.length === 0 &&
		accreditation?.length === 0 &&
		signAgreement?.length === 0 &&
		kyb?.length === 0 &&
		sessionLoaded &&
		(!form?.answer || Object.values(form?.answer ?? {})?.length === 0)
	) {
		return (
			<div className="kycDetails">
				<div className="kycDetails--header kycDetails--header--sticky ">
					<div className="kycUserName user__name_space">
						{isTransactionPage && (
							<div onClick={handleBackModal} className="arrow__left_icon_main">
								<i className="ri-arrow-left-line fa-lg" />
							</div>
						)}
						<div>{sessionSelected.name}</div>
					</div>
				</div>
				<div className="empty_data">
					<Image
						fileName="noData.svg"
						className="empty_data_img"
						height="100"
					/>
					<div className="empty-data-txt">No Data is available</div>
				</div>
			</div>
		);
	}

	const kycDetailsHeaderProps = {
		sessionSelected,
		handleBackModal,
		isTransactionPage,
		isComplexFlow,
		getLabel,
		setIsComplexFlow,
		isFundView,
		isUserPermissionWrite,
	};

	return (
		<>
			<div className="kycDetails">
				{/* Header */}
				{isShowHeader && <SessionDetailsHeader {...kycDetailsHeaderProps} />}
				<Fragment>
					<div className="session-column-container">
						<div className="session-col-detail">
							{/* session  page */}

							<div className="session-info-container">
								<SessionInfo
									loaded={sessionDeviceLoaded}
									isLoading={isLoading}
								/>
							</div>

							{/* Paras: order arrangement for kyc and kyb */}

							<Seperator />
							<KycDetailsWrapper
								kycDetail={kycDetailProps}
								isLoading={isLoading}
								isLoaded={sessionKycLoaded}
								isUserPermissionWrite={isUserPermissionWrite}
							/>

							{
								// eslint-disable-next-line no-constant-condition
								false ? (
									<>
										<Seperator />
										<KYBDetails kyb={kyb} isLoading={sessionKybLoaded} />
									</>
								) : (
									<>
										<Seperator />
										<KybWrapper
											kyb={kyb}
											isLoading={isLoading}
											isLoaded={sessionKybLoaded}
										/>
									</>
								)
							}

							{/* AML page */}
							<Seperator />
							<BackgroundCheck
								aml={aml}
								role={role}
								isAgentPermitted={isAgentPermitted}
								isTransactionPage={isTransactionPage}
								firstName={firstName}
								lastName={lastName}
								dateOfBirth={dateOfBirth}
								isLoading={isLoading}
								isLoaded={sessionAmlLoaded}
								isUserPermissionWrite={isUserPermissionWrite}
							/>

							{/* SignAgreement page */}
							<Seperator />
							{!isTransactionPage && (
								<SignAgreeMent
									signAgreement={signAgreement}
									role={role}
									isAgentPermitted={isAgentPermitted}
									isTransactionPage={isTransactionPage}
									isLoading={isLoading}
									isLoaded={sessionSignLoaded}
									isUserPermissionWrite={isUserPermissionWrite}
								/>
							)}

							{/* Accreditation page */}
							<Seperator />
							{!isTransactionPage && (
								<Accreditation
									accreditation={accreditation}
									role={role}
									isAgentPermitted={isAgentPermitted}
									isLoading={isLoading}
									isLoaded={sessionAccreditationLoaded}
									isUserPermissionWrite={isUserPermissionWrite}
								/>
							)}
							{/* Fund page */}
							<Seperator />
							{!isTransactionPage && (
								<div className="fundInvestment">
									<FundInvestmentDetails
										isAgentPermitted={isAgentPermitted ?? false}
										role={role}
										fundInvestment={fundInvestment}
										isLoading={isLoading}
										isLoaded={sessionFundLoaded}
										isUserPermissionWrite={isUserPermissionWrite}
									/>
								</div>
							)}

							{/* render proofReading card for linear */}
							<Seperator />
							<ProofReadDocument
								file={proofReading}
								isSkeletonLoading={!sessionProofReadingLoaded}
							/>
						</div>
						<div className="session-col-activity">
							<ActivityLog
								isLoading={isLoading}
								dataLoaded={activityLoaded}
							/>
						</div>
					</div>
				</Fragment>
			</div>
		</>
	);
};
