import { Button, Image, Loader } from '@storybook';
import { FC } from 'react';
import { IdentityIcon } from 'views/user-identity-flow/constants';
import { useError } from 'views/user-identity-flow/hooks';
import './error-page.scss';

interface IError {
	handleClose: () => void;
}
export const ErrorPage: FC<IError> = () => {
	const { isLoading, onRetry } = useError();
	return (
		<div className="Error">
			<Image fileName={IdentityIcon.warning} />
			<div className="Error--info">
				<h1>Oops! Something went wrong</h1>
				<p>
					We’re sorry that you have to experience some problem! Please try again
					later.
				</p>
			</div>
			<Button
				handleClick={onRetry}
				label={isLoading ? <Loader type="loader" dimension={20} /> : 'Retry'}
				type="button__filled button__filled--primary button__large"
				disabled={isLoading}
			/>
		</div>
	);
};
