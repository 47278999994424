import type { IConfirmationModal as Props } from './type';

import { Button, Image, Loader } from '@storybook';

import './confirmation.scss';

/**
 * Confirmation Modal component
 *
 * @param {boolean} Props.visible - Flag to indicate if the modal is visible
 * @param {string} Props.title - The title of the confirmation modal
 * @param {string | JSX.Element} Props.description - The main description text of the confirmation modal
 * @param {(visible: boolean, confirmed: boolean) => void} Props.handleModal - Callback function to handle modal visibility and confirmation action
 * @param {string} Props.boldDescription - Additional bold text to be included in the description
 * @param {string} Props.label - The label text for the confirmation button
 * @param {string} Props.type - The type of confirmation (e.g., 'danger')
 * @param {string} Props.secondryLabel - The label text for the secondary (cancel) button
 * @param {boolean} Props.loading - Flag to indicate whether the confirmation button is in a loading state
 */

export const Confirmation = ({
	visible,
	title,
	description,
	handleModal,
	boldDescription = '',
	label,
	type = 'danger',
	secondryLabel = 'Back',
	loading = false,
}: Props) => {
	if (!visible) {
		return null;
	}

	return (
		<div className="modal-main" onClick={() => handleModal(false, false)}>
			<div className="modal-content" onClick={e => e.stopPropagation()}>
				<div className="confirmation-header">
					<Image
						fileName="confirmation_page.svg"
						className="confirmation-image"
					/>
					<div className="modal-title">{title}</div>
				</div>
				<div>
					<div className="modal-des">
						<span>
							{description}
							{boldDescription && <strong>{boldDescription}</strong>}
						</span>
					</div>
					<div className="modal-footer">
						<Button
							label={secondryLabel}
							handleClick={() => handleModal(false, false)}
							type="button__filled button__filled--secondary button__large button-cancel"
							disabled={loading}
						/>
						<Button
							label={loading ? <Loader type="loader" dimension={20} /> : label}
							handleClick={() => handleModal(false, true)}
							type={`button__filled button__filled--${type} button__large button-ok`}
							disabled={loading}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
