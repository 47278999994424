import { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { kycSettingsState } from 'global-stores';
import {
	ClientEmailNavigateState,
	useEmailClient,
} from 'views/email-client/stores';
import './email-verify.scss';

export const EmailVerify = () => {
	// globle states
	const setClientEmailNavigate = useSetRecoilState(ClientEmailNavigateState);
	const setKycSettings = useSetRecoilState(kycSettingsState);

	// local state
	const [processing, setProcessing] = useState(0);

	// hooks
	const { updateEmailClient } = useEmailClient();

	useEffect(() => {
		(async () => {
			const resp = await updateEmailClient();
			if (resp) {
				setClientEmailNavigate('verification-status');
				setKycSettings(prev => ({
					...prev,
					whitelabelKey: resp.whitelabelKey,
					whitelistConfig: resp.whitelistConfig,
				}));
				return;
			} else setClientEmailNavigate('enter-client-details');
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (processing < 100) {
			setTimeout(() => {
				if (processing <= 70) {
					setProcessing(prev => prev + 10);
				} else if (processing <= 85) {
					setProcessing(prev => prev + 5);
				} else {
					setProcessing(prev => prev + 1);
				}
			}, 50);
		}
	}, [processing]);

	return (
		<div className="email-verify">
			<div className="email-verify_title">
				Verifying details and connecting to email client
			</div>
			<progress
				value={processing}
				max={100}
				className="email-verify__loading-bar"
			/>
		</div>
	);
};
