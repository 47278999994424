import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useNetwork, useNotification } from 'hooks';
import { SelectedSessionState } from 'global-stores';
import { API_URL, MESSAGE } from 'constant';
import {
	SelectedTransactionIdsState,
	SelectedTransactionState,
} from './states';

const defaultData: any = {
	accreditation: [],
	activities: [],
	aml: {},
	device: {},
	fundInvestment: {},
	kyc: [],
	signAgreement: [],
	userInfo: {},
};

export const useDownloadPdf = () => {
	// globle states
	const selectedTransactionIds = useRecoilValue(SelectedTransactionIdsState);
	const selecetedTransaction = useRecoilValue(SelectedTransactionState);
	const setSessionSelected = useSetRecoilState(SelectedSessionState);

	//hooks
	const { get } = useNetwork();
	const { post } = useNetwork({ returnResponse: true });
	const { errorNotification } = useNotification();

	const downloadPdf = useCallback(
		async (label: 'Download' | 'Download Zip', complianceData: boolean) => {
			const payload = {
				transactionIds:
					label === 'Download'
						? [selecetedTransaction._id]
						: selectedTransactionIds,
				fileType: label === 'Download' ? 'pdf' : 'zip',
				complianceData: complianceData,
			};

			const res = await post(API_URL.TRANSACTION_REPORTS, payload);
			const { url, errorCode, errorData } = res ?? {};
			if (errorCode && errorData) {
				errorNotification(errorData?.message ?? MESSAGE.ERROR);
				return;
			}
			if (url) {
				window.open(url, '_blank');
			} else {
				errorNotification(MESSAGE.ERROR);
			}
			return false;
		},
		[errorNotification, post, selecetedTransaction, selectedTransactionIds]
	);

	const getSessionDetails = useCallback(
		async (id: string) => {
			const resp = await get(`${API_URL.SESSION_DETAIL}/${id ?? ''}`);
			const { data } = resp ?? {};
			if (data) {
				setSessionSelected(data);
			} else {
				setSessionSelected(defaultData);
			}
		},
		[get, setSessionSelected]
	);
	return { downloadPdf, getSessionDetails };
};
