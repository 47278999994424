import { FC } from 'react';

import { ActionButton } from '../action';
import { DocSlider } from '../docSlider';
import { SessionDetailsHeading } from '../session-details-heading';
import { IAccreditation } from 'global-stores/sessions/type';
import { NoData } from '../no-data-available';
import { SessionDetailsSkeletonLoader } from 'views/sessions/session-details-loader';

interface IAccreditationWrapper {
	accreditation: IAccreditation[];
	role: string;
	isAgentPermitted?: boolean;
	isLoading: boolean;
	isLoaded?: boolean;
	nodeId?: string;
	isUserPermissionWrite?: boolean;
}
export const Accreditation: FC<IAccreditationWrapper> = ({
	accreditation,
	role,
	isAgentPermitted,
	isLoading,
	isLoaded,
	nodeId,
	isUserPermissionWrite=true,
}) => {
	return (
		<div className="accreditation">
			<div className="kycDetails-contain">
				<SessionDetailsHeading
					label={`Accreditation${accreditation?.length > 0
							? accreditation[0]?.source === '506c' ||
								accreditation[0]?.type === '506c'
								? '(506c)'
								: '(506b)'
							: ''
						}`}
					icon={
						<i className="ri-user-star-fill form-icon-profile-container__formIcon" />
					}
				>
					{accreditation?.length > 0 && (
						<div className="action-btn">
							{role === 'AGENT' && !isAgentPermitted ? (
								<div className="action-btn__agent">
									{accreditation[0]?.status}
								</div>
							) : (
								<ActionButton
									status={accreditation[0]?.status ?? ''}
									type="accreditation"
									isLoading={isLoading}
									nodeId={nodeId}
									isDisabled={!isUserPermissionWrite}
								/>
							)}
						</div>
					)}
				</SessionDetailsHeading>

				{!isLoading && isLoaded ? (
					<>
						{accreditation?.length > 0 ? (
							<div className="kycDetails-body">
								<DocSlider slides={accreditation} />
							</div>
						) : (
							<NoData />
						)}
					</>
				) : (
					<>
						<SessionDetailsSkeletonLoader />
					</>
				)}
			</div>
		</div>
	);
};
