import type { IComplexThenBlock } from 'views/complex-onboarding-flow/stores/states';

import { FC, createRef, useCallback, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { KybSelectAnswer } from 'views/complex-onboarding-flow/constant';
import { ComplexInitPipelines, NodesState } from 'views/complex-onboarding-flow/stores/states';
import { CustomHandle } from '../custom-handle';
import { IOption } from '../dropdown';
import '../question-container/question-wrapper.scss';
import { useOutsideClick } from 'hooks';
import { CountryDropdownModal } from '../question-container/countyr-modal';

interface IQuestionWrapper {
	stepId: string;
	conditionId: string | number;
	questionData: Record<string, string>;
	nodeId: string;
	blockId: string;
	questionLen: number;
	index: number;
	connect: string;
}
let prevShowModal = '';
export const KybQuestionWrapper: FC<IQuestionWrapper> = ({
	stepId,
	questionData,
	nodeId,
	blockId,
	questionLen,
	index,
	connect,
}) => {
	const [showModal, setShowModal] = useState<string>();
	const [dropdownConfig, setDropdownConfig] = useState<any>({});
	const ref = createRef<HTMLDivElement>();

	// Global variables
	const complexInitPipelines = useRecoilValue(ComplexInitPipelines);

	const [nodes, setNodes] = useRecoilState(NodesState);

	useOutsideClick(ref, () => {
		setShowModal('');
		prevShowModal = ''
	});

	const openCountryDropdown = useCallback((key: string) => {
		if (prevShowModal === key) {
			prevShowModal = ''
		} else {
			prevShowModal = key
		}
		setShowModal(prevShowModal)

	}, []);

	const closeCountryDropdown = useCallback((e: any) => {
		e.stopPropagation();
		setShowModal('');
		prevShowModal = ''
	}, []);

	const { stepVariables, thenConditions = [] } = useMemo(
		() => complexInitPipelines ?? {},
		[complexInitPipelines]
	);

	const { conditions: kyb } = useMemo(
		() => nodes.find((el: any) => el.id === nodeId) ?? [],
		[nodes, nodeId]
	);

	const defaultThenValue = useMemo(
		() =>
			kyb?.find((el: any) => el.currentId == nodeId && el.blockId === blockId)
				.then ?? ' ',
		[blockId, kyb, nodeId]
	);

	const OptionsResult = useMemo(() => {
		const options = stepVariables?.filter((el: any) => el.stepId === stepId);
		return options;
	}, [stepVariables, stepId]);

	const onRemoveQuestion = useCallback(() => {
		setNodes((prev: any) => {
			const prevObj = JSON.parse(JSON.stringify(prev));
			const findIndex = prevObj.findIndex((el: any) => el.id === nodeId);
			if (findIndex !== -1 && index !== -1) {
				const nodeIndex = prevObj[findIndex].conditions?.findIndex(
					(el: any) => el.currentId === nodeId && el.blockId === blockId
				);
				if (nodeIndex !== -1 && index !== -1) {
					if (prevObj[findIndex].conditions[nodeIndex][connect]?.length > 1) {
						prevObj[findIndex].conditions[nodeIndex][connect].splice(index, 1);
					} else prevObj[findIndex].conditions.splice(nodeIndex, 1);
				}
			}
			return prevObj;
		});
	}, [setNodes, index, nodeId, blockId, connect]);

	const handleChangeSelect = useCallback(
		({ value }: IOption, key: string) => {
			setNodes((prev: any) => {
				const prevObj = JSON.parse(JSON.stringify(prev));
				const findIndex = prevObj.findIndex((el: any) => el.id === nodeId);
				if (findIndex !== -1 && index !== -1) {
					const nodeIndex = prevObj[findIndex].conditions?.findIndex(
						(el: any) => el.currentId === nodeId && el.blockId === blockId
					);

					if (nodeIndex !== -1 && index !== -1) {
						Object.assign(
							prevObj[findIndex].conditions[nodeIndex][connect][index],
							{
								[key]: value,
							}
						);
					}
				}
				return prevObj;
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[index, nodeId, blockId, connect]
	);

	const handleThenChangeSelect = useCallback(
		({ value }: IOption) => {
			setNodes((prev: any) => {
				const prevObj = JSON.parse(JSON.stringify(prev));
				const findIndex = prevObj.findIndex((el: any) => el.id === nodeId);
				if (findIndex !== -1 && index !== -1) {
					const nodeIndex = prevObj[findIndex].conditions?.findIndex(
						(el: any) => el.currentId === nodeId && el.blockId === blockId
					);
					if (nodeIndex !== -1 && index !== -1) {
						prevObj[findIndex].conditions[nodeIndex]['then'] = value;
						prevObj[findIndex].conditions[nodeIndex][
							'key'
						] = `${stepId}_${blockId}_${nodeId}`;
					}
				}
				return prevObj;
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[index, nodeId, blockId, stepId]
	);

	const Options = useMemo(
		() =>
			OptionsResult?.map((el: { label: string; key: string }) => ({
				label: el.label,
				value: el.key,
			})),
		[OptionsResult]
	);

	const findDefaultParamValue = Options.find(
		(el: any) => el.value === questionData.param
	);

	const findDefaultOperand = KybSelectAnswer.find(
		(el: any) => el.value === questionData.operand
	);

	const thenOptions = thenConditions.map((el: IComplexThenBlock) => {
		const { key, label } = el;
		return { value: key, label };
	});

	const defaultThenObj = thenOptions.find(
		(el: any) => el.value === defaultThenValue
	);

	const hanldeOpenDropdown = useCallback((type: string) => {
		switch (type) {
			case 'operand':
				setDropdownConfig({
					type,
					options: KybSelectAnswer,
					defaultValue: findDefaultOperand || { label: 'Select', value: '' },
					handleChangeSelect: handleChangeSelect
				})
				break;
			case 'param':
				setDropdownConfig({
					type: "param",
					options: Options,
					defaultValue: findDefaultParamValue,
					handleChangeSelect: handleChangeSelect
				})
				break;
			case 'then':
				setDropdownConfig({
					type: "then",
					options: thenOptions,
					defaultValue: defaultThenObj,
					handleChangeSelect: handleThenChangeSelect
				})
				break;
			default:
				break;
		}
		openCountryDropdown(type);
	}, [Options, defaultThenObj, findDefaultOperand, findDefaultParamValue, thenOptions, handleChangeSelect, handleThenChangeSelect, openCountryDropdown])

	const countrySelectDropdown = useMemo(() => {
		return (
				<CountryDropdownModal
					defaultOperand={dropdownConfig.defaultValue}
					isOpen={!!showModal}
					onClose={closeCountryDropdown}
					handleChangeSelect={dropdownConfig.handleChangeSelect}
					type={dropdownConfig.type}
					options={dropdownConfig.options}
				/>
		)
	}, [dropdownConfig, showModal, closeCountryDropdown])

	return (
		<div className="question-container">
			<div className="question-container__threads">
				<div className="question-container__threads__left">
					<span>If</span>
					<div
						onClick={() => hanldeOpenDropdown('param')}
						className="question-container__select-country"
						ref={showModal === 'param' ? ref : null}
					>
						<span className='question-ellipsis'>
							{findDefaultParamValue?.label ? findDefaultParamValue.label : 'Select'}
						</span>
						<div className="select-arrow"></div>
						{showModal === 'param' && countrySelectDropdown}
					</div>
				</div>
				<div
					onClick={() => hanldeOpenDropdown('operand')}
					className="question-container__select-country"
					ref={showModal === 'operand' ? ref : null}
				>
					<span className='question-ellipsis'>
						{findDefaultOperand?.label ? findDefaultOperand.label : 'Select'}
					</span>
					<div className="select-arrow"></div>
					{showModal === 'operand' && countrySelectDropdown}
				</div>
				{questionLen === index + 1 && (
					<>
						<span>then</span>
						<div
							onClick={() => hanldeOpenDropdown('then')}
							className="question-container__select-country"
							ref={showModal === 'then' ? ref : null}
						>
							<span className='question-ellipsis'>
								{defaultThenObj?.label ? defaultThenObj.label : 'Select'}
							</span>
							<div className="select-arrow"></div>
							{showModal === 'then' && countrySelectDropdown}
						</div>
						<div className="custom_handle-main">
							<CustomHandle
								value={`${stepId}_${blockId}_${nodeId}`}
								showLeft={false}
								showRight
							/>
						</div>
					</>
				)}
				<div className="question-container__threads__right-container">
					<div className="right" onClick={onRemoveQuestion}>
						<i className="ri-indeterminate-circle-line"></i>
					</div>
				</div>
			</div>
		</div>
	);
};
