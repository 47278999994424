/* eslint-disable @typescript-eslint/ban-types */
import { useEffect, useRef } from 'react';

export const useInterval = (callback: any, delay: number) => {
	const savedCallback = useRef<Function>();

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	// Set up the interval.
	useEffect(() => {
		function tick() {
			savedCallback?.current?.();
		}
		if (delay !== null) {
			const id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
		return;
	}, [delay]);
};
