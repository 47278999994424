import {
	REACT_APP_FIREBASE_APIKEY,
	REACT_APP_FIREBASE_AUTHDOMAIN,
	REACT_APP_FIREBASE_DATABASEURL,
	REACT_APP_FIREBASE_PROJECTID,
	REACT_APP_FIREBASE_STORAGEBUCKET,
	REACT_APP_FIREBASE_MESSAGINGSENDERID,
	REACT_APP_FIREBASE_AAPID,
} from 'envs';
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

export const useFirebaseInit = () => {
	const firebaseConfig = {
		apiKey: REACT_APP_FIREBASE_APIKEY,
		authDomain: REACT_APP_FIREBASE_AUTHDOMAIN,
		databaseURL: REACT_APP_FIREBASE_DATABASEURL,
		projectId: REACT_APP_FIREBASE_PROJECTID,
		storageBucket: REACT_APP_FIREBASE_STORAGEBUCKET,
		messagingSenderId: REACT_APP_FIREBASE_MESSAGINGSENDERID,
		appId: REACT_APP_FIREBASE_AAPID,
	};

	// Initialize Firebase
	const app = initializeApp(firebaseConfig);
	const database = getDatabase(app);

	return { database };
};
