import { Loader } from '@storybook/loader';
import { LuckySheetLinks } from 'constant/links';
import { useNotification } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { InjectScript } from 'utils/script';

export const INVESTOR_CAPTABLE_DATA = [
	[
		'First Name',
		'Last Name',
		'Country Code',
		'Mobile',
		'Email',
		'Dollars Invested',
		'No. of Shares',
		'Price per Share',
		'Issue Date',
	],
	['', '', '', '', '', '', '', ''],
];

export const CaptableGrid = ({ isInvestor = false }) => {
	const DEFAULT_TABLE_DATA = JSON.parse(
		localStorage.getItem('CAPTABLE_HEADER') ?? '[]'
	);
	const { errorNotification } = useNotification();
	const tableArray: any = [];

	((isInvestor ? INVESTOR_CAPTABLE_DATA : DEFAULT_TABLE_DATA) ?? []).forEach(
		(rows: any, rowIndex: any) => {
			(rows ?? []).forEach((row: any, colIndex: any) => {
				const cellObj = {
					r: rowIndex,
					c: colIndex,
					v: {
						ct: { fa: 'General', t: 'g' },
						m: row,
						v: row,
					},
				};
				tableArray.push(cellObj);
			});
		}
	);
	const luckysheet = useRef(null);
	luckysheet.current = (window as any)?.luckysheet;
	const [loading, setLoading] = useState(true);

	const appendScripts = async () => {
		await InjectScript(LuckySheetLinks);
		setLoading(false);
	};
	useEffect(() => {
		if (typeof (luckysheet as any).current?.create !== 'function') {
			appendScripts();
		} else {
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		if (luckysheet?.current && !loading) {
			try {
				(luckysheet as any).current.create?.({
					container: 'luckysheet',
					showtoolbar: false,
					showsheetbar: false,
					showinfobar: false,
					showstatisticBar: false,
					sheetFormulaBar: false,
					defaultColWidth: 144,
					defaultRowHeight: 32,
					row: 10,
					data: [
						{
							celldata: tableArray,
						},
					],
					hook: {
						cellUpdateBefore: (_row: number, _col: number, value: string) => {
							if (_row === 0){
								errorNotification("Header is reserved for core data and cannot be modified.")
								return false;
								
							} 
							const regex = /[~`!#$%^&*(){}[\]\\;:<>?]/;
							if (regex.test(value)) return false;
							return true;
						},
						cellUpdated: () => {
							return true;
						},
					},
				});
				setTimeout(() => {
					(luckysheet?.current as any)?.setHorizontalFrozen(false);
				}, 500);
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log(error);
			}
		}
		// eslint-disable-next-line
	}, [loading]);

	useEffect(() => {
		try {
			if (
				(window as any)?.luckysheet?.transToCellData?.(
					(window as any)?.luckysheet?.getSheetData()
				)
			) {
				window.alert = () => ({});
				window.prompt = () => '';
				window.history.back = () => ({});
				window.history.forward = () => ({});
				window.confirm = () => false;
				window.print = () => ({});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			return;
		}
	}, []);

	return (
		<div
			id="luckysheet"
			style={{
				margin: '0px',
				padding: '0px',
				position: 'absolute',
				width: '100%',
				height: '100%',
				left: '0px',
				top: '0px',
			}}
		>
			{loading ? <Loader /> : <></>}
		</div>
	);
};
