const loadResource = (
	type: string,
	link: string,
	head: HTMLElement
): Promise<void> => {
	return new Promise((resolve, reject) => {
		if (type === 'link') {
			const linkElement: any = document.createElement('link');
			linkElement.href = link;
			linkElement.rel = 'stylesheet';
			linkElement.onload = resolve;
			linkElement.onerror = reject;
			head?.appendChild(linkElement);
		} else {
			const scriptElement: any = document.createElement('script');
			scriptElement.src = link;
			scriptElement.onload = resolve;
			scriptElement.onerror = reject;
			head?.appendChild(scriptElement);
		}
	});
};

export const InjectScript = async (data: any): Promise<void> => {
	if (!data || !data.length) {
		return;
	}

	const head = document.getElementsByTagName('head')[0];

	if (head) {
		for (let i = 0; i < data.length; i++) {
			if (
				data[i] &&
				(data[i].type === 'link' || data[i].type === 'script') &&
				data[i].link
			) {
				await loadResource(data[i].type, data[i].link, head).catch(error => {
					// eslint-disable-next-line no-console
					console.error('Error loading script or link:', error);
				});
			} else {
				// eslint-disable-next-line no-console
				console.error('Invalid data object');
			}
		}
	}
};
