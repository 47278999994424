import { useCallback, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { getLuckSheetData } from 'utils';

import { SelectedDocsForUploadState, loginState } from 'global-stores';
import { OnboardingStepInfoSelector } from 'views/sign-aggrement/store';
import { ESIGN_PURPOSE, IPurpose, SelectedQRTemplateIdState, userCapTableState } from 'views/pipelines/components';
import { useNetwork } from 'hooks';
import { API_URL } from 'constant';
import { MultiSignPrepareUrl, OverlayMultiSignPrepareUrl, OverlaySignAgreementEnvelopeId, SelectedComplexSignAgreementStep, SignAgreementComplexStepConfigured } from './state';
import { IQrHeader, QR_HEADER } from './constant';

interface IHeader {
	label: string,
		name: string,
		type: 'text'
}

interface IMultiSignPrepareUrlPayload {
	senderData : {
		fullName: string;
		email: string;
		testMode: boolean;
	}
	recipients: {fullName: string, email: string}[],
	configuration: {
		kyc: boolean,
		kyb: boolean,
		questionnaire: boolean,
	}
	purpose: IPurpose,
	questionnaire?: any,
	headers?: IHeader[]
}

export const useMultiSignAgreement = () => {
	const { firstName, lastName, sandboxStatus, email } =
		useRecoilValue(loginState);
	const flowSteps = useRecoilValue(OnboardingStepInfoSelector);
	const userCapTableData = useRecoilValue(userCapTableState);
	const setConfig = useSetRecoilState(MultiSignPrepareUrl);
	const [isLoaded, setIsLoaded] = useState(true);
	const { post } = useNetwork({ updateState: false, returnResponse: true });
	const QRTemplateId = useRecoilValue(SelectedQRTemplateIdState);
	const setOverlayInviteConfig = useSetRecoilState(OverlaySignAgreementEnvelopeId);

	const multiSignPrepareUrlPayload = useMemo(() => {
		if (getLuckSheetData) {
			const { rows = [], headers = [] } = getLuckSheetData();
			// Create the recipients array
			const recipients: { fullName: string; email: string }[] = [];

			// Assuming the order of columns is consistent
			(rows ?? []).forEach((row: { [x: string]: any }) => {
				const fullName =
					row[headers.indexOf('First Name')] +
					' ' +
					row[headers.indexOf('Last Name')];
				const email = row[headers.indexOf('Email')];

				recipients.push({
					fullName: fullName,
					email: email,
				});
			});

			// Create the final payload
			const newPayload: IMultiSignPrepareUrlPayload = {
				senderData: {
					fullName: firstName + ' ' + lastName,
					email: email as string,
					testMode: sandboxStatus as boolean,
				},
				recipients: recipients,
				configuration: {
					kyc: flowSteps.isKyc,
					kyb: flowSteps.isKyb,
					questionnaire: flowSteps.isQuestionnaire,
				},
				purpose: ESIGN_PURPOSE.MULTISIGNAGREEMENT,
			};
			if (flowSteps.isQuestionnaire) {
				newPayload.questionnaire = flowSteps.questionnaire;
			}
			const capHeaders: IHeader[] = [];
			userCapTableData?.headers?.forEach((name: string) => {
				if (name.trim()) {
					capHeaders.push({
						label: name,
						name,
						type: 'text', // for now all the headers that are coming through captable will have field type of text in the esign
					});
				}
			});
			newPayload.headers = capHeaders;
			return newPayload;
		}
		return;
	}, [
		email,
		firstName,
		flowSteps,
		lastName,
		sandboxStatus,
		userCapTableData?.headers,
	]) as IMultiSignPrepareUrlPayload;

	const overlayMultiSignPrepareUrlPayload = useMemo(() => {
		const { senderData, recipients, purpose } =
			multiSignPrepareUrlPayload ?? {};
		return { senderData, recipients, purpose } as Omit<
			IMultiSignPrepareUrlPayload,
			'configuration' | 'questionnaire' | 'headers'
		>;
	}, [multiSignPrepareUrlPayload]);

	const multiSignPrepareUrl = useCallback(
		async (payload: any): Promise<{envelopeId: string, prepareUrl: boolean}> => {
			setIsLoaded(false);
			const response = await post(API_URL.ENVELOPE, payload);
			const { envelopeId = '', prepareUrl = '' } = response ?? {};
			if (prepareUrl) {
				setConfig({ envelopeId, prepareUrl });
				return ({envelopeId, prepareUrl});
			} else {
				return ({envelopeId, prepareUrl});
			}
			setTimeout(() => {
				setIsLoaded(true);
			}, 500);
		},
		[post, setConfig]
	);

	const fetchMultiSignPrepareUrl = useCallback(() => {
		multiSignPrepareUrl(multiSignPrepareUrlPayload);		
	}, [multiSignPrepareUrl, multiSignPrepareUrlPayload]);

	const fetchOverlayMultisignerPrepareUrl = useCallback(async () => {
		const payload: any = overlayMultiSignPrepareUrlPayload
		payload.templateId = QRTemplateId
		const {envelopeId} = await multiSignPrepareUrl(payload);
		if(envelopeId) {
			setOverlayInviteConfig({envelopeId, status: "pending"});
		}		
	}, [overlayMultiSignPrepareUrlPayload, QRTemplateId, multiSignPrepareUrl, setOverlayInviteConfig]);

	const reconfigureMultiSignAgreement = useCallback(
		async (packetId: string) => {
			const payload = {
				packetId,
			};
			const resp = await post(`${API_URL.PACKET_EDIT}`, payload);
			const { prepareUrl, envelopeId } = resp ?? {};
			if (prepareUrl) {
				setConfig({ envelopeId, prepareUrl });
			}
		},
		[post, setConfig]
	);

	return { fetchMultiSignPrepareUrl, isLoaded, reconfigureMultiSignAgreement, fetchOverlayMultisignerPrepareUrl};
};

interface ICreateTemplate {
	configuration: {
		kyc: boolean;
		kyb: boolean;
		questionnaire: boolean;
	};
	type?: 'overlay';
	headers: IQrHeader[];
	source: "default" | "onboarding",
	questionaire?: any[];
}

export const useOverlaySignAgreement = () => {
	const flowSteps = useRecoilValue(OnboardingStepInfoSelector);
	const setConfig = useSetRecoilState(OverlayMultiSignPrepareUrl);
	const [selectedFiles, setSelectedFiles] = useRecoilState(SelectedDocsForUploadState);
	const setConfigurationStatus = useSetRecoilState(SignAgreementComplexStepConfigured);
	const selectedSignNode = useRecoilValue(SelectedComplexSignAgreementStep);
	const [isLoaded, setIsLoaded] = useState(true);
	const { post, patch } = useNetwork();
	const createTemplate = useCallback(async () => {
		setIsLoaded(false);
		const payload: ICreateTemplate = {
			configuration: {
				kyc: flowSteps.isKyc,
				kyb: flowSteps.isKyb,
				questionnaire: flowSteps.isQuestionnaire,
			},
			type: 'overlay',
			headers: QR_HEADER,
			source: "onboarding",
		};
		if (flowSteps.isQuestionnaire) {
			payload.questionaire = flowSteps.questionnaire;
		}
		const resp = await post(`${API_URL.SIGN_AGREEMENT}?type=esign`, payload);
		if (resp) {
			setSelectedFiles([resp]);
			const { prepareUrl, templateId } = resp;
			setConfig({ prepareUrl, templateId });
			if(selectedSignNode){
				setConfigurationStatus(prev => ({ ...prev, [selectedSignNode]: {templateId: templateId, status: "pending" } }));
			}
		}
		setIsLoaded(true);
	}, [flowSteps, post, selectedSignNode, setConfig, setConfigurationStatus, setSelectedFiles]);

	const updateTemplate = useCallback(async () => {
		setIsLoaded(false);
		const { templateId = '', prepareUrl = '' } = selectedFiles[0] ?? {};
		const payload: ICreateTemplate = {
			configuration: {
				kyc: flowSteps.isKyc,
				kyb: flowSteps.isKyb,
				questionnaire: flowSteps.isQuestionnaire,
			},			
			headers: QR_HEADER,
			source: "onboarding",
		};
		if (flowSteps.isQuestionnaire) {
			payload.questionaire = flowSteps.questionnaire;
		}
		const resp = await patch(`${API_URL.SIGN_AGREEMENT}/${templateId}`, payload);
		if (resp?._id) {
			const copiedFile = structuredClone(selectedFiles[0]);
			const newPrepareUrl = prepareUrl.replace(templateId, resp._id.toString());
			if(copiedFile) {
				copiedFile._id = resp._id;
				copiedFile.templateName = resp.name;
				copiedFile.templateId = resp._id;
				copiedFile.prepareUrl = newPrepareUrl;
				copiedFile._id = resp._id;
				setSelectedFiles([copiedFile]);
			}
			setConfig({ prepareUrl: newPrepareUrl, templateId: resp?._id });
			if(selectedSignNode){
				setConfigurationStatus(prev => ({ ...prev, [selectedSignNode]: {templateId: resp?._id, status: "pending" } }));
			}
		}
		setIsLoaded(true);
	}, [flowSteps, patch, selectedFiles, setConfig, setSelectedFiles, selectedSignNode, setConfigurationStatus]);

	return { createTemplate, updateTemplate, isLoaded };
};
