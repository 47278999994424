import { IAction } from 'global-stores';

import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Image } from '@storybook';

import { AddedActionsState, CurrentStepState } from 'views/onboarding-flow';
import { UnitPriceToggleState } from 'views/pipeline';
import { getImage } from '../../constants';

import './fund-verify.scss';

export const FundVerifyScreen = () => {
	const currentStep = useRecoilValue<IAction>(CurrentStepState);
	const isUnitPriceToggleOn = useRecoilValue(UnitPriceToggleState);
	const addedActions = useRecoilValue(AddedActionsState);

	const payMetaData = useMemo(
		() => addedActions?.find(item => item.key === 'fundInvestmentVerification'),
		[addedActions]
	);

	const paytype = useMemo(
		() =>
			payMetaData?.metadata?.find((item: any) => item?.key === 'payInPayOut')
				?.default ?? 'payIn',
		[payMetaData?.metadata]
	);

	const getConditionalImage = useMemo(()=>{
		if(isUnitPriceToggleOn?.[currentStep?.id ?? '']){
			return getImage["payInUnitPricing"]
		}
		return getImage[paytype]
	},[currentStep?.id, isUnitPriceToggleOn, paytype])

	return (
		<div className="fundverify-wrapper">
			<div className="fundverify-wrapper__inner">
				<Image fileName={getConditionalImage} />
			</div>
		</div>
	);
};
