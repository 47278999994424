import { ITemplateSectionOptionValue } from ".";

export const UPLOAD_HEADER = [
	{
		label: 'Document Name',
		key: 'name',
		format: 'string',
		width: '80%',
	},
	{
		label: 'File size',
		key: 'size',
		format: 'jsx',
		width: '10%',
	},
	{
		label: 'Actions',
		key: 'action',
		format: 'jsx',
		width: '10%',
	},
];

export const docProvider ={
	esign:"esign",
	docusign:"docusign"
}

export const docProviderType ={
	basic:"basic",
	overlay:"overlay"
}


export const uploadDocumentOptions: { color: string, icon: string, label: string; description: string; value: ITemplateSectionOptionValue, children?: JSX.Element }[] = [
	{
		color: '#389F75',
		icon: 'ri-file-upload-line',
		label: 'Choose from existing template',
		description: 'Each invitee will receive their own copy of the document to sign separately.',
		value: 'existing',
		children: <p>Hello</p>
	},
	{
		color: '#7935DE',
		icon: 'ri-file-text-line',
		label: 'Upload new documents',
		description: 'All invitees will sign on the same document.',
		value: 'newDocument',
	},
];

export const uploadDocumentOptionsValues: Record<ITemplateSectionOptionValue, ITemplateSectionOptionValue> = {
	existing: 'existing',
    newDocument: 'newDocument',
}
