import { useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { PipelineMasterSteps } from 'global-stores';
import { CurrentStepState } from '../../../store';
import './delivery-left.scss';

export const DeliverLeftView = () => {
	const masterSteps = useRecoilValue(PipelineMasterSteps);
	const [renderedObj, setRenderedObj] = useRecoilState(CurrentStepState);

	const handleSelectStep = useCallback(
		(act: any) => {
			setRenderedObj(act);
		},
		[setRenderedObj]
	);

	const deliveryActions = useMemo(
		() =>
			masterSteps.find(step => step.key === 'deliveryMethod')?.actions ?? [],
		[masterSteps]
	);

	const iconClassName = useCallback((label: string) => {
		switch (label) {
			case 'Text Message':
				return 'ri-mail-send-line';
			case 'Reminder Settings':
				return 'ri-notification-fill';
			default:
				return 'ri-message-2-fill';
		}
	}, []);

	return (
		<>
			<div className="OnboardingLeftNav--container">
				<div className="DeliveryLeftView__container">
					{deliveryActions.map(act => {
						return (
							<div
								key={act.label}
								className={`DeliveryLeftView__box ${
									renderedObj?.label === act?.label &&
									'DeliveryLeftView__box-active'
								}`}
								onClick={() => handleSelectStep(act)}
							>
								<div
									className={
										renderedObj?.label === act.label
											? 'DeliveryLeftView__icon-container'
											: 'DeliveryLeftView__selected'
									}
								>
									<i
										className={iconClassName(act.label)}
										style={{
											fontSize: 18,
											color:
												renderedObj?.label === act.label
													? '#3c65d6'
													: '#747e99',
										}}
									></i>
								</div>
								<div
									className='DeliveryLeftView__label-container'
								>
									<span
										className={
											renderedObj?.label === act.label
												? 'DeliveryLeftView__label-selected'
												: 'DeliveryLeftView__label'
										}
									>
										{act.label}
									</span>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
};
