import { Button, Image, Loader } from '@storybook';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { ActiveTabsIntegrateState } from 'global-stores';
import { Header } from 'views/header';
import './analytics.scss';
import {
	ChartHeader,
	DeviceTypeDetail,
	SankeyDiagram,
	StatusDetail,
	StepsDetail,
	onboardingStepsAvgTime,
} from './components';
import {
	CompareAverageTimeState,
	FlowAverageTimeState,
	NodeTitleCompareFunnelChartState,
	NodeTitleFunnelChartState,
	analyticsChartHeaderState,
	analyticsChartTwoHeaderStart,
	comparedFunnelChartsState,
	funnelChartFirstState,
	funnelChartSecondState,
	useAnalytics,
} from './hooks';

export const Analytics = () => {
	const {
		getPreFunnelDetails,
		getNextFunnelDetails,
		preFunnelLoader,
		nextFunnelLoader,
	} = useAnalytics();

	const [comparedChart, setComparedChart] = useRecoilState(
		comparedFunnelChartsState
	);
	const flowAverageTime = useRecoilValue(FlowAverageTimeState);
	const compareAverageTime = useRecoilValue(CompareAverageTimeState);
	const preFunnelDetail = useRecoilValue(funnelChartFirstState);
	const nextFunnelDetail = useRecoilValue(funnelChartSecondState);
	const activeIntegrate = useRecoilValue(ActiveTabsIntegrateState);
	const nodeTitle = useRecoilValue(NodeTitleFunnelChartState);
	const nodeCompareTitle = useRecoilValue(NodeTitleCompareFunnelChartState);
	const resetPreDateRange = useResetRecoilState(analyticsChartHeaderState);
	const resetCompareDateRange = useResetRecoilState(
		analyticsChartTwoHeaderStart
	);
	const resetNodeTitle = useResetRecoilState(NodeTitleFunnelChartState);
	const resetCompareNodeTitle = useResetRecoilState(
		NodeTitleCompareFunnelChartState
	);

	useEffect(() => {
		resetPreDateRange();
		resetCompareDateRange();
		resetNodeTitle();
		resetCompareNodeTitle();
		// eslint-disable-next-line
	}, []);

	const handlePreFunnelChart = useCallback(() => {
		getPreFunnelDetails();
	}, [getPreFunnelDetails]);

	const handleNextFunnelChart = useCallback(() => {
		getNextFunnelDetails();
	}, [getNextFunnelDetails]);

	const handleComparedButton = useCallback(() => {
		setComparedChart(pre => !pre);
		resetCompareDateRange();
		resetNodeTitle();
		resetCompareNodeTitle();
		// eslint-disable-next-line
	}, []);

	const chartWrapperClass = useMemo(() => {
		return classNames('analytics-wrapper__sankey ', {
			'analytics-wrapper__sankey-webComponent':
				activeIntegrate === 'webcomponent',
		});
	}, [activeIntegrate]);

	return (
		<div className="analytics-body ">
			<Header title="User Analytics" />
			<div className="analytics-wrapper">
				<div className="funnel-chart-button">
					<Button
						label={comparedChart ? 'Hide comparison ' : ' Show comparison'}
						handleClick={handleComparedButton}
						type="button__filled button__filled--primary button__large funnel-chart-button_content "
					/>
				</div>

				{comparedChart ? (
					<div className={chartWrapperClass}>
						<div className="analytics-wrapper_chart">
							<ChartHeader
								onSubmit={handlePreFunnelChart}
								chartType="chart1"
								averageTime={flowAverageTime[onboardingStepsAvgTime[nodeTitle]]}
							/>

							{preFunnelLoader ? (
								<div className="analytics-wrapper__noData">
									<Loader />
								</div>
							) : preFunnelDetail.length > 0 ? (
								<div className="sankydiagram-analytics">
									<SankeyDiagram
										title="onboarding"
										seriesData={preFunnelDetail}
										isLoading={false}
									/>
								</div>
							) : (
								<div className="analytics-wrapper__noData">
									<Image fileName="insight-empty.svg" />
									<div className="analytics-wrapper__noData-desc">
										Data is not available on this date range
									</div>
								</div>
							)}
						</div>

						<div className="analytics-wrapper_chart">
							<ChartHeader
								onSubmit={handleNextFunnelChart}
								chartType="chart2"
								averageTime={
									compareAverageTime[onboardingStepsAvgTime[nodeCompareTitle]]
								}
							/>
							{nextFunnelLoader ? (
								<div className="analytics-wrapper__noData">
									<Loader />
								</div>
							) : nextFunnelDetail.length > 0 ? (
								<div className="sankydiagram-analytics">
									<SankeyDiagram
										title="session"
										seriesData={nextFunnelDetail}
										isLoading={false}
									/>
								</div>
							) : (
								<div className="analytics-wrapper__noData">
									<Image fileName="insight-empty.svg" />
									<div className="analytics-wrapper__noData-desc">
										Data is not available on this date range
									</div>
								</div>
							)}
						</div>
					</div>
				) : (
					<div className="analytics-wrapper_funnel-chart">
						<div className="analytics-wrapper_chart">
							<ChartHeader
								onSubmit={handlePreFunnelChart}
								chartType="chart1"
								averageTime={flowAverageTime[onboardingStepsAvgTime[nodeTitle]]}
							/>

							{preFunnelLoader ? (
								<div className="analytics-wrapper__noData">
									<Loader />
								</div>
							) : preFunnelDetail.length > 0 ? (
								<div className="sankydiagram-analytics">
									<SankeyDiagram
										title="onboarding"
										seriesData={preFunnelDetail}
										isLoading={false}
									/>
								</div>
							) : (
								<div className="analytics-wrapper__noData">
									<Image fileName="insight-empty.svg" />
									<div className="analytics-wrapper__noData-desc">
										Data is not available on this date range
									</div>
								</div>
							)}
						</div>
					</div>
				)}

				<div className="analytics-wrapper__chart-wrapper">
					<DeviceTypeDetail />
					<StepsDetail />
					<StatusDetail />
				</div>
			</div>
		</div>
	);
};

export default Analytics;
