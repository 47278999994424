import { FC, useEffect } from 'react';

import { IKYBBarChart, useBasicBarChart } from '../../hooks';
import './kyb-chart.scss';

export const BasicBarChart: FC<IKYBBarChart> = ({
	id,
	isLoading,
	title,
	valuePrefix,
	valueSuffix,
	xAxis,
	yAxis,
	color,
}) => {
	const { getBarChartConfig } = useBasicBarChart();

	useEffect(() => {
		getBarChartConfig({
			id,
			isLoading,
			title,
			valuePrefix,
			valueSuffix,
			xAxis,
			yAxis,
			color,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, isLoading, title, valuePrefix, valueSuffix, xAxis, yAxis, color]);

	return (
		<div className="kyb-chart-wrapper">
			<div id={id} />
		</div>
	);
};
