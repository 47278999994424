import { atom } from 'recoil';

type IDashboardTypes = 'summary' | 'chartData';

type ISummaries = 'email' | 'session' | 'onboarding';
type IChartKeys = 'title' | 'XAxis' | 'data';
type ISummary = {
	[key in ISummaries]: any[];
};

type IChartData = {
	[key in IChartKeys | string]: any[] | string;
};

type IDashboardState = {
	[key in IDashboardTypes]: ISummary | IChartData | Record<never, string> | any; // add type in the chartData
};

interface IDashboardsAtom {
	loaded: boolean;
	error: boolean;
	data: IDashboardState;
}

export const DashboardsAtom = atom<IDashboardsAtom>({
	key: 'dashboard-atom-key',
	default: {
		loaded: true,
		error: false,
		data: {
			summary: {
				email: [],
				session: [],
				onboarding: [],
			},
			chartData: {
				title: '',
				XAxis: [],
				data: [
					{
						name: 'KYC',
						data: [0, 0, 0, 0, 0, 0, 0],
					},
					{
						name: 'KYB',
						data: [],
					},
					{
						name: 'AML',
						data: [],
					},
					{
						name: 'Accreditation',
						data: [],
					},
					{
						name: 'Sign Agreement',
						data: [],
					},
					{
						name: 'Fund Investment',
						data: [],
					},
				],
			},
		},
	},
});

export type ActivityActionType =
	| 'kyc'
	| 'kyb'
	| 'aml'
	| '506b'
	| '506c'
	| 'payIn'
	| 'payOut'
	| 'signAgreement';

interface IAnalyticsOverviewValue {
	amount: number;
	total: number;
}

interface IAnalyticsOverviewState {
	kyc: IAnalyticsOverviewValue;
	kyb: IAnalyticsOverviewValue;
	'506b': IAnalyticsOverviewValue;
	'506c': IAnalyticsOverviewValue;
	payIn: IAnalyticsOverviewValue;
	payOut: IAnalyticsOverviewValue;
	aml: IAnalyticsOverviewValue;
	signAgreement: IAnalyticsOverviewValue;
}

interface IAnalyticsOverviewAtom {
	loaded: boolean;
	error: boolean;
	data: IAnalyticsOverviewState[];
}

export const AnalyticsOverviewAtom = atom<IAnalyticsOverviewAtom>({
	key: 'analytics-overview-atom-key',
	default: {
		loaded: true,
		error: false,
		data: [],
	},
});

interface IOverviewFlowAnalyticsValue {
	processing: number;
	pending: number;
	completed: number;
	rejected: number;
	failed: number;
}

interface IOverviewFlowAnalyticsState {
	kyc: IOverviewFlowAnalyticsValue;
	kyb: IOverviewFlowAnalyticsValue;
	'506b': IOverviewFlowAnalyticsValue;
	'506c': IOverviewFlowAnalyticsValue;
	form: IOverviewFlowAnalyticsValue;
	payIn: IOverviewFlowAnalyticsValue;
	payOut: IOverviewFlowAnalyticsValue;
	aml: IOverviewFlowAnalyticsValue;
	signAgreement: IOverviewFlowAnalyticsValue;
}

interface IOverviewFlowAnalyticsAtom {
	loaded: boolean;
	error: boolean;
	data: IOverviewFlowAnalyticsState[];
}

export const OverviewFlowAnalyticsAtom = atom<IOverviewFlowAnalyticsAtom>({
	key: 'overview-flow-analytics-atom-key',
	default: {
		loaded: true,
		error: false,
		data: [],
	},
});
