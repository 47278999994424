import './kyb-enchanced-event-card.scss';
import { camelCaseToCapitalizedWords } from 'utils';

interface IMetadata {
	companyNumber?: string;
	jurisdictionCode?: string;
	startDate?: string;
	homeCompanyName?: string;
	homeCompanyJurisdictionCode?: string;
	type?: string;
	categories?: string;
}
interface KYBEventsCardProps {
	heading: string;
	date: string;
	description?: string;
	subHeading?: string;
	metaData?: IMetadata;
}

export const KYBEventsCard: React.FC<KYBEventsCardProps> = ({
	heading,
	date,
	description,
	subHeading,
	metaData,
}) => {
	return (
		<div className="event__wrapper">
			<div className="event__heading--wrapper">
				<div>
					<div className="event__card--heading">{heading}</div>
				</div>
				{subHeading && <div className="event__subheading">{subHeading}</div>}
			</div>
			<p className="event__date">{date}</p>
			{description ? (
				<div className="event__card--description">{description}</div>
			) : null}

			{Object.keys(metaData ?? {}).length > 0 && (
				<div className="event__body--wrapper">
					{Object.entries(metaData ?? {}).map(([key, value]) => {
						if (typeof value === 'object' || !value || /url|uri|link/gi.test(key)) {
							return <></>;
						}
						return (
							<>
								<div className="event__data--key">
									<span className="event__data--label">
										{camelCaseToCapitalizedWords(key)}:
									</span>
									<span className="event__data--value">{value ?? 'N/A'}</span>
								</div>
							</>
						);
})}
				</div>
			)}
		</div>
	);
};
