import { Button } from '@storybook';
import { useCallback } from 'react';
import { useSendNotification } from 'views/pipeline/store';
import { CheckboxInput } from './checkbox-input';
import './reminder-input.scss';

export const ReminderInput = () => {
	const {
		onDeleteDay,
		onKeyDown,
		onChangeCheckbox,
		val,
		onChange,
		daysList,
		error,
		onClickDay,
	} = useSendNotification();

	const renderDay = useCallback(
		(i: string) => {
			return (
				<div
					className={`day_item ${daysList.checked ? 'checked' : 'un_checked'}`}
					key={i}
				>
					{i} {+i === 1 ? 'day' : 'days'}
					<Button
						loader={<i className="ri-close-fill" />}
						handleClick={() => onDeleteDay(i)}
						disabled={daysList.checked}
						label=""
						type="cross_btn"
					/>
				</div>
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[daysList]
	);

	return (
		<div id="send__reminder">
			<CheckboxInput
				label="Send reminder in"
				onChangeCheckbox={onChangeCheckbox}
				checked={!daysList.checked}
				checkVal={false}
			/>
			<div className={`${daysList.checked ? 'reminder_wrapper' : ''}`}>
				<div className="reminder__input">
					<input
						type="text"
						value={val}
						onChange={onChange}
						onKeyDown={onKeyDown}
						placeholder="Press enter to select day"
						max={1}
						disabled={daysList.checked}
					/>
					<label htmlFor="days" onClick={onClickDay}>
						Day
					</label>
				</div>
				<p className="error">{error}</p>
				<div className="days_list">{daysList.days.map(renderDay)}</div>
			</div>
		</div>
	);
};
