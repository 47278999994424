import { useRecoilValue } from 'recoil';
import { IframePropsState } from 'global-stores';
import { SocialLink } from './contants';

interface ISocialLinks {
	url: string;
	icon: string;
}

export const Footer = () => {
	const currentYear = new Date().getFullYear();
	const { clientlogo = '' } = useRecoilValue(IframePropsState);

	if (clientlogo.length > 0) {
		return <></>;
	}

	return (
		<div className="AuthFooter">
			<div className="AuthFooter__icons">
				{SocialLink.map((el: ISocialLinks, index: number) => {
					return (
							<a
							    key={`${index.toString}_${el.icon}`}
								rel="noreferrer"
								href={el.url}
								className="AuthFooter__icons__icon"
								target={'_blank'}
							>
								<i className={el.icon} />
							</a>
					);
				})}
			</div>
			<div className="AuthFooter__text">
				© {currentYear} Simplici All rights reserved.
			</div>
		</div>
	);
};
