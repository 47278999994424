const oct02_023 = '2023-10-02T00:00:00.000Z';

export const useCheckOldUserValid = () => {
    
	const checkBefore2Oct23Date = (createdAt: string) => {

		const compareDates = (d1: string, d2: string) => {
			const date1 = new Date(d1).getTime();
			const date2 = new Date(d2).getTime();
			if (date1 < date2) {
				return true;
			}
			return false;
		};

		return compareDates(oct02_023, createdAt);
	};

	return { checkBefore2Oct23Date };
};
