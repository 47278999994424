import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { SelectedOnboardingAction } from 'global-stores';
import { NodesState } from 'views/complex-onboarding-flow/stores/states';
import { CustomHandle } from '../custom-handle';
import { AddBlockButton } from '../add-button';
import { Label } from '../condition-label';
import { RenderAccreditationQuestion } from './render-accreditation-question';

export interface IRenderBlock {
	label?: string | undefined;
	price: number;
	nodeId: string;
	stepId: string;
	stepKey: string;
}

export const RenderAccBlock: FC<IRenderBlock> = ({
	label,
	price,
	nodeId,
	stepId,
	stepKey,
}) => {
	// Global variables

	const [blockCount, setBlockCount] = useState(0);
	const [nodes, setNodes] = useRecoilState(NodesState);
	const setSelectedOnboarding = useRecoilValue(SelectedOnboardingAction);

	// Local variables

	const { conditions: accreditation } = useMemo(
		() => nodes?.find((el: any) => el.id === nodeId) ?? [],
		[nodes, nodeId]
	);

	const is506c = useMemo(() => stepKey === '506c', [stepKey]);

	useEffect(() => {
		if (/modify|clone/.test(setSelectedOnboarding.type)) {
			const currentNodesConditions = nodes.find(
				(el: any) => el.id === nodeId
			)?.conditions;
			if (currentNodesConditions) {
				const allNumber = [];

				for (let i = 0; i < currentNodesConditions.length; i++) {
					allNumber.push(
						currentNodesConditions[i]?.blockId?.split('_')?.[1] ?? 0
					);
				}
				setBlockCount(Math.max(...allNumber) + 1);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const addNewBlock = useCallback(() => {
		setBlockCount(prev => prev + 1);
		const count = blockCount + 1;
		const payload = {
			and: [{}],
			blockId: `block_${count}`,
			currentId: nodeId,
		};

		setNodes((prev: any) => {
			const prevObj = JSON.parse(JSON.stringify(prev));
			const findIndex = prevObj.findIndex((el: any) => el.id === nodeId);
			prevObj[findIndex].conditions.push(payload);
			return prevObj;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [blockCount, nodeId]);

	const AccredBlockList = useMemo(
		() => accreditation?.filter((el: any) => el.currentId === nodeId),
		[accreditation, nodeId]
	);

	return (
		<>
			<CustomHandle />
			<Label label={label || ''} price={price} priceLabel="Verification" />
			{is506c && (
				<div className="questions-wrapper">
					{AccredBlockList &&
						AccredBlockList?.map((el: any) => (
							<RenderAccreditationQuestion
								stepId={stepId}
								nodeId={nodeId}
								key={`${el.nodeId}_${el.blockId}`}
								blockId={el.blockId}
							/>
						))}
					{AccredBlockList?.length === 0 && (
						<AddBlockButton
							label="Add new condition block"
							onClick={addNewBlock}
						/>
					)}
				</div>
			)}
		</>
	);
};
