import { useRecoilState } from 'recoil';
import { useCSVDownloader } from 'react-papaparse';

import { FundDetailState } from 'global-stores';
import { useMemo } from 'react';
import { formatCamelCaseHeader } from 'utils';

export const JsonToCSV = () => {
	const [fundDetailList] = useRecoilState(FundDetailState);
	const { CSVDownloader, Type } = useCSVDownloader();

	const fundDetailListData = useMemo(() => {
		const result = fundDetailList.map(
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			({ rawData, fullStatus, ...rest }) => rest
		);
		return result;
	}, [fundDetailList]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const prepareCSVDataWithFormattedHeaders = (data: any) => {
		if (!data.length) return [];

		const headers = Object.keys(data[0]);
		const formattedHeaders = headers.map(formatCamelCaseHeader);

		return data.map((item: Record<string, unknown>) => {
			const formattedItem: Record<string, unknown> = {};
			headers.forEach((header: string, index) => {
				const key = formattedHeaders[index];
				if (key) {
					formattedItem[key] = item[header];
				}
			});
			return formattedItem;
		});
	};

	const formattedData = useMemo(
		() => prepareCSVDataWithFormattedHeaders(fundDetailListData),
		[fundDetailListData, prepareCSVDataWithFormattedHeaders]
	);

	return (
		<>
			{!!fundDetailListData?.length && (
				<CSVDownloader
					type={Type.Button}
					filename={'filename'}
					bom
					data={formattedData}
					className="csv-download"
				>
					Download Captable
				</CSVDownloader>
			)}
		</>
	);
};
