export type IMedia =
	| 'mobile'
	| 'tablet'
	| 'small_laptops'
	| 'large_screens'
	| 'small_mobile';

export const media = (): IMedia => {
	const width = window.innerWidth;
	switch (true) {
		case 320 <= width && width < 481:
			return 'mobile';
		case 481 <= width && width < 768:
			return 'tablet';
		case 769 <= width && width < 1024:
			return 'small_laptops';
		case 1025 <= width:
			return 'large_screens';
		default:
			return 'small_mobile';
	}
};
