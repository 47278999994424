import Accordion from '@storybook/accordion/accordion';
import { FC, ReactNode } from 'react';
import { CustomHandle } from '../custom-handle';
import { ICustomNode } from '../custom-node';

import { useKybForm } from './hooks';
import './kyb-form.scss';

export const KYBFromProvider = ({ children }: { children: ReactNode }) => {
	return <div className="kybForm">{children}</div>;
};

const KybFormBox = ({ nodeId }: { nodeId: string }) => {
	const {
		handleSaveDocument,
		handleSaveEntity,
		handleChange,
		handleSaveDocuments,
		handleChangeDocument,
		handleDocument,
		handleChangeCheckbox,
		handleDeleteDocuments,
		deleteDocument,
		state,
		errorMessage,
	} = useKybForm({ nodeId });

	const renderChoice = (
		el: any,
		item: any,
		parentIndex: number,
		childIndex: number
	) => {
		if (el.status !== 'pending') {
			return (
				<label
					key={`${parentIndex}_${childIndex}`}
					className="sd-selectbase__label"
				>
					<input
						type="checkbox"
						name={item.name}
						checked={el.checked}
						onChange={() =>
							handleChangeCheckbox('child', parentIndex, childIndex)
						}
					/>
					<span className="sv-string-viewer">{el.text}</span>
				</label>
			);
		}
		return (
			<div
				className="document_input_container"
				key={`${parentIndex}_${childIndex}`}
			>
				<input
					className="document_input"
					onChange={e => handleChange(e, parentIndex, childIndex)}
					autoFocus
					placeholder="Enter document"
				/>
				<div className="document_input__icon-wrapper">
					<div
						style={{ fontSize: '20px' }}
						onClick={() => handleSaveDocuments(parentIndex, childIndex)}
					>
						<i className="ri-save-line"></i>
					</div>
					<div
						style={{ fontSize: '20px' }}
						onClick={() => handleDeleteDocuments(parentIndex, childIndex)}
					>
						<i className="ri-delete-bin-6-line"></i>
					</div>
				</div>
			</div>
		);
	};

	return (
		<>
			{state[nodeId]?.elements.map((item: any, index: number) => (
				<Accordion
					key={`${nodeId}_${item.name}_${index.toString()}`}
					isCollapse={item.status !== 'pending'}
					title={
						item.status !== 'pending' ? (
							<div className="accordin-conatiner">
								<input
									type="checkbox"
									style={{ transform: 'scale(1.3)' }}
									checked={item.checked}
									onClick={e => e.stopPropagation()}
									onChange={() => handleChangeCheckbox('parent', index)}
								/>
								<div className="title">{item.title}</div>
							</div>
						) : (
							<>
								<div className="accordin-conatiner">
									<div className="document_input_container">
										<input
											type="checkbox"
											style={{ transform: 'scale(1.3)' }}
											disabled={item.status === 'pending'}
										/>
										<input
											type="text"
											className="document_input"
											onChange={e => handleChangeDocument(e, index)}
											placeholder="Enter document"
										/>

										<div className="document_input__icon-wrapper">
											<div
												style={{ fontSize: '20px' }}
												onClick={() => handleDocument(index)}
											>
												<i className="ri-save-line"></i>
											</div>
											<div
												style={{ fontSize: '20px' }}
												onClick={() => deleteDocument(index)}
											>
												<i className="ri-delete-bin-6-line"></i>
											</div>
										</div>
									</div>
								</div>
							</>
						)
					}
					customStyle={{
						background: '#fff',
						border: '1px solid #DFE3EC',
						borderRadius: '8px',
					}}
					content={
						<div
							className="checkbox-list"
							role="presentation"
							style={{ padding: '8px 32px' }}
						>
							{item.choices.map((el: any, idx: number) => {
								return renderChoice(el, item, index, idx);
							})}
							<div
								className="kybForm__add_document"
								onClick={() => handleSaveDocument(index)}
							>
								<i className="ri-add-circle-line"></i>
								<div className="kybForm__text">Add new Document</div>
							</div>
						</div>
					}
				/>
			))}
			{errorMessage && <p className="kybForm--error">{errorMessage}</p>}
			<div className="kybForm__add_entity" onClick={handleSaveEntity}>
				<i className="ri-add-circle-line"></i>
				<div>Add new Document</div>
			</div>
		</>
	);
};

export const KybAFromAction: FC<ICustomNode> = ({ id: nodeId }) => {
	return (
		<KYBFromProvider>
			<div className="heading">
				Required Document Checklist - Non Individual
			</div>
			<KybFormBox nodeId={nodeId} />
			<CustomHandle value={nodeId} />
		</KYBFromProvider>
	);
};
