type IComponentType = 'select' | 'input' | 'toggle';

interface IRightSideWebProperty {
	id: string;
	name: string;
	type: IComponentType;
	label: string;
}

export const ComponentType = {
	SELECT: 'select',
	INPUT: 'input',
	TOGGLE: 'toggle',
};

export const RightSideKey = {
	DEVELOPER_KEY: 'developerKey',
	BACKGROUND: 'background',
	TABLE_HEAD_COLOR: 'tableHeadColor',
	BTN_PRIMARY: 'btnPrimary',
	BTN_SECONDARY: 'btnSecondary',
	SIDEBAR: 'sidebar',
	// IS_SEARCH: 'isSearch',
	// NOTIFICATION: 'notification',
};

export const RightSideWebProperties: IRightSideWebProperty[] = [
	{
		id: '1',
		name: RightSideKey.DEVELOPER_KEY,
		type: 'select',
		label: 'Developer Key',
	},
	{
		id: '2',
		name: RightSideKey.BACKGROUND,
		type: 'input',
		label: 'Background',
	},
	{
		id: '3',
		name: RightSideKey.TABLE_HEAD_COLOR,
		type: 'input',
		label: 'Table Head Color',
	},
	{
		id: '4',
		name: RightSideKey.BTN_PRIMARY,
		type: 'input',
		label: 'Button Primary Color',
	},
	{
		id: '5',
		name: RightSideKey.BTN_SECONDARY,
		type: 'input',
		label: 'Button Secondary Color',
	},
	// {
	//   id: "6",
	//   name: RightSideKey.IS_SEARCH,
	//   type: "toggle",
	//   label: "Search",
	// },
	{
		id: '7',
		name: RightSideKey.SIDEBAR,
		type: 'toggle',
		label: 'Sidebar',
	},
	// {
	//   id: "8",
	//   name: RightSideKey.NOTIFICATION,
	//   type: "toggle",
	//   label: "Notification",
	// },
];
