import type { IReactModal as Props } from '.';

import Modal from 'react-modal';
import { Button } from '@storybook';
import './modal.scss';

/**

 * ReactModal component
 *
 * @param {boolean} Props.className - append class name for cosmatic changes
 * @param {boolean} Props.isModalOpen - Flag indicating whether the modal is open or closed
 * @param {JSX.Element} Props.body - Content to be displayed inside the modal body
 * @param {any} Props.handleSave - Callback function to handle save action
 * @param {() => void} Props.handleClose - Callback function to handle close action
 * @param {string} Props.header - Header text of the modal
 * @param {boolean} Props.isShowButton - Flag indicating whether to show action buttons
 * @param {string} Props.cancelButtonLabel - Label for the cancel button
 * @param {string | JSX.Element} Props.saveButtonLabel - Label for the save button
 * @param {boolean} Props.isShowCancelButton - Flag indicating whether to show the cancel button
 * @param {boolean} Props.isShowCrossButton - Flag indicating whether to show the close (cross) button
 * @param {boolean} Props.disableButton - Flag indicating whether the buttons should be disabled
 * @param {() => void} Props.handleCross - Callback function to handle the close (cross) button action
 * @param {string} Props.styleClass - Custom class name for styling
 */

export const ReactModal = ({
	isModalOpen,
	body,
	handleSave,
	handleClose,
	header,
	subheading,
	isShowButton = true,
	cancelButtonLabel = 'Cancel',
	saveButtonLabel = 'Save',
	isShowCancelButton = true,
	isShowCrossButton = true,
	disableButton = false,
	handleCross,
	styleClass,
}: Props) => {
	// useEffect(() => {
	//   Modal.setAppElement("body");
	// }, []);

	return (
		<Modal
			shouldCloseOnOverlayClick={false}
			isOpen={isModalOpen}
			onRequestClose={handleClose}
			style={{
				overlay: {
					backgroundColor: 'hsla(0, 0%, 0%, 0.48)',
				},
				content: {
					position: 'absolute',
					top: '40px',
					left: '40px',
					right: '40px',
					bottom: '40px',
					border: '1px solid #ccc',
					background: '#fff',
					WebkitOverflowScrolling: 'touch',
					borderRadius: '8px',
					outline: 'none',
					padding: '24px',
					overflowY: 'auto',
				},
			}}
			className={styleClass ? styleClass : 'react-modal'}
		>
			<div className="model-header">
				<div>
					<span>{header}</span>
					<div className="modal-subheading">{subheading}</div>
				</div>
				<span
					className="cancel-btn"
					onClick={isShowCrossButton ? handleClose : handleCross}
				>
					<i className="ri-close-line " />
				</span>
			</div>
			<div className="modal-body">{body}</div>
			{isShowButton && (
				<div
					className={header !== 'Payment' ? 'modal-btns' : 'modal-card-btns'}
				>
					<div className="btn-container">
						{isShowCancelButton && (
							<Button
								handleClick={handleClose}
								label={cancelButtonLabel}
								type="button__filled button__filled--secondary button__large"
							/>
						)}
						<Button
							handleClick={handleSave}
							label={saveButtonLabel}
							type="button__filled button__filled--primary button__large"
							disabled={disableButton}
						/>
					</div>
				</div>
			)}
		</Modal>
	);
};
