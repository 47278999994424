import { useCallback } from 'react';

export const useCookie = () => {
	const getCookie = useCallback((key: string) => {
		const name = key + '=';
		const decodedCookie = decodeURIComponent(document.cookie);
		const decodeCookie = decodedCookie.split(';');
		for (let i = 0; i < decodeCookie.length; i++) {
			let decodeKey: any = decodeCookie[i];
			while (decodeKey.charAt(0) === ' ') {
				decodeKey = decodeKey.substring(1);
			}
			if (decodeKey.indexOf(name) === 0) {
				return decodeKey.substring(name.length, decodeKey.length);
			}
		}
		return '';
	}, []);

	const get = useCallback(
		(key: string) => {
			try {
				let localData = getCookie(key);
				if (localData) {
					try {
						localData = JSON.parse(localData);
					} catch (err) {
						// do nothing
					}
				}
				return localData;
			} catch (err) {
				return null;
			}
		},
		[getCookie]
	);

	const set = useCallback(
		(key: string, value: any) => {
			try {
				const localData: any = get(key);
				let localValue = {
					...localData,
					...value,
				};
				if (typeof value === 'object') {
					localValue = JSON.stringify(localValue);
				}

				const exdays = 30;
				const newDate = new Date();
				newDate.setTime(newDate.getTime() + exdays * 24 * 60 * 60 * 1000);
				const expires = 'expires=' + newDate.toUTCString();
				document.cookie = key + '=' + localValue + ';' + expires + ';path=/';
				// localStorage.setItem(key, localValue);
			} catch (err) {
				// do nothing
			}
		},
		[get]
	);

	const deleteCookie = useCallback(
		(key: string) => {
			try {
				const localData = getCookie(key); // Check if the cookie exists
				if (localData) {
					try {
						const paths = ['/', '/login', window.location.pathname]; // Define paths to delete from
						paths.forEach((path: string) => {
							document.cookie = `${key}=; Path=${path}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`; // Delete the cookie
						});
					} catch (err) {
						// Silent error handling
					}
				}
			} catch (err) {
				// Silent error handling
			}
		},
		[getCookie]
	);

	const cookieEnabled = useCallback(async () => {
		return new Promise((resolve, reject) => {
			//let isSupported;
			const frame = document.createElement('iframe');
			frame.setAttribute('id', '3pc');
			frame.src = 'https://chamithrepo.github.io/create-third-party-cookie/';
			frame.style.display = 'none';
			frame.style.position = 'fixed';
			document.body.appendChild(frame);
			window.onmessage = event => {
				if (event.data === '3pcSupported' || event.data === '3pcUnsupported') {
					frame.remove();
					resolve(event.data === '3pcSupported');
				} else {
					reject(false);
				}
			};
		});
	}, []);

	return { get, set, deleteCookie, cookieEnabled };
};
