import { IOptionValue, SignOptionState, signOptions } from '../store';

import { useCallback } from 'react';
import { RadioGroup } from '@storybook';
import { useRecoilState } from 'recoil';

export const SignOption = () => {
	const [activeOption, setActiveOption] = useRecoilState<IOptionValue>(SignOptionState);

	const handleChange = useCallback((value: IOptionValue | any) => {
		setActiveOption(value);
	}, [setActiveOption]);

	return (
		<div className="sign-option__wrapper">
			<RadioGroup
				options={signOptions}
				value={activeOption}
				handleChange={handleChange}
			/>
		</div>
	);
};
