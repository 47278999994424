const statusLabel = (status: string | any) => {
	status = status?.toLowerCase();
	switch (status) {
		case 'completed':
			return 'Approved';
		case 'pending':
			return 'Pending';
		case 'processing':
			return 'Under Review';
		case 'rejected':
		case 'failed':
			return 'Rejected';
		default:
			return '';
	}
};

const typeStatus = (status: string | any) => {
	status = status?.toLowerCase();
	switch (status) {
		case 'completed':
			return 'var(--color-green)';
		case 'pending':
			return 'var(--color-text-50-light)';
		case 'processing':
			return 'var(--color-orange)';
		case 'rejected':
		case 'failed':
			return 'var(--color-rejected)';
		default:
			return 'var(--color-border-90-light)';
	}
};

export const renderColor = (key: string, status: string) => {
	switch (key) {
		case 'kyc':
			return {
				color: typeStatus(status),
				status: statusLabel(status),
			};
		case 'kyc(with liveness)':
			return {
				color: typeStatus(status),
				status: statusLabel(status),
			};
		case 'kyb':
			return {
				color: typeStatus(status),
				status: statusLabel(status),
			};
		case 'authentication':
			return {
				color: typeStatus(status),
				status: statusLabel(status),
			};
		case 'aml':
			return {
				color: typeStatus(status),
				status: statusLabel(status),
			};
		case 'fundInvestment':
			return {
				color: typeStatus(status),
				status: statusLabel(status),
			};
		case 'payOut':
			return {
				color: typeStatus(status),
				status: statusLabel(status),
			};
		case 'payIn':
			return {
				color: typeStatus(status),
				status: statusLabel(status),
			};
		case 'signAgreement_esign':
		case 'docusignSignAgreement':
		case 'simpliciSignAgreement':	
			return {
				color: typeStatus(status),
				status: statusLabel(status),
			};
		case 'signAgreement':
			return {
				color: typeStatus(status),
				status: statusLabel(status),
			};
		case '506b':
			return {
				color: typeStatus(status),
				status: statusLabel(status),
			};
		case '506c':
			return {
				color: typeStatus(status),
				status: statusLabel(status),
			};
		case 'form':
			return {
				color: typeStatus(status),
				status: statusLabel(status),
			};
		default:
			return {
				color: '#cccccc',
				status: '',
			};
	}
};
