import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import {
	ChartCompareFirstState,
	ChartCompareSecondState,
	comparedFunnelChartsState,
	funnelChartFirstState,
	funnelChartSecondState,
} from 'views/analytics/hooks';
import { ChartStatus } from '../chart-status';
import { VariablePieChart } from '../charts';
import { calculateTotalSum } from '../utils';
import { device_type_chart_color, lineColor } from './constants';

export const DeviceTypeDetail = () => {
	const comparePreDetail = useRecoilValue(ChartCompareFirstState);
	const compareNextDetail = useRecoilValue(ChartCompareSecondState);
	const comparedChart = useRecoilValue(comparedFunnelChartsState);

	const preFunnelDetail = useRecoilValue(funnelChartFirstState);
	const nextFunnelDetail = useRecoilValue(funnelChartSecondState);

	const chartDefaultPercent = useMemo(() => {
		return comparedChart
			? preFunnelDetail.length > 0 || nextFunnelDetail.length > 0
				? 100
				: 0
			: preFunnelDetail.length > 0
			? 100
			: 0;
	}, [comparedChart, nextFunnelDetail.length, preFunnelDetail.length]);

	const compareDeviceType: any = useMemo(() => {
		const desktopDiff =
			calculateTotalSum(comparePreDetail, 'desktop') -
			calculateTotalSum(compareNextDetail, 'desktop');
		const mobileDiff =
			calculateTotalSum(comparePreDetail, 'mobile') -
			calculateTotalSum(compareNextDetail, 'mobile');
		const tabletDiff =
			calculateTotalSum(comparePreDetail, 'tablet') -
			calculateTotalSum(compareNextDetail, 'tablet');

		const allDevice = desktopDiff + mobileDiff + tabletDiff;
		const desktopValue = (desktopDiff * 100) / allDevice || 0;
		const mobileValue = (mobileDiff * 100) / allDevice || 0;
		const tabletValue = (tabletDiff * 100) / allDevice || 0;

		const data = [
			{
				name: 'Mobile',
				y: mobileValue,
				z: mobileDiff ? 3 : 1,
				diff: mobileDiff,
			},
			{
				name: 'Tablet',
				y: tabletValue,
				z: tabletDiff ? 2 : 1,
				diff: tabletDiff,
			},
			{
				name: 'Desktop',
				y: desktopValue,
				z: 1,
				diff: desktopDiff,
			},
		];
		return data;
	}, [comparePreDetail, compareNextDetail]);
	const deviceType: any = useMemo(() => {
		const desktop = calculateTotalSum(comparePreDetail, 'desktop');
		const mobile = calculateTotalSum(comparePreDetail, 'mobile');
		const tablet = calculateTotalSum(comparePreDetail, 'tablet');
		const allDevice = desktop + mobile + tablet;
		const desktopValue = (desktop * 100) / allDevice || 0;
		const mobileValue = (mobile * 100) / allDevice || 0;
		const tabletValue = (tablet * 100) / allDevice || 0;

		const data = [
			{ name: 'Mobile', y: mobileValue, z: mobile ? 3 : 1, diff: mobile },
			{
				name: 'Tablet',
				y: tabletValue,
				z: tablet ? 2 : 1,
				diff: tablet,
			},
			{
				name: 'Desktop',
				y: desktopValue,
				z: 1,
				diff: desktop,
			},
		];
		return data;
	}, [comparePreDetail]);
	const deviceChartRender = useMemo(() => {
		return (
			<div className="analytics-wrapper__pie">
				<VariablePieChart
					isLoading={false}
					title="Device Type"
					seriesColor={device_type_chart_color}
					seriesData={comparedChart ? compareDeviceType : deviceType}
					chartName="Devices"
					chartPercent={chartDefaultPercent}
				/>
				<div className="analytics-wrapper__line-wrapper">
					{(comparedChart ? compareDeviceType : deviceType).map((item: any) => (
						<div key={item.name}>
							<ChartStatus
								percent={item.y}
								title={item.name}
								color={lineColor[item.name]}
								diff={item.diff}
							/>
						</div>
					))}
				</div>
			</div>
		);
	}, [chartDefaultPercent, compareDeviceType, comparedChart, deviceType]);
	return <>{deviceChartRender}</>;
};
