import { Fragment, useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { Loader } from '@storybook';
import {
	BillingSummary,
	SignatureProvider,
	useGetInviteOnboardingPayment,
} from '../../store';
import { useSandboxAndFreeTrial } from 'hooks';
import { useOnboardingHook } from 'views/onboarding-flow';
import { SelectedPipelineDetails } from 'global-stores';

import './order-summary.scss';

const stepName: any = {
	kycwithssn: 'SSN Verification',
	kycwithaddressverification: 'Address Verification',
};

export const OrderSummary = () => {
	const { data: summary, isLoaded } = useRecoilValue(BillingSummary);
	const { invitedUsers } = useGetInviteOnboardingPayment();
	const pipelineDetails = useRecoilValue(SelectedPipelineDetails);

	const { isSandboxMode } = useSandboxAndFreeTrial();
	const { isPricingHide } = useOnboardingHook();


	const checkAmlFrequency = useMemo(() => {
		// Return false immediately if pipelineDetails or nodes are undefined/null
		if (!pipelineDetails?.nodes) return false;

		// Check if there is any node with stepId 'aml' and its actions contain an action
		// with actionId 'amlVerification' where the metadata.amlFrequency equals 'dailyOnGoing'.
		return pipelineDetails.nodes.some(
			(node: {
				stepId: string;
				actions?: { actionId: string; metadata?: { amlFrequency?: string } }[];
			}) =>
				// Ensure the node matches the 'aml' stepId
				node?.stepId === 'aml' &&
				// Check if any action within the node matches the conditions
				node?.actions?.some(
					action =>
						// Ensure the actionId matches 'amlVerification'
						action?.actionId === 'amlVerification' &&
						// Verify that amlFrequency in metadata is 'dailyOnGoing'
						action?.metadata?.amlFrequency === 'dailyOnGoing'
				)
		);
	}, [pipelineDetails]);

	const isAmlDailyOnGoing = useCallback(
		(name: string) => name?.toLowerCase()?.includes('aml') && checkAmlFrequency,
		[checkAmlFrequency]
	);

	const getStepName = useCallback((name: string) => {
		if (/docusign/gi.test(name)) {
			return 'Sign agreement (Docusign)';
		}
		if (/esign|simplici/gi.test(name)) {
			return (
				<div className="summary-item__headers-esign">
					Sign Agreement (esign)
				</div>
			);
		}
		if (/Authentication|simplici/gi.test(name)) {
			return;
		}
		if (/proofReading/gi.test(name)) {
			return 'Document Review';
		}
		if (/kyb_comprehensive/gi.test(name)) {
			return 'KYB Comprehensive';
		}
		if (/business_sanctions_ofac/gi.test(name)) {
			return 'Business Sanctions and OFAC';
		}
		if (/kyb_subsidiary/gi.test(name)) {
			return 'KYB for Subsidiaries';
		}
		if (/representative_kyc_aml_verification/gi.test(name)) {
			return 'Representative KYC & AML Verification';
		}
		if (/kybForm/gi.test(name)) {
			return 'KYB Form';
		}
		if (isAmlDailyOnGoing(name)) {
			return 'Daily on Going AML';
		}
		return stepName[name?.toLowerCase()] ?? name;
	}, [isAmlDailyOnGoing]);

	const renderSummaryItems = useMemo(() => {
		if (!isLoaded) {
			return (
				<div className="loader_container">
					<Loader className="loader-blue" dimension={100} />
				</div>
			);
		}
		return summary.steps.map(({ name, price }) => (
			<Fragment key={name}>
				{price >= 0 && (
					<div className="order-summary__summary__summary-item">
						<div className="summary-item__headers">
							<div className="summary-item__headers__name">
								{getStepName(name)}
								{name === SignatureProvider.fundInvestment &&
									`(${summary?.fund?.type})`}
								{isPricingHide && name !== 'Authentication' && (
									<span className="summary-item__price">
										: ${!isSandboxMode ? Number(price).toFixed(2) : 0}
										{isAmlDailyOnGoing(name) ? '/Per year' :'/Verification'}
									</span>
								)}
							</div>
						</div>
					</div>
				)}
			</Fragment>
		));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoaded, summary?.fund?.type, summary.steps, isPricingHide , isAmlDailyOnGoing]);

	return (
		<div className="order-summary-wrapper">
			<div className="order-summary__summary">
				<div className="order-summary__summary__heading">Order Summary</div>
				<div className="order-summary__summary__summary-wrapper">
					{renderSummaryItems}
				</div>
			</div>
			{isLoaded && (
				<div className="order-summary__have-to-pay-wrapper">
					<div className="have-to-pay__messege summary-item__no_verification">
						No. of verifications{' '}
						<span className="have-to-pay__price">x{invitedUsers}</span>
					</div>
					{isPricingHide && (
						<div className="order-summary__have-to-pay">
							<div className="have-to-pay__icon-wrapper">
								<i className="ri-newspaper-fill" />
								<div className="have-to-pay__messege">You have to pay</div>
							</div>
							<div className="have-to-pay__text-wrapper">
								<div className="have-to-pay__price">
									{isSandboxMode
										? 'FREE'
										: `$ ${(summary.totalPrice * invitedUsers).toFixed(2)}`}
								</div>
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
};
