import { IDate } from './types'; // Importing IDate type

import { useCallback, useMemo } from 'react'; // React hooks for memoization and performance
import { useRecoilState } from 'recoil'; // Recoil for global state management

import { ReminderDeadLineState } from './states'; // Reminder deadline Recoil state

// Define the current date and minimum selectable date (next day)
const currentDate = new Date();
const minimumDate = new Date(currentDate.getTime() + 1 * 24 * 60 * 60 * 1000); // Next day from current date
const minDay = minimumDate.getDate();
const minMonth = minimumDate.getMonth() + 1; // Months are zero-based, so we add 1
const minYear = minimumDate.getFullYear();

// Custom hook to handle reminder deadline logic
export const useReminderDeadLine = () => {
	// Recoil state for the date value and potential error messages
	const [dateValue, setDateValue] = useRecoilState(ReminderDeadLineState);

	// Function to validate the selected date against the minimum date
	function isValidDate(value: IDate) {
		// Return true if no value is provided
		if (
			!value ||
			(isNaN(value.day) && isNaN(value.month) && isNaN(value.year))
		) {
			return true;
		}

		// Check if any of the day, month, or year is invalid
		if (isNaN(value.day) || isNaN(value.month) || isNaN(value.year)) {
			return false;
		}

		// Create Date objects for the provided value and the minimum date
		const valueDate = new Date(value.year, value.month, value.day);
		const minimumDate = new Date(minYear, minMonth, minDay);

		// If the constructed date is invalid, return false
		if (isNaN(valueDate.getTime())) {
			return false;
		}

		// Ensure the selected date is greater than or equal to the minimum date
		return valueDate.getTime() >= minimumDate.getTime();
	}

	// Function to handle changes to the date input
	const handleDateChange = useCallback(
		(e: any) => {
			const { value } = e?.target ?? {};
			const { month, day, year } = value ?? {};

			// Update the date value in Recoil state
			setDateValue(pre => ({
				// Use the previous state to create a new state object
				...pre,

				// Format the date as 'mm-dd-yyyy'
				value: `${month.toString().padStart(2, '0')}-${day
					.toString()
					.padStart(2, '0')}-${year}`, // Pad month and day with leading zeros if needed

				// Clear any existing error message
				errorMessage: '',
			}));

			// If the new date is invalid, set an error message
			if (!isValidDate(value)) {
				setDateValue(pre => ({
					...pre,
					errorMessage:
						'Invalid Date, Deadline should be greater than or equals to ' +
						`${minMonth}/${minDay}/${minYear}`,
				}));
			}
		},
		[setDateValue] // Memoize the function to avoid unnecessary re-renders
	);

	// Memoized value to format the selected date
	const getDate = useMemo(() => {
		// If no date value is set, return null
		if (!dateValue?.value) return null;

		// Destructure and parse the date value (month-day-year)
		const [month = 0, day = 0, year = 0] = dateValue.value.split(/[-/]/);

		// If any part of the date is invalid, return undefined
		if (isNaN(+year) || isNaN(+month) || isNaN(+day)) {
			return;
		}

		// Return the parsed date object
		const newDate = {
			day: +day,
			month: +month,
			year: +year,
		};
		return newDate;
	}, [dateValue?.value]); // Recalculate only when dateValue changes

	const customDateOptions = [
		{
			key: 'tomorrow',
			label: 'Tomorrow', // Label to display in the dropdown
			value: 1, // Represents 1 day from today
		},
		{
			key: '7_day',
			label: 'After 7 days', // Label to display in the dropdown
			value: 7, // Represents 7 days from today
		},
		{
			key: '30_days',
			label: 'After 30 days', // Label to display in the dropdown
			value: 30, // Represents 30 days from today
		},
		{
			key: 'quarter',
			label: 'After 90 days', // Label to display in the dropdown
			value: 90, // Represents 90 days (approximately one quarter) from today
		},
		{
			key: 'year',
			label: 'After one year', // Label to display in the dropdown
			value: 365, // Represents 365 days (one year) from today
		},
	];

	// Return the necessary values and functions for the component
	return {
		minDate: {
			year: minYear,
			month: minMonth,
			day: minDay, // Minimum selectable date is the day after today
		},
		handleDateChange, // Function to handle date changes
		errorMessage: dateValue?.errorMessage, // Error message to display
		getDate, // Memoized function to get the formatted selected date
		customDateOptions, // Array of custom date options to be shown alongside the calendar
	};
};
