import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { capitalize } from 'utils';
import {
	NodesState,
	isComplexViewOnlyState,
} from 'views/complex-onboarding-flow/stores/states';
import { AddConditionButton } from '../add-condition-button';
import { ConnectorThread } from '../connector';
import { IOption } from '../dropdown';
import { QuestionLabel } from '../question-label';
import { KycQuestionWrapper } from './kyc-questions';

export interface IRenderQuestion {
	labelId: string;
	stepId: string;
	nodeId: string;
	blockId: string;
}
export const RenderQuestion: FC<IRenderQuestion> = ({
	labelId,
	stepId,
	nodeId,
	blockId,
}) => {
	const [connect, setConnect] = useState('and');
	const [nodes, setNodes] = useRecoilState(NodesState);
	const isComplexViewOnly = useRecoilValue(isComplexViewOnlyState);

	const { conditions: kycActionNodes } = useMemo(
		() => nodes.find((el: any) => el.id === nodeId),
		[nodeId, nodes]
	);

	const handleChangeSelect = useCallback(
		(val: IOption) => {
			setNodes((prev: any) => {
				const prevObj = JSON.parse(JSON.stringify(prev));
				const findIndex = prevObj.findIndex((el: any) => el.id === nodeId);
				if (findIndex !== -1) {
					const index = prevObj[findIndex].conditions.findIndex(
						(el: any) =>
							el.currentId === nodeId &&
							el.blockId === blockId &&
							el.labelId === labelId
					);

					if (index !== -1) {
						const prevValue = prevObj[findIndex].conditions[index][connect];
						const payload = {
							[val.value]: prevValue,
						};
						Object.assign(prevObj[findIndex].conditions[index], payload);
						if (val.value !== connect) {
							delete prevObj[findIndex].conditions[index][connect];
						}
					}
				}

				return prevObj;
			});

			setConnect(val.value);
		},
		[setNodes, nodeId, blockId, labelId, connect]
	);

	const addNewQuestion = useCallback(() => {
		setNodes((prev: any) => {
			const prevObj = JSON.parse(JSON.stringify(prev));
			const findIndex = prevObj.findIndex((el: any) => el.id === nodeId);
			if (findIndex !== -1) {
				const findBlockIndex = prevObj[findIndex].conditions.findIndex(
					(el: any) =>
						el.labelId === labelId &&
						el.blockId === blockId &&
						el.currentId === nodeId
				);
				if (findBlockIndex !== -1) {
					prevObj[findIndex].conditions[findBlockIndex][connect]?.push({});
				}
			}
			return prevObj;
		});
	}, [setNodes, nodeId, labelId, blockId, connect]);

	const kycQuestionList = kycActionNodes.find(
		(el: any) =>
			el.currentId === nodeId &&
			el.blockId === blockId &&
			el.labelId === labelId
	)[connect];

	const onRemoveBlock = useCallback(
		(blockId: string) => () => {
			setNodes((prev: any) => {
				const prevObj = JSON.parse(JSON.stringify(prev));
				const findIndex = prevObj.findIndex((el: any) => el.id === nodeId);
				if (findIndex !== -1) {
					const filterdData = prevObj[findIndex].conditions.filter(
						(el: any) => !(el.labelId === labelId && el.blockId === blockId)
					);
					prevObj[findIndex].conditions = filterdData;
				}
				return prevObj;
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[nodeId, labelId]
	);

	useEffect(() => {
		if (!isComplexViewOnly && kycQuestionList?.length === 0) addNewQuestion();
	}, [addNewQuestion, isComplexViewOnly, kycQuestionList?.length]);

	const questionProps = {
		labelId,
		stepId,
		nodeId,
		blockId,
		questionLen: kycQuestionList?.length,
		connect,
	};

	useEffect(() => {
		const data = kycActionNodes?.find(
			(el: any) => el.currentId == nodeId && el.blockId === blockId
		);
		if (Object.keys(data ?? {}).includes('or')) {
			setConnect('or');
		}
	}, [blockId, kycActionNodes, nodeId]);

	// Rahul Singh: to get the operand for nationality
	const thanValue =
		kycActionNodes.find(
			(el: any) =>
				el.currentId === nodeId &&
				el.blockId === blockId &&
				el.labelId === labelId
		)?.then ?? '';

	const getDefaultOperand = useCallback(
		(index: number) => {
			return (
				kycActionNodes.find(
					(el: any) =>
						el.currentId === nodeId &&
						el.blockId === blockId &&
						el.labelId === labelId
				)?.[connect]?.[index]?.operand ?? ''
			);
		},
		[blockId, connect, kycActionNodes, labelId, nodeId]
	);

	return (
		<div className="question-list">
			<QuestionLabel blockId={blockId} onRemoveBlock={onRemoveBlock} />
			{kycQuestionList?.map((item: string, index: number) => {
				return (
					<Fragment key={`${item}_${index.toString()}`}>
						<KycQuestionWrapper
							{...questionProps}
							index={index}
							conditionId={item}
							questionData={item}
							defaultThenValue={thanValue}
							defaultOperandValue={getDefaultOperand(index)}
						/>
						{kycQuestionList?.length !== index + 1 && (
							<ConnectorThread
								handleChangeSelect={handleChangeSelect}
								value={{ label: capitalize(connect), value: connect }}
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								defaultValue={{ label: capitalize(connect), value: connect }}
								// Isdisable={!!selectedValue}
							/>
						)}
					</Fragment>
				);
			})}
			<AddConditionButton onClick={addNewQuestion} />
		</div>
	);
};
