import { FC } from 'react';
import { Image, Loader } from '@storybook';

import './archiveModal.scss';

interface IModal {
	visible: boolean;
	title: string;
	description?: string;
	boldDescription?: string;
	handleModal: (isOpen: boolean, value: boolean) => void;
	label: string | JSX.Element;
	disabled?: boolean;
	showLoader: boolean;
}

export const ArchiveConfirmationModal: FC<IModal> = ({
	visible,
	title,
	description,
	handleModal,
	boldDescription = '',
	label,
	disabled,
	showLoader,
}) => {
	if (!visible) {
		return null;
	}

	return (
		<div className="modal-main" onClick={() => handleModal(false, false)}>
			<div className="modal-content" onClick={e => e.stopPropagation()}>
				<div className="confirmation-header">
					<Image
						fileName="confirmation_page.svg"
						className="confirmation-image"
					/>
					<div className="modal-title">{title}</div>
				</div>
				<div>
					<div className="modal-des">
						<span>
							{description}
							<strong>{boldDescription}</strong>
						</span>
					</div>
					<div className="modal-footer">
						<button
							className="button-cancel"
							onClick={() => handleModal(false, false)}
						>
							Cancel
						</button>
						<button
							className="button-ok button-sumbit"
							onClick={() => handleModal(false, true)}
							disabled={disabled}
						>
							{showLoader ? (
								<Loader type="loader" className="loader-pink" dimension={22} />
							) : (
								label
							)}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
