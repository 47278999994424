import { Image } from '@storybook';

export const EmptyScreen = () => {
	return (
		<div className="empty-wrapper">
			<div className="empty-wrapper__inner">
				<Image fileName="no-action-settings.svg" />
				<div className="coming-soon-text">coming soon</div>
			</div>
		</div>
	);
};
