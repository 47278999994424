import { FC, ReactNode, useMemo } from 'react';

import { labelData } from 'views/complex-onboarding-flow/constant';
import { useKycActionState } from 'views/complex-onboarding-flow/hooks';
import { CustomHandle } from '../custom-handle';
import { ICustomNode } from '../custom-node';
import { RenderBlock } from './render-block';

export const KycWrapper = ({ children }: { children: ReactNode }) => {
	return <div className="kyc-action">{children}</div>;
};

const KycAction: FC<ICustomNode> = ({ id: nodeId, data }) => {
	const { StepIds, kycStepId } = useKycActionState(data.label, nodeId);
	const actionId = useMemo(() => data.label, [data.label]);

	const renderActionLayout = useMemo(
		() =>
			StepIds?.map(({ labelId }: { labelId: string }) => (
				<RenderBlock
					stepId={kycStepId}
					label={labelData[labelId]}
					key={labelId}
					labelId={labelId}
					nodeId={nodeId}
					actionId={actionId}
				/>
			)),
		[StepIds, kycStepId, nodeId, actionId]
	);

	return (
		<KycWrapper>
			<CustomHandle value={nodeId} />
			{renderActionLayout}
		</KycWrapper>
	);
};

export default KycAction;
