// packages import
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Input, ReactDropdown } from '@storybook';

// local components
import {
	FundsState,
	IsCreatingFundState,
	SelectedFundType,
	SelectedInviteFundState,
} from 'global-stores';
import { FundsIdState } from './store';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'routes';

interface IInviteChooseFund {
	selectedFundItem?: {
		label: string;
		value: string;
	};
}
export const InviteChooseFund: FC<IInviteChooseFund> = ({
	selectedFundItem,
}) => {
	// global states
	const funds = useRecoilValue(FundsState);
	const [fundType, setFundType] = useRecoilState(SelectedFundType);
	const [selectedFund, setSelectedFund] = useRecoilState(
		SelectedInviteFundState
	);
	const setFundsIdState = useSetRecoilState(FundsIdState);
	const isNewFund = useRecoilValue(IsCreatingFundState);

	// local states
	const [fundsList, setFundsList] = useState([]);

	// hooks
	const location = useLocation();

	// local variables
	const defaultFund = useMemo(() => {
		if (funds?.length > 0) {
			return { value: `${funds[0]?.investor}`, label: funds[0]?.name };
		}
		return null;
	}, [funds]);

	useEffect(() => {
		if (selectedFundItem) {
			setSelectedFund(selectedFundItem);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// this will select the fund from the dropdown
	const handleSelectDrop = useCallback(
		(item: any) => {
			const escrowAccount: any = funds.find(
				val => val.id === item.value
			)?.escrowAccounts;
			setFundsIdState({
				fundId: item.value,
				hasEscrowLink: escrowAccount?.length > 0 ? true : false,
			});
			setSelectedFund(item);
		},
		[setSelectedFund, setFundsIdState, funds]
	);

	// this will formate the funds list as per the dropdown requirements
	useEffect(() => {
		const options: any = [];
		funds.forEach((item: any) => {
			options.push({ label: item.name, value: item.id ?? item._id });
		});
		setFundsList(options);
	}, [funds]);

	const handleSetCreate = useCallback(() => {
		setFundType('create');
		if (fundType === 'edit') {
			setSelectedFund({
				label: '',
				value: '',
			});
		}
	}, [fundType, setFundType, setSelectedFund]);

	const hasFundRoute = useMemo(
		() => location?.pathname.includes(ROUTES.FUNDS),
		[location]
	);

	return (
		<div className="OnboardingInviteModal-body">
			<div
				className={`OnboardingInviteModal-body-sub ${
					!hasFundRoute && 'edit-modal-sub'
				}`}
			>
				<div
					className={`OnboardingInviteModal-create ${
						fundType === 'create' ? 'active-box' : ''
					}`}
					onClick={handleSetCreate}
				>
					<div className="OnboardingInviteModal-create-top">
						<input
							type="radio"
							id="create-fund"
							className="OnboardingInviteModal-create-radio"
							checked={fundType === 'create'}
						/>
						<div className="OnboardingInviteModal-create-labels">
							<div className="OnboardingInviteModal-create-label">
								Create Fund/Company
							</div>
							<div className="OnboardingInviteModal-create-desc">
								Create New Fund/Company for Investment
							</div>
						</div>
					</div>
					{fundType === 'create' && (
						<Input
							label="Fund Name"
							inputType="text"
							placeholder="Name"
							value={selectedFund.value}
							maxLength={40}
							handleChange={e =>
								setSelectedFund(preState => ({
									...preState,
									value: e.target.value,
								}))
							}
						/>
					)}
				</div>
				{!isNewFund && (
					<div
						className={`OnboardingInviteModal-create ${
							fundType === 'edit' ? 'active-box' : ''
						}`}
						onClick={() => setFundType('edit')}
					>
						<div className="OnboardingInviteModal-create-top">
							<input
								type="radio"
								id="select-fund"
								checked={fundType === 'edit'}
								className="OnboardingInviteModal-create-radio"
							/>
							<div className="OnboardingInviteModal-create-labels">
								<div className="OnboardingInviteModal-create-label">
									Select Fund/Company
								</div>
								<div className="OnboardingInviteModal-create-desc">
									Select Fund/Company for Investment
								</div>
							</div>
						</div>
						{fundType === 'edit' && (
							<div className="OnboardingInviteModal-edit">
								<div className="input__label">Drop down</div>
								<ReactDropdown
									options={fundsList}
									optionsStyle={{
										'&:hover': { background: 'var(--color-hover-light-100)' },
									}}
									value={
										selectedFund.label === '' ? null : (selectedFund as any)
									}
									defaultValue={defaultFund as any}
									handleChangeSelect={handleSelectDrop}
									isSearchable
									placeholder="Select or Search"
								/>
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
};
