import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { Button, Image, Loader } from '@storybook';
import { loginState } from 'global-stores';
import { SessionOutTimer, ShowWarningPopup } from './store';
import { useLogout } from 'hooks';
import Modal from '@storybook/new-modal/modal';

import './session-out.scss';

interface ISessionOutModal {
	isOpen: boolean;
	closeModal: () => void;
}

export const SessionOutModal: FC<ISessionOutModal> = ({
	isOpen,
	closeModal,
}) => {
	const resetTimer = useResetRecoilState(SessionOutTimer);
	const resetPopup = useResetRecoilState(ShowWarningPopup);
	const { logout } = useLogout();
	const { loggedIn } = useRecoilValue(loginState);
	const [loggingOut, setLoggingOut] = useState(false);

	// Close modal when logged out
	useEffect(() => {
		if (!loggedIn) closeModal();
	}, [loggedIn, closeModal]);

	const handlePrimaryBtnCallback = useCallback(() => {
		resetTimer();
		resetPopup();
	}, [resetTimer, resetPopup]);

	const handleSecondaryBtnCallback = useCallback(() => {
		setLoggingOut(true);
		logout();
		resetTimer();
		closeModal();
	}, [logout, resetTimer, closeModal]);

	const logoutLabel = useMemo(
		() => loggingOut ? <Loader type="loader" dimension={18} className="loader-blue" /> : 'Logout',
		[loggingOut]
	);

	const modalContent = useMemo(() => (
		<div className="session-out">
			<div className="session-out__image">
				<Image fileName="Confirmation.svg" />
			</div>
			<div className="session-out__text">
				<p className="session-out__text__heading">Are you still here?</p>
				<p className="session-out__text__subheading">
					It seems you have been inactive for a while. Click Continue to stay
					logged in, or Log Out if you are done for now.
				</p>
			</div>
			<div className="session-out__btn">
				<Button
					label={logoutLabel}
					type="button__filled--secondary"
					handleClick={handleSecondaryBtnCallback}
					disabled={loggingOut}
				/>
				<Button
					label={'Continue'}
					type="button__filled--primary"
					handleClick={handlePrimaryBtnCallback}
					disabled={loggingOut}
				/>
			</div>
		</div>
	), [logoutLabel, handleSecondaryBtnCallback, handlePrimaryBtnCallback, loggingOut]);

	return (
		<Modal
			isOpen={isOpen}
			modalName="session-out-modal"
			closeModal={closeModal}
			showCloseBtn
			isStopOutsideClick={false}
			className="session-out-modal"
		>
			{modalContent}
		</Modal>
	);
};
