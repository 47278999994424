import { FC, useMemo, useState } from 'react';
import { ReactDropdown } from '@storybook';

import { IKybDetails } from 'global-stores/sessions/type';
import { SessionDetailsSkeletonLoader } from 'views/sessions/session-details-loader';
import { ActionButton } from '../action';
import { SessionDetailsHeading } from '../session-details-heading';
import { KYBDownloadOption, SessionStatus } from './constant';
import { KybLogo } from './components/kyb-logo';
import {
	EmptyKybSection,
	KybEnchancedDetails,
	KybTypeSelectorSection,
} from './components';
import { useSessionDetailsActions } from 'views/sessions/hooks/use-session-actions';
import { KybComprehensiveCompanyDetails } from './components/kyb-comperhensive-details';

import './kyb-wrapper.scss';
interface IKYB {
	kyb: any;
	isLoading: boolean;
	isLoaded: boolean;
	nodeId?: string;
	isUserPermissionWrite?: boolean;
	role?: string;
	isAgentPermitted?: boolean | undefined;
}

const { Approved, Failed, Rejected } = SessionStatus;

export const KybWrapper: FC<IKYB> = ({
	kyb,
	isLoading,
	isLoaded,
	nodeId,
	isUserPermissionWrite = true,
	role,
	isAgentPermitted,
}) => {
	const { data, status = ''  } = useMemo(() => kyb?.[0] ?? {}, [kyb]);
	const { configurations } = data?.companyDetails ?? {};
	const [selectedKybType, setSeletedKybType] = useState(
		configurations?.[0] ?? ''
	);

	const KYBLabelDataKeys = useMemo(() => Object.keys(kyb?.[0] ?? {}), [kyb]);

	const isStatusApprovedOrRejected = useMemo(() => {
		return [Approved, Rejected, Failed].includes(status.toLowerCase());
	}, [status]);

	const { exportToJson } = useSessionDetailsActions();

	const handleChangeSelect = (event: any) => {
		const { value } = event;
		if (value === 'json') {
			exportToJson(kyb, 'KYb.json');
		}
	};

	// For default kyb tab select
	const handleKybType = () => {
		if (!configurations || configurations.length === 0) {
			// Handle the case when configurations are not available
			return '--';
		} else return configurations[0];
	};

	return (
		<div className="kyb">
			<div className="kyb-wrapper">
				<SessionDetailsHeading
					label="KYB"
					icon={<i className="ri-building-fill kyb-wrapper__header_icon" />}
				>
					{KYBLabelDataKeys?.length > 0 && (
						<div className="kyb-wrapper_download-wrapper">
							<ReactDropdown
								options={KYBDownloadOption}
								value={{ label: 'Download', value: 'download' }}
								defaultValue={{ label: 'Download', value: 'download' }}
								handleChangeSelect={handleChangeSelect}
								controlStyle={{ minHeight: 40, width: 200 }}
							/>
							<div className="action-btn">
								{role === 'AGENT' && !isAgentPermitted ? (
									<div className="action-btn__agent">{status}</div>
								) : (
									<ActionButton
										status={status}
										type="kyb"
										nodeId={nodeId}
										isDisabled={
											!isUserPermissionWrite || isStatusApprovedOrRejected
										}
									/>
								)}
							</div>
						</div>
					)}
				</SessionDetailsHeading>

				<div className="kyb-wrapper__details kyb-wrapper__details-wrapper">
					<KybLogo
						item={data?.companyDetails?.enhanced?.report as IKybDetails}
					/>
				</div>

				<KybTypeSelectorSection
					configurations={configurations}
					selectedKybType={selectedKybType}
					setSelectedKybType={item => setSeletedKybType(item)}
				/>

				{!isLoading && isLoaded ? (
					<>
						{kyb && kyb[0]?.data?.companyDetails ? (
							<>
								{selectedKybType === handleKybType() ? (
									<KybEnchancedDetails kyb={kyb} />
								) : (
									<KybComprehensiveCompanyDetails
										data={data}
										selectedKybType={selectedKybType}
									/>
								)}
							</>
						) : (
							<EmptyKybSection message={data?.error} />
						)}
					</>
				) : (
					<SessionDetailsSkeletonLoader />
				)}
			</div>
		</div>
	);
};
