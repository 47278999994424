import { useCallback, useMemo } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { IOption } from '@storybook';

import { PathOptions, StatusOptions } from '../constants';
import { IChoice, IQuestionnaireQuestion } from '../questionnaire-question';
import { AllowedQuestionnaire } from '../../questionnaire-choice';
import {
	ComplexDynamicFormPathsState,
	ComplexDynamicFormStatusState,
	EdgesState,
	NodesState,
} from '../../../../../stores/states';
import { useComplexLayout } from '../../../../../hooks';

export const useQuestionnaireQuestion = ({
	element,
	id: nodeId,
	page,
}: IQuestionnaireQuestion) => {
	// globle states
	const setNodes = useSetRecoilState(NodesState);
	const [edges] = useRecoilState(EdgesState);
	const [paths, setPaths] = useRecoilState(ComplexDynamicFormPathsState);
	const [status, setStatus] = useRecoilState(ComplexDynamicFormStatusState);

	// hooks
	const { handleDeleteEdges } = useComplexLayout();

	const { type, name, choices, labelFalse, labelTrue } = element ?? {};

	const getSourceHandle = useCallback(
		(choice: string) => `${nodeId}_${page}_${name}_${choice}`,
		[name, nodeId, page]
	);

	const getChoices = useMemo(() => {
		if (type === AllowedQuestionnaire.boolean) {
			return [
				{
					text: labelTrue ?? 'Yes',
					value: true,
				},
				{ text: labelFalse ?? 'No', value: false },
			];
		} else {
			return choices;
		}
	}, [choices, labelFalse, labelTrue, type]);

	const addCondition = useCallback(
		(choice: IChoice, status: string | undefined) => {
			const key = getSourceHandle(choice?.value ?? choice);
			setNodes((prev: any) => {
				const prevObj = JSON.parse(JSON.stringify(prev));
				const findIndex = prevObj.findIndex((el: any) => el.id === nodeId);
				if (findIndex !== -1) {
					const choiceIndex = prevObj[findIndex].conditions?.findIndex(
						(choosedItem: { key: string }) => choosedItem.key === key
					);
					const conditionPayload = {
						currentId: nodeId,
						page: page,
						question: name,
						key: key,
						choice: choice?.value ?? choice,
						status: status ?? StatusOptions[1]?.value,
					};
					if (choiceIndex !== -1) {
						prevObj[findIndex].conditions[choiceIndex] = {
							...prevObj[findIndex].conditions[choiceIndex],
							...conditionPayload,
						};
					} else {
						prevObj[findIndex].conditions.push({
							...conditionPayload,
						});
					}
					return prevObj;
				}
				return prevObj;
			});
		},
		[getSourceHandle, name, nodeId, page, setNodes]
	);

	const handleStatusChange = useCallback(
		(e: IOption, choice: IChoice) => {
			const key = getSourceHandle(choice?.value ?? choice);
			setStatus(pre => ({
				...pre,
				[key]: e,
			}));
			addCondition(choice, e.value);
		},
		[addCondition, getSourceHandle, setStatus]
	);

	const handlePathChange = useCallback(
		(e: IOption, choice: IChoice) => {
			const key = getSourceHandle(choice?.value ?? choice);
			const selectedStatus = status[key];
			setPaths(pre => ({
				...pre,
				[key]: e,
			}));
			if (e.value === PathOptions[0]?.value) {
				const edgeId = edges?.find(
					(edge: any) => edge.sourceHandle === key
				)?.id;
				if (edgeId) handleDeleteEdges(edgeId);
			}
			if (e.value === PathOptions[1]?.value) {
				addCondition(choice, selectedStatus?.value);
			}
		},
		[addCondition, edges, getSourceHandle, handleDeleteEdges, setPaths, status]
	);
	return {
		getSourceHandle,
		getChoices,
		handleStatusChange,
		handlePathChange,
		paths,
		status,
	};
};
