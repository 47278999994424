import { FC, Fragment, useCallback, useEffect, useMemo } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Button, Loader, ReactSwitch } from '@storybook';
import Modal from '@storybook/new-modal/modal';
import { SessionState } from 'global-stores';

import {
	CONFIGURE_SESSION_KEYS_VALUE,
	PAYLOAD_CONFIGURE_SESSION_KEYS_VALUE,
} from './constant';
import {
	IConfigureSession,
	useSelectedConfugureSessionRequests,
} from './stores';
import { ConfigureSelectedToggleKey } from './stores/states';

import './configure-session.scss';

export const ConfigureSession: FC<IConfigureSession> = ({
	isOpen = false,
	selectedSessionId = '',
	handleClose,
}) => {
	const [state, setState] = useRecoilState(ConfigureSelectedToggleKey);
	const sessionList = useRecoilValue(SessionState);
	const { submitConfugureSession, isLoaded: isConfigureLoaded } =
		useSelectedConfugureSessionRequests();

	const sessionObj: any = useMemo(
		() => sessionList.find(item => item._id === selectedSessionId),
		[selectedSessionId, sessionList]
	);

	const allKeys = useMemo(() => {
		const totalConfig = Object.keys(sessionObj?.configuration ?? {});
		if (totalConfig.length) {
			return (
				totalConfig.map(item => {
					return { value: sessionObj?.configuration[item], label: item };
				}) ?? []
			);
		}
		return (
			(Object.keys(sessionObj?.fullStatus ?? {}) ?? []).map(item => {
				return { value: true, label: item };
			}) ?? []
		);
	}, [sessionObj?.configuration, sessionObj?.fullStatus]);

	useEffect(() => {
		if (allKeys.length !== 0 && !Object.keys(state)?.length) {
			const newArrkeys = Object.assign(
				{},
				...allKeys.map((item: any) => ({ [item.label]: item.value }))
			);
			setState(newArrkeys);
		}
		// eslint-disable-next-line
	}, [allKeys]);

	const handleChange = useCallback(
		(e: boolean, label: any) => {
			setState(prev => ({ ...prev, [label]: e }));
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const handleCloseModal = useCallback(() => {
		handleClose();
		setState({});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleClose]);

	const handleSubmit = useCallback(() => {
		const sessionId = sessionObj?._id;
		const actions: { [key: string]: string } = {};
		(Object.keys(sessionObj?.fullStatus ?? {}) ?? []).forEach(item => {
			if (item === PAYLOAD_CONFIGURE_SESSION_KEYS_VALUE.KycWithLiveness) {
				actions['kyc'] = (state as any)[item];
			} else {
				actions[item] = (state as any)[item];
			}
		});
		delete actions.form; // tobe remove leter by pradeep
		const payload = { actions };

		submitConfugureSession(payload, sessionId as string, handleCloseModal);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sessionObj?._id, sessionObj?.fullStatus, state, submitConfugureSession]);

	const isDisable = useCallback(
		(label: string) => {
			let value = true;
			allKeys.forEach(() => {
				if (sessionObj?.fullStatus[label] === 'pending') {
					if (label === 'authentication') {
						value = false;
					}
				}
			});
			return value;
		},
		[allKeys, sessionObj]
	);

	const toolTipMessage = useCallback(
		(label: string) => {
			let message = '';
			if (label !== 'authentication') {
				message = 'Not Allowed';
			} else if (sessionObj?.fullStatus[label] !== 'pending') {
				message = 'Not allowed as session is under review';
			}
			return message;
		},
		[sessionObj]
	);

	const renderAllItems = useMemo(
		() =>
			allKeys?.map((el: any) => {
				return (
					el.label !== 'form' && ( // tobe remove leter by pradeep
						<div
							key={el.label}
							className="configure_session_modal__body_wrapper__form_items"
						>
							<div>
								{(CONFIGURE_SESSION_KEYS_VALUE as any)[el.label] ?? '--'}
							</div>
							<div id={el.label}>
								<ReactSwitch
									checked={(state as any)[el.label] ?? false}
									handleChange={e => handleChange(e, el.label)}
									id=""
									onColor="#33b87a"
									isDisabled={isDisable(el.label)}
								/>
							</div>
							<ReactTooltip
								anchorId={el.label}
								place="top"
								content={toolTipMessage(el.label)}
								style={{ zIndex: 1000, color: '#ffffff' }}
							/>
						</div>
					)
				);
			}),
		[allKeys, state, isDisable, toolTipMessage, handleChange]
	);

	const renderMainComponent = useMemo(() => {
		return (
			<Fragment>
				<div className="configure_session_modal__body_wrapper">
					<div className="configure_session_modal__body_wrapper__form_items_wrapper">
						{renderAllItems}
					</div>
				</div>
				<div className="configure_session_modal__footer_wrapper">
					<Button
						handleClick={handleCloseModal}
						label="Cancel"
						type="button__ghost button__filled--secondary"
						buttonType="button"
					/>

					<Button
						handleClick={handleSubmit}
						label={
							isConfigureLoaded ? (
								'Submit'
							) : (
								<Loader
									type="loader"
									dimension={26}
									className={'loader-white'}
								/>
							)
						}
						type="button__filled button__filled--primary"
						buttonType="button"
					/>
				</div>
			</Fragment>
		);
	}, [renderAllItems, handleCloseModal, handleSubmit, isConfigureLoaded]);

	return (
		<Modal
			isOpen={isOpen}
			closeModal={handleCloseModal}
			className="configure_session_modal"
			modalName="Session modal"
			title={
				<div className="configure_session_modal__header_wrapper">
					<div className="configure_session_modal__header_wrapper__title">
						Configure Session
					</div>
					<div className="configure_session_modal__header_wrapper__sub-title">
						{`Configure for selected 
             session`}
					</div>
				</div>
			}
		>
			{renderMainComponent}
		</Modal>
	);
};
