import { IFundInvestment, Transaction } from 'global-stores/sessions/type';

import { FC, Fragment, useCallback, useMemo } from 'react';
import classNames from 'classnames';

import { useFormatNumber, getDateWithTime } from 'utils';
import { NoData } from '../no-data-available';
import { KybInfoHeader } from '../kyb-wrapper';
import {
	fundReportDetails,
	formatStatusKey,
	BalanceType,
	TransactionUserType,
	UNIT_PRICE_NUMBER_FORMAT,
	REGULAR_NUMBER_FORMAT,
} from './constants';
import { DetailRow, FundInvestmentHeader, UnitPricingData } from './component';

// Props interface for ReportDetails
interface IReportDetails {
	fundInvestment: IFundInvestment; // Contains details of fund investments
}

/**
 * Main component to render fund investment details, including transaction details, unit pricing, and more.
 */
export const ReportDetails: FC<IReportDetails> = ({ fundInvestment }) => {
	const { numberDecimal } = useFormatNumber();

	const numberFormat = useMemo(
		() =>
		  Object.keys(fundInvestment?.payInPricingData ?? {})?.length
			? UNIT_PRICE_NUMBER_FORMAT
			: REGULAR_NUMBER_FORMAT,
		[fundInvestment.payInPricingData]
	  );

	/**
	 * Function to get the formatted balance based on key and subKey.
	 *
	 * @param fundAccount - The account object containing user details
	 * @param subKey - Sub-key to retrieve nested balance values ('available' or 'current')
	 * @returns The formatted balance value as a string
	 */
	const getBalance = useCallback(
		(fundAccount: Transaction, subKey: BalanceType) => {
			const balanceValue =
				fundAccount?.sender?.balance?.[subKey] ?? 0;
			const currencyCode = fundAccount?.sender?.balance?.currencyCode ?? '--';
			return `${numberDecimal(balanceValue , numberFormat) ?? '--'} ${currencyCode}`;
		},
		[numberDecimal , numberFormat]
	);

	/**
	 * Function to mask data with asterisks.
	 *
	 * @param value - The value to mask
	 * @returns The masked value as a string
	 */
	const getMaskedData = useCallback((value: string | undefined) => {
		return value ? `******${value}` : '--';
	}, []);

	/**
	 * Function to get the formatted investment amount.
	 *
	 * @param fundAccount - The account object containing user details
	 * @returns The formatted investment amount as a string
	 */
	const getFormattedInvestmentAmount = useCallback(
		(fundAccount: Transaction) => {
			const investmentAmount =
				numberDecimal(fundInvestment?.totalInvestmentAmount ?? 0 , numberFormat) ?? '--';
			const currencyCode = fundAccount?.sender?.balance?.currencyCode ?? '--';
			return `${investmentAmount} ${currencyCode}`;
		},
		[fundInvestment?.totalInvestmentAmount, numberDecimal, numberFormat]
	);

	/**
	 * Function to render value based on key, user type, and other parameters.
	 *
	 * @param fundAccount - The account object containing user details
	 * @param userType - Type of the user ('sender' or 'receiver')
	 * @param key - Key representing the value to retrieve ('balance', 'mask', etc.)
	 * @param subKey - Sub-key to retrieve nested values, if applicable
	 * @returns The formatted value as a string
	 */
	const renderValue = useCallback(
		(
			fundAccount: Transaction,
			userType: TransactionUserType,
			key: string,
			subKey: BalanceType
		): string => {
			switch (key) {
				case 'balance':
					return getBalance(fundAccount, subKey);

				case 'mask':
					return getMaskedData(fundAccount?.[userType]?.[key]);

				case 'accountHolderName':
					return fundAccount?.[userType]?.[key]?.join(', ') ?? '--';

				case 'transactionId':
					return fundAccount?.[key] ?? '--';

				case 'totalInvestmentAmount':
					return getFormattedInvestmentAmount(fundAccount);

				default:
					return fundAccount?.[userType]?.[key as 'mask'] ?? '--';
			}
		},
		[getBalance, getFormattedInvestmentAmount, getMaskedData]
	);

	/**
	 * Function to render additional information based on key.
	 *
	 * @param fundAccount - The account object containing user details
	 * @param key - Key representing the additional info to retrieve ('date', 'transactionId', etc.)
	 * @returns The formatted value as a string
	 */
	const renderInfoValue = useCallback(
		(fundAccount: Transaction, key: string): string => {
			if (key === 'date') {
				return getDateWithTime(fundAccount?.date);
			} else if (key === 'transactionId') {
				return fundAccount?.[key] ?? '--';
			} else return '--';
		},
		[]
	);

	/**
	 * Function to get the CSS class for the status icon based on fund account status.
	 *
	 * @param fundAccount - The account object containing user details
	 * @returns The CSS class for the status icon
	 */
	const statusClass = useCallback(
		(fundAccount: Transaction) =>
			classNames(
				'ri-circle-fill',
				'FundInvestmentReportDetails__status-icon',
				`FundInvestmentReportDetails__status-icon--${formatStatusKey(
					fundAccount?.status ?? ''
				)}`
			),
		[]
	);

	/**
	 * Memoized function to map and render fund investment transactions.
	 */
	const mapFundInvestment = useMemo(
		() =>
			fundInvestment?.transactions?.map(
				(fundAccount: Transaction, index: number) => {
					const fundInvestmentAmount =
						numberDecimal(fundInvestment?.totalInvestmentAmount ?? 0 , numberFormat) ?? '--';
					const currencyCode =
						fundAccount?.sender?.balance?.currencyCode ?? '--';

					return (
						<div
							className="kycDetails-body FundInvestmentReportDetails"
							key={`fundAccount-${index.toString()}`}
						>
							<FundInvestmentHeader
								fundInvestmentAmount={fundInvestmentAmount}
								currencyCode={currencyCode}
								statusClass={statusClass(fundAccount)}
								fundAccount={fundAccount}
								renderInfoValue={renderInfoValue}
							/>
							{index === 0 && fundInvestment?.payInPricingData && (
								<UnitPricingData
									payInPricingData={fundInvestment.payInPricingData}
								/>
							)}
							{fundReportDetails.map(({ userType, userTitle, details }) => (
								<Fragment key={userType}>
									<KybInfoHeader type={userTitle} />
									<div className="fundAccount">
										<div className="account-detail">
											<div className="kycDetail-column">
												{details.map(({ key, subKey = '', name }) => (
													<DetailRow
														key={key + subKey}
														label={name}
														value={renderValue(
															fundAccount,
															userType as TransactionUserType,
															key,
															subKey as BalanceType
														)}
													/>
												))}
											</div>
										</div>
									</div>
								</Fragment>
							))}
						</div>
					);
				}
			),
		[
			fundInvestment?.transactions,
			fundInvestment?.totalInvestmentAmount,
			fundInvestment.payInPricingData,
			numberDecimal,
			statusClass,
			renderInfoValue,
			renderValue,
			numberFormat
		]
	);

	return (
		<div className="ReportDetails">
			{fundInvestment?.transactions?.length ? mapFundInvestment : <NoData />}
		</div>
	);
};
