import { IAction, IActionKeyType, ISigner, ITemplateSource } from './store';

export const signedDocsHeader = {
	HEADER: [
		{
			label: 'Template Name',
			key: 'documentName',
			format: 'jsx',
			width: '20%',
		},
		{
			label: 'Size',
			key: 'documentSize',
			format: 'size',
			width: '10%',
		},
		{
			label: 'Template Id',
			key: 'documentId',
			format: 'string',
			width: '25%',
		},
		{
			label: 'Signers',
			key: 'signers',
			format: 'string',
			width: '5%',
		},
		{
			label: 'Provider',
			key: 'provider',
			format: 'jsx',
			width: '10%',
		},
		{
			label: 'Type',
			key: 'type',
			format: 'jsx',
			width: '10%',
		},
		{
			label: 'Creation Date',
			key: 'createdAt',
			format: 'date',
			width: '20%',
		},
		{
			label: 'Actions',
			key: 'action',
			format: 'jsx',
			width: '10%',
			className: 'signDoc__btn-action',
		},
	],
};

export const signedDocsBody = [
	{
		_id: '632807de332856e9ce669283',
		templateName: 'accreditation.pdf',
		fundName: 'fund1',
		url: 'https://storage.googleapis.com/agreement_doc/Accredited-Investor-Self-Certification.pdf?GoogleAccessId=nich-service-account%40glossy-fastness-305315.iam.gserviceaccount.com&Expires=1742169600&Signature=Ek4EtMH5PMxrrmv1yM%2FJ5yQ%2BxRO73%2FuZDxbFSR5RGHqPAg5DMcZ6D1RTkzTiRUlZiqj8ZFHCWdMO3D37cVHkEtMgFpX%2F2BKdShoGldMY3uQMPzJvnZFzfoLSkMS1ZCDVjEG13NIykVYxJLG5YYbdEu41jQVWvbBGCvXb7SGd%2F%2BV2UI5lpe2oHmrCoH%2Fj1NAjyZfmRmflCANQBAoKF5I6usRsoIhYuyNkEmAv5YaWnN0z874x1oJCFzUtHHrsFH7%2Fqsq6KjzCyK%2FBml3xWavgzMgZsFJYk2o3xdxMfF41B01X%2F2u6%2BsrcdJHOvdnkYGpSeMfz%2BZgiXdsjbJPwHkz1sA%3D%3D',
		createdAt: '2022-09-19T06:12:38.694Z',
	},
	{
		_id: '63280b32332856e9ce669393',
		templateName: 'signDoc.pdf',
		fundName: 'fund1',
		url: 'https://storage.googleapis.com/agreement_doc/fttn1a092019.2pages.pdf?GoogleAccessId=nich-service-account%40glossy-fastness-305315.iam.gserviceaccount.com&Expires=1742169600&Signature=NzV5eHsNnU0GzOeqtbfQaP3Vry%2BEiRlqmbqjM2uXEdpTixwq%2BrUdHKuj%2FjxDut8cAZDLxgbwy2Q%2FnfyH7mfIUNyBBOmhyMwQafty49JfmSMR5XbqDolbabAygAu8O6%2BbrZEVW1OLbiTo6bM0jCgXOvxEpYjaP3waxyzaPrZUFjmgEPUFrJM5URL0y6xaGBxWCMZfltUXdoWAFT42soevHwRkZYO6cq0XY8QVxCDlHnPxDOSgk7JxC8XckLsxq4fJWEGHHrf7aGjMoC7tINljo%2BAxmlK7lFPhhojjGwZ2ERyxFrlm2nqHJJRY5dG7ymIWxfWd86RBTi2vzVw9A1jW8w%3D%3D',
		createdAt: '2022-09-19T08:42:15.694Z',
	},
	{
		_id: '63280b32332856e9ce669397',
		templateName: 'dummy_agreement_latest.pdf',
		fundName: 'fund2',
		url: 'https://storage.googleapis.com/agreement_doc/dummy%20.pdf?GoogleAccessId=nich-service-account%40glossy-fastness-305315.iam.gserviceaccount.com&Expires=1742169600&Signature=N2jALCIAH2TIeFfg%2FaLPgmaCCOXQL9N6pA%2FHYtDCfUzKKUcCH%2B7h4fkPUMIIfa%2BEH1LuWr91TUhtUeol2YFedBYYEf0QwVcmQZeTaphHERU4Vwjez2yQ7oTUXWGjnHaphL1Yj31G2rnzzMWzn6l0FC7U8EO8pcVPF0kRJ72edAU0gxCOI%2BNMf%2FDIC5XlxDryUjGJk4FKVHWuE5SdxsJntd%2Bv96Y5bAlJhNXecNzSZJlXxqe5vxJmmr%2FnNx8lL%2BThZl3pAi0uVarh83OI6KC1NnxT1LNzITPsucTD3BQRpjS1gcPpNU8TjnTRSqshEqmeB%2FKyAAo113cnGcCRblE4Dg%3D%3D',
		createdAt: '2022-09-20T04:10:38.694Z',
	},
];

export const uploadedFilesColumn = [
	{
		label: 'File Name',
		key: 'name',
		format: 'jsx',
		width: '30%',
	},
	{
		label: 'File Size',
		key: 'size',
		format: 'size',
		width: '30%',
	},
	{
		label: 'Date Uploaded',
		key: 'createdAt',
		format: 'date',
		width: '30%',
	},
	{
		label: 'Actions',
		key: 'actions',
		format: 'jsx',
		className: 'upload-sign-action',
		width: '10%',
	},
];

export const signerList: ISigner[] = [
	{
		key: 1,
		label: 'esign',
		name: 'esign',
		penIcon: 'eSign.svg',
		arrIcon: 'ri-arrow-right-s-line',
		price: '$0.50/packet',
		isDisabled: false,
	},
	{
		key: 2,
		label: 'esign Overlay',
		name: 'esign_overlay',
		penIcon: 'eSign.svg',
		arrIcon: 'ri-arrow-right-s-line',
		price: '$0.50/packet',
		isDisabled: false,
	},
	//@avinashSatschel: commented Docusign as no longer use
	// {
	// 	key: 3,
	// 	label: 'Docusign',
	// 	name: 'docusign',
	// 	penIcon: 'Docusign.svg',
	// 	arrIcon: 'ri-arrow-right-s-line',
	// 	price: '$2.00/document',
	// 	isDisabled: false,
	// },
];

export const provider: { [key: string]: string } = {
	esign: 'esign',
	docusign: 'docusign',
};

export const actions: IAction[] = [
	{
		label: 'Delete',
		key: 'delete',
		icon: 'ri-delete-bin-6-line',
		iconColor: '#F55353',
		isDisabled: false,
	},
	{
		label: 'File',
		key: 'file',
		icon: 'ri-file-text-line',
		iconColor: '#747E99',
		isDisabled: false,
	},
	// {
	// 	label: 'Use Template',
	// 	key: 'useTemplate',
	// 	icon: 'ri-file-text-line',
	// 	iconColor: '#747E99',
	// 	isDisabled: true,
	// },
];

export const SignerTypes = {
	docusign: 'docusign',
	esign: 'esign',
	esignOverlay: 'esign_overlay',
};


export const ACTION_KEY_TYPE: IActionKeyType = {
	DELETE: 'delete',
	FILE: 'file',
	USETEMPLATE: 'useTemplate',
};

export const TEMPLATE_SOURCE: ITemplateSource = {
    ONBOARDING: "onboarding",
    DEFAULT: "default"
};
