import { useEffect, useState } from 'react';

export const useWindowSize = () => {
    // local stage
	const [innerSize, setInnerSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	useEffect(() => {
		// Handler to call on window resize
		const handleResize = () => {
			setInnerSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};

		// Add event listener
		window.addEventListener('resize', handleResize);

		// Call handler right away so state gets updated with initial window size
		handleResize();

		// Remove event listener on cleanup
		return () => window.removeEventListener('resize', handleResize);
	}, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

	return {
		innerSize,
	};
};
