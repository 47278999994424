import React from 'react';
import { BasicSection } from '../../kyb-comprehensive';
import { camelCaseToCapitalizedWords } from 'utils';
type ValueType = {
	value: string; 
};

type KeyValuePair = [string, ValueType];

type IProps = {
	items: KeyValuePair[]; // Change here to wrap in an object
};

export const ExtraInformationSection: React.FC<IProps> = ({ items }) => {
	if (!items.length) {
		return <></>;
	}
	return (
		<div>
			{(items ?? []).map(([key, { value }]) => (
				<BasicSection
					key={key}
					label={camelCaseToCapitalizedWords(key)}
					value={value ?? "N/A"}
				/>
			))}
		</div>
	);
};
