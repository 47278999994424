import { EnvType } from 'types';
import type { ISignDashboardConfig } from '../store';

import {
	Fragment,
	useCallback,
	useMemo,
	useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';


import { Button, Loader } from '@storybook';
import Modal from '@storybook/new-modal/modal';
import { SubscribeModal } from 'components';
import { environmentUrl } from 'constant';
import { loginState } from 'global-stores';
import { useAllowedInvites, useFreePlan, useNotification, useSubscription } from 'hooks';
import { ROUTES } from 'routes';
import { WelcomeNewUserModalState } from 'views/new-user-popup';
import { useSignedDocsInbox } from 'views/signed-doc-inbox';
import { useSignedDocsPacketsInbox } from 'views/signed-doc-packets-inbox';
import { useDefaultCard } from 'views/user-identity-flow';
import { useSignDashboard } from '../store';
import { PERMISSION_SCREEN, useUserRoles } from 'views/routes-children';
import { NavigateToBackConfirmation } from 'components/esign-back-modal';
import { SimpliciSignWebComponent } from 'shared-components';
import { IPurpose } from 'views/pipelines/components';


export const DocumentStartNow = () => {
	const { getSignDashboardPrepareUrl } = useSignDashboard();
	const [isOpen, setIsOpen] = useState(false);
	const [subscribeModal, setIsSubscribeModal] = useState(false);
	const [config, setConfig] = useState<ISignDashboardConfig>({
		prepareUrl: '',
		envelopeId: '',
		env: '',
	});
	const { getSignedDocsInbox } = useSignedDocsInbox();
	const { getSignedDocsPacketsInbox } = useSignedDocsPacketsInbox();
	const { successNotification, warningNotification } = useNotification();
	const navigate = useNavigate();
	const { checkSubscription } = useSubscription();
	const { checkUserWritePermission } = useUserRoles();
	const { isAllowedToInvite } = useAllowedInvites();
	const { isDefaultExist } = useDefaultCard();
	const { invitationSubscriptionCheck } =	useFreePlan();

	const { email, name, isVerifiedPhone, invitedDate } =
		useRecoilValue(loginState);

	/**
	 * 	it will use to open remaining credit modal
	 */
	const setWelcomeNewUser = useSetRecoilState(WelcomeNewUserModalState);

	const isVerified = useMemo(() => {
		const serviceSubscribed = checkSubscription('simpliciSign');
		if (invitedDate) {
			return true;
		} else if (!serviceSubscribed && isVerifiedPhone) {
			return true;
		}
		return isVerifiedPhone === true && isDefaultExist;
	}, [isDefaultExist, invitedDate, checkSubscription, isVerifiedPhone]);

	const navigateToBilling = useCallback(() => {
		navigate(ROUTES.BILLING);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleStart = useCallback(async () => {
		if (invitationSubscriptionCheck) {
			if (!checkUserWritePermission(PERMISSION_SCREEN.Billing)) {
				warningNotification('You need billing permission.');
				return;
			}
			return navigate(ROUTES.BILLING);
		}
		if (!isAllowedToInvite('simpliciSign')) {
			setWelcomeNewUser({
				open: true,
				type: 'Credits_Remain',
				serviceName: 'esign',
			});
			return;
		}

		const serviceSubscribed = checkSubscription('simpliciSign');
		if (!serviceSubscribed) {
			/**
			 * might be use leter
			 */
			// setIsSubscribeModal(true);
			// return;
		}
		setIsOpen(true);
		const payload = {
			senderData: {
				fullName: name,
				email: email,
				testMode: !isVerified,
			},
		};
		const response = await getSignDashboardPrepareUrl(payload);

		if (response) {
			setConfig(response);
			return;
		}
		setTimeout(() => {
			setIsOpen(false);
		}, 1000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		name,
		email,
		invitationSubscriptionCheck,
		isAllowedToInvite,
	]);

	const handleCloseModal = useCallback(() => {
		setIsOpen(false);
		setConfig({ envelopeId: '', prepareUrl: '', env: '' });
	}, []);

	const handleSubmitModal = useCallback((purpose?: IPurpose) => {
		if(purpose === 'selfsign') {
			successNotification('Your document has been successfully signed');
		} else {
			successNotification('Invitation Sent.');
		}
			setConfig({ envelopeId: '', prepareUrl: '', env: '' });
			getSignedDocsInbox();
			getSignedDocsPacketsInbox();
			setIsOpen(false);
			navigate(ROUTES.SIGNED_DOC_INBOX);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getSignedDocsInbox, getSignedDocsPacketsInbox]);

	const webComponent = useMemo(() => {
		const { prepareUrl, env } = config;
		const signUrl = prepareUrl?.replace(environmentUrl[env], '');
		return signUrl?.trim() ? (
			<SimpliciSignWebComponent signUrl={signUrl} env={env as EnvType} handleSubmitModal={handleSubmitModal} />
		) : (
			<Loader />
		);
	}, [config, handleSubmitModal]);

	const renderSimpliciSignIframe = useMemo(() => {
		return (
			<div className="iframe-container">
				{config.prepareUrl ? (
					<>
						<div
							className="iframe-container__loader-wrapper"
							id="simplici-sign-dashboard-screen"
						>
							{webComponent}
							<button onClick={handleCloseModal} className="close-btn">
								<i className="ri-close-line" />
							</button>
						</div>
					</>
				) : (
					<Loader className="loader-blue" dimension={60} />
				)}
			</div>
		);
	}, [config.prepareUrl, handleCloseModal, webComponent]);	

	return (
		<Fragment>
			<div className="user-dashboard__start-outer-container hover">
				<div
					className="user-dashboard__start-inner-container"
					onClick={handleStart}
				>
					<i className="ri-edit-box-line user-dashboard__create-flow-icon" />
					<div className="user-dashboard__create-flow-description">
						Create documents here to get started
					</div>
					<Button
						label="Start now"
						type="button__filled button__filled--primary button__large"
					/>
				</div>
				{isOpen && (
					<Modal
						isOpen={isOpen}
						modalName=""
						closeModal={handleCloseModal}
						closeOnEscBtn={false}
						isStopOutsideClick={false}
						showCloseBtn={false}
						className="start-new-modal"
					>
						{renderSimpliciSignIframe}
					</Modal>
				)}
			</div>
			{subscribeModal && (
				<SubscribeModal
					buttonLabel="Go to billing"
					closeModal={() => setIsSubscribeModal(false)}
					handleButton={navigateToBilling}
					isOpen={subscribeModal}
					modalName=""
					subTitle="You are not subscribe to esign service please go to billing page to subscribe."
					title="Subscription Required"
					modalTitle=""
				/>
			)}
			{isOpen && (
				<NavigateToBackConfirmation
					isModalOpen={isOpen}
					modalCloseHandler={handleCloseModal}
				/>
			)}
		</Fragment>
	);
};
