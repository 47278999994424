import type { EnvType } from 'types';
import type { IPurpose } from 'views/pipelines/components';

import { FC, useEffect, useState, CSSProperties } from 'react';
import { Loader } from '@storybook';

import { InjectScript } from 'utils/script';

import './simplici-sign.scss';
import { REACT_APP_ESIGN_WEBCOMPONENT_URL } from 'envs';

interface ISimpliciSign {
	env: EnvType;
	signUrl: string;
	handleSubmitModal: (purpose?: IPurpose) => void;
}

let isInjected = false;
export const SimpliciSignWebComponent: FC<ISimpliciSign> = ({
	env,
	signUrl,
	handleSubmitModal,
}) => {
	const [loading, setLoading] = useState(false);

	const appendScripts = async () => {
		const url = REACT_APP_ESIGN_WEBCOMPONENT_URL;
		await InjectScript([
			{
				type: 'script',
				link: url,
			},
		]);
		setLoading(false);
	};

	useEffect(() => {
		if (!isInjected) {
			isInjected = true;
			appendScripts();
		} else {
			setLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isInjected]);

	(window as any).handleSubmitModal = (status: boolean, purpose: IPurpose) => {
		if (status) {
			handleSubmitModal(purpose);
		}
	};

	if (loading) {
		return (
			<div className="simplici-sign-loader">
				<Loader />;
			</div>
		);
	}

	return (
		<div
			// - Necessary for displaying HTML content from sources like CMS, WYSIWYG editors, or third-party widgets.
			dangerouslySetInnerHTML={{
				__html: `<simplici-sign env=${env} url=${signUrl} submit_esign=${"handleSubmitModal"} />`,
			}}
		/>
	);
};

interface ISimpliciSIgnIframe {
	signUrl: string;
	handleSubmitModal: () => void;
	styles?: CSSProperties;
	title?: string;
	className?: string;
	onLoad?: () => void;
	id?: string;
}

export const SimpliciSignIframe: FC<ISimpliciSIgnIframe> = ({
	signUrl,
	handleSubmitModal,
	styles = {},
	onLoad = () => ({}),
	title = 'untitled-iframe',
	className = '',
	id,
}) => {
	useEffect(() => {
		
		 const messageHandler = (event: any) => {
			const receivedData: {
				type: 'submit_esign';
				data: Record<string, never>;
			} = event.data;
	
			if (receivedData.type === 'submit_esign') {
				handleSubmitModal();
			}    
		};

		window.addEventListener('message', messageHandler);
		 // Removing event listener on unmounting the modal
		return () => {
            window.removeEventListener('message', messageHandler);
        };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<iframe
			src={signUrl}
			style={{ ...styles }}
			height="90vh"
			width="85vw"
			title={title}
			id={id}
			className={className}
			onLoad={onLoad}
			allow="geolocation"
		></iframe>
	);
};
