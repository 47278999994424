import { FC, useCallback, useMemo, useState } from 'react';
import Tippy from '@tippyjs/react';
import { Image } from '@storybook';

import { formatSizeUnits, isValidURL } from 'utils';
import { KybInfoHeader } from '../kyb-info-header';
import { FileTypes } from '../../../../constants';

import './status-uploaded-documents.scss';

interface IStatusUploadedDocuments {
	files: string[];
}

export const StatusUploadedDocuments: FC<IStatusUploadedDocuments> = ({
	files = [],
}) => {
	// eslint-disable-next-line no-console
	console.log(files);
	
	// local states
	const [viewMore, setViewMore] = useState(false);

	const getFileDetailsFromUrl = (url: string) => {
		// Create a URL object
		const urlObj = new URL(url);

		// Extract the pathname and split it to get the file name
		const pathSegments = urlObj.pathname.split('/');

		// Find and decode the name segment
		const nameItem = pathSegments.find(segment => segment.startsWith('name-'));
		const name = nameItem
			? decodeURIComponent(nameItem.replace('name-', ''))
			: '';

		// Find the size segment
		const sizeItem = pathSegments.find(segment => segment.startsWith('size-'));
		const size = sizeItem ? sizeItem.replace('size-', '') : '0';

		return { name, size: Number(size) };
	};

	const fileName = (name: string) => {
		const splitedFile = name.split('.');
		const fileType: string | any =
			splitedFile[splitedFile.length - 1]?.toUpperCase();
		if (FileTypes[fileType]) {
			return fileType + '.svg';
		}
		return 'Default.svg';
	};

	const downloadFile = useCallback(async(url: string, filename: string)=> {
		const response = await fetch(url);
		const blob = await response.blob();
		const urlObject = window.URL.createObjectURL(blob);
		
		const a = document.createElement('a');
		a.style.display = 'none';
		a.href = urlObject;
		a.download = filename;
		
		document.body.appendChild(a);
		a.click();
		
		window.URL.revokeObjectURL(urlObject);
		document.body.removeChild(a);
	},[])
	

	const mapFiles = useMemo(
		() =>
			files?.length &&
			files.map((url, index: number) => {
				if (!isValidURL(url)) return <></>;
				const { name = '', size = 0 } = getFileDetailsFromUrl(url) ?? {};
				return (
					(index < 4 || viewMore) && (
						<div
							key={`${index.toString()}`}
							className="StatusUploadedDocuments__file-row"
						>
							<div className="StatusUploadedDocuments__file-label">
								<div className="StatusUploadedDocuments__file-icon">
									<Image fileName={fileName(name)} height="20px" />
								</div>
								<Tippy
									content={
										<div className="StatusUploadedDocuments__tippy">
											{name || url}
										</div>
									}
								>
									<div className="StatusUploadedDocuments__file-name">
										{name || url}
									</div>
								</Tippy>
								{!!size && (
									<>
										<i className="ri-circle-fill StatusUploadedDocuments__circle-icon" />
										<div className="StatusUploadedDocuments__file-size">
											{formatSizeUnits(size ?? 0)}
										</div>
									</>
								)}
							</div>
							<div className="StatusUploadedDocuments__file-value">
								<span>:</span>
								<div
									onClick={() => downloadFile(url , name)}
									className="StatusUploadedDocuments__file-view"
								>
									<i className="ri-download-line" />
									{'Download'}
								</div>
							</div>
						</div>
					)
				);
			}),
		[downloadFile, files, viewMore]
	);

	return (
		<div className="StatusUploadedDocuments__container">
			<KybInfoHeader type={'Uploaded Documents'} />
			{mapFiles}
			{files?.length > 4 && (
				<div className="StatusUploadedDocuments__view-more">
					<div
						onClick={() => setViewMore(prev => !prev)}
						className="StatusUploadedDocuments__view-more-btn"
					>
						{!viewMore ? 'View more' : 'View less'}
					</div>
				</div>
			)}
		</div>
	);
};
