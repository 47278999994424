/* eslint-disable no-console */
import { useUpdateUserToken } from 'hooks';

export function useRetryOperation() {
	const { updateToken } = useUpdateUserToken();

	async function retryOperation(operation: () => Promise<any>): Promise<any> {
		try {
			const result = await operation();
			// Handle unauthorized or forbidden access
			if (result?.status === 401 || result?.status === 403) {
				const isUpdated = await updateToken(); 
				if (isUpdated) {
					const retryResult = await operation();
					return Promise.resolve(retryResult); 
				}
			}

			if (result?.status === 200) {
				return result;
			}
			// Handle unexpected status codes
			throw new Error(`Unexpected status code: ${result?.status}`);
		} catch (error: any) {
			if(error?.response?.data?.message !== 'Logged in required'){
				return Promise.reject(error)
			}
			if (error?.response?.data?.message === 'Logged in required') {
				const isUpdated = await updateToken(); 
				if (isUpdated) {
					const retryResult = await operation();
					return Promise.resolve(retryResult); 
				}
			}
		}
	}

	return { retryOperation };
}
