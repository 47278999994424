import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { IframePropsState } from 'global-stores';
import { useCustomLoader } from 'hooks';

import './loader.scss';

interface Props {
	dimension?: number;
	className?: 'loader-blue' | 'loader-white' | 'loader-pink';
	type?: 'loader' | 'gif';
}

/**
 * Loader component
 *
 * @param {number} Props.dimension - The dimension (width and height) of the loader
 * @param {'loader-blue' | 'loader-white' | 'loader-pink'} Props.className - CSS class for styling the loader
 * @param {'loader' | 'gif'} Props.type - The type of loader ('loader' for CSS loader, 'gif' for GIF loader)
 */

export const Loader = ({
	dimension,
	className = 'loader-blue',
	type = 'gif',
}: Props) => {
	const iframeProps = useRecoilValue(IframePropsState);
	const { customLoaderElement } = useCustomLoader()

	const clientLogo = useMemo(
		() => iframeProps?.clientlogo ?? '',
		[iframeProps?.clientlogo]
	);
	let _dimension = dimension;
	if (!dimension) {
		_dimension = 70;
	}

	const loaderType = useMemo(() => {
		if (clientLogo?.length > 0) {
			return 'laoder';
		} else return type;
	}, [clientLogo?.length, type]);

	return (
		<div className="circle-loader">
			{loaderType === 'gif' ? (
				customLoaderElement(_dimension ?? 70)
			) : (
				<div
					style={{ width: _dimension, height: _dimension }}
					className={`loader ${className}`}
				/>
			)}
		</div>
	);
};
