import { FC, useCallback, useMemo } from 'react';

import Modal from '@storybook/new-modal/modal';
import { NoDataAvailable, ReactResponsiveTable } from '@storybook';
import { DropOptions } from 'views/pipelines/components';
import { HEADER } from 'views/sub-account/hooks';
import { ILoginState } from 'global-stores';
import { ArchiveConfirmationModal } from 'views/sessions';
import { useNewRoles } from 'views/user-roles/hooks';
import { PERMISSION_SCREEN, useUserRoles } from 'views';

interface IUserModal {
	isOpen: boolean;
	handleCloseModal: () => void;
	isSubUserLoading: boolean;
	subUsersList: any;
	onInviteSubAccountUser: () => void;
	handleEditSubAccountUser: (item: ILoginState) => void;
	onRemoveInvitedUser?: (isOpen: boolean, value: boolean) => void;
	hideBtn?: boolean;
	openDeleteInvitedUser?: boolean;
	invitedUserToDelete?: ILoginState | null;
	handleOpenDeleteInvitedUser?: (item: ILoginState) => void;
	loading?: boolean;
}

export const SubUserInviteModal: FC<IUserModal> = ({
	isOpen,
	handleCloseModal,
	subUsersList,
	isSubUserLoading,
	onInviteSubAccountUser,
	onRemoveInvitedUser = () => null,
	handleEditSubAccountUser,
	hideBtn = false,
	openDeleteInvitedUser = false,
	invitedUserToDelete,
	handleOpenDeleteInvitedUser = () => null,
	loading = false,
}) => {
	const { handleReinvite, isReInviteLoading } = useNewRoles({});
	const handleOnReInvite = useCallback(
		(id: string | undefined) => {
			if (!id) {
				return;
			}
			handleReinvite(id);
		},
		[handleReinvite]
	);

	const { checkUserWritePermission } = useUserRoles();

	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.SubAccount),
		[checkUserWritePermission]
	);

	const HeaderColumns = useMemo(() => {
		if (!isUserPermissionWrite) {
			return HEADER.USER.filter(headerItem => headerItem.key !== 'action');
		}
		return HEADER.USER;
	}, [isUserPermissionWrite]);
	
	const usersRows = useMemo(() => {
		if (subUsersList?.length) {
			const rows: any = [];
			(subUsersList ?? [])?.forEach(
				(item: ILoginState & { isPrimary: boolean }) => {
					if (item) {
						let row: any = {};
						HeaderColumns.forEach(({ format, key }) => {
							if (format === 'jsx' && key === 'subscription') {
								const value = () => (
									<div className="user-sub-account_wrapper">
										{item?.['role']?.['services']?.includes('esign') &&
										!item?.['role']?.['services']?.includes('onboarding') ? (
											<span className="user-sub-account_subscription user-sub-account_esign">
												esign
											</span>
										) : (
											<></>
										)}
										{item?.['role']?.['services']?.includes('onboarding') ? (
											<span className="user-sub-account_subscription user-sub-account_on-boarding ">
												On-boarding + esign
											</span>
										) : (
											<></>
										)}
									</div>
								);
								return (row[key] = value);
							}
							if (format === 'jsx' && key === 'firstName') {
								const value = () => (
									<span>
										{item[key]} {item['lastName']}
									</span>
								);
								return (row[key] = value);
							}

							if (format === 'jsx' && key === 'role') {
								const value = () => <span>{item[key]['name']}</span>;
								return (row[key] = value);
							}

							if (format === 'jsx' && key === 'price') {
								const value = () => <span>${item['role'][key]}</span>;
								return (row[key] = value);
							}

							if (format === 'jsx' && key === 'action') {
								const value = () => (
									<DropOptions
										isDisabled={item?.isPrimary}
										handleView={() => handleEditSubAccountUser(item)}
										handleDelete={() => {
											handleOpenDeleteInvitedUser(item);
										}}
										showReinvite={!item.isVerifiedPhone && !item.isBlocked}
										isReInviteLoading={isReInviteLoading}
										handleReinvite={() => handleOnReInvite(item._id)}
										isDeleteDisabled={true}
									/>
								);
								return (row[key] = value);
							}

							if (format === 'jsx' && key === 'status') {
								const value = () =>
									item.isBlocked ? (
										<div className="AssinedUser--container__blockTag">
											Blocked
										</div>
									) : (
										<>
											{item.isVerifiedPhone ? (
												<div className="AssinedUser--container__joinedTag">
													Joined
												</div>
											) : (
												<div className="AssinedUser--container__pendingTag">
													Pending
												</div>
											)}
										</>
									);
								return (row[key] = value);
							}

							row = {
								...row,
								[key]: item[key as 'name'] ?? '--',
							};
							return null;
						});

						rows.push(row);
					}
				}
			);

			return rows;
		}
		return [];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subUsersList, isReInviteLoading, HeaderColumns]);

	return (
		<Modal
			isOpen={isOpen}
			closeModal={handleCloseModal}
			modalName="user-sub-account-modal"
			className="user-sub-account-modal"
			title={
				<div className="onboarding-details__header">
					<div className="onboarding-details__title">User</div>
				</div>
			}
		>
			{!isSubUserLoading && !subUsersList?.length ? (
				<NoDataAvailable
					label="Invite User"
					illustration="add-group.svg"
					message="No user invited yet."
					description="You have not invited any sub-account user yet."
					handleClickBtn={() => onInviteSubAccountUser()}
					className="user-sub-no-data"
					hideBtn={hideBtn}
				/>
			) : (
				<div className="user-sub-account">
					<ReactResponsiveTable
						column={HeaderColumns}
						rows={usersRows}
						EmptyIllustration="add-group.svg"
						isLoading={isSubUserLoading}
						hideSortKey={['role', 'subscription', 'price', 'email', 'status']}
					/>
				</div>
			)}
			{openDeleteInvitedUser && (
				<ArchiveConfirmationModal
					description={`Deleting ${invitedUserToDelete?.firstName} ${invitedUserToDelete?.lastName} user.`}
					boldDescription={` Do you want to delete ${invitedUserToDelete?.firstName} ${invitedUserToDelete?.lastName} user from this sub-account?`}
					label="Delete"
					title="Are you sure?"
					visible={openDeleteInvitedUser}
					showLoader={loading}
					handleModal={onRemoveInvitedUser}
				/>
			)}
		</Modal>
	);
};
