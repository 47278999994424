import { loginState } from 'global-stores';
import { useFullStoryTrackEvent } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { NewUserPopup, WelcomeNewUserModalState } from 'views/new-user-popup';
import { useDashboradhook, usePaymentCard } from 'views/routes-children';
import { UserIdentityFlow } from 'views/user-identity-flow';

export const CompletedPage = () => {
	const preventExtraNotification = useRef(true);
	const { trackEvent } = useFullStoryTrackEvent();
	const setWelcomeNewUser = useSetRecoilState(WelcomeNewUserModalState);
	const { getSubscriptionPrice } = useDashboradhook();
	const { getPaymentCardsApi } = usePaymentCard();
	const [isOpenModal, setOpenModal] = useState(false);
	const user = useRecoilValue(loginState);

	const params = new URLSearchParams(window.location.search);
	const redirectSocialState = params.get('redirectSocialState');

	const trackFSSession = () => {
		const { userId, firstName, lastName, countryCode, phone, email } =
			user ?? {};
		const properties = {
			userId,
			firstName,
			lastName,
			phone: `${countryCode} ${phone}`,
			email,
			createdAt: new Date(),
		};
		trackEvent('new_user', properties);
	};

	useEffect(() => {
		const { parentBusinessId } = user ?? {};
		if (parentBusinessId) {
			setOpenModal(true);
		}
		if (!parentBusinessId) {
			trackFSSession();
			setWelcomeNewUser({ open: true, type: 'Welcome_User' });

			preventExtraNotification.current = false;
			if (redirectSocialState) {
				window.location.href = redirectSocialState;
				return;
			}
		} else {
			if (redirectSocialState) {
				window.location.href = redirectSocialState;
				return;
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, redirectSocialState]);

	const handleCloseSubsModal = () => {
		const { parentBusinessId } = user ?? {};
		if (!parentBusinessId) {
			setOpenModal(false);
		}
	};

	// Price API Calling
	useEffect(() => {
		getSubscriptionPrice();
		getPaymentCardsApi();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<NewUserPopup />
			<UserIdentityFlow
				isOpen={isOpenModal}
				handleCloseModal={handleCloseSubsModal}
			/>
		</>
	);
};
