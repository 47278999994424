import { FC, useCallback, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';

import { Button, Image } from '@storybook';
import { kycSettingsState } from 'global-stores';

import { EmailClientMethod, EmailClientMethodIcon } from 'views/email-client/constants';
import { useEmailClient } from 'views/email-client/stores';
import './connected-client.scss';

interface IConnectedClient {
	handleClick: () => void;
}

export const ConnectedClient: FC<IConnectedClient> = ({ handleClick }) => {
	const [openDrop, isOpenDrop] = useState(false);
	const [kycSettings, setKycSettings] = useRecoilState(kycSettingsState);
	const { whitelistConfig, whitelabelKey } = useMemo(
		() => kycSettings,
		[kycSettings]
	);
	const { removeEmailClient, changeEmailClient } = useEmailClient();

	const handleRemove = useCallback(
		async (key: string) => {
			const resp = await removeEmailClient(key);
			if (resp) {
				setKycSettings(prev => ({
					...prev,
					whitelabelKey: resp.whitelabelKey,
					whitelistConfig: resp.whitelistConfig,
				}));
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[removeEmailClient]
	);

	const handleChangeClient = useCallback(
		async (key: string) => {
			const resp = await changeEmailClient(key);
			if (resp) {
				setKycSettings(prev => ({
					...prev,
					whitelabelKey: resp.whitelabelKey,
				}));
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[changeEmailClient]
	);

	const renderEmailClient = useMemo(() => {
		return Object.keys(whitelistConfig ?? {}).length > 1 && (
			<div className="connected-client_wrapper">
				<div
					className="connected-client_header"
					onClick={() => isOpenDrop(pre => !pre)}
				>
					<div className="connected-client_header-wrapper">
						<div className="connected-client_logo">
							<Image
								fileName={EmailClientMethodIcon[whitelabelKey as 'SMTPMAIL']}
							/>
						</div>
						<div>
							<div className="connected-client_title">
								{EmailClientMethod[whitelabelKey as 'SMTPMAIL']}
							</div>
							<div className="connected-client_subtitle">
								{(whitelistConfig[whitelabelKey] as { email: string })['email']}
							</div>
						</div>
					</div>
					<i
						className={`ri-arrow-${
							openDrop ? 'up' : 'down'
						}-s-line connected-client_icon`}
					/>
				</div>

				{openDrop && (
					<div>
						<div className="connected-client_desc">Connected email clients</div>
						<div className="connected-client_card-wrapper">
							{Object.keys(whitelistConfig).map(keys => {
								if (keys !== '_id') {
									return (
										<div className="connected-client_email-wrapper" key={keys}>
											<div className="connected-client_header">
												<div
													className="connected-client_header-wrapper"
													onClick={() => handleChangeClient(keys)}
												>
													<input
														id="primary"
														type="radio"
														checked={whitelabelKey === keys}
														// style={{ transform: 'scale(1.5)' }}
													/>
													<div>
														<div className="connected-client_title-email">
															{EmailClientMethod[keys as 'SMTPMAIL']}
														</div>
														<div className="connected-client_subtitle">
															{
																(
																	whitelistConfig[keys] as {
																		email: string;
																	}
																)['email']
															}
														</div>
													</div>
												</div>

												<div
													className="connected-client_remove"
													onClick={() => handleRemove(keys)}
												>
													Remove
												</div>
											</div>
										</div>
									);
								}
								return;
							})}
						</div>
					</div>
				)}
			</div>
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [whitelistConfig, openDrop, whitelabelKey, kycSettings, handleRemove]);

	return <>
				<Button
				handleClick={handleClick}
				label={'Add your email client'}
				type="button__filled button__filled--secondary btn-add-email-client "
				/>
				{renderEmailClient}
			</>;
};