import { FC, useCallback, useMemo, useState } from 'react';
import { Loader } from '@storybook';
import Modal from '@storybook/new-modal/modal';

import { useSignedDocsInbox } from 'views/signed-doc-inbox';
import { useSignedDocsPacketsInbox } from 'views/signed-doc-packets-inbox';
import { ITemplateItem, useSimpliciSign } from '../store';
import { NavigateToBackConfirmation } from 'components/esign-back-modal';
import { SimpliciSignIframe } from 'shared-components';

import './esign.scss';

/**
 * Props for the SimpliciEditTemplateModal component.
 */
interface SimpliciEditTemplateModalProps {
	config: { prepareUrl: string; templateId: string };
	isLoaded: boolean;
	handleCloseModal: () => void;
	handleSubmit: () => void;
}

/**
 * Modal component for editing templates.
 */
const SimpliciEditTemplateModal: FC<SimpliciEditTemplateModalProps> = ({
	config,
	isLoaded,
	handleCloseModal,
	handleSubmit,
}) => {
	/**
	 * Renders the edit template modal content.
	 */
	const renderEditTemplateModal = useMemo(() => {
		if (!isLoaded) {
			return (
				<div className="SimpliciUseTemplate__iframe-loading">
					<Loader />
				</div>
			);
		}
		return (
			<>
				<SimpliciSignIframe
					className="SimpliciUseTemplate__iframe  SimpliciUseTemplate__iframe-wrapper"
					signUrl={config.prepareUrl}
					handleSubmitModal={handleSubmit}
				/>
				<div
					className="SimpliciUseTemplate__iframe-close-button"
					onClick={handleCloseModal}
				>
					<i className="ri-close-line" />
				</div>
			</>
		);
	}, [isLoaded, config, handleSubmit, handleCloseModal]);

	return (
		<Modal
			isOpen={true}
			closeModal={handleCloseModal}
			showCloseBtn={false}
			closeOnEscBtn={false}
			modalName={''}
			isStopOutsideClick={false}
			className="use-template-model"
		>
			{renderEditTemplateModal}
		</Modal>
	);
};

/**
 * Props for the SimpliciEditTemplate component.
 */
interface SimpliciEditTemplateProps {
	template: ITemplateItem;
}

/**
 * Component for editing templates.
 */
export const SimpliciEditTemplate: FC<SimpliciEditTemplateProps> = ({
	template,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const { getSignedDocsInbox } = useSignedDocsInbox();
	const { getSignedDocsPacketsInbox } = useSignedDocsPacketsInbox();
	const { isLoaded, updateTemplate } = useSimpliciSign();
	const { templateId, prepareUrl } = template;

	const [config, setConfig] = useState<{
		prepareUrl: string;
		templateId: string;
	}>({
		prepareUrl: '',
		templateId: '',
	});

	/**
	 * Handles saving the template.
	 */
	const handleSubmit = useCallback(() => {
		setConfig({ templateId: '', prepareUrl: '' });
		getSignedDocsInbox();
		getSignedDocsPacketsInbox();
		setIsOpen(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Handles editing the template.
	 */
	const handleEditTemplate = useCallback(async () => {
		setIsOpen(true);
		const status = await updateTemplate(templateId, false);
		if (status) {
			setConfig({ templateId, prepareUrl });
			return;
		}
		setTimeout(() => {
			setIsOpen(false);
		}, 1000);
	}, [updateTemplate, templateId, prepareUrl]);

	/**
	 * Handles closing the modal.
	 */
	const handleCloseModal = useCallback(() => {
		setIsOpen(false);
		setConfig({ templateId: '', prepareUrl: '' });
	}, []);

	return (
		<div className="SimpliciUseTemplate">
			<div
				onClick={handleEditTemplate}
				className="SimpliciUseTemplate__use-btn"
			>
				Edit
			</div>
			{isOpen && (
				<SimpliciEditTemplateModal
					config={config}
					isLoaded={isLoaded}
					handleCloseModal={handleCloseModal}
					handleSubmit={handleSubmit}
				/>
			)}
			{isOpen && (
				<NavigateToBackConfirmation
					isModalOpen={isOpen}
					modalCloseHandler={handleCloseModal}
				/>
			)}
		</div>
	);
};
