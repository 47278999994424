export const MFA_ACTIVE_MODAL = {
	RGISTER_MFA: 'rigister-mfa',
	BACK_UP_CODE: 'back-up-codes',
};
export const MFA_MODAL_HEADING_MESSAGES = {
	genrateCode: {
		heading: 'Enable two-factor authentication',
		subHeading:
			'Two-factor authentication (2FA) adds an extra layer of security to your account.',
	},
	backupCode: {
		heading: 'Backup verification codes',
		subHeading:
			'Please save these backup verification codes in a secure place. These codes can be used if you lose access to your authenticator app.',
	},
};

export const MFA_MESSAGES = {
	INVALID_CODE: 'Invalid verification code',
	MFA_SUCCESS: 'Enabled two-factor authentication ',
	MFA_DISABLED_SUCCESS: 'Multi authentication disabled',
	COMMING_SOON: 'Coming soon',
	DOWNLOAD_MESSAGE: "File downloaded succesfully"
};
export const ENCRPTION_KEY_MESSAGES = {
	MODAL_TITLE : 'Confirm new encryption key generation?',
	MODAL_DESP : "Please save the old encryption key before generating a new one. Data encrypted with the old key can only be decrypted with that key. ",
	MODAL_BOLD_DESP: 'Are you sure you want to generate a new encryption key?',
	DISABLED_SUCCESS: 'Encryption key Disabled.',
	ENABLED: 'Encryption key Enabled.',
	GENRATE_LIMIT: "Encryption key should enabled to generate new key.",
	BUTTON_LABEL : "Generate New Key",
	NEW_KEY_SUCCESS : "New Encryption key generated.",
	LEFT_KEY : "Key :"
};
