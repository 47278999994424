import { Confirmation, Loader } from '@storybook';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { ReactPipelineModal } from 'components';
import {
	BannerStatusState,
	SignDocModalState,
	SignedDocsState,
	UploadedTemplateDocsState,
	templateNameState,
	useSignedDocs,
} from 'global-stores';
import { detectIncognito, useTrackEvents } from 'helpers';
import { useFreePlan, useNetwork, useNotification } from 'hooks';
import { ROUTES } from 'routes';
import { Header } from 'views/header';
import { incognitoMessage } from 'views/sign-aggrement/components/constants';
import { AgreementTable, CreateTemplateSteps } from './components';
import './signed-doc.scss';
import {
	IsCreateTemplateStepperOpen,
	SelectedSignerState,
	useSignedDoc,
} from './store';
import { PERMISSION_SCREEN, useUserRoles } from 'views/routes-children';
import { NavigateToBackConfirmation } from 'components/esign-back-modal';
import { SimpliciSignIframe } from 'shared-components';


export const SignedDoc = () => {
	// global states
	const setSignDocState = useSetRecoilState(SignDocModalState);
	const [tempName, setTempName] = useRecoilState(templateNameState);
	const signedDocs = useRecoilValue(SignedDocsState);
	const isVerified = useRecoilValue(BannerStatusState);
	const [addedFiles, setAddedFiles] = useRecoilState(UploadedTemplateDocsState);
	const [templateId, setTemplateId] = useState('');
	const [selectedSigner, setSelectedSigner] =
		useRecoilState(SelectedSignerState);
	// local states
	const [confirmation, setConfirmation] = useState(false);
	const setIsModal = useSetRecoilState(IsCreateTemplateStepperOpen);
	const [configDoc, setConfigDoc] = useState({
		prepareUrl: '',
		documentId: '',
	});
	const [signModalVisible, setSignModalVisible] = useState<boolean>(false);
	const [isIframeClosed, setIsIframeClosed] = useState(false);

	const { Title, Description } = incognitoMessage;
	const { _id: uploadedDocId } = addedFiles?.[0] ?? {};

	const onloadCount = useRef(0);
	const { getDocuments } = useSignedDocs();
	const { getTemplateStatus, deleteEsignTemplate } =
		useSignedDoc();
	const { trackEvents } = useTrackEvents();
	const navigate = useNavigate();
	const { invitationSubscriptionCheck } = useFreePlan();

	// hooks
	const { errorNotification, successNotification, warningNotification } =
		useNotification();
	const {
		post: saveTemplate,
		data: templateData,
		error: templateDataError,
	} = useNetwork();

	const { checkUserWritePermission } = useUserRoles();

	const handleOnClickCreateDoc = useCallback(() => {
		if (isVerified === false) {
			errorNotification('Please verify your email to create new template.');
			return;
		}

		if (invitationSubscriptionCheck) {
			if (!checkUserWritePermission(PERMISSION_SCREEN.Billing)) {
				warningNotification('You need billing permission.');
				return;
			}
			return navigate(ROUTES.BILLING);
		}
		trackEvents('create-new-signedDoc-template', {});
		setIsModal(true);
		setSignDocState(false);
		setTempName('');
		setAddedFiles([]);
		setSelectedSigner('');
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isVerified, invitationSubscriptionCheck, checkUserWritePermission]);

	const handleOnClickSaveCreateDoc = useCallback(async () => {
		const result = await detectIncognito();
		if (result.isPrivate && selectedSigner === 'docusign') {
			setConfirmation(true);
			return;
		}
		setIsIframeClosed(false);
		setSignModalVisible(true);
		const docuSignPayload = {
			documentId: uploadedDocId,
		};
		const eSignPayload = { documentIds: addedFiles.map(file => file._id) };
		let payload = {};
		if (selectedSigner === 'docusign') {
			payload = docuSignPayload;
		} else if (selectedSigner === 'esign') {
			payload = eSignPayload;
		} else {
			payload = {
				type: 'overlay',
			};
		}

		trackEvents('config-signedDoc-template', { payload });
		saveTemplate(
			`/templates${
				selectedSigner === 'esign' || selectedSigner === 'esign_overlay'
					? '?type=esign'
					: ''
			}`,
			payload
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		uploadedDocId,
		addedFiles,
		selectedSigner,
		trackEvents,
		saveTemplate,
		tempName,
	]);

	const handleCloseIframe = useCallback(async () => {
		if (selectedSigner === 'esign' || selectedSigner === 'esign_overlay') {
			setSignModalVisible(false);
			if (selectedSigner === 'esign_overlay') {
				setConfigDoc({ prepareUrl: '', documentId: '' });
				setSelectedSigner('');
			}
			const status = await getTemplateStatus(templateId);
			if (status === 'created' || status === 'decline') {
				setTimeout(() => {
					deleteEsignTemplate(templateId);
					setTemplateId('');
				}, 1000);
			}
			return;
		}
		setSignModalVisible(false);
		onloadCount.current = 0;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deleteEsignTemplate, getTemplateStatus, selectedSigner, templateId]);

	const handleSavedTemplate = useCallback(() => {
		setIsIframeClosed(true);
		setTimeout(() => {
			setIsModal(false);
			setSignModalVisible(false);
			getDocuments();
			setIsIframeClosed(false);
			setConfigDoc({ prepareUrl: '', documentId: '' });
			successNotification('Document Configured Successfully.');
			setTemplateId('');
		}, 1000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getDocuments, successNotification]);

	const renderBoldSignIframe = () => {
		return (
			<div className="iframe-container">
				{configDoc.prepareUrl ? (
					<SimpliciSignIframe
						signUrl={configDoc.prepareUrl}
						className="iframe mera-wala-iframe doc-sign-modal-wrapper"
						title="uniqueTitle"
						handleSubmitModal={handleSavedTemplate}
					/>
				) : (					
					<Loader className="loader-blue" dimension={60} />
				)}
				<div
					style={{
						position: 'absolute',
						top: 8,
						right: 8,
						zIndex: 1,
						cursor: 'pointer',
					}}
					onClick={handleCloseIframe}
				>
					<i className="ri-close-line" />
				</div>
			</div>
		);
	};

	useEffect(() => {
		if (templateData) {
			const { documents, prepareUrl, templateId, documentId } =
				templateData ?? {};
			if (prepareUrl) {
				const id = documents?.[0]?._id ?? documentId;
				setConfigDoc({ documentId: id, prepareUrl });
				setTemplateId(templateId);
				return;
			}
			errorNotification('Failed to open file. try again later');
			setSignModalVisible(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [templateData]);

	useEffect(() => {
		if (templateDataError) {
			errorNotification('Failed to open file. try again later');
			if (selectedSigner === 'esign_overlay') {
				setSelectedSigner('');
			}
			setSignModalVisible(false);
		}
		// eslint-disable-next-line
	}, [templateDataError]);

	//@Todo : Manmohan gupta ,  There are lot of iframe containers that is overriding the class so for now we are going with this approach (quick fix),
	useEffect(() => {
		const arrElements = document.getElementsByClassName(
			'iframe-container'
		) as any;
		if (arrElements.length) {
			arrElements?.forEach((element: any) => {
				element.style.zIndex = 'unset';
			});
		}
		return () => {
			if (arrElements.length) {
				arrElements?.forEach((element: any) => {
					element.style.zIndex = 1;
				});
			}
		};
	}, []);

	const isDisabled = useMemo(() => {
		return !addedFiles.length;
	}, [addedFiles.length]);

	const renderModalBody = () => {
		return !isIframeClosed ? (
			renderBoldSignIframe()
		) : (
			<Loader className="loader-blue" dimension={60} />
		);
	};

	return (
		<div className="dashboard-main-body">
			<Header
				title="esign Templates"
				showBtn={signedDocs.length > 0}
				btnLabel="Create New"
				handleClickBtn={handleOnClickCreateDoc}
			/>
			<div className="signed-doc-body">
				<AgreementTable handleOpen={handleOnClickCreateDoc} />
			</div>
			<CreateTemplateSteps
				handleOnClickSaveCreateDoc={handleOnClickSaveCreateDoc}
				isDisabled={isDisabled}
			/>
			<Confirmation
				visible={confirmation}
				title={Title}
				description={''}
				boldDescription={Description}
				handleModal={() => setConfirmation(false)}
				label="Close"
			/>
			<ReactPipelineModal
				handleClose={() => {
					setConfigDoc({ prepareUrl: '', documentId: '' });
					setSignModalVisible(false);
					setIsIframeClosed(true);
					setIsModal(true);
				}}
				body={renderModalBody()}
				visible={signModalVisible}
				showFooter={false}
				showHeader={false}
				isFullScreen={false}
			/>
			{signModalVisible && (
					<NavigateToBackConfirmation
						isModalOpen={signModalVisible}
						modalCloseHandler={handleCloseIframe}
					/>
				)}			
		</div>
	);
};

export default SignedDoc;
