import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useNetwork } from 'hooks';
import { API_URL } from 'constant';
import { BillingTransactionsState } from '../states';
import { loginState } from 'global-stores';

export const useBillingTransaction = () => {
	// globle states
	const setBillingTransaction = useSetRecoilState(BillingTransactionsState);
	const userState = useRecoilValue(loginState);

	// hooks
	const { get } = useNetwork({ updateState: false });

	const billingTransaction = useCallback(async () => {
		const { business } = userState ?? {};
		const response = await get(
			`${API_URL.BILLING_TRANSACTION}?business=${business ?? ''}`
		);
		const { data } = response ?? {};
		if (data) setBillingTransaction(data);
		else setBillingTransaction([]);
		return data;
	}, [get, setBillingTransaction, userState]);

	return { billingTransaction };
};
