import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import {
	BusinessRegistrationState,
	UserSubscriptionsState,
	loginState,
} from 'global-stores';
import { AcountUserTypes } from 'views/settings';
import { ApprovalStatus } from 'views/user-identity-flow';

export const useFreePlan = () => {
	const userSubscriptions = useRecoilValue(UserSubscriptionsState);

	const businessRegistration = useRecoilValue(BusinessRegistrationState);

	const {
		accountType = '',
		approveStatus = '',
		kyc,
		kyb,
	} = useMemo(() => businessRegistration ?? {}, [businessRegistration]);
	const user = useRecoilValue(loginState);

	const {
		differentCompany = false,
		ownBilling = false,
		parentBusinessId = '',
	} = useMemo(() => user ?? {}, [user]);

	const hasSubscribedPerson = useMemo(
		() =>
			(userSubscriptions ?? [])[0]?.[AcountUserTypes.Person] &&
			(userSubscriptions ?? [])[0]?.[AcountUserTypes.Person].status,
		[userSubscriptions]
	);

	const hasSubscribedBusiness = useMemo(
		() =>
			(userSubscriptions ?? [])[0]?.[AcountUserTypes.Entity] &&
			(userSubscriptions ?? [])[0]?.[AcountUserTypes.Entity].status,
		[userSubscriptions]
	);

	const isServiceSubscribed = useMemo(
		() => ApprovalStatus.Approved === approveStatus,
		[approveStatus]
	);

	const invitationSubscriptionCheck = useMemo(() => {
		if (ownBilling && !hasSubscribedBusiness) {
			return true;
		} else if (differentCompany && !hasSubscribedBusiness) {
			return true;
		} else if (differentCompany && approveStatus !== ApprovalStatus.Approved) {
			return true;
		} else return false;
	}, [approveStatus, differentCompany, hasSubscribedBusiness, ownBilling]);

	const canUpdateBilling = useMemo(
		() => !parentBusinessId || ownBilling,
		[parentBusinessId, ownBilling]
	);

	const canReinitiate = useMemo(
		() => !parentBusinessId || differentCompany,
		[parentBusinessId, differentCompany]
	);

	const haveKybKyc = useMemo(() => {
		if (kyc?.success && kyb?.success) return true;
		else return false;
	}, [kyb?.success, kyc?.success]);

	const haveKyb = useMemo(() => {
		if (kyb?.success) return true;
		else return false;
	}, [kyb?.success]);

	const haveKyc = useMemo(() => {
		if (kyc?.success) return true;
		else return false;
	}, [kyc?.success]);

	return {
		hasSubscribedBusiness,
		hasSubscribedPerson,
		isServiceSubscribed,
		accountType,
		invitationSubscriptionCheck,
		userSubscriptions,
		canUpdateBilling,
		canReinitiate,
		haveKybKyc,
		haveKyb,
		haveKyc,
	};
};
