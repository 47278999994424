import { FC, ReactNode, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { PipelineMasterSteps } from 'global-stores';
import { ActionList } from 'views/complex-onboarding-flow/constant';
import { ComplexSettingFormState } from 'views/pipeline';
import { CustomHandle } from '../custom-handle';
import { ICustomNode } from '../custom-node';
import { RenderSignBlock } from './render-sign-block';

export const SignAgreemnetWrapper = ({ children }: { children: ReactNode }) => {
	return <div className="kyb-action">{children}</div>;
};

const SignKeys: Record<string, string> = {
	esign: 'esign',
	docusign: 'DocuSign',
};

export const SignAgreemnet: FC<ICustomNode> = ({ id: nodeId, data }) => {
	const complexSettingForm = useRecoilValue(ComplexSettingFormState);
	const pipelinesSteps = useRecoilValue(PipelineMasterSteps);

	const selectDefaultLabel = useMemo(
		() => complexSettingForm?.[nodeId]?.signAgreementProvider,
		[complexSettingForm, nodeId]
	);
	const actionId = useMemo(() => data.label, [data.label]);

	const actions = useMemo(
		() =>
			pipelinesSteps.find(
				pipelineStep => pipelineStep.key === ActionList[actionId]
			)?.actions[0],
		[actionId, pipelinesSteps]
	);

	// Calc. price
	const price = useMemo(() => {
		return (
			actions?.metadata?.[0]?.options?.find(el => el.key === selectDefaultLabel)
				?.price ?? actions?.price
		);
	}, [actions?.metadata, actions?.price, selectDefaultLabel]);

	return (
		<SignAgreemnetWrapper>
			<RenderSignBlock
				label={SignKeys[selectDefaultLabel] ?? SignKeys.esign}
				price={price}
			/>
			<CustomHandle value={nodeId} />
		</SignAgreemnetWrapper>
	);
};
