import { FC } from 'react';
import { OfacSessionHeader, SectionCount, Tabs } from './components';
import { KeyValuePair } from './components/key-value-pair/keyValuePair';
import { useRecoilValue } from 'recoil';
import { OfacSelector } from '../../hooks/store/selector/ofac';
import { OFACCaseSection } from './components/ofac-case';

export const OFAC: FC = () => {
	const OfacData = useRecoilValue(OfacSelector);
	return (
		<div>
			<OfacSessionHeader />
			<div className="ofac__total-wrapper">
				<div className="ofac__half-width">
					<SectionCount
						count={OfacData.totalSubCases?.value}
						label={OfacData.totalSubCases?.label}
					/>
				</div>
				<div className="ofac__half-width">
					<SectionCount
						count={OfacData?.totalMatches?.value}
						label={OfacData?.totalMatches?.label}
					/>
				</div>
			</div>
			{OfacData?.totalMatches?.value !== 0 && (
				<KeyValuePair
					label={OfacData?.caseId?.label}
					value={OfacData?.caseId?.value}
				/>
			)}
			<Tabs tabs={OfacData?.tabs} />
			<OFACCaseSection cases={OfacData?.tabs} />
		</div>
	);
};
