import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { IsBannerStatusState, loginState } from 'global-stores';
import { Header } from 'views/header';
import classNames from 'classnames';
import {
	BillingDetails,
	BillingInfo,
	BillingStatus,
	BillingSubAccount,
	BillingTransactions,
	Invoices,
	PaymentDetails,
	SubscriptionsBox,
} from './component';
import { ProceedBillingSubscriptionState } from './stores';
import './billing.scss';

export const Billing = () => {
	const bannerStatus = useRecoilValue(IsBannerStatusState);
	const isProceedBillingSubscription = useRecoilValue(
		ProceedBillingSubscriptionState
	);
	const { parentBusinessId = '' } = useRecoilValue(loginState);

	const billingWrapperClass = useMemo(() => {
		return classNames('billing-page_container', {
			'billing-page_banner': !bannerStatus,
		});
	}, [bannerStatus]);

	const billingSubscribeWrapperClass = useMemo(() => {
		return classNames('billing-page_wrapper', {
			'billing-page_subscribe-wrapper': isProceedBillingSubscription,
		});
	}, [isProceedBillingSubscription]);

	const billingSubscribeClass = useMemo(() => {
		return classNames('', {
			'billing-page_subscribe': isProceedBillingSubscription,
		});
	}, [isProceedBillingSubscription]);

	return (
		<div className="billing-page">
			<Header title="Billing" />
			<div className={billingWrapperClass}>
				<div className={billingSubscribeWrapperClass}>
					<BillingStatus />
					<PaymentDetails />
					<BillingInfo />
					<div className={billingSubscribeClass}>
						<SubscriptionsBox />
					</div>
				</div>
				{!parentBusinessId ? <BillingSubAccount /> : null}
				<BillingDetails />
				<BillingTransactions />
				<Invoices />
			</div>
		</div>
	);
};

export default Billing;
