import { useCallback, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { API_URL, MESSAGE } from 'constant';
import { useNetwork, useNotification } from 'hooks';
import { SignedDocInboxState } from './states';
import { ISignedDocInbox } from './types';

export const useSignedDocsInbox = () => {
	const { get, post } = useNetwork({ returnResponse: true });
	const { patch } = useNetwork({ returnResponse: true, updateState: false });
	const setSignedDocInboxState = useSetRecoilState(SignedDocInboxState);
	const { errorNotification, successNotification } = useNotification();
	const [isLoaded, setIsLoaded] = useState(true);

	const getSignedDocsInbox = useCallback(
		async (isLoad?: boolean): Promise<void> => {
			const resp = await get(
				`${API_URL.ENVELOPE}?status=configured&status=completed&status=voided&status=underReview`
			);
			if (!isLoad) {
				setSignedDocInboxState(prev => ({ ...prev, isLoaded: false }));
			}
			const { apiPayload: apiData, response, errorCode } = resp;
			const { data } = apiData || [];
			if (response?.status == 200) {
				setSignedDocInboxState(prev => {
					return { ...prev, data: data, isLoaded: !isLoad };
				});
			}
			if (errorCode == 404 || response?.status !== 200 || errorCode == 400) {
				setSignedDocInboxState(prev => {
					return { ...prev, isLoaded: !isLoad, error: true };
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const signDocInbox = useCallback(
		async (payload: any, setLoader: any): Promise<void | {url: string, env: string}> => {
			const resp = await post(`${API_URL.ENVELOPE_SIGN}`, payload);
			const { url, env, errorData, errorCode } = resp;
			if (url) {
				setLoader(false);
				return {url, env};
			}
			if (errorCode == 400) {
				const { message } = errorData;
				errorNotification(message);
				setLoader(false);
				return;
			}
			setLoader(false);
			errorNotification(MESSAGE.ERROR);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const getUseEnvelopeStatusInterval = useCallback(
		async (
			envelopeId: string,
			interval: any,
			successCb: () => void,
			errorCb: () => void
		): Promise<void> => {
			const resp = await get(`${API_URL.ENVELOPE_STATUS}/${envelopeId}`);
			const { apiPayload: apiData, response, errorCode } = resp;
			const { envelopeStatus, purpose } = apiData?.data ?? {};
			if (purpose === "selfsign") {
				if (envelopeStatus === 'completed') {
					clearInterval(interval);
					successCb();
				}
			}else {
				if (envelopeStatus === 'configured') {
					clearInterval(interval);
					successCb();
				}
			}
			
			if (errorCode == 400 || response?.status !== 200 || errorCode == 404) {
				// clearInterval(interval);
				errorCb();
				/**
				 * Do not clear the interval
				 * */ 
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const getSignEnvelopeStatusInterval = useCallback(
		async (
			envelopeId: string,
			interval: any,
			successCb: () => void,
			errorCb: () => void,
			recipientId: string
		): Promise<void> => {
			const resp = await get(
				`${API_URL.ENVELOPE_STATUS}/${envelopeId}?recipientId=${recipientId}`
			);
			const { apiPayload: apiData, response, errorCode } = resp;
			const { recipientStatus } = apiData?.data ?? {};
			if (recipientStatus === 'completed') {
				clearInterval(interval);
				successCb();
			}
			if (errorCode == 400 || response?.status !== 200 || errorCode == 404) {
				clearInterval(interval);
				errorCb();
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const resendSignDocInbox = useCallback(
		async (envId: string, setLoader: any): Promise<void> => {
			const resp = await get(`${API_URL.SIGN_INVITE}?envelopeId=${envId}`);
			const { apiPayload: apiData, response, errorCode } = resp;
			const { success } = apiData?.data[0] ?? {};
			if (errorCode) {
				setLoader(false);
				errorNotification('Failed to resend the invite');
				return;
			}
			if (response?.status == 200 && success) {
				setLoader(false);
				successNotification('Invitation Sent');
				return;
			}
			errorNotification('Failed to resend the invite');
			setLoader(false);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const downloadSignDocInbox = useCallback(
		async (envId: string, setLoader: any): Promise<void> => {
			const resp = await get(`${API_URL.ENVELOPE}/${envId}`);
			const { apiPayload: apiData, response, errorCode } = resp;
			const { status } = response ?? {};
			const { url } = apiData?.data || {};
			if (errorCode) {
				errorNotification('Failed to download the document');
				setLoader(false);
				return;
			}
			//Ankur Singh: open document in new TAB
			if (status == 200 && url) {
				window.open(url, '_blank');
				setLoader(false);
				return;
			}
			errorNotification('Failed to download the document');
			setLoader(false);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	/**
	 * @Manish  Define a callback function to void an envelope by its ID
	 * 
	*/
	const voidPacket = useCallback(
		async (envelopeId: string, payload: {status: "voided", statusMessage: string}) => {
			/**
			 * @Manish Set the loading state to false before making the request
			 * */ 
			setIsLoaded(false);

			/**
			 * @Manish Send a PATCH request to update the envelope's status to 'voided'
			 * */ 
			const response = await patch(
				`${API_URL.ENVELOPE}/${envelopeId}`,
				payload
			);

			/**
			 * @Manish Check if the response contains an '_id' property, indicating success
			 * */ 
			if (response?._id) {
				/**
				 * @Manish Update the state with the voided status for the corresponding envelope
				 * */ 
				setSignedDocInboxState(prev => {
					/**
					 * @Manish Create a clone of the previous state data
					 * */ 
					const prevState = structuredClone(prev.data);

					/**
					 * @manish Find the index of the envelope with the given ID
					 * */ 
					const foundIndex = prevState.findIndex(
						({ _id }) => envelopeId === _id
					);

					/**
					 * @Manish If the envelope is found, update its status to 'voided'
					 * */ 
					if (foundIndex > -1) {
						const foundEnvelope = prevState[foundIndex];
						prevState.splice(foundIndex, 1, {
							...foundEnvelope,
							status: 'voided',
						} as ISignedDocInbox);
					}

					/**
					 * @Manish Return the updated state with the voided envelope
					 * */ 
					return { ...prev, data: prevState };
				});

				/**
				 * @Manish Set the loading state back to true
				 * */ 
				setIsLoaded(true);

				/**
				 * @Manish Display a success notification
				 * */ 
				successNotification('Packet voided successfully');
			} else {
				/**
				 * @Manish If the api throws error display an error notification
				 * */ 
				errorNotification('Failed to void the packet. Please try again later');
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return {
		downloadSignDocInbox,
		voidPacket,
		resendSignDocInbox,
		getSignedDocsInbox,
		signDocInbox,
		getUseEnvelopeStatusInterval,
		getSignEnvelopeStatusInterval,
		isLoaded,
	};
};
