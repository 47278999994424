import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { UserRolesPermissions, loginState } from 'global-stores';

import { SUB_SIDEBAR } from 'routes';
import { useUserRoles } from 'views/routes-children';
import './toggle-route.scss';

interface SUB_ROUTE {
	ROUTE: string;
	TITLE: string;
	ICON: string;
}
interface ROUTE {
	ROUTE: string;
	TITLE: string;
	ICON: string;
	TAB_INDEX: number;
	SUB_ROUTE: SUB_ROUTE[];
}
interface AppProps {
	index: number;
	route: ROUTE;
	handleChangeTab: any;
	pathname: string;
	openToggle: string;
	setOpenToggle: any;
	setisActive: any;
	windowSize?: number[];
}

export const ToggleRoute = (props: AppProps) => {
	const { index, route, handleChangeTab, pathname, openToggle, setOpenToggle, setisActive } =
		props;

	const { role, developer, sandboxStatus } = useRecoilValue(loginState);
	const userRolesPermissions = useRecoilValue(UserRolesPermissions);

	const { customUserTab } = useUserRoles();

	const toggleIconclasss = classNames(
		'ri-arrow-down-s-line toggle-description-icon ',
		{
			'ri-arrow-up-s-line': openToggle === route.TITLE,
		}
	);

	const handleOpen = useCallback(() => {
		if (route.TITLE === openToggle) {
			setOpenToggle('');
		}
	}, [openToggle, route.TITLE, setOpenToggle]);

	const conditionalActiveTab = useCallback(
		(tabName: string) => {
			const existingKeysWithValues = (customUserTab ?? []).reduce(
				(acc: Record<string, string>, curr: string) => {
					// eslint-disable-next-line no-prototype-builtins
					if (SUB_SIDEBAR.hasOwnProperty(curr)) {
						acc[curr] = SUB_SIDEBAR[curr] as string;
					}
					return acc;
				},
				{}
			);

			const isAllow = Object.values(existingKeysWithValues ?? {})?.some(
				el => el === tabName
			);

			return isAllow ?? false;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[developer, role, customUserTab, userRolesPermissions?.fullAccess]
	);

	const subRouteMemo = useMemo(() => {
		return route.SUB_ROUTE.map(subRoute => {
			if (conditionalActiveTab(subRoute.TITLE)) {
				return sandboxStatus && subRoute.TITLE === 'Embed Web' ? (
					<></>
				) : (
					<li
						key={subRoute.ROUTE}
						className={
							pathname === `/${subRoute.ROUTE}` ||
							pathname.includes(`/${subRoute.ROUTE}`)
								? 'toggle--pad--active'
								: ''
						}
						onClick={() => {
							handleChangeTab(subRoute.ROUTE);
						}}
					>
						{subRoute.TITLE}
					</li>
				);
			}
			return <></>;
		});
	}, [
		route.SUB_ROUTE,
		conditionalActiveTab,
		pathname,
		sandboxStatus,
		handleChangeTab,
	]);

	return (
		<div
			className="toggle-description"
			key={index}
			onClick={() => {
				if (route.TITLE !== openToggle) {
					setOpenToggle(route.TITLE);
				}
			}}
		>
			<div className="toggle--main" onClick={handleOpen}>
			<i 
  				className={`sidebar__icon  ${setisActive ? `${route.ICON}fill` : `${route.ICON}line`}`} 
  				id={route.ICON} 
			/>

				<span className="toggle-title">{route.TITLE}</span>
				<i className={toggleIconclasss} />
			</div>

			{openToggle === route.TITLE && (
				<div className="toggle--sub">
					<ul className="toggle--pad">{subRouteMemo}</ul>
				</div>
			)}
		</div>
	);
};
