import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Button, Image, Loader } from '@storybook';

import {
	FundRecipientBankAccount,
	RecipientBankAccountLoadingState,
} from 'global-stores';
import { IFundRecipient } from 'global-stores/invite-investor/type';

import './fund-investment.scss';
import { ComplexConfigStep } from '../store';

interface IFundInvestment {
	setSelectedIndex: any;
	selectedIndex: number;
	setSelectedItem: any;
	selectedItem: any;
	handleGenerateToken: () => void;
	setSubSelectedItem: any;
	subSelectedItem: any;
	handleSelect?: any;
}
export const FundInvestment: FC<IFundInvestment> = ({
	setSelectedIndex,
	selectedIndex,
	setSelectedItem,
	selectedItem,
	handleGenerateToken,
	setSubSelectedItem,
	subSelectedItem,
	handleSelect,
}) => {
	//global state
	const loading = useRecoilValue(RecipientBankAccountLoadingState);
	const fundRecipient = useRecoilValue(FundRecipientBankAccount);

	const configStep = useRecoilValue(ComplexConfigStep);

	// const [subSelectedItem, setSubSelectedItem] = us(
	// 	SelectedFundRecipientBankAccount
	// );

	//local state
	const [error, setError] = useState<{ [key: string | number]: boolean }>({});

	//custom hooks

	useEffect(() => {
		if (fundRecipient.length > 0 && fundRecipient[0]?.accounts.length > 0) {
			if (handleSelect) {
				handleSelect({
					selectedItem: fundRecipient[0],
					subSelectedItem: {
						...fundRecipient[0]?.accounts[0],
						_id: fundRecipient[0]?._id,
						subIndex: 0,
						index: selectedIndex ?? 0,
					},
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (
			fundRecipient.length > 0 &&
			fundRecipient[0]?.accounts.length > 0 &&
			Object.keys(subSelectedItem ?? {}).length === 0 &&
			Object.keys(selectedItem ?? {}).length === 0
		) {
			setSelectedItem(fundRecipient[0]);
			setSubSelectedItem({
				...fundRecipient[0]?.accounts[0],
				_id: fundRecipient[0]?._id,
			});
		}
		// eslint-disable-next-line
	}, []);

	const handleBank = useCallback(
		(_: IFundRecipient, index: number) => {
			if (selectedIndex === index) setSelectedIndex(-1);
			else setSelectedIndex(index);
		},
		[selectedIndex, setSelectedIndex]
	);

	const renderNoFundRecipient = useMemo(
		() =>
			fundRecipient.length === 0 && (
				<div className="fi-wrapper__center">
					<div className="fi-wrapper__content">
						<div className="fi-wrapper__content__sp16">
							<Image fileName="no_fund_recipient.svg" />
							<div className="fi-wrapper__content__center">
								<div className="fi-wrapper__title">
									No Fund Recipient Bank Account Found
								</div>
								<div className="fi-wrapper__sub_title">
									Please add a fund recipient bank to receive fund investment.
								</div>
							</div>
							<Button
								type="button button__filled button__filled--primary nf-add-button"
								label="Add "
								handleClick={handleGenerateToken}
							/>
						</div>
					</div>
				</div>
			),
		[handleGenerateToken, fundRecipient]
	);

	const handleSubBank = useCallback(
		(item: any, subitem: any, subIdx: number) => {
			if (handleSelect) {
				handleSelect({
					selectedItem: item,
					subSelectedItem: {
						...subitem,
						_id: item._id,
						index: selectedIndex,
						subIndex: subIdx,
					},
				});
			} else {
				setSelectedItem(item);
				setSubSelectedItem({ ...subitem, _id: item._id });
			}
		},
		[handleSelect, selectedIndex, setSelectedItem, setSubSelectedItem]
	);

	const getChecked = useCallback(
		(el: any, subIdx: number) => {
			if (subSelectedItem?.length) {
				const isChecked =
					subSelectedItem.find(
						(el: any) =>
							el.node === configStep.id &&
							el.index === selectedIndex &&
							el.subIndex === subIdx
					)?.accountId === el.accountId;

				return isChecked;
			} else {
				const checked = subSelectedItem?.accountId === el?.accountId;
				return checked;
			}
		},
		[configStep.id, selectedIndex, subSelectedItem]
	);

	const renderFundRecipient = useMemo(
		() =>
			fundRecipient.length > 0 && (
				<div className="fi-wrapper__fr_inner">
					<div className="fi-wrapper__fr_inner__left">
						<div className="fi-wrapper__content__sp16">
							<Image fileName="no_fund_recipient.svg" />
							<div className="fi-wrapper__content__center">
								<div className="fi-wrapper__title">
									Select/Add Fund Recipient Bank Account
								</div>
								<div className="fi-wrapper__sub_title">
									Please select or add a bank account to process funds for pay
									in or payout.
								</div>
							</div>
							<Button
								type="fr-add-button"
								label="Add New "
								handleClick={handleGenerateToken}
							/>
						</div>
					</div>
					<div className="fi-wrapper__fr_inner__right">
						<div className="fi-wrapper__fr_inner__right__lists">
							{fundRecipient?.map((item: any, index: number) => (
								<div
									className={`fi-wrapper__fr_inner__right__lists__wrapper  ${
										selectedItem?._id === item?._id ? 'active' : ''
									}`}
									key={item._id}
								>
									<div
										className="fi-wrapper__fr_inner__right__lists__item "
										onClick={() => handleBank(item, index)}
									>
										{selectedItem?._id === item?._id && (
											<i className="ri-checkbox-circle-fill active-icon " />
										)}
										<div className="fi-wrapper__fr_inner__right__lists__item__details">
											{error[index] ? (
												<i className="ri-bank-line bank-logo__default-image" />
											) : (
												<Image
													className="bank-logo"
													fileName={`{
														getLogos[item.bankName] ?? getLogos['default']
													}`}
													onError={() =>
														setError(pre => ({ ...pre, [index]: true }))
													}
												/>
											)}

											<div className="fi-wrapper__fr_inner__right__lists__item__right">
												<div className="fi-wrapper__title">{item.bankName}</div>
												<div className="fi-wrapper__sub_title">
													{subSelectedItem?._id === item?._id ? (
														<div>
															{`${subSelectedItem?.subtype}`}{' '}
															(&#8226;&#8226;&#8226;&#8226;
															{subSelectedItem?.mask})
														</div>
													) : (
														item.accounts?.length + ' accounts available'
													)}
												</div>
											</div>
										</div>
										<i
											className="ri-arrow-down-s-line dropdown-icon"
											style={{
												transform:
													selectedIndex === index ? 'scale(-1)' : 'unset',
											}}
										/>
									</div>
									{selectedIndex === index && (
										<>
											<div className="line" />
											<div className="radio-list__wrapper">
												{item.accounts?.map((subitem: any, idx: number) => (
													<div
														className="radio-list__wrapper__item"
														onClick={() => handleSubBank(item, subitem, idx)}
														key={subitem.accountId}
													>
														<div className="radio-list__wrapper__item__right">
															<input
																id="primary"
																type="radio"
																name="selected-bank"
																checked={getChecked(subitem, idx)}
																style={{ transform: 'scale(1.5)' }}
															/>
															<div className="radio-list__wrapper__item__right__sub_type">
																{subitem.subtype}{' '}
															</div>
														</div>
														<div className="">
															&#8226;&#8226;&#8226;&#8226; {subitem.mask}
														</div>
													</div>
												))}
											</div>
										</>
									)}
								</div>
							))}
						</div>
					</div>
				</div>
			),
		[
			fundRecipient,
			handleGenerateToken,
			selectedItem?._id,
			error,
			subSelectedItem,
			selectedIndex,
			handleBank,
			getChecked,
			handleSubBank,
		]
	);

	const renderLoading = useMemo(
		() => (
			<div className="fi-wrapper__loader">
				<Loader className="loader-blue" dimension={45} />
			</div>
		),
		[]
	);

	const renderComponent = useMemo(() => {
		if (loading) {
			return renderLoading;
		}
		return (
			<Fragment>
				{renderNoFundRecipient}
				{renderFundRecipient}
			</Fragment>
		);
	}, [loading, renderFundRecipient, renderLoading, renderNoFundRecipient]);

	return <div className="fi-wrapper">{renderComponent}</div>;
};
