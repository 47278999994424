import { useRecoilValue } from 'recoil';
import { DrawSignBox } from './draw-sign-box';
import { SignatureErrorState } from '../store';

export const DrawSign = () => {
	const signatureError = useRecoilValue(SignatureErrorState);
	
	return (
		<div className="admin-draw-sign">
			<div className="admin-draw-sign__header">
				<div className="admin-draw-sign__header-label">
					Draw Signature and Initial
				</div>
			</div>
			<div className="admin-draw-sign__sign-pad-boxes">
				<DrawSignBox type="sign" isError={signatureError.signature.value} />
				<DrawSignBox type="initials" isError={signatureError.initial.value} />
			</div>
		</div>
	);
};
