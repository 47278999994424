import { useRecoilState } from 'recoil';

import {
	ActivePaymentType,
	PaymentNavigationState,
} from 'views/settings/billing/stores';

export const useChoose = () => {
	const [choose, setChoose] = useRecoilState(ActivePaymentType);
	const [addPaymentNaviagtion, setAddPaymentNaviagtion] = useRecoilState(
		PaymentNavigationState
	);

	const handleChoose = (val: string) => () => {
		if(val === 'Wire'){
			return;
		}
		setChoose(val);
		
	};

	const handleChooseNavigate = (val: string) => () => {
		if(val === 'WireView'){
			return;
		}
		setAddPaymentNaviagtion(val);
		setChoose(val);
	};

	return {
		choose,
		handleChoose,
		addPaymentNaviagtion,
		handleChooseNavigate,
	};
};
