import { useCallback, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { API_URL } from "constant";

import { RecipientErrorsState, RecipientLocalState } from "./state";
import { IRecipient, SignedDocInboxState } from "views/signed-doc-inbox";
import { useNetwork, useNotification } from "hooks";
import { RECIPIENT_SUCCESS_MESSAGE, UNIQUE_EMAIL_ERROR } from "./constants";

export const useValidateFields = () => {
    
	const setErrors = useSetRecoilState(RecipientErrorsState);
	const validateEmail = useCallback((email: string) => {
		return !email.trim().match(/^[\w+.~-]+@([\w-]+\.)+[\w-]{2,13}$/);
	}, []);
   
	const validateName = useCallback((name: string) => {
		const pattern = /^[A-Za-z\s]{3,}$/;
		return !pattern.test(name.trim());
	}, []);

	const validateFields = useCallback((localRecipients: IRecipient[]) => {
		const errorList: { [key: string]: string[] } = {};
          
		localRecipients.forEach((item: IRecipient) => {
			const errorFields = [];
            if (!item?.fullName || validateName(item.fullName)) {
                errorFields.push('fullName');
            }
            if (!item.email || validateEmail(item.email)) {
                errorFields.push('email');
            }
				
			if (errorFields.length > 0) {
				const id = item?._id;
				if (id) {
					errorList[id] = errorFields;
				}
			}
		});
		setErrors(errorList);
		if (Object.keys(errorList).length) {
			return false;
		}
		return true;
	}, [
		setErrors,
		validateEmail,
		validateName,
	]);

	return validateFields;
};


export const useRecipients = () => {
    const setLocalRecipients = useSetRecoilState(RecipientLocalState);
	const { errorNotification } = useNotification();

	const validateUniqeEmails = useCallback((localRecipients: IRecipient[]) => {
	
		const collection: { [key: string]: number } = {};
		for (let index = 0; index < localRecipients.length; index++) {
			const { email } = localRecipients[index] as IRecipient;
			if (collection[email]) {
				errorNotification(UNIQUE_EMAIL_ERROR);
				return false;
			} else {
				collection[email] = 1;
			}
		}
		return true;
	}, [errorNotification]);

    const setRecipientsWithValue = useCallback(
		(
			field: keyof IRecipient,
			value: string | boolean | undefined,
			index: number
		) => {
			setLocalRecipients((prev: IRecipient[]) => {
				const prevState = structuredClone(prev);
				let foundItem = prevState[index] as IRecipient;
                foundItem = { ...foundItem, [field]: value };
				prevState[index] = foundItem;
				return prevState;
			});
		},
		[setLocalRecipients]
	);

    return {
        setRecipientsWithValue,
		validateUniqeEmails
    }
}

export const useSubmitRecipient = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setHideModal] = useState(true);
    const validateFields = useValidateFields();
    const { validateUniqeEmails } = useRecipients();
    const { errorNotification, successNotification } = useNotification();
    const { patch } = useNetwork();
	const [signedInboxData, setSignedBoxData] = useRecoilState(SignedDocInboxState);
   
    const saveRecipient = useCallback( async (localRecipients: IRecipient[], envelopeId: string) => {
        if(validateFields(localRecipients) && validateUniqeEmails(localRecipients)) {
            setIsLoading(true)
			try {
				const sendData = localRecipients.map((item: IRecipient) => ({ fullName: item.fullName, email: item.email, _id: item._id }));
				const resp = await patch(`${API_URL.EDIT_RECIPIENTS}/${envelopeId}`, { recipients: sendData})
				if(!resp._id){
					throw new Error(resp.message);
				}
				/**
				 * updating the recoil state with new values
				 */
				const allData = structuredClone(signedInboxData);
				const currentIndex = allData.data.findIndex((item) => (item._id === envelopeId));				
				allData.data[currentIndex] = resp;
				setSignedBoxData(allData)
				successNotification(RECIPIENT_SUCCESS_MESSAGE);
				setHideModal(false)
			} catch (error) {
				let errorMessage = error;
				if(error instanceof Error)
                	errorMessage = error?.message;
                	errorNotification(errorMessage)
            } finally {
                setIsLoading(false)
            }
        }
    }, [patch, errorNotification, validateFields, successNotification, signedInboxData, setSignedBoxData, validateUniqeEmails]);


    return { isLoading, saveRecipient, showModal };
}