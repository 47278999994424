import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { BrowseFile, UploadedFiles } from '../components';
import { SelectedSignerState } from '../store';
import { SignDocModalState } from 'global-stores';

export const AgreementTemplate = () => {
	//hooks
	const signDocState = useRecoilValue(SignDocModalState);
	const provider = useRecoilValue(SelectedSignerState);

	const renderScreen = useMemo(() => {
		return provider === 'esign' ? (
			<>
				<BrowseFile />
				<UploadedFiles />
			</>
		) : signDocState ? (
			<UploadedFiles />
		) : (
			<BrowseFile />
		);
	}, [signDocState, provider]);

	return (
		<div className="SignAgreement--scroll CreateAgreementModal-body">
			{renderScreen}
		</div>
	);
};
