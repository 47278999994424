import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useRecoilState, useResetRecoilState } from 'recoil';
import { WithContext as ReactTags } from 'react-tag-input';
import classNames from 'classnames';

import { UserRoleState, CcEmailState, CcEmailInputState, BccEmailInputState, BCcEmailState } from 'global-stores';
import { validateEmail, message } from 'constant';
import { useNotification } from 'hooks';

const KeyCodes = {
	comma: 188,
	enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];
interface ICcEmail {
	id: string;
	text: string;
}
interface IEmailTag {
	isVisible: boolean;
	type: 'cc'| 'bcc'| ""
}

export const EmailTag: FC<IEmailTag> = ({ isVisible = false, type }) => {

	const[emailType, setEmailType] = useState('')
	const users = useRecoilValue(UserRoleState);
	const [tags, setTags] = useRecoilState(CcEmailState);
	const [bccTags, setBccTags] = useRecoilState(BCcEmailState);
	const [ccInput, setCcInput] = useRecoilState(CcEmailInputState);
	const [bccInput, setBccInput] = useRecoilState(BccEmailInputState);
	const resetCcInput = useResetRecoilState(CcEmailInputState);
	const resetBCcInput = useResetRecoilState(BccEmailInputState);
	
	const { InvalidEmailMessage, EMAIL_ALREADY_ADDED } = message;

	const { errorNotification } = useNotification();

	useEffect(()=>{
		if(type)
		setEmailType(type)
	},[type])
	const suggestions = useMemo(
		() =>
			users.map(user => {
				return {
					id: user._id,
					text: user.email,
				};
			}),
		[users]
	);

	const handleDelete = useCallback(
		(deletIndex: number) => {
			if( emailType === 'cc'){
				setTags(tags.filter((_, index) => index !== deletIndex));

			}
			if( emailType === 'bcc'){
				setBccTags(bccTags.filter((_, index) => index !== deletIndex));
			}
		},
		// eslint-disable-next-line
		[tags, bccTags]
	);

	const handleAddition = useCallback(
		(tag: ICcEmail) => {
			if (!validateEmail(tag.text)) {
				resetCcInput()
				resetBCcInput()
				return errorNotification(InvalidEmailMessage);
			}
			const alreadyAdded = tags.find(email => email.text === tag.text);
			const alreadyAddedBcc = bccTags.find(email => email.text === tag.text);

			if(!alreadyAddedBcc){
				if(emailType==='bcc'){
					setBccTags([...bccTags, tag]);
					setBccInput({ id: '', text: '' });
					return;
				}
			}
			if (!alreadyAdded) {
				if(emailType==='cc'){
				setTags([...tags, tag]);
				setCcInput({ id: '', text: '' });
				return;
				}
			}
			if (alreadyAdded) {
				resetCcInput()
				errorNotification(EMAIL_ALREADY_ADDED);
				return;
			}
			if (alreadyAddedBcc) {
				resetBCcInput()
				errorNotification(EMAIL_ALREADY_ADDED);
				return;
			}
			return null;
		},
		[tags, bccTags, resetCcInput, resetBCcInput, errorNotification, InvalidEmailMessage, emailType, setBccTags, setBccInput, setTags, setCcInput, EMAIL_ALREADY_ADDED]
	);

	const handleAdditionBtn = useCallback(
		(tag: ICcEmail) => {
			if (tag.text) {
				handleAddition(tag);
			}
		},
		[handleAddition]
	);

	const handleInputChange = useCallback((tag: string) => {
		if(emailType ==='bcc'){
			setBccInput({ id: tag, text: tag })
		}
		if(emailType ==='cc'){
		setCcInput({ id: tag, text: tag });
		}
	}, [emailType, setBccInput, setCcInput]);

	const classes = classNames('email-cc', {
		'email-cc-visible': isVisible,
	});

	return (
		<div className={classes}>
			<label className="email-cc-to">To:</label>
			<ReactTags
				tags={emailType==='cc' ? tags : bccTags}
				suggestions={suggestions}
				delimiters={delimiters}
				handleDelete={handleDelete}
				handleAddition={handleAddition}
				handleInputChange={handleInputChange}
				inputFieldPosition="inline"
				autocomplete
				placeholder="Please enter email"
				inputValue={emailType==='cc'? ccInput.text : bccInput.text}
				minQueryLength={1}
			/>
			<button
				className="emaill-cc-add"
				onClick={() => handleAdditionBtn(emailType==='bcc'? bccInput : ccInput)}
				disabled={emailType === 'cc' ? !ccInput.text : !bccInput.text} 
			>
				Add
			</button>
		</div>
	);
};
