import React from 'react';
import './user-account-detail.scss';

interface UserAccountDetailProps {
	label: string;
	value: string;
}

export const UserAccountDetail: React.FC<UserAccountDetailProps> = ({
	label,
	value,
}) => (
	<div className="UserAccountDetail--container">
		<div className="UserAccountDetail--container__dataLeft">
			<div className="UserAccountDetail--container__label">{label}</div>
			<div>:</div>
		</div>
		<div className="UserAccountDetail--container__dataRight">
			{value}
		</div>
	</div>
);
