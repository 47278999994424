import { FC, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Button, ErrorPage } from '@storybook';

import { IframePropsState } from 'global-stores';
import { NewAppLink, RESPONSIVE_LOGO } from 'constant';
import { MESSAGE } from './constants';

import './link-expired.scss';

interface ILinkExpired {
	onClose: () => void;
}
export const LinkExpired: FC<ILinkExpired> = ({ onClose }) => {
	const iframeProps = useRecoilValue(IframePropsState);

	const clientLogo = useMemo(
		() => iframeProps?.clientlogo ?? '',
		[iframeProps?.clientlogo]
	);

	return (
		<div className="le-wrapper">
			<div className="le-wrapper__header">
				<div className="le-wrapper__header__logo">
					<a target="_blank" href={NewAppLink} rel="noreferrer">
						<img
							loading="lazy"
							src={clientLogo || RESPONSIVE_LOGO}
							alt="login"
						/>
					</a>
				</div>
			</div>
			<ErrorPage
				imageName="missing.svg"
				heading={MESSAGE.LABEL}
				description={MESSAGE.DESCRIPTION}
				actionContent={
					<Button
						label="Close"
						handleClick={onClose}
						type="button__filled button__filled--secondary"
					/>
				}
			/>
		</div>
	);
};
