import { useCallback } from 'react';
import { ICheckbox as Props } from './types';

/**
 * Checkbox component
 *
 * @param {string} Props.label - The label text for the checkbox
 * @param {string} Props.linkText - The text for the link associated with the checkbox
 * @param {string} Props.link - The URL for the link associated with the checkbox
 * @param {boolean} Props.checked - Flag to indicate whether the checkbox is checked
 * @param {(e: IInputChange) => void} Props.handleCheck - Callback function triggered when the checkbox is checked or unchecked
 */

export const Checkbox = ({
	label,
	linkText,
	link,
	checked,
	handleCheck,
}: Props) => {
	const handleOpen = useCallback(() => {
		window.open(link, '_blank');
	}, [link]);

	return (
		<div className="checkbox-storybook">
			<label htmlFor="checkbox-storybook" className="checkbox-storybook__label">
				<div className="checkbox-storybook__label--text">{label}</div>
				{linkText && (
					<div className="checkbox-storybook__label--link" onClick={handleOpen}>
						{linkText}
					</div>
				)}
			</label>
			<input
				type="checkbox"
				id="checkbox-storybook"
				className="checkbox-storybook__field"
				onChange={handleCheck}
				checked={checked}
			/>
		</div>
	);
};
