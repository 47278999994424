import { atom } from 'recoil';

export interface ReminderData {
	days: string[];
	checked: boolean;
}
export interface IfileUploadStatus {
	loading: boolean
	loaded: boolean;
}
export const SendNotificationState = atom<ReminderData>({
	key: 'send-notification-state',
	default: {
		checked: true,
		days: [],
	},
});

export const ComplexSettingFormState = atom<any>({
	key: 'complex-setting-form-state',
	default: {},
});

export const uploadIsRequired = atom<boolean>({
	key: 'upload-is-required',
	default: false
})

export const fileUploadStatusState = atom<IfileUploadStatus>({
	key: 'file-upload-status-state',
	default: { loaded: false, loading: false },
});

export const UnitPriceToggleState = atom<{ [key: string]: boolean }>({
	key: 'unit-price-toggle-state-key',
	default: {},
});

export const UnitPriceInputValueState = atom<{ [key: string]: string }>({
	key: 'unit-price-input-value-state-key',
	default: {},
});

export const UnitPriceInputErrorState = atom<{ [key: string]: boolean }>({
	key: 'unit-price-input-error-state-key',
	default: {},
});
