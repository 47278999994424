import { EnvType } from 'types';
import { Loader } from '@storybook';
import Modal from '@storybook/new-modal/modal';
import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { SimpliciSignIframe, SimpliciSignWebComponent } from 'shared-components';

import { SubscribeModal } from 'components';
import { environmentUrl } from 'constant';
import { loginState } from 'global-stores';
import { useAllowedInvites, useSubscription } from 'hooks';
import { ROUTES } from 'routes';
import { WelcomeNewUserModalState } from 'views/new-user-popup';
import { useSignedDocsInbox } from 'views/signed-doc-inbox';
import { useSignedDocsPacketsInbox } from 'views/signed-doc-packets-inbox';
import { useDefaultCard } from 'views/user-identity-flow';
import { IConfig, useSimpliciSign } from '../store';
import { NavigateToBackConfirmation } from 'components/esign-back-modal';

import './esign.scss';

interface ISimpliciUseTemplate {
	template: any;
}

export const SimpliciUseTemplate: FC<ISimpliciUseTemplate> = ({ template }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [subscribeModal, setIsSubscribeModal] = useState(false);
	const { email, name, isVerifiedPhone, invitedDate } =
		useRecoilValue(loginState);
	const { getSignedDocsInbox } =
		useSignedDocsInbox();
	const { getSignedDocsPacketsInbox } = useSignedDocsPacketsInbox();
	const { isLoaded, simpliciSignUseTemplate } = useSimpliciSign();
	const { checkSubscription } = useSubscription();
	const { isAllowedToInvite } = useAllowedInvites();

	const { isDefaultExist } = useDefaultCard();
	const { templateId, type, provider } = template;

	const navigate = useNavigate();

	const [config, setConfig] = useState<IConfig>({
		prepareUrl: '',
		envelopeId: '',
		env: '',
	});

	/**
	 * 	it will use to open remaining credit modal
	 */
	const setWelcomeNewUser = useSetRecoilState(WelcomeNewUserModalState);

	const handleSubmitUseTemplateModal = useCallback(() => {
		setTimeout(() => {
		setConfig({ envelopeId: '', prepareUrl: '', env: '' });
		getSignedDocsInbox();
		getSignedDocsPacketsInbox();
		setIsOpen(false);
		navigate(ROUTES.SIGNED_DOC_INBOX);
		},1000)
	},[getSignedDocsInbox, getSignedDocsPacketsInbox, navigate]);

	const isVerified = useMemo(() => {
		const serviceSubscribed = checkSubscription('simpliciSign');
		if (invitedDate) {
			return true;
		} else if (!serviceSubscribed && isVerifiedPhone) {
			return true;
		}
		return isVerifiedPhone === true && isDefaultExist;
	}, [isDefaultExist, invitedDate, checkSubscription, isVerifiedPhone]);

	const handleUseTemplate = useCallback(async () => {
		if (!isAllowedToInvite('simpliciSign')) {
			setWelcomeNewUser({
				open: true,
				type: 'Credits_Remain',
				serviceName: 'esign',
			});
			return;
		}
		const serviceSubscribed = checkSubscription('simpliciSign');
		if (!serviceSubscribed) {
			/**
			 * might be use leter
			 */
			// setIsSubscribeModal(true);
			// return
		}
		setIsOpen(true);
		const payload = {
			templateId: templateId,
			senderData: {
				fullName: name,
				email: email,
				testMode: !isVerified,
			},
		};
		//TODO manishSatschel
		const response = await simpliciSignUseTemplate(payload);
		if (response) {
			setConfig(response);
			return;
		}
		setTimeout(() => {
			setIsOpen(false);
		}, 1000);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [templateId, name, email, isVerified, isAllowedToInvite]);

	const handleCloseModal = useCallback(() => {
		setIsOpen(false);
		setConfig({ envelopeId: '', prepareUrl: '', env: '' });
	}, []);

	const navigateToBilling = useCallback(() => {
		navigate('/billing');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const webComponent = useMemo(() => {
		const { prepareUrl, env } = config;
		const signUrl = prepareUrl?.replace(environmentUrl[env], '');
		return signUrl?.trim() ? (
			<SimpliciSignWebComponent signUrl={signUrl} env={env as EnvType} handleSubmitModal={handleSubmitUseTemplateModal} />
		) : (
			<Loader />
		);
	}, [config, handleSubmitUseTemplateModal]);

	const renderSimpliciSignWebComponent = useMemo(() => {
		if (!isLoaded) {
			return (
				<div className="SimpliciUseTemplate__iframe-loading">
					<Loader />
				</div>
			);
		}
		return (
			<>
				{type === 'basic' && provider === 'ESign' ? (
					webComponent
				) : (
					/**
					 * The iframe to be replaced with web-component in the near future
					 * */
					<SimpliciSignIframe
						className="SimpliciUseTemplate__iframe  SimpliciUseTemplate__iframe-wrapper"
						signUrl={config.prepareUrl}
						handleSubmitModal={handleSubmitUseTemplateModal}
					/>
				)}
				<div
					style={{
						position: 'absolute',
						top: 8,
						right: 16,
						zIndex: 1,
						cursor: 'pointer',
					}}
					onClick={handleCloseModal}
				>
					<i className="ri-close-line" />
				</div>
			</>
		);
	}, [isLoaded, type, provider, webComponent, config, handleSubmitUseTemplateModal, handleCloseModal]);

	return (
		<div className="SimpliciUseTemplate">
			<div onClick={handleUseTemplate} className="SimpliciUseTemplate__use-btn">
				Use
			</div>
			{isOpen && (
				<Modal
					isOpen={isOpen}
					modalName=""
					closeModal={handleCloseModal}
					isStopOutsideClick={false}
					showCloseBtn={false}
					closeOnEscBtn={false}
					className="use-template-model"
				>
					{renderSimpliciSignWebComponent}
				</Modal>
			)}
			{subscribeModal && (
				<SubscribeModal
					buttonLabel="Go to billing"
					closeModal={() => setIsSubscribeModal(false)}
					handleButton={navigateToBilling}
					isOpen={subscribeModal}
					modalName=""
					subTitle="You are not subscribe to esign service please go to billing page to subscribe."
					title="Subscription Required"
					modalTitle=""
				/>
			)}
				{isOpen && (
					<NavigateToBackConfirmation
						isModalOpen={isOpen}
						modalCloseHandler={handleCloseModal}
					/>
				)}
		</div>
	);
};
