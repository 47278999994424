import type { ICSVHeader, IFundType, ISetting } from './type';

import { atom } from 'recoil';

import { csvHeaders } from '../../views/captable/constants';

export const UploadCsvStateCaptable = atom<boolean>({
	key: 'upload-csv-state-captable',
	default: true,
});

export const CsvTableAddColumnCaptable = atom<ICSVHeader[]>({
	key: 'csv-column-key-captable',
	default: csvHeaders.HEADER,
});

export const PipelineIdStateCaptable = atom<string>({
	key: 'pipeline-id-investor-key-captable',
	default: '',
});

export const InvestorTableGridDataCaptable = atom<string[][]>({
	key: 'cap-grid-table-captable-captable',
	default: [
		[
			'Investor First Name',
			'Investor Last Name',
			'Investor Entity',
			'Investor Address',
			'Investor Country Code',
			'Investor Mobile',
			'Investors Email',
			'Number of Shares',
			'Price per Share',
			'Dollars Invested',
			'Share Class',
			'Issue Date',
			'Funding Round',
		],
		['', '', '', '', '', '', '', '', '', '', '', '', ''],
	],
});

export const ImportedCaptableFromCSV = atom<boolean>({
	key: 'is-imported-captable-key',
	default: false,
});

export const SelectedInviteFundStateCaptable = atom<{
	label: string;
	value: string;
}>({
	key: 'selected-invite-fund-state-captable',
	default: {
		label: '',
		value: '',
	},
});

export const fundTypesCaptable: { [key: string]: IFundType } = {
	CREATE: 'create',
	EDIT: 'edit',
};

export const SelectedFundTypeCaptable = atom<IFundType>({
	key: 'selected-fund-type-captable',
	default: fundTypesCaptable.CREATE,
});

export const IsCreatingFundStateCaptable = atom<boolean>({
	key: 'is-creating-fund-state-captable',
	default: false,
});

export const IsCapTableLoadingCaptable = atom<boolean>({
	key: 'is-capTable-loading-captable',
	default: false,
});

export const PrivateSettingStateCaptable = atom<ISetting>({
	key: 'captable-setting-state-captable',
	default: {
		theme: 'light',
	},
});

export const TableStateCaptable = atom<any>({
	key: 'captable-table-state-captable',
	default: {},
});

export const CapTableGridTableData = atom<any>({
	key: 'captable-table-state-captable-data',
	default: {},
});

export const CapTableMapKeyAndValue = atom<string[] | any[]>({
	key: 'cap-table-map-key-and-value',
	default: [],
});

export const AddCapTableMapKeyAndValue = atom<string[] | any[]>({
	key: 'add-cap-table-map-key-and-value',
	default: [],
});

export const RequiredField = atom<string[] | any[]>({
	key: 'Required_Field',
	default: [],
});
