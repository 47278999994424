import { Image, Loader } from '@storybook';
import { useCallback, useState } from 'react';
import { getDate, getTime, useFormatNumber } from 'utils';

export const BillingAction: any = {
	kyc: 'KYC',
	'kyc(with liveness)': 'KYC-Liveness',
	aml: 'AML',
	signAgreement_esign: 'esign',
	signAgreement_docusign: 'docusign',
	'accreditation(506b)': 'Accr(506b)',
	'accreditation(506c)': 'Accr(506c)',
	fundInvestment: 'Fund Inv.',
	kyb: 'KYB',
};

export const InvoiceTbody = ({ invoice }: any) => {
	const [isLoading, setIsLoading] = useState(false);

	const { numberDecimal } = useFormatNumber();

	const downloadUrl = useCallback(({ url }: any) => {
		setIsLoading(true);
		window.open(url, '_self');

		setTimeout(() => {
			setIsLoading(false);
		}, 3000);
	}, []);

	return (
		<>
			<td className="invoice-table-data">
				<div className="invoice-table-header_invoice invoice-table-center">
					{invoice.invoiceNumber}
				</div>
			</td>
			<td className="invoice-table-data">
				<div className="invoice-table-center invoice-table-date">
					<div>{getDate(invoice.date)}</div>
					<div>{getTime(invoice.date)}</div>
				</div>
			</td>
			<td className="invoice-table-data">
				<div className="invoice-table-center">
					${numberDecimal(invoice.invoiceAmount)}
				</div>
			</td>
			{/* <td className="invoice-table-data">
				<table className="invoice-table-action">
					<tr>
						{invoice.actions.map((about: any) => (
							<td key={about.itemName} className="invoice-table-action__row">
								<div className="invoice-table-action__value">
									{BillingAction[about.itemName]}
								</div>
							</td>
						))}
					</tr>

					<tr className="invoice-table-action-amount">
						{invoice.actions.map((about: any) => (
							<td key={`invoice-table-action__value__${about?.id}`}>
								<div className="invoice-table-action__value">
									$
									{numberDecimal(
										(about.unit_amount_decimal ?? 0) * (about.quantity ?? 0)
									)}
								</div>
							</td>
						))}
					</tr>
				</table>
			</td> */}
			<td className="invoice-table-data-paid">
				<div className="invoice-table-center">{invoice.status}</div>
			</td>

			<td className="invoice-table-data-files">
				{isLoading ? (
					<div className="invoice-table-data-loader">
						<Loader type='loader' dimension={20} />
					</div>
				) : (
					<a
						rel="noreferrer"
						href={invoice.receiptUrl}
						target="_black"
						style={{
							pointerEvents:
								invoice.status === 'draft' || invoice.status === ''
									? 'none'
									: 'auto',
						}}
					>
						<div
							className="invoice-table-data-download"
							onClick={
								invoice.status === 'draft' || invoice.status === ''
									? undefined
									: () => downloadUrl(invoice)
							}
							style={{
								cursor:
									invoice.status === 'draft' || invoice.status === ''
										? 'not-allowed'
										: 'pointer',
							}}
						>
							{invoice.status === 'draft' || invoice.status === '' ? (
								<div className="invoice-table-download-inprogress-text">{`In Progress`}</div>
							) : (
								<Image
									fileName="pdf.jpeg"
									className="invoice-table-data-download__image"
								/>
							)}
						</div>
					</a>
				)}
			</td>
		</>
	);
};
