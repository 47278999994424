import { API_URL } from 'constant';
import { useNetwork } from 'hooks';
import { useSetRecoilState } from 'recoil';
import {
	UserSubscriptionsState,
	loginState,
	subscriptionServiceState,
} from 'global-stores';

export const useUserInit = () => {
	const setSubscription = useSetRecoilState(subscriptionServiceState);
	const setUserSubscriptions = useSetRecoilState(UserSubscriptionsState);
	const setLogin = useSetRecoilState(loginState);

	const { get } = useNetwork();
	const { get: getSubscriptionType } = useNetwork();
	const initUser = async () => {
		const resp = await get(`${API_URL.CLIENT_SIGNUP}`);
		const subResp = await getSubscriptionType(API_URL.SUBSCRIPTIONS);
		if (resp?.message === 'ok') {
			const { business } = resp?.data?.[0] ?? {};
			const { subscription, name: companyName } = business;
			setSubscription(subscription ?? {});
			setLogin((prev: any) => ({ ...prev, companyName }));
		}
		if (subResp?.message === 'ok') {
			setUserSubscriptions(subResp?.data);
		}
	};

	return {
		initUser,
	};
};
