import { FC } from 'react';
import { IOption } from '@storybook';

import { ReminderInput } from '../reminder-input';
import { SelectComponent } from '../select-component';
import { ReminderDeadLine } from '../reminder-dead-line';

interface IProps {
	label?: string;
	name?: string;
	defaultValue?: IOption;
	selectOptions?: IOption[];
	hasInviteModal?: boolean;
}

export const ReminderPage: FC<IProps> = ({
	label = '',
	name = '',
	defaultValue,
	selectOptions = [],
	hasInviteModal = false,
}) => {
	return (
		<div className="reminder__notification_container">
			<div className="reminder__notification_wrapper">
				<ReminderInput />
				<SelectComponent
					label={label}
					name={name}
					selectOptions={selectOptions}
					defaultValue={defaultValue || { label: '', value: '' }}
					hasInviteModal={hasInviteModal}
				/>
			</div>
			 {/* Renders the ReminderDeadLine component */}
			 <ReminderDeadLine />
		</div>
	);
};
