import { IKycSettingsState, UserRoles } from './type';
import { atom } from 'recoil';
import { v4 } from 'uuid';

const getSettings = () => {
	const localStorageData = localStorage.getItem('theme-color');
	const localStorageFont = localStorage.getItem('theme-font');

	const localStorageLoader = localStorage.getItem('session-loader');
	const localObject = localStorageLoader && JSON.parse(localStorageLoader);

    const { loader, whitelabel, loaderWhitelabel } = localObject ?? {}

	return {
		name: 'Simplici',
		logo: '',
		whitelistedDomains: [],
		whitelabel,
		loader,
		loaderWhitelabel,
		whitelabelKey: '',
		whitelistConfig: {
			_id: '',
		},
		showPricing: true,
		settings: {
			bodyFont: {
				category: 'sans-serif',
				family: localStorageFont ? localStorageFont : 'Poppins',
			},
			brandColor: localStorageData ? localStorageData : '#0051cc',
			headerFont: {
				category: 'sans-serif',
				family: localStorageFont ? localStorageFont : 'Poppins',
			},
			_id: '',
		},
		_id: '',
	};
};

export const kycSettingsState = atom<IKycSettingsState>({
	key: 'kyc-setting-state',
	default: getSettings(),
});

export const IsKycLoading = atom<boolean>({
	key: 'is-kyc-loading',
	default: true,
});

export const UserRolesState = atom<UserRoles[] | null>({
	key: 'user-roles-state',
	default: null,
});

export const RolesTemplateState = atom<UserRoles[] | null>({
	key: 'roles-template-state',
	default: null,
});

export const UserRolesPermissions = atom<UserRoles | null>({
	key: 'user-roles-permission-state',
	default: null,
});

export const IsUserPermissionLoading = atom<boolean>({
	key: 'is-user-permission-loading',
	default: false,
});

export const AssinedUserState = atom<UserRoles | null>({
	key: 'assined-user-state',
	default: null,
});

export const AssinedUserLoadingState = atom<boolean>({
	key: v4(),
	default: false,
});
