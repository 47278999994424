export const transactionHeaders = [
	{
		label: '',
		key: 'checkbox',
		format: 'jsx',
		width: '5%',
	},
	{
		label: 'Sender',
		key: 'sender',
		format: 'string',
		width: '15%',
	},
	{
		label: 'Recipient',
		key: 'recipient',
		format: 'string',
		width: '15%',
	},
	{
		label: 'Amount (USD)',
		key: 'amount',
		format: 'price',
		width: '15%',
	},
	{
		label: 'Type',
		key: 'type',
		format: 'string',
		width: '15%',
	},
	{
		label: 'Transaction Id',
		key: '_id',
		format: 'string',
		width: '20%',
	},
	{
		label: 'Created Date',
		key: 'createdAt',
		format: 'dateTime',
		width: '15%',
	},
];

export const DownloadPdfOptions = [
	{
		complianceData: true,
		label: 'Download with compliance data',
		message: 'Do you want to download with compliance data ?',
	},
	{
		complianceData: false,
		label: 'Download without compliance data',
		message: 'Do you want to download without compliance data ?',
	},
];

export const senderCardInfo = [
	{
		key: 'name',
		label: 'Name :',
	},
	{
		key: 'os',
		label: 'Device OS :',
	},
	{
		key: 'ip',
		label: 'Device IP :',
	},
	{
		key: 'browser',
		label: 'Browser :',
	},
];

export const recipientCardInfo = [
	{
		key: 'name',
		label: 'Name :',
	},
	{
		key: 'os',
		label: 'Device OS :',
	},
	{
		key: 'ip',
		label: 'Device IP :',
	},
];

export enum TransactionType {
	Sender = 'sender',
	Recipient = 'recipient',
}

export enum TransactionIcon {
	sender = 'ri-upload-2-line',
	recipient = 'ri-download-2-line',
}

export const transactionInfo = [
	{
		key: 'transactionId',
		lable: 'Transaction ID',
		dataKey: '_id',
	},
	{
		key: 'transactionDate',
		lable: 'Date & time',
		dataKey: 'createdAt',
	},
];

export const userCardInfo = {
	sender: [
		{
			key: 'name',
			label: 'Name',
		},
		{
			key: 'email',
			label: 'Email',
		},
		{
			key: 'phone',
			label: 'Phone',
		},
		{
			key: 'kycAml',
			label: 'KYC',
		},
	],
	recipient: [
		{
			key: 'name',
			label: 'Name',
		},
		{
			key: 'email',
			label: 'Email',
		},
		{
			key: 'phone',
			label: 'Phone',
		},
		{
			key: 'kycAml',
			label: 'KYC',
		},
	],
};

export const TransactionDeviceInfo = [
	{
		key: 'os',
		infoTypeKey: 'device',
		label: 'Device OS',
		icon: 'ri-smartphone-line',
	},
	{
		key: 'browser',
		infoTypeKey: 'browser',
		label: 'Browser',
		icon: 'ri-global-line',
	},
	{
		key: 'ip',
		infoTypeKey: 'user',
		label: 'User IP',
		icon: 'ri-user-search-line',
	},
	{
		key: 'city',
		infoTypeKey: 'location',
		label: 'City',
		icon: 'ri-user-location-line',
	},
	{
		key: 'region',
		infoTypeKey: 'host',
		label: 'Region',
		icon: 'ri-map-pin-2-fill',
	},
	{
		key: 'countryCode',
		infoTypeKey: 'referrer',
		label: 'Country Code',
		icon: 'ri-flag-line',
	},
	{
		key: 'apple_id',
		infoTypeKey: 'user',
		label: 'Apple ID',
		icon: 'ri-apple-line',
	},
	{
		key: 'device_id',
		infoTypeKey: 'location',
		label: 'Device ID',
		icon: 'ri-device-line',
	},
];
