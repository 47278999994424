import { Image } from '@storybook';
import { usePaymentProcessing } from '../../hooks';

export const PaymentCardProcessing = () => {
	const { processing } = usePaymentProcessing();
	return (
		<div className="payNow-processing">
			<div className="payNow-processing__wrapper">
				<div className="payNow-processing__image-container">
					<Image fileName="business.svg" className="payNow-processing__image" />
				</div>
				<span className="payNow-processing__description">
					Verifying detail and adding payment method
				</span>
				<progress
					value={processing}
					max={100}
					className="payNow-processing__loading-bar"
				/>
			</div>
		</div>
	);
};
