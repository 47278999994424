import { IOption, Input, ReactDropdown, fetchCountryCodeFromPhone } from '@storybook';
import {
	ChangeEvent,
	FC,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AsYouType, CountryCode as CountryType, parsePhoneNumberFromString } from 'libphonenumber-js';
import countries from 'json/country-codes.json';
import { CountryCode } from 'components';
import { InviteUserFromMessages, ROLE, message, validateEmail } from 'constant';
import {
	EditModal,
	FundsState,
	InviteDetailsState,
	InviteEmailInputState,
	InviteFirstNameInputState,
	InviteLastNameInputState,
	InvitePhoneInputState,
	InviteRoleState,
	LoginPhoneNumberState,
	UserRoles,
	UserRolesState,
	loginState,
	phoneNumberDraftState,
} from 'global-stores';
import { SubAccountListState } from 'views/sub-account/hooks';
import classNames from 'classnames';
import { PermissionTag } from './permission-tag';

const createStyle = {
	control: (styles: { [key: string]: number | string }, state: { isFocused: boolean }) => ({
		...styles,
		backgroundColor: '#f5f8ff',
		minHeight: 52,
		border: state.isFocused ? '1px solid var(--color-primary-light)' : '1px solid rgba(215, 223, 245, 1)',
		borderRadius: 8,
		fontWeight: '500',
		color: '#fff',
		boxShadow: 'none',
		'&:hover': {
			cursor: 'pointer',
			borderColor: state.isFocused ? 'var(--color-primary-light)' : 'f5f8ff',
		},
		'&:focus': {
			borderColor: 'red',
		},
	}),
	placeholder: (styles: { [key: string]: any }) => ({
		...styles,
		paddingLeft: 7, 
		fontWeight: '400',
		marginLeft: 0,
		marginRight: 0,
		fontSize: 16,
	}),
	menu: (styles: { [key: string]: any }) => ({
		...styles,
		background: '#f5f8ff',
		borderRadius: 8,
		border: '1px solid #ced2de',
	}),
	menuList: (styles: any) => ({
		...styles,
		maxHeight: 190,
	}),
	multiValue: (styles: { [key: string]: any }) => ({
		...styles,
		backgroundColor: 'rgba(224, 233, 255, 1)',
		color: 'rgba(47, 50, 61, 1)',
		borderRadius: 50,
		paddingLeft: 8,
		paddingRight: 8,
		paddingTop: 4,
		paddingBottom: 4,
		margin: 4,
	}),
	multiValueRemove: (styles: any) => ({
		...styles,
		color: 'rgba(163, 177, 214, 1)',
		borderRadius: 50,
		marginLeft: 2,
		fontSize: 12,
	}),
	option: (styles: { [key: string]: any }) => {
		return {
			...styles,
			backgroundColor: '#f5f8ff',
			color: '#000',
			height: 48,
			cursor: 'pointer',
			paddingTop: 12,
			paddingBottom: 12,
			borderBottom: '1px solid #ced2de',
			fontWeight: '500',
			'&:hover': {
				color: '#fff',
				backgroundColor: '#3c65d6',
			},
			'&:last-child': {
				borderBottom: 'none',
			},
		};
	},
};
interface IInviteUserFrom {
	isDisableRole?: boolean;
	disableSubAccount?: boolean;
}

export const InviteUserFrom: FC<IInviteUserFrom> = ({
	isDisableRole = false,
	disableSubAccount = false,
}) => {
	const [inviteFunds, setInviteFunds] = useState<any>([]);
	const [showFirstNameError, setShowFirstNameError] = useRecoilState(
		InviteFirstNameInputState
	);
	const [showPhoneError, setShowPhoneError] = useRecoilState(
		InvitePhoneInputState
	);
	const [userPhoneNumber, setLoginPhoneNumber] = useRecoilState(
		LoginPhoneNumberState
	);
	const [phoneNumberDraft, setPhoneNumberDraft] = useRecoilState(phoneNumberDraftState);
	const [countryCode, setCountry] = useRecoilState(CountryCode);
	const [showLastNameError, setShowLastNameError] = useRecoilState(
		InviteLastNameInputState
	);
	const [showInviteEmailError, setShowInviteEmailError] = useRecoilState(
		InviteEmailInputState
	);
	const [showInviteRoleError, setShowInviteRoleError] = useRecoilState(
		InviteRoleState
	);

	const [isOpenDropdownModal, setIsOpenDropdownModal] = useState(false);

	const subAccountRows = useRecoilValue(SubAccountListState);
	const userRoles = useRecoilValue(UserRolesState);

	const [changeFirstNameError, setChangeFirstNameError] = useState(false);
	const [changeLastNameError, setChangeLastNameError] = useState(false);
	const [changeEmailError, setChangeEmailError] = useState(false);

	const edit = useRecoilValue(EditModal);

	const funds = useRecoilValue(FundsState);
	const [invites, setInvites] = useRecoilState(InviteDetailsState);
	const inviteFundsEdit = useRecoilValue<any>(InviteDetailsState);

	const [selectedRoles, setSelectedRole] = useState<string>();

	const { role: loginUserRole } = useRecoilValue(loginState);
	const { OWNER } = useMemo(() => ROLE, []);

	const {
		VALID_NAME_MESSAGE,
		EMAIL_REQUIRED_MESSAGE,
		VALID_NO_SPECIAL_NUMBER_MESSAGE,
		EMAIL_NOT_VALID_MESSAGE,
	} = useMemo(() => message, []);

	const onChangeInput = useCallback(
		(name: string, e: ChangeEvent<HTMLInputElement>) => {
			const { value } = e.target;
			const nameRegex = /^[a-zA-Z0-9]+$/;
			if (name === 'firstName') {
				if (!nameRegex.test(value)) {
					setChangeFirstNameError(true);
					setShowFirstNameError(true);
				}
				if (value.length < 3) {
					setChangeFirstNameError(false);
					setShowFirstNameError(true);
				}
				if (nameRegex.test(value) && value.length > 2) {
					setChangeFirstNameError(false);
					setShowFirstNameError(false);
				}
			}
			if (name === 'lastName') {
				if (!nameRegex.test(value)) {
					setChangeLastNameError(true);
					setShowLastNameError(true);
				}
				if (value.length < 3) {
					setChangeLastNameError(false);
					setShowLastNameError(true);
				}
				if (nameRegex.test(value) && value.length > 2) {
					setChangeLastNameError(false);
					setShowLastNameError(false);
				}
			}
			if (name === 'email') {
				if (!validateEmail(value)) {
					setChangeEmailError(true);
					setShowInviteEmailError(true);
				}
				if (value.length < 1) {
					setChangeEmailError(false);
					setShowInviteEmailError(true);
				}
				if (validateEmail(value) && value.length > 0) {
					setChangeEmailError(false);
					setShowInviteEmailError(false);
				}
			}

			setInvites(prev => {
				const prevState = JSON.parse(JSON.stringify(prev));
				prevState[name] = value;
				return { ...prevState };
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);


	useEffect(() => {
		const fundValues: IOption = [];
		funds.forEach(fund =>
			fundValues.push({ label: fund.name, value: fund.id })
		);
		if (fundValues.length) {
			setInviteFunds(fundValues);
		}
	}, [funds]);

	const handleChange = useCallback(
		(e: IOption, name: string) => {
			setInvites((prev: IOption) => {
				const prevState = { ...prev, [name]: e };
				return { ...prevState };
			});
		},
		[setInvites]
	);

	const handleErrorMsg = useCallback((parsedPhoneNumber: any, countryName: string | undefined) => {
		if (!parsedPhoneNumber || (parsedPhoneNumber && parsedPhoneNumber.isValid())) {
			setShowPhoneError("");
		} else if (countryName && countryName.length > 0) {
			setShowPhoneError(`Mobile number is invalid for ${countryName}`);
		} else {
			setShowPhoneError(`Invalid mobile number`);
		}
	}, [setShowPhoneError]);

	const getPhoneNumber = useMemo(() => {
		let countryLabel: CountryType = 'US';

		const countryObj = countries.find(item => item.label === countryCode);

		if (countryObj) countryLabel = countryObj.code as CountryType;

		let formattedNumber = '';
		const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumberDraft, countryLabel);
		handleErrorMsg(parsedPhoneNumber, countryObj?.value)
		if (phoneNumberDraft.length > 6) {
			formattedNumber = new AsYouType(countryLabel).input(phoneNumberDraft);
			if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
				formattedNumber = parsedPhoneNumber.formatNational();
				// Remove leading zero if it exists
				if (formattedNumber.startsWith('0')) {
					formattedNumber = formattedNumber.slice(1);
				}
				// Remove the country calling code prefix
				return formattedNumber.replace(`+${parsedPhoneNumber.countryCallingCode} `, '');
			}
		} else {
			formattedNumber = phoneNumberDraft;
		}
		return formattedNumber;
	}, [countryCode, handleErrorMsg, phoneNumberDraft]);

	// New function to handle country code changes from the dropdown
	const onCountryChange = useCallback((selectedCountry: IOption) => {
		const countryCodeLabel = selectedCountry.label;
		setCountry(countryCodeLabel);
		setLoginPhoneNumber(prevState => ({
			...prevState,
			countryCode: countryCodeLabel,
		}));
		setPhoneNumberDraft((prevNumber) => {
			const formatted = new AsYouType(countryCodeLabel as CountryType).input(prevNumber);
			return formatted;
		});
	}, [setCountry, setLoginPhoneNumber, setPhoneNumberDraft]);
	
	const onHandleChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement> | any) => {
			const { value } = e.target;
	
			// Remove spaces, parentheses (), and dashes - before processing
			let sanitizedPhoneNumber = value.replace(/[\s()-]/g, '');
			let detectedCountryCode = countryCode;
			if (sanitizedPhoneNumber.startsWith('+')) {
				const detectedCode = fetchCountryCodeFromPhone(sanitizedPhoneNumber);
				if (detectedCode) {
					sanitizedPhoneNumber = sanitizedPhoneNumber.replace(detectedCode, '').replace(/\D/g, '');
					setCountry(detectedCode);
					detectedCountryCode = detectedCode;
				} else {
					sanitizedPhoneNumber = sanitizedPhoneNumber.replace(/\D/g, '');
				}
			} 
	
			// Update the state only if the input is valid
			if ((/^\d+$/.test(sanitizedPhoneNumber) || sanitizedPhoneNumber === '') && sanitizedPhoneNumber.length <= 12) {
				setLoginPhoneNumber(prevState => ({
					...prevState,
					phone: sanitizedPhoneNumber,
					countryCode: detectedCountryCode || prevState.countryCode,
				}));
				
				setPhoneNumberDraft(sanitizedPhoneNumber);
				
				setInvites((prev: IOption) => ({
					...prev,
					phone: sanitizedPhoneNumber,
					countryCode: detectedCountryCode || prev.countryCode,
				}));
				
			}
		},
		[countryCode, setCountry, setLoginPhoneNumber, setPhoneNumberDraft, setInvites]
	);


	// select after back of edit of invite form
	useEffect(() => {
		const role = userRoles?.find(el => el._id === invites?.role);
		setSelectedRole(role?.name ?? '');
	}, [userRoles, invites]);

	const subAccountOptions = useMemo(
		() => subAccountRows?.map(item => ({ label: item.name, value: item._id, status: item.approveStatus })),
		[subAccountRows]
	);

	const handleClickDropDown = useCallback(
		(e: React.MouseEvent<HTMLInputElement>) => {
			e.stopPropagation();
			setIsOpenDropdownModal(prev => !prev);
		},
		[]
	);

	const handleSelectDropdown = useCallback(
		(e: React.MouseEvent<HTMLDivElement>, userRole: UserRoles) => {
			e.stopPropagation();
			setSelectedRole(userRole?.name ?? '');
			setInvites((prev: IOption) => {
				const prevState = {
					...prev,
					role: userRole?._id ?? '',
				};
				if (!disableSubAccount) {
					prevState.subAccount = null
				}
				return { ...prevState };
			});
			if (disableSubAccount && userRole?._id) {
				const role = userRoles?.find(i => i._id === userRole?._id)?.services?.includes('onboarding')
				setShowInviteRoleError(!role)
			}
			setIsOpenDropdownModal(prev => !prev);
		},
		// eslint-disable-next-line
		[]
	);

	const isSubAccountValid = useMemo(() => {
		if (subAccountOptions && subAccountOptions.length && inviteFundsEdit?.subAccount?.value) {
			const account = subAccountOptions.filter(item => inviteFundsEdit?.subAccount?.value === item.value && item.status === 'APPROVED')
			return !account.length ? InviteUserFromMessages.subAccountVerification : ''
		}
		return ''
	}, [inviteFundsEdit?.subAccount, subAccountOptions]);

	const disableRoleClass = useMemo(() => {
		return classNames('invite_roles--container', {
			'invite_roles--container_disableRole': isDisableRole,
		});
	}, [isDisableRole]);

	return (
		<div className="invite invite__form">
			<div className="invite__name">
				<Input
					inputType="text"
					label="First Name"
					handleChange={e => onChangeInput('firstName', e)}
					placeholder="Enter first name"
					isError={showFirstNameError}
					errorMessage={
						changeFirstNameError
							? VALID_NO_SPECIAL_NUMBER_MESSAGE
							: VALID_NAME_MESSAGE
					}
					value={invites.firstName}
					disabled={edit}
					isRequired
				/>
				<Input
					inputType="text"
					label="Last Name"
					handleChange={e => onChangeInput('lastName', e)}
					placeholder="Enter last name"
					isError={showLastNameError}
					errorMessage={
						changeLastNameError
							? VALID_NO_SPECIAL_NUMBER_MESSAGE
							: VALID_NAME_MESSAGE
					}
					value={invites.lastName}
					disabled={edit}
					isRequired
				/>
			</div>
			<div className="invite__email">
				<Input
					handleChange={onHandleChange}
					inputType="text" //keep type text as number is not giving suggestions for auto fill will use regex to accept number
					value={getPhoneNumber || userPhoneNumber.phone}
					placeholder={``}
					label="Mobile Number"
					handleChangeCountry={onCountryChange}
					isCountryCodeVisible={true}
					countryCode={countryCode}
					isError={!!showPhoneError}
					errorMessage={showPhoneError}
					autoComplete="tel"
					inputName="phone"
					disabled={edit}
					isRequired
				/>
			</div>
			<div className="invite__email">
				<Input
					inputType="text"
					placeholder="Enter email address"
					label="Email"
					value={invites.email}
					isError={showInviteEmailError}
					errorMessage={
						changeEmailError ? EMAIL_NOT_VALID_MESSAGE : EMAIL_REQUIRED_MESSAGE
					}
					handleChange={e => onChangeInput('email', e)}
					disabled={edit}
					isRequired
				/>
			</div>
			<div className="invite__funds">
				<div className="invite__funds__label">Funds</div>
				<ReactDropdown
					options={inviteFunds}
					optionsStyle={{
						'&:hover': { background: 'var(--color-hover-light-100)' },
					}}
					defaultValue={inviteFunds['label']}
					value={inviteFundsEdit?.funds}
					isMulti={true}
					closeMenuOnSelect
					handleChangeSelect={e => handleChange(e, 'funds')}
					createStyle={createStyle}
					placeholder="Select your funds"
				/>
			</div>

			<div className="invite_roles">
				<div className="invite__funds__label">
					Role <span className="input__isRequired"> *</span>
				</div>

				<div className={disableRoleClass}>
					<div className="dropdown--container" onClick={handleClickDropDown}>
						<div className="dropdown--container__value">
							{selectedRoles  ? (
								selectedRoles.charAt(0).toUpperCase() +
								selectedRoles.slice(1).toLowerCase()
							) : (
								isOpenDropdownModal &&
								<span className="dropdown--container--label">
									Select your role
								</span>
							)}
						</div>
						<div className="dropdown--container__icon">
							<i
								className={`ri-arrow-down-s-line ${
									isOpenDropdownModal && !isDisableRole && 'icon-active'
								}`}
							></i>
						</div>
					</div>
					{isOpenDropdownModal && !isDisableRole && (
						<div className="dropdown-modal">
							{(userRoles ?? [])?.map((role: UserRoles) => (
								<div
									className="dropdown-modal--container"
									key={role?._id}
									onClick={(e: React.MouseEvent<HTMLDivElement>) =>
										handleSelectDropdown(e, role)
									}
								>
									<div className="dropdown-modal--container__header">
										<div className="dropdown-modal--container__header--label">
											{role?.name
												? role?.name.charAt(0).toUpperCase() +
												  role?.name.slice(1).toLowerCase()
												: 'NA'}
											<div className="dropdown-modal--container__circle"></div>
											<div className="dropdown-modal--container__price">
												${role?.price ?? 'NA'}
												<span className="dropdown-modal--container__price-per-month">
													/month
												</span>
											</div>
										</div>
										<div className="dropdown-modal--container__header--subTitle">
											{role?.description ? role?.description : 'NA'}
										</div>
									</div>
									<PermissionTag role={role} />
								</div>
							))}
						</div>
					)}
				</div>
				{showInviteRoleError && (
					<span className="Error--message">
						{InviteUserFromMessages.roleVerification}
					</span>
				)}
			</div>
			{/* Don't have permission to see sub account on invite user label */}
			{loginUserRole === OWNER &&
				(isOpenDropdownModal && isDisableRole
					? userRoles
							?.find(i => i._id === invites.role)
							?.services?.includes('onboarding')
					: true) && (
					<div className="invite__funds">
						<div className="invite__funds__label">Sub account</div>
						<ReactDropdown
							options={subAccountOptions ?? []}
							optionsStyle={{
								'&:hover': { background: 'var(--color-hover-light-100)' },
							}}
							defaultValue={inviteFunds['label']}
							value={inviteFundsEdit?.subAccount}
							handleChangeSelect={e => handleChange(e, 'subAccount')}
							createStyle={createStyle}
							Isdisable={disableSubAccount}
							placeholder="Select your Sub Account"
							errorMessage={isSubAccountValid}
						/>
					</div>
				)}
			{/* <div className="invite__message">
				<Message
					text={'Note: Control the API flow by opting the developer option'}
					varient={'warning'}
				/>
			</div> */}
		</div>
	);
};
