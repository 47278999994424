import { FC } from 'react';
import { Label } from '../condition-label';

export interface IRenderBlock {
	label?: string | undefined;
	price: number;
}

export const RenderBiometricBlock: FC<IRenderBlock> = ({ price }) => {
	// Global variables

	// Local variables

	return (
		<>
			<Label label={'Biometric'} price={price} />
			<div className="questions-wrapper"></div>
		</>
	);
};
