import { useEffect, useRef, useState } from 'react';

const TIMER = 20;

export const useTimer = () => {
	const intervalRef = useRef<NodeJS.Timeout | null>(null);
	const [seconds, setSeconds] = useState(TIMER);

	useEffect(() => {
		intervalRef.current = setInterval(() => {
			setSeconds(prevSeconds => {
				if (prevSeconds > 0) {
					return prevSeconds - 1;
				} else return 0;
			});
		}, 1000);

		return () => clearInterval(intervalRef.current as NodeJS.Timeout);
	}, []);

	return {
		seconds,
		setSeconds,
	};
};
