import { Input } from '@storybook';
import { ChangeEvent, FC, useCallback, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
	IsPipelineFlowErrorMessage,
	PipelineSettingsFormState,
	kycSettingsState,
} from 'global-stores';
import { isNumber, validURL } from 'utils';
import { ComplexSettingFormState } from '../store';
import { PublishErrorMessage } from './constants';

interface IInputComponent {
	label: string;
	name: string;
	labelSize?: "large" | "small";
	defaultValue?: string;
	description?: string;
	id?: string;
	disable?: boolean;
	placeholder?: string;
}

export const InputComponent: FC<IInputComponent> = ({
	label,
	name,
	defaultValue,
	description,
	labelSize = "large",
	id,
	disable = false,
	placeholder
}) => {
	//globle states
	const [settingsForm, setSettingsForm] = useRecoilState(
		PipelineSettingsFormState
	);
	const kycSettings = useRecoilValue(kycSettingsState);
	const [complexSettingForm, setComplexSettingForm] = useRecoilState(
		ComplexSettingFormState
	);

	// local state
	const [isInputError, setIsInputError] = useRecoilState(
		IsPipelineFlowErrorMessage
	);

	// hooks

	const { whitelistConfig } = useMemo(() => kycSettings, [kycSettings]);

	// Setting default value for complex config
	useEffect(() => {
		if (id && !complexSettingForm?.[id]) {
			const payload = {
				[name]: defaultValue,
			};
			setComplexSettingForm((prev: any) => {
				const newObj = structuredClone(prev);
				if (!newObj[id]) {
					newObj[id] = {};
				}
				Object.assign(newObj[id], payload);
				return newObj;
			});
		}
	}, [id, name, complexSettingForm, defaultValue, setComplexSettingForm]);

	const handleChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const { value } = e.target;
			setIsInputError(prev => ({ ...prev, [name]: false }));
			if (name === 'publishRedirect' && !validURL(value)) {
				setIsInputError(prev => ({ ...prev, [name]: true }));
			}

			if (name === 'publishName' && !value.trim()) {
				setIsInputError(prev => ({ ...prev, [name]: true }));
			}
			if (name === 'publishWebhook' && !validURL(value)) {
				setIsInputError(prev => ({ ...prev, [name]: true }));
			}

			if (name === 'kybMatches' && (!isNumber(value) || parseInt(value) > 10)) {
				setIsInputError(prev => ({ ...prev, [name]: true }));
				return;
			}
			if (
				(name === 'publishRedirect' || name === 'publishWebhook') &&
				!value.trim()
			) {
				setIsInputError(prev => ({ ...prev, [name]: false }));
			}

			if (
				name === 'kybMatchThreshold' &&
				value !== '' &&
				(Number(value) > 1 || Number.isNaN(Number(value)))
			) {
				setIsInputError(prev => ({ ...prev, [name]: true }));
				return;
			}

			setSettingsForm(prev => {
				const prevState = { ...prev };
				prevState[name] = value;
				return { ...prevState };
			});

			//TODO shabaaaz
			setComplexSettingForm((prev: any) => ({
				...prev,
				[name]: value,
			}));
			//we have created another state as there could be multiple kyc aml
			if (id) {
				const payload = {
					[name]: value,
				};
				setComplexSettingForm((prev: any) => {
					const newObj = structuredClone(prev);
					if (!newObj[id]) {
						newObj[id] = {};
					}
					Object.assign(newObj[id], payload);
					return newObj;
				});
			}
		},
		[id, name, setComplexSettingForm, setIsInputError, setSettingsForm]
	);

	const handleFocusInput = useCallback(() => {
		if (name !== 'publishName') return;
		
		if (!settingsForm?.publishName?.trim()) {
			setIsInputError(prev => ({ ...prev, [name]: true }));
		} else {
			setIsInputError(prev => ({ ...prev, [name]: false }));
		}
	}, [name, setIsInputError, settingsForm?.publishName]);

	const handleBlurInput = useCallback(() => {
		if (!settingsForm.publishName) {
			setIsInputError(prev => ({ ...prev, [name]: true }));
		}
	}, [name, setIsInputError, settingsForm.publishName]);

	const isDisabled = useMemo(() => {
		return (
			(label === 'Sender Name' &&
				Object.keys(whitelistConfig ?? {}).length <= 1) ||
			disable
		);
	}, [label, whitelistConfig, disable]);

	const initialValue = useMemo(() => {
		return settingsForm[name] as string;
	}, [settingsForm, name]);

	return (
		<Input
			label={label}
			handleChange={handleChange}
			placeholder={(placeholder || defaultValue) ?? `Please Enter ${label}`}
			allowClear={false}
			handleBlur={handleBlurInput}
			handleFocus={handleFocusInput}
			labelSize={labelSize}
			autoFocus={name === 'publishName'? true : false}
			inputType="text"
			isError={
				isInputError[
					name as
						| 'publishRedirect'
						| 'publishRedirect'
						| 'kybMatches'
						| 'publishName'
				]
			}
			value={initialValue}
			errorMessage={PublishErrorMessage[name]}
			description={description}
			disabled={isDisabled}
		/>
	);
};
