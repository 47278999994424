
import { Button, Loader } from '@storybook';
import { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import Modal from '@storybook/new-modal/modal';
import { BusinessSession, KycWebComponents } from 'components';
import { BusinessRegistrationState } from 'global-stores';
import { useLogout, useNetwork } from 'hooks';
import { REJECTED_CONSTANTS } from './constants';
import { useCompliance } from 'routes/hooks';
import { AccountDetails } from 'views/account-details';
import { IdentityType } from 'views/user-identity-flow';
import classNames from 'classnames';
import { StepStatus, TimeLine } from 'views/business-processing/components';
import { AcountUserTypes } from 'views/settings';
import './business-rejected.scss';
import { SignUpSessionStatusState } from 'views/new-user-popup';
import { ISessionDataKeys } from 'views/new-user-popup/stores/types';
import { API_URL } from 'constant';

export const BusinessRejected = () => {
	const businessRegistration = useRecoilValue(BusinessRegistrationState);
	const { logout, loading } = useLogout();
	const businessSessionDetails = useRecoilValue(SignUpSessionStatusState);
	const { post } = useNetwork();
	const [isLoading, setIsLoading] = useState(false);

	const [openModal, setOpenModal] = useState({
		open: false,
		action: '',
	});
	const { getComplianceSessionStatus } = useCompliance();

	const {
		kyb,
		kyc,
		accountType = '',
		aml,
		kybForm,
	} = useMemo(() => businessRegistration ?? {}, [businessRegistration]);

	const { status: kycStatus = '' } = useMemo(
		() => kyc?.data ?? kyc ?? {},
		[kyc]
	);

	const { status: kybStatus = '' } = useMemo(
		() => kyb?.lexisNexisReport ?? kyb ?? {},
		[kyb]
	);

	const handleCloseModal = async () => {
		setOpenModal({ open: false, action: '' });
	};

	const handleSuccess = async () => {
		handleCloseModal();
		await getComplianceSessionStatus(accountType, true);
	};

	const handleOpenModal = (action: string) => {
		setOpenModal({ open: true, action });
	};

	const nodeIds: string[] = useMemo(() => {
		const rejectedSessionsList: string[] = [];
		const filteredSessions = businessSessionDetails?.sessionDetails[0]?.sessionData;
		const keys: ISessionDataKeys[] = [
			'userInfo', 'authentication', 'kyb', 'kyc', 'status', 'activities',
			'metadata', 'visitedOrder', 'kybForm', 'aml', 'accountType'
		];

		if (filteredSessions) {
			keys.forEach((key) => {
				const sessionArray = filteredSessions[key];

				if (sessionArray && Array.isArray(sessionArray) && typeof sessionArray[0] === 'object' && 'data' in sessionArray[0]) {
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
					if (Array.isArray(sessionArray[0].data) && ['rejected'].includes(sessionArray[0].data[0]?.status!)) {
						rejectedSessionsList.push(sessionArray[0]._id);
					} else if ('status' in sessionArray[0].data && ['rejected'].includes(sessionArray[0].data.status)) {
						rejectedSessionsList.push(sessionArray[0]._id);
					}
				}
			});
		}
		return rejectedSessionsList
	}, [businessSessionDetails])

	const handleReintiate = async (action: string) => {
		if (nodeIds?.length) {
			setIsLoading(true)
			// Perform the asynchronous POST request and await the response
			await post(API_URL.NODE_STEPS, {
				nodeIds: nodeIds
			});
			setIsLoading(false)
		}
		// If needed, you can open the modal or perform any other post-await actions
		setOpenModal({ open: true, action });
	};


	const type = useMemo(() => {
		if (
			['completed', 'rejected'].includes(kycStatus) &&
			['completed', 'rejected'].includes(kybStatus)
		) {
			return REJECTED_CONSTANTS.KYB;
		}
		if (['completed', 'rejected'].includes(kycStatus)) {
			return REJECTED_CONSTANTS.KYC;
		}

		if (['completed', 'rejected'].includes(kybStatus)) {
			return REJECTED_CONSTANTS.KYB;
		}

		return REJECTED_CONSTANTS.KYC;
	}, [kybStatus, kycStatus]);

	const renderButonLabel = useMemo(() => {
		if (kybStatus === 'rejected' && kycStatus === 'rejected') {
			return REJECTED_CONSTANTS.REINITIATE;
		}
		if (kycStatus === 'rejected') {
			return REJECTED_CONSTANTS.REINITIATE_KYC;
		}

		if (kybStatus === 'rejected') {
			return REJECTED_CONSTANTS.REINITIATE_KYB;
		}

		return REJECTED_CONSTANTS.REINITIATE;
	}, [kybStatus, kycStatus]);

	const renderModalBody = useMemo(() => {
		switch (openModal.action) {
			case REJECTED_CONSTANTS.VIEW_REPORT:
				return <BusinessSession />;
			case REJECTED_CONSTANTS.REINITIATE:
				return (
					<KycWebComponents
						type={accountType === IdentityType.Company ? 'kyb' : 'kyc'}
						businessType={accountType}
						handleSuccess={handleSuccess}
					// reInitiate
					/>
				);
			default:
				return null;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openModal.action, type, accountType]);

	const logoutUser = () => {
		if (loading) return;
		logout();
	};

	const businessRejectedModal = classNames(
		'Business-Rejected-Modal Business-Rejected-Session-Modal',
		{
			'Business-Rejected-Reinit-Modal':
				openModal.action !== REJECTED_CONSTANTS.VIEW_REPORT,
		}
	);

	return (
		<section className="Business-Rejected">
			<div className="Business-Rejected--body">
				<div className="Business-Processing--body">
					<div className="Business-Processing--header">
						<h1>Submitted for review</h1>
						<p>
							We&apos;ll review your information. Once your account get verified
							you can start using the platform.
						</p>
					</div>
					<div className="Business-Processing--body-container">
						<div className="Business-Processing--left">
							<StepStatus statusLabel="KYC" status={kycStatus} />
							<StepStatus statusLabel="AML" status={aml?.status} />
							{accountType === AcountUserTypes.Entity && (
								<>
									<StepStatus statusLabel="KYB" status={kybStatus} />
									<StepStatus statusLabel="KYB Form" status={kybForm?.status} />
								</>
							)}
						</div>

						<div className="Business-Processing--right">
							<TimeLine
								fromRejected
								className="circle-completed"
								viewReportContent={
									<div className="Business-Processing--view-report">
										Please{' '}
										<span
											className="Business-Rejected__ViewTheReport"
											onClick={() =>
												handleOpenModal(REJECTED_CONSTANTS.VIEW_REPORT)
											}
										>
											view the report
										</span>{' '}
										for details.
									</div>
								}
							>
								<div className="step step-active">
									<div>
										<div className="circle circle-rejected"></div>
									</div>
									<div>
										<h5 className="title title--rejected">Account rejected</h5>
										<p className="caption title--rejected">
											We regret to inform you that your verification has failed.
										</p>
									</div>
								</div>
							</TimeLine>
						</div>
					</div>
					<AccountDetails accountType={accountType} />
				</div>
				<div className="Business-Rejected-Buttons">
					<Button
						label="Logout"
						type="button__filled--secondry Business-Rejected-logout"
						handleClick={logoutUser}
						disabled={loading}
						prefixIcon="ri-logout-box-r-line"
					/>
					<Button
						label={isLoading ? <Loader dimension={20} type='loader' /> : renderButonLabel}
						type="button__filled--primary btn-label"
						handleClick={() => { handleReintiate(REJECTED_CONSTANTS.REINITIATE) }}
						disabled={loading}
					/>
				</div>
			</div>
			{openModal.open && (
				<Modal
					modalName="kyc"
					isOpen={openModal.open}
					closeModal={handleCloseModal}
					isStopOutsideClick={false}
					closeOnEscBtn={false}
					className={businessRejectedModal}
					isCloseButtonSticky={true}
				>
					<div className="Business-Rejected-Modal__wrapper">
						{renderModalBody}
					</div>
				</Modal>
			)}
		</section>
	);
};
