export const headerNavs = [
	{
		title: 'General Settings',
		key: 'generalSettings',
	},
	// {
	//     title : "Profile",
	//     key: "profile"
	// }
];
