import type { ICardBrandLogo } from './type';
import type { IAddedCard } from 'global-stores/settings/billing/type';

import { FC, useCallback, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Image } from '@storybook';
import { maskedDot } from 'utils';
import { PayNowCardId, useInviteOnboardingPayment } from '../../store';
import { PaymentCardMethodsState, loginState } from 'global-stores';

type IRenderCardLogo = {
	[key in ICardBrandLogo]: string;
};

// the will be used to show the logo of the billing card
const renderCardLogo: IRenderCardLogo | any = {
	Visa: 'visa-card.svg',
	MasterCard: 'master-card.svg',
	Discover: 'discover-card.svg',
	MaestroCard: 'maestro-card.svg',
	AmericanExpress: 'american-express-card.svg',
	Default: 'default-card.svg',
};

export const BusinessCard: FC<{
	card: IAddedCard;
	length: number;
	index: number;
}> = ({ card, length: cardsLength }) => {
	// global states
	const { role } = useRecoilValue(loginState);
	const [defaultId, setDefaultId] = useRecoilState(PayNowCardId);
	const paymentCardMethodsState = useRecoilValue(PaymentCardMethodsState);
	// hooks
	const { deleteBusinessCard } = useInviteOnboardingPayment();

	const defaultCard = useMemo(
		() => paymentCardMethodsState?.cards?.[0] ?? {},
		[paymentCardMethodsState?.cards]
	);
	const { brand, exp_month, exp_year, id, last4 } = useMemo(
		() => card ?? {},
		[card]
	);
	const current = new Date();
	const year = current.getFullYear();
	const month = current.getMonth();

	// this will manage the setting of the default card
	const onSetDefault = (id: string) => () => {
		setDefaultId(id);
	};

	const isCardExpired = useMemo(() => {
		return (
			card?.exp_year < year ||
			card?.exp_month > 12 ||
			//Gaurav: < replace to <= because getMonth() returns the month (0 to 11) of a date.
			(card?.exp_year === year && card?.exp_month <= month)
		);
	}, [card?.exp_year, card?.exp_month, month, year]);

	const defaultChecked = useMemo(
		() => id === defaultCard?.id,
		[id, defaultCard]
	);

	useEffect(() => {
		setDefaultId(defaultCard?.id);
	}, [defaultCard, setDefaultId]);

	//this will remove the card
	const handleRemoveCard = useCallback(() => {
		deleteBusinessCard(id, defaultChecked);
	}, [defaultChecked, deleteBusinessCard, id]);

	return (
		<div
			className={`payment-save-card-list__saved ${
				id === defaultId ? 'payment-save-card-list__active' : ''
			} multi-card`}
		>
			<label>
				<div className="payment-save-card-list__saved_card">
					<input
						type="radio"
						name="drone"
						id="primary"
						checked={id === defaultId}
						className="payment-save-card-list__radio"
						onClick={onSetDefault(id)}
						disabled={isCardExpired}
					/>

					<Image
						fileName={renderCardLogo[brand] ?? renderCardLogo['Default']}
						className="business-card-logo"
					/>
					<div className="payment-card-detail">
						<div className="payment-save-card-list__number">
							{maskedDot(last4 ?? '')}
						</div>
						<div className="payment-save-card-list__expire">
							Expires {exp_month}/{exp_year}
						</div>
					</div>
					{/* Gaurav :  Remove from above because it was not in center */}
					{isCardExpired ? (
						<span>
							<span className="payment-save-card-list__expired">Expired</span>
						</span>
					) : (
						' '
					)}
				</div>
			</label>
			{role === 'OWNER' && cardsLength > 1 && !defaultChecked && (
				<div
					className="payment-save-card-list__remove_btn remove-card-btn"
					onClick={handleRemoveCard}
				>
					Remove
				</div>
			)}
		</div>
	);
};
