import { FC, useEffect, useMemo } from 'react';
import { SessionDetailsHeading } from './session-details-heading';
import { Survey } from 'survey-react-ui';
import { Model } from 'survey-core';
import 'survey-core/defaultV2.min.css';
import 'survey-core/defaultV2.css';
import 'survey-creator-core/survey-creator-core.css';
import '../../../components/survey-form-builder/survay-form-builder.scss';
import { ActionButton } from './action';
import { IDynamicForm } from '../store';
import { useSetRecoilState } from 'recoil';
import { InvestorsCount, InvestorsDetails, IsInvestorsDetailsDisabled } from 'components/investor-questionnair/investor-details-form';
// import { ActionButton } from './action';

export interface MultipleChoice {
	label: string;
	key: string;
}

export interface IFormOne {
	multipleChoice: MultipleChoice[];
}

export interface IFormContainer {
	conditions: IFormOne[];
	choices: string[];
}

interface IDynamicFormContainer {
	dynamicForm: IDynamicForm;
	isLoading: boolean;
	nodeId?: string;
	isUserPermissionWrite?: boolean;
	role:string;
	isAgentPermitted?: boolean;
}

export const DynamicFormContainer: FC<IDynamicFormContainer> = ({
	dynamicForm,
	isLoading,
	nodeId,
	isUserPermissionWrite = true,
	role,
	isAgentPermitted,
}) => {
	const setInvestorsCount = useSetRecoilState(InvestorsCount);
	const setInvestorsDetails = useSetRecoilState(InvestorsDetails);
	const setInvestorsDetailsDisabled = useSetRecoilState(IsInvestorsDetailsDisabled);

	const survey = useMemo(() => {
		return new Model({
			description: '',
			logoPosition: '',
			pages: dynamicForm?.question?.formAction?.pages,
			showQuestionNumbers: 'off',
			title: '',
		});
	}, [dynamicForm]);

	useEffect(() => {
		survey.data = dynamicForm?.data?.answers;
		survey.mode = 'display';
	}, [dynamicForm, survey]);

	/**
	 * @Todo @Manish this is a temperory solution added by manish
	 * 
	 * and needs to make dynamic from survey form hence this has to be removed for sure.
	 * */ 
	useEffect(() => {
		const data: any  = dynamicForm?.data?.answers;
		const questionKey = Object.keys(data ?? {}).find((key) => {
			return data[key]?.investorsDetails
		});
		if(questionKey) {
			const {count, investorsDetails } = data[questionKey];
			setInvestorsCount(count);
			setInvestorsDetails(investorsDetails);
			setInvestorsDetailsDisabled(true);

		}
		return () => {
			setInvestorsCount("1");
			setInvestorsDetails([]);
			setInvestorsDetailsDisabled(false);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[dynamicForm?.data?.answers])

	return (
		<div className="kycDetails-contain">
			<SessionDetailsHeading
				label="Dynamic Form"
				icon={
					<i className="ri-file-list-3-fill form-icon-profile-container__formIcon" />
				}
			>
				{Object.values(dynamicForm ?? {}).length > 0 && (
						<div className="action-btn">
							{role === 'AGENT' && !isAgentPermitted ? (
								<div className="action-btn__agent">{dynamicForm?.status}</div>
							) : (
								<ActionButton
									status={dynamicForm?.status}
									type="dynamicForm"
									isLoading={isLoading}
									nodeId={nodeId}
									isDisabled={!isUserPermissionWrite}
								/>
							)}
						</div>
				)}
			</SessionDetailsHeading>
			<div id="surveyForm">
				<Survey model={survey} />
			</div>
		</div>
	);
};
