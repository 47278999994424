import type { ISignDashboardConfig, IPayload } from './types';

import { useCallback, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { API_URL, MESSAGE } from 'constant';
import { useNetwork, useNotification } from 'hooks';
import { getInitials } from 'utils';
import { CreateSignState } from 'views/create-sign';
import { SignDashboardSignatureState, SignDashboardState, SignatureLoadingState } from './states';


export const useSignDashboard = () => {
	const { get, post, patch } = useNetwork({ returnResponse: true });
	const { errorNotification, successNotification } = useNotification();
	const [signDashboardValue, setSignDashboard] = useRecoilState(SignDashboardState);
	const setUsername = useSetRecoilState(CreateSignState);
	const setSignDashboardSignature = useSetRecoilState(
		SignDashboardSignatureState
	);
	const setSignatureLoading = useSetRecoilState(SignatureLoadingState);
	const [isLoaded, setIsLoaded] = useState(false);
	const {_id: userId} = signDashboardValue.data;

	const getSignature = useCallback(async (id: string): Promise<void> => {
		const resp = await get(`${API_URL.USER_SIGNATURE}/${id}`);
		const { apiPayload: apiData, response } = resp;
		const { data } = apiData || {};

		if (response?.status == 200) {
			const { initialDetail, signDetail } = data;
			setSignDashboardSignature(prev => ({
				...prev,
				data: {
					initialDetail: initialDetail
						? {
								_id: initialDetail._id,
								type: initialDetail.type,
								url: initialDetail.document.path,
						  }
						: null,
					signDetail: signDetail
						? {
								_id: signDetail._id,
								type: signDetail.type,
								url: signDetail.document.path,
						  }
						: null,
				},
				isLoaded: true,
			}));
			setSignatureLoading(false);
			return;
		}
		setSignatureLoading(false);
		setSignDashboardSignature(prev => {
			return { ...prev, isLoaded: true };
		});
		return;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getSignDashboard = useCallback(
		async (isLoad?: boolean): Promise<void> => {
			if (isLoad) {
				setSignDashboard(prev => ({ ...prev, isLoaded: !isLoad }));
				setSignatureLoading(true);
			}
			const resp = await get(`${API_URL.SIGN_USER_DASHBOARD}`);
			const { apiPayload: apiData, response, errorCode } = resp;
			const { data = [] } = apiData || {};
			const { signature, name, image, email, _id, title, webhookConfig = {url: ""} } = data[0] ?? {};
			if (response?.status == 200) {
				if (signature) {
					getSignature(signature);
				} else {
					setSignDashboardSignature(prev => ({
						...prev,
						data: { initialDetail: null, signDetail: null },
						isLoaded: true,
					}));
					setSignatureLoading(false);
				}
				setSignDashboard(prev => {
					return {
						...prev,
						data: { signature, name, email, image, _id, title, webhookConfig },
						isLoaded: true,
					};
				});
				setUsername(prev => ({
					...prev,
					fullName: name,
					initials: getInitials(name ?? ''),
				}));
			}
			if (errorCode == 404 || response?.status !== 200 || errorCode == 400) {
				setSignDashboard(prev => {
					return { ...prev, isLoaded: true, error: true };
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const getSignDashboardPrepareUrl = useCallback(
		async (payload: any): Promise<ISignDashboardConfig | void> => {
			const response = await post(API_URL.ENVELOPE, payload);
			setIsLoaded(false);
			if (response?.prepareUrl) {
				setIsLoaded(true);
				return response;
			}
			setIsLoaded(true);
			errorNotification(MESSAGE.ERROR_TRY);
			return;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	//Ankur Singh: Define a `handleSaveTitle` function Hook for payload.
	const handleSaveTitle = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		async (payload: IPayload) => {
			//Ankur Singh: Set the `isLoaded` flag to `true` to indicate that a save operation is in progress.
			setIsLoaded(true);

			  //Ankur Singh: Send a PATCH request to an API endpoint with the `userId` and `payload`.
			const resp = await patch(`${API_URL.SIGN_USER_DASHBOARD}/${userId}`, payload);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const { response } = resp;

			//Ankur Singh: If the response status is 200, set `isLoaded` to `false`.
			if (response?.status === 200) {
				setIsLoaded(false);	
			}
			setIsLoaded(false);
			return resp;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[userId]
	);

	const addWebhookUrl = useCallback(async (payload: {webhookConfig: {url: string}}): Promise<boolean> => {
		const resp = await patch(`${API_URL.SIGN_USER_DASHBOARD}/${userId}`, payload);		
		if(resp?._id) {
			successNotification("Webhook url added");
			return true
		}
		errorNotification(resp?.message ?? "Failed to add/update webhook url.");
		return false
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[patch, userId])

	return {
		getSignDashboard,
		getSignDashboardPrepareUrl,
		isLoaded,
		handleSaveTitle,
		addWebhookUrl
	};
};

export const useSetSignDasboard = () => {
	const { get } = useNetwork({ updateState: false, returnResponse: true });
	const getUseEnvelopeStatusInterval = useCallback(
		async (
			envelopeId: string,
			interval: number,
			successCb: (purpose?: string) => void,
			errorCb: () => void
		): Promise<void> => {
			const resp = await get(`${API_URL.ENVELOPE_STATUS}/${envelopeId}`);
			const { apiPayload: apiData, response, errorCode } = resp;
			const { envelopeStatus, purpose } = apiData?.data ?? {};
			if(purpose === "multisignAgreement") {
				if (envelopeStatus === 'saved') {
					clearInterval(interval);
					successCb(purpose);
				}
			}
			else if (purpose === 'selfsign') {
				if (envelopeStatus === 'completed') {
					clearInterval(interval);
					successCb(purpose);
				}
			} else {
				if (envelopeStatus === 'configured') {
					clearInterval(interval);
					successCb();
				}
			}

			if (errorCode == 400 || response?.status !== 200 || errorCode == 404) {
				// clearInterval(interval);
				errorCb();
				/**
				 * Do not clear the interval
				*/
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);
	return { getUseEnvelopeStatusInterval };
};
