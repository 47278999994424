import { useEffect, useState } from 'react';
import { PlaidLinkOptions, usePlaidLink } from 'react-plaid-link';
import { useSetRecoilState } from 'recoil';

import { API_URL, PLAID_COUNTRY_CODE } from 'constant';
import { useNetwork, useNotification } from 'hooks';
import { PaymentNavigationState } from 'views/settings/billing/stores';
import {
	IndetityFlowNavigate,
	SubscriptionIdentityNaviagtion,
} from 'views/user-identity-flow';
import { PaymentNavigation } from '../../constant';

interface IUsePlaid {
	isSignup?: boolean;
}
export const usePlaid = ({ isSignup = false }: IUsePlaid) => {
	const [token, setToken] = useState(null);
	const [isLoadingPlaid, setIsLoadingPlaid] = useState(false);
	const setIdentityNavigate = useSetRecoilState(SubscriptionIdentityNaviagtion);

	const setAddPaymentNaviagtion = useSetRecoilState(PaymentNavigationState);

	const { post: generateToken } = useNetwork({ updateState: false });
	const { post: submitExchangeToken } = useNetwork();
	const { successNotification } = useNotification();

	const onSuccess = async (publicToken: any, metadata: any) => {
		const payload = {
			bankName: metadata.institution?.name,
			linkToken: token,
			token: publicToken,
			type: 'payment',
		};

		const response = await submitExchangeToken(API_URL.TokenExchange, payload);
		if (response?.id) {
			successNotification('Account Linked Successfully');
			if (isSignup) {
				setIdentityNavigate(IndetityFlowNavigate.DefaultAchPage);
			}
			setAddPaymentNaviagtion(PaymentNavigation.DefaultAchPage);
			setIsLoadingPlaid(false);
		}
		setToken(null);
	};

	const onEvent = () => null;

	const onExit = () => {
		setIsLoadingPlaid(false);
	};

	const initPlaid = async () => {
		setIsLoadingPlaid(true);
		const payload = {
			language: 'en',
			countryCodes: PLAID_COUNTRY_CODE,
		};
		const resp = await generateToken(API_URL.GenerateLinkToken, payload);
		if (resp?.token) {
			setToken(resp.token);
		}
	};

	const config: PlaidLinkOptions = {
		token,
		onSuccess,
		onEvent,
		onExit,
	};
	const { open } = usePlaidLink(config);

	useEffect(() => {
		if (token) {
			open();
		}
	}, [open, token]);

	return {
		initPlaid,
		isLoadingPlaid,
	};
};
