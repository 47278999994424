import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Confirmation, Image, Loader, Skeleton } from '@storybook';

import { kycSettingsState } from 'global-stores';
import { DropOption } from 'components';
import { ISelectedLoader, LOADER_KEYS, LOADING_ANIMATION } from './stores';
import { useLoadingAnimation } from '../../../stores';

import './loading-animation.scss';

export const LoadingAnimation = () => {
	// globel states
	const settingsKyc = useRecoilValue(kycSettingsState);

	// hooks
	const {
		handleSelectLoader,
		uploadComponent,
		handleEditLoader,
		dropOptionProps,
		optionCartClasses,
		handleFileUpload,
		checkBoxClasses,
		confirmationProps,
		isMp4,
		handleClickOnUpload
	} = useLoadingAnimation();

	const { title, description, options, uploaded } = LOADING_ANIMATION;

	const { loader: loaderAnimation = '' } = settingsKyc ?? {};

	const renderUploadComponent = useMemo(() => {
		switch (uploadComponent) {
			case LOADER_KEYS.Uploaded:
				return (
					<>
						<div className="LoadingAnimation__uploaded-imageWrapper">
							{isMp4(loaderAnimation) ? (
								<video
									src={loaderAnimation}
									className="LoadingAnimation__uploaded-image"
									controls={false}
									autoPlay
									muted // Mute the video to ensure autoplay works
									loop
								/>
							) : (
								<Image
									url={loaderAnimation}
									className="LoadingAnimation__uploaded-image"
								/>
							)}
						</div>
						<div
							className="LoadingAnimation__uploaded-edit"
							onClick={handleEditLoader}
						>
							<DropOption {...dropOptionProps} />
						</div>
					</>
				);
			case LOADER_KEYS.Retry:
				return (
					<div
						onClick={handleClickOnUpload}
						className="LoadingAnimation__upload-label"
					>
						<i className="ri-restart-line" />
						<span>Retry</span>
					</div>
				);
			case LOADER_KEYS.Loading:
				return (
					<div className="LoadingAnimation__option-loader">
						<Skeleton height={80} width={80} borderRadius={10} />
					</div>
				);
			default:
				return (
					<div
						onClick={handleClickOnUpload}
						className="LoadingAnimation__upload-label"
					>
						<i className="ri-upload-cloud-2-line" />
						<span>Upload</span>
					</div>
				);
		}
	}, [
		dropOptionProps,
		handleEditLoader,
		handleClickOnUpload,
		isMp4,
		loaderAnimation,
		uploadComponent,
	]);

	const mapLoaderCart = useMemo(
		() =>
			options.map(({ key, label, desc }) => {
				if (key === LOADER_KEYS.Custom && loaderAnimation) {
					label = uploaded.label;
					desc = uploaded.desc;
				}
				return (
					<div
						key={key}
						className={optionCartClasses(key)}
						onClick={() => handleSelectLoader(key as ISelectedLoader)}
					>
						<div className="LoadingAnimation__option-content">
							<div className="LoadingAnimation__option-textWrapper">
								<i className={checkBoxClasses(key)} />
								<div className="LoadingAnimation__option-text">
									<div className="LoadingAnimation__option-title">{label}</div>
									<div className="LoadingAnimation__option-disc">{desc}</div>
								</div>
							</div>
							{key === LOADER_KEYS.Default && (
								<div className="LoadingAnimation__option-loader">
									<Loader
										dimension={42}
										className="loader-blue"
										type="loader"
									/>
								</div>
							)}
							{key === LOADER_KEYS.Custom && (
								<>
									<input
										multiple={false}
										accept=".mp4, .gif"
										id="custom-loading-animation"
										type="file"
										onChange={handleFileUpload}
										style={{ display: 'none' }}
										onClick={e => e.stopPropagation()}
									/>
									<div className="LoadingAnimation__upload-wrapper">
										{renderUploadComponent}
									</div>
								</>
							)}
						</div>
					</div>
				);
			}),
		[
			options,
			loaderAnimation,
			optionCartClasses,
			checkBoxClasses,
			handleFileUpload,
			renderUploadComponent,
			uploaded.label,
			uploaded.desc,
			handleSelectLoader,
		]
	);

	return (
		<div className="LoadingAnimation__container ">
			<div className="LoadingAnimation__option-text">
				<div className="LoadingAnimation__option-title">{title}</div>
				<div className="LoadingAnimation__option-disc">{description}</div>
			</div>
			{mapLoaderCart}
			<Confirmation {...confirmationProps} />
		</div>
	);
};
