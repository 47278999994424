import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	PlaidLinkOnEvent,
	PlaidLinkOnExit,
	PlaidLinkOnSuccess,
	PlaidLinkOptions,
	usePlaidLink,
} from 'react-plaid-link';

import { useNetwork, useNotification } from 'hooks';
import { FundRecipientBankAccount } from 'global-stores';
import { API_URL, MESSAGE, PLAID_COUNTRY_CODE } from 'constant';
import {
	AddBankAccountLoadingState,
	AmountToDepositeToEscrowState,
	AmountToWithdrawFromEscrowState,
	EscrowDetailsAtom,
	EscrowModalBodyNavigatorState,
	EscrowModalOpenState,
	EscrowTransactionTypeState,
	FundDataForEscrowState,
	SelectedBankIndexState,
	SelectedBankSubItemState,
	SeletedBankItemState,
} from './states';
import { dummyEscrowDetails } from '../constants';

export const useAddBank = () => {
	// globle states
	const transactionType = useRecoilValue(EscrowTransactionTypeState);
	const setModalOpen = useSetRecoilState(EscrowModalOpenState);
	const setFundRecipient = useSetRecoilState(FundRecipientBankAccount);
	const setSelectedBankIndex = useSetRecoilState(SelectedBankIndexState);
	const setLoading = useSetRecoilState(AddBankAccountLoadingState);
	const setSubSelectedItem = useSetRecoilState(SelectedBankSubItemState);
	const setSelectedItem = useSetRecoilState(SeletedBankItemState);
	const [navigate, setNavigate] = useRecoilState(EscrowModalBodyNavigatorState);

	//local state
	const [token, setToken] = useState('');

	// hooks
	const { post: createToken } = useNetwork({ updateState: false });
	const { post: submitExchangeToken } = useNetwork({ updateState: false });
	const { get: fetchBusinessBankAccounts } = useNetwork({ updateState: false });
	const { errorNotification, successNotification } = useNotification();

	// handle navigate for add bank first time
	const handleNavigate = useCallback(() => {
		if (transactionType === 'withdraw') setNavigate('withdraw_fund');
		if (transactionType === 'deposit') setNavigate('deposite_fund');
	}, [setNavigate, transactionType]);

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const onEvent = useCallback<PlaidLinkOnEvent>(() => ({}), []);

	const onExit = useCallback<PlaidLinkOnExit>(() => {
		setToken('');
		setModalOpen(true);
	}, [setModalOpen]);

	// handle bank added success
	const onSuccess = useCallback<PlaidLinkOnSuccess>(
		async (publicToken, metadata) => {
			const payload = {
				bankName: metadata.institution?.name,
				linkToken: token,
				token: publicToken,
			};
			if (navigate === 'add_bank_account') {
				handleNavigate();
			}
			setLoading(true);
			setModalOpen(true);
			const response = await submitExchangeToken(
				API_URL.TokenExchange,
				payload
			);
			if (response?.id) {
				successNotification('Account Linked Successfully');
			} else {
				errorNotification(response?.message ?? MESSAGE.ERROR);
			}
			setToken('');
			fetchBusinessBankAccounts(API_URL.BusinessBankAccounts)
				.then(res => {
					if (res.data) {
						setFundRecipient(res.data);
						if (res.data.length > 0 && res.data[0]?.accounts.length > 0) {
							setSelectedItem(res.data[res.data.length - 1]);
							setSubSelectedItem({
								...res.data[res.data.length - 1]?.accounts[0],
								_id: res.data[res.data.length - 1]?._id,
							});
							setSelectedBankIndex(res.data.length - 1);
						}
					}
					setLoading(false);
				})
				.catch(err => {
					errorNotification(err.message ?? MESSAGE.ERROR);
					setLoading(false);
				});
		},
		[
			token,
			navigate,
			setLoading,
			setModalOpen,
			submitExchangeToken,
			fetchBusinessBankAccounts,
			handleNavigate,
			successNotification,
			errorNotification,
			setFundRecipient,
			setSelectedItem,
			setSubSelectedItem,
			setSelectedBankIndex,
		]
	);

	const config: PlaidLinkOptions = {
		token,
		onSuccess,
		onEvent,
		onExit,
	};

	const { open } = usePlaidLink(config);

	useEffect(() => {
		if (token) {
			setModalOpen(false);
			open();
		}
		// eslint-disable-next-line
	}, [open, token]);

	const addNewBank = useCallback(async () => {
		const payload = {
			language: 'en',
			countryCodes: PLAID_COUNTRY_CODE,
		};
		const res = await createToken(API_URL.GenerateLinkToken, payload);
		setToken(res.token);
	}, [createToken]);

	return { addNewBank };
};

export const useEscrowAccount = () => {
	// globle states
	const fund = useRecoilValue(FundDataForEscrowState);
	const [escrowDeatils, setEscrowDetails] = useRecoilState(EscrowDetailsAtom);
	const amountToDeposit = useRecoilValue(AmountToDepositeToEscrowState);
	const amountToWithout = useRecoilValue(AmountToWithdrawFromEscrowState);

	// hooks
	const { get, post } = useNetwork({ updateState: false });
	const { errorNotification } = useNotification();

	const { id } = useMemo(() => escrowDeatils.data, [escrowDeatils]);
	const { id: fundId, escrowAccounts } = useMemo(() => fund, [fund]);
	const selectedEscrow = useMemo(() => escrowAccounts[0], [escrowAccounts]);

	const getEscrowDetails = useCallback(
		(mock?: boolean) => {
			if (mock) {
				setEscrowDetails(prev => ({ ...prev, data: dummyEscrowDetails }));
			}
			setEscrowDetails(prev => ({ ...prev, loaded: false }));
			get(
				`${API_URL.ESCROW_ACCOUNT}/${
					selectedEscrow?.accountId
				}?fundId=${fundId}&provider=${selectedEscrow?.provider ?? 'FORTRESS'}`
			)
				.then(res => {
					if (res && res.data) {
						setEscrowDetails(prev => ({ ...prev, data: res.data }));
					}
				})
				.catch(() => {
					setEscrowDetails(prev => ({ ...prev, error: true }));
				})
				.finally(() => {
					setEscrowDetails(prev => ({ ...prev, loaded: true }));
				});
		},
		[fundId, get, selectedEscrow, setEscrowDetails]
	);

	const handleAccountBalance = useCallback(
		(type: 'deposit' | 'withdraw', amount: number) => {
			if (type === 'withdraw') {
				amount = -amount;
			}
			setEscrowDetails(prev => ({
				...prev,
				data: {
					...prev.data,
					total: prev.data.total + amount,
					disbursable: prev.data.disbursable + amount,
				},
			}));
		},
		[setEscrowDetails]
	);

	const accountTransaction = useCallback(
		async (type: 'deposit' | 'withdraw') => {
			const amount = Number(
				type === 'deposit' ? amountToDeposit : amountToWithout
			);
			const payload = {
				id: id ?? '',
				fundId: fundId ?? '',
				type: type,
				amount: amount,
			};
			const res = await post(API_URL.ESCROW_TRANSACTION, payload);
			const { success, message } = res ?? {};
			if (!success) {
				errorNotification(message ?? MESSAGE.ERROR);
			} else {
				handleAccountBalance(type, amount);
			}
			return !!success;
		},
		[
			amountToDeposit,
			amountToWithout,
			id,
			fundId,
			post,
			errorNotification,
			handleAccountBalance,
		]
	);
	return { getEscrowDetails, accountTransaction };
};
