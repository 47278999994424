import { atom } from 'recoil';
import { IAllowedInvites } from './types';

export const AllowedInvitesState = atom<IAllowedInvites>({
	key: 'allowed-invites-state-key',
	default: {
		onboarding: 0,
		simpliciSign: 0,
	},
});
