import {
	VerificationDetailsHeader,
	VerificationResult,
} from '../../../verificationInformation';
import { useRecoilValue } from 'recoil';
import { BusinessRegistrationState } from 'global-stores';
import { useFreePlan } from 'hooks';
import { useMemo } from 'react';
import { BussinessDetailsStatus } from './constant';
import { IdentityType } from 'views/user-identity-flow';
import { SignUpSessionStatusState } from 'views/new-user-popup';

export const accountRenderColor = (key: any) => {
	switch (key) {
		case 'pending':
			return 'var(--color-gray)';
		case 'completed':
			return 'var(--color-green)';
		case 'rejected':
			return 'var(--color-rejected)';
		case 'processing':
			return 'var(--color-orange)';
		default:
			return 'var(--color-rejected)';
	}
};

export const BussinessStatusDetails = () => {
	const businessRegistrationState = useRecoilValue(BusinessRegistrationState);
	const {
		kyc,
		kyb,
		isViewShow = false,
	} = useMemo(
		() => businessRegistrationState ?? {},
		[businessRegistrationState]
	);
	const sessionDetails = useRecoilValue(SignUpSessionStatusState);
	const physicalVerificationStatus = useMemo(() => {
		return sessionDetails?.physicalVerificationStatus ?? '';
	}, [sessionDetails]);

	const { status: kycStatus } = useMemo(() => kyc?.data ?? kyc ?? {}, [kyc]);

	const { status: kybStatus } = useMemo(
		() => kyb?.lexisNexisReport ?? kyb ?? {},
		[kyb]
	);
	const { accountType } = useFreePlan();

	return (
		<>
			<div className="subscriptions__bussinessDetails">
				<VerificationDetailsHeader
					isViewShow={isViewShow}
					Header="Verification information"
				/>
				<VerificationResult isViewShow={isViewShow} />
			</div>
			<div className="subscriptions__businessStatus">
				<div className="subscriptions__bussinessDetails">
					<div className="subscriptions__bussinesses">KYC</div>
					<div className="subscriptions__label">
						<div
							className="subscriptions__show-status-circle"
							style={{
								background: accountRenderColor(kycStatus),
							}}
						></div>
						<div className="subscriptions--right">
							{BussinessDetailsStatus[kycStatus] ?? 'N/A'}
						</div>
					</div>
				</div>
				{accountType === IdentityType.Company ? (
					<div className="subscriptions__bussinessDetails">
						<div className="subscriptions__bussinesses">KYB</div>
						<div className="subscriptions__label">
							<div
								className="subscriptions__show-status-circle"
								style={{
									background: accountRenderColor(kybStatus),
								}}
							></div>
							<div className="subscriptions--right">
								{BussinessDetailsStatus[kybStatus] ?? 'N/A'}
							</div>
						</div>
					</div>
				) : null}
				{accountType === IdentityType.Company ? (
					<div className="subscriptions__bussinessDetails">
						<div className="subscriptions__bussinesses">
							Physical verification
						</div>
						<div className="subscriptions__label">
							<div
								className="subscriptions__show-status-circle"
								style={{
									background: accountRenderColor(physicalVerificationStatus),
								}}
							></div>
							<div className="subscriptions--right">
								{BussinessDetailsStatus[physicalVerificationStatus] ?? 'N/A'}
							</div>
						</div>
					</div>
				) : null}
				{/* <div className="subscriptions__bussinessDetails">
					<div className="subscriptions__bussinesses">Business Status</div>
					<div className="subscriptions__label">
						<Image fileName="verifiedIcon.svg" />
						<div className="subscriptions--right">
							{BussinessStatus[approveStatus] ?? 'NA'}
						</div>
					</div>
				</div> */}
			</div>
		</>
	);
};
