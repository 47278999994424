import { v4 } from 'uuid';

const id = v4();

export const DefaultNode = {
	id: `node-${id}`,
	type: 'customNode',
	position: {
		x: 326,
		y: 292,
	},
	key: 'authentication',
	conditions: [],
	updateStyle: true,
	stepId: 'authentication',
	label: 'Biometric',
	parentId: [],
	data: {
		label: 'authentication',
	},
	zIndex: 99999,
	width: 535,
	height: 297,
};

export const defaultConfigedNode = {
	id: `node-${id}`,
	configed: false,
	stepId: 'authentication',
};
