import { IKYBBarChart } from './types';

import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { kycSettingsState } from 'global-stores';
import { NumberWithCommas } from 'utils';

export const useBasicBarChart = () => {
	const kycSettings = useRecoilValue(kycSettingsState);
	const { settings } = useMemo(() => kycSettings, [kycSettings]);
	const { bodyFont } = settings ?? {};

	const getBarChartConfig = ({
		id,
		isLoading,
		title,
		valuePrefix,
		valueSuffix,
		xAxis,
		yAxis,
		color,
	}: IKYBBarChart) => {
		const value = yAxis?.reduce(
			(a: number, b: number | null) => a + (b ?? 0),
			0
		);
		let yMax;
		if (value === 0) {
			yMax = 10;
		}
		const charts =
			(window as any).Highcharts?.chart(id, {
				chart: {
					type: 'column',
					height: 330,
					style: {
						fontFamily: bodyFont?.family !== '' ? bodyFont?.family : 'Poppins',
					},
				},

				title: {
					text: title,
					align: 'left',
					style: {
						color: '#2F323D',
						fontWeight: '600',
						fontSize: '16px',
						lineHeight: '24px',
					},
				},

				subtitle: {
					text: null,
				},

				exporting: {
					enabled: false,
				},

				credits: {
					enabled: false,
				},
				yAxis: {
					max: yMax,
					gridLineDashStyle: 'dash',
					title: {
						text: null,
					},
					labels: {
						formatter: function (this: any) {
							const label = NumberWithCommas(this.value) + valueSuffix;
							return label;
						},
					},
				},
				xAxis: {
					minTickInterval: 1,
					categories: xAxis,
					labels: {
						style: {
							color: '#8C97B8',
							fontWeight: 400,
							fontSize: '12px',
						},
					},
				},

				tooltip: {
					formatter: function (this: any) {
						return (
							`<span>` +
							this.key +
							`</span></br><span >` +
							title +
							' : </span><b>' +
							valuePrefix +
							NumberWithCommas(this?.y) +
							valueSuffix +
							'</b>'
						);
					},
				},

				plotOptions: {
					series: {
						maxPointWidth: 30,
					},
					column: {
						showInLegend: false,
					},
				},

				series: [
					{
						name: title,
						data: yAxis,
						color: color,
					},
				],
			}) ?? {};

		if (isLoading) {
			charts?.showLoading();
		} else {
			charts?.hideLoading();
		}
	};

	return { getBarChartConfig };
};
