export const DocumentsTypes = [
	{
		label: 'Driver License',
		value: 'driver_licence',
	},
	{
		label: 'SSN',
		value: 'ssn',
	},
	{
		label: 'Passport',
		value: 'passport',
	},
	{
		label: 'Govt. Id',
		value: 'govt_id',
	},
	{
		label: 'Accreditation',
		value: 'accreditation',
	},
	{
		label: 'Bank statement',
		value: 'bank_statement',
	},
	{
		label: 'Other',
		value: 'others',
	},
];

export const DocsLabel: any = {
	driver_licence: {
		label: 'Driver License',
		value: 'driver_licence',
	},
	ssn: {
		label: 'SSN',
		value: 'ssn',
	},
	passport: {
		label: 'Passport',
		value: 'passport',
	},
	govt_id: {
		label: 'Govt. Id',
		value: 'govt_id',
	},
	accreditation: {
		label: 'Accreditation',
		value: 'accreditation',
	},
	bank_statement: {
		label: 'Bank statement',
		value: 'bank_statement',
	},
	others: {
		label: 'Other',
		value: 'others',
	},
};

export const UploadDocumentMessage =
	'Note: Please select all the types of documents from their respective drop down and provide the password if they have any.';

export const FileTypes: any = {
	JPG: true,
	JPEG: true,
	PNG: true,
	DOC: true,
	DOCX: true,
	PDF: true,
};
