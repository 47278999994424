import freeEmailDomains from './free-domains';

//Gaurav: from this we get domain name only and removed https://*.
export const getDomain = (url: any) => {
	url = url.replace(/(https?:\/\/)?(www.)?/i, '');
	url = url.split('.');
	url = url.slice(url.length - 2).join('.');
	if (url.indexOf('/') !== -1) {
		return url.split('/')[0];
	}
	return url;
};

export const isFreeEmail = (email: string) => {
	const domain = email?.split('@')[1]?.toLowerCase();
	if (domain) {
		return !!freeEmailDomains[domain];
	} else return true;
};
