export const FUND_DETAIL_TABLE = {
	HEADER: [
		{
			label: 'Name',
			key: 'firstName',
			key2: 'lastName',
			key3: 'fullStatus',
			format: 'jsx',
			width: '18%',
			className: 'fund_name',
		},
		{
			label: 'Contact & Email',
			key: 'email',
			key2: 'phone',
			format: 'jsx',
			className: 'email__contact',
			width: '18%',
		},

		{
			label: 'Invested (USD)',
			key: 'invested',
			key2: 'fundingRound',
			format: 'jsx',
			width: '14%',
		},
		{
			label: 'Shares',
			key: 'share',
			key2: 'class',
			format: 'jsx',
			width: '14%',
		},
		{
			label: 'Price Per Share (USD)',
			key: 'price',
			format: 'price',
			width: '12%',
		},

		{
			label: 'Issue Date',
			key: 'issueDate',
			format: 'jsx',
			width: '14%',
		},
		// {
		//   label: "Completion Date",
		//   key: "completionDate",
		//   format: "dateTime",
		//   width: "12%",
		// },
		{
			label: 'Actions',
			key: 'action',
			format: 'jsx',
			width: '10%',
		},
	],
};

export const FUND_CAPTABLE = {
	LINK: 'https://storage.googleapis.com/satschel-assets-public/captable/captable-template.csv',
};

export const ADD_INVESTOR_ERROR = 'Investor cannot be added';

export enum Navigate {
	Csv = 'Csv',
	ConfigCaptable = 'ConfigCaptable',
}

export const Add_FUND_MAP_CAPTABLE_ROW = [
	{
		label: 'First Name',
		isOptional: false,
		isRequired: true,
		inputType: 'select',
	},
	{
		label: 'Email',
		isOptional: false,
		isRequired: true,
		inputType: 'select',
	},
	{
		label: 'Country Code',
		isOptional: false,
		isRequired: true,
		inputType: 'select',
	},
	{
		label: 'Mobile',
		isOptional: false,
		isRequired: true,
		inputType: 'select',
	},
	{
		label: 'Dollars Invested',
		isOptional: false,
		isRequired: true,
		inputType: 'select',
	},
	{
		label: 'No. of Shares',
		isOptional: true,
		isRequired: false,
		inputType: 'select',
	},
	{
		label: 'Price per Share',
		isOptional: true,
		isRequired: false,
		inputType: 'select',
	},
	{
		label: 'Issue Date',
		isOptional: true,
		isRequired: false,
		inputType: 'select',
	},
];

export const CAPTABLE_ALL_FIELDS_REQUIRED_MESSAEG = "Please fill all the required columns of investor(s)";
