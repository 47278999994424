import { Image, Loader } from '@storybook';
import { FC, Fragment, useCallback, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
	FundRecipientBankAccount,
	RecipientBankAccountLoadingState,
	SelectedBankFlowState,
	SelectedFundRecipientBankAccount,
} from 'global-stores';
import { isStage } from 'helpers';
import { BankType } from 'views/pipelines/constants';
import { FundsIdState } from '../store';
import { SelectedColor } from './constants';
import './select-bank.scss';

interface IFundInvestment {
	setSelectedIndex: any;
	selectedIndex: number;
	setSelectedItem: any;
	selectedItem: any;
}
export const SelectBankFlow: FC<IFundInvestment> = ({
	setSelectedItem,
	selectedItem,
}) => {
	//global state
	const [loading] = useRecoilState(RecipientBankAccountLoadingState);
	const [subSelectedItem, setSubSelectedItem] = useRecoilState(
		SelectedFundRecipientBankAccount
	);
	const fundsIdState = useRecoilValue(FundsIdState);
	const [selectBankFlow, setSelectedBankFlow] = useRecoilState(
		SelectedBankFlowState
	);
	const fundRecipient = useRecoilValue(FundRecipientBankAccount);

	useEffect(() => {
		if (
			fundRecipient.length > 0 &&
			fundRecipient[0]?.accounts.length > 0 &&
			Object.keys(subSelectedItem ?? {}).length === 0 &&
			Object.keys(selectedItem ?? {}).length === 0
		) {
			setSelectedItem(fundRecipient[0]);
			setSubSelectedItem({
				...fundRecipient[0]?.accounts[0],
				_id: fundRecipient[0]?._id,
			});
		}
		// eslint-disable-next-line
	}, [fundRecipient]);

	const handleBankFlow = useCallback(
		(type: string) => () => {
			setSelectedBankFlow(type);
		},
		[setSelectedBankFlow]
	);

	const renderNoFundRecipient = useMemo(
		() => (
			<div className="fi-wrapper__center">
				<div style={{ display: 'flex' }}>
					<div
						className="bankContainer"
						style={{
							borderColor:
								selectBankFlow === BankType.Bank
									? SelectedColor.Selected
									: SelectedColor.UnSelected,
						}}
						onClick={handleBankFlow(BankType.Bank)}
					>
						<div>
							<Image fileName="addBank.svg" className="images" />
						</div>
						<div className="flow-type">Bank</div>
						<div className="add-account">
							{fundRecipient?.length > 0
								? `${fundRecipient.length} Account Already Linked`
								: 'Add Account'}
						</div>
						{selectBankFlow === BankType.Bank && (
							<div className="selected-flow">Selected</div>
						)}
					</div>
					{isStage && (
						<div
							className="bankContainer"
							style={{
								borderColor:
									selectBankFlow === BankType.Escrow
										? SelectedColor.Selected
										: SelectedColor.UnSelected,
							}}
							onClick={handleBankFlow(BankType.Escrow)}
						>
							<div>
								<Image fileName="addEscrow.svg" />
							</div>
							<div className="flow-type">Escrow Account</div>
							<div className="add-account">
								{!fundsIdState.hasEscrowLink
									? 'Create Account'
									: 'One Account Already Linked'}
							</div>
							{selectBankFlow === BankType.Escrow && (
								<div className="selected-flow">Selected</div>
							)}
						</div>
					)}
				</div>
			</div>
		),
		[handleBankFlow, selectBankFlow, fundsIdState, fundRecipient]
	);

	const renderLoading = useMemo(
		() => (
			<div className="fi-wrapper__loader">
				<Loader className="loader-blue" dimension={45} />
			</div>
		),
		[]
	);

	const renderComponent = useMemo(() => {
		if (loading) {
			return renderLoading;
		}
		return <Fragment>{renderNoFundRecipient}</Fragment>;
	}, [loading, renderLoading, renderNoFundRecipient]);

	return <div className="fi-wrapper">{renderComponent}</div>;
};
