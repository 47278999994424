import { atom } from 'recoil';
import { IPermissions } from 'views/user-roles';

export interface IPrimaryUserDetails {
	firstName: string;
	lastName: string;
	email: string;
	countryCode?: string;
	phone: string;
}
export interface ICreateSubAccountState {
	name: string;
	ownBilling: boolean;
	differentCompany?: boolean;
	primaryUserDetails?: IPrimaryUserDetails;
}

export interface ISubAccountListState {
	_id: string;
	name: string;
	ownBilling: boolean;
	createdAt: string;
	updatedAt: string;
	totalUsers: number;
	differentCompany: boolean;
	approveStatus: 'PENDING' | 'APPROVED' | 'REJECTED' | 'UNDER_REVIEW';
}

export type ISubAccountKey = 'name' | 'createdAt';

export interface IRole {
	_id: string;
	name: string;
	createdAt: string;
	updatedAt: string;
	__v: number;
	description?: string;
	fullAccess: boolean;
	permissions: IPermissions;
	businessId: string | null;
}

export interface ISubAccountInvitedUsers {
	_id: string;
	firstName: string;
	lastName: string;
	countryCode: string;
	phone: string;
	email: string;
	password: string;
	role: IRole;
	tokens: any[];
	fundIds: string[];
	developer: boolean;
	isAgentPermitted: boolean;
	business: string;
	linkedInUrl: string;
	isVerifiedEmail: boolean;
	isVerifiedPhone: boolean;
	invitedDate: string;
	image: any;
	isLock: boolean;
	isDeleted: boolean;
	sandboxStatus: boolean;
	createdAt: string;
	updatedAt: string;
	__v: number;
}

export const CreateSubAccountState = atom<ICreateSubAccountState>({
	key: 'create-sub-account-state-key',
	default: {
		name: '',
		ownBilling: false,
		differentCompany: true,
		primaryUserDetails: {
			firstName: '',
			lastName: '',
			email: '',
			phone: '',
			countryCode: '+1',
		},
	},
});

export const SubAccountListState = atom<ISubAccountListState[]>({
	key: 'sub-account-list-state-key',
	default: [],
});
