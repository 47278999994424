import './profile.scss';

export const Profile = () => {
	return (
		<>
			<div className="settings-admin">
				<div className="row_2">
					{/* outer div */}
					<div className="outer">
						<div className="user-icon">
							<i className="ri-user-3-fill person-icon"></i>
						</div>
						<div className="profile-text">
							Person name
							<div className="company-logo-container__upload-container__browse">
								<label htmlFor="upload-logo" className="upload__browser-txt">
									Browse 
								</label>
								<input
									type="file"
									id="upload-logo"
									accept="image/png, image/jpeg"
									hidden
								/>
							</div>
						</div>
					</div>
					<div className="second">
						<div className="profile-email">
							<div className="email-icon">
								<i className="ri-mail-fill email-box"></i>
							</div>
							<div className="email-text">example@gmail.com</div>
						</div>
						<div className="profile-call">
							<div className="call-icon">
								<i className="ri-phone-fill phone-icon"></i>
							</div>
							<div className="call-number">415-767-6179</div>
						</div>
					</div>
				</div>
				<div className="login-main">
					<div className="login-outer">
						<div className="login">
							<div className="login-icon">
								<i className="ri-lock-password-fill lock-icon"></i>
							</div>
							<div className="login-text">Login Password</div>
						</div>
						<div>
							<button className="change-btn">Change</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
