import { FC } from 'react';
import './message.scss';

interface IMessage {
	text: string | JSX.Element;
	icon?: string;
	varient: 'warning' | 'info' | 'error' | 'none';
}
export const Message: FC<IMessage> = ({ text, varient , icon }) => {
	return (
		<div className={`message message--${varient}`}>
			<i className={icon || "ri-alarm-warning-line"} />
			<div className="message__text">{text}</div>
		</div>
	);
};
