import { IKybDetails } from 'global-stores/sessions/type';
import { FC, useState } from 'react';
import { KybInfoHeader } from '../kyb-info-header';
import { Image } from '@storybook';
import { formatUnderScoreRemove } from 'utils';
import './similar-companies.scss';

interface IKYBSimilarCompanies {
	item: IKybDetails;
}

export const SimilarCompanies: FC<IKYBSimilarCompanies> = ({ item }) => {
	const { similarCompanies } = item ?? {};
	const [showAllMembers, setShowAllMembers] = useState(false);

	const toggleShowAllMembers = () => {
		setShowAllMembers(!showAllMembers);
	};

	if (!item?.similarCompanies || !similarCompanies?.value?.length) {
		return <></>;
	}

	if (!similarCompanies?.value?.length) {
		return (
			<>
				<KybInfoHeader type="Similar Companies" />
				<div className="kyb-array-empty-wrapper">
					<div className="kyb-empty_data">
						<div className="kyb-empty_data__inner">
							<Image
								fileName="No_record_found.svg"
								className="empty_data_img"
								height="100"
							/>
							<div className="kyb-empty-data-txt">No records found</div>
						</div>
					</div>
				</div>
			</>
		);
	}

	return (
		<>
			<div className="similar-companies__header">
				<KybInfoHeader type="Similar Companies" />
				{similarCompanies?.value?.length > 2 && (
					<div
						onClick={toggleShowAllMembers}
						className="similar-companies__view-more"
					>
						{showAllMembers ? 'View less' : 'View more'}
					</div>
				)}
			</div>

			<div className="similar-companies__container">
				{similarCompanies?.value?.map((member, index) => {
					if ((index < 2 || showAllMembers) && member?.name) {
						return (
							<div
								key={
									typeof member?.name === 'string'
										? member?.name
										: member?.name?.value || member?.address?.value
								}
								className="similar-companies__card"
							>
								<div className="similar-companies__card__icon">
									<i className="ri-building-fill" />
								</div>

								<div className="similar-companies__card__initial">
									<div className="similar-companies__card__initial__text">
										{typeof member?.name === 'string'
											? member?.name
											: member?.name?.value}
									</div>
									<div className="similar-companies__card__initial__designation">
										{!member?.address
											? formatUnderScoreRemove(member?.country)
											: member?.address?.value}
									</div>
								</div>
							</div>
						);
					} else {
						return <></>;
					}
				})}
			</div>
		</>
	);
};
