import { useCallback, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { API_URL } from 'constant';
import { useNetwork } from 'hooks';
import {
	FundPaginationState,
	FundsLoadedState,
	FundsState,
	TempFundListState,
} from 'global-stores';

const pageLimit = 20;

export const useFunds = () => {
	const { get, loading: fundsLoading } = useNetwork({ updateState: false });
	const [fundsStateList , setFundStateList] = useRecoilState(TempFundListState);
	const [isLoaded, setIsLoaded] = useRecoilState(FundsLoadedState);
	const setFunds = useSetRecoilState(FundsState);
	const pageNumber = useRecoilValue(FundPaginationState);
	const[pageCounter, setPageCounter]=useState(0);

	const getFunds = useCallback(
		(fundsTablesearch: any = '', isPagination = false) => {
			setIsLoaded(false);
			//Rahul fundsTablesearch?1 bcz when search it shoud set the page to 1 from backend.
            const fundParam = `&page=${fundsTablesearch&&!isPagination?1:pageNumber}&limit=${pageLimit}${
                fundsTablesearch && `&search=${fundsTablesearch}`
            }`;
			get(`${API_URL.FUNDS}?${fundParam}`)
				.then(resp => {
					const { data } = resp ?? {};
					const pageCount = { ...(data ?? [])[0] }?.totalPages;
					setPageCounter(pageCount);
					if (data) {
						const newData = { ...(data ?? [])[0] }?.data;
						setFunds([...newData]);
						if(fundsStateList.length === 0 ){
							setFundStateList([...newData])
						}
					}
				})
				.finally(() => {
					setIsLoaded(true);
				});
			// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [get, setFunds, setFundStateList, fundsStateList, pageNumber]);

	return {
		getFunds,
		fundsLoading,
		isLoaded,
		pageCounter
	};
};
