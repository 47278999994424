import { useCallback } from 'react';

type CookieData = string | Record<string, any> | null;

export const useRefreshCookie = (cookieName = 'auth_refresh_token') => {
	const getCookie = useCallback((key: string) => {
		const name = `${key}=`;
		const decodedCookie = decodeURIComponent(document.cookie);
		const cookieArray = decodedCookie.split(';');
		for (let cookieItem of cookieArray) {
			cookieItem = cookieItem.trim();
			if (cookieItem.startsWith(name)) {
				return cookieItem.substring(name.length);
			}
		}
		return '';
	}, []);

	// Get parsed cookie data
	const get = useCallback(
		(key = cookieName): CookieData => {
			try {
				const cookieData = getCookie(key);
				if (cookieData) {
					try {
						return JSON.parse(cookieData);
					} catch {
						return cookieData; // Return as string if JSON parsing fails
					}
				}
			} catch {
				return null;
			}
			return null;
		},
		[cookieName, getCookie]
	);

	// Set or update cookie
	const set = useCallback(
		(key = cookieName, value: CookieData, days = 30): void => {
			try {
				const finalValue = typeof value === 'object' ? JSON.stringify(value) : value;
				const expires = new Date(Date.now() + days * 24 * 60 * 60 * 1000).toUTCString();
				document.cookie = `${key}=${finalValue}; expires=${expires}; path=/`;
			} catch {
				// Handle error silently
			}
		},
		[cookieName]
	);

	// Delete cookie by name and optional paths
	const deleteCookie = useCallback(
		(key = cookieName): void => {
			const paths = ['/', '/login', window.location.pathname];
			paths.forEach((path) => {
				document.cookie = `${key}=; Path=${path}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
			});
		},
		[cookieName]
	);

	return { get, set, deleteCookie };
};
