import { ISignDocTableColumns } from './types';

export const SIGN_HISTORY_TABLE_COLUMNS: ISignDocTableColumns[] = [
	{
		label: 'Time',
		key: 'createdAt',
		format: 'date',
	},
	{
		label: 'User',
		key: 'userName',
		format: 'string',
	},
	{
		label: 'Actions',
		key: 'action',
		format: 'string',
	},
	{
		label: 'Activity',
		key: 'activity',
		format: 'string',
	},
	{
		label: 'Status',
		key: 'status',
		format: 'jsx',
	},
];
export const EventToAction: { [key in string]: string } = {
	CREATED: 'Created',
	SENT: 'Sent Invitations',
	OPENED: 'Opened',
	SIGNED: 'Signed',
	COMPLETED: 'Completed',
	DELIVERED: 'Printable copy delivered',
	VOIDED: 'Packet Voided'
};
export const EventToActivity: { [key in string]: string } = {
	CREATED: 'The packet was created by %SENDER%',
	SENT: ' %SENDER% sent an invitation to %RECIPIENT%[%EMAIL%]',
	OPENED: '%RECIPIENT% opened the packet [documents:(%DOCUMENTS%)]',
	SIGNED: '%RECIPIENT% signed the packet',
	COMPLETED: 'Document signing is completed',
	DELIVERED: 'Printable attachments sent to %RECIPIENT% via email',
	VOIDED: 'This packet is voided by %SENDER%'
};

export const ActivityStatus: {[key in string]: string} = {
	selfconfigured: "Self Configured",	
}