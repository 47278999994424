import { FC, ReactNode, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { PipelineMasterSteps } from 'global-stores';
import { ActionList } from 'views/complex-onboarding-flow/constant';
import { ComplexSettingFormState } from 'views/pipeline';
import { CustomHandle } from '../custom-handle';
import { ICustomNode } from '../custom-node';
import { RenderAccBlock } from './render-accreditation-block';

export const AccreditationWrapper = ({ children }: { children: ReactNode }) => {
	return <div className="accreditation-action">{children}</div>;
};

export const AccreditationAction: FC<ICustomNode> = ({ id: nodeId, data }) => {
	const complexSettingForm = useRecoilValue(ComplexSettingFormState);
	const pipelinesSteps = useRecoilValue(PipelineMasterSteps);

	const selectDefaultLabel = useMemo(
		() => complexSettingForm?.[nodeId]?.accreditationType,
		[complexSettingForm, nodeId]
	);

	const actionId = useMemo(() => data.label, [data.label]);

	const actions = useMemo(
		() =>
			pipelinesSteps.find(
				pipelineStep => pipelineStep.key === ActionList[actionId]
			)?.actions[0],
		[actionId, pipelinesSteps]
	);

	const price = useMemo(() => {
		return (
			actions?.metadata?.[0]?.options?.find(
				el => el.label === selectDefaultLabel?.label
			)?.price ?? actions?.price
		);
	}, [actions?.metadata, actions?.price, selectDefaultLabel]);

	return (
		<AccreditationWrapper>
			<RenderAccBlock
				label={selectDefaultLabel?.label ?? 'Self Accreditation (506b)'}
				price={price}
				stepKey={selectDefaultLabel?.value ?? '506b'}
				nodeId={nodeId}
				stepId="accreditation"
			/>
			<CustomHandle value={nodeId} />
		</AccreditationWrapper>
	);
};
