import { FC } from 'react';
import './checkbox.scss';

interface IProps {
	label: string;
	onChangeCheckbox: any;
	checked: boolean;
	value: { [key: string]: any };
	nodeId: string;
	name: string;
}
export const CheckBox: FC<IProps> = ({
	label,
	onChangeCheckbox,
	// checked,
	value,
	nodeId,
	name,
}) => {
	const handleCheckBox = () => {
		const payload = {
			label: value?.label ?? '',
			key: value?.key ?? '',
			currentId: nodeId,
		};
		onChangeCheckbox(payload);
	};

	return (
		<div className="checkbox_input" onClick={handleCheckBox}>
			{/* <input type="radio" checked={checked} /> */}

			<input type="radio" name={name} />
			<label htmlFor="checkbox_input__checkbox" className="reminder_checkbox">
				{label}
			</label>
		</div>
	);
};
