import { ITransactionDeal } from '../type';

import { useCallback, useRef, useState } from 'react';

import { useOutsideClick } from 'hooks';
import { getColorForName } from '../../constants';

interface INames {
	name: string;
	isPrimary: boolean;
}

export const usePerson = () => {
	// local state
	const [showName, setShowName] = useState<{ [key: string]: boolean }>({});

	//hooks
	const wrapperRef = useRef<HTMLDivElement>(null);

	useOutsideClick(wrapperRef, () => {
		setShowName({});
	});

	const renderCircle = (label: string, index?: number, isPrimary?: boolean) => (
		<div
			key={`${label}-${index}`}
			style={{
				background: getColorForName(index),
			}}
			className="Deals__name-circle"
		>
			{label.substring(0, 2)}
			{isPrimary && <div className="Deals__primary-user" />}
		</div>
	);

	const renderCircles = useCallback((names: INames[]) => {
		const visibleNames = names.slice(0, 3);
		const circles = visibleNames.map(({ name, isPrimary }, index) =>
			renderCircle(name, index, isPrimary)
		);

		const additionalCount = names.length - visibleNames.length;
		const MAX_COUNT = 9;

		if (names.length && names.length <= 3) {
			return circles;
		} else if (names.length > 3 && names.length <= 12) {
			return [...circles, renderCircle(`+${additionalCount}`)];
		} else if (names.length > 12) {
			return [...circles, renderCircle(`${MAX_COUNT}+`)];
		} else return '--';
	}, []);

	const renderNames = useCallback((names: INames[], type: string) => {
		if (!Array.isArray(names) || !names.length) return <></>;
		return (
			<div id='Deals__name-list' className="Deals__name-list">
				{names.map(({ name, isPrimary }, index) => (
					<div
						key={`${name}-${index.toString()}`}
						className="Deals__name-wrapper"
					>
						{renderCircle(name, index, isPrimary)}
						<div>
							<div className="Deals__name">{name}</div>
							{isPrimary && <div className="Deals__sub-td">Primary {type}</div>}
						</div>
					</div>
				))}
			</div>
		);
	}, []);

	const renderPersons = useCallback(
		(deal: ITransactionDeal, key: string) => {
			const { dealId } = deal ?? {};
			let persons = deal[key as 'sellers'] ?? {};

			if (!Array.isArray(persons) || !persons?.length) {
				return '--';
			}

			// Create a new array with the item having percent 100 at the beginning if it exists
			const personWith100 = persons.find(
				person => person.paymentPercentage === 100
			);
			if (personWith100) {
				const otherPersons = persons.filter(
					person => person.paymentPercentage !== 100
				);
				persons = [{ ...personWith100, isPrimary: true }, ...otherPersons];
			}

			// Create an array of full names
			const names =
				persons?.map(person => ({
					name:
						person.firstName &&
						`${person.firstName} ${person.lastName ?? ''}`.trim(),
					isPrimary: person.isPrimary,
				})) ?? [];

			const usersType = key === 'sellers' ? 'seller' : 'buyer';

			return (
				<div
					ref={wrapperRef}
					className="Deals__persons"
					onClick={e => {
						e.preventDefault();
						e.stopPropagation();
						setShowName({ [`${dealId}-${key}`]: true });
					}}
				>
					{renderCircles(names)}
					{showName[`${dealId}-${key}`] && renderNames(names, usersType)}
				</div>
			);
		},
		[renderCircles, renderNames, showName]
	);

	return { renderPersons };
};
