import type { IRecipient } from "./types";

import { atom } from "recoil";

export const RecipientLocalState = atom<IRecipient[]>({
  key: "selected-recipients-user-local-state-key",
  default: [],
});

export const RecipientErrorsState = atom<{ [key: string]: string[] }>({
  key: "recipients-error-state",
  default: {},
});
