import { useCallback } from 'react';
import { paymentCardTypes } from '../../../constant';
import { useChoose } from '../../hooks';
import classNames from 'classnames';

export const PaymentChoose = () => {
	const { choose, handleChoose } = useChoose();
	
	const activePaymentMethod = useCallback((id:any)=>{return classNames('payment-method_wrapper_method', {
		'payment-method_wrapper_method--wire': id === 'Wire',
		'payment-method_wrapper_method_active': choose === id,
	  })},[choose])
	return (
		<div className="payment-method_wrapper">
			{paymentCardTypes.map(method => {
				return (
					<div
						className={activePaymentMethod(method.id)}
						key={method.key}
						onClick={handleChoose(method.id)}
					>
						<div className="payment-method_wrapper_detail">
							<div className="payment-method_wrapper_detail_icon-wrapper">
								<i className={method.icon} />
							</div>
							<div className="payment-method_wrapper_detail_text">
								{method.label}
							</div>
						</div>
						{choose === method.id && (
							<span className="payment-method_wrapper_method_selected">
								Selected
							</span>
						)}
					</div>
				);
			})}
		</div>
	);
};
