import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { kycSettingsState } from 'global-stores';
import { IDonutPieChart } from '../states';

export const useDonutChart = () => {
	const kycSettings = useRecoilValue(kycSettingsState);
	const { settings } = useMemo(() => kycSettings, [kycSettings]);
	const { bodyFont } = settings ?? {};

	const getDonutConfig = ({
		isLoading,
		title,
		value,
		seriesName,
		seriesData,
		innerSize,
		setSeriesName,
		setValue,
		chartWidth,
		percentColor,
		isSession,
	}: IDonutPieChart) => {
		const chart =
			(window as any).Highcharts?.chart(title, {
				chart: {
					type: 'pie',
					backgroundColor: 'transparent',
					height: chartWidth,
					width: chartWidth,
					animation: true,
					spacingTop: 0,
					spacingRight: 0,
					spacingBottom: 0,
					spacingLeft: 0,
					plotBorderWidth: 0,
					style: {
						fontFamily: bodyFont?.family !== '' ? bodyFont?.family : 'Poppins',
					},
					events: {
						render: function () {
							// eslint-disable-next-line @typescript-eslint/no-this-alias
							const chart: any = this,
								sum = value;

							chart.textGroup = chart.renderer.g('textGroup').add().toFront();

							const customText = (chart.myCustomText = chart.renderer
								.text(
									`<div style="color:${percentColor}; fontSize:${
										isSession ? '14px' : '24px'
									}">${sum}${
										title === 'signDashboard' ? '' : '%'
									}</div></br><div style="color:#7C8398">${seriesName}</div>`,

									isSession
										? chart.plotWidth / 1.48
										: seriesName.length > 13
										? chart.plotWidth / 1.25
										: seriesName.length > 10
										? chart.plotWidth / 1.46
										: seriesName.length > 6
										? chart.plotWidth / 1.52
										: chart.plotWidth / 1.6,
									isSession ? chart.plotHeight / 1.8 : chart.plotHeight / 2
								)
								.css({
									fontSize: '14px',
									lineHeight: 20,
									textAlign: 'center',
									fontWeight: 500,
									verticalAlign: 'middle',
									textAnchor: 'middle',
								})
								.add(chart.textGroup));
							chart.textGroup.translate(
								-chart.myCustomText.getBBox().width / 2
							);

							return customText;
						},
					},
				},

				title: {
					text: null,
				},

				credits: {
					enabled: false,
				},

				accessibility: {
					enabled: false,
				},
				exporting: { enabled: false },

				legend: {
					width: 0,
					align: 'center',
					verticalAlign: 'bottom',
					floating: true,
					x: 25,
					y: -30,
					symbolRadius: 10,
					title: {
						text: null,
					},
					itemStyle: {
						color: '#000000',
						fontWeight: '400',
						fontSize: '12px',
						lineHeight: '16px',
					},
					itemHoverStyle: {
						color: '#000000',
					},
				},

				plotOptions: {
					pie: {
						states: {
							inactive: {
								enabled: false,
							},
						},
						innerSize,
						borderWidth: 1,
						borderColor: undefined,
						showInLegend: false,
						borderRadius: 0,
						cursor: isSession ? 'default' : 'pointer',
						dataLabels: {
							enabled: false,
						},
					},
				},

				tooltip: {
					enabled: !isSession,
					borderWidth: 0,
					formatter: function (this: any) {
						return title === 'signDashboard'
							? `<span style="color:${this.color}">` +
									this.key +
									' : </span><b>' +
									this.y +
									'</b>'
							: `<span style="color:${this.color}">` +
									this.key +
									' : </span><b>' +
									this.y.toFixed(1) +
									'%</b>';
					},
				},

				series: [
					{
						id: 'idData',
						name: title,
						data: seriesData,
						point: {
							events: {
								click: function (this: any) {
									if (isSession) return;
									setSeriesName(this.name);
									setValue(
										title === 'signDashboard' ? this.y : this.y.toFixed(1)
									);
									this.series.data.map((point: any) => {
										if (point.name === this.name) {
											point.update({ opacity: 1 }, false);
										}
										if (point.name !== this.name) {
											point.update({ opacity: 0.2 }, false);
										}
									});
								},
							},
						},
						states: {
							hover: {
								enabled: false,
							},
						},
					},
				],
			}) ?? {};

		if (isLoading) {
			chart.showLoading();
		} else {
			chart.hideLoading();
		}
	};
	return { getDonutConfig };
};
