export const Messages = {
	NotAuth: 'Please verify your mobile number',
	PhoneError: 'Phone not verified',
	PhoneErrorMessage: 'Please verify your mobile number',
	AccountLocked: 'Account locked',
	AccountLockedErrorMessage:
		'Your account has been locked. Please contact your administrator',
	EmailError: 'Email not verified',
	EmailErrorMesssage: 'Please verify your Email',
	loginfaildeMessage: 'Failed to login try again later.',
	// InvalidEmail: 'Invalid email Id',
	// InvalidPhone: 'Invalid phone number',
	isSmallErrorMsg: "Please enter a valid credentials.",
	InvalidEmail: 'Please enter a valid email or phone number.',
	InvalidPhone: 'Please enter a valid email or phone number.',
	UnExpectedError: 'There are some unexpected errors, Please try again later',

};
