import { useCallback, useState } from 'react';
import { useNetwork, useNotification } from 'hooks';
import { useSetRecoilState } from 'recoil';
import { API_URL } from 'constant';
import {
	DocumentsUploaded,
	UploadedDocumentsState,
	ProgressState,
} from './state';
import { DocsLabel, DocumentsTypes } from '../components/constants';

export const useUploadDocuments = () => {
	const setUploadDocuments = useSetRecoilState(UploadedDocumentsState);
	const setAddedFiles = useSetRecoilState(DocumentsUploaded);
	const setProgress = useSetRecoilState(ProgressState);
	const [isLoaded, setIsLoaded] = useState(true);
	const { errorNotification, successNotification } = useNotification();

	const { get, post, formData } = useNetwork();

	const fetchUploadedDocuments = useCallback(
		async (id: string): Promise<void> => {
			setUploadDocuments(prev => ({ ...prev, isLoaded: false }));
			const resp = await get(API_URL.GET_UPLOADED_DOCUMENTS + id);
			const { data } = resp ?? {};
			if (data) {
				const formatedData = data.map((docs: any) => ({
					...docs,
					type: DocsLabel[docs.type],
				}));
				setUploadDocuments(prev => ({
					...prev,
					data: formatedData,
					isLoaded: true,
				}));
				return;
			}
			setUploadDocuments(prev => ({ ...prev, error: true, isLoaded: true }));
		},
		[get, setUploadDocuments]
	);

	const fileUplaod = useCallback(
		async (payload: any) => {
			setAddedFiles(prev => ({ ...prev, isLoaded: false }));
			const resp = await formData(API_URL.ATTACHMENT, payload);
			const { _id } = resp ?? {};
			if (_id) {
				setProgress({ isDisable: true, perentage: 100, data: [] });
				setAddedFiles(prev => {
					const prevState = { ...prev };
					prevState.data = [
						{ ...resp, type: DocumentsTypes[0], password: '' },
						...prevState.data,
					];
					return { ...prevState, isLoaded: true };
				});
				return;
			}
			setAddedFiles(prev => ({ ...prev, isLoaded: true }));
			errorNotification('Failed to upload file, try again');
		},
		[errorNotification, formData, setAddedFiles, setProgress]
	);

	const uploadAllDocument = useCallback(
		async (payload: any): Promise<boolean> => {
			setIsLoaded(false);
			const resp = await post(API_URL.UPLOAD_DOCUMENTS, payload);
			if (resp) {
				setIsLoaded(true);
				successNotification('Requested document sent successfully');
				return true;
			}
			errorNotification('Failed to upload documents. try again');
			setIsLoaded(true);
			return false;
		},
		[errorNotification, post, successNotification]
	);

	return {
		fetchUploadedDocuments,
		uploadAllDocument,
		fileUplaod,
		isLoaded,
	};
};
