/* eslint-disable react/no-array-index-key */
import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { Button, Loader } from '@storybook';
import { FundRecipientBankAccount } from 'global-stores';
import { useFormatNumber } from 'utils';
import { ShowEscrowDetails } from '../constants';
import {
	EscrowDetailsAtom,
	EscrowModalBodyNavigatorState,
	EscrowTransactionTypeState,
	FundDataForEscrowState,
	SelectedBankSubItemState,
	SeletedBankItemState,
} from '../stotes';
import { NoEscrow } from './no-escrow';

export const ShowDetails = () => {
	// globle states
	const fund = useRecoilValue(FundDataForEscrowState);
	const fundRecipient = useRecoilValue(FundRecipientBankAccount);
	const escrowDetails = useRecoilValue(EscrowDetailsAtom);
	const setNavigate = useSetRecoilState(EscrowModalBodyNavigatorState);
	const setTransactionType = useSetRecoilState(EscrowTransactionTypeState);
	const [selectedBankItem, setSelectedBankItem] =
		useRecoilState(SeletedBankItemState);
	const [selectedSubItem, setSelectedSubItem] = useRecoilState(
		SelectedBankSubItemState
	);

	// hooks
	const { numberDecimal } = useFormatNumber();

	const { data, loaded } = useMemo(() => escrowDetails, [escrowDetails]);
	const { total } = useMemo(() => data, [data]);
	const { escrowAccounts, name } = useMemo(() => ({ ...fund }), [fund]);

	// select first bank in the list at first time
	useEffect(() => {
		if (fundRecipient.length > 0) {
			if (!selectedBankItem) {
				setSelectedBankItem(fundRecipient[0]);
			}
			if (!selectedSubItem) {
				const item = fundRecipient[0];
				const subItem = item?.accounts?.[0];
				setSelectedSubItem({ ...subItem, _id: item?._id });
			}
		}
		// eslint-disable-next-line
	}, [fundRecipient, selectedBankItem, selectedSubItem]);

	// handle withdraw
	const handleWithdraw = useCallback(() => {
		setTransactionType('withdraw');
		if (fundRecipient.length) {
			setNavigate('withdraw_fund');
		} else {
			setNavigate('add_bank_account');
		}
	}, [fundRecipient.length, setNavigate, setTransactionType]);

	// handle deposit
	const handleDeposit = useCallback(() => {
		setTransactionType('deposit');
		if (fundRecipient.length) {
			setNavigate('deposite_fund');
		} else {
			setNavigate('add_bank_account');
		}
	}, [fundRecipient.length, setNavigate, setTransactionType]);

	const isNumber = useCallback(
		(key: string) =>
			key === 'disbursable' || key === 'locked' || key === 'pending',
		[]
	);

	const showDesc = useMemo(
		() => (
			<div className="show-desc">
				<div className="total-balance">
					<div className="total-balance__label">Total balance</div>
					<div className="total-balance__value">
						${numberDecimal(total || 0)}
					</div>
				</div>
				{ShowEscrowDetails.map((item, index) => (
					<div className="other-data-blocks" key={index}>
						{item.map(subItem => (
							<div className="data-row" key={subItem.key}>
								<div>{subItem?.label}</div>:
								<div
									className={`${
										subItem?.label === 'Currency' ? 'data-row__currency' : ''
									}`}
								>
									<span
										className={`${
											!isNumber(subItem.key) ? 'data-row__hide-dollar' : ''
										}`}
									>
										$
									</span>
									{isNumber(subItem.key)
										? numberDecimal(data?.[subItem['key']] || 0)
										: data?.[subItem['key']] || 'NA'}
								</div>
							</div>
						))}
					</div>
				))}
			</div>
		),
		[total, data, isNumber, numberDecimal]
	);

	return (
		<>
			{escrowAccounts.length ? (
				!loaded ? (
					<Loader type="gif" />
				) : (
					<div className="show-details-wrapper">
						<div className="show-header">
							<div className="show-header__wallet-wrapper">
								<i className="ri-wallet-3-line" />
							</div>
							<div className="show-header__fund-name">{name}</div>
							<div className="show-header__btn-wrapper">
								<Button
									label="Withdraw"
									handleClick={handleWithdraw}
									type="button__filled button__filled--secondary"
								/>
								<Button
									label="Deposit"
									handleClick={handleDeposit}
									type="button__filled button__filled--primary"
								/>
							</div>
						</div>
						{showDesc}
					</div>
				)
			) : (
				<NoEscrow />
			)}
		</>
	);
};
