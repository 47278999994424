import { FC, useMemo } from 'react';

import { StatusOptions, PathOptions } from './constants';
import { useQuestionnaireQuestion } from './hooks';
import {
	AllowedQuestionnaire,
	QuestionnaireTypes,
} from '../questionnaire-choice';
import { CustomHandle } from '../../../custom-handle';
import { Select } from '../../../select';

import './questionnaire-question.scss';

export interface IQuestionnaireQuestion {
	id: string;
	element: any;
	page: string;
	selectedForm: string;
}

export interface IChoice {
	text: string;
	value: string;
}

export const QuestionnaireQuestion: FC<IQuestionnaireQuestion> = ({
	element,
	page,
	id: nodeId,
	selectedForm = '',
}) => {
	// hooks
	const {
		getSourceHandle,
		getChoices,
		handleStatusChange,
		handlePathChange,
		paths,
		status,
	} = useQuestionnaireQuestion({ element, page, id: nodeId, selectedForm });

	const { type, title, name } = element ?? {};

	const renderChoices = useMemo(
		() =>
			(getChoices ?? []).map((choice: IChoice) => {
				const path = paths?.[getSourceHandle(choice?.value ?? choice)];
				const selectedStatus =
					status?.[getSourceHandle(choice?.value ?? choice)];
				const checkPath = path?.value === PathOptions[1]?.value;
				return (
					<div
						key={choice?.value ?? choice}
						className="QuestionnaireQuestion--answer"
					>
						<div className="QuestionnaireQuestion--answerChoice">
							{QuestionnaireTypes[type] && (
								<input type={QuestionnaireTypes[type]} />
							)}
							<span>{choice?.text ?? choice ?? '--'}</span>
						</div>
						<div className="QuestionnaireQuestion--approve">
							<Select
								defaultValue={StatusOptions[1]}
								options={StatusOptions}
								value={selectedStatus ?? StatusOptions[1]}
								handleChangeSelect={e => handleStatusChange(e, choice)}
								type="param"
								isSearchable={false}
							/>
						</div>
						<div className="QuestionnaireQuestion--path">
							<Select
								defaultValue={PathOptions[0]}
								value={path ?? PathOptions[0]}
								options={PathOptions}
								handleChangeSelect={e => handlePathChange(e, choice)}
								type="param"
								isSearchable={false}
							/>
							<div
								className={`QuestionnaireQuestion--customHandle ${
									checkPath ? 'QuestionnaireQuestion--show' : ''
								}`}
							>
								<CustomHandle
									value={getSourceHandle(choice?.value ?? choice)}
									showLeft={false}
								/>
							</div>
						</div>
					</div>
				);
			}),
		[
			getChoices,
			type,
			paths,
			getSourceHandle,
			handleStatusChange,
			handlePathChange,
			status,
		]
	);

	return (
		<div key={name} className="QuestionnaireQuestion">
			{AllowedQuestionnaire[type] && (
				<div className="QuestionnaireQuestion--label">
					{AllowedQuestionnaire[type]}
				</div>
			)}
			<div className="QuestionnaireQuestion--body">
				<div className="QuestionnaireQuestion--title">
					<i className="ri-question-line QuestionnaireQuestion--icon" />
					<span className="QuestionnaireQuestion--question">
						{title ?? name ?? '--'}
					</span>
				</div>
				{renderChoices}
			</div>
		</div>
	);
};
