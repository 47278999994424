import type {
	IAddressComponent,
	ILocationInfo,
	ITransactionDevice,
} from 'views/transactions/stores';

import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { REACT_APP_GOOGLE_MAP_SECRET_TOKEN as TOKEN } from 'envs';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

import { Loader } from '@storybook';

import { MapComponent } from 'views/sessions';
import {
	TransactionDeviceInfo,
	TransactionType,
} from 'views/transactions/constants';
import { GOOGLE_API } from 'constant';
import { useGetLocationDetails } from 'hooks';

export const TransactionDevice = ({
	type,
	senderOS,
	recipientOS,
	userOsName,
	userOsVersion,
	senderIP,
	recipientIP,
	senderBrowserMajor,
	senderBrowserName,
	latitude,
	longitude,
	appleDeviceID,
}: ITransactionDevice) => {
	const url = `${GOOGLE_API.MAP}${latitude},${longitude}`;
	const [location, setLocation] = useState<ILocationInfo>();
	const { getGeoInfo } = useGetLocationDetails();

	const fetchLocationData = async () => {
		try {
			const locationData = await getGeoInfo({ lat: latitude, lng: longitude });
			if (locationData) {
				// Location data is available, do something with it
				const countryCode = locationData.country.iso;

				const city = locationData.address.address_components.find(
					(component: IAddressComponent) => component.types.includes('locality')
				)?.long_name;

				const region = locationData.address.address_components.find(
					(component: IAddressComponent) =>
						component.types.includes('administrative_area_level_1')
				)?.long_name;
				const locationInfo = { countryCode, city, region };
				setLocation(locationInfo);
			} else {
				// eslint-disable-next-line no-console
				console.error('Failed to fetch location data.');
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('Error fetching location data:', error);
		}
	};

	useEffect(() => {
		fetchLocationData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [latitude, longitude]);

	const mapRender = useCallback((status: Status): ReactElement => {
		if (status === Status.FAILURE) return <></>;
		return <Loader />;
	}, []);

	const userOs = useMemo(
		() =>
			type === TransactionType.Sender
				? userOsName && userOsVersion
					? `${userOsName} ${userOsVersion}`
					: senderOS || 'NA'
				: recipientOS || 'NA',
		[recipientOS, senderOS, type, userOsName, userOsVersion]
	);

	const userBrowser = useMemo(
		() =>
			type === TransactionType.Sender
				? senderBrowserName && senderBrowserMajor
					? `${senderBrowserName} ${senderBrowserMajor}`
					: 'NA'
				: 'NA',
		[senderBrowserMajor, senderBrowserName, type]
	);

	const userIP = useMemo(
		() =>
			type === TransactionType.Sender ? senderIP || 'NA' : recipientIP || 'NA',
		[recipientIP, senderIP, type]
	);

	const userAppleId = useMemo(() => {
		return type === TransactionType.Sender
			? appleDeviceID?.senderAppleId || ''
			: appleDeviceID?.recipientAppleId || '';
	}, [appleDeviceID?.recipientAppleId, appleDeviceID?.senderAppleId, type]);

	const userDeviceId = useMemo(() => {
		return type === TransactionType.Sender
			? appleDeviceID?.senderDeviceId || 'NA'
			: appleDeviceID?.recipientDeviceId || 'NA';
	}, [appleDeviceID?.recipientDeviceId, appleDeviceID?.senderDeviceId, type]);

	const deviceInfoValues: { [key: string]: string } = useMemo(
		() => ({
			os: userOs,
			ip: userIP,
			browser: userBrowser,
			city: location?.city || 'NA',
			region: location?.region || 'NA',
			countryCode: location?.countryCode.toUpperCase() || 'NA',
			apple_id: userAppleId,
			device_id: userDeviceId,
		}),
		[
			userOs,
			userIP,
			userBrowser,
			location?.city,
			location?.region,
			location?.countryCode,
			userAppleId,
			userDeviceId,
		]
	);

	const renderTransactionDevice = useMemo(
		() => (
			<div className="TransactionCard--device-info">
				{(TransactionDeviceInfo ?? []).map(
					({ key, label, icon, infoTypeKey }) => (
						<div
							key={key}
							className={`TransactionCard--device-info__details-container`}
						>
							<div className="TransactionCard--device-info__details">
								<div
									className={`TransactionCard--device-info__icon-container ${infoTypeKey}`}
								>
									<i className={icon} />
								</div>
								<div className="TransactionCard--device-info__detailed">
									<span className="TransactionCard--device-info__key">
										{label}
									</span>
									<span className={`TransactionCard--device-info__value`}>
										{key === 'apple_id'
											? deviceInfoValues?.[key]?.toLowerCase() || 'NA'
											: deviceInfoValues?.[key] ?? 'NA'}
									</span>
								</div>
							</div>
						</div>
					)
				)}
			</div>
		),
		[deviceInfoValues]
	);

	return (
		<div className="TransactionCard--device">
			<div className="TransactionCard--device-title">
				<span>Device Info</span>
				<div className="TransactionCard--device-title__line" />
			</div>
			{renderTransactionDevice}
			{latitude && longitude && (
				<div className="map_container_body">
					<a
						href={url}
						target="_blank"
						rel="noreferrer"
						className="map_container"
					>
						<Wrapper apiKey={TOKEN} render={mapRender}>
							<MapComponent
								center={{
									lat: latitude,
									lng: longitude,
								}}
								zoom={11}
							/>
						</Wrapper>
					</a>
				</div>
			)}
		</div>
	);
};
