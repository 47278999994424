import { FC, useCallback, useMemo, useState } from 'react';
import { ConfigRow } from './add-config-row';
interface IOptions {
	label: string;
	value: string;
}
interface ICapTableHeader {
	options: IOptions[];
	label: string;
	isOptional: boolean;
	isRequired: boolean;
	inputType: string;
}
interface IProps {
	capTableHeader: ICapTableHeader[];
}

export const ConfigCaptable: FC<IProps> = ({ capTableHeader }) => {
	const [isReset, setIsReset] = useState(false);

	const handleOnClick = useCallback(() => {
		setIsReset(true);
	}, []);

	const renderCapTableConfiguration = useMemo(() => {
		return (
			<>
				<div className="reset">
					<div className="reset-btn" onClick={handleOnClick}>
						Reset All
					</div>
				</div>
				<div className="config-row-wrapper">
				{capTableHeader?.map(({ isRequired, label, options, isOptional }) => (
					<ConfigRow
						key={label}
						isRequired={isRequired}
						label={label}
						options={options}
						isOptional={isOptional}
						isReset={isReset}
						setIsReset={setIsReset}
					/>
				))}
				</div>		
			</>
		);
	}, [capTableHeader, handleOnClick, isReset]);

	return (
		<div className="captable-config override_captable-config">
			{renderCapTableConfiguration}
		</div>
	);
};
