import { FC, useCallback, useMemo, useState } from 'react';
import { KybInfoHeader } from '../kyb-info-header';

import './subsidiary-verification.scss';
import Modal from '@storybook/new-modal/modal';
import { Seperator } from 'views/sessions/components/divider';
import {
	KYB_INFO_HEADER_KEY,
	KYB_TYPE_TABS,
	KybOfacSelectedTab,
} from '../../hooks';
import { KybInformationDetails } from '../kyb-information-details';
import { KYB_TABS, getIconColor } from '../../constant';
import { IKybDetails, ISubsidiaryBusiness } from 'global-stores/sessions/type';
import { NoDataAvailable } from '@storybook';
import { OFAC } from '../ofac';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { ComplexSessionsNodesKybOfacDetailsState } from 'global-stores';

interface ISubsidiaryVerification {
	item: ISubsidiaryBusiness[]; // Array of subsidiary business items
}

// SubsidiaryVerification for KYB Business who has linked/subsidiary business
export const SubsidiaryVerification: FC<ISubsidiaryVerification> = ({
	item: subsidiaries,
}) => {
	// global states
	const [ofacSelector, setOfacSelector] = useRecoilState(
		ComplexSessionsNodesKybOfacDetailsState
	);
	const resetKybOfacSelectedTab = useResetRecoilState(KybOfacSelectedTab);
	const setKybOfacSelectedTab = useSetRecoilState(KybOfacSelectedTab);

	// local states
	// State to toggle showing all subsidiaries
	const [showAllSubsidiaries, setShowAllSubsidiaries] = useState(false);
	// State to manage visibility of report modal
	const [isShowReportModal, setIsShowReportModal] = useState(false);
	// State to manage KYB tabs of report modal
	const [selectedSubsidiaryKybTabType, setSelectedSubsidiaryKybTabType] =
		useState('kyb-enhanced');

	// State to hold data for the selected report
	const [reportData, setReportData] = useState<ISubsidiaryBusiness | null>();

	// State to store locally previous ofac data
	const [previousOFACData, setPreviousOFACData] = useState(ofacSelector);

	// Toggle function to show/hide all subsidiaries
	const toggleshowAllSubsidiaries = () => {
		setShowAllSubsidiaries(!showAllSubsidiaries);
	};

	// get subsidiaries based on showAllSubsidiaries state
	const getSubsidiaries = useMemo(() => {
		return showAllSubsidiaries ? subsidiaries : subsidiaries?.slice(0, 2);
	}, [showAllSubsidiaries, subsidiaries]);

	// function to handle viewing a subsidiary report
	const handleView = useCallback(
		(subsidiary: ISubsidiaryBusiness) => {
			const currentOFAC = structuredClone(ofacSelector);
			setReportData(subsidiary);
			setPreviousOFACData(currentOFAC);
			setOfacSelector(subsidiary?.ofac?.report);
			setKybOfacSelectedTab(
				subsidiary?.ofac?.report?.subcases?.value?.[0] || 'PEP'
			);
			setIsShowReportModal(true);
		},
		[ofacSelector, setKybOfacSelectedTab, setOfacSelector]
	);

	// render each subsidiary business details
	const renderSubsidiary = useMemo(() => {
		return getSubsidiaries?.map((data: ISubsidiaryBusiness, index: number) => {
			const { enhanced: subsidiary } = data;
			// Ensure getColor array colors cycle when index exceeds array length
			const iconColor = getIconColor[index % getIconColor?.length]?.color;
			return (
				<div
					className="subsidiary-verification__container"
					key={subsidiary?.tin}
				>
					<div className="subsidiary-verification__wrapper">
						<div className="subsidiary-verification__header">
							<div className="subsidiary-verification__header__company-icon">
								<i
									className="ri-building-fill"
									// Set icon color dynamically
									style={{ color: iconColor }}
								/>
							</div>
							<span className="subsidiary-verification__header__company-name">
								{subsidiary?.companyName || 'N/A'}
							</span>
						</div>
						<div className="subsidiary-verification__details__content">
							<span className="subsidiary-verification__details__label">
								TIN/EIN
							</span>
							<span style={{ fontWeight: '500' }}>:</span>
							<span className="subsidiary-verification__details__value">
								{subsidiary?.tin || 'N/A'}
							</span>
						</div>
						<div className="subsidiary-verification__details__content">
							<span className="subsidiary-verification__details__label">
								Address
							</span>
							<span style={{ fontWeight: '500' }}>:</span>
							<span className="subsidiary-verification__details__value">
								{subsidiary?.address || 'N/A'}
							</span>
						</div>
						<div className="subsidiary-verification__details__content">
							<span className="subsidiary-verification__details__label">
								KYB
							</span>
							<span style={{ fontWeight: '500' }}>:</span>
							<div className="subsidiary-verification__details__value">
								{subsidiary?.status ? (
									<div
										className={`subsidiary-verification__details__completed-badge`}
									>
										<i
											className={`subsidiary-verification__completed-status ri-checkbox-circle-fill`}
										/>
										<span className="subsidiary-verification__details__status">
											{subsidiary?.status}
										</span>
									</div>
								) : (
									'N/A'
								)}
							</div>
						</div>
						<div className="subsidiary-verification__details__content">
							<span className="subsidiary-verification__details__label">
								KYB Report
							</span>
							<span style={{ fontWeight: '500' }}>:</span>
							<div className="subsidiary-verification__details__value">
								<button
									className="subsidiary-verification__details__view-button"
									onClick={() => handleView(data || {})}
								>
									<i className="ri-eye-line" />
									<span className="subsidiary-verification__details__view-button__text">
										View
									</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			);
		});
	}, [getSubsidiaries, handleView]);

	// function to handle closing the report modal
	const handleOnCloseReportModal = useCallback(() => {
		const prevOFAC = structuredClone(previousOFACData);
		setIsShowReportModal(false);
		setReportData(null);
		setSelectedSubsidiaryKybTabType('kyb-enhanced');
		setPreviousOFACData(null);
		setOfacSelector(prevOFAC);
		resetKybOfacSelectedTab();
	}, [previousOFACData, resetKybOfacSelectedTab, setOfacSelector]);

	// render no data available component
	const renderNoSubsidiaryDataAvailable = useMemo(() => {
		return (
			<NoDataAvailable
				className="subsidiary-verification__subsidiary-report__no-data"
				message="No data available"
				illustration="empty-api-list.svg"
				hideBtn
			/>
		);
	}, []);

	// Render the report modal component
	const renderReportModalComponent = useMemo(() => {
		const { enhanced, ofac } = reportData || {};
		const { report: enhancedReport, companyName } = enhanced || {};
		const { report: ofacReport } = ofac || {};
		return (
			<>
				<Seperator />
				<div className="kyb-wrapper__details__kybInfo-header__sub-title" />
				<Seperator />
				<div className="subsidiary-verification__subsidiary-report__header">
					<i className="subsidiary-verification__subsidiary-report__header__header-icon ri-building-line" />
					<span className="subsidiary-verification__subsidiary-report__header__title">
						{companyName || 'N/A'}
					</span>
				</div>
				<Seperator />
				<div className="kyb-wrapper__kyb-type-wrapper">
					{KYB_TABS.map(item => (
						<span
							key={item}
							className={`kyb__type ${
								item === selectedSubsidiaryKybTabType ? 'kyb-active' : ''
							}`}
							onClick={() => setSelectedSubsidiaryKybTabType(item)}
						>
							{KYB_TYPE_TABS[item] || item}
						</span>
					))}
				</div>
				<Seperator />
				<div className="subsidiary-verification__subsidiary-report__wrapper">
					{selectedSubsidiaryKybTabType === 'kyb-enhanced' ? (
						<>
							{enhancedReport
								? KYB_INFO_HEADER_KEY.map(key => (
										<KybInformationDetails
											type={key}
											item={enhancedReport as unknown as IKybDetails}
											key={key}
										/>
								  ))
								: renderNoSubsidiaryDataAvailable}
						</>
					) : ofacReport ? (
						<OFAC />
					) : (
						renderNoSubsidiaryDataAvailable
					)}
				</div>
			</>
		);
	}, [
		renderNoSubsidiaryDataAvailable,
		reportData,
		selectedSubsidiaryKybTabType,
	]);

	// Subsidiary report modal props object
	const modalProps = {
		title: (
			<span className="subsidiary-verification__subsidiary-report__title">
				KYB Report
			</span>
		),
		modalName: 'subsidiary-report',
		className: 'subsidiary-verification__subsidiary-report',
		closeModal: handleOnCloseReportModal,
		children: renderReportModalComponent,
		isOpen: isShowReportModal,
	};

	// Render nothing if subsidiaries array is empty
	if (!subsidiaries?.length) {
		return <></>;
	}

	return (
		<>
			<div className="kyb-members_header">
				<KybInfoHeader type="Subsidiary Verification" />
				{subsidiaries?.length > 2 && (
					<div
						onClick={toggleshowAllSubsidiaries}
						className="kyb-members_view-more"
					>
						{showAllSubsidiaries ? 'View less' : 'View more'}
					</div>
				)}
			</div>

			{/* Render subsidiary items */}
			<div className="kyb-members_container">{renderSubsidiary}</div>
			{/* Render modal if isShowReportModal is true */}
			{isShowReportModal && <Modal {...modalProps} />}
		</>
	);
};
