import { FC, useMemo } from 'react';
import { KYB_INFO_HEADER_KEY } from '../../hooks';
import { KybInformationDetails } from '../kyb-information-details';
import { IKybDetails, ILinkRepresentative } from 'global-stores/sessions/type';
import { KybMembers } from '../kyb-members';
import { KYBInsightDetail } from '../kyb-insight-details';
import { SubsidiaryVerification } from '../subsidiary-verification';
import { SimilarCompanies } from '../similar-companies';
import { StatusUploadedDocuments } from '../status-uploaded-documents';
import { Announcements } from '../enhanced/announcement/announcement';
import { BeneficialOwners } from '../enhanced/beneficial-owners/beneficial-owners';
import { Branches } from '../enhanced/branches/branches';
import { ViewMoreHOC } from '../view-more-hoc/view-more-hoc';
import { AdditionalInfoWrapper, IndustryDetails } from '../enhanced';
import { KYBLoadingSection } from '../kyb-status-processing-ilustration';

interface IKYB {
	kyb: any;
}

export const KybEnchancedDetails: FC<IKYB> = ({ kyb }) => {
	const { data, uploadedFile } = useMemo(() => kyb?.[0] ?? {}, [kyb]);
	const { isDetailsFetched } = data?.companyDetails ?? {};

	const renderEnChancedkybCompanyDetail = useMemo(() => {
		return (
			<div className="kyb-wrapper__details kyb-wrapper__details-wrapper">
				{KYB_INFO_HEADER_KEY.map(key => (
					<KybInformationDetails
						linkRepresentatives={
							data?.linkRepresentatives as ILinkRepresentative[]
						}
						type={key}
						item={data?.companyDetails?.enhanced?.report as IKybDetails}
						key={key}
					/>
				))}
				<KybMembers
					item={data?.companyDetails?.enhanced?.report as IKybDetails}
				/>
				<IndustryDetails
					items={
						data?.companyDetails?.enhanced?.report?.industryDetails
							?.industryDetails
					}
				/>
				{/* <ViewMoreHOC
					Component={IndustryDetails}
					componentProp={
						data?.companyDetails?.enhanced?.report?.industryDetails
							.industryDetails
					}
					heading="Announcements"
				/> */}
				{/* This section if HIDE for now. Will go in Version 2.
				<KybFiling
					item={data?.companyDetails?.enhanced?.report as IKybDetails}
				/> */}
				<ViewMoreHOC
					Component={Announcements}
					componentProp={
						data?.companyDetails?.enhanced?.report?.announcements?.value
					}
					heading="Announcements"
				/>
				<ViewMoreHOC
					Component={BeneficialOwners}
					componentProp={
						data?.companyDetails?.enhanced?.report?.beneficialOwners?.value
					}
					heading="Beneficial Owners"
				/>
				<ViewMoreHOC
					Component={Branches}
					componentProp={
						data?.companyDetails?.enhanced?.report?.branches?.value
					}
					heading="Branches"
				/>
				{data?.companyDetails?.enhanced?.report?.similarCompanies && (
					<SimilarCompanies
						item={data?.companyDetails?.enhanced?.report as IKybDetails}
					/>
				)}
				<AdditionalInfoWrapper
					data={
						data?.companyDetails?.enhanced?.report?.metadata?.additionalDetails
							?.value as any
					}
				/>
				<KYBInsightDetail
					kyb={data?.companyDetails?.enhanced?.report as IKybDetails}
				/>
				<SubsidiaryVerification item={data?.companyDetails?.subsidiaries} />

				{/* StatusUploadedDocuments should always be at the bottom */}
				{Array.isArray(uploadedFile) && uploadedFile.length > 0 && (
					<StatusUploadedDocuments files={uploadedFile} />
				)}
				{isDetailsFetched === 'pending' && <KYBLoadingSection />}
			</div>
		);
	}, [
		data?.companyDetails?.enhanced?.report,
		data?.companyDetails?.subsidiaries,
		data?.linkRepresentatives,
		uploadedFile,
		isDetailsFetched,
	]);

	return renderEnChancedkybCompanyDetail;
};
