import { atom } from 'recoil';
import {
	IDocumentsUploadedAtom,
	IDocumentType,
	IProgressAtom,
	IUploadedDocumentsAtom,
	IUserType,
} from './types';

export const UploadedDocumentsState = atom<IUploadedDocumentsAtom>({
	key: 'uploaded-documents-public',
	default: {
		error: false,
		isLoaded: true,
		data: [],
	},
});
export const DocumentsUploaded = atom<IDocumentsUploadedAtom>({
	key: 'documents-uploaded-public',
	default: {
		isLoaded: true,
		error: false,
		data: [],
	},
});
export const ProgressState = atom<IProgressAtom>({
	key: 'Progress-public',
	default: {
		data: [],
		isDisable: false,
		perentage: 10,
	},
});
export const DocumentType = atom<IDocumentType>({
	key: 'Document-Type-public',
	default: {
		label: 'Others',
		value: 'others',
	},
});
export const UserStatus = atom<IUserType>({
	key: 'Document-Type-UserStatus',
	default: {
		isActive: false,
	},
});
