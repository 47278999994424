import { useCallback, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { PaymentCardMethodsState } from 'global-stores';
import { useNotification } from 'hooks';
import {
	CancelSubscriptionActiveStepState,
	CancelSubscriptionDueAmountState,
} from '../states';
import {
	CANCEL_SUBSCRIPTION_STEPS,
	paymentValidationMessages,
} from '../../constants';
import { useCancelSubscriptionConfirmation } from './useCancelSubscriptionConfirmation';

export const useCancelSubscriptionDues = () => {
	// Recoil state values for due amount, payment method, and active step in the process
	const dueAmount = useRecoilValue(CancelSubscriptionDueAmountState);
	const setActiveStep = useSetRecoilState(CancelSubscriptionActiveStepState);
	const paymentData = useRecoilValue(PaymentCardMethodsState);

	const { processedCancelSubscription } = useCancelSubscriptionConfirmation();
	const { errorNotification } = useNotification();

	// Memoized payment method validation logic
	const validatePaymentMethod = useMemo(() => {
		// Step 1: Ensure payment data contains a valid method
		if (!paymentData?.default?.method) {
			return {
				isPaymentMethodValid: false,
				reasonForInvalid: paymentValidationMessages.noPaymentMethod, // Error if no payment method
			};
		}

		// Destructure necessary fields from the paymentData
		const { method, detail } = paymentData.default ?? {};
		const { cards, wire } = paymentData ?? {};

		// Get the current date for card expiration checks
		const today = new Date();
		const currentMonth = today.getMonth() + 1; // Months are 0-based, so add 1
		const currentYear = today.getFullYear();

		// Step 2: Validation for different payment methods (ACH, card, wire)
		const validateACH = () => {
			// Ensure ACH method has linked bank accounts
			if (!detail?.accounts?.length) {
				return {
					isPaymentMethodValid: false,
					reasonForInvalid: paymentValidationMessages.noBankAccounts, // Error if no bank accounts
				};
			}

			const account = detail.accounts[0];
			// Ensure ACH account is correctly structured
			if (
				!account.accountId ||
				!account.mask ||
				account.type !== 'depository'
			) {
				return {
					isPaymentMethodValid: false,
					reasonForInvalid: paymentValidationMessages.invalidACHAccount, // Error if account details are invalid
				};
			}

			return { isPaymentMethodValid: true }; // ACH method is valid
		};

		const validateCard = () => {
			// Find the default card from the list of user's cards
			const defaultCard = cards?.find(
				(card: { default: { [key: string]: string | number } }) => card.default
			);
			// Check if no cards exist or no default card is selected
			if (!cards?.length || !defaultCard) {
				return {
					isPaymentMethodValid: false,
					reasonForInvalid: paymentValidationMessages.noCards, // Error if no default card
				};
			}

			// Check if the card has expired
			if (
				defaultCard.exp_year < currentYear ||
				(defaultCard.exp_year === currentYear &&
					defaultCard.exp_month < currentMonth)
			) {
				return {
					isPaymentMethodValid: false,
					reasonForInvalid: paymentValidationMessages.expiredCard, // Error if card is expired
				};
			}

			return { isPaymentMethodValid: true }; // Card method is valid
		};

		const validateWire = () => {
			// Ensure wire transfer details are complete
			if (!wire?.accountNumber || !wire?.routingNumber) {
				return {
					isPaymentMethodValid: false,
					reasonForInvalid: paymentValidationMessages.noWireDetails, // Error if wire details are missing
				};
			}

			return { isPaymentMethodValid: true }; // Wire transfer method is valid
		};

		// Step 3: Switch-case to validate different payment methods
		switch (method) {
			case 'ach':
				return validateACH(); // Validate ACH
			case 'card':
				return validateCard(); // Validate Card
			case 'wire':
				return validateWire(); // Validate Wire Transfer
			default:
				return {
					isPaymentMethodValid: false,
					reasonForInvalid: paymentValidationMessages.unknownMethod, // Error if payment method is unknown
				};
		}
	}, [paymentData]); // Recalculates whenever paymentData changes

	// Step 4: Callback to clear payment and process cancellation
	const onClearPayment = useCallback(() => {
		const { isPaymentMethodValid, reasonForInvalid } =
			validatePaymentMethod ?? {};
		if (!isPaymentMethodValid && reasonForInvalid) {
			// Show error notification if payment method is invalid
			return errorNotification(reasonForInvalid);
		}
		// Set the next active step and process cancellation
		setActiveStep(CANCEL_SUBSCRIPTION_STEPS.PROCESSING);
		processedCancelSubscription(CANCEL_SUBSCRIPTION_STEPS.DUES);
	}, [
		errorNotification,
		processedCancelSubscription,
		setActiveStep,
		validatePaymentMethod,
	]);

	// Step 5: Return the necessary methods and formatted amount for dues
	return {
		onClearPayment, // Method to handle payment clearing
		formattedAmount: Number(dueAmount)?.toFixed(2), // Format due amount to 2 decimal places
	};
};
