import { FC } from 'react';
import { Button, Image } from '@storybook';

import './pipeline-modal.scss';

interface IReactPipelineModal {
	visible: boolean;
	title?: string;
	handleClose?: () => void;
	body: JSX.Element;
	showHeader?: boolean;
	showFooter?: boolean;
	showPrimaryBtn?: boolean;
	showSecondaryBtn?: boolean;
	primaryBtnLevel?: string;
	secondaryBtnLevel?: string;
	primaryBtnClicked?: () => void;
	secondaryBtnClicked?: () => void;
	leftFooterCard?: JSX.Element;
	isFullScreen?: boolean;
}

export const ReactPipelineModal: FC<IReactPipelineModal> = ({
	visible,
	title = '',
	handleClose,
	body,
	showHeader = true,
	showFooter = true,
	showPrimaryBtn = true,
	showSecondaryBtn = true,
	primaryBtnLevel = '',
	secondaryBtnLevel = '',
	primaryBtnClicked = () => ({}),
	secondaryBtnClicked = () => ({}),
	leftFooterCard,
	isFullScreen = true,
}) => {
	if (!visible) {
		return null;
	}

	return (
		<div className="ppl__modal-overlay">
			<div
				className={`ppl__modal-content ppl__modal-content${
					!isFullScreen ? '--small' : ''
				}`}
			>
				{showHeader && (
					<div className="ppl__modal-header">
						<div className="ppl__brand">
							<Image
								className="ppl__brand-logo"
								fileName="SatschelLogo-Light.svg"
							/>
						</div>
						<div className="ppl__right-bar">
							<div className="ppl__title">{title}</div>
							{handleClose && (
								<div className="ppl__close" onClick={handleClose}>
									Exit
								</div>
							)}
						</div>
					</div>
				)}

				<div className="ppl__modal-body">{body}</div>
				{showFooter && (
					<div className="ppl__modal-footer">
						{leftFooterCard && (
							<div className="ppl__left-footer">{leftFooterCard}</div>
						)}

						<div className="ppl__right-footer">
							{showSecondaryBtn && (
								<Button
									handleClick={secondaryBtnClicked}
									label={secondaryBtnLevel}
									type="button__filled--secondary button__large button__iconFront"
									icon="ri-arrow-left-s-line"
								/>
							)}
							{showPrimaryBtn && (
								<Button
									handleClick={primaryBtnClicked}
									label={primaryBtnLevel}
									type="button__filled--primary button__large"
									icon="ri-arrow-right-s-line"
								/>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
