import { Button, Image, Loader, ReactDropdown } from '@storybook';
import Modal from '@storybook/new-modal/modal';
import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { FundsState } from 'global-stores';
import {
	AssignSelectedSessionState,
	ISelectedFundModal,
	useSelectedFundRequests,
} from './stores';

import './select-fund-modal.scss';

export const SelectedFundModal: FC<ISelectedFundModal> = ({
	isOpen = false,
	handleClose,
}) => {
	const [selectedFund, setSelectedFund] = useState('');
	const selectedAssignSession = useRecoilValue(AssignSelectedSessionState);
	const funds = useRecoilValue(FundsState);

	const { submitAssignFundToSession, isLoaded } = useSelectedFundRequests();

	const fundsOptions = useMemo(
		() =>
			funds?.map(item => {
				return { value: item.id, label: item.name };
			}),
		[funds]
	);

	const handleChange = useCallback(
		(event: { value: string; label: string }) => {
			setSelectedFund(event.value);
		},
		[]
	);

	const isSingleComplexSession = useMemo(() => {
		if (selectedAssignSession?.length === 1) {
			return selectedAssignSession[0]?.type === 'complex';
		}
		return false;
	}, [selectedAssignSession]);

	const handleSubmit = useCallback(() => {
		const payload = {
			sessionIds: selectedAssignSession?.map((el: any) => el._id) ?? [],
			fundId: selectedFund,
			...(isSingleComplexSession && { type: 'complex' }),
		};
		submitAssignFundToSession(payload, handleClose);
	}, [
		selectedAssignSession,
		selectedFund,
		handleClose,
		submitAssignFundToSession,
		isSingleComplexSession,
	]);

	useEffect(() => {
		if (selectedFund === '' && fundsOptions?.length > 0) {
			setSelectedFund(fundsOptions[0]?.value ?? '');
		}
	}, [fundsOptions, selectedFund]);

	const renderMainComponent = useMemo(() => {
		if (selectedAssignSession.length === 0)
			return (
				<div className="configure_session_modal__empty_wrapper">
					<Image fileName="invoice-illustration.svg" />
					<div className="configure_session_modal__empty_wrapper__title">
						Please select atleast one session
					</div>
				</div>
			);
		else
			return (
				<Fragment>
					<div className="selected_fund_modal__body_wrapper">
						<div className="selected_fund_modal__body_wrapper__form_items">
							<div className="selected_fund_modal__body_wrapper__label">
								Select Fund
							</div>
							<ReactDropdown
								options={fundsOptions}
								optionsStyle={{
									'&:hover': { background: 'var(--color-hover-light-100)' },
								}}
								defaultValue={fundsOptions[0]}
								handleChangeSelect={handleChange}
								isSearchable
							/>
						</div>
					</div>
					<div className="selected_fund_modal__footer_wrapper">
						<Button
							handleClick={handleClose}
							label="Cancel"
							type="button__ghost button__filled--secondary"
							buttonType="button"
						/>

						<Button
							handleClick={handleSubmit}
							type="button__filled button__filled--primary"
							buttonType="button"
							label={
								!isLoaded ? (
									'Submit'
								) : (
									<Loader
										type="loader"
										className="loader-white"
										dimension={20}
									/>
								)
							}
							disabled={isLoaded}
						/>
					</div>
				</Fragment>
			);
	}, [
		selectedAssignSession.length,
		fundsOptions,
		handleChange,
		handleClose,
		handleSubmit,
		isLoaded,
	]);
	return (
		<Modal
			isOpen={isOpen}
			closeModal={handleClose}
			className="selected_fund_modal"
			modalName="Session modal"
			title={
				<div className="selected_fund_modal__header_wrapper">
					<div className="selected_fund_modal__header_wrapper__title">
						Select Fund/Company
					</div>
					<div className="selected_fund_modal__header_wrapper__sub-title">
						{`Adding fund/company to selected ${
							selectedAssignSession.length ?? 0
						}
             sessions`}
					</div>
				</div>
			}
		>
			{renderMainComponent}
		</Modal>
	);
};
