import { useNetwork } from 'hooks';
import { useRecoilState } from 'recoil';
import { API_URL } from 'constant';
import { PaymentCardMethodsLoadingState, PaymentCardMethodsState } from 'global-stores';
import { useState } from 'react';

export const usePaymentCard = () => {
	const [paymentCardMethodsState, setPaymentCardMethodsState] = useRecoilState(
		PaymentCardMethodsState
	);
	const [paymentLoadingState,setPaymentLoadingState] = useRecoilState(PaymentCardMethodsLoadingState);
	const [loading, setLaoding] = useState(false);
	const { get: getPaymentCards } = useNetwork({ updateState: false });

	const getPaymentCardsApi = async () => {
		setLaoding(true);
		setPaymentLoadingState(true);
		const resp = await getPaymentCards(API_URL.Payment_Methods);

		if (resp?.message === 'ok') {
			const { data = [] } = resp;
			setPaymentCardMethodsState(data[0]);
		}
		setLaoding(false);
		setPaymentLoadingState(false);
	};

	return {
		getPaymentCardsApi,
		loading,
		paymentCardMethodsState,
		paymentLoadingState
	};
};
