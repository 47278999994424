import { useMemo } from 'react';
import CSS from 'csstype';

interface Props {
	bgcolor: string;
	progress: number;
	parentStyle?: CSS.Properties;
	childStyle?: CSS.Properties;
}

/**
 * Progress component
 *
 * @param {string} Props.bgcolor - Background color of the progress bar
 * @param {number} Props.progress - Progress value as a percentage
 * @param {CSS.Properties} Props.parentStyle - Styles for the parent container
 * @param {CSS.Properties} Props.childStyle - Styles for the progress bar
 */

const Progress = ({
	bgcolor,
	progress,
	childStyle = {},
	parentStyle = {},
}: Props) => {
	const styles = useMemo(() => {
		const childdiv = {
			height: '100%',
			width: `${progress}%`,
			backgroundColor: bgcolor,
			borderRadius: 40,
			...childStyle,
		};
		const parentdiv = {
			height: 6,
			width: '60%',
			backgroundColor: '#D9D9D9',
			borderRadius: 40,
			marginTop: 5,
			...parentStyle,
		};

		return {
			parentdiv,
			childdiv,
		};
	}, [bgcolor, childStyle, parentStyle, progress]);

	return (
		<div style={styles.parentdiv}>
			<div style={styles.childdiv}></div>
		</div>
	);
};

export default Progress;
