import type { IDateRange } from 'global-stores';
import type { INavigation } from '../store';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { endOfDay } from 'date-fns';

import { Button, Image, Loader, Skeleton } from '@storybook';
import { loginState, SelectedDateRangeState } from 'global-stores';
import { UserSignature } from './user-signature';
import { TitleModal } from './title-modal';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes';
import { DocumentStartNow } from './document-start-now';
import { DocumentStats } from './document-stats';
import {
	InboxfilteringTagsState,
	SignDocInboxSelector,
} from 'views/signed-doc-inbox';
import { IsCreateSignModalOpen } from 'views/create-sign';
import { PERMISSION_SCREEN, useUserRoles } from 'views/routes-children';
import {
	PacketInboxfilteringTagsState,
	SignDocPacketsInboxSelector,
	useSignedDocsPacketsInbox,
} from 'views/signed-doc-packets-inbox';
import {
	SignDashboardSignatureState,
	SignDashboardState,
	initialSignDefault,
	signDefault,
} from '../store';
import { createdAt } from 'constant';
import { EsignWebhookUrl } from './esign-webhook-url';


export const DashboardUserDetails = () => {
	const { data: packetSentBox} = useRecoilValue(SignDocInboxSelector);
	const {data: packetInbox} = useRecoilValue(SignDocPacketsInboxSelector);
	const { data, isLoaded } = useRecoilValue(SignDashboardState);
	const setIsOpen = useSetRecoilState(IsCreateSignModalOpen);
	const { data: signatureData } = useRecoilValue(SignDashboardSignatureState);
	const userData = useRecoilValue(loginState);
	const setPacketFilteringTags = useSetRecoilState(
		PacketInboxfilteringTagsState
	);
	const setSelectedDateRange = useSetRecoilState(SelectedDateRangeState);
	const { getSignedDocsPacketsInbox } = useSignedDocsPacketsInbox();
	const setInboxFilteringTags = useSetRecoilState(InboxfilteringTagsState);


	const { initialDetail, signDetail } = signatureData;
	const { url: initialUrl } = initialDetail ?? {};
	const { url: signUrl } = signDetail ?? {};
	const { name, email, signature: signId } = data;
	const navigate = useNavigate();

	// This will change to SignDashboardState recoil
	const { image: profilePic } = userData;

	const [isOpenTitleModal, setIsOpenTitleModal] = useState(false);

	const { checkUserWritePermission, checkUserReadPermission } = useUserRoles();

	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.SignDashboard),
		[checkUserWritePermission]
	);

	const titleModal = useCallback(() => {
		setIsOpenTitleModal(true);
	}, []);

	const titlesubHead = useMemo(() => {
		if (data.title) {
			return data.title;
		}
		return 'No title';
	}, [data.title]);

	const showUserProfileImage = useMemo(() => {
		return {
			...(profilePic && { url: profilePic }),
			...(!profilePic && { fileName: 'AvatarDefault-Light.svg' }),
			className: 'user_avatar',
		};
	}, [profilePic]);

	useEffect(() => {
		getSignedDocsPacketsInbox();
	}, [getSignedDocsPacketsInbox]);

	const ActionCounts = useMemo(() => {
		let completedCount = 0;
		let waitingForOthersCount = 0;

		packetSentBox.forEach(({ status, recipients }) => {
			if (status === 'completed') {
				completedCount++;
			} else {
				const senderDetails = recipients.find(
					({ email }) => email === userData.email
				);
				if(!senderDetails && status !== "voided"){
					 waitingForOthersCount++;
					 return
				}
				if(senderDetails?.status === "completed" && status !== "voided") {
					waitingForOthersCount++;
					return
				}				
			}
		});
		return {
			completed: completedCount,
			waitingForOthers: waitingForOthersCount,
		};
	}, [packetSentBox, userData.email]);

/**
 * Calculates the number of completed inboxes and the number of inboxes requiring action based on the provided packet inbox data.
 *
 * @param {Array} packetInbox - An array containing packet inbox data, each element representing an inbox.
 * @param {string} userData.email - The email of the current user. Used to identify the sender in the recipients list.
 * @returns {Object} An object containing the counts of completed inboxes and inboxes requiring action.
 */
const inboxActionsCount = useMemo(() => {
    let inboxCompleted = 0; // Counter for completed inboxes
    let actionRequired = 0; // Counter for inboxes requiring action

    // Iterate through each inbox in the packetInbox array
    packetInbox.forEach(inbox => {
        // Check if the inbox status is 'completed'
        if (inbox.status === 'completed') {
            // Increment the completed inbox counter
            inboxCompleted++;
            return; // Exit the current iteration
        } else {
            // If the inbox is not completed, extract recipients information
            const { recipients } = inbox;
            // Get the email of the current user to identify the sender
            const senderEmail = userData?.email;
            // Find the index of the sender in the recipients list
            const senderIndexInRecipients = recipients.findIndex(
                recipient => recipient.email === senderEmail
            );

            // Check if the inbox purpose is 'workflow', sender is in recipients, and the inbox is not voided
            if (inbox.purpose === 'workflow' && senderIndexInRecipients > -1 && inbox.status !== "voided") {
                // Get the sender's information from the recipients list
                const senderAvailableInRecipients = recipients[senderIndexInRecipients];
                // Check if the sender's status is 'pending'
                if (senderAvailableInRecipients?.status === 'pending') {
                    // If the sign order is off, increment the actionRequired counter
                    if (!inbox.signOrder) {
                        actionRequired++;
                    } else {
                        // If the sign order is on, check if all recipients before the sender are completed
                        const recipientsBeforeSender = recipients.slice(0, senderIndexInRecipients);
                        const anyIncompleteBeforeSender = recipientsBeforeSender.some(
                            recipient => recipient.status !== 'completed'
                        );
                        // If all recipients before the sender are completed, increment the actionRequired counter
                        if (!anyIncompleteBeforeSender) {
                            actionRequired++;
                        }
                    }
                }
            }
        }
    }, []);

    // Return an object containing the counts of completed inboxes and inboxes requiring action
    return { inboxCompleted, actionRequired };
}, [packetInbox, userData?.email]);

	const NAVIGATION_STATUS: Record<string, INavigation> = useMemo(
		() => ({
			'Action required': {
				navigation: ROUTES.INBOX,
				status: ['needToSign'],
				inboxType: ["selfSign", "multiSigner"],
				setState: setPacketFilteringTags,
				setDateRange: setSelectedDateRange,
			},
			'Waiting for others': {
				navigation: ROUTES.SIGNED_DOC_INBOX,
				status: ['waitingForOthers'],
				setState: setInboxFilteringTags,
				inboxType: ["multiSigner", "onboarding"],
				setDateRange: setSelectedDateRange,
			},
			'Sentbox completed': {
				navigation: ROUTES.SIGNED_DOC_INBOX,
				status: ['completed'],
				inboxType: ["multiSigner", "onboarding", "selfSign"],
				setState: setInboxFilteringTags,
				setDateRange: setSelectedDateRange,
			},
			'Inbox completed': {
				navigation: ROUTES.INBOX,
				status: ['completed'],
				inboxType: ["multiSigner", "onboarding", "selfSign"],
				setState: setPacketFilteringTags,
				setDateRange: setSelectedDateRange,
			},
		}),
		[setInboxFilteringTags, setPacketFilteringTags, setSelectedDateRange]
	);

	const handleNavigate = useCallback(
		(clickedKey: string) => {
			// Perform actions based on the clicked key
			const nav: INavigation | undefined = NAVIGATION_STATUS[clickedKey];
			if (!nav) {
				return;
			}
			const { status: actionStatus, navigation, setState, setDateRange, inboxType } = nav;
			navigate(navigation);
			setState(prev => {
				const newState = structuredClone(prev);
				const finalState = {
					...newState,
					actionStatus,
					inboxType
				};
				return finalState;
			});
			if(createdAt) {
				const dateRange = {
					endDate: endOfDay(new Date()),
					key: "selection",
					startDate : new Date(createdAt),
				} as IDateRange
				setDateRange([dateRange])
			}
		},
		[NAVIGATION_STATUS, navigate]
	);

	const ActionContainer = useMemo(
		() => [
			{
				key: 'Action required',
				count: inboxActionsCount.actionRequired,
				isReadPermission: checkUserReadPermission(PERMISSION_SCREEN.Inbox)
			},
			{
				key: 'Waiting for others',
				count: ActionCounts.waitingForOthers,
				isReadPermission: checkUserReadPermission(PERMISSION_SCREEN.Sent)
			},
			{
				key: 'Sentbox completed',
				count: ActionCounts.completed,
				isReadPermission: checkUserReadPermission(PERMISSION_SCREEN.Sent)
			},
			{
				key: 'Inbox completed',
				count: inboxActionsCount.inboxCompleted,
				isReadPermission: checkUserReadPermission(PERMISSION_SCREEN.Inbox)
			},
		],
		[ActionCounts, inboxActionsCount, checkUserReadPermission]
	);

	const renderDocumentAction = useMemo(() => {
		return (
			<div className="user-dashboard__user-details-section__user-action">
				{ActionContainer.map(({ key, count, isReadPermission }) => {
					return (
						<div
							onClick={() => isReadPermission && handleNavigate(key)}
							key={key}
							className={`user-dashboard__user-details-section__user-action__container ${!isReadPermission ? 'user-dashboard__user-details-section__user-action__disabled': 'hover' }`}
						>
							<div className="user-dashboard__user-details-section__user-action__count">
								{!isLoaded ? <Skeleton height={15} /> : count}
							</div>
							<div className={`user-dashboard__user-details-section__user-action__action-type ${!isReadPermission ? 'user-dashboard__user-details-section__user-action__disabled': '' }`}>
								{key}
							</div>
						</div>
					);
				})}
			</div>
		);
	}, [ActionContainer, handleNavigate, isLoaded]);

	const createSignLabel = useMemo(() => {
		if (signId) {
			return 'Edit';
		}
		return 'Create';
	}, [signId]);

	const createSignIcon = useMemo(() => {
		if (signId) {
			return 'ri-edit-2-line';
		}
		return '';
	}, [signId]);

	const titleLabel = useMemo(() => {
		//Ankur Singh: If `data.title` exists, set `titleLabel` to 'Edit'; otherwise, set it to 'Add'.
		if (data.title) {
			return 'Edit';
		}
		return 'Add';
	}, [data.title]);

	const titleIcon = useMemo(() => {
		if (data.title) {
			return 'ri-edit-2-line';
		}
		return '';
	}, [data.title]);

	return (
		<div className="user-dashboard__main-container">
			<div className="user-dashboard__user-details-section hover">
				<div className="user-dashboard__user-details-section__user">
					<div className="user-dashboard__user-details-section__user-creds">
						<div className="user-dashboard__user-details-section__user-creds__profile-pic">
							{!isLoaded ? (
								<Loader className="loader-blue" dimension={30} type="loader" />
							) : (
								<Image {...showUserProfileImage} />
							)}
						</div>
						<div className="user-dashboard__user-details-section__user-creds__details-container">
							<div className="user-dashboard__user-details-section__user-creds__name">
								{!isLoaded ? <Skeleton width={75} height={15} /> : name}
							</div>
							<div className="user-dashboard__user-details-section__user-creds__email">
								{!isLoaded ? <Skeleton width={175} height={15} /> : email}
							</div>
						</div>
					</div>
					{renderDocumentAction}
				</div>
				<div className="user-dashboard__wrapper">
					<div
						className="user-dashboard__user-details-section__signature-container"
						style={{ width: '100%' }}
					>
						<div className="user-dashboard__user-details-section__signature-container__signature">
							<UserSignature
								defaultImage={signDefault}
								url={signUrl}
								className={`user-dashboard__signature-img signing-node ${
									signUrl ? '' : 'default-sign-img'
								}`}
								skeletonWidth={100}
								alt="Sign"
							/>

							<UserSignature
								defaultImage={initialSignDefault}
								url={initialUrl}
								className={`user-dashboard__initial-img signing-node ${
									initialUrl ? '' : 'default-sign-img'
								}`}
								skeletonWidth={70}
								alt="Initial"
							/>
						</div>
						{isUserPermissionWrite && (
							<Button
								label={createSignLabel}
								prefixIcon={createSignIcon}
								type={
									!isLoaded
										? 'button-hide'
										: 'button__filled button__filled--primary user-dashboard__edit-btn'
								}
								handleClick={() => setIsOpen(true)}
							/>
						)}
					</div>
					<div
						className="user-dashboard__user-details-section__signature-container"
						style={{ width: '100%' }}
					>
						<div className="user-dashboard__user-details-section__signature-container__signature">
							<div className='user-dashboard__title-container'>
								<div className="user-dashboard__title-head">Title</div>
								<div className="user-dashboard__title-subhead" id={data._id}>
									{!isLoaded ? (
										<Skeleton width={75} height={15} />
									) : (
										titlesubHead
									)}
								</div>
							</div>
						</div>
						<ReactTooltip
							anchorId={data._id}
							place="bottom"
							content={!data.title ? 'No title' : data.title}
							style={{ zIndex: 1000, color: '#ffffff' }}
						/>
						{isUserPermissionWrite && (
							<Button
								label={titleLabel}
								prefixIcon={titleIcon}
								handleClick={titleModal}
								type={
									!isLoaded
										? 'button-hide'
										: 'button__filled button__filled--primary user-dashboard__edit-btn'
								}
							/>
						)}
					</div>
					<EsignWebhookUrl/>
				</div>
			</div>

			<div className="user-logout-modal">
				<TitleModal
					isOpen={isOpenTitleModal}
					setModalClose={setIsOpenTitleModal}
					loading={false}
				/>
			</div>

			<div className="user-dashboard__main-container__box">
				<DocumentStats />
				{isUserPermissionWrite && <DocumentStartNow />}
			</div>
		</div>
	);
};
