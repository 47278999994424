import { atom } from 'recoil';
import { IInvestor } from '../constant';

export const InvestorsCount = atom({
	key: 'investors-count-survey-form-state-key',
	default: "1",
});

export const InvestorsDetails = atom<IInvestor[]>({
	key: 'investors-details-survey-form-state-key',
	default: [],
});

export const IsInvestorsDetailsDisabled = atom<boolean>({
	key: 'investors-details-disabled-survey-form-state-key',
	default: false,
});
