import { useEffect, useMemo } from 'react';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import 'survey-core/defaultV2.min.css';

import { useRecoilValue } from 'recoil';
import { SelectedSessionState } from 'global-stores';

export const SurveyReadAnswer = () => {
	const selectedSession = useRecoilValue(SelectedSessionState);

	const { form = { question: {}, answer: {} } } = selectedSession ?? {};

	const survey = useMemo(() => {
		return new Model({
			description: '',
			logoPosition: '',
			pages: form.question?.formAction?.pages,
			showQuestionNumbers: 'off',
			title: '',
		});
	}, [form.question?.formAction?.pages]);

	useEffect(() => {
		survey.data = form.answer;
		survey.mode = 'display';
	}, [form.answer, survey]);
	return (
		<div id="surveyForm">
			{' '}
			<Survey model={survey} />
		</div>
	);
};
