import { useCallback } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { Button } from '@storybook';
import Modal from '@storybook/new-modal/modal';
import { EscrowModalBody } from './components';
import { EscrowModalBodyNavigatorState, EscrowModalOpenState } from './stotes';

export const FundEscrow = () => {
	// globle states
	const setNavigate = useSetRecoilState(EscrowModalBodyNavigatorState);
	const [isModalOpen, setModalOpen] = useRecoilState(EscrowModalOpenState);

	//handle close modal
	const handleCloseModal = useCallback(() => {
		setModalOpen(false);
		setNavigate('show_details');
	}, [setModalOpen, setNavigate]);

	return (
		<>
			<Modal
				isOpen={isModalOpen}
				closeModal={handleCloseModal}
				className=""
				modalName="Escrow modal"
			>
				<EscrowModalBody />
			</Modal>
			<Button
				label="Escrow Account"
				handleClick={() => setModalOpen(true)}
				type="button__filled button__filled--primary fundEscrow__escrow_button_hide"
			/>
		</>
	);
};
