/* eslint-disable no-console */
/* eslint-disable react/no-array-index-key */
import { useRecoilValue } from 'recoil';
import { KycComperhensiveSectionHOC, KycSectionHOC } from './components';
import { kycComperhensiveSelector } from './store';

export const KycComprehensive = () => {
	const kycComperhensive = useRecoilValue(kycComperhensiveSelector);
	const { kycComperhensiveData = {} } = kycComperhensive ?? {};

	return (
		<div>
			{Object.keys(kycComperhensiveData ?? {}).map((sectionKey, index) => {
				const section = kycComperhensiveData?.[sectionKey] ?? {};
				if (
					Object.keys(section ?? {})?.length === 0 ||
					section?.data?.metrics === null ||
					section?.data?.metrics?.length === 0
				) {
					return;
				}
				return (
					<KycSectionHOC key={index}>
						<>
							<KycComperhensiveSectionHOC
								key={index}
								showViewMoreBtn={
									section?.data?.sectionType !== 'DropCard' &&
									section?.data?.metrics?.length > 2
								}
								sectionData={section ?? {}}
							/>
						</>
					</KycSectionHOC>
				);
			})}
		</div>
	);
};
