import { selector } from 'recoil';
import { SignedDocPacketsState } from './states';

export const SignDocPacketsInboxSelector = selector({
	key: 'sign-document-packets-inbox-selector-key',
	get: ({ get }) => {
		const { data, isLoaded } = get(SignedDocPacketsState);
		return {
			data,
			isLoaded
		};
	},
});
