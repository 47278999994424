import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { API_URL, MESSAGE } from 'constant';
import { useCookie, useNetwork, useNotification } from 'hooks';
import { WEBAUTH_URL } from './constants';
import {
	ApprovalStatusState,
	ConfigHeaderForWebAuthnState,
	OptionsResponseState,
	userIdbeforeLoginState,
	VerificationIDState,
} from './states';

let counter = 0;

export const useLogin = () => {
	const { get } = useNetwork();
	const { post } = useNetwork({ returnResponse: true });
	const [isLoaded, setIsLoaded] = useState(true);
	const [isSocialLogin, setSocialLogin] = useState({
		google: false,
		apple: false,
		microsoft: false,
	});
	const setuserIdbeforeLogin = useSetRecoilState(userIdbeforeLoginState);

	const { errorNotification } = useNotification();
	const { deleteCookie } = useCookie();

	const loginWithEmail = useCallback(
		async (payload: any) => {
			setIsLoaded(false);
			// Shahbaaz: for marketing user login url not set initially in state that why I'm putting default endpoint
			// pradeep : url.login is replaced by API_URL.USER_LOGIN constant handle vera issues
			const resp = await post(API_URL.USER_LOGIN, payload);
			setuserIdbeforeLogin(resp?.userId ?? "")
			if (resp?.errorCode === 400 && resp?.errorData?.message) {
				errorNotification(resp.errorData.message);
			}
			setIsLoaded(true);
			return resp;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps	
		[post]
	);

	const verifyEmailCode = useCallback(
		async (payload: any) => {
			setIsLoaded(false);
			// pradeep : url.login is replaced by API_URL.USER_LOGIN constant handle vera issues
			const resp = await post(API_URL.USER_LOGIN, payload);
			setIsLoaded(true);
			return resp;
		},
		[post]
	);

	const loginWithSocial = useCallback(
		async (
			type: 'facebook' | 'google' | 'microsoft' | 'apple',
			state?: string
		) => {
			// eslint-disable-next-line no-console
			console.log('3456');

			deleteCookie('user');
			localStorage.clear();
			setSocialLogin(prev => ({ ...prev, [type]: true }));
			await get(
				`${API_URL.CLIENT_SOCIAL_LOGIN}?type=${type}&state=${state}`
			).then(res => {
				const { data } = res ?? {};
				const { url } = data?.[0] ?? {};
				// eslint-disable-next-line @typescript-eslint/no-unused-expressions
				url ? (window.location.href = url) : errorNotification(res?.message);
			});
			setSocialLogin(prev => ({ ...prev, [type]: false }));
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[get]
	);

	const clientWebAuth = useCallback(
		async (payload: any) => {
			setIsLoaded(false);
			// pradeep : url.web_auth_api is replaced by API_URL.WebAuthLogin constant handle vera issues
			const resp = await post(API_URL.WebAuthLogin, payload);
			setIsLoaded(true);
			return resp;
		},
		[post]
	);

	const verifyInviteUser = useCallback(
		async (payload: any) => {
			const response = await post(API_URL.USER_LOGIN, payload);
			return response;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[post, API_URL.USER_LOGIN]
	);

	const isSocialDisable = useMemo(
		() => Object.values(isSocialLogin).some(el => el),

		[isSocialLogin]
	);

	return {
		loginWithEmail,
		verifyEmailCode,
		clientWebAuth,
		isLoaded,
		loginWithSocial,
		verifyInviteUser,
		isSocialLogin,
		isSocialDisable,
	};
};

const getOrigin = () => {
	const ancestorOriginsLength =
		Object.values(document?.location?.ancestorOrigins ?? {})?.length ?? 0;
	const val =
		window.location === window.parent.location
			? document.location.origin
			: document.location.ancestorOrigins?.[ancestorOriginsLength - 1];

	return val ?? 'https://app.simplici.io';
};

export const useWebLogin = () => {
	// globle states
	const setOptionsResponse = useSetRecoilState(OptionsResponseState);
	const verficationStatus = useRecoilValue(ApprovalStatusState);
	const [verificationId, setVerificationId] =
		useRecoilState(VerificationIDState);
	const [configHeader, setConfigHeader] = useRecoilState(
		ConfigHeaderForWebAuthnState
	);

	//local states
	const [isLoaded, setIsLoaded] = useState(true);
	const [isOptionsLoaded, setOptionsLoaded] = useState(true);

	//hooks
	const { post } = useNetwork({ updateState: false });
	const { errorNotification } = useNotification();
	const rpId = getOrigin();

	const registrationOptionsWithToken = useCallback(
		async (accessToken?: any) => {
			const configHeader = { Authorization: `Bearer ${accessToken}` };
			setConfigHeader(configHeader);
			setOptionsLoaded(false);
			const optionPayload = {
				type: 'registrationOptsUID',
				rpId,
			};
			const response = await post(
				WEBAUTH_URL.CLIENT_WEBAUTHN,
				optionPayload,
				configHeader
			);
			const { message, id, registrationOptions } = response ?? {};
			if (id && registrationOptions) {
				setOptionsResponse(response);
			} else {
				errorNotification(message ?? 'Something went wrong');
			}
			setOptionsLoaded(true);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[errorNotification, post, setOptionsResponse]
	);

	const registrationOptions = useCallback(
		async (verifiedEmailId?: string) => {
			setOptionsLoaded(false);
			let optionPayload: any = {
				type: 'registrationOpts',
				verificationId: verifiedEmailId ?? verificationId,
				rpId,
			};
			if (!verifiedEmailId && !verificationId) {
				optionPayload = {
					type: 'registrationOptsUID',
				};
			}
			const response = await post(WEBAUTH_URL.CLIENT_WEBAUTHN, optionPayload);
			const { message, id, registrationOptions } = response ?? {};
			if (id && registrationOptions) {
				setOptionsResponse(response);
			} else {
				errorNotification(message ?? MESSAGE.ERROR);
			}
			setOptionsLoaded(true);
		},
		[errorNotification, post, rpId, setOptionsResponse, verificationId]
	);

	useEffect(() => {
		if (verficationStatus) {
			if (verficationStatus === 'approved') {
				if (counter < 1) {
					// eslint-disable-next-line @typescript-eslint/no-use-before-define
					registrationOptions();
				}
				counter++;
			}
			if (verficationStatus === 'rejected') {
				errorNotification('Approval Rejected.');
			}
		}
		// eslint-disable-next-line
	}, [verficationStatus]);

	const registrationApproval = useCallback(
		async (payload: any) => {
			payload['rpId'] = rpId;
			setIsLoaded(false);
			const response = await post(WEBAUTH_URL.CLIENT_WEBAUTHN, payload);
			const { verificationId } = response ?? '';
			setVerificationId(verificationId);
			return response;
		},
		[post, rpId, setVerificationId]
	);

	const verifyRegistration = useCallback(
		async (payload: any) => {
			payload['rpId'] = rpId;
			const response = await post(WEBAUTH_URL.CLIENT_WEBAUTHN, payload);
			return response;
		},
		[post, rpId]
	);

	const verifyRegistrationWithToken = useCallback(
		async (payload: any) => {
			payload['rpId'] = rpId;
			const response = await post(
				WEBAUTH_URL.CLIENT_WEBAUTHN,
				payload,
				configHeader
			);
			return response;
		},
		[configHeader, post, rpId]
	);

	const authenticateOptions = useCallback(
		async (payload: any) => {
			payload['rpId'] = rpId;
			const response = await post(WEBAUTH_URL.CLIENT_WEBAUTHN, payload);
			return response;
		},
		[post, rpId]
	);

	const verifyAuthentication = useCallback(
		async (payload: any) => {
			const response = await post(WEBAUTH_URL.CLIENT_WEBAUTHN, payload);
			return response;
		},
		[post]
	);

	return {
		registrationOptions,
		registrationApproval,
		authenticateOptions,
		verifyAuthentication,
		isOptionsLoaded,
		verifyRegistration,
		isLoaded,
		registrationOptionsWithToken,
		verifyRegistrationWithToken,
	};
};

export const getWebAuthnSupported = async () => {
	// return await PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
	//@avinash
	return (
		window?.PublicKeyCredential !== undefined &&
		typeof window.PublicKeyCredential === 'function'
	);
};
