import { FC } from 'react';

import { AML_INFO_HEADER } from '../../constants';

interface IAmlInfoHeader {
	type: string;
}

// Pradeep Chaurasia :  New Aml Details header 
export const AmlInfoHeader: FC<IAmlInfoHeader> = ({ type }) => {
	return (
		<div className="kyb-wrapper__details__kybInfo-header">
			<div className="kyb-wrapper__details__kybInfo-header__title">
				{AML_INFO_HEADER[type] ?? type}
			</div>
			<div className="kyb-wrapper__details__kybInfo-header__sub-title" />
		</div>
	);
};
