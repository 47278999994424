import { IRoleType } from 'types';

export const ROLE: { [key in IRoleType]: IRoleType } = {
	OWNER: 'OWNER',
	ADMIN: 'ADMIN',
	MANAGER: 'MANAGER',
	DEVELOPER: 'DEVELOPER',
	SUPER_ADMIN: 'SUPER_ADMIN',
	AGENT: 'AGENT',
};
