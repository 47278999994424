import { atom } from 'recoil';

export type ISignininRoutes = 'phone' | 'chooseMethod' | 'otpVerify';

export const SignInActiveStep = atom<ISignininRoutes>({
	key: 'signin-activestep',
	default: 'phone',
});

// TODO
// interface ISignupFormData {
// 	firstName?: string;
// 	lastName?: string;
// 	email?: string;
// 	countryCode: string;
// 	phone: string;
// }
export const SocialSignupDetails = atom<any>({
	key: 'social-sign-up-details',
	default: {
		firstName: '',
		lastName: '',
		email: '',
		countryCode: '',
		phone: '',
	},
});

export const SocialSignupDetailsFromParams = atom<any>({
	key: 'social-sign-up-details-from-params',
	default: {
		firstName: '',
		lastName: '',
		email: '',
		countryCode: '',
		phone: '',
	},
});
