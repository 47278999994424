import type { IClient } from './type';

import { Button, Loader, NoDataAvailable } from '@storybook';
import Modal from '@storybook/new-modal/modal';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ConfirmationModal } from 'components';
import { API_URL, ROLE, message } from 'constant';
import {
	ClientNameState,
	ClientsCredentialsState,
	duplicateClientNameCheckState,
	loginState,
} from 'global-stores';
import { useTrackEvents } from 'helpers';
import { useNetwork, useNotification } from 'hooks';
import { Header } from 'views/header';
import { PERMISSION_SCREEN, useUserRoles } from 'views/routes-children';
import {
	CredentialTable,
	EditSecretModal,
	RevealSecretModal,
} from './components';
import './credential.scss';

interface ICredentials {
	loading?: boolean;
}

export const Credential: FC<ICredentials> = ({ loading = false }) => {
	const { successNotification, errorNotification } = useNotification();
	const {
		post,
		loading: clientSecretLoader,
		data: clientSecret,
	} = useNetwork();
	const { remove, data: deletedData } = useNetwork();
	const { patch, data: credentialUpdated, loading: saveLoading } = useNetwork();

	const {
		CREDS_SUCCESS,
		DELETE_MESSAGE,
		DESCRIPTION_MESSAGE,
		TITLE_MESSAGE,
		BOLD_DESCRIPTION,
		UPDATE_SUCCESS_MESSAGE,
	} = useMemo(() => message, []);

	const [client, setClient] = useRecoilState(ClientsCredentialsState);
	const [showDuplicateError, setShowDuplicateError] = useRecoilState<boolean>(
		duplicateClientNameCheckState
	);
	const [isDeleted, setDeleted] = useState<boolean>(false);
	const [isConfirmationModal, setIsConfirmationModal] =
		useState<boolean>(false);
	const [deleteID, setDeleteId] = useState<string>('');
	const [showError, setShowError] = useState<boolean>(false);
	const { MANAGER } = ROLE;

	const [revealSecret, setRevealSecret] = useState<IClient | any>({
		name: '',
		client: '',
		createdAt: '',
		clientId: '',
		clientSecret: '',
		updatedAt: '',
		_id: '',
		isReveal: false,
		isEdited: false,
	});
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isEditModalOpen, setEditIsModalOpen] = useState<boolean>(false);
	const credentialName = useRecoilValue(ClientNameState);
	const [editIndex, setEditIndex] = useState<number>(0);
	const { role } = useRecoilValue(loginState);

	const { trackEvents } = useTrackEvents();
	const { checkUserWritePermission } = useUserRoles();

	const handleAddCredential = useCallback(() => {
		trackEvents('create-new-credential', {});
		post(`${API_URL.CREDENTIAL}`, {});
		// eslint-disable-next-line
	}, []);

	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.DevelopersKeys),
		[checkUserWritePermission]
	);

	useEffect(() => {
		if (clientSecret) {
			const { message } = clientSecret ?? {};
			if (message) {
				errorNotification(message);
			} else {
				successNotification(CREDS_SUCCESS);
				setClient(prev => [...prev, clientSecret]);
			}
		}
		// eslint-disable-next-line
	}, [CREDS_SUCCESS, clientSecret, successNotification]);

	const handleDeleteCredential = useCallback((id: string) => {
		setIsConfirmationModal(true);
		setDeleteId(id);
	}, []);

	useEffect(() => {
		if (isDeleted) {
			remove(`${API_URL.CREDENTIAL}/${deleteID}`);
		}
	}, [deleteID, isDeleted, remove]);

	useEffect(() => {
		if (deletedData) {
			successNotification(DELETE_MESSAGE);
			client.filter(item => item._id !== deletedData._id);
			setClient(prevState => {
				return prevState.filter(item => item._id !== deletedData._id);
			});
			setDeleteId('');
			setDeleted(false);
		}
		// eslint-disable-next-line
	}, [deletedData]);

	const handleDeleteModel = useCallback((isOpen: boolean, value: boolean) => {
		setIsConfirmationModal(isOpen);
		setDeleted(value);
	}, []);

	const handleCloseModal = useCallback(() => {
		setIsModalOpen(false);
		setShowError(false);
		setShowDuplicateError(false);
		setEditIsModalOpen(false);
	}, [setShowDuplicateError]);

	const revealCredential = useCallback(
		(index: number) => {
			setRevealSecret({ ...client[index], isReveal: true });
			setIsModalOpen(true);
		},
		[client]
	);
	const editCredential = useCallback(
		(index: number) => {
			setRevealSecret({ ...client[index], isEdited: true });
			setEditIndex(index);
			setEditIsModalOpen(true);
		},
		[client]
	);

	const onUpdateName = useCallback(() => {
		if (credentialName.length < 3) return setShowError(true);
		patch(
			`${API_URL.CREDENTIAL}/${revealSecret.isEdited && revealSecret._id}`,
			{ name: credentialName }
		);
		setShowError(false);
	}, [patch, revealSecret.isEdited, revealSecret._id, credentialName]);

	useEffect(() => {
		if (credentialUpdated) {
			setClient(prev => {
				const prevState = JSON.parse(JSON.stringify(prev));
				prevState[editIndex] = credentialUpdated;
				return [...prevState];
			});
			successNotification(UPDATE_SUCCESS_MESSAGE);
			setEditIsModalOpen(false);
		}
		// eslint-disable-next-line
	}, [credentialUpdated]);

	const saveButtonLabel = useMemo((): JSX.Element => {
		if (saveLoading) {
			return <Loader type="loader" dimension={18} className="loader-white" />;
		}
		return <div>Save</div>;
	}, [saveLoading]);

	const saveButtonLabels = useMemo((): JSX.Element => {
		if (clientSecretLoader) {
			return <Loader type="loader" dimension={18} className="loader-white" />;
		}
		return <div>Create New</div>;
	}, [clientSecretLoader]);

	const renderCredentials = useMemo(() => {
		if (!loading && !clientSecretLoader && !client.length) {
			return (
				<NoDataAvailable
					label="Create New "
					message="No API Keys Available"
					illustration="empty-api-list.svg"
					description="You have not created any API key yet."
					handleClickBtn={handleAddCredential}
					disabled={role === MANAGER}
					hideBtn={!isUserPermissionWrite}
				/>
			);
		}
		return (
			<div className="crendentialTable">
				<div className="credential-table">
					<CredentialTable
						client={client}
						editCredential={editCredential}
						revealCredential={revealCredential}
						handleDeleteCredential={handleDeleteCredential}
						isLoading={clientSecretLoader}
					/>
				</div>
			</div>
		);
	}, [
		loading,
		clientSecretLoader,
		client,
		editCredential,
		handleAddCredential,
		handleDeleteCredential,
		isUserPermissionWrite,
		revealCredential,
		role,
		MANAGER,
	]);

	return (
		<div className="dashboard-main-body">
			<Header
				title="Developer Keys"
				showBtn={true}
				btnLabel={saveButtonLabels}
				handleClickBtn={handleAddCredential}
				disabled={clientSecretLoader}
			/>
			{renderCredentials}
			<ConfirmationModal
				visible={isConfirmationModal}
				title={TITLE_MESSAGE}
				description={DESCRIPTION_MESSAGE}
				boldDescription={BOLD_DESCRIPTION}
				handleModal={handleDeleteModel}
				label="Delete"
			/>
			<Modal
				isOpen={isModalOpen}
				modalName="Reveal Credential"
				closeModal={handleCloseModal}
				className="crendential-modal"
				showCloseBtn={true}
				isStopOutsideClick={false}
				title={
					<div className="credential_header">
						<div className="credential_title">Client Details</div>
					</div>
				}
			>
				<RevealSecretModal
					name={revealSecret.isReveal && revealSecret.name}
					clientId={revealSecret.isReveal && revealSecret.clientId}
					clientSecret={revealSecret.isReveal && revealSecret.clientSecret}
					creationDate={revealSecret.isReveal && revealSecret.createdAt}
				/>
			</Modal>

			<Modal
				isOpen={isEditModalOpen}
				modalName="Edit Credential"
				closeModal={handleCloseModal}
				className="crendential-modal"
				showCloseBtn={true}
				isStopOutsideClick={false}
				title={
					<div className="credential_header">
						<div className="credential_title">Client Details</div>
					</div>
				}
			>
				<EditSecretModal
					name={revealSecret.isEdited && revealSecret.name}
					clientId={revealSecret.isEdited && revealSecret.clientId}
					clientSecret={revealSecret.isEdited && revealSecret.clientSecret}
					creationDate={revealSecret.isEdited && revealSecret.createdAt}
					onUpdate={onUpdateName}
					error={showError}
				/>

				<div className={'edit-crendential-btns'}>
					<div className="btn-container">
						<Button
							handleClick={handleCloseModal}
							label={'Cancel'}
							type="button__filled button__filled--secondary button__large"
						/>

						<Button
							handleClick={onUpdateName}
							label={saveButtonLabel}
							type="button__filled button__filled--primary button__large"
							disabled={
								credentialName.length < 3 || showDuplicateError ? true : false
							}
						/>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default Credential;
