import { Image } from '@storybook/image';
import { FC, useState } from 'react';

interface IProps {
	fileName?: string;
	messageHeading?: string;
	messageSubHeading?: string;
}

export const TableSearchEmptyMessage: FC<IProps> = ({
	fileName = 'empty.svg',
	messageHeading = 'No Document Added',
	messageSubHeading = 'Please upload all agreement document',
}) => {
	const [loaded, setLoaded] = useState(false);
	return (
		<div
			className="empty-table-search"
			style={loaded ? { display: '' } : { display: 'none' }}
		>
			<Image
				fileName={fileName}
				className="empty-table__image"
				onLoad={() => setLoaded(true)}
			/>
			<div className="empty-table-search__message">
				{messageHeading}
				<div className="empty-table-search__sub-message">
					{messageSubHeading}
				</div>
			</div>
		</div>
	);
};
