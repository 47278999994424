import { useMemo } from 'react';
import Tippy from '@tippyjs/react';
import { Button, Input } from '@storybook';

import { formatNumberWithCommasAndCurrencySignSign } from 'utils';
import { Message } from 'components';
import { useDealHeader } from '../../stores';

import './deal-header.scss';

export const DealHeader = () => {
	// hooks
	const {
		totalAmount = 0,
		priceBreak,
		showEditPrice,
		isOpenEditPriceInput,
		handleIsOpenEditPriceInput,
		handleChangeAmount,
		handleSubmitAmount,
		newSalePrice,
		updatingPrice,
	} = useDealHeader();

	const renderDetailsInfo = useMemo(
		() =>
			priceBreak.map(({ key, label, value }) => (
				<div key={key} className="DealHeader__info-item">
					<div className="DealHeader__info-key">{label}</div>
					<span>:</span>
					<div className="DealHeader__info-value">
						{value}
						{showEditPrice(key) && (
							<>
								{!isOpenEditPriceInput ? (
									<Tippy content={'Update sale price'}>
										<i
											className="ri-edit-line DealHeader__edit-price"
											onClick={handleIsOpenEditPriceInput}
										/>
									</Tippy>
								) : (
									<div className="DealHeader__amount-wrapper DealHeader__show-amount-wrapper">
										<div className="DealHeader__input-amount">
											<Input
												inputType="text"
												handleChange={handleChangeAmount}
												placeholder="Enter revised sale price"
												label=""
												value={newSalePrice}
											/>
											<Button
												label={<i className="ri-check-line" />}
												handleClick={handleSubmitAmount}
												type="DealHeader__button-ok"
												disabled={updatingPrice}
											/>
											<Button
												label={<i className="ri-close-line" />}
												handleClick={handleIsOpenEditPriceInput}
												type="DealHeader__button-cancel"
											/>
										</div>
										<Message
											text={
												'The revised sales price must be lower than the current price. Any discrepancy will result in an added penalty charge so make sure to adjust the price accordingly to avoid penalty charges.'
											}
											varient="warning"
										/>
									</div>
								)}
							</>
						)}
					</div>
				</div>
			)),
		[
			handleChangeAmount,
			handleIsOpenEditPriceInput,
			handleSubmitAmount,
			isOpenEditPriceInput,
			newSalePrice,
			priceBreak,
			showEditPrice,
			updatingPrice,
		]
	);

	return totalAmount || priceBreak?.length ? (
		<div className="DealHeader border p-16 radius-4">
			<div className="DealHeader__info-wrapper">
				<div className="DealHeader__info-title">
					{totalAmount ? (
						formatNumberWithCommasAndCurrencySignSign(
							totalAmount.toFixed(2),
							'$'
						)
					) : (
						<>____ </>
					)}
				</div>
				{renderDetailsInfo}
			</div>
		</div>
	) : (
		<></>
	);
};
