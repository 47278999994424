import { useEffect } from 'react';
import { SubscriptionWrapper } from './components';
import './payment.scss';
import { useInviteOnboardingPayment } from './store';

export const InviteOnboardingPayment = () => {
	const { fetchBillingSummary } = useInviteOnboardingPayment();

	useEffect(() => {
		fetchBillingSummary();
		// eslint-disable-next-line
	}, []);

	return (
		<div className="subscription_wrapper">
			<SubscriptionWrapper />
		</div>
	);
};
