import { FC } from 'react';

import { getHoursMinutesSeconds } from 'helpers';
import { lineBarColor } from '../constants';

import './agent-status.scss';

interface IAgentStatus {
	name: string;
	count: number;
	time: number;
	percent: number;
}

export const AgentStatus: FC<IAgentStatus> = ({
	name,
	count,
	time,
	percent,
}) => {
	const inPercentage = `${percent ? percent : 0}%`;
	return (
		<div className="agent-status">
			<div className="agent-status_status">
				<div className="support-agent_status_action">{name}</div>
				<div className="support-agent_status_counts">{count}</div>
				<div className="support-agent_status_action">
					{getHoursMinutesSeconds(time)}
				</div>
			</div>
			<div className="agent-status_line">
				<div
					className="agent-status_percentage"
					style={{
						width: inPercentage,
						background: lineBarColor[name],
					}}
				/>
			</div>
		</div>
	);
};
