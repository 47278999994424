import { FC } from 'react';

import { Button, Image } from '@storybook';
import Modal from '@storybook/new-modal/modal';

import './subscribe-modal.scss';

interface ISubscribeModal {
	modalName: string;
	isOpen: boolean;
	closeModal: () => void;
	modalTitle?: string | JSX.Element;
	title: string | JSX.Element;
	subTitle: string | JSX.Element;
	buttonLabel: string;
	handleButton: () => void;
	className?: string;
}

export const SubscribeModal: FC<ISubscribeModal> = ({
	isOpen,
	closeModal,
	modalTitle = '',
	modalName,
	subTitle,
	title,
	buttonLabel,
	handleButton,
}) => {
	return (
		<Modal
			isOpen={isOpen}
			modalName={modalName}
			closeModal={closeModal}
			showCloseBtn
			isStopOutsideClick={false}
			className="subscription-modal"
			title={modalTitle}
		>
			<div className="subscribe-modal">
				<Image fileName="subscribe.svg" className="subscribe-modal__image" />
				<div>
					<div className="subscribe-modal__title">{title}</div>
					<div className="subscribe-modal__subtitle">{subTitle}</div>
				</div>
				<Button
					label={buttonLabel}
					type="button__filled--primary"
					handleClick={handleButton}
				/>
			</div>
		</Modal>
	);
};
