import { FC } from 'react';

interface IWaringMessage {
	text: string | JSX.Element;
}
export const WaringMessage: FC<IWaringMessage> = ({ text }) => {
	return (
		<div className="warning">
			<i className="ri-alarm-warning-line" />
			<div className="warning__message">{text}</div>
		</div>
	);
};
