import { FC, ReactElement, ReactNode } from 'react';
import './time-line.scss';

interface ITimeLine {
	children?: ReactElement;
	fromRejected?: boolean;
	className?: string;
	viewReportContent?: ReactNode;
}
export const TimeLine: FC<ITimeLine> = ({
	children,
	fromRejected = false,
	className = '',
	viewReportContent = <></>,
}) => {
	const onClickSupport = () => {
		(window as any)?.Intercom?.(
			'showNewMessage',
			'Could you update me on my account status?'
		);
	};
	return (
		<div className="Timeline">
			<h1>Verification Timeline</h1>
			<div className="Timeline--body">
				<div className="step">
					<div>
						<div className="circle"></div>
					</div>
					<div>
						<h5 className="title">Completed verification</h5>
						<p className="caption">Submitted for review</p>
					</div>
				</div>

				{className ? <div className="step">
					<div>
						<div className="circle"></div>
					</div>
					<div>
						<h5 className="title">In review</h5>
						<p className="caption">Approx. 0-4 working days</p>
					</div>
				</div> :
					<div className="step step-active">
						<div>
							<div className={`circle circle-review`}></div>
						</div>
						<div>
							<h5 className="title">In review</h5>
							<p className="caption">Approx. 0-4 working days</p>
						</div>
					</div>}

				{children ? (
					children
				) : (
					<div className="step step-active">
						<div>
							<div className="circle circle-pending"></div>
						</div>
						<div>
							<h5 className="title">Account ready</h5>
							<p className="caption">
								You can create onboarding flow, esign documents and other
								features.
							</p>
						</div>
					</div>
				)}
			</div>
			{!fromRejected ? (
				<div className="Timeline--contact-us">
					<h5 className="title">Have any questions?</h5>
					<p>Our support team can help you.</p>
					<div className="Timeline--btn" onClick={onClickSupport}>
						<i className="ri-mail-line"></i>
						<span>Facing trouble? Click here for assistance.</span>
					</div>
				</div>
			) : (
				viewReportContent
			)}
		</div>
	);
};
