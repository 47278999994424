import { FC, useCallback } from 'react';

import {
	SetterOrUpdater,
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
} from 'recoil';
import {
	IPaymentType,
	OnboardingPaymentTypeState,
	OnboardingPaymentCurrentPage,
} from '../../store';

import './switch-screen-toggle.scss';
import { ConditionalOnboardingFlowState } from 'views/pipelines/components/store';
import { useSandboxAndFreeTrial } from 'hooks';

interface IProps {
	isToggle: boolean;
	setIsToggle: SetterOrUpdater<boolean>;
}

export const SwitchScreenToggle: FC<IProps> = ({ isToggle, setIsToggle }) => {
	const [paymentType, setPaymentType] = useRecoilState(
		OnboardingPaymentTypeState
	);
	const isComplex = useRecoilValue(ConditionalOnboardingFlowState);

	const resetCurrentPage = useResetRecoilState(OnboardingPaymentCurrentPage);
	const { isSandboxMode } = useSandboxAndFreeTrial();

	const handleSwitchScreen = useCallback(() => {
		if (isComplex || isSandboxMode) return;
		const switchObj: { [key in IPaymentType]: IPaymentType } = {
			monthly_billing: 'pay_now',
			pay_now: 'monthly_billing',
		};
		setPaymentType(switchObj[paymentType]);
		setIsToggle(prev => !prev);
		resetCurrentPage();
	}, [
		paymentType,
		resetCurrentPage,
		setIsToggle,
		setPaymentType,
		isComplex,
		isSandboxMode,
	]);

	return (
		<div className="switch-screen-wrapper">
			<div className="switchScreen" onClick={handleSwitchScreen}>
				<span>Pay as you go</span>
				<span
					style={{
						...(isSandboxMode && {
							cursor: 'not-allowed',
							opacity: 0.5,
						}),
					}}
				>
					Monthly Billing
				</span>
				<div className={isToggle ? 'pay_now' : 'monthly_billing'}>
					{isToggle ? 'Pay as you go' : 'Monthly Billing'}
				</div>
			</div>
		</div>
	);
};
