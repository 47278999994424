import { useEffect } from 'react';

interface MetaProps {
	title: string;
	description?: string;
	keywords?: string;
}

const useDocumentTitle = ({ title, description, keywords }: MetaProps) => {
	const currentURL = window.location.href;

	useEffect(() => {
		const titleChar = title.replaceAll('/', '');
		const formattedChar =
			titleChar.charAt(0).toUpperCase() + titleChar.slice(1);
		const formattedTitle = `Simplici | ${formattedChar} Page`;
		document.title = formattedTitle;

		if (description) {
			let descriptionMetaTag = document.querySelector(
				"meta[name='description']"
			);
			if (!descriptionMetaTag) {
				descriptionMetaTag = document.createElement('meta');
				descriptionMetaTag.setAttribute('name', 'description');
				document.head.appendChild(descriptionMetaTag);
			}
			descriptionMetaTag.setAttribute('content', description);
		}

		if (keywords) {
			let keywordsMetaTag = document.querySelector("meta[name='keywords']");
			if (!keywordsMetaTag) {
				keywordsMetaTag = document.createElement('meta');
				keywordsMetaTag.setAttribute('name', 'keywords');
				document.head.appendChild(keywordsMetaTag);
			}
			keywordsMetaTag.setAttribute('content', keywords);
		}

		let ogUrlMetaTag = document.querySelector("meta[property='og:url']");
		if (!ogUrlMetaTag) {
			ogUrlMetaTag = document.createElement('meta');
			ogUrlMetaTag.setAttribute('property', 'og:url');
			document.head.appendChild(ogUrlMetaTag);
		}
		ogUrlMetaTag.setAttribute('content', currentURL);

		let ogTitleMetaTag = document.querySelector("meta[property='og:title']");
		if (!ogTitleMetaTag) {
			ogTitleMetaTag = document.createElement('meta');
			ogTitleMetaTag.setAttribute('property', 'og:title');
			document.head.appendChild(ogTitleMetaTag);
		}
		ogTitleMetaTag.setAttribute('content', formattedTitle);

	}, [title, description, keywords, currentURL]);
};

export default useDocumentTitle;
