import { atom } from "recoil";
import { ICredential } from "global-stores/sessions/type";

export const TextForFilterSessionsState = atom<string>({
    key:'text-for-filter-sessions-state-key',
    default: ''
})

export const SessionTextFilterState = atom<string>({
    key:'session-text-filter-state-key',
    default: ''
})

export const FetchingSessionsFilterState = atom<boolean>({
    key: 'fetching-session-filter-state-key',
    default : false
})

export const SessionWithoutFilterState = atom<ICredential[]>({
	key: 'session-without-filter-state-key',
	default: [],
});

export const ComplexSessionWithoutFilterState = atom<ICredential[]>({
	key: 'complex-session-without-filter-state-key',
	default: [],
});

export const FoundFilterSessionsCountState = atom<number>({
    key: 'found-filter-sessions-count-state-key',
    default: 0
})
