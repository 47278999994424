import type { IReactSwitch as Props } from './type';

import Switch from 'react-switch';

/**
 * Interface for the props of the ReactSwitch component.
 * @param {string} Props.id - The unique identifier for the switch element.
 * @param {boolean} Props.checked - The current state of the switch (checked or unchecked).
 * @param {(checked: boolean) => void} Props.handleChange - Callback function invoked when the switch state changes.
 * @param {number} Props.width - The width of the switch component.
 * @param {number} Props.height - The height of the switch component.
 * @param {string} Props.onColor - The color of the switch when it is in the "on" state.
 * @param {string} Props.offColor - The color of the switch when it is in the "off" state.
 * @param {number} Props.diameter - The diameter of the handle of the switch.
 * @param {boolean} Props.isDisabled - A flag indicating whether the switch is disabled.
 * @param {string} Props.className - Additional CSS classes for styling the switch.
 */

export const ReactSwitch = ({
	id,
	checked = false,
	handleChange,
	width,
	height,
	onColor,
	offColor,
	diameter,
	isDisabled = false,
	className = '',
	activeBoxShadow='',
}: Props) => {
	return (
		<Switch
			activeBoxShadow={activeBoxShadow}
			id={id}
			checked={checked}
			disabled={isDisabled}
			checkedIcon={false}
			onColor={onColor ?? '#4574f5'}
			offColor={offColor ?? '#C9CDD6'}
			uncheckedIcon={false}
			height={height ?? 24}
			width={width ?? 48}
			className={className}
			handleDiameter={diameter ?? 16}
			onChange={handleChange}
		/>
	);
};
