const num =
	'zero first second third fourth fifth sixth seventh eighth ninth tenth eleventh twelfth thirteenth fourteenth fifteenth sixteenth seventeenth eighteenth nineteent'.split(
		' '
	);
const tens = 'twenty thirty forty fifty sixty seventy eighty ninety'.split(' ');
export const number2words: any = (n: number) => {
	if (n < 20) return num[n];
	const digit = n % 10;
	if (n < 100) return tens[~~(n / 10) - 2] + (digit ? '-' + num[digit] : '');
	if (n < 1000)
		return (
			num[~~(n / 100)] +
			' hundred' +
			(n % 100 === 0 ? '' : ' ' + number2words(n % 100))
		);
	return (
		number2words(~~(n / 1000)) +
		' thousand' +
		(n % 1000 !== 0 ? ' ' + number2words(n % 1000) : '')
	);
};
