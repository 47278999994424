import { useMemo } from 'react';
import cn from 'classnames';

import './skeleton-loader.scss';

interface Props {
	listsToRender: number;
	numberColumn: number;
	column?: any;
}

/**
 * Interface for the props of the SkeletonTable component.
 * @param {number} Props.listsToRender - The number of skeleton rows to render in the table.
 * @param {number} Props.numberColumn - The number of columns in each skeleton row.
 * @param {any} Props.column - Custom column configuration for the skeleton table. (Optional)
 */

export const SkeletonTable = ({
	listsToRender,
	numberColumn,
	column,
}: Props) => {
	const columns = column ?? Array(numberColumn).fill(1);
	const skeletonLoader = useMemo(() => {
		return Array(listsToRender)
			.fill(1)
			.map((card: any, index: number) => (
				<div
					key={`table__${index.toString() + card}`}
					className={cn('skeleton_table')}
				>
					{columns.map((colCard: any, idx: number) => (
						<div
							className="sub_table_div"
							key={`sub-table__${idx.toString()}`}
							style={{ width: colCard.width }}
						>
							<div
								className={cn('skeleton_table_symbol skeleton-loader-light')}
							/>
						</div>
					))}
				</div>
			));
	}, [listsToRender, columns]);

	return <div className="skeleton_body">{skeletonLoader}</div>;
};
