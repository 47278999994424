export interface Options {
	storeName: string;
	dbMode: IDBTransactionMode;
	error: (e: Event) => any;
	complete: (e: Event) => any;
	abort?: any;
}

// validate store name
export const validateStoreName = (db: IDBDatabase, storeName: string) => {
	return db.objectStoreNames.contains(storeName);
};

// validate before transaction
export const validateBeforeTransaction = (
	db: IDBDatabase,
	storeName: string,
	reject: (e: any) => void
) => {
	if (!db) {
		reject(
			'You need to use the openDatabase function to create a database before you query it!'
		);
	}
	if (!validateStoreName(db, storeName)) {
		reject(`objectStore does not exists: ${storeName}`);
	}
};

// create transaction
export const createTransaction = (
	db: IDBDatabase,
	options: Options
): IDBTransaction => {
	const transaction = db.transaction(options.storeName, options.dbMode);
	transaction.onerror = options.error;
	transaction.oncomplete = options.complete;
	transaction.onabort = options.abort;
	return transaction;
};

export const optionsGenerator = (
	type: any,
	storeName: any,
	reject: (e: Event) => void,
	resolve: () => void
): Options => {
	return {
		storeName,
		dbMode: type,
		error: (e: Event) => {
			reject(e);
		},
		complete: () => {
			resolve();
		},
		abort: (e: Event) => {
			reject(e);
		},
	};
};
