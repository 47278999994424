import { atom } from 'recoil';
import { IAtoms } from 'types';
import { ISignedDocInbox, data } from 'views/signed-doc-inbox';

export interface IInboxFIlterringTags {
	[key : string] : string[]
}

export const SignedDocPacketsState = atom<IAtoms<ISignedDocInbox[]>>({
	key: 'signed-packet-doc-inbox-state-key',
	default: {
		isLoaded: false,
		error: false,
		data: data,
	},
});


export const IsPacketStatusCollapseState = atom<boolean>({
	key: 'is-packet-status-collapse-state-key',
	default: false,
});

export const IsPacketPurposeCollapseState = atom<boolean>({
	key: "is-packet-purpose-collapse-state-key",
	default: false,
})

export const IsPacketEnvelopeTypeCollapseState = atom<boolean>({
	key: 'is-packet-envelope-type-collapse-state-key',
	default: false,
});

export const PacketInboxfilteringTagsState = atom<IInboxFIlterringTags>({
	key: 'packet-inbox-fitlering-tags-state-key',
	default: {},
});

export const PacketInboxfiltersIndicatorState = atom<number>({
	key: 'packet-inbox-filter-indicators-state-key',
	default: 0,
});
