export const UPLOAD_HEADER = [
	{
		label: 'Document Name',
		key: 'name',
		format: 'jsx',
		width: '80%',
	},
	{
		label: 'File size',
		key: 'size',
		format: 'jsx',
		width: '10%',
	},
	{
		label: 'Actions',
		key: 'action',
		format: 'jsx',
		width: '10%',
	},
];
