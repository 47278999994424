import { FC } from "react"
import { IAuthType } from "views/signed-doc-inbox/store"

interface IInboxAuth {
    type: IAuthType
}

type IFaceAuthObjItem = {[key in IAuthType] : {
    icon: string,
    text: string,
    backgroundColor: string,
    iconColor: string,
}}

export const InboxAuth:FC<IInboxAuth> = ({type}) => {

    const faceAuthObj: IFaceAuthObjItem = {
        facial: {
            icon: "ri-checkbox-circle-line",
            text: "True",
            backgroundColor: "#389F751F",
            iconColor: "#33B87A"
        },
        webauth: {
            backgroundColor: "#F553531F",
            icon: "ri-close-circle-line",
            iconColor: "#F55353",
            text: "False"
        }
    }

    const {icon, backgroundColor, iconColor, text} = faceAuthObj[type]

    return (
        <div className="inbox-face-auth" style={{backgroundColor}}>
            <i className={`inbox-face-auth__icon ${icon}`} style={{color: iconColor}}></i>
            <span className="inbox-face-auth__text">{text}</span>
        </div>
    )
}