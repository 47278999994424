import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { API_URL } from 'constant';
import { loginState } from 'global-stores';
import { useNetwork } from 'hooks';
import {
	AnalyticsOverviewAtom,
	DashboardsAtom,
	OverviewFlowAnalyticsAtom,
} from './states';

export const useDashboards = () => {
	// state
	const userLogin = useRecoilValue(loginState);
	const setDashboards = useSetRecoilState(DashboardsAtom);
	const setAnalyticsOnboarding = useSetRecoilState(AnalyticsOverviewAtom);
	const setOverviewFlowAnalytics = useSetRecoilState(OverviewFlowAnalyticsAtom);

	//hook
	const { get } = useNetwork();

	const { business } = userLogin ?? {};
	const timeZoneOffset = new Date().getTimezoneOffset();

	const getDashboards = useCallback(() => {
		get(`${API_URL.ONBOARDED_SUMMARY}?timeZoneOffset=${timeZoneOffset}`)
			.then(resp => {
				if (resp && resp.data) {
					setDashboards(prev => ({ ...prev, data: resp.data }));
				}
			})
			.catch(() => {
				setDashboards(prev => ({ ...prev, error: true }));
			})
			.finally(() => {
				setDashboards(prev => ({ ...prev, loaded: false }));
			});
	}, [get, setDashboards, timeZoneOffset]);

	const getAnalyticsData = useCallback(async () => {
		if(business){
			setAnalyticsOnboarding(prev => ({ ...prev, data:[], loaded: false }));
			const resp = await get(`/analytics?business=${business}&graph=true`);
			const { data } = resp ?? {};
			if (data) setAnalyticsOnboarding(prev => ({ ...prev, data }));
			else {
				setAnalyticsOnboarding(prev => ({ ...prev, data: [], loaded: false }));
			}
			setAnalyticsOnboarding(prev => ({ ...prev, loaded: true }));
		}
	}, [business, get, setAnalyticsOnboarding]);

	const getOnboardingFlowAnalyticsData = useCallback(async () => {
		if(business){
			setOverviewFlowAnalytics(prev => ({ ...prev, data:[], loaded: false }));
			const resp = await get(`/analytics?business=${business}`);
			const { data } = resp ?? {};
			if (data) setOverviewFlowAnalytics(prev => ({ ...prev, data }));
			else {
				setOverviewFlowAnalytics(prev => ({ ...prev, data: [], error: true }));
			}
			setOverviewFlowAnalytics(prev => ({ ...prev, loaded: true }));
		}
	}, [business, get, setOverviewFlowAnalytics]);

	return { getDashboards, getAnalyticsData, getOnboardingFlowAnalyticsData };
};
