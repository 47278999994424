import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { capitalize } from 'utils';
import {
	NodesState,
	isComplexViewOnlyState,
} from 'views/complex-onboarding-flow/stores/states';
import { AddConditionButton } from '../add-condition-button';
import { ConnectorThread } from '../connector';
import { IOption } from '../dropdown';
import { QuestionLabel } from '../question-label';
import { KybQuestionWrapper } from './kyb-question-field';

export interface IRenderQuestion {
	stepId: string;
	nodeId: string;
	blockId: string;
}
export const RenderKybQuestion: FC<IRenderQuestion> = ({
	stepId,
	nodeId,
	blockId,
}) => {
	// Global variables
	const [connect, setConnect] = useState('and');

	const [nodes, setNodes] = useRecoilState(NodesState);
	const isComplexViewOnly = useRecoilValue(isComplexViewOnlyState);

	const { conditions: kyb } = useMemo(
		() => nodes.find((el: any) => el.id === nodeId) ?? [],
		[nodes, nodeId]
	);

	// Local variables
	const [, setCount] = useState(0);

	const handleChangeSelect = useCallback(
		(val: IOption) => {

				setNodes((prev: any) => {
					const prevObj = JSON.parse(JSON.stringify(prev));
					const findIndex = prevObj.findIndex((el: any) => el.id === nodeId);
					if (findIndex !== -1) {
						const index = prevObj[findIndex].conditions.findIndex(
							(el: any) => el.currentId === nodeId && el.blockId === blockId
						);

						if (index !== -1) {
							const prevValue = prevObj[findIndex].conditions[index][connect];
							const payload = {
								[val.value]: prevValue,
							};
							Object.assign(prevObj[findIndex].conditions[index], payload);
							if (val.value !== connect) {
								delete prevObj[findIndex].conditions[index][connect];
							}
						}			
					}
					return prevObj;
				});

				setConnect(val.value);
		},
		[setNodes, nodeId, blockId, connect]
	);

	const addNewQuestion = useCallback(() => {
		setNodes((prev: any) => {
			const prevObj = JSON.parse(JSON.stringify(prev));
			const findIndex = prevObj.findIndex((el: any) => el.id === nodeId);
			if (findIndex !== -1) {
				const index = prevObj[findIndex]?.conditions?.findIndex(
					(el: any) => el.currentId === nodeId && el.blockId === blockId
				);
				if (index !== -1) {
					prevObj[findIndex]?.conditions[index]?.[connect]?.push({});
					setCount(prev => prev + 1);
				}
			}

			return prevObj;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nodeId, blockId, connect]);

	const kycQuestionList = useMemo(
		() =>
			kyb?.find(
				(el: any) => el.currentId == nodeId && el.blockId === blockId
			)?.[connect] ?? [{}],
		[kyb, connect, nodeId, blockId]
	);

	const onRemoveBlock = useCallback(
		(blockId: string) => () => {
			setNodes((prev: any) => {
				const prevObj = JSON.parse(JSON.stringify(prev));
				const findIndex = prevObj.findIndex((el: any) => el.id === nodeId);
				if (findIndex !== -1) {
					prevObj[findIndex].conditions = prevObj[
						findIndex
					]?.conditions?.filter((el: any) => el.blockId !== blockId);
				}
				return prevObj;
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[nodeId]
	);

	useEffect(() => {
		if (!isComplexViewOnly && kycQuestionList?.length === 0) addNewQuestion();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isComplexViewOnly, kycQuestionList?.length]);

	const questionProps = {
		stepId,
		nodeId,
		blockId,
		questionLen: kycQuestionList?.length,
		connect,
	};

	useEffect(() => {
		const data = kyb?.find(
			(el: any) => el.currentId == nodeId && el.blockId === blockId
		);
		if (Object.keys(data ?? {}).includes('or')) {
			setConnect('or');
		}
	}, [blockId, kyb, nodeId]);

	return (
		<div className="question-list">
			<QuestionLabel blockId={blockId} onRemoveBlock={onRemoveBlock} />
			{kycQuestionList?.map((_: any, index: number) => {
				return (
					<Fragment key={`condition_${index.toString()}`}>
						<KybQuestionWrapper
							{...questionProps}
							index={index}
							key={`${_.param}_${_.operand}_${blockId}_${nodeId}`}
							conditionId={`condition_${index}`}
							questionData={_}
						/>
						{kycQuestionList?.length !== index + 1 && (
							<ConnectorThread
								handleChangeSelect={handleChangeSelect}
								value={{ label: capitalize(connect), value: connect }}
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								defaultValue={{ label: capitalize(connect), value: connect }}
							/>
						)}
					</Fragment>
				);
			})}
			<AddConditionButton onClick={addNewQuestion} />
		</div>
	);
};
