/* eslint-disable no-console */
import { FC, useCallback, useMemo } from 'react';

import './bread-crumb.scss';
import { Handle, Position, useReactFlow } from 'reactflow';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
	ComplexConfigStep,
	ComplexConfigurationInvite,
} from 'views/pipelines/components';
import { UploadedDocsState } from 'global-stores';
import classNames from 'classnames';

interface IBreadcrumb {
	ExtraSteps?: any;
	data?: any;
	currentIndex?: number;
	id?: any;
	key?: string;
}

const CustomNode: FC<IBreadcrumb> = ({ id, data }) => {
	const setComplexConfigStep = useSetRecoilState(ComplexConfigStep);
	const uploadedFiles = useRecoilValue(UploadedDocsState);
	const { fundInvestment } = useRecoilValue(ComplexConfigurationInvite);

	const {getNode} = useReactFlow()
	const node = getNode(id);

	console.log('node', node);

	const handleClick = useCallback(
		(id: string, key: string) => {
			setComplexConfigStep({
				id,
				key,
			});
		},
		[setComplexConfigStep]
	);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const getName: any = {
		fundInvestmentVerification: 'Select / Add Fund',
		signAgreementVerification: 'Upload Agreement Doc.',
	};

	const { label, actionLabel } = useMemo(() => {
		switch (data.key) {
			case 'fundInvestmentVerification':
				return {
					label: 'Bank Added',
					actionLabel: 'Change',
				};
			case 'signAgreementVerification':
				return {
					label: 'Document Uploaded',
					actionLabel: 'Re Configure',
				};
			default:
				return {
					label: 'Bank Added',
					actionLabel: 'Change',
				};
		}
	}, [data.key]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const ReConfigured = () => {
		return (
			<div
				className={`${
					label === 'Bank Added'
						? 'document-configured-bankAdded'
						: 'document-configured'
				}`}
			>
				<div>{label}</div>
				<div className="document-configured__or">|</div>
				<div className="document-configured__reconfig">{actionLabel}</div>
			</div>
		);
	};

	const renderUpload = useMemo(() => {
		if (/fundInvestmentVerification|signAgreementVerification/.test(data.key)) {
			const isFundConfigured = !!fundInvestment.find(
				(fund: any) => fund.node === id
			);
			const isDocConfigured = !!uploadedFiles.find(
				(doc: any) => doc.node === id
			);
			const isConfigured = isFundConfigured || isDocConfigured;

			const boxClassNames = classNames('document-configured__arrow-top', {
				'document-configured__uploaded': isConfigured,
				'document-configured__box': !isConfigured
			})
			
			return (
				<div
					className="document-configured__bread-crumb-complex"
					onClick={() => handleClick(id, data.key)}
				>
					<div className={boxClassNames}>
						{!isConfigured ? getName[data.key] : <ReConfigured />}
					</div>
				</div>
			);
		}
		return <></>;
	}, [
		ReConfigured,
		data.key,
		fundInvestment,
		getName,
		handleClick,
		id,
		uploadedFiles,
	]);

	const getComponent = useMemo(() => {
		return (
			<div className="session-status">
				<div
					className="status__clip statusbg__completed statustct__authentication"
					style={{ display: 'none' }}
				/>

				{renderUpload}

				<div className="status__clip statusbg__pending statustct__506c">
					<Handle type="target" position={Position.Left} id={id} />
					<svg
						className="leftImg"
						width="6"
						viewBox="0 0 6 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M0 0H6V9.16667V20H0L6 9.16667L0 0Z"></path>
					</svg>
					<div className="inner__status">{data.label}</div>
					<svg
						width="6"
						viewBox="0 0 6 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M0 0L6 9.16667L0 20V0Z"></path>
					</svg>
					<Handle type="source" position={Position.Right} id={id} />
				</div>
			</div>
		);
	}, [data.label, id, renderUpload]);

	return getComponent;
};

export default CustomNode;
