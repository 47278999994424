/* eslint-disable react/no-array-index-key */
import { FC } from 'react';
import { BasicSection } from 'views/sessions/components/kyb-wrapper/components/kyb-comprehensive/components/basic-section';
import { KYC_SECTION_ICON, KycKeys } from '../constants';

interface IProps {
	cardLabel?: string;
	cardData?: any;
	key: any;
	userInformationIcon?: string;
}

export const KycComperhensiveCard: FC<IProps> = ({
	cardLabel,
	cardData,
	key,
	userInformationIcon = '',
}) => {
	return (
		<div key={key} className="kyc-comp__card">
			<div className="kyc-comp__card--header">
				<div className="kyc-comp__card--header__icon">
					<i
						className={
							userInformationIcon
								? userInformationIcon
								: KYC_SECTION_ICON[cardLabel ?? userInformationIcon]
						}
					></i>
				</div>
				<div className="kyc-comp__card--header__label">
					{KycKeys[cardLabel as string] ?? cardLabel ?? '--'}
				</div>
			</div>
			<div>
				{(cardData ?? []).map((item: any, index: number) => (
					<div className="kyc-comp__card--section" key={index}>
						{Object.entries(item ?? {}).map(([key, value]) => (
							<BasicSection
								key={key}
								label={KycKeys[key] ?? key}
								value={(value as string) ?? '--'}
							/>
						))}
					</div>
				))}
			</div>
		</div>
	);
};
