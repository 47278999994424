import { ChangeEvent, FC, useCallback, useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import classNames from 'classnames';
import { Input } from '@storybook';

import { ToggleComponent } from '../toggle-component';
import {
	ComplexSettingFormState,
	UnitPriceInputErrorState,
	UnitPriceInputValueState,
	UnitPriceToggleState,
} from '../../store';

import './unit-price.scss';

interface IUnitPrice {
	description: string;
	label: string;
	name: string;
	id: string;
}

export const UnitPrice: FC<IUnitPrice> = ({ description, label, name, id }) => {
	// global state
	const [showInput, setShowInput] = useRecoilState(UnitPriceToggleState);
	const [unitPriceInputValue, setUnitPriceInputValue] = useRecoilState(
		UnitPriceInputValueState
	);
	const [isInputError, setInputError] = useRecoilState(
		UnitPriceInputErrorState
	);
	const [complexSettingForm, setComplexSettingForm] = useRecoilState(
		ComplexSettingFormState
	);

	const isPayInSelected = useMemo(
		() => complexSettingForm?.[id ?? '']?.payInPayOut === 'payIn',
		[complexSettingForm, id]
	);

	useEffect(() => {
		// If PayIn is not selected, reset input error, hide the input, and clear the unit price input value for the current id
		if (!isPayInSelected) {
			setInputError(prev => ({ ...prev, [id]: false })); // Reset input error for the current id
			setShowInput(prev => ({ ...prev, [id]: false })); // Hide the input for the current id
			setUnitPriceInputValue(prev => ({ ...prev, [id]: '' })); // Clear the unit price input value for the current id
		}

		// If the input is not shown, ensure the error is reset and the unit price input is cleared for the current id
		if (!showInput?.[id]) {
			setInputError(prev => ({ ...prev, [id]: false })); // Reset input error for the current id
			setUnitPriceInputValue(prev => ({ ...prev, [id]: '' })); // Clear the unit price input value for the current id
		}

		// Disable exhaustive deps warning for `showInput?.[id]` and `isPayInSelected` as they are relevant dependencies
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showInput?.[id], isPayInSelected]);

	useEffect(() => {
		// Create a payload where the value is set only if PayIn is selected and the unit price input value exists
		const payload = {
			[name]:
				isPayInSelected && unitPriceInputValue?.[id]
					? Number(unitPriceInputValue?.[id]) // Convert the unit price input to a number if valid
					: undefined, // Otherwise, set it as undefined
		};

		// Update the complex settings form state
		setComplexSettingForm((prev: any) => {
			const newObj = structuredClone(prev); // Create a deep clone of the previous state

			// Initialize the object for the current id if it doesn't exist
			if (!newObj[id]) {
				newObj[id] = {};
			}

			// Merge the payload into the object for the current id
			Object.assign(newObj[id], payload);

			// Return the updated state object
			return newObj;
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unitPriceInputValue?.[id]]);

	const handleChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			// Reset input error for the current id
			setInputError(prev => ({ ...prev, [id]: false }));

			// Extract the value from the event target
			const { value } = e.target;

			// Validate the amount format: allows digits with optional decimal
			const isAmountValid = /^\d+(\.\d{0,4})?$/.test(value);

			// If value is present and is not valid, exit the function
			if (value && !isAmountValid) return;

			// Update the unit price input value for the current id
			setUnitPriceInputValue(prev => ({ ...prev, [id]: value }));
		},
		[id, setInputError, setUnitPriceInputValue]
	);

	const classes = useMemo(
		() =>
			classNames('UnitPrice__wrapper', {
				'UnitPrice__wrapper--disabled': !isPayInSelected,
			}),
		[isPayInSelected]
	);

	return (
		<div className={classes}>
			<>
				<ToggleComponent
					description={description}
					label={label}
					name={name}
					id={id}
				/>
			</>
			{showInput?.[id] && (
				<Input
					label="Price per unit (USD)"
					handleChange={handleChange}
					placeholder="0.0000"
					inputType="text"
					value={unitPriceInputValue?.[id]}
					isError={isInputError?.[id]}
					isRequired
				/>
			)}
		</div>
	);
};
