import {
	IDBConfiguration,
	ObjectStoreMeta,
	IndexedDBProps,
	IIndexedDB,
} from './types';

export const INDEXED_DB_CONFIGURATION: IDBConfiguration = {
	dbName: 'store',
	version: 1,
};

export const objectStores: ObjectStoreMeta[] = [{ store: 'defaultChannel' }];

export const INIT_CONFIGURATION: IndexedDBProps = {
	name: 'store',
	dbVersion: 1,
};

export const INIT_CONFIGURATION_SAVE: IIndexedDB = {
	currentStore: 'defaultChannel',
};
