import Tippy from '@tippyjs/react';
import classNames from 'classnames';
import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
	DisabledEsignPermission,
	PermissionTableData,
	PermissionTableHeader,
	TooltipMessage,
	UserRoleIds,
} from 'views/user-roles/constant';
import {
	AddNewRoleFormState,
	IPermissionType,
	SelectedUserRoleState,
} from 'views/user-roles/store';

interface IPermissionTable {
	isAllowedAccess?: boolean;
	isEditable?: boolean;
}

export const PermissionTable: FC<IPermissionTable> = ({
	isAllowedAccess,
	isEditable,
}) => {
	const [headerDropdown, setHeaderDropdown] = useState<string[]>([]);
	const [permissionHeaderCheckbox, setPermissionHeaderCheckbox] = useState<
		Record<string, Record<string, boolean>>
	>({});

	const selectedUserRole = useRecoilValue(SelectedUserRoleState);
	const [addRoleForm, setAddRoleForm] = useRecoilState(AddNewRoleFormState);
	const { permissions } = useMemo(() => addRoleForm, [addRoleForm]);

	useEffect(() => {
		if (selectedUserRole) {
			const { permissions } = selectedUserRole;
			const filteredPermissions = Object.keys(permissions ?? {}).reduce(
				(acc: Record<string, any>, key) => {
					if (
						Object.keys(addRoleForm?.permissions ?? {}).includes(key) &&
						key !== 'userProfile' &&
						key !== 'userRoles' &&
						key !== 'demo' &&
						key !== 'liveMode'
					) {
						acc[key] = permissions[key];
					}
					return acc;
				},
				{}
			);

			if (Object.keys(permissions ?? {}).length) {
				setAddRoleForm(prev => ({
					...prev,
					permissions: { ...prev['permissions'], ...filteredPermissions },
				}));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedUserRole]);

	useEffect(() => {
		const {
			onboarding,
			session,
			funds,
			signDashboard,
			template,
			inbox,
			sent,
			generalSettings,
			developersKeys,
			embedWeb,
			apiDocs,
			userAnalytics,
			supportAgent,
			transaction,
			checkDefense,
		} = permissions ?? {};

		setPermissionHeaderCheckbox(prev => ({
			...prev,
			onboardingFlow: {
				read: onboarding.read && session.read && funds.read,
				write: onboarding.write && session.write && funds.write,
			},
			esign: {
				read: signDashboard.read && template.read && inbox.read && sent.read,
				write:
					signDashboard.write && template.write && inbox.write && sent.write,
			},
			settings: {
				read: generalSettings.read && developersKeys.read,
				write: generalSettings.write && developersKeys.write,
			},
			integrate: {
				read: embedWeb.read && apiDocs.read,
				write: embedWeb.write && apiDocs.write,
			},
			insights: {
				read: userAnalytics.read && supportAgent.read,
				write: userAnalytics.write && supportAgent.write,
			},
			finance: {
				read: transaction.read && checkDefense.read,
				write: transaction.write && checkDefense.write,
			},
		}));
	}, [permissions]);

	const disabledRow = useCallback(
		(id: string) => {
			const isOnboardingSelected =
				addRoleForm?.services?.includes('onboarding');
			if (!isOnboardingSelected) {
				return DisabledEsignPermission.includes(id);
			}
			return false;
		},
		[addRoleForm?.services]
	);

	const disablePermissionRow = useCallback(
		(key: string) => {
			return classNames('permission-table-row', {
				'disabled-row': disabledRow(key),
			});
		},
		[disabledRow]
	);

	const disablePermissionSubRow = useCallback(
		(key: string) => {
			return classNames('permission-table-row__dropdownBody', {
				'disabled-row': disabledRow(key),
			});
		},
		[disabledRow]
	);

	// to open and close dropdown
	const handleHeaderDropdown = useCallback(
		(id: string) => () => {
			const isOnboardingSelected =
				addRoleForm?.services?.includes('onboarding');

			if (!isOnboardingSelected && id === 'onboardingFlow') {
				return;
			}
			if (headerDropdown.includes(id)) {
				const newIds = headerDropdown.filter(item => item !== id);
				setHeaderDropdown(newIds);
			} else {
				setHeaderDropdown((prev: string[]) => [...prev, id]);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[disabledRow, headerDropdown]
	);

	// to check or unchecked checkbox
	const handleClickCheckbox = useCallback(
		(type: string, id: string) => (e: any) => {
			e.stopPropagation();
			const { checked } = e.target;
			const opposite = type === 'read' ? 'write' : 'read';
			const isOnboardingSelected =
				addRoleForm?.services?.includes('onboarding');
			if (!isOnboardingSelected && DisabledEsignPermission.includes(id)) {
				return;
			} else {
				if (PermissionTableHeader.includes(id)) {
					const getMainHeader = PermissionTableData.find(
						item => item.key === id
					);
					if (getMainHeader) {
						const newPermissions = getMainHeader.services.reduce<
							Record<string, Record<string, boolean>>
						>(
							(
								acc: Record<string, Record<string, boolean>>,
								service: { key: string; label: string }
							) => {
								acc[service.key] = {
									[type]: checked,
									[opposite]:
										type === 'write' ? checked : checked ? !checked : checked,
								};
								return acc;
							},
							{}
						);

						setAddRoleForm(prev => ({
							...prev,
							permissions: {
								...prev.permissions,
								...newPermissions,
							},
						}));
					}
				} else {
					setAddRoleForm(prev => ({
						...prev,
						permissions: {
							...prev.permissions,
							[id]: {
								[type]: checked,
								[opposite]:
									type === 'write' ? checked : checked ? !checked : checked,
							},
						},
					}));
				}
			}
		},
		[addRoleForm, setAddRoleForm]
	);

	const renderTableHeader = useMemo(() => {
		return (
			<div
				className="AddNewRoleModal--container__permission--table__header"
				id={UserRoleIds.permission}
			>
				<div className="AddNewRoleModal--container__permission--table__header--action">
					<div className="AddNewRoleModal--container__permission--table__header--action__title">
						Action
					</div>
				</div>
				<div className="AddNewRoleModal--container__permission--table__header--allowaccess">
					<div className="AddNewRoleModal--container__permission--table__header--allowaccess__viewOnly">
						View Only
					</div>
					<div className="AddNewRoleModal--container__permission--table__header--allowaccess__CreateandView">
						Create, Edit and View
					</div>
				</div>
			</div>
		);
	}, []);

	const renderTableBody = useMemo(() => {
		return (
			<div className="AddNewRoleModal--container__permission--table__body">
				{PermissionTableData.map(list => {
					return (
						<Fragment key={`${list.key.toString()}`}>
							<div
								className={disablePermissionRow(list.key)}
								onClick={handleHeaderDropdown(list.key)}
							>
								<div className="permission-table-row__left">
									<div className="permission-table-row__left--dropdown">
										{list.services.length !== 0 && (
											<i
												className={
													headerDropdown.includes(list.key)
														? 'ri-arrow-down-s-fill'
														: 'ri-arrow-up-s-fill'
												}
											/>
										)}
									</div>
									<div className="permission-table-row__left--data">
										<div className="permission-table-row__left--data__icon">
											<i className={`ri-${list.icon}`} />
										</div>
										<div
											className={
												headerDropdown.includes(list.key)
													? 'permission-table-row__left--data__label'
													: 'isopen--label'
											}
										>
											{list.label}
										</div>
									</div>
								</div>
								<div className="permission-table-row__right">
									{isEditable ? (
										<div className="permission-table-row__right--checkboxView">
											{selectedUserRole?.['permissions']?.[
												list.key as IPermissionType
											]?.['read'] ??
											permissionHeaderCheckbox?.[list.key]?.['read'] ? (
												<i className="ri-check-line permission-checked" />
											) : (
												<></>
											)}
										</div>
									) : (
										<div className="permission-table-row__right--checkboxView">
											<input
												type="checkbox"
												onClick={handleClickCheckbox('read', list.key)}
												checked={
													addRoleForm['permissions']?.[
														list.key as IPermissionType
													]?.['read'] ??
													permissionHeaderCheckbox?.[list.key]?.['read']
												}
												className={`permission-table-checkbox ${
													list.isRead
														? 'permission-table-checkbox__disable'
														: ''
												}`}
												disabled={isAllowedAccess || list.isRead}
											/>
										</div>
									)}
									{isEditable ? (
										<div className="permission-table-row__right--createView">
											{selectedUserRole?.['permissions']?.[
												list.key as IPermissionType
											]?.['write'] ??
											permissionHeaderCheckbox?.[list.key]?.['write'] ? (
												<i className="ri-check-line permission-checked" />
											) : (
												<></>
											)}
										</div>
									) : (
										<Tippy
											disabled={list.key !== 'dashboard'}
											content={
												<div className="text-align-center">
													{TooltipMessage.dashboard}
												</div>
											}
										>
											<div className="permission-table-row__right--createView">
												<input
													type="checkbox"
													onClick={handleClickCheckbox('write', list.key)}
													checked={
														addRoleForm['permissions']?.[
															list.key as IPermissionType
														]?.['write'] ??
														permissionHeaderCheckbox?.[list.key]?.['write']
													}
													className={`permission-table-checkbox ${
														list.isWrite
															? 'permission-table-checkbox__disable'
															: ''
													}`}
													disabled={isAllowedAccess || list.isWrite}
												/>
											</div>
										</Tippy>
									)}
								</div>
							</div>
							{!headerDropdown.includes(list.key) &&
								list.services.map((subList: any) => {
									return (
										<div
											className={disablePermissionSubRow(subList.key)}
											key={subList.key}
										>
											<div className="permission-table-row__dropdownBody--row">
												<div className="permission-table-row__dropdownBody--row__left">
													<div className="permission-table-row__dropdownBody--row__left--dropdown">
														<i className="ri-arrow-down-s-fill" />
													</div>
													<div className="permission-table-row__dropdownBody--row__left--data">
														<div className='lassName="permission-table-row__dropdownBody--row__left--data__label'>
															{subList.label}
														</div>
													</div>
												</div>
												<div className="permission-table-row__dropdownBody--row__right">
													{isEditable ? (
														<div className="permission-table-row__dropdownBody--row__right--checkboxView">
															{selectedUserRole?.['permissions']?.[
																subList.key as IPermissionType
															]?.['read'] ? (
																<i className="ri-check-line permission-checked" />
															) : (
																<></>
															)}
														</div>
													) : (
														<Tippy
															disabled={!['embedWeb'].includes(subList.key)}
															content={
																<div className="text-align-center">
																	{TooltipMessage.embedWeb}
																</div>
															}
														>
															<div className="permission-table-row__dropdownBody--row__right--checkboxView">
																<input
																	type="checkbox"
																	onClick={handleClickCheckbox(
																		'read',
																		subList.key
																	)}
																	checked={
																		addRoleForm['permissions']?.[
																			subList.key as IPermissionType
																		]?.['read']
																	}
																	className={`permission-table-checkbox ${
																		list.isRead
																			? 'permission-table-checkbox__disable'
																			: ''
																	}`}
																	disabled={isAllowedAccess || subList?.isRead}
																/>
															</div>
														</Tippy>
													)}

													{isEditable ? (
														<div className="permission-table-row__dropdownBody--row__right--createView">
															{selectedUserRole?.['permissions']?.[
																subList.key as IPermissionType
															]?.['write'] ? (
																<i className="ri-check-line permission-checked" />
															) : (
																<></>
															)}
														</div>
													) : (
														<Tippy
															disabled={
																!['embedWeb', 'apiDocs'].includes(subList.key)
															}
															content={
																<div className="text-align-center">
																	{subList.key === 'embedWeb'
																		? TooltipMessage.embedWeb
																		: TooltipMessage.apiDocs}
																</div>
															}
														>
															<div className="permission-table-row__dropdownBody--row__right--createView">
																<input
																	type="checkbox"
																	onClick={handleClickCheckbox(
																		'write',
																		subList.key
																	)}
																	checked={
																		addRoleForm['permissions']?.[
																			subList.key as IPermissionType
																		]?.['write']
																	}
																	className={`permission-table-checkbox ${
																		list.isWrite
																			? 'permission-table-checkbox__disable'
																			: ''
																	}`}
																	disabled={isAllowedAccess || subList?.isWrite}
																/>
															</div>
														</Tippy>
													)}
												</div>
											</div>
										</div>
									);
								})}
						</Fragment>
					);
				})}
			</div>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		headerDropdown,
		addRoleForm,
		permissionHeaderCheckbox,
		isAllowedAccess,
		disabledRow,
	]);

	return (
		<div className="AddNewRoleModal--container__permission--table">
			{renderTableHeader}
			{renderTableBody}
		</div>
	);
};
