import { useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { CodeSpace } from '@storybook';
import { KycMobileHost, KycMobileHostSandbox } from 'helpers';
import { loginState } from 'global-stores';
import { SelectedQrInstanceState } from '../../stores';

import './embed-code.scss';

export const EmbedCode = () => {
	// global states
	const qrInstance = useRecoilValue(SelectedQrInstanceState);
	const { sandboxStatus } = useRecoilValue(loginState);

	const { _id, name } = qrInstance ?? {};

	useEffect(() => {
		const copyBtn = document.querySelector('.embed-code-wrapper .sc-aXZVg');
		if (copyBtn) {
			copyBtn.innerHTML = '<i class="ri-file-copy-line"></i>';
		}
	}, []);

	const sourceUrl = useMemo(
		() =>
			sandboxStatus
				? `${KycMobileHostSandbox}/${_id}?type=qr&allow=true`
				: `${KycMobileHost}/${_id}?type=qr`,
		[_id, sandboxStatus]
	);

	const embedCode = useMemo(
		() =>
			_id
				? `<iframe 
	src='${sourceUrl}' 
	title="${name}"
	style="width:100%; height:98vh; border:none"
	allow="camera; microphone">
</iframe>`
				: `<>Not available</>`,
		[_id, name, sourceUrl]
	);

	return (
		<div className="embed-code-wrapper">
			<CodeSpace code={embedCode} />
		</div>
	);
};
