import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { CreateSignModal, CreateSignState, useGenerateSignImage } from 'views/create-sign';
import { Header } from 'views/header';
import { SignedDocInboxState, useSignedDocsInbox } from 'views/signed-doc-inbox';
import { DashboardUserDetails } from './components';
import { SignDashboardSignatureInitialedState, SignDashboardState, useSignDashboard } from './store';

export const ESignDashboard = () => {
	const { data } = useRecoilValue(SignedDocInboxState);
	const { data: dashboardData } = useRecoilValue(SignDashboardState);
	const { getSignDashboard } = useSignDashboard();
	const { getSignedDocsInbox } = useSignedDocsInbox();
	const { handleCreateSignImages } = useGenerateSignImage();
	const userBioMetrics = useRecoilValue(CreateSignState);
	const  setInitialCreated = useSetRecoilState(
		SignDashboardSignatureInitialedState
	);

	// here we are fetching all the list of sign and initial images
	// that are being generated from the fullName
	useEffect(() => {
		const { fullName, initials } = userBioMetrics ?? {};
		if (fullName && initials) {
			handleCreateSignImages().then(() => setInitialCreated(true));
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleCreateSignImages, userBioMetrics]);

	useEffect(() => {
		if (dashboardData.name) {
			getSignDashboard();
		} else {
			getSignDashboard(true);
		}
		if (data.length < 1) {
			getSignedDocsInbox();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dashboardData.name, data.length]);

	return (
		<div className="SignedDoc-Inbox__wrapper">
			<Header title="esign Dashboard" />
			<div className="SignedDoc-Inbox__body">
				<DashboardUserDetails />
			</div>
			<CreateSignModal />
		</div>
	);
};

export default ESignDashboard;
