/* eslint-disable no-prototype-builtins */
export const calculateTotalSum = (obj: any, key: string) => {
	let totalSum = 0;
	if (obj.hasOwnProperty(key)) {
		const subObj = obj[key];
		for (const subKey in subObj) {
			if (
				subObj.hasOwnProperty(subKey) &&
				subObj[subKey].hasOwnProperty('total')
			) {
				totalSum += subObj[subKey].total;
			}
		}
	}
	return totalSum;
};
