export const NO_ACCESS = {
	dashboard: {
		read: false,
		write: false,
	},
	onboarding: {
		read: false,
		write: false,
	},
	session: {
		read: false,
		write: false,
	},
	funds: {
		read: false,
		write: false,
	},
	signDashboard: {
		read: false,
		write: false,
	},
	template: {
		read: false,
		write: false,
	},
	inbox: {
		read: false,
		write: false,
	},
	sent: {
		read: false,
		write: false,
	},
	transaction: {
		read: false,
		write: false,
	},
	checkDefense: {
		read: false,
		write: false,
	},
	generalSettings: {
		read: false,
		write: false,
	},
	developersKeys: {
		read: false,
		write: false,
	},
	userRoles: {
		read: false,
		write: false,
	},
	embedWeb: {
		read: false,
		write: false,
	},
	apiDocs: {
		read: false,
		write: false,
	},
	userAnalytics: {
		read: false,
		write: false,
	},
	supportAgent: {
		read: false,
		write: false,
	},
	billing: {
		read: false,
		write: false,
	},
	liveMode: {
		read: false,
		write: false,
	},
	demo: {
		read: false,
		write: false,
	},
	userProfile: {
		read: false,
		write: false,
	},
};

export const ALL_ACCESS = {
	dashboard: {
		read: true,
		write: true,
	},
	onboarding: {
		read: true,
		write: true,
	},
	session: {
		read: true,
		write: true,
	},
	funds: {
		read: true,
		write: true,
	},
	signDashboard: {
		read: true,
		write: true,
	},
	template: {
		read: true,
		write: true,
	},
	inbox: {
		read: true,
		write: true,
	},
	sent: {
		read: true,
		write: true,
	},
	transaction: {
		read: true,
		write: true,
	},
	checkDefense: {
		read: true,
		write: true,
	},
	generalSettings: {
		read: true,
		write: true,
	},
	developersKeys: {
		read: true,
		write: true,
	},
	userRoles: {
		read: true,
		write: true,
	},
	embedWeb: {
		read: true,
		write: true,
	},
	apiDocs: {
		read: true,
		write: true,
	},
	userAnalytics: {
		read: true,
		write: true,
	},
	supportAgent: {
		read: true,
		write: true,
	},
	billing: {
		read: true,
		write: true,
	},
	liveMode: {
		read: true,
		write: true,
	},
	demo: {
		read: true,
		write: true,
	},
	userProfile: {
		read: true,
		write: true,
	},
};
