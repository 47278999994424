import { ITransactionRow } from './types';

import { atom } from 'recoil';

export const TransactionsState = atom<ITransactionRow[]>({
	key: 'transactions-state',
	default: [],
});

export const TransactionRowsState = atom<ITransactionRow[]>({
	key: 'transaction-rows-state',
	default: [],
});

export const SelectedTransactionIdsState = atom<string[]>({
	key: 'selected-transaction-ids-state',
	default: [],
});

export const SelectedTransactionState = atom<any>({
	key: 'selected-transaction-state',
	default: {},
});
