import { FC, useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { NodesState } from 'views/complex-onboarding-flow/stores/states';
import { CheckBox } from '../check-box';

interface IFormQuestion {
	label: string;
	questionId: string;
	nodeId: string;
}
export const FormQuestion: FC<IFormQuestion> = ({
	label,
	questionId,
	nodeId,
}) => {
	const [nodes] = useRecoilState(NodesState);

	const onChangeCheckbox = useCallback(() => {
		//TODO avinash commented By Avinash
		// setNodes((prev: any) => {
		// 	const prevObj = JSON.parse(JSON.stringify(prev));
		// 	const findIndex = prevObj.findIndex((el: any) => el.id === nodeId);
		// 	if (findIndex !== -1) {
		// 		const blockIndex = prevObj[findIndex].conditions.findIndex(
		// 			(el: any) => el.key === data.key && el.currentId === nodeId
		// 		);
		// 		if (blockIndex === -1) {
		// 			prevObj[findIndex].conditions.push({
		// 				...data,
		// 				labelId: 'multipleChoice',
		// 			});
		// 		} else {
		// 			prevObj[findIndex].conditions.splice(blockIndex, 1);
		// 		}
		// 	}
		// 	return prevObj;
		// });
	}, []);

	const data = nodes.find((el: any) => el.id === nodeId);

	const finalData = data?.conditions
		?.filter(
			(el: any) => el.labelId === 'multipleChoice' && el.currentId === nodeId
		)
		?.map((elm: any) => elm.key);

	const isChecked = new Set(finalData ?? []);

	const props = {
		checked: isChecked.has(questionId),
		value: {
			label,
			key: questionId,
		},
		label: label,
		nodeId,
		name: 'form-node', // user can select only single item
		onChangeCheckbox: onChangeCheckbox,
	};

	return <CheckBox {...props} />;
};
