import { PopupModal } from 'react-calendly';
import { useRecoilState, useRecoilValue } from 'recoil';

import { CALENDLY_URL } from './components';
import { RequestDemoOpenState } from './stores';

import './book-appointment.scss';
import { loginState } from 'global-stores';

export const BookAppointment = () => {
	const [isOpenPopup, setOpenPopup] = useRecoilState(RequestDemoOpenState);

	const userDetails = useRecoilValue(loginState);

	const { email, name, firstName, lastName } = userDetails ?? {};

	return (
		<PopupModal
			url={CALENDLY_URL}
			onModalClose={() => setOpenPopup(false)}
			open={isOpenPopup}
			rootElement={document.getElementById('popupModal') as any}
			prefill={{
				email,
				firstName,
				lastName,
				name,
			}}
		/>
	);
};
