import { atom } from 'recoil';

interface PersonalFormDataType {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	dob: string;
	ssn: string;
	zip: string;
	city: string;
	country: string;
	state: string;
	address: string;
}

export interface BusinessFormDataType {
	website: string;
	ein: string;
	companyName: string;
	email: string;
	establishedDate: string;
	phone: string;
	naics: string;
	naicsType: string;
	region: string;
	zip: string;
	city: string;
	country: string;
	state: string;
	address: string;
	legalStructure: string;
	description: string;
	mailingAddress?: string;
	mailingZip?: string;
	mailingCity?: string;
	mailingState?: string;
	mailingCountry?: string;
	address2?: string;
	mailingAddress2?: string;
}

export const PersonalInformationState = atom<PersonalFormDataType>({
	key: 'personal-information-state',
	default: {
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		dob: '',
		ssn: '',
		zip: '',
		city: '',
		country: 'United States',
		state: '',
		address: '',
	},
});

export const PersonalInformationId = atom<null | string>({
	key: 'personal-information-id',
	default: null,
});

export const PersonalInformationErrorState = atom<PersonalFormDataType>({
	key: 'personal-information-error-state',
	default: {
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		dob: '',
		ssn: '',
		zip: '',
		city: '',
		country: '',
		state: '',
		address: '',
	},
});

export const BusinessInformationState = atom<BusinessFormDataType>({
	key: 'businesss-information-state',
	default: {
		website: '',
		ein: '',
		companyName: '',
		email: '',
		establishedDate: '',
		phone: '',
		naics: '',
		naicsType: '',
		region: '',
		address: '',
		address2: '',
		zip: '',
		city: '',
		description: '',
		legalStructure: '',
		country: 'United States',
		state: '',
		mailingAddress: '',
		mailingAddress2: '',
		mailingZip: '',
		mailingState: '',
		mailingCity: '',
		mailingCountry: 'United States',
	},
});

export const BusinessInformationErrorState = atom<BusinessFormDataType>({
	key: 'business-information-error-state',
	default: {
		website: '',
		ein: '',
		companyName: '',
		email: '',
		establishedDate: '',
		phone: '',
		naics: '',
		naicsType: '',
		region: '',
		address: '',
		address2: '',
		zip: '',
		city: '',
		country: '',
		state: '',
		description: '',
		legalStructure: '',
		mailingAddress: '',
		mailingAddress2: '',
		mailingZip: '',
		mailingState: '',
		mailingCity: '',
		mailingCountry: '',
	},
});
