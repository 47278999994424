import { FC, useMemo } from 'react';
import Tippy from '@tippyjs/react';
import { UserRoles } from 'global-stores';

import './permission-tag.scss';

interface PermissionTagProps {
	role: UserRoles;
}

export const PermissionTag: FC<PermissionTagProps> = ({
	role,
}) => {
	const getAllPermissionTag = useMemo(() => {
		const allValues = role?.permissions ?? {};
		const allActionValue = [];
		for (const key in allValues) {
			const readPermission = allValues[key]?.read;
			const writePermission = allValues[key]?.write;

			if (readPermission) {
				allActionValue.push(`${key} read`);
			}
			if (writePermission) {
				allActionValue.push(`${key} write`);
			}
		}
		// Remove globle access permissions from tooltip
		const itemsToRemove = [
			'liveMode read',
			'liveMode write',
			'userProfile read',
			'userProfile write',
			'demo read',
			'demo write',
		];

		const updatePermissions = allActionValue.filter(
			item => !itemsToRemove.includes(item)
		);

		return updatePermissions;
	}, [role?.permissions]);

	const getAllPermissionsList = useMemo(() => {
		const havePermission = getAllPermissionTag?.length;

		if (havePermission === 0) return <></>;
		if (havePermission < 3)
			return (
				<>
					{getAllPermissionTag?.map((action, index) => (
						<div
							className="PermisssionTag--container__colorPurple"
							key={index?.toString()}
						>
							{action}
						</div>
					))}
				</>
			);
		if (havePermission >= 3) {
			return (
				<>
					{getAllPermissionTag
						?.filter((_, index) => index < 2)
						?.map((action, index) => (
							<div
								className="PermisssionTag--container__color"
								key={index?.toString()}
							>
								{action}
							</div>
						))}
					<Tippy
						interactive={true}
						content={
							<div className="tooltip-userRole-list">
								{getAllPermissionTag.map((item, index) => {
									return (
										index > 1 && (
											<div key={item} className="tooltip-userRole">
												• {item}
											</div>
										)
									);
								})}
							</div>
						}
					>
						<span className="PermisssionTag--container__number">
							+{havePermission - 2}
						</span>
					</Tippy>
				</>
			);
		}
		return <></>;
	}, [getAllPermissionTag]);

	return (
		<div className="PermisssionTag--container">
			{role?.fullAccess ? (
				<div className="PermisssionTag--container__label">All access</div>
			) : (
				getAllPermissionsList
			)}
		</div>
	);
};
