import { FC } from 'react';
import { Label } from '../condition-label';

export interface IRenderBlock {
	label?: string | undefined;
	price: number;
}

export const RenderFundBlock: FC<IRenderBlock> = ({ label, price }) => {
	// Global variables

	// Local variables

	return (
		<>
			<Label label={label || ''} price={price} priceLabel="Verification" />
			<div className="questions-wrapper"></div>
		</>
	);
};
