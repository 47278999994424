import { FC, useCallback, useMemo } from 'react';
import { LOGO, NewAppLink } from 'constant';
import { useNavigate } from 'react-router-dom';
import './components.scss';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { CountryCode, LoginCredsState, LoginStep } from 'components';
import { IframePropsState } from 'global-stores';

type HeaderProps = {
	btnActive: 'login' | 'signup';
};

export const AuthHeader: FC<HeaderProps> = ({ btnActive }) => {
	const navigate = useNavigate();
	const resetStep = useResetRecoilState(LoginStep);
	const resetCountry = useResetRecoilState(CountryCode);
	const resetphone = useResetRecoilState(LoginCredsState);
	const iframeProps = useRecoilValue(IframePropsState);

	const handleClick = useCallback(
		(path: string) => {
			navigate(path);
			resetStep();
			resetCountry();
			resetphone();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[navigate]
	);

	const clientLogo = useMemo(
		() => iframeProps?.clientlogo ?? '',
		[iframeProps?.clientlogo]
	);

	return (
		<div className="LoginHeader">
			<div className="LoginHeader__logo">
				{clientLogo?.length > 0 ? (
					<div className="LoginHeader__logo__link">
						<img
							loading="lazy"
							src={clientLogo || LOGO.LIGHT}
							alt="login"
							className="LoginHeader__logo__img"
						/>
					</div>
				) : (
					<a
						target="_blank"
						href={NewAppLink}
						rel="noreferrer"
						className="LoginHeader__logo__link"
					>
						<img
							loading="lazy"
							src={clientLogo || LOGO.LIGHT}
							alt="login"
							className="LoginHeader__logo__img"
						/>
					</a>
				)}
			</div>
			<div className="LoginHeader__btnContainer">
				<div className="LoginHeader__btnContainer__btn">
					<div
						className={`btn ${
							btnActive === 'login' ? 'LoginHeader__btn-active  ' : ''
						}`}
						onClick={() => handleClick('/login')}
					>
						Login
					</div>
					<div
						className={`btn ${
							btnActive === 'signup' ? 'LoginHeader__btn-active' : ''
						}`}
						onClick={() => handleClick('/signup')}
					>
						Sign Up
					</div>
				</div>
			</div>
		</div>
	);
};
