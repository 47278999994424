import { ITransactionDeal } from '../type';

import { MouseEvent, useCallback, useMemo, useState } from 'react';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';
import { Loader } from '@storybook';

import { useNetwork, useNotification } from 'hooks';
import {
	ComplexSessionsNodesLoadingState,
	ConditionalSessionFlowState,
	FundPaginationState,
	SelectedDateRangeState,
} from 'global-stores';
import { API_URL, message } from 'constant';
import { getDate } from 'utils';
import {
	TransactionDealDetailsLoadingState,
	TransactionDealDetailsModalState,
	TransactionDealsState,
	SelectedTransactionDealState,
	DealSessionNodesState,
	DealUserSessionDetailsState,
	DealFilterTagsState,
	DealSelectedUserDetailsState,
} from '../states';
import { DealDetails, DealSessionPage } from '../../components';
import { debounce } from 'utils/debounce';
import { initialConfirmation } from '../../constants';

const pageLimit = 20;

export const useTransactionDeals = () => {
	// globle states
	const [deals, setDeals] = useRecoilState(TransactionDealsState);
	const [selectedDeal, setSelectedDeal] = useRecoilState(
		SelectedTransactionDealState
	);
	const resetDeals = useResetRecoilState(SelectedTransactionDealState);
	const [activeModal, setActiveModal] = useRecoilState(
		TransactionDealDetailsModalState
	);
	const resetDealDetailsLoading = useResetRecoilState(
		TransactionDealDetailsLoadingState
	);
	const setIsComplexFlow = useSetRecoilState(ConditionalSessionFlowState);
	const pageNumber = useRecoilValue(FundPaginationState);
	const selectedDateRange = useRecoilValue(SelectedDateRangeState);

	const resetTotalNodes = useResetRecoilState(DealSessionNodesState);
	const resetDealSessionDetails = useResetRecoilState(
		DealUserSessionDetailsState
	);
	const resetComplexSessionLoading = useResetRecoilState(
		ComplexSessionsNodesLoadingState
	);
	const dealFilteringTags = useRecoilValue(DealFilterTagsState);
	const selectedDealUser = useRecoilValue(DealSelectedUserDetailsState);

	// local state
	const [searchedText, setSearchedText] = useState('');
	const [isLoadingDeals, setIsLoadingDeals] = useState(false);
	const [confirmationModal, setConfirmationModal] =
		useState(initialConfirmation);

	// hooks
	const { get } = useNetwork();
	const { patch, loading: canceling } = useNetwork();
	const { errorNotification, successNotification } = useNotification();

	const { result: dealsResult } = deals ?? {};
	const { endDate, startDate }: any = selectedDateRange[0];

	const formatDateToMMDDYYYY = useCallback(
		(dateString: string | number | Date) => {
			return getDate(dateString, 'MM/dd/yyyy');
		},
		[]
	);

	const fetchDeals = useCallback(
		async (loader?: boolean) => {
			if (loader) {
				setIsLoadingDeals(true);
				setDeals(prev => ({ ...prev, result: [] }));
			}
			const { status } = dealFilteringTags ?? {};
			const params = `?isDeleted=false&page=${pageNumber}&limit=${pageLimit}${
				searchedText && '&search=' + searchedText
			}&startDate=${formatDateToMMDDYYYY(
				startDate
			)}&endDate=${formatDateToMMDDYYYY(endDate)}${
				status ? '&status=' + status.toString() : ''
			}`;
			const resp = await get(`${API_URL.DEALS}${params}`);
			const { data } = resp ?? {};
			if (data?.[0]) setDeals(data?.[0]);
			else setDeals({ totalPages: 0, result: [], currentPage: 0 });
			setIsLoadingDeals(false);
		},
		[
			dealFilteringTags,
			endDate,
			formatDateToMMDDYYYY,
			get,
			pageNumber,
			searchedText,
			setDeals,
			startDate,
		]
	);

	const handleCopy = useCallback(
		(e: MouseEvent<HTMLElement>, _id: string) => {
			e.stopPropagation();
			if (_id) {
				navigator.clipboard.writeText(_id);
				successNotification('Copied.');
			}
		},
		[successNotification]
	);

	const handleClose = useCallback(() => {
		setActiveModal('');
		setIsComplexFlow(false);
		resetDeals();
	}, [resetDeals, setActiveModal, setIsComplexFlow]);

	const handleOnClickRow = useCallback(
		(item: { id: string }) => {
			const { id } = item ?? {};
			const selectedDeal = dealsResult?.find(({ _id }) => _id === id);
			let { buyers, sellers } = selectedDeal ?? {};
			if (buyers?.length) {
				const buyerWith100 = buyers.find(
					buyer => buyer.paymentPercentage === 100
				);
				if (buyerWith100) {
					const otherBuyers = buyers.filter(
						buyer => buyer.paymentPercentage !== 100
					);
					buyers = [{ ...buyerWith100, isPrimary: true }, ...otherBuyers];
				}
			}
			if (sellers?.length) {
				const sellerWith100 = sellers.find(
					seller => seller.paymentPercentage === 100
				);
				if (sellerWith100) {
					const otherSellers = sellers.filter(
						seller => seller.paymentPercentage !== 100
					);
					sellers = [{ ...sellerWith100, isPrimary: true }, ...otherSellers];
				}
			}
			if (selectedDeal)
				setSelectedDeal({
					...selectedDeal,
					...(buyers && { buyers }),
					...(sellers && { sellers }),
				});
			else resetDeals();
			setIsComplexFlow(true);
			setActiveModal('deal-details');
		},
		[dealsResult, setSelectedDeal, resetDeals, setActiveModal, setIsComplexFlow]
	);

	const handleSearchedText = useCallback((value: string) => {
		setSearchedText(value);
	}, []);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debounceSearch = useCallback(debounce(handleSearchedText, 800), []);

	const handleCloseSessionDetails = useCallback(() => {
		setActiveModal('deal-details');
		resetDealDetailsLoading();
		resetTotalNodes();
		resetComplexSessionLoading();
		resetDealSessionDetails();
	}, [
		resetComplexSessionLoading,
		resetDealDetailsLoading,
		resetDealSessionDetails,
		resetTotalNodes,
		setActiveModal,
	]);

	const userName = useMemo(() => {
		const { firstName, lastName } = selectedDealUser ?? {};
		return `${firstName ?? ''} ${lastName ?? ''}`.trim();
	}, [selectedDealUser]);

	const dealName = useMemo(() => selectedDeal.name, [selectedDeal.name]);

	const { modal, component, onClose, className, title } = useMemo(() => {
		switch (activeModal) {
			case 'deal-details':
				return {
					modal: true,
					component: <DealDetails />,
					className: 'view-deal-details-modal',
					onClose: handleClose,
					title: (
						<div className="view-deal-details-modal__header">
							<div className="view-deal-details-modal__header-title">
								{dealName}
							</div>
						</div>
					),
				};
			case 'session-details':
				return {
					modal: true,
					component: <DealSessionPage />,
					className: 'view-deal-details-modal',
					onClose: handleCloseSessionDetails,
					title: (
						<div className="view-deal-details-modal__header view-deal-details-modal__session-header">
							<i
								onClick={handleCloseSessionDetails}
								className="ri-arrow-left-line view-deal-details-modal__back-icon"
							/>
							<div className="view-deal-details-modal__header-title">
								{userName}
							</div>
						</div>
					),
				};
			default:
				return {
					modal: false,
					component: <></>,
					className: '',
					header: <></>,
				};
		}
	}, [activeModal, dealName, handleClose, handleCloseSessionDetails, userName]);

	const modalProps = {
		title: '',
		modalName: className,
		className: className,
		closeModal: onClose,
		children: (
			<>
				{title}
				{component}
			</>
		),
		isOpen: modal,
		isStopOutsideClick: false,
	};

	const maskedDot = (value: string, visible: number): string => {
		if (!value) return '';
		return value.length <= visible
			? `: ${value}`
			: `: ${value.substring(0, visible)}...`;
	};

	const handleCloseDeal = useCallback(
		async (dealId: string, name: string) => {
			if (dealId) {
				setConfirmationModal({
					dealId,
					name,
					visible: true,
				});
			} else errorNotification('Deal not found.');
		},
		[errorNotification]
	);

	const handleConfirmationModal = useCallback(
		async (isOpen: boolean, value: boolean) => {
			if (!value && !isOpen) {
				setConfirmationModal(initialConfirmation);
			} else {
				const payload = {
					status: 'canceled',
					reqType: 'status',
				};
				const resp = await patch(
					`${API_URL.DEALS}/${confirmationModal?.dealId}`,
					payload
				);
				const { dealId, status , message: errorMessage } = resp ?? {};
				if (dealId && status) {
					setDeals(pre => {
						const { result } = structuredClone(pre);
						const index = result?.findIndex(deal => deal?.dealId === dealId);
						if (index !== -1 && result?.[index] !== undefined) {
							(result[index] as ITransactionDeal).status = status;
						}
						return { ...pre, result };
					});
					successNotification('Deal canceled successfully.');
					setConfirmationModal(initialConfirmation);
				} else {
					errorNotification(errorMessage ?? message.SomethingWentWrongMessage);
				}
			}
		},
		[
			confirmationModal?.dealId,
			errorNotification,
			patch,
			setDeals,
			successNotification,
		]
	);

	const confirmationProps = useMemo(
		() => ({
			visible: confirmationModal.visible,
			secondaryLabel: 'No',
			disabled: canceling,
			isStopOutsideClick: false,
			handleModal: handleConfirmationModal,
			title: 'Are you sure?',
			boldDescription: ' Do you want to cancel this deal?',
			description: `This will permanently cancel the deal${maskedDot(
				confirmationModal.name,
				14
			)}.`,
			label: canceling ? (
				<Loader type="loader" className="loader-white" dimension={26} />
			) : (
				'Yes'
			),
		}),
		[canceling, confirmationModal, handleConfirmationModal]
	);

	const menuOptions = useCallback(
		(deal: ITransactionDeal) => {
			// Destructure the relevant properties from the deal object
			const { dealId, name, status, transactionInitiated } = deal ?? {};

			// Determine if the deal status should disable the cancel option
			// Disable if the deal is 'completed' or 'canceled'
			const disableStatus = status === 'completed' || status === 'canceled';

			// Disable the cancel button if the deal status is disabled or the transaction has not been initiated
			const cancelDisabled = disableStatus || transactionInitiated;

			// Return the options for the menu, which includes a 'Cancel deal' option
			return [
				{
					title: 'Cancel deal', // Display title for the menu option
					handleOption: () => handleCloseDeal(dealId, name), // Function to handle when this option is clicked
					disabled: cancelDisabled, // Disable the option based on the calculated status
					toolTip: disableStatus
						? 'Already ' + status // Tooltip when the deal is completed or canceled
						: transactionInitiated
						? 'Transaction already initiated' // Tooltip when the transaction hasn't started
						: '', // No tooltip if the option is enabled
				},
			];
		},
		[handleCloseDeal] // Dependency for the useCallback hook
	);

	return {
		fetchDeals,
		handleCopy,
		modalProps,
		handleOnClickRow,
		isLoadingDeals,
		handleSearchedText: debounceSearch,
		searchedText,
		startDate,
		endDate,
		handleCloseSessionDetails,
		handleCloseDeal,
		confirmationProps,
		menuOptions,
	};
};
