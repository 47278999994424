import { Button, Image, SkeletonTable } from '@storybook';
import { ComprehensiveHeader } from '../kyb-wrapper/components/kyb-comprehensive/components/comprehensive-header';
import './linked-esign-session.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNetwork } from 'hooks';
import { API_URL, environmentUrl, sandboxEnvHost } from 'constant';
import { SimpliciSignIframe } from 'shared-components';
import Modal from '@storybook/new-modal/modal';
import { loginState } from 'global-stores';
import { envHost } from 'helpers';
import { useRecoilValue } from 'recoil';
import { ISignedDocInbox } from 'views/signed-doc-inbox';

interface ILinkedEsignSession {
	sessionId: string;
}

export const LinkedEsignSession = ({ sessionId }: ILinkedEsignSession) => {
	const [isLoading, setIsLoading] = useState(true);
	const [selectedEnvelope, setSelectedEnvelope] = useState<ISignedDocInbox>();
	const { sandboxStatus } = useRecoilValue(loginState);
	const [isShowViewModal, setIsShowViewModals] = useState(false);
	const [linkedEnvelopes, setLinkedEnvelopes] = useState<ISignedDocInbox[]>([]);
	const { get } = useNetwork();

	const getLinkedEnvelopes = useCallback(async () => {
		const resp = await get(
			API_URL.LINKED_ENVELOPES + `?sessionId=${sessionId}`
		);
		setLinkedEnvelopes(resp.data);
		// eslint-disable-next-line no-console
		console.log(resp);
		setIsLoading(false);
	}, [get, sessionId]);

	function formatDateTime(isoString: Date) {
		const date = new Date(isoString);

		// Get date components
		const day = date.getDate().toString().padStart(2, '0');
		const month = date.toLocaleString(undefined, { month: 'long' }); // Uses system's locale
		const year = date.getFullYear();

		// Get time components
		const hours = date.getHours() % 12 || 12; // Convert 24-hour to 12-hour format
		const minutes = date.getMinutes().toString().padStart(2, '0');
		const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

		return {
			date: `${day} ${month} ${year}`,
			time: `${hours}:${minutes} ${ampm}`,
		};
	}

	const renderViewModalBody = useMemo(() => {
		const host = !sandboxStatus
			? environmentUrl[envHost]
			: environmentUrl[sandboxEnvHost[envHost]];
		return (
			<SimpliciSignIframe
				signUrl={`${host}view-doc?envelopeId=${selectedEnvelope?._id}&view=true`}
				className="viewModal__iframe"
				id="view-modal-Iframe-inbox"
				handleSubmitModal={() => ({})}
			/>
		);
	}, [selectedEnvelope?._id, sandboxStatus]);

	const handleViewDoc = useCallback((envelope: ISignedDocInbox) => {
		setIsShowViewModals(true);
		setSelectedEnvelope(envelope);
	}, []);

	useEffect(() => {
		getLinkedEnvelopes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if(!isLoading && !linkedEnvelopes.length) return <></>

	return (
		<>
			<div className="LinkedEsignSession">
				<ComprehensiveHeader label={'Linked eSign'} />
				{!linkedEnvelopes.length && isLoading && (
					<SkeletonTable listsToRender={2} numberColumn={2} />
				)}
				{linkedEnvelopes.map((envelope: ISignedDocInbox) => (
					<div key={envelope._id} className="LinkedEsignSession__item">
						<div className="LinkedEsignSession__item-left">
							<Image
								className="LinkedEsignSession__item-logo"
								fileName="EsignNew.svg"
							/>
							<span className="LinkedEsignSession__item-details">
								<p className="heading">{envelope.name}</p>
								<p className="date">
									{formatDateTime(envelope.createdAt).date}
									<a className="ri-circle-fill"></a>
									{formatDateTime(envelope.createdAt).time}
								</p>
							</span>
						</div>
						<div className="LinkedEsignSession__item-right">
							<p className="LinkedEsignSession__item-status">
								<a className={`ri-circle-fill ${envelope.status}`}></a>
								{envelope.status === 'completed' ? 'Completed' : 'Incomplete'}
							</p>
							<Button
								label={
									<>
										<i className="ri-eye-line"></i> View
									</>
								}
								handleClick={() => handleViewDoc(envelope)}
								type="LinkedEsignSession__item-view-btn"
							/>
						</div>
					</div>
				))}
			</div>
			{isShowViewModal && (
				<Modal
					isOpen={isShowViewModal}
					modalName="View-Doc"
					className="view-doc-modal"
					closeModal={() => setIsShowViewModals(false)}
					isStopOutsideClick={false}
				>
					{renderViewModalBody}
				</Modal>
			)}
		</>
	);
};
