import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { IframePropsState, PipelineMasterSteps } from 'global-stores';
import { PipelineBody } from 'views/pipeline';
import { CurrentStepState } from '../../store';

export const DeliveryMethod = () => {
	const masterSteps = useRecoilValue(PipelineMasterSteps);
	const setCurrentStep = useSetRecoilState(CurrentStepState);
	const { background } = useRecoilValue(IframePropsState);

	useEffect(() => {
		const delivery: any = masterSteps.find(
			method => method.key === 'deliveryMethod'
		);
		if (delivery) {
			const deliveryStep = delivery.actions.find(
				(step: any) => step.key === 'deliveryMethodMessage'
			);
			setCurrentStep(deliveryStep);
		}
	}, [masterSteps, setCurrentStep]);

	return (
		<div
			className="onboarding-modal-height"
			style={{ backgroundColor: background }}
		>
			<PipelineBody />
		</div>
	);
};
