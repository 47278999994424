import { IOptionValue } from "./type";

export const signOptions: { label: string; description: string; value: IOptionValue }[] = [
	{
		label: 'Individual document',
		description: 'Each invitee will receive their own copy of the document to sign separately.',
		value: 'indivisual',
	},
	{
		label: 'Multi signing',
		description: 'All invitees will sign on the same document.',
		value: 'common',
	},
];

export const SIGN_OPTIONS: {[key in Uppercase<IOptionValue>]: IOptionValue} = {
	INDIVISUAL: 'indivisual',
    COMMON: 'common',
}
export type IPurpose = "selfsign" | "workflow" | "signAgreement" | "accreditation" | "multisignAgreement"

export const ESIGN_PURPOSE: { [key in Uppercase<IPurpose>]: IPurpose } = {
	ACCREDITATION: 'accreditation',
	MULTISIGNAGREEMENT: 'multisignAgreement',
	SELFSIGN: 'selfsign',
	SIGNAGREEMENT: 'signAgreement',
	WORKFLOW: 'workflow',
};