export const PipeLine = [
	{
		label: 'Pipeline 1',
		value: 'Pipeline 1',
	},
	{
		label: 'Pipeline 2',
		value: 'Pipeline 2',
	},
	{
		label: 'Pipeline 3',
		value: 'Pipeline 3',
	},
];

export const TableHeader = {
	'Investor Name': 'name',
	'Investor Entity': 'investorEntity',
	'Investor Address': 'address',
	'Investor Phone': 'contact',
	'Number Of Share': 'shares',
	'Dollar Investment': 'investAmount',
	Email: 'email',
	countryCode: 'countryCode',
};

export const csvHeaders = {
	HEADER: [
		{
			label: 'Name',
			key: 'Investor Name',
			format: 'string',
		},
		{
			label: 'Entity',
			key: 'Investor Entity',
			format: 'string',
		},
		{
			label: 'Address',
			key: 'Investor Address',
			format: 'string',
		},
		{
			label: 'Email',
			key: 'Email',
			format: 'string',
		},
		{
			label: 'Phone no.',
			key: 'Investor Phone',
			format: 'string',
		},
		{
			label: 'Share',
			key: 'Number Of Share',
			format: 'string',
		},
		{
			label: 'Investment',
			key: 'Dollar Investment',
			format: 'string',
		},
	],
};

export const DEFAULT_CSV_DATA = [
	[
		{ value: 'Investor First Name', readOnly: true },
		{ value: 'Investor Last Name', readOnly: true },
		{ value: 'Investor Entity', readOnly: true },
		{ value: 'Investor Address', readOnly: true },
		{ value: 'Investor Country Code', readOnly: true },
		{ value: 'Investor Mobile', readOnly: true },
		{ value: 'Investors Email', readOnly: true },
		{ value: 'Number of Shares', readOnly: true },
		{ value: 'Price per Share', readOnly: true },
		{ value: 'Dollars Invested', readOnly: true },
		{ value: 'Share Class', readOnly: true },
		{ value: 'Issue Date', readOnly: true },
	],
	[
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
	],
	[
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
	],
	[
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
	],
	[
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
	],
	[
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
	],
];

export const CSV_INVESTOR_TEMPLATE_URL =
	'https://storage.googleapis.com/satschel-assets-public/captable/captable-template.csv';

export const CSV_USER_TEMPLATE_URL =
	'https://storage.googleapis.com/satschel-assets-public/captable/user-captable-template.csv';
