import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { API_URL } from 'constant';
import { PaymentCardMethodsState } from 'global-stores';
import { useNetwork, useNotification } from 'hooks';
import { usePaymentCard } from 'views/routes-children';
import { PrimaryAccountChecked } from 'views/settings/billing/stores';
import { useDefaultCard } from 'views/user-identity-flow';
import { DefaultCardType } from '../../constant';

export const useWire = () => {
	const [defaultCheck, setDefaultCheck] = useRecoilState(PrimaryAccountChecked);
	const [loading, setLaoding] = useState(false);

	const paymentCardMethodsState = useRecoilValue(PaymentCardMethodsState);
	const {
		wire = {
			accountNumber: '',
			bankName: '',
			routingNumber: '',
		},
	} = useMemo(() => paymentCardMethodsState ?? {}, [paymentCardMethodsState]);

	const primaryAccount = useMemo(
		() => paymentCardMethodsState?.default?.detail ?? {},
		[paymentCardMethodsState?.default]
	);
	const { post } = useNetwork({
		updateState: false,
		returnResponse: true,
	});
	const { successNotification, errorNotification } = useNotification();
	const { getPaymentCardsApi } = usePaymentCard();
	const { isDefaultExist } = useDefaultCard();

	const handleDefaultWireAccount = async () => {
		const payload = {
			method: DefaultCardType.Wire,
		};
		const defaultResp = await post(API_URL.Payment_Methods, payload);
		if (defaultResp.success) {
			setDefaultCheck(true);
			await getPaymentCardsApi();
			successNotification(
				'Wire accounts have been set as primary successfully!'
			);
		} else errorNotification(defaultResp?.message);
		setLaoding(false);
	};

	const handleChangeDefaultCheck = (e: ChangeEvent<HTMLInputElement>) => {
		if (primaryAccount?.accountNumber === wire.accountNumber || !isDefaultExist)
			return;
		if (e.target.checked) {
			setLaoding(true);
			handleDefaultWireAccount();
		}
	};

	useEffect(() => {
		if (!isDefaultExist) setDefaultCheck(true);
		else if (primaryAccount?.accountNumber === wire.accountNumber)
			setDefaultCheck(true);
		else setDefaultCheck(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDefaultExist, primaryAccount?.accountNumber, wire.accountNumber]);

	return {
		handleChangeDefaultCheck,
		wire,
		defaultCheck,
		loading,
	};
};
