import { useRecoilValue } from 'recoil';
import { useMemo, useState } from 'react';
import classNames from 'classnames';

import { OnboardingFooterNavigateState } from 'views/onboarding-flow/store';
import { isChecqueFraudSelected } from 'global-stores';

export const ONBOARDING_STEPS_NAVIGATE = new Array(3).fill('').map(Math.random);

export const OnboardingProgress = () => {
	const navigate = useRecoilValue(OnboardingFooterNavigateState);
	const [activeIndex, setActiveIndex] = useState(0);

	const isChequeFraudSelected = useRecoilValue(isChecqueFraudSelected);

	type IONBOARDING_NAVIGATEKEY = 'publish' | 'deliveryMethod';
	type IONBOARDING_NAVIGATENAME =
		| 'Flow Builder'
		| 'Delivery Method'
		| 'Onboarding Flow'
		| 'Publish';
	interface IONBOARDING_NAVIGATE {
		name: IONBOARDING_NAVIGATENAME;
		key: IONBOARDING_NAVIGATEKEY;
	}
	const ONBOARDING_NAVIGATE = [
		{ name: 'Onboarding Flow', key: 'complex-flow' },
		{ name: 'Delivery Method', key: 'deliveryMethod' },
		{ name: 'Publish', key: 'publish' },
	] as IONBOARDING_NAVIGATE[];

	const renderProgress = useMemo(() => {
		// Clone the original ONBOARDING_NAVIGATE array to avoid mutating the original data.
		let arr = [...ONBOARDING_NAVIGATE];

		// AvinashSatschel has made modifications to dynamically exclude a specific step ('deliveryMethod') if a condition (isChequeFraudSelected) is met.
		if (isChequeFraudSelected) {
			// If the condition for Cheque Fraud is selected, filter out the 'deliveryMethod' step.
			arr = ONBOARDING_NAVIGATE.filter(el => el.key !== 'deliveryMethod');
		}

		return arr.map((item, i) => {
			const isActive = item.key === navigate;
			if (isActive) setActiveIndex(i);
			const stepClasses = classNames('OnboardingFooterProgress-step', {
				'OnboardingFooterProgress-step--active': isActive,
				'OnboardingFooterProgress-step--completed': activeIndex > i,
			});
			return (
				<div key={`${item.key}`} className={stepClasses}>
					<div
						className={`OnboardingFooterProgress-text  ${
							i === 0 && 'OnboardingFooterProgress-first-txt'
						}`}
					>
						{item.name}
					</div>
					<div className="OnboardingFooterProgress-step-number">{i + 1}</div>
				</div>
			);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeIndex, navigate, isChequeFraudSelected]);

	return (
		<div className="OnboardingFooterProgress__progress-wrapper">
			<div className="OnboardingFooterProgress__progress">{renderProgress}</div>
		</div>
	);
};
