export const CardKey = {
	EMAIL: 'email',
	SESSION: 'session',
	ONBOARDING: 'onboarding',
};
export const cardIcons: any = {
	email: 'email_logo.svg',
	session: 'session-icon.svg',
};

export const ids = {
	TOTAL: 'total',
	OPENED: 'opened',
	BOUNCED: 'bounced',
	COMPLETED: 'completed',
	FAILED: 'failed',
	PROCESSING: 'processing',
	PENDING: 'pending',
};
