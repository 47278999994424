import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { IAnalyticsPieChart } from './types';
import { kycSettingsState } from 'global-stores';

export const useSemiPieChart = () => {
	const kycSettings = useRecoilValue(kycSettingsState);
	const { settings } = useMemo(() => kycSettings, [kycSettings]);
	const { bodyFont } = settings ?? {};

	const getSemiPieConfig = ({
		isLoading,
		title,
		seriesData,
		value,
		seriesName,
		setSeriesName,
		setValue,
	}: IAnalyticsPieChart) => {
		const chart =
			(window as any).Highcharts?.chart(title, {
				chart: {
					type: 'pie',
					backgroundColor: 'transparent',
					style: {
						fontFamily: bodyFont?.family !== '' ? bodyFont?.family : 'Poppins',
					},
					marginLeft: 0,
					marginRight: 0,
					events: {
						render: function () {
							// eslint-disable-next-line @typescript-eslint/no-this-alias
							const chart: any = this,
								sum = value;

							chart.textGroup = chart.renderer.g('textGroup').add().toFront();

							const customText = (chart.myCustomText = chart.renderer
								.text(
									`<div style="color:#7C8398">${seriesName}</br><div style="color:#000000; fontSize:16px">${sum}%</div></div>`,
									seriesName.length > 10
										? chart.plotWidth / 1.4
										: seriesName.length > 6
										? chart.plotWidth / 1.48
										: chart.plotWidth / 1.72,
									chart.plotHeight / 2
								)
								.css({
									fontSize: '14px',
									lineHeight: 20,
									textAlign: 'center',
									fontWeight: 500,
									verticalAlign: 'middle',
									textAnchor: 'middle',
								})
								.add(chart.textGroup));
							chart.textGroup.translate(
								-chart.myCustomText.getBBox().width / 2
							);

							return customText;
						},
					},
				},

				title: {
					text: title,
					align: 'left',
					style: {
						color: '#000000',
						fontWeight: '600',
						fontSize: '16px',
					},
				},

				credits: {
					enabled: false,
				},

				accessibility: {
					enabled: false,
				},
				exporting: { enabled: false },

				legend: {
					width: 0,
					align: 'center',
					verticalAlign: 'bottom',
					floating: true,
					x: 25,
					y: -30,
					symbolRadius: 10,
					title: {
						text: null,
					},
					itemStyle: {
						color: '#000000',
						fontWeight: '400',
						fontSize: '12px',
						lineHeight: '16px',
					},
					itemHoverStyle: {
						color: '#000000',
					},
				},

				tooltip: {
					pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
				},

				plotOptions: {
					pie: {
						states: {
							inactive: {
								enabled: false,
							},
						},
						innerSize: '90%',
						borderWidth: 1,
						borderColor: undefined,
						slicedOffset: 6,
						startAngle: -90,
						endAngle: 90,
						showInLegend: true,
						cursor: 'pointer',
						borderRadius: 8,
						dataLabels: {
							enabled: false,
						},

						point: {
							events: {
								legendItemClick: function (this: any) {
									setSeriesName(this.name);
									setValue(this.y.toFixed(1));
									this.series.data.map((point: any) => {
										if (point.name === this.name) {
											point.update({ opacity: 1 }, false);
										}
										if (point.name !== this.name) {
											point.update({ opacity: 0.2 }, false);
										}
									});
									return false;
								},
							},
						},
					},
				},

				series: [
					{
						id: 'idData',
						name: title,
						data: seriesData,
						point: {
							events: {
								click: function (this: any) {
									setSeriesName(this.name);
									setValue(this.y.toFixed(1));
									this.series.data.map((point: any) => {
										if (point.name === this.name) {
											point.update({ opacity: 1 }, false);
										}
										if (point.name !== this.name) {
											point.update({ opacity: 0.2 }, false);
										}
									});
									return;
								},
							},
						},
						states: {
							hover: {
								enabled: false,
							},
						},
					},
				],
				responsive: {
					rules: [
						{
							condition: {
								maxWidth: 472,
							},
							chartOptions: {
								legend: {
									width: 300,
								},
							},
						},
					],
				},
			}) ?? {};

		if (isLoading) {
			chart.showLoading();
		} else {
			chart.hideLoading();
		}
	};
	return { getSemiPieConfig };
};
