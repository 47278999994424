import { atom, atomFamily, selector } from 'recoil';
import { IActiveEmailClient, IClientEmailNavigate } from './types';

export const ClientEmailNavigateState = atom<IClientEmailNavigate>({
	key: 'client-email-navigate-state-key',
	default: 'select-client',
});

export const ActiveEmailClientState = atom<IActiveEmailClient | null>({
	key: 'active-email-client-state-key',
	default: null,
});

export const ActiveEmailClientMethodState = atom<string>({
	key: 'active-email-client-method-state-key',
	default: '',
});

export const ActiveEmailClientFormDataState = atomFamily<
	{ [key: string]: string },
	string
>({
	key: 'active-email-client-form-data-state-key',
	default: {},
});

// selector state for reset ActiveEmailClientFormDataState
export const ResetCredentialsState = selector({
	key: 'reset-creds-state-key',
	get: () => ({}),
	set: ({ set }, newValue) => {
		const newSet = set(ActiveEmailClientFormDataState(newValue as string), {});
		return newSet;
	},
});
