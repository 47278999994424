import { FC, useEffect } from 'react';
import ReactFlow, { Background, Controls, ReactFlowProvider } from 'reactflow';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { useComplexLayout } from 'views/complex-onboarding-flow/hooks';
import { AddedActionsState, ConfigureModal } from 'views/onboarding-flow';
import { PipelineMasterSteps } from 'global-stores';
import { DefaultNode, defaultConfigedNode } from './constants';
import {
	ComplexConfigModalState,
	ComplexErrorSteps,
	ComplexFlowUnconfigedState,
	ConfigComplexNodeId,
	ConfigFormModalState,
	EdgesState,
	NodesState,
	isComplexViewOnlyState,
} from 'views/complex-onboarding-flow/stores/states';
import customNode from '../custom-node';
import CustomEdge from './custom-edge/custom-edge';

import 'reactflow/dist/style.css';
import { ComplexConfigureFormModal } from '../complex-survey-form';
import { ActionList } from 'views/complex-onboarding-flow/constant';

const nodeTypes = { customNode };

//Avinash: Added this Component for Custom Edges
const edgeTypes = {
	buttonedge: CustomEdge,
};

interface IComplexFlowLayout {
	nodes?: any;
	edges?: any;
	handleOnDrop?: any;
	isViewOnly?: boolean;
}
export const ComplexFlowLayout: FC<IComplexFlowLayout> = ({
	nodes: propsNode,
	edges: propsEdges,
	handleOnDrop,
	isViewOnly = false,
}) => {
	const setNodes = useSetRecoilState(NodesState);
	const setEdges = useSetRecoilState(EdgesState);
	const setIsComplexViewOnly = useSetRecoilState(isComplexViewOnlyState);
	const setComplexFlowConfiged = useSetRecoilState(ComplexFlowUnconfigedState);
	const [isConfigFormModal, setIsConfigFormModal] = useRecoilState(ConfigFormModalState);
	const [nodeId, setNodeId] = useRecoilState(ConfigComplexNodeId);
	const [isConfigModal, setIsConfigModal] = useRecoilState(
		ComplexConfigModalState
	);


	const steps = useRecoilValue(PipelineMasterSteps);
	const [actions, setAddedActions] = useRecoilState(AddedActionsState);
	const setComplexErrorSteps = useSetRecoilState(ComplexErrorSteps);	

	const {
		onNodesDelete,
		onDragOver,
		onConnect,
		onDrop,
		onNodesChange,
		onEdgesChange,
		setReactFlowInstance,
		validateConditions,
		edges,
		nodes,
		reactFlowWrapper,
	} = useComplexLayout();

	useEffect(() => {
		const { id } = validateConditions(nodes);
		setComplexErrorSteps(id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nodes]);

	useEffect(() => {
		if (propsNode && propsEdges) {
			setNodes(propsNode);
			setEdges(propsEdges);
		} else {
			if (nodes.length === 0) {
				setNodes([DefaultNode]);

				setComplexFlowConfiged((prev: any) => {
					const newArr = structuredClone(prev);
					newArr.push(defaultConfigedNode);
					return newArr;
				});
			}

			if (steps.length && !actions.length) {
				const action = steps
					.find((el: any) => el.key === 'authentication')
					?.actions.find((elm: any) => elm.key === 'authentication');

				if (action) setAddedActions([action]);
			}
		}

		setIsComplexViewOnly(isViewOnly);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [steps]);

	return (
		<div className="dndflow">
			<ReactFlowProvider>
				<div
					className={`reactflow-wrapper ${
						isViewOnly ? 'react-flow-view_only' : ''
					} `}
					ref={reactFlowWrapper}
				>
					{
						(isConfigFormModal || !!isConfigModal) 
						? null 
						: 
						<ReactFlow
						nodes={propsNode ?? nodes}
						edges={propsEdges ?? edges}
						nodeTypes={nodeTypes}
						onNodesChange={onNodesChange}
						onEdgesChange={onEdgesChange}
						onConnect={onConnect}
						onInit={setReactFlowInstance}
						onNodesDelete={onNodesDelete}
						onDrop={event => onDrop(event, handleOnDrop)}
						onDragOver={onDragOver}
						panOnDrag={true}
						//Avinash: Added this Component for Custom Edges
						edgeTypes={edgeTypes}
						zoomOnScroll={true}
						defaultViewport={{ x: 100, y: 100, zoom: 0 }}
						multiSelectionKeyCode={null}
						selectionKeyCode={null}
						deleteKeyCode={null}
					>
						<Controls style={{ marginTop: 100 }} position="top-right" />
						<Background color="#aaa" gap={24} size={3} />
					</ReactFlow>
					}
					
				</div>
			</ReactFlowProvider>

			{!!isConfigModal && (
				<ConfigureModal
					isOpen={isConfigModal}
					setIsOpen={setIsConfigModal}
					className={'config-setting-modal'}
				/>
			)}

			{isConfigFormModal && (
				<ComplexConfigureFormModal
					setIsFormOpen={setIsConfigFormModal}
					isOpen={isConfigFormModal}
					nodeId={nodeId}
					stepId={ActionList.form}
					setNodeId={setNodeId}
				/>
			)}
		</div>
	);
};
