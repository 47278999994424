import { Loader } from '@storybook';
import { useState } from 'react';

import { Header } from 'views/header';
import './document.scss';

const link = 'https://simplici.readme.io/reference';
export const Document = () => {
	const [isLoading, setIsLoadng] = useState(true);

	return (
		<div className="wrapper-iframe">
			{isLoading && <Loader />}
			<div className="outer--iframe">
				<div className="inner--iframe">
					<Header title="API Docs" />
				</div>
			</div>
			<iframe
				src={link}
				title="description"
				onLoad={() => setIsLoadng(false)}
				className="Document--iframe"
			/>
		</div>
	);
};

export default Document;
