import { IPerson, ITransactionDeal } from '../type';

import { useRecoilState, useSetRecoilState } from 'recoil';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';

import { formatNumberWithCommasAndCurrencySignSign } from 'utils';
import { API_URL, message } from 'constant';
import { useNetwork, useNotification } from 'hooks';
import { SelectedTransactionDealState, TransactionDealsState } from '../states';

export const useDealHeader = () => {
	// globle states
	const [selectedDeal, setSelectedDeal] = useRecoilState(
		SelectedTransactionDealState
	);
	const setDeals = useSetRecoilState(TransactionDealsState);

	// local states
	const [isOpenEditPriceInput, setIsOpenEditPriceInput] = useState(false);
	const [newSalePrice, setNewSalePrice] = useState('');

	// hooks
	const { patch, loading: updatingPrice } = useNetwork();
	const { errorNotification, successNotification } = useNotification();

	const { fees, salePrice, status, type, dealId } = useMemo(
		() => selectedDeal,
		[selectedDeal]
	);

	const totalAmount = useMemo(() => {
		let totalfees = 0;
		if (Array.isArray(fees))
			fees.forEach(({ name, amount }) => {
				if (name && typeof amount === 'number') {
					totalfees += amount;
				}
			});
		if (typeof salePrice === 'number') totalfees += salePrice;
		return totalfees;
	}, [fees, salePrice]);

	const getUserInfo = useCallback((key: string, user: IPerson) => {
		const { firstName, lastName, phone, countryCode, email } = user ?? {};
		switch (key) {
			case 'name':
				return (firstName || '--') + ' ' + (lastName || '');
			case 'email':
				return email;
			case 'phone':
				return (countryCode || '') + ' ' + (phone || '--');
			default:
				return '--';
		}
	}, []);

	const fromCamelCase = (camelCaseString: string) => {
		// Split the camelCase string into words
		const words =
			camelCaseString.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ') ?? [];
		// Join the words together with spaces
		return words.join(' ') || camelCaseString;
	};

	const priceBreak = useMemo(() => {
		const resultFees = [];
		if (salePrice) {
			resultFees.push({
				key: 'salePrice',
				label: 'Sale Price',
				value: !isOpenEditPriceInput
					? `${formatNumberWithCommasAndCurrencySignSign(
							salePrice.toFixed(2),
							'$'
					  )} `
					: '',
			});
		}
		if (Array.isArray(fees)) {
			fees.forEach(({ name, amount }) => {
				if (name && typeof amount === 'number') {
					resultFees.push({
						key: name,
						label: fromCamelCase(name),
						value: `${formatNumberWithCommasAndCurrencySignSign(
							amount.toFixed(2),
							'$'
						)} `,
					});
				}
			});
		}
		return [...resultFees];
	}, [fees, isOpenEditPriceInput, salePrice]);

	const showEditPrice = useCallback(
		(key: string) => {
			return (
				type === 'protect' &&
				key === 'salePrice' &&
				status !== 'completed' &&
				status !== 'canceled'
			);
		},
		[status, type]
	);

	const handleIsOpenEditPriceInput = useCallback(() => {
		setNewSalePrice('');
		setIsOpenEditPriceInput(prev => !prev);
	}, []);

	const handleChangeAmount = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target ?? {};
		const newAmount = Number(value);
		if (value && isNaN(newAmount)) return;
		if (!/^(\d+(\.\d{0,2})?|)$/.test(value)) return;
		setNewSalePrice(value.trim());
	}, []);

	const handleSubmitAmount = useCallback(async () => {
		const newAmount = Number(newSalePrice);
		if (!newAmount || isNaN(newAmount))
			return errorNotification('Please enter a valid amount.');
		if (salePrice && salePrice <= newAmount)
			return errorNotification(
				`The revised sale price must be lower than ${formatNumberWithCommasAndCurrencySignSign(
					salePrice,
					'$'
				)} USD`
			);
		const payload = {
			salePrice: newAmount,
			reqType: 'salePrice',
		};
		const resp = await patch(`${API_URL.DEALS}/${dealId}`, payload);
		const {
			fees: new_fees,
			dealId: deal_id,
			salePrice: sale_price,
			message: error_message,
		} = resp ?? {};
		if (deal_id && new_fees && sale_price) {
			setSelectedDeal(prev => ({
				...prev,
				fees: new_fees,
				salePrice: sale_price,
			}));
			setDeals(pre => {
				const { result } = structuredClone(pre);
				const index = result?.findIndex(deal => deal?.dealId === deal_id);
				if (index !== -1 && result?.[index] !== undefined) {
					(result[index] as ITransactionDeal).salePrice = sale_price;
					(result[index] as ITransactionDeal).fees = new_fees;
				}
				return { ...pre, result };
			});
			setIsOpenEditPriceInput(false);
			setNewSalePrice('');
			successNotification('Sale price updated successfully.');
		} else {
			errorNotification(error_message || message.SomethingWentWrongMessage);
		}
	}, [
		dealId,
		errorNotification,
		newSalePrice,
		patch,
		salePrice,
		setDeals,
		setSelectedDeal,
		successNotification,
	]);

	return {
		salePrice,
		totalAmount,
		priceBreak,
		getUserInfo,
		showEditPrice,
		isOpenEditPriceInput,
		handleIsOpenEditPriceInput,
		handleChangeAmount,
		handleSubmitAmount,
		newSalePrice,
		updatingPrice,
	};
};
