import { FC, MouseEvent, createRef, useCallback, useState } from 'react';
import { IconBtn } from '@storybook';
import { useOutsideClick } from 'hooks';
import './dropPayement.scss';

export interface IDropOptions {
	handleView: () => void;
	handleDelete: () => void;
	handleUseFlow: () => void;
	cardId: string;
	defaultCardId: string;
	isExpired?: boolean;
	isHideDelete?: boolean;
}

export const DropPaymentOptions: FC<IDropOptions> = ({
	handleView,
	handleDelete,
	cardId,
	defaultCardId,
	isExpired = false,
	isHideDelete = false,
}) => {
	// local state
	const [showOption, setShowOption] = useState<boolean>(false);

	// ref
	const ref = createRef<HTMLDivElement>();

	useOutsideClick(ref, () => {
		setShowOption(false);
	});

	const handleOptions = useCallback((e: MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		setShowOption(prev => !prev);
	}, []);

	const handleclickOnPrimary = useCallback(() => {
		setShowOption(false);
		handleView();
	}, [handleView]);

	const handleclickOnDelete = useCallback(() => {
		setShowOption(false);
		handleDelete();
	}, [handleDelete]);

	return (
		<div ref={ref} className={'options-wrapper'}>
			<IconBtn
				className="extra"
				btnClassName="ri-more-2-fill"
				handleClickIcon={handleOptions}
			/>
			{showOption && (
				<div className="options-box">
					{defaultCardId !== cardId && (
						<button
							onClick={handleclickOnPrimary}
							disabled={isExpired}
							className={`menu-item ${isExpired ? 'menu-disable' : ''}`}
						>
							<i className="ri-check-line space" />
							Set as Primary
						</button>
					)}

					{!isHideDelete && (
						<button className="menu-item" onClick={handleclickOnDelete}>
							<i className="ri-delete-bin-line space" />
							Delete
						</button>
					)}
				</div>
			)}
		</div>
	);
};
