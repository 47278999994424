import { FC, ReactNode, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { PipelineMasterSteps } from 'global-stores';
import { ActionList } from 'views/complex-onboarding-flow/constant';
import { ComplexSettingFormState } from 'views/pipeline';
import { CustomHandle } from '../custom-handle';
import { ICustomNode } from '../custom-node';
import { RenderKybBlock } from './render-kyb-block';
import './kyb-action.scss';

export const KYBWrapper = ({ children }: { children: ReactNode }) => {
	return <div className="kyb-action">{children}</div>;
};
const StepIds = ['Verify information about a business entity'];

export const KybAction: FC<ICustomNode> = ({ id: nodeId, data }) => {
	const pipelinesSteps = useRecoilValue(PipelineMasterSteps);
	const [complexSettingForm, setComplexSettingForm] = useRecoilState(
		ComplexSettingFormState
	);

	const actionId = useMemo(() => data.label, [data.label]);

	const actions = useMemo(
		() =>
			pipelinesSteps.find(
				pipelineStep => pipelineStep.key === (ActionList as any)[actionId]
			)?.actions[0],
		[actionId, pipelinesSteps]
	);

	// Arun kumar : showing price for kyb node as per the nodeID 
	const totalPrice = useMemo(() => {
		let price = 0;
		for (const item of actions?.metadata ?? []) {
			if (
				nodeId in complexSettingForm &&
				complexSettingForm[nodeId][item.key] === true
			) {
				price += item.price ?? 0;
			}
		}
		return price;
	}, [actions?.metadata, complexSettingForm, nodeId]);

	const renderActionLayout = useMemo(
		() =>
			StepIds.map((el: string) => (
				<RenderKybBlock
					stepId="kyb"
					label={actions?.label}
					key={el}
					price={totalPrice}
					nodeId={nodeId}
				/>
			)),
		[actions?.label, totalPrice, nodeId]
	);

	//Shahbaaz Set Kyb on complex config setting form for showing price breakdown
	useEffect(() => {
		// Arun Kumar : Action added in state for KYB in ComplexSettingFormState
		const kybActions = pipelinesSteps?.filter(item => {
			if (item.actions) {
				return item.actions.some(
					(action: { key: string }) => action.key === data?.label
				);
			}
			return [];
		});
		const metadatas = kybActions[0]?.actions[0]?.metadata || [];
		let expectedResult: any = {};

		metadatas.forEach(item => {
			if (item.key && item.default) {
				expectedResult[item.key] = item.default === 'true';
			}
		});
		// Arun kumar : Showing saved config to config modal
		if(complexSettingForm?.[nodeId]){
			expectedResult = complexSettingForm?.[nodeId]
		}
		setComplexSettingForm((prev: any) => {
			const newObj = structuredClone(prev);
			if (!newObj[nodeId]) {
				newObj[nodeId] = {};
			}
			Object.assign(newObj[nodeId], {
				Kyb: 'Kyb',
				...expectedResult,
			});

			return newObj;
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data?.label, nodeId, pipelinesSteps, setComplexSettingForm]);

	return (
		<KYBWrapper>
			{renderActionLayout}
			<CustomHandle value={nodeId} />
		</KYBWrapper>
	);
};
