/* eslint-disable react/no-array-index-key */
import { FC, useCallback, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { KybOfacSelectedTab } from 'views/sessions/components/kyb-wrapper/hooks';

interface IProps {
	tabs: string[];
}

export const Tabs: FC<IProps> = ({ tabs }) => {
	const [activeTab, setActiveTab] = useState<string>(tabs?.[0] ?? '');
	const setSelectedTab = useSetRecoilState(KybOfacSelectedTab);
	

	const handleTabs = useCallback(
		(tab: string) => () => {
			setActiveTab(tab);
			setSelectedTab(tab as any)
		},
		[setSelectedTab]
	);

	return (
		<div className="ofac__tabs-wrapper">
			<div className="ofac__tabs">
				{(tabs ?? []).map((tab, index) => (
					<div
						key={index}
						className={`ofac__tab ${
							activeTab === tab ? 'ofac__active-tab' : ''
						}`}
						onClick={handleTabs(tab)}
					>
						{tab}
					</div>
				))}
			</div>
		</div>
	);
};
