import { CaptableCustom } from '@storybook/custom-captable';
import { API_URL } from 'constant';
import {
	GridCAPTableData,
	InvestorTableGridData,
	IsCapTableLoading,
	IsCreatingFundState,
	SelectedFundType,
	SelectedInviteFundState,
	currentCapTableData,
} from 'global-stores';
import { useNetwork } from 'hooks';
import { useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { NewUserPopup } from 'views/new-user-popup';
import { mendatoryFields } from 'views/pipelines';
import { CSV_INVESTOR_TEMPLATE_URL, CSV_USER_TEMPLATE_URL } from '../constants';
import {
	DEFAULT_CAPTABLE,
	DEFAULT_SMALL_FUND_CAPTABLE,
} from '../constants/default-cap-table';
import '../invite-investor.scss';

export const UploadCSVInput = ({ selectedDetail, handleClose }: any) => {
	const [grid, setGrid] = useRecoilState(InvestorTableGridData);
	const setInvitesInvestorData = useSetRecoilState(GridCAPTableData);
	const selectedFund = useRecoilValue(SelectedInviteFundState);
	const fundType = useRecoilValue(SelectedFundType);
	const { get: getFundCaptable, data: currentCap, loading } = useNetwork();
	const [currentCapTable, setCurrentCapTable] =
		useRecoilState(currentCapTableData);
	const setIsLoading = useSetRecoilState(IsCapTableLoading);
	const isNewFund = useRecoilValue(IsCreatingFundState);

	const csvTemplateUrl = useMemo(() => {
		if (
			/signAgreement|fundInvestment|506b|506c/gi.test(
				selectedDetail.join(',')
			) ||
			isNewFund
		)
			return CSV_INVESTOR_TEMPLATE_URL;
		else return CSV_USER_TEMPLATE_URL;
	}, [selectedDetail, isNewFund]);

	useEffect(() => {
		setIsLoading(loading);
	}, [loading, setIsLoading]);

	useEffect(() => {
		// this condition was commented because fund investment is disabled for now
		// if (isNewFund) {

		if (
			(selectedDetail.includes('506b') ||
				selectedDetail.includes('506c') ||
				isNewFund) &&
			!selectedDetail?.includes('fundInvestment')
		) {
			setGrid(DEFAULT_CAPTABLE);
			setCurrentCapTable(DEFAULT_CAPTABLE);
		} else if (grid?.[1]?.[1]?.value === '') {
			if (selectedDetail?.includes('fundInvestment')) {
				if (fundType === 'edit') {
					getFundCaptable(`${API_URL.FUNDS}/${selectedFund.value}`);
				} else {
					setGrid(DEFAULT_SMALL_FUND_CAPTABLE);
					setCurrentCapTable(DEFAULT_SMALL_FUND_CAPTABLE);
				}
			} else {
				setGrid(DEFAULT_CAPTABLE);
				setCurrentCapTable(DEFAULT_CAPTABLE);
			}
		}
		// }
		// eslint-disable-next-line
	}, [fundType, selectedDetail, isNewFund]);

	useEffect(() => {
		if (currentCap?.data) {
			const header: any = [];
			currentCap.data?.capTable?.headers?.forEach((item: any) => {
				header.push({
					value: item.label,
					readOnly: true,
					...(mendatoryFields.includes(item.label) && {
						className: 'required',
					}),
				});
			});
			const gridTemp = [...grid];
			gridTemp[0] = header;
			const rows = [1, 2, 3, 4].map(() => {
				return new Array(header.length).fill({ value: '' });
			});

			rows.unshift(header);
			setGrid(rows);
			setCurrentCapTable(rows);
		}
		// eslint-disable-next-line
	}, [currentCap, setCurrentCapTable, setGrid]);

	useEffect(() => {
		if (grid) {
			const capTableHeader: any = [];
			const capTableRowArray: any = [];
			(grid as any)[0].forEach((item: any) => {
				capTableHeader.push(item?.value);
			});
			grid.forEach((item: any, index: any) => {
				const arr: any = [];
				if (index !== 0) {
					item.forEach((row: any) => {
						arr.push(row?.value ?? '');
					});
					capTableRowArray.push(arr);
				}
			});
			setInvitesInvestorData({
				headers: capTableHeader,
				rows: capTableRowArray,
			});
		}
		// eslint-disable-next-line
	}, [grid]);

	return (
		<div className="captable-warning">
			<CaptableCustom
				theme="light"
				captable={true.toString()}
				grid_data={currentCapTable}
				csvTemplateUrl={csvTemplateUrl}
			/>
			<NewUserPopup handleCaptableClose={handleClose} />
		</div>
	);
};
