import type { IAnalyticsSankeyChart } from './types';

import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { kycSettingsState } from 'global-stores';
import { getMinutesTime } from 'helpers';
import { onboardingSteps, onboardingStepsAvgTime } from '../components';

export const useSankeyChart = () => {
	const kycSettings = useRecoilValue(kycSettingsState);
	const { settings } = useMemo(() => kycSettings, [kycSettings]);
	const { bodyFont } = settings ?? {};

	const getSankeyConfig = ({
		isLoading,
		seriesData,
		setNodeTitle,
		flowAverageTime,
		title,
	}: IAnalyticsSankeyChart) => {
		const chart = (window as any).Highcharts?.chart(title, {
			chart: {
				type: 'sankey',
				backgroundColor: 'transparent',
				height:
					seriesData.length > 10
						? seriesData.length > 20
							? 600
							: 460
						: seriesData.length * 35,
				style: {
					fontFamily: bodyFont?.family !== '' ? bodyFont?.family : 'Poppins',
				},

				events: {
					load(this: any) {
						this.series[0].points.forEach((point: any) => {
							this.reflow();
							if (point.totalCount === 0) {
								point.update({
									weight: 0,
									color: 'transparent',
								});
							}
						});
					},
				},
			},

			credits: {
				enabled: false,
			},

			title: {
				text: null,
			},
			exporting: { enabled: false },

			plotOptions: {
				series: {
					point: {
						events: {
							click: function (this: any) {
								if (
									this.formatPrefix === 'node' &&
									onboardingSteps.includes(this.id)
								) {
									setNodeTitle(this.id);
								}
							},
						},
					},
				},
				sankey: {
					nodeWidth: 120,
					opacity: 0.85,
					shadow: true,
					borderRadius: 2,
					cursor: 'pointer',
					states: {
						inactive: {
							enabled: true,
							opacity: 0.5,
						},
					},
				},
			},

			accessibility: {
				enabled: false,
			},

			series: [
				{
					keys: ['from', 'to', 'weight', 'color', 'totalCount'],
					nodes: [
						{
							id: 'Mobile',
							color: {
								linearGradient: { x1: 1, x2: 0, y1: 0, y2: 0 },
								stops: [
									[0, '#55D6FF'],
									[1, '#1f2f98'],
								],
							},
						},
						{
							id: 'Tablet',
							color: {
								linearGradient: { x1: 1, x2: 0, y1: 0, y2: 0 },
								stops: [
									[0, '#55D6FF'],
									[1, '#1f2f98'],
								],
							},
						},
						{
							id: 'Desktop',
							color: {
								linearGradient: { x1: 1, x2: 0, y1: 0, y2: 0 },
								stops: [
									[0, '#55D6FF'],
									[1, '#1f2f98'],
								],
							},
						},
						{
							id: 'KYC',
							opacity: 1,
							color: {
								linearGradient: { x1: 1, x2: 0, y1: 0, y2: 0 },
								stops: [
									[0, '#E08EEC'],
									[1, '#FA738B'],
								],
							},
						},
						{
							id: 'KYB',
							opacity: 1,
							color: {
								linearGradient: { x1: 1, x2: 0, y1: 0, y2: 0 },
								stops: [
									[0, '#E08EEC'],
									[1, '#FA738B'],
								],
							},
						},
						{
							id: 'AML',
							opacity: 1,
							color: {
								linearGradient: { x1: 1, x2: 0, y1: 0, y2: 0 },
								stops: [
									[0, '#E08EEC'],
									[1, '#FA738B'],
								],
							},
						},
						{
							id: 'Acc. 506b',
							opacity: 1,
							color: {
								linearGradient: { x1: 1, x2: 0, y1: 0, y2: 0 },
								stops: [
									[0, '#E08EEC'],
									[1, '#FA738B'],
								],
							},
						},
						{
							id: 'Acc. 506c',
							opacity: 1,
							color: {
								linearGradient: { x1: 1, x2: 0, y1: 0, y2: 0 },
								stops: [
									[0, '#E08EEC'],
									[1, '#FA738B'],
								],
							},
						},
						{
							id: 'Sign Agreement',
							opacity: 1,
							color: {
								linearGradient: { x1: 1, x2: 0, y1: 0, y2: 0 },
								stops: [
									[0, '#E08EEC'],
									[1, '#FA738B'],
								],
							},
						},
						{
							id: 'Funds Pay In',
							opacity: 1,
							color: {
								linearGradient: { x1: 1, x2: 0, y1: 0, y2: 0 },
								stops: [
									[0, '#E08EEC'],
									[1, '#FA738B'],
								],
							},
						},
						{
							id: 'Funds Pay Out',
							opacity: 1,
							color: {
								linearGradient: { x1: 1, x2: 0, y1: 0, y2: 0 },
								stops: [
									[0, '#E08EEC'],
									[1, '#FA738B'],
								],
							},
						},
						{
							id: 'Authentication',
							opacity: 1,
							color: {
								linearGradient: { x1: 1, x2: 0, y1: 0, y2: 0 },
								stops: [
									[0, '#E08EEC'],
									[1, '#FA738B'],
								],
							},
						},
						{
							id: 'Accreditation',
							opacity: 1,
							color: {
								linearGradient: { x1: 1, x2: 0, y1: 0, y2: 0 },
								stops: [
									[0, '#E08EEC'],
									[1, '#FA738B'],
								],
							},
						},
						{
							id: 'Form',
							opacity: 1,
							color: {
								linearGradient: { x1: 1, x2: 0, y1: 0, y2: 0 },
								stops: [
									[0, '#E08EEC'],
									[1, '#FA738B'],
								],
							},
						},
						{
							id: 'Dynamic Form',
							opacity: 1,
							color: {
								linearGradient: { x1: 1, x2: 0, y1: 0, y2: 0 },
								stops: [
									[0, '#E08EEC'],
									[1, '#FA738B'],
								],
							},
						},
						{
							id: 'KYB Form',
							opacity: 1,
							color: {
								linearGradient: { x1: 1, x2: 0, y1: 0, y2: 0 },
								stops: [
									[0, '#E08EEC'],
									[1, '#FA738B'],
								],
							},
						},
						{
							id: 'Document Review',
							opacity: 1,
							color: {
								linearGradient: { x1: 1, x2: 0, y1: 0, y2: 0 },
								stops: [
									[0, '#E08EEC'],
									[1, '#FA738B'],
								],
							},
						},

						{
							id: 'Approved',
							color: {
								linearGradient: { x1: 1, x2: 0, y1: 0, y2: 0 },
								stops: [
									[0, '#2649FF'],
									[1, '#A645D3'],
								],
							},
						},
						{
							id: 'Pending',
							color: {
								linearGradient: { x1: 1, x2: 0, y1: 0, y2: 0 },
								stops: [
									[0, '#2649FF'],
									[1, '#A645D3'],
								],
							},
						},
						{
							id: 'Under Review',
							color: {
								linearGradient: { x1: 1, x2: 0, y1: 0, y2: 0 },
								stops: [
									[0, '#2649FF'],
									[1, '#A645D3'],
								],
							},
						},
						{
							id: 'Rejected',
							color: {
								linearGradient: { x1: 1, x2: 0, y1: 0, y2: 0 },
								stops: [
									[0, '#2649FF'],
									[1, '#A645D3'],
								],
							},
						},
						{
							id: 'Failed',
							color: {
								linearGradient: { x1: 1, x2: 0, y1: 0, y2: 0 },
								stops: [
									[0, '#2649FF'],
									[1, '#A645D3'],
								],
							},
						},
					],

					data: seriesData ?? [],
					type: 'sankey',
					name: 'Onboarding Flow',
					linkOpacity: 0,
					minLinkWidth: 20,

					dataLabels: {
						style: {
							fontSize: '12px',
							verticalAlign: 'middle',
							textAlign: 'center',
							color: '#ffffff',
							textOutline: '0px',
						},
					},

					tooltip: {
						headerFormat: '',
						pointFormat:
							'{point.fromNode.name} → {point.toNode.name} : <b>{point.totalCount}</b>',

						nodeFormatter: function (this: any) {
							if (onboardingSteps.includes(this.id)) {
								let pending;
								let approve;
								let review;
								this.series.data.map((item: any) => {
									if (item.from === this.id && item.to === 'Pending') {
										return (pending = item.weight);
									} else if (item.from === this.id && item.to === 'Approved') {
										return (approve = item.weight);
									} else if (
										item.from === this.id &&
										item.to === 'Under Review'
									) {
										return (review = item.weight);
									}
								});
								const pendingPercent =
									(
										((pending ?? 0) /
											((pending ?? 0) + (approve ?? 0) + (review ?? 0))) *
										100
									).toFixed(1) ?? 0;
								const approveUser = (approve ?? 0) + (review ?? 0) || 0;

								return `<span style="font-weight:400; padding-top:400px">${
									pendingPercent === 'NaN' ? 0 : pendingPercent
								}% of Users Went Back | ${approveUser} users proceeded | Avg ${getMinutesTime(
									flowAverageTime[onboardingStepsAvgTime[this.id]]
								)} seconds to proceed.</span>`;
							}
							return `<span style="font-weight:500">${this.id} : ${this.sum}</span>`;
						},
					},
				},
			],

			responsive: {
				rules: [
					{
						condition: {
							maxWidth: 644,
						},
						chartOptions: {
							plotOptions: {
								sankey: {
									nodeWidth: 100,
								},
							},
						},
					},
				],
			},
		});

		if (isLoading) {
			chart.showLoading();
		} else {
			chart.hideLoading();
		}
	};

	return { getSankeyConfig };
};
