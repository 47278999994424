import { Image, Loader } from '@storybook';
import {
	FC,
	ReactNode,
	createRef,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';

import { BRAND_LOGO, LOGO, ROUTE_TAB_NAMES } from 'constant';
import {
	IframePropsState,
	IsBannerStatusState,
	IsCreatingFundState,
	IsOpenInIframeState,
	IsUserPermissionLoading,
	kycSettingsState,
	loginState,
} from 'global-stores';
import { envHost } from 'helpers';
import {
	INIT_CONFIGURATION_SAVE,
	useIndexedDB,
	useLogout,
	useOutsideClick,
} from 'hooks';
import {
	MOBILE_VIEW_TABS,
	PARENT_SIDEBAR,
	ROUTES,
	SIDEBAR_ACCOUNT_TABS,
	SIDEBAR_COLLAPSE_TABS,
	SIDEBAR_TABS,
} from 'routes';
import { media } from 'utils';
import { RequestDemoOpenState } from 'views/book-appointment';
import { useUserRoles } from 'views/routes-children';
import { PacketInboxfilteringTagsState } from 'views/signed-doc-packets-inbox';
import { InboxfilteringTagsState } from 'views/signed-doc-inbox';
import { LogoutModal, SidebarSkeletonLoading, UserProfile } from './components';
import { SelectedRouteState, SidebarToogelState } from './stores';
import { ToggleRoute } from './toggle-route';
import './sidebar.scss';

interface ISidebar {
	brandLoaded: boolean;
	children: ReactNode;
	isShowSidebar: boolean;
}

export const Sidebar: FC<ISidebar> = ({
	brandLoaded,
	children,
	isShowSidebar,
}) => {
	const ref = createRef<HTMLDivElement>();
	const [isOpen, setIsOpen] = useRecoilState(SidebarToogelState);
	const [windowSizeWidth, setWindowSizeWidth] = useState(window.innerWidth);

	// global states
	const { image, sandboxStatus } = useRecoilValue(loginState);
	const setIsNewFund = useSetRecoilState(IsCreatingFundState);
	const {
		webAdminType,
		clientlogosmall = '',
		clientlogo = '',
	} = useRecoilValue(IframePropsState);
	const isWebComponentOpen = useRecoilValue(IsOpenInIframeState);
	const companyDetails = useRecoilValue(kycSettingsState);
	const bannerStatus = useRecoilValue(IsBannerStatusState);
	const { whitelabel } = useRecoilValue(kycSettingsState);
	const setPopupOpen = useSetRecoilState(RequestDemoOpenState);
	const setSeletedRoute = useSetRecoilState(SelectedRouteState);
	const isPermissionLoading = useRecoilValue(IsUserPermissionLoading);
	const resetFilteringTagsInbox = useResetRecoilState(
		PacketInboxfilteringTagsState
	);

	const resetFilteringTagsSentbox = useResetRecoilState(
		InboxfilteringTagsState
	);

	const [openToggle, setOpenToggle] = useState('');
	// hooks
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const mediaDevice = media();
	const { logout, loading } = useLogout();
	const { deleteKey } = useIndexedDB(INIT_CONFIGURATION_SAVE);
	const { customUserTab } = useUserRoles();

	const [userProfile, setUserProfile] = useState(false);
	const [userLogout, setUserLogout] = useState(false);

	useOutsideClick(ref, () => {
		setUserProfile(false);
	});

	// this will change the tabs
	const handleChangeTab = useCallback(
		(tabName: string) => {
			setSeletedRoute(tabName);
			if (tabName === 'Logout') {
				setUserLogout(true);
				deleteKey('session');
			} else if (tabName === 'User Profile') {
				setUserProfile(pre => !pre);
			} else {
				if (ROUTE_TAB_NAMES.includes(tabName)) {
					resetFilteringTagsInbox();
					resetFilteringTagsSentbox();
				}
				navigate(`/${tabName}`);
			}
			setIsNewFund(false);
		},
		// eslint-disable-next-line
		[navigate]
	);

	// this will manage the rolewise visibility of tabs
	const conditionalActiveTab = useCallback(
		(tabName: string) => {
			const Tabs =
				windowSizeWidth > 1024
					? isOpen
						? PARENT_SIDEBAR
						: MOBILE_VIEW_TABS
					: MOBILE_VIEW_TABS;
			const filteredValues = Array.from(
				new Set(
					customUserTab
						?.map(key => Tabs[key])
						?.filter(value => value !== undefined)
				)
			);
			const isAllow = filteredValues?.some(el => el === tabName);

			return isAllow ?? false;
		},
		[windowSizeWidth, customUserTab, isOpen]
	);
	// this will manage the visibility of integrate tab ie: will visible only if the plan is paid
	// as we have remove the payment for now there fore we are not using the function
	const isShowIntegrateTab = useCallback(
		(tabName: string) => {
			if (tabName === 'Integrate' || tabName === 'Api Docs') {
				return !isWebComponentOpen;
			}
			return true;
		},
		[isWebComponentOpen]
	);

	useEffect(() => {
		const handleWindowResize = () => {
			setWindowSizeWidth(window.innerWidth);
		};
		window.addEventListener('resize', handleWindowResize);
		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	});

	// here tabs are rendered using map
	const mapRender = useMemo(() => {
		const isLiveRoute = (ROUTE: any, SUB_ROUTE: any) => {
			let isExist = false;
			if (pathname === `/${ROUTE}` || pathname.includes(`/${ROUTE}`)) {
				isExist = true;
			} else {
				SUB_ROUTE?.map((route: any) => {
					if (
						pathname === `/${route.ROUTE}` ||
						pathname.includes(`/${route.ROUTE}`)
					) {
						isExist = true;
					}
				});
			}
			return isExist;
		};
		const lists = isOpen ? SIDEBAR_TABS : SIDEBAR_COLLAPSE_TABS;
		const excludedRoutes = [
			'Captable',
			'Stake holder',
			'Share class',
			'Equidity plan',
		];
		const tabs = lists.tabs.filter(
			item => !excludedRoutes.some(title => title === item.TITLE)
		);
		const listingData = envHost === 'stage' ? lists.tabs : tabs;
		return (
			<div className="anything">
				{listingData.map(
					({ ICON, ROUTE = '', TAB_INDEX, TITLE, SUB_ROUTE }, index) =>
						conditionalActiveTab(TITLE) &&
						isShowIntegrateTab(TITLE) && (
							<div key={TAB_INDEX}>
								<div
									className={`sidebar__item ${
										isLiveRoute(ROUTE, SUB_ROUTE)
											? 'sidebar__item--active'
											: ''
									} sidebar__item${!isOpen ? '--center' : ''}`}
									onClick={() => {
										if (!(SUB_ROUTE && isOpen && windowSizeWidth > 1024)) {
											handleChangeTab(ROUTE);
										}
									}}
									key={`${TAB_INDEX}-${TITLE}`}
								>
									{!(SUB_ROUTE && isOpen && windowSizeWidth > 1024) && (
										<i
											className={`sidebar__icon ${(isLiveRoute(ROUTE, SUB_ROUTE)?`${ICON}fill`:`${ICON}line`)} 
											${
												(!isOpen && sandboxStatus && ROUTE === 'integrate') || 
												(ROUTE === 'integrate' && windowSizeWidth <= 1024)
													? 'd-none'
													: ''
											}`}
											id={ICON}
										/>
									)}
									{SUB_ROUTE && isOpen && windowSizeWidth > 1024 ? (
										<ToggleRoute
											index={index}
											openToggle={openToggle}
											setOpenToggle={setOpenToggle}
											route={{ ICON, ROUTE, TAB_INDEX, TITLE, SUB_ROUTE }}
											handleChangeTab={handleChangeTab}
											pathname={pathname}
											setisActive={isLiveRoute(ROUTE, SUB_ROUTE)}
										/>
									) : (
										<div className={`sidebar__text  ${!isOpen && 'd-none'} `}>
											{TITLE}
										</div>
									)}
								</div>
								{!isOpen && (
									<ReactTooltip
										anchorId={ICON}
										place="right"
										content={TITLE}
										style={{ zIndex: 1000, color: '#ffffff' }}
									/>
								)}
							</div>
						)
				)}
			</div>
		);
	}, [
		isOpen,
		pathname,
		conditionalActiveTab,
		isShowIntegrateTab,
		windowSizeWidth,
		openToggle,
		handleChangeTab,
		sandboxStatus,
	]);
	useEffect(() => {
		if (mediaDevice !== 'large_screens' && isOpen) {
			setIsOpen(false);
		}
		// eslint-disable-next-line
	}, [mediaDevice]);

	const showUserProfileImage = useMemo(() => {
		return {
			...(image && { url: image }),
			...(!image && { fileName: 'AvatarDefault-Light.svg' }),
			className: 'user_avatar',
		};
	}, [image]);
	// this will render the bottom alligned  at bottom
	const mapProfileTabs = useMemo(
		() => (
			<div className={`${isOpen ? 'bottom-nav' : 'bottom_collapse_tabs'}`}>

				<div className="bottom-nav__nav-items">
					{SIDEBAR_ACCOUNT_TABS.tabs.map(
						({ ICON, TAB_INDEX, TITLE, isWebcomponentShow }) => {
							if (!isWebcomponentShow && isWebComponentOpen) {
								return;
							}
							return (
								<div
									className={`sidebar__item sidebar__item${
										!isOpen ? '--center' : ''
									}`}
									onClick={() => {
										if (TAB_INDEX === 3) handleChangeTab('User Profile');
										if (TAB_INDEX === 4) setPopupOpen(true);
										if (TAB_INDEX === 5) handleChangeTab('Logout');
									}}
									key={`${TAB_INDEX}-${TITLE}`}
								>
									{TITLE === 'User Profile' ? (
										<div
											id="user-profile-image"
											className="user-profile-image-wrapper"
										>
											<Image
												{...showUserProfileImage}
												className={
													isOpen
														? 'sidebar--user-profile-image'
														: 'sidebar--user-profile-image__close'
												}
											/>
											{!isOpen && (
												<ReactTooltip
													anchorId="user-profile-image"
													place="right"
													content={TITLE}
													style={{ zIndex: 1000, color: '#ffffff' }}
												/>
											)}
										</div>
									) : (
										<i className={`sidebar__icon ${ICON}`} id={ICON} />
									)}
									{!isOpen && (
										<ReactTooltip
											anchorId={ICON}
											place="right"
											content={TITLE}
											style={{ zIndex: 1000, color: '#ffffff' }}
										/>
									)}
									<div className={`sidebar__text ${!isOpen && 'd-none'} `}>
										{TITLE}
									</div>
								</div>
							);
						}
					)}
				</div>
			</div>
		),
		[
			handleChangeTab,
			isOpen,
			setPopupOpen,
			showUserProfileImage,
			isWebComponentOpen,
		]
	);

	// this will manage the click event on the logo
	const handleLogoClick = useCallback(
		(path: string) => {
			navigate(path);
		},
		[navigate]
	);

	const handleOpen = useCallback(() => {
		setIsOpen(pre => !pre);
		// eslint-disable-next-line
	}, []);

	const webComponentImage = useMemo(() => {
		return (
			<img
				src={
					(!isOpen ? clientlogosmall : clientlogo) ||
					companyDetails.logo ||
					BRAND_LOGO
				}
				alt="logo"
				className="sidebar-container__companyLogo"
			/>
		);
	}, [clientlogo, clientlogosmall, companyDetails.logo, isOpen]);

	const logoUrl = useMemo(() => {
		if (clientlogo?.length || clientlogosmall?.length) {
			return !isOpen ? clientlogosmall : clientlogo;
		} else return !isOpen ? BRAND_LOGO : LOGO.LIGHT;
	}, [clientlogo, clientlogosmall, isOpen]);

	const renderBrandLogo = useMemo(() => {
		if (!brandLoaded) {
			return <Loader type="loader" dimension={20} className="loader-blue" />;
		}

		if (webAdminType === 'onboarded' || isWebComponentOpen || whitelabel) {
			return webComponentImage;
		} else {
			return (
				<img
					src={logoUrl}
					alt="companylogo"
					className="sidebar-container__brandLogo__img"
				/>
			);
		}
	}, [
		brandLoaded,
		isWebComponentOpen,
		logoUrl,
		webAdminType,
		webComponentImage,
		whitelabel,
	]);

	return (
		<div className="Sidebar--container__main">
			{isShowSidebar && (
				<div
					className={`sidebar-container ${
						isOpen ? 'open-sidebar' : 'collapse-sidebar'
					}`}
					style={{ width: isOpen ? '242px' : '72px' }}
				>
					<div
						onClick={() => handleLogoClick(ROUTES.DASHBOARD)}
						className="sidebar-container__brandLogo"
					>
						{renderBrandLogo}
					</div>
					<div className="side-Container">
						<div className="outside-sidebar">
							<div className="logo-details" onClick={handleOpen}>
								<i
									className={`${
										isOpen
											? 'ri-arrow-left-s-line left-accor'
											: 'ri-arrow-right-s-line right-accor'
									}`}
								/>
							</div>
							<div
								className={`sidebar-tabs-container sidebar-tabs-container${
									!bannerStatus ? '--banner-active' : ''
								}`}
							>
								<div className="sidebar">
									{isPermissionLoading ? <SidebarSkeletonLoading /> : mapRender}
								</div>
								{webAdminType !== 'onboarded' && (
									<div className="sidebar" ref={ref}>
										{mapProfileTabs}
										{userProfile && <UserProfile />}
									</div>
								)}

								{userLogout && (
									<div className="user-logout-modal">
										<LogoutModal
											isOpen={userLogout}
											handleButton={logout}
											setLogout={setUserLogout}
											loading={loading}
										/>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			)}
			<div className="Sidebar--children">{children}</div>
		</div>
	);
};
