const API_CDN_SCRIPT =
	(window as any)?.__ENV?.REACT_APP_CDN_URL + '/images/scripts' ?? '';

export const LuckySheetLinks = [
	{
		type: 'script',
		link: API_CDN_SCRIPT + '/luckysheet.plugins.js',
	},
	{
		type: 'script',
		link: API_CDN_SCRIPT + '/luckysheet.plugins.umd.js',
	},
	{
		type: 'link',
		link: API_CDN_SCRIPT + '/luckysheet.pluginscss.css',
	},
	{
		type: 'link',
		link: API_CDN_SCRIPT + '/luckysheet.css',
	},
	{
		type: 'link',
		link: API_CDN_SCRIPT + '/luckysheet.plugins.css',
	},
	{
		type: 'link',
		link: API_CDN_SCRIPT + '/luckysheet.iconfont.css',
	},
];
