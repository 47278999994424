import { Loader } from '@storybook';
import {useMemo} from 'react';
import { useRecoilValue } from 'recoil';

import { IsKycLoading } from 'global-stores';
import { Header } from 'views/header';
import { headerNavs } from './constants';
import { GeneralSettings } from './general-settings';
import { Profile } from './profile';
import './settings.scss';

export const Settings = () => {
	// const [activeNav, setActiveNav] = useState<any>(headerNavs[0]);
	const isGeneralLoading = useRecoilValue(IsKycLoading);

	const pageLoading = useMemo(() => {
		return (
			<div className="page-loading">
				<Loader dimension={40} className="loader-blue" />
			</div>
		);
	}, []);

	// const headerNavClass = useCallback(
	// 	(key: string) => {
	// 		return classNames('header_text', {
	// 			header_text__active: activeNav.key === key,
	// 		});
	// 	},
	// 	[activeNav.key]
	// );

	// const handleClickOnNavItme = useCallback(
	// 	(navItem: { title: string; key: string }) => {
	// 		setActiveNav(navItem);
	// 	},
	// 	[]
	// );

	// const renderSettingsHeader = useMemo(() => {
	// 	return headerNavs.map(navItem => {
	// 		return (
	// 			<div
	// 				key={navItem.key}
	// 				className={headerNavClass(navItem.key)}
	// 				onClick={() => handleClickOnNavItme(navItem)}
	// 			>
	// 				{navItem.title}
	// 			</div>
	// 		);
	// 	});
	// 	// eslint-disable-next-line
	// }, [activeNav.key]);

	const renderSettingsBody = useMemo(() => {
		switch (headerNavs[0]?.key) {
			case 'generalSettings':
				return <GeneralSettings />;
			case 'profile':
				return <Profile />;
		}
		return;
	}, []);

	const renderSettings = useMemo(() => {
		if (isGeneralLoading) {
			return pageLoading;
		}

		return (
			<div>
				<Header title="Settings" />
				<div className="admin-settings-page-container">
					{/* Deepak: Commenting as per the discussion with Janvi and Awadhesh, as this was removed in the stage environment, so for now, it's being removed for others as well. */}
					{/* <div className="Settings--header">{renderSettingsHeader}</div> */}
					{renderSettingsBody}
				</div>
			</div>
		);
	}, [isGeneralLoading, pageLoading, renderSettingsBody]);

	return renderSettings;
};

export default Settings;
