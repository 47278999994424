import { Button } from '@storybook';
import Modal from '@storybook/new-modal/modal';
import Tippy from '@tippyjs/react';
import { useCallback, useEffect, useMemo } from 'react';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';

import { API_URL } from 'constant';
import {
	AddCardViewState,
	BannerStatusState,
	PaymentCardMethodsLoadingState,
	loginState,
} from 'global-stores';
import { useFreePlan, useNetwork, useNotification } from 'hooks';
import { DashboardsAtom } from 'views/dashboard-analytics';
import { PERMISSION_SCREEN, useUserRoles } from 'views/routes-children';
import { useDefaultCard } from 'views/user-identity-flow';
import {
	ActivePaymentType,
	AddCardModalState,
	BillingAddressFromState,
	PaymentNavigationState,
} from '../../stores';
import {
	BILLING_PERMISSION_TEXT,
	BillingAddressFrom,
	DefaultCardType,
	NON_LIVE_MODE_ERROR,
	PaymentNavigation,
} from '../constant';
import { SandboxView } from '../sandbox-view';
import { AddPaymentMethod } from '../upgrade';
import { PaymentIdentityHeader } from '../upgrade/components/payment-identity-header';
import { BankCard, Card, WireCard } from './components';

export const PaymentDetails = () => {
	// global states
	const [isAddCardView, setIsAddCardView] = useRecoilState(AddCardViewState);
	const isVerified = useRecoilValue(BannerStatusState);
	const user = useRecoilValue(loginState);
	const [openView, setOpenView] = useRecoilState(AddCardModalState);
	const [addPaymentNaviagtion, setAddPaymentNaviagtion] = useRecoilState(
		PaymentNavigationState
	);
	const paymentLoadingState = useRecoilValue(PaymentCardMethodsLoadingState);
	const billingAddressFrom = useRecoilValue(BillingAddressFromState);

	const resetaddCardModalState = useResetRecoilState(AddCardModalState);
	const restPaymentNavigationState = useResetRecoilState(
		PaymentNavigationState
	);
	const restActivePaymentType = useResetRecoilState(ActivePaymentType);
	const setDashboards = useSetRecoilState(DashboardsAtom);

	// hooks
	const { errorNotification } = useNotification();
	const { isDefaultExist, defaultMethod } = useDefaultCard();
	const { canUpdateBilling } = useFreePlan();
	const timeZoneOffset = new Date().getTimezoneOffset();
	const { get } = useNetwork();

	const { checkUserWritePermission } = useUserRoles();

	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.Billing),
		[checkUserWritePermission]
	);

	const getOnboardingSummary = useCallback(() => {
		get(`${API_URL.ONBOARDED_SUMMARY}?timeZoneOffset=${timeZoneOffset}`).then(
			resp => {
				if (resp && resp.data) {
					setDashboards(prev => ({ ...prev, data: resp.data }));
				}
			}
		);
	}, [get, setDashboards, timeZoneOffset]);

	useEffect(() => {
		getOnboardingSummary();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const methodName = useMemo(() => {
		const data = defaultMethod?.method;
		return data;
	}, [defaultMethod?.method]);

	// this will open the payment detail modal
	const handleView = useCallback(() => {
		if (!isVerified && !user.isVerifiedPhone)
			return errorNotification(NON_LIVE_MODE_ERROR);
		setOpenView({
			isOpen: true,
			isEdit: true,
		});
		switch (methodName) {
			case DefaultCardType.Ach:
				setAddPaymentNaviagtion(PaymentNavigation.AchView);
				break;
			case DefaultCardType.Wire:
				setAddPaymentNaviagtion(PaymentNavigation.WireView);
				break;
			default:
				setAddPaymentNaviagtion(PaymentNavigation.CardView);
				break;
		}
	}, [
		isVerified,
		user.isVerifiedPhone,
		errorNotification,
		methodName,
		setOpenView,
		setAddPaymentNaviagtion,
	]);

	const handleAddPaymentMethod = useCallback(() => {
		setOpenView(prev => ({ ...prev, isOpen: true }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// this will close the modal
	const handleCloseViewModal = useCallback(
		() => {
			setOpenView(prev => ({ ...prev, isOpen: false }));
			if (isAddCardView) {
				setIsAddCardView(false);
			}
			// Reset All State
			setTimeout(() => {
				resetaddCardModalState();
				restPaymentNavigationState();
				restActivePaymentType();
			}, 500);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isAddCardView]
	);

	const renderTitle = useMemo(() => {
		switch (addPaymentNaviagtion) {
			case PaymentNavigation.CardScreen:
				return (
					<PaymentIdentityHeader
						head="Card details"
						desc="Please provide your billing information."
					/>
				);

			case PaymentNavigation.Billing:
				if (billingAddressFrom === BillingAddressFrom.FromBillingInfo) {
					return 'Update Billing Address';
				}
				return (
					<PaymentIdentityHeader
						head="Billing Information"
						desc="Please provide card details below to ensure seamless future transactions."
					/>
				);
			case PaymentNavigation.Success:
				return <></>;
			case PaymentNavigation.Processing:
				return '';
			case PaymentNavigation.WireScreen:
				return (
					<PaymentIdentityHeader
						head="Wire transfer detail"
						desc="Please wire transfer money to the given account details below."
					/>
				);
			case PaymentNavigation.DefaultAchPage:
				return (
					<PaymentIdentityHeader
						head="Choose your default or primary payment method"
						desc="Select the account you'd like to set as your default or primary payment method."
					/>
				);
			case PaymentNavigation.ChoosePage:
				return (
					<PaymentIdentityHeader
						head="Add a payment method"
						desc="Please select your preferred payment method type."
					/>
				);
			case PaymentNavigation.CardView:
				return (
					<PaymentIdentityHeader
						head="Manage payment methods"
						desc="Easily manage your payment methods for seamless transactions."
					/>
				);
			case PaymentNavigation.WireView:
				return (
					<PaymentIdentityHeader
						head="Manage payment methods"
						desc="Easily manage your payment methods for seamless transactions."
					/>
				);
			case PaymentNavigation.AchView:
				return (
					<PaymentIdentityHeader
						head="Manage payment methods"
						desc="Easily manage your payment methods for seamless transactions."
					/>
				);
			default:
				return <></>;
		}
	}, [addPaymentNaviagtion, billingAddressFrom]);

	const renderDefaultMethod = useMemo(() => {
		switch (methodName) {
			case DefaultCardType.Ach:
				return (
					<BankCard
						result={defaultMethod?.detail}
						loading={paymentLoadingState}
					/>
				);
			case DefaultCardType.Card:
				return (
					<Card result={defaultMethod?.detail} loading={paymentLoadingState} />
				);
			default:
				return (
					<WireCard
						result={defaultMethod?.detail}
						loading={paymentLoadingState}
					/>
				);
		}
	}, [defaultMethod?.detail, methodName, paymentLoadingState]);

	const isUpdateView = useMemo(
		() =>
			isUserPermissionWrite &&
			!user.sandboxStatus &&
			user.role === 'OWNER' &&
			isDefaultExist &&
			!paymentLoadingState,
		[
			isDefaultExist,
			paymentLoadingState,
			user.role,
			user.sandboxStatus,
			isUserPermissionWrite,
		]
	);

	return (
		<>
			<div className="payment-detail hover">
				<div className="billing-detail-header payment-detail__bottom-border ">
					<div>
						<div className="billing-detail-title">Payment method</div>
						<div className="billing-detail-subtitle">
							{!user.sandboxStatus
								? 'Change how you pay for services'
								: 'Payment method is not available in sandbox mode'}
						</div>
					</div>

					{isUpdateView && (
						<div className="billing-detail-view" onClick={handleView}>
							Update
						</div>
					)}
				</div>
				{!user.sandboxStatus ? (
					<>
						{isDefaultExist ? (
							renderDefaultMethod
						) : (
							<Tippy
								disabled={isUserPermissionWrite}
								content={
									<div className="text-align-center">
										{BILLING_PERMISSION_TEXT.PaymentMethod}
									</div>
								}
							>
								<div className="add-payment-btn">
									<Button
										label="Add a payment method"
										type="add-payment-method"
										handleClick={handleAddPaymentMethod}
										disabled={
											!!defaultMethod?.detail ||
											!isUserPermissionWrite ||
											!canUpdateBilling
										}
									/>
								</div>
							</Tippy>
						)}
					</>
				) : (
					<SandboxView />
				)}
			</div>

			<Modal
				isOpen={openView.isOpen}
				modalName="Add a payment method"
				closeModal={handleCloseViewModal}
				className="payment-method-details"
				showCloseBtn={true}
				title={
					<div className="payment-method-details__header">
						<div className="payment-method-details__title">{renderTitle}</div>
					</div>
				}
			>
				<AddPaymentMethod />
			</Modal>
		</>
	);
};
