import { Button, Loader } from '@storybook';

import { useAch } from '../../hooks';
import { NoCard } from '../no-card';
import { AchListView } from './render-ach-list';

interface IUseAch {
	isSignup?: boolean;
}
export const ACH = ({ isSignup }: IUseAch) => {
	const { initPlaid, isLoadingPlaid, banks = [] } = useAch({ isSignup });

	if (banks.length === 0)
		return (
			<NoCard
				title="No account added"
				buttonLabel="Add an account"
				handleButton={initPlaid}
				buttonLoader={isLoadingPlaid}
				description="Add an account for easy payment"
				illustration="ach-method.svg"
			/>
		);

	return (
		<div className="Ach">
			<div className="Ach--container">
				<div className="Ach--container__header">
					<h2>Accounts</h2>
					<Button
						label="Add new account"
						loader={
							isLoadingPlaid ? (
								<Loader type="loader" className="loader-white" dimension={20} />
							) : (
								<></>
							)
						}
						type="button__filled--primary"
						handleClick={initPlaid}
						disabled={isLoadingPlaid}
					/>
				</div>
				<AchListView />
			</div>
		</div>
	);
};
