export const DEFAULT_CAPTABLE: any = [
	[
		{ value: 'First Name', readOnly: true, className: 'required' },
		{ value: 'Last Name', readOnly: true, className: 'required' },
		{ value: 'Country Code', readOnly: true, className: 'required' },
		{ value: 'Mobile', readOnly: true, className: 'required' },
		{ value: 'Email', readOnly: true, className: 'required' },
	],
	[{ value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }],
	[{ value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }],
	[{ value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }],
	[{ value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }],
	[{ value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }],
];
export const DEFAULT_SMALL_FUND_CAPTABLE: any = [
	[
		{ value: 'First Name', readOnly: true, className: 'required' },
		{ value: 'Last Name', readOnly: true, className: 'required' },
		{ value: 'Country Code', readOnly: true, className: 'required' },
		{ value: 'Mobile', readOnly: true, className: 'required' },
		{ value: 'Email', readOnly: true, className: 'required' },
		{ value: 'Dollars Invested', readOnly: true, className: 'required' },
	],
	[{ value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }],
	[{ value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }],
	[{ value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }],
	[{ value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }],
	[{ value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }],
	[{ value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }],
];

export const DEFAULT_FUND_TABLE = [
	[
		'First Name',
		'Last Name',
		'Country Code',
		'Mobile',
		'Email',
		'Fund Transfer Amount',
	],
	['Alina', 'Trombley', ' 1', '415-678-****', 'alina@youremail.com', '100'],
];

export const DEFAULT_FUND_OUT_TABLE = [
	[
		'First Name',
		'Last Name',
		'Country Code',
		'Mobile',
		'Email',
		'Amount To Pay',
	],
	['Alina', 'Trombley', ' 1', '415-678-****', 'alina@youremail.com', '100'],
];

export const DEFAULT_FUND_TRANSFER_TABLE = [
	[
		'First Name',
		'Last Name',
		'Country Code',
		'Mobile',
		'Email',
		'Fund Transfer Amount',
	],
	['Alina', 'Trombley', ' 1', '415-678-****', 'alina@youremail.com', '100'],
];

export const DEFAULT_TABLE = [
	['First Name', 'Last Name', 'Country Code', 'Mobile', 'Email'],
	['Alina', 'Trombley', '1', '415-678-****', 'alina@youremail.com'],
];

export const FUND_MAP_CAPTABLE_ROW = [
	// we will use it later
	// {
	//   label: "Number of Shares",
	//   isOptional: false,
	//   isRequired: true,
	//   inputType: "select",
	// },
	// {
	//   label: "Price per Share",
	//   isOptional: false,
	//   isRequired: true,
	//   inputType: "select",
	// },
	{
		label: 'First Name',
		isOptional: false,
		isRequired: true,
		inputType: 'select',
	},
	{
		label: 'Last Name',
		isOptional: false,
		isRequired: true,
		inputType: 'select',
	},
	{
		label: 'Country Code',
		isOptional: false,
		isRequired: true,
		inputType: 'select',
	},
	{
		label: 'Mobile',
		isOptional: false,
		isRequired: true,
		inputType: 'select',
	},
	{
		label: 'Email',
		isOptional: false,
		isRequired: true,
		inputType: 'select',
	},
	{
		label: 'Dollars Invested',
		isOptional: false,
		isRequired: true,
		inputType: 'select',
	},
];

export const DEFAULT_MAP_CAPTABLE_ROW = [
	{
		label: 'First Name',
		isOptional: false,
		isRequired: true,
		inputType: 'select',
	},
	{
		label: 'Last Name',
		isOptional: false,
		isRequired: true,
		inputType: 'select',
	},
	{
		label: 'Country Code',
		isOptional: false,
		isRequired: true,
		inputType: 'select',
	},
	{
		label: 'Mobile',
		isOptional: false,
		isRequired: true,
		inputType: 'select',
	},
	{
		label: 'Email',
		isOptional: false,
		isRequired: true,
		inputType: 'select',
	},
];

export const FUND_DEFAULT_CAPTABLE = [
	[
		{ value: 'Investor First Name', readOnly: true, className: 'required' },
		{ value: 'Investor Last Name', readOnly: true, className: 'required' },
		{ value: 'Investor Entity', readOnly: true, className: 'required' },
		{ value: 'Investor Address', readOnly: true, className: 'required' },
		{ value: 'Investor Country Code', readOnly: true, className: 'required' },
		{ value: 'Investor Mobile', readOnly: true, className: 'required' },
		{ value: 'Investors Email', readOnly: true, className: 'required' },
		{ value: 'Number of Shares', readOnly: true, className: 'required' },
		{ value: 'Price per Share', readOnly: true, className: 'required' },
		{ value: 'Dollars Invested', readOnly: true, className: 'required' },
		{ value: 'Share Class', readOnly: true, className: 'required' },
		{ value: 'Issue Date', readOnly: true, className: 'required' },
		{ value: 'Funding Round', readOnly: true, className: 'required' },
	],
	[
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
	],
	[
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
	],
	[
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
	],
	[
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
	],
	[
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
	],
];

export const ACCREDITATION_DEFAULT_CAPTABLE = [
	[
		{ value: 'First Name', readOnly: true, className: 'required' },
		{ value: 'Last Name', readOnly: true, className: 'required' },
		{ value: 'Entity', readOnly: true, className: 'required' },
		{ value: 'Address', readOnly: true, className: 'required' },
		{ value: 'Country Code', readOnly: true, className: 'required' },
		{ value: 'Mobile', readOnly: true, className: 'required' },
		{ value: 'Email', readOnly: true, className: 'required' },
		{ value: 'Number of Shares', readOnly: true },
		{ value: 'Price per Share', readOnly: true },
		{ value: 'Dollars Invested', readOnly: true },
		{ value: 'Share Class', readOnly: true },
		{ value: 'Issue Date', readOnly: true },
		{ value: 'Funding Round', readOnly: true },
	],
	[
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
	],
	[
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
	],
	[
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
	],
	[
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
	],
	[
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
		{ value: '' },
	],
];

export const CHANGE_ROW_LABLE: any = {
	'Dollars Invested': 'Fund Transfer Amount',
};

export const CHANGE_ROW_LABLE_FUND_OUT: any = {
	'Dollars Invested': 'Amount To Pay',
};
