import type { IComplexFlowUnconfiged } from 'views/complex-onboarding-flow/stores/states';
import type { IQuestionnaireChoices } from '../questionnaire-choices';

import { IOption } from '@storybook';
import { useCallback, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { PipelineTemplateState } from 'global-stores';
import { QuestionnaireStep } from 'views/complex-onboarding-flow/constant';
import {
	ComplexDynamicFormPathsState,
	ComplexDynamicFormStatusState,
	ComplexFlowUnconfigedState,
	EdgesState,
	NodesState,
} from 'views/complex-onboarding-flow/stores/states';
import { ComplexSettingFormState } from 'views/pipeline';

export const useQustionnaireForm = ({
	handleAdd,
	nodeId,
}: IQuestionnaireChoices) => {
	const [active, setActive] = useState(QuestionnaireStep.New);
	const [selectedForm, setSelectedForm] = useState('');

	const templateInit = useRecoilValue(PipelineTemplateState);
	const [complexSettingForm, setComplexSettingForm] = useRecoilState(
		ComplexSettingFormState
	);
	const setComplexFlowConfiged = useSetRecoilState(ComplexFlowUnconfigedState);
	const setNodes = useSetRecoilState(NodesState);
	const setEdges = useSetRecoilState(EdgesState);
	const setPaths = useSetRecoilState(ComplexDynamicFormPathsState);
	const setStatus = useSetRecoilState(ComplexDynamicFormStatusState);

	const resetQuestionnaireStates = useCallback(() => {
		setNodes((prev: any) => {
			const prevObj = JSON.parse(JSON.stringify(prev));
			const findIndex = prevObj.findIndex((el: any) => el.id === nodeId);
			if (findIndex !== -1) {
				prevObj[findIndex].conditions = [];
				return prevObj;
			}
			return prevObj;
		});
		setEdges((prev: any) => {
			const prevObj = JSON.parse(JSON.stringify(prev));
			const data = prevObj.filter(
				(el: any) => !(el.source === nodeId && el.sourceHandle !== nodeId)
			);
			return data;
		});
		setPaths(pre => {
			const prevObj = JSON.parse(JSON.stringify(pre));
			Object.keys(prevObj)?.forEach((pathKey: string) => {
				if (pathKey.startsWith(nodeId)) delete prevObj[pathKey];
			});
			return prevObj;
		});
		setStatus(pre => {
			const prevObj = JSON.parse(JSON.stringify(pre));
			Object.keys(prevObj)?.forEach((statusKey: string) => {
				if (statusKey.startsWith(nodeId)) delete prevObj[statusKey];
			});
			return prevObj;
		});
	}, [nodeId, setEdges, setNodes, setPaths, setStatus]);

	const onSelectActive = (val: string) => () => {
		setActive(val);
		if (val === QuestionnaireStep.New) {
			resetQuestionnaireStates();
			setSelectedForm('');
			handleAdd();
		}
	};

	const existFormData = useMemo(() => {
		if (complexSettingForm && complexSettingForm[nodeId]) {
			return complexSettingForm[nodeId];
		}
	}, [complexSettingForm, nodeId]);

	const tempOptions = useMemo(
		() =>
			(templateInit ?? [])?.map(item => ({
				label: item.name,
				value: item.name,
			})),
		[templateInit]
	);

	const getDefaultValue = (): IOption => {
		if (complexSettingForm[nodeId]?._id) {
			const { name = '' } =
				templateInit?.find(
					item => item._id === complexSettingForm[nodeId]?._id
				) ?? {};
			return {
				label: name,
				value: name,
			};
		}
		return {
			label: 'Select existing questionnaire template',
			value: '',
		};
	};

	const handleOperatorChangeSelect = (e: IOption) => {
		const questionnaire = (templateInit ?? []).find(el => el.name === e.value);
		setComplexSettingForm((prev: any) => {
			const newObj = structuredClone(prev);
			if (!newObj[nodeId as string]) {
				newObj[nodeId as string] = {};
			}
			Object.assign(newObj[nodeId as string], {
				...questionnaire?.data,
				_id: questionnaire?._id,
			});
			//Shahbaaz Set key for form complex config setting form for showing price breakdown
			Object.assign(newObj[nodeId as string], {
				key: 'formAction',
			});

			return newObj;
		});
		const complexNodes: IComplexFlowUnconfiged[] = [];
		setComplexFlowConfiged((prev: any) => {
			const newArr = structuredClone(prev);
			return newArr.map((el: any) => {
				if (el.id === nodeId) {
					const value = {
						...el,
						configed: true,
					};
					complexNodes.push(value);
					return value;
				} else {
					complexNodes.push(el);
					return el;
				}
			});
		});
		if (e.value !== selectedForm) {
			resetQuestionnaireStates();
		}
		setSelectedForm(e.value);
	};
	return {
		handleOperatorChangeSelect,
		tempOptions,
		existFormData,
		onSelectActive,
		selectedForm,
		active,
		setActive,
		getDefaultValue,
	};
};
