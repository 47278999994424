import { FC } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Tippy from '@tippyjs/react';
import { Button, Image } from '@storybook';

import { AssinedUserState, InviteDetailsState, InviteModal } from 'global-stores';

import './no-user-assigned.scss';

interface INoUserAssined {
	isUserPermissionWrite?: boolean;
}

export const NoUserAssined: FC<INoUserAssined> = ({
	isUserPermissionWrite = true,
}) => {
	const setIsOpenInviteUserModel = useSetRecoilState(InviteModal);
	const setInvites = useSetRecoilState(InviteDetailsState);
	const selectedUserRole = useRecoilValue(AssinedUserState);

	const handleInviteUser = () => {
		setIsOpenInviteUserModel(true);
		setInvites(prev => {
			const prevState = JSON.parse(JSON.stringify(prev));
			prevState['role'] = selectedUserRole?._id ?? '';
			return { ...prevState };
		});
	};
	return (
		<div className="NoUserAssined--container">
			<div className="NoUserAssined--container__wrapper">
				<div className="NoUserAssined--container__wrapper--img">
					<Image fileName="noUser.svg" />
				</div>
				<div className="NoUserAssined--container__wrapper--text">
					<div className="NoUserAssined--container__wrapper--text__heading">
						No user assigned
					</div>
					<div className="NoUserAssined--container__wrapper--text__subHeading">
						Assign roles to user to manage their permission
					</div>
				</div>
				<Tippy
					disabled={isUserPermissionWrite}
					content="You are not permitted to invite user."
				>
					<div className="NoUserAssined--container__wrapper--btn">
						<Button
							label="Invite user"
							type="button__filled--primary"
							handleClick={handleInviteUser}
							disabled={!isUserPermissionWrite}
						/>
					</div>
				</Tippy>
			</div>
		</div>
	);
};
