import { FC, ReactNode, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { PipelineMasterSteps } from 'global-stores';
import { ActionList } from 'views/complex-onboarding-flow/constant';
import { ComplexSettingFormState } from 'views/pipeline';
import { CustomHandle } from '../custom-handle';
import { ICustomNode } from '../custom-node';
import { RenderFundBlock } from './render-fund-block';

export const FundsWrapper = ({ children }: { children: ReactNode }) => {
	return <div className="kyb-action">{children}</div>;
};

export const FundAction: FC<ICustomNode> = ({ id: nodeId, data }) => {
	const complexSettingForm = useRecoilValue(ComplexSettingFormState);

	const selectDefaultLabel = useMemo(
		() => complexSettingForm?.[nodeId],
		[complexSettingForm, nodeId]
	);

	const label = useMemo(() => {
		if (selectDefaultLabel?.payInPayOut === 'payIn')
			return 'Pay In (Receive funds)';
		else if (selectDefaultLabel?.payInPayOut === 'payOut')
			return 'Pay Out (Send funds)';
		else return 'Pay In (Receive funds)';
	}, [selectDefaultLabel]);

	const pipelinesSteps = useRecoilValue(PipelineMasterSteps);

	const actionId = useMemo(() => data.label, [data.label]);

	const actions = useMemo(
		() =>
			pipelinesSteps.find(
				pipelineStep => pipelineStep.key === (ActionList as any)[actionId]
			)?.actions[0],
		[actionId, pipelinesSteps]
	);

	return (
		<FundsWrapper>
			<RenderFundBlock label={label} price={actions?.price || 0} />
			<CustomHandle value={nodeId} />
		</FundsWrapper>
	);
};
