import { SuccessIcon } from 'components';

import './upload-docs-success.scss';
import { useMemo } from 'react';

interface UploadDocumentSuccessProps {
	fileCount: number;
}

export const UploadDocumentSuccess: React.FC<UploadDocumentSuccessProps> = ({
	fileCount,
}) => {
	const document = useMemo(
		() => (fileCount === 1 ? 'Document' : 'Documents'),
		[fileCount]
	);
	return (
		<div className="upload-docs-success">
			<SuccessIcon />
			<div className="upload-docs-success_wrapper">
				<div className="upload-docs-success_title">
					{`${document} submitted successfully`}
				</div>
				<div className="upload-docs-success_description">
					{`Your ${document.toLowerCase()} has been successfully submitted.`}
				</div>
			</div>
			{/* <Button
				label="Close"
				type="button__filled button__filled--secondary"
				handleClick={handleClose}
			/> */}
		</div>
	);
};
