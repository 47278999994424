import { FC } from 'react';

import { KYB_INFO_HEADER } from '../../hooks';
interface IKybInfoHeader {
	type: string;
	show?:boolean
}

export const KybInfoHeader: FC<IKybInfoHeader> = ({ type, show = true }) => {
	if(!show) {
		return <></>
	}
	return (
		<div className="kyb-wrapper__details__kybInfo-header">
			<div className="kyb-wrapper__details__kybInfo-header__title">
				{KYB_INFO_HEADER[type] ?? type}
			</div>
			<div className="kyb-wrapper__details__kybInfo-header__sub-title" />
		</div>
	);
};
