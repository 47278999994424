import { Button, Loader } from '@storybook';
import { CaptableGrid } from '@storybook/custom-captable';
import Modal from '@storybook/new-modal/modal';
import { useEditCaptable } from '../hooks';

interface IProps {
	isModalOpen: boolean;
	closeAddInvestorModal: () => void;
	investerIds: string[];
	fundId: string;
}
export const EditFunds = ({
	closeAddInvestorModal,
	isModalOpen,
	investerIds,
	fundId,
}: IProps) => {
	const { handleEditInvestor, loading } = useEditCaptable(
		investerIds,
		fundId,
		closeAddInvestorModal
	);

	return (
		<div>
			<Modal
				isOpen={isModalOpen}
				closeModal={closeAddInvestorModal}
				className="react-modal_IFunds_Add_Investor"
				modalName="Session modal"
			>
				<div className="user-roles--header">Edit Investor</div>

				<div className="luckysheet-wrapper">
					<CaptableGrid isInvestor={false} />
				</div>
				<div className="ConfigureModal--footer">
					<Button
						label="Cancel"
						handleClick={closeAddInvestorModal}
						type="button__filled button__filled--secondary"
					/>
					<Button
						label={
							!loading ? (
								'Save'
							) : (
								<Loader type="loader" dimension={20} className="loader-white" />
							)
						}
						handleClick={handleEditInvestor}
						type="button__filled button__filled--primary"
						disabled={loading}
					/>
				</div>
			</Modal>
		</div>
	);
};
