
import { IComplexSettingFormState, InodeStateData, IPage, IPageElement, IShareholder } from './types';

import { FC, useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { DEFAULT_COMPLIANCE_SESSION_THRESHOLD, DEFAULT_MIN_TOTAL_SHARE_OWNED, IVESTOR_DETAILS_STRING, SHAREHOLDER_HEADINGS } from './constants';
import ShareholderThresholdItem from './shareholder-threshold-item'
import { ComplexSettingFormState } from 'views/pipeline';
import { NodesState } from 'views/complex-onboarding-flow/stores/states';

export const ShareholderThreshold: FC<IShareholder> = ({ nodeId }) => {
  const setComplexSettingFormState = useSetRecoilState(ComplexSettingFormState);
  const setNodeState = useSetRecoilState(NodesState);

  const updateComplexSettingFormState = useCallback((value: number, key: string) => {
   
    setComplexSettingFormState((complexSettingFormState: IComplexSettingFormState)=>{
      if(nodeId && complexSettingFormState) {
        const complexSettingState = structuredClone(complexSettingFormState);
        if(nodeId && complexSettingState && complexSettingState[nodeId]) {
          const allPages = complexSettingState[nodeId]?.pages;
          allPages?.forEach((page: IPage) => {
            page?.elements?.forEach((element: IPageElement) => {
              if(element.type === IVESTOR_DETAILS_STRING && element){
                element[key] = value;
              }
            })
          });
        }
        return complexSettingState;
      }
      return complexSettingFormState;
    });
    setNodeState((nodeStateData: InodeStateData[])=>{
      if(nodeId && nodeStateData) {
        const nodeStateCopied = structuredClone(nodeStateData)
        const currentNode = nodeStateCopied.find((item: any) => item.id === nodeId);
        if(currentNode && currentNode.formMetaData) {
          const allPages = currentNode.formMetaData.pages;
          allPages?.forEach((page: any) => {
            page.elements?.forEach((element: any) => {
              if(element.type === IVESTOR_DETAILS_STRING){
                element[key] = value;
              }
            })
          });
          return nodeStateCopied;
        }
      }
      return nodeStateData;
    });
  },[nodeId, setComplexSettingFormState, setNodeState])

  const onThresholdValueChange = useCallback((value: number) => {
    updateComplexSettingFormState(value, 'minTotalShareOwned')
  }, [updateComplexSettingFormState]);

  const onComplianceSessionValueChange = useCallback((value: number) => {
    updateComplexSettingFormState(value, 'complianceSessionThreshold')
  }, [updateComplexSettingFormState]);

  

  return (
    <div className='ShareholderThreshold'>
      <div className='ShareholderThreshold--heading'>
        <p>Shareholders details</p>
      </div>
      <div className='ShareholderThreshold--container'>
        <ShareholderThresholdItem
          title={SHAREHOLDER_HEADINGS.MIN_TOTAL_SHARE_OWNED_TITLE}
          subTitle={SHAREHOLDER_HEADINGS.MIN_TOTAL_SHARE_OWNED_SUB_TITLE}
          cb={onThresholdValueChange}
          defaultValue={DEFAULT_MIN_TOTAL_SHARE_OWNED}
          />
        <ShareholderThresholdItem
          title={SHAREHOLDER_HEADINGS.COMPLIANCE_SESSION_TITLE}
          subTitle={SHAREHOLDER_HEADINGS.COMPLIANCE_SESSION_SUB_TITLE}
          cb={onComplianceSessionValueChange}
          defaultValue={DEFAULT_COMPLIANCE_SESSION_THRESHOLD}
          />
      </div>
    </div>
  )
}

