import { Fragment, useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { Loader } from '@storybook';
import {
	BillingSummary,
	SignatureProvider,
	useGetInviteOnboardingPayment,
} from '../../store';
import { useSandboxAndFreeTrial } from 'hooks';
import { useOnboardingHook } from 'views/onboarding-flow';

import './order-summary.scss';

const stepName: any = {
	kycwithssn: 'SSN Verification',
	kycwithaddressverification: 'Address Verification',
};

export const OrderSummary = () => {
	const { data: summary, isLoaded } = useRecoilValue(BillingSummary);
	const { invitedUsers } = useGetInviteOnboardingPayment();

	const { isSandboxMode } = useSandboxAndFreeTrial();
	const { isPricingHide } = useOnboardingHook();

	const getStepName = useCallback((name: string) => {
		if (/docusign/gi.test(name)) {
			return 'Sign agreement (Docusign)';
		}
		if (/esign|simplici/gi.test(name)) {
			return (
				<div className="summary-item__headers-esign">
					Sign Agreement (esign)
				</div>
			);
		}
		if (/Authentication|simplici/gi.test(name)) {
			return;
		}
		if (/proofReading/gi.test(name)) {
			return 'Document Review';
		}
		if (/kyb_comprehensive/gi.test(name)) {
			return 'KYB Comprehensive';
		}
		if (/business_sanctions_ofac/gi.test(name)) {
			return 'Business Sanctions and OFAC';
		}
		if (/kyb_subsidiary/gi.test(name)) {
			return 'KYB for Subsidiaries';
		}
		if (/representative_kyc_aml_verification/gi.test(name)) {
			return 'Representative KYC & AML Verification';
		}
		if (/kybForm/gi.test(name)) {
			return 'KYB Form';
		}
		return stepName[name?.toLowerCase()] ?? name;
	}, []);

	const renderSummaryItems = useMemo(() => {
		if (!isLoaded) {
			return (
				<div className="loader_container">
					<Loader className="loader-blue" dimension={100} />
				</div>
			);
		}
		return summary.steps.map(({ name, price }) => (
			<Fragment key={name}>
				{price >= 0 && (
					<div className="order-summary__summary__summary-item">
						<div className="summary-item__headers">
							<div className="summary-item__headers__name">
								{getStepName(name)}
								{name === SignatureProvider.fundInvestment &&
									`(${summary?.fund?.type})`}
								{isPricingHide && name !== 'Authentication' && (
									<span className="summary-item__price">
										: ${!isSandboxMode ? Number(price).toFixed(2) : 0}
										/verification
									</span>
								)}
							</div>
						</div>
					</div>
				)}
			</Fragment>
		));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoaded, summary?.fund?.type, summary.steps, isPricingHide]);

	return (
		<div className="order-summary-wrapper">
			<div className="order-summary__summary">
				<div className="order-summary__summary__heading">Order Summary</div>
				<div className="order-summary__summary__summary-wrapper">
					{renderSummaryItems}
				</div>
			</div>
			{isLoaded && (
				<div className="order-summary__have-to-pay-wrapper">
					<div className="have-to-pay__messege summary-item__no_verification">
						No. of verifications{' '}
						<span className="have-to-pay__price">x{invitedUsers}</span>
					</div>
					{isPricingHide && (
						<div className="order-summary__have-to-pay">
							<div className="have-to-pay__icon-wrapper">
								<i className="ri-newspaper-fill" />
								<div className="have-to-pay__messege">You have to pay</div>
							</div>
							<div className="have-to-pay__text-wrapper">
								<div className="have-to-pay__price">
									{isSandboxMode
										? 'FREE'
										: `$ ${(summary.totalPrice * invitedUsers).toFixed(2)}`}
								</div>
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
};
