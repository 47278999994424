import { FC } from "react";

// Props interface for DetailRow
interface DetailRowProps {
	label: string; // The label text for the row
	value: string; // The value text for the row
}

/**
 * Component to render a single detail row with a label and value.
 */
export const DetailRow: FC<DetailRowProps> = ({ label, value }) => (
	<div className="kycDetail-row">
		<div className="report-label">{label}</div>
		<div>:</div>
		<p>{value || "--"}</p>
	</div>
);
