import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { Image, Loader } from '@storybook';
import Modal from '@storybook/new-modal/modal';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { REACT_APP_GOOGLE_MAP_SECRET_TOKEN as TOKEN } from 'envs';
import {
	formatNumberWithCommasAndCurrencySignSign,
	getDate,
	getTime,
} from 'utils';
import { SelectedChequeFraudTransactionsListState } from 'views/cheque-fraud/stores';
import { MapComponent } from 'views/sessions';
import './transactions.scss';

interface ICheckFraudTransactions {
	className?: string;
	type: 'fraud' | 'all';
}
export const CheckFraudTransactions = ({
	className,
	type,
}: ICheckFraudTransactions) => {
	//cftw stands for check fraud transaction wrapper;
	const [showImage, setShowImage] = useState(false);
	const [selectedImage, setSelectedImage] = useState('');

	const transactionLists = useRecoilValue(
		SelectedChequeFraudTransactionsListState
	);

	const lists = transactionLists?.filter(el =>
		type === 'fraud' ? el.isFraud : el
	);

	const mapRender = (status: Status): ReactElement => {
		if (status === Status.FAILURE) return <></>;
		return <Loader />;
	};

	const handleImage = (image: string) => {
		setShowImage(true);
		setSelectedImage(image);
	};
	const handleCloseModal = useCallback(() => {
		setShowImage(false);
		setSelectedImage('');
	}, []);

	const renderComponenet = useMemo(() => {
		return lists?.map(el => {
			if (!el) {
				return <></>;
			}
			const {
				fraudMessage,
				potentialRisk,
				cashierName,
				deviceId,
				geoLocation = {},
			} = el.fraudDetails ?? {};

			const {
				phone,
				countryCode,
				email = 'N/A',
				deviceId: userDeviceId = 'N/A',
			} = el.userInfo ?? {};
			const { latitude, longitude } = geoLocation;
			return (
				<div className={`cftw ${className}`} key={el._id}>
					<div className="cftw__inner">
						<div className="cftw__inner__header">
							<div className="cftw__inner__header__left">
								<div
									className={`cftw__inner__header${!el.isFraud ? '__success_icon' : '__error_icon'
										}`}
								>
									{!el.isFraud ? (
										<i className="ri-money-dollar-box-line"></i>
									) : (
										<i className="ri-error-warning-fill"></i>
									)}
								</div>
								<div>
									<div className="cftw__inner__header__label">
										{formatNumberWithCommasAndCurrencySignSign(
											el.amount ?? 0,
											'$'
										)}
									</div>
									<div className="cftw__inner__header__sublabel">
										{el.checkNumber
											? `Check(${el.checkNumber ?? 'N/A'})`
											: 'N/A'}
									</div>
								</div>
							</div>
							<div>
								<div className="cftw__inner__header__sublabel ">
									<div className="cftw__inner__header__time-date ">
										<div className="subTd">{getTime(el.createdAt)}</div>
										<span>|</span>
										<div>{getDate(el.createdAt)}</div>
									</div>
								</div>
								<div className="cftw__inner__header__sublabel">
									<div className="cftw__inner__header__transaction">
										<span>Transaction no.</span>
										<span> {el.transactionId ?? 'N/A'}</span>
									</div>
								</div>
							</div>
						</div>

						<div className="cftw__inner__body">
							<div className="cftw__inner__success-body">
								{el.path && (
									<div className="cftw__inner__body__row">
										<div className="cftw__inner__body__row__label">
											<div className="cftw__inner__body__row__key">
												Check image
											</div>
										</div>
										<div className="cftw__inner__body__row__value-wrapper">
											<div>:</div>
											<div className="cftw__inner__body__row__value">
												<div
													className="view-image-btn"
													onClick={() => handleImage(el.path)}
												>
													<div className="view-image-btn__info-link">
														<i className="ri-eye-line"></i>
														<span>View </span>
													</div>
												</div>
											</div>
										</div>
									</div>
								)}

								<div className="cftw__inner__body__row">
									<div className="cftw__inner__body__row__label">
										<div className="cftw__inner__body__row__key">Bank</div>
									</div>
									<div className="cftw__inner__body__row__value-wrapper">
										<div>:</div>
										<div className="cftw__inner__body__row__value">
											{el.bankBranch?.name ?? 'N/A'}
										</div>
									</div>
								</div>

								<div className="cftw__inner__body__row">
									<div className="cftw__inner__body__row__label">
										<div className="cftw__inner__body__row__key">Cashier</div>
									</div>
									<div className="cftw__inner__body__row__value-wrapper">
										<div>:</div>
										<div className="cftw__inner__body__row__value">
											{el.bankBranch?.cashierName ?? 'N/A'}
										</div>
									</div>
								</div>

								<div className="cftw__inner__body__row">
									<div className="cftw__inner__body__row__label">
										<div className="cftw__inner__body__row__key">
											Customer’s email
										</div>
									</div>
									<div className="cftw__inner__body__row__value-wrapper">
										<div>:</div>
										<div className="cftw__inner__body__row__value lower_case">
											{email}
										</div>
									</div>
								</div>

								<div className="cftw__inner__body__row">
									<div className="cftw__inner__body__row__label">
										<div className="cftw__inner__body__row__key">
											Customer’s phone number
										</div>
									</div>
									<div className="cftw__inner__body__row__value-wrapper">
										<div>:</div>
										<div className="cftw__inner__body__row__value">
											{phone && countryCode ? countryCode + phone : 'N/A'}
										</div>
									</div>
								</div>

								<div className="cftw__inner__body__row">
									<div className="cftw__inner__body__row__label">
										<div className="cftw__inner__body__row__key">
											Customer’s device ID
										</div>
									</div>
									<div className="cftw__inner__body__row__value-wrapper">
										<div>:</div>
										<div className="cftw__inner__body__row__value">
											{userDeviceId}
										</div>
									</div>
								</div>
							</div>
							{/* error Started */}

							{el.isFraud && (
								<div className="cftw__inner__error-body">
									<div>
										<div className="cftw__inner__body__row">
											<div className="cftw__inner__body__row__label">
												<div className="cftw__inner__body__row__key">
													Marked Fraud By
												</div>
											</div>
											<div className="cftw__inner__body__row__value-wrapper">
												<div>:</div>
												<div className="cftw__inner__body__row__value">
													{cashierName ?? 'N/A'}
												</div>
											</div>
										</div>
										<div className="cftw__inner__body__row">
											<div className="cftw__inner__body__row__label">
												<div className="cftw__inner__body__row__key">
													Fraud Matched
												</div>
											</div>
											<div className="cftw__inner__body__row__value-wrapper">
												<div>:</div>
												<ul className="cftw__inner__lists">
													{potentialRisk?.map((el: string, index: number) => {
														return (
															<li
																key={`${el}_${index.toString()}`}
																className="cftw__inner__body__row__value"
															>
																{el}
															</li>
														);
													})}
												</ul>
											</div>
										</div>

										<div className="cftw__inner__body__row">
											<div className="cftw__inner__body__row__label">
												<div className="cftw__inner__body__row__key">
													Device ID
												</div>
											</div>
											<div className="cftw__inner__body__row__value-wrapper">
												<div>:</div>
												<div className="cftw__inner__body__row__value">
													{deviceId ? deviceId : 'N/A'}
												</div>
											</div>
										</div>

										<div className="cftw__inner__body__row">
											<div className="cftw__inner__body__row__label">
												<div className="cftw__inner__body__row__key">Note</div>
											</div>
											<div className="cftw__inner__body__row__value-wrapper">
												<div>:</div>
												<div className="cftw__inner__body__row__value__note">
													{fraudMessage ? fraudMessage : 'N/A'}
												</div>
											</div>
										</div>

										{latitude && longitude && (
											<div className="map-container-body">
												<Wrapper apiKey={TOKEN} render={mapRender}>
													<MapComponent
														center={{ lat: latitude, lng: longitude }}
														zoom={13}
														showCircle
														showMarker={false}
														radius={1600}
													/>
												</Wrapper>
											</div>
										)}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lists]);

	if (lists.length === 0) {
		return (
			<div className="empty-transaction-wrapper">
				<div className="kycDetails-noDataFound">
					<Image fileName="AddComment.svg" />
					<div>No data available!</div>
				</div>
			</div>
		);
	}

	return (
		<>
			{renderComponenet}

			<Modal
				isOpen={showImage && !!selectedImage}
				modalName={''}
				className="view-cheque-image"
				closeModal={handleCloseModal}
			>
				<div className="image-wrapper">
					{selectedImage && <img src={selectedImage} />}
				</div>
			</Modal>
		</>
	);
};
