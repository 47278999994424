import { Image, Skeleton } from '@storybook';

export const WireCard = ({ result, loading }: any) => {
	return (
		<div className="BankView">
			{loading ? (
				<Skeleton width={40} height={36} />
			) : (
				<Image
					fileName="default-card.svg"
					className="payment-save-card-list__card-icon"
				/>
			)}
			<div className="payment-card-detail">
				<div className="payment-save-card-list__number">
					{loading ? (
						<Skeleton width={150} height={14} />
					) : (
						`Acc. Number : ${result?.accountNumber ?? '--'}`
					)}
				</div>
				<div className="payment-save-card-list__expire">
					{loading ? (
						<Skeleton width={150} height={14} />
					) : (
						<>
							<span>Routing Number :</span>
							{result?.routingNumber ?? '--'}
						</>
					)}
				</div>
			</div>
		</div>
	);
};
