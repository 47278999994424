import { FC, useState } from 'react';
import { ProgressBar } from '@storybook';

import { useInterval } from 'hooks';

interface ICancelSubscriptionProcessing {
	// The message to display on the progress bar. Defaults to 'Processing your request' if not provided.
	message: string;
}

export const CancelSubscriptionProcessing: FC<ICancelSubscriptionProcessing> = ({ message }) => {
	// State to track the progress percentage of the operation
	const [uploadPercentage, setUploadPercentage] = useState(0);

	// Use the custom hook `useInterval` to simulate progress increment
	useInterval(() => {
		// Increment progress by 2% every 100ms until it reaches 80%
		setUploadPercentage(prev => {
			if (prev < 80) {
				return prev + 2;
			} else {
				return prev; // Stop incrementing once it reaches 80%
			}
		});
	}, 100); // Interval of 100ms

	return (
		<div className="Cancel_Subscription__progress-container">
			<div className="Cancel_Subscription__progress-bar">
				{/* Display the message or default text if no message is provided */}
				<p className="progress-bar__heading">
					{message || 'Processing your request'}
				</p>

				{/* Progress bar component that visually represents the upload percentage */}
				<ProgressBar
					completed={uploadPercentage} // Dynamic completion value
					bgColor="#0051CC" // Custom background color for the progress bar
					height="4px" // Custom height for the progress bar
				/>
			</div>

			{/* Footer message to prevent the user from closing the modal */}
			<p className="Cancel_Subscription__progress-bar__footer">
				Please do not go back or close this modal until processing is done
			</p>
		</div>
	);
};
