import { Image, ReactDropdown, SkeletonTable } from '@storybook';
import Modal from '@storybook/new-modal/modal';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { DatePicker } from 'components/date-picker';
import {
	BillingDetailsLoaderState,
	BusinessRegistrationState,
	SelectedDateRangeState,
} from 'global-stores';
import { ApprovalStatus } from 'views/user-identity-flow';
import { BillingHeaderItemName } from '../constant';
import { useFormatDate, useSummaryBilling } from '../hook';
import { AcountUserTypes } from '../subscriptions/constants';
import { BillingItemDetailsTable } from './billing-item-details';
import { Category, CategoryFooter } from './components';
import { billingDetailState } from '../../stores';

import './billing-details.scss';

const hideBillingModal = false;
export const BillingDetails = () => {
	const { formatDate } = useFormatDate();

	//recoils
	const dateRange = useRecoilValue(SelectedDateRangeState);
	const billingLoaderState = useRecoilValue(BillingDetailsLoaderState);
	const businessRegistrationState = useRecoilValue(BusinessRegistrationState);
	const { accountType, approveStatus } = businessRegistrationState ?? {};
	// local state
	const [openView, setOpenView] = useState(false);
	const [selectedItem, setSelectedItem] = useState();
	const [selectedDropdownItem, setSelectedDropdownItem] = useState([]);

	const { endDate, startDate }: any = dateRange?.[0] ?? {};
	const fromDates = formatDate(startDate);
	const endDates = formatDate(endDate);
	const timeZoneOffset = new Date().getTimezoneOffset();

	const billingDetailData = useRecoilValue(billingDetailState);

	const {
		fetchBillingsDetails,
		showSelectedBillingDetail,
		setShowBillingSelectedDetail,
		findSubAccountBusiness,
		mergeData,
		defaultMainOption,
	} = useSummaryBilling();

	useEffect(() => {
		fetchBillingsDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [endDates, fromDates, timeZoneOffset]);

	useEffect(() => {
		if (defaultMainOption?.length) {
			setSelectedDropdownItem(defaultMainOption as any);
		}
	}, [defaultMainOption]);

	const handleOnClickBillingDetails = useCallback((name: any) => {
		if (name) {
			setOpenView(true);
			setSelectedItem(name);
		}
	}, []);

	const mangeBillingClass = useMemo(() => {
		switch (accountType) {
			case AcountUserTypes.Entity:
				if (approveStatus === ApprovalStatus.Pending) {
					return 'billing-detail__demo';
				} else return 'billing-detail__entity';
			case AcountUserTypes.Person:
				return 'billing-detail__person';
			default:
				return 'billing-detail__demo';
		}
	}, [accountType, approveStatus]);

	const manageTableContainer = useMemo(() => {
		switch (accountType) {
			case AcountUserTypes.Entity:
				return 'billing-table-container__entity';
			case AcountUserTypes.Person:
				return 'billing-table-container__person';
			default:
				return 'billing-table-container__person';
		}
	}, [accountType]);

	const handleChangeDropdown = useCallback(
		(event: any) => {
			const findDifferences = (
				event > selectedDropdownItem ? event : selectedDropdownItem
			).filter(
				(item2: any) =>
					!(event > selectedDropdownItem ? selectedDropdownItem : event).some(
						(item1: any) => item1.value === item2.value
					)
			);

			if (event > selectedDropdownItem) {
				setShowBillingSelectedDetail(
					mergeData(
						showSelectedBillingDetail,
						findSubAccountBusiness(findDifferences[0].value),
						'merge'
					)
				);
			} else {
				setShowBillingSelectedDetail(
					mergeData(
						showSelectedBillingDetail,
						findSubAccountBusiness(findDifferences[0].value),
						'remove'
					)
				);
			}
			setSelectedDropdownItem(event);
		},
		[
			findSubAccountBusiness,
			mergeData,
			selectedDropdownItem,
			setShowBillingSelectedDetail,
			showSelectedBillingDetail,
		]
	);

	const accountOptions = useMemo(() => {
		const options = billingDetailData.map(item => {
			if(!item?.name) return {}
			return {
				label: (
					<div className="account-dropdown-wrapper">
						<div className="account-dropdown-wrapper__label">{item.name}</div>
						{item.isDeleted && (
							<div className="account-dropdown-wrapper__delete">Deleted</div>
						)}
					</div>
				),
				value: item.businessId,
			};
		});
		return options;
	}, [billingDetailData]);

	return (
		<Fragment>
			<div className={`${mangeBillingClass} billing-detail hover`}>
				{billingLoaderState ? (
					<>
						<div className="billing-detail-header">
							<div>
								<div className="billing-detail-title billing--title">
									Usage Summary
								</div>

								<div className="Billing--Info__subtitle">
									Manage your subscription from here
								</div>
							</div>
							<div className="billing-details-date-picker">
								<DatePicker />
							</div>
						</div>
						<div className="billing-detail-loader">
							<SkeletonTable listsToRender={8} numberColumn={4} />
						</div>
					</>
				) : (
					<>
						{Object.keys(showSelectedBillingDetail?.services ?? {}).length ===
						0 ? (
							<>
								<div className="billing-detail-header">
									<div>
										<div className="billing-detail-title billing--title">
											Accounts Overview
										</div>

										<div className="Billing--Info__subtitle">
											View and manage transactions, invoices, and usage for
											specific account or all accounts
										</div>
									</div>
								</div>

								<div className="user-sumary--empty-state">
									<Image
										fileName="billing-detail-illustration.svg"
										className="Billing-detail-empty-image"
									/>
								</div>
							</>
						) : (
							<>
								<div className="billing-detail-header">
									<div>
										<div className="billing-detail-title billing--title">
											Accounts Overview
										</div>

										<div className="Billing--Info__subtitle">
											View and manage transactions, invoices, and usage for
											specific account or all accounts
										</div>
									</div>
									<div className="billing-details-date-picker">
										<DatePicker />
									</div>
								</div>
								<div
									className={`${manageTableContainer} billing-table-container `}
								>
									{accountOptions?.length > 0 && (
										<div className="billing-detail_dropdown">
											<ReactDropdown
												label="Accounts"
												options={accountOptions}
												isMulti
												handleChangeSelect={e => handleChangeDropdown(e)}
												value={selectedDropdownItem}
												defaultValue={selectedDropdownItem}
												optionsStyle={{
													'&:hover': {
														color: '#fff',
														backgroundColor: '#3c65d6',
														'.account-dropdown-wrapper__delete': {
															color: '#d32222 !important',
															backgroundColor: '#f2d0d4',
														},
													},
												}}
											/>
										</div>
									)}
									<table className="billing-table">
										<thead className="billing-table-container_header">
											<tr>
												<th className="billing-table-header">PRODUCT</th>
												<th className="billing-table-header billing-table-header--text-right">QUANTITY</th>
												<th className="billing-table-header billing-table-header--text-right">UNIT PRICE (USD)</th>
												<th className="billing-table-header billing-table-header--text-right">TOTAL COST (USD)</th>
											</tr>
										</thead>
										{Object.keys(showSelectedBillingDetail?.services).map(
											category => (
												<Category
													key={category}
													category={category}
													SubCategoryList={
														showSelectedBillingDetail?.services[category]
													}
													handleOnClickBillingDetails={
														handleOnClickBillingDetails
													}
												/>
											)
										)}
										<CategoryFooter data={showSelectedBillingDetail}/>
									</table>
								</div>
							</>
						)}
					</>
				)}
			</div>

			{hideBillingModal && (
				<Modal
					isOpen={openView}
					modalName="Verification Details"
					closeModal={() => setOpenView(false)}
					className="billing-detail-modal"
					showCloseBtn={true}
					title={
						<div className="payment-method-details__header">
							<div className="payment-method-details__title">
								{`Verification Details (${
									BillingHeaderItemName[selectedItem ?? 'kyc']
								})`}
							</div>
						</div>
					}
				>
					<BillingItemDetailsTable item={selectedItem as any} />
				</Modal>
			)}
		</Fragment>
	);
};
