export const PathOptions = [
	{
		label: 'Same Path',
		value: 'same_path',
	},
	{
		label: 'New Path',
		value: 'new_path',
	},
];

export const StatusOptions = [
	{
		label: 'Approve',
		value: 'completed',
	},
	{
		label: 'Manual Review',
		value: 'processing',
	},
	{
		label: 'Reject',
		value: 'rejected',
	},
];
