import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { AllPipelinesState, kycSettingsState } from 'global-stores';
import { getDateWithTime } from 'utils';

export const useOnboardingHook = () => {
	const pipelines = useRecoilValue(AllPipelinesState);
	const kycSettings = useRecoilValue(kycSettingsState);

	const onboardingName = useCallback(
		(name: string) => {
			let publishName = name.trim();
			let count = 0;
			const uniqueName = (newName: string) => {
				count = count + 1;
				const index = pipelines.findIndex(item => item.name === newName);
				if (index !== -1) {
					newName = name + '_' + count;
					publishName = newName;
					uniqueName(newName);
				}
			};
			uniqueName(name);
			return publishName;
		},
		[pipelines]
	);

	const onboardingNameWithTime = useCallback(() => {
		const time = getDateWithTime(new Date().getTime(), 'MMM dd, yyyy HH:mm:ss');
		const publishName = `Onboarding ${time}`;
		return publishName;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getDateWithTime]);

	const isPricingHide = useMemo(() => {
		const { showPricing } = kycSettings;
		return showPricing ?? true;
	}, [kycSettings]);

	return { onboardingName, onboardingNameWithTime, isPricingHide };
};
