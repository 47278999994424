import { FC, useEffect, useMemo } from 'react';

import { PipelineMasterSteps } from 'global-stores';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ActionList, labelData } from 'views/complex-onboarding-flow/constant';
import { ComplexSettingFormState } from 'views/pipeline';
import { CustomHandle } from '../custom-handle';
import { ICustomNode } from '../custom-node';
import { RenderFormBlock } from './render-form-block';
import './account-form-action.scss';

const StepIds = ['radio'];

export const AccountFormAction: FC<ICustomNode> = ({ id: nodeId, data }) => {
	const pipelinesSteps = useRecoilValue(PipelineMasterSteps);
	const setComplexSettingForm = useSetRecoilState(ComplexSettingFormState);

	const actionId = useMemo(() => data.label, [data.label]);

	const actions = useMemo(
		() =>
			pipelinesSteps.find(
				pipelineStep => pipelineStep.key === (ActionList as any)[actionId]
			)?.actions[0],
		[actionId, pipelinesSteps]
	);

	const renderActionLayout = useMemo(
		() =>
			StepIds.map((el: string) => (
				<RenderFormBlock
					label={labelData[el]}
					key={el}
					price={actions?.price || 0}
					nodeId={nodeId}
				/>
			)),
		[nodeId, actions]
	);
	//Shahbaaz Set questionries on complex config setting form for showing price breakdown
	useEffect(() => {
		setComplexSettingForm((prev: any) => {
			const newObj = structuredClone(prev);
			if (!newObj[nodeId]) {
				newObj[nodeId] = {};
			}
			Object.assign(newObj[nodeId], {
				Identity: 'Identity',
			});

			return newObj;
		});
	}, [nodeId, setComplexSettingForm]);

	return (
		<>
			<div className="account-form-action">{renderActionLayout}</div>
			<CustomHandle value={nodeId} showRight={false} />
		</>
	);
};
