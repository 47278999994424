export const getLuckSheetData = (typePayIn?: boolean) => {
	const finalXlsValue: any = window;
	finalXlsValue?.luckysheet.exitEditMode();
	const finalData = finalXlsValue?.luckysheet?.transToCellData?.(
		finalXlsValue?.luckysheet.getSheetData()
	);
	const expectedArray = finalData?.reduce((acc: any, curr: any) => {
		if (!acc[curr.r]) {
			acc[curr.r] = [];
		}
		if (typePayIn) {
			if (curr.v.v || curr.v.v === 0) {
				acc[curr.r][curr.c] = curr.v.m?.toString();
			} else {
				if (acc[0][curr.c] === 'Fund Transfer Amount' && acc[curr.r]?.length) {
					acc[curr.r][curr.c] = '0';
				}
			}
			return acc;
		} else {
			if (curr.v.v) {
				acc[curr.r][curr.c] = curr.v.m?.toString();
			} else {
				if (acc[0][curr.c] === 'Fund Transfer Amount' && acc[curr.r]?.length) {
					acc[curr.r][curr.c] = '0';
				}
			}
			return acc;
		}
	}, []);

	const headers = expectedArray[0];
	const rows = expectedArray.filter(
		(item: any, index: number) => index !== 0 && item?.length
	);

	return {
		headers,
		rows,
		expectedArray,
	};
};


export const deepCompareLucksheetData = (
	firstDataSet: any,
	secondDataSet: any
) => {
	if (
		firstDataSet.headers &&
		firstDataSet.rows &&
		firstDataSet.expectedArray &&
		secondDataSet.headers &&
		secondDataSet.rows &&
		secondDataSet.expectedArray
	) {
		return (
			JSON.stringify(firstDataSet.headers) ===
				JSON.stringify(secondDataSet.headers) &&
			JSON.stringify(firstDataSet.rows) ===
				JSON.stringify(secondDataSet.rows) &&
			JSON.stringify(firstDataSet.expectedArray) ===
				JSON.stringify(secondDataSet.expectedArray)
		);
	}
	return false;
};
