import { IMAGE_GCP_URL } from 'constant';

interface IProps {
	fileName?: string;
	url?: string;
	className?: string;
	height?: string;
	width?: string;
	props?: any;
	onLoad?: any;
	onError?: () => void;
	loading?: 'lazy' | 'eager';
}

/**
 * Image component
 *
 * @param {string} IProps.fileName - The name of the image file
 * @param {string} IProps.url - The URL of the image
 * @param {string} IProps.className - Additional CSS class for styling
 * @param {string} IProps.height - The height of the image
 * @param {string} IProps.width - The width of the image
 * @param {any} IProps.props - Additional props to be passed to the <img> element
 * @param {() => void} IProps.onLoad - Callback function triggered when the image is loaded
 * @param {() => void} IProps.onError - Callback function triggered when there is an error loading the image
 * @param {"lazy" | "eager"} IProps.loading - The loading strategy for the image
 */

export const Image = ({
	fileName,
	url: imageUrl,
	className,
	height,
	width,
	props,
	onLoad,
	onError,
	loading = 'eager',
}: IProps) => {
	const url = imageUrl ?? `${IMAGE_GCP_URL}/${fileName}`;
	return (
		<img
			loading={loading}
			src={url}
			alt={fileName}
			className={className ?? ''}
			height={height}
			width={width}
			onLoad={onLoad}
			{...props}
			{...(onError ? { onError } : {})}
		/>
	);
};
