import { useCallback, useMemo, useState } from 'react';
import { AmlInfoHeader } from '../aml-info-header';
import { AML_DETAILS_TABLE, AML_INFO_HEADER } from '../../constants';
import { NoData } from 'views/sessions/components/no-data-available';
import './aml-details.scss';

// Pradeep Chaurasia :  New Aml Details UI
export const AmlDetails = ({ aml = {} }: any) => {
	// state for toggle
	const [toggel, setToggel] = useState<any>({});
	// here entity data is getting formating
	const entityFormatter = useCallback((data: any) => {
		if (Array.isArray(data)) {
			return data;
		} else if (typeof data === 'object' && data !== null) {
			return [data];
		} else if (typeof data === 'string') {
			return [{ '&t': data }];
		} else {
			return [];
		}
	}, []);

	const getAmlValue = useCallback(
		(key: string, itemKey: string, item: any, index: number) => {
			const { entity } = item?.[itemKey][index] ?? {};
			switch (key) {
				case 'riskScore':
					return item?.[itemKey][index]?.[key] ?? 'N/A';
				case 'name':
				case 'OriginalSource':
					return entity?.[key] ?? 'N/A';
				case 'dob': {
					const getDOB = entityFormatter(entity?.dobs?.dob);

					// return entity?.dobs?.dob?.$t ?? 'N/A';
					return (
						<div className="Aml-Details--content-wrapper__desc__amlInfoDetailsRow__value__list">
							{getDOB?.length
								? (getDOB ?? []).map((dob: { $t: string }) => (
										<div
											key={dob?.$t}
											className="Aml-Details--content-wrapper__desc__amlInfoDetailsRow__value__list__list-item"
										>
											{getDOB?.length > 1 && <i className="ri-circle-fill" />}
											{dob?.$t}
										</div>
								  ))
								: 'N/A'}
						</div>
					);
				}
				case 'pob':
					return entity?.pobs?.pob ?? 'N/A';
				case 'title':
					return entity?.titles?.title ?? 'N/A';
				case 'Gender':
				case 'NameSource':
				case 'Relationship':
				case 'SubCategory':
				case 'EntityLevel':
				case 'OtherInformation': {
					const sdfData = entity?.sdfs?.sdf;
					const getSdfData = entityFormatter(sdfData);
					return getSdfData.length
						? getSdfData.find(
								(sdfItem: { name: string }) => sdfItem?.name === key
						  )?.$t ?? 'N/A'
						: 'N/A';
				}
				case 'alias': {
					const getAliases = entityFormatter(entity?.aliases?.alias);
					return (
						<div className="Aml-Details--content-wrapper__desc__amlInfoDetailsRow__value__list">
							{getAliases?.length
								? (getAliases ?? []).map((aliasItem: { $t: string }) => (
										<div
											key={aliasItem?.$t}
											className="Aml-Details--content-wrapper__desc__amlInfoDetailsRow__value__list__list-item"
										>
											{getAliases?.length > 1 && (
												<i className="ri-circle-fill" />
											)}
											{aliasItem?.$t}
										</div>
								  ))
								: 'N/A'}
						</div>
					);
				}
				case 'LanguagesSpoken': {
					const getSdfsData = entityFormatter(entity?.sdfs?.sdf);
					return (
						<div className="Aml-Details--content-wrapper__desc__amlInfoDetailsRow__value__list">
							{(getSdfsData ?? [])?.filter(
								(sdfItem: { name: string }) =>
									sdfItem.name === 'LanguagesSpoken'
							)?.length
								? (getSdfsData ?? []).map(
										(sdfItem: { $t: string; name: string }) =>
											sdfItem.name === 'LanguagesSpoken' && (
												<div
													key={sdfItem?.$t}
													className="Aml-Details--content-wrapper__desc__amlInfoDetailsRow__value__list__list-item"
												>
													{getSdfsData?.length > 1 && (
														<i className="ri-circle-fill" />
													)}
													{sdfItem?.$t}
												</div>
											)
								  )
								: 'N/A'}
						</div>
					);
				}
				case 'address': {
					const getAddress = entityFormatter(entity?.addresses?.address);
					return (
						<div className="Aml-Details--content-wrapper__desc__amlInfoDetailsRow__value__list">
							{getAddress?.length
								? (getAddress ?? []).map((addressItem: any) => (
										<div
											key={addressItem}
											className="Aml-Details--content-wrapper__desc__amlInfoDetailsRow__value__list__list-item"
										>
											{getAddress?.length > 1 && (
												<i className="ri-circle-fill" />
											)}
											{Object.values(addressItem ?? {}).join(', ')}
										</div>
								  ))
								: 'N/A'}
						</div>
					);
				}

				default:
					return 'N/A';
			}
		},
		[entityFormatter]
	);

	const renderDiscTable = useCallback(
		(itemKey: string, item: any, index: number) => {
			const getKey = itemKey?.split('-')[0];

			return (AML_DETAILS_TABLE?.[getKey as string] ?? []).map(
				({ key, label }: { key: string; label: string }) => (
					<div
						key={key + '_' + getKey}
						className="Aml-Details--content-wrapper__desc__amlInfoDetailsRow"
					>
						<div className="Aml-Details--content-wrapper__desc__amlInfoDetailsRow__label">
							{label}
						</div>

						<div className="Aml-Details--content-wrapper__desc__amlInfoDetailsRow__value">
							<span>:</span>
							{getAmlValue(key, getKey as string, item, index)}
						</div>
					</div>
				)
			);
		},
		[getAmlValue]
	);

	// Pradeep Chaurasia : check Aml is empty
	const checkAml = useMemo(
		() => Object.keys(aml).filter((key: string) => AML_INFO_HEADER[key]).length,
		[aml]
	);

	const renderAmlData = useMemo(() => {
		if (!(aml && checkAml)) return <NoData />;

		return Object.keys(aml).map(key => {
			if (
				!Array.isArray(aml[key]) ||
				aml[key].length === 0 ||
				key === 'riskScores'
			)
				return null;
			return aml[key]?.map((entry: any, index: any) => {
				const { entity, riskScore, subCaseStatus } = entry;
				const entryKey = `${key}-${index}`;

				return (
					<div key={entryKey ?? ''} className="Aml-Details--content-wrapper">
						<AmlInfoHeader type={key} />
						<div
							onClick={() =>
								setToggel({
									...toggel,
									[`${entryKey}`]: toggel[`${entryKey}`] ? false : true,
								})
							}
							className="Aml-Details--content-wrapper__head"
						>
							<div className="Aml-Details--content-wrapper__head__title">
								<span>{entity?.name ?? '--'}</span>
								<i
									className={`ri-arrow-${
										toggel?.[entryKey] ? 'up' : 'down'
									}-s-line Aml-Details--content-wrapper__head__title__icon`}
								/>
							</div>
							<div className="Aml-Details--content-wrapper__head__sub-title">
								<div className="Aml-Details--content-wrapper__head__sub-title__risk">
									Risk Score :{' '}
									<span className="Aml-Details--content-wrapper__head__sub-title__risk-value">
										{riskScore ?? '0%'}
									</span>
								</div>
								<i className="ri-circle-fill Aml-Details--content-wrapper__head__sub-title__circle" />
								<div className="Aml-Details--content-wrapper__head__sub-title__status">
									Status :{' '}
									<span className="Aml-Details--content-wrapper__head__sub-title__status-value">
										{subCaseStatus ?? 'N/A'}
									</span>
								</div>
							</div>
						</div>
						{toggel?.[entryKey] && (
							<div className="Aml-Details--content-wrapper__desc">
								{renderDiscTable(entryKey, aml, index)}
							</div>
						)}
					</div>
				);
			});
		});
	}, [aml, checkAml, renderDiscTable, toggel]);

	const renderAdverseMedia = useMemo(
		() =>
			false && (
				<div className="Aml-Details--adverse-media">
					<AmlInfoHeader type={'Adverse media'} />
					<div className="Aml-Details--adverse-media-main">
						<div className="Aml-Details--adverse-media-main__head">
							It’s About Systems, Not the individual
						</div>
						<div className="Aml-Details--adverse-media-main__desc">
							Clinton almost got disbarred, but was eventually suspended by the
							Arkansas Bar Association for 5 years in 2001 for his role in his
							sex scandal.
						</div>
						<div className="Aml-Details--adverse-media-main__date">
							<>September 11, 2006</>
							<i className="ri-circle-fill Aml-Details--adverse-media-main__date__circle" />
							<>Factiva Article - AFNWS00020060912e29b000lp</>
						</div>
						<div className="Aml-Details--adverse-media-main__url">
							URL :{' '}
							<a
								target="__blank"
								href="https://idmerit.com/anti-money-laundering-aml"
								className="Aml-Details--adverse-media-main__url__link"
							>
								https://idmerit.com/anti-money-laundering-aml
							</a>
						</div>
					</div>
				</div>
			),
		[]
	);

	return (
		<div className="Aml-Details--wrapper">
			{renderAmlData}
			{renderAdverseMedia}
		</div>
	);
};
