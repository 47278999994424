export const LOADING_ANIMATION = {
	title: 'Loading animation',
	description:
		'Select either a generic loader or upload your own custom design to personalize your brand loading experience.',
	uploaded: {
		label: 'Splash Screen / Loader .gif',
		desc: (
			<>
				Enjoy a personalised loading experience reflecting your brand&apos;s
				identity and user preferences. <br />
				File size limit: <strong>Upto 5mb</strong>
			</>
		),
	},
	options: [
		{
			key: 'default',
			label: 'Default',
			desc: 'The default loader provides a standard loading animation for seamless transitions throughout the user experience.',
		},
		{
			key: 'custom',
			label: 'Upload your Splash Screen / Loader .gif',
			desc: (
				<>
					Upload your own loader in MP4 or GIF format, for a personalized user
					experience for your end user. <br />
					File size limit: <strong>Upto 5mb</strong>
				</>
			),
		},
	],
};

export const ALLOWED_FILE_TYPES: { [key: string]: boolean } = {
	'video/mp4': true,
	'image/gif': true,
};

export const ALLOWED_FILE_SIZE = 5 * 1024 * 1024;

export const LOADER_KEYS =  {
	Default : "default",
	Custom : "custom",
	Uploaded: 'uploaded',
	Upload:'upload',
	Loading:'loading',
	Edit: 'edit',
	Remove: 'remove',
	Retry: 'retry',
}
