import { FC, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import {
	analyticsChartHeaderState,
	analyticsChartTwoHeaderStart,
	useSemiPieChart,
} from 'views/analytics/hooks';
import './analytics-chart.scss';

interface ISeriesData {
	name: string;
	y: number;
	sliced?: boolean;
	color: string;
	diff?: number;
	opacity?: number;
}
interface ISemiPieChart {
	isLoading: boolean;
	title: string;
	seriesData: ISeriesData[];
	chartPercent: number;
}

export const SemiPieChart: FC<ISemiPieChart> = ({
	isLoading,
	title,
	seriesData,
	chartPercent,
}) => {
	const { getSemiPieConfig } = useSemiPieChart();

	const compareDateRange = useRecoilValue(analyticsChartTwoHeaderStart);
	const selectedDate = useRecoilValue(analyticsChartHeaderState);

	const [value, setValue] = useState<number>(chartPercent);
	const [seriesName, setSeriesName] = useState<string>('Steps');

	useEffect(() => {
		setSeriesName('Steps');
		setValue(chartPercent);
	}, [chartPercent, selectedDate, compareDateRange]);

	useEffect(() => {
		getSemiPieConfig({
			isLoading,
			title,
			seriesData,
			value,
			seriesName,
			setSeriesName,
			setValue,
		});
	}, [getSemiPieConfig, isLoading, seriesData, seriesName, title, value]);

	return (
		<div className="analytic-chart-wrapper">
			<div id={title} />
		</div>
	);
};
