import { memo, FC, useMemo } from 'react'
import { Image } from '@storybook'

interface IProps {
	image: string,
	status: string,
}

export const RecipientImage: FC<IProps> = memo(({ status, image }) => {

    const isCompleted = useMemo(() => status === "completed", [status]);

    if(image)
        return <Image className='recipients-deatils__image' url={image} />
    
    return (
        <div className='recipients-deatils__image'>
            {
                isCompleted ?  <i className="ri-file-user-line" /> : <i className="ri-file-history-line" />
            }
            <p>{isCompleted ? 'Received signed document' : 'Not yet signed'}</p>
        </div>
    )
});

RecipientImage.displayName = 'RecipientImage'