import { FC } from 'react';
import './gesture-setttings.scss';

interface IGestureSettings {
	handleChange: any;
	parentIndex: number;
	fieldIndex: number;
	options: any;
	label: string;
	description: string;
	className?: string;
}

export const GestureSettings: FC<IGestureSettings> = ({
	handleChange,
	parentIndex,
	fieldIndex,
	options,
	label,
	description,
	className = '',
}) => {
	return (
		<div
			style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
			className={className}
		>
			<label className="input__label">{label}</label>
			<div className="dropdown__container__description">{description}</div>
			<div
				style={{
					border: '1px solid #DFE3EC',
					background: 'var(--color-input-background)',
					padding: '16px',
					display: 'flex',
					flexDirection: 'column',
					gap: 8,
					borderRadius: 8,
				}}
			>
				<div
					style={{
						display: 'flex',
						width: '100%',
						gap: 16,
						justifyContent: 'space-between',
						borderBottom: '1px solid #D9D9D9',
						paddingBottom: 8,
					}}
				>
					<div style={{ display: 'flex', width: '100%', gap: 16 }}>
						<input
							type="checkbox"
							style={{ transform: 'scale(1.3)' }}
							onClick={(event: any) =>
								handleChange(
									event.target.checked,
									parentIndex,
									fieldIndex,
									0,
									'options',
									'checked',
									'all'
								)
							}
						/>
						<div style={{ color: '#363A45', fontSize: 'var(--font-size-3)' }}>
							Select randomly
						</div>
					</div>
				</div>
				{options.map((el: any, index: number) => (
					<div
						key={el.name + index.toString()}
						style={{
							display: 'flex',
							width: '100%',
							gap: 8,
							justifyContent: 'space-between',
						}}
					>
						<div
							style={{ display: 'flex', width: '100%', gap: 16 }}
							onClick={() =>
								handleChange(
									!el.checked,
									parentIndex,
									fieldIndex,
									index,
									'options',
									'checked'
								)
							}
						>
							<input
								checked={el.checked}
								type="checkbox"
								style={{ transform: 'scale(1.3)' }}
								defaultChecked={el.checked}
							/>
							<div
								style={{
									color: '#363A45',
									fontSize: 'var(--font-size-3)',
								}}
							>
								{el.label}
							</div>
						</div>
						<div
							style={{
								display: 'flex',
								width: '100%',
								gap: 8,
								justifyContent: 'flex-end',
							}}
						>
							<div
								style={{
									height: '24px',
									width: '24px',
									display: 'grid',
									placeItems: 'center',
									border: '1px solid rgba(223, 227, 236, 1)',
									fontSize: 16,
									opacity: el.value == 0 ? 0.5 : 1,
									cursor: el.value == 0 ? 'not-allowed' : 'pointer',
								}}
								onClick={() =>
									handleChange(
										el.value === 0 ? 0 : el.value - 1,
										parentIndex,
										fieldIndex,
										index,
										'options',
										'value'
									)
								}
							>
								<i className="ri-subtract-line"></i>
							</div>
							<div
								style={{
									height: '24px',
									width: '24px',
									display: 'grid',
									placeItems: 'center',
									border: '1px solid rgba(223, 227, 236, 1)',
									background: 'rgba(223, 227, 236, 1)',
								}}
							>
								{el.value}
							</div>
							<div
								style={{
									height: '24px',
									width: '24px',
									display: 'grid',
									placeItems: 'center',
									fontSize: 16,
									border: '1px solid rgba(223, 227, 236, 1)',
									opacity: el.value === 10 ? 0.5 : 1,
									cursor: el.value === 10 ? 'not-allowed' : 'pointer',
								}}
								onClick={() =>
									handleChange(
										el.value === 10 ? 10 : el.value + 1,
										parentIndex,
										fieldIndex,
										index,
										'options',
										'value'
									)
								}
							>
								<i className="ri-add-line"></i>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};
