import { FC, useCallback, useState } from 'react';
import { SectionCount } from '../section-count';
import { KybInfoHeader } from '../../../kyb-info-header';

interface IProps {
	label: string;
	matches: number;
	showViewMoreBtn?: boolean;
	children: JSX.Element;
	handleViewMore?: any;
}

export const OFACSectionHOC: FC<IProps> = ({
	label,	
	children,
	matches,
	showViewMoreBtn = true,
	handleViewMore,
}) => {
	const [showMoreDetails, setShowMoreDetails] = useState(false);

	const toggleMoreButton = useCallback(() => {
		setShowMoreDetails(!showMoreDetails);
		handleViewMore(!showMoreDetails);
	}, [handleViewMore, showMoreDetails]);

	return (
		<div>
			<div className="kyb-members_header ofac-header">
				<KybInfoHeader type={label} />
			</div>
			{matches && <SectionCount count={matches} label="Total matches" />}
			{children}
			{showViewMoreBtn && (
				<div onClick={toggleMoreButton} className="kyb-members_view-more">
					{showMoreDetails ? 'View less' : 'View more'}
				</div>
			)}
		</div>
	);
};
