import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
	InvestorTableGridDataCaptable,
	PrivateSettingStateCaptable,
	TableStateCaptable,
} from './states';
import { InvestorTableGridData } from 'global-stores';
import { Captable } from './views';
interface IProp {
	theme: string;
	captable: string;
	grid_data: any[][];
	csvTemplateUrl: string;
}
export const CaptableCustom = ({
	theme,
	captable,
	grid_data,
	csvTemplateUrl,
}: IProp) => {
	const setSetting = useSetRecoilState(PrivateSettingStateCaptable);
	const setGrid = useSetRecoilState(InvestorTableGridDataCaptable);
	const setOrignalGrid = useSetRecoilState(InvestorTableGridData);
	const table = useRecoilValue(TableStateCaptable);

	useEffect(() => {
		setSetting({
			theme: theme ?? 'light',
		});
		if (theme === 'dark') {
			document.body.classList.add('dark-theme');
		}
		// eslint-disable-next-line
	}, [theme]);

	useEffect(() => {
		// eslint-disable-next-line array-callback-return
		const header: any[] = [];
		table?.columns?.forEach((item: any) => {
			if (item.dataType !== 'null') {
				const headerItem =
					item.dataType === 'text' ? item.label : item.dataType;
				header.push({ value: headerItem });
			}
		});

		const tableBody = table?.data?.map((item: any) => {
			const rowData: any[] = [];
			Object.keys(item).forEach((row: any) => {
				if (row !== 'ID') {
					rowData.push({ value: item[row] });
				}
			});
			return rowData;
		});

		const tableData = [];
		tableData.push(header);
		tableBody?.forEach((item: any) => {
			tableData.push(item);
		});

		setOrignalGrid(tableData);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [table]);

	useEffect(() => {
		if (grid_data) {
			const updatedGridData = grid_data.map(item => {
				return item.map(ele => {
					return ele.value;
				});
			});

			setGrid(updatedGridData);
		}
	}, [grid_data, setGrid]);

	return (
		<div className="captable-wrapper">
			{captable === 'true' && <Captable templateUrl={csvTemplateUrl} />}

			{/* TODO @chandan commented By Avinash
       
       <CaptableGrid /> */}
		</div>
	);
};
