import { atom } from 'recoil';

import {
	IDealFilterTags,
	INodes,
	IPerson,
	ITransactionDeal,
	ITransactionDealDetailsModal,
	ITransactionDeals,
} from './type';

export const TransactionDealsState = atom<ITransactionDeals>({
	key: 'transaction-deals-state-key',
	default: {
		totalPages: 0,
		currentPage: 0,
		result: [],
	},
});

export const TransactionDealDetailsModalState =
	atom<ITransactionDealDetailsModal>({
		key: 'transaction-deals-details-modal-state-key',
		default: '',
	});

export const SelectedTransactionDealState = atom<ITransactionDeal>({
	key: 'selected-transaction-deal-state-key',
	default: {
		name:'',
		_id: '',
		sellers: [],
		buyers: null,
		salePrice: null,
		fees: [],
		type: '',
		business: '',
		isDeleted: false,
		deletedAt: '',
		createdAt: '',
		updatedAt: '',
		dealId: '',
		status: 'pending',
	},
});

export const TransactionDealDetailsLoadingState = atom<boolean>({
	key: 'transaction-deal-details-loading-state-key',
	default: true,
});

export const DealSessionNodesState = atom<INodes[]>({
	key: 'deal-session-nodes-state-key',
	default: [],
});

export const DealUserSessionDetailsState = atom({
	key: 'deal-user-session-details-state-key',
	default: null,
});

export const DealSelectedUserDetailsState = atom<IPerson | null>({
	key: 'deal-selected-user-details-state-key',
	default: null,
});

export const DealFilterIndicatorState = atom<number>({
	key: 'deal-filter-indicator-state-key',
	default: 0,
});

export const DealFilterTagsState = atom<IDealFilterTags>({
	key : 'deal-filter-tag-state-key',
	default: {}
})
