import type { IClient } from '../type';

import { FC, useCallback, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { IconBtn, ReactResponsiveTable } from '@storybook';
import { message, ROLE } from 'constant';
import { CredentialTableRow, loginState } from 'global-stores';
import { useNotification } from 'hooks';
import { PERMISSION_SCREEN, useUserRoles } from 'views/routes-children';
import { credentialTableHead } from './../constants';

interface IHeader {
	client: IClient[];
	revealCredential: (i: number) => void;
	editCredential: (i: number) => void;
	handleDeleteCredential: (id: string) => void;
	isLoading: boolean;
}

interface IOptionsRow {
	action: () => void;
	createdAt: string;
	name: string;
	_id: string;
}

export const CredentialTable: FC<IHeader> = ({
	client,
	revealCredential,
	editCredential,
	handleDeleteCredential,
	isLoading,
}) => {
	const { successNotification } = useNotification();
	const { COPY_TOASTER_MESSAGE } = message;
	const { role } = useRecoilValue(loginState);
	const { MANAGER } = ROLE;
	const [credentialListRows, setCredentialListRows] =
		useRecoilState(CredentialTableRow);

	const { checkUserWritePermission } = useUserRoles();

	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.DevelopersKeys),
		[checkUserWritePermission]
	);

	const handleCopy = useCallback(
		(secretId: string) => {
			navigator.clipboard.writeText(secretId);
			successNotification(COPY_TOASTER_MESSAGE);
		},
		[COPY_TOASTER_MESSAGE, successNotification]
	);

	useEffect(() => {
		if (client && credentialTableHead.HEADER.length) {
			const rows: IOptionsRow[] = [];
			client.forEach((item: any, i, totalClients) => {
				if (item) {
					let row: any = {};
					credentialTableHead.HEADER.forEach(({ format, key }) => {
						if (format === 'jsx' && key === 'action') {
							const value = () => (
								<div className="btn-group">
									<IconBtn
										handleClickIcon={() => revealCredential(i)}
										btnClassName="ri-eye-line icon__primary icon__large icon"
										isDisabled={role === MANAGER}
									/>
									{isUserPermissionWrite && (
										<>
											<IconBtn
												handleClickIcon={() => editCredential(i)}
												btnClassName="ri-edit-line icon__primary icon__large icon"
												isDisabled={role === MANAGER}
											/>
											<IconBtn
												handleClickIcon={() => handleDeleteCredential(item._id)}
												btnClassName="ri-delete-bin-line  icon__danger icon__large icon"
												isDisabled={
													role === MANAGER || totalClients.length === 1
												}
											/>
										</>
									)}
								</div>
							);
							return (row[key] = value);
						}
						if (format === 'jsx' && key === 'clientId') {
							const value = () => (
								<>
									<div className="text_cell">{item[key]}</div>
									<div className="btn__Onhover">
										<IconBtn
											btnClassName="ri-file-copy-line  icon__primary icon__large"
											handleClickIcon={() => handleCopy(item.clientId)}
										/>
									</div>
								</>
							);
							row = {
								...row,
								[key]: value,
								clientID: item[key] ?? '--',
							};
							return (row[key] = value);
						}
						row = { ...row, [key]: item[key] ?? '--' };
						return null;
					});
					rows.push(row);
				}
			});
			return setCredentialListRows(rows);
		}
	}, [
		MANAGER,
		client,
		editCredential,
		handleCopy,
		handleDeleteCredential,
		revealCredential,
		role,
		setCredentialListRows,
		isUserPermissionWrite,
	]);

	return (
		<ReactResponsiveTable
			isLoading={isLoading}
			tableType="developerKey"
			column={credentialTableHead.HEADER}
			rows={credentialListRows}
			showSearch={true}
			showFilter={false}
			height={'calc(100vh - 200px)'}
			emptyHeight={'calc(100vh - 240px'}
			columnHandle={false}
			showDateFilter={true}
			EmptyIllustration={'empty-api-list.svg'}
			EmptyMessageHeading={'No Developer Key Available'}
			EmptyMessageDescription={'You have not created any Developer key yet.'}
		/>
	);
};
