import { FocusEventHandler } from 'react';
import Select, { components } from 'react-select';

import './dropdown.scss';

export type IOption = { label: string; value: any } | any;

type IMenuType = 'auto' | 'top' | 'bottom';

interface Props {
	handleChangeSelect?: (event: IOption) => void;
	options: IOption[];
	value?: IOption;
	defaultValue?: IOption;
	isSearchable?: boolean;
	isMulti?: boolean;
	createStyle?: any;
	controlStyle?: any;
	menuStyle?: any;
	multiValueStyle?: any;
	multiValueRemoveStyle?: any;
	optionsStyle?: any;
	Isdisable?: boolean;
	placeholder?: string;
	label?: string;
	handleBlur?: FocusEventHandler<HTMLInputElement>;
	isRequired?: boolean;
	optionsDropHeight?: number | null;
	errorMessage?: string;
	maxMenuHeight?: number | undefined;
	closeMenuOnSelect?: boolean;
	description?: string;
	footerLabel?: JSX.Element | string;
	handleFooterLabelClick?: () => void;
	isCustomComponent?: boolean;
	focusValue?: string;
}

/**
 * Interface for the props of the ReactDropdown component.
 * @param {Function} Props.handleChangeSelect - Callback function invoked when the selection changes.
 * @param {IOption[]}Props.options - The list of options for the dropdown.
 * @param {IOption} Props.value - The selected value(s) in the dropdown.
 * @param {IOption} Props.defaultValue - The default value for the dropdown.
 * @param {boolean} Props.isSearchable - Flag indicating whether the dropdown is searchable.
 * @param {boolean} Props.isMulti - Flag indicating whether the dropdown supports multiple selections.
 * @param {Styles} Props.createStyle - Custom styles for the ReactSelect component.
 * @param {Styles} Props.controlStyle - Custom styles for the control section of the dropdown.
 * @param {Styles} Props.menuStyle - Custom styles for the menu section of the dropdown.
 * @param {Styles} Props.multiValueStyle - Custom styles for the multi-value section of the dropdown.
 * @param {Styles} Props.multiValueRemoveStyle - Custom styles for removing multi-value in the dropdown.
 * @param {Styles} Props.optionsStyle - Custom styles for the individual options in the dropdown.
 * @param {boolean} Props.Isdisable - Flag indicating whether the dropdown is disabled.
 * @param {string} Props.placeholder - The placeholder text for the dropdown.
 * @param {string} Props.label - The label for the dropdown.
 * @param {FocusEventHandler<HTMLInputElement>} Props.handleBlur - Callback function invoked when the dropdown loses focus.
 * @param {boolean} Props.isRequired - Flag indicating whether the dropdown is required.
 * @param {number}  Props.optionsDropHeight - The maximum height of the dropdown options menu.
 * @param {string} Props.errorMessage - Error message to display.
 * @param {string} Props.maxMenuHeight - Maximum height of the menu before scrolling
 */

export const ReactDropdown = ({
	handleChangeSelect,
	options,
	value,
	defaultValue,
	isSearchable = false,
	isMulti = false,
	createStyle,
	controlStyle = {},
	menuStyle = {},
	multiValueStyle = {},
	multiValueRemoveStyle = {},
	optionsStyle = {},
	Isdisable,
	placeholder = 'Select',
	handleBlur,
	label,
	isRequired = false,
	optionsDropHeight = null,
	errorMessage,
	maxMenuHeight = undefined,
	closeMenuOnSelect = false,
	description = '',
	footerLabel = '',
	isCustomComponent = false,
	focusValue = '',
}: Props) => {
	const customStyle = {
		control: (styles: { [key: string]: number | string }) => ({
			...styles,
			backgroundColor: !errorMessage ? '#f5f8ff' : 'rgba(255,0,0,.06);',
			minHeight: 52,
			border: !errorMessage
				? '1px solid rgba(215, 223, 245, 1)'
				: '1px solid var(--color-error)',
			borderRadius: 8,
			fontWeight: '500',
			color: '#fff',
			boxShadow: 'none',
			'&:hover': {
				cursor: 'pointer',
				borderColor: '#ced2de',
			},
			'&:focus': {
				borderColor: 'red',
			},
			...controlStyle,
		}),
		menu: (styles: { [key: string]: any }) => ({
			...styles,
			background: '#f5f8ff',
			borderRadius: 8,
			border: '1px solid #ced2de',
			zIndex: 3,
			overflow: 'hidden',
			maxHeight: optionsDropHeight,
			...menuStyle,
		}),
		multiValue: (styles: { [key: string]: any }) => ({
			...styles,
			backgroundColor: 'rgba(224, 233, 255, 1)',
			color: 'rgba(47, 50, 61, 1)',
			borderRadius: 50,
			paddingLeft: 8,
			paddingRight: 8,
			paddingTop: 4,
			paddingBottom: 4,
			margin: 4,
			...multiValueStyle,
		}),

		multiValueRemove: (styles: any) => ({
			...styles,
			color: 'rgba(163, 177, 214, 1)',
			borderRadius: 50,
			marginLeft: 2,
			fontSize: 12,
			...multiValueRemoveStyle,
		}),
		option: (styles: { [key: string]: any }, { data }: any) => {
			const { fontFamily } = data ?? {};
			return {
				...styles,
				fontFamily,
				backgroundColor: data.value === focusValue ? '#3c65d6' : '#f5f8ff',
				color: data.value === focusValue ? '#fff' : '#000',
				// height: 52,
				cursor: 'pointer',
				paddingTop: 12,
				paddingBottom: 12,
				borderBottom: '1px solid #ced2de',
				fontWeight: '500',
				'&:hover': {
					color: '#fff',
					backgroundColor: '#3c65d6',
				},
				'&:last-child': {
					borderBottom: 'none',
				},
				...optionsStyle,
			};
		},
		valueContainer: (provided: any) => ({
			...provided,
			textOverflow: 'ellipsis',
			overflowY: 'scroll',
			display: 'flex',
			maxHeight: '130px',
		}),
	};

	const scrollProps = {
		...(maxMenuHeight && {
			maxMenuHeight: maxMenuHeight,
			menuPlacement: 'auto' as IMenuType,
		}),
	};

	const customComponents = {
		Menu: (props: any) => (
			<components.Menu {...props}>
				{props.children}
				{footerLabel}
			</components.Menu>
		),
	};

	return (
		<div
			id="dropdown__container"
			className={`${Isdisable ? 'dropdown__container--disabled' : ''}`}
		>
			{label && (
				<label htmlFor={label} className="input__label">
					{label}
					{isRequired && <span className="input__isRequired"> *</span>}
				</label>
			)}
			{description && (
				<div className="dropdown__container__description">{description}</div>
			)}
			<Select
				isDisabled={Isdisable}
				onBlur={handleBlur}
				defaultValue={defaultValue}
				isMulti={isMulti}
				value={value}
				placeholder={placeholder}
				className="dropdown"
				styles={createStyle ? createStyle : customStyle}
				options={options}
				isClearable={false}
				closeMenuOnSelect={closeMenuOnSelect || !isMulti}
				isSearchable={isSearchable}
				onChange={handleChangeSelect as any}
				components={
					isCustomComponent
						? customComponents
						: { IndicatorSeparator: () => null }
				}
				{...scrollProps}
			/>
			{/* Shahbaaz: Showing error message  */}
			{errorMessage && <span className="Error--message">{errorMessage}</span>}
		</div>
	);
};
